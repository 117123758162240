/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 18-01-2024
 */
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Button,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { appColors } from "../Assests/AppTheme/appThemeConst";

function UserCreditNoteDialog({
  open,
  setOpen,
  selectedCreditNotes,
  setSelectedCreditNotes,
  creditDetails,
}) {
  const [checked, setChecked] = React.useState([]);
  const consumerbuyBackOrderDetails = useSelector(
    (state) => state.buyBackOrderDetails.creditDetails
  );

  //function for handel close operation
  const handleOnClose = () => {
    setOpen(false);
  };

  const checkBoxFun = (e, item) => {
    if (e.target.checked) {
      console.log(item);
      setSelectedCreditNotes((prev) => [
        ...prev,
        {
          credit_id: item?.credit_id,
          total_amount: item?.total_amount,
          pending_amount: item?.pending_amount,
          amount: item?.pending_amount,
        },
      ]);
      // Also, add this credit_id to checked state
      setChecked((prevChecked) => [...prevChecked, item?.credit_id]);
    } else {
      setSelectedCreditNotes((prev) =>
        prev.filter((note) => note.credit_id !== item?.credit_id)
      );

      setChecked((prevChecked) =>
        prevChecked.filter((id) => id !== item?.credit_id)
      );
    }
  };

  console.log(selectedCreditNotes);
  return (
    <SwipeableDrawer
      anchor="right"
      onClose={handleOnClose}
      open={open}
      sx={{
        width: "500px",
        "& .MuiDrawer-paper": { width: { xs: "20rem", sm: "25rem" } },
        zIndex: 7876787,
      }} // Increase the drawer width
    >
      <Box
        sx={{
          boxShadow: 5,
          margin: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "100vh",
        }}
      >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={3} // Makes the header span across all columns
                  sx={{
                    fontWeight: "bold", // Bold text for the header
                    textAlign: "center", // Center align text
                    fontSize: "1.2rem", // Increase font size if needed
                  }}
                >
                  Credit Note Details
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Credit Id
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Pending Amount
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Select
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creditDetails?.map((obj, idx) => (
                <>
                  {obj?.status == 100 && (
                    <TableRow
                      key={obj?.credit_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {obj?.credit_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        ₹ {obj?.total_amount}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        ₹ {obj?.pending_amount}
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Checkbox
                            onChange={(e) => checkBoxFun(e, obj)}
                            size="small"
                            checked={checked.includes(obj?.credit_id)}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={handleOnClose}
        >
          Close
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}

export default UserCreditNoteDialog;
