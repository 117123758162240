import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  isLoading: true,
  role_id: "",
  token: localStorage.getItem("token"),
  verifyError: true,
  changePasswordError: true,
  promoterDetailsList: [{ email: "", phoneNumber: "", name: "" }],
  promoterChecksum: "",
};

const authReducers = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.SIGN_IN:
      return {
        ...state,
        error: action.payload.error,
        token: action.payload.tkn,
        role_id: action.payload.role_id,
        isLoading: false,
      };
    case actionType.PROMOTERS_DETAILS_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        promoterDetailsList: action.payload.data,
      };
    case actionType.PROMOTER_EMAIL_VERIFY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        promoterChecksum: action.payload.checksum,
      };
    case actionType.PROMOTER_LOGIN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        token: action.payload.tkn,
        role_id: action.payload.role_id,
        isLoading: false,
      };
    //supplier reducer
    case actionType.POST_LOGIN_SUPPLIER:
      return {
        ...state,
        error: action.payload.error,
        token: action.payload.tkn,
        role_id: action.payload.role_id,
        isLoading: false,
      };
    case actionType.RESET_PASSWORD_VERIFY:
      return {
        ...state,
        verifyError: action.payload.error,
        isLoading: false,
      };
    case actionType.FORGOT_PASSWORD_USER_VERIFY_OTP:
      return {
        ...state,
        changePasswordError: action.payload.error,
        isLoading: false,
      };
    case actionType.SIGN_OUT:
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default authReducers;
