import * as Material from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import CustomConsole from "../../CustomConsole";

function PlaceDistributorReturnOrderDialog(props) {
  const [orderQty, setOrderQty] = React.useState("");
  const [selectedAddress, setSelectedAddress] = React.useState("");
  const userDetailsUpdate = useSelector(
    (state) => state.userDetailsUpdate.address
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetFilteredUserAddressAdmin());
  }, []);
  const handleClose = () => {
    props.handleClose();
  };
  const handleSubmit = () => {
    props.handleSubmit({
      orderQty: orderQty,
      setOrderQty: setOrderQty,
      address_id: selectedAddress,
    });
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openPlaceReturnCanOrderDialog}>
        <Material.DialogContent>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Material.Box sx={{ display: "flex" }}>
              <Material.TextField
                id="outlined-select-currency-native"
                select
                sx={{
                  width: { sm: "100%", xs: "100%" },
                }}
                size="small"
                value={selectedAddress}
                onChange={(e) => setSelectedAddress(e.target.value)}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="" disabled>
                  Select WareHouse *
                </option>
                {userDetailsUpdate?.map((option) => (
                  <option key={option.address_id} value={option.address_id}>
                    {option.contact_name}/{option.address_line1}/
                    {option.address_line2}/ {option.pincode}
                  </option>
                ))}
              </Material.TextField>
            </Material.Box>
            <Material.TextField
              label="Order Quantity"
              value={orderQty}
              onChange={(event) => setOrderQty(event.target.value)}
            />
          </Material.Box>
        </Material.DialogContent>
        <Material.Box
          sx={{ display: "flex", justifyContent: "center", gap: 2, m: 2 }}
        >
          <Material.Button
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            close
          </Material.Button>
          <Material.Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!orderQty || !selectedAddress}
          >
            submit
          </Material.Button>
        </Material.Box>
      </Material.Dialog>
    </Material.Box>
  );
}

export default PlaceDistributorReturnOrderDialog;
