import React from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomConsole from "../../CustomConsole";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  GetInvoiceData,
  GetPriceBreakDown,
  ItemizedInvoice,
  ItemInvoiceProgressReset,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { useDispatch, useSelector } from "react-redux";
import ConsolidatedDialog from "./ConsolidatedDialog";
import moment from "moment/moment";
import { SettlementPostpaid } from "../Redux/Actions/adminPoTrackPostpaid";
import Paper from "@mui/material/Paper";
import { SearchUserAdmin } from "../Redux/Actions/addUserAdminAction";
import { GetWalletDetails } from "../Redux/Actions/userDetailsAdminAction";
import { toast } from "react-toastify";
import {
  GetPoTrackDetails,
  SendEmailPostpaidConsumer,
} from "../Redux/Actions/adminPoTrackPostpaid";
import {
  InvoiceGeneration,
  InvoiceProgressReset,
} from "../Redux/Actions/invoiceAction";
import DownloadIcon from "@mui/icons-material/Download";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SettlementsDialog({
  open,
  setOpen,
  poTrackSet,
  userPhone,
  pageNumber,
  phone,
  pageSize,
  poTrack,
  orderType,
  sort,
}) {
  const dispatch = useDispatch();
  const poDetails = useSelector((state) => state.poTrackDetails);
  const addUserAdminDetails = useSelector(
    (state) => state.addUserAdminDetails.users
  );
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const invoiceProgress = useSelector((state) => state.invoicegenerate);

  const [invoiceData, setInvoiceData] = React.useState([]);
  const [priceBreak, setPriceBreak] = React.useState({});
  const [selectedInvoice, setSelectedInvoice] = React.useState(0);
  const [openConsoliDialog, setOpenConsoliDialog] = React.useState(false);
  const [isSettled, setIsSettled] = React.useState(false);
  const [payConsId, setPayConsId] = React.useState("");
  const [walletBal, setWalletBal] = React.useState(0);
  const [cancelInvTost, setCancelInvTost] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState(null);
  const [itemIntervel, setItemIntervel] = React.useState(null);
  const [rotate, setRotate] = React.useState(false);
  const [rotateItem, setRotateItem] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const checkBoxFun = (e, item) => {
    CustomConsole(item);
    if (e.target.checked === true) {
      let obj = {
        invoiceId: item.invoice_id,
      };
      setPriceBreak(obj);
      setSelectedInvoice(parseInt(item.invoice_id));
    } else if (e.target.checked === false) {
      setPriceBreak({});
      setSelectedInvoice(0);
    }
  };

  const getPriceBreak = () => {
    if (Object.keys(priceBreak).length) {
      dispatch(GetPriceBreakDown(priceBreak));
      setOpenConsoliDialog(true);
    }
  };

  const cancelInvoice = () => {
    let reqObj = {
      invoiceId: selectedInvoice,
      is_cancelled: true,
    };
    CustomConsole(reqObj);
    dispatch(SettlementPostpaid(reqObj));
    setCancelInvTost(true);
  };

  const downloadInvoice = (id) => {
    dispatch(InvoiceGeneration(id));
    setRotate(true);
  };

  const sendInvoiceEmail = (id) => {
    let reqObj = {
      invoiceId: id,
      user: payConsId,
    };
    // CustomConsole(reqObj);
    dispatch(SendEmailPostpaidConsumer(reqObj));
  };

  const downloadItemInvoice = (id) => {
    dispatch(ItemizedInvoice(id));
    setRotateItem(true);
  };

  React.useEffect(() => {
    CustomConsole("-----Loading----Invoice---");
    CustomConsole(invoiceProgress);

    if (parseInt(invoiceProgress.progress) === 100) {
      const timerId = setTimeout(() => {
        CustomConsole("----Setting back to zero----");
        dispatch(InvoiceProgressReset(0));
      }, 200);
      setIntervalId(timerId);
    }
    if (parseInt(invoiceProgress.progress) === 0) {
      setRotate(false);
    }

    if (parseInt(invoiceProgress.invoiceGenerationProg) === 0) {
      setRotateItem(false);
    } else if (parseInt(invoiceProgress.invoiceGenerationProg) === 100) {
      const timerId = setTimeout(() => {
        CustomConsole("----Setting back to zero----");
        dispatch(ItemInvoiceProgressReset(0));
      }, 200);
      setItemIntervel(timerId);
    }

    return () => {
      if (intervalId) {
        clearTimeout(intervalId);
      }
      if (itemIntervel) {
        clearTimeout(itemIntervel);
      }
    };
  }, [invoiceProgress]);

  React.useEffect(() => {
    if (poTrackSet) {
      dispatch(GetInvoiceData(poTrackSet));
    }
  }, [poTrackSet]);

  React.useEffect(() => {
    CustomConsole(poDetails.invoiceData);
    if (poDetails.invoiceData) {
      setInvoiceData(poDetails.invoiceData);
    }
  }, [poDetails.invoiceData]);

  React.useEffect(() => {
    if (userPhone) {
      dispatch(SearchUserAdmin(userPhone));
    }
  }, [userPhone]);

  React.useEffect(() => {
    CustomConsole(addUserAdminDetails);
    CustomConsole("---------Setting Uid---------");
    if (addUserAdminDetails && addUserAdminDetails.length) {
      CustomConsole("---------Inside if Setting Uid---------");
      setPayConsId(addUserAdminDetails[0].id);

      // setAddressCalled(true)
      // addUserAdminDetails.users = [];
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (payConsId) {
      dispatch(GetWalletDetails(payConsId));
    }
  }, [payConsId]);

  React.useEffect(() => {
    if (updateUserDetails.walletAmount) {
      setWalletBal(
        () => updateUserDetails.walletAmount ?? updateUserDetails.walletAmount
      );
    }
  }, [updateUserDetails]);

  React.useEffect(() => {
    CustomConsole("----inside the toast Effect----- ");
    if (poDetails.error === false && cancelInvTost === true) {
      CustomConsole("----Inside the toast Effect if condition----- ");
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      let reqObj = {
        page_size: pageSize,
        page_number: pageNumber,
        pp_track_id: poTrack,
        order_status: orderType,
        sort: sort,
        phone: phone,
      };
      dispatch(GetPoTrackDetails(reqObj));
      dispatch(GetInvoiceData(poTrackSet));
      setOpen(false);
      setCancelInvTost(false);
    } else if (poDetails.error === true && cancelInvTost === true) {
      toast.error(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setCancelInvTost(false);
    }
    setCancelInvTost(false);
  }, [poDetails, poTrackSet]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle textAlign="center">{invoiceData?.[0]?.order_id}</DialogTitle>
      <Box>
        <FormControlLabel
          value="end"
          control={
            <Switch
              checked={isSettled}
              onChange={() => {
                setIsSettled((boo) => !boo);
              }}
              color="success"
            />
          }
          label="Settled Orders"
          labelPlacement="end"
        />
      </Box>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Invoice Id</TableCell>
                <TableCell>Invoice Date</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell>Item Invoice</TableCell>
                <TableCell>Mail Invoice</TableCell>
                <TableCell>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData.map((obj, idx) => {
                return obj.is_settled === isSettled ? (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {obj.invoice_id}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {obj.invoice_date
                        ? moment(obj.invoice_date)
                            .tz("Asia/kolkata")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {obj.invoice_status !== 300 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <IconButton
                            variant="outlined"
                            size="small"
                            onClick={() => downloadInvoice(obj.invoice_id)}
                          >
                            <DownloadIcon />
                          </IconButton>
                          {rotate ? <CircularProgress color="inherit" /> : null}
                        </Box>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell>
                      {obj.invoice_status !== 300 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <IconButton
                            onClick={() => downloadItemInvoice(obj.invoice_id)}
                          >
                            <DownloadIcon />
                          </IconButton>
                          {rotateItem ? (
                            <CircularProgress color="inherit" />
                          ) : null}
                        </Box>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell>
                      {obj.invoice_status !== 300 ? (
                        <Button
                          type="small"
                          variant="outlined"
                          onClick={() => sendInvoiceEmail(obj.invoice_id)}
                        >
                          Email Invoice
                        </Button>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {!isSettled && obj.invoice_status !== 300 ? (
                        <Box>
                          <Checkbox
                            onChange={(e) => checkBoxFun(e, obj)}
                            size="small"
                            checked={
                              selectedInvoice === parseInt(obj.invoice_id)
                            }
                          />
                        </Box>
                      ) : isSettled ? (
                        <span>Settled</span>
                      ) : obj.invoice_status === 300 ? (
                        <span>Cancelled</span>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {Object.keys(priceBreak).length && !isSettled ? (
          <Button onClick={cancelInvoice}>Cancel Invoice</Button>
        ) : null}
        {Object.keys(priceBreak).length && !isSettled ? (
          <Button onClick={getPriceBreak}>Price Details</Button>
        ) : null}
      </DialogActions>
      <ConsolidatedDialog
        open={openConsoliDialog}
        setOpen={setOpenConsoliDialog}
        selectedInvoice={selectedInvoice}
        walletBal={walletBal}
        payConsId={payConsId}
        poTrackSet={poTrackSet}
        pageNumber={pageNumber}
      />
    </Dialog>
  );
}

export default SettlementsDialog;
