import { Box, Dialog, DialogContent, RadioGroup } from "@mui/material";
import { Button, DialogActions, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  GetAllPromoCodeListAction,
  PostPromoCodeAddAction,
} from "../Redux/Actions/promocodeAction";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { Radio } from "@mui/material";

function AddPromoDialog({
  addPromoDialogFlag,
  setAddPromoDialogFlag,
  pageNumber,
  promoExpired,
  promoCodeType,
  promoOfferType,
  promoCreatedAt,
  setPromoCreateRepeat,
  promoCreateRepeat,
}) {
  const adminPromoCodeData = useSelector((state) => state.promocodeAdmin);
  const [promCodetype, setPromCodetype] = React.useState("");
  const [maxUsagelimit, setMaxUsagelimit] = React.useState(1);
  const [promOffertype, setPromOffertype] = React.useState("");
  const [switchpromoExpired, setSwitchpromoExpired] = React.useState(false);
  const [changeSwitchExpired, setChangeSwitchExpired] = React.useState(false);
  const [promolabel, setPromolabel] = React.useState("");
  const [minimunConditionUse, setMinimunConditionUse] = React.useState("");
  const [promoOfferValue, setPromoOfferValue] = React.useState("");
  const [validityInHours, setValidityInHours] = React.useState("");
  const [validityInDays, setValidityInDays] = React.useState("");
  const [promoDisclaimer, setPromoDisclaimer] = React.useState("");
  const [promoOffertypeappmeta, setPromoOffertypeappmeta] = React.useState([]);
  const [promoCodeTypeappMeta, setPromoCodeTypeappMeta] = React.useState([]);
  const [promoExpiredFromValue, setPromoExpiredFromValue] = React.useState(() =>
    moment()
  );
  const [promoExpiredToValue, setPromoExpiredToValue] = React.useState(() =>
    moment()
  );

  const [selectAt, setSelectAt] = React.useState("Hours");
  CustomConsole(promoOffertypeappmeta);
  // const adminBookingMeta = useSelector((state) => state.adminBookingData)
  const bookingDetails = useSelector((state) => state.adminBookingData);
  const [addPromoBtn, setAddPromoBtn] = React.useState(false);
  CustomConsole(bookingDetails);
  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
  }, []);

  // var minDate = moment(date).subtract(1, 'days')

  const dispatch = useDispatch();
  CustomConsole(adminPromoCodeData);

  React.useEffect(() => {
    CustomConsole(adminPromoCodeData);
    if (
      (adminPromoCodeData.msg === adminPromoCodeData.addPromoerror) === false &&
      addPromoBtn === true
    ) {
      CustomConsole("Created successfully");
      dispatch(
        GetAllPromoCodeListAction(
          pageNumber - 1,
          promoExpired,
          promoCodeType,
          promoOfferType,
          promoCreatedAt
        )
      );
      setAddPromoBtn(false);
    }
  }, [adminPromoCodeData]);
  React.useEffect(() => {
    CustomConsole(bookingDetails);
    bookingDetails.setupConfigData.map((data) => {
      CustomConsole(data);
      if (data.key === "promo_offer_types") {
        console.log("promCodetype", promCodetype);
        const promoOfferType =
          promCodetype === 500
            ? data.value?.filter((item) => item.k !== 200 && item.k !== 300)
            : data.value;
        console.log("promoOfferType", promoOfferType);
        setPromoOffertypeappmeta(promoOfferType);
      }
      if (data.key === "promo_code_types") {
        setPromoCodeTypeappMeta(data.value);
      }
    });
  }, [bookingDetails, promCodetype]);

  React.useEffect(() => {
    CustomConsole(adminPromoCodeData);
    CustomConsole(adminPromoCodeData.promoSingleDetails.promo_code_type);
    CustomConsole(adminPromoCodeData.promoSingleDetails.promo_offer_type);

    if (adminPromoCodeData.error === false && promoCreateRepeat) {
      setPromolabel(adminPromoCodeData.promoSingleDetails.promo_label);

      setPromCodetype(adminPromoCodeData.promoSingleDetails.promo_code_type);

      CustomConsole(adminPromoCodeData.promoSingleDetails.promo_offer_type);
      setPromOffertype(adminPromoCodeData.promoSingleDetails.promo_offer_type);
      setMinimunConditionUse(
        adminPromoCodeData.promoSingleDetails.min_charges_to_use
      );
      setPromoOfferValue(
        adminPromoCodeData.promoSingleDetails.promo_offer_value
      );

      setMaxUsagelimit(adminPromoCodeData.promoSingleDetails.max_usage_limit);

      setPromoDisclaimer(
        adminPromoCodeData.promoSingleDetails.promo_disclaimer
      );
    }
  }, [adminPromoCodeData]);

  const handleMaxUsagelimit = (event) => {
    if (event.target.value >= 0) {
      setMaxUsagelimit(event.target.value);
    }
    if (event.target.value >= 1) {
      setMaxUsagelimit(event.target.value);
    } else {
      toast.error(" Please Enter Minimum One ", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleValidityInHours = (event) => {
    setValidityInHours(event.target.value);
  };
  const handleValidityInDays = (event) => {
    setValidityInDays(event.target.value);
  };

  const handlePromoOfferValue = (event) => {
    if (event.target.value >= 0) {
      setPromoOfferValue(event.target.value);
    }
  };

  const handleMinimunConditionUse = (event) => {
    if (event.target.value >= 0) {
      setMinimunConditionUse(event.target.value);
    }
  };

  const clickClose = () => {
    setAddPromoDialogFlag(false);
    setPromolabel("");
    setPromCodetype("");
    setPromOffertype("");
    setMinimunConditionUse("");
    setPromoDisclaimer("");
    setMaxUsagelimit("");
    setPromoOfferValue("");
    setValidityInHours("");
    setValidityInDays("");
    setPromoExpiredFromValue(() => moment());
    setPromoExpiredToValue(() => moment());
    setPromoDisclaimer("");
    setSwitchpromoExpired(false);
    setPromoStartDate(() => moment());
    setPromoEndDate(() => moment());
    if (promoCreateRepeat) {
      setPromolabel("");
      setPromCodetype("");
      setPromOffertype("");
      setMinimunConditionUse("");
      setPromoDisclaimer("");
      setMaxUsagelimit("");
      setPromoOfferValue("");
      setPromoCreateRepeat(false);
      setPromoExpiredFromValue(() => moment());
      setPromoExpiredToValue(() => moment());
      setPromoDisclaimer("");
      setSwitchpromoExpired(false);
      setPromoStartDate(() => moment());
      setPromoEndDate(() => moment());
    }
  };

  const [validity, setValidity] = React.useState("inHours");
  const handleValidity = (event) => {
    setValidity(event.target.value);
  };
  const handlePromoExpiredSwitch = (event) => {
    setChangeSwitchExpired(true);
    if (event.target.checked) {
      setSwitchpromoExpired(true);
    } else if (event.target.checked === false) {
      setSwitchpromoExpired(false);
    }
  };

  const [promoStartDate, setPromoStartDate] = React.useState(() => moment());

  const [promoEndDate, setPromoEndDate] = React.useState(() => moment());

  const handleChangePromoExpiredFrom = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setPromoStartDate(() => moment(newValue.$d));
      setPromoExpiredFromValue(newValue);
      CustomConsole(newValue.$d);
    }
  };

  const handleChangePromoExpiredTo = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setPromoEndDate(() => moment(newValue.$d));
      setPromoExpiredToValue(newValue);
      CustomConsole(newValue.$d);
    }
  };

  const handleClose = () => {
    setAddPromoDialogFlag(false);
  };

  const handlekAdd = (event) => {
    setAddPromoBtn(true);

    CustomConsole(promOffertype);
    if (
      addPromoDialogFlag === true &&
      promolabel !== "" &&
      promCodetype !== "" &&
      promOffertype !== "" &&
      minimunConditionUse !== "" &&
      promoOfferValue !== "" &&
      promoExpiredFromValue !== "" &&
      // validityInDays !== "" &&
      // maxUsagelimit !== "" &&
      // switchpromoExpired !== "" &&
      promoDisclaimer !== ""
    ) {
      let reqObj = {
        promo_label: promolabel,
        promo_code_type: promCodetype,
        promo_offer_type: promOffertype,
        min_charges_to_use: minimunConditionUse,
        promo_offer_value: promoOfferValue,
        valid_till:
          parseInt(validityInDays) * 24 + parseInt(validityInHours) ||
          undefined,
        expiry_date: promoEndDate || undefined,
        start_date: promoStartDate,
        max_usage_limit: maxUsagelimit || undefined,
        // is_expired: switchpromoExpired,
        promo_disclaimer: promoDisclaimer,
      };
      dispatch(PostPromoCodeAddAction(reqObj));
      setAddPromoDialogFlag(false);
      CustomConsole(reqObj);
      CustomConsole(promoOfferType);
      setPromolabel("");
      setPromCodetype("");
      setPromOffertype("");
      setMinimunConditionUse("");
      setPromoOfferValue("");
      setValidityInDays("");
      setValidityInHours("");
      setPromoExpiredFromValue(() => moment());
      setPromoExpiredToValue(() => moment());
      setPromoDisclaimer("");
      setSwitchpromoExpired(false);
      setPromoStartDate(() => moment());
      setPromoEndDate(() => moment());
    } else {
      let reqObj = {
        promo_label: promolabel,
        promo_code_type: promCodetype,
        promo_offer_type: promOffertype,
        min_charges_to_use: minimunConditionUse,
        promo_offer_value: promoOfferValue,
        valid_till:
          parseInt(validityInDays) * 24 + parseInt(validityInHours) ||
          undefined,
        expiry_date: promoExpiredToValue || undefined,
        start_date: promoExpiredFromValue,
        max_usage_limit: promCodetype === 100 ? 1 : maxUsagelimit,
        // is_expired: switchpromoExpired,
        promo_disclaimer: promoDisclaimer,
      };
      CustomConsole(reqObj);
      toast.error("Fill all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    // Clear All Text Field data After Add
    // if (addPromoDialogFlag === true) {
    //   setPromolabel("");
    //   setPromCodetype("");
    //   setPromOffertype("");
    //   setMinimunConditionUse("");
    //   setPromoOfferValue("");
    //   setValidityInDays("");
    //   setValidityInHours("");
    //   setPromoExpiredFromValue(() => moment());
    //   setPromoExpiredToValue(() => moment());
    //   setPromoDisclaimer("");
    //   setSwitchpromoExpired(false);
    //   setPromoStartDate(() => moment());
    //   setPromoEndDate(() => moment());
    // }
  };

  return (
    <Box>
      <Dialog
        open={addPromoDialogFlag}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Create or Add Promo Code Here
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label="Promo Label"
              variant="outlined"
              value={promolabel}
              onChange={(event) => setPromolabel(event.target.value)}
            />
            <FormControl sx={{ minWidth: "210px", margin: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                Promo Code Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={promCodetype}
                label="Promo Code Type"
                onChange={(event) => {
                  setPromCodetype(event.target.value);
                  CustomConsole(event.target.value);
                }}
              >
                {promoCodeTypeappMeta.map((data) => {
                  return <MenuItem value={data.k}>{data.v}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ minWidth: "210px", margin: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                Promo Offer Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={promOffertype}
                label="Promo Offer Type"
                onChange={(event) => setPromOffertype(event.target.value)}
              >
                {promoOffertypeappmeta.map((data) => {
                  return <MenuItem value={data.k}>{data.v}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <TextField
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label="Minimum order Price"
              variant="outlined"
              value={minimunConditionUse}
              helperText="Consumer min. order price"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleMinimunConditionUse}
            />
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label="Promo Offer Value"
              variant="outlined"
              value={promoOfferValue}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handlePromoOfferValue}
            />
            {promCodetype === 100 ? (
              ""
            ) : (
              <TextField
                sx={{ margin: "10px" }}
                id="outlined-basic"
                label="Max Usage Limit"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={maxUsagelimit}
                onChange={handleMaxUsagelimit}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Promo Valid Start Date"
                inputFormat="DD/MM/YYYY"
                disablePast
                value={promoExpiredFromValue}
                onChange={handleChangePromoExpiredFrom}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: "20px" }}>
            <Box>
              <FormControl>
                <RadioGroup value={validity} onChange={handleValidity}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Validity In Hours"
                    value="inHours"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Validity To Date "
                    value="inDate"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {/* <Box
              sx={{
                margin: "10px",
                marginLeft: "80px",
                marginTop: "20px",
                alignContent: "end",
              }}
            >
              <FormControlLabel
                control={<Switch checked={switchpromoExpired} />}
                label={switchpromoExpired ? "Expired" : "UnExpired"}
                onChange={handlePromoExpiredSwitch}
              />
            </Box> */}
          </Box>
          {validity === "inHours" ? (
            <Box>
              <TextField
                sx={{ margin: "10px" }}
                id="outlined-basic"
                label=" Validity In Days"
                variant="outlined"
                type="number"
                value={validityInDays}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handleValidityInDays}
              />
              <TextField
                sx={{ margin: "10px" }}
                id="outlined-basic"
                label=" Validity In Hours"
                variant="outlined"
                type="number"
                value={validityInHours}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handleValidityInHours}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: {
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                justifyContent: "center",
                gap: "10px",
                maxWidth: "450px",
                marginLeft: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Promo valid End Date"
                  inputFormat="DD/MM/YYYY"
                  // minDate={moment().subtract(0, "days")}
                  value={promoExpiredToValue}
                  disablePast={promoExpiredFromValue}
                  onChange={handleChangePromoExpiredTo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={"Select More Than One Day From Start Date"}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          )}
          <Box></Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              fullWidth
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label=" Promo Disclaimer"
              multiline
              rows={4}
              variant="outlined"
              value={promoDisclaimer}
              onChange={(event) => setPromoDisclaimer(event.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
          <Button variant="contained" color="error" onClick={clickClose}>
            close
          </Button>
          {/* { moment(promoStartDate) <=  moment(promoEndDate) ? ( */}
          <Button variant="contained" color="success" onClick={handlekAdd}>
            Add
          </Button>
          {/* ) : ("")} */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddPromoDialog;
