import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

const CrateFilterDialog = ({ isOpen, onClose, handelFilterOption }) => {
  const [active, setActive] = useState(true);
  const [sortByCapacity, setSortByCapacity] = useState("DESC");
  const [sortByName, setSortByName] = useState("ASC");
  const [sortByPrice, setSortByPrice] = useState("ASC");
  const [sortByCreatedAt, setSortByCreatedAt] = useState("DESC");

  //function for handling filter operation
  const handleApplyFilter = () => {
    let reqObj = {
      active,
      sortByCapacity,
      sortByName,
      sortByPrice,
      sortByCreatedAt,
    };
    // Call the callback function with the selected filter options
    handelFilterOption(reqObj);
    onClose();
  };

  //Function for handling filter operation
  const handelClearFilter = () => {
    setActive(true);
    setSortByCapacity("DESC");
    setSortByName("ASC");
    setSortByPrice("ASC");
    setSortByCreatedAt("ASC");
  };
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Filter Options</DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: "1rem", display: "flex", gap: 2 }}>
          <Box>
            <Typography>Active</Typography>
            <FormControl fullWidth size="small">
              <Select
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>In Active</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Typography>Capacity</Typography>
            <FormControl fullWidth size="small">
              <Select
                value={sortByCapacity}
                onChange={(e) => setSortByCapacity(e.target.value)}
              >
                <MenuItem value="DESC">Larger</MenuItem>
                <MenuItem value="ASC">Smaller</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "1rem", display: "flex", gap: 2 }}>
          <Box>
            <Typography>Name</Typography>
            <FormControl fullWidth size="small">
              <Select
                value={sortByName}
                onChange={(e) => setSortByName(e.target.value)}
              >
                <MenuItem value="DESC">Descending</MenuItem>
                <MenuItem value="ASC">Ascending</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Typography>Price</Typography>
            <FormControl fullWidth size="small">
              <Select
                value={sortByPrice}
                onChange={(e) => setSortByPrice(e.target.value)}
              >
                <MenuItem value="DESC">high to low</MenuItem>
                <MenuItem value="ASC">low to high</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography>Sort by date</Typography>
          <FormControl fullWidth size="small">
            <Select
              value={sortByCreatedAt}
              onChange={(e) => setSortByCreatedAt(e.target.value)}
            >
              <MenuItem value="DESC">Recent First</MenuItem>
              <MenuItem value="ASC">Old First</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/* Repeat similar pattern for other filter options */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handelClearFilter}>Clear Filter</Button>
        <Button
          onClick={handleApplyFilter}
          variant="contained"
          color="primary"
          size="small"
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrateFilterDialog;
