import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { Box } from "@mui/system";
import CanSwapDetailsTable from "./CanSwapDetailsTable";
import CanSwapAppectOrRejectDialog from "../../Components/Dialog/CanSwapAppectOrRejectDialog";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetQrBarcodeSwapCanByBulkQr,
  GetQrBarcodeSwapCanByOrderId,
  GetQrBarcodeSwapList,
  PutQrBarcodeSwapAcceptOrReject,
} from "../Redux/Actions/canSwapingAction";
import CanWapFilterDialog from "../../Components/Dialog/CanWapFilterDialog";
import { GetViewOrderDetails } from "../Redux/Actions/ordersAdminAction";
import QrDetailsDialog from "./QrDetailsDialog";
import CustomConsole from "../../CustomConsole";

function AdminCanSwapMain() {
  const dispatch = useDispatch();
  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);

  const [
    openCanSwapApproveRejectDialog,
    setOpenCanSwapApproveRejectDialog,
  ] = React.useState(false);
  const [openFilterCanSwap, setOpenFilterCanSwap] = React.useState(false);
  const [
    getQrBarcodeSwapCanByQrId,
    setgetQrBarcodeSwapCanByQrId,
  ] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [requesteddata, setRequesteddata] = React.useState({});
  const [alignment, setAlignment] = React.useState("ASC");
  const [swapCanReqEmptyData, setSwapCanReqEmptyData] = React.useState([]);
  const [swapCanReqFilledData, setSwapCanReqFilledData] = React.useState([]);
  const [selectedRequest, setSelectedRquest] = React.useState({});
  const [qrDetailsDialog, setQrDetailsDialog] = React.useState(false);

  const [
    swapCanApprovedFilledData,
    setSwapCanApprovedFilledData,
  ] = React.useState([]);
  const [
    swapCanApprovedEmptyData,
    setSwapCanApprovedEmptyData,
  ] = React.useState([]);

  const [filterOptions, setFilterOptions] = React.useState({ status: 100 });
  // React.useEffect(() => {
  //   CustomConsole("-- filterOptions --");
  //   CustomConsole(filterOptions);
  // }, [filterOptions]);
  React.useEffect(() => {
    CustomConsole(canSwapDeatails.getQrBarcodeSwapCanByOrderId);
    if (
      !canSwapDeatails.error &&
      canSwapDeatails.getQrBarcodeSwapCanByOrderId.length > 0 &&
      Object.keys(selectedRequest).length
    ) {
      // if (qrDetailsDialog && !openCanSwapApproveRejectDialog) {
      CustomConsole("--setgetQrBarcodeSwapCanByQrId---");
      setgetQrBarcodeSwapCanByQrId(
        canSwapDeatails.getQrBarcodeSwapCanByOrderId
      );
      setQrDetailsDialog(true);
      // setOpenCanSwapApproveRejectDialog(true);
      // }
    }
  }, [canSwapDeatails.getQrBarcodeSwapCanByOrderId]);

  React.useEffect(() => {
    CustomConsole("------pageNumber------pageSize-----");
    apiCallForCanSwapRequests();
  }, [pageNumber, pageSize, filterOptions]);
  const apiCallForCanSwapRequests = () => {
    let reqObj = filterOptions;
    reqObj.page_number = pageNumber;
    reqObj.page_size = pageSize;
    dispatch(GetQrBarcodeSwapList(reqObj));
  };
  const handleOpenSingleId = (props) => {
    CustomConsole("-------------handleOpenSingleId------");
    CustomConsole(props.req_empty);
    CustomConsole(props.req_empty);
    setOrderId(props.order_id);
    setSelectedRquest(props);
    if (
      !props.req_empty_details.length &&
      !props.req_filled_details.length &&
      !props.approved_empty_details.length &&
      !props.approved_filled_details.length
    ) {
      // toast.error(
      //   "Product details not found, please contact bookwater support!",
      //   {
      //     position: "bottom-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   }
      // );
      const qrIds = [
        ...props.req_empty,
        ...props.req_filled,
        ...props.approved_empty,
        ...props.approved_filled,
      ];
      CustomConsole("qrIds: " + qrIds);
      dispatch(GetQrBarcodeSwapCanByBulkQr({ qr_codes: qrIds }));
    } else {
      setQrDetailsDialog(true);
    }
    // const qrIds = [
    //   ...props.req_empty,
    //   ...props.req_filled,
    //   ...props.approved_empty,
    //   ...props.approved_filled,
    // ];
    // CustomConsole("qrIds: " + qrIds);
    // dispatch(GetQrBarcodeSwapCanByBulkQr({ qr_codes: qrIds }));
    // dispatch(GetViewOrderDetails(props.order_id));
  };
  const handleCloseDialog = () => {
    CustomConsole("-------------handleCloseDialog-----------");
    setQrDetailsDialog(false);
    setgetQrBarcodeSwapCanByQrId([]);
  };
  const handleReject = (props) => {
    CustomConsole("-------------handleReject Main Page-----------");
    if (props.selectionModel !== "" || props.selectionModelReqFilled !== "") {
      CustomConsole("-------------Selected  Qr-----------");
      const reqObj = {
        req_id: props.reqId,
        qr_barcode: {
          empty: props.selectionModel,
          filled: props.selectionModelReqFilled,
        },
        order_id: props.orderId,
        is_accept: false,
      };
      CustomConsole(reqObj);
      dispatch(PutQrBarcodeSwapAcceptOrReject(reqObj));
      setOpenCanSwapApproveRejectDialog(false);
    } else {
      CustomConsole("-------------Selct Qr-----------");
      toast.error("Please Select Qe Check Box", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setTimeout(() => {
      dispatch(
        GetQrBarcodeSwapList({
          status: 100,
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }, 1000);
  };
  const handleApprove = (props) => {
    CustomConsole("-------------handleApprove Main Page---------");
    CustomConsole(props);
    if (props.selectionModel || props.selectionModelReqFilled) {
      const reqObj = {
        req_id: props.reqId,
        qr_barcode: {
          empty: props.selectionModel,
          filled: props.selectionModelReqFilled,
        },
        order_id: props.orderId,
        is_accept: true,
      };
      CustomConsole(reqObj);
      dispatch(PutQrBarcodeSwapAcceptOrReject(reqObj));
      setOpenCanSwapApproveRejectDialog(false);
    } else {
      toast.error("Please Select Qe Check Box", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setTimeout(() => {
      dispatch(
        GetQrBarcodeSwapList({
          status: 100,
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }, 1000);
  };

  const handleCloseFilter = () => {
    setOpenFilterCanSwap(false);
  };
  const handleFilterCanSwap = (props) => {
    setFilterOptions(props);
    // if (pageNumber !== 1) {
    setPageNumber(1);
    // } else {
    //   // apiCallForCanSwapRequests();
    // }
    setOpenFilterCanSwap(false);
  };

  return (
    <Material.Box sx={{ marginTop: "60px" }}>
      <Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            // margin: "15px",
            marginLeft: "14px",
            justifyContent: { md: "flex-end" },
            gap: "10px",
            width: { xs: "65VW", md: "100%" },
          }}
        >
          <Material.Box>
            <Material.Button
              variant="contained"
              onClick={() => setOpenFilterCanSwap(true)}
            >
              <MuiIcons.FilterList /> filter
            </Material.Button>
          </Material.Box>

          <Material.Box sx={{ maxHeight: "20px" }}>
            <Material.FormControl sx={{ minWidth: 120 }} size="small">
              <Material.InputLabel>Page size</Material.InputLabel>
              <Material.Select
                value={pageSize}
                label="Page size"
                onChange={(event) => setPageSize(event.target.value)}
              >
                <Material.MenuItem value={25}>25</Material.MenuItem>
                <Material.MenuItem value={50}>50</Material.MenuItem>
                <Material.MenuItem value={100}>100</Material.MenuItem>
                <Material.MenuItem value={500}>500</Material.MenuItem>
              </Material.Select>
            </Material.FormControl>
          </Material.Box>
          <Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Material.IconButton
                onClick={() => {
                  if (pageNumber - 1 > 0) {
                    setPageNumber(() => pageNumber - 1);
                  }
                }}
              >
                <MuiIcons.FirstPage
                  sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                />
              </Material.IconButton>
              <Material.Typography
                sx={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {pageNumber}
              </Material.Typography>
              <Material.IconButton
                sx={{ maxHeight: "50px" }}
                onClick={() => {
                  if (
                    !canSwapDeatails.error &&
                    canSwapDeatails?.getQrBarcodeSwapList?.length
                  ) {
                    setPageNumber(() => pageNumber + 1);
                  }
                }}
              >
                <MuiIcons.LastPage
                  sx={{
                    color:
                      !canSwapDeatails.error &&
                      canSwapDeatails?.getQrBarcodeSwapList?.length
                        ? "blue"
                        : "gray",
                  }}
                />
              </Material.IconButton>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        {/* </Material.Paper> */}
      </Material.Box>
      <CanSwapDetailsTable
        pageNumber={pageNumber}
        pageSize={pageSize}
        handleOpenSingleId={handleOpenSingleId}
        data={canSwapDeatails.getQrBarcodeSwapList}
      />
      {/* {!qrDetailsDialog && openCanSwapApproveRejectDialog ? (
        <CanSwapAppectOrRejectDialog
          openCanSwapApproveRejectDialog={openCanSwapApproveRejectDialog}
          handleCloseDialog={handleCloseDialog}
          // swapCanSingleOrderIdData={swapCanSingleOrderIdData}
          orderId={orderId}
          data={selectedRequest}
          handleApprove={handleApprove}
          handleReject={handleReject}
          getQrBarcodeSwapCanByQrId={getQrBarcodeSwapCanByQrId}
        />
      ) : null} */}
      {qrDetailsDialog ? (
        <QrDetailsDialog
          openCanSwapApproveRejectDialog={qrDetailsDialog}
          handleCloseDialog={handleCloseDialog}
          handleApprove={handleApprove}
          handleReject={handleReject}
          data={selectedRequest}
          getQrBarcodeSwapCanByQrId={getQrBarcodeSwapCanByQrId}
        />
      ) : null}
      {openFilterCanSwap && (
        <CanWapFilterDialog
          filterData={filterOptions}
          openFilterCanSwap={openFilterCanSwap}
          handleFilterCanSwap={handleFilterCanSwap}
          handleCloseFilter={handleCloseFilter}
        />
      )}
    </Material.Box>
  );
}

export default AdminCanSwapMain;
