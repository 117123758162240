import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SearchAreasByPincode } from "../Redux/Actions/adminBookingAction";
import {
  AddDeliveryArea,
  GetDistributorDetails,
} from "../Redux/Actions/appSettingsAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function AssignDeliveryArea({ distriNum, scrollDown, adminRole }) {
  const [pincode, setPincode] = React.useState("");
  const [area, setArea] = React.useState("");
  const [transportCharge, setTransportCharge] = React.useState("");
  const [addFields, setAddFields] = React.useState(false);
  const [updateFlag, setUpdateFlag] = React.useState(false);

  const dispatch = useDispatch();
  const pincodeDetails = useSelector(
    (state) => state.adminBookingData.availableAreas
  );
  const updatedResponse = useSelector((state) => state.appSettingsDetails);

  const handleChangePincode = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length === 6) {
      dispatch(SearchAreasByPincode(e.target.value));
      setAddFields(true);
      setUpdateFlag(true);
    }
  };

  let pincodeEntry = () => {
    if (area === "" || transportCharge === "") {
      CustomConsole("---Error---");
      toast.error("Fill all fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    if (
      pincode.toString().length === 6 &&
      area !== "" &&
      transportCharge !== "" &&
      distriNum !== ""
    ) {
      CustomConsole("----All details in-----");
      let reqObj = {
        area_name: [area],
        phone: distriNum,
        pincode: pincode,
        transportation_charges: transportCharge,
      };
      CustomConsole(reqObj);
      dispatch(AddDeliveryArea(reqObj));
    }
  };

  React.useEffect(() => {
    if (updatedResponse.error === false && updateFlag === true) {
      CustomConsole(updatedResponse);
      toast.success(updatedResponse.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAddFields(false);
      setPincode("");
      setArea("");
      setTransportCharge("");
      setUpdateFlag(false);
      dispatch(GetDistributorDetails(distriNum));
    }
  }, [updatedResponse]);

  return (
    <>
      {ADMIN_ROLES.BW_CS === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
      ADMIN_ROLES.BW_CSLEAD === adminRole ||
      ADMIN_ROLES.BW_OPS === adminRole ||
      ADMIN_ROLES.BW_OPSHEAD === adminRole ||
      ADMIN_ROLES.BW_FINANCE === adminRole ||
      ADMIN_ROLES.BW_FINANCEHEAD === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ||
      ADMIN_ROLES.BW_SALES === adminRole ? (
        ""
      ) : (
        <Grid
          ref={scrollDown}
          direction="row"
          justifyContent="center"
          alignItems="center"
          container
          spacing={2}
        >
          <Grid item md={6}>
            <Card sx={{ padding: "10px", marginTop: "15px" }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: "18", fontWeight: "bold", color: "blue" }}
                  display="block"
                  gutterBottom
                >
                  Assign delivery area
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    marginTop: "8px",
                  }}
                  spacing={3}
                >
                  <Grid item md={12}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={0.5}
                    >
                      <TextField
                        id="outlined-number"
                        size="small"
                        label="Pincode"
                        type="number"
                        onKeyDown={(e) =>
                          (e.key === "e" || e.key === "-" || e.key === "+") &&
                          e.preventDefault()
                        }
                        value={pincode}
                        onChange={handleChangePincode}
                      />
                    </Stack>
                  </Grid>
                  {addFields ? (
                    <>
                      <Grid item md={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <InputLabel id="area-select-small">Area</InputLabel>
                          <Select
                            labelId="area-select-small"
                            id="area-select-small"
                            label="Area"
                            style={{ width: 210 }}
                            value={area}
                            onChange={(e) => {
                              setArea(e.target.value);
                            }}
                          >
                            {pincodeDetails.map((option) => (
                              <MenuItem key={option.Name} value={option.Name}>
                                {option.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="outlined-number"
                          required
                          size="small"
                          label="Transport charges"
                          type="number"
                          onKeyDown={(e) =>
                            (e.key === "e" || e.key === "-" || e.key === "+") &&
                            e.preventDefault()
                          }
                          value={transportCharge}
                          onChange={(e) => {
                            setTransportCharge(e.target.value);
                          }}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    marginTop: "8px",
                  }}
                >
                  <Grid item md={6}>
                    {addFields ? (
                      <Button onClick={pincodeEntry} variant="contained">
                        Add area
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AssignDeliveryArea;
