import * as React from "react";
import {
  Box,
  Dialog,
  Typography,
  TextField,
  Select,
  MenuItem,
  Slide,
  IconButton,
  ListItemButton,
  ListItemText,
  List,
  Button,
  AppBar,
  Divider,
  DialogContentText,
  DialogContent,
  Toolbar,
  DialogActions,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  InputAdornment,
  Chip,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { Grade } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import productDetailsForAddressTestData from "./productDetailsForAddress.json";
import { useDispatch, useSelector } from "react-redux";
import Consumer from "../Consumer";
import {
  BookNow,
  ConsumersCouponCodes,
  EditSubscriptionDetails,
  GetProductsAddress,
  SubscriptionBooking,
  ValidatingProducts,
} from "../../Redux/Actions/adminSkuBookingAction";
import moment from "moment";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";
import dayjs from "dayjs";
import SubscriptionSchedule from "../../Subscription/SubscriptionDateCard";
import ReassignSupplierDialog from "../../AdminPoTrackPostPaid/ReassignSupplierDialog";
import { GetUserAddressAdmin } from "../../Redux/Actions/userDetailsAdminAction";
import { GetPoTrackDetails } from "../../Redux/Actions/adminPoTrackPostpaid";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function BookRegularProducts(props) {
  const dispatch = useDispatch();
  const referenceDate = dayjs(moment(props.lastDate).endOf("day").format());
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const productDet = useSelector((state) => state.adminSkuBookingData.products);
  const productSku = useSelector((state) => state.adminSkuBookingData);
  const validproducts = useSelector(
    (state) => state.adminSkuBookingData.validProducts
  );
  const allData = useSelector(
    (state) => state.adminSkuBookingData.cartCalulations
  );
  const couponDetails = useSelector(
    (state) => state.adminSkuBookingData.couponCodeData
  );
  const inValidproducts = useSelector(
    (state) => state.adminSkuBookingData.inValidvalidProducts
  );
  const [addressProductData, setAddressProductData] = React.useState([]);
  const [editSubSelectedDateFlag, setEditSubSelectedDateFlag] =
    React.useState(false);
  const [postpaidPoProducts, setPostpaidPoProducts] = React.useState([]);
  const [userStock, setUserStock] = React.useState({});
  // const [selectedDays, setSelectedDays] = React.useState([]);
  const [nextDeliveryDate, setNextDeliveryDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [supplierSelectDig, setSupplierSelectDig] = React.useState(false);
  const [addressId, setAddressId] = React.useState(null);
  const [addressIdEdiSub, setAddressIdEdiSub] = React.useState(null);
  const [payMethod, setPayMethod] = React.useState(() =>
    !props.isPostpaid ? "RAZORPAY" : "POSTPAID"
  );
  const [deliveryDate, setDeliveryDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [disableDeliveryBtn, setDisDeliveryBtn] = React.useState(false);
  CustomConsole("-------deliveryDate--------");
  CustomConsole(deliveryDate);
  const [selectedOrderQty, setSelectedOrderQty] = React.useState(0);
  const [qty, setQty] = React.useState(0);
  const [orderDate, setOrderDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [updateCartStatus, setUpdateCartStatus] = React.useState(false);
  const [alignmentOneTimeSaleAndReill, setAlignmentOneTimeSaleAndReill] =
    React.useState("Refill");
  const [updateCartShowProductDetails, setUpdateCartShowProductDetails] =
    React.useState(false);
  const [placeOrderFlag, setPlaceOrderFlag] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [minProdClone, setMinProdClone] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState("NONE");
  const [selectedPromocode, setSelectedPromocode] = React.useState("NONE");
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const [userDetails, setUserDetails] = React.useState({});
  const [BookType, setbookType] = React.useState(1);
  const [maxReturn, setMaxReturn] = React.useState(0);
  const [updateCartObj, setUpdatecartObj] = React.useState({
    products: [],
    address_id: props.addressIdPre,
    booking_type: 1,
    coupon_code: "",
    water_drops: 0,
    payment_method:
      props.bookNowType === 10
        ? "WALLET"
        : props.bookNowType === 40
        ? "WALLET"
        : "POSTPAID",
    secondary_payment_method: "",
    is_otp_less: true,
    delivery_date: "",
    is_extra_charges_disabled: false,
    is_auto_apply_water_drops: true,
    is_auto_apply_coupon: true,
    // supplier_id:
    //   props.bookNowType === 10
    //     ? undefined
    //     : props.bookNowType === 40
    //     ? undefined
    //     : props.supplierId,
    invalid_products: [],
  });
  const [invalidProd, setInvalidProd] = React.useState([]);

  // const availableProducts =
  //   productDetailsForAddressTestData.data.products ?? [];
  //   selectedProduct

  //setSelectedProducts((prev)=>prev[index])

  const handleSelectProduct = (props) => {
    CustomConsole("-- handleSelectProduct --");
    const product = props.split("-D-");
    CustomConsole(product);
    CustomConsole(addressProductData);
    CustomConsole(selectedProducts);
    setSelectedProduct(props);

    if (props !== "NONE" && product.length === 2) {
      const isProductExists = selectedProducts.filter(
        (data) => data.capacity === product[0] && data.material === product[1]
      );
      CustomConsole(isProductExists);
      //User Selected New Product
      if (!(isProductExists && isProductExists.length)) {
        const userSelectedProductDetails = addressProductData.filter(
          (data) => data.capacity === product[0] && data.material === product[1]
        );
        CustomConsole(productSku.userStock);
        const returnVal = productSku?.userStock?.filter(
          (data) =>
            data.container_capacity === product[0] &&
            data.container_material === product[1]
        );
        CustomConsole(returnVal);

        CustomConsole("userSelectedProductDetails: ");
        CustomConsole(userSelectedProductDetails);
        if (userSelectedProductDetails && userSelectedProductDetails.length) {
          const productDetails = [
            {
              name: userSelectedProductDetails[0].name,
              sku_qty: userSelectedProductDetails[0].sku_qty[0],
              capacity: userSelectedProductDetails[0].capacity,
              material: userSelectedProductDetails[0].material,
              quantity: 1,
              return: 0,
              max_qty: userSelectedProductDetails[0].max_book_qty,
              min_qty: userSelectedProductDetails[0].min_book_qty,
              max_return: returnVal[0]?.max_return,
              booking_type: userSelectedProductDetails[0].booking_type,
            },
          ];
          setSelectedProducts([...selectedProducts, ...productDetails]);
        }
      }
    }

    setUpdateCartShowProductDetails(false);
    setSelectedProduct("");
  };
  const handleRemoveProduct = (prod) => {
    CustomConsole("-----Selecting deleted -----");
    CustomConsole(prod);

    setSelectedProducts((prev) =>
      prev.filter(
        (obj) =>
          !(obj.capacity === prod.capacity && obj.material === prod.material)
      )
    );
    setInvalidProd((prev) =>
      prev.filter(
        (obj) =>
          !(obj.capacity === prod.capacity && obj.material === prod.material)
      )
    );
    setUpdateCartShowProductDetails(false);
  };

  const handleAddressChange = (e) => {
    setAddressId(e.target.value);
  };
  const handleAddressChangeEditSub = (e) => {
    setAddressIdEdiSub(e.target.value);
    setUpdateCartShowProductDetails(false);
  };

  const handleQuantity = (selectedQuantity) => {
    const value = parseInt(selectedQuantity.e.target.value);
    if (
      selectedQuantity.e.target.value === "" ||
      (Number(selectedQuantity.e.target.value) > 0 &&
        !isNaN(selectedQuantity.e.target.value))
    ) {
      setSelectedProducts((prev) => {
        let returnQty;
        if (
          selectedQuantity.sku_qty * selectedQuantity.e.target.value <=
            selectedQuantity.max_return &&
          selectedQuantity.sku_qty * selectedQuantity.e.target.value >= 0 &&
          selectedQuantity.sku_qty * selectedQuantity.e.target.value <= 0
        ) {
          returnQty =
            parseInt(selectedQuantity.e.target.value) -
            selectedQuantity.sku_qty * selectedQuantity.quantity;
        }
        const prevProducts = [...prev];
        prevProducts[selectedQuantity.index] = {
          ...prevProducts[selectedQuantity.index],
          quantity: parseInt(selectedQuantity.e.target.value || 0),
          return: returnQty || 0,
          // quantity:
          //   parseInt(selectedQuantity.event.target.value) > 0
          //     ? parseInt(selectedQuantity.event.target.value || 0)
          //     : parseInt(selectedQuantity.prev.quantity || 0),
        };

        return prevProducts;
      });

      setUpdateCartShowProductDetails(false);
    }
    // if (
    //   selectedQuantity.sku_qty * selectedQuantity.e.target.value <=
    //   selectedQuantity.max_return
    // ) {
    //   setSelectedProducts((prev) => {
    //     const prevProducts = [...prev];
    //     prevProducts[selectedQuantity.index] = {
    //       ...prevProducts[selectedQuantity.index],
    //       return:
    //         parseInt(selectedQuantity.e.target.value) -
    //         selectedQuantity.sku_qty * selectedQuantity.quantity,
    //     };
    //     return prevProducts;
    //   });
    // }
  };
  const handlePlusOrderQty = (selectedProducts, index) => {
    setSelectedOrderQty(selectedProducts + 1);
    CustomConsole(index + 1);
    CustomConsole(selectedProducts + 1);
    setSelectedProducts((prev) => {
      const prevProducts = [...prev];
      prevProducts[index] = {
        ...prevProducts[index],
        quantity: parseInt(selectedProducts) + 1,
      };
      return prevProducts;
    });
    setUpdateCartShowProductDetails(false);
  };

  const handleMinusOrderQty = (selectedProducts) => {
    CustomConsole(selectedProducts);

    if (selectedProducts.quantity > 1) {
      setSelectedProducts((prev) => {
        const prevProducts = [...prev];
        prevProducts[selectedProducts.index] = {
          ...prevProducts[selectedProducts.index],
          quantity: parseInt(selectedProducts.quantity) - 1,
        };
        return prevProducts;
      });
      if (
        selectedProducts.sku_qty * selectedProducts.quantity <=
        selectedProducts.max_return
      ) {
        setSelectedProducts((prev) => {
          let returnQty = 0;

          if (
            parseInt(selectedProducts.return) - selectedProducts.sku_qty >=
            0
          ) {
            returnQty =
              parseInt(selectedProducts.return) - selectedProducts.sku_qty;
          }
          if (selectedProducts.quantity <= returnQty) {
            returnQty = selectedProducts.quantity;
          }
          const prevProducts = [...prev];
          prevProducts[selectedProducts.index] = {
            ...prevProducts[selectedProducts.index],
            return: returnQty,
          };
          return prevProducts;
        });
      }
      setUpdateCartShowProductDetails(false);
    }
  };

  const handleReturnQuantity = (returnData) => {
    // const value = parseInt(e.target.value);
    CustomConsole(returnData);
    // if (/^(?!0\d*$)\d*\.?\d*$/.test(e.target.value) && e.target.value >= 0) {
    if (returnData.e.target.value <= returnData.max_return) {
      setSelectedProducts((prev) => {
        const prevProducts = [...prev];
        prevProducts[returnData.index] = {
          ...prevProducts[returnData.index],
          return: parseInt(returnData.e.target.value || 0),
        };
        return prevProducts;
      });
      setUpdateCartShowProductDetails(false);
    }
    // }
  };

  const handlePlusReturnQty = (selectedProductsReturn) => {
    CustomConsole(selectedProductsReturn);
    let returnQty = 0;
    if (selectedProductsReturn.return) {
      returnQty = selectedProductsReturn.return;
    }
    CustomConsole(
      selectedProductsReturn.return > selectedProductsReturn.max_return
    );
    CustomConsole(
      selectedProductsReturn.sku_qty * selectedProductsReturn.quantity
    );
    //@Removed/CustomConsole("----Return Check----");
    //@Removed/CustomConsole(selectedProductsReturn.return);

    //@Removed/CustomConsole(
    //   selectedProductsReturn.sku_qty * selectedProductsReturn.quantity
    // );
    //@Removed/CustomConsole(
    //   selectedProductsReturn.return <
    //     selectedProductsReturn.sku_qty * selectedProductsReturn.quantity
    // );
    if (
      returnQty < selectedProductsReturn.max_return &&
      returnQty <
        selectedProductsReturn.sku_qty * selectedProductsReturn.quantity
    ) {
      setSelectedProducts((prev) => {
        const prevProducts = [...prev];
        prevProducts[selectedProductsReturn.index] = {
          ...prevProducts[selectedProductsReturn.index],
          return: parseInt(returnQty) + 1,
        };
        return prevProducts;
      });
    }
    setUpdateCartShowProductDetails(false);
  };

  const shouldDisableDate = (date) => {
    return date.isAfter(referenceDate);
  };

  const handleMinusReturnQty = (selectedProducts, index) => {
    if (selectedProducts > 0) {
      setSelectedProducts((prev) => {
        const prevProducts = [...prev];
        prevProducts[index] = {
          ...prevProducts[index],
          return: parseInt(selectedProducts) - 1,
        };
        return prevProducts;
      });
      setUpdateCartShowProductDetails(false);
    }
  };

  const updateCart = () => {
    CustomConsole(selectedOrderQty);
    CustomConsole("minProdClone", minProdClone);

    for (const data of minProdClone) {
      CustomConsole("minProdClonedata");
      //@Removed/CustomConsole(data.quantity);
      setQty(data.quantity);
    }
    // if (qty + 1 > 0) {
    CustomConsole(minProdClone);
    const updatedProducts = minProdClone.map((obj) => {
      //@Removed/CustomConsole(obj.quantity);
      // if (obj.quantity > 0) {
      // if (obj.quantity_valid) {
      return {
        ...obj,
        quantity_valid: undefined,
        return_valid: undefined,
        deliveryCharge: undefined,
        price_valid: undefined,
        deposit_valid: undefined,
        booking_type: undefined,
        invalid_products: undefined,
        // booking_type: undefined,
      };
      // }
      // } else {
      //    //@Removed/CustomConsole("------error-------");
      //   toast.error(
      //     "Please Enter Proper Order Order Qunatity Or Select Product",
      //     {
      //       position: "bottom-right",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "dark",
      //     }
      //   );
      // }
      return obj;
    });
    CustomConsole(updatedProducts);
    setMinProdClone(updatedProducts);
    setUpdatecartObj((prev) => ({ ...prev, products: updatedProducts }));
    setUpdateCartShowProductDetails(true);
    setUpdateCartStatus(true);
    setSelectedProduct("");

    // } else {
    //   CustomConsole("------------SEnter--------------");
    //   toast.error(
    //     "Please Enter Proper Order Order Qunatity Or Select Product",
    //     {
    //       position: "bottom-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "dark",
    //     }
    //   );
    // }
  };

  const orderPlaceFinalArr = (obj) => {
    CustomConsole(obj);
    if (props.bookNowType === 10) {
      //Regular Data
      let validArr = obj?.valid_products ? obj.valid_products : [];
      const isValidArr = validArr.every(
        (product) => product.quantity.is_valid && product.return.is_valid
      );
      if (
        !isValidArr ||
        validArr.length === 0 ||
        !obj.payment_method.is_valid
      ) {
        return false;
      }
      const transformedData = obj.valid_products.reduce(
        (acc, product) => {
          acc.products.push({
            capacity: product.capacity,
            material: product.material,
            quantity: product.quantity.selected,
            return: product.return.selected,
            sku_qty: product.sku_qty,
          });
          return acc;
        },
        {
          products: [],
          booking_type: updateCartObj.booking_type,
          address_id: obj.address_id,
          coupon_code: obj.coupon_code_details.selected,
          water_drops: obj.water_drop_details.water_drops,
          payment_method: obj.payment_method.payment_method,
          secondary_payment_method: "",
          // is_otp_less: obj.is_otp_less,
          delivery_date: updateCartObj.delivery_date,
          final_price: obj.final_price,
          is_postpaid: false,
          // supplier_id: isPostpaid ? supplierId : "",
          // po_track_id: !isPostpaid ? "" : poTrack,
          is_extra_charges_disabled: updateCartObj.is_extra_charges_disabled,
          is_otp_less: updateCartObj.is_otp_less,
        }
      );
      return transformedData;
    } else if (props.bookNowType === 40) {
      // subscription data
      let validArr = obj?.valid_products ? obj.valid_products : [];
      console.log(validArr);
      const isValidArr = validArr.every(
        (product) => product.quantity.is_valid && product.return.is_valid
      );
      console.log(isValidArr);
      if (
        !isValidArr ||
        validArr.length === 0 ||
        !obj.payment_method.is_valid
      ) {
        return false;
      }

      const transformedData = obj.valid_products.reduce(
        (acc, product) => {
          acc.order_details.products.push({
            capacity: product.capacity,
            material: product.material,
            quantity: product.quantity.selected,
            return: product.return.selected,
            sku_qty: product.sku_qty,
          });
          return acc;
        },
        {
          user_id: props.userId,
          booking_type: updateCartObj.booking_type,
          order_details: {
            products: [],
            is_auto_apply_coupon: updateCartObj.is_auto_apply_coupon,
            is_auto_apply_water_drops: updateCartObj.is_auto_apply_water_drops,
            is_otp_less: updateCartObj.is_otp_less,
          },
          repeat_days: props.selectedDays,
          start_date: nextDeliveryDate,
          address_id: obj.address_id,
          is_forced_create: true,
        }
      );
      return transformedData;
    } else {
      //postpaid data
      let validArr = obj?.valid_products ? obj.valid_products : [];
      const isValidArr = validArr.every(
        (product) => product.quantity.is_valid && product.return.is_valid
      );
      if (
        !isValidArr ||
        validArr.length === 0 ||
        !obj.payment_method.is_valid
      ) {
        return false;
      }
      const transformedData = obj.valid_products.reduce(
        (acc, product) => {
          acc.products.push({
            capacity: product.capacity,
            material: product.material,
            quantity: product.quantity.selected,
            return: product.return.selected,
            sku_qty: product.sku_qty,
          });
          return acc;
        },
        {
          products: [],
          booking_type: obj.valid_products[0].booking_type,
          address_id: obj.address_id,
          coupon_code: obj.coupon_code_details.selected,
          water_drops: obj.water_drop_details.water_drops,
          payment_method: obj.payment_method.payment_method,
          is_otp_less: obj.is_otp_less,
          delivery_date: updateCartObj.delivery_date,
          final_price: obj.final_price,
          is_postpaid: props.isPostpaid,
          supplier_id: props.isPostpaid ? props.supplierId : "",
          po_track_id: !props.isPostpaid ? "" : props.poTrack,
          // is_extra_charges_disabled: !props.isPostpaid
          //   ? !props.deliveryCharge
          //   : false,
          is_extra_charges_disabled: updateCartObj.is_extra_charges_disabled,
        }
      );
      return transformedData;
    }
  };

  const placeOrder = (orderObj) => {
    CustomConsole(orderObj);
    console.log(orderObj);
    const reqParam = {
      user_phone: userDetails.phone,
    };
    if (props.bookNowType === 10) {
      // Regular Data
      const data = {
        transaction_ts: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        amount: orderObj.final_price,
        order_id: "",
        payment_method: "WATERWALLET",
        payment_type: "PREPAID",
        user_id: props.userId,
      };
      delete orderObj.final_price;
      if (
        orderObj.payment_method === "WALLET" &&
        productSku?.products?.water_wallet_balance >= allData?.final_price
      ) {
        dispatch(BookNow(orderObj, data));
        setUpdateCartShowProductDetails(false);
        setPlaceOrderFlag(true);
        props.close(false);
      } else if (
        orderObj.payment_method === "RAZORPAY" ||
        orderObj.payment_method === "PAYSHARP"
      ) {
        CustomConsole("-----------Other PayMent Methods------------");
        dispatch(BookNow(orderObj, data));
        setUpdateCartShowProductDetails(false);
        setPlaceOrderFlag(true);
        props.close(false);
      } else {
        CustomConsole("-----------LoW Balance------------");
        toast.error(
          "Low Water Wallet Balance,Please Recharge Your Water Wallet ",
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        props.close(false);
      }
    } else if (props.bookNowType === 40) {
      // Subscription Data
      if (productSku?.products?.water_wallet_balance >= allData?.final_price) {
        if (props.editSubscriptionDialog) {
          console.log(orderObj);
          if (orderObj !== false) {
            dispatch(
              EditSubscriptionDetails(orderObj, reqParam, props.subscriptionId)
            );
            props.setEditSubscriptionDialog(false);
            setAlignmentOneTimeSaleAndReill("Refill");
            setSelectedProduct([]);
            props.setSelectedDays([]);
            setAddressIdEdiSub(null);
            props.close(false);
          } else {
            toast.error("Please Check Order Or Return Or Max_Return Quantity", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        } else {
          CustomConsole(orderObj);
          if (orderObj.repeat_days.length > 0) {
            dispatch(SubscriptionBooking(orderObj, reqParam));
            setSelectedProduct([]);
            props.setSelectedDays([]);
            props.setUpdateSubscriptonText(false);
            setAddressIdEdiSub(null);
            props.close(false);
          } else {
            toast.error("Please Select Delivery Schedule", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
      } else {
        toast.error(
          "Low Water Wallet Balance,Please Recharge Your Water Wallet ",
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        props.close(false);
      }
    } else {
      //Postpaid Data
      const data = {
        transaction_ts: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        amount: orderObj.final_price,
        order_id: "",
        payment_method: "WATERWALLET",
        payment_type: "PREPAID",
        user_id: props.userId,
      };
      delete orderObj.final_price;
      dispatch(BookNow(orderObj, data));
      setUpdateCartShowProductDetails(false);
      setPlaceOrderFlag(true);
      setSelectedProducts([]);
      setAddressId(null);
      props.close(false);
      setTimeout(() => {
        let reqObj = {
          page_size: 10,
          page_number: 0,
          pp_track_id: "",
          order_status: "",
          sort: "DESC",
          phone: "",
        };
        dispatch(GetPoTrackDetails(reqObj));
      }, 1500);
    }
  };

  const handleMinusWaterDrops = () => {
    if (updateCartObj.water_drops > 0) {
      setUpdatecartObj((prev) => ({
        ...prev,
        water_drops: parseInt(updateCartObj.water_drops) - 1,
      }));
    }
  };
  //Post Paid Date Delivery Date
  const onChangeStartDate = (newValue) => {
    if (newValue != null && newValue.$d != null) {
      setOrderDate(() => moment(newValue.$d).tz("Asia/kolkata").format());

      CustomConsole(newValue.$d);
    }
  };

  const handleChangeOneTimeSaleAndReill = (event, newAlignment) => {
    setAlignmentOneTimeSaleAndReill(newAlignment);
  };

  const handleChangeWaterWrops = (e, applicableWaterDrops) => {
    CustomConsole(applicableWaterDrops);
    if (e.target.value >= 0 && applicableWaterDrops >= e.target.value) {
      setUpdatecartObj((prev) => ({
        ...prev,
        water_drops: e.target.value > 0 ? e.target.value : 0,
      }));
    } else {
      CustomConsole("------Not Apply-----");
      toast.error("Can't Apply More Than Max. Applicable Water Drops", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handlePlusWaterDrops = (applicableWaterDrops) => {
    if (applicableWaterDrops > parseInt(updateCartObj.water_drops)) {
      setUpdatecartObj((prev) => ({
        ...prev,
        water_drops: parseInt(updateCartObj.water_drops) + 1,
      }));
    } else {
      toast.error("Can't Apply More Than Max. Applicable Water Drops", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const checkInvalid = (material) => {
    let isValid = invalidProd.find((obj) => obj.name === material);
    return isValid;
  };

  const handleChangeDate = (newValue) => {
    setDeliveryDate(moment(newValue.$d).tz("Asia/kolkata").format());
    setUpdateCartShowProductDetails(false);

    CustomConsole(newValue.$d);
  };

  const blockAfterOneWeek = (date) => {
    const today = dayjs();
    const nextWeek = today.add(7, "day");
    return dayjs(date).isAfter(nextWeek, "day");
  };

  // React.useState(() => {
  // if (placeOrderFlag === true && productSku.validatingErr === false) {
  //   props.close(false);
  // }
  // else {
  //   toast.error("Please Enter Proper Data", {
  //     position: "bottom-right",
  //     autoClose: 2000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //   });
  // }
  // }, [placeOrderFlag]);
  React.useEffect(() => {
    CustomConsole("----Address Id Changing---");
    CustomConsole(addressId);
    // if (addressId) {
    //   // setCartProducts([]);
    //   // setOrderProducts([]);
    //   // setProducts([]);
    //   setSelectedProduct([]);
    //   dispatch(GetProductsAddress({ address_id: addressId }));
    // }
    if (addressId) {
      setUpdatecartObj((prev) => ({
        ...prev,
        address_id:
          props.bookNowType === 10
            ? props.addressIdPre
            : props.bookNowType === 40
            ? props.addressIdPre
            : addressId,
      }));
    }
  }, [addressId, props.addressIdPre]);

  React.useEffect(() => {
    console.log("----GetProductsAddress--------");
    console.log(addressId);
    if (addressId && props.createDeliveryBtnFlag) {
      dispatch(GetProductsAddress({ address_id: addressId }));
      props.setCreateDeliveryBtnFlag(false);
    }
  }, [addressId, props.createDeliveryBtnFlag]);
  React.useEffect(() => {
    CustomConsole("-------addressIdEdiSub----------");
    CustomConsole(addressIdEdiSub);
    // setAddressIdEdiSub(props.editingAdrs.address_id);
    if (addressIdEdiSub) {
      setUpdatecartObj((prev) => ({
        ...prev,
        address_id:
          props.updateSubscriptonText === true
            ? addressIdEdiSub
            : props.addressIdPre,
      }));
    }
    let address = updateUserDetails.address;
    if (addressIdEdiSub && props.updateSubscriptonText) {
      console.log("---------addressss--------");
      const filteredAddress = address.filter(
        (obj) => obj.address_id === addressIdEdiSub
      );
      CustomConsole(filteredAddress[0]);
      setSelectedAddress(filteredAddress[0]);
    }
  }, [addressIdEdiSub]);

  React.useEffect(() => {
    setUpdatecartObj((prev) => ({
      ...prev,
      supplier_id:
        props.bookNowType === 10
          ? undefined
          : props.bookNowType === 40
          ? undefined
          : props.supplierId,
    }));
  }, [props.supplierId]);
  React.useEffect(() => {
    props.setSelectedDays(props.selectedDays);
  }, [openEditDialog]);

  React.useEffect(() => {
    setUpdateCartShowProductDetails(true);
  }, []);

  // React.useEffect(() => {
  //   CustomConsole("-------------addressProductData---------");
  //   CustomConsole(addressProductData);
  //   CustomConsole(props.poProducts);
  //   CustomConsole(props.isPostpaid);
  //   if (props.isPostpaid) {
  //     let productsToShow = [];
  //     if (props.isPostpaid && addressProductData && props.poProducts) {
  //       productsToShow = addressProductData?.filter((obj) =>
  //         props.poProducts.some(
  //           (item) =>
  //             item.container_capacity === obj.capacity &&
  //             item.container_material === obj.material
  //         )
  //       );

  //       CustomConsole("selected products for postpaid");
  //       CustomConsole(productsToShow);
  //       CustomConsole(productsToShow);
  //     } else {
  //       productsToShow = addressProductData?.products
  //         ? addressProductData.products
  //         : [];
  //     }
  //     if (alignmentOneTimeSaleAndReill === "Refill") {
  //       CustomConsole("refill");
  //       const refillProductsPost = productsToShow.filter(
  //         (item) => item.booking_type === 1 || item.booking_type === 3
  //       );
  //       CustomConsole("----");
  //       CustomConsole(refillProductsPost);
  //       setPostpaidPoProducts(() =>
  //         refillProductsPost ? refillProductsPost : []
  //       );
  //       setbookType(1);
  //       setUpdatecartObj((prev) => ({ ...prev, booking_type: 1 }));
  //     } else {
  //       const oneTimePurchaseProductsPost = productsToShow.filter(
  //         (item) => item.booking_type === 2 || item.booking_type === 3
  //       );
  //       setPostpaidPoProducts(() =>
  //         oneTimePurchaseProductsPost ? oneTimePurchaseProductsPost : []
  //       );
  //       setbookType(2);
  //       setUpdatecartObj((prev) => ({ ...prev, booking_type: 2 }));
  //     }
  //   }

  //   // if (tabValue === "1" && productsToShow) {
  //   //   setProducts(() =>
  //   //     productsToShow.filter(
  //   //       (item) => item.booking_type === 1 || item.booking_type === 3
  //   //     )
  //   //   );
  //   // } else if (tabValue === "2" && productsToShow) {
  //   //   setProducts(() =>
  //   //     productsToShow.filter(
  //   //       (item) => item.booking_type === 2 || item.booking_type === 3
  //   //     )
  //   //   );
  //   // } else {
  //   //   setProducts([]);
  //   // }
  // }, [
  //   alignmentOneTimeSaleAndReill,
  //   // addressProductData,
  //   props.isPostpaid,
  //   props.poProducts,
  //   addressId,
  // ]);

  React.useState(() => {
    if (postpaidPoProducts) {
      setAddressProductData(postpaidPoProducts);
    }
  }, [postpaidPoProducts]);
  React.useEffect(() => {
    if (userStock?.[0]) {
      const returnStock = productSku.userStock[0].max_return;
      setMaxReturn(returnStock);
    }
  }, [productSku.userStock]);
  React.useEffect(() => {
    // if (updateCartObj.products.length > 0) {
    //   dispatch(ValidatingProducts(updateCartObj));
    // }
    if (props.editSubscriptionDialog) {
      CustomConsole("----inside prohoibitted zone-----");
      CustomConsole(props);
      // setAddressId(editAdrs.address_id);
      props.setSelectedDays(props.selectedDays);

      setSelectedProducts(props.editProducts);
      setAddressIdEdiSub(props.editingAdrs.address_id);
      if (props.booktypeSub === 1) {
        setAlignmentOneTimeSaleAndReill("Refill");
      } else {
        setAlignmentOneTimeSaleAndReill("OneTimeSale");
      }
      // setChecks({ ...discountChecks });
    }
  }, [props.editSubscriptionDialog]);

  React.useEffect(() => {
    if (
      inValidproducts !== undefined &&
      inValidproducts !== null &&
      inValidproducts.length > 0
    ) {
      setInvalidProd(inValidproducts);
    }
  }, [inValidproducts]);

  React.useEffect(() => {
    //@Removed/CustomConsole("---Cart Obj---");
    //@Removed/CustomConsole(updateCartObj);
  }, [updateCartObj]);

  React.useEffect(() => {
    if (
      moment().format("YYYY-MM-DD") ===
      moment(deliveryDate).format("YYYY-MM-DD")
    ) {
      if (props.bookNowType === 10) {
        setDisDeliveryBtn(false);
      } else {
        setDisDeliveryBtn(true);
      }

      setUpdatecartObj((prev) => ({
        ...prev,
        is_extra_charges_disabled: props.bookNowType === 10 ? false : true,
      }));
    } else if (
      moment().format("YYYY-MM-DD") !==
      moment(deliveryDate).format("YYYY-MM-DD")
    ) {
      setDisDeliveryBtn(true);
      setUpdatecartObj((prev) => ({
        ...prev,
        is_extra_charges_disabled: true,
      }));
    }
    if (deliveryDate) {
      setUpdatecartObj((prev) => ({ ...prev, delivery_date: deliveryDate }));
    }
  }, [deliveryDate]);

  React.useEffect(() => {
    if (orderDate) {
      setUpdatecartObj((prev) => ({
        ...prev,
        delivery_date: moment(orderDate).format("YYYY-MM-DD"),
      }));
    }
  }, [orderDate]);

  React.useEffect(() => {
    CustomConsole("----SKU Booking Data--");
    CustomConsole(productSku.userStock);
  }, [productSku]);

  React.useEffect(() => {
    CustomConsole("----Selected---");
    CustomConsole(selectedProducts);

    if (selectedProducts) {
      setMinProdClone(selectedProducts);
    }
  }, [selectedProducts]);
  React.useEffect(() => {
    if (
      props.createDeliveryBtnFlag &&
      props.isPostpaid &&
      updateUserDetails?.address !== undefined &&
      updateUserDetails?.address !== null &&
      updateUserDetails?.address.length > 0
    ) {
      setAddressId(updateUserDetails?.address[0].address_id);
      // dispatch(
      //   GetProductsAddress({
      //     address_id: updateUserDetails?.address[0].address_id,
      //   })
      // );
    }
  }, [updateUserDetails, props.isPostpaid]);

  React.useEffect(() => {
    CustomConsole("----Address Details---");
    CustomConsole(updateUserDetails);
    CustomConsole(props.addressIdPre);
    CustomConsole(addressId);

    let address = updateUserDetails.address;
    if (address) {
      if (props.bookNowType === 10) {
        const filteredAddress = address.filter(
          (obj) => obj.address_id === props.addressIdPre
        );
        CustomConsole(filteredAddress[0]);
        setSelectedAddress(filteredAddress[0]);
      } else if (props.bookNowType === 40) {
        const filteredAddress = address.filter(
          (obj) => obj.address_id === props.addressIdPre
        );
        CustomConsole(filteredAddress[0]);
        setSelectedAddress(filteredAddress[0]);
      } else {
        CustomConsole("-----addressId--------");
        CustomConsole(addressId);
        const filteredAddress = address.filter(
          (obj) => obj.address_id === addressId
        );
        CustomConsole(filteredAddress[0]);
        setSelectedAddress(filteredAddress[0]);
      }
    }

    if (props.addressIdPre) {
      CustomConsole("-----addressId--n------");
      dispatch(GetProductsAddress({ address_id: props.addressIdPre }));
    }
  }, [updateUserDetails, props.addressIdPre, addressId]);

  React.useEffect(() => {
    if (updateCartObj.products.length > 0) {
      dispatch(ValidatingProducts(updateCartObj));
    }
  }, [updateCartObj]);

  React.useEffect(() => {
    CustomConsole("---selected address---");
    CustomConsole(selectedAddress);
    CustomConsole(addressProductData);
  }, [selectedAddress, addressProductData]);

  React.useEffect(() => {
    CustomConsole("----consumer details---");
    CustomConsole(addUserAdminDetails);
    const userDetails = addUserAdminDetails.userData[0];
    if (userDetails) {
      setUserDetails(userDetails);
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    CustomConsole("---Products----");
    CustomConsole(productDet);
    CustomConsole(addressProductData);
    CustomConsole(props.poProducts);
    // setAddressProductData(() =>
    //   productDet?.products ? productDet.products : []
    // );
    let productsToShow = [];
    productsToShow = productDet.products?.filter((obj) =>
      props.poProducts?.some(
        (item) =>
          item.container_capacity === obj.capacity &&
          item.container_material === obj.material
      )
    );

    CustomConsole("------productsToShow----------");
    CustomConsole(productsToShow);
    if (alignmentOneTimeSaleAndReill === "Refill") {
      if (props.bookNowType === 10) {
        const refillProducts = productDet.products?.filter(
          (obj) => obj.booking_type === 1 || obj.booking_type === 3
        );

        CustomConsole(refillProducts);
        setAddressProductData(() => (refillProducts ? refillProducts : []));
        setbookType(1);
        setUpdatecartObj((prev) => ({ ...prev, booking_type: 1 }));
      } else if (props.bookNowType === 40) {
        const refillProducts = productDet.products?.filter(
          (obj) => obj.booking_type === 1 || obj.booking_type === 3
        );

        CustomConsole(refillProducts);
        setAddressProductData(() => (refillProducts ? refillProducts : []));
        setbookType(1);
        setUpdatecartObj((prev) => ({ ...prev, booking_type: 1 }));
      } else {
        const refillProducts = productsToShow?.filter(
          (obj) => obj.booking_type === 1 || obj.booking_type === 3
        );

        CustomConsole(refillProducts);
        setAddressProductData(() => (refillProducts ? refillProducts : []));
        setbookType(1);
        setUpdatecartObj((prev) => ({ ...prev, booking_type: 1 }));
      }
    } else {
      if (props.bookNowType === 10) {
        const oneTimePurchaseProducts = productDet.products?.filter(
          (obj) => obj.booking_type === 2 || obj.booking_type === 3
        );
        CustomConsole(oneTimePurchaseProducts);
        setAddressProductData(() =>
          oneTimePurchaseProducts ? oneTimePurchaseProducts : []
        );
        setbookType(2);
        setUpdatecartObj((prev) => ({ ...prev, booking_type: 2 }));
      } else if (props.bookNowType === 40) {
        const oneTimePurchaseProducts = productDet.products?.filter(
          (obj) => obj.booking_type === 2 || obj.booking_type === 3
        );
        CustomConsole(oneTimePurchaseProducts);
        setAddressProductData(() =>
          oneTimePurchaseProducts ? oneTimePurchaseProducts : []
        );
        setbookType(2);
        setUpdatecartObj((prev) => ({ ...prev, booking_type: 2 }));
      } else {
        const oneTimePurchaseProducts = productsToShow?.filter(
          (obj) => obj.booking_type === 2 || obj.booking_type === 3
        );
        CustomConsole(oneTimePurchaseProducts);
        setAddressProductData(() =>
          oneTimePurchaseProducts ? oneTimePurchaseProducts : []
        );
        setbookType(2);
        setUpdatecartObj((prev) => ({ ...prev, booking_type: 2 }));
      }
    }
  }, [productDet, alignmentOneTimeSaleAndReill]);

  React.useEffect(() => {
    CustomConsole("----Print cart----");
    CustomConsole(updateCartObj);
  }, [updateCartObj]);

  React.useEffect(() => {
    if (props.updateSubscriptonText === false) {
      setSelectedProduct([]);
    }
  }, [props.updateSubscriptonText]);

  // React.useEffect(() => {
  //   if (validproducts && inValidproducts) {
  //     setAfterUpdateCart([...validproducts, ...inValidproducts]);
  //   }
  // }, [validproducts, inValidproducts]);

  React.useEffect(() => {
    if (validproducts) {
      CustomConsole("---valid products---");
      CustomConsole(validproducts);
      let productToUpdate = selectedProducts;
      productToUpdate.forEach((item) => {
        CustomConsole("----items----");
        CustomConsole(item);
        let matchingItem = validproducts.find(
          (obj) =>
            obj.material === item.material && obj.capacity === item.capacity
        );
        CustomConsole("---matched---");
        CustomConsole(matchingItem);
        if (matchingItem) {
          item.quantity_valid = matchingItem.quantity;
          item.return_valid = matchingItem.return;
          item.deposit_valid = matchingItem.deposit;
          item.deliveryCharge = matchingItem.extra_charges;
          item.price_valid = matchingItem.final_price;
          item.price = matchingItem.price;
          item.product_price = matchingItem.product_price;
          item.booking_type = matchingItem.booking_type;
        }
      });

      CustomConsole("----mapped product----");
      CustomConsole(productToUpdate);
      // selectedProducts(selectedProduct);
    }
  }, [validproducts]);

  React.useEffect(() => {
    const reqObj = {
      user_id: props.userId,
      final_water_price: allData?.order_price ? allData.order_price : 0,
      can_count: 1,
      is_wallet: false,
    };

    dispatch(ConsumersCouponCodes(reqObj));
  }, [props.userId, allData]);

  React.useEffect(() => {
    CustomConsole("----Coupon details---");
    CustomConsole(couponDetails);
  }, [couponDetails]);

  React.useEffect(() => {
    if (
      selectedProducts &&
      selectedProducts.length > 0 &&
      productSku?.userStock
    ) {
      let prevProducts = selectedProducts.map((obj) => {
        const stock = productSku.userStock.filter(
          (item) =>
            item.container_capacity === obj.capacity &&
            item.container_material === obj.material
        );
        CustomConsole("----Stock---");
        CustomConsole(stock);
        if (stock && stock.length > 0) {
          return { ...obj, max_return: stock[0].max_return };
        }
        return obj;
      });
      CustomConsole("----FInal OP---");
      CustomConsole(prevProducts);
      setSelectedProducts(prevProducts);
    }
  }, [productSku, props.editSubscriptionDialog]);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={() => {
        props.close(false);
      }}
      TransitionComponent={Transition}
      keepMounted={false}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setSelectedProduct([]);
              props.setSelectedDays([]);
              setUpdateCartStatus(false);
              props.setEditSubscriptionDialog(false);
              props.close(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.bookNowType === 10
              ? "Regular Booking"
              : props.bookNowType === 40
              ? "Subscription Booking"
              : "PostPaid Booking"}
          </Typography>
          <Button
            autoFocus
            onClick={() => {
              props.close(false);
              props.setSelectedDays([]);
              setUpdateCartStatus(false);
              setSelectedProduct([]);
              props.setUpdateSubscriptonText(false);
              props.setEditSubscriptionDialog(false);
              // setUpdateCartShowProductDetails(false);
            }}
            sx={{ backgroundColor: "brown", color: "white" }}
          >
            close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText
          id="scroll-dialog-description"
          // ref={descriptionElementRef}
          tabIndex={-1}
        >
          {/* <Typography>
            {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
              )
              .join("\n")}
          </Typography> */}
          {/* Consumer Details who is booking */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper
                elevation={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <Typography sx={styles.paperHeadText}>
                  User Details & Address:{" "}
                </Typography>
                <Box sx={styles.wrapBox}>
                  {[
                    { key: "Consumer name", value: userDetails.fullname },
                    { key: "Consumer phone", value: userDetails.phone },
                    {
                      key: "Contact details",
                      value:
                        selectedAddress?.contact_name +
                        "-" +
                        selectedAddress?.contact_phone,
                    },
                    {
                      key: "Pincode - Area",
                      value:
                        selectedAddress?.pincode +
                        "-" +
                        selectedAddress?.service_area,
                    },
                    {
                      key: "State - Country",
                      value:
                        selectedAddress?.state + "-" + selectedAddress?.country,
                    },
                    {
                      key: "Address",
                      value: (
                        <>
                          {selectedAddress?.address_line1}
                          <br />
                          {selectedAddress?.address_line2} <br />{" "}
                          {selectedAddress?.address_line3} <br />{" "}
                          {selectedAddress?.landmark}
                        </>
                      ),
                    },
                  ].map((data, index) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                        key={index}
                      >
                        <Typography sx={styles.textHead}>
                          {data.key}:
                        </Typography>
                        <Typography sx={styles.textAnswer}>
                          {data.value}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper
                elevation={12}
                sx={{
                  padding: "10px",
                  gap: "10px",
                }}
              >
                {props.isPostpaid ? (
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <FormControl sx={{ width: 220 }} size="small">
                        <InputLabel shrink={!!addressId}>
                          Select Address
                        </InputLabel>
                        <Select
                          value={addressId}
                          label="Select Address"
                          onChange={handleAddressChange}
                        >
                          {updateUserDetails?.address?.map((data, idx) => {
                            return (
                              <MenuItem key={idx} value={data.address_id}>
                                {data.contact_name}: {data.pincode}-
                                {data.service_area} &nbsp; &nbsp;
                                {data.is_primary ? (
                                  <>
                                    <Chip
                                      label={"Primary"}
                                      sx={{
                                        bgcolor: "green",
                                        color: "white",
                                      }}
                                    />
                                  </>
                                ) : null}{" "}
                                &nbsp;
                                {data.is_billing_address === true ? (
                                  <>
                                    <Chip
                                      label={"Billing"}
                                      sx={{
                                        bgcolor: "red",
                                        color: "white",
                                      }}
                                    />
                                  </>
                                ) : null}
                                &nbsp;
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                ) : null}
                {props.updateSubscriptonText === true &&
                props.bookNowType === 40 ? (
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <FormControl sx={{ width: 220 }} size="small">
                        <InputLabel>Select Address</InputLabel>
                        <Select
                          value={addressIdEdiSub}
                          label="Select Address"
                          onChange={handleAddressChangeEditSub}
                        >
                          {updateUserDetails?.address?.map((data, idx) => {
                            return (
                              <MenuItem key={idx} value={data.address_id}>
                                {data.contact_name}: {data.pincode}-
                                {data.service_area} &nbsp; &nbsp;
                                {data.is_primary ? (
                                  <>
                                    <Chip
                                      label={"Primary"}
                                      sx={{
                                        bgcolor: "green",
                                        color: "white",
                                      }}
                                    />
                                  </>
                                ) : null}{" "}
                                &nbsp;
                                {data.is_billing_address === true ? (
                                  <>
                                    <Chip
                                      label={"Billing"}
                                      sx={{
                                        bgcolor: "red",
                                        color: "white",
                                      }}
                                    />
                                  </>
                                ) : null}
                                &nbsp;
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                ) : null}
                <Box sx={{ m: 2, display: "flex", justifyContent: "center" }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignmentOneTimeSaleAndReill}
                    exclusive
                    onChange={handleChangeOneTimeSaleAndReill}
                    aria-label="Platform"
                    size="small"
                  >
                    <ToggleButton value="Refill">Refill Products</ToggleButton>
                    <ToggleButton value="OneTimeSale">
                      One Time Purchase Products
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {selectedProducts.length > 0 ? (
                  <Button
                    color="secondary"
                    sx={{ float: "right" }}
                    onClick={updateCart}
                    disabled={updateCartShowProductDetails === true}
                  >
                    Update Cart
                  </Button>
                ) : (
                  ""
                )}
                <Typography sx={styles.paperHeadText}>Products</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "15px",
                    marginTop: "15px",
                    gap: "30px",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <TextField
                    label="Delivery Date"
                    variant="standard"
                    value={deliveryDate}
                    type="date"
                    onChange={handleChangeDate}
                    // inputProps={{
                    //   min: moment().format("YYYY-MM-DD"),
                    //   max: moment().add(6, "days").format("YYYY-MM-DD"),
                    //   readOnly: true,
                    // }}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputProps: {
                        min: moment().format("YYYY-MM-DD"),
                        max: moment().add(6, "days").format("YYYY-MM-DD"),
                      },
                    }}
                  /> */}
                  {props.bookNowType === 40 ? (
                    <Box sx={{ width: { md: "50%", xs: "100%" } }}>
                      <SubscriptionSchedule
                        selectedDays={props.selectedDays}
                        setSelectedDays={props.setSelectedDays}
                        setNextDayDate={setNextDeliveryDate}
                        setUpdateCartShowProductDetails={
                          setUpdateCartShowProductDetails
                        }
                        editSubscriptionDialog={props.editSubscriptionDialog}
                      />
                    </Box>
                  ) : props.bookNowType === 10 ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Delivery Date"
                        inputFormat="DD/MM/YYYY"
                        // minDate={moment().subtract(0, 'days')}
                        value={deliveryDate}
                        onChange={handleChangeDate}
                        disablePast
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            required
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                        shouldDisableDate={blockAfterOneWeek}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography>
                          Default Distributor: {props.distributerName}
                        </Typography>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => setSupplierSelectDig(true)}
                        >
                          Change
                        </Button>
                      </Box>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Delivery Date"
                            inputFormat="DD/MM/YYYY"
                            // minDate={moment().subtract(0, 'days')}
                            value={orderDate}
                            onChange={onChangeStartDate}
                            disablePast
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                required
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: true,
                                }}
                              />
                            )}
                            shouldDisableDate={
                              !props.isPostpaid
                                ? blockAfterOneWeek
                                : shouldDisableDate
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  )}

                  <FormControl sx={{ width: "250px" }}>
                    <InputLabel>Available products</InputLabel>
                    <Select
                      variant="standard"
                      defaultValue={"NONE"}
                      value={selectedProduct}
                      onChange={(event) => {
                        CustomConsole(event.target.value);
                        handleSelectProduct(event.target.value);
                        // setSelectedSupplier(event.target.value);
                      }}
                      label="Availble products"
                    >
                      <MenuItem value={"NONE"}>None</MenuItem>
                      {addressProductData.map((data, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={data.capacity + "-D-" + data.material}
                          >
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                    padding: "15px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {selectedProducts !== undefined &&
                    selectedProducts !== null &&
                    selectedProducts?.map((data, index) => {
                      return (
                        <>
                          <Paper
                            elevation={20}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              padding: "10px",
                              height: "100%",
                              maxWidth: "400px",
                              bgcolor: checkInvalid(data.name)
                                ? "#F5B7B1"
                                : "white",
                            }}
                            key={index}
                          >
                            {checkInvalid(data.name) ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography sx={{ color: "red" }}>
                                  OUT OF STOCK
                                </Typography>
                              </Box>
                            ) : (
                              ""
                            )}
                            <Box sx={styles.rowBox}>
                              <Typography sx={styles.textHead}>
                                Product:
                              </Typography>

                              <Typography sx={styles.textAnswer}>
                                {data.name}
                              </Typography>
                              <Typography
                                sx={{
                                  textAlign: "end",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleRemoveProduct(data)}
                                >
                                  <DeleteForeverIcon
                                    sx={{ color: "red" }}
                                    variant="small"
                                  />
                                </IconButton>
                              </Typography>
                            </Box>

                            <Box sx={styles.rowBox} key={index}>
                              <Typography sx={styles.textHead}>
                                SKU:{" "}
                              </Typography>

                              <Typography sx={styles.textAnswer}>
                                1 Pack | {data.sku_qty} Units
                              </Typography>
                            </Box>
                            <Box sx={styles.rowBox}>
                              <Typography sx={styles.textHead}>
                                Order quantity (Packs):{" "}
                              </Typography>
                              <Box
                                sx={{
                                  ...styles.rowBox,
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  disabled={checkInvalid(data.name)}
                                  variant="contained"
                                  sx={styles.incDecBtn}
                                  onClick={() =>
                                    handleMinusOrderQty({
                                      sku_qty: data.sku_qty,
                                      return: selectedProducts[index].return,
                                      max_return: data.max_return,
                                      quantity:
                                        selectedProducts[index].quantity,
                                      index: index,
                                    })
                                  }
                                >
                                  -
                                </Button>
                                <TextField
                                  disabled={checkInvalid(data.name)}
                                  type="text"
                                  sx={{
                                    ...styles.textAnswer,
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    width: "40px",
                                  }}
                                  variant="standard"
                                  value={selectedProducts[index].quantity}
                                  onChange={(e) => {
                                    handleQuantity({
                                      e: e,
                                      index: index,
                                      quantity:
                                        selectedProducts[index].quantity,
                                      sku_qty: data.sku_qty,
                                      return: selectedProducts[index].return,
                                      max_return: data.max_return,
                                    });
                                  }}
                                >
                                  0
                                </TextField>
                                <Button
                                  disabled={checkInvalid(data.name)}
                                  variant="contained"
                                  sx={styles.incDecBtn}
                                  onClick={() =>
                                    handlePlusOrderQty(
                                      selectedProducts[index].quantity,
                                      index
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </Box>{" "}
                            </Box>
                            {alignmentOneTimeSaleAndReill === "Refill" ? (
                              <Box sx={styles.rowBox}>
                                <Typography sx={styles.textHead}>
                                  Return quantity (Units):{" "}
                                </Typography>
                                <Box
                                  sx={{
                                    ...styles.rowBox,
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    disabled={checkInvalid(data.name)}
                                    variant="contained"
                                    sx={styles.incDecBtn}
                                    onClick={() =>
                                      handleMinusReturnQty(
                                        selectedProducts[index].return,
                                        index
                                      )
                                    }
                                  >
                                    -
                                  </Button>
                                  <TextField
                                    disabled={checkInvalid(data.name)}
                                    type="text"
                                    variant="standard"
                                    sx={{
                                      ...styles.textAnswer,
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      width: "40px",
                                    }}
                                    value={selectedProducts[index].return || 0}
                                    onChange={(e) => {
                                      handleReturnQuantity({
                                        e: e,
                                        index: index,
                                        max_return: data.max_return,
                                      });
                                    }}
                                  >
                                    0
                                  </TextField>
                                  <Button
                                    disabled={checkInvalid(data.name)}
                                    variant="contained"
                                    sx={styles.incDecBtn}
                                    onClick={() =>
                                      handlePlusReturnQty({
                                        sku_qty: data.sku_qty,
                                        return: selectedProducts[index].return,
                                        max_return: data.max_return,
                                        index: index,
                                        quantity:
                                          selectedProducts[index].quantity,
                                      })
                                    }
                                  >
                                    +
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )}
                            {alignmentOneTimeSaleAndReill === "Refill" ? (
                              <Box sx={styles.rowBox}>
                                <Typography sx={styles.textHead}>
                                  Max. return quantity:{" "}
                                </Typography>
                                <Typography sx={styles.textAnswer}>
                                  {/* {data.min_qty} */}
                                  {data.max_return}
                                </Typography>
                              </Box>
                            ) : (
                              ""
                            )}
                            {updateCartShowProductDetails === true ? (
                              <>
                                {/* <Box sx={styles.rowBox}>
                                  <Typography sx={styles.textHead}>
                                    Max. order quantity:{" "}
                                  </Typography>
                                  <Typography sx={styles.textAnswer}>
                                    {data.max_qty}
                                  </Typography>
                                </Box> */}

                                <Box sx={styles.rowBox}>
                                  <Typography sx={styles.textHead}>
                                    {selectedProducts[index].quantity} x{" "}
                                    {data.name} Price:{" "}
                                  </Typography>
                                  <Typography sx={styles.textAnswer}>
                                    {selectedProducts[index]?.product_price}
                                  </Typography>
                                </Box>
                                <Box sx={styles.rowBox}>
                                  <Typography sx={styles.textHead}>
                                    Sale Price:{" "}
                                  </Typography>
                                  <Typography sx={styles.textAnswer}>
                                    {selectedProducts[index]?.price?.sp}
                                  </Typography>
                                </Box>
                                <Box sx={styles.rowBox}>
                                  <Typography sx={styles.textHead}>
                                    MRP:{" "}
                                  </Typography>
                                  <Typography sx={styles.textAnswer}>
                                    {selectedProducts[index]?.price?.mrp}
                                  </Typography>
                                </Box>
                                <Box sx={styles.rowBox}>
                                  <Typography sx={styles.textHead}>
                                    Delivery charges:{" "}
                                  </Typography>
                                  <Typography sx={styles.textAnswer}>
                                    {
                                      selectedProducts[index]?.deliveryCharge
                                        ?.price
                                    }
                                  </Typography>
                                </Box>
                                <Box sx={styles.rowBox}>
                                  <Typography sx={styles.textHead}>
                                    Deposit details:{" "}
                                  </Typography>
                                  <Typography sx={styles.textAnswer}>
                                    {
                                      selectedProducts[index]?.deposit_valid
                                        ?.price
                                    }
                                  </Typography>
                                </Box>
                                <Box sx={styles.rowBox}>
                                  <Typography sx={styles.textHead}>
                                    Final price:{" "}
                                  </Typography>
                                  <Typography sx={styles.textAnswer}>
                                    {selectedProducts[index]?.price_valid}
                                  </Typography>
                                </Box>

                                {/* <Box sx={styles.rowBox}>
                              <Typography sx={styles.textHead}>
                                Product status:{" "}
                              </Typography>
                              <Typography sx={styles.textAnswer}>
                                Available
                              </Typography>
                            </Box> */}
                              </>
                            ) : (
                              ""
                            )}
                            <Box
                              sx={{ position: "absolute", bgcolor: "blue" }}
                            ></Box>
                          </Paper>
                        </>
                      );
                    })}
                </Box>
              </Paper>
            </Grid>
            {/* {updateCartShowProductDetails === true ? ( */}
            {selectedProducts.length > 0 &&
            productSku.validatingErr === false &&
            updateCartShowProductDetails === true ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper
                  elevation={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    gap: "10px",
                  }}
                >
                  <Typography sx={styles.paperHeadText}>
                    Booking Summary
                  </Typography>
                  <Box sx={styles.wrapBox}>
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>Otp-Less: </Typography>
                      <Checkbox
                        checked={updateCartObj.is_otp_less}
                        onChange={() =>
                          setUpdatecartObj((prev) => ({
                            ...prev,
                            is_otp_less: !prev.is_otp_less,
                          }))
                        }
                        sx={{ color: "green" }}
                      />
                    </Box>
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Delivery charges:{" "}
                      </Typography>
                      <Checkbox
                        checked={!updateCartObj.is_extra_charges_disabled}
                        onChange={() =>
                          setUpdatecartObj((prev) => ({
                            ...prev,
                            is_extra_charges_disabled:
                              !updateCartObj.is_extra_charges_disabled,
                          }))
                        }
                        sx={{ color: "green" }}
                        disabled={disableDeliveryBtn === true}
                      />
                    </Box>
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Auto apply promocode:{" "}
                      </Typography>
                      <Checkbox
                        checked={updateCartObj.is_auto_apply_coupon}
                        onChange={() =>
                          setUpdatecartObj((prev) => ({
                            ...prev,
                            is_auto_apply_coupon: !prev.is_auto_apply_coupon,
                          }))
                        }
                        sx={{ color: "green" }}
                      />
                    </Box>
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Auto apply waterdrops:{" "}
                      </Typography>
                      <Checkbox
                        checked={updateCartObj.is_auto_apply_water_drops}
                        onChange={() =>
                          setUpdatecartObj((prev) => ({
                            ...prev,
                            is_auto_apply_water_drops:
                              !prev.is_auto_apply_water_drops,
                          }))
                        }
                        sx={{ color: "green" }}
                      />
                    </Box>
                    {updateCartObj.is_auto_apply_coupon === false ? (
                      <Box sx={styles.rowBox}>
                        <Typography sx={styles.textHead}>
                          Available promocodes:{" "}
                        </Typography>
                        {/* <Checkbox defaultChecked sx={{ color: "green" }} /> */}
                        <FormControl sx={{ width: "150px" }}>
                          {/* <InputLabel>Available promocodes</InputLabel> */}
                          <Select
                            variant="standard"
                            value={updateCartObj.coupon_code}
                            onChange={(e) => {
                              setUpdatecartObj((prev) => ({
                                ...prev,
                                coupon_code: e.target.value,
                              }));
                            }}
                            label="Availble products"
                          >
                            <MenuItem value={""}>None</MenuItem>
                            {couponDetails?.map((data, index) => {
                              return (
                                <MenuItem key={index} value={data.promo_label}>
                                  {data.promo_label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : (
                      ""
                    )}
                    {updateCartObj.is_auto_apply_water_drops === false ? (
                      <Box sx={styles.rowBox}>
                        <Typography sx={styles.textHead}>
                          Water Drops :{" "}
                        </Typography>
                        <Box
                          sx={{
                            ...styles.rowBox,
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={styles.incDecBtn}
                            onClick={handleMinusWaterDrops}
                          >
                            -
                          </Button>
                          <TextField
                            type="number"
                            sx={{
                              ...styles.textAnswer,
                              marginLeft: "10px",
                              marginRight: "10px",
                              width: "60px",
                            }}
                            variant="standard"
                            // disabled
                            value={updateCartObj.water_drops}
                            // onChange={(e) => {
                            //   setUpdatecartObj((prev) => ({
                            //     ...prev,
                            //     water_drops: e.target.value,
                            //   }));
                            // }}
                            onChange={(e) =>
                              handleChangeWaterWrops(
                                e,
                                allData?.water_drop_details?.max_applicable
                              )
                            }
                          >
                            0
                          </TextField>
                          <Button
                            variant="contained"
                            sx={styles.incDecBtn}
                            onClick={() =>
                              handlePlusWaterDrops(
                                allData?.water_drop_details?.max_applicable
                              )
                            }
                          >
                            +
                          </Button>
                        </Box>{" "}
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Order price :{" "}
                      </Typography>
                      <Typography sx={styles.textAnswer}>
                        {" "}
                        {allData?.order_price ? allData.order_price : 0}
                      </Typography>
                    </Box>
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Discount(s) Applied :{" "}
                      </Typography>
                      <Typography sx={styles.textAnswer}>
                        {allData?.discount ? allData.discount : 0}
                      </Typography>
                    </Box>

                    <Box sx={styles.rowBox}>
                      <Typography sx={{ color: "green", fontWeight: "bold" }}>
                        Final price :{" "}
                      </Typography>
                      <Typography sx={styles.textAnswer}>
                        {allData?.final_price ? allData.final_price : 0}
                      </Typography>
                    </Box>
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Payment Method:{" "}
                      </Typography>
                      {props.bookNowType === 10 ? (
                        <FormControl sx={{ width: "150px" }}>
                          <Select
                            variant="standard"
                            defaultValue={"NONE"}
                            value={updateCartObj.payment_method}
                            onChange={(e) => {
                              setUpdatecartObj((prev) => ({
                                ...prev,
                                payment_method: e.target.value,
                              }));
                            }}
                            label="Payment Method"
                          >
                            {["WALLET", "RAZORPAY", "PAYSHARP"].map(
                              (data, index) => {
                                return (
                                  <MenuItem key={index} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      ) : props.bookNowType === 40 ? (
                        <FormControl sx={{ width: "150px" }}>
                          <Select
                            variant="standard"
                            defaultValue={"NONE"}
                            value={updateCartObj.payment_method}
                            onChange={(e) => {
                              setUpdatecartObj((prev) => ({
                                ...prev,
                                payment_method: e.target.value,
                              }));
                            }}
                            label="Payment Method"
                          >
                            {["WALLET"].map((data, index) => {
                              return (
                                <MenuItem key={index} value={data}>
                                  {data}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        "POSTPAID"
                      )}
                    </Box>

                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Available Wallet Balance:{" "}
                      </Typography>
                      <Typography sx={styles.textAnswer}>
                        {" "}
                        {productSku?.products?.water_wallet_balance
                          ? productSku.products.water_wallet_balance
                          : 0}
                      </Typography>
                    </Box>
                    <Box sx={styles.rowBox}>
                      <Typography sx={styles.textHead}>
                        Max. applicable water drops:{" "}
                      </Typography>
                      <Typography sx={styles.textAnswer}>
                        {allData?.water_drop_details?.max_applicable
                          ? allData.water_drop_details.max_applicable
                          : 0}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ) : (
              ""
            )}
            {/* ) : (
              ""
            )} */}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.close(false);
            setSelectedProduct([]);
            props.setSelectedDays([]);
            setUpdateCartStatus(false);
            props.setEditSubscriptionDialog(false);
          }}
          sx={{
            backgroundColor: "brown",
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "red", // Change background color on hover
              color: "black",
            },
          }}
        >
          cancel
        </Button>
        {productSku.validatingErr === false &&
        updateCartShowProductDetails === true &&
        updateCartStatus === true ? (
          <Button
            onClick={() => placeOrder(orderPlaceFinalArr(allData))}
            sx={{
              backgroundColor: "green",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#8EB1E8", // Change background color on hover
                color: "black",
              },
            }}
            disabled={updateCartShowProductDetails === false}
          >
            {props.updateSubscriptonText === true ? "Update" : "Place Order"}
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
      <ReassignSupplierDialog
        open={supplierSelectDig}
        setOpen={setSupplierSelectDig}
        setSupplierSelect={props.setSupplier}
        setSupplierName={props.setDistributerName}
      />
    </Dialog>
  );
}

export default BookRegularProducts;

const styles = {
  paperHeadText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "16px",
  },
  textHead: {
    color: "blue",
    fontSize: "14px",
    fontWeight: "bold",
  },
  textAnswer: {
    color: "gray",
    fontSize: "14px",
    fontWeight: "bold",
  },
  incDecBtn: {
    height: "25px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  rowBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
    gap: 2,
    // padding: "5px",
    // marginRight: "5px",
    border: "1px solid black",
    padding: "1px",
  },
  wrapBox: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    gap: 3,
  },
};
