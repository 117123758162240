import useAxios from "../../Axios/useAxios";
import CustomConsole from "../../../CustomConsole";
import actionType from "./actionType";
import { toast } from "react-toastify";

export const GetContainerDepositDetails = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/getDepositDetails`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_CONTAINER_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const UpdateContainerDepositDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/updateDepositDetails`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_CONTAINER_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetContainerDepositDetailsList = (
  capacity,
  material,
  pageNumber,
  isActive,
  filetAt
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/admin/getDepositDetailsList?container_capacity=${capacity}&container_material=${material}&is_active=${isActive}&date=${filetAt}&page_number=${pageNumber}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DEPOSIT_LIST,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdateActiveDeactivateCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/admin/updateDepositDetails`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_ACTIVE_DEACTIVE_CANS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

//function to get iot detail action
export const GetIotLiveData = (params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/TM/iotqualitydetails`, { params: params });
      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      toast.error(" Unable to get Iot details!", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return false;
    }
  };
};
