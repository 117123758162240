/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { REACT_APP_BASE_URL } from "../../Components/AppMeta/appMetaConfig";

// export const url = "https://staging.bookwater.com";
// export const url = "https://link.bookwater.com";
export const url = REACT_APP_BASE_URL;
export const setHeaders = () => {
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };
  return headers;
};
