/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  data: {},
  progress: 0,
  loaded: "",
invoiceGenerationProg: 0,
};

const invoiceGenerationReducer = (state = initialState, action) => {
  CustomConsole("----Action----Progress---");
  CustomConsole(action);
  switch (action.type) {
    case actionType.INVOICE_GENERATION:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        data: action.payload.data,
        progress: action.progress || 100,
        loaded: action.loaded,
      };
    case actionType.UPDATE_GENERATION:
      return {
        ...state,
        error: action.payload.error,
        progress: action.progress,
      };
case actionType.INVOICE_ITEMIZED_GENERATION:
      return {
        ...state,
        error: action.payload.error,
        invoiceGenerationProg: action.progress,
      };
    case actionType.ITEM_UPDATE_GENERATION:
      return {
        ...state,
        error: action.payload.error,
        invoiceGenerationProg: action.progress,
      };
    default:
      return state;
  }
};

export default invoiceGenerationReducer;
