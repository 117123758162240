import { Box, Button, Dialog, DialogContent } from "@mui/material";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import React from "react";

import { Close } from "@mui/icons-material";

function ProductCapColorDialog(props) {
  const handleCloseCapColorDialog = () => {
    props.handleCloseCapColorDialog();
  };
  return (
    <Dialog open={props.openProductCapColorDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleCloseCapColorDialog}
          >
            <Close />
          </Button>
        </Box>
        {props.canColorResp.error ? (
          props.canColorResp.msg.length ? (
            <h5 style={{ color: "red" }}>{props.canColorResp.msg}</h5>
          ) : (
            ""
          )
        ) : props.canColorResp.data && props.canColorResp.data.color ? (
          <Box
            sx={{
              marginTop: "35px",
            }}
          >
            <h5>No. of times used: {props.canColorResp.data.usage_count}</h5>
            <h5>Product Can Color:</h5>
            <AdfScannerIcon
              sx={{
                fontSize: "150px",
                color: props.canColorResp.data.color,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ProductCapColorDialog;
