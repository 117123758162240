import { Close, Email, Send } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Box,
  Paper,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import React from "react";

function StockDetailsDialog(props) {
  const handleSendEmailExpiredOrNonExpired = () => {
    props.handleSendEmailExpiredOrNonExpired();
  };
  const handleOpenReturnRequest = () => {
    props.handleOpenReturnRequest();
  };
  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };
  return (
    <Dialog open={props.openStockDetailsDialog}>
      <DialogContent>
        {/* <Paper sx={{ padding: "10px", borderRadius: "10px" }} elevation={10}> */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
          <Box sx={{ m: 1 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              STOCK DETAILS
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseDialog}
            >
              <Close />
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ textAlign: "start" }}>
              Expired Cans : {props.expiredCansWithConsumer}
            </Typography>
            <Typography sx={{ textAlign: "start" }}>
              Non-Expired Cans : {props.nonExpiredCans}
            </Typography>
          </Box>
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="outlined"
              onClick={handleSendEmailExpiredOrNonExpired}
            >
              send <Email />{" "}
            </Button>
          </Box>
        </Box>
        <Divider
          sx={{
            height: `${1}px`, // Adjust the thickness as needed
            borderStyle: "dashed",
            border: "1px solid black",
          }}
        />
        <Box sx={{ m: 1, display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={handleOpenReturnRequest}>
            <Send />
            Return Request
          </Button>
        </Box>
        {/* </Paper> */}
      </DialogContent>
    </Dialog>
  );
}

export default StockDetailsDialog;
