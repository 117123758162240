import React from "react";
import bwLogo from "../../Images/bwLogo.png";
import CustomConsole from "../../CustomConsole";

const ImageLoader = ({ filename }) => {
  const loadImage = () => {
    try {
      // Retrieve the image URL from local storage

      const storedImageURL = localStorage.getItem(`image_${filename}`);

      if (storedImageURL) {
        // Display the image in the UI using the stored URL
        return (
          <img
            src={storedImageURL}
            alt={filename}
            style={{
              // height: "120px", // Set the desired  height
              width: "60px", // Set the desired width
              objectFit: "cover",
              mt: "10px",
            }}
          />
        );
      } else {
        // Handle the case when the image URL is not found in local storage
        return (
          <img
            alt=""
            src={bwLogo}
            style={{
              objectFit: "contain",
              height: "120px", // Set the desired  height
              width: "60px", // Set the desired width
              mt: "10px",
            }}
          />
        );
      }
    } catch (error) {
      console.error("Error loading image:", error);
      // Handle errors, e.g., display an error message
      return <p>Error loading image</p>;
    }
  };

  return <div>{loadImage()}</div>;
};

export default ImageLoader;
