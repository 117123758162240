import React from "react";
import AdminSearchUserCard from "./AdminSearchUserCard";
import { Box, Container, Grid } from "@mui/material";
import AdminSettlementFormCard from "./AdminSettlementFormCard";
import AdminSettlementHistoryCard from "./AdminSettlementHistoryCard";
import AdminSettlementRequestTable from "./AdminSettlementRequestTable";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDetailsFromDateToDate,
  RequestForDetails,
  SettlementHistory,
  SettlementRequestList,
  WfsInfluencerSupplierSettlements,
} from "../Redux/Actions/adminSettlementAction";
import CustomConsole from "../../CustomConsole";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import moment from "moment";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { toast } from "react-toastify";

function AdminSettlement() {
  // All the required variables declared

  /**
   * @author Pravesh
   * @description  All the required variables declared
   * @date 20/05/23
   *
   *  */
  const [userRadio, setUserRadio] = React.useState("settlement_request");

  const [searchBtnFlag, setSearchBtnFlag] = React.useState(false);
  const [searchDateToDateFlag, setSearchDateToDateFlag] = React.useState(false);
  const [showSettlementData, setShowSettlementData] = React.useState(false);
  const [updateBtnFlag, setUpdateBtnFlag] = React.useState(false);
  const [dateToDateFlag, setDateToDateFlag] = React.useState(false);
  const [historyFlag, setHistoryFlag] = React.useState(false);
  const [requestListFlag, setRequestListFlag] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [userType, setUserType] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [phoneDetails, setPhoneDetails] = React.useState("");
  const [emailDetails, setEmailDetails] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [wfsId, setWfsId] = React.useState("");
  const [emailWfs, setEmailWfs] = React.useState("");
  const [emailIdError, setEmailIdError] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(1, "days").startOf("day").format("YYYY/MM/DD")
  );
  const [toDate, setToDate] = React.useState(moment().format("YYYY/MM/DD"));

  const [amountToSettle, setAmountToSettle] = React.useState("");
  const [baseCanPrice, setBaseCanPrice] = React.useState("");
  const [canInLoss, setCanInLoss] = React.useState("");
  const [canInProfit, setCanInProfit] = React.useState("");
  const [neutralCans, setNeutralCans] = React.useState("");
  const [totalAmount, setTotalAmount] = React.useState("");
  const [totalCansSold, setTotalCansSold] = React.useState("");
  const [totalLossEach, setTotalLossEach] = React.useState("");
  const [totalProfitEach, setTotalProfitEach] = React.useState("");
  const [tdsCharges, setTdsCharges] = React.useState(0);
  const [extraCharges, setExtraCharges] = React.useState(0);
  const [salesInvoiceFile, setSalesInvoiceFile] = React.useState("");
  const [salesInvoiceURL, setSalesInvoiceURL] = React.useState("");
  const [paymentReciptFile, setPaymentReciptFile] = React.useState("");
  const [paymentReciptURL, setPaymentReciptURL] = React.useState("");
  const [uploadSalesBtn, setUploadSalesBtn] = React.useState(false);
  const [uploadPaymentBtn, setUploadPaymentBtn] = React.useState(false);
  const [settlementPricePerCan, setSettlementPricePerCan] = React.useState("");
  const [existingWaterDrops, setExistingWaterDrops] = React.useState("");
  const [requestedWaterDrops, setRequestedWaterDrops] = React.useState("");
  const [currentWaterDropPrice, setCurrentWaterDropPrice] = React.useState("");
  const [totalSettlementPrice, setTotalSettlementPrice] = React.useState("");
  const [tsStatus, setTsStatus] = React.useState("1001");
  const [selectedUserType, setSelectedUserType] = React.useState(3000);
  // Settlement History variables

  const [settlementData, setSettlementData] = React.useState([]);
  const [settlementHistoryPayURL, setSettlementHistoryPayURL] =
    React.useState("");
  const [settlementHistoryInvoiceURL, setSettlementHistoryInvoiceURL] =
    React.useState("");
  // API flags variable to avoid rendering and refersh issue
  const [apiFlag1, setApiFlag1] = React.useState(false);
  const [apiFlag2, setApiFlag2] = React.useState(false);
  const [apiFlag3, setApiFlag3] = React.useState(false);
  const [apiFlag4, setApiFlag4] = React.useState(false);
  const [apiFlag5, setApiFlag5] = React.useState(false);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageNumberHistory, setPageNumberHistory] = React.useState(1);
  const [pageSizeHistory, setPageSizeHistory] = React.useState(22);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const dispatch = useDispatch();
  // To access store data from action and reducer (redux)
  const settlementsDetails = useSelector((state) => state.settlementsDetails);
  const fileHandler = useSelector((state) => state.fileHandler);

  // useEffect to get the data after action performed (API CALL) and setting values from for the state variables declared.

  /**
   * @author Pravesh
   * @description  useEffect to get the data after action performed (API CALL) and setting values from for the state variables declared.
   * @date 20/05/23
   *
   *  */

  React.useEffect(() => {
    dispatch(
      SettlementHistory({
        page_number: pageNumberHistory - 1,
        page_size: pageSizeHistory,
        user_type: selectedUserType,
        phone: phoneNumber,
      })
    );
  }, [pageNumberHistory]);

  React.useEffect(() => {
    CustomConsole(settlementsDetails);
    if (
      settlementsDetails.userSettlementError === false &&
      Object.keys(settlementsDetails.userSettlementDetails).length > 0 &&
      apiFlag1 === true
    ) {
      CustomConsole("Inside If");
      // setUserRadio("Details");
      setUserType(settlementsDetails.userSettlementDetails.user_type);
      setUserName(settlementsDetails.userSettlementDetails.supplier_name);
      setEmailDetails(settlementsDetails.userSettlementDetails.email);
      setPhoneDetails(settlementsDetails.userSettlementDetails.phone);
      setUserId(settlementsDetails.userSettlementDetails.user_id);
      if (
        settlementsDetails.userSettlementDetails.user_type ===
        ADMIN_ROLES.BW_WFSADMIN
      ) {
        setWfsId(settlementsDetails.userSettlementDetails.wfs_id);
        setUserName(settlementsDetails.userSettlementDetails.owner_name);
      } else {
        CustomConsole("Not WFS");
      }
      if (
        settlementsDetails.userSettlementDetails.user_type ===
        ADMIN_ROLES.BW_CONSUMER
      ) {
        setUserName(settlementsDetails.userSettlementDetails.user_name);
        setExistingWaterDrops(
          settlementsDetails.userSettlementDetails.existing_water_drops
        );
        setRequestedWaterDrops(
          settlementsDetails.userSettlementDetails.requested_water_drops
        );
        setCurrentWaterDropPrice(
          settlementsDetails.userSettlementDetails.current_water_drop_price
        );
        setTotalSettlementPrice(
          settlementsDetails.userSettlementDetails.total_settlement_price
        );
      } else {
        CustomConsole("Not distributor");
      }
      setApiFlag1(false);
      setRequestListFlag(false);
      setHistoryFlag(false);
    } else {
      CustomConsole("Main Else block error");
    }

    if (
      settlementsDetails.settlementDetailByDateError === false &&
      Object.keys(settlementsDetails.settlementDetailByDate).length > 0 &&
      apiFlag2 === true
    ) {
      if (
        userType === ADMIN_ROLES.BW_DISTRIBUTER ||
        userType === ADMIN_ROLES.BW_CONSUMER
      ) {
        setAmountToSettle(
          settlementsDetails.settlementDetailByDate.amount_to_settle
        );
        setSettlementPricePerCan(
          settlementsDetails.settlementDetailByDate.settlement_price_per_can
        );
        setBaseCanPrice(
          settlementsDetails.settlementDetailByDate.base_can_price
        );
        setCanInLoss(settlementsDetails.settlementDetailByDate.can_in_loss);
        setCanInProfit(settlementsDetails.settlementDetailByDate.can_in_profit);
        setNeutralCans(settlementsDetails.settlementDetailByDate.neutral_cans);
        setTotalAmount(settlementsDetails.settlementDetailByDate.total_amount);
        setTotalCansSold(
          settlementsDetails.settlementDetailByDate.total_cans_sold
        );
        setTotalLossEach(
          settlementsDetails.settlementDetailByDate.total_loss_each
        );
        setTotalProfitEach(
          settlementsDetails.settlementDetailByDate.total_profit_each
        );
        setApiFlag2(false);
      } else if (userType === ADMIN_ROLES.BW_WFSADMIN) {
        setAmountToSettle(
          settlementsDetails.settlementDetailByDate.amount_to_settle
        );
        setSettlementPricePerCan(
          settlementsDetails.settlementDetailByDate.settlement_price_per_can
        );
        setBaseCanPrice(
          settlementsDetails.settlementDetailByDate.base_can_price
        );
        // setCanInLoss(settlementsDetails.settlementDetailByDate.can_in_loss);
        // setCanInProfit(settlementsDetails.settlementDetailByDate.can_in_profit);
        // setNeutralCans(settlementsDetails.settlementDetailByDate.neutral_cans);
        setTotalAmount(settlementsDetails.settlementDetailByDate.total_price);
        setTotalCansSold(settlementsDetails.settlementDetailByDate.total_cans);
        setTotalLossEach(
          settlementsDetails.settlementDetailByDate.total_loss_each
        );
        setTotalProfitEach(
          settlementsDetails.settlementDetailByDate.total_profit_each
        );
        setApiFlag2(false);
        // setShowSettlementData(false);
      }
      setRequestListFlag(false);
      setHistoryFlag(false);
    } else {
      CustomConsole("Else Block Error");
    }

    if (
      settlementsDetails.settlementHistoryError === false &&
      settlementsDetails.settlementHistory !== undefined &&
      apiFlag4 === true
    ) {
      setSettlementData(settlementsDetails.settlementHistory);
      setUserType(settlementsDetails.settlementHistory.user_type);
      setApiFlag4(false);
      setRequestListFlag(false);
      setHistoryFlag(true);
    } else {
      CustomConsole("Else block error");
    }

    if (
      settlementsDetails.requestError === false &&
      settlementsDetails.requestListData !== undefined &&
      apiFlag5 === true
    ) {
      setApiFlag5(false);
      setRequestListFlag(true);
      setHistoryFlag(false);
      setDisableBtn(false);
    } else {
      CustomConsole("else block error");
      setDisableBtn(true);
    }
  }, [settlementsDetails]);

  //  Hitting APIs according to the button click

  /**
   * @author Pravesh
   * @description Hitting APIs according to the button click
   * @date 20/05/23
   *
   *  */

  React.useEffect(() => {
    dispatch(SettlementRequestList("ASC", tsStatus, pageNumber - 1));
  }, [pageNumber]);
  React.useEffect(() => {
    CustomConsole(searchBtnFlag);
    if (searchBtnFlag === true) {
      CustomConsole(userRadio);

      if (userRadio === "Settlement History") {
        dispatch(
          SettlementHistory({
            page_number: pageNumberHistory - 1,
            page_size: pageSizeHistory,
            user_type: selectedUserType,
            phone: phoneNumber,
          })
        );
        setApiFlag4(true);
        setSearchBtnFlag(false);
      } else if (userRadio === "settlement_request") {
        setApiFlag5(true);
        //@Removed/console.log("-----------SettlementRequestList----------------");
        dispatch(SettlementRequestList("ASC", 1001, pageNumber - 1));
        setSearchBtnFlag(false);
      }
    } else if (searchDateToDateFlag === true) {
      if (
        userType === ADMIN_ROLES.BW_DISTRIBUTER ||
        userType === ADMIN_ROLES.BW_CONSUMER
      ) {
        const reqObj = {
          start_date: fromDate,
          end_date: toDate,
          container_capacity: "20_Liters",
          container_material: "PET",
          user_type: userType,
          user_id: userId,
          isWfs: false,
        };
        CustomConsole(reqObj);
        dispatch(GetDetailsFromDateToDate(reqObj));
        setApiFlag2(true);
        setSearchDateToDateFlag(false);
      } else if (userType === ADMIN_ROLES.BW_WFSADMIN) {
        setUserId("");
        const reqObj = {
          start_date: fromDate,
          end_date: toDate,
          container_capacity: "20_Liters",
          container_material: "PET",
          user_type: userType,
          user_id: wfsId,
          isWfs: true,
        };
        CustomConsole(reqObj);
        dispatch(GetDetailsFromDateToDate(reqObj));
        setApiFlag2(true);
        setSearchDateToDateFlag(false);
      }
    } else if (updateBtnFlag === true) {
      if (userType === ADMIN_ROLES.BW_DISTRIBUTER) {
        const reqObj = {
          user_type: userType,
          user_id: userId,
          start_date: fromDate,
          end_date: toDate,
          settlement_price_per_can: settlementPricePerCan,
          tds_charges: tdsCharges !== "" ? tdsCharges : 0,
          extra_charges: extraCharges !== "" ? extraCharges : 0,
          container_capacity: "20_Liters",
          container_material: "PET",
          attachment: {
            payment_receipt: paymentReciptURL,
            sales_report: salesInvoiceURL,
          },
        };
        CustomConsole(reqObj);
        if (
          reqObj.attachment.payment_receipt !== "" ||
          reqObj.attachment.sales_report !== ""
        ) {
          CustomConsole("Getting here");
          dispatch(WfsInfluencerSupplierSettlements(reqObj));
          setApiFlag3(true);
          setUpdateBtnFlag(false);
        } else {
          toast.error("Upload files (Sales Invoice / Payment Receipt)", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setUpdateBtnFlag(false);
        }
      } else if (userType === ADMIN_ROLES.BW_WFSADMIN) {
        CustomConsole(emailWfs);
        const reqObj = {
          user_type: userType,
          email: emailWfs,
          wfs_id: wfsId,
          start_date: fromDate,
          end_date: toDate,
          settlement_price_per_can: 2.0,
          tds_charges: tdsCharges !== "" ? tdsCharges : 0,
          extra_charges: extraCharges !== "" ? extraCharges : 0,
          container_capacity: "20_Liters",
          container_material: "PET",
          // email: emailDetails,
          attachment: {
            payment_receipt: paymentReciptURL,
            sales_report: salesInvoiceURL,
          },
        };
        CustomConsole(reqObj);
        dispatch(WfsInfluencerSupplierSettlements(reqObj));
        setApiFlag3(true);
        setUpdateBtnFlag(false);
      } else if (userType === ADMIN_ROLES.BW_CONSUMER) {
        const reqObj = {
          user_type: userType,
          user_id: userId,
          existing_water_drops: requestedWaterDrops,
          tds_charges: tdsCharges !== "" ? tdsCharges : 0,
          extra_charges: extraCharges !== "" ? extraCharges : 0,
          current_water_drop_price: currentWaterDropPrice,
          attachment: {
            payment_receipt: paymentReciptURL,
          },
        };
        CustomConsole(reqObj);
        //@Removed/console.log(
        //   "-----------WfsInfluencerSupplierSettlements----------------"
        // );
        dispatch(WfsInfluencerSupplierSettlements(reqObj));
        setApiFlag3(true);
        setUpdateBtnFlag(false);
        setTimeout(() => {
          dispatch(SettlementRequestList("ASC", 1001, pageNumber - 1));
        }, 200);
      }
    }
  }, [searchBtnFlag, searchDateToDateFlag, updateBtnFlag]);

  React.useEffect(() => {
    CustomConsole(emailWfs);
  }, [emailWfs]);
  // Uploading files and response after uploading will get here and stored in state variable
  /**
   * @author Pravesh
   * @description Uploading files and response after uploading will get here and stored in state variable
   * @date 20/05/23
   */
  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileHandler.error === false && fileHandler.isFileUploaded === true) {
      if (uploadSalesBtn === true) {
        setSalesInvoiceURL(fileHandler.fileName);
        setUploadSalesBtn(false);
        toast.success("Sales Invoice file Uploaded", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (uploadPaymentBtn === true) {
        setPaymentReciptURL(fileHandler.fileName);
        setUploadPaymentBtn(false);
        toast.success("Payment Recipt file Uploaded", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      CustomConsole("Error while uploading");
    }
  }, [fileHandler]);

  // Uploading files action according to user upload
  /**
   * @author Pravesh
   * @description Uploading files action according to user upload
   * @date 20/05/23
   */
  React.useEffect(() => {
    CustomConsole(paymentReciptFile);
    CustomConsole(salesInvoiceFile);
    if (paymentReciptFile !== "") {
      dispatch(UploadFile(paymentReciptFile));
      setUploadPaymentBtn(true);
      setPaymentReciptFile("");
    } else if (salesInvoiceFile !== "") {
      dispatch(UploadFile(salesInvoiceFile));
      setUploadSalesBtn(true);
      setSalesInvoiceFile("");
    }
  }, [paymentReciptFile, salesInvoiceFile]);

  const handleSearchPageSize = () => {
    dispatch(
      SettlementHistory({
        page_number: pageNumberHistory - 1,
        page_size: pageSizeHistory,
        user_type: selectedUserType,
        phone: phoneNumber,
      })
    );
  };

  return (
    <Container
      sx={{
        marginTop: "50px",
        padding: "0px",
      }}
    >
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12}>
            <AdminSearchUserCard
              userRadio={userRadio}
              setUserRadio={setUserRadio}
              setSearchBtnFlag={setSearchBtnFlag}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              setSelectedUserType={setSelectedUserType}
              selectedUserType={selectedUserType}
              setShowSettlementData={setShowSettlementData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AdminSettlementFormCard
              userType={userType}
              userName={userName}
              phoneDetails={phoneDetails}
              emailDetails={emailDetails}
              userId={userId}
              setSearchDateToDateFlag={setSearchDateToDateFlag}
              setFromDate={setFromDate}
              fromDate={fromDate}
              setToDate={setToDate}
              toDate={toDate}
              amountToSettle={amountToSettle}
              setAmountToSettle={setAmountToSettle}
              baseCanPrice={baseCanPrice}
              canInLoss={canInLoss}
              canInProfit={canInProfit}
              neutralCans={neutralCans}
              totalAmount={totalAmount}
              totalCansSold={totalCansSold}
              totalLossEach={totalLossEach}
              totalProfitEach={totalProfitEach}
              setTdsCharges={setTdsCharges}
              setExtraCharges={setExtraCharges}
              setSalesInvoiceFile={setSalesInvoiceFile}
              salesInvoiceFile={salesInvoiceFile}
              setPaymentReciptFile={setPaymentReciptFile}
              paymentReciptFile={paymentReciptFile}
              setUpdateBtnFlag={setUpdateBtnFlag}
              existingWaterDrops={existingWaterDrops}
              requestedWaterDrops={requestedWaterDrops}
              currentWaterDropPrice={currentWaterDropPrice}
              setCurrentWaterDropPrice={setCurrentWaterDropPrice}
              totalSettlementPrice={totalSettlementPrice}
              setTotalSettlementPrice={setTotalSettlementPrice}
              tdsCharges={tdsCharges}
              extraCharges={extraCharges}
              setSettlementPricePerCan={setSettlementPricePerCan}
              settlementPricePerCan={settlementPricePerCan}
              setEmailWfs={setEmailWfs}
              emailWfs={emailWfs}
              userRadio={userRadio}
              setEmailIdError={setEmailIdError}
              emailIdError={emailIdError}
              setShowSettlementData={setShowSettlementData}
              showSettlementData={showSettlementData}
              searchDateToDateFlag={searchDateToDateFlag}
              paymentReciptURL={paymentReciptURL}
            />
          </Grid>

          {historyFlag === true && userRadio === "Settlement History" ? (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <AdminSettlementHistoryCard
                  userType={userType}
                  pageNumberHistory={pageNumberHistory}
                  pageSizeHistory={pageSizeHistory}
                  setPageNumberHistory={setPageNumberHistory}
                  setPageSizeHistory={setPageSizeHistory}
                  selectedUserType={selectedUserType}
                  settlementData={settlementData}
                  settlementHistoryPayURL={settlementHistoryPayURL}
                  settlementHistoryInvoiceURL={settlementHistoryInvoiceURL}
                  handleSearchPageSize={handleSearchPageSize}
                />
              </Grid>
            </>
          ) : (
            ""
          )}

          {
            // requestListFlag === true &&
            userRadio === "settlement_request" ? (
              <>
                {" "}
                <Grid item xs={12} sm={12} md={12}>
                  <AdminSettlementRequestTable
                    setApiFlag1={setApiFlag1}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    setSearchBtnFlag={setSearchBtnFlag}
                    disableBtn={disableBtn}
                    tsStatus={tsStatus}
                    setTsStatus={setTsStatus}
                  />
                </Grid>
              </>
            ) : (
              ""
            )
          }
        </Grid>
      </>
    </Container>
  );
}

export default AdminSettlement;
