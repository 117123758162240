/**
 * @author vinay kumar
 * @description Supplier Change Password
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 01-07-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomConsole from "../../CustomConsole";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SampleButton from "../../Components/Buttons/SampleButton";
import { useDispatch } from "react-redux";
import { PostSupplierChangePassword } from "../Redux/Actions/supliersAction";
import { toast } from "react-toastify";

function ChangePassword() {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [conformNewPassword, setConformNewPassword] = React.useState("");
  const dispatch = useDispatch();
  const [valuesOldPassword, setValuesOldPassword] = React.useState({
    showPassword: false,
  });
  const [valuesNewPassword, setValuesNewPassword] = React.useState({
    showPassword: false,
  });
  const [valuesConformNewPassword, setValuesConformNewPassword] =
    React.useState({
      showPassword: false,
    });

  const handleChangeOldPassword = (event) => {
    CustomConsole(event.target.value);
    setOldPassword(event.target.value);
  };

  const handleChangeNewPassword = (event) => {
    CustomConsole(event.target.value);
    setNewPassword(event.target.value);
  };

  const handleChangeNewConformPassword = (event) => {
    CustomConsole(event.target.value);
    setConformNewPassword(event.target.value);
  };

  const handleSavePassword = () => {
    CustomConsole("----handleSavePassword------");

    if (oldPassword !== "" && newPassword !== "" && conformNewPassword !== "") {
      if (newPassword === conformNewPassword) {
        dispatch(
          PostSupplierChangePassword({
            new_password: newPassword,
            old_password: oldPassword,
          })
        );
      } else {
        toast.error("Your entered InCorrect Conform New Password ", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      setOldPassword("");
      setNewPassword("");
      setConformNewPassword("");
    } else {
      toast.error("Please Fill All Fields ", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleClickShowOldPassword = () => {
    setValuesOldPassword({ showPassword: !valuesOldPassword.showPassword });
  };

  const handleClickShowNewPassword = () => {
    setValuesNewPassword({ showPassword: !valuesNewPassword.showPassword });
  };

  const handleClickShowConfornNewPassword = () => {
    setValuesConformNewPassword({
      showPassword: !valuesConformNewPassword.showPassword,
    });
  };
  return (
    <Material.Box>
      <Material.Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <TextField
          margin="normal"
          required
          //   fullWidth
          name="Old Password"
          label="Old Password"
          type={valuesOldPassword.showPassword ? "text" : "password"}
          id="password"
          onChange={handleChangeOldPassword}
          value={oldPassword}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowOldPassword}
                >
                  {valuesOldPassword.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          //   fullWidth
          name="New Password"
          label="New Password"
          type={valuesNewPassword.showPassword ? "text" : "password"}
          id="password"
          onChange={handleChangeNewPassword}
          value={newPassword}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowNewPassword}
                >
                  {valuesNewPassword.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          //   fullWidth
          name="Conform New password"
          label="Confirm New Password"
          type={valuesConformNewPassword.showPassword ? "text" : "password"}
          id="password"
          onChange={handleChangeNewConformPassword}
          value={conformNewPassword}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowConfornNewPassword}
                >
                  {valuesConformNewPassword.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Material.Box>
      <Material.Box>
        {/* <Material.Button variant="contained" size="large" >
                save
            </Material.Button> */}
        <SampleButton title="Save" handleClick={handleSavePassword} />
      </Material.Box>
    </Material.Box>
  );
}

export default ChangePassword;
