import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Chip,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ScannerRoundedIcon from "@mui/icons-material/ScannerRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { toast } from "react-toastify";
import Html5QrcodePlugin1 from "../../Pages/wfsHome/HtmlQrcodePlugin1";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Required Props for this custom scanner dialog box
// {
//   setOpenScanningDialog;
//   openScanningDialog;
//   scannedQrBarcode;
//   setScannedQrBarcode;
//   currentScanned;
//   setCurrentScanned;
//   scanQtyMax;
//   scanOrderId;
//   scannedCount;
//   setScannedCount;
//   setRequestObject;
//   setOpenLinkFromStartDialog;
// }

export default function ScanningDialog({ title, ...props }) {
  const scanLimit = 5;
  const [scanOption, setScanOption] = React.useState("scanner");
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const handleClose = () => {
    setScanOption("scanner");
    props.setOpenScanningDialog(false);
  };

  // Scanning option change function
  const handleSelectScanOption = (event) => {
    //@Removed/console.log("-- handleSelectScanOption --");
    //@Removed/console.log(event.target.value);
    setScanOption(event.target.value);
  };

  //   Handling QR/Barcode from scanner
  const handleQrBarcodeIdFromScanner = (event) => {
    //@Removed/console.log(event.target.value);
    setQrBarcodeId(event.target.value);

    setTimeout(() => {
      if (event.target.value !== props.scannedQrBarcode) {
        //@Removed/console.log("set code for bulk");
        props.setScannedQrBarcode(event.target.value);
      } else {
        //@Removed/console.log("---code not set for bulk ---");
      }
    }, 500);
  };

  // Handling camera scanned codes
  const handleCameraScannedCode = (decodedText) => {
    //@Removed/console.log("decodedText");
    if (decodedText !== "") {
      props.setScannedQrBarcode(decodedText);
    }
  };
  // If any QR/barcode scanned it will come to this useEffect function
  React.useEffect(() => {
    //@Removed/console.log("Scanned QR/Barcode code");
    //@Removed/console.log(props.scannedQrBarcode);
    //@Removed/console.log("scan qty max", props.scanQtyMax);
    const newOrderId = props.scanOrderId;
    //@Removed/console.log("scan order Id", newOrderId);

    // Check if scanned QR/barcode is in the expired list

    const isExpired = props.validateExpired?.some(
      (code) =>
        code.qr_code === props.scannedQrBarcode ||
        code.bar_code === props.scannedQrBarcode
    );
    if (isExpired) {
      toast.error("Can expired", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      if (props.isBatch) {
        setTimeout(() => {
          props.setScannedQrBarcode("");
          setQrBarcodeId("");
        }, 200);

        return;
      }
    }
    const condition =
      props.scannedQrBarcode && props.scannedQrBarcode.length > 0 && newOrderId
        ? true
        : false;
    //@Removed/console.log("condition");
    //@Removed/console.log(condition);
    if (condition) {
      let qrCodeList =
        props.currentScanned &&
        Object.keys(props.currentScanned).length &&
        props.currentScanned[newOrderId]
          ? props.currentScanned[newOrderId]
          : [];
      //@Removed/console.log(qrCodeList);
      if (qrCodeList.length && qrCodeList.includes(props.scannedQrBarcode)) {
        toast.error(`Can is scanned already!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        //@Removed/console.log("qrCodeList.length <= props.scanQtyMax: ");
        //@Removed/console.log(qrCodeList.length <= props.scanQtyMax);
        if (qrCodeList.length < props.scanQtyMax) {
          qrCodeList = [...qrCodeList, props.scannedQrBarcode];
          props.setScannedCount(qrCodeList.length);
          let localStorageData = {};
          localStorageData[newOrderId] = qrCodeList;
          //@Removed/console.log("localStorageData: ");
          //@Removed/console.log(localStorageData);
          props.setCurrentScanned(() => localStorageData);

          toast.success("Successfully Scanned!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          //@Removed/console.log("WFS Order limit reached!");
          toast.error(`Scan limit reached, please click submit button`, {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
    setTimeout(() => {
      props.setScannedQrBarcode("");
      setQrBarcodeId("");
    }, 200);
  }, [props.scannedQrBarcode]);

  const loadData = () => {
    //@Removed/console.log("Change in order id");
    // Assume `props.orderId` is the current order ID we need to check against
    const storedData = localStorage.getItem("current_scanned_codes");
    const data = storedData ? JSON.parse(storedData) : {};
    //@Removed/console.log("storedData");
    //@Removed/console.log(storedData);
    //@Removed/console.log(props.scanOrderId);
    //@Removed/console.log(data);
    //@Removed/console.log(data[props.scanOrderId]);

    // Check if the current order ID from props matches the one stored in local storage
    if (data && data[props.scanOrderId]) {
      //@Removed/console.log("matching");
      props.setCurrentScanned(data);
      props.setScannedCount(data[props.scanOrderId].length);
    } else {
      // If not matching or not existing, reset the scanned codes
      //@Removed/console.log("not matching");
      props.setCurrentScanned({});
      props.setScannedCount(0);
      // localStorage.setItem("current_scanned_codes", {});
      if (localStorage.getItem("current_scanned_codes")) {
        localStorage.removeItem("current_scanned_codes");
      }
    }
  };
  React.useEffect(() => {
    if (props.scanOrderId) {
      loadData();
    }
  }, [props.scanOrderId]);

  React.useEffect(() => {
    //@Removed/console.log("currentScanned");
    //@Removed/console.log(props.currentScanned);
    //@Removed/console.log("currentScanned && Object.keys(currentScanned).length: ");
    //@Removed/console.log(
    //   props.currentScanned && Object.keys(props.currentScanned).length
    // );
    if (props.currentScanned && Object.keys(props.currentScanned).length) {
      //@Removed/console.log("Setting the data in local storage!");
      //@Removed/console.log(props.currentScanned);
      props.setScannedCount(props.currentScanned[props.scanOrderId].length);
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(props.currentScanned)
      );
    }
  }, [props.currentScanned]);

  const handleClickSubmit = () => {
    const reqObj = {
      order_id: props.scanOrderId,
      data: props.currentScanned[props.scanOrderId]?.map((item) => ({
        id: item,
      })),
    };
    //@Removed/console.log(reqObj);
    setScanOption("scanner");
    props.setRequestObject(reqObj);
    props.setSubmitBtnFlg(true);
  };
  React.useEffect(() => {
    if (props.scannedCount === props.scanQtyMax) {
      console.log("allow");
      const reqObj = {
        order_id: props.scanOrderId,
        data: props.currentScanned[props.scanOrderId]?.map((item) => ({
          id: item,
        })),
      };
      props.setRequestObject(reqObj);
    }
  }, [props.scannedCount, props.scanQtyMax]);

  React.useEffect(() => {
    //@Removed/console.log("scannedCount");
    if (props.scannedCount === scanLimit) {
      //@Removed/console.log("scan limit reached");
      handleClickSubmit();
    }
  }, [props.scannedCount]);

  return (
    <React.Fragment>
      <Dialog
        open={props.openScanningDialog}
        // TransitionComponent={Transition}
        // keepMounted
        // onClose={handleClose}
        // disableBackdropClick={true}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box
            sx={{
              marginTop: "15px",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            {title}
            {props.scanQtyMax - props.scannedCount > 0 ? (
              <>
                <Box>
                  <ToggleButtonGroup
                    color="primary"
                    value={scanOption}
                    exclusive
                    onChange={handleSelectScanOption}
                    aria-label="Platform"
                  >
                    <ToggleButton value="scanner">
                      Scanner <ScannerRoundedIcon />
                    </ToggleButton>
                    <ToggleButton value="camera">
                      Camera <CameraAltRoundedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box>
                  {scanOption === "scanner" && (
                    <TextField
                      onChange={handleQrBarcodeIdFromScanner}
                      label="QR/Barcode:"
                      value={qrBarcodeId}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}

                  {scanOption === "camera" && (
                    <Html5QrcodePlugin1
                      fps={1}
                      qrbox={200}
                      disableFlip={false}
                      qrCodeSuccessCallback={handleCameraScannedCode}
                    />
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}
            <Box>
              <Chip
                label={`Scanned Cans Count: ${props.scannedCount} / ${props.scanQtyMax}`}
                color="success"
              />
            </Box>
            <Box>
              <Button
                onClick={handleClickSubmit}
                variant="contained"
                size="small"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => props.setOpenLinkFromStartDialog(true)}
              >
                Reset Scan
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="error"
                onClick={handleClose}
                variant="contained"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
