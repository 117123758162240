import { Box, Grid, Typography, Container } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import WfsDetailsTable from "./WfsDetailsTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { WfsList } from "../Redux/Actions/adminAddUpdateWfsAction";
import UpdateWfsDetailsCard from "./UpdateWfsDetailsCard";
import { ToastContainer, toast } from "react-toastify";
import { Avatar } from "@mui/material";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import CustomConsole from "../../CustomConsole";
function AddUpdateWFS() {
  const [alignment, setAlignment] = React.useState("1");

  const handleChangeToggle = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  React.useEffect(() => {
    CustomConsole(alignment);
    if (alignment === "2") {
      setAddWfsFlag(true);
    } else if (alignment === "1") {
      setAddWfsFlag(false);
      setOpenWfsTable(false);
    }
  }, [alignment]);
  const dispatch = useDispatch();
  const addUpdateDetails = useSelector((state) => state.adminAddUpdateWfsData);
  const [available, setAvailable] = React.useState(true);
  const [pincode, setPincode] = React.useState("");
  const [wfsDetails, setWfsDetails] = React.useState({});
  const [wfsTabelFlag, setWfsTabelFlag] = React.useState(false);
  const [showDetailsFlag, setShowDetailsFlag] = React.useState(false);

  //params
  const [dateFilter, setDateFilter] = React.useState("DESC");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [assigned, setAssigned] = React.useState(1);
  const [instock, setInstock] = React.useState(0);

  //ADD WFS Flag
  const [addWfsFlag, setAddWfsFlag] = React.useState(false);
  const [triggEffect, setTrigEffect] = React.useState(0);
  const [filterEnable, setFilterEnable] = React.useState(false);
  const [openwfsTable, setOpenWfsTable] = React.useState(false);

  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");
  const [search, setSearch] = React.useState(false);

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  React.useEffect(() => {
    CustomConsole("----addUpdateDetails----");
    CustomConsole(addUpdateDetails);
    // if (addUpdateDetails.wfsData.length === 0 && wfsTabelFlag) {
    //   toast.error("No Data available for this pincode", {
    //     position: "bottom-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",toast.error("No data available for this pincode area")
    // useEffect(() => {
    // }, [])
    //   });
    //   setWfsTabelFlag(false);
    // }
  }, [addUpdateDetails]);

  const handleClickWfs = () => {
    CustomConsole(pincode.length);
    setAddWfsFlag(false);
    if (pincode.length === 6) {
      const obj = {
        pincode: pincode,
        pageNumber: pageNumber - 1,
        isAssigned: assigned,
        availablity: available,
        inStock: instock,
        date: dateFilter,
      };
      dispatch(WfsList(obj));
      CustomConsole(addUpdateDetails);
      setFilterEnable(true);
      setWfsTabelFlag(true);
      setAddWfsFlag(false);
      setOpenWfsTable(true);
    } else if (pincode.length < 6 || pincode === "") {
      CustomConsole("---hitting inside toast---");
      toast.error("Enter valid pincode", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleChange = (event) => {
    setAvailable(event.target.value);
  };
  const handleSearchBtn = () => {
    CustomConsole("search");
    setSearch(true);
    setShowDetailsFlag(false);
  };

  React.useEffect(() => {
    if (search === true) {
      CustomConsole(available);
      CustomConsole(pincode.length);
      setAddWfsFlag(false);
      if (pincode.length === 6) {
        const obj = {
          pincode: pincode,
          pageNumber: pageNumber - 1,
          isAssigned: assigned,
          availablity: available,
          inStock: instock,
          date: dateFilter,
        };
        dispatch(WfsList(obj));
        CustomConsole(addUpdateDetails);
        setFilterEnable(true);
        setWfsTabelFlag(true);
        setAddWfsFlag(false);
        setOpenWfsTable(true);
      } else if (pincode.length < 6 || pincode === "") {
        CustomConsole("---hitting inside toast---");
        toast.error("Enter valid pincode", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setSearch(false);
    }
  }, [search]);

  React.useEffect(() => {
    if (pincode !== "") {
      handleClickWfs();
    }
  }, [pageNumber]);

  const onClickScroll = React.useRef(null);
  const scroll = () => {
    onClickScroll.current?.scrollIntoView({ behavior: "smooth" });
  };

  let addNewWfs = () => {
    setAddWfsFlag(true);
  };

  return (
    <Container sx={{ marginTop: "50px" }}>
      {ADMIN_ROLES.BW_ADMIN === adminRole ||
      ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ||
      ADMIN_ROLES.BW_OPSHEAD === adminRole ||
      ADMIN_ROLES.BW_IVML === adminRole ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "20px",
                    padding: "10px",

                    // borderRadius: "10px",
                    // background: "rgb(208,221,242)",
                    // backgroundImage:
                    //   "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
                  }}
                >
                  {/* <Typography
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Select to check water filling station details
                  </Typography> */}
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChangeToggle}
                    aria-label="Platform"
                  >
                    <ToggleButton value="1">
                      Check filling station details
                    </ToggleButton>
                    <ToggleButton value="2">
                      Add new filling station
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  {addWfsFlag === false && (
                    <>
                      {" "}
                      <Grid item md={6}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item md={6}>
                            <TextField
                              type="number"
                              id="outlined-basic"
                              label="Enter Pincode"
                              variant="outlined"
                              fullWidth
                              value={pincode}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              onChange={(event) =>
                                setPincode(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item md={6}>
                            <FormControl sx={{ minWidth: 210 }}>
                              <InputLabel id="demo-simple-select-label">
                                WFS Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={available}
                                label="Select"
                                onChange={handleChange}
                                // onClick={handleClickWfs}
                              >
                                <MenuItem value={true}>Available</MenuItem>
                                <MenuItem value={false}>Unavailable</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Box sx={{ padding: "10px" }}>
                          <Button variant="contained" onClick={handleSearchBtn}>
                            Search
                          </Button>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {/* <Grid item md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="contained" onClick={addNewWfs}>
                        Add New WFS
                      </Button>
                    </Box>
                  </Grid> */}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    padding: "10px",
                  }}
                ></Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            {addUpdateDetails.wfsData && !addWfsFlag && openwfsTable ? (
              <WfsDetailsTable
                setAddWfsFlag={setAddWfsFlag}
                setWfsDetails={setWfsDetails}
                setTrigEffect={setTrigEffect}
                setDateFilter={setDateFilter}
                setPageNumber={setPageNumber}
                setAssigned={setAssigned}
                setInstock={setInstock}
                instock={instock}
                pageNumber={pageNumber}
                handleClickWfs={handleClickWfs}
                wfsTabelFlag={wfsTabelFlag}
                dateFilter={dateFilter}
                assigned={assigned}
                scroll={scroll}
                triggEffect={triggEffect}
                filterEnable={filterEnable}
                setShowDetailsFlag={setShowDetailsFlag}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            {(Object.keys(wfsDetails).length &&
              addUpdateDetails.wfsData.length > 0 &&
              showDetailsFlag) ||
            addWfsFlag ? (
              <>
                {" "}
                <Box>
                  <UpdateWfsDetailsCard
                    wfsDetails={wfsDetails}
                    triggEffect={triggEffect}
                    addWfsFlag={addWfsFlag}
                    onClickScroll={onClickScroll}
                    handleClickWfs={handleClickWfs}
                    showDetailsFlag={showDetailsFlag}
                  />
                </Box>
              </>
            ) : (
              ""
            )}
            <ToastContainer />
            {/* </Box> */}
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ marginTop: "10px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Container>
  );
}

export default AddUpdateWFS;
