import React from "react";
import * as Material from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { appColors } from "../Assests/AppTheme/appThemeConst";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function PlaceReturnCanDialog(props) {
  const [selectAddress, setSelectAddress] = React.useState("");
  const [filledCanChecked, setFilledCanChecked] = React.useState(false);
  return (
    <Material.Box>
      <Material.Dialog open={props.openReturnCanDialog}>
        <Material.DialogContent>
          <Material.Box sx={{ display: "flex", flexDirection: "column" }}>
            <Material.Box sx={{ margin: "10px" }}>
              <Material.TextField label="Total Cans to be Returned" fullWidth />
            </Material.Box>
            <Material.Box sx={{ margin: "10px" }}>
              <Material.TextField
                label="Filled Cans to be Returned"
                fullWidth
              />
            </Material.Box>
            <Material.Box sx={{ minWidth: 120, margin: "10px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Address</InputLabel>
                <Select
                  value={selectAddress}
                  label="Select Address"
                  onChange={(event) => setSelectAddress(event.target.value)}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Material.Box>
          </Material.Box>
          <Material.Box sx={{ margin: "20px" }}>
            <FormControlLabel
              control={<Checkbox />}
              label="I want to return completely filled can"
            />
          </Material.Box>
          <Material.Box
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Material.Button color="error" variant="contained">
              close
            </Material.Button>
            <Material.Button
              variant="contained"
              sx={{
                background: appColors.commonBtnColor.background,
              }}
            >
              submit
            </Material.Button>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default PlaceReturnCanDialog;
