import React from "react";
import * as Material from "@mui/material";
import moment from "moment";
import { UploadFile } from "../../Pages/Redux/Actions/fileHandlingAction";
import { Snackbar, Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { InputLabel, Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import { UploadQrFile } from "../../Pages/Redux/Actions/fileHandlingAction";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { getFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
function WfsStockDetailsCard(props) {
  CustomConsole(props);
  const [waterQualityRepotFile, setWaterQualityRepotFile] = React.useState("");
  const [uploadWQReprtBtn, setUploadWQReprtBtn] = React.useState(false);
  const [uploadFlag, setUploadFlag] = React.useState(false);
  const [qWRepotUrl, setQWRepotUrl] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [fileToUpload, setFileToUpload] = React.useState("");
  const [isChooseFileOpen, setIsChooseFileOpen] = React.useState(false);
  const [downloadFile, setDownloadFile] = React.useState(
    props.downloadQWReport
  );
  const [downloadQd, setDownloadQa] = React.useState(false);
  CustomConsole(qWRepotUrl);
  //refs
  const uploadFile = React.useRef(null);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);

  const Input = styled("input")({
    display: "none",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  React.useEffect(() => {
    CustomConsole(fileToUpload);
  }, [fileToUpload]);
  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileHandler.error === false) {
      if (fileHandler.fileName !== "") {
        dispatch(UploadQrFile(fileHandler.fileName, props.wfsId));
      }
      if (fileHandler.isFileUploaded === true) {
        CustomConsole("Successfully uploaded file");
        setOpenAlert(true);

        //  setTimeout(()=>{
        //   dispatch(GetWfsDetails());
        // },1000)

        setFileToUpload("");
        setIsChooseFileOpen(false);
      }
    }
  }, [fileHandler]);

  // React.useEffect(() => {
  //   if (downloadQd === true) {
  //     CustomConsole("useEffect getFiles API");
  //     if(downloadFile!==""){
  //       dispatch(getFiles(downloadFile));
  //     }

  //     setDownloadQa(false);
  //   }
  // }, [downloadQd]);

  // const handleDownloadQD = () => {
  //   setDownloadQa(true);
  //   CustomConsole(props.downloadQWReport);

  // };

  // React.useEffect(() => {
  //   CustomConsole(fileHandler);
  //   if (
  //     fileHandler.error === false &&
  //     fileHandler.isFileUploaded === true &&
  //     uploadFlag === true
  //   ) {
  //     toast.success("Water Quality Report File uploaded successfully", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     setUploadFlag(false);
  //   }
  //   if (fileHandler.isFileUploaded === true && uploadWQReprtBtn === true) {
  //     CustomConsole("Water Quality Report file uploaded file here");
  //     setQWRepotUrl(fileHandler.fileName);
  //     setUploadWQReprtBtn(false);
  //   }
  // }, [fileHandler]);
  // React.useEffect(() => {
  //   if (fileHandler.error === false) {
  //     if (qWRepotUrl !== "") {
  //       dispatch(UploadQrFile(qWRepotUrl, props.wfsId));
  //     }
  //   }
  // }, [fileHandler]);
  // React.useEffect(() => {
  //   CustomConsole(`WQReportFile ${waterQualityRepotFile}`);

  //   if (waterQualityRepotFile !== "") {
  //     dispatch(UploadFile(waterQualityRepotFile));
  //     setUploadFlag(true);
  //     setUploadWQReprtBtn(true);
  //   }
  // }, [waterQualityRepotFile]);

  const uploadFn = () => {
    uploadFile.current.click();
  };

  const uploadChange = async (e) => {
    try {
      const selectedFile = e.target.files[0];
      dispatch(UploadFile(selectedFile));
    } catch (error) {}
  };
  return (
    <Material.Box>
      <TableContainer component={Paper} elevation={2}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={12}
                sx={{ fontWeight: "bold" }}
              >
                Stock Details
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>S.no</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Capacity</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Material
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Empty
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Filled
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                OnGoing
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Physically Damaged
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Qr Bar Code Damage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.wfsStockDetails?.assignedWfsStock?.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left"> {row.container_capacity}</TableCell>
                <TableCell align="left"> {row.container_material}</TableCell>
                <TableCell align="center"> {row.empty_containers}</TableCell>
                <TableCell align="center"> {row.filled_containers}</TableCell>
                <TableCell align="center"> {row.ongoing_containers}</TableCell>
                <TableCell align="center">
                  {" "}
                  {row.physically_damaged_containers}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {row.qr_bar_code_damaged_containers}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Material.Box>
  );
}

export default WfsStockDetailsCard;
