/**
 * @author vinay kumar
 * @description Supplier Add Or Update Delivery Agent Dialog Box
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 29-06-2023
 */
// Importing the Libraries and Other Files

import React, { useEffect } from "react";
import * as Material from "@mui/material";
import { styled } from "@mui/material/styles";
import CameraAlt from "@mui/icons-material/CameraAlt";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { InputLabel, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import userLogo from "./../../Assests/Images/userLogo.png";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { appColors } from "../../Assests/AppTheme/appThemeConst";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Button } from "@mui/material";
import {
  UploadFile,
  getFiles,
  ShowImagesInApp,
} from "../../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import moment from "moment";
import {
  GetSupplierEmployeesDetailsAdmin,
  PostSupplierAddDeliveryAgent,
  PutSupplierUpdateDeliveryAgent,
} from "../../../Pages/Redux/Actions/supliersAction";
import { toast } from "react-toastify";
import { employeeType } from "../../AppMeta/appMetaConfig";
import CustomConsole from "../../../CustomConsole";
// import { styled } from "@mui/material/styles";
const ManagerRequiredFields = [
  "aadhaar_no",
  "aadhaar_url",
  "address_line1",
  "address_line2",
  "address_line3",
  "emergency_contact",
  "city",
  "state",
  "phone",
  "phone_code",
  "pincode",
  "fullname",
  "password",
  "dob",
];
const LoadersRequiredFields = [
  "aadhaar_no",
  "aadhaar_url",
  "address_line1",
  "address_line2",
  "address_line3",
  "emergency_contact",
  "city",
  "state",
  "phone",
  "phone_code",
  "pincode",
  "fullname",
  "password",
  "dob",
];
const DriverRequiredFields = [
  "aadhaar_no",
  "address_line1",
  "address_line2",
  "address_line3",
  "badge_no",
  "badge_valid_from",
  "badge_valid_to",
  "emergency_contact",
  "licence_valid_from",
  "license_no",
  "license_valid_to",
  "city",
  "state",
  "phone",
  "phone_code",
  "pincode",
  "fullname",
  "password",
  "badge_url",
  "license_url",
  "aadhaar_url",
  "dob",
];
function AddorUpdateDeliveryAgentDialog({
  singleData,
  isUpdate,
  supplierId,
  openAddDeliveryAgentDialog,
  handleDialogClose,
  employee_type,
  operationType,
  supplierIntegerId,
}) {
  // CustomConsole(props);
  const requiredFields =
    employee_type == employeeType.MANAGER
      ? ManagerRequiredFields
      : employee_type == employeeType.LOADER
      ? LoadersRequiredFields
      : DriverRequiredFields;
  const [drivers, setDrivers] = React.useState();
  const [managers, setManagers] = React.useState();
  const [loaders, setLoaders] = React.useState();
  const [assignedTo, setAssignedTo] = React.useState();
  const [driverId, setDriverId] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [emergencyContact, setEmergencyContact] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [addressLine3, setAddressLine3] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [diverLicense, setDiverLicense] = React.useState("");
  const [driverBadge, setDriverBadge] = React.useState("");
  const [aadhaar, setAadhaar] = React.useState("");
  const [isValid, setIsValid] = React.useState(true);
  const [uploadProfileImg, setUploadProfileImg] = React.useState("");
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [profileImgUrl, setProfileImgUrl] = React.useState("");
  const [uploadProfileImgBtn, setUploadProfileImgBtn] = React.useState(false);
  const [driverBadgeValidFromDate, setDriverBadgeValidFromDate] =
    React.useState(() => moment());
  const [driverBadgeToDate, stdriverBadgeToDate] = React.useState(() =>
    moment()
  );
  const [driverLicenseValidFromDate, setDriverLicenseValidFromDate] =
    React.useState(() => moment());
  const [dateOfBirth, setDateOfBirth] = React.useState(() =>
    moment().subtract(18, "years")
  );
  const [driverLicenseValidToDate, setDriverLicenseValidToDate] =
    React.useState(() => moment());
  const [licenceValidFromDate, setLicenceValidFromDate] = React.useState(() =>
    moment()
  );
  const [dateOfBirthUser, setDateOfBirthUser] = React.useState(() => moment());
  const [licenseValidToDate, setLicenseValidToDate] = React.useState(() =>
    moment()
  );
  const [badgeValidFromDate, setBadgeValidFromDate] = React.useState(() =>
    moment()
  );
  const [badgeValidToDate, setBadgeValidToDate] = React.useState(() =>
    moment()
  );
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [phoneNumberError, setPhoneNumberError] = React.useState("");

  const [aadharNumEr, setAAdharNumEr] = React.useState(false);
  const [emergrncyNumberError, setEmergrncyNumberError] = React.useState("");
  const isSubmitDisabled = phoneNumber === emergencyContact;

  // const handleChangePassword = (event) => {
  //   CustomConsole(event.target.value);
  //   setPassword(event.target.value);
  // };
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  React.useEffect(() => {
    let managers = [];
    let drivers = [];
    let loaders = [];
    setAssignedTo("");
    let warehouseDrivers = supplierDeliveries?.warehouseEmployees?.drivers;
    let warehouseManagers = supplierDeliveries?.warehouseEmployees?.managers;
    let warehouseLoaders = supplierDeliveries?.warehouseEmployees?.loaders;
    supplierDeliveries?.employees?.map((item) => {
      if (item.employee_type == employeeType.MANAGER) {
        managers.push({
          id: item.user_id,
          name: item.driver_profile?.fullname,
          is_selected: warehouseManagers?.includes(item.user_id),
        });
      }
      if (item.employee_type == employeeType.DRIVER) {
        drivers.push({
          id: item.user_id,
          name: item.driver_profile?.fullname,
          is_selected: warehouseDrivers?.includes(item.user_id),
        });
      }
      if (item.employee_type == employeeType.LOADER) {
        if (item.user_id == assignedTo) {
          setAssignedTo(item.user_id);
        }
        loaders.push({
          id: item.user_id,
          name: item.driver_profile?.fullname,
          is_selected: warehouseLoaders?.includes(item.user_id),
        });
      }
    });
    CustomConsole(drivers, ":drivers", managers, ":managers");
    setManagers(managers);
    setDrivers(drivers);
    setLoaders(loaders);
  }, [supplierDeliveries]);
  useEffect(() => {
    dispatch(GetSupplierEmployeesDetailsAdmin({ supplier_id: supplierId }));
  }, []);
  const [uploadFlag, setUploadFlag] = React.useState(false);

  //urls
  const [driverBadgeUrl, setDriverBadgeUrl] = React.useState("");
  const [driverLicenseUrl, setDriverLicenseUrl] = React.useState("");
  const [licencestore, setLicencestore] = React.useState("");
  const [bagdetore, setBagdetore] = React.useState("");
  const [aadhaarstore, setAadhaarstore] = React.useState("");

  CustomConsole(licencestore);
  const [aadharUrl, setAadharUrl] = React.useState("");
  CustomConsole(driverBadgeUrl);
  CustomConsole(driverLicenseUrl);
  CustomConsole(aadharUrl);
  CustomConsole(profileImgUrl);

  //uploadBtns
  const [uploadAadharBtn, setUploadAadharBtn] = React.useState(false);
  const [uploadDriverLicenseBtn, setUploadDriverLicenseBtn] =
    React.useState(false);
  const [uploadDriverBadgeBtn, setUploadDriverBadgeBtn] = React.useState(false);

  //files
  const [aadharFile, setAadharFile] = React.useState("");
  const [driverLicenseFile, setDriverLicenseFile] = React.useState("");
  const [driverBadgeFile, setDriverBadgeFile] = React.useState("");

  const profilePic = useSelector((state) => state.fileHandler);
  CustomConsole(aadharFile);
  CustomConsole(driverLicenseFile);
  CustomConsole(driverBadgeFile);

  const Input = styled("input")({
    display: "none",
  });
  React.useEffect(() => {
    CustomConsole("---- data ----");
    CustomConsole(profilePic);
  }, [profilePic]);

  React.useEffect(() => {
    CustomConsole("------dffffffff---------");
    // CustomConsole(props);
    if (
      singleData !== undefined &&
      singleData !== null &&
      singleData?.driver_profile !== undefined &&
      singleData?.driver_profile.profile_pic_url != undefined &&
      singleData?.driver_profile.profile_pic_url.length
    ) {
      // dispatch(getFiles(singleData?.driver_profile.profile_pic_url));

      dispatch(ShowImagesInApp(singleData?.driver_profile?.profile_pic_url));
    }
  }, []);

  React.useEffect(() => {
    // CustomConsole(props);
    if (isUpdate === true) {
      setDriverId(singleData?.driver_id);
      setFullName(singleData?.driver_profile?.fullname);
      setPassword(singleData?.driver_profile?.password);
      setPhoneNumber(singleData?.driver_profile?.phone);
      setEmergencyContact(singleData?.driver_profile?.emergency_contact);
      setAddressLine1(singleData?.driver_profile?.address_line1);
      setAddressLine2(singleData?.driver_profile?.address_line2);
      setAddressLine3(singleData?.driver_profile?.address_line3);
      setAssignedTo(singleData?.details?.assigned_to);
      setCity(singleData?.driver_profile?.city);
      setState(singleData?.driver_profile?.state);
      setPincode(singleData?.driver_profile?.pincode);
      setDiverLicense(singleData?.driver_profile?.license_no);
      setDateOfBirth(singleData?.driver_profile?.dob);
      setDriverLicenseUrl(singleData?.driver_profile?.license_url);
      setDriverBadge(singleData?.driver_profile?.badge_no);
      setDriverBadgeUrl(singleData?.driver_profile?.badge_url);
      setAadhaar(singleData?.driver_profile?.aadhaar_no);
      setAadharUrl(singleData?.driver_profile?.setAadharUrl);
      setAadhaarstore(singleData?.driver_profile?.aadhaar_url);
      // setDriverLicenseFile(singleData?.driver_profile?.license_url);
      setLicencestore(singleData?.driver_profile?.license_url);
      setBagdetore(singleData?.driver_profile?.badge_url);
      stdriverBadgeToDate(singleData?.driver_profile?.badge_valid_to);
      setDriverBadgeValidFromDate(singleData?.driver_profile?.badge_valid_from);
      setDriverLicenseValidToDate(singleData?.driver_profile?.license_valid_to);
      setDriverLicenseValidFromDate(
        singleData?.driver_profile?.licence_valid_from
      );
      setProfileImgUrl(singleData?.driver_profile?.profile_pic_url);
    }
  }, [supplierDeliveries]);

  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileHandler.error === false && fileHandler.isFileUploaded === true) {
      if (uploadDriverLicenseBtn === true) {
        setDriverLicenseUrl(fileHandler.fileName);
        setUploadDriverLicenseBtn(false);
        toast.success("Driver License  file Uploaded", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (uploadDriverBadgeBtn === true) {
        setDriverBadgeUrl(fileHandler.fileName);
        setUploadDriverBadgeBtn(false);
        toast.success("Driver Badge file Uploaded", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (uploadAadharBtn === true) {
        setAadharUrl(fileHandler.fileName);
        setUploadAadharBtn(false);
        toast.success(operationType?.name + " Aadhar file Uploaded", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      CustomConsole("Error while uploading");
    }
    if (fileHandler.isFileUploaded === true && uploadProfileImgBtn === true) {
      CustomConsole("Aadhaar file uploaded file here");
      setProfileImgUrl(fileHandler.fileName);
      setUploadProfileImgBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("image uploaded successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUploadFlags(false);
    }
  }, [fileHandler]);

  React.useEffect(() => {
    CustomConsole(`driverLicenseFile ${driverLicenseFile}`);
    CustomConsole(`driverBadgeFile ${driverBadgeFile}`);
    CustomConsole(`aadharFile ${aadharFile}`);
    CustomConsole(`driverBadgeUrl ${driverBadgeUrl}`);
    if (driverLicenseFile !== "") {
      dispatch(UploadFile(driverLicenseFile));
      setUploadDriverLicenseBtn(true);
      setDriverLicenseFile("");
    } else if (driverBadgeFile !== "") {
      dispatch(UploadFile(driverBadgeFile));
      setUploadDriverBadgeBtn(true);
      setDriverBadgeFile("");
    } else if (aadharFile !== "") {
      dispatch(UploadFile(aadharFile));
      setUploadAadharBtn(true);
      setAadharFile("");
    }
  }, [driverLicenseFile, driverBadgeFile, aadharFile]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //Refs to trigger upload file
  const DriverLicenseRef = React.useRef(null);
  const DriverBadgeRef = React.useRef(null);
  const DriverAadharRef = React.useRef(null);

  //Upload Driver license File
  const handleUploadDriverLicense = () => {
    DriverLicenseRef.current.click();
  };

  const handleUploadDriverBadge = () => {
    DriverBadgeRef.current.click();
  };

  const handleUploadDriverAadhar = () => {
    DriverAadharRef.current.click();
  };

  const handleChangeLicenseValidFrom = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setLicenceValidFromDate(() => moment(newValue.$d));
      setDriverLicenseValidFromDate(newValue);
      CustomConsole(newValue.$d);
    }
  };
  const handleChangeDateOfBirth = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      // const age = // Assuming newValue.$d contains the birthdate
      // const age = moment().diff(moment(newValue.$d), "years");

      // console.log();
      // if (age < 18) {
      //   toast.error("Age Should be greater than 18");
      //   return;
      // }
      setDateOfBirthUser(() => moment(newValue.$d));
      setDateOfBirth(newValue.$d);
      CustomConsole(newValue.$d);
    }
  };
  const handleChangeLicenseValidToDate = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setLicenseValidToDate(() => moment(newValue.$d));
      setDriverLicenseValidToDate(newValue);
      CustomConsole(newValue.$d);
    }
  };

  const handleChangeBadgeValidFromDate = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setBadgeValidFromDate(() => moment(newValue.$d));
      setDriverBadgeValidFromDate(newValue);
      CustomConsole(newValue.$d);
    }
  };

  const handleChangeBadgeValidToDate = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setBadgeValidToDate(() => moment(newValue.$d));
      stdriverBadgeToDate(newValue);
      CustomConsole(newValue.$d);
    }
  };

  const handleChandgeDriverLicense = (event) => {
    const inputLicenseNumber = event.target.value;
    setDiverLicense(inputLicenseNumber);
    // Regular expression pattern for driver's license number validation
    const licenseNumberPattern = /^[A-Z0-9]{15,18}$/;

    setIsValid(licenseNumberPattern.test(inputLicenseNumber));
  };

  const validatePhoneNumber = (input) => {
    const phoneRegex = /^[6-9]{1}[0-9]{9}$/;
    if (!input.match(phoneRegex)) {
      setPhoneNumberError("Invalid phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  const validateEmergencyContact = (input) => {
    const phoneRegex = /^[6-9]{1}[0-9]{9}$/;
    if (!input.match(phoneRegex)) {
      setEmergrncyNumberError("phone no and Emergency no should be different");
    } else {
      setEmergrncyNumberError("");
    }
    if (phoneNumber === input) {
      setEmergrncyNumberError("phone no and Emergency no should be different");
    }
  };

  const handleCancle = () => {
    CustomConsole("----------Cancle---------");
    handleDialogClose();
  };

  const handlePhoneNumberChange = (event) => {
    if (event.target.value >= "6") setPhoneNumber(event.target.value);
    validatePhoneNumber(event.target.value);
  };

  const handleEmergencyPhoneNumberChange = (event) => {
    if (event.target.value >= "6") setEmergencyContact(event.target.value);
    validateEmergencyContact(event.target.value);
  };

  const handleAddDeliveryAgent = () => {
    CustomConsole("--------handleUpdateDeliveryAgent---------");
    let reqObj = {
      aadhaar_no: aadhaar,
      employee_type: employee_type,
      aadhaar_url: aadharUrl,
      assigned_to: assignedTo,
      address_line1: addressLine1,
      address_line2: addressLine2,
      address_line3: addressLine3,
      badge_no: driverBadge,
      badge_url: driverBadgeUrl,
      badge_valid_from: badgeValidFromDate,
      badge_valid_to: badgeValidToDate,
      emergency_contact: emergencyContact,
      licence_valid_from: licenceValidFromDate,
      license_no: diverLicense,
      license_url: driverLicenseUrl,
      license_valid_to: licenseValidToDate,
      supplier_id: supplierIntegerId,
      dob: moment(dateOfBirth).format("YYYY-MM-DD"),
      city: city,
      state: state,
      phone: phoneNumber,
      phone_code: +91,
      pincode: pincode,
      profile_pic_url: profileImgUrl,
      fullname: fullName,
      password: password,
    };
    let text;
    if (aadharNumEr) {
      text = "Please Enter valid Aadhar number";
    }
    if (emergrncyNumberError || phoneNumberError) {
      text = "Please Enter Valid Phone number";
    }
    if (reqObj.password) {
      let password_regex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
      if (!password_regex.test(reqObj.password)) {
        text = "Please Enter Strong Password";
      }
    }
    // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/
    Object.keys(reqObj).forEach((key) => {
      if (requiredFields.includes(key) && reqObj[key] == "") {
        // ? Aadhar url should't be empty makes no sence to user . so replace url as file
        let field = key.split("_");
        field[field.length - 1] == "url"
          ? (field[field.length - 1] = "file")
          : (field[field.length - 1] = field[field.length - 1]);
        text = `${field.join(" ")} should not be empty`;
      }
    });
    if (isUpdate === true) {
      if (!text) {
        CustomConsole(reqObj);
        dispatch(PutSupplierUpdateDeliveryAgent(driverId, reqObj)).then(() => {
          handleDialogClose();
        });
      } else {
        toast.error(text, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      CustomConsole("--------handleAddDeliveryAgent---------");

      if (!text) {
        let reqObj = {
          aadhaar_no: aadhaar,
          assigned_to: assignedTo,
          aadhaar_url: aadharUrl,
          address_line1: addressLine1,
          address_line2: addressLine2,
          address_line3: addressLine3,
          employee_type: employee_type,
          badge_no: driverBadge,
          badge_url: driverBadgeUrl,
          supplier_id: supplierIntegerId,
          dob: moment(dateOfBirth).format("YYYY-MM-DD"),
          badge_valid_from: badgeValidFromDate,
          badge_valid_to: badgeValidToDate,
          emergency_contact: emergencyContact,
          licence_valid_from: licenceValidFromDate,
          license_no: diverLicense,
          license_url: driverLicenseUrl,
          license_valid_to: licenseValidToDate,
          city: city,
          state: state,
          phone: phoneNumber,
          phone_code: +91,
          pincode: pincode,
          profile_pic_url: profileImgUrl,
          fullname: fullName,
          password: password,
          supplier_id: supplierId,
        };
        CustomConsole(reqObj);
        dispatch(PostSupplierAddDeliveryAgent(reqObj)).then(() => {
          handleDialogClose();
        });
      } else {
        toast.error(text, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (supplierDeliveries === false) {
        setFullName("");
        setPassword("");
        setPhoneNumber("");
        setEmergencyContact("");
        setAddressLine1("");
        setAddressLine2("");
        setAddressLine3("");
        setCity("");
        setState("");
        setPincode("");
        setDiverLicense("");
        setDriverBadge("");
        setAadhaar("");
        setAadharFile("");
        setDriverLicenseFile("");
        setDriverBadgeFile("");
        setBadgeValidToDate(() => moment());
        setBadgeValidFromDate(() => moment());
        setLicenseValidToDate(() => moment());
        setLicenceValidFromDate(() => moment());
      }
    }
  };
  React.useEffect(() => {
    if (uploadProfileImg !== "") {
      dispatch(UploadFile(uploadProfileImg));
      setUploadFlags(true);
      setUploadProfileImgBtn(true);
    }
  }, [uploadProfileImg]);
  const handleUploadAadhaarFile = (e) => {
    CustomConsole(e.target.files[0]);
    setUploadProfileImg(e.target.files[0]);
  };

  return (
    <Material.Box>
      <Material.Dialog open={openAddDeliveryAgentDialog}>
        <Material.DialogTitle>
          {isUpdate ? (
            <Material.Typography
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              Update {operationType?.name} Here
            </Material.Typography>
          ) : (
            <Material.Typography
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              Create {operationType?.name} Here
            </Material.Typography>
          )}
        </Material.DialogTitle>
        <Material.DialogContent>
          <Material.Grid container spacing={2}>
            <Material.Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              md={12}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <Avatar sx={{ height: "40px", width: "40px" }}>
                    <InputLabel htmlFor="import-button1">
                      <Input
                        id="import-button1"
                        type="file"
                        onChange={handleUploadAadhaarFile}
                      />
                      <Tooltip title="Upload Image">
                        <CameraAlt sx={{ height: "30px", width: "30px" }} />
                      </Tooltip>
                    </InputLabel>
                  </Avatar>
                }
              >
                {/* <Avatar
                  sx={{ height: "100px", width: "100px" }}
                  alt="Travis Howard"
                  src={
                    profileImgUrl !== undefined &&
                    profileImgUrl !== "" &&
                    profileImgUrl !== null
                      ? profileImgUrl
                      : null
                  }
                /> */}
                <img
                  style={{ height: "100px", width: "100px" }}
                  src={
                    singleData !== undefined &&
                    singleData !== null &&
                    singleData?.driver_profile !== undefined &&
                    singleData?.driver_profile.profile_pic_url &&
                    singleData?.driver_profile.profile_pic_url !== undefined &&
                    profilePic.filePath !== undefined &&
                    profilePic.filePath !== null &&
                    profilePic.filePath !== ""
                      ? profilePic.filePath
                      : userLogo
                  }
                  alt=""
                />
              </Badge>
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                sx={{ marginTop: "13px" }}
                required
                label="Full Name"
                variant="filled"
                value={fullName}
                onChange={(event) => setFullName(event.target.value)}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <TextField
                margin="normal"
                required
                // fullWidth
                name="Password"
                label="Password"
                type={values.showPassword ? "text" : "password"}
                id="password"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() =>
                          setValues({ showPassword: !values.showPassword })
                        }
                      >
                        {values.showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="Phone Number "
                variant="filled"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                error={Boolean(phoneNumberError)}
                helperText={phoneNumberError}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="Emergency Phone"
                variant="filled"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                error={Boolean(emergrncyNumberError)}
                helperText={emergrncyNumberError}
                value={emergencyContact}
                onChange={handleEmergencyPhoneNumberChange}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="Address Line 1"
                variant="filled"
                value={addressLine1}
                onChange={(event) => setAddressLine1(event.target.value)}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="Address Line 2"
                variant="filled"
                value={addressLine2}
                onChange={(event) => setAddressLine2(event.target.value)}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="Address Line 3"
                variant="filled"
                value={addressLine3}
                onChange={(event) => setAddressLine3(event.target.value)}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="City"
                variant="filled"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="State"
                variant="filled"
                value={state}
                onChange={(event) => setState(event.target.value)}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <Material.TextField
                required
                label="Pincode"
                variant="filled"
                type="number"
                value={pincode}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                }}
                onChange={(event) => setPincode(event.target.value)}
              />
            </Material.Grid>
            {requiredFields.includes("license_no") && (
              <Material.Grid item md={10}>
                <Material.TextField
                  fullWidth
                  required
                  label="Driver License"
                  variant="filled"
                  value={diverLicense}
                  onChange={handleChandgeDriverLicense}
                  error={!isValid}
                  helperText={!isValid ? "Invalid driver license number" : ""}
                />
              </Material.Grid>
            )}
            {requiredFields.includes("license_no") && (
              <Material.Grid item md={2}>
                {/* <Box sx={{ margin: "14px" }}>
                <InputLabel htmlFor="import-button1">
                  <Input
                    id="import-button1"
                    type="file"
                    onChange={handleUploadDriverLicense}
                  />
                  <Tooltip title="Upload Diver License">
                    <FileUploadRoundedIcon sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </InputLabel>
              </Box> */}
                <Box>
                  <Button
                    onClick={handleUploadDriverLicense}
                    variant="outlined"
                  >
                    Upload License
                    <input
                      type="file"
                      ref={DriverLicenseRef}
                      style={{ display: "none" }}
                      onChange={(e) => setDriverLicenseFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Material.Grid>
            )}
            {/* <Material.Grid item md={2}>
              <Box sx={{ margin: "10px" }}>
                <VisibilityRoundedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => dispatch(getFiles(licencestore))}
                />
              </Box>
            </Material.Grid> */}
            {requiredFields.includes("license_no") && (
              <Material.Grid item={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Driver License Valid From Date"
                    inputFormat="DD/MM/YYYY"
                    value={driverLicenseValidFromDate}
                    onChange={handleChangeLicenseValidFrom}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Material.Grid>
            )}
            {requiredFields.includes("license_no") && (
              <Material.Grid item={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Driver License Valid To Date"
                    inputFormat="DD/MM/YYYY"
                    value={driverLicenseValidToDate}
                    onChange={handleChangeLicenseValidToDate}
                    maxDate={null}
                    minDate={
                      driverLicenseValidFromDate
                        ? driverLicenseValidFromDate
                        : null
                    }
                    disabled={!driverLicenseValidFromDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Material.Grid>
            )}
            {requiredFields.includes("license_no") && (
              <Material.Grid item md={10}>
                <Material.TextField
                  fullWidth
                  required
                  label="Driver Badge"
                  variant="filled"
                  value={driverBadge}
                  onChange={(event) => setDriverBadge(event.target.value)}
                />
              </Material.Grid>
            )}
            {requiredFields.includes("license_no") && (
              <Material.Grid item md={2}>
                {/* <Box sx={{ margin: "14px" }}>
                <InputLabel htmlFor="import-button2">
                  <Input
                    id="import-button2"
                    type="file"
                    onChange={handleUploadDriverBadge}
                  />
                  <Tooltip title="Upload Diver Badge">
                    <FileUploadRoundedIcon sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </InputLabel>
              </Box> */}
                <Box>
                  <Button onClick={handleUploadDriverBadge} variant="outlined">
                    Upload Badge
                    <input
                      type="file"
                      ref={DriverBadgeRef}
                      style={{ display: "none" }}
                      onChange={(e) => setDriverBadgeFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Material.Grid>
            )}
            {/* <Material.Grid item md={2}>
              <Box sx={{ margin: "10px" }}>
                <VisibilityRoundedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => dispatch(getFiles(bagdetore))}
                />
              </Box>
            </Material.Grid> */}
            {requiredFields.includes("license_no") && (
              <Material.Grid item={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Driver Badge Valid From Date"
                    inputFormat="DD/MM/YYYY"
                    value={driverBadgeValidFromDate}
                    onChange={handleChangeBadgeValidFromDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Material.Grid>
            )}
            {requiredFields.includes("license_no") && (
              <Material.Grid item={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="driver Badge Valid To Date"
                    inputFormat="DD/MM/YYYY"
                    value={driverBadgeToDate}
                    onChange={handleChangeBadgeValidToDate}
                    maxDate={null}
                    minDate={
                      driverBadgeValidFromDate ? driverBadgeValidFromDate : null
                    }
                    disabled={!driverBadgeValidFromDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Material.Grid>
            )}
            {operationType.employee_type == employeeType.LOADER && (
              <Material.Grid item md={6}>
                <Material.FormControl fullWidth>
                  <Material.InputLabel
                    id="area-select-small"
                    error={assignedTo ? false : true}
                    // required
                  >
                    Select Driver
                  </Material.InputLabel>
                  <Material.Select
                    labelId="area-select-small"
                    id="area-select-small"
                    label="Select Driver"
                    // style={{ width: 210 }}
                    fullWidth
                    // options={serviceAreas}
                    // defaultValue={details?.service_area}
                    value={assignedTo}
                    onChange={(event) => setAssignedTo(event.target.value)}
                  >
                    {drivers?.map((option) => {
                      return (
                        <Material.MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </Material.MenuItem>
                      );
                    })}
                  </Material.Select>
                </Material.FormControl>
              </Material.Grid>
            )}
            {/* <Material.Grid item={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date Of Birth"
                  inputFormat="DD/MM/YYYY"
                  value={dateOfBirth}
                  minDate={moment().subtract(500, "years")}
                  maxDate={moment().subtract(18, "years")}
                  onChange={handleChangeDateOfBirth}
                  renderInput={(params) => <TextField disabled {...params} />}
                />
              </LocalizationProvider>
            </Material.Grid> */}
            <Material.Grid
              item
              md={operationType.employee_type === employeeType.LOADER ? 10 : 4}
            >
              <Material.TextField
                fullWidth
                required
                label="Aadhaar Number"
                variant="filled"
                value={aadhaar}
                error={aadharNumEr}
                onChange={(e) => {
                  const isValidAadharNumber =
                    /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(e.target.value);
                  setAadhaar(e.target.value);
                  setAAdharNumEr(!isValidAadharNumber);
                }}
              />
            </Material.Grid>

            <Material.Grid item md={2}>
              <Box>
                <Button onClick={handleUploadDriverAadhar} variant="outlined">
                  Upload Aadhar
                  <input
                    type="file"
                    ref={DriverAadharRef}
                    style={{ display: "none" }}
                    onChange={(e) => setAadharFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Material.Grid>
          </Material.Grid>
        </Material.DialogContent>
        <Material.DialogActions>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handleCancle}
            sx={{
              background: appColors.commonBtnColor.background,
              borderRadius: "20px",
            }}
          >
            Cancel
          </Material.Button>

          <Material.Button
            variant="contained"
            size="small"
            onClick={handleAddDeliveryAgent}
            disabled={isSubmitDisabled}
            sx={{
              marginRight: "10px",
              background: isSubmitDisabled
                ? "gray"
                : appColors.commonBtnColor.background,
              color: isSubmitDisabled ? "black" : "white",
              borderRadius: "20px",
            }}
          >
            {isUpdate ? "Update " : "Add "}
            {operationType?.name}
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </Material.Box>
  );
}

export default AddorUpdateDeliveryAgentDialog;
