import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, TextField } from "@mui/material";
import { ExtraEmpty } from "../Redux/Actions/supliersAction";
import { GetNewSearchOrderDetails } from "../Redux/Actions/ordersAdminAction";
import moment from "moment/moment";
import DeleteIcon from "@mui/icons-material/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ExtraEmptyReturn({ open, setOpen, setReq, reqObj }) {
  //   const [open, setOpen] = React.useState(false);
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);

  const dispatch = useDispatch();
  const [quantity, setQuantity] = React.useState(0);
  const [pdwOrders, setPdwOrders] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setQuantity(inputValue);
    }
  };

  const submitExtraReturn = () => {
    let bodyParam = {
      ...reqObj,
      quantity: quantity,
    };
    dispatch(ExtraEmpty(bodyParam));
  };

  const handleDeleteExtra = () => {
    let bodyParam = {
      ...reqObj,
    };
    dispatch(ExtraEmpty(bodyParam));
  };

  React.useEffect(() => {
    //@Removed/console.log("----Order Data--");
    //@Removed/console.log(orderDetailsData);
    //@Removed/console.log(orderDetailsData.orderDetails);
    if (
      orderDetailsData?.orderDetails &&
      Object.keys(orderDetailsData.orderDetails).length > 0
    ) {
      setPdwOrders(orderDetailsData.orderDetails);
    }
  }, [orderDetailsData, reqObj]);

  React.useEffect(() => {
    if (pdwOrders && pdwOrders?.extra_return_products?.length > 0) {
      const productExtraQty = pdwOrders.extra_return_products
        ? pdwOrders.extra_return_products.filter(
            (obj) =>
              obj.material === reqObj.material &&
              obj.capacity === reqObj.capacity
          )
        : [];
      //@Removed/console.log("----Extra Qty---");
      //@Removed/console.log(productExtraQty);

      if (productExtraQty?.[0]) {
        setQuantity(productExtraQty[0].quantity);
      }
    }
    if (pdwOrders && pdwOrders?.extra_return_products?.length === 0) {
      setQuantity(0);
    }
  }, [pdwOrders]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Enter The Extra Return Qty."}</DialogTitle>
        <DialogContent>
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              label="Enter a number"
              type="number"
              value={quantity}
              onChange={handleInputChange}
              InputProps={{
                inputProps: {
                  pattern: "\\d*",
                  inputMode: "numeric",
                },
              }}
            />
            <IconButton onClick={handleDeleteExtra} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={submitExtraReturn}>Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ExtraEmptyReturn;
