import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { GetSupplierDetailsList } from "../../Pages/Redux/Actions/supplierVehiclesActions";
import { useDispatch } from "react-redux";
import {
  EditBuyBackOrderDetails,
  GetBuyBackOrderDetails,
} from "../../Pages/Redux/Actions/buyBackOrdersAction";

function EditBuyBackOrderOrderDialog({ open, setOpen, editData }) {
  const dispatch = useDispatch();
  const [editDetails, setEditDetails] = React.useState({
    order_id: "",
    products: [],
  });

  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [supplierDetails, setSupplierDetails] = React.useState([]);

  //useeffect to get supplier details
  React.useEffect(() => {
    dispatch(GetSupplierDetailsList()).then((data) => {
      setSupplierDetails(data?.data);
    });
  }, []);
  // Function to handle adding quantity (insert if first time)
  const handelAddQty = (product) => {
    setEditDetails((prevDetails) => {
      const productExists = prevDetails.products.find(
        (data) => data.id === product.id
      );

      if (productExists) {
        return {
          ...prevDetails,
          products: prevDetails.products.map((data) =>
            data.id === product.id
              ? { ...data, order_qty: data.order_qty + 1 }
              : data
          ),
        };
      } else {
        return {
          ...prevDetails,
          products: [...prevDetails.products, { ...product, order_qty: 1 }],
        };
      }
    });
  };

  // Function to handle reducing quantity (insert if first time)
  const handelReduseQty = (product) => {
    setEditDetails((prevDetails) => {
      const productExists = prevDetails.products.find(
        (data) => data.id === product.id
      );

      if (productExists) {
        // If product exists and qty is greater than 1, reduce the quantity
        return {
          ...prevDetails,
          products: prevDetails.products.map((data) =>
            data.id === product.id && data.order_qty > 1
              ? { ...data, order_qty: data.order_qty - 1 }
              : data
          ),
        };
      } else {
        return {
          ...prevDetails,
          products: [...prevDetails.products, { ...product, order_qty: 1 }],
        };
      }
    });
  };

  // Function to handle manual quantity change
  const handelQtyChange = (product, value) => {
    const newQty = parseInt(value, 10);
    if (newQty >= 1) {
      setEditDetails((prevDetails) => ({
        ...prevDetails,
        products: prevDetails.products.map((data) =>
          data.id === product.id ? { ...data, order_qty: newQty } : data
        ),
      }));
    }
  };

  React.useEffect(() => {
    setEditDetails({
      ...editDetails,
      products: editData?.order_details?.products,
      order_id: editData?.order_details?.order_id,
    });
  }, [editData]);

  //function to close dialog
  const handelClose = () => {
    setOpen(false);
  };

  //function to handel supplier change
  const handelSupplierChange = (e) => {
    setSelectedSupplier(e.target.value);
  };
  console.log(editDetails);

  //function to handel supplier select
  React.useEffect(() => {
    if (selectedSupplier !== null) {
      setEditDetails({
        ...editDetails,
        supplier_id: selectedSupplier,
      });
    }
  }, [selectedSupplier]);

  //function to handel submit
  const handleSubmit = () => {
    dispatch(EditBuyBackOrderDetails(editDetails)).then((data) => {
      if (!data.error) {
        setOpen(false);
        dispatch(GetBuyBackOrderDetails());
      }
    });
  };
  return (
    <Dialog open={open}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: "10px" }}>
          <Typography>Edit Order</Typography>
        </Box>
        {editDetails?.products?.map((data) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: ".5rem",
            }}
            key={data?.id}
          >
            <Typography sx={{ textAlign: "start" }}>
              {`${data?.container_capacity}-${data?.container_material}`} =
            </Typography>
            <OrderButtonSku
              handelAddQuantity={() => handelAddQty(data)}
              handelMinusQuantity={() => handelReduseQty(data)}
              handelQuantityChange={() => handelQtyChange(data)}
              inputValue={parseInt(data?.order_qty)}
            />
          </Box>
        ))}

        <Box>
          <Typography>Edit Supplier</Typography>

          <FormControl fullWidth>
            <InputLabel sx={{ marginTop: "2px" }} id="demo-simple-select-label">
              Supplier
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              label="Supplier"
              onChange={handelSupplierChange}
            >
              {supplierDetails?.map((obj, idx) => {
                if (obj?.supplier_name !== null) {
                  return (
                    <MenuItem
                      value={obj?.id}
                      sx={{ fontSize: "0.69rem" }}
                      key={idx}
                    >
                      {`${obj?.supplier_profile?.company_name}`}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          {" "}
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={handelClose}
          >
            Close
          </Button>
          <Button size="small" variant="contained" onClick={handleSubmit}>
            submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditBuyBackOrderOrderDialog;
