import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import TextField from "@mui/material/TextField";
import CustomConsole from "../../CustomConsole";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  DownloadEmailInvoice,
  DownloadInvoice,
} from "../Redux/Actions/ordersAdminAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";

const styles = {
  typographyStylesHeading: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  typographyStylesRes: {
    fontSize: "13px",
  },
};

export default function UpdatedUserDetails({ orderDetails, newOrderDetails }) {
  const dispatch = useDispatch();
  CustomConsole("----order details---Updated user details----");
  CustomConsole(orderDetails);
  return (
    <Card sx={{ height: { md: "340px", xs: "100%" } }} elevation={20}>
      <CardContent>
        <Box
          sx={{
            bgcolor: "#152057",
            color: "white",
            padding: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Last Updated By
          </Typography>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                textAlign: "start",
                color: "blue",
                fontWeight: "bold",
              }}
            >
              Email :{" "}
            </Typography>

            <Typography
              sx={{
                display: "flex",
                textAlign: "end",
                fontWeight: "bold",
              }}
            >
              {newOrderDetails[0]?.updated_by_email !== null
                ? newOrderDetails[0]?.updated_by_email
                : "- - -"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                textAlign: "start",
                color: "blue",
                fontWeight: "bold",
              }}
            >
              Name :{" "}
            </Typography>

            <Typography
              sx={{
                display: "flex",
                textAlign: "end",
                fontWeight: "bold",
              }}
            >
              {newOrderDetails[0]?.updated_by_name !== null
                ? newOrderDetails[0]?.updated_by_name
                : "- - -"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                textAlign: "start",
                color: "blue",
                fontWeight: "bold",
              }}
            >
              Phone :{" "}
            </Typography>

            <Typography
              sx={{
                display: "flex",
                textAlign: "end",
                fontWeight: "bold",
              }}
            >
              {newOrderDetails[0]?.updated_by_phone !== null
                ? newOrderDetails[0]?.updated_by_phone
                : "- - -"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                textAlign: "start",
                color: "blue",
                fontWeight: "bold",
              }}
            >
              Updated At :{" "}
            </Typography>

            <Typography
              sx={{
                display: "flex",
                textAlign: "end",
                fontWeight: "bold",
              }}
            >
              {newOrderDetails[0]?.last_updated_at !== null
                ? newOrderDetails[0]?.last_updated_at
                : "- - -"}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
