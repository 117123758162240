import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";

function CurrentDayAllOrdersTable(props) {
  return (
    <Material.Box sx={{ maxHeight: "500px", overflow: "auto" }}>
      <Material.TableContainer component={Material.Paper}>
        <Material.Table size="small">
          <Material.TableHead sx={{ bgcolor: "#30A2F3" }}>
            <Material.TableRow>
              <Material.TableCell sx={{ color: "white" }}>
                Pincodes
              </Material.TableCell>
              <Material.TableCell sx={{ color: "white" }} align="right">
                Orders
              </Material.TableCell>
              {/* <Material.TableCell sx={{ color: "white" }} align="right">
                Order Qty
              </Material.TableCell> */}
              <Material.TableCell sx={{ color: "white" }} align="right">
                Service Area
              </Material.TableCell>
            </Material.TableRow>
          </Material.TableHead>
          <Material.TableBody>
            {props.data !== undefined &&
            props.data !== null &&
            props.data.length > 0 ? (
              <>
                {props.data.map((row) => (
                  <Material.TableRow
                    // key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <Material.TableCell component="th" scope="row">
                      {row.pincode}
                    </Material.TableCell>
                    <Material.TableCell align="right">
                      {row.order_count}
                    </Material.TableCell>
                    {/* <Material.TableCell align="right">{"500"}</Material.TableCell> */}
                    <Material.TableCell align="right">
                      {row.areaname}
                    </Material.TableCell>
                  </Material.TableRow>
                ))}
              </>
            ) : (
              <Material.Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  fontWeight: "bold",
                  paddingLeft:"10px"
                }}
              >
                No Data Found !!
              </Material.Typography>
            )}
          </Material.TableBody>
        </Material.Table>
      </Material.TableContainer>
    </Material.Box>
  );
}

export default CurrentDayAllOrdersTable;
