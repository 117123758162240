/**
 * @author Gowtham Prasath
 * @description Place Emppty Can orders page
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PlaceOrderEmptyCans } from "../../Pages/Redux/Actions/adminLineDetailsAction";
import {
  GetAvailableProducts,
  GetListofCrates,
} from "../../Pages//Redux/Actions/SKUProductDetailsAction";
import { InputAdornment } from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { WfsEmptyCanOrdersSku } from "../../Pages/Redux/Actions/skuWfsDetailsAction";
import CustomConsole from "../../CustomConsole";
export default function DistributorOrderDialog({
  open,
  onClose,
  userId,
  address,
}) {
  const dispatch = useDispatch();
  const wfsLineDetails = useSelector((state) => state.adminWfsLineData);
  const [productName, setProductName] = React.useState("");
  const [productId, setProductId] = React.useState("");
  const [productData, setProductDate] = React.useState({});
  const [crateName, setCrateName] = React.useState("");
  const [crateProduct, setCrateProduct] = React.useState([]);
  const [productOrderQty, setProductOrderQty] = React.useState(0);
  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    address_id: address,
    user_id: userId,
    order_qty: 0,
    return_qty: 0,
    crates: [],
  });

  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const crateDetails = skuContainerDetails.cateDetails;
  const skuQty = productData?.product_details?.sku_qty[0];
  //useEffect for getting list available crates
  React.useEffect(() => {
    setOrderParams((prev) => ({
      ...prev,
      address_id: address,
    }));
  }, [address]);

  //useEffect for getting list available crates
  React.useEffect(() => {
    // dispatch(
    //   GetListofCrates({
    //     activeStatus: true,
    //     capacity: "ASC",
    //     sort_by_name: "ASC",
    //     sort_by_price: "ASC",
    //     sort_by_created_at: "ASC",
    //   })
    // );
    dispatch(GetAvailableProducts());
  }, []);

  React.useEffect(() => {}, [orderParams]);

  //function for handling selected product
  const handleSelectedProduct = (data) => {
    setProductId(data.target.value);
    const product = data.target.value;
    let duplicateElement = skuContainerDetails.getAvailableProducts.find(
      (data) => data.product_details.id === product
    );
    setProductDate(duplicateElement);
    setProductName(duplicateElement.product_details.name);
    setCrateProduct(duplicateElement.crate_details);
    if (Object.keys(duplicateElement).length > 0) {
      setOrderParams((prev) => ({
        ...prev,
        container_material: duplicateElement.product_details.material,
        container_capacity: duplicateElement.product_details.capacity,
        order_qty: 0,
        return_qty: 0,
      }));
    }
  };

  //function for handling orderqty minus action
  const handelMinusQuantity = (productData) => {
    if (orderParams.order_qty >= 1) {
      setOrderParams((prev) => ({
        ...prev,
        order_qty: orderParams.order_qty - 1,
      }));
    }
  };
  //function for handling orderqty plus action
  const handelAddQuantity = (productData) => {
    CustomConsole("----------handelAddQuantity---------------");
    CustomConsole(productData);
    CustomConsole(orderParams.order_qty);
    setOrderParams((prev) => ({
      ...prev,
      order_qty: parseInt(orderParams.order_qty) + 1,
    }));
  };
  const handelChangeQuantity = (productData) => {
    CustomConsole(productData);
    setOrderParams((prev) => ({
      ...prev,
      order_qty: orderParams.order_qty,
    }));
  };

  //function for handling orderqty plus action
  const handelQuantityChange = (event, data) => {
    // Extract the input value as a number
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        order_qty: inputValue,
      }));
    }
  };

  //handel empty qty change
  //function for handling orderqty plus action
  const handelReturnQty = (event) => {
    CustomConsole(event);
    // Extract the input value as a number
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        return_qty: inputValue,
      }));
    }
  };

  //function for handling orderqty minus action
  const handelMinusReturnQuantity = () => {
    if (orderParams.return_qty >= 1) {
      setOrderParams((prev) => ({
        ...prev,
        return_qty: orderParams.return_qty - 1,
      }));
    }
  };
  //function for handling orderqty plus action
  const handelAddReturnQuantity = () => {
    if (orderParams.return_qty < orderParams.order_qty * skuQty) {
      setOrderParams((prev) => ({
        ...prev,
        return_qty: parseInt(orderParams.return_qty) + 1,
      }));
    }
  };
  //function for deleting product detiails
  const deleteProductDetails = (event) => {
    setOrderParams((prev) => ({
      ...prev,
      container_material: "",
      container_capacity: "",
    }));
    setProductId("");
  };

  //function for handling crate adding
  const handelSelectedCrate = () => {
    let crateData = crateProduct.find((data) => data.name === crateName);
    let duplicateElement = orderParams.crates.filter(
      (data) => data.name === crateName
    );
    CustomConsole(crateData);
    CustomConsole(crateProduct);
    CustomConsole(crateDetails);
    if (Object.keys(crateData).length > 0 && duplicateElement.length === 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: [
          ...prev.crates,
          {
            name: crateData.name,
            order_qty: 0,
            capacity: crateData.capacity,
            return_qty: 0,
          },
        ],
      }));
    }
  };

  //function for adding crate name
  const handelChangeSelectedCrate = (e) => {
    setCrateName(e.target.value);
  };
  CustomConsole(orderParams);

  //function for handling crate adding
  const handelAddCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) =>
        crate.name === data.name
          ? { ...crate, order_qty: crate.order_qty + 1 }
          : crate
      ),
    }));
  };
  //function for handling crate adding
  const handelMinusCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) => {
        if (crate.name === data.name && crate.order_qty > 0) {
          // Update the order_qty for the matching crate
          return { ...crate, order_qty: crate.order_qty - 1 };
        }
        // Return the original crate object if it doesn't match the condition
        return crate;
      }),
    }));
  };
  //function for handling crate adding
  const handelCrateQuantityChange = (data, e) => {
    // Extract the input value as a number
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.name === data.name) {
            return { ...crate, order_qty: inputValue };
          }
          return crate;
        }),
      }));
    }
  };

  //function for handling crate adding
  const handelAddReturnCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) =>
        crate.name === data.name
          ? { ...crate, return_qty: crate.return_qty + 1 }
          : crate
      ),
    }));
  };
  //function for handling crate adding
  const handelMinusReturnCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) => {
        if (crate.name === data.name && crate.return_qty > 0) {
          // Update the order_qty for the matching crate
          return { ...crate, return_qty: crate.return_qty - 1 };
        }
        // Return the original crate object if it doesn't match the condition
        return crate;
      }),
    }));
  };
  //function for handling crate adding
  const handelCrateReturnQuantityChange = (data, e) => {
    // Extract the input value as a number
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.name === data.name) {
            return { ...crate, return_qty: inputValue };
          }
          return crate;
        }),
      }));
    }
  };

  //const delete crate details
  const deleteCrateDetails = (index) => {
    setOrderParams((prev) => {
      const newCrates = [...prev.crates];
      newCrates.splice(index, 1);
      return { ...prev, crates: newCrates };
    });
    setCrateName("");
  };

  //function for selecting address id
  const handleSelectedAddress = (e) => {
    setOrderParams((prev) => ({ ...prev, address_id: e.target.value }));
  };

  //function for handling order function
  const handelSubmitOrder = () => {
    const quantityCheck = orderParams?.crates?.some(
      (data) => data.order_qty === 0
    );
    if (
      orderParams.order_qty !== "" &&
      orderParams.return_qty !== "" &&
      !quantityCheck
    ) {
      const reqObj = {
        container_material: orderParams.container_material,
        container_capacity: orderParams.container_capacity,
        address_id: orderParams.address_id,
        user_id: userId,
        order_qty: orderParams.order_qty * skuQty,
        return_qty: orderParams.return_qty,
        crates: orderParams.crates,
      };
      dispatch(WfsEmptyCanOrdersSku(reqObj));
      onClose();
    } else {
      toast.error("Please add order Quantity to Place order", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  //change Order quantity in text field
  const handleChangeorderQty = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setOrderParams((prev) => ({
      ...prev,
      order_qty: event.target.value,
    }));
    // setProductOrderQty(event.target.value);
  };
  //c
  const handleChangeReturnQty = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    if (event.target.value <= orderParams.order_qty * skuQty) {
      setOrderParams((prev) => ({
        ...prev,
        return_qty: event.target.value,
      }));
    } else {
      toast.error("You Can't Place More Than Order Quantity", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleChangeCrateQty = (data, event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.name === data.name) {
            return { ...crate, order_qty: inputValue };
          }
          return crate;
        }),
      }));
    }
  };

  const handleChangeCrateReturnQty = (data, event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.name === data.name) {
            return { ...crate, return_qty: inputValue };
          }
          return crate;
        }),
      }));
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignContent="center"
            >
              <Grid item xs={12}>
                <Typography>Select Products to Place order</Typography>
                <FormControl sx={{ minWidth: "205px" }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Select Product
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={productId}
                    label="Select Address"
                    onChange={handleSelectedProduct}
                    sx={{
                      color: "black",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "gray",
                      },
                    }}
                  >
                    {skuContainerDetails?.getAvailableProducts?.map(
                      (option) => (
                        <MenuItem
                          key={option.product_details.id}
                          value={option.product_details.id}
                        >
                          {option.product_details.name}/Material:
                          {option.product_details.material}/Capacity:
                          {option.product_details.capacity}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                {orderParams.container_capacity !== "" ? (
                  <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>
                          {productName}/{orderParams.container_capacity}/
                          {orderParams.container_material}
                        </Typography>
                        <DeleteIcon
                          color="error"
                          onClick={() => deleteProductDetails()}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                          marginTop: "1rem",
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            gap: "20px",
                            flexDirection: "column",
                            marginTop: "1rem",
                          }}
                        >
                          <Typography>Required&nbsp;&nbsp;</Typography>
                          <OrderButtonSku
                            handelAddQuantity={() =>
                              handelAddQuantity(productData)
                            }
                            handelMinusQuantity={() =>
                              handelMinusQuantity(productData)
                            }
                            handelQuantityChange={handleChangeorderQty}
                            inputValue={orderParams.order_qty}
                          />
                          {/* <TextField
                            size="small"
                            label="Requried"
                            value={orderParams.order_qty}
                            InputProps={{
                              maxLength: 10, // Optional: You can set the maximum length if needed
                              onInput: handleChangeorderQty, // Attach the input event handler
                            }}
                          /> */}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            gap: "20px",
                            flexDirection: "column",
                            marginTop: "1rem",
                          }}
                        >
                          <Typography>Empty Can(Units)&nbsp;&nbsp;</Typography>
                          <OrderButtonSku
                            handelAddQuantity={handelAddReturnQuantity}
                            handelMinusQuantity={handelMinusReturnQuantity}
                            handelQuantityChange={handleChangeReturnQty}
                            inputValue={orderParams.return_qty}
                          />
                          {/* <TextField
                            size="small"
                            label="Empty Can(Units)"
                            autoFocus
                            value={orderParams.return_qty}
                            InputProps={{
                              maxLength: 10, // Optional: You can set the maximum length if needed
                              onInput: handleChangeReturnQty, // Attach the input event handler
                            }}
                          /> */}
                        </Box>
                      </Box>
                    </CardContent>
                    <Box
                      sx={{
                        width: "100%",
                        bgcolor: "#C9EEFF",
                        // height: "30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ marginLeft: "1rem" }}>
                        SKU Qty:{skuQty}
                      </Typography>
                      <Typography sx={{ marginLeft: "1rem" }}>
                        Order Qty:
                        {orderParams.order_qty * skuQty
                          ? orderParams.order_qty * skuQty
                          : 0}
                      </Typography>
                      <Typography sx={{ marginRight: "1rem" }}>
                        Empty Qty:
                        {orderParams.return_qty ? orderParams.return_qty : 0}
                      </Typography>
                    </Box>
                  </Card>
                ) : null}
              </Grid>
              {crateProduct[0]?.id !== undefined &&
              crateProduct[0]?.id !== null ? (
                <Grid item xs={12}>
                  <FormControl sx={{ minWidth: "205px" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Select Crate
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={crateName}
                      label="Select Address"
                      onChange={handelChangeSelectedCrate}
                      sx={{
                        color: "black",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "gray",
                        },
                      }}
                    >
                      {crateProduct?.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}/Capacity:{option.capacity}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  &nbsp;&nbsp;
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handelSelectedCrate}
                    sx={{ marginTop: ".5rem" }}
                  >
                    Add
                  </Button>
                  {orderParams?.crates?.length > 0 ? (
                    <>
                      {orderParams.crates.map((data, index) => (
                        <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                {data.name}/{data.capacity}
                              </Typography>
                              <DeleteIcon
                                color="error"
                                onClick={() => deleteCrateDetails(index)}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginTop: "1rem",
                                flexDirection: { xs: "column", md: "row" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  flexDirection: "column",
                                  gap: "20px",
                                  marginTop: "1rem",
                                }}
                              >
                                <Typography>Required&nbsp;&nbsp;</Typography>
                                <OrderButtonSku
                                  handelAddQuantity={() =>
                                    handelAddCrateQuantity(data)
                                  }
                                  handelMinusQuantity={() =>
                                    handelMinusCrateQuantity(data)
                                  }
                                  handelQuantityChange={(event) =>
                                    handleChangeCrateQty(data, event)
                                  }
                                  inputValue={data.order_qty}
                                />
                                {/* <TextField
                                  size="small"
                                  label="Requried"
                                  value={data.order_qty}
                                  InputProps={{
                                    maxLength: 10, // Optional: You can set the maximum length if needed
                                    onInput: (event) =>
                                      handleChangeCrateQty(data, event), // Attach the input event handler
                                  }}
                                /> */}
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  flexDirection: "column",
                                  gap: "20px",
                                  marginTop: "1rem",
                                }}
                              >
                                <Typography>Return&nbsp;&nbsp;</Typography>
                                <OrderButtonSku
                                  handelAddQuantity={() =>
                                    handelAddReturnCrateQuantity(data)
                                  }
                                  handelMinusQuantity={() =>
                                    handelMinusReturnCrateQuantity(data)
                                  }
                                  handelQuantityChange={(event) =>
                                    handleChangeCrateReturnQty(data, event)
                                  }
                                  inputValue={data.return_qty}
                                />
                                {/* <TextField
                                  size="small"
                                  label="Return"
                                  value={data.order_qty}
                                  InputProps={{
                                    maxLength: 10, // Optional: You can set the maximum length if needed
                                    onInput: (event) =>
                                      handleChangeCrateReturnQty(data, event), // Attach the input event handler
                                  }}
                                /> */}
                              </Box>
                            </Box>
                          </CardContent>
                          <Box
                            sx={{
                              width: "100%",
                              bgcolor: "#C9EEFF",
                              height: "30px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ marginLeft: "1rem" }}>
                              Order Qty:{data.order_qty}
                            </Typography>
                            {/* <Typography sx={{ marginRight: "1rem" }}>
                            Return Qty:{data.order_qty}
                          </Typography> */}
                          </Box>
                        </Card>
                      ))}
                    </>
                  ) : null}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Close
        </Button>
        <Button size="small" variant="contained" onClick={handelSubmitOrder}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
    // </Paper>
  );
}
