import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";

const WfsDetailsFilterDialog = ({
  open,
  onClose,
  onSubmit,
  wfsFilters,
  setWfsFilters,
}) => {
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setWfsFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    onSubmit(wfsFilters);
  };

  const handelClearFilter = () => {
    setWfsFilters({
      page_number: 1,
      page_size: 100,
      wfs_id: "",
      wfs_name: "",
      wfs_owner_phone: "",
      incharge_phone: "",
      incharge_email: "",
      sort_created_at: "",
      sort_wfs_name: "",
      wfs_availability: "",
    });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter Options</DialogTitle>
      <DialogContent>
        <Grid container rowGap={2} sx={{ paddingTop: "0.5rem" }}>
          <Grid item md={6} xs={12}>
            <TextField
              label="WFS Name"
              name="wfs_name"
              size="small"
              value={wfsFilters.wfs_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="WFS Owner Phone"
              name="wfs_owner_phone"
              size="small"
              value={wfsFilters.wfs_owner_phone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Incharge Phone"
              name="incharge_phone"
              size="small"
              value={wfsFilters.incharge_phone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Incharge Email"
              name="incharge_email"
              size="small"
              value={wfsFilters.incharge_email}
              onChange={handleInputChange}
            />{" "}
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort by Created At</InputLabel>
              <Select
                name="sort_created_at"
                size="small"
                value={wfsFilters.sort_created_at}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl sx={{ width: "80%" }} variant="filled">
              <InputLabel>Sort by Wfs Name</InputLabel>
              <Select
                name="sort_wfs_name"
                size="small"
                value={wfsFilters.sort_wfs_name}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort by Wfs Name</InputLabel>
              <Select
                name="wfs_availability"
                size="small"
                value={wfsFilters.wfs_availability}
                onChange={handleInputChange}
              >
                <MenuItem value="true">Available</MenuItem>
                <MenuItem value="false">Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button
            onClick={onClose}
            color="error"
            variant="contained"
            size="small"
          >
            close
          </Button>
          <Button
            onClick={handelClearFilter}
            color="primary"
            variant="contained"
            size="small"
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="small"
          >
            Apply
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WfsDetailsFilterDialog;
