import React from "react";
import * as Material from "@mui/material";
import { Box, TextField } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { useDispatch } from "react-redux";
import { getFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import { useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";

function CompanyProfileCard(props) {
  const [companyName, setCompanyName] = React.useState("");
  const [companyType, setCompanyType] = React.useState("");

  const [companyTypeName, setCompanyTypeName] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [address3, setAddress3] = React.useState("");
  const [city, setCity] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [pan, setPan] = React.useState("");
  const [panUrl, setPanUrl] = React.useState("");
  const [tan, setTan] = React.useState("");
  const [tanUrl, setTanUrl] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [gstUrl, setGstUrl] = React.useState("");
  const [aadhaar, setAadhaar] = React.useState("");
  const [aadhaarUrl, setAadhaarUrl] = React.useState("");

  const dispatch = useDispatch();

  React.useEffect(() => {
    CustomConsole("Supplier data");
    CustomConsole(props.data);
    if (props.data !== undefined) {
      setCompanyName(props.data.company_name);
      setCompanyType(props.data.company_type);
      setCompanyTypeName(props.data.company_type_name);
      setAddress1(props.data.address_line1);
      setAddress2(props.data.address_line2);
      setAddress3(props.data.address_line3);
      setCity(props.data.city);
      setPincode(props.data.pincode);
      setPan(props.data.pan);
      setPanUrl(props.data.pan_file_url);
      setTan(props.data.tan);
      setTanUrl(props.data.tan_file_url);
      setGst(props.data.gst);
      setGstUrl(props.data.gst_file_url);
      setAadhaar(props.data.aadhaar_no);
      setAadhaarUrl(props.data.aadhaar_no_file_url);
    }
  }, [props.data]);

  return (
    <Material.Box>
      <Material.Card
        elevation={24}
        sx={{
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
          },
        }}
      >
        <Material.CardContent>
          <>
            <Material.Grid container spacing={2}>
              <Material.Grid item md={6} sm={12} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label="Company name"
                  InputLabelProps={{ shrink: true }}
                  value={companyName}
                  onChange={(event) => setCompanyName(event.target.value)}
                />
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                {/* {companyTypepMeta.map((data)=>{
                  <TextField
                  fullWidth
                  label="Company Type"
                  value={data.k}
                  onChange={(event) => setCompanyTypepMeta(event.target.value)}
                  disabled
                />
                })} */}

                <TextField
                  fullWidth
                  label="Company Type"
                  InputLabelProps={{ shrink: true }}
                  value={companyTypeName}
                  onChange={(event) => setCompanyTypeName(event.target.value)}
                  disabled
                />
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label="Address line 1"
                  InputLabelProps={{ shrink: true }}
                  value={address1}
                  onChange={(event) => setAddress1(event.target.value)}
                />
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label="Address line 2"
                  InputLabelProps={{ shrink: true }}
                  value={address2}
                  onChange={(event) => setAddress2(event.target.value)}
                />
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label="Address line 3"
                  InputLabelProps={{ shrink: true }}
                  value={address3}
                  onChange={(event) => setAddress3(event.target.value)}
                />
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label="City"
                  InputLabelProps={{ shrink: true }}
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                />
              </Material.Grid>
              <Material.Grid item md={12} sm={6} xs={12}>
                <TextField
                  // size="small"
                  disabled
                  sx={{ minWidth: "300px" }}
                  label="Pincode"
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  type="number"
                  value={pincode}
                  onChange={(event) => setPincode(event.target.value)}
                />
              </Material.Grid>

              <Material.Grid item md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    marginLeft: "90px",
                  }}
                >
                  <Box>
                    <TextField
                      // size="small"
                      //  fullWidth
                      disabled
                      label="Aadhaar"
                      InputLabelProps={{ shrink: true }}
                      value={aadhaar}
                      type="number"
                      onChange={(event) => setAadhaar(event.target.value)}
                    />
                  </Box>
                  <Box>
                    <VisibilityRoundedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        aadhaarUrl !== "" && dispatch(getFiles(aadhaarUrl))
                      }
                    />
                  </Box>
                </Box>
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    marginLeft: "90px",
                  }}
                >
                  <Box>
                    <TextField
                      // size="small"
                      // fullWidth
                      disabled
                      label="PAN"
                      InputLabelProps={{ shrink: true }}
                      value={pan}
                      onChange={(event) => setPan(event.target.value)}
                    />
                  </Box>
                  <Box>
                    <VisibilityRoundedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        panUrl !== "" && dispatch(getFiles(panUrl))
                      }
                    />
                  </Box>
                </Box>
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    marginLeft: "90px",
                  }}
                >
                  <Box>
                    <TextField
                      // size="small"
                      // fullWidth
                      disabled
                      label="TAN"
                      InputLabelProps={{ shrink: true }}
                      value={tan}
                      onChange={(event) => setTan(event.target.value)}
                    />
                  </Box>
                  <Box>
                    <VisibilityRoundedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        tanUrl !== "" && dispatch(getFiles(tanUrl))
                      }
                    />
                  </Box>
                </Box>
              </Material.Grid>
              <Material.Grid item md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    marginLeft: "90px",
                  }}
                >
                  <Box>
                    <TextField
                      // size="small"
                      // fullWidth
                      disabled
                      label="GST"
                      InputLabelProps={{ shrink: true }}
                      value={gst}
                      onChange={(event) => setGst(event.target.value)}
                    />
                  </Box>
                  <Box>
                    <VisibilityRoundedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        gstUrl !== "" && dispatch(getFiles(gstUrl))
                      }
                    />
                  </Box>
                </Box>
              </Material.Grid>
            </Material.Grid>
          </>
        </Material.CardContent>
      </Material.Card>
    </Material.Box>
  );
}

export default CompanyProfileCard;
