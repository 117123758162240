/**
 * @author vinay kumar
 * @description Supplier User Stock
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 01-07-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import { Typography } from "@mui/material";
import * as Material from "@mui/material";
import SupplierUserStockCard from "../../Components/Cards/SupplierUserStockCard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";
import {
  GetSupplierUserStockDetails,
  PostRequestSlotForFilling,
} from "../Redux/Actions/supliersAction";
import { Paper } from "@material-ui/core";
import SupplierReturnCanStockDetailsCard from "../../Components/Cards/SupplierReturnCanStockDetailsCard";
import PlaceDistributorReturnOrderDialog from "../AdminUsers/PlaceDistributorReturnOrderDialog";
import { WfsEmptyCanOrdersSku } from "../Redux/Actions/skuWfsDetailsAction";

function SupplierUserStock(props) {
  const dispatch = useDispatch();
  const [alignmentStock, setAlignmentStock] = React.useState("Regular");
  const [openPlaceReturnCanOrderDialog, setOpenPlaceReturnCanOrderDialog] =
    React.useState(false);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  React.useEffect(() => {
    CustomConsole("----------supplierId-----------------");
    CustomConsole(props.supplierId);

    dispatch(GetSupplierUserStockDetails({ user_id: props.supplierId }));
  }, []);
  const handleChangeStock = (event, newAlignment) => {
    setAlignmentStock(newAlignment);
  };
  const handlePlaceReturnCanOrder = () => {
    setOpenPlaceReturnCanOrderDialog(true);
  };

  const handleClose = () => {
    setOpenPlaceReturnCanOrderDialog(false);
  };
  const handleSubmit = (props) => {
    CustomConsole("---------handleSubmit---------");
    if (props.orderQty) {
      const reqObj = {
        container_capacity: supplierDeliveries?.distStock.container_capacity,
        container_material: supplierDeliveries?.distStock.container_material,
        is_return_can_order: true,
        order_qty: 0,
        address_id: props.address_id,
        return_qty: parseInt(props.orderQty),
        is_return_can_order: true,
      };
      dispatch(WfsEmptyCanOrdersSku(reqObj));
      setOpenPlaceReturnCanOrderDialog(false);
    } else {
      CustomConsole("---------fill---------");
      toast.error("Please Enter Return Can Quantity", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <Material.Box sx={{ width: "70Vw" }}>
      <Material.Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        {/* <Material.Typography
          sx={{
            textAlign: "start",
            paddingLeft: "14px",
            fontSize: "12px",
          }}
        >
          Stock Details
        </Material.Typography> */}
        <Material.Stack
          direction="row"
          // spacing={1}

          sx={{ maxHeight: "30px" }}
        >
          <Material.ToggleButtonGroup
            value={alignmentStock}
            exclusive
            size="sm"
            color="primary"
            onChange={handleChangeStock}
            // sx={{ bgcolor: "#73AFEB" }}
            // className={classes.reducedWidth}
          >
            <Material.ToggleButton value="Regular">
              REGULAR
            </Material.ToggleButton>
            <Material.ToggleButton value="Return">RETURN</Material.ToggleButton>
          </Material.ToggleButtonGroup>
        </Material.Stack>
      </Material.Box>
      {alignmentStock === "Regular" ? (
        <>
          {supplierDeliveries.getSupplerSupplierStockDetails !== null &&
          supplierDeliveries.getSupplerSupplierStockDetails !== undefined &&
          supplierDeliveries.getSupplerSupplierStockDetails.length > 0 ? (
            <>
              {supplierDeliveries.getSupplerSupplierStockDetails.map(
                (data, key) => {
                  return (
                    <SupplierUserStockCard
                      key={key}
                      container_material={data.container_material}
                      container_capacity={data.container_capacity}
                      empty_containers={data.empty_container_qty}
                      filled_containers={data.filled_container_qty}
                      physically_damaged_container={
                        data.physical_damaged_container_qty
                      }
                      qr_barcode_damaged_container={
                        data.qr_barcode_damaged_container_qty
                      }
                      new_containers_to_get={data.new_containers_to_provide}
                      ongoing_containers={data.ongoing_ordered_containers}
                      delivery_containers={data.delivery_containers}
                    />
                  );
                }
              )}
            </>
          ) : (
            "No Stock Details Available"
          )}
        </>
      ) : (
        <>
          {Object.keys(supplierDeliveries?.distStock || {}).length ? (
            <>
              <SupplierReturnCanStockDetailsCard
                supplierDeliveries={supplierDeliveries}
                handlePlaceReturnCanOrder={handlePlaceReturnCanOrder}
              />
            </>
          ) : null}
        </>
      )}
      {openPlaceReturnCanOrderDialog && (
        <PlaceDistributorReturnOrderDialog
          openPlaceReturnCanOrderDialog={openPlaceReturnCanOrderDialog}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </Material.Box>
  );
}

export default SupplierUserStock;
