/**
 * @author Vinay Kumar
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 06-03-2024
 */
import React from "react";
import * as Material from "@mui/material";
import userLogo from "../Assests/Images/userLogo.png";
import { ADMIN_ROLES } from "../AppMeta/appMetaConfig";
import { Cancel } from "@mui/icons-material";
import CustomConsole from "../../CustomConsole";

function AdminProfileDialog(props) {
  CustomConsole(props);
  //function for handel close operation
  const handleOnClose = () => {
    props.handleOnClose();
  };
  //   CustomConsole(props.productCrateDetails);
  return (
    <Material.SwipeableDrawer
      anchor="right"
      onClose={handleOnClose}
      open={props.openAdminProfileDialog}
    >
      <Material.Box sx={{border:"2px solid black"}}>
        <Material.Box sx={{display:"flex",justifyContent:"flex-end"}}>
          <Cancel sx={{fontSize:"35px",color:"red"}} onClick={handleOnClose}/>
        </Material.Box>
        <Material.Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            style={{ height: "70px", width: "70px" }}
            src={userLogo}
            alt=""
          />
        </Material.Box>
        <Material.Box sx={{display:"flex",padding:"5px",paddingLeft:"15px",pr:"15px",gap:"5px"}}>
          <Material.Typography sx={{color:"#21618C",fontWeight:"bold"}}>Full Name :</Material.Typography>
          <Material.Typography sx={{fontWeight:"bold"}}>{props.profileData.fullname}</Material.Typography>
        </Material.Box>
        <Material.Box sx={{display:"flex",padding:"5px",paddingLeft:"15px",pr:"15px",gap:"5px"}}>
          <Material.Typography sx={{color:"#21618C",fontWeight:"bold"}}>Phone:</Material.Typography>
          <Material.Typography sx={{fontWeight:"bold"}}>{props.profileData.phone}</Material.Typography>
        </Material.Box>
        <Material.Box sx={{display:"flex",padding:"5px",paddingLeft:"15px",pr:"15px",gap:"5px"}}>
          <Material.Typography sx={{color:"#21618C",fontWeight:"bold"}}>Email:</Material.Typography>
          <Material.Typography sx={{fontWeight:"bold"}}>{props.profileData.email}</Material.Typography>
        </Material.Box>
        <Material.Box sx={{display:"flex",padding:"5px",paddingLeft:"15px",pr:"15px",gap:"5px"}}>
          <Material.Typography sx={{color:"#21618C",fontWeight:"bold"}}>Role:</Material.Typography>
          <Material.Typography sx={{fontWeight:"bold"}}>   {props.profileData.role_id === ADMIN_ROLES.BW_DISTRIBUTER
                    ? "SUPPLIER"
                    : [
                        props.profileData.role_id === ADMIN_ROLES.BW_CONSUMER
                          ? "CONSUMER"
                          : [
                              props.profileData.role_id === ADMIN_ROLES.BW_DELIVERYAGENT
                                ? "DELIVERY AGENT"
                                : [
                                    props.profileData.role_id === ADMIN_ROLES.BW_SUPERADMIN
                                      ? "SUPER ADMIN"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_ADMIN
                                      ? "ADMIN"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_DELIVERYAGENT
                                      ? "Delivery agent"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_WFSADMIN
                                      ? "WFS INCHARGE"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_FINANCE
                                      ? "Finance"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_CS
                                      ? "Customer Support"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_FINANCEHEAD
                                      ? "Finance Head"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_CSHEAD
                                      ? "Customer Support Head"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_ADMIN
                                      ? "Admin"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_OPS
                                      ? "Operation"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_OPSHEAD
                                      ? "Operation Head"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_QA
                                      ? "QA"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_QAHEAD
                                      ? "QA Head"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_SALES
                                      ? "Sales"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_SALESHEAD
                                      ? "Sales Head"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_SUPERADMIN
                                      ? "Super Admin"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_CSLEAD
                                      ? "Customer Support Lead"
                                      : props.profileData.role_id === ADMIN_ROLES.BW_IVML
                                      ? "Inventory Lead"
                                      : props.profileData.role_id ===
                                        ADMIN_ROLES.BW_SUPPLIERMANAGER
                                      ? "WAREHOUSE MANAGER"
                                      : props.profileData.role_id ===
                                        ADMIN_ROLES.BW_SUPPLIER_LOADERS
                                      ? "LOADER"
                                      : "",
                                  ],
                            ],
                      ]}</Material.Typography>
        </Material.Box>
      </Material.Box>
    </Material.SwipeableDrawer>
  );
}

export default AdminProfileDialog;
