import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";

import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const GetAllTransactionDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      // email_phone=${reqObj.email_phone}&order_id=${reqObj.order_id}&transaction_id=${reqObj.transaction_id}
      //   &transaction_type=${reqObj.transaction_type}&from_date=${reqObj.from_date}&to_date=${reqObj.to_date}
      //   &payment_method=${reqObj.payment_method}&payment_status=${reqObj.payment_status}&is_deposited=${reqObj.deposited}
      //   &sort_by_time=${reqObj.ascDesc}&page_number=${reqObj.pageNumber}&page_size=${reqObj.pageSize}
      // const type_1 = `email_phone=${reqObj.email_phone}&transaction_type=${reqObj.transaction_type}&from_date=${reqObj.from_date}&to_date=${reqObj.to_date}
      // &payment_method=${reqObj.payment_method}&payment_status=${reqObj.payment_status}
      // &sort_by_time=${reqObj.ascDesc}&page_number=${reqObj.pageNumber}&page_size=${reqObj.pageSize}`;

      // const type_2 = `email_phone=${reqObj.email_phone}&transaction_type=${reqObj.transaction_type}
      // &from_date=${reqObj.from_date}&to_date=${reqObj.to_date}
      // &payment_status=${reqObj.payment_status}
      // &sort_by_time=${reqObj.ascDesc}&page_number=${reqObj.pageNumber}&page_size=${reqObj.pageSize}`;

      // const type_3 = `email_phone=${reqObj.email_phone}&transaction_type=${reqObj.transaction_type}
      // &from_date=${reqObj.from_date}&to_date=${reqObj.to_date}
      // &is_deposited=${reqObj.deposited}
      // &sort_by_time=${reqObj.ascDesc}&page_number=${reqObj.pageNumber}&page_size=${reqObj.pageSize}`;

      // const type_4 = `transaction_type=${reqObj.transaction_type}&sort_by_time=${reqObj.ascDesc}&page_number=${reqObj.pageNumber}&page_size=${reqObj.pageSize}`;

      // const type_5 = `&transaction_id=${reqObj.transaction_id}`;

      let response = await api.get(
        // `/OM/transaction/details?${
        //   reqObj.order_id !== ""
        //     ? type_4
        //     : reqObj.transaction_id !== ""
        //     ? type_5
        //     : reqObj.transaction_type === 101 || reqObj.transaction_type === 104
        //     ? type_1
        //     : reqObj.transaction_type === 102
        //     ? type_2
        //     : reqObj.transaction_type === 103
        //     ? type_3
        //     : ""
        // }`
        `/OM/transaction/details`,
        { params: reqObj }
      );
      CustomConsole("..RESPONSE..");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_TRANSACTION_DETAILS,
          payload: response.data,
        });
        // toast.success(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch({
          type: actionType.GET_TRANSACTION_DETAILS,
          payload: { error: true, data: [], msg: response.data.msg },
        });
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_TRANSACTION_DETAILS,
        payload: { error: true, data: [], msg: error.response.data.msg },
      });
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
