import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { useSelector } from "react-redux";
import { Close, Recycling } from "@mui/icons-material";

function PlaceRecycleOrderDialog(props) {
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [selectedProductRecycle, setSelectedProductRecycle] = React.useState();
  const [selectedProductDetails, setSelectedProductDetails] = React.useState(
    {}
  );

  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    physical_damaged_qty: 0,
    qrBarcode_damaged_qty: 0,
    expired_qty: 0,
  });

  const handelQuantityphysicalChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      physical_damaged_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.physical_damaged_qty),
    }));
  };
  const handelQuantityqrbarcodeChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      qrBarcode_damaged_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.qrBarcode_damaged_qty),
    }));
  };
  const handelQuantityExpiredChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      expired_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.expired_qty),
    }));
  };
  const handleSelectedProductRecycle = (data) => {
    setSelectedProductRecycle(data.target.value);
    const product = data.target.value;
    let duplicateElement = skuContainerDetails?.getAvailableProducts?.find(
      (data) => data.product_details.id === product
    );
    setSelectedProductDetails(duplicateElement);
    if (Object.keys(duplicateElement).length > 0) {
      setOrderParams((prev) => ({
        ...prev,
        container_material: duplicateElement.product_details.material,
        container_capacity: duplicateElement.product_details.capacity,
        qrBarcode_damaged_qty: 0,
        physical_damaged_qty: 0,
        expired_qty: 0,
      }));
    }
  };
  const handleSubmitRecycleOrder = () => {
    props.handleSubmitRecycleOrder({
      expired_qty: orderParams.expired_qty,
      qrBarcode_damaged_qty: orderParams.qrBarcode_damaged_qty,
      physical_damaged_qty: orderParams.physical_damaged_qty,
      container_capacity: orderParams.container_capacity,
      container_material: orderParams.container_material,
      setOrderParams: setOrderParams,
      setSelectedProductRecycle: setSelectedProductRecycle,
    });
  };
  const handleCloseRecycleDialog = () => {
    props.handleCloseRecycleDialog();
  };
  return (
    <Dialog open={props.openPlaceRecycleDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handleCloseRecycleDialog}
          >
            <Close />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ width: 220 }} size="small">
            <InputLabel id="demo-select-small-label">Select Product</InputLabel>
            <Select
              value={selectedProductRecycle}
              label="Select Product"
              onChange={handleSelectedProductRecycle}
              sx={{
                color: "black",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "gray",
                },
              }}
            >
              {skuContainerDetails?.getAvailableProducts?.map((option) => (
                <MenuItem
                  key={option.product_details.id}
                  value={option.product_details.id}
                >
                  {option.product_details.name}/Material:
                  {option.product_details.material}/Capacity:
                  {option.product_details.capacity}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedProductRecycle && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "1rem",
                    ml: "0px",
                  }}
                >
                  <Typography>Physical Damaged Quantity&nbsp;&nbsp;</Typography>
                  <OrderButtonSku
                    handelAddQuantity={() =>
                      handelQuantityphysicalChange(
                        parseInt(orderParams.physical_damaged_qty) + 1
                      )
                    }
                    handelMinusQuantity={() =>
                      handelQuantityphysicalChange(
                        parseInt(orderParams.physical_damaged_qty) - 1
                      )
                    }
                    handelQuantityChange={(event) =>
                      handelQuantityphysicalChange(event.target.value)
                    }
                    inputValue={orderParams.physical_damaged_qty}
                  />
                </Box>
                {selectedProductDetails.product_details.is_qr === true ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "10px",
                        marginTop: "1rem",
                      }}
                    >
                      <Typography>
                        Qr/Barcode Damaged Quantity&nbsp;&nbsp;
                      </Typography>
                      <OrderButtonSku
                        handelAddQuantity={() =>
                          handelQuantityqrbarcodeChange(
                            parseInt(orderParams.qrBarcode_damaged_qty) + 1
                          )
                        }
                        handelMinusQuantity={() =>
                          handelQuantityqrbarcodeChange(
                            parseInt(orderParams.qrBarcode_damaged_qty) - 1
                          )
                        }
                        handelQuantityChange={(event) =>
                          handelQuantityqrbarcodeChange(event.target.value)
                        }
                        inputValue={orderParams.qrBarcode_damaged_qty}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        gap: "10px",
                        marginTop: "1rem",
                      }}
                    >
                      <Typography>Expired Quantity&nbsp;&nbsp;</Typography>
                      <OrderButtonSku
                        handelAddQuantity={() =>
                          handelQuantityExpiredChange(
                            parseInt(orderParams.expired_qty) + 1
                          )
                        }
                        handelMinusQuantity={() =>
                          handelQuantityExpiredChange(
                            parseInt(orderParams.expired_qty) - 1
                          )
                        }
                        handelQuantityChange={(event) =>
                          handelQuantityExpiredChange(event.target.value)
                        }
                        inputValue={orderParams.expired_qty}
                      />
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  mt: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {orderParams.physical_damaged_qty ||
                orderParams.qrBarcode_damaged_qty ||
                orderParams.expired_qty ? (
                  <Button
                    variant="contained"
                    onClick={handleSubmitRecycleOrder}
                  >
                    Place order
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default PlaceRecycleOrderDialog;
