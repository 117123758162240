import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import { GetPostpaidOrders } from "../Redux/Actions/adminPostpaidOrdersAction";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { UploadPoFile } from "../Redux/Actions/fileHandlingAction";
import { useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";
const Input = styled("input")({
  display: "none",
});
export default function PostpaidUploadPo({
  setPostpaidInitiated,
  postpaidInitiated,
  postpaidFinalPrice,
  selectedOrderId,
  postpaidCharges,
  userId,
  status,
  pageNumber,
  orderby,
  emailPhone,
}) {
  const dispatch = useDispatch();
  const [poFile, setPoFile] = React.useState("");
  const handleClose = () => {
    setPostpaidInitiated(false);
  };

  React.useEffect(() => {
    if (poFile !== "") {
      const reqObj = {
        order_id: selectedOrderId,
        order_price: postpaidFinalPrice,
        postpaid_perc: 0,
        postpaid_charges: postpaidCharges,
        final_order_price: postpaidFinalPrice,
        user_id: userId,
        payment_type: "POSTPAID",
        status_id: "0",
        status: "PENDING",
        conv_fee: "0.00",
        conv_charges: "0.00",
        poFile: poFile,
      };
      dispatch(UploadPoFile(reqObj));
      setPostpaidInitiated(false);
      let settlement = false;
      setPoFile("");
      setTimeout(
        () =>
          dispatch(
            GetPostpaidOrders(
              emailPhone,
              orderby,
              status,
              pageNumber - 1,
              settlement
            )
          ),
        1000
      );
    }
  }, [poFile]);
  return (
    <div>
      <Dialog
        open={postpaidInitiated}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Upload PO file for the Order
              </Typography>
            </Box>
            <Box>
              <InputLabel htmlFor="import-button">
                <Input
                  id="import-button"
                  type="file"
                  onChange={(e) => setPoFile(e.target.files[0])}
                />
                <Tooltip title="Upload PO file">
                  <FileUploadRoundedIcon sx={{ cursor: "pointer" }} />
                </Tooltip>
              </InputLabel>
            </Box>
            <Box>
              <Tooltip title="Order will not be placed without uploading PO file">
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
