import React from "react";
import {
  CompleteDistributorReturnOrder,
  GetDistributorReturnCanOrders,
  ReturnCanVerifyOtp,
} from "../Redux/Actions/wfsOrdersAction";
import { useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { Orderstyles } from "../AdminWFSCanOrders/cardStyles";
import { styles } from "./cardStyles";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Paper,
  Button,
} from "@mui/material";
import SupplierOrdersEnterOtpDialog from "../../Components/DialoBoxes/SupplierOrdersEnterOtpDialog";
import WfsReturnCanCameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/WfsReturnCanCameraDialog";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { toast } from "react-toastify";
// import WfsReturnCanOrderCameraDialog from "../../Components/DialoBoxes/WfsReturnCanOrderCameraDialog";
function ReturnCanOrders(props) {
  CustomConsole(props);
  const dispatch = useDispatch();
  const [enterOtpOpenDialog, setEnterOtpOpenDialog] = React.useState(false);
  const [retunReceivingDistri, setRetunReceivingDistri] = React.useState({
    empty_qty: 0,
    filled_qty: 0,
    physically_damaged: 0,
    qr_barcode_damaged: 0,
  });
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [openReturnCanCameraDialog, setOpenReturnCanCameraDialog] =
    React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [openCamera, setOpenCamera] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");

  const handleSubmitOtp = () => {
    CustomConsole("---handleReturnSubmitotp--");
    CustomConsole(props.data.return_can_order_id);
    // CustomConsole(returnCanOrderId);
    setEnterOtpOpenDialog(false);
    const reqObj = {
      return_can_otp: enterOtpTextfied,
    };
    CustomConsole(reqObj);
    dispatch(ReturnCanVerifyOtp(props.data.return_can_order_id, reqObj));
    setEnterOtpTextfied("");
    setTimeout(() => {
      dispatch(GetDistributorReturnCanOrders());
    }, 500);
  };

  const handleCloseDialog = () => {
    CustomConsole("---- handleCloseDialog -----");
    setEnterOtpOpenDialog(false);
  };

  const handleButtonData = () => {
    if (
      props.data.is_submit_started === false &&
      props.data.is_submit_finished === false
    ) {
      setEnterOtpOpenDialog(true);
    } else if (
      props.data.is_submit_started === true &&
      props.data.is_submit_finished === false
    ) {
      // setOpenReturnCanCameraDialog(true);
      const reqObj = {
        empty_qty: retunReceivingDistri.empty_qty,
        filled_qty: retunReceivingDistri.filled_qty,
        physically_damaged: retunReceivingDistri.physically_damaged,
        qr_barcode_damaged: retunReceivingDistri.qr_barcode_damaged,
      };
      CustomConsole(reqObj);
      console.log(reqObj);
      if (
        reqObj.empty_qty +
          reqObj.filled_qty +
          reqObj.physically_damaged +
          reqObj.qr_barcode_damaged ===
        props.data.container_qty
      ) {
        dispatch(
          CompleteDistributorReturnOrder(props.data.return_can_order_id, reqObj)
        );
        setTimeout(() => {
          dispatch(GetDistributorReturnCanOrders());
        }, 500);
      } else {
        toast.error(
          `Ordered Quantity And Returning Quantity is Mismatching! Or Can't Submit Less Than Zero Every Field `,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    }
  };
  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenReturnCanCameraDialog(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", marginTop: ".5rem" }}>
      <Box sx={Orderstyles.orderCardBox}>
        <Card sx={Orderstyles.orderCard}>
          <Box sx={Orderstyles.orderCardHearder}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box sx={Orderstyles.BoxInsideContent}>
                <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                  Order ID: {props.data.return_can_order_id}
                </Typography>
              </Box>
            </Box>
            {/* <Box sx={Orderstyles.labelDesign}>
            <Typography sx={Orderstyles.labelText}>
              {"status"}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Typography>
          </Box> */}
          </Box>
          <CardContent
            //   onClick={() => handelDetailClick()}
            sx={{ cursor: "pointer" }}
          >
            <Box
              sx={{
                marginBottom: ".5rem",
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={Orderstyles.TypograpyCommon1}>
                  Container Quantity:
                  <span style={{ color: "#068fff" }}>
                    {props.data.container_qty}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: ".5rem",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
                textAlign: "start",
              }}
            >
              <Typography sx={Orderstyles.TypograpyCommon1}>
                Order Status:
                <span style={{ color: "#068fff" }}>
                  {props.data.order_status === 200 ? "APPROVED" : ""}
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: ".5rem",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
                textAlign: "start",
              }}
            >
              <Typography sx={Orderstyles.TypograpyCommon1}>
                Product:{" "}
                <span style={{ color: "#068fff" }}>
                  {props.data.container_capacity} -
                  {props.data.container_material}
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: ".5rem",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
                textAlign: "start",
              }}
            >
              <Typography sx={Orderstyles.TypograpyCommon1}>
                Received Can Qty:{" "}
                <span style={{ color: "#068fff" }}>
                  {props.data.received_container_qty}
                </span>
              </Typography>
            </Box>
            <Box>
              <Box>
                {props.data.is_submit_started === false &&
                props.data.is_submit_finished === false ? (
                  <Button variant="contained" onClick={handleButtonData}>
                    Enter Otp
                  </Button>
                ) : (
                  ""
                )}
              </Box>
              <Box>
                {props.data.is_submit_started === true &&
                props.data.is_submit_finished === false &&
                parseInt(props.data.order_status) === 200 ? (
                  <Box>
                    <Box
                      sx={{
                        bgcolor: "#F4F6F7",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: { md: "row", xs: "column" },
                        // justifyContent: "space-around",
                        pt: "10px",
                        gap: { xs: "10px", md: "20px" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ textAlign: "start" }}>
                          Empty : -&nbsp;&nbsp;
                        </Typography>
                        <OrderButtonSku
                          handelAddQuantity={() =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              empty_qty: parseInt(
                                retunReceivingDistri.empty_qty + 1
                              ),
                            }))
                          }
                          handelMinusQuantity={() =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              empty_qty: parseInt(
                                retunReceivingDistri.empty_qty > 0
                                  ? retunReceivingDistri.empty_qty - 1
                                  : 0
                              ),
                            }))
                          }
                          handelQuantityChange={(event) =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              empty_qty:
                                event.target.value >= 0 &&
                                event.target.value <= 999
                                  ? parseInt(event.target.value)
                                  : parseInt(prev.empty_qty),
                            }))
                          }
                          inputValue={parseInt(retunReceivingDistri.empty_qty)}
                          // disabled={false}
                        />
                      </Box>
                      {/* Filled RECEIVING */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ textAlign: "start" }}>
                          Filled : -&nbsp;&nbsp;
                        </Typography>
                        <OrderButtonSku
                          handelAddQuantity={() =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              filled_qty: parseInt(
                                retunReceivingDistri.filled_qty + 1
                              ),
                            }))
                          }
                          handelMinusQuantity={() =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              filled_qty: parseInt(
                                retunReceivingDistri.filled_qty > 0
                                  ? retunReceivingDistri.filled_qty - 1
                                  : 0
                              ),
                            }))
                          }
                          handelQuantityChange={(event) =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              filled_qty: parseInt(
                                event.target.value >= 0 &&
                                  event.target.value <= 999
                                  ? parseInt(event.target.value)
                                  : parseInt(prev.filled_qty)
                              ),
                            }))
                          }
                          inputValue={retunReceivingDistri.filled_qty}
                          // disabled={false}
                        />
                      </Box>

                      {/* Qr Barcode Damage RECEIVING */}
                      {props.data.is_qr === true ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography sx={{ textAlign: "start" }}>
                            Qr Barcode Damage &nbsp;&nbsp;
                          </Typography>

                          <OrderButtonSku
                            handelAddQuantity={() =>
                              setRetunReceivingDistri((prev) => ({
                                ...prev,
                                qr_barcode_damaged: parseInt(
                                  retunReceivingDistri.qr_barcode_damaged + 1
                                ),
                              }))
                            }
                            handelMinusQuantity={() =>
                              setRetunReceivingDistri((prev) => ({
                                ...prev,
                                qr_barcode_damaged: parseInt(
                                  retunReceivingDistri.qr_barcode_damaged > 0
                                    ? retunReceivingDistri.qr_barcode_damaged -
                                        1
                                    : 0
                                ),
                              }))
                            }
                            handelQuantityChange={(event) =>
                              setRetunReceivingDistri((prev) => ({
                                ...prev,
                                qr_barcode_damaged: parseInt(
                                  event.target.value >= 0 &&
                                    event.target.value <= 999
                                    ? parseInt(event.target.value)
                                    : parseInt(prev.qr_barcode_damaged)
                                ),
                              }))
                            }
                            inputValue={retunReceivingDistri.qr_barcode_damaged}
                            // disabled={false}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ textAlign: "start" }}>
                          Physical Damage&nbsp;&nbsp;
                        </Typography>

                        <OrderButtonSku
                          handelAddQuantity={() =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              physically_damaged: parseInt(
                                retunReceivingDistri.physically_damaged + 1
                              ),
                            }))
                          }
                          handelMinusQuantity={() =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              physically_damaged: parseInt(
                                retunReceivingDistri.physically_damaged > 0
                                  ? retunReceivingDistri.physically_damaged - 1
                                  : 0
                              ),
                            }))
                          }
                          handelQuantityChange={(event) =>
                            setRetunReceivingDistri((prev) => ({
                              ...prev,
                              physically_damaged: parseInt(
                                event.target.value >= 0 &&
                                  event.target.value <= 999
                                  ? parseInt(event.target.value)
                                  : parseInt(prev.physically_damaged)
                              ),
                            }))
                          }
                          inputValue={retunReceivingDistri.physically_damaged}
                          // disabled={false}
                        />
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{ m: 1 }}
                      onClick={handleButtonData}
                    >
                      complete
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {enterOtpOpenDialog === true ? (
        <SupplierOrdersEnterOtpDialog
          isDialogOpen={enterOtpOpenDialog}
          handleClose={handleCloseDialog}
          handleSubmitOtp={handleSubmitOtp}
          enterOtpTextfied={enterOtpTextfied}
          setEnterOtpTextfied={setEnterOtpTextfied}
          otpValidationError={otpValidationError}
          setOtpValidationError={setOtpValidationError}
        />
      ) : (
        ""
      )}
      {/* <WfsReturnCanOrderCameraDialog
        openCamera={openCamera}
        openReturnCanCameraDialog={openReturnCanCameraDialog}
        returnCanOrderId={orderId}
        setOpenCamera={setOpenCamera}
        setOpenReturnCanCameraDialog={setOpenReturnCanCameraDialog}
        handleClose={handleCloseCameraDialog}
      /> */}
      <WfsReturnCanCameraDialog
        openCamera={openCamera}
        openReturnCanCameraDialog={openReturnCanCameraDialog}
        returnCanOrderId={props.data.return_can_order_id}
        setOpenCamera={setOpenCamera}
        setOpenReturnCanCameraDialog={setOpenReturnCanCameraDialog}
        handleClose={handleCloseCameraDialog}
      />
    </Box>
  );
}

export default ReturnCanOrders;
