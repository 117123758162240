/**
 * @author vinay kumar
 * @description Add User Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
 // This UI Complete ReDesign Done by Vinay
import React from "react";
import * as Material from "@mui/material";
import * as IconName from "react-icons/fc";
import { Card } from "@material-ui/core";
import CustomConsole from "../../CustomConsole";

function AddUserDialog({ addUserSuccessMsg, setAddUserSuccessMsg }) {
  const handleClose = () => {
    setAddUserSuccessMsg(false);
  };
  return (
    <>
    
      <Material.Box>
        <Material.Dialog
          open={addUserSuccessMsg}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.Card>
            <Material.CardContent>
              <Material.DialogTitle id="alert-dialog-title">
                {"Successfully Added"}
              </Material.DialogTitle>
              <Material.DialogContent>
                <Material.DialogContentText
                  id="alert-dialog-description"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconName.FcApproval size="95px" />
                </Material.DialogContentText>
              </Material.DialogContent>
              <Material.Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Material.Button variant="outlined" onClick={handleClose}>
                  Close
                </Material.Button>
              </Material.Box>
            </Material.CardContent>
          </Material.Card>
        </Material.Dialog>
      </Material.Box>
    </>
  );
}

export default AddUserDialog;
