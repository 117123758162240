import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";

export const GetLostCansList = (userType,page_number) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/invalidCansList?userType=${userType}&page_number=${page_number}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_LOSTCANS_LIST,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const SendNotificationUsers = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/notifyInvalidCans`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SEND_NOTIFICATION_USERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const DisableUserCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/admin/disableQrBulk`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DISABLE_USER_CANS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
