import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialOrdersState = {
  error: true,
  msg: "",
  transactionDetails: [],
};

const transactionDetailsReducer = (state = initialOrdersState, action) => {
  switch (action.type) {
    case actionType.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        transactionDetails: action.payload.data,
      };
    default:
      return state;
  }
};

export default transactionDetailsReducer;
