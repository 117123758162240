import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  reportDetailsMsg: "",
  reportDetails: {},
  reportBasedonType:{},
  reportBasedonTypeMsg:"",
  reportBasedonPhoneType:{},
  reportBasedonPhoneTypeMsg:""
};

const ReportAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_REPORT_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        reportDetailsMsg: action.payload.msg,
        reportDetails: action.payload.data,
      };
      case actionType.GET_REPORT_BY_TYPE:
        return {
          ...state,
          error: action.payload.error,
          reportBasedonTypeMsg: action.payload.msg,
          reportBasedonType: action.payload.data,
        };
        case actionType.GET_REPORT_DETAILSBY_PHONE:
        return {
          ...state,
          error: action.payload.error,
          reportBasedonPhoneTypeMsg: action.payload.msg,
          reportBasedonPhoneType: action.payload.data,
        };
    default:
      return state;
  }
};

export default ReportAdminReducer;
