/**
 * @author vinay kumar
 * @description Supplier Assigned Area Card
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay

import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as Material from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomConsole from "../../CustomConsole";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  UpdateTransportCharge,
  GetDistributorDetails,
  RemoveTransportCharge,
} from "../Redux/Actions/appSettingsAction";
import { toast } from "react-toastify";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function DistributorAssignedAreaCard({
  pwdPincodeAreas,
  distriNum,
  adminRole,
  setAdminRoleId,
}) {
  CustomConsole(pwdPincodeAreas);
  CustomConsole(distriNum);

  const [areaName, setAreaName] = React.useState("");
  const [charge, setCharge] = React.useState("");
  const [id, setId] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [agreeDialog, setAgreeDialog] = React.useState(false);

  //toast
  const [deleteToast, setDeleteToast] = React.useState(false);
  const [chargeToast, setChargeToast] = React.useState(false);

  const dispatch = useDispatch();
  const appSettingsDetails = useSelector((state) => state.appSettingsDetails);

  React.useEffect(() => {
    if (deleteToast === true && appSettingsDetails.error === false) {
      toast.success(appSettingsDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(appSettingsDetails);
      setDeleteToast(false);
    }
    CustomConsole("in---");
    CustomConsole(appSettingsDetails);
    CustomConsole(chargeToast);
    if (chargeToast === true && appSettingsDetails.error === false) {
      toast.success(appSettingsDetails.updatingMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChargeToast(false);
    }
  }, []);

  const updateTransCharge = (data) => {
    CustomConsole(data);
    setAreaName(data.area_name);
    setCharge(data.transportation_charges);
    setId(data.id);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setAgreeDialog(false);
  };
  const updateTransChargeArea = () => {
    CustomConsole(
      `The area is ${areaName} and the charge is ${charge} and the id is ${id}`
    );
    const reqObj = {
      id: id,
      transportation_charges: charge,
    };
    if (reqObj.id !== "" && reqObj.transportation_charges !== "") {
      dispatch(UpdateTransportCharge(reqObj));
      setOpenDialog(false);
      setChargeToast(true);
      dispatch(GetDistributorDetails(distriNum));
    } else {
      toast.error("Transport charges cannot be empty", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  React.useEffect(() => {
    dispatch(GetDistributorDetails(distriNum));
  }, [distriNum]);

  pwdPincodeAreas.sort((a, b) => a.id - b.id);

  const deleteBtn = (data) => {
    setId(data.id);
    setAgreeDialog(true);
  };

  const removeTransCharge = () => {
    const reqObj = {
      id: id,
    };

    if (reqObj.id !== "") {
      dispatch(RemoveTransportCharge(reqObj));
      dispatch(GetDistributorDetails(distriNum));
      setDeleteToast(true);
    }
    setAgreeDialog(false);
  };

  const handleChangeCharges = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "" || (Number(inputValue) >= 0 && !isNaN(inputValue))) {
      setCharge(e.target.value);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          padding: "15px",
          gap: "10px",
          width: { md: "600px", xs: "360px" },
        }}
      >
        <Card
          // elevation={24}
          sx={{
            //  minWidth: "300px",
            //   backgroundColor: "#41638A",
            //   color: "white",
            maxHeight: "300px",
            overflow: "auto",
            // paddingLeft: { xs: "90px" },
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // padding: "10px",
                // gap: "10px",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "18", fontWeight: "bold", color: "blue" }}
                  // variant="button"
                  // display="block"
                  // gutterBottom
                >
                  Assigned Areas
                </Typography>
              </Box>
              {/* <Box sx={{ display: "flex" }}>
                <Typography sx={{ margin: "10px", fontWeight: "bold" }}>
                  {" "}
                  Assigned Areas
                </Typography>
                <Typography sx={{ margin: "10px", fontWeight: "bold" }}>
                  Transport Charges
                </Typography>
              </Box> */}
              <Material.TableContainer sx={{ maxHeight: 440 }}>
                <Material.Table>
                  <Material.TableHead sx={{ bgcolor: "#6495ED" }}>
                    <Material.TableRow>
                      <Material.TableCell sx={{ color: "white" }}>
                        Service Area
                      </Material.TableCell>
                      <Material.TableCell sx={{ color: "white" }}>
                        Transport Charges
                      </Material.TableCell>

                      <Material.TableCell sx={{ color: "white" }}>
                        Delete & Edit
                      </Material.TableCell>
                    </Material.TableRow>
                  </Material.TableHead>
                  <Material.TableBody>
                    {pwdPincodeAreas !== undefined &&
                    pwdPincodeAreas !== null &&
                    pwdPincodeAreas.length > 0 ? (
                      <>
                        {pwdPincodeAreas?.map((data) => {
                          return (
                            <Material.TableRow>
                              <Material.TableCell>
                                {data.area_name}
                              </Material.TableCell>
                              <Material.TableCell>
                                {data.transportation_charges}
                              </Material.TableCell>
                              <Material.TableCell>
                                {" "}
                                {ADMIN_ROLES.BW_ADMIN === adminRole ||
                                ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                                ADMIN_ROLES.BW_CSHEAD === adminRole ||
                                ADMIN_ROLES.BW_SALESHEAD === adminRole ||
                                ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
                                  ""
                                ) : (
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "10px",
                                        justifyContent: "space-between",
                                        alignItems: "space-evenly",
                                      }}
                                    >
                                      <DeleteIcon
                                        onClick={() => {
                                          deleteBtn(data);
                                        }}
                                      />
                                      <EditIcon
                                        onClick={() => {
                                          updateTransCharge(data);
                                        }}
                                      />
                                    </Box>
                                  </>
                                )}
                              </Material.TableCell>
                            </Material.TableRow>
                          );
                        })}
                      </>
                    ) : (
                      "No Assined Areas"
                    )}
                  </Material.TableBody>
                </Material.Table>
              </Material.TableContainer>
              {/* {pwdPincodeAreas.map((data, key) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "90px",
                    }}
                    key={key + 1}
                  >
                    <Box sx={{ width: "100px" }}>
                      <Typography sx={{ textAlign: "center", gap: "20px" }}>
                        {data.area_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{data.transportation_charges}</Typography>
                    </Box>{" "}
                    {ADMIN_ROLES.BW_ADMIN === adminRole ||
                    ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                    ADMIN_ROLES.BW_CSHEAD === adminRole ||
                    ADMIN_ROLES.BW_SALESHEAD === adminRole ||
                    ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
                      ""
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between",
                            alignItems: "space-evenly",
                          }}
                        >
                          <DeleteIcon
                            onClick={() => {
                              deleteBtn(data);
                            }}
                          />
                          <EditIcon
                            onClick={() => {
                              updateTransCharge(data);
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                );
              })} */}
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>
          <b>{areaName}</b>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            variant="standard"
            type="number"
            onKeyDown={(e) =>
              (e.key === "e" || e.key === "-" || e.key === "+") &&
              e.preventDefault()
            }
            label="Transport Charge"
            value={charge}
            inputProps={{ pattern: "[0-9]*" }}
            onChange={handleChangeCharges}
          />
          <Button
            variant="contained"
            sx={{
              float: "right",
              marginTop: "8px",
            }}
            onClick={updateTransChargeArea}
          >
            Update
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={agreeDialog} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={removeTransCharge}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DistributorAssignedAreaCard;
