import actionType from "../Actions/actionType";

import CustomConsole from "../../../CustomConsole";
const initialState = {
  error: true,
  msg: "",
};

const adminEditOrderReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action)
  switch (action.type) {
    case actionType.EDIT_ORDER_USER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default adminEditOrderReducer;
