import React, { useRef } from "react";
import * as Material from "@mui/material";
import * as MuiIcon from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import {
  GetOrderIdDetails,
  GetWFSDetails,
} from "../Redux/Actions/adminEmptyCanOrderAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { useDispatch, useSelector } from "react-redux";
import WFSDetailsTable from "./WFSDetailsTable";
import SupplierStartSubmissionDialog from "../../Components/DialoBoxes/SupplierStartSubmissionDialog";
import { PutSupplierOngoingStartSubmission } from "../Redux/Actions/supliersAction";
import * as MuiIcons from "@mui/icons-material";
import EditEmptyCanOrdersDialog from "../wfsCanOrders/EditEmptyCanOrdersDialog";
import { PutEditEmptyCanOrder } from "../Redux/Actions/wfsOrdersAction";
import {
  GetWfsDetailsSku,
  GetWfsEmptyCanDetailsSku,
  WfsEmptyCanAcceptOrdersSku,
  WfsEmptyCanRejectOrdersSku,
} from "../Redux/Actions/skuWfsDetailsAction";
import moment from "moment";
import EmptyCanOrderEdit from "../../Components/DialogBoxes/emptyCanEditOrder";
import AssignDeliveryAgentDialog from "../../Components/Dialog/AssignDeliveryAgentDialog";
import AssignWareHouseEmptyCanDialog from "../../Components/Dialog/AssignWareHouseEmptyCan";
import CustomConsole from "../../CustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SingleWFsOrderDetailsDialog(props) {
  CustomConsole(props);
  const dispatch = useDispatch();
  const wfsTableRef = useRef(null);
  const singleIdData = useSelector((state) => {
    const emptyCanDetails = state.skuWfsDetails.emptyCanDetails;
    if (emptyCanDetails && emptyCanDetails.length > 0) {
      return emptyCanDetails[0];
    }
    return;
  });
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const wfsDetails = useSelector((state) => state.emptyCanOrderData.wfsDetails);
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [wfsTable, setWFSTable] = React.useState(false);
  const [wareHouseDetails, setWareHouseDetails] = React.useState([]);
  const [deliveryAgent, setDeliveryAgent] = React.useState([]);
  const [assingedWfsDetails, setAssingedWfsDetails] = React.useState([]);
  const [orderedUserDetails, setOrderedUserDetails] = React.useState([]);
  const [createdUserDetails, setCreatedUserDetails] = React.useState([]);
  const [updatedUserDetails, setUpdatedUserDetails] = React.useState([]);
  const [openEditEmptyCanOrderDialog, setOpenEditEmptyCanOrderDialog] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [openStartSubmissionDialog, setOpenStartSubmissionDialog] =
    React.useState(false);
  const [assignAgentOpen, setAssignAgentOpen] = React.useState(false);
  const [editAddressOpen, setEditAddressOpen] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const scrollWFSTable = React.useRef(null);
  const scrollToWfsTable = () => {
    scrollWFSTable.current?.scrollIntoView({ behavior: "smooth" });
  };
  //useEffect for scrool component
  React.useEffect(() => {
    if (props.wfstable && wfsTableRef.current) {
      // Scroll to the bottom of the component
      wfsTableRef.current.scrollTop = wfsTableRef.current.scrollHeight;
    }
  }, [props.wfstable]);

  //function for order status
  const getStatusMessage = (status) => {
    switch (status) {
      case 100:
        return "Order Confirmed";
      case 200:
        return "Order Completed";
      case 501:
        return "Order Admin Accepted";
      case 502:
        return "Order Admin Rejected";
      default:
        return "Unknown Status";
    }
  };

  //function for getting role id
  const getRoleName = (roleId) => {
    const roleMapping = {
      [ADMIN_ROLES.BW_DISTRIBUTER]: "SUPPLIER",
      [ADMIN_ROLES.BW_CONSUMER]: "CONSUMER",
      [ADMIN_ROLES.BW_DELIVERYAGENT]: "DELIVERY AGENT",
      [ADMIN_ROLES.BW_SUPERADMIN]: "SUPER ADMIN",
      [ADMIN_ROLES.BW_ADMIN]: "ADMIN",
      [ADMIN_ROLES.BW_DELIVERYAGENT]: "Delivery agent",
      [ADMIN_ROLES.BW_WFSADMIN]: "WFS INCHARGE",
      [ADMIN_ROLES.BW_FINANCE]: "Finance",
      [ADMIN_ROLES.BW_CS]: "Customer Support",
      [ADMIN_ROLES.BW_FINANCEHEAD]: "Finance Head",
      [ADMIN_ROLES.BW_CSHEAD]: "Customer Support Head",
      [ADMIN_ROLES.BW_ADMIN]: "Admin",
      [ADMIN_ROLES.BW_OPS]: "Operation",
      [ADMIN_ROLES.BW_OPSHEAD]: "Operation Head",
      [ADMIN_ROLES.BW_QA]: "QA",
      [ADMIN_ROLES.BW_QAHEAD]: "QA Head",
      [ADMIN_ROLES.BW_SALES]: "Sales",
      [ADMIN_ROLES.BW_SALESHEAD]: "Sales Head",
      [ADMIN_ROLES.BW_SUPERADMIN]: "Super Admin",
      [ADMIN_ROLES.BW_CSLEAD]: "Customer Support Lead",
      [ADMIN_ROLES.BW_IVML]: "Inventory Lead",
    };

    return roleMapping[roleId] || "";
  };
  React.useEffect(() => {
    if (singleIdData?.ec_order_id !== "") {
      let data = [
        { type: "EC Order Id", payload: singleIdData?.ec_order_id },
        {
          type: "Otp",
          payload: singleIdData?.otp,
        },
        {
          type: "Material",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.container_material
              : 0,
        },
        {
          type: "Capacity",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.container_capacity
              : 0,
        },
        {
          type: "New Container Qty",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.new_qty
              : 0,
        },
        {
          type: "Total Order Qty",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.order_qty
              : 0,
        },
        {
          type: "Empty Oty",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.return_qty
              : 0,
        },
        {
          type: "Damged Qty",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.damaged_qty
              : 0,
        },
        {
          type: "Inscanned Container",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.inscanned_cans
              : 0,
        },
        {
          type: "Outscanned Containers",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.outscanned_cans
              : 0,
        },
        {
          type: "Submitting Return Containers",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.return_containers_submitting
              : 0,
        },
        {
          type: "submitted Return Containers",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.submitted_return_cans
              : 0,
        },
        {
          type: "Expired Qty",
          payload:
            singleIdData?.order_details !== undefined &&
            singleIdData?.order_details !== null
              ? singleIdData?.order_details.expired_qty
              : 0,
        },
        {
          type: "Status",
          payload: getStatusMessage(singleIdData?.ec_order_status),
        },
        { type: "Created At", payload: singleIdData?.created_at },
        {
          type: "Updated At",
          payload: singleIdData?.updated_at,
        },
      ];
      setOrderDetails(data);
      let WareHousedata = [
        {
          type: "InCharge Name",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_incharge_name
              : "-",
        },
        {
          type: "InCharge Phone",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_incharge_phone
              : "-",
        },
        {
          type: "Status",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_active_status
              : "-",
        },
        {
          type: "Address Line 1",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_address_line1
              : "-",
        },
        {
          type: "Address Line 2",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_address_line2
              : "-",
        },
        {
          type: "Address Line 3",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_address_line3
              : "-",
        },
        {
          type: "Service Area",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_service_area
              : "-",
        },
        {
          type: "Pincode",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_pincode
              : "-",
        },
        {
          type: "Country",
          payload:
            singleIdData?.warehouse_details !== undefined &&
            singleIdData?.warehouse_details !== null
              ? singleIdData?.warehouse_details.warehouse_country
              : "-",
        },
      ];
      setWareHouseDetails(WareHousedata);
      let deliveryAgent = [
        {
          type: "Agent Name",
          payload:
            singleIdData?.deivery_agent_details !== undefined &&
            singleIdData?.deivery_agent_details !== null
              ? singleIdData?.deivery_agent_details.user_name
              : "-",
        },
        {
          type: "Phone",
          payload:
            singleIdData?.deivery_agent_details !== undefined &&
            singleIdData?.deivery_agent_details !== null
              ? singleIdData?.deivery_agent_details.phone
              : "-",
        },
      ];
      setDeliveryAgent(deliveryAgent);
      let AssignedWfsdata = [
        {
          type: "WFS Name",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details.wfs_name
              : "-",
        },
        {
          type: "Owner Name",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details.wfs_owner_name
              : "-",
        },
        {
          type: "Owner Phone",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details.wfs_owner_phone
              : "-",
        },
        {
          type: "WFS Address",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details?.wfs_address?.District
              : "-",
        },
        {
          type: "WFS Pincode",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details?.wfs_address?.Pincode
              : "-",
        },
        {
          type: "WFS InCharge Name",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details.wfs_incharge_name
              : "-",
        },
        {
          type: "WFS InCharge Phone",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details.wfs_incharge_phone
              : "-",
        },
        {
          type: "WFS InCharge Email",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details.wfs_incharge_email
              : "-",
        },
        {
          type: "WFS Is_Active",
          payload:
            singleIdData?.assigned_wfs_details !== undefined &&
            singleIdData?.assigned_wfs_details !== null
              ? singleIdData?.assigned_wfs_details.is_active
              : "-",
        },
      ];
      setAssingedWfsDetails(AssignedWfsdata);
      let OrderedUserdata = [
        {
          type: "Name",
          payload:
            singleIdData?.ordered_user_details !== undefined &&
            singleIdData?.ordered_user_details !== null
              ? singleIdData?.ordered_user_details.user_name
              : "-",
        },
        {
          type: "Phone",
          payload:
            singleIdData?.ordered_user_details !== undefined &&
            singleIdData?.ordered_user_details !== null
              ? singleIdData?.ordered_user_details.phone
              : "-",
        },
        {
          type: "Email",
          payload:
            singleIdData?.ordered_user_details !== undefined &&
            singleIdData?.ordered_user_details !== null
              ? singleIdData?.ordered_user_details.email
              : "-",
        },
        {
          type: "Placed By",
          payload:
            singleIdData?.ordered_user_details !== undefined &&
            singleIdData?.ordered_user_details !== null
              ? getRoleName(singleIdData?.ordered_user_details.user_role)
              : "-",
        },
      ];
      setOrderedUserDetails(OrderedUserdata);
      let CreatedUserdata = [
        {
          type: "Name",
          payload:
            singleIdData?.created_user_details !== undefined &&
            singleIdData?.created_user_details !== null
              ? singleIdData?.created_user_details.user_name
              : "-",
        },
        {
          type: "Phone",
          payload:
            singleIdData?.created_user_details !== undefined &&
            singleIdData?.created_user_details !== null
              ? singleIdData?.created_user_details.phone
              : "-",
        },
        {
          type: "Email",
          payload:
            singleIdData?.created_user_details !== undefined &&
            singleIdData?.created_user_details !== null
              ? singleIdData?.created_user_details.email
              : "-",
        },
        {
          type: "Created By",
          payload:
            singleIdData?.created_user_details !== undefined &&
            singleIdData?.created_user_details !== null
              ? getRoleName(singleIdData?.created_user_details.user_role)
              : "-",
        },
      ];
      setCreatedUserDetails(CreatedUserdata);
      let UpdatedUserdata = [
        {
          type: "Name",
          payload:
            singleIdData?.updated_user_details !== undefined &&
            singleIdData?.updated_user_details !== null
              ? singleIdData?.updated_user_details.user_name
              : "-",
        },
        {
          type: "Phone",
          payload:
            singleIdData?.updated_user_details !== undefined &&
            singleIdData?.updated_user_details !== null
              ? singleIdData?.updated_user_details.phone
              : "-",
        },
        {
          type: "Email",
          payload:
            singleIdData?.updated_user_details !== undefined &&
            singleIdData?.updated_user_details !== null
              ? singleIdData?.updated_user_details.email
              : "-",
        },
        {
          type: "Updated By",
          payload:
            singleIdData?.updated_user_details !== undefined &&
            singleIdData?.updated_user_details !== null
              ? getRoleName(singleIdData?.updated_user_details.user_role)
              : "-",
        },
      ];
      setUpdatedUserDetails(UpdatedUserdata);
    }
  }, [singleIdData]);
  const handleCloseWFSOrderDialog = () => {
    props.handleCloseWFSOrderDialog();
  };
  //function for reject empty can order
  const handleRejectBtn = (data) => {
    CustomConsole(data);
    setOrderId(data.ec_order_id);
    const reqObj = {
      reject_reason: moment().format("YYYY-MM-DD"),
    };
    dispatch(WfsEmptyCanRejectOrdersSku(data?.ec_order_id, reqObj));
  };

  //function for accept empty can order
  const handleAcceptBtn = (data) => {
    props.setWFSTable(true);
    setOrderId(data.ec_order_id);
    const obj1 = {
      page_number: pageNumber - 1,
      order_id: data?.ec_order_id,
    };
    CustomConsole(obj1);
    dispatch(GetWFSDetails(obj1));
  };

  const handleOkDialog = () => {
    CustomConsole("-----handleOkDialog-----------");
    setOpenStartSubmissionDialog(false);
    setTimeout(
      () => dispatch(GetOrderIdDetails(singleIdData?.ec_order_id)),
      1000
    );
  };
  const handleStart = () => {
    CustomConsole("----------Start Submission-----------");
    setOpenStartSubmissionDialog(true);
    dispatch(
      PutSupplierOngoingStartSubmission({
        order_id: singleIdData?.ec_order_id,
      })
    );
  };

  const handleEditEmptyCan = () => {
    setOpenEditEmptyCanOrderDialog(true);
  };
  const handleClose = () => {
    setOpenEditEmptyCanOrderDialog(false);
    dispatch(GetOrderIdDetails(singleIdData?.ec_order_id));
  };
  const handleSubmitCans = (props) => {
    CustomConsole("--------handleSubmitCans-------------");
    const reqObj = {
      order_id: props.ec_order_id,
      order_qty: props.orderQty + props.emptyCanQty,
      empty_qty: props.emptyCanQty,
    };
    CustomConsole(reqObj);
    dispatch(PutEditEmptyCanOrder(reqObj));
    setOpenEditEmptyCanOrderDialog(false);
    setTimeout(
      () => dispatch(GetOrderIdDetails(singleIdData?.ec_order_id)),
      1000
    );
  };

  //useEffect for refreshing data
  React.useEffect(() => {
    if (!skuWfsDetails.acceptError && props.openWFSOrdersDialog) {
      dispatch(
        GetWfsEmptyCanDetailsSku({
          ec_order_id: singleIdData?.ec_order_id,
        })
      );
      skuWfsDetails.acceptError = true;
    }
    if (!skuWfsDetails.rejectError && props.openWFSOrdersDialog) {
      dispatch(
        GetWfsEmptyCanDetailsSku({
          ec_order_id: singleIdData?.ec_order_id,
        })
      );
      skuWfsDetails.rejectError = true;
    }
    if (!skuWfsDetails.assignAgentError && props.openWFSOrdersDialog) {
      dispatch(
        GetWfsEmptyCanDetailsSku({
          ec_order_id: singleIdData?.ec_order_id,
        })
      );
      skuWfsDetails.assignAgentError = true;
    }
    if (!skuWfsDetails.editOrderError && props.openWFSOrdersDialog) {
      dispatch(
        GetWfsEmptyCanDetailsSku({
          ec_order_id: singleIdData?.ec_order_id,
        })
      );
      skuWfsDetails.editOrderError = true;
    }
    if (!supplierDeliveries.onGoingError && props.openWFSOrdersDialog) {
      dispatch(
        GetWfsEmptyCanDetailsSku({
          ec_order_id: singleIdData?.ec_order_id,
        })
      );
      supplierDeliveries.onGoingError = true;
    }
  }, [
    skuWfsDetails.acceptError,
    skuWfsDetails.rejectError,
    skuWfsDetails.assignAgentError,
    skuWfsDetails.editOrderError,
    supplierDeliveries.onGoingError,
  ]);

  //function for opening assign delivery agent dialog
  const handelAssignDeliveryAgent = () => {
    setAssignAgentOpen(!assignAgentOpen);
  };

  //function for opening assign Warehouse dialog
  const handelSetEditWareHouseOpen = () => {
    setEditAddressOpen(!editAddressOpen);
  };

  CustomConsole(singleIdData?.warehouse_details);
  return (
    <Material.Dialog
      fullScreen
      open={props.openWFSOrdersDialog}
      onClose={handleCloseWFSOrderDialog}
      TransitionComponent={Transition}
    >
      <Material.AppBar sx={{ position: "relative" }}>
        <Material.Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseWFSOrderDialog}
          >
            <CloseIcon />
          </IconButton>
          <Material.Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
          >
            {singleIdData?.ec_order_id}
          </Material.Typography>
          <Material.Button
            autoFocus
            color="inherit"
            onClick={handleCloseWFSOrderDialog}
          >
            Close
          </Material.Button>
        </Material.Toolbar>
      </Material.AppBar>
      <Material.DialogContent>
        <Material.Grid container spacing={2}>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  ORDER DETAILS
                </Material.Typography>
              </Material.Box>
              {orderDetails.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>

          {singleIdData?.order_details?.crates?.length > 0 && (
            <Material.Grid item md={4} xs={12}>
              <Material.Paper sx={{ padding: "10px" }}>
                <Material.Box sx={{ bgcolor: "#365A91" }}>
                  <Material.Typography
                    sx={{ textAlign: "center", color: "white" }}
                  >
                    CRATE DETAILS
                  </Material.Typography>
                </Material.Box>
                {singleIdData?.order_details?.crates?.map((data) => {
                  return (
                    <Material.Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderBottom: "2px dashed gray",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          color: "blue",
                          fontWeight: "bold",
                        }}
                      >
                        Container Capacity:{data.container_capacity}
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          color: "blue",
                          fontWeight: "bold",
                        }}
                      >
                        Container Material:{data.container_material}
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          color: "blue",
                          fontWeight: "bold",
                        }}
                      >
                        Order Qty:{data.order_qty}
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          color: "blue",
                          fontWeight: "bold",
                        }}
                      >
                        Return Qty:{data.return_qty}
                      </Material.Typography>
                    </Material.Box>
                  );
                })}
              </Material.Paper>
            </Material.Grid>
          )}

          {singleIdData?.warehouse_details !== null &&
            singleIdData?.warehouse_details !== undefined &&
            Object.keys(singleIdData?.warehouse_details).length > 0 && (
              <Material.Grid item md={4} xs={12}>
                <Material.Paper sx={{ padding: "10px" }}>
                  <Material.Box sx={{ bgcolor: "#365A91" }}>
                    <Material.Typography
                      sx={{ textAlign: "center", color: "white" }}
                    >
                      WAREHOUSE DETAILS
                    </Material.Typography>
                  </Material.Box>
                  {wareHouseDetails.map((data) => {
                    return (
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                          }}
                        >
                          {data.type}
                        </Material.Typography>
                        <Material.Typography>
                          {data.payload}
                        </Material.Typography>
                      </Material.Box>
                    );
                  })}
                  {singleIdData?.order_details.is_submit_started === false &&
                  singleIdData?.ec_order_status === 501 ? (
                    <Material.Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Material.Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handelAssignDeliveryAgent(singleIdData);
                        }}
                      >
                        Assign Delivery Agent
                      </Material.Button>
                      <Material.Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handelSetEditWareHouseOpen(singleIdData);
                        }}
                      >
                        Edit Warehouse
                      </Material.Button>
                    </Material.Box>
                  ) : (
                    ""
                  )}
                </Material.Paper>
              </Material.Grid>
            )}

          {singleIdData?.deivery_agent_details !== null &&
            singleIdData?.deivery_agent_details !== undefined &&
            Object.keys(singleIdData?.deivery_agent_details).length > 0 && (
              <Material.Grid item md={4} xs={12}>
                <Material.Paper sx={{ padding: "10px" }}>
                  <Material.Box sx={{ bgcolor: "#365A91" }}>
                    <Material.Typography
                      sx={{ textAlign: "center", color: "white" }}
                    >
                      DELIVER AGENT DETAILS
                    </Material.Typography>
                  </Material.Box>
                  {deliveryAgent.map((data) => {
                    return (
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                          }}
                        >
                          {data.type}
                        </Material.Typography>
                        <Material.Typography>
                          {data.payload}
                        </Material.Typography>
                      </Material.Box>
                    );
                  })}
                </Material.Paper>
              </Material.Grid>
            )}
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  ASSIGNED WFS DETAILS
                </Material.Typography>
              </Material.Box>
              {assingedWfsDetails.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  ORDERED USER DETAILS
                </Material.Typography>
              </Material.Box>
              {orderedUserDetails.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  CREATED USER DETAILS
                </Material.Typography>
              </Material.Box>
              {createdUserDetails.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  UPDATED USER DETAILS
                </Material.Typography>
              </Material.Box>
              {updatedUserDetails.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>
          <Material.Grid item md={12} xs={12}>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              {singleIdData?.ec_order_status == "100" ? (
                <>
                  <Material.Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleAcceptBtn(singleIdData);
                    }}
                  >
                    Accept order
                  </Material.Button>
                </>
              ) : null}
              {singleIdData?.order_details?.is_submit_finished === false &&
              singleIdData?.ec_order_status !== 200 &&
              singleIdData?.ec_order_status !== 502 ? (
                <>
                  <Material.Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleRejectBtn(singleIdData);
                    }}
                    sx={{
                      backgroundColor: "#F01111",
                      "&:hover": {
                        backgroundColor: "#BE2828",
                      },
                    }}
                  >
                    Cancel order
                  </Material.Button>
                  <Material.Button
                    variant="contained"
                    size="small"
                    onClick={handleEditEmptyCan}
                  >
                    <MuiIcons.BorderColor /> Edit
                  </Material.Button>
                </>
              ) : (
                ""
              )}
              {singleIdData?.ec_order_status === 501 &&
              singleIdData?.order_details?.is_submit_started === false ? (
                <Material.Box
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Material.Button
                    onClick={handleStart}
                    variant="contained"
                    sx={{
                      borderTopRightRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                  >
                    Start Submission
                  </Material.Button>
                </Material.Box>
              ) : (
                ""
              )}
            </Material.Box>
          </Material.Grid>
          <Material.Grid item md={12}>
            {wfsDetails.length > 0 && props.wfsTable === true ? (
              <div
                ref={wfsTableRef}
                style={{ overflowY: "auto", maxHeight: "300px" }}
              >
                <WFSDetailsTable
                  orderId={orderId}
                  distributerPgNo={props.distributerPgNo}
                  setPageNumber={setPageNumber}
                  wfsDetails={wfsDetails}
                  setWFSTable={props.setWFSTable}
                  setDistributerPgNo={props.setDistributerPgNo}
                  scrollWFSTable={scrollWFSTable}
                  setOpenWFSOrdersDialog={props.setOpenWFSOrdersDialog}
                  handleCloseWFSOrderDialog={props.handleCloseWFSOrderDialog}
                />{" "}
              </div>
            ) : null}
          </Material.Grid>
        </Material.Grid>
        <SupplierStartSubmissionDialog
          openStartSubmissionDialog={openStartSubmissionDialog}
          handleClose={handleOkDialog}
          // storeStartSubmissionData={storeStartSubmissionData}
        />
        {openEditEmptyCanOrderDialog && (
          <EmptyCanOrderEdit
            open={openEditEmptyCanOrderDialog}
            editDetails={singleIdData}
            onClose={handleClose}
          />
        )}
        {assignAgentOpen && (
          <AssignDeliveryAgentDialog
            open={assignAgentOpen}
            setOpen={setAssignAgentOpen}
            selectedData={singleIdData}
          />
        )}
        {editAddressOpen && (
          <AssignWareHouseEmptyCanDialog
            handleCloseWFSOrderDialog={props.handleCloseWFSOrderDialog}
            open={editAddressOpen}
            setOpen={handelSetEditWareHouseOpen}
            selectedData={singleIdData}
          />
        )}
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default SingleWFsOrderDetailsDialog;
