/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Styles } from "./Trips";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VehicleChecklist from "./VehicleChecklist";
import CreateTrip from "./TripPlanning/CreateTrip";
import AddorUpdateSupplierVehicle from "../../Components/DialogBoxes/SupplierDialogBox/AddorUpdateSupplierVehicle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { GetVehiclesWithTrip } from "../Redux/Actions/supplierVehiclesActions";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import { useHistory } from "react-router-dom";
import { LocalShipping } from "@mui/icons-material";
const VehicleTrips = ({ setTab, TabValues }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const VehicleList = useSelector(
    (state) => state.vehicleManagement.vehicleTrips
  );
  const vehicleManagement = useSelector((state) => state.vehicleManagement);
  const [filteredVehiclesList, setFilteredVehiclesList] = useState([]);
  const [openVehicleModal, setOpenVehicleModal] = useState();
  const [OpenAddModel, setOpenAddModel] = useState(false);
  const [openTripModal, setOpenTripModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    dispatch(GetVehiclesWithTrip());
  }, []);
  useEffect(() => {
    console.log(searchText);
    if (searchText) {
      let temp = VehicleList?.filter(
        (item) =>
          item?.tanker_profile?.make
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toLocaleLowerCase()) ||
          item?.tanker_profile?.model
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toLocaleLowerCase()) ||
          item?.tanker_id
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toLocaleLowerCase())
      );
      setFilteredVehiclesList(temp || []);
    } else {
      setFilteredVehiclesList(VehicleList || []);
    }
  }, [VehicleList, searchText]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ p: 1, gap: 1 }}>
          <Paper elevation={10} sx={{ p: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControl variant="outlined" sx={{ mr: "10px" }}>
                <InputLabel htmlFor="input-with-icon-adornment">
                  Search Reg.No ...
                </InputLabel>
                <Input
                  variant="outlined"
                  id="input-with-icon-adornment"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{ height: "40px", mt: 1 }}
                  variant="contained"
                  // elevation={5}
                  onClick={() => setOpenVehicleModal(true)}
                  // sx={{ p: "3px", borderRadius: 50 }}
                >
                  {/* <AddCircleOutlineIcon
                  sx={{ float: "left", fontSize: "30px" }}
                /> */}{" "}
                  Add <LocalShipping />
                </Button>
              </Box>
            </Box>
          </Paper>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "right" }}>
            <Paper
              elevation={5}
              sx={{
                display: "flex",
                alignItems: "center",
                px: 1,
                mt: 1,
                mb: 1,
              }}
            >
              <Box sx={{ fontWeight: "bold" }}>Current Warehouse:</Box>
              <Box
                onClick={() => {
                  if (vehicleManagement.punchDetails?.warehouse?.warehouse_id) {
                    history.push(`warehouse`);
                  }
                }}
                sx={{
                  padding: 1,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Box>
                  <PersonPinCircleIcon sx={{ fontSize: "30px" }} />
                </Box>
                <Box>
                  {vehicleManagement.punchDetails?.warehouse?.warehouse_name}
                </Box>
              </Box>
            </Paper>
          </Box>
        </Paper>
        <Box sx={{ overflow: "hidden" }}>
          {/* <Paper
            elevation={18}
            sx={[
              Styles.paperCommon,
              { p: 0, pr: 1, mx: 0 },
              // { display: "flex", flexDirection: "column" },
            ]}
          > */}
          <TableContainer sx={{ p: 0 }} component={Paper} elevation={18}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#D0D3D4" }}>
                  <TableCell
                    align="center"
                    sx={{
                      padding: 0,
                      py: 1,
                      fontWeight: "bold",
                      border: "1.5px solid #D5DBDB",
                    }}
                  >
                    Name(Reg no)
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: 0,
                      py: 1,
                      fontWeight: "bold",
                      border: "1.5px solid #D5DBDB",
                    }}
                  >
                    Pending Orders
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: 0,
                      py: 1,
                      fontWeight: "bold",
                      border: "1.5px solid #D5DBDB",
                    }}
                  >
                    Delivered Orders
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: 0,
                      py: 1,
                      fontWeight: "bold",
                      border: "1.5px solid #D5DBDB",
                    }}
                  >
                    Drivers
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: 0,
                      py: 1,
                      fontWeight: "bold",
                      border: "1.5px solid #D5DBDB",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVehiclesList?.map((item, index) => {
                  return (
                    <TableRow
                      onClick={(e) => {
                        // e.stopPropagation();
                        // setOpenVehicleModal(true);
                        // setSelectedVehicle(item);
                      }}
                      key={index}
                    >
                      <TableCell
                        onClick={() => {
                          setOpenVehicleModal(true);
                          setSelectedVehicle(item);
                        }}
                        sx={{
                          padding: 0,
                          border: "1.5px solid #D5DBDB",
                          textDecorationStyle: "underline",
                          color: "blue",
                        }}
                        align="center"
                      >
                        {item?.tanker_profile?.make} {/*Do Not Remove  */}
                        {item?.tanker_profile?.model}
                        <br />
                        {item?.tanker_id || "-"}
                      </TableCell>
                      <TableCell
                        onClick={(e) => {
                          if (item?.pending > 0) {
                            setOpenAddModel("deliveries");
                            setSelectedVehicle(item);
                          }
                        }}
                        sx={{ padding: 0, border: "1.5px solid #D5DBDB" }}
                        align="center"
                      >
                        {parseInt(item?.pending) || 0}
                      </TableCell>
                      <TableCell
                        sx={{ padding: 0, border: "1.5px solid #D5DBDB" }}
                        align="center"
                      >
                        {parseInt(
                          item?.trips?.[0]?.other_details?.completed_orders
                            ?.length
                        ) || 0}
                      </TableCell>
                      <TableCell
                        sx={{ padding: 0, border: "1.5px solid #D5DBDB" }}
                        align="center"
                      >
                        {item?.trips?.[0]?.driver?.fullname || "-"}
                        <br />
                        {item?.trips?.[0]?.driver?.phone || "-"}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 0,
                          py: 1,
                          fontWeight: "bold",
                          border: "1.5px solid #D5DBDB",
                        }}
                        align="center"
                      >
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedVehicle(item);
                            setOpenAddModel(true);
                          }}
                        >
                          {item?.trips?.[0]?.start_time ? (
                            item?.trips?.[0]?.end_time ? (
                              <>
                                {/* <Box color="red">Inactive</Box> */}
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{ fontSize: "12px" }}
                                >
                                  Start Trip
                                </Button>
                              </>
                            ) : parseInt(item?.pending) ||
                              parseInt(item?.delivered) ? (
                              // <Box color="green">
                              //   <Box>{item?.pending}</Box>
                              //   Deliveries
                              // </Box>
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                                sx={{
                                  padding: "0",
                                  fontSize: "10px",
                                  py: 1,
                                }}
                              >
                                View Trip
                              </Button>
                            ) : item?.trips?.[0]?.start_time ? (
                              // <Box color="yellowgreen">Active</Box>
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                                sx={{
                                  padding: "0",
                                  fontSize: "10px",
                                  py: 1,
                                }}
                              >
                                View Trip
                              </Button>
                            ) : (
                              <Box>
                                {/* <Box color="red">Inactive</Box> */}
                                <Button variant="contained" size="small">
                                  Start Trip
                                </Button>
                              </Box>
                            )
                          ) : (
                            <Box>
                              {/* <Box color="red">Inactive</Box> */}
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  padding: "0",
                                  fontSize: "10px",
                                  py: 1,
                                }}
                              >
                                Start Trip
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!filteredVehiclesList?.length && (
              <Box sx={{ w: "100%", textAlign: "center" }}>
                No Active Vehicles Found
              </Box>
            )}
          </TableContainer>
          {/* </Paper> */}
        </Box>
        {/* <Box sx={{ width: "100%", p: 2 }}>
          <Button
            onClick={() => setTab("Employees")}
            sx={{ float: "left", borderRadius: 10 }}
            variant="contained"
          >
            <ArrowBackIosIcon /> Attendance
          </Button>
        </Box> */}
      </Box>
      {OpenAddModel && (
        <VehicleChecklist
          force_tab={OpenAddModel}
          vehicleDetails={selectedVehicle}
          open={OpenAddModel ? true : false}
          // isEnd={
          //   selectedVehicle &&
          //   parseInt(selectedVehicle?.pending) &&
          //   parseInt(selectedVehicle?.pending) ===
          //     parseInt(selectedVehicle?.delivered)
          // }
          onClose={() => {
            dispatch(GetVehiclesWithTrip());
            setOpenAddModel(false);
            setSelectedVehicle({});
          }}
        />
      )}
      <CreateTrip setOpen={setOpenTripModal} open={openTripModal} />
      <AddorUpdateSupplierVehicle
        onClose={() => {
          setSelectedVehicle({});
          dispatch(GetVehiclesWithTrip());
          setOpenVehicleModal(false);
        }}
        details={selectedVehicle}
        open={openVehicleModal}
      />
    </Box>
  );
};

export default VehicleTrips;
