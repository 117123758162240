import React from "react";
import * as Material from "@mui/material";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import CustomConsole from "../../../CustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateBarchDialog(props) {
    CustomConsole(props)
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lineId, setLineId] = React.useState("");
  const [batchQuantity, setBatchQuantity] = React.useState("");
  const selectCapacity = [{ value: "20_Liters" }, { value: "5_Liters" }];
  const selectMaterial = [
    { value: "PET" },
    { value: "PVC" },
    { value: "COPPER" },
    { value: "POLYCARBONATE" },
  ];
  const selectLineId = [{ value: "Line ID 1" }, { value: "Line ID 2" }];

  //   const handleClose = () => {
  //     setIsCreateBatchDialogOpen(false);
  //   };
  //   const handleBatchQuantityChange = (event) => {
  //     CustomConsole(event.target.value);
  //     setBatchQuantity(event.target.value);
  //   };

  const handleCreatebatchAdmin = () => {
    CustomConsole("------------handleCreatebatch--------------");
    props.handleCreatebatchAdmin({
      Capacity: capacity,
      Material: material,
      Batch_Quantity: batchQuantity,
      LineId : lineId
    });
    // if (props.wfsBatchDetails.lineDetailsError === false && lineId!=="") {
    //   setCreateBatchFlag(true);
    // } else {
    //   toast.error(
    //     "Contact Bookwater Support to add line details to create batch",
    //     {
    //       position: "bottom-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "dark",
    //     }
    //   );
    // }
  };
  return (
    <Material.Box>
      <Material.Dialog
        open={props.isCreateBatchDialogOpen} 
        keepMounted
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Material.Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Create Batch Here
          </Material.Typography>
        </Material.Box>
        <Material.DialogContent>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              padding: "10px",
            }}
          >
            <Material.Box>
              <Material.TextField
                select
                label="Select Capacity"
                helperText="Please select your capacity"
                value={capacity}
                onChange={(event) => setCapacity(event.target.value)}
              >
                {selectCapacity.map((option) => (
                  <MenuItem value={option.value}>{option.value}</MenuItem>
                ))}
              </Material.TextField>
            </Material.Box>

            <Material.Box>
              <Material.TextField
                select
                label="Select Material"
                helperText="Please select your material"
                value={material}
                onChange={(event) => setMaterial(event.target.value)}
              >
                {selectMaterial.map((option) => (
                  <MenuItem value={option.value}>{option.value}</MenuItem>
                ))}
              </Material.TextField>
            </Material.Box>
            <Material.Box>
              {props.lineDetailsError === false ? (
                <Material.TextField
                  select
                  label="Select Line ID"
                  value={lineId}
                  helperText="Please select your line ID"
                  onChange={(event) => setLineId(event.target.value)}
                >
                  {props.lineBatchDetails.map((option) => (
                    <MenuItem value={option.line_id}>{option.line_id}</MenuItem>
                  ))}
                </Material.TextField>
              ) : (
                ""
              )}
            </Material.Box>
            <Material.Box>
              <Material.TextField
                id="standard-number"
                label="Enter batch quantity"
                type="number"
                value={batchQuantity}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={(event) => setBatchQuantity(event.target.value)}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
              />
            </Material.Box>

            <Material.Box sx={{ display: "flex", marginTop: "10px" }}>
              <Material.Button
                variant="contained"
                onClick={handleCreatebatchAdmin}
              >
                Create batch
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default CreateBarchDialog;
