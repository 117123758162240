import { useEffect, useRef, useState } from "react";
import * as Material from "@mui/material";
import userLogo from "../../Components/Assests/Images/userLogo.png";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { ordersStatus } from "../../Components/AppMeta/appMetaConfig";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import waterDrop from "../../Images/waterDrop.svg";
import CustomConsole from "../../CustomConsole";
import moment from "moment";
import QRCode from "qrcode.react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Add,
  BrowserUpdated,
  DeleteForever,
  DeleteOutlined,
  Download,
  DriveFileRenameOutlineOutlined,
  PlusOne,
  SyncRounded,
} from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-toastify";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddReduceReturnDialog from "../AdminUsers/AddReduceReturnDialog";
import AddAddressDialog from "../AdminUsers/AddAddressDialog";
import EditAddressDialog from "../AdminUsers/EditAddressDialog";
import {
  DeleteUser,
  DeleteUserAddress,
  DisableUserAdmin,
  EditUserAddress,
  EnableUserAdmin,
  GetDepositDetails,
  GetProfileDetails,
  GetReferralCode,
  GetSubscriptionOrderDetails,
  GetUserAddressAdmin,
  GetUserStockDetails,
  GetWalletDetails,
  InfluencerEnableDisable,
  MakeUserInfluencer,
  PostRefundConsumerStock,
  UpdateGstDetails,
  UpdateReferralCode,
  UpdateUserDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { useDispatch, useSelector } from "react-redux";
import RedeemAmountToAccount from "../AdminUsers/RedeemAmountToAccount";
import DeleteUserDialog from "../../Components/Dialog/DeleteUserDialog";
import ReplacementDialogue from "../AdminUsers/ReplacementDialogue";
import SubscriptionDetailsDialog from "../AdminUsers/SubcriptionDetailsDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  BooknowOrderDetails,
  GetSetUpConfigAction,
  GetUserDepositDetails,
} from "../Redux/Actions/adminBookingAction";
import PdwOrderDialog from "../AdminUsers/PdwOrderDialog";
import { PayThroughWallet } from "../Redux/Actions/adminOrderPayAction";
import TransactionsTable from "../AdminUsers/TransactionsTable";
import UserTransactions from "../AdminUsers/UserTransactions";
import AddUpdateConsumerProductPrice from "../../Components/Dialog/ProductDiscountCustomer";
import RefundDialog from "./RefundDialog";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RegularBooking from "./RegularBooking";
import { getFiles } from "../Redux/Actions/fileHandlingAction";
import BookRegularProducts from "./BookingChnage/BookRegularProducts";
import BuyBackProductsOrdersDialog from "../AdminUsers/OrderDialogs/BuyBackOrderDialog";
import AccountClosureCard from "../../Components/Cards/AccountClosure/AccountClosureCard";
import React from "react";
import AddGiftCard from "./AddGiftCard";
import {
  GetGiftCarData,
  PostCreateSingleUserGiftCard,
} from "../Redux/Actions/AdminSettingsAction";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function Consumer(props) {
  CustomConsole(props);
  CustomConsole(props.bookingDetails);
  const qrRef = useRef();
  const dispatch = useDispatch();
  const settingsDetails = useSelector((state) => state.adminSettingsData);
  const history = useHistory();
  const [addressnamePhone, setAddressnamePhone] = useState("");
  const [stockCapacity, setStockCapacity] = useState("");
  const [singleSelectedStockData, setSingleSelectedStockData] = useState({});
  const [bookNowType, setBookNowType] = useState("");
  const [changeEmailPostpaid, setChangeEmailPostpaid] = useState(false);
  const [updateSubscriptonText, setUpdateSubscriptonText] = useState(false);
  const [singleSelectedAddressData, setSingleSelectedAddressData] = useState({
    service_area: "-",
    contact_phone: "-",
  });
  const [isBookwaterConsumer, setIsBookwaterConsumer] = useState("");
  const [orderPrice, setOrderPrice] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [stockUpdates, setStockUpdates] = useState("");
  const [alignmentTransactionsOrders, setAlignmentTransactionsOrders] =
    useState("Transactions");
  const [openCreateGiftCard, setOpenCreateGiftCard] = React.useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [addReduceReturn, setAddReduceReturn] = useState(false);
  const [is_b2b, setIs_b2b] = useState(false);
  const [is_b2c, setIs_b2c] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [editSubscriptionDialog, setEditSubscriptionDialog] = useState(false);
  const [editProducts, setEditproducts] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [lowWalletBalance, setLowWalletBalance] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});

  const [canPrice, setCanPrice] = useState(0);
  const [depositData, setDepositData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [walletTrigger, setWalletTrigger] = useState(false);
  const [pdwData, setPwdData] = useState({});
  const [containerCapacity, setContainerCapacity] = useState([]);
  const [containerMaterial, setContainerMaterial] = useState([]);
  const [cansCapacity, setCansCapacity] = useState(0);
  const [bottleCapacity, setVBottlesCapacity] = useState(0);
  const [userId, setUserId] = useState("");
  const [replacementReasons, setReplacementReasons] = useState("");
  const [toastFlag, setToastFlag] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [singleSelectedAccountData, setSingleSelectedAccountData] = useState(
    {}
  );
  const [open, setOpen] = useState([]);
  // Add Address
  const [addressBtn, setAddressBtn] = useState(false);
  const [changeNotification, setChangeNotification] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({});
  const [deliveryTime, setDeliveryTime] = useState("");
  const [name, setName] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [addressemail, setaddressemail] = useState("");
  const [cratesCapacity, setCratesCapacity] = useState(0);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");

  const [isEditAddressDialogOpen, setIsEditAddressDialogOpen] = useState(false);
  const [isPrimaryAddress, setIsPrimaryAddress] = useState(false);
  const [showPincodeToEnter, setShowPincodeToEnter] = useState(false);
  // const [props.influencerSetting, setInfluencerSetting] = useState(false);
  const [isSubscriptionOrder, setIsSubscriptionOrder] = useState(false);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [subscriptionBtn, setSubscriptionBtn] = useState(false);
  const [openPdwOrderDialog, setOpenPdwOrderDialog] = useState(false);
  const [postpaidInitiated, setPostpaidInitiated] = useState(false);
  const [address, setAddress] = useState({});

  const [deleteUserBtn, setDeleteUserBtn] = useState(false);
  const [saveAddressFlag, setSaveAddressFlag] = useState(false);
  const [isPhoneProper, setisPhoneProper] = useState(true);
  const [changeInInfluencer, setChangeInInfluencer] = useState(false);
  const [postPaidPercentage, setPostPaidPercentage] = useState(0);
  const [postpaidFinalPrice, setPostpaidFinalPrice] = useState(0);
  const [value, setValue] = useState([]);
  // const [isSwitchOn, setIsSwitchOn] = useState(true);
  //current change
  const [availableAreasFlag, setAvailableAreasFlag] = useState(false);
  const [subscriptionFlag, setSubscriptionFlag] = useState(false);
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);
  const [consumerName, setConsumerName] = useState("");
  const [consumerArea, setConsumerArea] = useState("");
  const [consumerId, setConsumerId] = useState("");
  const [postpaidPercentageValue, setPostpaidPercentageValue] = useState([]);
  const [twntyLtrDiscount, setTwntyLtrDiscount] = useState(0);
  const [packagedrinkingwaterData, setPackagedDrinkingWaterData] = useState({});

  const [distributorType, setDistributorType] = useState("");
  const [updateUserBtn, setUpdateUserBtn] = useState(false);
  const [gstFieldError, setGstFieldError] = useState(false);
  const [selectedStock, setSelectedStock] = useState({});
  const [min, setMin] = useState("");
  const [bookNow, setBookNow] = useState(0);
  // Redeem water drop handling button click function
  const [openRedeem, setOpenRedeem] = useState(false);
  const [areasAvailable, setAvailableArea] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [billname, setBillname] = useState("");
  const [gst, setGst] = useState("");
  const [changeInSwitch, setChangeInSwitch] = useState(false);
  const [max, setMax] = useState("");
  const [selectArea, setSelectArea] = useState(false);
  const [areaValue, setAreaValue] = useState("");
  const [discount, setDiscount] = useState("");
  const [customPrice, setCustomPrice] = useState("");
  //usestate for adding product details
  const [conformAddProduct, setConformAddProduct] = useState(false);
  const [addressAddProduct, setAddressAddProduct] = useState(false);
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [productData, setProductData] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [editingAdrs, setEditingAdrs] = useState({});
  const [booktypeSub, setBooktypeSub] = useState(0);
  const [buyBackOrderOpen, setbuyBackOrderOpen] = useState(false);
  const [url, setUrl] = useState(
    `https://webapp.bookwater.com/authenticate?referrer=${props.referralCode}`
  );
  const [openAccountClosure, setOpenAccountClosure] = useState(false);
  const [paymentCycle, setPaymentCycle] = useState({
    nextPaymentCycleDate: moment().format("YYYY-MM-DD"),
    interval: 1,
    startDate: moment().format("YYYY-MM-DD"),
    isFirstTime: true,
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      background: "rgb(208,221,242)",
      backgroundImage:
        "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      // overflowWrap: "break-word",
    },
  }));

  const renderText = props.updateUserDetails?.address?.map((data) => {
    if (data.is_billing_address && !data.is_primary) {
      return "vinay";
    } else if (!data.is_billing_address && data.is_primary) {
      return "naveen";
    } else if (data.is_billing_address && data.is_primary) {
      return "chandhu";
    } else {
      return "text";
    }
  });

  const handleFromDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setPaymentCycle((prev) => ({
        ...prev,
        startDate: moment(newValue.$d).format("YYYY-MM-DD"),
      }));
    } else {
      return false;
    }
  };

  const handleNextDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY-MM-DD"));
      return setPaymentCycle((prev) => ({
        ...prev,
        nextPaymentCycleDate: moment(newValue.$d).format("YYYY-MM-DD"),
      }));
    } else {
      return false;
    }
  };

  const handleIntervalChange = (e) => {
    let inputValue = e.target.value;

    // Remove leading zeros but allow a single '0' if the input is just '0'
    if (inputValue.length > 1 && inputValue.startsWith("0")) {
      inputValue = inputValue.slice(1);
    }

    // Update the state with only digits between 0 and 9
    if (/^\d*$/.test(inputValue)) {
      setPaymentCycle((prev) => ({ ...prev, interval: inputValue }));
    }
  };

  useEffect(() => {
    setUserId(props.userId);
  }, [props.userId]);

  useEffect(() => {
    CustomConsole("----- updateUserDetails.userProfileData ------");
    CustomConsole(props.updateUserDetails);

    CustomConsole("-----------enable_notification-------------");
    CustomConsole(
      props.updateUserDetails?.userProfileData?.consumer?.enable_notification
    );

    if (
      props.updateUserDetails?.userProfileData?.consumer
        ?.postpaid_gen_inv_order_completion !== undefined &&
      props.updateUserDetails?.userProfileData?.consumer
        ?.postpaid_gen_inv_order_completion !== null
    ) {
      setChangeEmailPostpaid(
        props.updateUserDetails?.userProfileData?.consumer
          ?.postpaid_gen_inv_order_completion
      );
    } else {
      setChangeEmailPostpaid(false);
    }
    if (
      props.updateUserDetails?.userProfileData?.consumer
        ?.enable_notification !== undefined &&
      props.updateUserDetails?.userProfileData?.consumer
        ?.enable_notification !== null
    ) {
      CustomConsole("-----------enable_notification-------------");
      setChangeNotification(
        props.updateUserDetails?.userProfileData?.consumer?.enable_notification
      );
    } else {
      CustomConsole("-----------Disavle_notification-------------");
      setChangeNotification(false);
    }

    if (props.updateUserDetails.userProfileData.consumer?.billing_name) {
      setBillname(
        props.updateUserDetails.userProfileData.consumer?.billing_name
      );
    } else {
      setBillname("");
      setGst("");
    }

    setGst(props.updateUserDetails.userProfileData.consumer?.gst);
    setProfileData(() => {
      return props.updateUserDetails.userProfileData.consumer;
    });
  }, [props.updateUserDetails.userProfileData]);
  useEffect(() => {
    if (
      props.updateUserDetails.userProfileData !== undefined &&
      props.updateUserDetails.userProfileData.consumer !== undefined &&
      props.updateUserDetails.userProfileData.consumer.user_type !==
        undefined &&
      props.updateUserDetails.userProfileData.consumer.user_type !== null &&
      props.renderFlag === true
    ) {
      CustomConsole(props.updateUserDetails.userProfileData.consumer.user_type);
      props.setInfluencerSetting(
        props.updateUserDetails.userProfileData.consumer.user_type === "111"
      );
      // if (
      //   props.updateUserDetails.userProfileData.consumer.user_type === "111"
      // ) {
      //   props.setInfluencerSetting(true);
      // } else {
      //   props.setInfluencerSetting(false);
      // }
      // setInfluencerValidFrom(
      //   props.updateUserDetails.userProfileData.consumer.valid_from
      // );
      // setInfluencerValidTill(
      //   props.updateUserDetails.userProfileData.consumer.valid_till
      // );
      props.setRenderFlag(false);
    }
  }, [props.updateUserDetails.userProfileData.consumer]);
  useEffect(() => {
    if (props.userId !== "") {
      dispatch(GetUserStockDetails(props.userId));
    }
  }, [props.bookingDetails]);
  useEffect(() => {
    console.log("-------Consumer Profile Details-------");
    console.log(profileData);
    if (profileData && Object.keys(profileData).length) {
      CustomConsole(profileData.is_b2c);
      setIs_b2c(profileData.is_b2c ? profileData.is_b2c : false);
      props.setFinanceEmail(
        profileData.finance_email ? profileData.finance_email : ""
      );
      if (profileData.payment_cycle && profileData.payment_cycle.startDate) {
        setPaymentCycle((prev) => ({
          ...prev,
          isFirstTime: false,
          startDate: profileData.payment_cycle.startDate,
          nextPaymentCycleDate: profileData.payment_cycle.nextPaymentCycleDate,
          interval: profileData.payment_cycle.interval,
        }));
      }
    }
  }, [profileData]);

  useEffect(() => {
    CustomConsole("----->>>>>This is the b2c enable<<<<<-----");

    setIs_b2b(!is_b2c);
  }, [is_b2c]);

  useEffect(() => {
    CustomConsole("----->>>>>This is the b2c enable<<<<<-----");

    CustomConsole(is_b2b);
  }, [is_b2b]);
  useEffect(() => {
    CustomConsole("----->>>>>This is the b2c enable<<<<<-----");

    setIs_b2b(!is_b2c);
  }, [is_b2c]);
  useEffect(() => {
    setUserId(props.userId);
  }, [props]);
  useEffect(() => {
    CustomConsole("-------influencer details------------------");
    CustomConsole(props.influencerDetails);
  }, [props.influencerDetails]);
  // useEffect(() => {
  //   CustomConsole(props.influencerSetting);
  //   if (props.influencerSetting === true && changeInInfluencer === true) {
  //     const reqObj = {
  //       user_id: props.userId,
  //       user_type: 111,
  //       valid_from: "",
  //       valid_till: "",
  //     };
  //     dispatch(InfluencerEnableDisable(reqObj));
  //     setChangeInInfluencer(false);
  //   } else if (
  //     props.influencerSetting === false &&
  //     changeInInfluencer === true
  //   ) {
  //     const reqObj = {
  //       user_id: props.userId,
  //       user_type: 113,
  //       valid_from: "",
  //       valid_till: "",
  //     };
  //     dispatch(InfluencerEnableDisable(reqObj));
  //     setChangeInInfluencer(false);
  //   }
  // }, [props.influencerSetting]);
  useEffect(() => {
    if (changeInSwitch === true && props.isSwitchOn === true) {
      toast.success(props.updateUserDetails.enableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
    if (changeInSwitch === true && props.isSwitchOn === false) {
      toast.success(props.updateUserDetails.disableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
  }, [props.updateUserDetails]);
  // useEffect(() => {
  //   CustomConsole("----hitting----userstock----");
  //   CustomConsole(props.userUpdates);
  //   if (props.userUpdates.userStockData.length > 0) {
  //     //   setUserStockData(props.userUpdates.userStockData);
  //   }
  //   if (props.userUpdates.error === false && toastFlag) {
  //     toast.success(props.userUpdates.msg, {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //     setToastFlag(false);
  //     setRequestDialogOpen(false);
  //   }
  //   if (props.userUpdates.error === true && toastFlag) {
  //     toast.error(props.userUpdates.msg, {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //     setToastFlag(false);
  //     setRequestDialogOpen(false);
  //   }
  // }, [props.userUpdates]);

  useEffect(() => {
    CustomConsole("setting min and max cans values");
    CustomConsole(value);
    if (value.length) {
      setMax(value[2].v);
      setMin(value[3].v);
    }
    CustomConsole(postpaidPercentageValue);
    if (postpaidPercentageValue.length) {
      setPostPaidPercentage(postpaidPercentageValue[0].v);
    }
  }, [value, postpaidPercentageValue]);

  useEffect(() => {
    CustomConsole(props.bookingDetails);
    CustomConsole(props.bookingDetails.availableAreas.length);
    if (props.bookingDetails.subscriptionOrderError === false) {
      dispatch(GetSubscriptionOrderDetails(props.userId));
    }
    if (Object.keys(props.bookingDetails.postPaidBookingData).length) {
      // CustomConsole("orderId");
      // CustomConsole(bookingDetails.postPaidBookingData.order_id[0]);
      if (props.bookingDetails.postPaidBookingData.error === false) {
        setPostpaidInitiated(true);
      } else {
        setPostpaidInitiated(false);
      }
    } else {
      setPostpaidInitiated(false);
    }

    if (props.bookingDetails.error === false) {
      if (Object.keys(props.bookingDetails.booknowOrderData).length > 0) {
        CustomConsole("Setting PDW Object here");
        setPackagedDrinkingWaterData(
          props.bookingDetails.booknowOrderData.packagedrinkingwater
        );
      }

      if (props.bookingDetails.setupConfigData.length) {
        props.bookingDetails?.setupConfigData?.map((data) => {
          if (data.key === "cansminmax") {
            setValue(data.value);
          } else if (data.key === "postpaidcharges") {
            setPostpaidPercentageValue(data.value);
          } else if (data.key === "replacementReasons") {
            setReplacementReasons(data.value);
          }
        });
      }
    }
  }, [props.bookingDetails]);
  useEffect(() => {
    CustomConsole("--------updateUserDetails---------");
    CustomConsole(props.updateUserDetails);
    // CustomConsole(updateUserDetails.userProfileData.length);
    // CustomConsole(updateUserDetails.userProfileData.consumer);
    if (props.updateUserDetails.error === false) {
      //   setIsrevertbackenabled(props.updateUserDetails.is_revert_back_enabled);
      // CustomConsole(updateUserDetails.userProfileData.length);
      // CustomConsole(Object.keys(updateUserDetails.userProfileData).length);
      if (
        props.updateUserDetails.userProfileData !== undefined &&
        props.updateUserDetails.userProfileData !== null &&
        Object.keys(props.updateUserDetails.userProfileData).length > 0
      ) {
        CustomConsole("--------updateUserDetails---------");
        CustomConsole(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        CustomConsole(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        setTwntyLtrDiscount(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        setCustomPrice(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        CustomConsole("--------custom_twentyltr_price---------");
      }
      setUpdateUserBtn(false);
      // setUpdateFlagCheck(false);
    }
    if (props.updateUserDetails.error === false && updateUserBtn === true) {
      CustomConsole("Profile");

      CustomConsole("Profile");
      dispatch(GetProfileDetails(props.userId));
      setUpdateUserBtn(false);
    }
    // setTwntyLtrDiscount("");
    // setCustomPrice("");
  }, [props.updateUserDetails]);
  useEffect(() => {
    CustomConsole(props.adminUsers);

    props.adminUsers?.map((data, key) => {
      // setPhone(data.phone);
      // setUserId(data.id);
      // dispatch(GetProfileDetails(data.id));
      // dispatch(GetDepositDetails(data.id));
      // dispatch(GetUserAddressAdmin(data.id));
      setConsumerName(data.fullname);
      setConsumerId(data.consumer_id);
    });
  }, [props.adminUsers]);

  useEffect(() => {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var result = reg.test(props.email);
    var emailResult = false;
    if (props.roleId === 3000) {
      let regEmail = /^[^@]+@bookwater\.com$/;
      emailResult = regEmail.test(props.email);
    }
    if (updateUserBtn === true) {
      CustomConsole(parseInt(twntyLtrDiscount) < parseInt(customPrice));
      if (
        props.phone.length === 10 &&
        result === true &&
        props.fullname.trim() !== "" &&
        emailResult === false
      ) {
        dispatch(
          UpdateUserDetails(
            props.fullname,
            props.phone,
            props.email,
            props.roleId,
            twntyLtrDiscount || 0,
            props.userId,
            distributorType,
            customPrice
          )
        );
      } else {
        toast.error("Please update valid details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUpdateUserBtn(false);
      }

      setUpdateUserBtn(false);
    }
  }, [updateUserBtn]);
  useEffect(() => {
    CustomConsole(props.userUpdates);
    if (props.userUpdates.address.length) {
      CustomConsole("Storing user address");
      // If User has primary address marked that address will be set ad current address.
      // If not unmarked primary address will be set as current address.
      const primaryAddress = props.userUpdates.address.find(
        (data) => data.is_primary === true
      );
      CustomConsole(primaryAddress);
      const addressToStore = primaryAddress
        ? primaryAddress
        : props.userUpdates.address.find((data) => data.is_primary === false);

      CustomConsole(addressToStore);
      // setCurrentAddress(addressToStore);
    }
    props.userUpdates?.accounts?.map((data) => {
      // setUserId(data.user_id);
    });

    if (Object.keys(props.userUpdates.subscriptionDetails).length !== 0) {
      setSubscriptionExists(true);
    } else {
      setSubscriptionExists(false);
    }
    if (
      props.userUpdates.updateMsgSubscription !== "" &&
      subscriptionBtn === true
    ) {
      CustomConsole("subscription msg");
      toast.success(props.userUpdates.updateMsgSubscription, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setSubscriptionBtn(false);
      CustomConsole(props.userUpdates.subscriptionDetailsError);
      if (props.userUpdates.subscriptionDetailsError === false) {
        setSubscriptionData(props.userUpdates.subscriptionDetails);
        setDepositData(props.userUpdates.userDepositData);
        setStockData(props.userUpdates.userStockData);
        setOpenSubscriptionDialog(true);
      }
    }
  }, [props.userUpdates]);

  useEffect(() => {
    setUrl(
      `https://webapp.bookwater.com/authenticate?referrer=${props.referralCode}`
    );
  }, [props.referralCode]);

  const handleQr = (event) => {
    setUrl(event.target.value);
  };

  useEffect(() => {
    if (subscriptionFlag === true) {
      dispatch(GetSubscriptionOrderDetails(props.userId));
      setSubscriptionFlag(false);
    }
  }, [subscriptionFlag]);
  useEffect(() => {
    CustomConsole(packagedrinkingwaterData);
    CustomConsole("PWD Object set use Effect");
    if (
      Object.keys(packagedrinkingwaterData).length > 0 &&
      props.orderBtn === true
    ) {
      if (
        packagedrinkingwaterData.twenty_ltr_amount !== 0 &&
        packagedrinkingwaterData.twenty_ltr_amount !== null
      ) {
        CustomConsole("setting PDW data");
        CustomConsole(props.bookingDetails.booknowOrderData);
        setPwdData(props.bookingDetails.booknowOrderData);
        setCanPrice(
          props.bookingDetails.booknowOrderData.packagedrinkingwater
            .twenty_ltr_amount -
            props.bookingDetails.booknowOrderData.packagedrinkingwater
              .packagedDrinkingwaterUserDiscount.twenty_ltr_discount
        );
        setOpenPdwOrderDialog(true);
        props.setOrderBtn(false);
      } else {
        CustomConsole("Not available for this area");
        toast.error("Not available for this pincode area", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      CustomConsole(packagedrinkingwaterData);
    }
  }, [packagedrinkingwaterData]);
  useEffect(() => {}, [props.updateUserDetails.address]);
  useEffect(() => {
    if (props.walletBalance < orderPrice) {
      setLowWalletBalance(true);
    } else {
      setLowWalletBalance(false);
    }
  }, [orderPrice, props.walletBalance]);
  // On component Mounting
  useEffect(() => {
    //
    if (
      props.updateUserDetails?.userStockData &&
      props.updateUserDetails.userStockData.length > 0 &&
      stockCapacity.length <= 0
    ) {
      setStockCapacity(
        () =>
          props.updateUserDetails.userStockData[0].container_capacity +
          "-" +
          props.updateUserDetails.userStockData[0].container_material
      );
    }
  }, [props.updateUserDetails?.userStockData]);

  useEffect(() => {
    if (
      props.updateUserDetails?.address &&
      props.updateUserDetails?.address.length > 0
    ) {
      setAddressnamePhone(() => props.updateUserDetails?.address[0].address_id);
    }
  }, [props.updateUserDetails?.address]);
  useEffect(() => {
    if (props.updateUserDetails?.userStockData && stockCapacity) {
      const output = props.updateUserDetails?.userStockData?.find((obj) => {
        return (
          obj.container_capacity + "-" + obj.container_material ===
          stockCapacity
        );
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      // setSelectedStock(output);
      if (output) {
        setSingleSelectedStockData(output);
      } else {
        setSingleSelectedStockData({});
      }
    }
  }, [props.updateUserDetails?.userStockData, stockCapacity]);

  useEffect(() => {
    if (props.updateUserDetails?.address && addressnamePhone) {
      const output = props.updateUserDetails?.address?.find((obj) => {
        return obj.address_id === addressnamePhone;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      // setSelectedStock(output);
      if (output) {
        setSingleSelectedAddressData(output);
      } else {
        setSingleSelectedAddressData({});
      }
    }
  }, [props.updateUserDetails?.address, addressnamePhone]);

  useEffect(() => {
    if (
      props.updateUserDetails?.accounts &&
      props.updateUserDetails?.accounts.length > 0
    ) {
      setAccountNumber(props.updateUserDetails?.accounts[0].account_id);
    }
  }, [props.updateUserDetails?.accounts]);

  useEffect(() => {
    if (props.updateUserDetails?.accounts && accountNumber) {
      const output = props.updateUserDetails?.accounts?.find((obj) => {
        return obj.account_id === accountNumber;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      // setSelectedStock(output);
      if (output) {
        setSingleSelectedAccountData(output);
      } else {
        setSingleSelectedAccountData({});
      }
    }
  }, [props.updateUserDetails?.accounts, accountNumber]);

  useEffect(() => {
    CustomConsole("-------Booking Details-------");
    CustomConsole(props.bookingDetails);
    if (walletTrigger === true) {
      CustomConsole(props.bookingDetails.bookingOrderId);
      const data = {
        transaction_ts: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        amount: orderPrice,
        order_id: props.bookingDetails.bookingOrderId,
        payment_method: "WATERWALLET",
        payment_type: "PREPAID",
        user_id: props.userId,
      };

      CustomConsole(props.walletBalance >= orderPrice);
      CustomConsole(paymentType);
      CustomConsole(props.bookingDetails.bookingOrderId);
      if (
        props.walletBalance >= orderPrice &&
        paymentType === 3 &&
        props.bookingDetails.bookingOrderId !== ""
      ) {
        CustomConsole(data);
        dispatch(PayThroughWallet(data));
      } else {
        CustomConsole("Low balance");
      }
      setWalletTrigger(false);
    }
  }, [props.bookingDetails.bookingOrderId]);
  useEffect(() => {
    if (
      props.updateUserDetails.deleteUserAddressMsg !== "" &&
      deleteUserBtn === true
    ) {
      // toast.success(props.updateUserDetails.deleteUserAddressMsg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setDeleteUserBtn(false);
      setTimeout(() => {
        dispatch(GetUserAddressAdmin(props.userId));
      }, 1000);
    }
  }, [props.updateUserDetails]);
  const orderStatus = (order_status) => {
    switch (order_status) {
      case ordersStatus.ORDER_STATUS_NEW_REQUEST:
        return "NEW REQUEST";
      case ordersStatus.ORDER_STATUS_CANCELLED:
        return "CANCELLED";
      case ordersStatus.ORDER_STATUS_ACCEPTED:
        return "ACCEPTED ";
      case ordersStatus.ORDER_STATUS_REJECTED:
        return "REJECTED";
      case ordersStatus.ORDER_STATUS_DELIVERED:
        return "DELIVERED";
      default:
        return "";
    }
  };

  // const handleChangeInfluencer = (event) => {
  //   CustomConsole(event.target.checked);
  //   props.setInfluencerSetting(event.target.checked);
  //   setChangeInInfluencer(true);
  // };

  const handleChangeInfluencer = (event) => {
    CustomConsole("------handleSwitchDeliverySlots------");
    // const reqObj = {
    //   user_id: props.userId,
    //   delivery_slots_status: true,
    // };
    if (event.target.checked) {
      // props.setDeliverySlotStatus(true);
      const reqObj = {
        user_id: props.userId,
        user_type: 111,
        valid_from: "",
        valid_till: "",
      };
      dispatch(InfluencerEnableDisable(reqObj));
      // setChangeInInfluencer(true);
      props.setInfluencerSetting(true);
    } else if (event.target.checked === false) {
      const reqObj = {
        user_id: props.userId,
        user_type: 113,
        valid_from: "",
        valid_till: "",
      };
      dispatch(InfluencerEnableDisable(reqObj));
      // setChangeInInfluencer(false);
      props.setInfluencerSetting(false);
    }
  };

  const handleChangeSwitch = (event) => {
    setChangeInSwitch(true);
    if (event.target.checked) {
      props.setIsSwitchOn(true);
      dispatch(EnableUserAdmin(props.userId));
    } else if (event.target.checked === false) {
      props.setIsSwitchOn(false);
      dispatch(DisableUserAdmin(props.userId));
    }
  };

  const handleChangeSwitchNotification = (event) => {
    if (event.target.checked === true) {
      setChangeNotification(true);
    } else if (event.target.checked === false) {
      setChangeNotification(false);
    }
  };
  const handleSwitchEmailPostPaidInvoice = (event) => {
    if (event.target.checked === true) {
      setChangeEmailPostpaid(true);
    } else if (event.target.checked === false) {
      setChangeEmailPostpaid(false);
    }
  };
  const handleChangeGstNum = (event) => {
    CustomConsole(event.target.value.split(""));
    setGst(event.target.value);
    if (event.target.value.split("").length === 0) {
      CustomConsole("---inside the condition---");
      setGstFieldError(false);
    } else {
      let reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      var result = reg.test(event.target.value);
      CustomConsole(result);
      setGstFieldError(!result);
    }
  };

  const handleChangeAddressNamePhone = (event) => {
    // const selectedAddresssData = props.updateUserDetails?.address.find(
    //   (data) => data.address_id === event.target.value
    // );
    // CustomConsole(selectedAddresssData);
    // if (selectedAddresssData) {
    //   setSingleSelectedAddressData(selectedAddresssData);
    // } else {
    //   const emptyObject = {
    //     service_area: "-",
    //     contact_phone: "-",
    //   };
    //   setSingleSelectedAddressData(emptyObject);
    // }
    CustomConsole("--------address Id---------");

    CustomConsole(event.target.value);
    setAddressnamePhone(event.target.value);
  };
  const handleChangeStock = (event) => {
    // const selectedStockData = props.updateUserDetails?.userStockData.find(
    //   (data) => data.container_capacity === event.target.value
    // );
    // CustomConsole(selectedStockData);
    // if (selectedStockData) {
    //   setSingleSelectedStockData(selectedStockData);
    // }
    setStockCapacity(event.target.value);
  };
  const handleChangeACcount = (event) => {
    // const selectedAccountData = props.updateUserDetails?.accounts.find(
    //   (data) => data.account_id === event.target.value
    // );
    // CustomConsole(selectedAccountData);
    // if (selectedAccountData) {
    //   setSingleSelectedAccountData(selectedAccountData);
    // }
    setAccountNumber(event.target.value);
  };
  const handleReturnStock = () => {
    CustomConsole("------------handleReturnStock------------------");
    setAddReduceReturn(true);
    setStockUpdates("Add Stock");
  };
  const handleEditAddress = (data) => {
    CustomConsole(data);
    if (data.service_area !== "-") {
      setIsEditAddressDialogOpen(true);
    } else {
      toast.error("Select Address", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    setAddress(data);
  };
  const handleEditAddressSave = (data, addressId, userId) => {
    dispatch(EditUserAddress(data, addressId, userId));
    // setSaveAddressFlag(true);
    setAddressId(addressId);
    // setTimeout(() => {
    //   dispatch(GetUserAddressAdmin(props.userId));
    // }, 1000);
  };

  const handleDeleteAddress = (data) => {
    CustomConsole(data);
    if (data.user_id && data.address_id) {
      dispatch(DeleteUserAddress(data.user_id, data.address_id));
    } else {
      toast.error("Select Address", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    setDeleteUserBtn(true);
  };
  const handleOrderListContactName = (contactname) => {
    const url = `/dashboard/orders?phoneNumber=${contactname}`;
    history.push(url);
  };

  const handleClickRedeemWaterDrop = () => {
    CustomConsole("Redeem water drop");
    setOpenRedeem(true);
  };
  // handleing edit referral code click
  const handleClickEditReferral = () => {
    const reqObj = {
      user_id: props.userId,
      edited_refCode: props.referralCode,
    };
    dispatch(UpdateReferralCode(reqObj));

    setTimeout(() => {
      dispatch(GetReferralCode(props.userId));
    }, 1000);
  };
  const handleChangeReferralCode = (event) => {
    CustomConsole(event.target.value);
    const inputValue = event.target.value;
    if (inputValue.length <= 10) {
      props.setReferralCode(inputValue);
    }
  };
  // make me as influencer function
  const handleInfluencerReq = () => {
    const reqObj = {
      pan_number: "",
      bank_name: "",
      ifsc_number: "",
      account_number: "",
      user_id: props.userId,
    };
    dispatch(MakeUserInfluencer(reqObj));
  };
  const handleUpdateUserDetails = () => {
    setUpdateUserBtn(true);
    setTimeout(() => {
      dispatch(GetProfileDetails(props.userId));
    }, 1000);
  };
  const handleCloseDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };
  const handleDeleteUserDialog = () => {
    dispatch(DeleteUser(props.userId));
    props.setIsUserDetailsOpen(false);
    setDeleteUserDialog(false);
    setTimeout(() => {
      dispatch(GetProfileDetails(props.userId));
    }, 500);
  };
  const handleDeleteUser = () => {
    // dispatch(DeleteUser(userId));
    // setIsUserDetailsOpen(false);
    setDeleteUserDialog(true);
  };
  const handleOpenAccountClosure = () => {
    setOpenAccountClosure(true);
  };
  const replacementDialogData = () => {
    //Replacement dialogue open and data sending
    props.updateUserDetails.userStockData.forEach((value) => {
      setContainerCapacity((capacity) => [
        ...capacity,
        value.container_capacity,
      ]);
      setContainerMaterial((material) => [
        ...material,
        value.container_material,
      ]);
    });
  };

  const handleChangeBookNow = (event) => {
    setBookNow(event.target.value);
    if (event.target.value === 10) {
      CustomConsole(singleSelectedAddressData.pincode);
      if (
        singleSelectedAddressData.pincode !== undefined &&
        singleSelectedAddressData.pincode !== null &&
        singleSelectedAddressData.pincode !== ""
      ) {
        // CustomConsole("Search area API");
        // CustomConsole(props.userId);
        // setPostpaidInitiated(false);
        // // dispatch(SearchAreasByPincode(data.pincode));
        // // dispatch(GetUserAddressAdmin(userId));
        // dispatch(GetDepositDetails());
        // dispatch(GetUserDepositDetails(props.userId));
        // dispatch(GetSetUpConfigAction());
        // setCurrentAddress(singleSelectedAddressData);
        setBookNow("");
        setOpenBookingDialog(true);
        setBookNowType(10);
      } else {
        toast.error("Select Address", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      // setIsSubscriptionOrder(false);
      // const reqObj = {
      //   tankerType: 3,
      //   pincode: singleSelectedAddressData.pincode,
      //   phone: props.phone,
      //   status: false,
      //   area: singleSelectedAddressData.service_area,
      // };
      // CustomConsole(reqObj);
      // // setOpenPdwOrderDialog(true);
      // if (singleSelectedAddressData.service_area !== "") {
      //   dispatch(BooknowOrderDetails(reqObj));
      //   props.setOrderBtn(true);
      //   setBookNow("");
      // } else {
      //   toast.error("Select area", {
      //     position: "bottom-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "dark",
      //   });
      // }
    } else if (event.target.value === 20) {
      setAddReduceReturn(true);
      setStockUpdates("Return Stock");
      setBookNow("");
    } else if (event.target.value === 30) {
      //Replacement dialogue open and data sending
      setRequestDialogOpen(true);
      replacementDialogData();
      setBookNow("");
    } else if (event.target.value === 40) {
      setSubscriptionFlag(true);
      setSubscriptionBtn(true);
      setBookNow("");
      setBookNowType(40);
    } else if (event.target.value === 50) {
      setbuyBackOrderOpen(true);
      setBookNowType(50);
    } else if (event.target.value === 50) {
      setbuyBackOrderOpen(true);
      setBookNowType(50);
    }
  };
  const handleUpdateGstBillName = () => {
    // CustomConsole(billname.trim() !== "");
    if (gstFieldError === false) {
      dispatch(
        UpdateGstDetails({
          billing_name: billname,
          gst,
          userId,
          is_b2c: !is_b2b,
          enable_notification: changeNotification,
          postpaid_gen_inv_order_completion: changeEmailPostpaid,
          payment_cycle: paymentCycle,
          finance_email: props.financeEmail,
        })
      );
    } else {
      toast.error("Please Enter Valid Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  //function for handling add product dialog box
  CustomConsole(productData);
  const handelConformData = (data) => {
    CustomConsole(data);
    const filteredProduct = data?.products?.filter(
      (obj) => Object.keys(obj).length !== 0
    );
    setProductData(filteredProduct);
    setAddressId(data.address_id);
    setAddressAddProduct(true);
  };

  const handleChangeToggleTransactionOrders = (event, newAlignment) => {
    setAlignmentTransactionsOrders(newAlignment);
  };

  const handleCloseRefundDialog = () => {
    setOpenRefundDialog(false);
  };

  const hadleSubmitRefund = (data) => {
    const reqObj = {
      capacity: data.capacity,
      material: data.material,
      quantity: data.quantity,
      is_crate: data.is_crate,
      user_id: props.userId,
    };
    CustomConsole(reqObj);
    dispatch(PostRefundConsumerStock(reqObj));
    setOpenRefundDialog(false);
    setTimeout(() => {
      dispatch(GetUserStockDetails(props.userId));
      dispatch(GetWalletDetails(props.userId));
    }, 1000);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("user-QR");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    const fileName = `${props.fullname}_${props.phone}`;
    downloadLink.download = `${fileName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const toggleRow = (index) => {
    const isOpen = open[index];
    const newOpenRows = [...open];
    newOpenRows[index] = !isOpen;
    setOpen(newOpenRows);
  };

  const handleOpenCreateGiftCard = () => {
    setIsBookwaterConsumer("YES");
    setOpenCreateGiftCard(true);
  };

  const handleSubmitGiftCard = (data) => {
    console.log("data", props);
    const reqObj = {
      amount: parseInt(data.amount),
      isPasswordRequired: data.isPasswordRequired,
      redeemThrough: data.redeemThrough,
      receiver: {
        phone: props.phone,
        email: props.email,
        isLoadGiftMoneyDirectly: data.isLoadWalletDirectly,
      },
      userGreeting: {
        ownWishes: data.ownWishes || undefined,
        selecetedCategory: data.selecetedCategory || undefined,
        selectedGreetingImage: data.selectedGreetingImage || undefined,
      },
    };
    console.log(reqObj);
    if (
      data.amount !== "" &&
      data.isPasswordRequired !== "" &&
      data.redeemThrough !== "" &&
      data.isLoadWalletDirectly !== "" &&
      props.phone !== "" &&
      props.email !== ""
    ) {
      dispatch(PostCreateSingleUserGiftCard(reqObj));
    }
    setOpenCreateGiftCard(false);
  };
  const handleCloseGitCard = () => {
    setOpenCreateGiftCard(false);
  };
  return (
    <>
      <Material.Box>
        <Material.Grid container spacing={1}>
          <Material.Grid item md={3.5} lg={3.5} xs={12}>
            <Material.Box sx={{ mb: 1, ml: 1, mr: 1 }}>
              <Material.Box>
                <Material.Paper
                  sx={{ borderRadius: "10px", padding: "10px" }}
                  elevation={3}
                >
                  <Material.Box>
                    {parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_SUPERADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_ADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CSHEAD ? (
                      <Material.Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <Material.Button
                          variant="contained"
                          size="small"
                          color="error"
                          // sx={{
                          //   background: appColors.commonBtnColor.background,
                          // }}
                          sx={{ borderRadius: "30px" }}
                          onClick={handleDeleteUser}
                        >
                          <DeleteForever /> */}
                        <Material.Button
                          disabled={
                            settingsDetails?.getGiftCarddata?.gift_card_settings
                              ?.isEnable === false
                          }
                          variant="contained"
                          size="small"
                          color="success"
                          onClick={handleOpenCreateGiftCard}
                        >
                          <Add />
                          Gift Card
                        </Material.Button>
                        <Material.Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={handleOpenAccountClosure}
                        >
                          <DeleteForever />
                        </Material.Button>
                      </Material.Box>
                    ) : (
                      ""
                    )}
                    <Material.Box
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        style={{ height: "50px", width: "50px" }}
                        src={userLogo}
                        alt=""
                      />
                    </Material.Box>
                  </Material.Box>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Material.TextField
                      label="FullName"
                      size="small"
                      value={props.fullname}
                      onChange={(event) =>
                        props.setFullname(event.target.value)
                      }
                      // sx={{ mb: "10px", width: "250px" }}
                    />
                    <Material.TextField
                      label="Phone"
                      size="small"
                      value={props.phone}
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={(event) => props.setPhone(event.target.value)}
                      // sx={{ mb: "10px", width: "250px" }}
                    />
                    <Material.TextField
                      label="Email"
                      size="small"
                      value={props.email}
                      onChange={(event) => props.setEmail(event.target.value)}
                      // sx={{ mb: "10px", width: "250px" }}
                    />

                    <Material.Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        // width: "250px",
                        mb: 1,
                        mt: 1,
                        // ml: { xs: 3.4, md: 1 },
                      }}
                    >
                      <Material.TextField
                        size="small"
                        label="Referral code"
                        sx={{ width: "100%" }}
                        InputProps={{ maxLength: 10 }}
                        value={props.referralCode}
                        onChange={handleChangeReferralCode}
                      />

                      <Material.Button
                        variant="contained"
                        size="small"
                        sx={{
                          bgcolor: "green",
                        }}
                        onClick={handleClickEditReferral}
                      >
                        Update
                      </Material.Button>
                    </Material.Box>

                    <Material.Box
                      sx={{
                        border: "2px solid black",
                        // padding:"5px",
                        pl: "20px",
                        // width: "250px",
                        // m: 1,
                        // ml: { xs: 3.4, md: 1 },
                      }}
                    >
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-around",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              // fontSize: "18px",
                              // fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            User
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          {parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_ADMIN ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_SUPERADMIN ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CSHEAD ? (
                            <Material.FormControlLabel
                              control={
                                <Material.Switch checked={props.isSwitchOn} />
                              }
                              label={
                                props.isSwitchOn ? (
                                  <Material.Typography
                                    sx={{
                                      color: "black",
                                    }}
                                  >
                                    Active
                                  </Material.Typography>
                                ) : (
                                  <Material.Typography
                                    sx={{
                                      color: "black",
                                    }}
                                  >
                                    InActive
                                  </Material.Typography>
                                )
                              }
                              onChange={handleChangeSwitch}
                            />
                          ) : (
                            ""
                          )}
                        </Material.Box>
                      </Material.Box>
                    </Material.Box>
                    <Material.Box
                      sx={{
                        border: "2px solid black",
                        // padding:"5px",
                        pl: "20px",
                        // width: "250px",
                        // m: 1,
                        // ml: { xs: 3.4, md: 1 },
                      }}
                    >
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-around",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              // fontSize: "18px",
                              // fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Influencer
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          {parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_ADMIN ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_SUPERADMIN ||
                          (parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CSHEAD &&
                            props.userRoleId === ADMIN_ROLES.BW_CONSUMER) ? (
                            <Material.FormControlLabel
                              control={
                                <Material.Switch
                                  checked={props.influencerSetting}
                                />
                              }
                              label={
                                props.influencerSetting ? (
                                  <Material.Typography>
                                    Active
                                  </Material.Typography>
                                ) : (
                                  <Material.Typography>
                                    InActive
                                  </Material.Typography>
                                )
                              }
                              onChange={handleChangeInfluencer}
                            />
                          ) : (
                            ""
                          )}
                        </Material.Box>
                      </Material.Box>
                    </Material.Box>

                    <Material.Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        mb: 1,
                        mt: 1,
                      }}
                    >
                      <Material.Button
                        variant="contained"
                        //   sx={{
                        //     background: appColors.commonBtnColor.background,
                        //   }}
                        size="small"
                        sx={{ fontSize: "10px", fontWeight: "bold" }}
                        onClick={handleInfluencerReq}
                      >
                        Make as influencer
                      </Material.Button>
                      <Material.Button
                        variant="contained"
                        size="small"
                        color="success"
                        sx={{ bgcolor: "green" }}
                        onClick={handleUpdateUserDetails}
                      >
                        <BrowserUpdated /> Update
                      </Material.Button>
                    </Material.Box>
                    <Material.Box sx={{ display: "flex" }}>
                      <Material.Box
                        sx={{ padding: "10px", bgcolor: "white" }}
                        ref={qrRef}
                      >
                        <Material.Typography>
                          Scan to Register with BookWater <br />
                          by the referrel code
                        </Material.Typography>
                        {/* {qrcode} */}
                        <QRCode
                          id="user-QR"
                          value={url}
                          size={100}
                          level={"H"}
                          includeMargin={true}
                        />
                      </Material.Box>
                      <Material.Box sx={{ mt: "110px" }}>
                        <Download onClick={downloadQRCode} />
                      </Material.Box>
                    </Material.Box>
                    <Material.Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Material.Button
                        variant="contained"
                        size="small"
                        sx={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          bgcolor: "green",
                        }}
                        onClick={() => setConformAddProduct(true)}
                      >
                        <Add /> Product
                      </Material.Button>
                      <Material.Button
                        variant="contained"
                        size="small"
                        onClick={handleClickRedeemWaterDrop}
                        sx={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          bgcolor: "brown",
                        }}
                      >
                        Request To Redeem{" "}
                        <img
                          src={waterDrop}
                          alt="icon"
                          style={{
                            height: "20px",
                            width: "12px",
                            marginLeft: "2px",
                          }}
                        />
                      </Material.Button>
                    </Material.Box>
                    {/* <Material.Paper
                      elevation={3}
                      sx={{ padding: "5px", mt: 1 }}
                    >
                      <Material.Box>
                        <Material.Box>
                          <Material.Typography sx={{ fontWeight: "bold" }}>
                            {" "}
                            Reffered By User Details
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            Reffered By Name :
                          </Material.Typography>
                          <Material.Typography
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              ml: "50px",
                              fontWeight: "bold",
                            }}
                          >
                            vinay kumar Inturu
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            Reffered By Phone :
                          </Material.Typography>
                          <Material.Typography
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              ml: "50px",
                              fontWeight: "bold",
                            }}
                          >
                            9177327808
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            Refferal Code :
                          </Material.Typography>
                          <Material.Typography
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              ml: "50px",
                              fontWeight: "bold",
                            }}
                          >
                            ACF$%GGH
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            // width: "250px",
                            mb: 1,
                            mt: 1,
                            // ml: { xs: 3.4, md: 1 },
                          }}
                        >
                          <Material.TextField
                            size="small"
                            label="Add Referred By Refferal Code"
                            sx={{ width: "100%" }}
                            InputProps={{ maxLength: 10 }}
                            value={props.referralCode}
                            onChange={handleChangeReferralCode}
                          />

                          <Material.Button
                            variant="contained"
                            size="small"
                            sx={{
                              bgcolor: "green",
                            }}
                            // onClick={handleClickEditReferral}
                          >
                            Add
                          </Material.Button>
                        </Material.Box>
                      </Material.Box>
                    </Material.Paper> */}
                    <Material.Paper
                      elevation={3}
                      sx={{
                        padding: "5px",
                        mt: 1,
                        border: "2px solid #21618c",
                      }}
                    >
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          {" "}
                          <Material.Box>
                            <Material.Typography
                              sx={{
                                // fontSize: "18px",
                                // fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Mark as B2B
                            </Material.Typography>
                          </Material.Box>
                          <Material.Box>
                            {/* {parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_ADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_SUPERADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CSHEAD ? ( */}
                            <Material.Switch
                              checked={is_b2b}
                              onChange={() => setIs_b2b((prev) => !prev)}
                              color="success"
                            />
                            {/* // ) : (
                    //   ""
                    // )} */}
                          </Material.Box>
                        </Material.Box>
                        <Material.TextField
                          label="Bill Name"
                          size="small"
                          value={billname}
                          onChange={(event) => setBillname(event.target.value)}
                          // sx={{ mb: "10px" }}
                          sx={{ width: "100%" }}
                        />
                        <Material.TextField
                          label="GST"
                          size="small"
                          value={gst}
                          error={gstFieldError}
                          onChange={handleChangeGstNum}
                          helperText={
                            gstFieldError && "Enter proper GST number"
                          }
                          sx={{ width: "100%" }}
                          // sx={{ mb: "10px" }}
                        />
                        <Material.TextField
                          label="Finance Email"
                          size="small"
                          value={props.financeEmail}
                          onChange={(event) =>
                            props.setFinanceEmail(event.target.value)
                          }
                          sx={{ width: "100%" }}
                          // sx={{ mb: "10px", width: "250px" }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Start From"
                            inputFormat="DD/MM/YYYY"
                            value={paymentCycle?.startDate}
                            onChange={handleFromDate}
                            disabled={!paymentCycle?.isFirstTime}
                            renderInput={(params) => (
                              <Material.TextField
                                {...params}
                                size="small"

                                // onKeyDown={handleInputChange}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {!paymentCycle?.isFirstTime && (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="Next Gen. Date"
                              inputFormat="DD/MM/YYYY"
                              value={paymentCycle?.nextPaymentCycleDate}
                              onChange={handleNextDate}
                              renderInput={(params) => (
                                <Material.TextField
                                  {...params}
                                  size="small"

                                  // onKeyDown={handleInputChange}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}

                        <Material.TextField
                          label="Enter Interval"
                          variant="outlined"
                          value={paymentCycle?.interval}
                          size="small"
                          onChange={handleIntervalChange}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />

                        <Material.Box
                          sx={{
                            border: "2px solid black",
                            // padding:"5px",
                            pl: "20px",
                            // width: "250px",
                            // m: 1,
                            // ml: { xs: 3.4, md: 1 },
                          }}
                        >
                          <Material.Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "space-around",
                              gap: "10px",
                            }}
                          >
                            {" "}
                            <Material.Box>
                              <Material.Typography
                                sx={{
                                  // fontSize: "18px",
                                  // fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                Notification
                              </Material.Typography>
                            </Material.Box>
                            <Material.Box>
                              {parseInt(localStorage.getItem("roleId")) ===
                                ADMIN_ROLES.BW_ADMIN ||
                              parseInt(localStorage.getItem("roleId")) ===
                                ADMIN_ROLES.BW_SUPERADMIN ||
                              parseInt(localStorage.getItem("roleId")) ===
                                ADMIN_ROLES.BW_CSHEAD ? (
                                <Material.FormControlLabel
                                  control={
                                    <Material.Switch
                                      checked={changeNotification}
                                    />
                                  }
                                  label={
                                    changeNotification ? (
                                      <Material.Typography
                                        sx={{
                                          color: "black",
                                        }}
                                      >
                                        Active
                                      </Material.Typography>
                                    ) : (
                                      <Material.Typography
                                        sx={{
                                          color: "black",
                                        }}
                                      >
                                        InActive
                                      </Material.Typography>
                                    )
                                  }
                                  onChange={handleChangeSwitchNotification}
                                />
                              ) : (
                                ""
                              )}
                            </Material.Box>
                          </Material.Box>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            border: "2px solid black",
                            // padding:"5px",
                            pl: "20px",
                            // width: "250px",
                            // m: 1,
                            // ml: { xs: 3.4, md: 1 },
                          }}
                        >
                          <Material.Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "space-around",
                              gap: "10px",
                            }}
                          >
                            {" "}
                            <Material.Box>
                              <Material.Typography
                                sx={{
                                  // fontSize: "18px",
                                  // fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                Post-Paid Email
                              </Material.Typography>
                            </Material.Box>
                            <Material.Box>
                              {parseInt(localStorage.getItem("roleId")) ===
                                ADMIN_ROLES.BW_ADMIN ||
                              parseInt(localStorage.getItem("roleId")) ===
                                ADMIN_ROLES.BW_SUPERADMIN ||
                              parseInt(localStorage.getItem("roleId")) ===
                                ADMIN_ROLES.BW_CSHEAD ? (
                                <Material.FormControlLabel
                                  control={
                                    <Material.Switch
                                      checked={changeEmailPostpaid}
                                    />
                                  }
                                  label={
                                    changeEmailPostpaid ? (
                                      <Material.Typography
                                        sx={{
                                          color: "black",
                                        }}
                                      >
                                        Active
                                      </Material.Typography>
                                    ) : (
                                      <Material.Typography
                                        sx={{
                                          color: "black",
                                        }}
                                      >
                                        InActive
                                      </Material.Typography>
                                    )
                                  }
                                  onChange={handleSwitchEmailPostPaidInvoice}
                                />
                              ) : (
                                ""
                              )}
                            </Material.Box>
                          </Material.Box>
                        </Material.Box>
                        <Material.Button
                          variant="contained"
                          // size="small"
                          // sx={{ height: "161px" }}
                          // sx={{
                          //   background: appColors.commonBtnColor.background,
                          // }}
                          onClick={() => handleUpdateGstBillName()}
                          sx={{ width: "100%", bgcolor: "green" }}
                        >
                          Update
                        </Material.Button>
                      </Material.Box>
                    </Material.Paper>
                  </Material.Box>
                </Material.Paper>
              </Material.Box>
            </Material.Box>
          </Material.Grid>
          <Material.Grid item md={8.5} lg={8.5} xs={12}>
            <Material.Box>
              <Material.Box sx={{ mb: 1, ml: 1, mr: 1 }}>
                <Material.Paper sx={{ borderRadius: "10px" }} elevation={3}>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { md: "space-between", xs: "center" },
                      // gap: "10px",
                    }}
                  >
                    <Material.Box>
                      <Material.Button
                        variant="contained"
                        size="small"
                        sx={{ mt: 1.5, ml: 1, bgcolor: "green" }}
                        // sx={{
                        //   background: appColors.commonBtnColor.background,
                        // }}
                        onClick={handleReturnStock}
                      >
                        Add Stock
                      </Material.Button>
                      <Material.Button
                        variant="contained"
                        size="small"
                        sx={{ mt: 1.5, ml: 1, bgcolor: "brown" }}
                        // sx={{
                        //   background: appColors.commonBtnColor.background,
                        // }}
                        onClick={() => setOpenRefundDialog(true)}
                      >
                        Refund
                      </Material.Button>
                    </Material.Box>
                    <Material.Box sx={{ mt: 2 }}>
                      <Material.Typography sx={{ fontWeight: "bold" }}>
                        STOCK DETAILS
                      </Material.Typography>
                    </Material.Box>

                    <Material.Box>
                      <Material.FormControl
                        sx={{ m: 1, width: 220 }}
                        size="small"
                      >
                        <Material.InputLabel>
                          Select Capacity/Material
                        </Material.InputLabel>
                        <Material.Select
                          value={stockCapacity}
                          label="Select Capacity/Material"
                          onChange={handleChangeStock}
                        >
                          {props.updateUserDetails?.userStockData?.map(
                            (data) => {
                              return (
                                <Material.MenuItem
                                  value={
                                    data.container_capacity +
                                    "-" +
                                    data.container_material
                                  }
                                >
                                  {data.container_material}/
                                  {data.container_capacity}
                                </Material.MenuItem>
                              );
                            }
                          )}
                        </Material.Select>
                      </Material.FormControl>
                    </Material.Box>
                  </Material.Box>
                  <Material.Grid
                    container
                    spacing={0.5}
                    sx={{ paddingLeft: "10px", paddingBottom: "10px" }}
                  >
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontSize: "14px",
                          color: "blue",
                          fontWeight: "bold",
                        }}
                      >
                        Container Material
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.container_material}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Container Capacity
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.container_capacity}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Deposit Amount
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.current_deposit_amount}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Deposit Containers
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.total_deposited_containers_qty}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Undeposited Containers
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.total_undeposited_containers_qty}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Filled Containers
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.filled_container_qty}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Containers Left
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.new_containers_to_provide}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Empty Containers
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedStockData.empty_container_qty}
                    </Material.Grid>
                  </Material.Grid>
                </Material.Paper>
              </Material.Box>
              <Material.Box sx={{ mb: 1, mr: 1 }}>
                {" "}
                <Material.Paper
                  sx={{ ml: 1, borderRadius: "10px" }}
                  elevation={3}
                >
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { md: "space-between", xs: "center" },
                      gap: "10px",
                    }}
                  >
                    {parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_SUPERADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_ADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CSHEAD ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CS ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CSLEAD ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_OPSHEAD ? (
                      <Material.Box sx={{ mt: 1.5, ml: 1 }}>
                        <Material.Button
                          variant="contained"
                          size="small"
                          // sx={{
                          //   background: appColors.commonBtnColor.background,
                          // }}
                          onClick={() => setAddressBtn(true)}
                          sx={{ bgcolor: "green" }}
                        >
                          Add Address
                        </Material.Button>
                      </Material.Box>
                    ) : (
                      ""
                    )}
                    <Material.Box sx={{ mt: 2 }}>
                      <Material.Typography sx={{ fontWeight: "bold" }}>
                        ADDRESS DETAILS
                      </Material.Typography>
                      <Material.Box sx={{ marginBottom: "5px" }}>
                        {singleSelectedAddressData.is_primary ? (
                          <Material.Chip
                            label={"Primary"}
                            sx={{
                              bgcolor: "green",
                              color: "white",
                              fontSize: "10px",
                            }}
                          />
                        ) : null}{" "}
                        &nbsp;
                        {singleSelectedAddressData?.is_billing_address ===
                        true ? (
                          <Material.Chip
                            label={"Billing"}
                            sx={{
                              bgcolor: "red",
                              color: "white",
                              fontSize: "10px",
                            }}
                          />
                        ) : null}{" "}
                      </Material.Box>
                    </Material.Box>

                    <Material.Box>
                      <Material.FormControl
                        sx={{ m: 1, width: 220 }}
                        size="small"
                      >
                        <Material.InputLabel>
                          Select Address
                        </Material.InputLabel>
                        <Material.Select
                          value={addressnamePhone}
                          label="Select Address"
                          onChange={handleChangeAddressNamePhone}
                        >
                          {props.updateUserDetails?.address?.map((data) => {
                            return (
                              <Material.MenuItem value={data.address_id}>
                                {data.contact_name}: {data.pincode}-
                                {data.service_area} &nbsp; &nbsp;
                                {data.is_primary ? (
                                  <>
                                    <Material.Chip
                                      label={"Primary"}
                                      sx={{ bgcolor: "green", color: "white" }}
                                    />
                                  </>
                                ) : null}{" "}
                                &nbsp;
                                {data.is_billing_address === true ? (
                                  <>
                                    <Material.Chip
                                      label={"Billing"}
                                      sx={{ bgcolor: "red", color: "white" }}
                                    />
                                  </>
                                ) : null}
                                &nbsp;
                              </Material.MenuItem>
                            );
                          })}
                        </Material.Select>
                      </Material.FormControl>
                    </Material.Box>
                  </Material.Box>
                  <Material.Grid
                    container
                    spacing={0.5}
                    sx={{ paddingLeft: "10px", paddingBottom: "10px" }}
                  >
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Contact Name
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.contact_name
                        ? singleSelectedAddressData.contact_name
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Contact Phone
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.contact_phone
                        ? singleSelectedAddressData.contact_phone
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Custom Price
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.custom_price
                        ? singleSelectedAddressData.custom_price
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Discount
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.discount
                        ? singleSelectedAddressData.discount
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Land Mark
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.landmark
                        ? singleSelectedAddressData.landmark
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Address Line 1
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      {singleSelectedAddressData.address_line1
                        ? singleSelectedAddressData.address_line1
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Address Line 2
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.address_line2
                        ? singleSelectedAddressData.address_line2
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Address Line 3
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.address_line3
                        ? singleSelectedAddressData.address_line3
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Service Area
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.service_area
                        ? singleSelectedAddressData.service_area
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Pincode
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.pincode
                        ? singleSelectedAddressData.pincode
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        City
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.city
                        ? singleSelectedAddressData.city
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Country
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.country
                        ? singleSelectedAddressData.country
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Email
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.email
                        ? singleSelectedAddressData.email
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Cans Capacity
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.details?.stock_capacity?.cans
                        ? singleSelectedAddressData.details?.stock_capacity
                            ?.cans
                        : 0
                        ? singleSelectedAddressData.details?.stock_capacity
                            ?.cans
                          ? singleSelectedAddressData.details?.stock_capacity
                              ?.cans
                          : 0
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Bottles Capacity
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.details?.stock_capacity
                        ?.bottles
                        ? singleSelectedAddressData.details?.stock_capacity
                            ?.bottles
                        : 0
                        ? singleSelectedAddressData.details?.stock_capacity
                            ?.bottles
                          ? singleSelectedAddressData.details?.stock_capacity
                              ?.bottles
                          : 0
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Crates Capacity
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.details?.stock_capacity?.crates
                        ? singleSelectedAddressData.details?.stock_capacity
                            ?.crates
                        : 0
                        ? singleSelectedAddressData.details?.stock_capacity
                            ?.crates
                          ? singleSelectedAddressData.details?.stock_capacity
                              ?.crates
                          : 0
                        : 0}
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ textAlign: "left" }}
                    >
                      <Material.Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "center",
                          m: 1,
                        }}
                      >
                        <Material.Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Material.Button
                            variant="contained"
                            size="small"
                            //   sx={{
                            //     background: appColors.commonBtnColor.background,
                            //   }}
                            onClick={() =>
                              handelConformData(singleSelectedAddressData)
                            }
                          >
                            <DriveFileRenameOutlineOutlined />
                            Product Details
                          </Material.Button>
                          {parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_SUPERADMIN ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_ADMIN ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CSHEAD ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CS ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CSLEAD ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_OPSHEAD ? (
                            <Material.Button
                              variant="contained"
                              size="small"
                              //   sx={{
                              //     background: appColors.commonBtnColor.background,
                              //   }}
                              onClick={() =>
                                handleEditAddress(singleSelectedAddressData)
                              }
                            >
                              <DriveFileRenameOutlineOutlined />
                              Edit
                            </Material.Button>
                          ) : (
                            ""
                          )}
                          {parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_SUPERADMIN ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_ADMIN ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CSHEAD ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CS ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_CSLEAD ||
                          parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_OPSHEAD ? (
                            <Material.Button
                              variant="contained"
                              size="small"
                              color="error"
                              //   sx={{
                              //     background: appColors.commonBtnColor.background,
                              //   }}
                              onClick={() =>
                                handleDeleteAddress(singleSelectedAddressData)
                              }
                            >
                              <DeleteOutlined />
                              Delete
                            </Material.Button>
                          ) : (
                            ""
                          )}
                        </Material.Box>
                        <Material.Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Material.FormControl
                            sx={{ m: 1, minWidth: 180 }}
                            size="small"
                            success
                          >
                            {/* <Material.InputLabel>Book Now</Material.InputLabel> */}
                            <Material.Select
                              value={0}
                              // label="Book Now"
                              onChange={handleChangeBookNow}
                            >
                              <Material.MenuItem value={0}>
                                <b>Booking options</b>
                              </Material.MenuItem>
                              <Material.MenuItem value={10}>
                                Regular
                              </Material.MenuItem>
                              <Material.MenuItem value={20}>
                                Return{" "}
                              </Material.MenuItem>
                              <Material.MenuItem value={30}>
                                Replacement
                              </Material.MenuItem>
                              <Material.MenuItem value={40}>
                                SubScription
                              </Material.MenuItem>
                              <Material.MenuItem
                                value={50}
                                name="Buy Back Products"
                              >
                                Buy Back Products
                              </Material.MenuItem>
                            </Material.Select>
                          </Material.FormControl>
                        </Material.Box>
                        <Material.Box>
                          {/* <Material.Button
                            startIcon={<ShoppingCartIcon />}
                            variant="contained"
                            onClick={() => setOpenBookingDialog(true)}
                          >
                            Order
                          </Material.Button> */}
                        </Material.Box>
                      </Material.Box>
                    </Material.Grid>
                  </Material.Grid>
                </Material.Paper>
              </Material.Box>
              <Material.Box sx={{ mb: 1, ml: 1, mr: 1 }}>
                <Material.Paper sx={{ borderRadius: "10px" }} elevation={3}>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { md: "flex-end", xs: "center" },
                      gap: { md: "100px", xs: "10px" },
                    }}
                  >
                    <Material.Box sx={{ mt: 2 }}>
                      <Material.Typography sx={{ fontWeight: "bold" }}>
                        ACCOUNT DETAILS
                      </Material.Typography>
                    </Material.Box>

                    <Material.Box>
                      <Material.FormControl
                        sx={{ m: 1, width: 220 }}
                        size="small"
                      >
                        <Material.InputLabel>
                          Select A/c Number
                        </Material.InputLabel>
                        <Material.Select
                          value={accountNumber}
                          label="Select A/c Number"
                          onChange={handleChangeACcount}
                        >
                          {props.updateUserDetails?.accounts?.map((data) => {
                            return (
                              <Material.MenuItem value={data.account_id}>
                                {data.account_number}/{data.bank_name}
                              </Material.MenuItem>
                            );
                          })}
                        </Material.Select>
                      </Material.FormControl>
                    </Material.Box>
                  </Material.Box>
                  <Material.Grid
                    container
                    spacing={0.5}
                    sx={{ paddingLeft: "10px", paddingBottom: "10px" }}
                  >
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Bank Name
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.bank_name}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Account Number
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.account_number}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        IFSC Code
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.ifsc_code}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Branch Name
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.branch_name}
                    </Material.Grid>
                  </Material.Grid>
                </Material.Paper>
              </Material.Box>
              <Material.Box sx={{ mb: 1, mr: 1 }}>
                <Material.Paper
                  sx={{ ml: 1, borderRadius: "10px", pt: 1 }}
                  elevation={3}
                >
                  <Material.Box
                    sx={{ display: "flex", justifyContent: "flex-start", m: 1 }}
                  >
                    <Material.ToggleButtonGroup
                      color="primary"
                      value={alignmentTransactionsOrders}
                      exclusive
                      onChange={handleChangeToggleTransactionOrders}
                      aria-label="Platform"
                      size="small"
                    >
                      <Material.ToggleButton value="Transactions">
                        Transactions
                      </Material.ToggleButton>
                      <Material.ToggleButton value="Orders">
                        Orders
                      </Material.ToggleButton>
                    </Material.ToggleButtonGroup>
                  </Material.Box>
                  {alignmentTransactionsOrders === "Transactions" ? (
                    <>
                      {parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_SUPERADMIN ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_ADMIN ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_CS ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_CSHEAD ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_CSLEAD ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_FINANCE ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_FINANCEHEAD ? (
                        <UserTransactions userId={props.userId} />
                      ) : (
                        <Material.Typography sx={{ color: "red" }}>
                          TRANSACTIONS ACCESS DENIED
                        </Material.Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {props?.orderList?.length > 0 ? (
                        <>
                          <Material.Box
                            sx={{
                              maxWidth: { md: "150vw", xs: "350px" },
                              overflow: "auto",
                            }}
                          >
                            <TableContainer component={Paper}>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    ></StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>S.no</strong>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <strong>Order Id</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>OTP</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Pincode</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Service Area</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Order Type</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="Large"
                                    >
                                      {" "}
                                      <strong>Order Status</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Consumer Name</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Consumer Number</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Supplier Name</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Delivery Slot</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Amount</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Discount Type</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Delivery Name</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Delivery Phone</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Booking Date</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Delivery Date</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Transaction Id</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Payment Type</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Payment Method</strong>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Payment Status</strong>
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <strong>Payment Created At</strong>
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {props?.orderList?.map((row, index) => (
                                    <>
                                      <StyledTableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                          cursor: "pointer",
                                          "&:hover": {
                                            boxShadow: "none",
                                            backgroundColor: "#D3E2F4",
                                          },
                                        }}
                                        // onClick={() => {
                                        //   handleOrderListClick(row.order_id);
                                        // }}
                                      >
                                        <StyledTableCell>
                                          <Material.IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => toggleRow(index)}
                                            sx={{
                                              bgcolor: "#E5E8E8",
                                              color: "black",
                                            }}
                                          >
                                            {open[index] ? (
                                              <KeyboardArrowUpIcon />
                                            ) : (
                                              <KeyboardArrowDownIcon />
                                            )}
                                          </Material.IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {/* {pageNumber > 1
                                          ? pageNumber * 10 + (key + 1) - 10
                                          : key + 1} */}
                                          {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {row.order_id}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.delivery_otp || "---"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.delivery_address?.pincode}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.delivery_address?.service_area}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="arge"
                                        >
                                          {row.order_type}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            // ...styles.cardStyleValue,
                                            color:
                                              row.order_type === "INSTA"
                                                ? "red"
                                                : row?.order_type === "POSTPAID"
                                                ? "#5DADE2"
                                                : row?.order_type ===
                                                  "SUBSCRIBED"
                                                ? "#808B96"
                                                : "#CA6F1E",
                                          }}
                                          align="center"
                                          size="Large"
                                        >
                                          {/* {orderStatus(row.order_status)} */}
                                          {row.order_status_name}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            textDecorationLine:
                                              row.consumer_name
                                                ? "underline"
                                                : "none",
                                            color: "blue",
                                          }}
                                          onClick={() => {
                                            handleOrderListContactName(
                                              row.consumer_phone
                                            );
                                          }}
                                          align="center"
                                          size="small"
                                        >
                                          {row.consumer_name}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            textDecorationLine:
                                              row.consumer_phone
                                                ? "underline"
                                                : "none",
                                            color: "blue",
                                          }}
                                          onClick={() => {
                                            handleOrderListContactName(
                                              row.consumer_phone
                                            );
                                          }}
                                          align="center"
                                          size="small"
                                        >
                                          {row.consumer_phone}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.supplier_name}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.packaged_drinking_water
                                            ?.delivery_slots
                                            ?.admin_selected_delivery_slot ||
                                            row.packaged_drinking_water
                                              ?.delivery_slots
                                              ?.supplier_selected_delivery_slot}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.order_price}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.packaged_drinking_water
                                            ?.isWaterDropUsed
                                            ? "Water drop"
                                            : row.packaged_drinking_water
                                                ?.promo_label
                                            ? `Promo code(${row.packaged_drinking_water?.promo_label})`
                                            : "---"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.delivery_address.contact_name}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.delivery_address.contact_phone}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.booking_date}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.delivery_date}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.payment_details
                                            ?.transaction_id || "---"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row?.payment_type === 300
                                            ? "POSTPAID"
                                            : row.payment_details
                                                ?.payment_type || "---"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.payment_details
                                            ?.payment_method || "---"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {row.payment_details
                                            ?.payment_status || "---"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          size="small"
                                        >
                                          {moment(
                                            row.payment_details?.created_at
                                          ).format("DD-MM-YYYY")}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      <StyledTableRow>
                                        <StyledTableCell
                                          style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                          }}
                                          colSpan={6}
                                        >
                                          <Material.Collapse
                                            in={open[index]}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <Material.Box sx={{ margin: 1 }}>
                                              <Material.Typography
                                                variant="h6"
                                                gutterBottom
                                                component="div"
                                              >
                                                Products
                                              </Material.Typography>
                                              <Table
                                                size="small"
                                                aria-label="purchases"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <StyledTableCell>
                                                      Capacity
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                      Material
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                      Order Quantity(packs)
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                      Return Quantity(units)
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                      SKU Size
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                      Scanned Empty
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                      Scanned Filled
                                                    </StyledTableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {!row.packaged_drinking_water
                                                    .is_invalid ? (
                                                    <TableRow key={100}>
                                                      <StyledTableCell
                                                        component="th"
                                                        scope="row"
                                                      >
                                                        {
                                                          row
                                                            .packaged_drinking_water
                                                            .selected_container_capacity
                                                        }
                                                      </StyledTableCell>
                                                      <StyledTableCell align="center">
                                                        {
                                                          row
                                                            .packaged_drinking_water
                                                            .selected_container_material
                                                        }
                                                      </StyledTableCell>
                                                      <StyledTableCell align="center">
                                                        {
                                                          row
                                                            .packaged_drinking_water
                                                            .twentyltr_capacity_qty
                                                        }
                                                      </StyledTableCell>
                                                      <StyledTableCell align="center">
                                                        {
                                                          row
                                                            .packaged_drinking_water
                                                            .empty_container_qty
                                                        }
                                                      </StyledTableCell>
                                                      <StyledTableCell align="center">
                                                        1
                                                      </StyledTableCell>
                                                      <StyledTableCell align="center">
                                                        {
                                                          row
                                                            .packaged_drinking_water
                                                            .received_empty_cans
                                                        }
                                                      </StyledTableCell>
                                                      <StyledTableCell align="center">
                                                        {
                                                          row
                                                            .packaged_drinking_water
                                                            .submitted_filled_cans
                                                        }
                                                      </StyledTableCell>
                                                    </TableRow>
                                                  ) : null}
                                                  {row.products.map(
                                                    (product, key) => (
                                                      <TableRow key={key}>
                                                        <StyledTableCell
                                                          component="th"
                                                          scope="row"
                                                        >
                                                          {product.capacity}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                          {product.material}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                          {" "}
                                                          {product.quantity}
                                                          <br />(
                                                          {product.quantity} *
                                                          Pack Of{" "}
                                                          {product.sku_qty}={" "}
                                                          {product.quantity *
                                                            product.sku_qty}
                                                          )
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                          {product.return}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                          {product.sku_qty}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                          {
                                                            product.consumer_submitted_empty
                                                          }
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                          {
                                                            product.consumer_received_filled
                                                          }
                                                        </StyledTableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </Material.Box>
                                          </Material.Collapse>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Material.Box>
                        </>
                      ) : (
                        <Material.Box sx={{ m: 2 }}>
                          No Data available
                        </Material.Box>
                      )}
                    </>
                  )}
                </Material.Paper>
              </Material.Box>
            </Material.Box>
          </Material.Grid>
        </Material.Grid>
      </Material.Box>
      {addReduceReturn ? (
        <>
          <AddReduceReturnDialog
            addReduceReturn={addReduceReturn}
            setAddReduceReturn={setAddReduceReturn}
            userId={props.userId}
            stockUpdates={stockUpdates}
            address={props.updateUserDetails.address}
            walletBalance={props.walletBalance}
            singleSelectedStockData={singleSelectedStockData}
            roleId={props.roleId}
          />
        </>
      ) : (
        ""
      )}
      {addressBtn && (
        <>
          <AddAddressDialog
            addressBtn={addressBtn}
            setAddressBtn={setAddressBtn}
            name={name}
            setName={setName}
            setDiscount={setDiscount}
            setVBottlesCapacity={setVBottlesCapacity}
            bottleCapacity={bottleCapacity}
            setCansCapacity={setCansCapacity}
            cansCapacity={cansCapacity}
            setCustomPrice={setCustomPrice}
            mobileNum={mobileNum}
            setMobileNum={setMobileNum}
            addressemail={addressemail}
            cratesCapacity={cratesCapacity}
            setCratesCapacity={setCratesCapacity}
            setaddressemail={setaddressemail}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            addressLine3={addressLine3}
            setAddressLine3={setAddressLine3}
            landmark={landmark}
            setLandmark={setLandmark}
            pincode={pincode}
            setPincode={setPincode}
            areaValue={areaValue}
            discount={discount}
            customPrice={customPrice}
            setAreaValue={setAreaValue}
            deliveryTime={deliveryTime}
            setDeliveryTime={setDeliveryTime}
            isPrimaryAddress={isPrimaryAddress}
            setIsPrimaryAddress={setIsPrimaryAddress}
            userIdA={props.userId}
          />
        </>
      )}
      {isEditAddressDialogOpen ? (
        <>
          <EditAddressDialog
            isEditAddressDialogOpen={isEditAddressDialogOpen}
            setIsEditAddressDialogOpen={setIsEditAddressDialogOpen}
            handleEditAddressSave={handleEditAddressSave}
            address={address}
            userId={props.userId}
          />
        </>
      ) : (
        ""
      )}
      <RedeemAmountToAccount
        setOpenRedeem={setOpenRedeem}
        openRedeem={openRedeem}
        userId={props.userId}
        accountDetails={props.updateUserDetails.accounts}
      />
      {deleteUserDialog && (
        <DeleteUserDialog
          deleteUserDialog={deleteUserDialog}
          handleDelete={handleDeleteUserDialog}
          handleClose={handleCloseDeleteUserDialog}
        />
      )}
      <ReplacementDialogue
        requestDialogOpen={requestDialogOpen}
        setRequestDialogOpen={setRequestDialogOpen}
        replacementReasons={replacementReasons}
        containerMaterial={containerMaterial}
        containerCapacity={containerCapacity}
        setContainerMaterial={setContainerMaterial}
        setContainerCapacity={setContainerCapacity}
        setToastFlag={setToastFlag}
        userId={props.userId}
      />
      {buyBackOrderOpen && (
        <BuyBackProductsOrdersDialog
          open={buyBackOrderOpen}
          setOpen={setbuyBackOrderOpen}
          userId={userId}
        />
      )}
      {openSubscriptionDialog ? (
        <>
          <SubscriptionDetailsDialog
            userData={props.userData}
            openSubscriptionDialog={openSubscriptionDialog}
            setOpenSubscriptionDialog={setOpenSubscriptionDialog}
            subscriptionData={subscriptionData}
            depositData={props.userUpdates.userDepositData}
            stockData={props.userUpdates.userStockData}
            userId={props.userId}
            setOpenBookingDialog={setOpenBookingDialog}
            subscriptionId={subscriptionId}
            setSubscriptionId={setSubscriptionId}
            editSubscriptionDialog={editSubscriptionDialog}
            setEditSubscriptionDialog={setEditSubscriptionDialog}
            editProducts={editProducts}
            setEditproducts={setEditproducts}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            setUpdateSubscriptonText={setUpdateSubscriptonText}
            editingAdrs={editingAdrs}
            setEditingAdrs={setEditingAdrs}
            setBooktypeSub={setBooktypeSub}
          />
        </>
      ) : (
        ""
      )}
      {openPdwOrderDialog ? (
        <>
          <PdwOrderDialog
            walletBalance={props.walletBalance}
            setOpenPdwOrderDialog={setOpenPdwOrderDialog}
            openPdwOrderDialog={openPdwOrderDialog}
            pdwData={pdwData}
            twentyLtrPrice={canPrice}
            depositData={props.userUpdates.userDepositData}
            userStockData={props.bookingDetails.userStockData}
            min={min}
            max={max}
            isSubscriptionOrder={isSubscriptionOrder}
            setIsSubscriptionOrder={setIsSubscriptionOrder}
            currentAddress={currentAddress}
            setCurrentAddress={setCurrentAddress}
            consumerName={consumerName}
            consumerArea={consumerArea}
            consumerId={consumerId}
            setShowPincodeToEnter={setShowPincodeToEnter}
            setPostpaidFinalPrice={setPostpaidFinalPrice}
            setBookOrderBtn={props.setBookOrderBtn}
            bookOrderBtn={props.bookOrderBtn}
            checkAvailability={props.checkAvailability}
            setCheckAvailability={props.setCheckAvailability}
            pincode={singleSelectedAddressData.pincode}
            area={singleSelectedAddressData.service_area}
            orderBtn={props.orderBtn}
            setOrderBtn={props.setOrderBtn}
            setPostpaidFlag={props.setPostpaidFlag}
            setWalletTrigger={setWalletTrigger}
            setOrderPrice={setOrderPrice}
            setPaymentType={setPaymentType}
            subscriptionExists={subscriptionExists}
            lowWalletBalance={lowWalletBalance}
          />
        </>
      ) : (
        ""
      )}
      <AddUpdateConsumerProductPrice
        conformAddProduct={conformAddProduct}
        setConformAddProduct={setConformAddProduct}
        userId={props.userId}
        data={props.updateUserDetails.userProfileData}
        area="product"
      />
      <AddUpdateConsumerProductPrice
        conformAddProduct={addressAddProduct}
        setConformAddProduct={setAddressAddProduct}
        userId={addressId}
        data={productData}
        area="address"
        useridaddress={props.userId}
      />
      {openRefundDialog && (
        <RefundDialog
          openRefundDialog={openRefundDialog}
          singleSelectedStockData={singleSelectedStockData}
          handleCloseRefundDialog={handleCloseRefundDialog}
          hadleSubmitRefund={hadleSubmitRefund}
        />
      )}
      {openAccountClosure && (
        <AccountClosureCard
          handleDeleteUser={handleDeleteUser}
          open={openAccountClosure}
          setOpen={setOpenAccountClosure}
          user_id={props.userId}
          waterBalance={props.walletBalance}
        />
      )}
      {openBookingDialog ? (
        // <RegularBooking
        //   isPostpaid={false}
        // userId={userId}
        // open={openBookingDialog}
        // setOpen={setOpenBookingDialog}
        // consumerId={props.consumerId}
        // addressIdPre={addressnamePhone}
        // />

        <BookRegularProducts
          bookNowType={bookNowType}
          userId={userId}
          open={openBookingDialog}
          close={setOpenBookingDialog}
          consumerId={props.consumerId}
          addressIdPre={addressnamePhone}
          subscriptionId={subscriptionId}
          editSubscriptionDialog={editSubscriptionDialog}
          setEditSubscriptionDialog={setEditSubscriptionDialog}
          editProducts={editProducts}
          setEditproducts={setEditproducts}
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          updateSubscriptonText={updateSubscriptonText}
          setUpdateSubscriptonText={setUpdateSubscriptonText}
          editingAdrs={editingAdrs}
          booktypeSub={booktypeSub}
        />
      ) : null}
      {openCreateGiftCard && (
        <AddGiftCard
          openCreateGiftCard={openCreateGiftCard}
          handleSubmitGiftCard={handleSubmitGiftCard}
          handleCloseGitCard={handleCloseGitCard}
          settingsDetails={settingsDetails}
          isBookwaterConsumer={isBookwaterConsumer}
        />
      )}
    </>
  );
}

export default Consumer;
