/**
 * @author vinay kumar
 * @description completed Deliveries
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 21-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import { GetCompletedDeliveries } from "../Redux/Actions/supliersAction";
import Select from "@mui/material/Select";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import AllDeliveriesCard from "../../Components/Cards/AllDeliveriesCard";
// import DeliveryCard from '../../Components/Cards/DeliveryCard';
import { Box, Button } from "@mui/material";
import SupplierDeliverieswTable from "../../Components/Tables/SupplierDeliveriesTable";
import { Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DeliveriesFilterDialog from "../../Components/DialogBoxes/SupplierDialogBox/DeliveriesFilterDialog";

function CompletedDeliveries() {
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (
      supplierDeliveries !== undefined &&
      supplierDeliveries.getCompletedDeliveries !== undefined &&
      supplierDeliveries.getCompletedDeliveries.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  }, [supplierDeliveries]);
  React.useEffect(() => {
    if (pageNumber) {
      dispatch(
        GetCompletedDeliveries({
          pageNumber: pageNumber - 1,
          pageSize: pageSize,
        })
      );
    }
  }, [pageNumber]);

  React.useEffect(() => {
    dispatch(
      GetCompletedDeliveries({
        pageNumber: pageNumber - 1,
        pageSize: pageSize,
      })
    );
  }, [pageSize]);
  React.useEffect(() => {
    CustomConsole(supplierDeliveries);
    dispatch(
      GetCompletedDeliveries({ pageNumber: pageNumber - 1, pageSize: pageSize })
    );
  }, []);
  const handleClickBack = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  //Foewqrd Arrow Button
  const handleClickForward = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleFilteropen = () => {
    setFilterDialogOpen(true);
  };
  const handleClosefilterDialog = (props) => {
    CustomConsole("--------handleClosefilterDialog---------");
    CustomConsole(props);
    setFilterDialogOpen(false);
  };
  const handleApplyFilter = (props) => {
    CustomConsole(props);
    dispatch(
      GetCompletedDeliveries({
        pageNumber: pageNumber - 1,
        pageSize: pageSize,
        agent_assigned: props.agent_assigned || undefined,
        phone: props.phone || undefined,
        pincode: props.enter_Pincode || undefined,
        booking_time: props.select_Booking_Time || undefined,
        delivery_slot_sort: props.select_Delivery_slot || undefined,
        order_type: props.order_Type || undefined,
      })
    );
    setFilterDialogOpen(false);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={handleFilteropen}>
          Filter
        </Button>
      </Box>
      {supplierDeliveries.getCompletedDeliveries !== null &&
      supplierDeliveries.getCompletedDeliveries !== undefined &&
      supplierDeliveries.getCompletedDeliveries.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            maxHeight: "550px",
            overflow: "auto",
          }}
        >
          {supplierDeliveries.getCompletedDeliveries.map((data, key) => {
            return (
              <AllDeliveriesCard
                key={key}
                order_id={data.order_id}
                order_Qty={data.packaged_drinking_water.twentyltr_capacity_qty}
                return_can_Qty={
                  data.packaged_drinking_water.empty_container_qty
                }
                order_type={
                  data.payment_type === 300
                    ? "POSTPAID"
                    : data.packaged_drinking_water?.order_type === 700
                    ? "INSTA"
                    : data.packaged_drinking_water?.is_subscribed_order
                    ? "SUBSCRIBED"
                    : "REGULAR"
                }
                delivery_slot={
                  data.packaged_drinking_water.delivery_slots
                    ? data.packaged_drinking_water.delivery_slots
                        .admin_selected_delivery_slot
                      ? data.packaged_drinking_water.delivery_slots
                          .admin_selected_delivery_slot
                      : data.packaged_drinking_water.delivery_slots
                          .supplier_selected_delivery_slot
                      ? data.packaged_drinking_water.delivery_slots
                          .supplier_selected_delivery_slot
                      : "-"
                    : "-"
                }
                twanty_ltrs_mrp={
                  data.packaged_drinking_water.twenty_ltr_capacity_amt
                }
                twanty_ltrs_total_price={
                  data.packaged_drinking_water.twenty_ltr_capacity_totamt
                }
                address={data.delivery_address}
              />
            );
          })}
        </Box>
      ) : (
        <Typography sx={{ fontWeight: "bold" }}>
          No more completed deliveries left!
        </Typography>
      )}
      <DeliveriesFilterDialog
        filterDialogOpen={filterDialogOpen}
        handleClose={handleClosefilterDialog}
        handleClickApply={handleApplyFilter}
        // handleSearch={handleSearchOrder}
        // orderid={orderid}
        agentListata={supplierDeliveries.getAssignDeliveryAgent}
        CompletedDelivery={supplierDeliveries.getCompletedDeliveries}
      />
      {/* <Box sx={{ display: "flex", justifyContent: "center", margin: "10px" }}>
        
        <Button onClick={handleClickBack}>
          <ArrowBackIos /> Prev
        </Button>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        
        <Button disabled={pageStatuss} onClick={handleClickForward}>
          Next <ArrowForwardIos />
        </Button>
      </Box>
      <Box>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Page size</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={pageSize}
            label="Page size"
            onChange={(e) => {
              setPageSize(e.target.value);
            }}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            
          </Select>
        </FormControl>
      </Box> */}
    </Box>
  );
}

export default CompletedDeliveries;
