import { Box, Grid, Typography, Button } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetContainerDepositDetails } from "../Redux/Actions/adminContainerDetailsAction";
import ContainerDetailsView from "./ContainerDetailsView";
import DetailsEditTable from "./DetailsEditTable";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styles } from "./containerDetailsCardStyles";
import { LostCans } from "../AdminLostCans/LostCans";
import LostCansCard from "../AdminLostCans/LostCansCard";
import { useMediaQuery } from "@material-ui/core";
import ButtonGroup from "@mui/material/ButtonGroup";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";

import CustomConsole from "../../CustomConsole";
export function ContainerDetails() {
  const dispatch = useDispatch();
  const adminContainerData = useSelector((state) => state.adminContainerData);
  const [containerDetails, setContainerDetails] = React.useState([]);

  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");
  const [alignment, setAlignment] = React.useState("update");
  //For app bar tab change
  const handleChange = (event) => {
    setAlignment(event.target.value);
    if (
      event.target.value === "update" &&
      event.target.value != "list" &&
      event.target.value != "lost"
    ) {
      styles.ButtonMain.color = "black";
      styles.ButtonMain.background =
        appColors.commonLayoutColor.backgroundImage;
    } else {
      styles.ButtonMain.color = "white";
      styles.ButtonMain.background = appColors.commonBtnColor.background;
    }
    if (
      event.target.value != "update" &&
      event.target.value === "list" &&
      event.target.value != "lost"
    ) {
      styles.ButtonMain1.color = "black";
      styles.ButtonMain1.background =
        appColors.commonLayoutColor.backgroundImage;
    } else {
      styles.ButtonMain1.color = "white";
      styles.ButtonMain1.background = appColors.commonBtnColor.background;
    }
    if (
      event.target.value != "update" &&
      event.target.value != "list" &&
      event.target.value === "lost"
    ) {
      styles.ButtonMain2.color = "black";
      styles.ButtonMain2.background =
        appColors.commonLayoutColor.backgroundImage;
    } else {
      styles.ButtonMain2.color = "white";
      styles.ButtonMain2.background = appColors.commonBtnColor.background;
    }
  };

  React.useEffect(() => {
    dispatch(GetContainerDepositDetails());
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
    if (alignment === "update") {
      styles.ButtonMain.color = "black";
      styles.ButtonMain.background =
        appColors.commonLayoutColor.backgroundImage;
    } else {
      styles.ButtonMain.color = "white";
      styles.ButtonMain.background = appColors.commonBtnColor.background;
    }
    if (alignment === "list") {
      styles.ButtonMain1.color = "black";
      styles.ButtonMain1.background =
        appColors.commonLayoutColor.backgroundImage;
    } else {
      styles.ButtonMain1.color = "white";
      styles.ButtonMain1.background = appColors.commonBtnColor.background;
    }
    if (alignment === "lost") {
      styles.ButtonMain2.color = "black";
      styles.ButtonMain2.background =
        appColors.commonLayoutColor.backgroundImage;
    } else {
      styles.ButtonMain2.color = "white";
      styles.ButtonMain2.background = appColors.commonBtnColor.background;
    }
  }, []);

  React.useEffect(() => {
    CustomConsole(adminContainerData);
    if (adminContainerData.error === false) {
      setContainerDetails(adminContainerData.containerDetails);
    }
  }, [adminContainerData]);

  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  CustomConsole(alignment);
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          lg={3}
          sm={12}
          sx={{ marginTop: { lg: "6em", sm: "4em", xs: "4em" } }}
        >
          <ButtonGroup
            variant="contained"
            sx={styles.ButtonGroup}
            aria-label="outlined primary button group"
            orientation={isSmallScreen ? "horizontal" : "vertical"}
          >
            <Button
              sx={styles.ButtonMain}
              value="update"
              onClick={handleChange}
            >
              Update Container Details
            </Button>
            <Button sx={styles.ButtonMain1} value="list" onClick={handleChange}>
              Container Deposit Details
            </Button>
            <Button sx={styles.ButtonMain2} value="lost" onClick={handleChange}>
              Lost Containers
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item lg={9} sm={12}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1em",
              gap: "10px",
            }}
          >
            {ADMIN_ROLES.BW_ADMIN === adminRole ||
            ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
            ADMIN_ROLES.BW_OPSHEAD === adminRole ||
            ADMIN_ROLES.BW_IVML === adminRole ? (
              <Box>
                {alignment === "update" ? (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {ADMIN_ROLES.BW_ADMIN === adminRole ||
                    ADMIN_ROLES.BW_SUPERADMIN === adminRole ? (
                      <Grid item md={12}>
                        {containerDetails && containerDetails.length ? (
                          <DetailsEditTable
                            containerDetails={containerDetails}
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                ) : (
                  ""
                )}
                {alignment === "list" ? (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Grid item md={12}>
                      <ContainerDetailsView />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              <Box sx={{ marginTop: "50px" }}>
                {alignment === "lost" ? (
                  ""
                ) : (
                  <Alert severity="error">Access Denied</Alert>
                )}
              </Box>
            )}
            {ADMIN_ROLES.BW_ADMIN === adminRole ||
            ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
            ADMIN_ROLES.BW_OPSHEAD === adminRole ||
            ADMIN_ROLES.BW_FINANCEHEAD === adminRole ||
            ADMIN_ROLES.BW_FINANCE === adminRole ||
            ADMIN_ROLES.BW_CSHEAD === adminRole ||
            ADMIN_ROLES.BW_CSLEAD === adminRole ||
            ADMIN_ROLES.BW_CS === adminRole ||
            ADMIN_ROLES.BW_IVML === adminRole ? (
              <Box>
                {alignment === "lost" ? (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Grid item md={12}>
                      <LostCans />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              <Box>
                {alignment === "lost" ? (
                  <Alert severity="error">Access Denied</Alert>
                ) : (
                  ""
                )}
              </Box>
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
