/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author
 * @description Add product dialog box
 * @date
 * @version
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { GetSupplierDeliveryAgentDetailsAdmin } from "../../Pages/Redux/Actions/supliersAction";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  AssignDeliverAgentEmptyCan,
  EditWareHouseEmptycan,
} from "../../Pages/Redux/Actions/skuWfsDetailsAction";
import { GetUserAddressAdmin } from "../../Pages/Redux/Actions/userDetailsAdminAction";
import CustomConsole from "../../CustomConsole";

export default function AssignWareHouseEmptyCanDialog({
  handleCloseWFSOrderDialog,
  open,
  setOpen,
  selectedData,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userDetailsUpdate = useSelector(
    (state) => state.userDetailsUpdate.address
  );
  const [driverName, setDriverNmae] = React.useState("");
  const [driverId, setDriverId] = React.useState({
    warehouse_id: "",
  });
  //function for handling assign delivery agent

  const handleSelectedProduct = (e) => {
    CustomConsole(e.target.value);
    const userId = userDetailsUpdate.find(
      (data) => data.address_id === e.target.value
    );
    setDriverId({ warehouse_id: userId.address_id });
    setDriverNmae(e.target.value);
  };

  //function for handelclose operation
  const handleClose = () => {
    setOpen(false);
  };

  //useEffect for getting delivery agent
  React.useEffect(() => {
    if (selectedData?.ordered_user_details?.user_id !== undefined) {
      dispatch(
        GetUserAddressAdmin(selectedData?.ordered_user_details?.user_id)
      );
    }
  }, [selectedData]);

  //function for submitting assig n delivery agent
  const handelCrateSubmit = () => {
    handleCloseWFSOrderDialog();
    dispatch(EditWareHouseEmptycan(selectedData?.ec_order_id, driverId));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Assign Warehouse "}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex" }}>
            <FormControl sx={{ width: "100%" }} size="small">
              <InputLabel id="demo-select-small-label">
                Select Warehouse
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={driverName}
                label="Select Address"
                onChange={handleSelectedProduct}
                sx={{
                  color: "black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                  },
                }}
              >
                {userDetailsUpdate?.map((option) => (
                  <MenuItem key={option.address_id} value={option.address_id}>
                    {option.contact_name}/{option.address_line1}/
                    {option.address_line2}/ {option.pincode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button onClick={handelCrateSubmit} size="small" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
