import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import { Close } from "@mui/icons-material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRequestedRecycleOrderList,
  PostCompleteScanningRecycleOrder,
} from "../Redux/Actions/RecycleOrdersAction";

function RecycleScanningDialog(props) {
  console.log(props);
  const dispatch = useDispatch();
  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    physical_damaged: "",
    qrBarcode_damaged: 0,
    expired: "",
    qr_less_physical_damaged: 0,
  });
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");

  const [qrBarcodeIdBulk, setQrBarcodeIdBulk] = React.useState("");
  const [scanOption, setScanOption] = React.useState("scanner");
  const [damageOptions, setDamageOptions] = useState();
  const [typeOfRecycle, setTypeOfRecycle] = React.useState("physical");
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [valueRecycle, setValueRecycle] = React.useState(0);
  const [decodedResults, setDecodedResults] = React.useState([]);
  console.log(decodedResults);
  const styless = {
    AdminStyleHeading: {
      display: "flex",
      // alignItems: "flex-start",
      // padding: "1px",
      // gap: "3px",
      color: "#0a83c4",
      justifyContent: "space-between",
    },
  };
  const span = {
    spanStyle: {
      color: "black",
      fontWeight: "normal",
      marginRight: "30px",
    },
  };
  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      console.log("--- qrBarcodeIdNew === qrBarcodeId ---");
      console.log(qrBarcodeIdNew);
      console.log(qrBarcodeId);
      console.log("-- inscan ---");
      if (decodedResults?.includes(qrBarcodeId)) {
        return;
      }
      setDecodedResults((prev) => [...prev, qrBarcodeId]);
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
        setQrBarcodeIdBulk("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew, qrBarcodeIdBulk]);

  React.useEffect(() => {
    if (typeOfRecycle === "physical" && decodedResults.length > 0) {
      console.log("----physical-----");
      const reqObj = {
        capacity: props.singleOrderData?.details?.capacity,
        material: props.singleOrderData?.details?.material,
        physical_damage: decodedResults,
        qr_barcode_damage: 0,
        expired: [],
        ignore_can_status: true,
        order_id: props.singleOrderData?.details?.order_id,
      };
      dispatch(PostCompleteScanningRecycleOrder(reqObj)).then((data) => {
        console.log(data);

        dispatch(
          GetRequestedRecycleOrderList({
            page_number: 1,
            page_size: 100,
          })
        );
      });
    } else if (typeOfRecycle === "Expired" && decodedResults.length > 0) {
      console.log("------expired-------");
      const reqObj = {
        capacity: props.singleOrderData?.details?.capacity,
        material: props.singleOrderData?.details?.material,
        physical_damage: [],
        qr_barcode_damage: 0,
        expired: decodedResults,
        order_id: props.singleOrderData?.details?.order_id,
      };
      dispatch(PostCompleteScanningRecycleOrder(reqObj)).then((data) => {
        console.log(data);

        dispatch(
          GetRequestedRecycleOrderList({
            page_number: 1,
            page_size: 100,
          })
        );
      });
    }
  }, [decodedResults]);
  useEffect(() => {
    setTypeOfRecycle(
      props.singleOrderData?.details?.qr_barcode_damage > 0
        ? "Qr/BarcodeDamage"
        : props.singleOrderData?.details?.expired > 0
        ? "Expired"
        : props.singleOrderData?.details?.physical_damage
        ? "physical"
        : ""
    );
  }, [props.singleOrderData?.details]);

  const handleSelectScanOption = (event) => {
    setScanOption(event.target.value);
  };
  const onNewScanResult = (decodedText, decodedResult) => {
    // setQrBarcodeId(decodedText);
    // setDecodedResults((prev) => [...prev, decodedText]);
    setDecodedResults(decodedText);
  };
  const handleQrBarcodeIdFromScanner = (event) => {
    let value = event.target.value;
    console.log(value);
    setQrBarcodeId(value);
    setQrBarcodeId(event.target.value);

    if (value === 0) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdBulk) {
          console.log("set code for bulk");
          setQrBarcodeIdBulk(event.target.value);
        } else {
          console.log("---code not set for bulk ---");
        }
      }, 500);
    }

    if (value !== 0) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdNew) {
          console.log("-- set data --");
          setQrBarcodeIdNew(event.target.value);
        } else {
          console.log("--- data is set ---");
        }
      }, 500);
    }
  };
  const handleChangeRecycle = (event, newValue) => {
    setValueRecycle(newValue);
  };

  const handelQuantityQrBarcodeDamagedChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      qrBarcode_damaged:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.qrBarcode_damaged),
    }));
  };
  const handelQrlessPhysicalDamgedChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      qr_less_physical_damaged:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.qr_less_physical_damaged),
    }));
  };

  const handleSubmitQrlessOrder = () => {
    console.log("------handleSubmitQrlessOrder--------------");
    const reqObj = {
      capacity: props.singleOrderData?.details?.capacity,
      material: props.singleOrderData?.details?.material,
      physical_damage: orderParams.qr_less_physical_damaged,
      order_id: props.singleOrderData?.details?.order_id,
    };
    dispatch(PostCompleteScanningRecycleOrder(reqObj)).then((data) => {
      console.log(data);
      dispatch(
        GetRequestedRecycleOrderList({
          page_number: 1,
          page_size: 100,
        })
      );
    });
    props.setOpenRecyclescanningDialog(false);
  };
  const handleChangetypeOfRecycle = (event) => {
    setTypeOfRecycle(event.target.value);
    setQrBarcodeIdNew("");
    setQrBarcodeId("");
    setQrBarcodeIdBulk("");
    setDecodedResults([]);
  };
  const handleCloseRecycleScanningDialog = () => {
    props.handleCloseRecycleScanningDialog();
  };

  const handleSubmitQrBarcodeDamgedOrder = () => {
    console.log("--------handleSubmitQrBarcodeDamgedOrder-----------");
    const reqObj = {
      capacity: props.singleOrderData?.details?.capacity,
      material: props.singleOrderData?.details?.material,
      qr_barcode_damage: orderParams.qrBarcode_damaged,
      expired: [],
      physical_damage: [],
      order_id: props.singleOrderData?.details?.order_id,
    };
    dispatch(PostCompleteScanningRecycleOrder(reqObj)).then((data) => {
      dispatch(
        GetRequestedRecycleOrderList({
          page_number: 1,
          page_size: 100,
        })
      );
    });
    props.setOpenRecyclescanningDialog(false);
  };
  return (
    <Dialog open={props.openRecyclescanningDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseRecycleScanningDialog}
          >
            <Close />
          </Button>
        </Box>
        {props.qrType === true ? (
          <>
            <Paper elevation={8} sx={{ padding: "10px", mt: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { md: "none", xs: "wrap" },
                }}
              >
                {" "}
                <Box sx={styless.AdminStyleHeading}>
                  <Typography
                    sx={{
                      margin: 1,
                      fontWeight: "bold",
                      fontSize: { xs: "1em" },
                    }}
                  >
                    Physical Damaged Qty:
                    <span style={span.spanStyle}>
                      &nbsp;&nbsp;&nbsp;
                      {props.singleOrderData?.details
                        ?.scanned_physical_damage || 0}{" "}
                      / {props.singleOrderData?.details?.physical_damage}
                    </span>
                  </Typography>
                </Box>
                <Box sx={styless.AdminStyleHeading}>
                  <Typography
                    sx={{
                      margin: 1,
                      fontWeight: "bold",
                      fontSize: { xs: "1em" },
                    }}
                  >
                    Expired Qty:
                    <span style={span.spanStyle}>
                      &nbsp;&nbsp;&nbsp;
                      {props.singleOrderData?.details?.scanned_expired ||
                        0} / {props.singleOrderData?.details?.expired}
                    </span>
                  </Typography>
                </Box>
                <Box sx={styless.AdminStyleHeading}>
                  <Typography
                    sx={{
                      margin: 1,
                      fontWeight: "bold",
                      fontSize: { xs: "1em" },
                    }}
                  >
                    Qr/Barcode Damaged Qty:
                    <span style={span.spanStyle}>
                      &nbsp;&nbsp;&nbsp;
                      {props.singleOrderData?.details
                        ?.scanned_qr_barcode_damage || 0}{" "}
                      / {props.singleOrderData?.details?.qr_barcode_damage}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <Box>
              <Paper elevation={8} sx={{ padding: "10px", mt: "10px" }}>
                <FormControl>
                  <FormLabel>Select Type Of Recycle</FormLabel>
                  <RadioGroup
                    row
                    value={typeOfRecycle}
                    defaultValue="physical"
                    onChange={handleChangetypeOfRecycle}
                  >
                    <FormControlLabel
                      value="physical"
                      control={<Radio />}
                      label="Physical Damaged"
                    />
                    <FormControlLabel
                      value="Expired"
                      control={<Radio />}
                      label="Expired"
                    />
                    <FormControlLabel
                      value="Qr/BarcodeDamage"
                      control={<Radio />}
                      label="Qr/Barcode Damage"
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Box>

            <Paper elevation={8} sx={{ padding: "10px", mt: "10px" }}>
              {typeOfRecycle === "physical" || typeOfRecycle === "Expired" ? (
                <>
                  <FormControl sx={{ marginTop: "15px" }}>
                    <FormLabel>Select Scanning Option</FormLabel>
                    <RadioGroup
                      defaultValue="scanner"
                      value={scanOption}
                      name="radio-buttons-group"
                      sx={{ display: "flex", flexDirection: "row" }}
                      onChange={handleSelectScanOption}
                    >
                      <FormControlLabel
                        value="scanner"
                        control={<Radio />}
                        label="Scanner Device"
                      />

                      <FormControlLabel
                        value="Scan all and Link"
                        control={<Radio />}
                        label="Camera"
                      />
                    </RadioGroup>
                  </FormControl>
                  {scanOption === "scanner" ? (
                    <>
                      <Box
                        sx={{
                          marginTop: "10px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                          padding: "10px",
                        }}
                      >
                        {/* Form element wrapping the TextField */}
                        <TextField
                          // focused={finalValue == ""}
                          autoFocus
                          label="QR/Barcode:"
                          onChange={handleQrBarcodeIdFromScanner}
                          // autoFocus
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={qrBarcodeId}
                          // onChange={handleChange} // Listen for changes in TextField
                        />
                        {/* Hidden submit button */}
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* Bulk Scaning  */}
                      {scanOption && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <Html5QrcodePlugin1
                              fps={1}
                              qrbox={200}
                              disableFlip={false}
                              qrCodeSuccessCallback={onNewScanResult}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginTop: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        gap: "20px",
                        marginTop: "1rem",
                      }}
                    >
                      <Typography>Enter Quantity&nbsp;&nbsp;</Typography>
                      <OrderButtonSku
                        handelAddQuantity={() =>
                          handelQuantityQrBarcodeDamagedChange(
                            parseInt(orderParams.qrBarcode_damaged) + 1
                          )
                        }
                        handelMinusQuantity={() =>
                          handelQuantityQrBarcodeDamagedChange(
                            parseInt(orderParams.qrBarcode_damaged) - 1
                          )
                        }
                        handelQuantityChange={(event) =>
                          handelQuantityQrBarcodeDamagedChange(
                            event.target.value
                          )
                        }
                        inputValue={orderParams.qrBarcode_damaged}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {orderParams.qrBarcode_damaged ? (
                      <Button
                        variant="contained"
                        onClick={handleSubmitQrBarcodeDamgedOrder}
                      >
                        submit
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              )}
            </Paper>
          </>
        ) : (
          <>
            <Paper elevation={8} sx={{ padding: "10px", mt: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { md: "none", xs: "wrap" },
                }}
              >
                {" "}
                <Box sx={styless.AdminStyleHeading}>
                  <Typography
                    sx={{
                      margin: 1,
                      fontWeight: "bold",
                      fontSize: { xs: "1em" },
                    }}
                  >
                    Physical Damaged Qty:
                    <span style={span.spanStyle}>
                      &nbsp;&nbsp;&nbsp;
                      {props.singleOrderData?.details?.physical_damage}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <Paper elevation={8} sx={{ padding: "10px", mt: "10px" }}>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",

                  flexDirection: "column",
                  gap: "20px",
                  marginTop: "1rem",
                }}
              > */}

              <Typography>
                <li style={{ fontSize: "16px" }}>
                  Physical Damaged Quantity&nbsp;&nbsp;
                </li>
              </Typography>

              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "10px" }}
              >
                <OrderButtonSku
                  handelAddQuantity={() =>
                    handelQrlessPhysicalDamgedChange(
                      parseInt(orderParams.qr_less_physical_damaged) + 1
                    )
                  }
                  handelMinusQuantity={() =>
                    handelQrlessPhysicalDamgedChange(
                      parseInt(orderParams.qr_less_physical_damaged) - 1
                    )
                  }
                  handelQuantityChange={(event) =>
                    handelQrlessPhysicalDamgedChange(event.target.value)
                  }
                  inputValue={orderParams.qr_less_physical_damaged}
                />
              </Box>
              {/* </Box> */}

              <Box
                sx={{
                  mt: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {orderParams.qr_less_physical_damaged ? (
                  <Button variant="contained" onClick={handleSubmitQrlessOrder}>
                    submit
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            </Paper>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default RecycleScanningDialog;
