import { Box } from "@mui/material";
import React from "react";
import * as Material from "@mui/material";
import {
  GetSupplierWfsOngoingOrders,
  PutSupplierAddDelivertSlots,
  PutSupplierOngoingStartSubmission,
} from "../Redux/Actions/supliersAction";
import { useSelector, useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";
import * as MuiIcons from "@mui/icons-material";
import moment from "moment";
import SupplierStartSubmissionDialog from "../../Components/DialoBoxes/SupplierStartSubmissionDialog";
import { ordersStatusWFSCan } from "../../Components/AppMeta/appMetaConfig";
import { PutEditEmptyCanOrder } from "../Redux/Actions/wfsOrdersAction";
import EditEmptyCanOrdersDialog from "../wfsCanOrders/EditEmptyCanOrdersDialog";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import EmptyCanOrderEdit from "../../Components/DialogBoxes/emptyCanEditOrder";
import SupplierWfsSingleOrderDetailsDialog from "./SupplierWfsSingleOrderDetailsDialog";
function WfsOrdersTable(props) {
  CustomConsole(props);
  const dispatch = useDispatch();
  const [openStartSubmissionDialog, setOpenStartSubmissionDialog] =
    React.useState(false);
  const [openSupplierWFSOrdersDialog, setOpenSupplierWFSOrdersDialog] =
    React.useState(false);
  const [singleIdData, setSingleIdData] = React.useState({});
  const [orderDetails, setOrderDetails] = React.useState({});
  const [openEditEmptyCanOrderDialog, setOpenEditEmptyCanOrderDialog] =
    React.useState(false);
  const orderStatuswfsCansorder = (ec_order_status) => {
    switch (ec_order_status) {
      case ordersStatusWFSCan.EC_ORDER_CONFIRMED:
        return "CONFIRMED";
      case ordersStatusWFSCan.EC_ORDER_COMPLETED:
        return "COMPLETED";
      case ordersStatusWFSCan.EC_ORDER_CANCELLED:
        return "CANCELLED";
      case ordersStatusWFSCan.EC_ORDER_PAYMENT_PENDING:
        return "PAYMENT PENDING";
      case ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED:
        return "ADMIN ACCEPTED";
      case ordersStatusWFSCan.EC_ORDER_ADMIN_REJECTED:
        return "ADMIN REJECTED";
      default:
        return "";
    }
  };
  const handleOkDialog = (props) => {
    CustomConsole("-----handleOkDialog-----------");
    CustomConsole(props);
    setOpenStartSubmissionDialog(false);
    // setStartBtn(true);
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(props.selectedOrderType));
    }, 500);
  };
  const handleStart = (id) => {
    CustomConsole("----------Start Submission-----------");
    CustomConsole(id);
    setOpenStartSubmissionDialog(true);
    // setOrderId(props.OrderId);
    dispatch(
      PutSupplierOngoingStartSubmission({
        order_id: id,
      })
    );
  };
  const handleEditEmptyCan = (props) => {
    setOrderDetails(props);
    setOpenEditEmptyCanOrderDialog(true);
  };
  const handleClose = () => {
    setOpenEditEmptyCanOrderDialog(false);
  };
  const handleSubmitCans = (props) => {
    CustomConsole("--------handleSubmitCans-------------");
    const reqObj = {
      order_id: props.ec_order_id,
      order_qty: props.orderQty + props.emptyCanQty,
      empty_qty: props.emptyCanQty,
    };
    CustomConsole(reqObj);
    dispatch(PutEditEmptyCanOrder(reqObj));
    setOpenEditEmptyCanOrderDialog(false);
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(props.selectedOrderType));
    }, 500);
  };

  const handleOpenWfsSingleOrderDetails = (data) => {
    CustomConsole("--------handleOpenWfsSingleOrderDetails-------------");
    setOpenSupplierWFSOrdersDialog(true);
    setSingleIdData(data);
  };

  const handleCloseWFSOrderDialog = () => {
    setOpenSupplierWFSOrdersDialog(false);
  };
  return (
    <>
      <Material.Box
        sx={{
          maxHeight: "500px",
          maxWidth: { md: "100Vw", xs: "87Vw" },
          overflow: "auto",
        }}
      >
        <Material.TableContainer component={Material.Paper}>
          <Material.Table size="small">
            <Material.TableHead sx={{ bgcolor: "#BB8FCE " }}>
              <Material.TableRow>
                <Material.TableCell sx={{ color: "white" }}>
                  Order Id
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Submmission Status
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Edit
                </Material.TableCell>
                {/* <Material.TableCell sx={{ color: "white" }} align="right">
              Order Qty
            </Material.TableCell> */}
                <Material.TableCell sx={{ color: "white" }} align="right">
                  New Containers
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  OTP
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Damaged Containers
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Expired Containers
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Order Status
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Order Assigned To
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Created At
                </Material.TableCell>
                <Material.TableCell sx={{ color: "white" }} align="right">
                  Address
                </Material.TableCell>
              </Material.TableRow>
            </Material.TableHead>
            <Material.TableBody>
              {props.data !== undefined &&
              props.data !== null &&
              props.data.length > 0 ? (
                <>
                  {props.data.map((row) => (
                    <Material.TableRow
                      // key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Material.TableCell
                        component="th"
                        scope="row"
                        style={{
                          textDecorationLine: row.ec_order_id
                            ? "underline"
                            : "none",
                          color: "red",
                        }}
                        onClick={() => handleOpenWfsSingleOrderDetails(row)}
                      >
                        {row.ec_order_id}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {/* {row.ec_order_status ===
                        ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED ? ( */}
                        <Material.Box sx={{ marginTop: "10px" }}>
                          {row.ec_order_status ==
                            ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED &&
                          row.order_details?.is_submit_finished === false &&
                          row.order_details?.is_submit_started === false ? (
                            <>
                              <Material.Button
                                onClick={() => handleStart(row.ec_order_id)}
                                variant="contained"
                                sx={{
                                  borderTopRightRadius: "20px",
                                  borderBottomLeftRadius: "20px",
                                }}
                              >
                                start
                              </Material.Button>
                            </>
                          ) : row.ec_order_status ==
                            ordersStatusWFSCan.EC_ORDER_ADMIN_REJECTED ? (
                            <>
                              <Material.Box>
                                <Material.Typography
                                  sx={{ fontWeight: "bold", color: "red" }}
                                >
                                  REJECTED
                                </Material.Typography>
                              </Material.Box>
                            </>
                          ) : (
                            <>
                              <Material.Box>
                                <Material.Typography
                                  sx={{ fontWeight: "bold", color: "green" }}
                                >
                                  STARTED
                                </Material.Typography>
                              </Material.Box>
                            </>
                          )}
                        </Material.Box>
                        {/* ) : (
                          ""
                        )} */}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        <Material.Box sx={{ mt: 0.6 }}>
                          <Material.Button
                            variant="contained"
                            size="small"
                            onClick={() => handleEditEmptyCan(row)}
                          >
                            <MuiIcons.BorderColor /> Edit
                          </Material.Button>
                        </Material.Box>
                      </Material.TableCell>
                      {/* <Material.TableCell align="right">{"500"}</Material.TableCell> */}
                      <Material.TableCell align="right">
                        {row.order_details?.order_qty}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {row.otp}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {row.order_details?.damaged_qty}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {row.order_details?.expired_qty}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {orderStatuswfsCansorder(row.ec_order_status)}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {row.assigned_wfs_details?.wfs_incharge_name}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {moment(row.created_at).format("MM-DD-YYYY")}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {row.assigned_wfs_details?.wfs_address?.door_no},{" "}
                        {row.assigned_wfs_details?.wfs_address?.Street},{" "}
                        {row.assigned_wfs_details?.wfs_address?.Village},{" "}
                        {row.assigned_wfs_details?.wfs_address?.State},{" "}
                        {row.assigned_wfs_details?.wfs_address?.District}-{" "}
                        {row.assigned_wfs_details?.wfs_address?.Pincode}
                      </Material.TableCell>
                    </Material.TableRow>
                  ))}
                </>
              ) : (
                <Material.Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                  }}
                >
                  No Data Found !!
                </Material.Typography>
              )}
            </Material.TableBody>
          </Material.Table>
        </Material.TableContainer>
      </Material.Box>
      <SupplierStartSubmissionDialog
        openStartSubmissionDialog={openStartSubmissionDialog}
        handleClose={handleOkDialog}
        // storeStartSubmissionData={storeStartSubmissionData}
      />
      {openEditEmptyCanOrderDialog && (
        <EmptyCanOrderEdit
          open={openEditEmptyCanOrderDialog}
          onClose={handleClose}
          editDetails={orderDetails}
        />
      )}
      <SupplierWfsSingleOrderDetailsDialog
        openSupplierWFSOrdersDialog={openSupplierWFSOrdersDialog}
        singleIdData={singleIdData}
        handleCloseWFSOrderDialog={handleCloseWFSOrderDialog}
        ordersStatusWFSCan={ordersStatusWFSCan}
        handleStart={handleStart}
        handleEditEmptyCanDetails={handleEditEmptyCan}
      />
    </>
  );
}

export default WfsOrdersTable;
