/**
 * @author vinay kumar
 * @description Assin Delivery Agent Dialog Box
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 18-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../../CustomConsole";

function AssignDeliveryAgentDialog(props) {
  const [deliveryAgentId, setDeliveryAgentId] = React.useState("");

  CustomConsole(props);

  const handleClickAssignDeliveryAgent = (data) => {
    CustomConsole("----------handleClickAssignDeliveryAgent-----");
    setDeliveryAgentId(data);

    props.handleClickAssignDeliveryAgent(data);
  };

  const hadleClose = () => {
    CustomConsole("----------hadleClose-----");
    props.hadleClose(props);
  };

  return (
    <Material.Box>
      <Material.Dialog open={props.openAssignDeliveryAgentDialog}>
        <Material.DialogTitle>
          <Material.Typography sx={{ fontWeight: "bold" }}>
            Choose Delivery Agent
          </Material.Typography>
        </Material.DialogTitle>
        <Material.DialogContent>
          {props?.agentListata?.map((data) => {
            return (
              <Material.Card
                onClick={() => handleClickAssignDeliveryAgent(data)}
                sx={{ margin: "10px" }}
                elevation={20}
              >
                <Material.CardContent sx={{ fontWeight: "bold" }}>
                  <span style={{ color: "blue" }}>ID: &nbsp; </span>
                  {data.driver_id} <br />
                  <span style={{ color: "blue" }}>Name:&nbsp;</span>
                  {data.driver_profile.fullname} <br />
                  <span style={{ color: "blue" }}>Phone:&nbsp;</span>
                  {data.driver_profile.phone}
                </Material.CardContent>
              </Material.Card>
            );
          })}
        </Material.DialogContent>
        <Material.Button variant="contained" onClick={hadleClose}>
          close
        </Material.Button>
      </Material.Dialog>
    </Material.Box>
  );
}

export default AssignDeliveryAgentDialog;
