import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  Paper,
  Button,
  DialogActions,
  Tooltip,
  Input,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import { AvailablePincodes } from "../Redux/Actions/pincodesAction";
import { useDispatch, useSelector } from "react-redux";
import { Close, FileUpload } from "@mui/icons-material";
import moment from "moment";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { toast } from "react-toastify";
import { expense_type } from "../../Components/AppMeta/appMetaConfig";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { wrapAndSliceX } from "ol/extent";

function WarehouseAddDialog(props) {
  console.log(props);
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);

  const wareHouseDetails = useSelector(
    (state) => state.userDetailsUpdate.addresses
  );
  const uploadPaymentRecipt = React.useRef(null);
  const [stopSwitch, setStopSwitch] = React.useState(true);
  const [purpose, setPurpose] = React.useState("");
  const [selectedExpenseType, setSelectedExpenseType] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [selectMonth, setSelectMonth] = React.useState("");
  const [paidDate, setPaidDate] = React.useState(
    moment().tz("Asia/Kolkata").format("YYYY-MM-DD")
  );
  console.log(paidDate);
  const [amount, setAmount] = React.useState("");
  const [reciptupload, setReciptupload] = React.useState("");
  const [uploadReciptFile, setUploadReciptFile] = React.useState("");
  const [seqid, setSeqid] = React.useState("");
  const [warehouseFilterCountry, setWarehouseFilterCountry] = useState("INDIA");
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  console.log(selectedWarehouse);
  const [selectedSupplierId, setSelectedSupplierId] = React.useState(null);
  console.log(selectedSupplierId);
  const [uploadReciptBtn, setUploadReciptBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [nextDueDate, setNextDueDate] = React.useState();

  const [reciptUrl, setReciptUrl] = React.useState("");

  //function for handling file upload opreation
  const handleUploadRecipt = () => {
    uploadPaymentRecipt.current.click();
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  //useEffect for handling file upload operation
  React.useEffect(() => {
    if (uploadReciptFile !== "") {
      dispatch(UploadFile(uploadReciptFile));
      setUploadReciptBtn(true);
      setUploadReciptFile("");
      setUploadFlags(true);
    }
  }, [uploadReciptFile]);
  //file handler
  React.useEffect(() => {
    console.log(fileHandler);
    if (fileHandler.isFileUploaded === true && uploadReciptBtn === true) {
      setReciptUrl(fileHandler.fileName);
      setUploadReciptBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("Recipt  uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);

  React.useEffect(() => {
    if (props.openEditSingleOperationCost) {
      console.log(props.singleOperationCostDetails);
      console.log(
        moment(props.singleOperationCostDetails.paid_date)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY")
      );
      console.log(
        props.singleOperationCostDetails.supplier?.supplier_profile
          ?.company_name
      );
      console.log(
        props.singleOperationCostDetails.warehouse_details?.contact_name
      );
      setSeqid(props.singleOperationCostDetails.id);
      setSelectedWarehouse({
        name: props.singleOperationCostDetails.warehouse_details?.contact_name,
      });
      setSelectedSupplierId({
        name: props.singleOperationCostDetails.supplier?.supplier_profile
          ?.company_name,
      });
      setAccountNumber(
        props.singleOperationCostDetails?.details?.account_number
      );
      setSelectedExpenseType(props.singleOperationCostDetails.expense_type);
      setAmount(props.singleOperationCostDetails.amount);
      setPaidDate(
        moment(props.singleOperationCostDetails.paid_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
      setReciptUrl(props.singleOperationCostDetails.payment_receipt);
      setNextDueDate(
        props.singleOperationCostDetails.due_date
          ? moment(props.singleOperationCostDetails.due_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD")
          : props.singleOperationCostDetails.next_due_date
          ? moment(props.singleOperationCostDetails.next_due_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD")
          : undefined
      );
      setPurpose(props.singleOperationCostDetails.details?.reason);
      setSelectMonth(props.singleOperationCostDetails.details?.month);
    }
  }, [props.openEditSingleOperationCost]);

  //function to get WareHouse Details details based on address_id
  React.useEffect(() => {
    console.log(selectedSupplierId);
    if (selectedSupplierId?.user_id !== undefined) {
      //   getDistricts({ country: selectedCountry });

      GetWareHouseDetails({
        user_id: selectedSupplierId?.user_id,
      });
    }
  }, [selectedSupplierId]);

  //function to download warehouse details
  function GetWareHouseDetails(data) {
    console.log("WareHouseDetails");
    console.log(data);
    setSelectedWarehouse(null);
    dispatch(
      GetFilteredUserAddressAdmin({
        user_id: data.user_id,
      })
    );
  }

  const handleCloseWarehouse = () => {
    props.handleCloseWarehouse();
  };

  const cities = [
    { label: "Ponmar Warehouse", warehouse_id: 4665 },
    { label: "Warehouse 2", warehouse_id: 4716 },
  ];

  const supplerList = [{ label: "AvinashDeveloperTest", supplier_id: 394 }];

  const handleSubmitWarehouse = () => {
    if (
      selectedWarehouse?.warehouse_id ||
      props.singleOperationCostDetails.warehouse_details?.address_id
    ) {
      props.handleSubmitWarehouse({
        selectedExpenseType: selectedExpenseType,
        selectMonth: selectMonth,
        amount: amount,
        paidDate: paidDate,
        reciptUrl: reciptUrl,
        nextDueDate: nextDueDate,
        purpose: purpose,
        selectedWarehouse:
          selectedWarehouse?.warehouse_id ||
          props.singleOperationCostDetails.warehouse_details?.address_id,
        seqid: seqid,
        accountNumber: accountNumber,
        edt_next_due_date: props.editNextDueDate,
        stop_expense: stopSwitch,
        ops_type: 100,
      });
    } else {
      console.log("------else---------");
      toast.error("Please Select warehouse", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <Dialog open={props.openWarehouseAddDialog} onClose={handleCloseWarehouse}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: "15px" }}>
          <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
            {props.openEditSingleOperationCost === true ? "EDIT" : "ADD"}{" "}
            WAREHOUSE OPERATING COST
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // flexWrap: "wrap",
            flexDirection: { md: "row", xs: "column" },
            gap: "10px",
            mb: "10px",
          }}
        >
          <Autocomplete
            disabled={
              !props.supplierDeliveries?.supplierList?.length ||
              props.editNextDueDate === "edit"
            }
            id="country-select-demo"
            sx={{ width: 210, mb: "10px" }}
            options={
              (props.supplierDeliveries?.supplierList !== null &&
                props.supplierDeliveries?.supplierList?.map((item, index) => {
                  return {
                    name: item.supplier_name !== null ? item.supplier_name : "",
                    user_id: item.user_id,
                  };
                })) ||
              []
            }
            value={selectedSupplierId}
            autoHighlight
            getOptionLabel={(nameSearch) => `${nameSearch.name} `}
            onChange={(_, newValue) => {
              setSelectedSupplierId(newValue); // Set the selected  as the value
            }}
            renderOption={(props, nameSearch) => (
              <Box
                component="li"
                // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {console.log(nameSearch)}
                {nameSearch.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={
                  props.supplierDeliveries?.supplierList !== undefined &&
                  props.supplierDeliveries?.supplierList !== null &&
                  props.supplierDeliveries?.supplierList?.length > 0
                    ? "Select Supplier"
                    : "No Supplier Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: null, // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            disabled={!selectedSupplierId?.user_id}
            id="country-select-demo"
            sx={{ width: 210, mb: "10px" }}
            options={
              wareHouseDetails?.map((item, index) => {
                return {
                  name: item.contact_name,
                  warehouse_id: item.address_id,
                  id: index,
                };
              }) || []
            }
            autoHighlight
            value={selectedWarehouse}
            getOptionLabel={(nameSearch) => `${nameSearch.name}`}
            onChange={(_, newValue) => {
              setSelectedWarehouse(newValue); // Set the selected  as the value
            }}
            renderOption={(props, nameSearch) => (
              <Box
                component="li"
                // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {nameSearch.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={
                  wareHouseDetails?.length
                    ? "Search Warehouse"
                    : "No warehouses Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: null, // disable autocomplete and autofill
                }}
              />
            )}
          />
          <FormControl sx={{ width: 220 }} size="small">
            <InputLabel>Select Type</InputLabel>
            <Select
              disabled={
                !selectedWarehouse?.warehouse_id ||
                props.editNextDueDate === "edit"
              }
              value={selectedExpenseType}
              label="Select Expense Type"
              onChange={(event) => setSelectedExpenseType(event.target.value)}
            >
              <MenuItem value={expense_type.RENT}>RENT</MenuItem>
              <MenuItem value={expense_type.WFI}>WI-FI</MenuItem>
              <MenuItem value={expense_type.ELECTRICITY}>ELECTRICITY</MenuItem>
              <MenuItem value={expense_type.CONSUMABLES}>CONSUMABLES</MenuItem>
              <MenuItem value={expense_type.ALLOWANCES}>ALLOWANCES</MenuItem>
              <MenuItem value={expense_type.OTHERS}>OTHERS</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          {selectedExpenseType === expense_type.RENT ? (
            <Paper elevation={8} sx={{ padding: "10px", bgcolor: "#f5eef8" }}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                RENT
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <FormControl sx={{ width: 220 }} size="small">
                    <InputLabel>Select Month</InputLabel>
                    <Select
                      value={selectMonth}
                      label="Select Month"
                      onChange={(event) => setSelectMonth(event.target.value)}
                    >
                      <MenuItem value={1}>JAN</MenuItem>
                      <MenuItem value={2}>FEB</MenuItem>
                      <MenuItem value={3}>MARCH</MenuItem>
                      <MenuItem value={4}>APRIL</MenuItem>
                      <MenuItem value={5}>MAY</MenuItem>
                      <MenuItem value={6}>JUNE</MenuItem>
                      <MenuItem value={7}>JULY</MenuItem>
                      <MenuItem value={8}>AUG</MenuItem>
                      <MenuItem value={9}>SEP</MenuItem>
                      <MenuItem value={10}>OCT</MenuItem>
                      <MenuItem value={11}>NOV</MenuItem>
                      <MenuItem value={12}>DESC</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    type="date"
                    size="small"
                    label="Paid Date"
                    value={paidDate}
                    onChange={(event) => setPaidDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"), // Set the maximum date
                    }}
                  />
                  <TextField
                    label="Amount"
                    variant="outlined"
                    size="small"
                    sx={{ width: 100 }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    type="number"
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                  />
                </Box>
                <Box>
                  <TextField
                    type="date"
                    size="small"
                    label="Next Due Date"
                    value={nextDueDate}
                    onChange={(event) => setNextDueDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button
                    onClick={handleUploadRecipt}
                    // variant="contained"
                    size="small"
                  >
                    <FileUpload sx={{ mt: "8px" }} />
                    <input
                      type="file"
                      ref={uploadPaymentRecipt}
                      style={{ display: "none" }}
                      onChange={(e) => setUploadReciptFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.WFI ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                WI-FI
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <FormControl sx={{ width: 150 }} size="small">
                    <InputLabel>Select Month</InputLabel>
                    <Select
                      value={selectMonth}
                      label="Select Month"
                      onChange={(event) => setSelectMonth(event.target.value)}
                    >
                      <MenuItem value={1}>JAN</MenuItem>
                      <MenuItem value={2}>FEB</MenuItem>
                      <MenuItem value={3}>MARCH</MenuItem>
                      <MenuItem value={4}>APRIL</MenuItem>
                      <MenuItem value={5}>MAY</MenuItem>
                      <MenuItem value={6}>JUNE</MenuItem>
                      <MenuItem value={7}>JULY</MenuItem>
                      <MenuItem value={8}>AUG</MenuItem>
                      <MenuItem value={9}>SEP</MenuItem>
                      <MenuItem value={10}>OCT</MenuItem>
                      <MenuItem value={11}>NOV</MenuItem>
                      <MenuItem value={12}>DESC</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    // type="date"
                    size="small"
                    label="Account Number"
                    value={accountNumber}
                    onChange={(event) => setAccountNumber(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type="date"
                    size="small"
                    label="Paid Date"
                    value={paidDate}
                    onChange={(event) => setPaidDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"), // Set the maximum date
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    type="date"
                    size="small"
                    label="Next Due Date"
                    value={nextDueDate}
                    onChange={(event) => setNextDueDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                  />
                  <Button onClick={handleUploadRecipt} size="small">
                    <FileUpload sx={{ mt: "8px" }} />
                    <input
                      type="file"
                      ref={uploadPaymentRecipt}
                      style={{ display: "none" }}
                      onChange={(e) => setUploadReciptFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.ELECTRICITY ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                ELECTRICITY
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <FormControl sx={{ width: 150 }} size="small">
                    <InputLabel>Select Month</InputLabel>
                    <Select
                      value={selectMonth}
                      label="Select Month"
                      onChange={(event) => setSelectMonth(event.target.value)}
                    >
                      <MenuItem value={1}>JAN</MenuItem>
                      <MenuItem value={2}>FEB</MenuItem>
                      <MenuItem value={3}>MARCH</MenuItem>
                      <MenuItem value={4}>APRIL</MenuItem>
                      <MenuItem value={5}>MAY</MenuItem>
                      <MenuItem value={6}>JUNE</MenuItem>
                      <MenuItem value={7}>JULY</MenuItem>
                      <MenuItem value={8}>AUG</MenuItem>
                      <MenuItem value={9}>SEP</MenuItem>
                      <MenuItem value={10}>OCT</MenuItem>
                      <MenuItem value={11}>NOV</MenuItem>
                      <MenuItem value={12}>DESC</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    // type="date"
                    size="small"
                    label="Account Number"
                    value={accountNumber}
                    onChange={(event) => setAccountNumber(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type="date"
                    size="small"
                    label="Paid Date"
                    value={paidDate}
                    onChange={(event) => setPaidDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"), // Set the maximum date
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    type="date"
                    size="small"
                    label="Next Due Date"
                    value={nextDueDate}
                    onChange={(event) => setNextDueDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                  />
                  <Button onClick={handleUploadRecipt} size="small">
                    <FileUpload sx={{ mt: "8px" }} />
                    <input
                      type="file"
                      ref={uploadPaymentRecipt}
                      style={{ display: "none" }}
                      onChange={(e) => setUploadReciptFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.CONSUMABLES ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                CONSUMABLES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                  sx={{ width: "200px" }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.ALLOWANCES ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                ALLOWANCES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                  sx={{ width: "200px" }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.OTHERS ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                OTHERS
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                  sx={{ width: "200px" }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {props.editNextDueDate === "edit" ? (
            <>
              <Paper
                elevation={8}
                sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    label="Reason"
                    variant="outlined"
                    size="small"
                    value={purpose}
                    sx={{ mt: "5px" }}
                    onChange={(event) => setPurpose(event.target.value)}
                  />
                  <Box>
                    <Typography sx={{ color: "red" }}>
                      Stop Expense :
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        sx={{
                          color: stopSwitch === false ? "#30A2F3" : "black",
                        }}
                      >
                        Disable
                      </Typography>
                      <AntSwitch
                        checked={stopSwitch}
                        onChange={(event) =>
                          setStopSwitch(event.target.checked)
                        }
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography
                        sx={{
                          color: stopSwitch === true ? "#30A2F3" : "black",
                        }}
                      >
                        Enable
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Paper>
            </>
          ) : (
            ""
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={handleCloseWarehouse}
        >
          close
        </Button>
        <Button variant="contained" onClick={handleSubmitWarehouse}>
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WarehouseAddDialog;
