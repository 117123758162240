/**
 * @author vinay kumar
 * @description Supplier Settings Main
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 29-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomConsole from "../../CustomConsole";
import Typography from "@mui/material/Typography";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import WarehouseStocks from "./WarehouseStocks";
import SuplierStock from "../SupplierSettings/SupplierUserStock";
import SupplierDriversList from "./SupplierDriversList";
import WareHouseList from "./WareHouseList";
import { employeeType } from "../../Components/AppMeta/appMetaConfig";
import SupplierVehicles from "./SupplierVehicles";
import Trips from "./Trips";
import TripsMain from "./TripsMain";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function SettingsMain(props) {
  CustomConsole(props);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Material.Box sx={{ marginTop: "50px", width: { md: "100%", xs: "95Vw" } }}>
      <Box sx={{ bgcolor: "background.paper" }}>
        <AppBar
          position="static"
          sx={{
            color: "white",
            borderRadius: "10px",
            width: { md: "100%", xs: "95Vw" },
            // background: appColors.commonLayoutColor.background,
            // backgroundImage: appColors.commonLayoutColor.backgroundImage,
            background: "#6495ED",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            // variant="fullWidth"
            variant="scrollable"
            aria-label="full width tabs example"
          >
            <Tab label="Scan" {...a11yProps(0)} />
            <Tab label="Stocks" {...a11yProps(1)} />
            <Tab label="Managers" {...a11yProps(2)} />
            <Tab label="Agents" {...a11yProps(3)} />
            <Tab label="Loaders" {...a11yProps(4)} />
            <Tab label="Warehouses" {...a11yProps(5)} />
            {/* <Tab label="Vehicles" {...a11yProps(6)} /> */}
            {/* <Tab label="Trips" {...a11yProps(7)} /> */}
          </Tabs>
        </AppBar>
        {/* <Tab
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          // height="100vh"
        > */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <WarehouseStocks supplierId={props.supplierId} />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <SuplierStock supplierId={props.supplierId} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SupplierDriversList
            supplierId={props.supplierIntegerId}
            employee_type={employeeType.MANAGER}
          />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <SupplierDriversList
            supplierId={props.supplierIntegerId}
            employee_type={employeeType.DRIVER}
          />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <SupplierDriversList
            supplierId={props.supplierIntegerId}
            employee_type={employeeType.LOADER}
          />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <WareHouseList
            supplierId={props.supplierId}
            supplierIntegerId={props.supplierIntegerId}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={6} dir={theme.direction}>
          <SupplierVehicles />
        </TabPanel> */}
        {/* <TabPanel value={value} index={7} dir={theme.direction}>
           <TripsMain supplierId={props.supplierId} />
        </TabPanel> */}
        {/* </Tab> */}
      </Box>
    </Material.Box>
  );
}

export default SettingsMain;
