/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description BookWater App Authentication Page With Login & SignUP Components
 * @date 13/02/23
 * @version 0.0.1 - Initial Release
 */

import React, { useEffect, useState } from "react";
import CustomConsole from "../../CustomConsole";
import * as Material from "@mui/material";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import ConformationDialog from "../../Components/Dialog/conformationDialog";

function GroupProductCamera({
  setGroupingProduct,
  groupingProduct,
  handelSubmit,
  onNewItemScanned,
  handelClear,
  setCancelGrouping,
  type,
  cancelGrouping,
}) {
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [qrBarcodeId, setQrBarcodeId] = useState("");

  //function for scanning and grouping products
  const onNewScanResult = (decodedText, decodedResult) => {
    setQrBarcodeId(decodedText);
  };
  useEffect(() => {
    if (qrBarcodeId) {
      onNewItemScanned(qrBarcodeId);
      setQrBarcodeId("");
    }
  }, [qrBarcodeId]);
  //function for closing infodialogbox
  const handelProceed = () => {
    setConfirmDialog(false);
  };
  return (
    <Material.Box>
      <Html5QrcodePlugin1
        fps={1}
        qrbox={200}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResult}
      />
      <Material.Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Material.Button
          size="small"
          onClick={handelSubmit}
          variant="contained"
        >
          Submit
        </Material.Button>
        <Material.Button size="small" onClick={handelClear} variant="contained">
          Clear Action
        </Material.Button>
      </Material.Box>
      <ConformationDialog
        setConfirmDialog={setConfirmDialog}
        confirmDialog={confirmDialog}
        dialogMsg={dialogMsg}
        handelProceed={handelProceed}
      />
    </Material.Box>
  );
}

export default GroupProductCamera;
