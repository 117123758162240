import React from "react";
import * as Material from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { transferCansStatus } from "../AppMeta/appMetaConfig";

function TransferCansFilter(props) {
  // const [orderStatus, setOrderStatus] = React.useState(
    //   transferCansStatus.TRANSFER_CAN_ORDER_CREATED
  // );
  
  const handleClose = () => {
    props.handleClose();
    // setOrderStatus("");
  };
  const handleFilter = () => {
    props.handleFilter();
    // setOrderStatus("");
  };
  const handleChangeOrderStatus = (event) => {
    // props.handleChangeOrderStatus();
    props.setOrderStatus(event.target.value);
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openTransferCansFilterDialog}>
        <Material.DialogContent>
          <Material.Typography sx={{ marginBottom: "20px" }}>
            Order Status
          </Material.Typography>
          <Material.Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel>Order Status</InputLabel>
              <Select
                value={props.orderStatus}
                label="Order Status"
                onChange={handleChangeOrderStatus}
              >
                <MenuItem value={transferCansStatus.TRANSFER_CAN_ORDER_CREATED}>
                  ORDER_CREATED
                </MenuItem>
                <MenuItem
                  value={transferCansStatus.TRANSFER_CAN_ORDER_CONFIRMED}
                >
                  ORDER_CONFIRMED
                </MenuItem>
                <MenuItem
                  value={transferCansStatus.TRANSFER_CAN_ORDER_INSCAN_COMPLETED}
                >
                  ORDER_INSCAN_COMPLETED
                </MenuItem>
                <MenuItem
                  value={transferCansStatus.TRANSFER_CAN_ORDER_REJECTED}
                >
                  ORDER_REJECTED
                </MenuItem>
              </Select>
            </FormControl>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "10px",
            }}
          >
            <Material.Button
              variant="contained"
              onClick={handleClose}
              color="error"
            >
              Close
            </Material.Button>
            <Material.Button variant="contained" onClick={handleFilter}>
              Filter
            </Material.Button>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default TransferCansFilter;
