import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { styles } from "./style";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomConsole from "../../CustomConsole";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { toast } from "react-toastify";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { FileUpload } from "@mui/icons-material";

function AdminSettlementFormCard(props) {
  const settlementsDetails = useSelector((state) => state.settlementsDetails);
  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);

  React.useEffect(() => {
    CustomConsole(userDetailsUpdate);
  }, [userDetailsUpdate]);
  //Refs to trigger upload file
  const salesInvoiceRef = React.useRef(null);
  const paymentReciptRef = React.useRef(null);

  // Setting the date variables
  const handleChangeFrom = (newValue) => {
    CustomConsole(newValue);
    CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
    props.setFromDate(moment(newValue.$d).format("YYYY/MM/DD"));
  };

  // Setting the date variables
  const handleChangeTo = (newValue) => {
    CustomConsole(newValue);
    CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
    props.setToDate(moment(newValue.$d).format("YYYY/MM/DD"));
  };

  const disableDates = (date) => {
    return dayjs(date).isAfter(dayjs().subtract(1, "day"), "day");
  };

  const uploadFilesSalesInvoice = () => {
    salesInvoiceRef.current.click();
  };

  const uploadFilesPaymentRecipt = () => {
    paymentReciptRef.current.click();
  };

  const handleSearchDateToDate = () => {
    CustomConsole("Search By Date to Date");
    props.setSearchDateToDateFlag(true);
    props.setShowSettlementData(true);
  };

  const handleUpdateSettlementWFSDistributor = () => {
    if (props.emailIdError === false && props.paymentReciptURL !== "") {
      props.setUpdateBtnFlag(true);
    } else if (props.paymentReciptURL === "") {
      toast.error("Upload the payment recipt", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Email ID is not proper", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  React.useEffect(() => {
    CustomConsole(props.paymentReciptFile);
  }, [props.paymentReciptFile]);

  // Calculating according to the settlement price change as well as TDS, Extra charges change. Then showing the Amount for settlement
  React.useEffect(() => {
    CustomConsole(props.settlementPricePerCan);
    CustomConsole(props.totalCansSold);

    CustomConsole(props.settlementPricePerCan * props.totalCansSold);
    CustomConsole(
      (props.tdsCharges * (props.settlementPricePerCan * props.totalCansSold)) /
        100
    );
    CustomConsole(
      (props.extraCharges *
        (props.settlementPricePerCan * props.totalCansSold)) /
        100
    );
    props.setAmountToSettle(
      props.totalCansSold * props.settlementPricePerCan -
        ((props.tdsCharges *
          (props.settlementPricePerCan * props.totalCansSold)) /
          100 +
          (props.extraCharges *
            (props.settlementPricePerCan * props.totalCansSold)) /
            100)
    );
  }, [props.settlementPricePerCan, props.tdsCharges, props.extraCharges]);

  // Setting the TDS, extra charges Values
  const handleChangeTds = (e) => {
    const value = e.target.value;
    if (value === "" || value >= 0) {
      props.setTdsCharges(e.target.value);
    }
  };
  const handleChangeExtraCharges = (e) => {
    const value = e.target.value;
    if (value === "" || value >= 0) {
      props.setExtraCharges(e.target.value);
    }
  };

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.(com)$/i;

    CustomConsole(emailRegex.test(email));
    props.setEmailWfs(email);

    if (emailRegex.test(email) === true) {
      props.setEmailIdError(false);
    } else {
      props.setEmailIdError(true);
    }
  };

  React.useEffect(() => {
    let currentPrice = props.currentWaterDropPrice * props.requestedWaterDrops;
    let tdsCharge = (currentPrice * props.tdsCharges) / 100;
    let extraCharge = (currentPrice * props.extraCharges) / 100;
    let totalSettlementPrice = currentPrice - tdsCharge - extraCharge;

    CustomConsole(totalSettlementPrice);
    props.setTotalSettlementPrice(totalSettlementPrice.toFixed(2));
  }, [props.requestedWaterDrops, props.tdsCharges, props.extraCharges]);
  return (
    <>
      {settlementsDetails.settleInfluncererror &&
      settlementsDetails.userSettlementError === false &&
      props.userRadio === "settlement_request" ? (
        <Card sx={{ mt: "25px" }} elevation={10}>
          <CardContent>
            <>
              {" "}
              {/* {props.userRadio === "settlement_request" && ( */}
              <>
                {/* // <Card sx={{ mt: "25px" }} elevation={10}>
                //   <CardContent> */}
                {settlementsDetails.settleInfluncererror &&
                settlementsDetails.userSettlementError === false ? (
                  <>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginTop: "5px",
                      }}
                    >
                      User Details
                    </Typography>
                    <Box sx={styles.parentBox}>
                      <Box sx={styles.childBox}>
                        <Typography sx={styles.childTypo}>
                          User Name:
                        </Typography>
                        <Typography>
                          {props.userName === undefined ||
                          props.userName === "" ||
                          props.userName == null
                            ? "Not Mentioned"
                            : props.userName}
                        </Typography>
                        <Typography sx={styles.childTypo}>Email:</Typography>
                        <Typography>{props.emailDetails}</Typography>
                      </Box>
                      <Box sx={styles.childBox}>
                        <Typography sx={styles.childTypo}>
                          User Type:{" "}
                        </Typography>
                        <Typography>
                          {props.userType === 2000
                            ? "Supplier"
                            : props.userType === 3000
                            ? "Consumer"
                            : props.userType === 1002
                            ? "Filling station"
                            : "---"}
                        </Typography>
                        <Typography sx={styles.childTypo}>Phone:</Typography>
                        <Typography> {props.phoneDetails}</Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {/* {props.userType !== ADMIN_ROLES.BW_CONSUMER ? (
                    <>
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          gap: "10px",
                          marginTop: "20px",
                        }}
                      >
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="From"
                              inputFormat="DD/MM/YYYY"
                              value={props.fromDate}
                              onChange={handleChangeFrom}
                              shouldDisableDate={disableDates}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="To"
                              inputFormat="DD/MM/YYYY"
                              value={props.toDate}
                              onChange={handleChangeTo}
                              disableFuture={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                      <Box sx={{ marginTop: "20px" }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ float: "center" }}
                          onClick={handleSearchDateToDate}
                        >
                          Search
                        </Button>
                      </Box>
                    </>
                  ) : (
                    ""
                  )} */}
                {/* //   </CardContent>
                // </Card> */}
              </>
              {/* )} */}
              {/* <Card
                sx={{
                  marginTop: "20px",
                }}
                elevation={10}
              > */}
              {/* {(props.userType === ADMIN_ROLES.BW_DISTRIBUTER ||
              props.userType === ADMIN_ROLES.BW_WFSADMIN) &&
            props.showSettlementData === true ? (
              <>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#AED6F1",

                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#1B2631",
                      fontWeight: "600",
                    }}
                  >
                    Settlement (Distributor / WFS)
                  </Typography>
                </Box>
                <CardContent>
                  <Box
                    sx={{
                      marginTop: "20px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                        Total Cans
                        <Typography>
                          {props.totalCansSold !== null
                            ? props.totalCansSold
                            : "---"}
                        </Typography>
                      </Box>
                      <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                        Total Price
                        <Typography>
                          {props.totalAmount !== null
                            ? props.totalAmount
                            : "---"}
                        </Typography>
                      </Box>
                      <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                        Base Can Price
                        <Typography>
                          {props.baseCanPrice !== null
                            ? props.baseCanPrice
                            : "---"}
                        </Typography>
                      </Box>
                    </Stack>
                    {props.userType === ADMIN_ROLES.BW_DISTRIBUTER && (
                      <Stack
                        sx={{
                          marginTop: "30px",
                        }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                          Cans Sold For Base Price:
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "15px",
                              backgroundColor: "#9ECBDE",
                              color: "#5E6F6F",
                              padding: "10px",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Total Cans:
                              {props.neutralCans !== null
                                ? props.neutralCans
                                : "---"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                          Cans Sold Less Than Base Price:
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "15px",
                              backgroundColor: "#9ECBDE",
                              color: "#5E6F6F",
                              padding: "10px",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Total Cans:
                              {props.canInLoss !== null
                                ? props.canInLoss
                                : "---"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                          Cans Sold More Than Base Price:
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "15px",
                              backgroundColor: "#9ECBDE",
                              color: "#5E6F6F",
                              padding: "10px",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Total Cans:
                              {props.canInProfit !== null
                                ? props.cansInProfit
                                : "---"}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                    )}

                    <Stack
                      sx={{
                        marginTop: "30px",
                      }}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {props.userType === ADMIN_ROLES.BW_DISTRIBUTER && (
                        <>
                          <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                            Total Loss Each:
                            <Typography>
                              {props.totalLossEach !== null
                                ? props.totalLossEach
                                : "---"}
                            </Typography>
                          </Box>
                          <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                            Total Profit Each:
                            <Typography>
                              {props.totalProfitEach !== null
                                ? props.totalProfitEach
                                : "---"}
                            </Typography>
                          </Box>
                        </>
                      )}

                      <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                        Total Amount To Settle:
                        <Typography>
                          {props.amountToSettle !== null
                            ? props.amountToSettle
                            : "---"}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      sx={{
                        marginTop: "30px",
                      }}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "13px" }}
                        >
                          TDS Charges
                        </Typography>
                        <TextField
                          label="price"
                          type="number"
                          size="small"
                          value={props.tdsCharges}
                          // onChange={(e) => props.setTdsCharges(e.target.value)}
                          onChange={handleChangeTds}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "13px" }}
                        >
                          Extra Charges
                        </Typography>
                        <TextField
                          label="price"
                          type="number"
                          size="small"
                          value={props.extraCharges}
                          onChange={handleChangeExtraCharges}
                        />
                      </Box>
                    </Stack>
                    <Stack
                      sx={{
                        marginTop: "30px",
                      }}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "13px" }}
                        >
                          Settlement Price Per Can:
                        </Typography>
                        <TextField
                          label="price"
                          type="number"
                          size="small"
                          value={props.settlementPricePerCan}
                          onChange={(e) => {
                            {
                              const value = e.target.value;
                              if (value === "" || value >= 0) {
                                props.setSettlementPricePerCan(e.target.value);
                              }
                            }
                          }}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ padding: "10px" }}>
                      {props.userType === ADMIN_ROLES.BW_WFSADMIN && (
                        <TextField
                          label="Email"
                          type="email"
                          size="small"
                          value={props.emailWfs}
                          onChange={handleChangeEmail}
                          error={props.emailIdError}
                        />
                      )}
                    </Box>
                    <Stack
                      sx={{
                        marginTop: "30px",
                      }}
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={2}
                    >
                      <Box>
                        <Button
                          onClick={uploadFilesSalesInvoice}
                          variant="outlined"
                        >
                          Upload Sales Invoice
                          <input
                            type="file"
                            ref={salesInvoiceRef}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              props.setSalesInvoiceFile(e.target.files[0])
                            }
                          />
                        </Button>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          onClick={uploadFilesPaymentRecipt}
                          variant="outlined"
                        >
                          Upload Payment Receipt
                          <input
                            type="file"
                            ref={paymentReciptRef}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              props.setPaymentReciptFile(e.target.files[0])
                            }
                          />
                        </Button>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        marginTop: "20px",
                        float: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleUpdateSettlementWFSDistributor}
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </>
            ) : (
              ""
            )} */}
              {props.userType === ADMIN_ROLES.BW_CONSUMER &&
              props.userRadio === "settlement_request" ? (
                <>
                  {" "}
                  {/* <CardContent> */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      padding: "10px",
                    }}
                  >
                    <Box>
                      <Typography sx={{ color: "#4497B8", fontWeight: "bold" }}>
                        Existing Water Drops:
                      </Typography>
                      <Typography>
                        {props.existingWaterDrops !== null
                          ? props.existingWaterDrops
                          : "---"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "#4497B8", fontWeight: "bold" }}>
                        Requested Water Drops:
                      </Typography>
                      <Typography>
                        {props.requested_water_drops !== null
                          ? props.requestedWaterDrops
                          : "---"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "#4497B8", fontWeight: "bold" }}>
                        Total Settlement Price:
                      </Typography>
                      <Typography sx={{ color: "brown", fontWeight: "bold" }}>
                        {props.totalSettlementPrice !== null
                          ? props.totalSettlementPrice
                          : "---"}
                      </Typography>
                    </Box>
                  </Box>
                  <Stack
                    sx={{
                      marginTop: "30px",
                    }}
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    gap="20px"
                  >
                    <Box
                      sx={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                          // gap: "5px",
                        }
                      }
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          // fontSize: "13px",
                          color: "#4497B8",
                          padding: "5px",
                        }}
                      >
                        TDS Charges
                      </Typography>
                      <TextField
                        label="Percentage %"
                        type="number"
                        size="small"
                        value={props.tdsCharges}
                        // onChange={(e) => props.setTdsCharges(e.target.value)}
                        onChange={(e) => {
                          {
                            const value = e.target.value;
                            if (value === "" || value >= 0) {
                              props.setTdsCharges(e.target.value);
                            }
                          }
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        // display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography sx={{ color: "#4497B8", fontWeight: "bold" }}>
                        Current Water Drop Price:
                      </Typography>
                      <TextField
                        disabled
                        label="Price"
                        type="number"
                        size="small"
                        value={props.currentWaterDropPrice}
                        onChange={(e) => {
                          {
                            const value = e.target.value;
                            if (value === "" || value >= 0) {
                              props.setCurrentWaterDropPrice(e.target.value);
                            }
                          }
                        }}
                      />
                    </Box>
                    <Box
                      sx={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                          // gap: "10px",
                        }
                      }
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          // fontSize: "13px",
                          color: "#4497B8",
                          padding: "5px",
                        }}
                      >
                        Extra Charges
                      </Typography>
                      <TextField
                        label="Percentage %"
                        type="number"
                        size="small"
                        value={props.extraCharges}
                        // onChange={(e) => props.setExtraCharges(e.target.value)}
                        onChange={(e) => {
                          {
                            const value = e.target.value;
                            if (value === "" || value >= 0) {
                              props.setExtraCharges(e.target.value);
                            }
                          }
                        }}
                      />
                    </Box>
                  </Stack>
                  {Object.keys(userDetailsUpdate.userProfileData.consumer)
                    .length > 0 && (
                    <>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          marginTop: "30px",
                        }}
                      >
                        Bank Details
                      </Typography>
                      <Box sx={styles.parentBox}>
                        <Box sx={styles.childBox}>
                          <Typography sx={styles.childTypo}>
                            IFSC Code:
                          </Typography>
                          <Typography>
                            {userDetailsUpdate.userProfileData.consumer
                              .IFSC_number
                              ? userDetailsUpdate.userProfileData.consumer
                                  .IFSC_number
                              : "---Not Available---"}
                          </Typography>
                          <Typography sx={styles.childTypo}>
                            Account Number:
                          </Typography>
                          <Typography>
                            {" "}
                            {userDetailsUpdate.userProfileData.consumer
                              .account_number
                              ? userDetailsUpdate.userProfileData.consumer
                                  .account_number
                              : "---Not Available---"}
                          </Typography>
                        </Box>
                        <Box sx={styles.childBox}>
                          <Typography sx={styles.childTypo}>
                            Bank Name:{" "}
                          </Typography>
                          <Typography>
                            {userDetailsUpdate.userProfileData.consumer
                              .bank_name
                              ? userDetailsUpdate.userProfileData.consumer
                                  .bank_name
                              : "---Not Available---"}
                          </Typography>
                          <Typography sx={styles.childTypo}>
                            PAN Number:
                          </Typography>
                          <Typography>
                            {" "}
                            {userDetailsUpdate.userProfileData.consumer
                              .pan_number
                              ? userDetailsUpdate.userProfileData.consumer
                                  .pan_number
                              : "---Not Available---"}
                          </Typography>
                        </Box>
                      </Box>
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                          marginTop: "10px",
                          borderRadius: "10px",
                          backgroundColor: appColors.commonLayoutColor,
                          padding: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "left",
                          }}
                        >
                          <Typography>IFSC Number: </Typography>
                          <Typography>Account Number: </Typography>
                          <Typography>Bank Name: </Typography>
                          <Typography>PAN Number: </Typography>
                        </Box>
                        <Box
                          sx={{
                            textAlign: "left",
                          }}
                        >
                          <Typography>
                            {
                              userDetailsUpdate.userProfileData.consumer
                                .IFSC_number
                            }
                          </Typography>
                          <Typography>
                            {
                              userDetailsUpdate.userProfileData.consumer
                                .account_number
                            }
                          </Typography>
                          <Typography>
                            {
                              userDetailsUpdate.userProfileData.consumer
                                .bank_name
                            }
                          </Typography>
                          <Typography>
                            {
                              userDetailsUpdate.userProfileData.consumer
                                .pan_number
                            }
                          </Typography>
                        </Box>
                      </Box> */}
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      // padding: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={uploadFilesPaymentRecipt}
                    >
                      <FileUpload /> Upload Payment Receipt{" "}
                      <input
                        type="file"
                        ref={paymentReciptRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          props.setPaymentReciptFile(e.target.files[0])
                        }
                      />
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleUpdateSettlementWFSDistributor}
                    >
                      Settle Influncer
                    </Button>
                  </Box>
                  {/* </CardContent> */}
                </>
              ) : (
                ""
              )}
              {/* </Card> */}
            </>
          </CardContent>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}

export default AdminSettlementFormCard;
