import React from "react";
import * as Material from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import CustomConsole from "../../CustomConsole";

function TranferCansAcceptOrRejectDialog(props) {
  CustomConsole(props);
  // const [isAccepted, setIsAccepted] = React.useState(true);
  const [isAcceptedValue, setIsAcceptedValue] = React.useState(false);
  CustomConsole(isAcceptedValue);
  const [supplerName, setSupplerName] = React.useState("");
  const [rejectReason, setRejectReason] = React.useState("");
  CustomConsole(supplerName);

  const handleChangeIsAccepected = (event) => {
    CustomConsole("-------------handleChangeIsAccepected-------------");
    CustomConsole(isAcceptedValue);
    // setIsAcceptedValue(event.target.value );
    // if (isAcceptedValue === "Accept") {
    //   setIsAcceptedValue(true);
    // } else if (isAcceptedValue === "Reject") {
    //   setIsAcceptedValue(false);
    // }
    const value = event.target.value === "true";
    // setIsAcceptedValue(value);
    setIsAcceptedValue(value);

    props.handleChangeIsAccepected({
      user_id: parseInt(props.singleData.user_id),
      total_container_qty: props.singleData.total_container_qty,
      container_capacity: props.singleData.container_capacity,
      container_material: props.singleData.container_material,
      value: value,
    });
  };
  const handleAcceptOrReject = () => {
    CustomConsole("-------------handleAcceptOrReject-------------");
    setIsAcceptedValue("");
    // setIsAccepted("");
    props.handleAcceptOrReject({
      order_id: props.singleData.transfer_cans_order_id,
      supplier_id: supplerName,
      is_accepted: isAcceptedValue,
      total_container_qty: props.singleData.total_container_qty,
      reject_reason: rejectReason,
      setIsAcceptedValue: setIsAcceptedValue,
      // setIsAccepted: setIsAccepted,
      setRejectReason: setRejectReason,
      setSupplerName: setSupplerName,
    });
    setIsAcceptedValue("");
    // setIsAccepted("");
    setRejectReason("");
  };
  const handleCloseAcceptRejectDialog = () => {
    CustomConsole("-------------handleCloseAcceptRejectDialog-------------");
    props.handleCloseAcceptRejectDialog({
      setIsAcceptedValue: setIsAcceptedValue,
      // setIsAccepted: setIsAccepted,
      setRejectReason: setRejectReason,
      setSupplerName: setSupplerName,
    });
  };
  const handlechangeSupplierList = (event) => {
    setSupplerName(event.target.value);
    // props.handlechangeSupplierList({
    //   user_id: parseInt(props.singleData.user_id),
    //   total_container_qty: props.singleData.total_container_qty,
    //   container_capacity: props.singleData.container_capacity,
    //   container_material: props.singleData.container_material,
    // });
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openDialogAcceptOrReject}>
        <Material.DialogContent>
          <FormControl>
            <FormLabel>Accept / Reject</FormLabel>
            <RadioGroup
              value={isAcceptedValue}
              onChange={handleChangeIsAccepected}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Accept"
              />
              {isAcceptedValue && (
                <Box sx={{ minWidth: 220 }}>
                  <FormControl fullWidth>
                    <InputLabel>Supplier List</InputLabel>
                    <Select
                      value={supplerName}
                      label="Supplier List"
                      onChange={handlechangeSupplierList}
                    >
                      {props.supplierList?.map((data) => {
                        return (
                          <MenuItem value={data.user_id}>
                            {data.fullname}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Reject"
              />
              {isAcceptedValue === false && (
                <Material.TextField
                  label="Enter Reason"
                  value={rejectReason}
                  onChange={(event) => setRejectReason(event.target.value)}
                />
              )}
            </RadioGroup>
          </FormControl>

          <Material.Box
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Material.Box sx={{ margin: "10px" }}>
              <Material.Button
                onClick={handleCloseAcceptRejectDialog}
                variant="contained"
                color="error"
              >
                Close
              </Material.Button>
            </Material.Box>
            <Material.Box sx={{ margin: "10px" }}>
              <Material.Button
                onClick={handleAcceptOrReject}
                variant="contained"
              >
                submit
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default TranferCansAcceptOrRejectDialog;
