/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 29-01-2024
 */
import React from "react";
import * as Material from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";

function GentateQrBarCodeCrateDialog(props) {
  const dispatch = useDispatch();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const crateDetails = skuContainerDetails.cateDetails;

  const [selectedOption, setSelectedOption] = React.useState();
  const handleGenrateCrateDilaog = () => {
    props.handleGenrateCrateDilaog();
  };
  const handleCloseCrateDilaog = () => {
    props.handleCloseCrateDilaog();
  };

  //function for handling crate details
  const handelSelectedCrate = (id) => {
    const crateSelected = crateDetails.find((option) => option.name === id);
    setSelectedOption(crateSelected.name);
    props.setgenerateCrateQRData((prev) => ({
      ...prev,
      crate_id: crateSelected.id,
      crate_name: crateSelected.name,
    }));
  };
  return (
    <Material.Dialog open={props.openGenrateQrBarcodeCrate}>
      <Material.DialogContent>
        <Material.Typography>Crate Details</Material.Typography>
        <Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            {" "}
            <Material.TextField
              id="outlined-select-currency-native"
              select
              sx={{
                width: { sm: "100%", xs: "100%" },
                marginTop: ".6rem",
              }}
              size="small"
              label="Crate Name*"
              value={selectedOption}
              onChange={(e) => handelSelectedCrate(e.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              <option>Select Crate</option>
              {crateDetails.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}/Capacity:{option.capacity}
                </option>
              ))}
            </Material.TextField>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            {" "}
            <Material.TextField
              label="Quantity*"
              name="qty"
              value={props.generateCrateQRData.qty}
              onChange={props.handleChangeCrateQrGenerate}
              type="number"
              fullWidth
              size="small"
              error={!!props.error.qty}
              helperText={props.error.qty}
            />
            <Material.TextField
              label="Company Name*"
              name="company_name"
              value={props.generateCrateQRData.company_name}
              onChange={props.handleMftDetailsChange}
              fullWidth
              size="small"
              error={!!props.error.company_name}
              helperText={props.error.company_name}
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            {" "}
            <Material.TextField
              label="Owner Name*"
              name="owner_name"
              value={props.generateCrateQRData.owner_name}
              onChange={props.handleMftDetailsChange}
              fullWidth
              size="small"
              error={!!props.error.owner_name}
              helperText={props.error.owner_name}
            />
            <Material.TextField
              label="Owner Phone*"
              name="owner_phone"
              value={props.generateCrateQRData.owner_phone}
              onChange={props.handleMftDetailsChange}
              type="number"
              fullWidth
              size="small"
              error={!!props.error.owner_phone}
              helperText={props.error.owner_phone}
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            {" "}
            <Material.TextField
              label="Line 1*"
              name="line1"
              value={props.generateCrateQRData.mft_details.address.line1}
              onChange={props.handleAddressChange}
              fullWidth
              size="small"
            />
            <Material.TextField
              label="Line 2*"
              name="line2"
              value={props.generateCrateQRData.mft_details.address.line2}
              onChange={props.handleAddressChange}
              fullWidth
              size="small"
            />
          </Material.Box>
          <Material.Box sx={{ margin: "10px" }}>
            {" "}
            <Material.TextField
              label="Landmark*"
              name="landmark"
              value={props.generateCrateQRData.mft_details.address.landmark}
              onChange={props.handleAddressChange}
              fullWidth
              size="small"
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            {" "}
            <Material.TextField
              label="Area"
              name="area"
              value={props.generateCrateQRData.mft_details.address.area}
              onChange={props.handleAddressChange}
              fullWidth
              size="small"
            />
            <Material.TextField
              label="State"
              name="state"
              value={props.generateCrateQRData.mft_details.address.state}
              onChange={props.handleAddressChange}
              fullWidth
              size="small"
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            {" "}
            <Material.TextField
              label="Country*"
              name="country"
              value={props.generateCrateQRData.mft_details.address.country}
              onChange={props.handleAddressChange}
              fullWidth
              size="small"
            />
            <Material.TextField
              label="Pincode*"
              type="number"
              name="pincode"
              value={props.generateCrateQRData.mft_details.address.pincode}
              onChange={props.handleAddressChange}
              error={!!props.error.pincode}
              helperText={props.error.pincode}
              fullWidth
              size="small"
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            {" "}
            <Material.TextField
              label="Gst Number*"
              name="GST"
              value={props.generateCrateQRData.mft_details.address.GST}
              onChange={props.handleOtherDetailsChange}
              fullWidth
              size="small"
              error={!!props.error.GST}
              helperText={props.error.GST}
            />
            <Material.TextField
              label="PAN Number*"
              name="PAN"
              value={props.generateCrateQRData.mft_details.address.PAN}
              onChange={props.handleOtherDetailsChange}
              fullWidth
              size="small"
              error={!!props.error.PAN}
              helperText={props.error.PAN}
            />
          </Material.Box>
        </Material.Box>
      </Material.DialogContent>
      <Material.DialogActions>
        <Material.Button
          color="error"
          variant="contained"
          onClick={handleCloseCrateDilaog}
          size="small"
        >
          Close
        </Material.Button>
        <Material.Button
          size="small"
          variant="contained"
          onClick={props.handleQrGenerateCrate}
        >
          Genrate
        </Material.Button>
      </Material.DialogActions>
    </Material.Dialog>
  );
}

export default GentateQrBarCodeCrateDialog;
