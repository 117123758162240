import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { styles } from "./postpaidCardStyles";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  GetWalletDetails,
  PostpaidOrder,
  RejectPostpaidOrder,
  SelectSuppiersPostpaid,
  UpdateAllPostpaidPrice,
  AssignSupplier,
  GetPostpaidOrders,
} from "../Redux/Actions/adminPostpaidOrdersAction";
import { ToastContainer, toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/system";
import { getFiles } from "../Redux/Actions/fileHandlingAction";

export default function PaymentCardPostpaid({
  phonePostpaidList,
  userId,
  setUserId,
  selected,
  totalPrice,
  request,
  setPaymentMethod,
  paymentMethod,
  orderby,
  status,
  emailPhone,
  pageNumber,
  setTotalPrice,
  rejectBtn,
  setRejectBtn
}) {
  const dispatch = useDispatch();
  const postpaidDetails = useSelector((state) => state.postpaidOrderAdmin);
  const setUpConfigData = useSelector((state) => state.adminBookingData);
  const assingningSupplierResponse = useSelector(
    (state) => state.postpaidOrderAdmin.orderDetails
  );

  const [updateBtn, setUpdateBtn] = React.useState(false);
  const [price, setPrice] = React.useState(null);

  const [walletBalance, setWalletBalance] = React.useState(null);
  let settlement=true
  React.useEffect(() => {
    if (userId !== "") {
      dispatch(GetWalletDetails(userId));
      dispatch(GetPostpaidOrders(emailPhone, orderby, status, pageNumber - 1,settlement));
    }
  }, [userId]);
  CustomConsole(userId);
  React.useEffect(() => {
    CustomConsole(postpaidDetails);
    if (Object.keys(postpaidDetails.walletDetails).length > 0) {
      setWalletBalance(postpaidDetails.walletDetails.amount);
    }
    if (rejectBtn === true && postpaidDetails.error === false) {
      dispatch(GetPostpaidOrders(emailPhone, orderby, status, pageNumber - 1,settlement));
      setRejectBtn(false);
    }
    if (rejectBtn === true && postpaidDetails.error === true) {
      CustomConsole("error");
      toast.error(postpaidDetails.rejectPostpaidMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setRejectBtn(false);
    }

    if (postpaidDetails.error === false) {
      setUpdateBtn(false);
    }
  }, [postpaidDetails]);

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  CustomConsole(paymentMethod);
  const handleUpdatePrice = () => {
    if (paymentMethod !== "" && totalPrice > 0) {
      let obj = {
        order_id: request,
        totalamount: totalPrice,
        payment_method: paymentMethod,
      };
      dispatch(UpdateAllPostpaidPrice(obj));
      setPrice(null);
      setUpdateBtn(true);
      setTotalPrice(0);
      setTimeout(() => dispatch(GetWalletDetails(userId)), 2000);
      setRejectBtn(true);
    } else {
      toast.error("Please select payment method and price to update", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handlePriceChange = (event) => {
    if (event.target.value >= 0) {
      setTotalPrice(event.target.value);
    } else {
      toast.error("Price cannot be less than zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <Paper elevation={24} sx={{marginTop:"2em"}}>
        <Card>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              <span style={styles.updateStyle}>Update price</span>
            </Typography>
          </Box>
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box sx={styles.stockDataStyleHeading}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Total Pending price:
                    <span style={styles.stockDataStyleValue}>{totalPrice}</span>
                  </Typography>
                </Box>
                <Box sx={styles.stockDataStyleHeading}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Wallet balance:
                    <span style={styles.stockDataStyleValue}>
                      {walletBalance}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "10px",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                // gap: "20px",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                <TextField
                  id="outlined-number"
                  label="Price"
                  value={totalPrice}
                  type="number"
                  onChange={handlePriceChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ padding: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Payment method
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={paymentMethod}
                    label="Payment method"
                    onChange={handleChange}
                  >
                    <MenuItem value="WaterWallet">WaterWallet</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleUpdatePrice}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Paper>
      <ToastContainer />
    </>
  );
}
