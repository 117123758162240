import * as React from "react";
import CustomConsole from "../../CustomConsole";
import Slide from "@mui/material/Slide";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { GetWfsOngoingBatchLineDetails } from "../Redux/Actions/wfsOnGoingBatchAction";
import { useDispatch, useSelector } from "react-redux";
import { GetWfsOngoingBatch } from "../Redux/Actions/wfsOnGoingBatchAction";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WfsCreateBatchCard({
  isCreateBatchDialogOpen,
  setIsCreateBatchDialogOpen,
  capacity,
  material,
  lineId,
  batchQuantity,
  setCapacity,
  setMaterial,
  setLineId,
  setBatchQuantity,
  setCreateBatchFlag,
  wfsBatchDetails,
  pageNumber,
  isUpdate,
  setIsUpdate,
}) {
  const dispatch = useDispatch();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [productId, setProductId] = React.useState(0);
  const handleClose = () => {
    setIsCreateBatchDialogOpen(false);
    setBatchQuantity("");
    setLineId("");
    setMaterial("");
    setCapacity("");
  };
  const handleBatchQuantityChange = (event) => {
    // CustomConsole(event.target.value);
    // if (event.target.value > 0) {
    //   setBatchQuantity(event.target.value);
    // }
    const value = event.target.value;

    // Check if the entered value is a positive number
    if ((/^\d*\.?\d*$/.test(value) && parseFloat(value) > 0) || value === "") {
      setBatchQuantity(value);
    } else {
      toast.error("Please enter More Than Zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleCreatebatch = () => {
    if (wfsBatchDetails.lineDetailsError === false && lineId !== "") {
      setCreateBatchFlag(true);
    } else {
      toast.error(
        "Contact Bookwater Support to add line details to create batch",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  const handleChangeSelectedProduct = (event) => {
    setProductId(event.target.value);
    const selectedDetails = skuContainerDetails.getAvailableProducts.find(
      (data) => data.product_details.id === event.target.value
    );
    if (selectedDetails) {
      setCapacity(selectedDetails.product_details.capacity);
      setMaterial(selectedDetails.product_details.material);
    }
  };
  CustomConsole(skuContainerDetails);
  return (
    <div>
      <Dialog
        open={isCreateBatchDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", m: "10px" }}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleClose}
          >
            <Close />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Create Batch Here
          </Typography>
        </Box>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "20px",
              padding: "10px",
            }}
          >
            <FormControl sx={{ minWidth: "100%" }} size="small">
              <InputLabel id="demo-select-small">Product*</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={productId}
                label="Capacity"
                onChange={handleChangeSelectedProduct}
              >
                {skuContainerDetails.getAvailableProducts.map((data) => (
                  <MenuItem
                    value={data.product_details.id}
                    key={data.product_details.id}
                  >
                    <div>
                      <span>
                        {" "}
                        <strong>{data.product_details.name}</strong>
                      </span>
                      <br />
                      <small>
                        Capacity:
                        {data.product_details.capacity}/
                      </small>
                      <small>
                        Material:
                        {data.product_details.material}
                      </small>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ width: "100%" }}>
              {wfsBatchDetails.lineDetailsError === false ? (
                <TextField
                  select
                  size="small"
                  label="Select Line ID"
                  helperText="Please select your line ID"
                  onChange={(event) => setLineId(event.target.value)}
                >
                  {wfsBatchDetails?.lineBatchDetails?.map((option) => (
                    <MenuItem value={option.line_id}>{option.line_id}</MenuItem>
                  ))}
                </TextField>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <TextField
                id="standard-number"
                label="Enter batch quantity"
                // type="number"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                variant="standard"
                value={batchQuantity}
                onChange={handleBatchQuantityChange}
                // onInput={(e) => {
                //   e.target.value = Math.max(1, parseInt(e.target.value))
                //     .toString()
                //     .slice(1, 10);
                // }}
              />
            </Box>

            <Box sx={{ display: "flex", marginTop: "10px" }}>
              <Button variant="contained" onClick={handleCreatebatch}>
                Create batch
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
