import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  qrDetails: "",
  containerDetails: { qualityDetails: [], ownerDetails: [], ownerType: 0 },
  crateContainerDetails: { qualityDetails: [], ownerDetails: [], ownerType: 0 },
  generateQrMsg: "",
  generateBarMsg: "",
  regenerateBarMsg: "",
  qrListData: [],
  qrListMsg: "",
  linkingError: false,
  linkingMsg: "",
  inScanError: false,
  inScanMsg: "",
  outScanError: false,
  outScanMsg: "",
};

const adminQrBarcodeReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_CONTAINER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        containerDetails: action.payload.data,
      };
      case actionType.GET_CRATE_DETAILS:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
          crateContainerDetails: action.payload.data,
        };
    case actionType.GET_QR_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        qrDetails: action.payload.data,
      };
    case actionType.GENERATE_QR_CODE:
      return {
        ...state,
        error: action.payload.error,
        generateQrMsg: action.payload.msg,
      };
    case actionType.GENERATE_BAR_CODE:
      return {
        ...state,
        error: action.payload.error,
        generateBarMsg: action.payload.msg,
      };
    case actionType.REGENERATE_QRBAR_CODE:
      return {
        ...state,
        error: action.payload.error,
        regenerateBarMsg: action.payload.msg,
      };
    case actionType.GET_QR_LIST:
      return {
        ...state,
        error: action.payload.error,
        qrListData: action.payload.data,
        qrListMsg: action.payload.msg,
      };
    case actionType.LINK_QR_BARCODE:
      return {
        ...state,
        linkingError: action.payload.error,
        linkingMsg: action.payload.msg,
      };
    case actionType.IN_SCAN_EMPTY:
      return {
        ...state,
        inScanError: action.payload.error,
        inScanMsg: action.payload.msg,
      };
    case actionType.OUT_SCAN_FILLED:
      return {
        ...state,
        outScanError: action.payload.error,
        outScanMsg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default adminQrBarcodeReducer;
