import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchAreasByPincode } from "../Redux/Actions/adminBookingAction";
import CustomConsole from "../../CustomConsole";
import {
  ServiceAreaAndRate,
  UpdatePackageDrinkingWater,
} from "../Redux/Actions/appSettingsAction";
import { toast } from "react-toastify";
import { Stack } from "@mui/system";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function CsaRateforAll({ adminRole }) {
  const [pincode, setPincode] = React.useState(""); 
  const [addFields, setAddFields] = React.useState(false);
  const [openArea, setOpenArea] = React.useState(false);
  const [area, setArea] = React.useState("");
  const [renderingFlag, setRenderingFlag] = React.useState(false);
  const [priceUpdateCard, setPriceUpdateCard] = React.useState(false);

  //States for editing form

  const [twentyLit, setTwentyLit] = React.useState("");
  const [fiveLit, setFiveLit] = React.useState(0);
  const [twoLit, setTwoLit] = React.useState(0);
  const [oneLit, setOneLit] = React.useState(0);
  const [fiveHndMl, setFiveHndMl] = React.useState(0);
  const [twoHndMl, setTwoHndMl] = React.useState(0);

  const dispatch = useDispatch();
  const pincodeDetails = useSelector(
    (state) => state.adminBookingData.availableAreas
  );
  const pdwDetails = useSelector(
    (state) => state.appSettingsDetails.packagedDrinkingWater
  );
  const responsePWDUpdate = useSelector((state) => state.appSettingsDetails);

  const scrollDownToDetailsCard = React.useRef(null);

  // const pincodeUpdate = () => {
  //   CustomConsole(pincode);
  //   if (pincode.toString().length > 6 || pincode.toString().length < 6) {
  //     toast.error("Enter valid pin number", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   }
  //   if (pincode.toString().length === 6) {
  //     dispatch(SearchAreasByPincode(pincode));
  //     CustomConsole(pincodeDetails);
  //     setAddFields(true);
  //     setPriceUpdateCard(false);
  //   }
  // };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length === 6) {
      dispatch(SearchAreasByPincode(e.target.value));
      setAddFields(true);
      setPriceUpdateCard(false);
      setArea("");
    }
    if (e.target.value.length < 6) {
      setOpenArea(false);
    }
  };

  React.useEffect(() => {
    CustomConsole(pincodeDetails);
    if (pincodeDetails && pincodeDetails.length > 0 && addFields) {
      setOpenArea(true);
      setAddFields(false);
    }
    if (!pincodeDetails && addFields) {
      setOpenArea(false);
      setAddFields(false);
    }
  }, [pincodeDetails]);

  const getpincodeDetails = () => {
    if (pincode.toString().length === 6 && area !== "") {
      let reqObj = {
        pincode: pincode,
        area: area,
      };
      CustomConsole(reqObj);
      dispatch(ServiceAreaAndRate(reqObj));
      setPriceUpdateCard(true);
      setTimeout(() => {
        scrollDownToDetailsCard.current?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (pdwDetails !== undefined) {
      setTwentyLit(pdwDetails.twenty_ltr_amount);
      setFiveLit(pdwDetails.five_ltr_amount);
      setTwoLit(pdwDetails.two_ltr_amount);
      setOneLit(pdwDetails.one_ltr_amount);
      setFiveHndMl(pdwDetails.fivehnd_ml_amount);
      setTwoHndMl(pdwDetails.twohnd_ml_amount);
    }
    if (!pdwDetails) {
      setTwentyLit("");
      setFiveLit(0);
      setTwoLit(0);
      setOneLit(0);
      setFiveHndMl(0);
      setTwoHndMl(0);
    }
    // setTimeout(() => {
    //   if (pdwDetails === undefined) {
    //     toast.error("Data not available", {
    //       position: "bottom-right",
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "colored",
    //     });
    //     setAddFields(false);
    //     setPriceUpdateCard(false);
    //   }
    // }, 1000);
  }, [pdwDetails]);

  const updateRateForCans = () => {
    if (
      pincode !== "" &&
      area !== "" &&
      twentyLit !== "" &&
      fiveLit !== "" &&
      twoLit !== "" &&
      oneLit !== "" &&
      fiveHndMl !== "" &&
      twoHndMl !== ""
    ) {
      const reqObj = {
        area_name: area,
        five_ltr_amount: fiveLit,
        fivehnd_ml_amount: fiveHndMl,
        one_ltr_amount: oneLit,
        pincode: pincode,
        twenty_ltr_amount: twentyLit,
        two_ltr_amount: twoLit,
        twohnd_ml_amount: twoHndMl,
      };
      CustomConsole(reqObj);
      dispatch(UpdatePackageDrinkingWater(reqObj));
      setRenderingFlag(true);
    } else {
      toast.error("Fill all fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  React.useEffect(() => {
    if (responsePWDUpdate.error === false && renderingFlag === true) {
      toast.success(responsePWDUpdate.updatingMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setPincode("");
      setArea("");
      setTwentyLit("");
      setFiveLit("");
      setTwoLit("");
      setOneLit("");
      setFiveHndMl("");
      setTwoHndMl("");
      setRenderingFlag(false);
      setPriceUpdateCard(false);
    }
  }, [responsePWDUpdate]);

  function handleKeyDownNum(event) {
    // Allow only numeric keys and some special keys
    const allowedKeys = ["Backspace", "Delete", "Tab", "Escape", "Enter"];
    if (!allowedKeys.includes(event.key) && isNaN(parseInt(event.key))) {
      event.preventDefault();
    }
  }
  return (
    <>
      {ADMIN_ROLES.BW_SALES === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ? (
        ""
      ) : (
        <Card ref={scrollDownToDetailsCard}>
          <CardContent>
            <Typography
              sx={{ fontSize: "18", fontWeight: "bold", color: "blue" }}
              display="block"
              gutterBottom
            >
              Common service area and rate for all
            </Typography>
            <form>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Grid item md={12}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <TextField
                      id="outlined-number"
                      size="small"
                      label="Pincode"
                      inputProps={{
                        maxLength: 6,
                      }}
                      value={pincode}
                      onKeyDown={handleKeyDownNum}
                      onChange={handlePincodeChange}
                    />
                  </Stack>
                </Grid>

                {openArea ? (
                  <>
                    <Grid item sm={12} md={12}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="area-select-small">Area</InputLabel>
                        <Select
                          labelId="area-select-small"
                          id="area-select-small"
                          label="Area"
                          style={{ width: 210 }}
                          value={area}
                          onChange={(e) => {
                            setArea(e.target.value);
                          }}
                        >
                          {pincodeDetails.map((option) => (
                            <MenuItem key={option.Name} value={option.Name}>
                              {option.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={12}>
                      <Button onClick={getpincodeDetails} variant="contained">
                        View
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
      {/* Edit field */}
      {priceUpdateCard ? (
        <Card
          sx={{
            marginTop: "8px",
          }}
        >
          <CardContent>
            <form>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="20 Liter Rate"
                    style={{ marginBottom: 10 }}
                    inputProps={{
                      maxLength: 5,
                    }}
                    value={twentyLit}
                    onKeyDown={handleKeyDownNum}
                    onChange={(e) => {
                      setTwentyLit(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="5 Liter Rate"
                    type="number"
                    style={{ marginBottom: 10 }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: 14 },
                    }}
                    disabled={true}
                    value={fiveLit}
                    onChange={(e) => {
                      setFiveLit(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 5);
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="2 Liter Rate"
                    type="number"
                    style={{ marginBottom: 10 }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: 14 },
                    }}
                    disabled={true}
                    value={twoLit}
                    onChange={(e) => {
                      setTwoLit(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 5);
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="1 Liter Rate"
                    type="number"
                    style={{ marginBottom: 10 }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: 14 },
                    }}
                    disabled={true}
                    value={oneLit}
                    onChange={(e) => {
                      setOneLit(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 5);
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="500 ML Rate"
                    type="number"
                    style={{ marginBottom: 10 }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: 14 },
                    }}
                    disabled={true}
                    value={fiveHndMl}
                    onChange={(e) => {
                      setFiveHndMl(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 5);
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="200 ML Rate"
                    type="number"
                    style={{ marginBottom: 10 }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: 14 },
                    }}
                    disabled={true}
                    value={twoHndMl}
                    onChange={(e) => {
                      setTwoHndMl(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 5);
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button variant="contained" onClick={updateRateForCans}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}

export default CsaRateforAll;
