import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { expense_type } from "../../Components/AppMeta/appMetaConfig";
import { useDispatch, useSelector } from "react-redux";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import { GetRunningVehiclesList } from "../Redux/Actions/supplierVehiclesActions";
import { GetSupplierDeliveryAgentDetailsAdmin } from "../Redux/Actions/supliersAction";

function CommonOperationLevelCostFilterDialog(props) {
  const dispatch = useDispatch();
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const wareHouseDetails = useSelector(
    (state) => state.userDetailsUpdate.addresses
  );
  const [availableVehicles, setAvailableVehicles] = React.useState([]);
  const [designationList, setDesignationList] = React.useState([]);
  const [selectedEmployeesDisignation, setSelectedEmployeesDisignation] =
    React.useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState(null);
  const [selectedEmployeeName, setSelectedEmployeeName] = React.useState(null);
  const [selectedVehicle, setSelectedVehicle] = React.useState(null);
  const [selectedWFS, setSelectedWFS] = React.useState(null);
  const [selectMonthFilter, setSelectMonthFilter] = React.useState("");
  const [selectedSupplierId, setSelectedSupplierId] = React.useState(null);
  const [purpose, setPurpose] = React.useState("");
  const [selectedExpenseType, setSelectedExpenseType] = React.useState("");
  const [selectMonth, setSelectMonth] = React.useState("");
  const [paidDate, setPaidDate] = React.useState();
  const [amount, setAmount] = React.useState("");
  const [nextDueDate, setNextDueDate] = React.useState();

  React.useEffect(() => {
    if (selectedEmployeesDisignation?.employee_type) {
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          // user_id: selectedSupplierId.user_id,
          supplier_id: selectedSupplierId.supplier_id,
          employee_type: selectedEmployeesDisignation?.employee_type,
        })
      );
    }
  }, [selectedEmployeesDisignation]);
  React.useEffect(() => {
    if (selectedSupplierId?.supplier_id !== "") {
      const designationList = [
        { label: "MANAGERS", employee_type: 1 },
        { label: "DRIVERS", employee_type: 2 },
        { label: "LOADERS", employee_type: 3 },
      ];
      setDesignationList(designationList);
    }
  }, [selectedSupplierId?.supplier_id]);

  React.useEffect(() => {
    console.log(selectedSupplierId);
    if (selectedSupplierId?.user_id !== undefined) {
      //   getDistricts({ country: selectedCountry });

      GetWareHouseDetails({
        user_id: selectedSupplierId?.user_id,
      });
    }
  }, [selectedSupplierId]);
  //function to get vehicles Details details based on supolier_id
  React.useEffect(() => {
    console.log(selectedSupplierId);
    if (selectedSupplierId?.user_id && props.filterExpenseType === 300) {
      //   getDistricts({ country: selectedCountry });
      GetWareVehicleDetails({
        user_id: selectedSupplierId?.user_id,
      });
    }
  }, [selectedSupplierId]);

  //function to download warehouse details
  function GetWareHouseDetails(data) {
    console.log("WareHouseDetails");
    console.log(data);
    dispatch(
      GetFilteredUserAddressAdmin({
        user_id: data.user_id,
      })
    );
  }

  const handleCloseCommonFilterDialog = () => {
    props.handleCloseCommonFilterDialog();
  };

  //function to download vehicles details
  function GetWareVehicleDetails(data) {
    console.log("WareHouseDetails");
    console.log(data);
    dispatch(
      GetRunningVehiclesList({
        supplier_number: selectedSupplierId.supplier_number,
      })
    ).then((data) => {
      setAvailableVehicles(data?.data || []);
    });
  }

  const handleCommonFilterDialog = () => {
    props.handleCommonFilterDialog({
      selectedExpenseType: selectedExpenseType,
      selectMonth: selectMonth,
      amount: amount,
      paidDate: paidDate,
      nextDueDate: nextDueDate,
      purpose: purpose,
      selectedWFS: selectedWFS,
      selectedSupplierId: selectedSupplierId?.supplier_id,
      selectedWarehouse: selectedWarehouse?.warehouse_id,
      selectedVehicle: selectedVehicle?.name,
      selectedEmployeeid: selectedEmployeeName?.driver_id,
    });
    // setTimeout(() => {
    //   handleclearfilterDialog();
    // }, 3000);
  };

  const handleclearfilterDialog = () => {
    setSelectMonth("");
    setPaidDate("");
    setAmount("");
    setNextDueDate("");
    setSelectedExpenseType("");
    setSelectedSupplierId(null);
    setSelectedEmployeesDisignation("");
    setSelectedWarehouse(null);
    setSelectedEmployeeName("");
    setSelectedWFS(null);
  };
  return (
    <Dialog open={props.openCommonFilterDialog}>
      <DialogContent>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { md: "row", xs: "column" },
          }}
        > */}{" "}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            gap: "10px",
            m: "10px",
          }}
        >
          <FormControl sx={{ width: 220 }} size="small">
            <InputLabel>Select Month</InputLabel>
            <Select
              value={selectMonth}
              label="Select Month"
              onChange={(event) => setSelectMonth(event.target.value)}
            >
              <MenuItem value={1}>JAN</MenuItem>
              <MenuItem value={2}>FEB</MenuItem>
              <MenuItem value={3}>MARCH</MenuItem>
              <MenuItem value={4}>APRIL</MenuItem>
              <MenuItem value={5}>MAY</MenuItem>
              <MenuItem value={6}>JUNE</MenuItem>
              <MenuItem value={7}>JULY</MenuItem>
              <MenuItem value={8}>AUG</MenuItem>
              <MenuItem value={9}>SEP</MenuItem>
              <MenuItem value={10}>OCT</MenuItem>
              <MenuItem value={11}>NOV</MenuItem>
              <MenuItem value={12}>DESC</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="date"
            size="small"
            label="Next Due Date"
            value={nextDueDate}
            onChange={(event) => setNextDueDate(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            gap: "10px",
            m: "10px",
          }}
        >
          <TextField
            label="Amount"
            variant="outlined"
            size="small"
            sx={{ width: 220 }}
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
          />
          <TextField
            type="date"
            size="small"
            label="Paid Date"
            value={paidDate}
            onChange={(event) => setPaidDate(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          {props.filterExpenseType === "Duedates" ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FormControl sx={{ width: 220 }} size="small">
                <InputLabel>Select Type</InputLabel>
                <Select
                  value={selectedExpenseType}
                  label="Select Expense Type"
                  onChange={(event) =>
                    setSelectedExpenseType(event.target.value)
                  }
                >
                  <MenuItem value={expense_type.RENT}>RENT</MenuItem>
                  <MenuItem value={expense_type.WFI}>WI-FI</MenuItem>
                  <MenuItem value={expense_type.ELECTRICITY}>
                    ELECTRICITY
                  </MenuItem>
                  <MenuItem value={expense_type.EMI}>EMI</MenuItem>
                  <MenuItem value={expense_type.INSURANCE}>INSURANCE</MenuItem>
                  <MenuItem value={expense_type.POLLUTION_CERTIFICATE}>
                    POLLUTION_CERTIFICATE
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {props.filterExpenseType === 100 ? (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FormControl sx={{ width: 220 }} size="small">
                <InputLabel>Select Type</InputLabel>
                <Select
                  value={selectedExpenseType}
                  label="Select Expense Type"
                  onChange={(event) =>
                    setSelectedExpenseType(event.target.value)
                  }
                >
                  <MenuItem value={expense_type.RENT}>RENT</MenuItem>
                  <MenuItem value={expense_type.WFI}>WI-FI</MenuItem>
                  <MenuItem value={expense_type.ELECTRICITY}>
                    ELECTRICITY
                  </MenuItem>
                  <MenuItem value={expense_type.CONSUMABLES}>
                    CONSUMABLES
                  </MenuItem>
                  <MenuItem value={expense_type.ALLOWANCES}>
                    ALLOWANCES
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                // gap: "1px",
                justifyContent: "space-around",
              }}
            >
              <Autocomplete
                disabled={!props.supplierDeliveries?.supplierList?.length}
                id="country-select-demo"
                sx={{ width: 210, m: "10px" }}
                options={
                  props.supplierDeliveries?.supplierList !== null &&
                  props.supplierDeliveries?.supplierList?.map((item, index) => {
                    return {
                      name:
                        item.supplier_name !== null ? item.supplier_name : "",
                      user_id: item.user_id,
                      supplier_id: item.id,
                    };
                  })
                }
                value={selectedSupplierId}
                autoHighlight
                getOptionLabel={(nameSearch) => `${nameSearch.name} `}
                onChange={(_, newValue) => {
                  setSelectedSupplierId(newValue); // Set the selected  as the value
                }}
                renderOption={(props, nameSearch) => (
                  <Box
                    component="li"
                    // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {console.log(nameSearch)}
                    {nameSearch.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                      props.supplierDeliveries?.supplierList !== undefined &&
                      props.supplierDeliveries?.supplierList !== null &&
                      props.supplierDeliveries?.supplierList?.length > 0
                        ? "Select Supplier"
                        : "No Supplier Found"
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: null, // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <Autocomplete
                disabled={!selectedSupplierId?.user_id}
                id="country-select-demo"
                sx={{ width: 210, m: "10px" }}
                options={wareHouseDetails?.map((item, index) => {
                  return {
                    name: item.contact_name,
                    warehouse_id: item.address_id,
                    id: index,
                  };
                })}
                autoHighlight
                value={selectedWarehouse}
                getOptionLabel={(nameSearch) => `${nameSearch.name}`}
                onChange={(_, newValue) => {
                  setSelectedWarehouse(newValue); // Set the selected  as the value
                }}
                renderOption={(props, nameSearch) => (
                  <Box
                    component="li"
                    // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {nameSearch.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                      wareHouseDetails?.length
                        ? "Search Warehouse"
                        : "No warehouses Found"
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: null, // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        ) : props.filterExpenseType === 200 ? (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <FormControl sx={{ width: 220 }} size="small">
              <InputLabel>Select Expense Type</InputLabel>
              <Select
                value={selectedExpenseType}
                label="Select Expense Type"
                onChange={(event) => setSelectedExpenseType(event.target.value)}
              >
                <MenuItem value={expense_type.RENT}>RENT</MenuItem>
                <MenuItem value={expense_type.WFI}>WI-FI</MenuItem>
                <MenuItem value={expense_type.ELECTRICITY}>
                  ELECTRICITY
                </MenuItem>
                <MenuItem value={expense_type.CONSUMABLES}>
                  CONSUMABLES
                </MenuItem>
                <MenuItem value={expense_type.ALLOWANCES}>ALLOWANCES</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              disabled={!skuWfsDetails.wfsDetails?.length}
              sx={{ width: 210, mb: "10px" }}
              options={skuWfsDetails.wfsDetails?.map((item, index) => {
                return {
                  name: item.company_name,
                  wfs_id: item.wfs_master_id,
                  id: index,
                };
              })}
              autoHighlight
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedWFS(newValue); // Set the selected  as the value
              }}
              value={selectedWFS}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    skuWfsDetails.wfsDetails?.length
                      ? "Search WFS"
                      : "No WFS Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Box>
        ) : props.filterExpenseType === 300 ? (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FormControl sx={{ width: 220 }} size="small">
                <InputLabel>Select Expense Type</InputLabel>
                <Select
                  value={selectedExpenseType}
                  label="Select Expense Type"
                  onChange={(event) =>
                    setSelectedExpenseType(event.target.value)
                  }
                >
                  <MenuItem value={expense_type.FUEL}>FUEL</MenuItem>
                  <MenuItem value={expense_type.TRAFFIC_CHALLAN}>
                    TRAFFIC CHALLAN
                  </MenuItem>
                  <MenuItem value={expense_type.INSURANCE}>INSURANCE</MenuItem>
                  <MenuItem value={expense_type.POLLUTION_CERTIFICATE}>
                    POLLUTION CERTIFICATE
                  </MenuItem>
                  <MenuItem value={expense_type.REPAIRS_DAMAGE}>
                    REPAIRS & DAMAGE
                  </MenuItem>
                  <MenuItem value={expense_type.MAINTENANCE}>
                    MAINTENANCE
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              <Autocomplete
                disabled={!props.supplierDeliveries?.supplierList?.length}
                id="country-select-demo"
                sx={{ width: 210, m: "10px" }}
                options={
                  props.supplierDeliveries?.supplierList !== null &&
                  props.supplierDeliveries?.supplierList?.map((item, index) => {
                    return {
                      name:
                        item.supplier_name !== null ? item.supplier_name : "",
                      user_id: item.user_id,
                      supplier_number: item.phone,
                      supplier_id: item.id,
                    };
                  })
                }
                value={selectedSupplierId}
                autoHighlight
                getOptionLabel={(nameSearch) => `${nameSearch.name} `}
                onChange={(_, newValue) => {
                  setSelectedSupplierId(newValue); // Set the selected  as the value
                }}
                renderOption={(props, nameSearch) => (
                  <Box
                    component="li"
                    // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {nameSearch.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                      props.supplierDeliveries?.supplierList !== undefined &&
                      props.supplierDeliveries?.supplierList !== null &&
                      props.supplierDeliveries?.supplierList?.length > 0
                        ? "Select Supplier"
                        : "No Supplier Found"
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: null, // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                disabled={!availableVehicles?.length}
                id="country-select-demo"
                sx={{ width: 210, m: "10px" }}
                options={availableVehicles?.map((item, index) => {
                  return {
                    name: item.tanker_id,
                    make: item.tanker_profile?.make,
                    model: item.tanker_profile?.model,
                    id: index,
                  };
                })}
                autoHighlight
                value={selectedVehicle}
                getOptionLabel={(nameSearch) =>
                  `${nameSearch.name} ${nameSearch.make} -${nameSearch.model}`
                }
                onChange={(_, newValue) => {
                  setSelectedVehicle(newValue); // Set the selected  as the value
                }}
                renderOption={(props, nameSearch) => (
                  <Box
                    component="li"
                    // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {nameSearch.name}-{nameSearch.make}-{nameSearch.model}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                      availableVehicles?.length
                        ? "Select Vehicle"
                        : "No Vehicles Found"
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: null, // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        ) : props.filterExpenseType === 400 ? (
          <Box
            sx={{
              display: "flex",
              // gap: "10px",
              justifyContent: "space-around",
              flexWrap: "wrap",
              ml: "14px",
            }}
          >
            <Autocomplete
              disabled={!props.supplierDeliveries?.supplierList?.length}
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                (props.supplierDeliveries?.supplierList !== undefined &&
                  props.supplierDeliveries?.supplierList !== null &&
                  props.supplierDeliveries?.supplierList?.map((item, index) => {
                    return {
                      name:
                        item.supplier_name !== null ? item.supplier_name : "",
                      supplier_id: item.id,
                      user_id: item.user_id,
                    };
                  })) ||
                []
              }
              value={selectedSupplierId}
              autoHighlight
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedSupplierId(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    props.supplierDeliveries?.supplierList !== undefined &&
                    props.supplierDeliveries?.supplierList !== null &&
                    props.supplierDeliveries?.supplierList?.length > 0
                      ? "Select Supplier"
                      : "No Supplier Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              disabled={!selectedSupplierId?.supplier_id}
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                designationList?.map((item, index) => {
                  return {
                    name: item.label,
                    employee_type: item.employee_type,
                    id: index,
                  };
                }) || []
              }
              autoHighlight
              value={selectedEmployeesDisignation}
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedEmployeesDisignation(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box component="li" {...props}>
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    designationList?.length
                      ? "Select Designation"
                      : "No Designations Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              disabled={
                !props.supplierDeliveries.getSupplierDriverDataAdmin?.length
              }
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                props.supplierDeliveries.getSupplierDriverDataAdmin?.map(
                  (item, index) => {
                    return {
                      name: item.driver_profile?.fullname,
                      driver_id: item.driver_id,
                      id: index,
                    };
                  }
                ) || []
              }
              autoHighlight
              value={selectedEmployeeName}
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedEmployeeName(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    props.supplierDeliveries.getSupplierDriverDataAdmin?.length
                      ? "Select Employee"
                      : "No Employee Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <FormControl sx={{ width: 210 }} size="small">
              <InputLabel>Select Expense Type</InputLabel>
              <Select
                value={selectedExpenseType}
                label="Select Expense Type"
                onChange={(event) => setSelectedExpenseType(event.target.value)}
              >
                <MenuItem value={expense_type.MONTHLY_SALARY}>
                  MONTHLY SALARY
                </MenuItem>
                <MenuItem value={expense_type.DAILY_WAGES}>
                  DAILY WAGES
                </MenuItem>
                <MenuItem value={expense_type.WEEKLY_SALARY}>
                  WEEKLY SALARY
                </MenuItem>
                <MenuItem value={expense_type.ADVANCED_SALARY}>
                  ADVANCED SALARY
                </MenuItem>
                <MenuItem value={expense_type.BONUS}>BONUS</MenuItem>
                <MenuItem value={expense_type.ALLOWANCES}>ALLOWANCES</MenuItem>
                <MenuItem value={expense_type.OTHERS}>OTHERS</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ) : (
          ""
        )}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            m: "10px",
          }}
        > */}
        {/* <FormControl sx={{ width: 220 }} size="small">
            <InputLabel>Operation Type</InputLabel>
            <Select
              // value={selectWarehouse}
              label="Operation Type"
              // onChange={(event) => setSelectWarehouse(event.target.value)}
            >
              <MenuItem value={100}>WAREHOUSE</MenuItem>
              <MenuItem value={200}>WFS</MenuItem>
              <MenuItem value={300}>VEHICLES</MenuItem>
              <MenuItem value={400}>EMPLOYEES</MenuItem>
            </Select>
          </FormControl> */}
        {/* </Box> */}
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleclearfilterDialog}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={handleCloseCommonFilterDialog}
        >
          close
        </Button>
        <Button variant="contained" onClick={handleCommonFilterDialog}>
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CommonOperationLevelCostFilterDialog;
