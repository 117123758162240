import React from "react";
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CustomConsole from "../../CustomConsole";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import { SettlementPostpaid } from "../Redux/Actions/adminPoTrackPostpaid";
import { toast } from "react-toastify";
import {
  GetPoTrackDetails,
  GetInvoiceData,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { GetCreditNoteDetails } from "../Redux/Actions/buyBackOrdersAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConsolidatedDialog({
  open,
  setOpen,
  selectedInvoice,
  walletBal,
  payConsId,
  pageNumber,
  selectedCreditNotes,
  userId,
  setPriceBreak,
}) {
  const poDetails = useSelector((state) => state.poTrackDetails);
  const fileUploadDetails = useSelector((state) => state.fileHandler);
  const setUpConfigData = useSelector((state) => state.adminBookingData);
  const dispatch = useDispatch();
  const [priceBreakUp, setPriceBreakUp] = React.useState([]);
  const [totalAmt, setTotalAmt] = React.useState(0);
  const uploadPoRef = React.useRef(null);
  const [poFile, setPoFIle] = React.useState("");
  const [isPoUploaded, setPoUploaded] = React.useState(false);
  const [postpaidPaymentMethods, setPostpaidPaymentMethods] = React.useState(
    []
  );
  const [paymentMethod, setPaymentMethod] = React.useState(0);
  const [invoiveSetFlag, setInvoiceSetFlag] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);
  const [crediNotesDetails, setCrediNotesDetails] = React.useState([]);
  const [totalCreditAmount, setTotalCreditAmount] = React.useState(0);
  const triggerPoRef = () => {
    uploadPoRef.current.click();
  };

  const styles = {
    headerStyle: {
      fontWeight: "bold",
    },
  };
  const poUploadChange = (e) => {
    CustomConsole("----upload button clicked---");
    setPoFIle("");
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setPoUploaded(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPriceBreakUp([]);
    setPaymentMethod(0);
  };

  const handleSettlement = () => {
    let reqObj = {
      invoiceId: selectedInvoice,
      payment_reciept: paymentMethod === 600 ? "" : poFile,
      payment_method: paymentMethod,
      credit_settlement: crediNotesDetails,
    };
    if (paymentMethod === 600) {
      reqObj.amount = parseFloat((totalAmt - discount).toFixed(2));
      reqObj.userId = payConsId;
    }

    if (!reqObj.payment_method) {
      return toast.error("Choose a payment method!.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    if (reqObj.payment_method !== 600 && !reqObj.payment_reciept) {
      return toast.error("Upload the payment reciept!.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    if (reqObj.invoiceId && reqObj.payment_method && reqObj.payment_reciept) {
      dispatch(SettlementPostpaid(reqObj)).then((resp) => {
        if (!resp.error) {
          dispatch(
            GetCreditNoteDetails({
              user_id: userId,
              order_status: 100,
              page_size: 100,
            })
          );
          setPriceBreak({});
        }
      });
      setInvoiceSetFlag(true);
    } else if (reqObj.payment_method === 600 && !reqObj.payment_reciept) {
      dispatch(SettlementPostpaid(reqObj)).then((resp) => {
        if (!resp.error) {
          dispatch(
            GetCreditNoteDetails({
              user_id: userId,
              order_status: 100,
              page_size: 100,
            })
          );
          setPriceBreak({});
        }
      });
      setInvoiceSetFlag(true);
    }
  };

  React.useEffect(() => {
    if (poDetails.invoicePriceData) {
      setPriceBreakUp(poDetails.invoicePriceData);
    }
  }, [poDetails.invoicePriceData, open]);

  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
  }, []);

  React.useEffect(() => {
    if (selectedCreditNotes?.length > 0) {
      const newCreditNotes = selectedCreditNotes.map((data) => ({
        ...data, // Spread the existing properties of the object
        invoice_id: selectedInvoice, // Add or update the invoice_id property
      }));
      setCrediNotesDetails(newCreditNotes);
    }
  }, [selectedCreditNotes]);

  React.useEffect(() => {
    if (crediNotesDetails?.length > 0) {
      const totalAmount = crediNotesDetails.reduce((total, data) => {
        total += parseFloat(data.amount);
        return total;
      }, 0);
      setTotalCreditAmount(totalAmount);
    }
  }, [crediNotesDetails]);

  React.useEffect(() => {
    CustomConsole(fileUploadDetails);
    if (fileUploadDetails.isFileUploaded === true && isPoUploaded) {
      setPoFIle(fileUploadDetails.fileName);
      setPoUploaded(false);
      fileUploadDetails.isFileUploaded = false;
    }
  }, [fileUploadDetails]);

  React.useEffect(() => {
    if (setUpConfigData.error === false) {
      if (setUpConfigData.setupConfigData.length) {
        setUpConfigData.setupConfigData.map((data) => {
          if (data.key === "postpaid_payment_types") {
            return setPostpaidPaymentMethods(data.value);
          }
          return false;
        });
      }
    }
  }, [setUpConfigData]);

  React.useEffect(() => {
    CustomConsole("----inside the toast Effect----- ");
    if (poDetails.error === false && invoiveSetFlag === true) {
      CustomConsole("----Inside the toast Effect if condition----- ");
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      let reqObj = {
        page_size: "",
        page_number: pageNumber,
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));
      dispatch(GetInvoiceData(payConsId));
      setOpen(false);
      setInvoiceSetFlag(false);
      setPoFIle("");
    } else if (poDetails.error === true && invoiveSetFlag === true) {
      toast.error(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setInvoiceSetFlag(false);
    }
    setInvoiceSetFlag(false);
    if (poDetails.discount) {
      setDiscount(poDetails.discount);
    } else {
      setDiscount(0);
    }
  }, [poDetails]);

  React.useEffect(() => {
    if (priceBreakUp) {
      const totalPrice = priceBreakUp.reduce((acc, curr) => {
        acc += Math.round(curr.taxableAmt + curr.cgstAmt + curr.sgstAmt);
        if (curr?.extraCharges && curr?.extraCharges?.totalAmt) {
          acc += curr.extraCharges.totalAmt;
        }
        return acc;
      }, 0);
      setTotalAmt(() => (totalPrice ? totalPrice : 0));
    }
  }, [priceBreakUp]);

  // Handler for amount change
  const handleAmountChange = (e, data) => {
    const value = parseFloat(e.target.value);
    const orginalValu = selectedCreditNotes?.find((details) => {
      return details?.credit_id === data?.credit_id;
    });

    // Compare the entered value with the original value
    if (orginalValu && value > orginalValu.total_amount) {
      toast.error(
        "Please enter a value less than or equal to the available credit amount.",
        {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      return; // Prevent further execution if the value exceeds the original amount
    }
    setCrediNotesDetails((prevNotes) =>
      prevNotes.map((note) =>
        note.credit_id === data?.credit_id
          ? {
              ...note,
              amount: value,
            }
          : note
      )
    );
  };

  // Prevent scroll increment/decrement on input
  const handleWheel = (e) => {
    e.preventDefault();
  };
  console.log(crediNotesDetails);
  console.log((totalAmt - discount).toFixed(2));
  console.log(totalCreditAmount);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle textAlign={"center"}>Price Breakup</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          mb={2}
        >
          {priceBreakUp &&
            priceBreakUp.map((item, idx) => {
              return (
                <>
                  <Grid key={idx} container spacing={1}>
                    <Grid item md={6}>
                      <Typography>
                        {item.quantity} X {item.material}-{item.product}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>
                        ₹{" "}
                        {Math.round(
                          item.taxableAmt + item.cgstAmt + item.sgstAmt
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography sx={styles.headerStyle}>
                        Taxable Amt.
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>₹ {item.taxableAmt}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography sx={styles.headerStyle}>CGST Amt.</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography> ₹ {item.cgstAmt}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography sx={styles.headerStyle}>SGST Amt.</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>₹ {item.sgstAmt}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              );
            })}
          <Grid container spacing={2} mt={1}>
            <Grid item md={6}>
              <Typography sx={styles.headerStyle}>Discount Amt.</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>₹ {discount}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography sx={styles.headerStyle}>Actual Total Amt.</Typography>
            </Grid>

            <Grid item md={6}>
              <Typography>₹ {(totalAmt - discount).toFixed(2)}</Typography>
            </Grid>
            {(totalAmt - discount).toFixed(2) > totalCreditAmount && (
              <>
                <Grid item md={6}>
                  <Typography sx={styles.headerStyle}>Credit Used</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography>₹ {totalCreditAmount.toFixed(2)}</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography sx={styles.headerStyle}>
                    Total Amount to settle
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography>
                    ₹ {(totalAmt - discount - totalCreditAmount).toFixed(2)}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Divider />
          <Grid container spacing={2} mt={1}>
            {crediNotesDetails?.length > 0 ? (
              <>
                <Grid xs={12} sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                    {" "}
                    Credit Note Details
                  </Typography>
                </Grid>

                <Grid
                  container
                  sx={{ textAlign: "center", marginBottom: "1rem" }}
                >
                  {/* Headings for Credit Id and Amount */}
                  <Grid item xs={4}>
                    <Typography sx={styles.headerStyle}>Credit Id</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={styles.headerStyle}>
                      Available Credit
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={styles.headerStyle}>Amount</Typography>
                  </Grid>

                  {/* Mapping through credit notes details */}
                  {crediNotesDetails?.map((data) => (
                    <Grid
                      container
                      key={data?.credit_id}
                      spacing={2}
                      sx={{ textAlign: "center", marginBottom: ".5rem" }}
                    >
                      <Grid item xs={4}>
                        <Typography>{data?.credit_id}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>₹{data?.pending_amount}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="number"
                          value={data?.amount} // Controlled value
                          onChange={(e) => handleAmountChange(e, data)}
                          onWheel={handleWheel}
                          inputProps={{
                            min: 0, // Minimum value is 0
                            max: data?.total_amount, // Maximum value is the actual total amount
                            step: 1, // Optional: set step size for decimals
                          }}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Box>
        <Stack mt={1}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={styles.headerStyle}>
              Payment method
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={paymentMethod}
              label="Payment method"
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              {postpaidPaymentMethods
                ? postpaidPaymentMethods.map((data, key) => {
                    return (
                      <MenuItem key={key + 1} value={data.k}>
                        {data.v}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Stack>
        {paymentMethod === 600 &&
        walletBal > priceBreakUp?.total_mrp - discount ? (
          <Box sx={{ padding: "10px", width: "300px" }}>
            <Alert severity="info">
              Amount will be deducted from the consumers wallet.
            </Alert>
          </Box>
        ) : paymentMethod === 600 &&
          walletBal < priceBreakUp?.total_mrp - discount ? (
          <Box sx={{ padding: "10px", width: "300px" }}>
            <Alert severity="warning">
              Low wallet balance order will go to payment pending.
            </Alert>
          </Box>
        ) : (
          <Stack mt={2}>
            <input
              style={{ display: "none" }}
              type="file"
              ref={uploadPoRef}
              onChange={poUploadChange}
            />
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "0.65rem",
                backgroundColor: poFile ? "green" : "herit",
              }}
              onClick={triggerPoRef}
            >
              {poFile ? "PR Uploaded" : "Upload PR"}
            </Button>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          size="small"
          variant="contained"
          color="error"
        >
          Close
        </Button>
        {(totalAmt - discount).toFixed(2) > totalCreditAmount ? (
          <Button
            disabled={
              paymentMethod === 600 &&
              walletBal < priceBreakUp?.total_mrp - discount
            }
            size="small"
            onClick={handleSettlement}
            variant="contained"
          >
            Settle invoice
          </Button>
        ) : (
          <Alert severity="warning">
            Please reduce the credit amount; it should be less than the invoice
            amount.
          </Alert>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConsolidatedDialog;
