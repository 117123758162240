import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  Stack,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  RadioGroup,
  Radio,
} from "@mui/material";
import FilterComponent from "./FilterComponent";
import CustomConsole from "../../CustomConsole";
import * as Material from "@mui/material";
import { toast } from "react-toastify";
import Summary from "./Summary";
import OrderDetails from "./OrderDetails";
import AssigningDialog from "./AssigningDialog";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import TimerIcon from "@mui/icons-material/Timer";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import TableViewIcon from "@mui/icons-material/TableView";
import StatusTable from "./StatusTable";
import { styled } from "@mui/material/styles";
import * as MuiIcons from "@mui/icons-material";
import {
  GetAssignDeliveryAgent,
  GetAvailableDeliverySlots,
  GetSupplierPincodes,
  GetSupplierUnassigedSlotAgent,
  PostSupplierAssignDeliveryAgent,
  PutSupplierAddDelivertSlots,
} from "../Redux/Actions/supliersAction";
import { useDispatch, useSelector } from "react-redux";

function Assigning() {
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const supplierPincodes = useSelector(
    (state) => state.supplierDeliveries.supplierPincodes
  );
  CustomConsole(supplierDeliveries);
  const [openAssignDialog, setOpenAssignDialog] = React.useState(false);
  const [isFbEnabled, setIsFbEnabled] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [supplierPinCodes, setSupplierPincodes] = React.useState([]);
  const [addDeliveryBtn, setAddDeliveryBtn] = React.useState(false);
  const [slotsData, setSlotsData] = React.useState([]);
  const [agentData, setAgentData] = React.useState([]);
  const [orderTypeStore, setOrderTypeStore] = React.useState("");
  const [pincoseStore, setPincodeStore] = React.useState("");
  const [areaStore, setAreaStore] = React.useState("");
  // CustomConsole(slotsData);
  // CustomConsole(agentData);
  const [pageSizeC, setPageSizeCatch] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [assignType, setAssignType] = React.useState(2);
  const [unAssign, setUnAssign] = React.useState(2);
  const [tableview, setTableView] = React.useState(false);
  const [deliveryAgent, setDeliveryAgent] = React.useState("");
  const [selectedSlot, setSelectedSlot] = React.useState({});
  const [checkBoxDefaultStatus, setCheckBoxDefaultStatus] =
    React.useState(false);
  const [assignStatusPincodeSwitch, setAssignStatusPincodeSwitch] =
    React.useState(false);
  const [deliveryId, setDeliveryId] = React.useState("");
  const [assignDeliveryAgentBtn, setAssignDeliveryAgentBtn] =
    React.useState(false);
  const assigningAdents = useSelector(
    (state) => state.supplierDeliveries.getAssignDeliveryAgent
  );
  const deliverySlots = useSelector(
    (state) => state.supplierDeliveries.getAvailableDeliverySlots
  );

  React.useEffect(() => {
    dispatch(GetAvailableDeliverySlots());
    dispatch(GetAssignDeliveryAgent());
    // dispatch(
    //   GetSupplierUnassigedSlotAgent({
    //     page_number: pageNumber - 1,
    //     page_size: pageSizeC,
    //     // is_agent: 2,
    //   })
    // );
    dispatch(GetSupplierPincodes());
  }, []);
  // React.useEffect(() => {
  //   setAgentData(supplierDeliveries.getAssignDeliveryAgent);
  //   setSlotsData(supplierDeliveries.getAvailableDeliverySlots);
  // }, [
  //   supplierDeliveries.getAssignDeliveryAgent,
  //   supplierDeliveries.getAvailableDeliverySlots,
  // ]);
  React.useEffect(() => {
    CustomConsole("-----Supplier pincodes-----");
    CustomConsole(supplierPincodes);
    if (supplierPincodes) {
      setSupplierPincodes(supplierPincodes);
    }
  }, [supplierPincodes]);
  const handleChangeSelectAllCheckBox = (e) => {
    // CustomConsole("---- changeSelectAllCheckBox ----");
    // setSelectAll(!selectAll);

    // setCheckBoxDefaultStatus(!selectAll);
    // let newCheckedItems = [];
    // if (!selectAll) {
    //   supplierDeliveries.getSupplierUnassigedSlotAgent.map((data) => {
    //     CustomConsole(data);
    //     newCheckedItems.push(data.order_id);
    //   });
    // }
    // CustomConsole(newCheckedItems);
    if (e.target.checked === true) {
      let newCheckedItems =
        supplierDeliveries.getSupplierUnassigedSlotAgent.map(
          (data) => data.order_id
        );
      setSelectAll(true);
      setSelectionModel(newCheckedItems);
    } else if (e.target.checked === false) {
      setSelectionModel([]);
      setSelectAll(false);
    }
  };
  React.useEffect(() => {
    if (
      !supplierDeliveries.assigDeliveryAgenterror ||
      !supplierDeliveries.addDeliveyerror
    ) {
      CustomConsole("---View order details API call here----");
      dispatch(GetSupplierUnassigedSlotAgent({ is_agent: unAssign }));
      // if (assignDeliveryAgentBtn === true) {
      //   setAssignDeliveryAgentBtn(false);
      // }
      if (addDeliveryBtn === true) {
        setAddDeliveryBtn(false);
      }
    }
  }, [assignDeliveryAgentBtn, addDeliveryBtn]);

  // React.useEffect(() => {
  //   dispatch(
  //     GetSupplierUnassigedSlotAgent({
  //       page_number: pageNumber - 1,
  //       page_size: pageSizeC,
  //       is_agent: unAssign,
  //       // orderType: 200,
  //     })
  //   );
  // }, [pageNumber]);
  React.useEffect(() => {
    dispatch(
      GetSupplierUnassigedSlotAgent({
        page_number: pageNumber - 1,
        page_size: pageSizeC,
        is_agent: unAssign,
        orderType: orderTypeStore,
        pincode: pincoseStore || undefined,
        area: areaStore || undefined,
      })
    );
  }, [pageNumber, pageSizeC]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 4,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleChangeAssignTog = (event, newAlignment) => {
    CustomConsole("------Alignment------");

    if (newAlignment) {
      setAssignType(newAlignment);
    }
  };
  const handleassignStatusPincodeSwitch = (event) => {
    setAssignStatusPincodeSwitch(event.target.checked);
  };
  const handleApply = (props) => {
    CustomConsole("-----------handleApply---------");
    CustomConsole(props);
    setOrderTypeStore(props.orderType);
    setPincodeStore(props.pincode);
    setAreaStore(props.area);
    dispatch(
      GetSupplierUnassigedSlotAgent({
        pincode: props.pincode || undefined || "",
        area: props.area || undefined || "",
        orderType: props.orderType || "",
        is_agent: unAssign,
      })
    );
  };
  const handleAgentCheckBoxChage = (props) => {
    CustomConsole(props);
    setDeliveryId(props);
  };
  const handleAssignAgent = () => {
    CustomConsole(deliveryId);
    if (unAssign === 3) {
      CustomConsole("-----------handleAssignAgent---------");
      if (deliveryId !== "" && deliveryAgent !== "") {
        const reqObj = {
          delivery_agent_id: deliveryAgent,
          delivery_id: deliveryId,
        };
        CustomConsole(reqObj);
        dispatch(PostSupplierAssignDeliveryAgent(reqObj));
        // setAssignDeliveryAgentBtn(true);
      } else {
        toast.error("Select Delivery Agent Or Select Order Card ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    if (unAssign === 2) {
      CustomConsole("-----------handleAssignSlot---------");
      if (
        selectionModel &&
        selectedSlot.startTimeSlot &&
        selectedSlot.endTimeSlot
      ) {
        const reqObj = {
          delivery_slot_timings:
            selectedSlot.startTimeSlot + "-" + selectedSlot.endTimeSlot,
          is_today: true,
          order_id: selectionModel,
        };
        CustomConsole(reqObj);
        dispatch(PutSupplierAddDelivertSlots(reqObj));
        setAddDeliveryBtn(true);
      } else {
        toast.error("Select Delivery Slot", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };
  const handleSelectCheckBox = (props) => {
    CustomConsole("--- handleSelectCheckBox ---");
    CustomConsole(props);
    // CustomConsole("---- selectCheckBox ----");
    // let newCheckedItems = selectionModel;
    // CustomConsole(newCheckedItems);
    // if (props.checkBoxStatus === true) {
    //   CustomConsole("------ condtion true ---------------- ");
    //   CustomConsole(newCheckedItems.indexOf(props.order_id));
    //   setIsFbEnabled(true);
    //   if (newCheckedItems.indexOf(props.order_id) === -1) {
    //     newCheckedItems.push(props.order_id);
    //   }
    // } else {
    //   CustomConsole("---false---");
    //   setIsFbEnabled(false);
    //   newCheckedItems.splice(newCheckedItems.indexOf(props.order_id, 1), 1);
    // }
    // CustomConsole(newCheckedItems);
    // // setSelectedOrderIds(newCheckedItems);
    // setSelectionModel((prev)=>[...props,props]);
  };

  React.useEffect(() => {
    if (unAssign) {
      setAssignType(unAssign);
    }
  }, [unAssign]);

  React.useEffect(() => {
    CustomConsole("----selected order---");
    CustomConsole(selectionModel);
  }, [selectionModel]);

  // React.useEffect(() => {
  //   CustomConsole("-----Slot & Agent---------");
  //   CustomConsole(supplierDeliveries);
  //   CustomConsole(supplierDeliveries.getAvailableDeliverySlots);
  //   CustomConsole(supplierDeliveries.getAssignDeliveryAgent);
  //   setAgentData(supplierDeliveries.getAssignDeliveryAgent);
  //   setSlotsData(supplierDeliveries.getAvailableDeliverySlots);
  // }, [supplierDeliveries]);
  React.useEffect(() => {
    if (assigningAdents) setAgentData(assigningAdents);
  }, [assigningAdents]);

  React.useEffect(() => {
    if (deliverySlots) {
      setSlotsData(deliverySlots);
    }
  }, [deliverySlots]);

  const handleAssign = () => {
    CustomConsole("---------handleAssign----------");
    CustomConsole(assignType);
    if (assignType === 2) {
      dispatch(
        GetSupplierUnassigedSlotAgent({
          // pincode: props.pincode || undefined,
          // area: props.area || undefined,
          // orderType: props.orderType || undefined,
          is_agent: 3,
        })
      );
    }
    if (assignType === 3) {
      dispatch(
        GetSupplierUnassigedSlotAgent({
          // pincode: props.pincode || undefined,
          // area: props.area || undefined,
          // orderType: props.orderType || undefined,
          is_agent: 2,
        })
      );
    }
  };

  return (
    <Box
      mt={5}
      p={0}
      sx={{ display: "flex", flexDirection: "column", mt: "60px" }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Material.Typography
          sx={{
            color: assignStatusPincodeSwitch === false ? "#30A2F3" : "black",
          }}
        >
          Assign Orders
        </Material.Typography>
        <AntSwitch
          checked={assignStatusPincodeSwitch}
          onChange={handleassignStatusPincodeSwitch}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Material.Typography
          sx={{
            color: assignStatusPincodeSwitch === true ? "#30A2F3" : "black",
          }}
        >
          Status
        </Material.Typography>
      </Stack>
      {assignStatusPincodeSwitch === false ? (
        <Grid
          container
          rowSpacing={0.5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={assignType}
                exclusive
                onChange={handleChangeAssignTog}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton
                  value={2}
                  disabled={unAssign === 2 || !unAssign ? false : true}
                >
                  <TimerIcon />
                </ToggleButton>

                <ToggleButton
                  value={3}
                  disabled={unAssign === 3 || !unAssign ? false : true}
                >
                  <DeliveryDiningIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={tableview}
                  exclusive
                  onChange={() => {
                    setTableView((prev) => !prev);
                  }}
                  aria-label="Platform"
                  size="small"
                >
                  <ToggleButton value={true}>
                    <TableViewIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6} mt={{ md: 3 }}>
            <Summary
              dataSlots={slotsData}
              dataAgents={agentData}
              assignType={assignType}
              deliveryAgent={deliveryAgent}
              setDeliveryAgent={setDeliveryAgent}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              unAssign={unAssign}
              selectionModel={selectionModel}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <FilterComponent
              setUnAssign={setUnAssign}
              unAssign={unAssign}
              handleApply={handleApply}
              supplierPinCodes={supplierPinCodes}
              handleAssign={handleAssign}
            />
          </Grid>
          <Grid item mt={1} xs={12} md={6} sx={{ mt: "0px" }}>
            <Paper elevation={10} sx={{ padding: "10px" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <FormControl variant="standard" sx={{ minWidth: 70 }}>
                    <InputLabel>Size</InputLabel>
                    <Select
                      variant="standard"
                      value={pageSizeC}
                      onChange={(e) => {
                        setPageSizeCatch(e.target.value);
                      }}
                      label="Size"
                      size="small"
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={500}>500</MenuItem>
                    </Select>
                  </FormControl>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Material.IconButton
                      onClick={() => {
                        if (pageNumber - 1 > 0) {
                          setPageNumber(() => pageNumber - 1);
                        }
                      }}
                    >
                      <MuiIcons.FirstPage
                        sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                      />
                    </Material.IconButton>
                    <Material.Typography
                      sx={{ fontSize: "15px", fontWeight: "bold" }}
                    >
                      {pageNumber}
                    </Material.Typography>
                    <Material.IconButton
                      sx={{ maxHeight: "50px" }}
                      onClick={() => {
                        if (
                          supplierDeliveries?.getSupplierUnassigedSlotAgent
                            ?.length
                        ) {
                          setPageNumber(() => pageNumber + 1);
                        }
                      }}
                    >
                      <MuiIcons.LastPage
                        sx={{
                          color: supplierDeliveries
                            ?.getSupplierUnassigedSlotAgent?.length
                            ? "blue"
                            : "gray",
                        }}
                      />
                    </Material.IconButton>
                  </Material.Box>
                </Stack>

                <Stack direction={"row"} alignItems="center">
                  {assignType === 2 ? (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectAll}
                            onChange={handleChangeSelectAllCheckBox}
                          />
                        }
                        label="All"
                      />
                    </FormGroup>
                  ) : (
                    ""
                  )}
                  <Box component={"span"}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleAssignAgent}
                    >
                      Assign
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {" "}
            <Box
              sx={{
                height: { xs: "60vh", md: "50vh" },
                overflowX: "auto",
                mt: "10px",
                // display: "flex",
                // flexDirection: "row",
                // justifyContent: "space-around",
                // flexWrap: "wrap",
                // maxHeight: "550px",
                // overflow: "auto",
              }}
            >
              {supplierDeliveries.getSupplierUnassigedSlotAgent !== undefined &&
              supplierDeliveries.getSupplierUnassigedSlotAgent !== null &&
              supplierDeliveries.getSupplierUnassigedSlotAgent.length > 0 ? (
                <>
                  {supplierDeliveries.getSupplierUnassigedSlotAgent.map(
                    (obj, key) => {
                      return (
                        <Box>
                          <OrderDetails
                            key={key}
                            orders={obj}
                            is_checked={selectAll}
                            assignType={assignType}
                            setSelectionModel={setSelectionModel}
                            handleAgentCheckBoxChage={handleAgentCheckBoxChage}
                            handleCheckBoxChage={handleSelectCheckBox}
                            selectionModel={selectionModel}
                            setDeliveryId={setDeliveryId}
                            deliveryId={deliveryId}
                          />
                        </Box>
                      );
                    }
                  )}
                </>
              ) : (
                <Material.Box sx={{ marginTop: "30px" }}>
                  <Material.Typography sx={{ color: "red" }}>
                    No Order Details Found
                  </Material.Typography>
                </Material.Box>
              )}
            </Box>
          </Grid>
          <Grid item mt={1} xs={12}>
            <AssigningDialog
              open={openAssignDialog}
              setOpen={setOpenAssignDialog}
            />
          </Grid>
        </Grid>
      ) : (
        <StatusTable tableview={tableview} setTableView={setTableView} />
      )}
    </Box>
  );
}

export default Assigning;
