import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import styles from "./campaignApprovalStyles";
import moment from "moment";
import CustomConsole from "../../CustomConsole";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function GameApprovalCard(props) {
  const [playedGameDetails, setPlayedGameDetails] = React.useState([]);
  React.useEffect(() => {
    CustomConsole("-- props --");
    CustomConsole(props);
    CustomConsole(Object.keys(props.data).length);
    if (Object.keys(props.data).length) {
      let gameData = [
        { key: "Name:", value: props.data?.customerReward?.name || "-" },
        {
          key: "Description:",
          value: props.data?.customerReward?.description || "-",
        },
        {
          key: "Reward Points:",
          value: props.data?.customerReward?.points || "-",
        },
        {
          key: "Sales Points:",
          value: props.data?.customerAction?.points || "-",
        },
        { key: "Promoter Email:", value: props.data?.promoterEmail || "-" },
        {
          key: "Start Date:",
          value: props.data.startDate
            ? moment(props.data.startDate).format("DD-MM-YYYY")
            : "-",
        },
        {
          key: "End Date:",
          value: props.data.endDate
            ? moment(props.data.endDate).format("DD-MM-YYYY")
            : "-",
        },
        {
          key: "Game Status:",
          value: props.data.rewardStatus
            ? parseInt(props.data.rewardStatus) === 100
              ? "PENDING"
              : parseInt(props.data.rewardStatus) === 200
              ? "APPROVED"
              : parseInt(props.data.rewardStatus) === 300
              ? "REJECTED"
              : "-"
            : "-",
        },
      ];
      setPlayedGameDetails(gameData);
    }
  }, [props]);

  const handleApproveRejectButton = (is_approved) => {
    let actionObject = JSON.parse(JSON.stringify(props.data));
    actionObject.response_id = actionObject.responseId;
    actionObject.referral_id = actionObject.userReferralCode;
    if (is_approved) {
      actionObject.is_accepted = true;
      actionObject.rewardStatus = 300;
    } else {
      actionObject.is_accepted = false;
      actionObject.rewardStatus = 200;
    }
    props.handleApprovalButtonAction(actionObject);
  };
  const handleUserDetailsButton = () => {
    if (props.data.userReferralCode) {
      props.handleUserDetailsButton({
        user_referral: props.data.userReferralCode,
      });
    } else {
      CustomConsole("-- Invalid Referral code --");
    }
  };
  return (
    <Material.Grid item xs={12} sm={12} md={6} lg={4}>
      <Material.Paper elevation={8} sx={{ padding: "10px" }}>
        <Material.Box sx={styles.contenctAlignBox}>
          <Material.Typography sx={styles.mainHeading}>
            Campaign Details:
          </Material.Typography>
          <Material.Typography sx={styles.mainHeadData}>
            {props.data?.name}
            <span style={{ fontSize: "12px", color: "black" }}>
              &nbsp;&nbsp;&nbsp; ({props.data?.location},{" "}
              {props.data?.subLocation})
            </span>
          </Material.Typography>
        </Material.Box>
        <Material.Typography
          sx={{
            marginTop: "15px",
            fontSize: "14px",
            textAlign: "center",
            fontWeight: "bold",
            color: "red",
          }}
        >
          Game Details
        </Material.Typography>
        {playedGameDetails.map((data, index) => {
          return (
            <Material.Box sx={styles.contenctAlignBox} key={index}>
              <Material.Typography sx={styles.heading}>
                {data.key}
              </Material.Typography>
              <Material.Typography sx={styles.answerData}>
                {data.value}
              </Material.Typography>
            </Material.Box>
          );
        })}

        <Material.Box sx={styles.contenctAlignBox}>
          <Material.Typography sx={styles.heading}>
            User Referral:
          </Material.Typography>
          <Material.Typography sx={styles.answerData}>
            {props.data?.userReferralCode || "-"}
          </Material.Typography>
          <Material.IconButton
            onClick={handleUserDetailsButton}
            disabled={
              props.data.userReferralCode && props.data.userReferralCode.length
                ? false
                : true
            }
          >
            <MuiIcons.Info
              sx={{
                color:
                  props.data.userReferralCode &&
                  props.data.userReferralCode.length
                    ? "#4e89ed"
                    : "gray",
                "&:hover": {
                  color: "blue",
                },
              }}
            />
          </Material.IconButton>
        </Material.Box>
        {props.data.rewardStatus &&
        parseInt(props.data.rewardStatus) === 100 &&
        parseInt(localStorage.getItem("roleId")) !== ADMIN_ROLES.BW_PROMOTER ? (
          <Material.Box
            sx={{
              marginTop: "15px",
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.Button
              disabled={
                props.data.userReferralCode &&
                props.data.userReferralCode.length
                  ? false
                  : true
              }
              sx={{
                backgroundColor: "green",
                "&:hover": { backgroundColor: "black" },
              }}
              variant="contained"
              startIcon={<MuiIcons.Verified />}
              onClick={() => handleApproveRejectButton(true)}
            >
              Approve
            </Material.Button>

            <Material.Button
              sx={{
                backgroundColor: "red",
                "&:hover": { backgroundColor: "black" },
              }}
              variant="contained"
              startIcon={<MuiIcons.Cancel />}
              onClick={() => handleApproveRejectButton(false)}
            >
              Reject
            </Material.Button>
          </Material.Box>
        ) : (
          ""
        )}
      </Material.Paper>
    </Material.Grid>
  );
}

export default GameApprovalCard;
