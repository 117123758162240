import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { Close } from "@mui/icons-material";

function ReplcementQrlessInScanDialog(props) {
  const [replacementQrless, setReplacementQrless] = React.useState({
    reuseable: 0,
    nonReuseble: 0,
  });
  const handleSubmitInscnQrless = () => {
    props.handleSubmitInscnQrless({
      reuseable: replacementQrless.reuseable,
      nonReuseble: replacementQrless.nonReuseble,
    });
  };
  const handleCloseReplaceQrless = () => {
    props.handleCloseReplaceQrless();
  };
  return (
    <Dialog open={props.openReplacementQrlessDialog}>
      <DialogContent>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseReplaceQrless}
            >
              <Close />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ textAlign: "start" }}>
                Reuseable :-&nbsp;&nbsp;
              </Typography>

              <OrderButtonSku
                handelAddQuantity={() =>
                  setReplacementQrless((prev) => ({
                    ...prev,
                    reuseable: parseInt(replacementQrless.reuseable + 1),
                  }))
                }
                handelMinusQuantity={() =>
                  setReplacementQrless((prev) => ({
                    ...prev,
                    reuseable: parseInt(
                      replacementQrless.reuseable > 0
                        ? replacementQrless.reuseable - 1
                        : 0
                    ),
                  }))
                }
                handelQuantityChange={(event) =>
                  setReplacementQrless((prev) => ({
                    ...prev,
                    reuseable: parseInt(
                      event.target.value >= 0 && event.target.value <= 999
                        ? parseInt(event.target.value || 0)
                        : parseInt(prev.reuseable || 0)
                    ),
                  }))
                }
                inputValue={replacementQrless.reuseable}
                // disabled={false}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ textAlign: "start" }}>
                Non-Reuseble :-&nbsp;&nbsp;
              </Typography>

              <OrderButtonSku
                handelAddQuantity={() =>
                  setReplacementQrless((prev) => ({
                    ...prev,
                    nonReuseble: parseInt(replacementQrless.nonReuseble + 1),
                  }))
                }
                handelMinusQuantity={() =>
                  setReplacementQrless((prev) => ({
                    ...prev,
                    nonReuseble: parseInt(
                      replacementQrless.nonReuseble > 0
                        ? replacementQrless.nonReuseble - 1
                        : 0
                    ),
                  }))
                }
                handelQuantityChange={(event) =>
                  setReplacementQrless((prev) => ({
                    ...prev,
                    nonReuseble: parseInt(
                      event.target.value >= 0 && event.target.value <= 999
                        ? parseInt(event.target.value || 0)
                        : parseInt(prev.nonReuseble || 0)
                    ),
                  }))
                }
                inputValue={replacementQrless.nonReuseble}
                // disabled={false}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button variant="contained" onClick={handleSubmitInscnQrless}>
              submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ReplcementQrlessInScanDialog;
