// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History:
// Date of Creation:
// File Name:
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

export const skuBookingButtonStyle = {
  orderButtonStyle: {
    backgroundColor: "#068FFF",
    height: "30px",
    width: "50px",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "0.3rem 0 0 .3rem",
    color: "#fff",
  },
  orderButtonStylePlus: {
    backgroundColor: "#068FFF",
    height: "30px",
    width: "50px",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "0 0.3rem .3rem 0",
    color: "#fff",
  },
  canQtyStyle: {
    backgroundColor: "#F0FFFF",
    height: "30px",
    width: "2.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600",
    fontSize: "13px",
  },
};
