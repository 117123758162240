import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";

import Paper from "@mui/material/Paper";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import {
  GetProfileDetails,
  GetUserStockDetails,
  GetWalletDetails,
  UpdateSupplierDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { InputLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getFiles, UploadFile } from "../Redux/Actions/fileHandlingAction";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UsersStyles } from "./UsersStyles";

import * as Material from "@mui/material";

const Input = styled("input")({
  display: "none",
});

export default function SupplierDetailsCard({
  supplierData,
  userId,
  setShowSupplierDetails,
  showSupplierDetails,
  companyTypes,
}) {
  const theme = createTheme();
  const dispatch = useDispatch();
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const fileHandler = useSelector((state) => state.fileHandler);

  // React.useEffect(() => {
  //   CustomConsole(userId);
  //   if (userId !== "") {
  //     CustomConsole("user ID");
  //     dispatch(GetProfileDetails(userId));
  //     dispatch(GetUserStockDetails(userId));

  //     // dispatch(GetInfluencerDetails(userId));
  //     // dispatch(GetReferralCode(userId));
  //   }
  // }, [userId]);

  React.useEffect(() => {
    CustomConsole("companyTypes");
    CustomConsole(companyTypes);
  }, [companyTypes]);

  React.useEffect(() => {
    CustomConsole("In Supplier Details page");
    CustomConsole(supplierData);
  }, [supplierData]);

  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [uploadFlag, setUploadFlag] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [companyType, setCompanyType] = React.useState(null);
  const [companyTypeName, setCompanyTypeName] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [address3, setAddress3] = React.useState("");
  const [city, setCity] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [pan, setPan] = React.useState("");
  const [panUrl, setPanUrl] = React.useState("");
  const [tan, setTan] = React.useState("");
  const [tanUrl, setTanUrl] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [gstUrl, setGstUrl] = React.useState("");
  const [aadhaar, setAadhaar] = React.useState("");
  const [aadhaarUrl, setAadhaarUrl] = React.useState("");
  const [gstFile, setGstFile] = React.useState("");
  const [tanFile, setTanFile] = React.useState("");
  const [panFile, setPanFile] = React.useState("");
  const [aadhaarFile, setAadhaarFile] = React.useState("");

  //validation
  const [panNumberError, setPanNumberError] = React.useState(false);
  const [isGstNumberError, setIsGstNumberError] = React.useState(false);
  const [isAadhaarNumError, setAadhaarNumError] = React.useState(false);

  const validatePanNumber = (e) => {
    const { value } = e.target;
    const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
    setPan(value);
    setPanNumberError(!isValidPanNumber);
  };

  const validateGstNum = (e) => {
    const { value } = e.target;
    const isValidGstNumber =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value);
    setGst(value);
    setIsGstNumberError(!isValidGstNumber);
  };

  const validateAadhar = (e) => {
    const { value } = e.target;
    const isValidAadharNumber = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(
      value
    );
    setAadhaar(value);

    setAadhaarNumError(!isValidAadharNumber);
  };

  React.useEffect(() => {
    CustomConsole(updateUserDetails);
    if (updateUserDetails.error === false && updateFlag === true) {
      setUpdateFlag(false);
      dispatch(GetProfileDetails(userId));
    }
  }, [updateUserDetails]);

  const handleUpdateSupplierDetails = () => {
    CustomConsole("---- handleUpdateSupplierDetails ----");

    if (
      aadhaar &&
      address1 &&
      address2 &&
      address3 &&
      city &&
      companyName &&
      companyType &&
      gst &&
      pan &&
      pincode &&
      tan
    ) {
      const reqObj = {
        aadhaar_no: aadhaar,
        aadhaar_no_file_url: aadhaarUrl,
        address_line1: address1,
        address_line2: address2,
        address_line3: address3,
        city: city,
        company_name: companyName,
        company_type: parseInt(companyType),
        company_type_name: companyTypeName,
        gst: gst,
        gst_file_url: gstUrl,
        is_kyc_done: true,
        landmark: "",
        pan: pan,
        pan_file_url: panUrl,
        pincode: pincode,
        status: true,
        supplier_type: "",
        tan: tan,
        tan_file_url: tanUrl,
      };
      CustomConsole(reqObj);
      dispatch(UpdateSupplierDetails(reqObj, userId));
      CustomConsole("Passed");
      setUpdateFlag(true);
    } else {
      toast.error("Fill all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  React.useEffect(() => {
    CustomConsole("Supplier data");
    CustomConsole(supplierData);
    if (supplierData !== undefined) {
      setCompanyName(supplierData.company_name);
      setCompanyType(supplierData.company_type);
      setCompanyTypeName(supplierData.company_type_name);
      setAddress1(supplierData.address_line1);
      setAddress2(supplierData.address_line2);
      setAddress3(supplierData.address_line3);
      setCity(supplierData.city);
      setPincode(supplierData.pincode);
      setPan(supplierData.pan);
      setPanUrl(supplierData.pan_file_url);
      setTan(supplierData.tan);
      setTanUrl(supplierData.tan_file_url);
      setGst(supplierData.gst);
      setGstUrl(supplierData.gst_file_url);
      setAadhaar(supplierData.aadhaar_no);
      setAadhaarUrl(supplierData.aadhaar_no_file_url);
    }
  }, [supplierData]);

  const handleViewDetails = () => {
    setShowSupplierDetails(true);
  };

  React.useEffect(() => {
    setShowSupplierDetails(true);
  }, []);

  const [uploadGstBtn, setUploadGstBtn] = React.useState(false);
  const [uploadAadhaarBtn, setUploadAadhaarBtn] = React.useState(false);
  const [uploadTanBtn, setUploadTanBtn] = React.useState(false);
  const [uploadPanBtn, setUploadPanBtn] = React.useState(false);

  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlag === true
    ) {
      toast.success("File uploaded successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUploadFlag(false);
    }
    if (fileHandler.isFileUploaded === true && uploadGstBtn === true) {
      CustomConsole("GST file uploaded file here");
      setGstUrl(fileHandler.fileName);
      setUploadGstBtn(false);
    }
    if (fileHandler.isFileUploaded === true && uploadPanBtn === true) {
      CustomConsole("PAN file uploaded file here");
      setPanUrl(fileHandler.fileName);
      setUploadPanBtn(false);
    }
    if (fileHandler.isFileUploaded === true && uploadTanBtn === true) {
      CustomConsole("TAN file uploaded file here");
      setTanUrl(fileHandler.fileName);
      setUploadTanBtn(false);
    }
    if (fileHandler.isFileUploaded === true && uploadAadhaarBtn === true) {
      CustomConsole("Aadhaar file uploaded file here");
      setAadhaarUrl(fileHandler.fileName);
      setUploadAadhaarBtn(false);
    }
  }, [fileHandler]);

  React.useEffect(() => {
    CustomConsole(`gstFile ${gstFile}`);
    CustomConsole(`aadhaarFile ${aadhaarFile}`);
    CustomConsole(`panFile ${panFile}`);
    CustomConsole(`tanFile ${tanFile}`);
    if (gstFile !== "") {
      dispatch(UploadFile(gstFile));
      setUploadFlag(true);
      setUploadGstBtn(true);
    } else if (panFile !== "") {
      dispatch(UploadFile(panFile));
      setUploadFlag(true);
      setUploadPanBtn(true);
    } else if (tanFile !== "") {
      dispatch(UploadFile(tanFile));
      setUploadFlag(true);
      setUploadTanBtn(true);
    } else if (aadhaarFile !== "") {
      dispatch(UploadFile(aadhaarFile));
      setUploadFlag(true);
      setUploadAadhaarBtn(true);
    }
  }, [gstFile, panFile, tanFile, aadhaarFile]);

  const handleChange = (event) => {
    CustomConsole(event.target.value);
    setCompanyType(event.target.value);
    companyTypes.map((data) => {
      if (data.k === event.target.value) {
        CustomConsole(data.v);
        setCompanyTypeName(data.v);
      }
    });
  };

  const handleUploadAadhaarFile = (e) => {
    CustomConsole(e.target.files[0]);
    setAadhaarFile(e.target.files[0]);
  };
  const handleUploadPanFile = (e) => {
    CustomConsole(e.target.files[0]);
    setPanFile(e.target.files[0]);
  };
  const handleUploadGSTFile = (e) => {
    CustomConsole(e.target.files[0]);
    setGstFile(e.target.files[0]);
  };
  const handleUploadTanFile = (e) => {
    CustomConsole(e.target.files[0]);
    setTanFile(e.target.files[0]);
  };
  return (
    // <Paper elevation={24} sx={UsersStyles.cardshadow}>
    <Box>
      {/* <Paper elevation={12}> */}
      {/* <Card sx={{ maxWidth: 375, padding: "10px", marginTop: "10px" }}>
          <CardContent> */}
      <Box>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
            // sx={{
            //   display: "flex",
            //   flexDirection: "column",
            //   alignItems: "center",
            // }}
            >
              <Box
                component="form"
                //   onSubmit={handleSubmit}
                noValidate
              >
                {/* {showSupplierDetails && ( */}
                <>
                  <Material.Grid container spacing={2}>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Company name"
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                      />
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Company types
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={companyType}
                          label="Company types"
                          onChange={handleChange}
                        >
                          {companyTypes.map((data) => {
                            return <MenuItem value={data.k}>{data.v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Address line 1"
                        value={address1}
                        onChange={(event) => setAddress1(event.target.value)}
                      />
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Address line 2"
                        value={address2}
                        onChange={(event) => setAddress2(event.target.value)}
                      />
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Address line 3"
                        value={address3}
                        onChange={(event) => setAddress3(event.target.value)}
                      />
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      />
                    </Material.Grid>
                    <Material.Grid item md={12} sm={6} xs={12}>
                      <TextField
                        // size="small"

                        // sx={{ minWidth: "300px" }}
                        label="Pincode"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 6);
                        }}
                        type="number"
                        value={pincode}
                        onChange={(event) => setPincode(event.target.value)}
                      />
                    </Material.Grid>

                    <Material.Grid item md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          <TextField
                            // size="small"
                            sx={{ width: "100%" }}
                            label="Aadhaar"
                            value={aadhaar}
                            type="number"
                            onChange={validateAadhar}
                            error={isAadhaarNumError}
                          />
                        </Box>
                        <Box>
                          <VisibilityRoundedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              aadhaarUrl !== "" &&
                              dispatch(getFiles(aadhaarUrl))
                            }
                          />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="import-button1">
                            <Input
                              id="import-button1"
                              type="file"
                              onChange={handleUploadAadhaarFile}
                            />
                            <Tooltip title="Upload Aadhaar">
                              <FileUploadRoundedIcon
                                sx={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </InputLabel>
                        </Box>
                      </Box>
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          <TextField
                            // size="small"
                            fullWidth
                            label="PAN"
                            value={pan}
                            onChange={validatePanNumber}
                            error={panNumberError}
                          />
                        </Box>
                        <Box>
                          <VisibilityRoundedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              panUrl !== "" && dispatch(getFiles(panUrl))
                            }
                          />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="import-button2">
                            <Input
                              id="import-button2"
                              type="file"
                              onChange={handleUploadPanFile}
                            />
                            <Tooltip title="Upload PAN">
                              <FileUploadRoundedIcon
                                sx={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </InputLabel>
                        </Box>
                      </Box>
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          <TextField
                            // size="small"
                            fullWidth
                            label="TAN"
                            value={tan}
                            onChange={(event) => setTan(event.target.value)}
                          />
                        </Box>
                        <Box>
                          <VisibilityRoundedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              tanUrl !== "" && dispatch(getFiles(tanUrl))
                            }
                          />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="import-button3">
                            <Input
                              id="import-button3"
                              type="file"
                              onChange={handleUploadTanFile}
                            />
                            <Tooltip title="Upload TAN">
                              <FileUploadRoundedIcon
                                sx={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </InputLabel>
                        </Box>
                      </Box>
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          <TextField
                            // size="small"
                            fullWidth
                            label="GST"
                            value={gst}
                            onChange={validateGstNum}
                            error={isGstNumberError}
                          />
                        </Box>
                        <Box>
                          <VisibilityRoundedIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              gstUrl !== "" && dispatch(getFiles(gstUrl))
                            }
                          />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="import-button4">
                            <Input
                              id="import-button4"
                              type="file"
                              onChange={handleUploadGSTFile}
                            />
                            <Tooltip title="Upload GST">
                              <FileUploadRoundedIcon
                                sx={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </InputLabel>
                        </Box>
                      </Box>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      marginBottom="20px"
                    >
                      <Button
                        variant="contained"
                        // size="small"
                        onClick={handleUpdateSupplierDetails}
                      >
                        Update
                      </Button>
                    </Material.Grid>
                  </Material.Grid>
                </>
                {/* )} */}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </Box>
      {/* </CardContent>
        </Card> */}
      {/* </Paper> */}
      <ToastContainer />
    </Box>
    // </Paper>
  );
}
