import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import CustomConsole from "../../CustomConsole";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  PostpaidOrder,
  NotConfirmedOrderDetails,
} from "../Redux/Actions/adminPostpaidOrdersAction";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function PostpaidPhoneOrdersTable({
  phonePostpaidList,
  setPhoneOrderDetails,
  phoneOrderDetails,
  setNotConfirmOdr,
  setCardVisible,
  setCheckedItems,
  checkedItems,
  setorderid,
  orderid,
  setSelected,
  selected,
  userId,
  setUserId,
  setTotalPrice,
  totalPrice,
  setPaybutton,
  request,
  setrequest,
  rejectBtn,
}) {
  const dispatch = useDispatch();
  const postpaidOrdersData = useSelector((state) => state.postpaidOrderAdmin);
  const pendingOrderDet = useSelector((state) => state.postpaidOrderAdmin);
  const [selectedOrderId, setSelectedOrderId] = React.useState("");
  const [tableOrderBtn, setTableOrderBtn] = React.useState(false);
  const [tableOrderFlag, setTableOrderFlag] = React.useState(false);

  const [flag, setflag] = React.useState(1);
  const [amount, setamount] = React.useState([]);

  React.useEffect(() => {
    CustomConsole(phonePostpaidList);
  }, []);
  CustomConsole(userId);

  const handleOrderListClick = (orderId, status) => {
    CustomConsole(orderId);
    CustomConsole(status);
    if (status === "PENDING") {
      CustomConsole("inside Pending");
      setSelectedOrderId(orderId);
      setTableOrderBtn(true);
      setCardVisible(true);
    } else {
      setSelectedOrderId(orderId);
      setTableOrderBtn(true);
      setCardVisible(true);
    }
  };

  const handleChange = (event, key, data) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[key] = event.target.checked;
    setCheckedItems(newCheckedItems);

    if (event.target.checked === true) {
      setSelected([
        ...selected,
        {
          order_id: data.order_id,
        },
      ]);
      setrequest([...request, data.order_id]);
      setamount([...amount, parseFloat(data.pending_amount)]);
    } else if (event.target.checked === false) {
      selected.map((selectedData, key) => {
        if (selectedData.order_id === data.order_id) {
          selected.splice(key, 1);
          request.splice(key, 1);
          amount.splice(key, 1);
          setflag(flag + 1);
        }
      });
    }
    if (event.target.checked === true) {
      setorderid(data.order_id);
    }
    setPhoneOrderDetails(selected);
  };

  React.useEffect(() => {
    setTotalPrice(0);
    let a = 0;
    if (amount.length > 0) {
      amount.forEach((item) => {
        a += item;
      });
      setTotalPrice(a);
    }
  }, [amount, flag]);

  const handelorder = () => {
    setPaybutton(true);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "15px",
            fontWeight: "bold",
            padding: "10px",
            marginBottom: "10px",
          }}
          varient="h6"
        >
          Post paid order list
        </Typography>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Order ID</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Status
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Delivery Date
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Pending Amount
              </TableCell>
              <TableCell padding="checkbox">
                {/* <Checkbox color="primary" {...label} /> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {phonePostpaidList.map((data, key) => (
              <TableRow
                key={key + 1}
                onClick={() => handleOrderListClick(data.order_id, data.status)}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#036bfc",
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {data.order_id}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {data.status === "ACCEPTED" ? (
                    <>
                      {" "}
                      <Box>{data.status}</Box>
                      <Box>
                        <CheckCircleRoundedIcon color="success" />
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                  {data.status === "REJECTED" ? (
                    <>
                      {" "}
                      <Box>{data.status}</Box>
                      <Box>
                        <CancelRoundedIcon color="error" />
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                  {data.status === "PENDING" ? (
                    <>
                      {" "}
                      <Box>{data.status}</Box>
                      <Box>
                        <InfoRoundedIcon color="secondary" />
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell align="center">{data.delivery_date}</TableCell>
                <TableCell component="th" align="center" scope="row">
                  {data.pending_amount}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    color="primary"
                    checked={checkedItems[key] || false}
                    onChange={(event) => handleChange(event, key, data)}
                    // checked={isItemSelected}
                    // inputProps={{
                    //   'aria-labelledby': labelId,
                    // }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Button variant="contained" color="success" onClick={handelorder}>
        {" "}
        Pay to Orders
      </Button> */}
    </Box>
  );
}
