import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetDamageOrders } from "../Redux/Actions/supliersAction";
import moment from "moment";
import FilterDialog from "./Components/FilterDialog";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { RECYCLE_STATUS_CODE } from "../../Components/AppMeta/appMetaConfig";

const DamagedHistory = () => {
  const pageSizeList = [25, 50, 100, 200, 500, 1000, 2500, 5000];
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const [OrdersList, setOrdersList] = useState([]);
  const [filters, setFilters] = useState({
    page_size: pageSizeList?.[0],
    page_number: 1,
  });
  const fetchData = (params) => {
    dispatch(GetDamageOrders(params)).then((data) => {
      setOrdersList(data?.data || []);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleClearFilter = () => {
    setOpenFilter(false);
    delete filters.phone;
    delete filters.order_id;
    fetchData(filters);
    setFilters((prev) => {
      return {
        ...filters,
      };
    });
  };
  const handleFilter = () => {
    fetchData(filters);
    setOpenFilter(false)
  };
  const handleChangePagination = (pagination) => {
    setFilters((prev) => {
      fetchData({
        ...prev,
        ...pagination,
      });

      return {
        ...prev,
        ...pagination,
      };
    });
  };
  const orderStatus = (order_status) => {
    switch (order_status) {
      case RECYCLE_STATUS_CODE.FINISHED:
        return "FINISHED";
      case RECYCLE_STATUS_CODE.CANCELLED:
        return "CANCELLED";
      case RECYCLE_STATUS_CODE.REJECTED:
        return "REJECTED";
      case RECYCLE_STATUS_CODE.ACCEPTED:
        return "ACCEPTED";
      case RECYCLE_STATUS_CODE.REQUESTED:
        return "PAYMENT PENDING";
      case RECYCLE_STATUS_CODE.STARTED:
        return "STARTED";
      default:
        return "";
    }
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Box sx={{ mt: "5px" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => setOpenFilter(true)}
          >
            Filter
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "8px" }}>Page size:</InputLabel>
          <Select
            variant="standard"
            label="Page Size"
            value={filters.page_size}
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              width: "60px",
            }}
            onChange={(e) => {
              handleChangePagination({ page_size: e.target.value });
            }}
          >
            {pageSizeList.map((data, key) => {
              return (
                <MenuItem value={data} key={key}>
                  {data}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        {/* Page Number */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "8px" }}>Page number:</InputLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstPageIcon
              sx={{ color: filters.page_number - 1 > 0 ? "blue" : "gray" }}
              onClick={() => {
                if (filters.page_number - 1 > 0) {
                  handleChangePagination({
                    page_number: filters.page_number - 1,
                  });
                }
              }}
            />
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {filters.page_number}
            </Typography>
            <LastPageIcon
              sx={{
                color:
                  filters.page_number > 0 &&
                  OrdersList?.length === filters.page_number
                    ? "blue"
                    : "gray",
              }}
              onClick={() => {
                if (filters.page_number > 0) {
                  handleChangePagination({
                    page_number: filters.page_number + 1,
                  });
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ bgcolor: "#e5e8e8" }}>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", border: "1.5px solid #fbfcfc " }}
              >
                Order Id
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", border: "1.5px solid #fbfcfc " }}
              >
                Damaged By
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  paddingLeft: "50px",
                  border: "1.5px solid #fbfcfc ",
                }}
                align="left"
              >
                Products
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", border: "1.5px solid #fbfcfc " }}
                align="left"
              >
                Created By
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", border: "1.5px solid #fbfcfc " }}
                align="left"
              >
                Created Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", border: "1.5px solid #fbfcfc " }}
                align="left"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {OrdersList?.map((item) => {
              return (
                <TableRow sx={{ bgcolor: "#f8f9f9" }}>
                  <TableCell
                    align="center"
                    sx={{ border: "1.5px solid #d5d8dc " }}
                  >
                    {item?.details?.order_id}
                  </TableCell>
                  <TableCell
                    sx={{ border: "1.5px solid #d5d8dc " }}
                    align="center"
                  >
                    {item?.damaged_by?.fullname} <br />{" "}
                    {item?.damaged_by?.phone}
                  </TableCell>
                  <TableCell
                    sx={{ border: "1.5px solid #d5d8dc " }}
                    align="center"
                  >
                    {/* <TableRow
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        border: "0px solid white",
                      }}
                    >
                      <TableCell sx={{ padding: 0 }}>
                        <Typography
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          name
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: 0 }}>
                        <Typography sx={{ fontWeight: "bold" }}>Qty</Typography>
                      </TableCell>
                    </TableRow> */}
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingRight: "20px",
                        }}
                      >
                        name
                      </td>
                      <td style={{ fontWeight: "bold", textAlign: "left" }}>
                        Qty
                      </td>
                    </tr>
                    {item?.details?.products?.map((product) => {
                      return (
                        <tr align="center">
                          <td
                            style={{
                              textAlign: "left",
                              paddingRight: "20px",
                            }}
                          >
                            {product?.capacity}- {product?.material}
                          </td>
                          <td align="center">
                            {(product?.physical_damaged_container_qty || 0) +
                              (product?.qr_barcode_damaged_container_qty || 0)}
                          </td>
                        </tr>
                      );
                    })}
                  </TableCell>
                  <TableCell
                    sx={{ border: "1.5px solid #d5d8dc " }}
                    align="left"
                  >
                    {item?.created_details?.fullname}
                  </TableCell>
                  <TableCell
                    sx={{ border: "1.5px solid #d5d8dc " }}
                    align="left"
                  >
                    {moment(item?.created_at).format("DD-MM-YYYY hh:mm a")}
                  </TableCell>
                  <TableCell
                    sx={{ border: "1.5px solid #d5d8dc " }}
                    align="left"
                  >
                    {orderStatus(item?.status)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <FilterDialog
        filters={filters}
        setFilters={setFilters}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        openRecycleFilterDialog={openFilter}
        handleClose={() => setOpenFilter(false)}
      />
    </div>
  );
};

export default DamagedHistory;
