import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { green } from "@mui/material/colors";
import { styled } from "@mui/system";
// import { useDispatch, useSelector } from "react-redux";
// import CommonSettleDialog from "../../../Pages/AdminPoTrackPostPaid/CommonSettleDialog";
// import { GetInvoiceData } from "../../../Pages/Redux/Actions/adminPoTrackPostpaid";

const PendingAmountWrapper = styled(Box)({
  padding: "22px",
  borderRadius: "10px",
  marginBottom: "20px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  background: "linear-gradient(135deg, #f1f8e9, #dcedc8)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  "@media (mix-width:881px)": {
    padding: "16px",
    boxShadow: "none",
    flexDirection: "column",
  },
  "@media (min-width:704) ": { marginBottom: "20px" },
  // "@media (max-width:1180px)": { marginBottom: "10px" },
  // "@media (min-width:720px) and (max-width:1180px)": {
  //   padding: "22px",
  //   marginBottom: "42px",
  // },
});

const PendingAmount = ({ pendingAmount, userId }) => {
  console.log(userId);
  // const dispatch = useDispatch();
  // const invoiceData = useSelector((state) => state.poTrackDetails.invoiceData);

  // const [open, setOpen] = useState(false);

  // const handleOpenDialog = () => {
  //   if (userId) {
  //     dispatch(GetInvoiceData(userId));
  //     setOpen(true);
  //   } else {
  //     console.error("User ID is undefined. Cannot fetch invoices.");
  //   }
  // };

  // useEffect(() => {
  //   if (invoiceData && invoiceData.length > 0) {
  //     console.log("Invoices fetched: ", invoiceData);
  //   }
  // }, [invoiceData]);

  return (
    <Box sx={{ width: "100%" }}>
      <PendingAmountWrapper>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "1rem", sm: "1rem" },
          }}
        >
          <span style={{ color: green[600] }}>₹{pendingAmount || 0}</span>
        </Typography>
        {/* <Button
          variant="contained"
          onClick={handleOpenDialog}
          sx={{
            backgroundColor: green[600],
            "&:hover": { backgroundColor: green[800] },
          }}
        >
          Settle Amount
        </Button> */}
      </PendingAmountWrapper>

      {/* <CommonSettleDialogCommonSettleDialog
        open={open}
        setOpen={setOpen}
        poTrackSet={userId}
        hideSearchHeader={true}
        // invoices={invoiceData}
      /> */}
    </Box>
  );
};

export default PendingAmount;
