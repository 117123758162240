import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { styles } from "./cardStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
 
import CustomConsole from "../../CustomConsole";
function WfsOrderDetailFormView(props) {
  CustomConsole(props);
  const history = useHistory();
  const [singleSelectedData, setSingleSelectedData] = React.useState("");
  const [valueOrderId, setValueOrderId] = React.useState("");
  React.useEffect(() => {}, [props]);

  const handleChangeOrderId = (event) => {
    const selectedOrdersData = props?.data?.find(
      (data) => data.ec_order_id === event.target.value
    );
    CustomConsole(selectedOrdersData);
    setSingleSelectedData(selectedOrdersData);
    setValueOrderId(event.target.value);
  };
  const handleViewDetails = () => {
    if (
      singleSelectedData.ec_order_id !== undefined &&
      singleSelectedData.ec_order_id !== null &&
      singleSelectedData.ec_order_id
    ) {
      const url = `/dashboard/WfsSingleOrder?order_id=${singleSelectedData.ec_order_id}`;
      history.push(url);
    } else {
      toast.error("Please Select Order Id", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <Material.Paper
      elevation={14}
      sx={{ padding: "20px", bgcolor: "#E9F7EF", maxWidth: "100%" }}
    >
      <Material.Box>
        <Material.Box
          sx={{
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            gap: "10px",
          }}
        >
          <Material.Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                alignItems: "center",
              }}
            >
              <Material.InputLabel
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Order ID:
              </Material.InputLabel>
              <Material.Select
                variant="standard"
                sx={{ fontSize: "16px", fontWeight: "bold", width: "190px" }}
                value={valueOrderId}
                label="Order Id"
                onChange={handleChangeOrderId}
              >
                {props.data &&
                  props.data !== undefined &&
                  props.data !== null &&
                  props.data.length > 0 &&
                  props.data.map((orderIds, index) => {
                    return (
                      <Material.MenuItem
                        key={index}
                        value={orderIds.ec_order_id}
                      >
                        {orderIds.ec_order_id}
                      </Material.MenuItem>
                    );
                  })}
              </Material.Select>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            textAlign: "center",
          }}
        >
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Id </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.ec_order_id}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Status </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.order_status}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Received Cans </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.order_details?.outscanned_cans}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Submitted Cans </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.order_details?.inscanned_cans}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Expired Can Qty </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.order_details?.expired_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Qty </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.order_details?.order_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Can Capacity </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.order_details?.container_capacity}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Can Material </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.order_details?.container_material}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        {/* {singleSelectedData.ec_order_status === 501 ? ( */}
        <Material.Box>
          <Material.Button variant="contained" onClick={handleViewDetails}>
            view Details
          </Material.Button>
        </Material.Box>
        {/* ) : (
          ""
        )} */}
      </Material.Box>
    </Material.Paper>
  );
}

export default WfsOrderDetailFormView;
