/**
 * @author vinay kumar
 * @description Transfer Can Order List
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.52
 * @Date 29-10-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomConsole from "../../CustomConsole";

import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { transferCansStatus } from "../../Components/AppMeta/appMetaConfig";
import {
  GetSupplierTransferCansOrderList,
  GetTransferCansSupplierList,
  PutTransferAcceptReject,
} from "../Redux/Actions/ordersAdminAction";
import TranferCansAcceptOrRejectDialog from "../../Components/Dialog/TranferCansAcceptOrRejectDialog";
import TransferCansFilter from "../../Components/Dialog/TransferCansFilter";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "azure",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));
function TransferCanOrderList() {
  const [openDialogAcceptOrReject, setOpenDialogAcceptOrReject] =
    React.useState(false);
  const [singleData, setSingleData] = React.useState("");
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [changeMinusBtn, setChangeMinusBtn] = React.useState(false);
  const [changePlusBtn, setChangePlusBtn] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const [useId, setUseId] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [supplierId, setSupplierId] = React.useState("");
  const [rejectReason, setRejectReason] = React.useState("");
  const [totalCantainerQty, setTotalCantainerQty] = React.useState("");
  const [openTransferCansFilterDialog, setOpenTransferCansFilterDialog] =
    React.useState(false);
  const [orderStatus, setOrderStatus] = React.useState(
    transferCansStatus.TRANSFER_CAN_ORDER_CREATED
  );

  const [farBtnDisable, setFarBtnDisable] = React.useState(false);
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);
  CustomConsole(allOrderData);
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(
  //     GetSupplierTransferCansOrderList({ page_number: 0, page_size: pageSize })
  //   );
  //   // dispatch(
  //   //   GetTransferCansSupplierList({
  //   //     user_id: parseInt(useId),
  //   //     total_container_qty: totalCantainerQty,
  //   //     container_capacity: capacity,
  //   //     container_material: material,
  //   //   })
  //   // );
  // }, []);

  React.useEffect(() => {
    if (allOrderData.getTransferCansOrderList.length > 0) {
      setPageStatus(false);
    } else {
      setPageStatus(true);
    }
  });
  React.useEffect(() => {
    dispatch(
      GetSupplierTransferCansOrderList({
        page_number: pageNumber > 0 ? pageNumber - 1 : 0,
        page_size: pageSize,
        order_status: orderStatus,
      })
    );
  }, [pageNumber, pageSize]);
  const TransferCanStatus = (order_status) => {
    CustomConsole(order_status);
    switch (order_status) {
      case transferCansStatus.TRANSFER_CAN_ORDER_CREATED:
        return "ORDER_CREATED";
      case transferCansStatus.TRANSFER_CAN_ORDER_CONFIRMED:
        return "ORDER_CONFIRMED";
      case transferCansStatus.TRANSFER_CAN_ORDER_INSCAN_COMPLETED:
        return "ORDER_INSCAN_COMPLETED";
      case transferCansStatus.TRANSFER_CAN_ORDER_REJECTED:
        return "ORDER REJECTED";
      default:
        return "";
    }
  };
  // const handleChangeMinus = () => {
  //   if (pageNumber > 1) {
  //     setPageNumber(pageNumber - 1);
  //   }
  //   setChangeMinusBtn(true);
  // };

  // const handleChangePlus = () => {
  //   if (pageNumber > 0) {
  //     setPageNumber(pageNumber + 1);
  //   }
  //   setChangePlusBtn(true);
  // };
  // const pageForward = () => {
  //   if (allOrderData.getTransferCansOrderList.length > 0) {
  //     setPageNumber(pageNumber + 1);
  //   } else {
  //     setFarBtnDisable(true);
  //   }
  // };

  // const pageBackward = () => {
  //   // if (pageNumber - 1 > 0) {
  //   //   setPageNumber(pageNumber - 1);
  //   //   setFarBtnDisable(false);
  //   // }
  //     // if (pageNumber > 1) {
  //     //   setPageNumber(pageNumber - 1);
  //     // }
  //     // setFarBtnDisable(false);
  // };

  const handleChangeMinus = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setChangeMinusBtn(true);
  };

  const handleChangePlus = () => {
    if (pageNumber > 0 && allOrderData.getTransferCansOrderList.length > 0) {
      setPageNumber(pageNumber + 1);
    }
    setChangePlusBtn(true);
  };

  const handleSingleOrderClick = (row) => {
    CustomConsole("--------------handleTransferOrderDetails-----------------");
    CustomConsole(row.fullname);
    setSingleData(row);
    setOrderId(row.transfer_cans_order_id);
    setUseId(row.user_id);
    setCapacity(row.container_capacity);
    setMaterial(row.container_material);
    setSupplierId(row.supplier_id);
    setRejectReason(row.reject_reason);
    setTotalCantainerQty(row.total_container_qty);
    {
      row.order_status === 0 && setOpenDialogAcceptOrReject(true);
    }
  };
  const handleCloseAcceptRejectDialog = (props) => {
    CustomConsole(
      "--------------handleCloseAcceptRejectDialog-----------------"
    );
    CustomConsole(props);
    props.setIsAcceptedValue("");
    // props.setIsAccepted("");
    props.setSupplerName("");
    props.setRejectReason("");
    setOpenDialogAcceptOrReject(false);
  };
  const handleAcceptOrReject = (props) => {
    CustomConsole("--------------handleAcceptOrReject-----------------");
    CustomConsole(props);
    const reqObj = {
      order_id: props.order_id,
      supplier_id: props.supplier_id || undefined,
      is_accepted: props.is_accepted,
      total_container_qty: props.total_container_qty,
      reject_reason: props.reject_reason,
    };
    CustomConsole(reqObj);
    dispatch(PutTransferAcceptReject(reqObj));
    props.setIsAcceptedValue("");
    // props.setIsAccepted("");
    props.setSupplerName("");
    props.setRejectReason("");
    setOpenDialogAcceptOrReject(false);
    if (allOrderData.error === false || allOrderData.error === true) {
      setTimeout(() => {
        dispatch(
          GetSupplierTransferCansOrderList({
            page_number: 0,
            page_size: pageSize,
          })
        );
      }, 1000);
    }
  };
  const handleChangeIsAccepected = (props) => {
    CustomConsole(props);
    {
      props.value === true &&
        dispatch(
          GetTransferCansSupplierList({
            user_id: parseInt(props.user_id),
            total_container_qty: props.total_container_qty,
            container_capacity: props.container_capacity,
            container_material: props.container_material,
          })
        );
    }
  };

  const handleOpenFilter = () => {
    CustomConsole("--------------handleOpenFilter---------------");
    setOpenTransferCansFilterDialog(true);
  };

  const handleFilter = (props) => {
    CustomConsole("--------------handleFilter---------------");
    dispatch(
      GetSupplierTransferCansOrderList({
        page_number: pageNumber - 1,
        page_size: pageSize,
        order_status: orderStatus,
      })
    );
    setOpenTransferCansFilterDialog(false);
    setPageNumber(1);
    setPageSize(25);
  };
  const handleClose = () => {
    CustomConsole("-------------handleClose--------------");
    setOpenTransferCansFilterDialog(false);
  };

  return (
    <Material.Box>
      <Material.Box
        sx={{
          display: "flex",
          justifyContent: { md: "flex-end", xs: "center" },
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            border: "1px solid #1FA6F9",
            "&:hover": {
              backgroundColor: "#D1E9F7",
              color: "black",
            },
          }}
          onClick={handleOpenFilter}
        >
          Filter&nbsp;
          <FilterAltIcon />
        </Button>

        <FormControl sx={{ minWidth: 100 }} size="small">
          <InputLabel>Page size</InputLabel>
          <Select
            value={pageSize}
            label="Page size"
            onChange={(e) => {
              setPageSize(e.target.value);
              // setSearchOrderFlag(true);
            }}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={500}>500</MenuItem>
          </Select>
        </FormControl>
        <Material.Box sx={{ display: "flex", justifyContent: "center" }}>
          <Material.Button onClick={handleChangeMinus}>
            <ArrowBackIos />
          </Material.Button>
          <Material.Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "40px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber}
          </Material.Typography>
          <Material.Button disabled={pageStatus} onClick={handleChangePlus}>
            <ArrowForwardIos />
          </Material.Button>
        </Material.Box>
      </Material.Box>
      <Material.Box sx={{ maxWidth: "150Vw" }}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow sx={{ bgcolor: "#AED6F1" }}>
                <StyledTableCell align="center" size="small">
                  <strong>S.no</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Order Id</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  {" "}
                  <strong>Full Name</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Status</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Container Qty</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Assigned Supplier</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Material</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Capacity</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>OTP</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Created At</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Updated At</strong>
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  <strong>Reject Reason</strong>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allOrderData.getTransferCansOrderList !== undefined &&
              allOrderData.getTransferCansOrderList !== null &&
              allOrderData.getTransferCansOrderList.length > 0 ? (
                <>
                  {allOrderData.getTransferCansOrderList.map((row, key) => (
                    <StyledTableRow
                      // key={key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "#036bfc",
                        },
                      }}
                      // onClick={handleTransferOrderDetails(() => {
                      //   row.transfer_cans_order_id;
                      // })}
                      onClick={() => handleSingleOrderClick(row)}
                    >
                      <StyledTableCell align="center" size="small">
                        {pageNumber > 1
                          ? pageNumber * 10 + (key + 1) - 10
                          : key + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.transfer_cans_order_id}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.fullname}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {TransferCanStatus(row.order_status)}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.total_container_qty}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.supplier_name ? row.supplier_name : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.container_material}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.container_capacity}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.otp}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.created_at}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.updated_at}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.reject_reason !== undefined &&
                        row.reject_reason !== null
                          ? row.reject_reason
                          : "--"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                "NO Orders Found"
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Material.Box>
      <>
        {" "}
        <TranferCansAcceptOrRejectDialog
          openDialogAcceptOrReject={openDialogAcceptOrReject}
          handleAcceptOrReject={handleAcceptOrReject}
          handleCloseAcceptRejectDialog={handleCloseAcceptRejectDialog}
          singleData={singleData}
          supplierList={allOrderData.getTransferCansSupplierList}
          handleChangeIsAccepected={handleChangeIsAccepected}
        />
        <TransferCansFilter
          openTransferCansFilterDialog={openTransferCansFilterDialog}
          orderStatus={orderStatus}
          handleClose={handleClose}
          handleFilter={handleFilter}
          setOrderStatus={setOrderStatus}
          // handleChangeOrderStatus={handleChangeOrderStatus}
        />
      </>
    </Material.Box>
  );
}

export default TransferCanOrderList;
