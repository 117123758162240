import React from "react";
import * as Material from "@mui/material";
import { appColors } from "../Assests/AppTheme/appThemeConst";
import CustomConsole from "../../CustomConsole";

function DistributorTransferCanOrderCansCard(props) {
CustomConsole(props);
  const transferCanData = [
    { type: "Full Name", payload: props.SingleData.fullname },
    { type: "Order id", payload: props.SingleData.transfer_cans_order_id },
    {
      type: "Total Container Qty",
      payload: props.SingleData.total_container_qty,
    },
    {
      type: "Order Status",
      payload: props.transferCansStatusShow(props.SingleData.order_status),
    },
    {
      type: "Container Material",
      payload: props.SingleData.container_material,
    },
    {
      type: "Container Capacity",
      payload: props.SingleData.container_capacity,
    },
  ];

  const handleClickOrder = () => {
    CustomConsole("----------handleClickOrder-------------");
    props.handleClickOrder({
      ordeer_id: props.SingleData.transfer_cans_order_id,
      order_status: props.SingleData.order_status,
      is_submit_started: props.SingleData.is_submit_started,
      is_submit_finished: props.SingleData.is_submit_finished,
    });
  };

  return (
    <Material.Box sx={{ display: "flex", flexDirection: "row" }}>
      <Material.Card
        elevation={24}
        sx={{
          borderRadius: "20px",
          margin: "10px",
          margin: "10px",
          minWidth: 300,
          maxHeight: 350,
          background: "#dfedf7",
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
          },
        }}
      >
        <Material.CardContent>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {transferCanData.map((data, key) => {
              return (
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Material.Typography
                    sx={{
                      display: "flex",
                      textAlign: "start",
                      color: "blue",
                      fontWeight: "bold",
                    }}
                  >
                    {data.type} :{" "}
                  </Material.Typography>

                  <Material.Typography>{data.payload}</Material.Typography>
                </Material.Box>
              );
            })}
          </Material.Box>
          {/* {props.currentDay ? ( */}
          <Material.Box sx={{ marginTop: "10px" }}>
            <Material.Button
              onClick={handleClickOrder}
              variant="contained"
              sx={{
                background: appColors.commonBtnColor.background,
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
            >
              {props.SingleData.is_submit_started === false &&
              props.SingleData.is_submit_finished === false &&
              props.SingleData.order_status === 200
                ? "Enter OTP"
                : props.SingleData.is_submit_started === true &&
                  props.SingleData.is_submit_finished === false &&
                  props.SingleData.order_status === 200
                ? "IN-Scan"
                : null}
            </Material.Button>
          </Material.Box>
          {/* ) : (
          ""
        )} */}
        </Material.CardContent>
      </Material.Card>
    </Material.Box>
  );
}

export default DistributorTransferCanOrderCansCard;
