/**
 * @author vinay kumar
 * @description Completed Batch Edit Dialog
 * @copyright Bookwater tech pvt ltd
 * @Date 01-08-2023
 */
// This UI Complete ReDesign Done by Vinay

import React from "react";
import * as Material from "@mui/material";
import { UploadFile } from "../../../Pages/Redux/Actions/fileHandlingAction";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { InputLabel, Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import moment from "moment";
import CustomConsole from "../../../CustomConsole";
function CompletedBatchEditDialog(props) {
  CustomConsole(props)
  const [waterQualityRepotFile, setWaterQualityRepotFile] = React.useState("");
  const [uploadWQReprtBtn, setUploadWQReprtBtn] = React.useState(false);
  const [uploadFlag, setUploadFlag] = React.useState(false);
  const [qWRepotUrl, setQWRepotUrl] = React.useState("");
  // const [manufacturingDate, setManufacturingDate] = React.useState("");
  // const [expiryDate, setExpiryDate] = React.useState("");
  const [manufacturingDate, setManufacturingDate] = React.useState(() => moment(props.wfsMftDate));
  const [expiryDate, setExpiryDate] = React.useState(() => moment(props.wfsExpiryDate));
  const [mtfgDate, setMtfgDate] = React.useState(() =>
    moment(props.wfsMftDate)
  );
  const [expiryWaterDate, setExpiryWaterDate] = React.useState(() =>
    moment(props.wfsExpiryDate)
  );
  
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);

  const Input = styled("input")({
    display: "none",
  });

  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlag === true
    ) {
      toast.success("Water Quality Report File uploaded successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUploadFlag(false);
    }
    if (fileHandler.isFileUploaded === true && uploadWQReprtBtn === true) {
      CustomConsole("Water Quality Report file uploaded file here");
      setQWRepotUrl(fileHandler.fileName);
      setUploadWQReprtBtn(false);
    }
  }, [fileHandler]);
  React.useEffect(() => {
    CustomConsole(`WQReportFile ${waterQualityRepotFile}`);

    if (waterQualityRepotFile !== "") {
      dispatch(UploadFile(waterQualityRepotFile));
      setUploadFlag(true);
      setUploadWQReprtBtn(true);
    }
  }, [waterQualityRepotFile]);

  const handleUploadWaterQualityReportFile = (e) => {
    CustomConsole(e.target.files[0]);
    setWaterQualityRepotFile(e.target.files[0]);
  };

  const handleClose = () => {
    CustomConsole("-------------handleClose--------------");
    props.handleClose();
  };
  const handleSubmit = () => {
    CustomConsole("----------handleSubmit-----------");
    props.handleSubmit({
      WQReportUrl: qWRepotUrl,
      Manufacturing_date: mtfgDate,
      Expiry_date: expiryWaterDate
    });
  };

  const handleChangeManufacturingDate = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setMtfgDate(() => moment(newValue.$d));
      setManufacturingDate(newValue);
      CustomConsole(newValue.$d);
    }
  };
  const handleChangeExpiryDate = (newValue) => {
    CustomConsole(newValue);

    if (newValue != null && newValue.$d != null) {
      setExpiryWaterDate(() => moment(newValue.$d));
      setExpiryDate(newValue);
      CustomConsole(newValue.$d);
    }
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.editOpenDialog}>
        <Material.DialogTitle>
          <Material.Typography>Edit Batch</Material.Typography>
        </Material.DialogTitle>
        <Material.DialogContent>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Material.Box>
              <Material.Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Upload Water Quality Report
              </Material.Typography>
            </Material.Box>
            <Material.Box>
              <InputLabel htmlFor="import-button">
                <Input
                  id="import-button"
                  type="file"
                  onChange={handleUploadWaterQualityReportFile}
                />
                <Tooltip title="Upload Water Quality Repot">
                  <FileUploadRoundedIcon sx={{ cursor: "pointer" }} />
                </Tooltip>
              </InputLabel>
            </Material.Box>
            <Material.Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Manufacturing Date"
                inputFormat="DD/MM/YYYY"
                  value={manufacturingDate}
                  onChange={handleChangeManufacturingDate}
                renderInput={(params) => <Material.TextField {...params} />}
              />
            </LocalizationProvider>
              {/* <Material.Typography>Manufacturing Date</Material.Typography>
              <Material.TextField value={manufacturingDate} onChange={(event)=> setManufacturingDate(event.target.value)} /> */}
            </Material.Box>
            <Material.Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Expiry Date"
                inputFormat="DD/MM/YYYY"
                  value={expiryDate}
                  onChange={handleChangeExpiryDate}
                renderInput={(params) => <Material.TextField {...params} />}
              />
            </LocalizationProvider>
              {/* <Material.Typography>Expiry Date</Material.Typography>
              <Material.TextField value={expiryDate} onChange={(event)=> setExpiryDate(event.target.value)} /> */}
            </Material.Box>
            <Material.Box>
              <Material.Box sx={{ display: "flex", gap: "20px" }}>
                <Material.Button variant="contained" onClick={handleClose}>
                  Cancel
                </Material.Button>
                <Material.Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Material.Button>
              </Material.Box>
            </Material.Box>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default CompletedBatchEditDialog;
