import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import CustomConsole from "../../CustomConsole";

function UserDetailsByReferralDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      sx={{ width: "100%" }}
    >
      <DialogTitle>User Details:</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box sx={styles.contenctAlignBox}>
            <Typography sx={styles.heading}>Full name:</Typography>
            <Typography sx={styles.answerData}>
              {props.data.fullname}
            </Typography>
          </Box>
          <Box sx={styles.contenctAlignBox}>
            <Typography sx={styles.heading}>Email:</Typography>
            <Typography sx={styles.answerData}>{props.data.email}</Typography>
          </Box>
          <Box sx={styles.contenctAlignBox}>
            <Typography sx={styles.heading}>Phone:</Typography>
            <Typography sx={styles.answerData}>{props.data.phone}</Typography>
          </Box>

          <Box sx={styles.contenctAlignBox}>
            <Typography sx={styles.heading}>Registered at:</Typography>
            <Typography sx={styles.answerData}>
              {moment(props.data.created_at).format("DD/MM/YYYY hh:mm A")}
            </Typography>
          </Box>
          <Box sx={styles.contenctAlignBox}>
            <Typography sx={styles.heading}>Referral id:</Typography>
            <Typography sx={styles.answerData}>
              {props.data.referral_id}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={styles.buttonStyle} onClick={props.handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserDetailsByReferralDialog;

const styles = {
  buttonStyle: {
  color: "white",
  fontWeight: "bold",
    backgroundImage: "linear-gradient(#021636,#0c4aad)",
    "&:hover": {
      backgroundImage: "linear-gradient(#6b8df2,#021636)",
    },
  },
  contenctAlignBox: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "5px",
    padding: "5px",
  },

  answerData: {
    display: "flex",
    width: "100%",
    textAlign: "start",
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  heading: {
    marginRight: "5px",
    textAlign: "start",
    width: "100%",
    color: "blue",
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
};
