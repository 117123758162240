import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Card, Typography } from "@mui/material";
import AddBannerDetailsDialog from "./AddBannerDetailsDialog";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import {
  GetParticularBannerDetails,
  GetAllBanners,
} from "../Redux/Actions/adminBannersAction";
import UpdateBannerDetailsDialog from "./UpdateBannerDetailsDialog";
import "../Common/drawer.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    background: "rgb(208,221,242)",
    backgroundImage:
      "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AllBannersTable({
  allBanners,
  bannerTypeList,
  setBannerDetailsFilterFlag,
  createdAt,
  isEnabled,
  addListEmpty,
  setBannerTypeFilterDialog,
  setBannerDetailsFilterDialog,
}) {
  const dispatch = useDispatch();
  const bannersData = useSelector((state) => state.adminBannersData);

  const [isAddBannerDetailsDialog, setIsAddBannerDetailsDialog] =
    React.useState(false);
  const [isUpdateBannerDetailsDialog, setIsUpdateBannerDetailsDialog] =
    React.useState(false);
  const [tableClicks, setTableClicks] = React.useState(false);

  const [bannerDetails, setBannerDetails] = React.useState({});
  const [bannerId, setBannerId] = React.useState("");
  const [allBannerPageNum, setAllBannerPageNum] = React.useState(1);
  const [apiTriggerFlag, setApiTriggerFlag] = React.useState(false);
  React.useEffect(() => {
    CustomConsole(bannersData);
    if (bannersData.error === false) {
      if (
        Object.keys(bannersData.particularBannerDetails).length > 0 &&
        tableClicks === true
      ) {
        setBannerDetails(bannersData.particularBannerDetails);
        setIsUpdateBannerDetailsDialog(true);
        setTableClicks(false);
      }
    }
  }, [bannersData]);

  React.useEffect(() => {
    CustomConsole(allBanners);

    CustomConsole(bannerTypeList);
  }, [allBanners, bannerTypeList]);

  React.useEffect(() => {
    if (apiTriggerFlag === true) {
      dispatch(GetAllBanners(allBannerPageNum - 1, createdAt, isEnabled));
      setApiTriggerFlag(false);
    }
  }, [allBannerPageNum]);

  const handleAllBannerTableClick = (bannerId) => {
    dispatch(GetParticularBannerDetails(bannerId));
    setBannerId(bannerId);
    setTableClicks(true);
  };

  const pageFarward = () => {
    setAllBannerPageNum((page) => page + 1);
    setApiTriggerFlag(true);
  };
  const pageBackward = () => {
    if (allBannerPageNum - 1 > 0) {
      setAllBannerPageNum((page) => page - 1);
      setApiTriggerFlag(true);
    }
  };
  return (
    <Box sx={{ maxWidth: { xs: 340, lg: 1, md: 1, sm: 1 } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { md: "flex-end", xs: "center" },
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ padding: "10px" }}>
          <Button
            variant="contained"
            onClick={() => setIsAddBannerDetailsDialog(true)}
          >
            Add Banners
          </Button>
        </Box>
        <Box sx={{ marginTop: "11px" }}>
          <Button
            variant="outlined"
            // size="small"
            color="secondary"
            onClick={() => setBannerDetailsFilterDialog(true)}
          >
            Filter
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gap: "15px",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={pageBackward}
            >
              <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
            </Button>
          </Box>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "2px",
              width: "30px",
              height: "25px",
              borderRadius: "15px",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {allBannerPageNum}
          </Typography>
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={pageFarward}
              disabled={addListEmpty}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
            </Button>
          </Box>
        </Box>
      </Box>
      <Card>
        <TableContainer component={Paper} className="drawer">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Banner header</StyledTableCell>
                <StyledTableCell>Banner type</StyledTableCell>
                <StyledTableCell>Message</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allBanners.map((row, key) => (
                <StyledTableRow
                  key={key}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#BECAE0",
                    },
                  }}
                  onClick={() => handleAllBannerTableClick(row.id)}
                >
                  <StyledTableCell component="th" scope="row">
                    {key + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.banner_header}</StyledTableCell>
                  <StyledTableCell>{row.banner_type_name}</StyledTableCell>
                  <StyledTableCell sx={{ width: "10px" }}>
                    {row.message}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.status ? "Enabled" : "Disabled"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {isAddBannerDetailsDialog ? (
        <>
          <AddBannerDetailsDialog
            isAddBannerDetailsDialog={isAddBannerDetailsDialog}
            setIsAddBannerDetailsDialog={setIsAddBannerDetailsDialog}
            bannerTypeList={bannerTypeList}
            setBannerDetailsFilterFlag={setBannerDetailsFilterFlag}
          />
        </>
      ) : (
        ""
      )}
      {isUpdateBannerDetailsDialog ? (
        <>
          <UpdateBannerDetailsDialog
            isUpdateBannerDetailsDialog={isUpdateBannerDetailsDialog}
            setIsUpdateBannerDetailsDialog={setIsUpdateBannerDetailsDialog}
            bannerDetails={bannerDetails}
            bannerId={bannerId}
            createdAt={createdAt}
            isEnabled={isEnabled}
            pageNumber={allBannerPageNum}
          />
        </>
      ) : (
        ""
      )}
    </Box>
  );
}
