import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

const BuyBackFilterDialog = ({
  open,
  setOpen,
  handelFilterSubmit,
  type,
  handelClearSubmit,
}) => {
  const [active, setActive] = useState(true);
  const [sortByCapacity, setSortByCapacity] = useState("DESC");
  const [sortByDate, setSortByDate] = useState("ASC");
  const [sortByStatus, setSortByStatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);

  //function for handling filter operation
  const handleApplyFilter = () => {
    let reqObj = {
      sortByStatus,
      sortByDate,
      phoneNumber,
    };
    // Call the callback function with the selected filter options
    handelFilterSubmit(reqObj);
    setOpen(false);
  };

  //Function for handling filter operation
  const handelClearFilter = () => {
    setSortByDate("DESC");
    setSortByStatus("");
    setPhoneNumber(null);
    handelClearSubmit();
    setOpen(false);
  };

  //function for handling input changes
  const handleInputChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Filter Options</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>Order Status</Typography>
          <FormControl fullWidth size="small">
            <Select
              value={sortByStatus}
              onChange={(e) => setSortByStatus(e.target.value)}
            >
              {type === 100 && <MenuItem value="200">Accepted</MenuItem>}
              {type === 100 && <MenuItem value="500">Completed</MenuItem>}
              {type === 100 && <MenuItem value="400">Cancelled</MenuItem>}
              {type === 200 && <MenuItem value="100">Pending</MenuItem>}
              {type === 200 && <MenuItem value="200">Settled</MenuItem>}
              {type === 200 && <MenuItem value="300">Cancelled</MenuItem>}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{marginTop:"1rem"}}>
        <Typography>User Phone Number</Typography>
          <TextField
            label="User Phone"
            name="phone_number"
            size="small"
            type="tel" // Set input type to 'tel'
            value={phoneNumber}
            onChange={handleInputChange}
            inputProps={{
              pattern: "[0-9]*", // Optionally restrict input to numbers only
              maxLength: 15, // Set a maximum length if desired
            }}
          />
        </Box>
        <Box>
          <Typography>Sort by date</Typography>
          <FormControl fullWidth size="small">
            <Select
              value={sortByDate}
              onChange={(e) => setSortByDate(e.target.value)}
            >
              <MenuItem value="DESC">Recent First</MenuItem>
              <MenuItem value="ASC">Old First</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/* Repeat similar pattern for other filter options */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>close</Button>
        <Button onClick={handelClearFilter}>Clear Filter</Button>
        <Button
          onClick={handleApplyFilter}
          variant="contained"
          color="primary"
          size="small"
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyBackFilterDialog;
