import { Box, Grid, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CustomConsole from "../../CustomConsole";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import FaceIcon from "@mui/icons-material/Face";
import Paper from "@mui/material/Paper";
import { styles } from "./stockDataStyles";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { GetWfsDetails } from "../Redux/Actions/profileAction";
import {
  getFiles,
  UploadFile,
  UploadQrFile,
} from "../Redux/Actions/fileHandlingAction";
import MuiAlert from "@mui/material/Alert";
import ScannerConnectDescriptionDialog from "../../Components/Dialog/ScannerConnectDescriptionDialog";
import WfsStockDetailsCard from "../../Components/Cards/WfsStockDetailsCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
function WfsHome() {
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profile);
  const wfsDetails = useSelector((state) => state.wfsDetails);

  const fileHandler = useSelector((state) => state.fileHandler);
  const [petStock, setPetStock] = React.useState([]);
  const [fileToUpload, setFileToUpload] = React.useState("");
  const [downloadQd, setDownloadQa] = React.useState(false);
  const [isChooseFileOpen, setIsChooseFileOpen] = React.useState(false);
  const [wfsId, setWfsId] = React.useState(0);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [wfsCardDetails, setWfsCardDetails] = React.useState({});
  const [downloadFile, setDownloadFile] = React.useState("");

  //refs
  const uploadFile = React.useRef(null);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  React.useEffect(() => {
    CustomConsole(fileToUpload);
  }, [fileToUpload]);
  React.useEffect(() => {
    CustomConsole("---You are searching this----");
    CustomConsole(profileDetails);
    if (profileDetails.user) {
      setWfsCardDetails(profileDetails.user);
    }
    CustomConsole();
  }, [profileDetails]);

  React.useEffect(() => {
    CustomConsole(wfsDetails);
    if (wfsDetails.error === false) {
      setWfsId(wfsDetails.data.wfs_id);
      CustomConsole("--changing File---");
      setDownloadFile(wfsDetails.data.water_quality_report_file);
    }
  }, [wfsDetails]);
  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileHandler.error === false) {
      if (fileHandler.fileName !== "") {
        dispatch(UploadQrFile(fileHandler.fileName, wfsId));
      }
      if (fileHandler.isFileUploaded === true) {
        CustomConsole("Successfully uploaded file");
        setOpenAlert(true);

        setTimeout(() => {
          dispatch(GetWfsDetails());
        }, 1000);

        setFileToUpload("");
        setIsChooseFileOpen(false);
      }
    }
  }, [fileHandler]);

  React.useEffect(() => {
    if (downloadQd === true) {
      CustomConsole("useEffect getFiles API");
      if (downloadFile !== "") {
        dispatch(getFiles(downloadFile));
      }

      setDownloadQa(false);
    }
  }, [downloadQd]);

  React.useEffect(() => {
    CustomConsole(wfsDetails.stock);
    if (wfsDetails.stock) {
      wfsDetails.stock.map((data, key) => {
        if (data.container_material === "PET") {
          CustomConsole(data);
          setPetStock(data);
        }
      });
    }
  }, [wfsDetails]);

  const handleDownloadQD = () => {
    setDownloadQa(true);
    CustomConsole(wfsDetails.data.water_quality_report_file);
    // saveAs(wfsDetails.data.water_quality_report_file, "image1.jpg");
  };

  // const handleUploadQD = () => {
  //   setIsChooseFileOpen(true);
  // };

  // const handleConfirmToUpload = () => {
  //   CustomConsole(fileToUpload);
  //   if (fileToUpload !== "") {
  //     dispatch(UploadFile(fileToUpload));
  //   }
  // };
  // const [decodedResult, setDecodedResult] = React.useState([]);
  // this.onNewScanResult = this.onNewScanResult.bind(this);
  const [decodedResults, setDecodedResults] = React.useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  // <Html5QrcodePlugin fps={10} qrbox={250} disableFlip={true} />
  //     <ResultContainerPlugin results={decodedResult} />

  const uploadFn = () => {
    uploadFile.current.click();
  };

  const uploadChange = async (e) => {
    try {
      const selectedFile = e.target.files[0];
      dispatch(UploadFile(selectedFile));
    } catch (error) {}
  };
  const [openQrDetailsDialog, setOpenQrDetailsDialog] = React.useState(false);
  const handleCloseQrDetailsDialog = () => {
    setOpenQrDetailsDialog(false);
  };

  //useEffect for getting wfs details
  React.useEffect(() => {
    dispatch(GetWfsDetails());
  }, []);

  return (
    <Grid conntainer spacing={2}>
      <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
        {profileDetails.error === false &&
        Object.keys(wfsCardDetails).length > 0 ? (
          <>
            {" "}
            <Card sx={{ marginTop: "4rem" }}>
              <CardContent>
                <Chip
                  icon={<FaceIcon />}
                  label={profileDetails.user.fullname}
                  variant="outlined"
                  color="success"
                  sx={{ marginBottom: "10px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                  >
                    Email: {profileDetails.user.email}
                  </Typography>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                  >
                    Phone: {profileDetails.user.phone}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </>
        ) : (
          <Box sx={{ width: 300 }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
        <Paper>
          <Box>
            <TableContainer component={Paper} elevation={2}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{ fontWeight: "bold" }}
                    >
                      Stock Details
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>S.no</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Capacity</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Material
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Empty
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Filled
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      OnGoing
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Physically Damaged
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Qr Bar Code Damage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wfsDetails?.stock?.map((row, index) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {row.container_capacity}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {row.container_material}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.empty_containers}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.filled_containers}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.ongoing_containers}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.physically_damaged_containers}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.qr_bar_code_damaged_containers}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Chip
                  label="Water Quality Details"
                  // variant="outlined"
                  color="success"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleDownloadQD}
                    // to={wfsDetails.data.water_quality_report_file}
                    target="_blank"
                  >
                    <FileDownloadRoundedIcon /> Download
                  </Button>

                  <Button onClick={uploadFn} variant="contained" size="small">
                    <FileUploadRoundedIcon /> Upload
                  </Button>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={uploadFile}
                    onChange={uploadChange}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      {openAlert ? (
        <>
          <Snackbar
            open={openAlert}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Uploaded Successfully!
            </Alert>
          </Snackbar>
        </>
      ) : (
        ""
      )}
      <Grid item xs={12}>
        <Paper sx={{ marginTop: "20px", padding: "10px" }}>
          <Typography
            sx={{ color: "blue", fontWeight: "bold", fontSize: "16px" }}
          >
            QR/Barcode Scanner Pairing Process
          </Typography>
          <Button
            variant="contained"
            sx={{ color: "white", fontWeight: "bold", marginTop: "10px" }}
            onClick={() => setOpenQrDetailsDialog(true)}
          >
            View
          </Button>
        </Paper>
      </Grid>
      <ScannerConnectDescriptionDialog
        open={openQrDetailsDialog}
        handleClose={handleCloseQrDetailsDialog}
      />
    </Grid>
  );
}

export default WfsHome;
