import actionType from "./actionType";
import useAxios from "../../Axios/useAxios";

export const GetOngoingOrders = (params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      console.log("Params:", params);
      const requestUrl = `/OM/user/ongoing-orders?id=${String(
        params.id || ""
      )}`;
      console.log("Request URL:", requestUrl);
      let response = await api.get(requestUrl);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ONGOING_ORDERS,
          payload: response.data,
        });
      } else {
        console.error("Error in GetOngoingOrders Action");
      }
    } catch (error) {
      console.error("API call error:", error); // Log the error for more details
    }
  };
};

export const GetPendingAmount = (params) => {
  let api = useAxios();

  return async (dispatch) => {
    dispatch({ type: actionType.GET_PENDING_AMOUNT_REQUEST });

    try {
      let response = await api.get(
        `/OM/user/pending-amount?id=${String(params.id || "")}`
      );
      console.log("Pending Amount Action Payload:", response.data);

      if (response.status === 200) {
        dispatch({
          type: actionType.GET_PENDING_AMOUNT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.GET_PENDING_AMOUNT_FAILURE,
          error: "Failed to fetch pending amount",
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_PENDING_AMOUNT_FAILURE,
        error: error.message,
      });
    }
  };
};
