import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  ordersTransactionError: false,
  ordersTransaction: [],
  waterDropsData: {},
  waterDropsError: false,
  //   depositTransactionHistory: [],
  //   depositTransactionError: false,
};

const adminTransactionReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_PAYMENT_HISTORY:
      return {
        ...state,
        ordersTransactionError: action.payload.error,
        msg: action.payload.msg,
        ordersTransaction: action.payload.data,
      };
    case actionType.GET_DEPOSIT_HISTORY:
      return {
        ...state,
        ordersTransactionError: action.payload.error,
        msg: action.payload.msg,
        ordersTransaction: action.payload.data,
      };
    case actionType.GET_WATERDROPS_TRANSACTIONS:
      return {
        ...state,
        ordersTransactionError: action.payload.error,
        msg: action.payload.msg,
        ordersTransaction: action.payload.data,
      };
    case actionType.GET_USER_WATERDROPS:
      return {
        ...state,
        waterDropsError: action.payload.error,
        msg: action.payload.msg,
        waterDropsData: action.payload.data,
      };
    case actionType.GET_WALLET_TRANSACTIONS:
      return {
        ...state,
        ordersTransactionError: action.payload.error,
        msg: action.payload.msg,
        ordersTransaction: action.payload.orders,
      };
    default:
      return state;
  }
};

export default adminTransactionReducer;
