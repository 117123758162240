/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as MuiIcons from "@mui/icons-material";
import PrimaryButton from "../../Buttons/primaryButton";
import { appColors } from "../../Assests/AppTheme/appThemeConst";
import { Box, Divider, Stack, Typography } from "@mui/material";

export default function AssignDeliverAgentViaMap({
  open,
  onClose,
  orderId,
  assignOrder,
  type,
  handlePhone,
}) {
  //Function for handling cancel order Option
  const handleClose = () => {
    onClose(false);
  };

  //function to handel add delivery agent
  const handleAddDeliveryAgent = () => {
    assignOrder(orderId?.delivery_id, orderId, true);
  };

  //function to handel map route
  const handelMapRoute = () => {
    console.log(orderId);
    let coordinates = [
      orderId?.delivery_address?.longitude,
      orderId?.delivery_address?.latitude,
    ];
    //       // Open new map page with current location and clicked location
    const url = `https://www.google.com/maps/dir/?api=1&destination=${coordinates[1]},${coordinates[0]}`;
    window.open(url, "_blank");
  };

  const handlePhoneNo = (event) => {
    event.stopPropagation();
    handlePhone(orderId);
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {type === "distributor"
            ? `Assign ${orderId?.order_id}`
            : "Order Details"}
        </DialogTitle>
        {type === "agent" && (
          <DialogContent>
            <Box sx={{ paddingLeft: "7px" }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textAlign: "start",
                    // fontWeight: "bold",
                  }}
                >
                  {orderId?.delivery_address?.contact_name},
                  {orderId?.delivery_address?.contact_phone}{" "}
                  <MuiIcons.Call
                    onClick={handlePhoneNo}
                    sx={{ fontSize: "25px", color: "blue" }}
                  />
                </Typography>
                <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
                  {orderId?.delivery_address?.address_line1},
                  {orderId?.delivery_address?.address_line2},
                  {orderId?.delivery_address?.address_line3}
                </Typography>
                {/* <Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {orderId?.delivery_address?.address_line3}
            </Typography> */}
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "start",
                      paddingTop: "1px",
                    }}
                  >
                    {orderId?.delivery_address?.landmark},
                    {orderId?.delivery_address?.service_area},
                    {orderId?.delivery_address?.pincode}{" "}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Divider orientation="horizontal" flexItem />
              <Stack
                direction={"row"}
                justifyContent="space-evenly"
                alignItems="center"
                // divider={<Divider orientation="vertical" flexItem />}
                mt={0.5}
              >
                <MuiIcons.LocalShippingOutlined />
                <Typography sx={{ fontSize: "12px", textAlign: "start" }}>
                  {orderId?.driver_profile?.fullname || orderId?.tanker_id}
                </Typography>
                <Divider orientation="vertical" flexItem />
                <MuiIcons.DepartureBoardOutlined />
                <Typography sx={{ fontSize: "12px", textAlign: "start" }}>
                  {orderId?.packaged_drinking_water?.delivery_slots
                    ? orderId?.packaged_drinking_water?.delivery_slots
                        .admin_selected_delivery_slot &&
                      orderId?.packaged_drinking_water?.delivery_slots
                        .admin_selected_delivery_slot.length
                      ? orderId?.packaged_drinking_water?.delivery_slots
                          .admin_selected_delivery_slot
                      : orderId?.packaged_drinking_water?.delivery_slots
                          .supplier_selected_delivery_slot &&
                        orderId?.packaged_drinking_water?.delivery_slots
                          .supplier_selected_delivery_slot.length
                      ? orderId?.packaged_drinking_water?.delivery_slots
                          .supplier_selected_delivery_slot
                      : "---"
                    : "---"}
                </Typography>
              </Stack>
            </Box>
          </DialogContent>
        )}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PrimaryButton
            handleButtonClick={handleClose}
            btnName="Close"
            color="red"
          />
          <PrimaryButton
            handleButtonClick={handelMapRoute}
            btnName="Direction"
            color={appColors.blueColor}
          />
          {type === "distributor" && (
            <PrimaryButton
              handleButtonClick={handleAddDeliveryAgent}
              btnName="Assign"
              color={appColors.blueColor}
            />
          )}
        </DialogActions>
        {/* <img
          alt=""
          src={closeIcon}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            height: "30px",
          }}
          onClick={handleClose}
        /> */}
      </Dialog>
    </React.Fragment>
  );
}
