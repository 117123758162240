import React from "react";
import * as Material from "@mui/material";
import { Typography } from "@mui/material";
import CustomConsole from "../../CustomConsole";

function SupplierUserStockCard(props) {
  const [displayData, setDisplayData] = React.useState([
    { header: "container material :", data: props.container_material },
    { header: "container capacity:", data: props.container_capacity },
    { header: "empty containers:", data: props.empty_containers },
    { header: "filled containers :", data: props.filled_containers },
    { header: "physically damaged:", data: props.physically_damaged_container },
    {
      header: "qr bar code damaged containers:",
      data: props.qr_barcode_damaged_container,
    },
    { header: "new containers to get:", data: props.new_containers_to_get },
    { header: "ongoing containers:", data: props.ongoing_containers },
    { header: "delivery containes:", data: props.delivery_containers },
  ]);
  React.useEffect(() => {
    setDisplayData(() => [
      { header: "container material :", data: props.container_material },
      { header: "container capacity:", data: props.container_capacity },
      { header: "empty containers:", data: props.empty_containers },
      { header: "filled containers :", data: props.filled_containers },
      {
        header: "physically damaged:",
        data: props.physically_damaged_container,
      },
      {
        header: "qr bar code damaged containers:",
        data: props.qr_barcode_damaged_container,
      },
      { header: "new containers to get:", data: props.new_containers_to_get },
      { header: "ongoing containers:", data: props.ongoing_containers },
      { header: "delivery containes:", data: props.delivery_containers },
    ]);
    CustomConsole(displayData);
    displayData?.map((content, key) => {
      CustomConsole(content.header);
      CustomConsole(content.data);
    });
  }, []);
  return (
    <Material.Box>
      <Material.Card
        elevation={24}
        sx={{
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
          },
          marginTop: "20px",
        }}
      >
        <Material.CardContent>
          {/* <Material.Box sx={{display:"flex",flexWrap:"wrap",flexDirection:"row",justifyContent:"space-around"}}>
          <Material.Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-around" }}>
            <Material.Box sx={{textAlign:"start"}}>
            <Typography sx={{fontWeight: "bold",color: "blue",}}>Container Material</Typography>
            <Typography sx={{fontWeight: "bold",color: "blue",}}>Container Capacity</Typography>
            <Typography sx={{fontWeight: "bold",color: "blue",}}>Empty Container</Typography>
            <Typography sx={{fontWeight: "bold",color: "blue",}}>Ongoing Containers</Typography>
            <Typography sx={{fontWeight: "bold",color: "blue",}}>Delivery Containers</Typography>
            </Material.Box>
            <Material.Box sx={{textAlign:"start"}}>
            <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.container_material}</Typography>
            <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.container_capacity}</Typography>
            <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.empty_containers}</Typography>
            <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.ongoing_containers}</Typography>
            <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.delivery_containers}</Typography>
            </Material.Box>
          </Material.Box>

          <Material.Box sx={{display:"flex",flexDirection:"row"}}>
            <Material.Box sx={{margin:"5px",textAlign:"start"}}>
              <Typography sx={{fontWeight: "bold",color: "blue",}}>Filled Container</Typography>
              <Typography sx={{fontWeight: "bold",color: "blue",}}>Physically Damaged Containers</Typography>
              <Typography sx={{fontWeight: "bold",color: "blue",}}>Qr/BarCode Damage Containers</Typography>
              <Typography sx={{fontWeight: "bold",color: "blue",}}>New Containers to Get</Typography>
            </Material.Box>
            <Material.Box sx={{margin:"5px",textAlign:"start"}}>
              <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.filled_containers}</Typography>
              <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.physically_damaged_container}</Typography>
              <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.qr_barcode_damaged_container}</Typography>
              <Typography sx={{color: "black",fontWeight: "bold"}}>:  {props.new_containers_to_get}</Typography>

            </Material.Box>
          </Material.Box>
          </Material.Box> */}

          <Material.Grid container spacing={2}>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Container Material
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.container_material}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Container Capacity
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.container_capacity}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Empty Container
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.empty_containers}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Filled Container
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.filled_containers}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Physically Damaged Containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.physically_damaged_container}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Qr/BarCode Damage Containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.qr_barcode_damaged_container}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                New Containers to Get
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.new_containers_to_get}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Ongoing Containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.ongoing_containers}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography
                sx={{ color: "blue", textAlign: "left", fontWeight: "bold" }}
              >
                Delivery Containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              : {props.delivery_containers}
            </Material.Grid>
          </Material.Grid>
        </Material.CardContent>
      </Material.Card>
    </Material.Box>
  );
}

export default SupplierUserStockCard;
