import * as React from "react";
import * as IconName from "react-icons/fc";
import * as Material from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import CustomConsole from "../../CustomConsole";
import {
  GetPostpaidOrders,
  GetPostpaidOrdersbyPhone,
} from "../Redux/Actions/adminPostpaidOrdersAction";
import Select from "@mui/material/Select";
import { Box, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  PostpaidOrder,
  NotConfirmedOrderDetails,
} from "../Redux/Actions/adminPostpaidOrdersAction";
import { ToastContainer, toast } from "react-toastify";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Phone } from "@mui/icons-material";
import "../Common/drawer.css";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import PostpaidUploadPo from "./PostpaidPoUpload";
import PostpaidOrderTableNew from "./PostpaidOrderTableNew.jsx";
import CustomConsole from "../../CustomConsole";
export default function PostpaidOrdersTable({
  postpaidList,
  setOrderDetails,
  orderDetails,
  setNotConfirmOdr,
  setCardVisible,
  cardVisible,
  setEmailPhone,
  setorderby,
  setStatus,
  orderby,
  status,
  emailPhone,
  pageNumber,
  setPageNumber,
  handelSearch,
  paginationModel,
  setPaginationModel,
}) {
  const dispatch = useDispatch();
  const postpaidOrdersData = useSelector((state) => state.postpaidOrderAdmin);
  const pendingOrderDet = useSelector((state) => state.postpaidOrderAdmin);
  const [selectedOrderId, setSelectedOrderId] = React.useState("");
  const [tableOrderBtn, setTableOrderBtn] = React.useState(false);
  const [tableOrderFlag, setTableOrderFlag] = React.useState(false);
  const [enableForBtn, setEnableForBtn] = React.useState(false);
  const [enableBakBtn, setEnableBakBtn] = React.useState(true);
  const [filterflag, setfilterflag] = React.useState(false);
  const [postpaidFinalPrice, setpostpaidFinalPrice] = React.useState(0);
  const [postpaidCharges, setpostpaidCharges] = React.useState(0);
  const [userId, setuserId] = React.useState("");
  const [postpaidInitiated, setPostpaidInitiated] = React.useState(false);
  const [searchOrderId, setSearchOrderId] = React.useState("");
  let Orderby = ["ASC ", "DESC"];
  let Status = ["ACCEPTED", "REJECTED", "PENDING", "NEW REQUEST"];
  React.useEffect(() => {
    CustomConsole(postpaidList);
  }, [postpaidList]);

  React.useEffect(() => {
    CustomConsole("----Your Details-----");
    CustomConsole(pendingOrderDet.orders);
    setNotConfirmOdr(pendingOrderDet.orders);
  }, [pendingOrderDet]);

  React.useEffect(() => {
    CustomConsole(postpaidOrdersData.postpaidOrder[0]);
    if (
      tableOrderFlag === true &&
      postpaidOrdersData.postpaidOrder.length > 0
    ) {
      setOrderDetails(postpaidOrdersData.postpaidOrder[0]);
      // postpaidOrdersData.postpaidOrder.map((data) => {
      //   CustomConsole(data);
      //   setOrderDetails(data);
      // });
    }
  }, [postpaidOrdersData]);

  React.useEffect(() => {
    CustomConsole(orderDetails);
  }, [orderDetails]);

  const handleOrderListClick = (orderId, status, price, id, charges) => {
    CustomConsole(orderId);
    CustomConsole(status);
    // if (status === "REJECTED") {
    if (
      status === "PENDING" ||
      status === "REJECTED" ||
      status === "ACCEPTED"
    ) {
      CustomConsole("inside Pending");
      setSelectedOrderId(orderId);
      dispatch(NotConfirmedOrderDetails(orderId));
      setTableOrderBtn(true);
      setCardVisible(true);
    } else {
      setSelectedOrderId(orderId);
      setTableOrderBtn(true);
      setCardVisible(true);
    }
    if (status === "NEW REQUEST") {
      setPostpaidInitiated(true);
      setpostpaidCharges(
        charges !== undefined && charges !== null ? charges : 0
      );
      setpostpaidFinalPrice(price);
      setuserId(id);
      setSelectedOrderId(orderId);
    }
    // }
  };

  React.useEffect(() => {
    if (tableOrderBtn === true) {
      dispatch(PostpaidOrder(selectedOrderId));
      setTableOrderFlag(true);
      setTableOrderBtn(false);
    }
  }, [tableOrderBtn]);

  const handleViewDetails = () => {
    if (searchOrderId.length) {
      // selectedOrderId("BTORD-" + searchOrderId)
      // setTableOrderBtn(true);
      dispatch(PostpaidOrder("BTORD-" + searchOrderId));
      setTableOrderFlag(true);
      setTableOrderBtn(false);
      setCardVisible(true);
    }
    // setSearchOrderId("");
  };

  const handleorderChange = (e) => {
    setorderby(e.target.value);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleEmailPhoneChange = (event) => {
    CustomConsole(event.target.value);
    setEmailPhone(event.target.value);
  };
  const handelFilter = (e) => {
    if (filterflag === false) {
      setfilterflag(true);
    } else {
      setfilterflag(false);
    }
  };
  const handelfilterSubmit = () => {
    // if (status !== "" && emailPhone !== "") {
    setPageNumber(1);
    let settlement = false;
    dispatch(GetPostpaidOrders(emailPhone, orderby, status, 0, settlement));
    // } else {
    //   toast.error("Please fill all details", {
    //     position: "bottom-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }
    setEnableForBtn(false);
    setPageNumber(1);
  };
  const pageChangeForward = () => {
    CustomConsole(pageNumber);
    if (postpaidList.length > 0) {
      setPageNumber(pageNumber + 1);
    }
    if (pageNumber - 1 >= 1) {
      setEnableBakBtn(false);
    }
    postpaidList.length < 10 ? setEnableForBtn(true) : setEnableBakBtn(false);
  };
  React.useEffect(() => {
    let settlement = false;
    dispatch(
      GetPostpaidOrders(
        emailPhone,
        orderby,
        status,
        pageNumber - 1,
        settlement,
        paginationModel.pageSize
      )
    );
  }, [pageNumber, paginationModel.pageSize]);
  const pageChangeBackward = () => {
    CustomConsole(pageNumber);
    if (pageNumber - 1 >= 1) {
      setPageNumber(pageNumber - 1);
      setEnableForBtn(false);
    }
    if (pageNumber - 1 <= 1) {
      setEnableBakBtn(true);
    }
  };
  const handelallOrders = () => {
    // window.location.reload(true);
    dispatch(GetPostpaidOrders("", "", "", "", false));
    setEnableForBtn(false);
    setPageNumber(1);
    setorderby("");
    setStatus("");
  };
  const handlePhoneChange = (event) => {
    // Get the value entered in the text field
    const inputValue = event.target.value;

    // Remove any non-numeric characters using regular expression
    const numericValue = inputValue.replace(/\D/g, "");

    // Update the value in the text field
    event.target.value = numericValue;

    setSearchOrderId(() => event.target.value);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          // flexDirection: "row",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ padding: { xs: 2 } }}>
          <Material.TextField
            label="Search By Order Id"
            value={searchOrderId}
            // onChange={(event) => setSearchOrderId(event.target.value)}
            InputProps={{
              startAdornment: (
                <Material.InputAdornment position="start">
                  <Material.Typography
                    sx={{ fontWeight: "bold", color: "black" }}
                  >
                    BTORD-
                  </Material.Typography>
                </Material.InputAdornment>
              ),
              endAdornment: (
                <Material.InputAdornment position="end">
                  <IconName.FcSearch
                    cursor="pointer"
                    size="30px"
                    onClick={handleViewDetails}
                  />
                </Material.InputAdornment>
              ),
              style: { borderRadius: "50px", marginRight: "1em" },
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handlePhoneChange, // Attach the input event handler
            }}
          />
        </Box>
        <Box sx={{ margin: "10px", marginTop: "28px" }}>
          <Button
            variant="contained"
            onClick={handelFilter}
            size="small"
            sx={{
              background: appColors.commonBtnColor.background,
              marginRight: "1em",
            }}
          >
            Filter
          </Button>
        </Box>
        <Box sx={{ margin: "10px", marginTop: "28px" }}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handelSearch}
            sx={appColors.commonBtnColor}
          >
            Settle Orders
          </Button>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageChangeBackward}
                // disabled={enableBakBtn}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                // marginLeft: "10px",
                // marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageChangeForward}
                disabled={enableForBtn}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {filterflag === true ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection:
                cardVisible === false
                  ? { lg: "row", md: "column", sm: "column", xs: "column" }
                  : "column",
              justifyContent: "space-around",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            <Box sx={{ marginBottom: "1em" }}>
              <TextField
                id="outlined-basic"
                label="Phone No"
                variant="outlined"
                type="number"
                size="small"
                value={emailPhone}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handleEmailPhoneChange}
              />
            </Box>
            <Box sx={{ marginBottom: "1em" }}>
              <FormControl sx={{ minWidth: "15em" }}>
                <InputLabel>Order by</InputLabel>
                <Select
                  value={orderby}
                  size="small"
                  label="Payment method"
                  onChange={handleorderChange}
                >
                  {Orderby.map((data, key) => {
                    return (
                      <MenuItem key={key + 1} value={data}>
                        {data}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: "15em" }}>
                <InputLabel>Order status</InputLabel>
                <Select
                  value={status}
                  size="small"
                  label="Payment status"
                  onChange={handleStatusChange}
                >
                  {Status.map((data, key) => {
                    return (
                      <MenuItem key={key + 1} value={data}>
                        {data}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Button
            variant="contained"
            onClick={handelfilterSubmit}
            size="small"
            sx={{
              background: appColors.commonBtnColor.background,
              marginRight: "1em",
            }}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            onClick={handelallOrders}
            color="success"
            size="small"
          >
            All Orders
          </Button>
        </>
      ) : (
        ""
      )}

      <PostpaidOrderTableNew
        postpaidList={postpaidList}
        handleOrderListClick={handleOrderListClick}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        pageNumber={pageNumber}
      />

      <PostpaidUploadPo
        postpaidInitiated={postpaidInitiated}
        setPostpaidInitiated={setPostpaidInitiated}
        postpaidFinalPrice={postpaidFinalPrice}
        selectedOrderId={selectedOrderId}
        postpaidCharges={postpaidCharges}
        userId={userId}
        status={status}
        pageNumber={pageNumber}
        orderby={orderby}
        emailPhone={emailPhone}
      />
      <ToastContainer />
    </Box>
  );
}
