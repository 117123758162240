import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CustomConsole from "../../CustomConsole";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function AdminSearchUserCard(props) {
  const handleSearchClick = () => {
    props.setSearchBtnFlag(true);
    props.setShowSettlementData(false);
  };

  const inputRegex = /^[0-9\b]+$/;
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={12} md={12}>
        <Card elevation={10}>
          <CardContent>
            <Box
            // sx={{
            //   display: "flex",
            //   justifyContent: "space-around",
            //   alignItems: "center",
            // }}
            >
              <Box>
                <FormControl>
                  <FormLabel id="controlled-radio-buttons-group">
                    Select Type
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="controlled-radio-buttons-group"
                    name="Select User"
                    value={props.userRadio}
                    row
                    onChange={(e) => {
                      props.setUserRadio(e.target.value);
                    }}
                  >
                    {/* <FormControlLabel
                      value="Details"
                      control={<Radio />}
                      label="Settlement"
                    /> */}
                    <FormControlLabel
                      value="settlement_request"
                      control={<Radio />}
                      label="Settlement Requested"
                      // disabled={true}
                    />
                    <FormControlLabel
                      value="Settlement History"
                      control={<Radio />}
                      label="Settlement History"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                {props.userRadio === "settlement_request" ? null : (
                  <TextField
                    label="Enter Phone Number"
                    size="small"
                    variant="outlined"
                    // onKeyDown={(e) => {
                    //   if (e.key !== "Backspace" && !inputRegex.test(e.key)) {
                    //     e.preventDefault(); // Prevents input of non-numeric characters
                    //   }
                    // }}
                    inputProps={{
                      pattern: "[0-9]*", // For browser validation
                      maxLength: 10, // Limits input to 10 characters
                    }}
                    value={props.phoneNumber}
                    onChange={(e) => {
                      props.setPhoneNumber(e.target.value);
                      CustomConsole(e.target.value);
                    }}
                  />
                )}
                {props.userRadio === "Settlement History" && (
                  <>
                    <Box sx={{ padding: "10px", marginTop: "10px" }}>
                      <FormControl sx={{ minWidth: 210 }}>
                        <InputLabel id="demo-simple-select-label">
                          User Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={props.selectedUserType}
                          label="User Type"
                          onChange={(e) =>
                            props.setSelectedUserType(e.target.value)
                          }
                        >
                          {/* <MenuItem value={1002}>WFS</MenuItem>
                          <MenuItem value={2000}>Distributor</MenuItem> */}
                          <MenuItem value={3000}>Influencer</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            {props.userRadio === "Settlement History" && (
              <Box
                sx={{
                  float: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AdminSearchUserCard;
