import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import pairQr from "./../Assests/Images/scannerLinkQR1.png";

function ScannerConnectDescriptionDialog(props) {
  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: "bold", textAlign: "start" }}
          >
            Scan the QR For Pairing with the QR/Barcode Scanner Device
          </Typography>
          <Box sx={{border:"5px solid red", borderRadius:"10px", marginTop:"10px"}}>
          <img
            src={pairQr}
            alt={"Scanner Pairing QR"}
            style={{
              height: "150px",
              width: "150px",
              backgroundColor: "black",
            }}
          />

          </Box>

          <Typography
            sx={{ fontSize: "16px", fontWeight: "bold", textAlign: "start" , marginTop:"20px"}}
          >
            Follow the below steps for pairing:
          </Typography>
          <ul>
            <li>
              Scan the setting code of "Bluetooth HID Pairing", Bluetooth
              pairing exits after one minute.
            </li>
            <li>
              Open Bluetooth device and search for Bluetooth name: "Barcode
              Bluetooth HID".
            </li>

            <li>
              Click the Bluetooth pairing to complete.
            </li>
            <li>
              Open a text document to test the uploaded data.
            </li>
          </ul>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScannerConnectDescriptionDialog;
