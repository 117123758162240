/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import Html5QrcodePlugin1 from "../../../Pages/wfsHome/HtmlQrcodePlugin1";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";

import CustomConsole from "../../../CustomConsole";
import { GetAvailableProducts } from "../../../Pages/Redux/Actions/SKUProductDetailsAction";
import OrderButtonSku from "../../Buttons/orderButton";
function WarehouseStockScaning({
  openCameraDialog,
  onMoveClicked,
  scanType,
  isDistributor,
  setOpenCameraDialog,
}) {
  const [scannedQrCode, setScannedQrCode] = useState("");
  const [scannedBarCode, setScannedBarCode] = useState("");
  const [totalScanned, setTotalScanned] = useState(0);
  const [linkingDialogOpen, setLinkingDialogOpen] = useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = useState(false);
  const [qrlessAvailableProducts, setQrlessAvailableProducts] = useState([]);
  const [finalValue, setFinalValue] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [validUserInput, setValidUserInput] = useState();
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = useState("");
  const [scanOption, setScanOption] = useState("scanner");
  const [ValidatedValidContainers, setValidatedValidContainers] = useState();
  const [value, setValue] = useState(1);
  const [orderQrLessParams, setOrderQrLessParams] = useState({
    container_material: "",
    container_capacity: "",
    order_qty: 1,
    empty_qty: 1,
  });

  const [qrBarcodeId, setQrBarcodeId] = useState("");
  const bulkTabValue = 0;
  const singleTabValue = 1;
  const QrLessTabValue = 3;
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const dispatch = useDispatch();

  CustomConsole(supplierDeliveries.warehouseStockMovement);
  useEffect(() => {
    supplierDeliveries.warehouseStockMovement = {};
    if (openCameraDialog) {
      dispatch(GetAvailableProducts());
    }
  }, [openCameraDialog]);
  useEffect(() => {
    if (skuContainerDetails?.getAvailableProducts.length > 0) {
      let qrLessProducts = skuContainerDetails?.getAvailableProducts?.filter(
        (data) => data.product_details.is_qr === false || true //Allowing Qr products and qrless products for warehouse Scanning
      );
      //@Removed/console.log(qrLessProducts);
      setQrlessAvailableProducts(qrLessProducts);
    }
  }, [skuContainerDetails]);
  useEffect(() => {
    let stocks = [];
    supplierDeliveries?.warehouseStockMovement?.validated_qrs?.map((item) => {
      if (item.is_valid_scan) {
        stocks.push(item.qr_id);
      }
    });
    if (supplierDeliveries?.warehouseStockMovement?.is_proper_scan) {
      setTotalScanned(
        (prev) =>
          prev +
          supplierDeliveries?.warehouseStockMovement?.validated_qrs?.length
      );
    }
    setValidatedValidContainers(stocks);
    return () => {
      supplierDeliveries.warehouseStockMovement = {};
    };
  }, [supplierDeliveries]);

  useEffect(() => {
    //Scanned Qr Code
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    //Scanned Bar Code
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  const onNewScanResult = (decodedText, decodedResult) => {
    setQrBarcodeId(decodedText);
    setFinalValue(decodedText);
  };
  const handleQrBarcodeIdFromScanner = (event) => {
    let value = event.target.value;
    setQrBarcodeId(value);
  };

  const hadleBulkValidate = () => {
    onMoveClicked({ qrCodes: scannedQrCodeDetails, validate: true });
  };

  useEffect(() => {
    if (qrBarcodeId) {
      setTimeout(() => {
        if (
          finalValue === qrBarcodeId &&
          qrBarcodeId.length &&
          qrBarcodeId.length > 6
        ) {
          if (value === singleTabValue) {
            onMoveClicked({ qrCodes: [qrBarcodeId], type: "single" });
            setFinalValue("");
            setQrBarcodeId("");
          } else {
            const setArray = new Set(scannedQrCodeDetails);
            setArray.add(qrBarcodeId);
            CustomConsole(scannedQrCodeDetails, Array.from(setArray));
            if (scannedQrCodeDetails.length === Array.from(setArray).length) {
              //
            }
            setScannedQrCodeDetails(Array.from(setArray));
          }
          setFinalValue("");
          setQrBarcodeId("");
        }
      }, 500);
    }
  }, [qrBarcodeId, finalValue]);

  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
    } else {
    }
  };

  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    supplierDeliveries.warehouseStockMovement = {};
    setValue(newValue);
  };
  const handleSelectedProductData = (data) => {
    setSelectedProduct(data.target.value);
    const product = data.target.value;
    let duplicateElement = skuContainerDetails?.getAvailableProducts?.find(
      (data) => data.product_details.id === product
    );
    // setProductDate(duplicateElement);
    // setProductName(duplicateElement.product_details.name);
    // setCrateProduct(duplicateElement.crate_details);
    if (Object.keys(duplicateElement).length > 0) {
      setOrderQrLessParams((prev) => ({
        ...prev,
        container_material: duplicateElement.product_details.material,
        container_capacity: duplicateElement.product_details.capacity,
        order_qty: 0,
        empty_qty: 0,
      }));
    }
  };

  const moveBulkValid = () => {
    setScannedQrCodeDetails(ValidatedValidContainers);
    onMoveClicked({ qrCodes: ValidatedValidContainers });
  };
  const handelQuantityEmptyChange = (value) => {
    setOrderQrLessParams((prev) => ({
      ...prev,
      empty_qty:
        value >= 0 && value <= 999
          ? parseInt(value || 0)
          : parseInt(prev.empty_qty || 0),
      // value >= 0 && value <= 999 ? value : prev.order_qty,
    }));
  };

  const handelQuantityRequriedChange = (value) => {
    setOrderQrLessParams((prev) => ({
      ...prev,
      order_qty:
        value >= 0 && value <= 999
          ? parseInt(value || 0)
          : parseInt(prev.order_qty || 0),
    }));
  };

  const handleSubmitQrless = () => {
    onMoveClicked({
      qr_less: {
        material: orderQrLessParams.container_material,
        capacity: orderQrLessParams.container_capacity,
        filled: orderQrLessParams.order_qty,
        empty: orderQrLessParams.empty_qty,
      },
    });
    setOpenCameraDialog(false);
  };
  return (
    <Box>
      <Dialog fullWidth open={openCameraDialog}>
        <DialogContent sx={{ width: "100%", height: "700px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              width: "100%",
              // padding: "10px",
            }}
          >
            {scanType.charAt(0).toUpperCase() + scanType.slice(1)} Scan
            <Tabs value={value} onChange={handleChange} centered>
              {/* <Tab
                value={bulkTabValue}
                label="Link bulk"
                // disabled={inScannedNo !== bulkTabValue || outScannedNo !== bulkTabValue}
              /> */}
              <Tab value={singleTabValue} label="Scan" />
              <Tab value={QrLessTabValue} label="Qr Less" />
            </Tabs>
            {value === singleTabValue ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FormControl sx={{ marginTop: "15px" }}>
                  <FormLabel>Select Scanning Option</FormLabel>
                  <RadioGroup
                    defaultValue="scanner"
                    value={scanOption}
                    name="radio-buttons-group"
                    sx={{ display: "flex", flexDirection: "row" }}
                    onChange={handleSelectScanOption}
                  >
                    <FormControlLabel
                      value="scanner"
                      control={<Radio />}
                      label="Scanner Device"
                    />

                    <FormControlLabel
                      value="Scan all and Link"
                      control={<Radio />}
                      label="Camera"
                    />
                  </RadioGroup>
                </FormControl>
                {isDistributor && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        padding: "10px",
                      }}
                    >
                      {value === bulkTabValue ? (
                        <Chip
                          label={`No of Scanned: ${scannedQrCodeDetails.length}`}
                          color="success"
                        />
                      ) : (
                        ""
                      )}

                      {value === singleTabValue &&
                        (supplierDeliveries.warehouseStockMovement
                          ?.is_proper_scan === true ? (
                          <Chip
                            label={`${totalScanned || ""} Scan is Successful `}
                            color="success"
                          />
                        ) : supplierDeliveries.warehouseStockMovement
                            ?.is_proper_scan === false ? (
                          <Chip
                            label={
                              supplierDeliveries.warehouseStockMovement
                                ?.validated_qrs?.[0]?.msg
                            }
                            color="error"
                          />
                        ) : (
                          ""
                        ))}
                    </Box>
                  </>
                )}
                {
                  // !All Scaning options

                  value === singleTabValue ||
                  !(
                    (value === bulkTabValue &&
                      supplierDeliveries.warehouseStockMovement?.validated_qrs
                        ?.length) ||
                    supplierDeliveries.warehouseStockMovement?.complete_scan
                  ) ? (
                    // All the scanning options before bulk scan or while in single scan
                    <>
                      {scanOption === "scanner" ? (
                        <>
                          <Box
                            sx={{
                              marginTop: "10px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                              padding: "10px",
                            }}
                          >
                            <form
                              onSubmit={(event) => {
                                event.preventDefault(); // Prevent default form submission
                                setFinalValue(qrBarcodeId);
                                // alert(`Value entered: ${qrBarcodeId}`);
                              }}
                            >
                              {/* Form element wrapping the TextField */}
                              {!finalValue && (
                                <TextField
                                  disabled={finalValue ? true : false}
                                  // focused={finalValue == ""}
                                  autoFocus
                                  label="QR/Barcode:"
                                  onChange={handleQrBarcodeIdFromScanner}
                                  // autoFocus
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={qrBarcodeId}
                                  // onChange={handleChange} // Listen for changes in TextField
                                />
                              )}
                              <Button
                                type="submit"
                                style={{ display: "none" }}
                              />{" "}
                              {/* Hidden submit button */}
                            </form>
                          </Box>
                        </>
                      ) : (
                        <>
                          {/* Bulk Scaning  */}
                          {scanOption && (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <Html5QrcodePlugin1
                                  fps={1}
                                  qrbox={200}
                                  disableFlip={false}
                                  qrCodeSuccessCallback={onNewScanResult}
                                />
                              </Box>
                            </>
                          )}
                        </>
                      )}
                      {value === bulkTabValue && (
                        <Button
                          onClick={hadleBulkValidate}
                          variant="contained"
                          sx={{ marginTop: "10px" }}
                        >
                          Validate
                        </Button>
                      )}
                    </>
                  ) : !supplierDeliveries.complete_scan ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        {supplierDeliveries.warehouseStockMovement?.validated_qrs?.map(
                          (item, index) => {
                            return !item.is_valid_scan ? (
                              <Chip label={index + 1} color="error" />
                            ) : (
                              <Chip
                                label={`${index + 1} ${item.is_full ? "" : ""}`}
                                color="success"
                              />
                            );
                          }
                        )}
                      </Box>
                      {!supplierDeliveries.warehouseStockMovement
                        .is_proper_scan ? (
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {ValidatedValidContainers?.length &&
                          validUserInput ===
                            ValidatedValidContainers?.length ? (
                            <Chip
                              label={"Some Containers are not Valid"}
                              color="error"
                            />
                          ) : (
                            <Chip
                              label={"No valid Containers Found"}
                              color="error"
                            />
                          )}
                          {/* <Box>
                        Are you sure you want to continue?
                      </Box>
                      <TextField
                        onChange={(e) => {
                          setValidUserInput(e.target.value);
                        }}
                        autoFocus
                        label="Number of valid:"
                      /> */}
                          <Button
                            disabled={!ValidatedValidContainers?.length}
                            onClick={moveBulkValid}
                            variant="contained"
                          >
                            Link
                          </Button>
                        </Box>
                      ) : (
                        <Button onClick={moveBulkValid} variant="contained">
                          Link
                        </Button>
                      )}
                    </Box>
                  ) : (
                    "Scanning is Succesful"
                  )
                }
              </Box>
            ) : value === QrLessTabValue ? (
              <Box>
                <FormControl sx={{ width: 220 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Select Product
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedProduct}
                    label="Select Product"
                    onChange={handleSelectedProductData}
                    sx={{
                      color: "black",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "gray",
                      },
                    }}
                  >
                    {qrlessAvailableProducts?.map((option) => (
                      <MenuItem
                        key={option.product_details.id}
                        value={option.product_details.id}
                      >
                        {option.product_details.name}/Material:
                        {option.product_details.material}/Capacity:
                        {option.product_details.capacity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedProduct && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                          gap: "20px",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography>Filled (Units)&nbsp;&nbsp;</Typography>
                        <OrderButtonSku
                          handelAddQuantity={
                            () =>
                              handelQuantityRequriedChange(
                                parseInt(orderQrLessParams.order_qty) + 1
                              )
                            // setOrderQrLessParams((prev) => ({
                            //   ...prev,
                            //   filled:
                            //     orderQrLessParams.filled + 1 < 999
                            //       ? orderQrLessParams.filled + 1
                            //       : orderQrLessParams.filled,
                            // }))
                          }
                          handelMinusQuantity={() =>
                            handelQuantityRequriedChange(
                              parseInt(orderQrLessParams.order_qty) - 1
                            )
                          }
                          handelQuantityChange={(event) =>
                            handelQuantityRequriedChange(event.target.value)
                          }
                          inputValue={orderQrLessParams.order_qty}
                          // disabled={true}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          marginTop: "1rem",
                          gap: "15px",
                        }}
                      >
                        <Typography>Empty (Units)&nbsp;&nbsp;</Typography>
                        <OrderButtonSku
                          handelAddQuantity={() =>
                            handelQuantityEmptyChange(
                              parseInt(orderQrLessParams.empty_qty) + 1
                            )
                          }
                          handelMinusQuantity={() =>
                            handelQuantityEmptyChange(
                              parseInt(orderQrLessParams.empty_qty) - 1
                            )
                          }
                          handelQuantityChange={(event) =>
                            handelQuantityEmptyChange(
                              event.target.value,
                              "Empty Can"
                            )
                          }
                          inputValue={orderQrLessParams.empty_qty}
                          // disabled={true}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        mt: "50px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {orderQrLessParams.empty_qty ||
                      orderQrLessParams.order_qty ? (
                        <Button
                          variant="contained"
                          onClick={handleSubmitQrless}
                        >
                          submit
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          ></Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            onClick={() => setOpenCameraDialog(false)}
            variant="outlined"
            size="small"
            color="error"
          >
            Close
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
}

export default WarehouseStockScaning;
