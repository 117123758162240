import React from "react";
import * as Material from "@mui/material";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import SampleButton from "../Buttons/SampleButton";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteDriver,
  DeleteDriverAdmin,
  GetSupplierDeliveryAgentDetails,
} from "../../Pages/Redux/Actions/supliersAction";
import CustomConsole from "../../CustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

function DeliveryAgentListCard({
  operationType,
  onRefresh,
  user_id,
  deliver_Id,
  ...props
}) {
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);

  const [openDilaog, setOpenDialog] = React.useState(false);
  const [reloadFlagDel, setReloadFlagDel] = React.useState(false);

  CustomConsole(props);
  const handleUpdateDeliveryAgent = () => {
    CustomConsole("-------handleUpdateAgent---------");
    props.handleUpdateDeliveryAgent(props);
  };
  const [agentData, setAgentData] = React.useState([
    { header: "fullname :", data: props.fullname },
    { header: "phone :", data: props.phone },
  ]);

  React.useEffect(() => {
    setAgentData(() => [
      { header: "fullname :", data: props.fullname },
      { header: "phone :", data: props.phone },
    ]);
    agentData?.map((content, key) => {
      CustomConsole(content.header);
      CustomConsole(content.data);
    });
  }, []);
  const handleDeleteDriver = () => {
    props.handleDeleteDriver();
  };

  const deleteAgent = (id) => {
    CustomConsole(id);
    if (props.isAdmin) {
      dispatch(DeleteDriverAdmin(user_id)).then(() => {
        onRefresh();
      });
      // setTimeout(props.handleUpdate(), 500);
    } else {
      dispatch(DeleteDriver(user_id)).then(() => {
        onRefresh();
      });
      setReloadFlagDel(true);
    }
  };

  React.useEffect(() => {
    CustomConsole("----reload the api---for drivers");
    CustomConsole(supplierDeliveries.error);
    CustomConsole(reloadFlagDel);
    CustomConsole(props.isAdmin);
    if (props.isAdmin && !supplierDeliveries.error && reloadFlagDel) {
      props.handleUpdate();
      setReloadFlagDel(false);
    }
    if (!props.isAdmin && !supplierDeliveries.error && reloadFlagDel) {
      dispatch(GetSupplierDeliveryAgentDetails({ employee_type: 2 }));

      setReloadFlagDel(false);
    }
  }, [supplierDeliveries]);

  React.useEffect(() => {}, [supplierDeliveries]);

  return (
    <Material.Box sx={{ margin: "10px" }}>
      <Material.Card
        elevation={24}
        sx={{
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
          },
        }}
      >
        <Material.CardContent>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-around",
              flexWrap: "wrap",
              gap: "20px",
              marginTop: { xs: "10px" },
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: { md: "40px", xs: "5px" },
                flexWrap: "wrap",
              }}
            >
              <Material.Typography sx={{ fontWeight: "bold", color: "blue" }}>
                {operationType?.name} Name :{" "}
                <span style={{ fontWeight: "bold", color: "black" }}>
                  {props.fullname}
                </span>
              </Material.Typography>
              <Material.Typography sx={{ fontWeight: "bold", color: "blue" }}>
                {operationType?.name} phone :{" "}
                <span style={{ fontWeight: "bold", color: "black" }}>
                  {props.phone}
                </span>
              </Material.Typography>
            </Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: { md: "40px", xs: "5px" },
              }}
            >
              <SampleButton
                title="Update"
                handleClick={handleUpdateDeliveryAgent}
              />

              <Material.Button
                variant="contained"
                color="error"
                onClick={() => setOpenDialog(true)}
              >
                Delete
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.CardContent>
      </Material.Card>
      <Material.Dialog
        open={openDilaog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialog(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <Material.DialogContent>
          <Material.DialogContentText id="alert-dialog-slide-description">
            Delete the user?
          </Material.DialogContentText>
        </Material.DialogContent>
        <Material.DialogActions>
          <Material.Button onClick={() => setOpenDialog(false)}>
            NO
          </Material.Button>
          <Material.Button onClick={() => deleteAgent(props.userId)}>
            YES
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </Material.Box>
  );
}

export default DeliveryAgentListCard;
