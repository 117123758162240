import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CustomConsole from "../../CustomConsole";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";

function FilterPromo({
  filterDialogFlag,
  setFilterDialogFlag,
  setPromoCreatedAt,
  setPromoExpired,
  clickFilterPromoDetails,
  setPromoCodeType,
  promoCodeType,
  setPromoOfferType,
  promoOfferType,
  promoCreatedAt,
  promoExpired,
}) {
  const [promoOffertypeappMea, setPromoOffertypeappMea] = React.useState([]);
  const [promoCodeTypeappMeta, setPromoCodeTypeappMeta] = React.useState([]);
  const bookingDetails = useSelector((state) => state.adminBookingData);
  CustomConsole(bookingDetails);

  const handleClose = () => {
    setFilterDialogFlag(false);
  };

  const clickPromotype = (event) => {
    setPromoCodeType(event.target.value);
  };

  const clickPromoOffertype = (event) => {
    setPromoOfferType(event.target.value);
  };

  const clickFilter = () => {
    setFilterDialogFlag(false);
    clickFilterPromoDetails();
    // if (filterDialogFlag === true) {
    //   setPromoOfferType(0);
    //   setPromoCodeType(0);
    // }
  };

  React.useEffect(() => {
    bookingDetails.setupConfigData.map((data) => {
      CustomConsole(data);
      if (data.key === "promo_offer_types") {
        setPromoOffertypeappMea(data.value);
      }
      if (data.key === "promo_code_types") {
        setPromoCodeTypeappMeta(data.value);
      }
    });
  }, [bookingDetails]);

  return (
    <Box>
      <Dialog
        open={filterDialogFlag}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box>
            <Box sx={{ marginTop: "20px" }}>
              <FormControl sx={{ minWidth: "210px", marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Promo Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={promoCodeType}
                  label="Promo  Type"
                  onChange={clickPromotype}
                >
                  {promoCodeTypeappMeta.map((data) => {
                    return <MenuItem value={data.k}>{data.v}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <FormControl sx={{ minWidth: "210px", marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Promo Offer Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={promoOfferType}
                  label="Promo offer Type"
                  onChange={clickPromoOffertype}
                >
                  {promoOffertypeappMea.map((data) => {
                    return <MenuItem value={data.k}>{data.v}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <FormLabel id="demo-radio-buttons-group-label">
            Promo Created At
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={promoCreatedAt}
            onChange={(event) => setPromoCreatedAt(event.target.value)}
          >
            <FormControlLabel
              label="Decending"
              value="DESC"
              control={<Radio />}
            />
            <FormControlLabel
              value="ASEC"
              control={<Radio />}
              label="Ascending"
            />
          </RadioGroup>

          <Box>
            <FormLabel id="demo-radio-buttons-group-label">
              Expired OR Un-Expired
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={promoExpired}
              onChange={(event) => setPromoExpired(event.target.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Expired"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="UnExpired"
              />
            </RadioGroup>
            <Box sx={{ margin: "30px" }}>
              <Button variant="contained" color="primary" onClick={clickFilter}>
                Filter
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default FilterPromo;
