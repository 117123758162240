/**
 * @author vinay kumar
 * @description Addressusers Page
 * @copyright Bookwater tech pvt ltd
 * @
 */
// This UI Complete ReDesign Done by Vinay
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import CustomConsole from "../../CustomConsole";
import {
  AddUserAddressAdmin,
  DeleteUserAddress,
  EditUserAddress,
  GetAccountsDetails,
  GetUserAddressAdmin,
} from "../Redux/Actions/userDetailsAdminAction";
import { SearchAreasByPincode } from "../Redux/Actions/adminBookingAction";
import { useSelector } from "react-redux";

import EditAddressDialog from "./EditAddressDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";

import { FormControl, Grid, InputLabel, Select } from "@mui/material";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { UsersStyles } from "./UsersStyles";
import * as IconName from "react-icons/fc";
import AddAddressDialog from "./AddAddressDialog";
import DriveFileRenameOutlineOutlined from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { makeStyles } from "@mui/styles";
import AddUpdateConsumerProductPrice from "../../Components/Dialog/ProductDiscountCustomer";

const useStyles = makeStyles((theme) => ({
  customBadge: {
    width: "100px", // Set your desired width
    height: "60px", // Set your desired height
    borderRadius: "20%", // For a circular badge
    backgroundColor: "red", // Set your desired background color
    color: "white", // Set your desired text color
    fontSize: "14px", // Set your desired font size
    alignItems: "center",
  },
}));
function AddressUsers({ id, userRoleId, userIdA }) {
  const dispatch = useDispatch();
  const skuCoustomProductDetails = useSelector(
    (state) => state.skuCoustomProductDetails
  );
  const classes = useStyles();
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate);
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const [adminRole, setAdminRoleId] = React.useState("");
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const delivery = [
    {
      value: "Delivery Time (6 AM- 6 PM)",
    },
    {
      value: "24 hours",
    },
  ];

  const [addressBtn, setAddressBtn] = React.useState(false);
  const [deliveryTime, setDeliveryTime] = React.useState("");
  const [name, setName] = React.useState("");
  const [mobileNum, setMobileNum] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [addressLine3, setAddressLine3] = React.useState("");
  const [landmark, setLandmark] = React.useState("");
  const [pincode, setPincode] = React.useState("");

  const [isEditAddressDialogOpen, setIsEditAddressDialogOpen] =
    React.useState(false);
  const [isPrimaryAddress, setIsPrimaryAddress] = React.useState(true);
  const [address, setAddress] = React.useState([]);

  const [userId, setUserId] = React.useState("");

  const [deleteUserBtn, setDeleteUserBtn] = React.useState(false);
  const [saveAddressFlag, setSaveAddressFlag] = React.useState(false);
  const [isPhoneProper, setisPhoneProper] = React.useState(true);
  //current change
  const [availableAreasFlag, setAvailableAreasFlag] = React.useState(false);
  const [areasAvailable, setAvailableArea] = React.useState(null);
  const [selectArea, setSelectArea] = React.useState(false);
  const [areaValue, setAreaValue] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [customPrice, setCustomPrice] = React.useState("");
  const bookingDetails = useSelector((state) => state.adminBookingData);
  //usestate for adding product details
  const [conformAddProduct, setConformAddProduct] = React.useState(false);
  const [productData, setProductData] = React.useState([]);
  const [addressId, setAddressId] = React.useState("");
  CustomConsole(productData);
  React.useEffect(() => {
    if (addUserAdminDetails.error === false) {
      addUserAdminDetails.users.map((data) => {
        CustomConsole("hitting address api in address card");
        dispatch(GetUserAddressAdmin(data.id));
        setUserId(data.id);
      });
    }
  }, [addUserAdminDetails, saveAddressFlag, deleteUserBtn]);

  React.useEffect(() => {
    CustomConsole(bookingDetails);
    if (availableAreasFlag && bookingDetails.availableAreas.length > 0) {
      const area = bookingDetails.availableAreas[0].Name;
      CustomConsole(area);
      setAreaValue(area);

      setAvailableArea(bookingDetails.availableAreas);
      setAvailableAreasFlag(false);
      setSelectArea(true);
    }

    // CustomConsole("in address card");
    // CustomConsole(id);
    // dispatch(GetUserAddressAdmin(id));
  }, [bookingDetails]);

  React.useEffect(() => {
    dispatch(GetAccountsDetails(userIdA));
  }, []);

  React.useEffect(() => {
    CustomConsole(userAddressAdmin);
    userAddressAdmin.accounts.map((data) => {
      setUserId(data.user_id);
    });
    if (userAddressAdmin.error === true && saveAddressFlag === true) {
      // toast.success(userAddressAdmin.addUserAddressMsg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setSaveAddressFlag(false);
      setAddressBtn(false);
      // setDeleteUserBtn(false);
      setAddressLine1("");
      setAddressLine2("");
      setAddressLine3("");
      setName("");
      setDiscount("");
      setCustomPrice("");
      setMobileNum("");
      setDeliveryTime("");
      // setIsPrimaryAddress("");
      setLandmark("");
      setPincode("");
    }
  }, [userAddressAdmin]);

  React.useEffect(() => {
    if (
      userAddressAdmin.deleteUserAddressMsg !== "" &&
      deleteUserBtn === true
    ) {
      toast.success(userAddressAdmin.deleteUserAddressMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setDeleteUserBtn(false);
    }
  }, [userAddressAdmin]);

  const handleEditAddressSave = (data, addressId, userId) => {
    dispatch(EditUserAddress(data, addressId, userId));
  };
  const handleDeleteAddress = (data) => {
    CustomConsole(data);
    dispatch(DeleteUserAddress(data.user_id, data.address_id));
    setDeleteUserBtn(true);
  };

  const handleAddressButton = () => {
    setAddressBtn(!addressBtn);
  };

  const handleEditAddress = (data) => {
    setIsEditAddressDialogOpen(true);
    setAddress(data);
  };

  //function for handling add product dialog box
  CustomConsole(userAddressAdmin.address);
  const handelConformData = (data) => {
    const filteredProduct = data.products.filter(
      (obj) => Object.keys(obj).length !== 0
    );
    setProductData(filteredProduct);
    setAddressId(data.address_id);
    setConformAddProduct(true);
  };

  //function for calling api function
  React.useEffect(() => {
    if (!skuCoustomProductDetails.customDataErrorAddress) {
      dispatch(GetUserAddressAdmin(userId));
      skuCoustomProductDetails.customDataErrorAddress = true;
    }
    if (!skuCoustomProductDetails.deleteAddressError) {
      dispatch(GetUserAddressAdmin(userId));
      skuCoustomProductDetails.deleteAddressError = true;
    }
  }, [
    skuCoustomProductDetails.customDataErrorAddress,
    skuCoustomProductDetails.deleteAddressError,
  ]);
  return (
    <>
      {userRoleId === 3000 ? (
        <Box sx={{ overflow: "auto", maxHeight: "400px", padding: "10px" }}>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              gap: "20px",
            }}
          >
            {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
            ADMIN_ROLES.BW_ADMIN === adminRole ||
            ADMIN_ROLES.BW_CS === adminRole ||
            ADMIN_ROLES.BW_CSLEAD === adminRole ||
            ADMIN_ROLES.BW_CSHEAD === adminRole ||
            ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ background: appColors.commonBtnColor.background }}
                  onClick={handleAddressButton}
                >
                  Add address
                </Button>
              </Box>
            ) : null}
            <>
              {userAddressAdmin.address.map((data, key) => {
                return (
                  <Grid container>
                    <Grid item md={12}>
                      <Card key={key} sx={UsersStyles.cardshadow}>
                        <CardContent>
                          <Box
                            sx={{
                              margin: "10px",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              classes={{ badge: classes.customBadge }}
                              badgeContent={
                                data.is_billing_address === true
                                  ? "Billing Address"
                                  : null
                              }
                            ></Badge>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              flexWrap: "wrap",
                              gap: "10px",
                            }}
                          >
                            <Box>
                              {data.is_primary ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "20px",
                                    padding: "10px",
                                  }}
                                >
                                  <IconName.FcManager size="30px" />
                                  <Typography>Contact name : </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.contact_name}
                                    <span style={{ color: "red" }}>*</span>
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "20px",
                                    padding: "10px",
                                  }}
                                >
                                  <IconName.FcManager size="30px" />
                                  <Typography> Contact name : </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.contact_name}
                                  </Typography>
                                </Box>
                              )}
                              <Typography>
                                <IconName.FcPhoneAndroid size="30px" />
                                Contact Number : {data.contact_phone}
                              </Typography>
                              <Typography>
                                {/* <IconName.FcPhoneAndroid size="30px" /> */}
                                Custom price : {data?.custom_price}
                              </Typography>
                              <Typography>
                                {/* <IconName.FcPhoneAndroid size="30px" /> */}
                                Discount : {data?.discount}%
                              </Typography>
                              <Typography>
                                {/* <IconName.FcPhoneAndroid size="30px" /> */}
                                Cans Available : {data?.existing_cans}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <IconName.FcHome size="30px" />
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Address :
                                </Typography>{" "}
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  // padding: "10px",
                                }}
                              >
                                <Typography> Line 1 :</Typography>
                                <Typography>{data.address_line1}</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  // padding: "10px",
                                }}
                              >
                                <Typography> Line 2 : </Typography>
                                <Typography>{data.address_line2}</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  // padding: "10px",
                                }}
                              >
                                <Typography> Line 3 : </Typography>
                                <Typography>{data.address_line3}</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  // padding: "10px",
                                }}
                              >
                                <Typography> Pincode : </Typography>
                                <Typography> {data.pincode}</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  // padding: "10px",
                                }}
                              >
                                <Typography> Service Area : </Typography>
                                <Typography> {data.service_area}</Typography>
                              </Box>
                            </Box>
                          </Box>
                          {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                          ADMIN_ROLES.BW_ADMIN === adminRole ||
                          ADMIN_ROLES.BW_CS === adminRole ||
                          ADMIN_ROLES.BW_CSLEAD === adminRole ||
                          ADMIN_ROLES.BW_CSHEAD === adminRole ||
                          ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  background:
                                    appColors.commonBtnColor.background,
                                }}
                                onClick={() => handelConformData(data)}
                              >
                                <DriveFileRenameOutlineOutlined />
                                View Product
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  background:
                                    appColors.commonBtnColor.background,
                                }}
                                onClick={() => handleEditAddress(data)}
                              >
                                <DriveFileRenameOutlineOutlined />
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  background:
                                    appColors.commonBtnColor.background,
                                }}
                                onClick={() => handleDeleteAddress(data)}
                              >
                                <DeleteOutlined />
                                Delete
                              </Button>
                            </Box>
                          ) : null}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                );
              })}
            </>

            {/* {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
            ADMIN_ROLES.BW_ADMIN === adminRole ||
            ADMIN_ROLES.BW_CS === adminRole ||
            ADMIN_ROLES.BW_CSHEAD === adminRole ||
            ADMIN_ROLES.BW_OPS === adminRole ||
            ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleAddressButton}
                >
                  Add address
                </Button>
              </Box>
            ) : null} */}

            {addressBtn && (
              <>
                <AddAddressDialog
                  addressBtn={addressBtn}
                  setAddressBtn={setAddressBtn}
                  name={name}
                  setName={setName}
                  setDiscount={setDiscount}
                  setCustomPrice={setCustomPrice}
                  mobileNum={mobileNum}
                  setMobileNum={setMobileNum}
                  addressLine1={addressLine1}
                  setAddressLine1={setAddressLine1}
                  addressLine2={addressLine2}
                  setAddressLine2={setAddressLine2}
                  addressLine3={addressLine3}
                  setAddressLine3={setAddressLine3}
                  landmark={landmark}
                  setLandmark={setLandmark}
                  pincode={pincode}
                  setPincode={setPincode}
                  areaValue={areaValue}
                  discount={discount}
                  customPrice={customPrice}
                  setAreaValue={setAreaValue}
                  deliveryTime={deliveryTime}
                  setDeliveryTime={setDeliveryTime}
                  isPrimaryAddress={isPrimaryAddress}
                  setIsPrimaryAddress={setIsPrimaryAddress}
                  userIdA={userIdA}
                />
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Card
          sx={{
            "&:hover": {
              boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
            },
          }}
        >
          <CardContent>
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                No address to show
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
      <AddUpdateConsumerProductPrice
        conformAddProduct={conformAddProduct}
        setConformAddProduct={setConformAddProduct}
        userId={addressId}
        data={productData}
        area="address"
      />
      {isEditAddressDialogOpen ? (
        <>
          <EditAddressDialog
            isEditAddressDialogOpen={isEditAddressDialogOpen}
            setIsEditAddressDialogOpen={setIsEditAddressDialogOpen}
            handleEditAddressSave={handleEditAddressSave}
            address={address}
            userId={userId}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AddressUsers;
