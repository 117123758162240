import React from "react";
import * as Material from "@mui/material";
import Paper from "@mui/material/Paper";
import { Box, TextField } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { useDispatch } from "react-redux";
import { getFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import { useSelector } from "react-redux";

function WarehouseStocksCard({ warehouseDetails }) {
  const stocks = warehouseDetails.stocks;

  return (
    <Material.Box>
      <Material.Card
        elevation={24}
        sx={{
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
          },
        }}
      >
        <Material.CardContent>
          <Material.Box display={"flex"} position={"relative"}>
            <Material.Grid container>
              <Material.Grid
                item
                md={3}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 1 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>
                  Warehouse Name :
                </Material.Box>
                <Material.Box>{warehouseDetails.contact_name}</Material.Box>
              </Material.Grid>
              <Material.Grid
                item
                md={3}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 2 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>
                  Pin Code :
                </Material.Box>
                <Material.Box>{warehouseDetails.pincode}</Material.Box>
              </Material.Grid>
              <Material.Grid
                item
                md={3}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 2 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>Area : </Material.Box>
                <Material.Box>{warehouseDetails.service_area}</Material.Box>
              </Material.Grid>
              <Material.Grid
                item
                md={3}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 2 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>Phone :</Material.Box>
                <Material.Box>{warehouseDetails.contact_phone}</Material.Box>
              </Material.Grid>
            </Material.Grid>
          </Material.Box>

          <Material.Box>
            {stocks?.length > 1 || stocks?.[0]?.container_capcity != null ? (
              <Material.TableContainer component={Paper}>
                <Material.Table
                  // sx={{ minWidth: 650 }}
                  sx={{ color: "#000000" }}
                  aria-label="simple table"
                >
                  <Material.TableHead>
                    <Material.TableRow>
                      <Material.TableCell>Container</Material.TableCell>
                      <Material.TableCell align="right">
                        Filled
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        Empty
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        Outgoing Filled
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        OutGoing Empty
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        Incoming Filled
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        Incoming Empty
                      </Material.TableCell>
                    </Material.TableRow>
                  </Material.TableHead>
                  <Material.TableBody>
                    {stocks?.map((row, index) => (
                      <Material.TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Material.TableCell component="th" scope="row">
                          {row.container_capcity} {row.container_material}
                        </Material.TableCell>
                        <Material.TableCell align="right">
                          {row.filled || 0}
                        </Material.TableCell>
                        <Material.TableCell align="right">
                          {row.empty || 0}
                        </Material.TableCell>
                        <Material.TableCell align="right">
                          {row.outgoing_filled || 0}
                        </Material.TableCell>
                        <Material.TableCell align="right">
                          {row.outgoing_empty || 0}
                        </Material.TableCell>
                        <Material.TableCell align="right">
                          {row.incoming_filled || 0}
                        </Material.TableCell>
                        <Material.TableCell align="right">
                          {row.incoming_empty || 0}
                        </Material.TableCell>
                      </Material.TableRow>
                    ))}
                  </Material.TableBody>
                </Material.Table>
              </Material.TableContainer>
            ) : (
              "No Stocks found for this Warehouse"
            )}
          </Material.Box>
        </Material.CardContent>
      </Material.Card>
    </Material.Box>
  );
}

export default WarehouseStocksCard;
