import { Discount } from "@mui/icons-material";
import actionType from "../Actions/actionType";

const initialState = {
  error: true,
  msg: "",
  poData: [],
  suppliers: [],
  invoiceData: [],
  invoicePriceData: [],
  poUpdateError: true,
  poUpdateMsg: "",
  consolidatePOOrders: [],
  businessUsers: [],
  discount: 0,
};

const adminPoTrackPostpaidReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_PO_TRACK_DETAILS:
      return {
        ...state,
        poData: action.payload.data,
      };
    case actionType.POST_PO_REQUEST:
      return {
        ...state,
        msg: action.payload.msg,
        error: action.payload.error,
      };
    case actionType.GET_SUPPLIERS_PINCODE:
      return {
        ...state,
        suppliers: action.payload.data,
        error: action.payload.error,
      };
    case actionType.PO_ACCEPT_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.POSTPAID_ORDER_PLACE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.POSTPAID_SETTLEMENT_INVOICES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        invoiceData: action.payload.data,
      };
    case actionType.POSTPAID_INVOICES_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        invoicePriceData: action.payload.data,
        discount: action.payload.discount,
      };
    case actionType.POSTPAID_SETTLE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PAY_THROUGH_WALLET_PP:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.POSTPAID_GENERATE_INVOICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.SEND_EMAIL_POSTPAID:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.UPDATE_POSTPAID_ORDER:
      return {
        ...state,
        poUpdateError: action.payload.error,
        poUpdateMsg: action.payload.msg,
      };
    case actionType.POSTPAID_CHALLAN_ZIP:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.CONSOLIDATE_PO_GET:
      return {
        ...state,
        error: action.payload.error,
        consolidatePOOrders: action.payload.data,
      };
    case actionType.BUSINESS_USERS:
      return {
        ...state,
        error: action.payload.error,
        businessUsers: action.payload.data,
      };
    default:
      return state;
  }
};

export default adminPoTrackPostpaidReducer;
