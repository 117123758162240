import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  GetDepositDetails,
  GetFilteredUserAddressAdmin,
  GetProfileDetails,
  GetUserAddressAdmin,
  GetUserStockDetails,
  ReturnCansOrder,
} from "../Redux/Actions/userDetailsAdminAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetUserProfile } from "../Redux/Actions/profileAction";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import ReturnCanOrders from "../wfsCanOrders/ReturnCanOrders";
import { GetDistributorReturnCanOrders } from "../Redux/Actions/wfsOrdersAction";
import DistributorReturnOrderDetails from "./DistributorReturnOrderDetails";
import {
  GetWithdrawallist,
  PutEditReturnCanOrder,
} from "../Redux/Actions/AdminWithdrawalListAction";
import CustomConsole from "../../CustomConsole";
import { FirstPage, LastPage, Search } from "@mui/icons-material";

function PlaceReturnOrderToWfs(props) {
  const dispatch = useDispatch();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const adminWithdrawallistData = useSelector(
    (state) => state.withdrawalOrderlistAdmin
  );
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const wfsOrderDetails = useSelector((state) => state.wfsOrderDetails);
  const ProfileDetails = useSelector((state) => state.profile);
  const [profileData, setProfileData] = React.useState({});
  const [openEditReturnCanDialog, setOpenEditReturnCanDialog] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [productName, setProductName] = React.useState("");
  const [pageSize, setPageSize] = React.useState(25);
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const [addressId, setAddressId] = React.useState("");

  React.useEffect(() => {
    dispatch(GetUserProfile());
    dispatch(
      GetWithdrawallist({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  }, [pageNumber]);
  React.useEffect(() => {
    if (ProfileDetails.user) {
      setProfileData(ProfileDetails.user);
    }
  }, [ProfileDetails]);

  React.useEffect(() => {
    if (profileData.user_id) {
      dispatch(GetAvailableProducts());
      dispatch(GetUserAddressAdmin(profileData.user_id));
    }
  }, [profileData.user_id]);
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = skuContainerDetails.getAvailableProducts.filter(
      (data) =>
        `${data.product_details.capacity}-${data.product_details.material}` ===
        event.target.value
    );
    if (selectedDetails.length > 0) {
      setProductName(
        `${selectedDetails[0].product_details.capacity}-${selectedDetails[0].product_details.material}`
      );
      setCapacity(selectedDetails[0].product_details.capacity);
      setMaterial(selectedDetails[0].product_details.material);
    }
  };

  const handleChangeQuality = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setQuantity(inputValue);
    }
  };
  const handleSubmitReturnOrder = () => {
    const data = {
      container_capacity: capacity,
      container_material: material,
      container_qty: parseInt(quantity),
      user_id: props.userId,
      address_id: addressId || undefined,
    };
    if (
      addressId !== "" &&
      capacity !== "" &&
      material !== "" &&
      quantity > 0
    ) {
      dispatch(ReturnCansOrder(data));
      setProductName("");
      setCapacity("");
      setMaterial("");
      setQuantity(1);
      setAddressId("");
      setTimeout(() => {
        dispatch(
          GetWithdrawallist({
            page_number: 0,
            page_size: 25,
          })
        );
      }, 1000);
    } else {
      toast.error("Check the values you entered", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handlePageSizeChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(event.target.value);
  };
  const handleSearchPageSize = () => {
    dispatch(
      GetWithdrawallist({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
        mt: "10px",
      }}
    >
      <Box>
        <FormControl sx={{ minWidth: "220px" }} size="small">
          <InputLabel>Product Details</InputLabel>
          <Select
            value={productName}
            label="Product Details"
            onChange={handleChangeSelectedProduct}
          >
            {skuContainerDetails.getAvailableProducts.map((data) => (
              <MenuItem
                value={`${data.product_details.capacity}-${data.product_details.material}`}
                key={data.product_details.id}
              >
                <div>
                  <span>
                    {" "}
                    <strong>{data.product_details.name}</strong>
                  </span>
                  <br />
                  <small>
                    Capacity:
                    {data.product_details.capacity}/
                  </small>
                  <small>
                    Material:
                    {data.product_details.material}
                  </small>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl sx={{ minWidth: "220px" }}>
          <InputLabel sx={{ fontSize: "0.80rem", marginTop: "2px" }}>
            Address
          </InputLabel>
          <Select
            label="Address"
            value={addressId}
            onChange={(e) => setAddressId(e.target.value)}
            sx={{ fontSize: "0.69rem" }}
          >
            {updateUserDetails.address.map((obj, idx) => {
              return (
                <MenuItem
                  value={obj.address_id}
                  sx={{ fontSize: "0.69rem" }}
                  key={idx}
                >
                  {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <TextField
          sx={{ minWidth: "220px" }}
          size="small"
          type="number"
          label="Quantity"
          InputProps={{
            inputProps: {
              pattern: "\\d*",
              inputMode: "numeric",
            },
          }}
          variant="outlined"
          value={quantity}
          onChange={handleChangeQuality}
        />
      </Box>
      <Box
        sx={{ display: "flex", marginTop: "10px", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          onClick={handleSubmitReturnOrder}
          //   sx={{
          //     background: appColors.commonBtnColor.background,
          //   }}
        >
          Submit
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: "20px",
          mb: "20px",
        }}
      >
        <Box
          sx={{
            maxWidth: "120px",
            maxHeight: "10px",
            // margin: { xs: 4 },
            // marginTop: 1,
          }}
        >
          <TextField
            size="small"
            value={pageSize}
            label="Page size"
            // onChange={(e) => {
            //   setPageSize(e.target.value);
            //   // setSearchOrderFlag(true);
            // }}
            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handlePageSizeChange, // Attach the input event handler
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              if (pageNumber > 0) {
                setPageNumber(() => pageNumber - 1);
              }
            }}
          >
            <FirstPage sx={{ color: pageNumber > 0 ? "blue" : "gray" }} />
          </IconButton>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            {pageNumber + 1}
          </Typography>
          <IconButton
            sx={{ maxHeight: "50px" }}
            onClick={() => {
              if (pageSize == adminWithdrawallistData?.withdrawalList?.length) {
                setPageNumber(() => pageNumber + 1);
              }
            }}
          >
            <LastPage
              sx={{
                color:
                  adminWithdrawallistData?.withdrawalList?.length === pageSize
                    ? "blue"
                    : "gray",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {adminWithdrawallistData?.withdrawalList !== undefined &&
        adminWithdrawallistData?.withdrawalList !== null &&
        adminWithdrawallistData?.withdrawalList.length > 0 ? (
          <>
            {adminWithdrawallistData?.withdrawalList?.map((data, key) => (
              <DistributorReturnOrderDetails
                data={data}
                key={key}
                openEditReturnCanDialog={openEditReturnCanDialog}
                setOpenEditReturnCanDialog={setOpenEditReturnCanDialog}
                pageSize={pageSize}
                pageNumber={pageNumber}
              />
            ))}
          </>
        ) : (
          <Box>
            <Typography sx={{ color: "red" }}>
              No withdrawal orders Found
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PlaceReturnOrderToWfs;
