import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function QrBarcodeLinkingDialog({
  setLinkingDialogOpen,
  linkingDialogOpen,
  scannedBarCode,
  scannedQrCode,
  setLink,
  scanCount,
  setLinkQrBarcodeBtn,
  setIsCameraOpen,
  inQrBarcodeAdmin,
}) {
  const handleClose = () => {
    if (inQrBarcodeAdmin) {
      setLinkingDialogOpen(false);
      setLinkQrBarcodeBtn(true);
    } else {
      setLinkingDialogOpen(false);
      setIsCameraOpen(true);
    }

  };

  const handleLink = () => {
    setLink(true);
  };
  return (
    <div>
      <Dialog
        open={linkingDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            {scanCount} of 2 scanned Successfully
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your scan has been successful, scan next to link
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {scanCount === 2 ? (
              <Button onClick={handleLink} autoFocus>
                Link
              </Button>
            ) : (
              <Button onClick={handleClose}>Scan next</Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
