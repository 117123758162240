import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomConsole from "../../CustomConsole";

export default function ContainerFilterDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilter = () => {
    props.clickFilerDetailsDetails()
  }
  const handleFilterClose = () => {
    props.setFilterDialogFlag(false)
  }
  const handleChange = (event) => {
    CustomConsole(event.target.value);
    props.setCapacity(event.target.value);
  };
  const handleChangeMaterial = (event) => {
    CustomConsole(event.target.value);
    props.setMaterial(event.target.value);
  };

  return (
    <div>

      <Dialog
        open={props.filterDialogFlag}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Select options to Filter"}
        </DialogTitle>
        <DialogContent>

          <Box>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                FILTER AT
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => props.setFiletAt(event.target.value)}
              >
                <FormControlLabel
                  value="DESC"
                  control={<Radio />}
                  label="DESC(Date)"
                />
                <FormControlLabel
                  value="ASC"
                  control={<Radio />}
                  label="ASC(Date)"
                />
              </RadioGroup>
            </FormControl>
          </Box>

        </DialogContent>
        <DialogContent>
          <Box>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                ACTIVE OR INACTIVE
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => props.setIsActive(event.target.value)}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="InActive"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl
              sx={{ m: 1, minWidth: 250 }}
            >
              <InputLabel id="demo-simple-select-label">
                Capacity
              </InputLabel>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.capacity}
                label="Capacity"
                onChange={handleChange}
              >
                <MenuItem value={"20_Liters"}>20_Liters</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <InputLabel id="demo-simple-select-label">
                Material
              </InputLabel>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.material}
                label="Capacity"
                onChange={handleChangeMaterial}
              >
                <MenuItem value={"PET"}>PET</MenuItem>
                <MenuItem value={"PVC"}>PVC</MenuItem>
                <MenuItem value={"POLYCARBONATE"}>POLYCARBONATE</MenuItem>
                <MenuItem value={"COPPER"}>COPPER</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        {/* <DialogActions> */}
        <Box sx={{ display: "flex", flexDirection: "row",justifyContent:"space-evenly" }}>
          <Button sx={{ margin: "4px" }} variant="contained" onClick={() => handleFilter()} >
            Filter
          </Button>
          <Button sx={{ margin: "4px" }} variant="contained" color="error" onClick={() => handleFilterClose()} >
            close
          </Button>
        </Box>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}