/**
 * @author Pravesh
 * @description bank details reducer
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "../Actions/actionType";

const initialProfileState = {
  error: true,
  msg: "",
  branchDetails: {},
};

const bankDetailsReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.GET_BANK_DETAILS_IFSC:
      return {
        error: action.payload.error,
        msg: action.payload.msg,
        branchDetails: action.payload.data,
      };

    default:
      return state;
  }
};

export default bankDetailsReducer;
