import actionType from "./actionType";
import useAxios from "../../Axios/useAxios";
import { toast } from "react-toastify";

import CustomConsole from "../../../CustomConsole";
export const PostQrBarcodeSwapRequest = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/QSM/product_swap/request`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_QRBARCODE_SWAP_REQUEST,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};
export const GetQrBarcodeSwapList = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/QSM/product_swap/request`, {
        params: props,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_QRBARCODE_SWAP_LIST,
          payload: response.data,
        });
      } else {
        // toast.error(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      }
      CustomConsole("error");
    } catch (error) {
      dispatch({
        type: actionType.GET_QRBARCODE_SWAP_LIST,
        payload: { error: true, msg: "", data: [] },
      });
      // toast.error(error.response.data.msg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      CustomConsole(error);
    }
  };
};
export const GetQrBarcodeSwapCanByBulkQr = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/QSM/bulk_qr/details`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_QRBARCODE_SWAP_CAN_BY_ORDERID,
          payload: response.data,
        });
      } else {
        // toast.error(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
        CustomConsole("error");
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole("error");
    }
  };
};
export const PutQrBarcodeSwapAcceptOrReject = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/QSM/product_swap/request/accept/reject`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_QRBARCODE_SWAP_ACCEPT_REJECT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};
