/**
 * @author vinay kumar
 * @description Place Emppty Can orders page
 * @copyright Bookwater tech pvt ltd
 */
 // This UI Complete ReDesign Done by Vinay
 import * as React from "react";
 import Box from "@mui/material/Box";
 import CustomConsole from "../../CustomConsole";
 import Card from "@mui/material/Card";
 import CardActions from "@mui/material/CardActions";
 import CardContent from "@mui/material/CardContent";
 import Button from "@mui/material/Button";
 import Typography from "@mui/material/Typography";
 import { Grid, Paper } from "@mui/material";
 import InputLabel from "@mui/material/InputLabel";
 import MenuItem from "@mui/material/MenuItem";
 import FormControl from "@mui/material/FormControl";
 import Select from "@mui/material/Select";
 import TextField from "@mui/material/TextField";
 import { toast } from "react-toastify";
 import { useDispatch, useSelector } from "react-redux";
 import { PlaceOrderEmptyCans } from "../Redux/Actions/adminLineDetailsAction";
 
 export default function PlaceOrderEmptyCansCard({ userId }) {
   const dispatch = useDispatch();
   const wfsLineDetails = useSelector((state) => state.adminWfsLineData);
 
   const [capacity, setCapacity] = React.useState("");
   const [material, setMaterial] = React.useState("");
   const [newCansRequired, setNewCansRequired] = React.useState("");
   const [expiredCans, setExpiredCans] = React.useState("");
   const [damagedCans, setDamagedCans] = React.useState("");
   const [placeOrderFlag, setPlaceOrderFlag] = React.useState(false);
 
   React.useEffect(() => {
     CustomConsole(wfsLineDetails);
     if (wfsLineDetails.error === false && placeOrderFlag === true) {
       toast.success(wfsLineDetails.placeOrderMsg, {
         position: "bottom-right",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
       });
       toast.success(wfsLineDetails.emptyCanOrderId, {
         position: "bottom-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
       });
       setPlaceOrderFlag(false);
     }
   }, [wfsLineDetails]);
   const handleChangeCapacity = (event) => {
     setCapacity(event.target.value);
   };
   const handleChangeMaterial = (event) => {
     setMaterial(event.target.value);
   };
 
   const handleChangeNewCansRequired = (event) => {
     if (event.target.value > 0) {
       setNewCansRequired(event.target.value);
     } else {
       toast.error("Cant be less than zero", {
         position: "bottom-right",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
       });
     }
   };
   const handleChangeExpired = (event) => {
     if (event.target.value >= 0) {
       setExpiredCans(event.target.value);
     } else {
       toast.error("Cant be less than zero", {
         position: "bottom-right",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
       });
     }
   };
   const handleChangeDamagedCans = (event) => {
     if (event.target.value >= 0) {
       setDamagedCans(event.target.value);
     } else {
       toast.error("Cant be less than zero", {
         position: "bottom-right",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
       });
     }
   };
   const handlePlaceOrder = () => {
     CustomConsole(userId);
     if (damagedCans != "" && expiredCans != "" && newCansRequired != "") {
       const reqObj = {
         container_capacity: capacity,
         container_material: material,
         damaged_container_qty: parseInt(damagedCans),
         expired_container_qty: parseInt(expiredCans),
         new_container_qty: parseInt(newCansRequired),
         wfs_incharge_id: userId,
       };
       dispatch(PlaceOrderEmptyCans(reqObj));
       setPlaceOrderFlag(true);
     } else {
       toast.error("Please Fill all Details", {
         position: "bottom-right",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
       });
     }
   };
   return (
     // <Paper elevation={24} sx={{"&:hover": {
     //   boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
     // },}}>
        <Card sx={{ minWidth: 275 }}>
         <CardContent> 
           <Box
             sx={{
               display: "flex",
               flexWrap:"wrap",
               flexDirection: "column",
               alignItems: "center",
               justifyContent: "center",
               gap: "20px",
               padding: "10px",
             }}
           >
             <Grid container spacing={2}>
               <Grid item md={6} sm={12} xs={12}>
                 <FormControl fullWidth>
                   <InputLabel id="demo-select-small">Capacity</InputLabel>
                   <Select
                     labelId="demo-select-small"
                     id="demo-select-small"
                     value={capacity}
                     label="Capacity"
                     onChange={handleChangeCapacity}
                   >
                     <MenuItem value={"20_Liters"}>20_Liters</MenuItem>
                   </Select>
                 </FormControl>
               </Grid>
 
               <Grid item md={6} sm={12} xs={12}>
                 <FormControl fullWidth>
                   <InputLabel id="demo-select-small">Material</InputLabel>
                   <Select
                     labelId="demo-select-small"
                     id="demo-select-small"
                     value={material}
                     label="Material"
                     onChange={handleChangeMaterial}
                   >
                     <MenuItem value={"PET"}>PET</MenuItem>
                     <MenuItem value={"PVC"}>PVC</MenuItem>
                     <MenuItem value={"COPPER"}>COPPER</MenuItem>
                     <MenuItem value={"POLYCORBONATE"}>POLYCARBONATE</MenuItem>
                   </Select>
                 </FormControl>
               </Grid>
               <Grid item md={4}>
                 <TextField
                   id="outlined-number"
                   label="New Cans required"
                   type="number"
                   value={newCansRequired}
                   InputLabelProps={{
                     shrink: true,
                   }}
                   onChange={handleChangeNewCansRequired}
                 />
               </Grid>
               <Grid item md={4}>
                 <TextField
                   id="outlined-number"
                   label="Expired cans"
                   type="number"
                   value={expiredCans}
                   InputLabelProps={{
                     shrink: true,
                   }}
                   onChange={handleChangeExpired}
                 />
               </Grid>
               <Grid item md={4}>
                 <TextField
                   id="outlined-number"
                   label="Damaged cans"
                   type="number"
                   value={damagedCans}
                   InputLabelProps={{
                     shrink: true,
                   }}
                   onChange={handleChangeDamagedCans}
                 />
               </Grid>
             </Grid>
 
             <Grid item md={12}>
               <Button variant="contained" onClick={handlePlaceOrder}>
                 Place order for empty cans
               </Button>
             </Grid>
           </Box>
         </CardContent>
       </Card>
     // </Paper>
   );
 }
 