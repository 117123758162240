/**
 * @author vinay kumar
 * @description WfsLineDetails
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import CustomConsole from "../../CustomConsole";
import { CardContent, Paper } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  AddWfsLine,
  GetWfsLineDetails,
} from "../Redux/Actions/adminLineDetailsAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { Card } from "@material-ui/core";
import { UsersStyles } from "./UsersStyles";
import moment from "moment";

function WfsLineDetails({ wfsId,userId }) {
  const dispatch = useDispatch();
  const wfsLineDetails = useSelector((state) => state.adminWfsLineData);
  const theme = useTheme();

  const [addLinesFlag, setAddLinesFlag] = React.useState(false);

  const [submitBtn, setSubmitBtn] = React.useState(false);

  const handleAddLines = () => {
    const reqObj = {
      wfs_id: wfsId,
    };
    dispatch(AddWfsLine(reqObj));
    setAddLinesFlag(true);
  };

  // React.useEffect(() => {
  //   CustomConsole("---------Line----------")
  //   CustomConsole(wfsId);
  //   CustomConsole(userId)
  //   dispatch(GetWfsLineDetails(wfsId));
  // }, [userId,wfsId]);

  React.useEffect(() => {
    CustomConsole(wfsLineDetails);
    if (wfsLineDetails.error === false && addLinesFlag === true) {
      toast.success(wfsLineDetails.addWfsLineMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAddLinesFlag(false);
      dispatch(GetWfsLineDetails(wfsId));
    }
    if (
      wfsLineDetails.allFillingStationsDetails.length <= 0 &&
      submitBtn === true
    ) {
      toast.error("Check entered pincode", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSubmitBtn(false);
    }
  }, [wfsLineDetails]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button variant="outlined" onClick={handleAddLines}>
          Add lines
        </Button>
      </Box>

      <Box>
        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Line ID
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Device number
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Created at
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wfsLineDetails.wfsLineDetails.map((row) => (
                <TableRow
                  key={row.line_id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.line_id}
                  </TableCell>
                  <TableCell align="right">
                    {row.iot_device_number !== ""
                      ? row.iot_device_number
                      : "Null"}
                  </TableCell>
                  <TableCell align="right">
                    {moment(row.created_at).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    {row.is_active === true ? "Active" : "Inactive"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default WfsLineDetails;
