import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";

function SupplierStartSubmissionDialog(props) {
  const handleOk = () => {
    CustomConsole("----handleOk---");
    props.handleClose(props);
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openStartSubmissionDialog}>
        <Material.DialogContent>
          <Material.DialogTitle sx={{ fontWeight: "bold" }}>
            Submmision Started!
          </Material.DialogTitle>
          <Material.Typography>
            {props.storeStartSubmissionData}
          </Material.Typography>
          <Material.Typography>
            Ongoing Order Started,Please Submit the Empty Cans Now (if not New
            User)!!!
          </Material.Typography>
        </Material.DialogContent>
        <Material.DialogActions>
          <Material.Button variant="contained" onClick={handleOk}>
            Ok
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </Material.Box>
  );
}

export default SupplierStartSubmissionDialog;
