/**
 * @author vinay kumar
 * @description Orders Main Component
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 23-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import InputLabel from "@mui/material/InputLabel";
import CustomConsole from "../../CustomConsole";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import OngoingOrders from "./OngoingOrders";
import CompletedOrders from "./CompletedOrders";
import AllOrders from "./AllOrders";
import ReplacementCansOrders from "./ReplacementCansOrders";
import ReturnCanOrders from "./ReturnCanOrders";
import Send from "@mui/icons-material/Send";

import TransFerCanOrders from "./TransFerCanOrders";
import WfsAllOrdersView from "./WfsAllOrdersView";
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import PlaceReturnOrderToWfs from "./PlaceReturnOrderToWfs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function OrdersMain() {
  const theme = useTheme();
  const [selectOrders, setSelectOrders] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [selectWFSCanOrders, setSelectWFSCanOrders] = React.useState("");
  const [enterOtpOpenDialog, setEnterOtpOpenDialog] = React.useState(false);

  const handleChangeSelectOrders = (event) => {
    setSelectOrders(event.target.value);
  };

  const handleChangeWFSCanOrders = (event) => {
    setSelectWFSCanOrders(event.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ marginTop: "50px" }}>
        <Box sx={{ bgcolor: "background.paper" }}>
          <AppBar
            position="static"
            sx={{
              color: "black",
              borderRadius: "100px",
              // background: appColors.commonLayoutColor.background,
              // backgroundImage: appColors.commonLayoutColor.backgroundImage,
              background: "#85C1E9",
              padding: "5px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              // variant="scrollable"
              aria-label="full width tabs example"
              TabIndicatorProps={{ style: { display: "none" } }} // Hide the bottom border
            >
              <Tab
                label="Wfs"
                {...a11yProps(0)}
                sx={{
                  ...(value === 0 && {
                    bgcolor: "#F7F9F9", // Change color when active
                    color: "black",
                  }),
                  borderRadius: 10,
                }}
              />
              <Tab
                label="Return To Wfs"
                {...a11yProps(1)}
                sx={{
                  ...(value === 1 && {
                    bgcolor: "#F7F9F9", // Change color when active
                    color: "black",
                  }),
                  borderRadius: 10,
                }}
              />
              <Tab
                label="Transfer"
                {...a11yProps(2)}
                sx={{
                  ...(value === 2 && {
                    bgcolor: "#F7F9F9", // Change color when active
                    color: "black",
                  }),
                  borderRadius: 10,
                }}
              />
            </Tabs>
          </AppBar>
          <Box></Box>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <WfsAllOrdersView />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <PlaceReturnOrderToWfs />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <TransFerCanOrders />
            </TabPanel>
          </SwipeableViews>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ minWidth: 200, margin: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Orders
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectOrders}
                label="Select Orders"
                onChange={handleChangeSelectOrders}
              >
                <MenuItem value={10}>WFS can Orders</MenuItem>
                
                <MenuItem value={40}>Transfer Order Cans</MenuItem>
              </Select>
            </FormControl>
          </Box>
        
        </Box> */}
        {selectOrders === 30 ? <ReplacementCansOrders /> : ""}

        {selectOrders === 20 ? (
          <ReturnCanOrders
            enterOtpOpenDialog={enterOtpOpenDialog}
            setEnterOtpOpenDialog={setEnterOtpOpenDialog}
          />
        ) : (
          ""
        )}

        {selectOrders === 40 ? <TransFerCanOrders /> : ""}
      </Box>
      {selectWFSCanOrders === 60 && selectOrders === 10 ? (
        <OngoingOrders />
      ) : (
        ""
      )}
      {selectWFSCanOrders === 70 && selectOrders === 10 ? (
        <CompletedOrders />
      ) : (
        ""
      )}
      {selectOrders === 10 ? (
        // <Box sx={{ minWidth: 250, margin: "10px" }}>
        //   <FormControl fullWidth>
        //     <InputLabel id="demo-simple-select-label">
        //       Select WFS Can Orders
        //     </InputLabel>
        //     <Select
        //       labelId="demo-simple-select-label"
        //       id="demo-simple-select"
        //       value={selectWFSCanOrders}
        //       label="Select WFS Can Orders"
        //       onChange={handleChangeWFSCanOrders}
        //     >
        //       <MenuItem value={60}>Ongoing Orders</MenuItem>
        //       <MenuItem value={70}>Completed Orders</MenuItem>
        //       <MenuItem value={80}>All Orders</MenuItem>
        //     </Select>
        //   </FormControl>
        // </Box>
        <WfsAllOrdersView />
      ) : (
        ""
      )}
      {selectWFSCanOrders === 80 && selectOrders === 10 ? <AllOrders /> : ""}
    </Box>
  );
}

export default OrdersMain;
