import React from "react";
import * as Material from "@mui/material";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CustomConsole from "../../CustomConsole";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import EnterOTPDialog from "./EnterOTPDialog";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

function WfsCrateScanDialog({
  isCrateCameraOpen,
  is_inscan,
  handleCloseDialog,
  handleQrBarcodeIdFromScannerCrate,
  scanCrateOption,
  setScanCrateOption,
  handleSubmitCrateScan,
  onNewScanCateResult,
  qrBarcodeIdCrate,
  handelCameraSubmit,
  handelProductQty,
  crateProductQty,
  skuWfsDetails,
}) {
  //function for setting up product details
  const handleSelectCrateScanOption = (event) => {
    setScanCrateOption(event.target.value);
  };
  return (
    <Material.Box sx={{ marginTop: "40px" }}>
      <Material.Dialog
        open={isCrateCameraOpen}
        onClose={handleCloseDialog}
        // fullScreen
      >
        <Material.DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            padding: "10px",
          }}
        >
          {skuWfsDetails?.is_admin === false && is_inscan == true && (
            <Material.TextField
              disabled={false}
              autoFocus
              size="small"
              label="Crate Capacity"
              InputLabelProps={{
                shrink: true,
              }}
              value={crateProductQty}
              onChange={handelProductQty} // Listen for changes in TextField
            />
          )}
          <>
            <FormControl sx={{ marginTop: "15px" }}>
              <FormLabel>Select Scanning Option</FormLabel>
              <RadioGroup
                defaultValue="scanner"
                value={scanCrateOption}
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={handleSelectCrateScanOption}
              >
                <FormControlLabel
                  value="scanner"
                  control={<Radio />}
                  label="Scanner Device"
                />
                {true && (
                  <FormControlLabel
                    value="camera"
                    control={<Radio />}
                    label="Camera"
                  />
                )}
              </RadioGroup>
            </FormControl>
            <>
              {isCrateCameraOpen && scanCrateOption === "camera" ? (
                <Html5QrcodePlugin1
                  fps={10}
                  qrbox={250}
                  disableFlip={false}
                  qrCodeSuccessCallback={onNewScanCateResult}
                />
              ) : isCrateCameraOpen && scanCrateOption === "scanner" ? (
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    padding: "10px",
                  }}
                >
                  <form onSubmit={handleSubmitCrateScan}>
                    {/* Form element wrapping the TextField */}
                    <Material.TextField
                      disabled={false}
                      autoFocus
                      size="small"
                      label="QR/Barcode:"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={qrBarcodeIdCrate}
                      onChange={handleQrBarcodeIdFromScannerCrate} // Listen for changes in TextField
                    />
                    <Material.Button
                      type="submit"
                      style={{ display: "none" }}
                    />{" "}
                    {/* Hidden submit button */}
                  </form>
                </div>
              ) : (
                ""
              )}
            </>
          </>
        </Material.DialogContent>
        <Material.DialogActions
          sx={{ display: "flex", justifyContent: "space-around" }}
        >
          <Material.Button
            size="small"
            variant="contained"
            color="error"
            onClick={handleCloseDialog}
          >
            Close
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </Material.Box>
  );
}

export default WfsCrateScanDialog;
