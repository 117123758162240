import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";

function AddWaterDropsDialog(props) {
  const [reasonForAddWaterDrops, setReasonForAddWaterDrops] =
    React.useState("");
  const [enterWaterDrops, setEnterWaterDrops] = React.useState(null);
  const handleAddWaterDrops = () => {
    CustomConsole("------------handleReduceWalletAmount-------------");
    props.handleAddWaterDrops({
      enterWaterDrops: enterWaterDrops,
      setEnterWaterDrops: setEnterWaterDrops,
      reasonForAddWaterDrops: reasonForAddWaterDrops,
      setReasonForAddWaterDrops: setReasonForAddWaterDrops,
    });
  };
  const handleCloseAddWaterDrops = () => {
    props.handleCloseAddWaterDrops();
  };
  return (
    <Material.Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        },
      }}
      open={props.openAddWaterDropsDialog}
    >
      <Material.DialogContent>
        <Material.Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          ADD WATER DROPS
        </Material.Typography>
        <Material.Box
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Material.TextField
            id="outlined-basic"
            label="Enter Water Drops"
            variant="outlined"
            color="secondary"
            // type="number"
            onKeyDown={(e) => {
              if (!/^([0-9]\b)+$/.test(e.key)) {
                e.preventDefault();
              }
              if (e.key === "Backspace") {
                setEnterWaterDrops((preAmount) => preAmount.slice(0, -1));
              }
            }}
            value={enterWaterDrops}
            // sx={{ backgroundColor: "white" }}
            size="small"
            onChange={(event) => setEnterWaterDrops(event.target.value)}
          />

          <Material.TextField
            id="outlined-basic"
            label="Reason For Add Water drops *"
            variant="outlined"
            color="secondary"
            value={reasonForAddWaterDrops}
            size="small"
            onChange={(event) => setReasonForAddWaterDrops(event.target.value)}
          />
        </Material.Box>
        <Material.Box
          sx={{ display: "flex", gap: "10px", m: 1, justifyContent: "center" }}
        >
          <Material.Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleCloseAddWaterDrops}
          >
            close
          </Material.Button>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handleAddWaterDrops}
          >
            ADD Now
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default AddWaterDropsDialog;
