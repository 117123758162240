/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 25-01-2024
 */
import React from "react";
import * as Material from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { ordersStatusWFSCan } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";
import {
  GetOngoingOrdersWfsInAdmin,
  StartSubmissinOngoingWfsAdmin,
} from "../Redux/Actions/adminWfsCanOrdersAction";
import { useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
function WfsEmptyCanOrderTabel({ selectedOrderData, selectedOrderType }) {
  CustomConsole(selectedOrderData);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  //function for start submission
  const handleStartSubmission = (data) => {
    const reqobj = {
      order_id: data,
    };
    dispatch(StartSubmissinOngoingWfsAdmin(reqobj));
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    }, 1000);
  };
  //function for handling view icon
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };
  return (
    <>
      <TableContainer sx={{ maxHeight: "650px", overflow: "auto" }}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow sx={appColors.commonLayoutColor}>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap", fontSize: "16px" }}
                colSpan={12}
              >
                <strong>
                  {selectedOrderType === "completed"
                    ? "Completed Order"
                    : selectedOrderType === "ongoing"
                    ? "Ongoing Orders"
                    : "All Orders"}{" "}
                </strong>
              </TableCell>
            </TableRow>
            <TableRow sx={appColors.commonLayoutColor}>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong></strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>S.no</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Order ID</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Order OTP</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Empty Cans</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>New Cans</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Order status</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Ordered date</strong>
              </TableCell>

              <TableCell size="small" align="center">
                <strong>Capacity</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>Material</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>Received</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Placed by</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Batch</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedOrderData?.map((data, index) => (
              <>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor:
                      (data.order_details.is_submit_started === false &&
                        data.ec_order_status == "100") ||
                      (data.order_details.is_submit_started === false &&
                        data.ec_order_status == "501")
                        ? "#4FFFB0"
                        : "#fff",
                  }}
                  key={index}
                >
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handelIcon(index)}
                    >
                      {openFlag ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell size="small" align="start">
                    {index + 1}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.ec_order_id}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.otp}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details.return_qty}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details.new_qty}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_status}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.created_at.slice(0, 10)}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details.container_capacity}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details.container_material}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details.submitted_return_cans}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.is_admin ? "Admin" : "Wfs"}
                  </TableCell>
                  <TableCell size="small" align="center">
                    <Material.Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {(data.order_details.is_submit_started === false &&
                        data.ec_order_status == "100") ||
                      (data.order_details.is_submit_started === false &&
                        data.ec_order_status == "501") ? (
                        <Material.Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          <Material.Box>
                            <Material.Button
                              variant="contained"
                              size="small"
                              onClick={() =>
                                handleStartSubmission(data.ec_order_id)
                              }
                            >
                              Start Submission
                            </Material.Button>
                          </Material.Box>
                        </Material.Box>
                      ) : (
                        <Material.Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            borderRadius: "10px",
                            color: "white",
                            background: "rgb(67,135,245)",
                          }}
                        >
                          {data?.ec_order_status === 502
                            ? "Rejected"
                            : "Order Started"}
                        </Material.Typography>
                      )}
                    </Material.Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                  >
                    {/* <Collapse in={open} timeout="auto" unmountOnExit > */}
                    {open === index && openFlag === true ? (
                      <Material.Box sx={{ margin: 1 }}>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow sx={appColors.commonLayoutColor}>
                              <TableCell size="small">
                                <strong>Material</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Capacity</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Order Qty</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Return Qty</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* {data.order_details?.crates?.map((data) => ( */}
                            <TableRow key={data?.id}>
                              <TableCell size="small">
                                {data.order_details.container_material}
                              </TableCell>
                              <TableCell size="small">
                                {data.order_details.container_capacity}
                              </TableCell>
                              <TableCell size="small">
                                {data.order_details.order_qty}
                              </TableCell>
                              <TableCell size="small">
                                {data.order_details.return_qty}
                              </TableCell>
                            </TableRow>
                            {/* ))} */}
                          </TableBody>
                        </Table>
                      </Material.Box>
                    ) : (
                      ""
                    )}
                    {/* </Collapse> */}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default WfsEmptyCanOrderTabel;
