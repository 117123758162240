import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CustomConsole from "../../CustomConsole";
import Slide from "@mui/material/Slide";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { PostPaidInvoices } from "../Redux/Actions/adminPoTrackPostpaid";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import moment from "moment/moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  GetPoTrackDetails,
  GetInvoiceData,
  GenerateInvoice,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InvoiceDialog({
  open,
  setOpen,
  invoiceData,
  pageNumber,
  poTrackSet,
  phone,
  pageSize,
  poTrack,
  orderType,
  sort,
}) {
  const userAddress = useSelector((state) => state.userDetailsUpdate.address);
  const invoice = useSelector((state) => state.invoicegenerate);
  const poDetails = useSelector((state) => state.poTrackDetails);

  const [address, setAddress] = React.useState([]);
  const [deliveryAddress, setDeliveryAddress] = React.useState({});
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [selectedOrders, setSelectedOrders] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [mapingData, setMapingdata] = React.useState([]);
  const [deliveryCharge, setDeliveryCharge] = React.useState(0);
  const [startDate, setStartDate] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY/MM/DD"));
  const [generateInv, setGenerateInv] = React.useState(false);
  const [invoiceDate, setInvoiceDate] = React.useState(
    moment().format("YYYY/MM/DD")
  );

  const [finalPrice, setFinalPrice] = React.useState(0);
  const dispatch = useDispatch();

  const [selectAllOrders, setSelectAllOrders] = React.useState(false);
  React.useEffect(() => {
    setSelectAllOrders(false);
    setFinalPrice(0);
    setDeliveryCharge(0);
    setOrderDetails([]);
  }, []);
  const handleClose = () => {
    setOpen(false);
    setSelectedOrders([]);
    setDeliveryAddress({});
    let reqObj = {
      page_size: pageSize,
      page_number: pageNumber,
      pp_track_id: poTrack,
      order_status: orderType,
      sort: sort,
      phone: phone,
    };
    dispatch(GetPoTrackDetails(reqObj));
    dispatch(GetInvoiceData(poTrackSet));
  };

  const handleCheckBoxChange = (e, item) => {
    if (e.target.checked === true) {
      setSelectedOrders((orderId) => [...orderId, item.order_id]);
      // setFinalPrice((price) => price + item.final_price);
      if (parseInt(item.ppDeliveryCharge) > 0) {
        setDeliveryCharge(
          (price) => price + item.ppDeliveryCharge * item.twentyltrqty
        );
      }

      // setSelectedAreas_2((area) => [...area, item]);
    }
    if (e.target.checked === false) {
      setSelectedOrders((orderId) => {
        const removedArr = orderId.filter((id) => id !== item.order_id);
        return removedArr;
      });
      // setFinalPrice((price) =>
      //   price - item.final_price >= 0 ? price - item.final_price : 0
      // );
      if (parseInt(item.ppDeliveryCharge) > 0) {
        setDeliveryCharge(
          (price) => price - item.ppDeliveryCharge * item.twentyltrqty
        );
      }
      // setSelectedAreas_2((area) => {
      //   const removedArr = area.filter((filObj) => filObj.id !== item.id);
      //   return removedArr;
      // });
    } else {
    }
  };
  const handleSelectAllFun = (e) => {
    setSelectAllOrders(() => !selectAllOrders);
    if (e.target.checked === true) {
      let idArr = [];
      // let initialPrice = 0;
      let currentOrders = [...mapingData];

      CustomConsole(currentOrders);
      currentOrders.forEach((obj) => {
        if (obj.order_status === 500 && obj.settled === false) {
          // initialPrice += obj.final_price;
          idArr.push(obj.order_id);
          if (parseInt(obj.ppDeliveryCharge) > 0) {
            setDeliveryCharge(
              (price) => price + obj.ppDeliveryCharge * obj.twentyltrqty
            );
          }
        }
      });
      setSelectedOrders(() => idArr);
      // setFinalPrice(() => initialPrice);

      // setSelectAllArea(true);
    } else if (e.target.checked === false) {
      setSelectedOrders([]);
      setFinalPrice(0);
      setDeliveryCharge(0);
      // setSelectedAreas_2([]);
      // setSelectAllArea(false);
    }
  };

  const generatingInvoices = () => {
    CustomConsole("---Inside invoice funtion---");
    CustomConsole(selectedOrders);
    CustomConsole(invoiceData.pp_track_id);
    CustomConsole(deliveryAddress);
    let reqObj = {
      orderId: selectedOrders,
      poTrackId: invoiceData.pp_track_id,
      addressId: deliveryAddress.address_id,
      invoice_date: invoiceDate,
    };
    if (reqObj.poTrackId && reqObj.addressId && reqObj.orderId) {
      CustomConsole(reqObj);
      dispatch(GenerateInvoice(reqObj));
      // dispatch(PostPaidInvoices(reqObj));
      setGenerateInv(true);
    } else {
      toast.error("Choose a delivery address!..", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const searchOnChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFromDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setStartDate(moment(newValue.$d).format("YYYY/MM/DD"));
    } else {
      return false;
    }
  };

  const handleToDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setEndDate(moment(newValue.$d).format("YYYY/MM/DD"));
    } else {
      return false;
    }
  };

  const handleInvoiceDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setInvoiceDate(moment(newValue.$d).format("YYYY/MM/DD"));
    } else {
      return false;
    }
  };

  const handleDateFilter = () => {
    CustomConsole(startDate);
    CustomConsole(endDate);
    const filteredData = orderDetails.filter((item) => {
      const itemDate = moment(item.delivery_date);
      return itemDate.isBetween(startDate, endDate, null, "[]");
    });
    return setMapingdata(filteredData);
  };

  const handleClearFilter = () => {
    setMapingdata(orderDetails);
    setSearchTerm("");
  };

  React.useEffect(() => {
    if (invoiceData.order_details) {
      setOrderDetails(invoiceData.order_details);
    }
  }, [invoiceData, searchTerm]);

  React.useEffect(() => {
    if (searchTerm) {
      const filterOp = [];

      for (let item of orderDetails) {
        const pincodeStr = item.pincode.toString();
        if (
          pincodeStr.includes(searchTerm) ||
          item.servicearea.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          filterOp.push(item);
        }
      }
      setMapingdata(filterOp);
    } else {
      setMapingdata(orderDetails);
    }
  }, [orderDetails, searchTerm]);

  React.useEffect(() => {
    if (mapingData || selectedOrders) {
      CustomConsole(mapingData);
      CustomConsole(selectedOrders);

      let currentOrders = mapingData?.filter((order) =>
        selectedOrders.includes(order.order_id)
      );
      CustomConsole("-----Current Orders-----");
      CustomConsole(currentOrders);
      const totalprice = currentOrders.reduce(
        (acc, order) => acc + order.final_price,
        0
      );
      const deliveryTotal = currentOrders.reduce(
        (acc, order) =>
          order.ppDeliveryCharge > 0
            ? acc + order.twentyltrqty * order.ppDeliveryCharge
            : acc,
        0
      );
      CustomConsole(totalprice);
      CustomConsole(deliveryTotal);
      setFinalPrice(totalprice);
      setDeliveryCharge(deliveryTotal);
    }
  }, [mapingData, selectedOrders]);

  React.useEffect(() => {
    setAddress(userAddress);
  }, [userAddress]);

  React.useEffect(() => {
    CustomConsole("----------OrderId-------");
    CustomConsole(selectedOrders);
  }, [selectedOrders]);

  React.useEffect(() => {
    if (poDetails.error === false && generateInv) {
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (poDetails.error === true && generateInv) {
      toast.error(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setGenerateInv(false);
  }, [poDetails]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle textAlign="center">{invoiceData.pp_track_id}</DialogTitle>

      <DialogContent>
        <Stack direction={"row"} spacing={2} sx={{ padding: "10px" }}>
          <TextField
            variant="outlined"
            placeholder="Search"
            sx={{ width: "30%" }}
            size="small"
            value={searchTerm}
            onChange={searchOnChange}
            label={"Pincode/Area"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Order From"
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={handleFromDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // onKeyDown={handleInputChange}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Order To"
                inputFormat="DD/MM/YYYY"
                value={endDate}
                onChange={handleToDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // onKeyDown={handleInputChange}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <Button onClick={handleDateFilter} variant="contained">
              Search
            </Button>
          </Box>
        </Stack>
        <Stack
          mt={1}
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl sx={{ width: "250px" }}>
            <InputLabel
              sx={{ fontSize: "0.90rem", marginTop: "2px" }}
              id="demo-simple-select-label"
            >
              Delivery Address
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={deliveryAddress}
              label="Delivery Address"
              onChange={(e) => setDeliveryAddress(e.target.value)}
              size="small"
              sx={{ fontSize: "0.90rem" }}
            >
              {address.map((obj, idx) => {
                return (
                  <MenuItem value={obj} sx={{ fontSize: "0.69rem" }} key={idx}>
                    {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Invoice Date"
              inputFormat="DD/MM/YYYY"
              value={invoiceDate}
              onChange={handleInvoiceDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  // onKeyDown={handleInputChange}
                />
              )}
            />
          </LocalizationProvider>
          <Box>
            <Button onClick={handleClearFilter} variant="outlined">
              Clear Filter
            </Button>
          </Box>
        </Stack>
        <Stack width={"100%"} spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#77B7EC",
              borderRadius: "2px",
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                padding: "10px",
                fontFamily: "sans-serif",
                flexGrow: 1,
                textAlign: "center",
              }}
            >
              Order Id's
            </Typography>
            <Box>
              <Checkbox
                onClick={handleSelectAllFun}
                size="small"
                checked={selectAllOrders}
              />
            </Box>
          </Box>
        </Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell align="right">Pincode</TableCell>
                <TableCell align="right">Area</TableCell>
                <TableCell align="right">Booking Date</TableCell>
                <TableCell align="right">Delivery Date</TableCell>
                <TableCell align="right">Qty.</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mapingData.map((obj, idx) => {
                return obj.order_status === 500 && obj.settled === false ? (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {obj.order_id}
                    </TableCell>
                    <TableCell align="right">{obj.pincode}</TableCell>
                    <TableCell align="right">{obj.servicearea}</TableCell>
                    <TableCell align="right">{obj.booking_date}</TableCell>
                    <TableCell align="right">{obj.delivery_date}</TableCell>
                    <TableCell align="right">{obj.twentyltrqty}</TableCell>
                    <TableCell align="right">{obj.final_price}</TableCell>
                    <TableCell align="right">
                      {" "}
                      <Checkbox
                        onChange={(e) => handleCheckBoxChange(e, obj)}
                        size="small"
                        checked={selectedOrders.some(
                          (selected) => selected === obj.order_id
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ) : null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction={"row"} mt={2}>
          <Typography>Delivery Charge</Typography>:
          <Typography>{deliveryCharge}</Typography>
        </Stack>
        <Stack direction={"row"} mt={2}>
          <Typography>Water Price</Typography>:
          <Typography>{finalPrice}</Typography>
        </Stack>
        <Stack direction={"row"} mt={2}>
          <Typography>Total price</Typography>:
          <Typography>{finalPrice + deliveryCharge}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={generatingInvoices}>Generate Invoice</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InvoiceDialog;
