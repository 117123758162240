import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Box,
  Button,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Typography,
  Autocomplete,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import WaterCanImg from "../../../Images/WaterCanImg.png";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  EmployeesOtherPunchInOut,
  EmployeesPunchInOut,
  GetPunchinEmployees,
} from "../../Redux/Actions/supplierVehiclesActions";
import { ADMIN_ROLES } from "../../../Components/AppMeta/appMetaConfig";
import { useHistory } from "react-router-dom";
import { Add, Fingerprint, Remove } from "@mui/icons-material";
import VehicleTrips from "../VehicleTrips";
import ProductsDetailsDialog from "./ProductsDetailsDialog";
import TransferProductVehicleToVehicleMain from "./TransferProductVehicleToVehicleMain";

const EmployeesAttendance = ({ setTab }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [availableEmployees, setAvailableEmployees] = useState();

  const [searchText, setSearchText] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [valueTab, setValueTab] = React.useState("1");

  const vehicleManagement = useSelector((state) => state.vehicleManagement);
  const punchInUser = (user_id, type) => {
    dispatch(
      EmployeesOtherPunchInOut({
        type: type || "IN",
        warehouse_id: vehicleManagement.punchDetails.warehouse_id,
        user_id,
      })
    ).then(() => {
      getEmployeesPunches();
    });
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const getEmployeesPunches = () => {
    if (vehicleManagement.punchDetails.warehouse_id) {
      dispatch(
        GetPunchinEmployees({
          warehouse_id: vehicleManagement.punchDetails.warehouse_id,
        })
      ).then((data) => {
        setAvailableEmployees(data?.data);
      });
    }
  };
  useEffect(() => {
    getEmployeesPunches();
  }, [vehicleManagement.punchDetails]);
  useEffect(() => {
    if (searchText) {
      let temp = availableEmployees?.filter(
        (employee) =>
          employee?.driver_profile?.fullname
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toString()?.toLocaleLowerCase()) ||
          employee?.driver_profile?.phone
            ?.toString()
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toString()?.toLocaleLowerCase())
      );
      setFilteredEmployees(temp || []);
    } else {
      setFilteredEmployees(availableEmployees || []);
    }
  }, [availableEmployees, searchText]);
  return (
    <Box>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTab}>
              <Tab label="Attendance" value="1" />
              <Tab label="Vehicles" value="2" />
              <Tab label="Transfer" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "0px", paddingTop: "12px" }}>
            {" "}
            <Paper elevation={10} sx={{ pt: 1, pr: 1 }}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="input-with-icon-adornment">
                  Search Name, Phone...
                </InputLabel>
                <Input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  variant="outlined"
                  id="input-with-icon-adornment"
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Paper>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Paper
                elevation={5}
                sx={{
                  mt: 1,
                  mb: 1,
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                }}
              >
                <Box sx={{ fontWeight: "bold" }}>Current Warehouse:</Box>

                <Box
                  onClick={() => {
                    if (
                      vehicleManagement.punchDetails?.warehouse?.warehouse_id
                    ) {
                      history.push(`warehouse`);
                    }
                  }}
                  sx={{
                    padding: 1,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box>
                    <PersonPinCircleIcon sx={{ fontSize: "30px" }} />
                  </Box>
                  <Box>
                    {vehicleManagement.punchDetails?.warehouse?.warehouse_name}
                  </Box>
                </Box>
              </Paper>
            </Box>
            <Paper
              sx={{
                p: "3px",
              }}
            >
              <TableContainer component={Paper} elevation={10}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        py: 1,
                        justifyContent: "center",
                        textAlign: "center",
                        bgcolor: "#D6EAF8",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: 0,
                          py: 1,
                          border: "1.5px solid #D5DBDB",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 0,
                          py: 1,
                          border: "1.5px solid #D5DBDB",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Role
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 0,
                          py: 1,
                          border: "1.5px solid #D5DBDB",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        In-Time
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 0,
                          py: 1,
                          border: "1.5px solid #D5DBDB",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Out-Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredEmployees?.map((employee, index) => {
                      return (
                        <TableRow key={index} sx={{ py: 1 }}>
                          <TableCell
                            sx={{
                              padding: 0,
                              py: 1,
                              border: "1.5px solid #D5DBDB",
                              textAlign: "center",
                            }}
                          >
                            {employee?.driver_profile?.fullname}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: 0,
                              py: 1,
                              border: "1.5px solid #D5DBDB",
                              textAlign: "center",
                            }}
                          >
                            {employee.role_id === ADMIN_ROLES.BW_DELIVERYAGENT
                              ? "Driver"
                              : employee.role_id ===
                                ADMIN_ROLES.BW_SUPPLIERMANAGER
                              ? "Manager"
                              : employee.role_id ===
                                ADMIN_ROLES.BW_SUPPLIER_LOADERS
                              ? "Loader"
                              : "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: 0,
                              py: 1,
                              border: "1.5px solid #D5DBDB",
                            }}
                          >
                            {employee?.punch_details?.[0]?.in_time &&
                            !employee?.punch_details?.[0]?.out_time ? (
                              <>{employee?.punch_details?.[0]?.in_time}</>
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {/* {" "}
                          -- */}
                                <Chip
                                  onClick={() => punchInUser(employee.user_id)}
                                  //   sx={{ padding: 0, px: 1 }}
                                  variant="outlined"
                                  color="primary"
                                  //   sx={{ borderRadius: 50 }}
                                  sx={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                  }}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box>
                                        <Fingerprint />
                                      </Box>
                                      <Box>
                                        <Typography sx={{ fontWeight: "bold" }}>
                                          In
                                        </Typography>
                                      </Box>
                                    </Box>
                                  }
                                ></Chip>
                              </Box>
                            )}
                          </TableCell>
                          {/* <TableCell sx={{ padding: 0, py: 1 }}>{employee.outTime}</TableCell> */}
                          <TableCell
                            sx={{
                              padding: 0,
                              py: 1,
                              border: "1.5px solid #D5DBDB",
                              textAlign: "center",
                            }}
                          >
                            {employee?.punch_details?.[0]?.out_time ? (
                              <>{employee?.punch_details?.[0]?.out_time}</>
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {employee?.punch_details?.[0]?.in_time ? (
                                  <Chip
                                    onClick={() =>
                                      punchInUser(employee.user_id, "OUT")
                                    }
                                    //   sx={{ padding: 0, px: 1 }}
                                    variant="outlined"
                                    color="primary"
                                    //   sx={{ borderRadius: 50 }}
                                    sx={{
                                      textAlign: "center",
                                      justifyContent: "center",
                                    }}
                                    label={
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box>
                                          <Fingerprint />
                                        </Box>
                                        <Box>
                                          <Typography
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            Out
                                          </Typography>
                                        </Box>
                                      </Box>
                                    }
                                  ></Chip>
                                ) : (
                                  "--"
                                )}
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!filteredEmployees?.length && (
                  <Box sx={{ w: "100%", textAlign: "center" }}>
                    No Active Employees Found
                  </Box>
                )}
              </TableContainer>
            </Paper>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0px", paddingTop: "12px" }}>
            {" "}
            {/* <Box sx={{ width: "100%", p: 2 }}>
              <Button
                onClick={() => setTab("Vehicles")}
                sx={{ float: "right", borderRadius: 10 }}
                variant="contained"
              >
                Vehicles <ArrowForwardIosIcon />
              </Button>
            </Box> */}
            <VehicleTrips />
          </TabPanel>
          <TabPanel value="3" sx={{ padding: "0px", paddingTop: "12px" }}>
            <TransferProductVehicleToVehicleMain />
            {/* <Box>
                <Typography sx={{ textAlign: "start", mt: "10px", mb: "10px" }}>
                  Select Product :
                </Typography>
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Select Product
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedProduct}
                    label="Select Product"
                    onChange={handleOpenTransferProductsDialog}
                    sx={{
                      color: "black",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "gray",
                      },
                    }}
                  >
                   
                    <MenuItem value={10}>20_Liter</MenuItem>
                    <MenuItem value={20}>500 ML</MenuItem>
                  </Select>
                </FormControl>
              </Box> */}
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default EmployeesAttendance;
