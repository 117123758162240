import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import CustomConsole from "../../CustomConsole";
export default function AddBannerTypeDialog({
  isAddBannerTypeDialog,
  setIsAddBannerTypeDialog,
  switchInAddBannerType,
  setSwitchInAddBannerType,
  setBannerTypeName,
  bannerTypeName,
  setAddBannerTypeFlag,
}) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setSwitchInAddBannerType(event.target.checked);
  };
  const handleClose = () => {
    setIsAddBannerTypeDialog(false);
  };

  const handleAddBtn = () => {
    if (bannerTypeName !== "") {
      setAddBannerTypeFlag(true);
    } else {
      CustomConsole("enter name to proceed");
    }
  };
  return (
    <div>
      <Dialog
        open={isAddBannerTypeDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              gap: "20px",
            }}
          >
            <Box>
              <TextField
                id="outlined-basic"
                label="Banner type name"
                variant="outlined"
                value={bannerTypeName}
                onChange={(event) => setBannerTypeName(event.target.value)}
              />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label="Enabled"
                  onChange={handleChange}
                  checked={switchInAddBannerType}
                />
              </FormGroup>
            </Box>
            <Box>
              <Button variant="outlined" size="small" onClick={handleAddBtn}>
                Add
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
