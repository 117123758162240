import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TransactionsTable from "./TransactionsTable";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserDepositHistoryTransaction,
  GetUserPaymentHistory,
  GetUserWaterDrops,
  GetWalletTransactions,
  GetWaterDropTransactions,
} from "../Redux/Actions/transactionsActions";
import { Typography } from "@mui/material";
import { GetSettlementDetails } from "../Redux/Actions/buyBackOrdersAction";
import CrediNoteSettlementTable from "../../Components/Tables/CreditSettlementDetails";

function UserTransactions(props) {
  const dispatch = useDispatch();
  const adminTransactionDetails = useSelector(
    (state) => state.adminTransactionDetails
  );
  const [transactionType, setTransactionType] = React.useState(10);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [changeInPage, setChangeInPage] = React.useState(false);
  const [searchPageSize, setSearchPageSize] = React.useState(false);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [settlementDetails, setSettlementDetails] = React.useState([]);
  React.useEffect(() => {
    if (props.userId) {
      dispatch(GetUserPaymentHistory(props.userId, pageSize, pageNumber - 1));
    }
  }, [props.userId]);
  React.useEffect(() => {
    if (adminTransactionDetails.ordersTransaction.length > 0) {
      CustomConsole("Data available");
      setDisableBtn(false);
    } else {
      CustomConsole("No data");
      setDisableBtn(true);
    }
  }, [adminTransactionDetails]);

  const handleChange = (e) => {
    setTransactionType(e.target.value);
    if (e.target.value === 10) {
      setPageNumber(1);
      setPageSize(25);
      dispatch(GetUserPaymentHistory(props.userId, pageSize, pageNumber - 1));
    } else if (e.target.value === 20) {
      CustomConsole("Hit wallet API");
      setPageNumber(1);
      setPageSize(25);
      dispatch(GetWalletTransactions(props.userId, pageSize, pageNumber - 1));
    } else if (e.target.value === 30) {
      setPageNumber(1);
      setPageSize(25);
      dispatch(
        GetUserDepositHistoryTransaction(props.userId, pageSize, pageNumber - 1)
      );
    } else if (e.target.value === 40) {
      setPageNumber(1);
      setPageSize(25);
      dispatch(
        GetWaterDropTransactions(props.userId, pageSize, pageNumber - 1)
      );
      dispatch(GetUserWaterDrops(props.userId));
    } else if (e.target.value === 50) {
      setPageNumber(1);
      setPageSize(25);
      dispatch(
        GetSettlementDetails({
          user_id: props.userId,
          page_size: pageSize,
          page_number: pageNumber - 1,
        })
      ).then((data) => {
        if (!data?.error) setSettlementDetails(data?.data);
      });
      dispatch(GetUserWaterDrops(props.userId));
    }
  };
  const handleSearchPageSize = () => {
    if (transactionType === 10) {
      dispatch(GetUserPaymentHistory(props.userId, pageSize, pageNumber - 1));
    } else if (transactionType === 20) {
      dispatch(GetWalletTransactions(props.userId, pageSize, pageNumber - 1));
    } else if (transactionType === 30) {
      dispatch(
        GetUserDepositHistoryTransaction(props.userId, pageSize, pageNumber - 1)
      );
    } else if (transactionType === 40) {
      dispatch(
        GetWaterDropTransactions(props.userId, pageSize, pageNumber - 1)
      );
      // dispatch(GetUserWaterDrops(props.userId));
    }
  };

  React.useEffect(() => {
    // if (changeInPage) {
    if (transactionType === 10) {
      dispatch(GetUserPaymentHistory(props.userId, pageSize, pageNumber - 1));
    } else if (transactionType === 20) {
      CustomConsole("Hit wallet API");
      dispatch(GetWalletTransactions(props.userId, pageSize, pageNumber - 1));
    } else if (transactionType === 30) {
      dispatch(
        GetUserDepositHistoryTransaction(props.userId, pageSize, pageNumber - 1)
      );
    } else if (transactionType === 40) {
      dispatch(
        GetWaterDropTransactions(props.userId, pageSize, pageNumber - 1)
      );
      // dispatch(GetUserWaterDrops(props.userId));
    } else if (transactionType === 50) {
      dispatch(
        GetSettlementDetails({
          user_id: props.userId,
          page_size: pageSize,
          page_number: pageNumber - 1,
        })
      );
    }
  }, [pageNumber]);

  //to get settlement details based on page size
  React.useEffect(() => {
    if (transactionType === 50 && pageSize > 25) {
      dispatch(
        GetSettlementDetails({
          user_id: props.userId,
          page_size: pageSize,
          page_number: pageNumber - 1,
        })
      );
    }
  }, [pageSize]);

  React.useEffect(() => {
    CustomConsole(adminTransactionDetails);
  }, [adminTransactionDetails]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "20px",
          justifyContent: { md: "flex-end", xs: "center" },
          m: 1,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ textDecoration: "undeline", fontWeight: "bold" }}>
            TRANSACTION DETAILS
          </Typography>
        </Box>
        <FormControl size="small" sx={{ minWidth: 220, m: 1 }}>
          <InputLabel>Select transaction type</InputLabel>
          <Select
            value={transactionType}
            label="Select transaction type"
            onChange={handleChange}
          >
            <MenuItem value={10}>Orders</MenuItem>
            <MenuItem value={20}>Wallet</MenuItem>
            <MenuItem value={30}>Deposit</MenuItem>
            <MenuItem value={40}>Water Drops</MenuItem>
            <MenuItem value={50}>Buy Back Transaction</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {adminTransactionDetails.waterDropsError === false &&
        transactionType === 40 ? (
          <>
            <Box
              sx={{
                display: "Flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  justifyContent: "flex-left",
                }}
              >
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                  Influencer Earnings:{" "}
                </Typography>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                  Water Drops:{" "}
                </Typography>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                  Redeemed Amount:{" "}
                </Typography>
              </Box>
              <Box
                sx={{
                  justifyContent: "flex-left",
                }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  {adminTransactionDetails.waterDropsData.influencerEarnings !==
                  undefined
                    ? adminTransactionDetails.waterDropsData.influencerEarnings
                    : "---"}
                </Typography>
                <Typography sx={{ textAlign: "left" }}>
                  {adminTransactionDetails.waterDropsData.water_drops !==
                    undefined ||
                  adminTransactionDetails.waterDropsData.water_drops !== null ||
                  adminTransactionDetails.waterDropsData.water_drops !== ""
                    ? adminTransactionDetails.waterDropsData.water_drops
                    : "---"}
                </Typography>
                <Typography sx={{ textAlign: "left" }}>
                  {adminTransactionDetails.waterDropsData.redeemed_amount !==
                    undefined ||
                  adminTransactionDetails.waterDropsData.redeemed_amount !==
                    null ||
                  adminTransactionDetails.waterDropsData.redeemed_amount !== ""
                    ? adminTransactionDetails.waterDropsData.redeemed_amount
                    : "---"}
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          ""
        )}

        <Box>
          {adminTransactionDetails.ordersTransactionError === false &&
          transactionType !== 50 ? (
            <>
              {" "}
              <TransactionsTable
                transactionType={transactionType}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                onPaginationModelChange={setPaginationModel}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                disableBtn={disableBtn}
                setChangeInPage={setChangeInPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                setSearchPageSize={setSearchPageSize}
                handleSearchPageSize={handleSearchPageSize}
              />
            </>
          ) : (
            ""
          )}
          {transactionType === 50 && (
            <CrediNoteSettlementTable
              pageNumber={pageNumber}
              disableBtn={disableBtn}
              setChangeInPage={setChangeInPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              settlementDetails={settlementDetails}
              setPageNumber={setPageNumber}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default UserTransactions;
