/**
 * @author vinay kumar
 * @description Supplier More Setings Main
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 01-07-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import Accordion from "@mui/material/Accordion";
import CustomConsole from "../../CustomConsole";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChangePassword from "./ChangePassword";
import ScannerConnectDescriptionDialog from "../../Components/Dialog/ScannerConnectDescriptionDialog";

function MoreSettings() {
  const [openQrDetailsDialog, setOpenQrDetailsDialog] = React.useState(false);
  const handleCloseQrDetailsDialog = () => {
    setOpenQrDetailsDialog(false);
  };

  return (
    <Material.Box sx={{width: "70Vw"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            marginBottom: "1em",
            "&:hover": {
              backgroundColor: "#d4dee2",
            },
            boxShadow: 3,
            borderRadius: "6px",
            backgroundColor: "#d8e5f8de",
          }}
        >
          <Typography>Change Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChangePassword />
        </AccordionDetails>
      </Accordion>
      <Material.Paper elevation={12} sx={{marginTop:"20px", padding:"10px"}}>
        <Typography
          sx={{ color: "blue", fontWeight: "bold", fontSize: "16px" }}
        >
          QR/Barcode Scanner Pairing Process
        </Typography>
        <Material.Button
          variant="contained"
          sx={{ color: "white", fontWeight: "bold", marginTop: "10px" }}
          onClick={() => setOpenQrDetailsDialog(true)}
        >
          View
        </Material.Button>
      </Material.Paper>
      <ScannerConnectDescriptionDialog
        open={openQrDetailsDialog}
        handleClose={handleCloseQrDetailsDialog}
      />
    </Material.Box>
  );
}

export default MoreSettings;
