
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import CustomConsole from "../../CustomConsole";

function SampleButton(props) {
  const handleBtnClick = () => {
    CustomConsole("---- handleBtnClick -----");
    props.handleClick(props.data);
  };
  return (
    <Box>
      <Button
        sx={{
        background: appColors.commonBtnColor.background,
          color: "white",
        }}
        onClick={handleBtnClick}
      >
        {props.title}
      </Button>
    </Box>
  );
}

export default SampleButton;
