import React from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import AddPincodeForm from "./AddPincodeForm";
import CustomConsole from "../../CustomConsole";
import GettingPincodes from "./GettingPincodes";

const useStyles = makeStyles({

  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },

});

function Pincode() {
  const [tabValue, setTabValue] = React.useState("1");
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <TabContext value={tabValue}>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <TabList onChange={handleTabChange} className={classes.tabUi}>
            <Tab label="Get Pincodes" value="1" />
            <Tab label="Add New Pincode" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <GettingPincodes />
        </TabPanel>
        <TabPanel value="2">
          <AddPincodeForm />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default Pincode;
