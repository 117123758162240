import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  isPaymentUpdated:"",
};

const adminOrderPayReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PAY_THROUGH_WALLET:
      return {
        error: action.payload.data.error,
        msg: action.payload.data.msg,
        isPaymentUpdated: action.payload.statusFlag,
      };

    default:
      return state;
  }
};

export default adminOrderPayReducer;
