import React from "react";
import * as Material from "@mui/material";
import CanIcon from "../../Images/CanIcon.svg";
import * as MuiIcons from "@mui/icons-material";

function SupplierCompletedDeliveriesCard(props) {
//   const handleScaDetails = (event) => {
//     event.stopPropagation();
//     props.handleScaDetails(props.data);
//   };
  const handleCopyAddress = (event) => {
    event.stopPropagation();
    props.handleCopyAddress(props.data);
  };
  const handleLocation = (event) => {
    event.stopPropagation();
    props.handleLocation(props.data);
  };
  const handlePhone = (event) => {
    event.stopPropagation();
    props.handlePhone(props.data);
  };
  const handleViewDetails = () => {
    props.handleViewDetails(props.data);
  };
  return (
    <Material.Box sx={{ display: "flex", flexDirection: "row" }}>
      <Material.Paper
        sx={{ marginTop: "10px", margin: "10px", minWidth: 250 }}
        spacing={2}
        elevation={15}
        onClick={handleViewDetails}
      >
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Material.Typography sx={{ padding: "5px" }}>
            <Material.Chip
              avatar={
                <Material.Avatar
                  sx={{
                    color: "black",
                    bgcolor: "white",
                    fontWeight: "bold",
                  }}
                >
                  {props.data.order_type === "POST PAID ORDER"
                    ? "P"
                    : props.data.order_type === "INSTA DELIVERY ORDER"
                    ? "i"
                    : props.data.order_type === "SUBSCRIBED ORDER"
                    ? "S"
                    : "R"}
                </Material.Avatar>
              }
              label={props.data.order_id}
              // onClick={handleViewDetails}
              sx={{
                bgcolor:
                  props.data.order_type === "POST PAID ORDER"
                    ? "#6d147d"
                    : props.data.order_type === "INSTA DELIVERY ORDER"
                    ? "#EE4190"
                    : props.data.order_type === "SUBSCRIBED ORDER"
                    ? "#3BC2C0"
                    : "#924EEF",
                color: "white",
              }}
            />
          </Material.Typography>
          <Material.Box sx={{display:"flex",gap:"10px",padding: "10px" }}>
            <MuiIcons.Call onClick={handlePhone} sx={{ fontSize: "25px" }} />
            <MuiIcons.Place
                onClick={handleLocation}
                color="primary"
                sx={{ maxHeight: "25px", marginLeft: "15px",color:"red" }}
              />
              
          </Material.Box>
          
        </Material.Box>
        <Material.Divider orientation="horizantal" flexItem />
        <Material.Box sx={{ paddingLeft: "7px" }}>
          <Material.Box>
            <Material.Typography
              sx={{
                fontSize: "12px",
                textAlign: "start",
                // fontWeight: "bold",
              }}
            >
              {props.data.delivery_address.contact_name},
              {props.data.delivery_address.contact_phone}{" "}
              
            </Material.Typography>
            <Material.Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.data.delivery_address.address_line1},
              {props.data.delivery_address.address_line2},
              {props.data.delivery_address.address_line3}
            </Material.Typography>
          </Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box>
              <Material.Typography
                sx={{
                  fontSize: "12px",
                  textAlign: "start",
                  paddingTop: "1px",
                }}
              >
                {props.data.delivery_address.landmark},
                {props.data.delivery_address.service_area},
                {props.data.delivery_address.pincode}{" "}
              </Material.Typography>
            </Material.Box>
            <Material.Box>
              {" "}
              <MuiIcons.ContentCopy
                onClick={handleCopyAddress}
                sx={{ fontSize: "19px", marginLeft: "15px" }}
              />{" "}
             
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box>
          <Material.Divider orientation="horizontal" flexItem />
          <Material.Stack
            direction={"row"}
            justifyContent="space-evenly"
            alignItems="center"
            // divider={<Material.Divider orientation="vertical" flexItem />}
            mt={0.5}
          >
            <MuiIcons.LocalShippingOutlined />
            <Material.Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.data?.driver_profile?.fullname}
            </Material.Typography>
            <Material.Divider orientation="vertical" flexItem />
            <MuiIcons.DepartureBoardOutlined />
            <Material.Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.data.packaged_drinking_water?.delivery_slots
                ? props.data.packaged_drinking_water?.delivery_slots
                    .admin_selected_delivery_slot &&
                  props.data.packaged_drinking_water?.delivery_slots
                    .admin_selected_delivery_slot.length
                  ? props.data.packaged_drinking_water?.delivery_slots
                      .admin_selected_delivery_slot
                  : props.data.packaged_drinking_water.delivery_slots
                      .supplier_selected_delivery_slot &&
                    props.data.packaged_drinking_water?.delivery_slots
                      .supplier_selected_delivery_slot.length
                  ? props.data.packaged_drinking_water?.delivery_slots
                      .supplier_selected_delivery_slot
                  : "---"
                : "---"}
            </Material.Typography>
          </Material.Stack>
        </Material.Box>
      </Material.Paper>
    </Material.Box>
  );
}

export default SupplierCompletedDeliveriesCard;
