import * as React from "react";

import Dialog from "@mui/material/Dialog";

import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Subscription from "../Subscription/Subscription";
import { Typography } from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubscriptionDetailsDialog({
  openSubscriptionDialog,
  setOpenSubscriptionDialog,
  setOpenBookingDialog,
  setSubscriptionId,
  subscriptionId,
  editSubscriptionDialog,
  setEditSubscriptionDialog,
  editProducts,
  setEditproducts,
  selectedDays,
  setSelectedDays,
  setUpdateSubscriptonText,
  setEditingAdrs,
  editingAdrs,
  setBooktypeSub,
}) {
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleClose = () => {
    setOpenSubscriptionDialog(false);
  };

  React.useEffect(() => {
    CustomConsole("-----Admin detail action----");
    CustomConsole(addUserAdminDetails);
    if (addUserAdminDetails.userData?.[0].phone) {
      setPhoneNumber(() => addUserAdminDetails.userData?.[0].phone);
    }
  }, [addUserAdminDetails]);

  return (
    <div>
      <Dialog
        fullScreen
        open={openSubscriptionDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Subscription details
            </Typography>
          </Toolbar>
        </AppBar>
        <Subscription
          phoneNumber={phoneNumber}
          setOpenBookingDialog={setOpenBookingDialog}
          subscriptionId={subscriptionId}
          setSubscriptionId={setSubscriptionId}
          editSubscriptionDialog={editSubscriptionDialog}
          setEditSubscriptionDialog={setEditSubscriptionDialog}
          editProducts={editProducts}
          setEditproducts={setEditproducts}
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          setUpdateSubscriptonText={setUpdateSubscriptonText}
          editingAdrs={editingAdrs}
          setEditingAdrs={setEditingAdrs}
          setBooktypeSub={setBooktypeSub}
        />
      </Dialog>
    </div>
  );
}
