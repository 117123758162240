import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetBuyBackOrderDetails } from "../Redux/Actions/buyBackOrdersAction";
import BuyBackOrderDetailsCard from "../../Components/Cards/buyBackOrderDetailsCards";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FcSearch } from "react-icons/fc";
import { FirstPage, LastPage } from "@mui/icons-material";

export default function BuyBackOrderDetailsMain() {
  const dispatch = useDispatch();
  const history = useHistory();
  const buyBackOrderDetails = useSelector(
    (state) => state.buyBackOrderDetails.orderDetails
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchByOrderId, setSearchByOrderId] = useState("");
  //function to get buy back order details
  useEffect(() => {
    dispatch(
      GetBuyBackOrderDetails({
        page_number: pageNumber - 1,
        page_size: pageSize,
        order_status: 200,
      })
    );
  }, [pageNumber, pageSize]);

  //function to handel consumer phone details
  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.delivery_address.contact_phone !== "") {
      CustomConsole(props.delivery_address.contact_phone);
      const telUrl = `tel:${props.delivery_address.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  //function to copy address
  const handleLocation = (props) => {
    if (
      props.delivery_address.latitude !== undefined &&
      props.delivery_address.latitude !== null &&
      props.delivery_address.longitude !== undefined &&
      props.delivery_address.longitude !== null &&
      parseFloat(props.delivery_address.latitude) > 0 &&
      parseFloat(props.delivery_address.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props.delivery_address.latitude},${props.delivery_address.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handleCopyAddress = (props) => {
    const address_compile = ` ${props.delivery_address.contact_name},${props.delivery_address.contact_phone},${props.delivery_address.address_line1},
      ${props.delivery_address.address_line2},
      ${props.delivery_address.address_line3},
      ${props.delivery_address.service_area}-
      ${props.delivery_address.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };
  //function to handel view for single order
  const handleViewDetails = (props) => {
    const url = `/dashboard/BuyBackProductSingleOrder?order_id=${props}`;
    history.push(url);
  };

  //function to search order details
  const handleSearchOrder = () => {
    CustomConsole("------------handleSearchOrder------------");
    if (searchByOrderId !== "") {
      const url = `/dashboard/BuyBackProductSingleOrder?order_id=${
        "BBCP" + searchByOrderId
      }`;
      history.push(url);
    } else {
      toast.error("Please Enter Order ID", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  //function to handel page size change
  const handleFilterChange = (event) => {
    setPageSize(event.target.value);
  };
  return (
    <>
      <div>
        <Box
          sx={{
            margin: "10px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            gap: 1,
          }}
        >
          <TextField
            //   sx={{ borderRadius: "30px" }}
            size="small"
            // sx={{ maxWidth: "200px" }}
            label="Search Order"
            variant="outlined"
            value={searchByOrderId}
            onChange={(event) => setSearchByOrderId(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ fontWeight: "bold" }}>BPCP</Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <FcSearch size="30px" onClick={handleSearchOrder} />
                </InputAdornment>
              ),
              style: { borderRadius: "50px" },
              maxLength: 10, // Optional: You can set the maximum length if needed
              // onInput: handlePhoneChange, // Attach the input event handler
            }}
          />

          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel>Page size</InputLabel>
            <Select
              value={pageSize}
              label="Page size"
              onChange={handleFilterChange}
              name="page_size"
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            >
              <FirstPage sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }} />
            </IconButton>
            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {pageNumber}
            </Typography>
            <IconButton
              sx={{ maxHeight: "50px" }}
              onClick={() => {
                if (pageSize == buyBackOrderDetails?.length) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            >
              <LastPage
                sx={{
                  color:
                    pageSize < buyBackOrderDetails?.length ? "blue" : "gray",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ maxHeight: "640px", overflow: "auto" }}>
          <BuyBackOrderDetailsCard
            buyBackOrderDetails={buyBackOrderDetails}
            handlePhone={handlePhone}
            handleLocation={handleLocation}
            handleCopyAddress={handleCopyAddress}
            handleViewDetails={handleViewDetails}
          />
        </Box>
      </div>
    </>
  );
}
