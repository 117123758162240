import actionType from "./actionType";

export const setLoading = (isLoading) => ({
  type: actionType.SET_LOADING,
  payload: isLoading,
});

export const updateLoading = (isLoading) => ({
  type: actionType.SET_UPDATE_LOADING,
  payload: isLoading,
});

