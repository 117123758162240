import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import appUpadte from "../Assests/Images/appUpdateIcon.svg";
import { appColors } from "../Assests/AppTheme/appThemeConst";
import { ADMIN_ROLES } from "../AppMeta/appMetaConfig";
function AppForceUpdateDialog({ forceUpdateOpen, setForceUpdateOpen }) {
  const handelUpdate = () => {
    window.location.reload();
  };
  const handleClose = () => {
    setForceUpdateOpen(false);
  };
  return (
    <>
      <Dialog
        open={forceUpdateOpen}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle>
          <img
            alt="bookwater"
            src={appUpadte}
            style={{ height: "7rem", width: "13rem", objectFit: "contain" }}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            color: appColors.commonTextColor,
            fontSize: "1.2rem",
            textAlign: "center",
          }}
        >
          Please Update The App!
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            variant="contained"
            size="small"
            onClick={handelUpdate}
            sx={{ bgcolor: appColors.commonBtnColor }}
          >
            Update
          </Button>
          {ADMIN_ROLES.BW_SUPERADMIN ===
            parseInt(window.localStorage.getItem("roleId"), 10) ||
          ADMIN_ROLES.BW_ADMIN ===
            parseInt(window.localStorage.getItem("roleId"), 10) ? (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AppForceUpdateDialog;
