import { Add, LocalShipping, Remove, SyncAlt } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import WaterCanImg from "../../Images/WaterCanImg.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";

import { GetVehicleCurrentStatus } from "../Redux/Actions/supplierVehiclesActions";
export default function VehicleProductSwapDialog(props) {
  const dispatch = useDispatch();
  const [availableProducts, setAvailableProducts] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [filled, setFilled] = React.useState(0);
  const [empty, setEmpty] = React.useState(0);
  const [selectedProduct, setSelectedProduct] = React.useState();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [IsLoading, setIsLoading] = React.useState(true);

  // useEffect(() => {
  //   dispatch(GetVehicleCurrentStatus({ tanker_id: "TN03AB003" }));
  // });
  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };

  const handleTransferProducts = () => {
    props.handleTransferProducts();
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <Dialog
      open={props.openVehicelProductSwap}
      fullScreen
      sx={{ m: "0px", p: "0px" }}
    >
      <DialogContent overflow="auto" sx={{ m: "0px", p: "0px" }}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ gap: "10px" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              sx={{ bgcolor: "red" }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ display: "flex", gap: "3px", mt: "6px" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Products Transfer
              </Typography>
              <Typography>
                <LocalShipping />
                <SyncAlt /> <LocalShipping />
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: "10px" }}>
          <Accordion sx={{ bgcolor: "#D0D3D4", color: "black" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <LocalShipping /> Vehicle Details
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={{ md: 2, xs: 0 }}
                sx={{ padding: "10px" }}
              >
                <Grid sx={{ fontWeight: "bold" }} item md={6} xs={12}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Reg.No</Typography>
                    <Typography>
                      87654567{/* {vehicleDetails.tanker_id} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Capacity
                    </Typography>
                    <Typography>
                      223{/* {vehicleDetails?.capacity}(SKU) */}
                    </Typography>
                  </Box>
                </Grid>
                <Grid sx={{ fontWeight: "bold" }} item md={6} xs={12}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Model</Typography>
                    <Typography>
                      AMM{/* {vehicleDetails?.tanker_profile?.model} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}> Make</Typography>
                    <Typography>
                      MAH{/* {vehicleDetails?.tanker_profile?.make} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {" "}
                      Assigned Orders
                    </Typography>
                    <Typography>{100}</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {" "}
                      Last Delivered Order
                    </Typography>
                    <Typography>
                      BTORD-765434
                      {/* {vehicleDetails?.trip_details?.recent_order
                    ? vehicleDetails?.trip_details?.recent_order
                    : "--"} */}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Grid container sx={{ bgcolor: "#F7F9F9", pl: 1 }}>
                    <Grid sx={{ fontWeight: "bold" }} item xs={3}>
                      Driver
                    </Grid>
                    <Grid item xs={9} sx={{}}>
                      Sesuraj Driver(6547462312)
                      {/* {vehicleCurrentStatus?.employees?.map((item) => {
                    if (item?.role_id == ADMIN_ROLES.BW_DELIVERYAGENT) {
                      return (
                        <Box>
                          {item?.driver_profile?.fullname}(
                          {item?.driver_profile?.phone})
                        </Box>
                      );
                    }
                    return <></>;
                  })} */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container sx={{ bgcolor: "#F2F3F4", pl: 1 }}>
                    <Grid sx={{ fontWeight: "bold" }} item xs={3}>
                      Loaders
                    </Grid>
                    <Grid item xs={9} sx={{}}>
                      Loader 1(6455456465)
                      {/* {vehicleCurrentStatus?.employees?.map((item) => {
                    if (item?.role_id == ADMIN_ROLES.BW_SUPPLIER_LOADERS) {
                      return (
                        <Box>
                          {item?.driver_profile?.fullname}(
                          {item?.driver_profile?.phone})
                        </Box>
                      );
                    }
                    return <></>;
                  })} */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Grid container spacing={{ md: 2, xs: 0 }} sx={{ padding: "10px" }}>
          <Grid item md={12} xs={12}>
            <Paper elevation={5}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        padding: "0px",
                        border: "1px solid black",
                        bgcolor: "#ECF0F1",
                      }}
                      colSpan={4}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Current Vehicle Stock
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid black" }}>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Products
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Empty
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Filled
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Damaged
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      20 Liter-PET
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      10
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      20
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      10
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      500 ML
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      10
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      20
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      7
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid sx={{ fontWeight: "bold" }} item md={12} xs={12}>
            <Paper
              sx={{
                // display: "flex",
                width: "100%",
                // justifyContent: "center",
                gap: 1,
                p: 1,
                pt: 2,
                mt: 2,
                border: "1px solid black",
              }}
              elevation={5}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "3px",
                  alignContent: "center",
                  justifyContent: "center",
                  borderBottom: "1.5px solid black",
                  mb: "10px",
                  bgcolor: "#ECF0F1",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Transfer Products
                </Typography>
                <Typography>
                  <LocalShipping />
                  <SyncAlt /> <LocalShipping />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    mb: 2,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {/* <FormControl sx={{ width: 220 }} fullWidth size="small">
                    <InputLabel>Select Vehicle Number</InputLabel>
                    <Select
                      //   key={selectedProduct}

                      value={selectedProduct}
                      label={"Select Vehicle Number"}
                      onChange={(e) => setSelectedProduct(e.target.value)}
                      sx={{
                        color: "black",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "gray",
                        },
                      }}
                    >
                   
                      <MenuItem value={10}>TN09BF8769</MenuItem>
                      <MenuItem value={20}>TN09BF8769</MenuItem>
                      <MenuItem value={30}>TN09BF8766</MenuItem>
                      <MenuItem value={40}>TN09BF8764</MenuItem>
                    </Select>
                  </FormControl> */}
                  <Autocomplete
                    sx={{ width: "200px", fontSize: "16px" }}
                    value={selectedCountry}
                    // options={countries?.map((item, index) => {
                    //   return { name: item.name, code: item.code, id: item.id };
                    // })}
                    options={[]}
                    defaultValue={selectedCountry}
                    autoHighlight
                    getOptionLabel={(nameSearch) => `${selectedCountry} `}
                    // defaultValue={countries[0]?.name}
                    onChange={(_, newValue) => {
                      setSelectedCountry(newValue?.country || ""); // Set the selected  as the value
                    }}
                    renderOption={(props, item) => {
                      return (
                        <MenuItem
                          onClick={() => setSelectedCountry(item.country)}
                          key={item}
                          value={item.country}
                        >
                          {item.country}
                        </MenuItem>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={"Search Vehicle Number"}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "country", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Box>
                {/* {selectedProduct === 0 || selectedProduct ? ( */}

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: "",
                    gap: "10px",
                  }}
                >
                  {/* {orderProducts !== undefined &&
                  orderProducts !== null &&
                  orderProducts.length > 0 ? ( */}
                  <>
                    {/* {orderProducts?.map((data, index) => {
                        let extra_qty = extraReturnProducts?.filter(
                          (extra_product) =>
                            extra_product.capacity === data.capacity &&
                            extra_product.material === data.material
                        )?.[0]?.quantity;
                        return ( */}
                    {selectedProduct === 10 ||
                    selectedProduct === 20 ||
                    selectedProduct === 30 ||
                    selectedProduct === 40 ? (
                      <Paper
                        sx={{
                          padding: "10px",
                          width: { xs: "100%", md: "50%" },
                          borderRadius: "10px",
                          bgcolor: "#F4F6F7",
                        }}
                        elevation={5}
                      >
                        {/* <Checkbox {...label} /> */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            // bgcolor: "#CCD1D1",
                            bgcolor: "#ECF0F1",
                            pl: "5px",
                            gap: "10px",
                            // pr: "5px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#21618C",
                              fontWeight: "bold",
                            }}
                          >
                            {"20-LITER PET BOTTLE"}
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              textAlign: "end",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            ({"20_Liter"} - {"PET"})
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box sx={{ mt: "40px" }}>
                            <img
                              src={WaterCanImg}
                              alt="img"
                              style={{
                                height: "120px", // Set the desired  height
                                width: "60px", // Set the desired width
                                objectFit: "cover",
                                mt: "10px",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      textAlign: "center",
                                      mt: "8px",
                                    }}
                                  >
                                    Empty :
                                  </Typography>
                                </Box>
                                <Box>
                                  {" "}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // gap: "5px",
                                      border: "1px solid black",
                                      height: "30px",
                                    }}
                                  >
                                    <Box>
                                      <IconButton
                                      // onClick={() =>
                                      //   handleReturnChange(
                                      //     data.material,
                                      //     data.capacity,
                                      //     orderId,
                                      //     false,
                                      //     data?.return,
                                      //     index,
                                      //     (extra_qty || 1) - 1 //extra_return_products
                                      //   )
                                      // }
                                      // disabled={extra_qty ? false : true}
                                      >
                                        <Remove />
                                      </IconButton>
                                    </Box>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ border: "1px solid black" }}
                                    />
                                    <Box>
                                      {/* {editingProduct == index ? ( */}
                                      <TextField
                                        size="small"
                                        type="number"
                                        autoFocus={true}
                                        sx={{ width: "60px" }}
                                        // onChange={(event) => {
                                        //   let qty = parseInt(
                                        //     event.target.value || 0
                                        //   );
                                        //   let extra =
                                        //     qty - parseInt(data?.return);
                                        //   setExtraReturnQty(qty);
                                        //   console.log(qty, extra, "OUT");
                                        //   handleReturnChange(
                                        //     data.material,
                                        //     data.capacity,
                                        //     orderId,
                                        //     false,
                                        //     data?.return,
                                        //     index,
                                        //     extra //extra_return_products
                                        //   );

                                        // }}
                                        // inputProps={{
                                        //   min: minReturn,
                                        //   style: {
                                        //     height: "10px",
                                        //     width: "20px",
                                        //   },
                                        // }}
                                      />
                                      {/* ) : (
                                      <Box
                                        sx={{ padding: "20px" }}
                                        onClick={() => setEditingProduct(index)}
                                      >
                                        {parseInt(data.return || 0) +
                                          parseInt(extra_qty || 0)}
                                      </Box>
                                    )} */}
                                    </Box>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ border: "1px solid black" }}
                                    />
                                    <Box>
                                      <IconButton
                                      // onClick={() =>
                                      //   handleReturnChange(
                                      //     data.material,
                                      //     data.capacity,
                                      //     orderId,
                                      //     false,
                                      //     data.return,
                                      //     index,
                                      //     (extra_qty || 0) + 1 //extra_return_products
                                      //   )
                                      // }
                                      >
                                        <Add />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      textAlign: "center",
                                      mt: "8px",
                                    }}
                                  >
                                    Filled :
                                  </Typography>
                                </Box>
                                <Box>
                                  {" "}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // gap: "5px",
                                      border: "1px solid black",
                                      height: "30px",
                                    }}
                                  >
                                    <Box>
                                      <IconButton
                                      // onClick={() =>
                                      //   handleReturnChange(
                                      //     data.material,
                                      //     data.capacity,
                                      //     orderId,
                                      //     false,
                                      //     data?.return,
                                      //     index,
                                      //     (extra_qty || 1) - 1 //extra_return_products
                                      //   )
                                      // }
                                      // disabled={extra_qty ? false : true}
                                      >
                                        <Remove />
                                      </IconButton>
                                    </Box>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ border: "1px solid black" }}
                                    />
                                    <Box>
                                      {/* {editingProduct == index ? ( */}
                                      <TextField
                                        size="small"
                                        type="number"
                                        autoFocus={true}
                                        sx={{ width: "60px" }}
                                        // onChange={(event) => {
                                        //   let qty = parseInt(
                                        //     event.target.value || 0
                                        //   );
                                        //   let extra =
                                        //     qty - parseInt(data?.return);
                                        //   setExtraReturnQty(qty);
                                        //   console.log(qty, extra, "OUT");
                                        //   handleReturnChange(
                                        //     data.material,
                                        //     data.capacity,
                                        //     orderId,
                                        //     false,
                                        //     data?.return,
                                        //     index,
                                        //     extra //extra_return_products
                                        //   );

                                        // }}
                                        // inputProps={{
                                        //   min: minReturn,
                                        //   style: {
                                        //     height: "10px",
                                        //     width: "20px",
                                        //   },
                                        // }}
                                      />
                                      {/* ) : (
                                      <Box
                                        sx={{ padding: "20px" }}
                                        onClick={() => setEditingProduct(index)}
                                      >
                                        {parseInt(data.return || 0) +
                                          parseInt(extra_qty || 0)}
                                      </Box>
                                    )} */}
                                    </Box>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ border: "1px solid black" }}
                                    />
                                    <Box>
                                      <IconButton
                                      // onClick={() =>
                                      //   handleReturnChange(
                                      //     data.material,
                                      //     data.capacity,
                                      //     orderId,
                                      //     false,
                                      //     data.return,
                                      //     index,
                                      //     (extra_qty || 0) + 1 //extra_return_products
                                      //   )
                                      // }
                                      >
                                        <Add />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      textAlign: "center",
                                      mt: "8px",
                                    }}
                                  >
                                    Damaged :
                                  </Typography>
                                </Box>
                                <Box>
                                  {" "}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // gap: "5px",
                                      border: "1px solid black",
                                      height: "30px",
                                    }}
                                  >
                                    <Box>
                                      <IconButton
                                      // onClick={() =>
                                      //   handleReturnChange(
                                      //     data.material,
                                      //     data.capacity,
                                      //     orderId,
                                      //     false,
                                      //     data?.return,
                                      //     index,
                                      //     (extra_qty || 1) - 1 //extra_return_products
                                      //   )
                                      // }
                                      // disabled={extra_qty ? false : true}
                                      >
                                        <Remove />
                                      </IconButton>
                                    </Box>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ border: "1px solid black" }}
                                    />
                                    <Box>
                                      {/* {editingProduct == index ? ( */}
                                      <TextField
                                        size="small"
                                        type="number"
                                        autoFocus={true}
                                        sx={{ width: "60px" }}
                                        // onChange={(event) => {
                                        //   let qty = parseInt(
                                        //     event.target.value || 0
                                        //   );
                                        //   let extra =
                                        //     qty - parseInt(data?.return);
                                        //   setExtraReturnQty(qty);
                                        //   console.log(qty, extra, "OUT");
                                        //   handleReturnChange(
                                        //     data.material,
                                        //     data.capacity,
                                        //     orderId,
                                        //     false,
                                        //     data?.return,
                                        //     index,
                                        //     extra //extra_return_products
                                        //   );

                                        // }}
                                        // inputProps={{
                                        //   min: minReturn,
                                        //   style: {
                                        //     height: "10px",
                                        //     width: "20px",
                                        //   },
                                        // }}
                                      />
                                      {/* ) : (
                                      <Box
                                        sx={{ padding: "20px" }}
                                        onClick={() => setEditingProduct(index)}
                                      >
                                        {parseInt(data.return || 0) +
                                          parseInt(extra_qty || 0)}
                                      </Box>
                                    )} */}
                                    </Box>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      sx={{ border: "1px solid black" }}
                                    />
                                    <Box>
                                      <IconButton
                                      // onClick={() =>
                                      //   handleReturnChange(
                                      //     data.material,
                                      //     data.capacity,
                                      //     orderId,
                                      //     false,
                                      //     data.return,
                                      //     index,
                                      //     (extra_qty || 0) + 1 //extra_return_products
                                      //   )
                                      // }
                                      >
                                        <Add />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: "10px",
                              }}
                            >
                              <Box>
                                <Button
                                  onClick={() => handleTransferProducts()}
                                  variant="contained"
                                >
                                  Load
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    ) : (
                      ""
                    )}
                    {/* );
                      })} */}
                  </>
                  {/* ) : (
                    "No Order Details Found"
                  )} */}
                </Box>

                {/* {(selectedProduct === 0 || selectedProduct) &&
              (filled || empty) ? ( */}
              </Box>

              {/* ) : (
                <></>
              )} */}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
