import actionType from "../Actions/actionType";

import CustomConsole from "../../../CustomConsole";
const initialState = {
  error: true,
  msg: "",
  containerDetails: [],
  updateMsg: "",
  depositedList: [],
  updateActiveDeactiveMsg: "",
};

const adminContainerDetailsReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.GET_CONTAINER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        containerDetails: action.payload.data,
      };
    case actionType.UPDATE_CONTAINER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        updateMsg: action.payload.msg,
      };
    case actionType.GET_DEPOSIT_LIST:
      return {
        ...state,
        error: action.payload.error,
        depositedList: action.payload.data,
      };
    case actionType.UPDATE_ACTIVE_DEACTIVE_CANS:
      return {
        ...state,
        error: action.payload.error,
        updateActiveDeactiveMsg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default adminContainerDetailsReducer;
