export const Style={
    boxStyle:{
        marginBottom: "1em",
        "&:hover": {
          backgroundColor: "#d8e5f8de",
        },
        boxShadow: 3,
        borderRadius: '6px' ,
        backgroundColor:"#d4dee2",
        
    }
}