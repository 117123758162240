import React from "react";
import * as Material from "@mui/material";
import { useDispatch } from "react-redux";
import Html5QrcodePlugin1 from "../../../Pages/wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../../../Pages/wfsHome/ResultContainerPlugin1";
import {
  CompleteReplacementOrder,
  GetSupplierReplacementCanOrders,
  PutLinkInScanQrBarCode,
} from "../../../Pages/Redux/Actions/supliersAction";
import { PutSupplierOutScanFilledCans } from "../../../Pages/Redux/Actions/supliersAction";
import { GetOrderDetailsById } from "../../../Pages/Redux/Actions/adminReplacementOrderAction";
import CustomConsole from "../../../CustomConsole";
import { Button } from "@mui/base";
import { QrCode2 } from "@mui/icons-material";

function ReplacementCameraDialog(props) {
  CustomConsole(props);
  const dispatch = useDispatch();
  const [outScanBtn, setOutScanBtn] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scanCount, setScanCount] = React.useState(0);
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [openQrBarCodeCamera, setOpenQrBarCodeCamera] = React.useState(false);
  const [openOutScanCamera, setOpenOutScanCamera] = React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [qrBarcodeDamageCheckBox, setQrBarcodeDamageCheckBox] =
    React.useState(true);
  console.log(props.reasonData.selectReason);
  const [inScanBtn, setInScanBtn] = React.useState(false);
  const [scanOption, setScanOption] = React.useState("scanner");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  React.useEffect(() => {
    //Scanned Qr Code
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    //Scanned Bar Code
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    console.log(props.reasonData.selectReason);
    if (scannedQrCode !== "" || scannedBarCode !== "") {
      // const reqObj = {
      //   is_filled_can: true || undefined,
      //   order_id: props.orderId,
      //   qr_barcode_id1: scannedQrCode || scannedBarCode,
      //   qr_barcode_id2: "",
      //   type_of_can: parseInt(props.reasonData.selectReason),
      // };
      const reqObj = {
        in: [
          {
            qr: scannedQrCode || scannedBarCode,
            is_reuseable: props.reasonData.selectReason,
          },
        ],
      };
      CustomConsole(reqObj);
      // dispatch(PutLinkInScanQrBarCode(reqObj));
      dispatch(CompleteReplacementOrder(props.orderId, reqObj));
      props.setReplacementInScanBtn(true);
      props.setOpenCameraDialog(false);
      setOpenQrBarCodeCamera(false);
      setTimeout(() => {
        dispatch(GetOrderDetailsById(props.orderId));
      }, 500);
      setScannedQrCode("");
      setScannedBarCode("");
      // setLinkingDialogOpen(false);
      // setScanCount(0);
      setDecodedResults([]);
      // setLink(false);
      // setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    // const reqObj = {
    //   order_id: props.orderId,
    //   qr_id: scannedQrCodeDetails,
    // };
    const reqObj = {
      out: [scannedQrCodeDetails],
    };
    CustomConsole(reqObj);
    // dispatch(PutLinkInScanQrBarCode(reqObj));

    if (scannedQrCodeDetails !== "") {
      // dispatch(PutSupplierOutScanFilledCans(reqObj));
      dispatch(CompleteReplacementOrder(props.orderId, reqObj));
      setOpenOutScanCamera(false);
      props.setOpenCameraDialog(false);
      //   setReplacementOutScanBtn(true);
      props.setScanQrCodeDetailBtn(false);
      setTimeout(() => {
        dispatch(GetOrderDetailsById(props.orderId));
      }, 500);
      setTimeout(() => {
        dispatch(
          GetSupplierReplacementCanOrders({
            page_number: props.pageNumber,
            page_size: props.pageSize,
          })
        );
      }, 1000);
      setScannedQrCodeDetails("");
    }
  }, [scannedQrCodeDetails]);

  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("-------------inScan---------------");
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole("-----------outScan--------------");
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeDetails(decodedText);
    }
  };
  const handleClose = () => {
    CustomConsole("-------------onClose------------");
    props.handleClose();
  };
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      if (props.openQrBarCodeCamera === true) {
        setScannedQrCode(qrBarcodeId);
        setDecodedResults((prev) => [...prev, qrBarcodeId]);
      } else {
        setScanQrCodeDetailBtn(true);
        setScannedQrCodeDetails(qrBarcodeId);
        setDecodedResults([]);
      }

      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);
  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };
  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };

  const handleCompleteReplacement = (decodedResults) => {
    const reqObj = {
      in: [{ qr: scannedQrCode || scannedBarCode, is_reuseable: false }],
      out: ["OIUYT"],
    };
  };
  const handleQrBarcodeDamageChecked = (event) => {
    CustomConsole("----checked-----QrBarCode------");
    CustomConsole(event.target.checked);
    setQrBarcodeDamageCheckBox(event.target.checked);
  };

  const handleResetScan = () => {
    const reqObj = {
      is_reset: true,
    };
    dispatch(CompleteReplacementOrder(props.orderId, reqObj));
    props.setOpenCameraDialog(false);
    setTimeout(() => {
      dispatch(GetOrderDetailsById(props.orderId));
    }, 500);
  };

  return (
    <Material.Box>
      <Material.Dialog open={props.openCameraDialog} onClose={handleClose}>
        <Material.DialogContent sx={{ height: "400px" }}>
          {/* <Material.Box>
            <Material.Checkbox
              defaultChecked
              value={qrBarcodeDamageCheckBox}
              onChange={handleQrBarcodeDamageChecked}
              {...label}
            />{" "}
            is reusable
          </Material.Box> */}
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // height: "100%",
              // padding: "10px",
            }}
          >
            <Material.FormControl sx={{ marginTop: "15px" }}>
              <Material.FormLabel>Select Scanning Option</Material.FormLabel>
              <Material.RadioGroup
                defaultValue="scanner"
                value={scanOption}
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={handleSelectScanOption}
              >
                <Material.FormControlLabel
                  value="scanner"
                  control={<Material.Radio />}
                  label="Scanner Device"
                />
                <Material.FormControlLabel
                  value="camera"
                  control={<Material.Radio />}
                  label="Camera"
                />
              </Material.RadioGroup>
            </Material.FormControl>
            {scanOption === "camera" ? (
              props.openQrBarCodeCamera === true ? (
                <Material.Box>
                  <Html5QrcodePlugin1
                    fps={1}
                    qrbox={200}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                  />
                </Material.Box>
              ) : (
                <Material.Box>
                  <Html5QrcodePlugin1
                    fps={1}
                    qrbox={200}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResultQrCode}
                  />
                </Material.Box>
              )
            ) : (
              <Material.TextField
                onChange={handleQrBarcodeIdFromScanner}
                label="QR/Barcode:"
                value={qrBarcodeId}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Material.Box>
          <Material.Box
            sx={{ display: "flex", justifyContent: "flex-start", mt: "20px" }}
          >
            <Material.Button
              variant="contained"
              color="error"
              onClick={handleResetScan}
            >
              reset
              <QrCode2 />
            </Material.Button>
          </Material.Box>
          {/* <Material.Box>
            <Material.Button
              onClick={() => handleCompleteReplacement(decodedResults)}
            >
              complete
            </Material.Button>
          </Material.Box> */}

          {/* {props.openQrBarCodeCamera === true && scanOption === "camera" ? (
            <Material.Box>
              <Html5QrcodePlugin1
                fps={1}
                qrbox={200}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            </Material.Box>
          ) : null}
          <Material.Box>
            {props.openOutScanCamera === true && scanOption === "camera" ? (
              <>
                {" "}
                <Html5QrcodePlugin1
                  fps={1}
                  qrbox={200}
                  disableFlip={false}
                  qrCodeSuccessCallback={onNewScanResultQrCode}
                />
              </>
            ) : (
              ""
            )}
          </Material.Box> */}

          {/* {props.openQrBarCodeCamera === true ? (
            <Material.Box>
              <Html5QrcodePlugin1
                fps={1}
                qrbox={200}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            </Material.Box>
          ) : null}
          <Material.Box>
            {props.openOutScanCamera === true ? (
              <>
                {" "}
                <Html5QrcodePlugin1
                  fps={1}
                  qrbox={200}
                  disableFlip={false}
                  qrCodeSuccessCallback={onNewScanResultQrCode}
                />
              </>
            ) : (
              ""
            )}
          </Material.Box> */}
          <ResultContainerPlugin1
            results={decodedResults}
            setScanCount={setScanCount}
          />
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default ReplacementCameraDialog;
