import {
  Cancel,
  EmojiEventsOutlined,
  SubtitlesOutlined,
  Verified,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomConsole from "../../CustomConsole";
import moment from "moment";
import React from "react";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { useDispatch, useSelector } from "react-redux";
import { PlayAGame } from "../Redux/Actions/gamificationActions";

function CampDetailsTableView(props) {
  const dispatch = useDispatch();
  const [userReferral, setUserReferral] = React.useState("");
  const [promoterEmail, setPromoterEmail] = React.useState("");
  const [salesRepEmail, setSalesRepEmail] = React.useState("");
  const [selectedRowId, setSelectedRowId] = React.useState(0);

  const gamificationStore = useSelector((state) => state.gamificationReducer);
  React.useEffect(() => {
    CustomConsole("-- GameActionCard PlayAGame Response ---");
    CustomConsole(gamificationStore.playAGame);
    if (
      !gamificationStore.error &&
      gamificationStore.playAGame.responseId &&
      gamificationStore.playAGame.responseId.length
    ) {
      setUserReferral("");
      setPromoterEmail("");
      setSalesRepEmail("");
    }
  }, [gamificationStore.playAGame]);
  React.useEffect(() => {
    CustomConsole("-- props --");
    CustomConsole(props);
  }, [props]);
  const handleUserGameAction = (gameData) => {
    CustomConsole("--- handleUserGameAction ---");
    CustomConsole(gameData);
    let gameActionObject = {
      sales_rep_email: salesRepEmail,
      promoter_email: promoterEmail,
      action_id: gameData.row.gameDetails.action_id || "",
      reward_id: gameData.row.gameDetails.reward_id || "",
      consumer_referral: userReferral,
      campaign_id: gameData.row.gameDetails.campaignId,
      fromDate: gameData.row.gameDetails.from_date,
      toDate: gameData.row.gameDetails.to_date,
    };
    CustomConsole(gameActionObject);
    if (props.salesRep.length) {
      gameActionObject.sales_rep_email = props.salesRep + "@bookwater.com";
    }
    CustomConsole("-- Hit API here --");
    CustomConsole(gameActionObject);
    props.setOpenProgressBar(true);
    dispatch(PlayAGame(gameActionObject));
  };

  const headerData = [
    { field: "campName", headerName: "Campaign Name", width: 130 },
    { field: "gameName", headerName: "Game Name", width: 130 },
    localStorage.getItem("roleId") &&
    parseInt(localStorage.getItem("roleId")) !== ADMIN_ROLES.BW_PROMOTER
      ? {
          field: "promoterEmail",
          headerName: "Promoters Email",
          width: 200,
          renderCell: (params) => {
            return (
              <FormControl variant="standard" fullWidth>
                <InputLabel>Promoter Email</InputLabel>
                <Select
                  value={selectedRowId === params.id ? promoterEmail : ""}
                  label="Promoter Email"
                  onChange={(e) => {
                    setSelectedRowId(params.id);
                    setPromoterEmail(e.target.value);
                  }}
                >
                  {params.row.promoterEmail.map((email, index) => {
                    return (
                      <MenuItem key={index} value={email}>
                        {email}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          },
        }
      : "",
    localStorage.getItem("roleId") &&
    parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_PROMOTER
      ? {
          field: "salesRepEmail",
          headerName: "Sales Rep. Email",
          width: 200,
          renderCell: (params) => {
            return (
              <FormControl variant="standard" fullWidth>
                <InputLabel>Sales Rep. Email</InputLabel>
                <Select
                  value={selectedRowId === params.id ? salesRepEmail : ""}
                  label="Sales Rep. Email"
                  onChange={(e) => {
                    CustomConsole(e.target.value);
                    setSelectedRowId(params.id);
                    setSalesRepEmail(e.target.value);
                  }}
                >
                  {params.row.salesRepEmails &&
                    params.row.salesRepEmails.map((email, index) => {
                      return (
                        <MenuItem key={index} value={email}>
                          {email}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            );
          },
        }
      : "",
    {
      field: "referralId",
      headerName: "Referral ID",
      width: 200,
      renderCell: (params) => {
        return (
          <TextField
            variant="standard"
            label="Referral Code"
            value={selectedRowId === params.id ? userReferral : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SubtitlesOutlined />
                </InputAdornment>
              ),
            }}
            onChange={(event) =>
              setUserReferral(
                event.target.value.toUpperCase().replace(/\s/g, "")
              )
            }
            // error={
            //   selectedRowId === params.id && userReferral.length > 7
            //     ? false
            //     : true
            // }
            sx={{
              margin: "10px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "blue",
            }}
          />
        );
      },
    },
    {
      field: "rewardUser",
      headerName: "Reward User",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            disabled={
              selectedRowId === params.id && userReferral.length > 7
                ? false
                : true
            }
            variant="contained"
            sx={{
              backgroundImage:
                selectedRowId === params.id && userReferral.length > 7
                  ? "linear-gradient(#021636,#0c4aad)"
                  : "gray",
              "&:hover": {
                backgroundImage: "linear-gradient(#6b8df2,#021636)",
              },
            }}
            startIcon={<EmojiEventsOutlined />}
            onClick={() => handleUserGameAction(params)}
          >
            Reward User
          </Button>
        );
      },
    },
    { field: "rewardPoints", headerName: "Reward Points", width: 130 },
    { field: "salesPoints", headerName: "Sales Points", width: 130 },
    { field: "campLocation", headerName: "Campaign Location", width: 130 },
    { field: "gameDescription", headerName: "Game Description", width: 130 },
    { field: "startDate", headerName: "Start Date", width: 130 },
    { field: "endDate", headerName: "End Date", width: 130 },
    { field: "id", headerName: "SNO", width: 90 },
  ];
  let rows = [];
  let indexValue = 0;
  props.data.map((campDetails, index) => {
    campDetails.userActionsAndRewards.map((gameDetails, index) => {
      indexValue = indexValue + 1;
      let playAGameDetails = gameDetails;
      playAGameDetails.campaignId = campDetails.campaignId;
      playAGameDetails.from_date = campDetails.startDate;
      playAGameDetails.end_date = campDetails.endDate;
      let campAndGameDetails = {
        campName: campDetails.name,
        id: indexValue,
        campLocation: campDetails.location + "," + campDetails.subLocation,
        gameName: gameDetails.name,
        gameDescription: gameDetails.description,
        rewardPoints: gameDetails.reward_points,
        salesPoints: gameDetails.action_points,
        promoterEmail: campDetails.promoterEmails,
        salesRepEmails: campDetails.salesRepEmails,
        startDate: moment(campDetails.startDate).format("DD/MM/YYYY"),
        endDate: moment(campDetails.endDate).format("DD/MM/YYYY"),
        gameDetails: playAGameDetails,
      };
      rows.push(campAndGameDetails);
    });
  });

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#a3a1e6",
            color: "black",
            fontWeight: "bold",
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });
  React.useEffect(() => {}, [selectedRowId]);
  const handleRowClick = (params) => {
    CustomConsole("-- handleRowClick ---");
    CustomConsole(params.row.id);
    setSelectedRowId(params.row.id);
  };
  return (
    <Box
      sx={{
        height: 520,
        maxWidth: { xs: "300px", sm: "300px", md: "100%", lg: "100%" },
        overflow: "auto",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={rows}
          columns={headerData}
          autoHeight
          hideFooter={true}
          onRowClick={handleRowClick}
          sx={{
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </ThemeProvider>
    </Box>
  );
}

export default CampDetailsTableView;

const formStyles = {
  formSelect: {
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  inputLabel: { fontSize: "14px", fontWeight: "bold", color: "blue" },
  mainBox4: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
  },
  mainBox3: {
    marginTop: "10px",
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
    alignItems: "center",
    gap: "15px",
  },
  rewardText: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "blue",
  },
  rewardBtn: {
    marginTop: { xs: "15px", sm: "15px", md: "25px", lg: "25px" },
    "&:hover": {
      backgroundImage: "linear-gradient(#6b8df2,#021636)",
    },
  },
  mainBox2: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
    gap: "20px",
  },
  contentBox: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
  questionText: {
    textAlign: "start",
    color: "blue",
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  answerText: {
    textAlign: "center",
    color: "black",
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  mainBox: {
    ddisplay: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "15px",
    width: "100%",
  },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  boxContent: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
    justifyContent: "center",
    gap: "15px",
  },
  headerText: {
    color: "brown",
    fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" },
    fontWeight: "bold",
    textAlign: "start",
  },
};
