import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
import useAxios from "../../Axios/useAxios";

export const GetAdminReports = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      console.log("----API Called reports---");
      let response = await api.get(`/SSM/reports/available/list`);
      console.log("API Response:", response);

      if (response.status === httpStatus.SUCCESS) {
        console.log("Payload to Dispatch:", response.data);
        dispatch({
          type: actionType.GET_ADMIN_REPORTS,
          payload: response.data,
        });
      }
      return response.data;
    } catch (error) {
      CustomConsole(error);
      console.error("Error fetching reports:", error);
    }
  };
};

export const GetReport = (reportId, params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async () => {
    try {
      console.log("----API Called to get report---");

      // Prepare the payload for the API request
      let payload = {
        id: reportId,
        query: params,
      };

      console.log("Payload for API Call:", payload); // Added log for payload

      // Make the API request
      let response = await api.post(`/SSM/reports/get-report`, payload);
      console.log("API Response:", response);
      console.log("API Response Data:", response.data);
      if (response.status === httpStatus.SUCCESS) {
        console.log("Report data:", response.data);
        return response.data; // Ensure this is the correct data
      } else {
        console.error("Failed to fetch report:", response.status);
        return null; // Return null or an empty value on failure
      }
    } catch (error) {
      console.error("Error fetching report:", error);
      return null; // Return null or handle the error as needed
    }
  };
};

// export const GetReport = async (reportId, params) => {
//   const httpStatus = {
//     SUCCESS: 200,
//     BAD_REQUEST: 400,
//   };

//   let api = useAxios();

//   try {
//     console.log("----API Called to get report---");

//     // Prepare the payload for the API request
//     let payload = {
//       id: reportId,
//       query: params,
//     };

//     console.log("Payload for API Call:", payload); // Added log for payload

//     // Make the API request
//     let response = await api.post(`/SSM/reports/get-report`, payload);
//     console.log("API Response:", response);
//     console.log("API Response Data:", response.data);
//     if (response.status === httpStatus.SUCCESS) {
//       console.log("Report data:", response.data);
//       return response.data; // Ensure this is the correct data
//     } else {
//       console.error("Failed to fetch report:", response.status);
//       return null; // Return null or an empty value on failure
//     }
//   } catch (error) {
//     console.error("Error fetching report:", error);
//     return null; // Return null or handle the error as needed
//   }
// };
