import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { url } from "../apis";
import React from "react";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

//function for getting wfsdetails
export const CrateInscanSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`QSM/crates/inscan/ec_order`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CRATE_INSCAN_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for getting wfsdetails
export const CrateOutscanSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`QSM/crates/outscan/ec_order?`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CRATE_OUTSCAN_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for qrless product inscan wfsdetails
export const QrlessProductInScan = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`OM/operations/instock`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.QRLESSS_INSCAN,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for qrless product inscan wfsdetails
export const QrlessProductOutScan = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`OM/operations/outstock`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.QRLESSS_OUTSCAN,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
