import React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import * as MuiIcons from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import moment from "moment";
import RejectAcceptSettlement from "./RejectAcceptSettlement";
import {
  RequestForDetails,
  SettlementRequestList,
  SettlementRequestReject,
} from "../Redux/Actions/adminSettlementAction";
import RequestListFilteredDialog from "./RequestListFilterdDialog.jsx";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GetProfileDetails } from "../Redux/Actions/userDetailsAdminAction";
// import RequestListFilteredDialog from "./requestListFilterdDialog";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: "0px",
  },

  tableContainer: {
    overflowX: "auto",
    "& .MuiTable-root": {
      width: "100%",
      tableLayout: "fixed",
    },
    "& .MuiTableCell-root": {
      padding: "20px",
    },
    maxHeight: "300px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00B7EB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AdminSettlementRequestTable(props) {
  const settlementsDetails = useSelector((state) => state.settlementsDetails);
  const dispatch = useDispatch();
  const [rejectAcceptDialog, setRejectAcceptDialog] = React.useState(false);
  const [rejectBtn, setRejectBtn] = React.useState(false);
  const [settleBtn, setSettleBtn] = React.useState(false);
  const [settlementMasterId, setSettlementMasterId] = React.useState("");
  const [requestedPhone, setRequestedPhone] = React.useState("");
  const [requestedUserId, setRequestedUserId] = React.useState("");
  const [requestedName, setRequestedName] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("ASC");

  const [filterBtn, setFilterBtn] = React.useState(false);
  const classes = useStyles();

  const handleClickTable = (settlementMasterId, phone, fullname, userId) => {
    setSettlementMasterId(settlementMasterId);
    setRequestedPhone(phone);
    setRequestedUserId(userId);
    setRequestedName(fullname);
    setRejectAcceptDialog(true);
    CustomConsole();
  };

  React.useEffect(() => {
    CustomConsole(filterBtn);
    if (filterBtn === true) {
      dispatch(
        SettlementRequestList(orderBy, props.tsStatus, props.pageNumber - 1)
      );
      setFilterBtn(false);
    }
  }, [filterBtn]);

  // const pageNumberBackward = () => {
  //   if (props.pageNumber - 1 > 0) {
  //     props.setPageNumber(props.pageNumber - 1);
  //     // setdisableBtn(false);
  //     props.setSearchBtnFlag(true);
  //   }
  // };
  // const pageNumberForward = () => {
  //   // if (allOrders.length > 0) {
  //   props.setPageNumber(props.pageNumber + 1);
  //   props.setSearchBtnFlag(true);
  //   // }
  // };
  React.useEffect(() => {
    if (settleBtn === true) {
      dispatch(RequestForDetails(requestedPhone));
      dispatch(GetProfileDetails(requestedUserId));
      props.setApiFlag1(true);
      setSettleBtn(false);
      setRejectAcceptDialog(false);
      setTimeout(() => {
        dispatch(
          SettlementRequestList(orderBy, props.tsStatus, props.pageNumber - 1)
        );
      }, 1000);
    } else if (rejectBtn === true) {
      const reqObj = {
        settlement_master_id: settlementMasterId,
      };
      dispatch(SettlementRequestReject(reqObj));
      setTimeout(() => {
        dispatch(
          SettlementRequestList(orderBy, props.tsStatus, props.pageNumber - 1)
        );
      }, 1000);
      setRejectBtn(false);
      setRejectAcceptDialog(false);
    }
  }, [settleBtn, rejectBtn]);

  return (
    <Box sx={{ maxWidth: { xs: 350, lg: 1, md: 1, sm: 1 } }}>
      {/* {settlementsDetails.requestError === false ? ( */}
      <>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
            gap: "10px",
          }}
        >
          {" "}
          <Box>
            <Button variant="outlined" onClick={() => setOpenFilter(true)}>
              Filter
            </Button>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  if (props.pageNumber - 1 > 0) {
                    props.setPageNumber(() => props.pageNumber - 1);
                  }
                }}
              >
                <MuiIcons.FirstPage
                  sx={{ color: props.pageNumber - 1 > 0 ? "blue" : "gray" }}
                />
              </IconButton>
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                {props.pageNumber}
              </Typography>
              <IconButton
                sx={{ maxHeight: "50px" }}
                onClick={() => {
                  if (settlementsDetails.requestListData?.length) {
                    props.setPageNumber(() => props.pageNumber + 1);
                  }
                }}
              >
                <MuiIcons.LastPage
                  sx={{
                    color: settlementsDetails.requestListData?.length
                      ? "blue"
                      : "gray",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              sx={{ minWidth: 700 }}
              stickyHeader
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">User Type</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "210px" }}>
                    Email{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">Requested On</StyledTableCell>
                  <StyledTableCell align="center">
                    Settlement Price
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {settlementsDetails.requestListData !== undefined &&
              settlementsDetails.requestListData !== null &&
              settlementsDetails.requestListData.length > 0 ? (
                <TableBody>
                  {settlementsDetails.requestListData.map((val, idx) => {
                    return (
                      <StyledTableRow
                        key={idx}
                        onClick={() =>
                          handleClickTable(
                            val.settlement_master_id,
                            val.phone,
                            val.fullname,
                            val.user_id
                          )
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow: "none",
                            color: "red",
                            backgroundColor: "#036bfc",
                            "& > *": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <StyledTableCell align="center">
                          {val.fullname}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {val.user_type === ADMIN_ROLES.BW_WFSADMIN
                            ? "WFS"
                            : val.user_type === ADMIN_ROLES.BW_DISTRIBUTER
                            ? "Distributor"
                            : val.user_type === ADMIN_ROLES.BW_CONSUMER &&
                              "Consumer"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {val.phone}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            width: "100%",
                          }}
                        >
                          {val.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(val.requested_at).format("DD/MM/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {parseInt(val.price_per_wc) *
                            parseInt(val.total_wc_qty)}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              ) : (
                "No Data Available"
              )}
            </Table>
          </TableContainer>
          {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageNumberBackward}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Box>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {props.pageNumber}
              </Typography>
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageNumberForward}
                  disabled={props.disableBtn}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>
            </Box> */}
        </Box>
        <RejectAcceptSettlement
          rejectAcceptDialog={rejectAcceptDialog}
          setRejectAcceptDialog={setRejectAcceptDialog}
          rejectBtn={rejectBtn}
          settleBtn={settleBtn}
          setRejectBtn={setRejectBtn}
          setSettleBtn={setSettleBtn}
          requestedName={requestedName}
          tsStatus={props.tsStatus}
          setTsStatus={props.setTsStatus}
        />
        <RequestListFilteredDialog
          setOpenFilter={setOpenFilter}
          openFilter={openFilter}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          tsStatus={props.tsStatus}
          setTsStatus={props.setTsStatus}
          setFilterBtn={setFilterBtn}
        />
      </>
      {/* ) : (
        ""
      )} */}
    </Box>
  );
}

export default AdminSettlementRequestTable;
