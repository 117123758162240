/**
 * @author vinay kumar
 * @description Admin Deliceries Beat Plan
 * @copyright Bookwater tech pvt ltd
 * @Date 09-08-2023
 */

import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  // getSalesRepDetails: [],
  getProIndiaCampaignDetails:[],
  postProIndiaRewardCustomer:{},
  getProIndiaSalesRevenueDetails:[]
};

const MarketingReducer = (state = initialState, action) => {
    CustomConsole(action);
    switch (action.type) {
      // case actionType.GET_SALES_REP_DETAILS:
      //   return {
      //     ...state,
      //     error: action.payload.error,
      //     msg: action.payload.msg,
      //     getSalesRepDetails: action.payload.data,
      //   };
        case actionType.GET_PRO_INDIA_CAMPAIGN_DETAILS:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
          getProIndiaCampaignDetails: action.payload.data,
        };
        case actionType.POST_PRO_INDIA_REWARD_CUSTOMER:
          return {
            ...state,
            error: action.payload.error,
            msg: action.payload.msg,
            postProIndiaRewardCustomer: action.payload.data,
          };
          case actionType.GET_PRO_INDIA_SALES_REVENUE_DETAILS:
            return {
              ...state,
              error: action.payload.error,
              msg: action.payload.msg,
              getProIndiaSalesRevenueDetails: action.payload.data,
            };
      default:
        return state;
    }
  };
  export default  MarketingReducer