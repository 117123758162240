import React from "react";
import * as Material from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { replacementCanOrdersStatus } from "../AppMeta/appMetaConfig";

function ReplacementFilterDialog(props) {
  // const [orderStatus, setOrderStatus] = React.useState(
  //   replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED
  // );

  const handleClose = () => {
    props.handleClose();
  };
  const handleFilter = () => {
    props.handleFilter();
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openFilterDialog}>
        <Material.DialogContent>
          <Material.Typography sx={{ marginBottom: "20px" }}>
            Filter Based On Status
          </Material.Typography>
          <Material.Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel>Order Status</InputLabel>
              <Select
                value={props.orderStatus}
                label="Order Status"
                onChange={(event) => props.setOrderStatus(event.target.value)}
              >
                <MenuItem
                  value={replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED}
                >
                  ORDER REQUESTED
                </MenuItem>
                <MenuItem
                  value={
                    replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
                  }
                >
                  ADMIN ACCEPTED
                </MenuItem>
                <MenuItem
                  value={
                    replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_REJECTED
                  }
                >
                  ADMIN REJECTED
                </MenuItem>
                <MenuItem
                  value={replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED}
                >
                  ORDER STARTED
                </MenuItem>
                <MenuItem
                  value={replacementCanOrdersStatus.CAN_REPLACE_ORDER_FINISHED}
                >
                  ORDER DELIVERED
                </MenuItem>
              </Select>
            </FormControl>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "10px",
            }}
          >
            <Material.Button variant="contained" onClick={handleClose}>
              Close
            </Material.Button>
            <Material.Button variant="contained" onClick={handleFilter}>
              Filter
            </Material.Button>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default ReplacementFilterDialog;
