import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { styles } from "./cardStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomConsole from "../../CustomConsole";
import { toast } from "react-toastify";

function ReturnCanFormView(props) {
  CustomConsole(props);
  const history = useHistory();
  const [singleSelectedData, setSingleSelectedData] = React.useState("");
  const [valueOrderId, setValueOrderId] = React.useState("");
  React.useEffect(() => {}, [props]);

  const handleChangeOrderId = (event) => {
    const selectedOrdersData = props.data.find(
      (data) => data.return_can_order_id === event.target.value
    );
    CustomConsole(selectedOrdersData);
    setSingleSelectedData(selectedOrdersData);
    setValueOrderId(event.target.value);
  };
  //   const handleViewDetails = () => {
  //     if (singleSelectedData.ec_order_id) {
  //       const url = `/dashboard/WfsSingleOrder?order_id=${singleSelectedData.ec_order_id}`;
  //       history.push(url);
  //     } else {
  //       toast.error("Please Select Order Id", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   };
  return (
    <Material.Paper elevation={14} sx={{ padding: "20px", bgcolor: "#E9F7EF" }}>
      <Material.Box>
        <Material.Box
          sx={{
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            gap: "10px",
          }}
        >
          <Material.Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                alignItems: "center",
              }}
            >
              <Material.InputLabel
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Order ID:
              </Material.InputLabel>
              <Material.Select
                variant="standard"
                sx={{ fontSize: "16px", fontWeight: "bold", width: "190px" }}
                value={valueOrderId}
                label="Order Id"
                onChange={handleChangeOrderId}
              >
                {props.data &&
                  props.data.length &&
                  props.data.map((orderIds, index) => {
                    return (
                      <Material.MenuItem
                        key={index}
                        value={orderIds.return_can_order_id}
                      >
                        {orderIds.return_can_order_id}
                      </Material.MenuItem>
                    );
                  })}
              </Material.Select>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Id </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {props.data.return_can_order_id}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Container Quantity </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {props.data.container_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Status </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {props.data.order_status === 200 ? "APPROVED" : ""}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Container Material </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {props.data.container_material}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Container Capacity </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {props.data.container_capacity}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>
                  Received Container Qty{" "}
                </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {props.data.received_container_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        {/* {singleSelectedData !== "" ? (
          <Material.Box>
            <Material.Button variant="contained" onClick={handleViewDetails}>
              view Details
            </Material.Button>
          </Material.Box>
        ) : (
          ""
        )} */}
      </Material.Box>
    </Material.Paper>
  );
}

export default ReturnCanFormView;
