import React from "react";
import { styled } from "@mui/material/styles";
import * as IconName from "react-icons/fc";
import * as Material from "@mui/material";
import { styles } from "./ecoStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { toast } from "react-toastify";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableRow from "@mui/material/TableRow";
import * as MuiIcons from "@mui/icons-material";
import moment from "moment";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import {
  Button,
  Card,
  CardContent,
  Pagination,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GetEmptyCanOrders,
  GetOrderIdDetails,
} from "../Redux/Actions/adminEmptyCanOrderAction";
import { Box } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import SingleWFsOrderDetailsDialog from "./SingleWFsOrderDetailsDialog";
import WFSFilterDialog from "./WFSFilterDialog";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import WfsEmptyOrderFilter from "../../Components/Dialog/wfsOrderFilterDialog";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "azure",
//     color: "black",
//     fontWeight: 12,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const TableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));
// const useStyles = makeStyles(() => ({
//   // root: {
//   //   flexGrow: 1,
//   //   padding: "0px",
//   // },

//   // tableContainer: {
//   //   overflowX: "auto",
//   //   "& .MuiTable-root": {
//   //     width: "100%",
//   //     tableLayout: "fixed",
//   //   },
//   //   maxHeight: "90vh",
//   //   "& .MuiTableCell-root": {
//   //     padding: "10px",
//   //   },
//   // },
//   tableRow: {
//     "&:last-child td, &:last-child th": { border: 0 },
//     cursor: "pointer",
//     "&:hover": {
//       boxShadow: "none",
//       backgroundColor: "#036bfc",
//     },
//   },
// }));

function DistributerEcoTable({
  setWFSTable,
  setDistributerPgNo,
  distributerPgNo,
  scrollToBottom,
  setEmpDetcard,
  pageNumber,
  setPageNumber,
  scrollToWfsTable,
  scrollWFSTable,
  wfsTable,
}) {
  const [enableForBtn, setEnableForBtn] = React.useState(false);
  const [enableBakBtn, setEnableBakBtn] = React.useState(true);
  const [openWFSOrdersDialog, setOpenWFSOrdersDialog] = React.useState(false);
  const [openFilterWFSDialog, setOpenFilterWFSDialog] = React.useState(false);
  const [searchOrderId, setSearchOrderId] = React.useState("");
  const [pageSize, setPageSize] = React.useState(100);
  const [testArr, setTestArr] = React.useState([]);
  const [eCOrderId, setECOrderId] = React.useState("");
  const [singleIdData, setSingleIdData] = React.useState({});
  const [selectedOrderType, setSelectedOrderType] = React.useState({
    sort_order: "DESC",
    page_size: 25,
    page_number: 0,
    wfs_id: "",
    user_phone: "",
    ec_order_id: "",
    address_id: "",
    from_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    is_updated_date: true,
    order_status: "",
  });
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const dispatch = useDispatch();

  // const emptyCanOrderDetails = useSelector((state) => state.emptyCanOrderData);
  const emptyCanOrderDetails = useSelector((state) => state.skuWfsDetails);
  CustomConsole(emptyCanOrderDetails);
  const emptyCanData = useSelector(
    (state) => state.skuWfsDetails.emptyCanDetails
  );
  CustomConsole(emptyCanData);

  React.useEffect(() => {
    // dispatch(GetEmptyCanOrders(pageNumber - 1, pageSize));
    dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  }, [selectedOrderType.page_number]);

  // React.useEffect(() => {
  //   setSingleIdData(emptyCanOrderDetails.emptyCanDetails);
  // }, [emptyCanOrderDetails]);

  const pageChangeForward = () => {
    if (emptyCanData?.length == selectedOrderType.page_size) {
      setSelectedOrderType((prev) => ({
        ...prev,
        page_number: prev.page_number + 1,
      }));
    }
  };

  const pageChangeBackward = () => {
    if (selectedOrderType.page_number > 0) {
      setSelectedOrderType((prev) => ({
        ...prev,
        page_number: prev.page_number - 1,
      }));
    }
  };

  const settingOrderId = (data) => {
    // dispatch(GetOrderIdDetails(id));
    // setECOrderId(id);
    dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: data.ec_order_id }));
    setSingleIdData(data);
    setOpenWFSOrdersDialog(true);
    setEmpDetcard(true);
    setWFSTable(false);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };
  const handleViewDetails = () => {
    // dispatch(GetOrderIdDetails("ECON" + searchOrderId));
    CustomConsole("BTORD-" + searchOrderId);
    if (searchOrderId) {
      const orderId = searchOrderId.trim().toUpperCase();
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: "ECON" + orderId }));
      emptyCanOrderDetails.emptyCanDetails[0].map((data) => {
        return setSingleIdData(data);
      });
      setOpenWFSOrdersDialog(true);
      // setEmpDetcard(true);
    } else {
      toast.error("Please Enter Order Id", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    // setListOrderFlag(true);
    // setSearchOrderId("");
  };
  let result = [];
  function toCSV(items) {
    if (items) {
      items.map((a) => {
        result.push({
          ec_order_id: a.ec_order_id,
          product:
            a.order_details !== undefined && a.order_details !== null
              ? a.order_details.container_material +
                "-" +
                a.order_details.container_capacity
              : "-",
          otp: a.otp ? a.otp : "-",
          order_status: a.order_status,
          order_qty:
            a.order_details !== undefined && a.order_details !== null
              ? a.order_details.order_qty
              : 0,
          return_qty:
            a.order_details !== undefined && a.order_details !== null
              ? a.order_details.return_qty
              : 0,
          damaged_qty:
            a.order_details !== undefined && a.order_details !== null
              ? a.order_details.damaged_qty
              : 0,
          expired_qty:
            a.order_details !== undefined && a.order_details !== null
              ? a.order_details.expired_qty
              : 0,
        });
        // result.push(a);
      });
    }
    setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setSelectedOrderType((prev) => ({
      ...prev,
      page_size: event.target.value,
    }));
  };
  const handleSearchPageSize = () => {
    dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  };
  const handleCloseWFSOrderDialog = () => {
    setOpenWFSOrdersDialog(false);
    dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  };
  const handleOpenFilterDialog = () => {
    setOpenFilterWFSDialog(!openFilterWFSDialog);
  };
  //function for handling filter submit
  const handelFilterSubmit = () => {
    setOpenWFSOrdersDialog(false);
    dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  };

  //function for handling view icon
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };

  return (
    <Box sx={{ maxWidth: { xs: 360, lg: 1, md: 1, sm: 1 } }}>
      <Box
        sx={{
          // margin: "10px",
          display: "flex",
          justifyContent: { md: "flex-end", xs: "flex-start" },
          gap: "2px",
          flexWrap: "wrap",
          mr: { md: "0px", xs: "20px" },
        }}
      >
        <Box
          sx={{
            // m: 1,
            display: "flex",
            // justifyContent: "flex-end",
            gap: { md: "10px", xs: "10px" },
            m: { md: "0px", xs: "10px" },
          }}
        >
          <Box>
            <Material.TextField
              label="Search By Order Id"
              size="small"
              value={searchOrderId}
              onChange={(event) => setSearchOrderId(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <Material.Typography
                      sx={{ fontWeight: "bold", color: "black" }}
                    >
                      ECON
                    </Material.Typography>
                  </Material.InputAdornment>
                ),
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <IconName.FcSearch
                      cursor="pointer"
                      size="30px"
                      onClick={handleViewDetails}
                    />
                  </Material.InputAdornment>
                ),
                style: {
                  borderRadius: "50px",
                  minWidth: { md: "220px", xs: "160px" },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              maxWidth: { md: "120px", xs: "120px" },
              maxHeight: "10px",
              // margin: { xs: 4 },
              // marginTop: 1,
            }}
          >
            <Material.TextField
              size="small"
              value={selectedOrderType.page_size}
              label="Page size"
              // onChange={(e) => {
              //   setPageSize(e.target.value);
              //   // setSearchOrderFlag(true);
              // }}
              InputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handleChangePageSize, // Attach the input event handler
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <MuiIcons.Search
                      cursor="pointer"
                      size="30px"
                      onClick={handleSearchPageSize}
                    />
                  </Material.InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gap: "15px",
            // padding: "10px",
            // marginTop: "10px",
          }}
        >
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={pageChangeBackward}
              // disabled={enableBakBtn}
            >
              <ArrowBackIosIcon />
            </Button>
          </Box>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              background:
                "radial-gradient(rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
              color: "white",
              padding: "5px",
              width: "40px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {selectedOrderType.page_number + 1}
          </Typography>
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={pageChangeForward}
              disabled={enableForBtn}
            >
              <ArrowForwardIosIcon />
            </Button>
          </Box>
        </Box>
        <Box>
          <Button variant="contained" onClick={handleOpenFilterDialog}>
            filter
          </Button>
        </Box>
        <Box>
          <CSVLink {...csvLink}>
            {" "}
            <DownloadIcon
              sx={{ fontSize: 35 }}
              onClick={() => toCSV(emptyCanData)}
            />
          </CSVLink>
        </Box>
      </Box>
      <Box
        sx={{
          // maxWidth: { md: "150vw", xs: "350px" },
          overflow: "auto",
        }}
      >
        <TableContainer component={Paper} sx={{ maxHeight: "600px" }}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" size="small"></TableCell>
                <TableCell align="center" size="small">
                  <strong>S.no</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Order Id</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Material</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Capacity</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>OTP</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Status</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Order Qty</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Return Qty</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Damage Qty</strong>
                </TableCell>
                <TableCell align="center" size="small">
                  <strong>Expiry Qty</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emptyCanData !== undefined &&
              emptyCanData !== null &&
              emptyCanData?.length > 0 ? (
                <>
                  {emptyCanData.map((row, key) => (
                    <>
                      <TableRow
                        key={row.ec_order_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#036bfc",
                          },
                        }}
                      >
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handelIcon(key)}
                          >
                            {openFlag ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {key + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {row.ec_order_id}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {row.order_details !== undefined &&
                          row.order_details !== null
                            ? row.order_details.container_material
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {row.order_details !== undefined &&
                          row.order_details !== null
                            ? row.order_details.container_capacity
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {row.otp ? row.otp : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          // sx={{
                          //   color:
                          //     row.ec_order_status === 100
                          //       ? "blue"
                          //       : row.ec_order_status === 200
                          //       ? "green"
                          //       : row.ec_order_status === 300
                          //       ? "red"
                          //       : row.ec_order_status === 400
                          //       ? "yellow"
                          //       : row.ec_order_status === 501
                          //       ? "black"
                          //       : row.ec_order_status === 502
                          //       ? "red"
                          //       : null,
                          // }}
                        >
                          {/* {row.ec_order_status === 100
                          ? "New Request"
                          : row.ec_order_status === 200
                          ? "Completed"
                          : row.ec_order_status === 300
                          ? "Cancelled"
                          : row.ec_order_status === 400
                          ? "Payment Pending"
                          : row.ec_order_status === 501
                          ? "Admin Accepted"
                          : row.ec_order_status === 502
                          ? "Admin Rejected"
                          : null} */}
                          {row.order_status}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {row.order_details !== undefined &&
                          row.order_details !== null
                            ? row.order_details.order_qty
                            : 0}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {row.order_details !== undefined &&
                          row.order_details !== null
                            ? row.order_details.return_qty
                            : 0}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {row.order_details !== undefined &&
                          row.order_details !== null
                            ? row.order_details.damaged_qty
                            : 0}
                        </TableCell>
                        <TableCell
                          align="center"
                          size="small"
                          onClick={() => {
                            settingOrderId(row);
                          }}
                        >
                          {row.order_details !== undefined &&
                          row.order_details !== null
                            ? row.order_details.expired_qty
                            : 0}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={12}
                        >
                          {/* <Collapse in={open} timeout="auto" unmountOnExit > */}
                          {open === key && openFlag === true ? (
                            <Material.Box sx={{ margin: 1 }}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                              >
                                Crates
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow sx={appColors.commonLayoutColor}>
                                    <TableCell size="small">
                                      <strong>Material</strong>
                                    </TableCell>
                                    <TableCell size="small">
                                      <strong>Capacity</strong>
                                    </TableCell>
                                    <TableCell size="small">
                                      <strong>Order Qty</strong>
                                    </TableCell>
                                    <TableCell size="small">
                                      <strong>Return Qty</strong>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row?.order_details?.crates.length > 0 ? (
                                    <>
                                      {row?.order_details?.crates.map(
                                        (data) => (
                                          <TableRow key={data?.id}>
                                            <TableCell size="small">
                                              {data.container_material}
                                            </TableCell>
                                            <TableCell size="small">
                                              {data.container_capacity}
                                            </TableCell>
                                            <TableCell size="small">
                                              {data.order_qty}
                                            </TableCell>
                                            <TableCell size="small">
                                              {data.return_qty}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    "No More Crate Details Available"
                                  )}
                                </TableBody>
                              </Table>
                            </Material.Box>
                          ) : (
                            ""
                          )}
                          {/* </Collapse> */}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </>
              ) : (
                "No Data Found"
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* <Pagination
            count={1}
            page={pageNumber}
            onChange={pageChange}
            color="primary"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "15px",
            }}
            
          /> */}
      <SingleWFsOrderDetailsDialog
        openWFSOrdersDialog={openWFSOrdersDialog}
        handleCloseWFSOrderDialog={handleCloseWFSOrderDialog}
        singleIdData={singleIdData}
        setDistributerPgNo={setDistributerPgNo}
        distributerPgNo={distributerPgNo}
        scrollToWfsTable={scrollToWfsTable}
        scrollWFSTable={scrollWFSTable}
        setOpenWFSOrdersDialog={setOpenWFSOrdersDialog}
        setWFSTable={setWFSTable}
        wfsTable={wfsTable}
      />
      <WfsEmptyOrderFilter
        open={openFilterWFSDialog}
        onClose={handleOpenFilterDialog}
        onSubmit={handelFilterSubmit}
        selectedOrderType={selectedOrderType}
        setSelectedOrderType={setSelectedOrderType}
      />
    </Box>
  );
}

export default DistributerEcoTable;
