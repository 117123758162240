import { Box, Button, Typography, Grid } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BannerDetailsFilterDialog from "../AdminUsers/BannerDetailsFilterDialog";
import CustomConsole from "../../CustomConsole";
import {
  GetAllBanners,
  GetBannerTypeList,
  GetBannerTypes,
} from "../Redux/Actions/adminBannersAction";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AllBannersTable from "./AllBannersTable";
import BannerTypeFilterDialog from "./BannerTypeFilerDialog";
import BannerTypesTable from "./BannerTypesTable";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
function AdminBanners() {
  const dispatch = useDispatch();
  const bannersData = useSelector((state) => state.adminBannersData);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [createdAt, setCreatedAt] = React.useState("DESC");
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [bannerTypes, setBannerTypes] = React.useState([]);
  const [allBanners, setAllBanners] = React.useState([]);
  const [addBannerTypeFlag, setAddBannerTypeFlag] = React.useState(false);
  const [bannerTypeList, setBannerTypeList] = React.useState([]);
  const [bannerTypeFilterDialog, setBannerTypeFilterDialog] =
    React.useState(false);
  const [bannerDetailsFilterDialog, setBannerDetailsFilterDialog] =
    React.useState(false);
  const [bannerTypeFilterFlag, setBannerTypeFilterFlag] = React.useState(false);
  const [bannerDetailsFilterFlag, setBannerDetailsFilterFlag] =
    React.useState(false);
  const [listEmpty, setListEmpty] = React.useState(false);
  const [addListEmpty, setAddListEmpty] = React.useState(false);
  const [tabValueBanners, setTabValueBanners] = React.useState("BannersType");

  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  React.useEffect(() => {
    CustomConsole("Banners data");
    CustomConsole(bannersData);
    if (bannersData.error === false) {
      if (bannersData.getBannerTypes.length) {
        setBannerTypes(bannersData.getBannerTypes);
        setAllBanners(bannersData.getAllBannersData);
        setBannerTypeList(bannersData.bannerTypeListData);
        setListEmpty(false);
      }
    } else {
      setListEmpty(true);
    }
  }, [bannersData]);

  React.useEffect(() => {
    if (bannersData.error === false) {
      if (bannersData.getBannerTypes.length) {
        setAddListEmpty(false);
      }
    } else {
      setAddListEmpty(true);
    }
  }, [bannersData]);
  React.useEffect(() => {
    dispatch(GetBannerTypes(pageNumber - 1, createdAt, isEnabled));
    dispatch(GetAllBanners(pageNumber - 1, createdAt, isEnabled));
    dispatch(GetBannerTypeList());
  }, [addBannerTypeFlag]);

  React.useEffect(() => {
    if (bannerTypeFilterFlag) {
      dispatch(GetBannerTypes(pageNumber - 1, createdAt, isEnabled));
      setBannerTypeFilterFlag(false);
      setBannerTypeFilterDialog(false);
    }
  }, [bannerTypeFilterFlag]);

  React.useEffect(() => {
    if (bannerDetailsFilterFlag) {
      dispatch(GetAllBanners(pageNumber - 1, createdAt, isEnabled));
      setBannerDetailsFilterFlag(false);
      setBannerDetailsFilterDialog(false);
    }
  }, [bannerDetailsFilterFlag]);

  const handleFilterBannerType = () => {
    setBannerTypeFilterDialog(true);
  };

  const handleFilterBannerDetails = () => {
    CustomConsole("click");
    setBannerDetailsFilterDialog(true);
  };
  const handleChangeTabValueBanners = (event, newValue) => {
    setTabValueBanners(newValue);
  };
  return (
    <>
      <TabContext
        value={tabValueBanners}
        variant="scrollable"
        scrollButtons={false}
        
      >
        <TabList
          onChange={handleChangeTabValueBanners}
          // className={classes.tabUi}
          sx={{ bgcolor: "#AED6F1" }}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab label="Banners Type" value="BannersType" />
          <Tab label="Banners" value="Banners" />
        </TabList>

        <TabPanel value="BannersType" sx={{padding: "10px"}}>
          {ADMIN_ROLES.BW_ADMIN === adminRole ||
          ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
          ADMIN_ROLES.BW_CSHEAD === adminRole ||
          ADMIN_ROLES.BW_CSLEAD === adminRole ? (
            <Box>
              <BannerTypesTable
                bannerTypes={bannerTypes}
                pageNumber={pageNumber}
                addBannerTypeFlag={addBannerTypeFlag}
                setAddBannerTypeFlag={setAddBannerTypeFlag}
                createdAt={createdAt}
                isEnabled={isEnabled}
                setPageNumber={setPageNumber}
                listEmpty={listEmpty}
                setBannerTypeFilterDialog={setBannerTypeFilterDialog}
              />
            </Box>
          ) : (
            <Box sx={{ marginTop: "50px" }}>
              <Alert severity="error">Access Denied</Alert>
            </Box>
          )}
        </TabPanel>
        <TabPanel value="Banners" sx={{padding: "10px"}}>
          {ADMIN_ROLES.BW_ADMIN === adminRole ||
          ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
          ADMIN_ROLES.BW_CSHEAD === adminRole ||
          ADMIN_ROLES.BW_CSLEAD === adminRole ? (
            <Box>
              <AllBannersTable
                allBanners={allBanners}
                bannerTypeList={bannerTypeList}
                setBannerDetailsFilterFlag={setBannerDetailsFilterFlag}
                createdAt={createdAt}
                isEnabled={isEnabled}
                addListEmpty={addListEmpty}
                setBannerDetailsFilterDialog={setBannerDetailsFilterDialog}
              />
            </Box>
          ) : (
            <Box sx={{ marginTop: "50px" }}>
              <Alert severity="error">Access Denied</Alert>
            </Box>
          )}
        </TabPanel>
      </TabContext>
      {/* {ADMIN_ROLES.BW_ADMIN === adminRole ||
      ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
      ADMIN_ROLES.BW_CSLEAD === adminRole ? (
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box
            sx={{
              marginTop: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "auto"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                padding: "10px",
              }}
            >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                BANNER TYPES
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleFilterBannerType}
              >
                Filter
              </Button>
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "flex-end",
                // marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                BANNERS
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleFilterBannerDetails}
              >
                Filter
              </Button>
            </Box>
            <Box>
              <AllBannersTable
                allBanners={allBanners}
                bannerTypeList={bannerTypeList}
                setBannerDetailsFilterFlag={setBannerDetailsFilterFlag}
                createdAt={createdAt}
                isEnabled={isEnabled}
                addListEmpty={addListEmpty}
                setBannerTypeFilterDialog={setBannerTypeFilterDialog}
              />
            </Box>
          </Grid>

          </Box>
        
          </Box>
        </Grid>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )} */}
      {bannerTypeFilterDialog ? (
        <>
          <BannerTypeFilterDialog
            bannerTypeFilterDialog={bannerTypeFilterDialog}
            setBannerTypeFilterDialog={setBannerTypeFilterDialog}
            setCreatedAt={setCreatedAt}
            setIsEnabled={setIsEnabled}
            setBannerTypeFilterFlag={setBannerTypeFilterFlag}
          />
        </>
      ) : (
        ""
      )}

      {bannerDetailsFilterDialog ? (
        <>
          <BannerDetailsFilterDialog
            bannerDetailsFilterDialog={bannerDetailsFilterDialog}
            setBannerDetailsFilterDialog={setBannerDetailsFilterDialog}
            setCreatedAt={setCreatedAt}
            setIsEnabled={setIsEnabled}
            setBannerDetailsFilterFlag={setBannerDetailsFilterFlag}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AdminBanners;
