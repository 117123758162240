import React, { useState } from "react";
import { Box, Paper, Typography, Button } from "@mui/material";
import { grey, blue, red } from "@mui/material/colors";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import ReduceWalletAmountDialog from "../../../Pages/UserDetails/ReduceWalletAmountDialog";
import { toast } from "react-toastify";
import {
  GetWalletDetails,
  PostReduceWaterWallet,
} from "../../../Pages/Redux/Actions/userDetailsAdminAction";
import { useDispatch } from "react-redux";
import { styled } from "@mui/system";

const WalletWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "linear-gradient(135deg, #e1f5fe, #b3e5fc)",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  "@media (max-width:600px)": {
    padding: "16px",
    boxShadow: "none",
    flexDirection: "column",
  },
}));

const WalletButton = styled(Button)(({ theme }) => ({
  backgroundColor: blue[600],
  color: "#fff",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: blue[800],
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  //   padding: theme.spacing(1),
  // },
}));

const WaterWallet = ({ walletAmount, userId, waterBalance }) => {
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenReduceWaterWallet = () => {
    setIsDialogOpen(true);
  };

  const handleCloseReduceWalletAmount = () => {
    setIsDialogOpen(false);
  };

  const handleReduceWalletAmount = (props) => {
    console.log(props);
    console.log("---------handleReduceWalletAmount----------------");
    if (
      props.amountToReduceWaterWallet !== "" &&
      props.reasonForReduceWaterWallet !== "" &&
      props.reduceWalletTransActionId !== "" &&
      props.receiptFile !== ""
    ) {
      const reqObj = {
        user_id: userId,
        reason: props.reasonForReduceWaterWallet,
        amount: parseFloat(props.amountToReduceWaterWallet),
        trans_id: props.reduceWalletTransActionId,
        transaction_meta: {
          transaction_receipt: props.receiptFileName,
        },
      };
      if (
        parseFloat(props.amountToReduceWaterWallet) <= parseFloat(waterBalance)
      ) {
        dispatch(PostReduceWaterWallet(reqObj));
        setIsDialogOpen(false);
      } else {
        toast.error("Please Enter Less Than Wallet Amount", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsDialogOpen(true);
      }
      props.setAmountToReduceWaterWallet("");
      props.setReasonForReduceWaterWallet("");
      props.setReduceWalletTransActionId("");
      setTimeout(() => {
        dispatch(GetWalletDetails(userId));
      }, 1000);
    } else {
      // toast.error("Please Fill All Data", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
      setIsDialogOpen(true);
    }
  };

  const validWalletAmount = !isNaN(walletAmount)
    ? parseFloat(walletAmount).toFixed(2)
    : "0.00";

  return (
    <Box sx={{ p: { xs: 1, sm: 2 }, width: "100%" }}>
      <WalletWrapper>
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <AccountBalanceWallet sx={{ fontSize: 40, color: blue[600] }} />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1rem", sm: "1rem" },
              color: blue[800],
            }}
          >
            ₹{validWalletAmount}
          </Typography>
        </Box>
        {parseFloat(validWalletAmount) !== 0 && (
          <WalletButton onClick={handleOpenReduceWaterWallet}>
            Reduce Wallet
          </WalletButton>
        )}
      </WalletWrapper>

      {isDialogOpen && (
        <ReduceWalletAmountDialog
          openReduceWalletAmount={isDialogOpen}
          handleCloseReduceWalletAmount={handleCloseReduceWalletAmount}
          handleReduceWalletAmount={handleReduceWalletAmount}
        />
      )}
    </Box>
  );
};

export default WaterWallet;
