import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialOrdersState = {
  error: true,
  linkbatcherror: true,
  msg: "",
  validatedData: [],
  validatedDataOrder: [],
  validatedExpired: [],
};

const wfsBatchLinkingReducer = (state = initialOrdersState, action) => {
  switch (action.type) {
    case actionType.VALIDATE_EXPIRED:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        validatedExpired: action.payload.data,
      };
    case actionType.LINK_VALID_CANS:
      return {
        ...state,
        linkbatcherror: action.payload.error,
        msg: action.payload.msg,
        validatedData: action.payload.data,
      };
    case actionType.LINK_EMPTY_INSCAN_VALID_CANS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        validatedDataOrder: action.payload.data,
      };
    case actionType.LINK_EMPTY_OUTSCAN_VALID_CANS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        validatedDataOrder: action.payload.data,
      };
    case actionType.CLEAR_DATA_REFRESH_BATCH:
      return {
        ...state,
        linkbatcherror: true,
      };
    case actionType.CLEAR_VALIDATED_DATA:
      CustomConsole("clear reducer");
      CustomConsole(actionType);
      return {
        error: true,
        msg: "",
        validatedData: [],
        validatedDataOrder: [],
      };

    default:
      return state;
  }
};

export default wfsBatchLinkingReducer;
