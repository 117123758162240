import { FileUpload } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { expense_type } from "../../Components/AppMeta/appMetaConfig";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

function WFSAddCostDialog(props) {
  const [selectedWFS, setSelectedWFS] = useState(null);
  console.log(selectedWFS);
  const [selectWFS, setSelectWFS] = useState("");
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const uploadPaymentRecipt = React.useRef(null);
  const [purpose, setPurpose] = React.useState("");
  const [seqid, setSeqid] = React.useState("");
  const [stopSwitch, setStopSwitch] = React.useState(true);
  const [selectedExpenseType, setSelectedExpenseType] = React.useState("");
  const [selectMonth, setSelectMonth] = React.useState("");
  const [paidDate, setPaidDate] = React.useState(
    moment().tz("Asia/Kolkata").format("YYYY-MM-DD")
  );
  const [accountNumber, setAccountNumber] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [reciptupload, setReciptupload] = React.useState("");
  const [uploadReciptFile, setUploadReciptFile] = React.useState("");
  console.log(reciptupload);
  const [uploadReciptBtn, setUploadReciptBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [nextDueDate, setNextDueDate] = useState();
  const [reciptUrl, setReciptUrl] = React.useState("");

  //function for handling file upload opreation
  const handleUploadRecipt = () => {
    uploadPaymentRecipt.current.click();
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  //useEffect for handling file upload operation
  React.useEffect(() => {
    if (uploadReciptFile !== "") {
      dispatch(UploadFile(uploadReciptFile));
      setUploadReciptBtn(true);
      setUploadReciptFile("");
      setUploadFlags(true);
    }
  }, [uploadReciptFile]);
  //file handler
  React.useEffect(() => {
    console.log(fileHandler);
    if (fileHandler.isFileUploaded === true && uploadReciptBtn === true) {
      setReciptUrl(fileHandler.fileName);
      setUploadReciptBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("Recipt  uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);

  React.useEffect(() => {
    if (props.openEditSingleOperationCost) {
      console.log(props.singleOperationCostDetails);
      console.log(props.singleOperationCostDetails.wfs_details?.company_name);
      setSelectedWFS({
        name: props.singleOperationCostDetails.wfs_details?.company_name,
      });
      setSeqid(props.singleOperationCostDetails.id);
      setAccountNumber(
        props.singleOperationCostDetails?.details?.account_number
      );
      setSelectedExpenseType(props.singleOperationCostDetails.expense_type);
      setAmount(props.singleOperationCostDetails.amount);
      setPaidDate(
        moment(props.singleOperationCostDetails.paid_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
      setReciptUrl(props.singleOperationCostDetails.payment_receipt);
      setNextDueDate(
        props.singleOperationCostDetails.due_date
          ? moment(props.singleOperationCostDetails.due_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD")
          : props.singleOperationCostDetails.next_due_date
          ? moment(props.singleOperationCostDetails.next_due_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD")
          : undefined
      );
      setPurpose(props.singleOperationCostDetails.details?.reason);
      setSelectMonth(props.singleOperationCostDetails.details?.month);
    }
  }, [props.openEditSingleOperationCost]);

  const wfsList = [
    { label: "WFS1", pincode: 603103 },
    { label: "WFS2", pincode: 600118 },
  ];

  const handleChangeWFS = (event) => {
    setSelectWFS(event.target.value);
  };

  const handleCloseAddWFSDialog = () => {
    props.handleCloseAddWFSDialog();
  };
  const handleSubmitAddWFSDialog = () => {
    props.handleSubmitAddWFSDialog({
      selectedExpenseType: selectedExpenseType,
      selectMonth: selectMonth,
      amount: amount,
      paidDate: paidDate,
      reciptUrl: reciptUrl,
      nextDueDate: nextDueDate,
      purpose: purpose,
      selectedWFS:
        selectedWFS?.wfs_id || props.singleOperationCostDetails.details?.wfs_id,
      seqid: seqid,
      accountNumber: accountNumber,
      edt_next_due_date: props.editNextDueDate,
      stop_expense: stopSwitch,
      ops_type: 200,
    });
  };
  return (
    <Dialog open={props.openAddWFSDetailsDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: "15px" }}>
          <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
            {props.openEditSingleOperationCost === true ? "EDIT" : "ADD"} WFS
            OPERATING COST
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // flexWrap: "wrap",
            flexDirection: { md: "row", xs: "column" },
            gap: "10px",
            mb: "10px",
          }}
        >
          <Autocomplete
            disabled={
              !skuWfsDetails.wfsDetails?.length ||
              props.editNextDueDate === "edit"
            }
            sx={{ width: 210, mb: "10px" }}
            options={
              skuWfsDetails.wfsDetails?.map((item, index) => {
                return {
                  name: item.company_name,
                  wfs_id: item.wfs_master_id,
                  id: index,
                };
              }) || []
            }
            autoHighlight
            getOptionLabel={(nameSearch) => `${nameSearch.name} `}
            onChange={(_, newValue) => {
              setSelectedWFS(newValue); // Set the selected  as the value
            }}
            value={selectedWFS}
            renderOption={(props, nameSearch) => (
              <Box
                component="li"
                // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {nameSearch.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={
                  skuWfsDetails.wfsDetails?.length
                    ? "Search WFS"
                    : "No WFS Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: null, // disable autocomplete and autofill
                }}
              />
            )}
          />
          <FormControl sx={{ width: 220 }} size="small">
            <InputLabel>Select Expense Type</InputLabel>
            <Select
              disabled={
                !selectedWFS?.wfs_id || props.editNextDueDate === "edit"
              }
              value={selectedExpenseType}
              label="Select Expense Type"
              onChange={(event) => setSelectedExpenseType(event.target.value)}
            >
              <MenuItem value={expense_type.RENT}>RENT</MenuItem>
              <MenuItem value={expense_type.WFI}>WI-FI</MenuItem>
              <MenuItem value={expense_type.ELECTRICITY}>ELECTRICITY</MenuItem>
              <MenuItem value={expense_type.CONSUMABLES}>CONSUMABLES</MenuItem>
              <MenuItem value={expense_type.ALLOWANCES}>ALLOWANCES</MenuItem>
              <MenuItem value={expense_type.OTHERS}>OTHERS</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          {selectedExpenseType === expense_type.RENT ? (
            <Paper elevation={8} sx={{ padding: "10px", bgcolor: "#f5eef8" }}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                RENT
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <FormControl sx={{ width: 220 }} size="small">
                    <InputLabel>Select Month</InputLabel>
                    <Select
                      value={selectMonth}
                      label="Select Month"
                      onChange={(event) => setSelectMonth(event.target.value)}
                    >
                      <MenuItem value={1}>JAN</MenuItem>
                      <MenuItem value={2}>FEB</MenuItem>
                      <MenuItem value={3}>MARCH</MenuItem>
                      <MenuItem value={4}>APRIL</MenuItem>
                      <MenuItem value={5}>MAY</MenuItem>
                      <MenuItem value={6}>JUNE</MenuItem>
                      <MenuItem value={7}>JULY</MenuItem>
                      <MenuItem value={8}>AUG</MenuItem>
                      <MenuItem value={9}>SEP</MenuItem>
                      <MenuItem value={10}>OCT</MenuItem>
                      <MenuItem value={11}>NOV</MenuItem>
                      <MenuItem value={12}>DESC</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    type="date"
                    size="small"
                    label="Paid Date"
                    value={paidDate}
                    onChange={(event) => setPaidDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"), // Set the maximum date
                    }}
                  />
                  <TextField
                    label="Amount"
                    variant="outlined"
                    size="small"
                    sx={{ width: 100 }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    type="number"
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                  />
                </Box>
                <Box>
                  <TextField
                    type="date"
                    size="small"
                    label="Next Due Date"
                    value={nextDueDate}
                    onChange={(event) => setNextDueDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button
                    onClick={handleUploadRecipt}
                    // variant="contained"
                    size="small"
                  >
                    <FileUpload sx={{ mt: "8px" }} />
                    <input
                      type="file"
                      ref={uploadPaymentRecipt}
                      style={{ display: "none" }}
                      onChange={(e) => setUploadReciptFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.WFI ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                WI-FI
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <FormControl sx={{ width: 150 }} size="small">
                    <InputLabel>Select Month</InputLabel>
                    <Select
                      value={selectMonth}
                      label="Select Month"
                      onChange={(event) => setSelectMonth(event.target.value)}
                    >
                      <MenuItem value={1}>JAN</MenuItem>
                      <MenuItem value={2}>FEB</MenuItem>
                      <MenuItem value={3}>MARCH</MenuItem>
                      <MenuItem value={4}>APRIL</MenuItem>
                      <MenuItem value={5}>MAY</MenuItem>
                      <MenuItem value={6}>JUNE</MenuItem>
                      <MenuItem value={7}>JULY</MenuItem>
                      <MenuItem value={8}>AUG</MenuItem>
                      <MenuItem value={9}>SEP</MenuItem>
                      <MenuItem value={10}>OCT</MenuItem>
                      <MenuItem value={11}>NOV</MenuItem>
                      <MenuItem value={12}>DESC</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    // type="date"
                    size="small"
                    label="Account Number"
                    value={accountNumber}
                    onChange={(event) => setAccountNumber(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type="date"
                    size="small"
                    label="Paid Date"
                    value={paidDate}
                    onChange={(event) => setPaidDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"), // Set the maximum date
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    type="date"
                    size="small"
                    label="Next Due Date"
                    value={nextDueDate}
                    onChange={(event) => setNextDueDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                  />
                  <Button onClick={handleUploadRecipt} size="small">
                    <FileUpload sx={{ mt: "8px" }} />
                    <input
                      type="file"
                      ref={uploadPaymentRecipt}
                      style={{ display: "none" }}
                      onChange={(e) => setUploadReciptFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.ELECTRICITY ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                ELECTRICITY
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <FormControl sx={{ width: 150 }} size="small">
                    <InputLabel>Select Month</InputLabel>
                    <Select
                      value={selectMonth}
                      label="Select Month"
                      onChange={(event) => setSelectMonth(event.target.value)}
                    >
                      <MenuItem value={1}>JAN</MenuItem>
                      <MenuItem value={2}>FEB</MenuItem>
                      <MenuItem value={3}>MARCH</MenuItem>
                      <MenuItem value={4}>APRIL</MenuItem>
                      <MenuItem value={5}>MAY</MenuItem>
                      <MenuItem value={6}>JUNE</MenuItem>
                      <MenuItem value={7}>JULY</MenuItem>
                      <MenuItem value={8}>AUG</MenuItem>
                      <MenuItem value={9}>SEP</MenuItem>
                      <MenuItem value={10}>OCT</MenuItem>
                      <MenuItem value={11}>NOV</MenuItem>
                      <MenuItem value={12}>DESC</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    // type="date"
                    size="small"
                    label="Account Number"
                    value={accountNumber}
                    onChange={(event) => setAccountNumber(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type="date"
                    size="small"
                    label="Paid Date"
                    value={paidDate}
                    onChange={(event) => setPaidDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"), // Set the maximum date
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    type="date"
                    size="small"
                    label="Next Due Date"
                    value={nextDueDate}
                    onChange={(event) => setNextDueDate(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={amount}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onChange={(event) => setAmount(event.target.value)}
                  />
                  <Button onClick={handleUploadRecipt} size="small">
                    <FileUpload sx={{ mt: "8px" }} />
                    <input
                      type="file"
                      ref={uploadPaymentRecipt}
                      style={{ display: "none" }}
                      onChange={(e) => setUploadReciptFile(e.target.files[0])}
                    />
                  </Button>
                </Box>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectWFS === 400 ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                CONSUMABLES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.CONSUMABLES ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                CONSUMABLES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.ALLOWANCES ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                ALLOWANCES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.OTHERS ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                OTHERS
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {props.editNextDueDate === "edit" ? (
            <>
              <Paper
                elevation={8}
                sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    label="Reason"
                    variant="outlined"
                    size="small"
                    value={purpose}
                    sx={{ mt: "5px" }}
                    onChange={(event) => setPurpose(event.target.value)}
                  />
                  <Box>
                    <Typography sx={{ color: "red" }}>
                      Stop Expense :
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        sx={{
                          color: stopSwitch === false ? "#30A2F3" : "black",
                        }}
                      >
                        Disable
                      </Typography>
                      <AntSwitch
                        checked={stopSwitch}
                        onChange={(event) =>
                          setStopSwitch(event.target.checked)
                        }
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography
                        sx={{
                          color: stopSwitch === true ? "#30A2F3" : "black",
                        }}
                      >
                        Enable
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Paper>
            </>
          ) : (
            ""
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={handleCloseAddWFSDialog}
        >
          close
        </Button>
        <Button variant="contained" onClick={handleSubmitAddWFSDialog}>
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WFSAddCostDialog;
