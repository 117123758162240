import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { styles } from "../wfsCanOrders/cardStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";
import {
  GetSupplierWfsOngoingOrders,
  PutSupplierAddDelivertSlots,
  PutSupplierOngoingStartSubmission,
} from "../Redux/Actions/supliersAction";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ordersStatusWFSCan } from "../../Components/AppMeta/appMetaConfig";
import moment from "moment";
import SupplierStartSubmissionDialog from "../../Components/DialoBoxes/SupplierStartSubmissionDialog";

function TransferCanSFormView(props) {
  CustomConsole(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const [SelectDeliverySlot, setSelectDeliverySlot] = React.useState("");
  const [singleSelectedData, setSingleSelectedData] = React.useState("");
  const [addDeliveryBtn, setAddDeliveryBtn] = React.useState(false);
  const [valueOrderId, setValueOrderId] = React.useState("");
  const [selecteId, setSelecteId] = React.useState({});
  CustomConsole(selecteId);
  const [isFbEnabled, setIsFbEnabled] = React.useState(false);
  const [selectCheckBox, setSelectCheckBox] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openStartSubmissionDialog, setOpenStartSubmissionDialog] =
    React.useState(false);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  React.useEffect(() => {}, [props]);

  React.useEffect(() => {}, [singleSelectedData]);

  //   const orderStatuswfsCansorder = (ec_order_status) => {
  //     switch (ec_order_status) {
  //       case ordersStatusWFSCan.EC_ORDER_CONFIRMED:
  //         return "CONFIRMED";
  //       case ordersStatusWFSCan.EC_ORDER_COMPLETED:
  //         return "COMPLETED";
  //       case ordersStatusWFSCan.EC_ORDER_CANCELLED:
  //         return "CANCELLED";
  //       case ordersStatusWFSCan.EC_ORDER_PAYMENT_PENDING:
  //         return "PAYMENT PENDING";
  //       case ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED:
  //         return "ADMIN ACCEPTED";
  //       case ordersStatusWFSCan.EC_ORDER_ADMIN_REJECTED:
  //         return "ADMIN REJECTED";
  //       default:
  //         return "";
  //     }
  //   };

  const handleChangeOrderId = (event) => {
    const selectedOrdersData = props.data.find(
      (data) => data.transfer_cans_order_id === event.target.value
    );
    CustomConsole(selectedOrdersData);
    setSingleSelectedData(selectedOrdersData);
    setValueOrderId(event.target.value);
  };
  React.useEffect(() => {
    if (props.data && valueOrderId) {
      const output = props.data.find((obj) => {
        return obj.transfer_cans_order_id === valueOrderId;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      setSelecteId(output);
    }
  }, [props.data, valueOrderId]);

  const handleClickOrder = () => {
    CustomConsole("----------handleClickOrder-------------");
    props.handleClickOrder({
      ordeer_id: selecteId?.transfer_cans_order_id,
      order_status: selecteId?.order_status,
      is_submit_started: selecteId?.is_submit_started,
      is_submit_finished: selecteId?.is_submit_finished,
    });
  };

  return (
    <Material.Paper elevation={14} sx={{ padding: "20px", bgcolor: "#E9F7EF" }}>
      <Material.Box
        sx={{
          // padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: {
            xs: "row",
            sm: "row",
            md: "row",
            lg: "row",
          },
          gap: "10px",
        }}
      >
        <Material.Box
          sx={{
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              alignItems: "center",
            }}
          >
            <Material.InputLabel sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Order ID:
            </Material.InputLabel>
            <Material.Select
              variant="standard"
              sx={{ fontSize: "16px", fontWeight: "bold", width: "190px" }}
              value={valueOrderId}
              label="Order Id"
              onChange={handleChangeOrderId}
            >
              {props.data &&
                props.data !== undefined &&
                props.data !== null &&
                props.data.length > 0 &&
                props.data.map((orderIds, index) => {
                  return (
                    <Material.MenuItem
                      key={index}
                      value={orderIds.transfer_cans_order_id}
                    >
                      {orderIds.transfer_cans_order_id}
                    </Material.MenuItem>
                  );
                })}
            </Material.Select>
          </Material.Box>
        </Material.Box>
      </Material.Box>
      <Material.Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
          textAlign: "center",
        }}
      >
        <Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box sx={styles.cardStyleHeading}>
              <Material.Typography>Full Name </Material.Typography>
            </Material.Box>
            <Material.Box sx={styles.cardStyleValue}>
              <Material.Typography>{selecteId?.fullname}</Material.Typography>
            </Material.Box>
          </Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box sx={styles.cardStyleHeading}>
              <Material.Typography>Order Id </Material.Typography>
            </Material.Box>
            <Material.Box sx={styles.cardStyleValue}>
              <Material.Typography>
                {selecteId?.transfer_cans_order_id}
              </Material.Typography>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box sx={styles.cardStyleHeading}>
              <Material.Typography>Total Container Qty </Material.Typography>
            </Material.Box>
            <Material.Box sx={styles.cardStyleValue}>
              <Material.Typography>
                {selecteId?.total_container_qty}
              </Material.Typography>
            </Material.Box>
          </Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box sx={styles.cardStyleHeading}>
              <Material.Typography>Order Status </Material.Typography>
            </Material.Box>
            <Material.Box sx={styles.cardStyleValue}>
              <Material.Typography>
                {props.transferCansStatusShow(selecteId?.order_status)}
              </Material.Typography>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box sx={styles.cardStyleHeading}>
              <Material.Typography>Capacity </Material.Typography>
            </Material.Box>
            <Material.Box sx={styles.cardStyleValue}>
              <Material.Typography>
                {selecteId?.container_capacity}
              </Material.Typography>
            </Material.Box>
          </Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box sx={styles.cardStyleHeading}>
              <Material.Typography>Material </Material.Typography>
            </Material.Box>
            <Material.Box sx={styles.cardStyleValue}>
              <Material.Typography>
                {selecteId?.container_material}
              </Material.Typography>
            </Material.Box>
          </Material.Box>
        </Material.Box>
      </Material.Box>
      <Material.Box sx={{ marginTop: "10px" }}>
        <Material.Button
          onClick={handleClickOrder}
          variant="contained"
          sx={{
            // background: appColors.commonBtnColor.background,
            color: "white",
            borderRadius: "10px",
            margin: "10px",
          }}
        >
          {selecteId?.is_submit_started === false &&
          selecteId?.is_submit_finished === false &&
          selecteId?.order_status === 200
            ? "Enter OTP"
            : selecteId?.is_submit_started === true &&
              selecteId?.is_submit_finished === false &&
              selecteId?.order_status === 200
            ? "IN-Scan"
            : null}
        </Material.Button>
      </Material.Box>
    </Material.Paper>
  );
}

export default TransferCanSFormView;
