import { ActionTypes } from "@mui/base";
import CustomConsole from "../../../CustomConsole";
import actionType from "../Actions/actionType";

const initialState = {
  error: true,
  msg: "",
  withdrawalList: [],
  withdrawalOrderid: {},
  withdrawalDetailrefund: {},
  withdrawalDetailsrejected: {},
  withdrawalDetailsAccept: [],
  withdrawalDetailsSecondAccept: [],
  withdrawalDetailsAssignDialog: {},
  damageCansData: [],
  damageAcceptReject: [],
};

const AdminWithdrawallistadmin = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_WITHDRAWAL_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        withdrawalList: action.payload.data,
      };
    case actionType.GET_WITHDRAWAL_ORDERID:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        withdrawalOrderid: action.payload.data,
      };
    case actionType.POST_WITHDRAWALDETAILS_REFUND:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        withdrawalDetailrefund: action.payload.data,
      };
    case actionType.PUT_WITHRAWALDETAILS_REJECTED:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        withdrawalDetailsrejected: action.payload.data,
      };
    case actionType.GET_WITHDRAWALDETAILS_FIRSTACCEPT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        withdrawalDetailsAccept: action.payload.data,
      };
    case actionType.GET_WITHDRAWALDETAILS_SECONDACCEPT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        withdrawalDetailsSecondAccept: action.payload.data,
      };
    case actionType.POST_WITHDRAWALDETAILS_DIALOG_ASSIGN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        withdrawalDetailsAssignDialog: action.payload.data,
      };
    case actionType.DAMAGE_CANS_DATA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        damageCansData: action.payload.data,
      };
    case actionType.DAMAGE_CAN_ACCEPT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        damageAcceptReject: action.payload.data,
      };
    case actionType.DAMAGE_CANS_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        damageAcceptReject: action.payload.data,
      };
    case actionType.PUT_EDIT_RETURN_CAN_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PUT_EDIT_RETURN_CAN_ORDERS_DISTRI_TO_WFS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    default:
      return state;
  }
};
export default AdminWithdrawallistadmin;
