import React, { useState } from "react";
import * as Material from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { toast } from "react-toastify";

function ReduceWalletAmountDialog(props) {
  const dispatch = useDispatch();

  const [reduceWalletTransActionId, setReduceWalletTransActionId] =
    useState("");
  const [reasonForReduceWaterWallet, setReasonForReduceWaterWallet] =
    useState("");
  const [amountToReduceWaterWallet, setAmountToReduceWaterWallet] =
    useState("");
  const [receiptFile, setReceiptFile] = useState(null); // State for receipt file
  const [uploadSuccess, setUploadSuccess] = useState(false); // State for upload success
  const [awsFileName, setAwsFileName] = useState(""); // State for storing AWS filename

  const handleReduceWalletAmount = async () => {
    props.handleReduceWalletAmount({
      reduceWalletTransActionId: reduceWalletTransActionId,
      setReduceWalletTransActionId: setReduceWalletTransActionId,
      reasonForReduceWaterWallet: reasonForReduceWaterWallet,
      amountToReduceWaterWallet: amountToReduceWaterWallet,
      setReasonForReduceWaterWallet: setReasonForReduceWaterWallet,
      setAmountToReduceWaterWallet: setAmountToReduceWaterWallet,
      receiptFileName: awsFileName,
    });

    props.handleCloseReduceWalletAmount();
    // toast.success("Wallet amount successfully reduced!");
  };

  const handleCloseReduceWalletAmount = () => {
    props.handleCloseReduceWalletAmount();
  };

  const handleChangeReduceWallet = (event) => {
    const inputValue = event.target.value;
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setAmountToReduceWaterWallet(inputValue);
    }
  };

  const handleReceiptUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setReceiptFile(file);

      try {
        const uploadedData = await dispatch(UploadFile(file)); // Upload the selected file

        if (uploadedData) {
          setAwsFileName(uploadedData.filename);
          setUploadSuccess(true);
          // toast.success("File uploaded successfully!");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file.");
      }
    }
  };

  const handleDeleteReceipt = () => {
    setReceiptFile(null);
    setUploadSuccess(false);
    setAwsFileName("");
  };

  return (
    <Material.Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "20px",
            backgroundColor: "#fafafa",
            maxWidth: { xs: "90%", sm: "50%", md: "35%" }, // Adjusting the dialog box width for mobile
            width: "100%",
          },
        },
      }}
      open={props.openReduceWalletAmount}
    >
      <Material.DialogContent sx={{ padding: { xs: "16px", sm: "24px" } }}>
        <Material.Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: { xs: "1.2rem", sm: "1.5rem" },
            marginBottom: "20px",
            color: "#333",
          }}
        >
          Reduce Water Wallet
        </Material.Typography>

        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            "@media (max-width: 600px)": {
              gap: "10px",
            },
          }}
        >
          <Material.TextField
            id="amount"
            label="Enter amount"
            variant="outlined"
            color="secondary"
            type="number"
            value={amountToReduceWaterWallet}
            size="small"
            onChange={handleChangeReduceWallet}
            fullWidth
            sx={{ backgroundColor: "#fff" }}
          />

          <Material.TextField
            id="reason"
            label="Reason For Reduce Wallet *"
            variant="outlined"
            color="secondary"
            value={reasonForReduceWaterWallet}
            size="small"
            onChange={(event) =>
              setReasonForReduceWaterWallet(event.target.value)
            }
            fullWidth
            sx={{ backgroundColor: "#fff" }}
          />

          <Material.TextField
            id="transaction-id"
            label="Transaction Id*"
            variant="outlined"
            color="secondary"
            value={reduceWalletTransActionId}
            size="small"
            onChange={(event) =>
              setReduceWalletTransActionId(event.target.value)
            }
            fullWidth
            sx={{ backgroundColor: "#fff" }}
          />

          <Material.Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              "@media (max-width: 600px)": {
                flexDirection: "column",
              },
            }}
          >
            <Material.Button
              variant="outlined"
              component="label"
              startIcon={
                uploadSuccess ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CloudUploadIcon />
                )
              }
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              {uploadSuccess ? "Uploaded" : "Upload Receipt"}
              <input
                type="file"
                hidden
                accept="image/*,application/pdf"
                onChange={handleReceiptUpload} // Trigger file upload on file select
              />
            </Material.Button>

            {receiptFile && (
              <Material.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  flexDirection: "row",
                  "@media (max-width: 600px)": {
                    // flexDirection: "column",
                    alignItems: "flex-start",
                  },
                }}
              >
                <span>{receiptFile.name}</span>
                <DeleteIcon
                  sx={{
                    cursor: "pointer",
                    color: "error.main",
                    "@media (max-width: 600px)": {
                      marginTop: 0,
                    },
                  }}
                  onClick={handleDeleteReceipt}
                />
              </Material.Box>
            )}
          </Material.Box>
        </Material.Box>

        <Material.Box
          sx={{
            display: "flex",
            gap: "10px",
            m: 1,
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "row" },
            "& button": { width: { xs: "100%", sm: "auto" } },
          }}
        >
          <Material.Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleCloseReduceWalletAmount}
            sx={{
              "&:hover": { backgroundColor: "#d32f2f" },
            }}
          >
            Close
          </Material.Button>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handleReduceWalletAmount}
            sx={{
              "&:hover": { backgroundColor: "#1976d2" },
            }}
          >
            Reduce Now
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default ReduceWalletAmountDialog;
