import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import CustomConsole from "../../CustomConsole";
import QRBarcodeDetailslist from "./QRBarcodeDetailslist";
import QRBarcodelist from "./QRBarcodelist";
import Divider from "@mui/material/Divider";
import { OpenCamera } from "../wfsCanOrders/OpenCamera";
import { useDispatch, useSelector } from "react-redux";
import QrcodeDetailsDialog from "./QrcodeDetailsDialog";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import QrCode2Icon from "@mui/icons-material/QrCode2";
import {
  GenerateBarcodes,
  GenerateQrCode,
  GetCompleteQrDetails,
  GetDetailsFromQrcode,
  GetQrList,
  LinkQRBarcode,
  RegenerateQrCode,
} from "../Redux/Actions/adminQrBarcodeAction";
import { toast } from "react-toastify";
import QrDetailsListTables from "./QrDetailsListTables";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterQrbarcodeDialog from "./FilterQrbarcodeDialog";
import BarcodeReader from "react-barcode-reader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import QrBarcodeLinkingDialog from "./QrBarcodeLinkingDialog";
import Alert from "@mui/material/Alert";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { QRstyles } from "./QRStyles";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import QrDetailsDialog from "./QrDetailsDialog";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import QrBarcodeCrates from "./QrBarcodeCrates";
import {
  GetAvailableProducts,
  GetListofCrates,
} from "../Redux/Actions/SKUProductDetailsAction";
function QRBarcode() {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const dispatch = useDispatch();

  const inQrBarcodeAdmin = true;
  const handleCameraResponse = (qrCode) => {
    CustomConsole(qrCode);
  };

  const [qrCodeDetails, setQrCodeDetails] = React.useState({});
  const [completeQrCodeDetails, setCompleteQrCodeDetails] = React.useState({});
  const adminQrBarcodeData = useSelector((state) => state.adminQrBarcodeData);
  const [openQrDetailsDialog, setOpenQrDetailsDialog] = React.useState(false);
  const [linkQrScannedId, setLinkQrScannedId] = React.useState("");
  const [qrListData, setQrListData] = React.useState([]);
  const [pageStatus, setPageStatus] = React.useState(false);
  const [qrBarcodeProductVsCrateSwitch, setQrBarcodeProductVsCrateSwitch] =
    React.useState(false);
  //filter
  const [filterDialog, setFilterDialog] = React.useState(false);
  const [filterFlag, setFilterFlag] = React.useState(false);
  const [ascDsc, setAscDsc] = React.useState("DESC");
  const [qrBarCode, setQrbarcode] = React.useState(true);
  const [seriesType, setSeriesType] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [selectAction, setSelectAction] = React.useState("");
  const [productName, setProductName] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [quantity, setQuantity] = React.useState(2);
  const [batchId, setBatchId] = React.useState("");
  const [manufacturer, setManufacturer] = React.useState("");
  const [manufacturerAddress, setManufacturerAddress] = React.useState("");
  const [isQrbarcode, setIsQrbarcode] = React.useState(false);
  const [pagePlusBtn, setPagePlusBtn] = React.useState(false);
  const [pageMinusBtn, setPageMinusBtn] = React.useState(false);
  const [generateBtn, setGenerateBtn] = React.useState(false);
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [scanCount, setScanCount] = React.useState(0);
  const [link, setLink] = React.useState(false);
  // const [alignment, setAlignment] = React.useState('Scan');
  const [scanQrFlag, setScanQrFlag] = React.useState(false);
  const [linkQrFlag, setLinkQrFlag] = React.useState(false);
  const [GenerateFlag, setGenerateFlag] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [unMountFlag, setUnMountFlag] = React.useState(false);
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [linkToggle, setLinkToggle] = React.useState("linkQr");

  const handleChangeLink = (event) => {
    setLinkQrScannedId("");
    setScannedQrCode("");
    setScannedBarCode("");
    setScanCount(0);
    setDecodedResults([]);
    setLinkToggle(event.target.value);
  };
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  let reqObjectCrateDetails = {
    activeStatus: true,
    capacity: "ASC",
    sort_by_name: "ASC",
    sort_by_price: "ASC",
    sort_by_created_at: "ASC",
  };
  React.useEffect(() => {
    dispatch(GetQrList(pageNumber - 1, ascDsc, seriesType, qrBarCode));
    dispatch(GetAvailableProducts());
    dispatch(GetListofCrates(reqObjectCrateDetails));

    setOpenQrDetailsDialog(false);
  }, []);

  React.useEffect(() => {
    CustomConsole(adminQrBarcodeData);
    if (adminQrBarcodeData.error === false) {
      setQrCodeDetails(adminQrBarcodeData.qrDetails);
      setCompleteQrCodeDetails(adminQrBarcodeData.containerDetails);
      CustomConsole(adminQrBarcodeData.qrListData);
      setQrListData(adminQrBarcodeData.qrListData);
      setScanQrFlag(false);
      if (adminQrBarcodeData.qrListData.length > 0) {
        CustomConsole("data available");
        setPageStatus(false);
      } else {
        CustomConsole("data not available");
        setPageStatus(true);
      }
    }
    if (
      adminQrBarcodeData.generateQrMsg ===
        "Successfully generated Barcode/QR IDs Sereis" &&
      generateBtn === true
    ) {
      setSelectAction("");
      setCapacity("");
      setMaterial("");
      setQuantity(2);
      setBatchId("");
      setManufacturer("");
      setManufacturerAddress("");
      dispatch(GetQrList(pageNumber - 1, ascDsc, seriesType, qrBarCode));
      setGenerateBtn(false);
    }
    if (adminQrBarcodeData.linkingError === false) {
    }
  }, [adminQrBarcodeData]);

  React.useEffect(() => {
    CustomConsole("qrCode details");
    CustomConsole(qrCodeDetails);
    if (Object.keys(qrCodeDetails).length > 0 && unMountFlag === true) {
      setOpenQrDetailsDialog(true);
    }
    CustomConsole("component mounted");
    return () => {
      // Perform cleanup tasks here
      setUnMountFlag(false);
    };
  }, [qrCodeDetails]);
  // completeQrCodeDetails
  React.useEffect(() => {
    CustomConsole("complete qrCode details");
    CustomConsole(completeQrCodeDetails);
    if (
      Object.keys(completeQrCodeDetails).length > 0 &&
      unMountFlag === true &&
      (completeQrCodeDetails.qualityDetails.length > 0 ||
        completeQrCodeDetails.ownerDetails.length > 0)
    ) {
      setOpenQrDetailsDialog(true);
    }
  }, [completeQrCodeDetails]);
  const handleChange = (event) => {
    setSelectAction(event.target.value);
    if (event.target.value === "REGenrateQr") {
      setIsQrbarcode(true);
    } else if (event.target.value === "REGenrateQr") {
      setIsQrbarcode(false);
    }
  };

  //useEffect for handling selected product details
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = skuContainerDetails.getAvailableProducts.filter(
      (data) =>
        `${data.product_details.capacity}-${data.product_details.material}` ===
        event.target.value
    );
    if (selectedDetails.length > 0) {
      setProductName(
        `${selectedDetails[0].product_details.capacity}-${selectedDetails[0].product_details.material}`
      );
      setCapacity(selectedDetails[0].product_details.capacity);
      setMaterial(selectedDetails[0].product_details.material);
    }
  };

  const handleBatchIdChange = (event) => {
    if (event.target.value > 0) {
      setBatchId(event.target.value);
    } else {
      toast.error("Batch ID cannot be zero or less", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleQuantityChange = (event) => {
    if (event.target.value >= 2) {
      setQuantity(event.target.value);
    } else {
      toast.error("Quantity must be greater than 1", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleManufacturerChange = (event) => {
    setManufacturer(event.target.value);
  };
  const handleManufacturerAddressChange = (event) => {
    setManufacturerAddress(event.target.value);
  };

  const handleGenerate = () => {
    const reqObj1 = {
      batch_id: batchId,
      container_capacity: capacity,
      container_material: material,
      mft_address: manufacturerAddress,
      mft_name: manufacturer,
      quantity: parseInt(quantity),
    };
    const reqObj2 = {
      quantity: parseInt(quantity),
    };
    const reqObj3 = {
      is_qr_barcode: isQrbarcode,
      quantity: parseInt(quantity),
    };
    if (selectAction === "GenrateQr") {
      dispatch(GenerateQrCode(reqObj1));
      setGenerateBtn(true);
    } else if (selectAction === "GenrateBarcode") {
      dispatch(GenerateBarcodes(reqObj2));
      setGenerateBtn(true);
    } else {
      dispatch(RegenerateQrCode(reqObj3));
      setGenerateBtn(true);
    }
  };

  React.useEffect(() => {
    if (pagePlusBtn || pageMinusBtn === true) {
      dispatch(GetQrList(pageNumber - 1, ascDsc, seriesType, qrBarCode));
      setPageMinusBtn(false);
      setPagePlusBtn(false);
    }
  }, [pageNumber]);

  React.useEffect(() => {
    if (filterFlag === true) {
      dispatch(GetQrList(pageNumber - 1, ascDsc, seriesType, qrBarCode));
      setFilterFlag(false);
      setFilterDialog(false);
    }
  }, [filterFlag]);
  const handlePageNumberPlus = () => {
    setPageNumber(pageNumber + 1);
    setPagePlusBtn(true);
  };
  const handlePageNumberMinus = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setPageMinusBtn(true);
  };

  // React.useEffect(() => {
  //   CustomConsole(`scanCount ${scanCount}`);
  // }, [scanCount]);

  //For Handling Toggle Button
  // const handleToggleChange = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };

  React.useEffect(() => {
    CustomConsole(scanCount);
  }, []);

  React.useEffect(() => {
    if (link === true) {
      const reqObj = {
        qr_barcode_id1: scannedQrCode,
        qr_barcode_id2: scannedBarCode,
      };
      dispatch(LinkQRBarcode(reqObj));
      setScannedQrCode("");
      setScannedBarCode("");
      setLinkingDialogOpen(false);
      // setScanCount(0);
      setDecodedResults([]);
      setLink(false);
      setLinkQrBarcodeBtn(false);
    }
  }, [link]);
  React.useEffect(() => {
    if (linkToggle === "linkQr" && linkQrScannedId) {
      console.log(linkQrScannedId);
      const reqObj = {
        qr_barcode_id1: linkQrScannedId,
        qr_barcode_id2: "",
      };
      console.log(reqObj);
      dispatch(LinkQRBarcode(reqObj));
      setLinkQrScannedId("");
      setScannedQrCode("");
      setScannedBarCode("");
      setScanCount(0);
      setDecodedResults([]);
    }
  }, [linkToggle, linkQrScannedId]);

  React.useEffect(() => {
    console.log(linkToggle);
    console.log(scannedQrCode);
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (
      linkToggle === "linkQrBarcode" &&
      scannedQrCode !== "" &&
      scannedQrCode !== undefined
    ) {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (
      linkToggle === "linkQrBarcode" &&
      scannedBarCode !== "" &&
      scannedBarCode !== undefined
    ) {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode, linkToggle]);

  React.useEffect(() => {
    if (scannedQrCodeDetails !== "") {
      // dispatch(GetDetailsFromQrcode(scannedQrCodeDetails));

      dispatch(GetCompleteQrDetails(scannedQrCodeDetails));
      setScanQrCodeDetailBtn(false);
      setScannedQrCodeDetails("");
    }
  }, [scannedQrCodeDetails]);

  const [scanOption, setScanOption] = React.useState("scanner");
  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeDetails(decodedText);

      // dispatch(GetDetailsFromQrcode(decodedText));
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedQrCodeDetails(decodedText);
    }
    setScanQrFlag(false);
    setScanOption("scanner");
  };

  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setLinkQrScannedId(decodedText);

      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setLinkQrScannedId(decodedText);

      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
    // setScanQrFlag(false);
    // setScanOption("scanner");
  };

  React.useEffect(() => {
    CustomConsole(
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN
    );
  }, []);

  //For opeaning icon for Scan qr button
  const handelQrScan = () => {
    // setScanQrFlag(!scanQrFlag);
    // setScanQrCodeDetailBtn(!scanQrcodeDetailBtn);
    // setUnMountFlag(true);
  };
  //For linking qr and barcode scanner
  const handelLinkQrBarCodeScan = () => {
    setLinkQrFlag(linkQrFlag === true ? false : true);
    setLinkQrBarcodeBtn(!linkQrBarcodeBtn);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue !== 0) {
      setScanOption("scanner");
    }
  };
  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      setScanQrCodeDetailBtn(true);
      setScanQrFlag(true);
      setUnMountFlag(true);
    } else {
      setScanQrCodeDetailBtn(false);
      setScanQrFlag(false);
      setUnMountFlag(false);
    }
  };

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      if (tabValue === 0) {
        setScanQrCodeDetailBtn(true);
        setUnMountFlag(true);
        setScannedQrCodeDetails(qrBarcodeId);
        setDecodedResults([]);
      } else if (tabValue === 1) {
        CustomConsole("==== Data Tab2 ====");
        CustomConsole(qrBarcodeId);
        setScannedQrCode(decodedResults[0]);
        setLinkQrScannedId(qrBarcodeId);
        setDecodedResults((prev) => [...prev, qrBarcodeId]);
        setScannedBarCode(qrBarcodeId);
      }
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);
  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };

  const handleQrBarcodeProductVsCrateSwitch = (event) => {
    setQrBarcodeProductVsCrateSwitch(event.target.checked);
  };
  return (
    <>
      {(parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_WFSADMIN ||
        parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_DISTRIBUTER) && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ marginTop: "60px", marginBottom: "20px" }}
        >
          <Typography
            sx={{
              color:
                qrBarcodeProductVsCrateSwitch === false ? "#30A2F3" : "black",
            }}
          >
            Product Qr/Barcode
          </Typography>
          <AntSwitch
            checked={qrBarcodeProductVsCrateSwitch}
            onChange={handleQrBarcodeProductVsCrateSwitch}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography
            sx={{
              color:
                qrBarcodeProductVsCrateSwitch === true ? "#30A2F3" : "black",
            }}
          >
            Crate Qr/Barcode
          </Typography>
        </Stack>
      )}
      {qrBarcodeProductVsCrateSwitch === false ? (
        <div>
          {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
          parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_SUPERADMIN ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPS ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPSHEAD ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_QA ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_QAHEAD ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
          parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_WFSADMIN ||
          parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_DISTRIBUTER ? (
            <>
              {/* <ToggleButtonGroup
            color="primary"
            value={alignment}
            sx={QRstyles.ToggleGroup}
            exclusive
            onChange={handleToggleChange}
            aria-label="Platform"
          >
            <ToggleButton value="Scan">Scan Qr/Barcode</ToggleButton>
            <ToggleButton value="Generate">Generate Qr/Barcode</ToggleButton>
          </ToggleButtonGroup> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: "3.5em",
                }}
              >
                <>
                  <AppBar
                    position="static"
                    sx={{
                      borderRadius: "10px",
                      background: "rgb(208,221,242)",
                      backgroundImage:
                        "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      defaultValue={0}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab
                        value={0}
                        label="Scan Qr Code"
                        onClick={handelQrScan}
                      />
                      {parseInt(localStorage.getItem("roleId")) !==
                        ADMIN_ROLES.BW_DISTRIBUTER && (
                        <Tab
                          value={1}
                          label="Link Qr Code"
                          onClick={handelLinkQrBarCodeScan}
                        />
                      )}
                      {parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_WFSADMIN ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_DISTRIBUTER ? (
                        ""
                      ) : (
                        <Tab
                          value={2}
                          label="Generate Qr/Barcode"
                          onClick={() =>
                            setGenerateFlag(GenerateFlag ? false : true)
                          }
                        />
                      )}
                    </Tabs>
                  </AppBar>
                  <Box>
                    {tabValue === 0 ? (
                      <FormControl>
                        <FormLabel>Select Scanning Option</FormLabel>
                        <RadioGroup
                          defaultValue="scanner"
                          value={scanOption}
                          name="radio-buttons-group"
                          sx={{ display: "flex", flexDirection: "row" }}
                          onChange={handleSelectScanOption}
                        >
                          <FormControlLabel
                            value="scanner"
                            control={<Radio />}
                            label="Scanner Device"
                          />
                          <FormControlLabel
                            value="camera"
                            control={<Radio />}
                            label="Camera"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : tabValue === 1 ? (
                      <>
                        <Box>
                          <ToggleButtonGroup
                            color="primary"
                            value={linkToggle}
                            exclusive
                            onChange={(event) => handleChangeLink(event)}
                            aria-label="Platform"
                          >
                            <ToggleButton value="linkQr">
                              Link Qr Code
                            </ToggleButton>
                            <ToggleButton value="linkQrBarcode">
                              Link Qr & Barcode
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <FormControl>
                          <FormLabel>Select Scanning Option</FormLabel>
                          <RadioGroup
                            defaultValue="scanner"
                            value={scanOption}
                            name="radio-buttons-group"
                            sx={{ display: "flex", flexDirection: "row" }}
                            onChange={handleSelectScanOption}
                          >
                            <FormControlLabel
                              value="scanner"
                              control={<Radio />}
                              label="Scanner Device"
                            />
                            <FormControlLabel
                              value="camera"
                              control={<Radio />}
                              label="Camera"
                            />
                          </RadioGroup>
                        </FormControl>
                      </>
                    ) : (
                      ""
                    )}
                    {scanOption === "camera" && tabValue === 0 ? (
                      <>
                        {" "}
                        <Html5QrcodePlugin1
                          fps={10}
                          qrbox={250}
                          scanQrFlag={scanQrFlag}
                          disableFlip={false}
                          qrCodeSuccessCallback={onNewScanResultQrCode}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {scanOption === "camera" && tabValue === 1 ? (
                      <>
                        {" "}
                        <Html5QrcodePlugin1
                          fps={1}
                          qrbox={200}
                          disableFlip={false}
                          qrCodeSuccessCallback={onNewScanResult}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {scanOption === "scanner" &&
                    (tabValue === 0 || tabValue === 1) ? (
                      <Box>
                        <TextField
                          onChange={handleQrBarcodeIdFromScanner}
                          label="QR/Barcode:"
                          value={qrBarcodeId}
                          size="small"
                          sx={{
                            height: "10px",
                            width: "200px",
                            marginBotton: "30px",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    ) : (
                      ""
                    )}

                    <ResultContainerPlugin1
                      results={decodedResults}
                      setScanCount={setScanCount}
                      scanType={scanOption}
                    />
                  </Box>
                </>

                {/* : ""} */}
                {tabValue === 2 ? (
                  <Box>
                    <FormControl sx={{ minWidth: 180 }} size="small">
                      <InputLabel
                        id="demo-select-small"
                        sx={{ fontSize: "12px" }}
                      >
                        Generate Qr/Bar Code
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selectAction}
                        label="Select action"
                        onChange={handleChange}
                      >
                        {/* <MenuItem value={10}>Generate QR code</MenuItem>
                        <MenuItem value={20}>Generate Bar code</MenuItem>
                        <MenuItem value={30}>Re-generate QR code</MenuItem>
                        <MenuItem value={40}>Re-generate Bar code</MenuItem> */}
                        <MenuItem value={"GenrateQr"}>
                          Generate QR code
                        </MenuItem>
                        <MenuItem value={"GenrateBarcode"}>
                          Generate Bar code
                        </MenuItem>
                        <MenuItem value={"REGenrateQr"}>
                          Re-generate QR code
                        </MenuItem>
                        <MenuItem value={"ReGenrateBarCode"}>
                          Re-generate Bar code
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  ""
                )}
              </Box>

              {tabValue === 2 && GenerateFlag === true ? (
                <Paper elevation={24}>
                  <Box sx={{ padding: "20px" }}>
                    {selectAction === "GenrateQr" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: {
                              lg: "row",
                              md: "column",
                              sm: "column",
                              xs: "column",
                            },
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Box>
                            <FormControl
                              sx={{ m: 1, minWidth: 140 }}
                              size="small"
                            >
                              <InputLabel id="demo-select-small">
                                Product
                              </InputLabel>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={productName}
                                label="Capacity"
                                onChange={handleChangeSelectedProduct}
                              >
                                {skuContainerDetails.getAvailableProducts.map(
                                  (data) => (
                                    <MenuItem
                                      value={`${data.product_details.capacity}-${data.product_details.material}`}
                                      key={data.product_details.id}
                                    >
                                      <div>
                                        <span>
                                          {" "}
                                          <strong>
                                            {data.product_details.name}
                                          </strong>
                                        </span>
                                        <br />
                                        <small>
                                          Capacity:
                                          {data.product_details.capacity}/
                                        </small>
                                        <small>
                                          Material:
                                          {data.product_details.material}
                                        </small>
                                      </div>
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box>
                            <TextField
                              id="outlined-number"
                              label="Enter the quantity"
                              type="number"
                              size="small"
                              value={quantity}
                              // onInput={(e) => {
                              //   e.target.value = Math.max(0, parseInt(e.target.value))
                              //     .toString()
                              //     .slice(0, 5);
                              // }}
                              onChange={handleQuantityChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="outlined-number"
                              label="Enter batch ID"
                              type="number"
                              size="small"
                              value={batchId}
                              onChange={handleBatchIdChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="outlined-number"
                              label="Manufacturer Name"
                              size="small"
                              value={manufacturer}
                              onChange={handleManufacturerChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="outlined-number"
                              label="Manufacturer address"
                              size="small"
                              value={manufacturerAddress}
                              onChange={handleManufacturerAddressChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                    {selectAction !== "GenrateQr" && selectAction !== "" && (
                      <Box>
                        <TextField
                          id="outlined-number"
                          label="Enter the quantity"
                          type="number"
                          value={quantity}
                          onChange={handleQuantityChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    )}

                    {selectAction !== "" && (
                      <Box sx={{ padding: "10px" }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={appColors.commonBtnColor}
                          onClick={handleGenerate}
                        >
                          Generate
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>
              ) : (
                ""
              )}
              {openQrDetailsDialog === true && (
                // completeQrCodeDetails &&
                // (completeQrCodeDetails.qualityDetails.length ||
                //   completeQrCodeDetails.ownerDetails.length) &&
                <QrDetailsDialog
                  open={openQrDetailsDialog}
                  handleClose={() =>
                    setOpenQrDetailsDialog(() => !openQrDetailsDialog)
                  }
                  data={completeQrCodeDetails}
                  // qrCodeDetails={qrCodeDetails}
                />
              )}

              {openQrDetailsDialog === true ? (
                <>
                  {/* <QrcodeDetailsDialog
                qrCodeDetails={qrCodeDetails}
                openQrDetailsDialog={openQrDetailsDialog}
                setOpenQrDetailsDialog={setOpenQrDetailsDialog}
                setScanQrFlag={setScanQrFlag}
              /> */}
                  {/* <QrDetailsDialog /> */}
                </>
              ) : (
                ""
              )}
              {parseInt(localStorage.getItem("roleId")) ===
                ADMIN_ROLES.BW_WFSADMIN ||
              parseInt(localStorage.getItem("roleId")) ===
                ADMIN_ROLES.BW_DISTRIBUTER ? (
                ""
              ) : (
                <Box sx={{ marginTop: "1em" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ color: "#0f0399" }} variant="h6">
                      <strong>QR/Bar Code Details List</strong>
                    </Typography>
                    <Button
                      onClick={() => setFilterDialog(true)}
                      sx={{ paddingLeft: "8em" }}
                    >
                      Filter &nbsp; &nbsp; &nbsp;
                      <FilterListIcon />
                    </Button>
                  </Box>
                </Box>
              )}

              {parseInt(localStorage.getItem("roleId")) ===
                ADMIN_ROLES.BW_WFSADMIN ||
              parseInt(localStorage.getItem("roleId")) ===
                ADMIN_ROLES.BW_DISTRIBUTER ? (
                ""
              ) : (
                <Box sx={{ maxWidth: { lg: 1, md: 1, sm: 1, xs: 340 } }}>
                  <QrDetailsListTables qrListData={qrListData} />
                </Box>
              )}
              {parseInt(localStorage.getItem("roleId")) ===
                ADMIN_ROLES.BW_WFSADMIN ||
              parseInt(localStorage.getItem("roleId")) ===
                ADMIN_ROLES.BW_DISTRIBUTER ? (
                ""
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                    padding: "10px",
                  }}
                >
                  <Box>
                    <Button
                      onClick={handlePageNumberMinus}
                      sx={{ cursor: "pointer" }}
                    >
                      <ArrowBackIosIcon />
                    </Button>
                  </Box>
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    sx={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      background: appColors.commonBtnColor.background,
                      color: "white",
                      padding: "5px",
                      width: "40px",
                      height: "35px",
                      borderRadius: "15px",
                      textAlign: "center",
                    }}
                  >
                    {pageNumber}
                  </Typography>
                  <Box>
                    <Button
                      disabled={pageStatus}
                      onClick={handlePageNumberPlus}
                      sx={{ cursor: "pointer" }}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                  </Box>
                </Box>
              )}
              <Box>
                <FilterQrbarcodeDialog
                  filterDialog={filterDialog}
                  setFilterDialog={setFilterDialog}
                  ascDsc={ascDsc}
                  qrBarCode={qrBarCode}
                  seriesType={seriesType}
                  setAscDsc={setAscDsc}
                  setQrbarcode={setQrbarcode}
                  setSeriesType={setSeriesType}
                  setFilterFlag={setFilterFlag}
                />
              </Box>
              <Box>
                <QrBarcodeLinkingDialog
                  setLinkingDialogOpen={setLinkingDialogOpen}
                  linkingDialogOpen={linkingDialogOpen}
                  scannedQrCode={scannedQrCode}
                  scannedBarCode={scannedBarCode}
                  setLink={setLink}
                  scanCount={scanCount}
                  setLinkQrBarcodeBtn={setLinkQrBarcodeBtn}
                  inQrBarcodeAdmin={inQrBarcodeAdmin}
                />
              </Box>
            </>
          ) : (
            <Box sx={{ marginTop: "50px" }}>
              <Alert severity="error">Access Denied</Alert>
            </Box>
          )}
        </div>
      ) : (
        <QrBarcodeCrates />
      )}
    </>
  );
}

export default QRBarcode;
