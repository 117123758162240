import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  bannerTypeMsg: "",
  UpdateTypeMsg: "",
  getBannersMsg: "",
  getBannerTypes: [],
  particularTypeMsg: "",
  particularTypeData: {},
  createBannersMsg: "",
  updateBannerMsg: "",
  getAllBannersMsg: "",
  getAllBannersData: [],
  particularBannerMsg: "",
  particularBannerDetails: [],
  bannerTypeListMsg: "",
  bannerTypeListData: [],
};

const adminBannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_BANNER_TYPE:
      return {
        ...state,
        error: action.payload.error,
        bannerTypeMsg: action.payload.msg,
      };
    case actionType.UPDATE_BANNER_TYPE:
      return {
        ...state,
        error: action.payload.error,
        UpdateTypeMsg: action.payload.msg,
      };
    case actionType.GET_BANNER_TYPES:
      return {
        ...state,
        error: action.payload.error,
        getBannersMsg: action.payload.msg,
        getBannerTypes: action.payload.data,
      };
    case actionType.GET_PARTICULAR_BANNER_TYPE:
      return {
        ...state,
        error: action.payload.error,
        particularTypeMsg: action.payload.msg,
        particularTypeData: action.payload.data,
      };
    case actionType.CREATE_BANNERS:
      return {
        ...state,
        error: action.payload.error,
        createBannersMsg: action.payload.msg,
      };
    case actionType.UPDATE_BANNERS:
      return {
        ...state,
        error: action.payload.error,
        updateBannerMsg: action.payload.msg,
      };
    case actionType.GET_ALL_BANNERS:
      return {
        ...state,
        error: action.payload.error,
        getAllBannersMsg: action.payload.msg,
        getAllBannersData: action.payload.data,
      };
    case actionType.GET_PARTICULAR_BANNER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        particularBannerMsg: action.payload.msg,
        particularBannerDetails: action.payload.data,
      };
    case actionType.GET_BANNERTYPE_LIST:
      return {
        ...state,
        error: action.payload.error,
        bannerTypeListMsg: action.payload.msg,
        bannerTypeListData: action.payload.data,
      };
    default:
      return state;
  }
};

export default adminBannersReducer;
