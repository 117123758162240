import React from "react";
import * as Material from "@mui/material";
import {
  Box,
  FormControl,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  RadioGroup,
  Radio,
} from "@mui/material";
import {GetSupplierOnGoingAssignedOrderStatus} from "../Redux/Actions/supliersAction";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";

function ReAssignStatusTable() {
  const [radioDeliveriesValues, setRadioDeliveriesValues] =
    React.useState("Pending");

  const [status,setStatus] = React.useState([]);
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries.getSupplierOngoingDeliveriesStatus);

  

  React.useEffect(()=>{
    if(radioDeliveriesValues ==="Pending"){
      dispatch(GetSupplierOnGoingAssignedOrderStatus({status_id:2}))
    }else if(radioDeliveriesValues === "Delayed"){
      dispatch(GetSupplierOnGoingAssignedOrderStatus({status_id:1}))
    }
  },[radioDeliveriesValues])

  React.useEffect(()=>{
    CustomConsole("-----Supplier deliveries---")
  CustomConsole(supplierDeliveries)
  if(supplierDeliveries){
    setStatus(supplierDeliveries)
  }
  },[supplierDeliveries])
  return (
    <Material.Box>
      <Box elevation={3} width={"85%"}>
        <FormControl>
          <RadioGroup
            row
            size="small"
            value={radioDeliveriesValues}
            onChange={(e) => setRadioDeliveriesValues(e.target.value)}
          >
            <FormControlLabel
              value="Pending"
              control={<Radio />}
              label="Pending"
            />
            <FormControlLabel
              value="Delayed"
              control={<Radio />}
              label="Delayed"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: "#30A2F3" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Pincode</TableCell>
              <TableCell sx={{ color: "white" }}>Area</TableCell>
              <TableCell sx={{ color: "white" }} align="right">
                Orders
              </TableCell>
        
            </TableRow>
          </TableHead>
          <TableBody>
            {status.map((row) => (
              <TableRow
                key={row.pincode  }
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.pincode}
                </TableCell>
                <TableCell >{row.areaname}</TableCell>
                <TableCell >{row.order_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Material.Box>
  );
}

export default ReAssignStatusTable;
