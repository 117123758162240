import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Fab,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GetUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import { toast } from "react-toastify";
import { GenerateInvoice } from "../Redux/Actions/adminPoTrackPostpaid";
import UserCreditNoteDialog from "../../Components/Dialog/creditNoteDialog";
import { GetCreditNoteDetails } from "../Redux/Actions/buyBackOrdersAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConsolidatePoDialog({ open, setOpen, poTrack, selectedOrders }) {
  const dispatch = useDispatch();
  const userAddress = useSelector((state) => state.userDetailsUpdate.address);
  const addUserAdminDetails = useSelector(
    (state) => state.addUserAdminDetails.users
  );
  const poDetails = useSelector((state) => state.poTrackDetails);
  const consumerbuyBackOrderDetails = useSelector(
    (state) => state.buyBackOrderDetails.creditDetails
  );
  const [arrayPO, setArrayPO] = React.useState([]);
  const [orderIds, setOrderIds] = React.useState(new Set());
  const [orders, setOrders] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [deliveryAddress, setDeliveryAddress] = React.useState({});
  const [startDate, setStartDate] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY/MM/DD"));
  const [address, setAddress] = React.useState([]);
  const [invoiceDate, setInvoiceDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [totalOrderPrice, setTotalOrderPrice] = React.useState(0);
  const [totalOrderQty, setOrderQty] = React.useState(0);
  const [totalDeliveryCharge, setTotalDeliveryCharge] = React.useState(0);
  const [generateInv, setGenerateInv] = React.useState(false);
  const [settledOrders, setSettledOrders] = React.useState(false);
  const [creditDialogOpen, setCreditDialogOpen] = React.useState(false);
  const searchOnChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFromDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setStartDate(moment(newValue.$d).format("YYYY/MM/DD"));
    } else {
      return false;
    }
  };

  const handleToDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setEndDate(moment(newValue.$d).format("YYYY/MM/DD"));
    } else {
      return false;
    }
  };

  const handleDateFilter = () => {
    CustomConsole(startDate);
    CustomConsole(endDate);
    const filteredData = selectedOrders.filter((item) => {
      const itemDate = moment(item.delivery_date);
      return itemDate.isBetween(startDate, endDate, null, "[]");
    });
    return setOrders(() =>
      filteredData.filter((obj) => obj.settled === settledOrders)
    );
  };

  const handleClearFilter = () => {
    setOrders(() =>
      selectedOrders.filter((obj) => obj.settled === settledOrders)
    );
    setSearchTerm("");
  };

  const handleInvoiceDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setInvoiceDate(moment(newValue.$d).format("YYYY-MM-DD"));
    } else {
      return false;
    }
  };

  const handleSelect = (orderId) => {
    let orderIdsVar = new Set(orderIds);
    if (orderIdsVar.has(orderId)) {
      orderIdsVar.delete(orderId);
    } else {
      orderIdsVar.add(orderId);
    }
    setOrderIds(orderIdsVar);
  };

  const isSelected = (orderId) => orderIds.has(orderId);

  const selectAllFn = () => {
    if (orderIds.size === orders.length) {
      setOrderIds(new Set());
    } else {
      const allSelect = new Set(orders.map((obj) => obj.order_id));
      setOrderIds(allSelect);
    }
  };

  const generatingInvoices = () => {
    const orderIdArr = Array.from(orderIds);
    const trackIdArr = Array.from(poTrack);
    let reqObj = {
      orderId: orderIdArr,
      poTrackId: trackIdArr,
      addressId: deliveryAddress.address_id,
      invoice_date: invoiceDate,
    };
    if (reqObj.poTrackId && reqObj.addressId && reqObj.orderId) {
      CustomConsole(reqObj);
      dispatch(GenerateInvoice(reqObj));

      setGenerateInv(true);
    } else {
      toast.error("Choose a delivery address!..", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
    setOrderIds(new Set());
    setDeliveryAddress({});
  };

  React.useEffect(() => {
    CustomConsole("-------");
    CustomConsole(selectedOrders);
    if (selectedOrders) {
      setOrders(() =>
        selectedOrders.filter((obj) => obj.settled === settledOrders)
      );
    }
  }, [selectedOrders, settledOrders]);

  React.useEffect(() => {
    if (poTrack) {
      setArrayPO(Array.from(poTrack));
    }
  }, [poTrack]);

  React.useEffect(() => {
    if (searchTerm) {
      const filterOp = [];
      const searchWord = searchTerm.trim();
      for (let item of selectedOrders) {
        const pincodeStr = item.pincode.toString();
        if (
          pincodeStr.includes(searchWord) ||
          item.servicearea.toLowerCase().includes(searchWord.toLowerCase())
        ) {
          filterOp.push(item);
        }
      }
      setOrders(() => filterOp.filter((obj) => obj.settled === settledOrders));
    } else if (selectedOrders) {
      setOrders(() =>
        selectedOrders.filter((obj) => obj.settled === settledOrders)
      );
    }
  }, [selectedOrders, searchTerm, settledOrders]);

  React.useEffect(() => {
    setAddress(userAddress);
  }, [userAddress]);

  React.useEffect(() => {
    if (addUserAdminDetails && addUserAdminDetails.length) {
      setUserId(addUserAdminDetails[0].id);
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (userId && userId.length) {
      dispatch(GetUserAddressAdmin(userId));

    }
  }, [userId]);

  React.useEffect(() => {
    CustomConsole("-----Effect is trigrred----");

    let orderIdsArr = Array.from(orderIds);
    let currentOrders = orders?.filter((order) =>
      orderIdsArr.includes(order.order_id)
    );
    CustomConsole("-----Current Orders-----");
    CustomConsole(currentOrders);

    const totalprice = currentOrders.reduce(
      (acc, order) => acc + order.final_price,
      0
    );
    const orderQty = currentOrders.reduce(
      (acc, order) => acc + order.twentyltrqty,
      0
    );
    const deliveryTotal = currentOrders.reduce(
      (acc, order) =>
        order.ppDeliveryCharge > 0
          ? acc + order.twentyltrqty * order.ppDeliveryCharge
          : acc,
      0
    );
    setTotalOrderPrice(totalprice);
    setOrderQty(orderQty);
    setTotalDeliveryCharge(deliveryTotal);
  }, [orders, orderIds]);

  React.useEffect(() => {
    if (poDetails.error === false && generateInv) {
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (poDetails.error === true && generateInv) {
      toast.error(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setGenerateInv(false);
  }, [poDetails]);

  React.useEffect(() => {
    let orderIdsArr = Array.from(orderIds);
    // let testOrders = ["BTORD-26265", "BTORD-26305"];
    let ordersObj = {};

    orders.forEach((item) => {
      ordersObj[item.order_id] = true;
    });
    CustomConsole("-----New Effect Final Object----");
    CustomConsole(ordersObj);
    orderIdsArr = orderIdsArr.filter((item) => ordersObj[item]);
    CustomConsole("-----New Effect Final OutPut----");
    CustomConsole(orderIdsArr);
    setOrderIds(new Set(orderIdsArr));
  }, [orders]);

  //function to handel credit details
  const handelCreditDetails = () => {
    setCreditDialogOpen(true);
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {arrayPO.join(", ")}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ padding: "20px", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              sx={{ width: "15%" }}
              size="small"
              value={searchTerm}
              onChange={searchOnChange}
              label={"Pincode/Area"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Order From"
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  onChange={handleFromDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      // onKeyDown={handleInputChange}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Order To"
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={handleToDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      // onKeyDown={handleInputChange}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Button onClick={handleDateFilter} variant="contained">
              Search
            </Button>
            <Button onClick={handleClearFilter} variant="outlined">
              Clear Filter
            </Button>
          </Box>

          <Box mt={1} mb={1}>
            <Divider variant="middle" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <FormControl sx={{ width: "250px" }}>
              <InputLabel
                sx={{ fontSize: "0.90rem", marginTop: "2px" }}
                id="demo-simple-select-label"
              >
                Delivery Address
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={deliveryAddress}
                label="Delivery Address"
                onChange={(e) => setDeliveryAddress(e.target.value)}
                size="small"
                sx={{ fontSize: "0.90rem" }}
              >
                {address.map((obj, idx) => {
                  return (
                    <MenuItem
                      value={obj}
                      sx={{ fontSize: "0.69rem" }}
                      key={idx}
                    >
                      {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Invoice Date"
                inputFormat="DD/MM/YYYY"
                value={invoiceDate}
                onChange={handleInvoiceDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // onKeyDown={handleInputChange}
                  />
                )}
              />
            </LocalizationProvider>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <Card sx={{ padding: "10px", backgroundColor: "#D4EBF6" }}>
              Total Qty.: <strong>{totalOrderQty}</strong>
            </Card> */}
              <Card sx={{ padding: "10px", backgroundColor: "#D4EBF6" }}>
                Total amt. &#8377;:{" "}
                <strong>
                  {(totalOrderPrice + totalDeliveryCharge).toFixed(2)}
                </strong>
              </Card>
            </Box>
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sl.no</TableCell>
                  <TableCell align="left">Order Id</TableCell>
                  <TableCell align="left">Pincode</TableCell>
                  <TableCell align="left">Area</TableCell>
                  <TableCell align="left">Booking Date</TableCell>
                  <TableCell align="left">Delivery Date</TableCell>
                  {/* <TableCell align="left">Qty</TableCell> */}
                  <TableCell align="left">Delivery Charge</TableCell>
                  <TableCell align="left">Amount</TableCell>

                  <TableCell align="left">
                    <Checkbox
                      indeterminate={
                        orderIds.size > 0 && orderIds.size < orders.length
                      }
                      checked={orderIds.size === orders.length}
                      onChange={selectAllFn}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map(
                  (row, idx) =>
                    !row.settled && (
                      <TableRow
                        key={row.pp_track_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {idx + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.order_id}
                        </TableCell>
                        <TableCell align="left">{row.pincode}</TableCell>
                        <TableCell align="left">{row.servicearea}</TableCell>
                        <TableCell align="left">{row.booking_date}</TableCell>
                        <TableCell align="left">{row.delivery_date}</TableCell>
                        {/* <TableCell align="left">
                        <Typography>{row.twentyltrqty}</Typography>
                      </TableCell> */}
                        <TableCell align="left">
                          <Typography>
                            {row.ppDeliveryCharge * row.twentyltrqty}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>{row.final_price}</Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Checkbox
                            checked={isSelected(row.order_id)}
                            onChange={() => handleSelect(row.order_id)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {orderIds.size > 0 ? (
            <Fab
              sx={{ mr: 1, position: "fixed", bottom: 16, right: 16 }}
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              onClick={generatingInvoices}
            >
              Generate Invoice
            </Fab>
          ) : null}
          {/* {consumerbuyBackOrderDetails?.length > 0 && orderIds.size > 0 ? (
            <Fab
              sx={{ mr: 1, position: "fixed", bottom: 16, right: 180 }}
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              onClick={handelCreditDetails}
            >
              Credit Details
            </Fab>
          ) : null} */}
        </Box>
        <UserCreditNoteDialog
          open={creditDialogOpen}
          setOpen={setCreditDialogOpen}
        />
      </Dialog>
    </>
  );
}

export default ConsolidatePoDialog;
