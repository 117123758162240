import {
  Button,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import CreateRoles from "./CreateRoles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetAdminRoles,
  EditAdminRoles,
} from "../Redux/Actions/superAdminAction";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: "0px",
  },

  tableContainer: {
    overflowX: "auto",
    "& .MuiTable-root": {
      width: "100%",
      tableLayout: "fixed",
    },
    "& .MuiTableCell-root": {
      padding: "10px",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00B7EB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AdminRolesTable({ roles, departmentList }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const superAdminUpdates = useSelector((state) => state.superAdminUpdates);
  const [openAddNewDlg, setOpenAddNewDlg] = React.useState(false);
  const [addRoleFlag, setAddRoleFlag] = React.useState(false);
  const [roleName, setRoleName] = React.useState("");
  const [editRole, setEditRole] = React.useState(false);
  const [roleId, setRoleId] = React.useState("");
  const [editRoleFlag, setEditRoleFlag] = React.useState(false);

  React.useState(() => {
    dispatch(GetAdminRoles());
  }, []);

  React.useEffect(() => {
    CustomConsole(superAdminUpdates);
    if (addRoleFlag && !superAdminUpdates.addingError) {
      toast.success(superAdminUpdates.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(GetAdminRoles());
      setOpenAddNewDlg(false);
      setAddRoleFlag(false);
    }
    if (editRoleFlag && !superAdminUpdates.error) {
      toast.success(superAdminUpdates.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(GetAdminRoles());
      setOpenAddNewDlg(false);
      setEditRoleFlag(false);
    }
  }, [superAdminUpdates]);

  const addingNewRoles = () => {
    // setOpenAddNewDlg(true);
    // setRoleName("");
    // setEditRole(false);
    toast.info("Adding New Role Feature is in Development Stage!!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const editRoles = (name, id) => {
    toast.info("Editing the Admin Roles is in Development Stage!!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    // setOpenAddNewDlg(true);
    // setEditRole(true);
    // setRoleName(name);
    // setRoleId(id);
  };

  const disableEnableRole = (bool, name, id) => {
    // const reqObj = {
    //   role_name: name,
    //   role_id: id,
    //   is_disabled: !bool,
    // };
    // CustomConsole(reqObj);
    // dispatch(EditAdminRoles(reqObj));
    // setEditRoleFlag(true);
    toast.info("Enable/Disable the Admin Roles is in Development Stage!!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <div className={classes.root}>
      <Box>
        <Button
          sx={{
            float: "right",
            margin: "5px 5px 5px 0px",
          }}
          variant="contained"
          onClick={addingNewRoles}
        >
          Add New Role
        </Button>
      </Box>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Role Name</StyledTableCell>
              {/* <StyledTableCell align="center">Current Status</StyledTableCell> */}
              <StyledTableCell align="center">Change Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.length > 0
              ? roles.map((roles, idx) => {
                  return (
                    <StyledTableRow key={idx + 1}>
                      <StyledTableCell align="center">
                        {roles.role_name}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {roles.is_disabled ? "Inactive" : "Active"}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <Switch
                          checked={!roles.is_disabled}
                          onChange={() => {
                            disableEnableRole(
                              roles.is_disabled,
                              roles.role_name,
                              roles.role_id
                            );
                          }}
                          color="success"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          sx={{
                            marginRight: "5px",
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            editRoles(roles.role_name, roles.role_id);
                          }}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateRoles
        openAddNewDlg={openAddNewDlg}
        setOpenAddNewDlg={setOpenAddNewDlg}
        setAddRoleFlag={setAddRoleFlag}
        roleName={roleName}
        setRoleName={setRoleName}
        editRole={editRole}
        roleId={roleId}
        setEditRoleFlag={setEditRoleFlag}
        departmentList={departmentList}
      />
    </div>
  );
}

export default AdminRolesTable;
