/* @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { url } from "../apis";
import actionType from "./actionType";
//  import * as toastMessage from "../../Assets/Constants/ToastMessages";
//  import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Axios/useAxios";
//  import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const InvoiceProgressReset = (progress) => {
  return (dispatch) => {
    dispatch({
      payload: { error: false },
      type: actionType.UPDATE_GENERATION,
      progress: progress,
    });
  };
};

export const InvoiceGeneration = (invoiceId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/postpaid/generateInvoice?invoiceId=${invoiceId}`,
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            // Calculate and use the progress
            const totalLength = progressEvent.total;
            const loaded = progressEvent.loaded;
            const progress = Math.round((loaded / totalLength) * 100);
            CustomConsole(`Download Progress: ${progress}%`);
            dispatch({
              payload: { error: false },
              type: actionType.INVOICE_GENERATION,
              progress: progress,
            });
          },
        }
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.INVOICE_GENERATION,
          payload: response.data,
        });
        CustomConsole("--------Response From the Api------");
        CustomConsole(response);
        // Create a Blob from the binary response data
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Open the PDF in a new window/tab
        window.open(url, "_blank");
        if (response.data.length <= 0) {
          CustomConsole("no available toast");
        }
        toast.dark("Invoice Generated!", {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.INVOICE_GENERATION,
        payload: {
          error: true,
          msg: "Error in generating invoice check your order combinations!..",
        },
      });
      toast.dark(
        "Error in generating invoice check your order combinations!..",
        {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        }
      );
    }
  };
};

export const PostPaidInvoices = (reqObj) => {
  //   CustomConsole("------- (getFiles) getFiles Action--------");
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      CustomConsole(`${url}/SSM/postpaid/generateInvoice`);
      await fetch(`${url}/SSM/postpaid/generateInvoice`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/pdf",
        },
        body: JSON.stringify(reqObj),
        // responseType: "arrayBuffer",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          CustomConsole("download" + link.href);
          window.open(link.href);
          dispatch({
            type: actionType.DOWNLOAD_INVOICE,
            payload: link.href,
          });
        })
        .catch((error) => {
          CustomConsole("download invoice error : " + error);
        });
    };
  } else {
    toast.dark("Error while downloading", {
      position: "bottom-right",
      autoClose: 5000,
      type: "error",
    });
  }
};
