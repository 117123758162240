import React, { useState } from "react";
// Import necessary dependencies and components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Grid,
} from "@mui/material";

import { useSelector } from "react-redux";
import moment from "moment";
import CustomConsole from "../../CustomConsole";

const WfsEmptyOrderFilter = ({
  open,
  onClose,
  onSubmit,
  selectedOrderType,
  setSelectedOrderType,
}) => {
  const today = moment().add(7, "days").format("YYYY-MM-DD");

  const handleApplyFilters = () => {
    // Perform actions with selectedOrderType
    onSubmit();
    // Close the dialog
    onClose();
  };

  // Handler for resetting filters
  const handleResetFilters = () => {
    // Reset selectedOrderType to initial state
    setSelectedOrderType({
      sort_order: "DESC",
      page_size: 25,
      page_number: 0,
      user_phone: selectedOrderType.user_phone,
      ec_order_id: "",
      address_id: "",
      from_date: selectedOrderType.from_date,
      to_date: selectedOrderType.to_date,
      is_updated_date: false,
      order_status: "",
    });
  };

  //function for setting date value
  const handleChangeFrom = (e) => {
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    CustomConsole(momentDate);
    setSelectedOrderType({ ...selectedOrderType, from_date: momentDate });
  };
  //function for setting date value
  const handleChangeTo = (e) => {
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    CustomConsole(momentDate);
    setSelectedOrderType({ ...selectedOrderType, to_date: momentDate });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter Dialog</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ padding: "1rem" }}>
          <Grid item xs={12} md={6}>
            {/* Add your filter fields here */}
            <TextField
              label="User Phone"
              size="small"
              sx={{ width: "100%" }}
              value={selectedOrderType.user_phone}
              onChange={(e) =>
                setSelectedOrderType({
                  ...selectedOrderType,
                  user_phone: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Add your filter fields here */}
            <TextField
              label="Order Id"
              size="small"
              sx={{ width: "100%" }}
              value={selectedOrderType.ec_order_id}
              onChange={(e) =>
                setSelectedOrderType({
                  ...selectedOrderType,
                  ec_order_id: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort by Order</InputLabel>
              <Select
                name="order_status"
                size="small"
                value={selectedOrderType.order_status}
                onChange={(e) =>
                  setSelectedOrderType({
                    ...selectedOrderType,
                    order_status: e.target.value,
                  })
                }
              >
                <MenuItem value="">All Orders</MenuItem>
                <MenuItem value="100">Order Confirmed</MenuItem>
                <MenuItem value="200">Order Completed</MenuItem>
                <MenuItem value="501">Admin Accepted</MenuItem>
                <MenuItem value="502">Admin Rejected</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort by Order</InputLabel>
              <Select
                name="sort_created_at"
                size="small"
                value={selectedOrderType.sort_order}
                onChange={(e) =>
                  setSelectedOrderType({
                    ...selectedOrderType,
                    sort_order: e.target.value,
                  })
                }
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Add other filter fields (text fields, selects, date pickers, etc.) */}

            {/* Date Pickers */}
            <label
              htmlFor="fromDay"
              style={{ fontSize: "13px", fontWeight: "600" }}
            >
              From:
            </label>
            <input
              type="date"
              id="fromDay"
              name="from"
              value={selectedOrderType.from_date}
              onChange={handleChangeFrom}
              style={{
                backgroundColor: "#AED6F1",
                border: 0,
                padding: "10px",
                fontFamily: "serif",
                fontSize: "16px",
                fontWeight: "500",
                borderRadius: "10px",
                marginLeft: "5px",
              }}
              max={today}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <label
                htmlFor="toDay"
                style={{ fontSize: "13px", fontWeight: "600" }}
              >
                To:
              </label>
              <input
                type="date"
                id="toDay"
                name="to"
                value={selectedOrderType.to_date}
                onChange={handleChangeTo}
                style={{
                  backgroundColor: "#AED6F1",
                  border: 0,
                  padding: "10px",
                  fontFamily: "serif",
                  fontSize: "16px",
                  fontWeight: "500",
                  borderRadius: "10px",
                  marginLeft: "5px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOrderType.is_updated_date}
                  onChange={(e) =>
                    setSelectedOrderType({
                      ...selectedOrderType,
                      is_updated_date: e.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label="Is Updated Date"
            />
          </Grid>
        </Grid>
        <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
          {/* Buttons for applying and resetting filters */}
          <Button
            onClick={onClose}
            color="error"
            variant="contained"
            size="small"
          >
            close
          </Button>
          <Button
            onClick={handleResetFilters}
            color="secondary"
            variant="contained"
            size="small"
          >
            Reset
          </Button>
          <Button
            onClick={handleApplyFilters}
            color="primary"
            variant="contained"
            size="small"
          >
            Filter
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default WfsEmptyOrderFilter;
