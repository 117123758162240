import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import CustomConsole from "../../CustomConsole";
import * as IconName from "react-icons/fc";
import {
  GetSupplierWfsAllOrders,
  GetSupplierWfsCompletedOrders,
  GetSupplierWfsOngoingOrders,
  PutSupplierOngoingStartSubmission,
} from "../Redux/Actions/supliersAction";
import { useDispatch, useSelector } from "react-redux";
import OngoingOrders from "./OngoingOrders";
import AllOrders from "./AllOrders";
import CompletedOrders from "./CompletedOrders";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import SupplierWFSTable from "../../Components/Tables/SupplierWFSTable";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import WfsOrderFormView from "./WfsOrderFormView";
import SupplierStartSubmissionDialog from "../../Components/DialoBoxes/SupplierStartSubmissionDialog";
import WfsOrdersTable from "./WfsOrdersTable";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import { toast } from "react-toastify";
import SupplierWfsSingleOrderDetailsDialog from "./SupplierWfsSingleOrderDetailsDialog";

function WfsAllOrdersView() {
  const dispatch = useDispatch();
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const emptyCanData = useSelector(
    (state) => state.skuWfsDetails.emptyCanDetails
  );
  CustomConsole(emptyCanData);
  CustomConsole(skuWfsDetails);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [wfsOrdersView, setWfsOrdersView] = React.useState("form");
  const [wfsOrdersType, setWfsOrdersType] = React.useState("ONGOING");
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [testArr, setTestArr] = React.useState([]);

  const [pageForward, setPageForward] = React.useState(false);
  const [openSupplierWFSOrdersDialog, setOpenSupplierWFSOrdersDialog] =
    React.useState(false);
  const [searchOrderId, setSearchOrderId] = React.useState("");
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [openStartSubmissionDialog, setOpenStartSubmissionDialog] =
    React.useState(false);
  const [selectedOrderType, setSelectedOrderType] = React.useState({
    sort_order: "DESC",
    page_size: pageSize,
    page_number: pageNumber,
    wfs_id: "",
    user_phone: "",
    ec_order_id: "",
    address_id: "",
    from_date: "",
    to_date: "",
    is_updated_date: true,
    order_status: 501,
  });
  // React.useEffect(() => {
  //   dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  // }, []);

  React.useEffect(() => {
    if (wfsOrdersView === "form" && wfsOrdersType === "ONGOING") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "form" && wfsOrdersType === "COMPLETED") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "form" && wfsOrdersType === "ALLORDERS") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "table" && wfsOrdersType === "ONGOING") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "table" && wfsOrdersType === "COMPLETED") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "table" && wfsOrdersType === "ALLORDERS") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "card" && wfsOrdersType === "ONGOING") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "card" && wfsOrdersType === "COMPLETED") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "card" && wfsOrdersType === "ALLORDERS") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    }
  }, [selectedOrderType]);

  // React.useEffect(() => {
  //   if (wfsOrdersView === "form" && wfsOrdersType === "ONGOING") {
  //     dispatch(
  //       GetSupplierWfsOngoingOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "form" && wfsOrdersType === "COMPLETED") {
  //     dispatch(
  //       GetSupplierWfsCompletedOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "form" && wfsOrdersType === "ALLORDERS") {
  //     dispatch(
  //       GetSupplierWfsAllOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "table" && wfsOrdersType === "ONGOING") {
  //     dispatch(
  //       GetSupplierWfsOngoingOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "table" && wfsOrdersType === "COMPLETED") {
  //     dispatch(
  //       GetSupplierWfsCompletedOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "table" && wfsOrdersType === "ALLORDERS") {
  //     dispatch(
  //       GetSupplierWfsAllOrders({
  //         page_number: pageNumber,
  //         pageSize: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "card" && wfsOrdersType === "ONGOING") {
  //     dispatch(
  //       GetSupplierWfsOngoingOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "card" && wfsOrdersType === "COMPLETED") {
  //     dispatch(
  //       GetSupplierWfsCompletedOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "card" && wfsOrdersType === "ALLORDERS") {
  //     dispatch(
  //       GetSupplierWfsAllOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //   }
  // }, [pageSize]);

  // React.useEffect(() => {
  //   skuWfsDetails.emptyCanDetails &&
  //     skuWfsDetails.emptyCanDetails !== undefined &&
  //     skuWfsDetails.emptyCanDetails !== null &&
  //     skuWfsDetails.emptyCanDetails.length > 0 &&
  //     skuWfsDetails.emptyCanDetails.map((data) => {
  //       setSingleIdData(data);
  //     });
  // }, [skuWfsDetails]);

  React.useEffect(() => {
    if (
      skuWfsDetails !== undefined &&
      skuWfsDetails.emptyCanDetails !== undefined &&
      skuWfsDetails.emptyCanDetails.length > 0
    ) {
      // if (pageForward) {
      //   setPageNumber(() => pageNumber + 1);
      // } else if (pageBackWard) {
      //   setPageNumber(() => pageNumber - 1);
      // }
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
    if (
      skuWfsDetails !== undefined &&
      skuWfsDetails.emptyCanDetails !== undefined &&
      skuWfsDetails.emptyCanDetails.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
    if (
      skuWfsDetails !== undefined &&
      skuWfsDetails.emptyCanDetails !== null &&
      skuWfsDetails.emptyCanDetails !== undefined &&
      skuWfsDetails.emptyCanDetails.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  }, [skuWfsDetails]);

  const handleOrdersView = (event) => {
    setWfsOrdersView(event.target.value);
    setPageSize(25);
    setPageNumber(0);
  };
  const handleClickForward = () => {
    CustomConsole("---handleClickForward -----");
    CustomConsole("--pageNumber---:" + pageNumber);
    CustomConsole(
      "--supplierDeliveries.length---:" + supplierDeliveries.length
    );
    CustomConsole(JSON.stringify(supplierDeliveries));
    if (pageNumber + 1 > 0) {
      setPageNumber(() => pageNumber + 1);
      setSelectedOrderType((prev) => ({
        ...prev,
        page_number: prev.page_number + 1,
      }));
    }
    // setPageStatuss(false);
  };

  const handleClickBack = () => {
    if (pageNumber > 0) {
      setPageBackWard(true);
      setPageForward(false);
      setPageNumber(() => pageNumber - 1);
      setSelectedOrderType((prev) => ({
        ...prev,
        page_number: prev.page_number - 1,
      }));
    }
  };
  const handleChangeWfsOrdersType = (event) => {
    var status = "";
    if (event.target.value === "ONGOING") {
      status = 501;
    }
    if (event.target.value === "COMPLETED") {
      status = 200;
    }
    if (event.target.value === "ALLORDERS") {
      status = "";
    }

    setWfsOrdersType(event.target.value);
    setPageSize(25);
    setPageNumber(0);
    setSelectedOrderType((prev) => ({
      ...prev,
      order_status: status,
    }));
  };
  const handleStartSubmission = (props) => {
    CustomConsole(props);

    setOpenStartSubmissionDialog(true);
    // dispatch(
    //   PutSupplierOngoingStartSubmission({
    //     order_id: props,
    //   })
    // );
  };
  const handleOkDialog = (props) => {
    CustomConsole("-----handleOkDialog-----------");
    CustomConsole(props);
    setOpenStartSubmissionDialog(false);
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    }, 300);
  };

  let result = [];
  function toCSV(items) {
    if (items) {
      items.map((a) => {
        result.push(a);
        // result.push(a.packaged_drinking_water);
      });
    }
    setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };

  const handleSearchPageSize = () => {
    if (wfsOrdersView === "form" && wfsOrdersType === "ONGOING") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "form" && wfsOrdersType === "COMPLETED") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "form" && wfsOrdersType === "ALLORDERS") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "table" && wfsOrdersType === "ONGOING") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "table" && wfsOrdersType === "COMPLETED") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "table" && wfsOrdersType === "ALLORDERS") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "card" && wfsOrdersType === "ONGOING") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "card" && wfsOrdersType === "COMPLETED") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    } else if (wfsOrdersView === "card" && wfsOrdersType === "ALLORDERS") {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    }
  };
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
    setSelectedOrderType((prev) => ({
      ...prev,
      page_size: event.target.value,
    }));
  };

  const handleViewDetails = () => {
    // dispatch(GetOrderIdDetails("ECON" + searchOrderId));
    CustomConsole("BTORD-" + searchOrderId);
    if (searchOrderId) {
      const orderId = searchOrderId.trim().toUpperCase();
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: "ECON" + orderId }));
      // setOpenSupplierWFSOrdersDialog(true);
    } else {
      toast.error("Please Enter Order Id", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    // setListOrderFlag(true);
    // setSearchOrderId("");
  };
  const handleCloseWFSOrderDialog = () => {
    setOpenSupplierWFSOrdersDialog(false);
  };
  return (
    <Material.Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "end", xs: "center" },
          flexWrap: "wrap",
        }}
      >
        {/* <Material.Box
          sx={{
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "row",
            gap: "10px",
          }}
        > */}
        <Box sx={{ mt: 2 }}>
          <Material.TextField
            label="Search By Order Id"
            size="small"
            value={searchOrderId}
            onChange={(event) => setSearchOrderId(event.target.value)}
            InputProps={{
              startAdornment: (
                <Material.InputAdornment position="start">
                  <Material.Typography
                    sx={{ fontWeight: "bold", color: "black" }}
                  >
                    ECON
                  </Material.Typography>
                </Material.InputAdornment>
              ),
              endAdornment: (
                <Material.InputAdornment position="end">
                  <IconName.FcSearch
                    cursor="pointer"
                    size="30px"
                    onClick={handleViewDetails}
                  />
                </Material.InputAdornment>
              ),
              style: { borderRadius: "50px", width: "220px" },
            }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Material.Box
            sx={{
              margin: "10px",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              alignItems: "center",
            }}
          >
            <FormControl>
              <Material.InputLabel>Ui View</Material.InputLabel>
              <Material.Select
                variant="standard"
                sx={{ fontSize: "14px", fontWeight: "bold", width: "80px" }}
                value={wfsOrdersView}
                label="Ui View"
                onChange={handleOrdersView}
              >
                <Material.MenuItem value="form">Form</Material.MenuItem>
                <Material.MenuItem value="table">Table</Material.MenuItem>
                <Material.MenuItem value="card">Card</Material.MenuItem>
              </Material.Select>
            </FormControl>
          </Material.Box>

          {/* </Material.Box> */}
          <Material.Box sx={{ margin: "10px" }}>
            <FormControl>
              <InputLabel>Select Delivery Type</InputLabel>
              <Select
                variant="standard"
                value={wfsOrdersType}
                label="Select Delivery Type"
                onChange={handleChangeWfsOrdersType}
                sx={{ fontSize: "14px", fontWeight: "bold", width: "150px" }}
              >
                <MenuItem value="ONGOING">ONGOING </MenuItem>
                <MenuItem value="ALLORDERS">ALLORDERS</MenuItem>
                <MenuItem value="COMPLETED">COMPLETED</MenuItem>
              </Select>
            </FormControl>
          </Material.Box>
          {/* <Box sx={{ marginTop: "20px" }}>
            <Button variant="outlined" onClick={handleFilterDialogOpen}>
              Filter
            </Button>
          </Box> */}
        </Box>

        {/* <Box sx={{ marginTop: "20px" }}>
            <Button variant="outlined" onClick={handleFilterDialogOpen}>
              Filter
            </Button>
          </Box> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // margin: "10px",
          }}
        >
          {/* Click on Backward Arrow Button */}
          <Button onClick={handleClickBack}>
            <ArrowBackIos />
          </Button>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "30px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber + 1}
          </Typography>
          {/* Click on Forward Arrow Button */}
          <Button disabled={pageStatuss} onClick={handleClickForward}>
            <ArrowForwardIos />
          </Button>
        </Box>
        <Box sx={{ maxWidth: "120px", maxHeight: "10px" }}>
          <Material.TextField
            size="small"
            value={pageSize}
            label="Page size"
            // onChange={(e) => {
            //   setPageSize(e.target.value);
            //   // setSearchOrderFlag(true);
            // }}

            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ margin: 1 }}>
          <CSVLink {...csvLink}>
            {" "}
            <DownloadIcon
              sx={{ fontSize: 35 }}
              onClick={() =>
                toCSV(
                  wfsOrdersType === "ONGOING"
                    ? skuWfsDetails.emptyCanDetails
                    : wfsOrdersType === "ALLORDERS"
                    ? skuWfsDetails.emptyCanDetails
                    : wfsOrdersType === "COMPLETED"
                    ? skuWfsDetails.emptyCanDetails
                    : null
                )
              }
            />
          </CSVLink>
        </Box>
      </Box>
      {(skuWfsDetails.emptyCanDetails !== undefined &&
        skuWfsDetails.emptyCanDetails !== null &&
        skuWfsDetails.emptyCanDetails.length > 0) ||
      (skuWfsDetails.emptyCanDetails !== undefined &&
        skuWfsDetails.emptyCanDetails !== null &&
        skuWfsDetails.emptyCanDetails.length > 0) ||
      (skuWfsDetails.emptyCanDetails !== undefined &&
        skuWfsDetails.emptyCanDetails !== null &&
        skuWfsDetails.emptyCanDetails.length > 0) ? (
        <Box sx={{ marginTop: "10px" }}>
          {wfsOrdersView === "form" ? (
            <WfsOrderFormView
              selectedOrderType={selectedOrderType}
              data={
                wfsOrdersType === "ONGOING"
                  ? skuWfsDetails.emptyCanDetails
                  : wfsOrdersType === "ALLORDERS"
                  ? skuWfsDetails.emptyCanDetails
                  : wfsOrdersType === "COMPLETED"
                  ? skuWfsDetails.emptyCanDetails
                  : null
              }
              pageSize={pageSize}
              pageNumber={pageNumber}
            />
          ) : wfsOrdersView === "table" ? (
            <WfsOrdersTable
              selectedOrderType={selectedOrderType}
              data={
                wfsOrdersType === "ONGOING"
                  ? skuWfsDetails.emptyCanDetails
                  : wfsOrdersType === "ALLORDERS"
                  ? skuWfsDetails.emptyCanDetails
                  : wfsOrdersType === "COMPLETED"
                  ? skuWfsDetails.emptyCanDetails
                  : null
              }
              handleStartSubmission={handleStartSubmission}
            />
          ) : // <SupplierWFSTable
          //   data={
          //     wfsOrdersType === "ONGOING"
          //       ? skuWfsDetails.emptyCanDetails
          //       : wfsOrdersType === "ALLORDERS"
          //       ? skuWfsDetails.emptyCanDetails
          //       : wfsOrdersType === "COMPLETED"
          //       ? skuWfsDetails.emptyCanDetails
          //       : null
          //   }
          //   handleStartSubmission={handleStartSubmission}
          // />
          wfsOrdersView === "card" ? (
            wfsOrdersType === "ONGOING" ? (
              <OngoingOrders selectedOrderType={selectedOrderType} />
            ) : wfsOrdersType === "ALLORDERS" ? (
              <AllOrders selectedOrderType={selectedOrderType} />
            ) : wfsOrdersType === "COMPLETED" ? (
              <CompletedOrders selectedOrderType={selectedOrderType} />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Box>
      ) : (
        <Material.Box sx={{ marginTop: "20px" }}>
          <Material.Typography color="error">
            No Orders Found
          </Material.Typography>
        </Material.Box>
      )}
      <SupplierStartSubmissionDialog
        openStartSubmissionDialog={openStartSubmissionDialog}
        handleClose={handleOkDialog}
        // storeStartSubmissionData={storeStartSubmissionData}
      />
      {/* {openSupplierWFSOrdersDialog && (
        <SupplierWfsSingleOrderDetailsDialog
          openSupplierWFSOrdersDialog={openSupplierWFSOrdersDialog}
          singleIdData={singleIdData}
          handleCloseWFSOrderDialog={handleCloseWFSOrderDialog}
          // ordersStatusWFSCan={ordersStatusWFSCan}
          // handleStart={handleStart}
          // handleEditEmptyCanDetails={handleEditEmptyCan}
        />
      )} */}
    </Material.Box>
  );
}

export default WfsAllOrdersView;
