import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import GameActionCard from "./GameActionCard";
import styles from "./campaignDetailsStyle";
import moment from "moment";
import { useDispatch } from "react-redux";
import { PlayAGame } from "../Redux/Actions/gamificationActions";

import CustomConsole from "../../CustomConsole";
function CampaignDetails(props) {
  const dispatch = useDispatch();
  const [campStatus, setCampStatus] = React.useState(true);
  React.useEffect(() => {
    CustomConsole("-- props --");
    CustomConsole(props);
    if (props.data.endDate) {
      CustomConsole(props.data.endDate);
      CustomConsole(moment());
      CustomConsole(moment(props.data.endDate).isSameOrAfter(moment(), "day"));
      const isToday = moment(props.data.endDate).isSameOrAfter(moment(), "day");
      setCampStatus(isToday);
    }
  }, [props]);
  const handleUserGameAction = (gameDetails) => {
    CustomConsole("--- handleUserGameAction ---");
    CustomConsole(props);
    let gameActionObject = gameDetails;
    gameActionObject.campaign_id = props.data.campaignId;
    gameActionObject.fromDate = props.data.startDate;
    gameActionObject.toDate = props.data.endDate;
    gameActionObject.sales_rep_email =
      props.data.salesRepEmails && props.data.salesRepEmails.length
        ? props.data.salesRepEmails[0]
        : "";

    if (props.salesRep.length) {
      gameActionObject.sales_rep_email = props.salesRep + "@bookwater.com";
    }
    CustomConsole("-- Hit API here --");
    CustomConsole(gameActionObject);
    props.setOpenProgressBar(true);
    dispatch(PlayAGame(gameActionObject));
  };
  return (
    <Material.Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: props.indexValue % 2 === 0 ? "#fce4d4" : "#f5f7c6",
      }}
      elevation={12}
    >
      <Material.Box sx={styles.contenctAlignBoxHead}>
        <Material.Typography sx={styles.mainHeading}>
          Campaign Name:
        </Material.Typography>
        <Material.Typography sx={[styles.mainHeadData, { fontSize: "16px" }]}>
          {props?.data?.name || "-"}
        </Material.Typography>

        <Material.Typography
          sx={{
            marginLeft: { md: "10px" },
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          ({props?.data?.location + " , " + props?.data?.subLocation || "-"})
        </Material.Typography>
      </Material.Box>
      <Material.Typography
        sx={{
          marginLeft: { md: "10px" },
          fontSize: "13px",
          fontWeight: "bold",
        }}
      >
        {props.data.startDate && props.data.endDate
          ? `${moment(props.data.startDate).format("DD-MM-YYYY")} to ${moment(
              props.data.endDate
            ).format("DD-MM-YYYY")}`
          : ""}
      </Material.Typography>
      <Material.Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        {props.data.userActionsAndRewards &&
          props.data.userActionsAndRewards.length &&
          props.data.userActionsAndRewards.map((data, index) => {
            return (
              <GameActionCard
                campstatus={campStatus}
                promoters={props.data.promoterEmails}
                key={index}
                data={data}
                handleUserGameAction={handleUserGameAction}
              />
            );
          })}
      </Material.Grid>
    </Material.Paper>
  );
}

export default CampaignDetails;
