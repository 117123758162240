/**
 * @author vinay kumar
 * @description Users  Styles
 * @copyright Bookwater tech pvt ltd
 */

export const UsersStyles = {
  cardshadow: {
    "&:hover": {
      boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
    },
    border: "1px solid black",
    ToggleButton: {
      background: "#EBF5FB",
      color: "blue",
      borderRadius: "10px",
    },
    padding: "10px",
  },
};
