/**
 * @author vinay kumar
 * @description Ongoing Orders
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 22-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import SupplierWFSTable from "../../Components/Tables/SupplierWFSTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import Select from "@mui/material/Select";
import {
  GetSupplierWfsOngoingOrders,
  PutSupplierOngoingStartSubmission,
} from "../Redux/Actions/supliersAction";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import SupplierStartSubmissionDialog from "../../Components/DialoBoxes/SupplierStartSubmissionDialog";
import WfsAllOrderDetailsCard from "../../Components/Cards/WfsAllOrderDetailsCard";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";

function OngoingOrders({ selectedOrderType }) {
  const history = useHistory();
  const [orderId, setOrderId] = React.useState("");
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [startBtn, setStartBtn] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  CustomConsole(orderId);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [openStartSubmissionDialog, setOpenStartSubmissionDialog] =
    React.useState(false);
  const [storeStartSubmissionData, setStoreStartSubmissionData] =
    React.useState("");
  const [pageSize, setPageSize] = React.useState(25);
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  // React.useEffect(() => {
  //   dispatch(GetSupplierWfsOngoingOrders({ pageNumber: pageNumber - 1 }));
  // }, []);
  React.useEffect(() => {
    CustomConsole(supplierDeliveries);
    if (
      supplierDeliveries &&
      supplierDeliveries.putSupplerOnGoingStartSubmission &&
      supplierDeliveries.putSupplerOnGoingStartSubmission.msg &&
      !supplierDeliveries.putSupplerOnGoingStartSubmission.error
    ) {
      setStoreStartSubmissionData(
        supplierDeliveries.putSupplerOnGoingStartSubmission.msg
      );
    }
    // Pagination Status enable or Disable
    // if (
    //   supplierDeliveries !== undefined &&
    //   supplierDeliveries.getSupplierWfsOngoingOrders !== undefined &&
    //   supplierDeliveries.getSupplierWfsOngoingOrders.length > 0
    // ) {
    //   setPageStatuss(false);
    // } else {
    //   setPageStatuss(true);
    // }

    if (
      skuWfsDetails?.emptyCanDetails?.emptyCanError === false &&
      startBtn === true
    ) {
      dispatch(dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType)));
      setStartBtn(false);
    }
  }, [skuWfsDetails?.emptyCanDetails]);
  // React.useEffect(() => {}, [pageForward, pageBackWard]);
  // React.useEffect(() => {
  //   if (pageSize) {
  //     dispatch(
  //       GetSupplierWfsOngoingOrders({
  //         pageNumber: 0,
  //         pageSize: pageSize,
  //       })
  //     );
  //   }
  // }, [pageSize]);
  // const handleOpenStartSubmissionDialog = (props) => {
  //   CustomConsole("-----handleOpenStartSubmissionDialog-----------");
  //   CustomConsole(props);
  //   if (props.OrderId && props.OrderId.length) {
  //     dispatch(PutSupplierOngoingStartSubmission({ order_id: props.OrderId }));
  //     setOrderId(props.OrderId);
  //     setOpenStartSubmissionDialog(true);
  //     setStartBtn(true);
  //   } else {
  //     CustomConsole("-- order id not proper --");
  //   }
  // };

  const handleOkDialog = (props) => {
    CustomConsole("-----handleOkDialog-----------");
    CustomConsole(props);
    setOpenStartSubmissionDialog(false);
    setStartBtn(true);
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    }, 300);
  };
  React.useEffect(() => {
    if (pageNumber) {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
      setPageBackWard(true);
      setPageForward(true);
    }
  }, [pageNumber]);

  React.useEffect(() => {
    if (
      skuWfsDetails?.emptyCanDetails !== undefined &&
      skuWfsDetails?.emptyCanDetails.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  });

  const handleClickBack = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setPageBackWard(true);
  };

  const handleClickForward = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
    setPageForward(true);
  };
  // React.useEffect(() => {
  //   dispatch(
  //     GetSupplierWfsOngoingOrders({
  //       pageNumber: pageNumber - 1,
  //       pageSize: pageSize,
  //     })
  //   );
  // }, [pageNumber]);

  // const handleClickForward = () => {
  //   if (supplierDeliveries.getSupplierWfsOngoingOrders.length > 0) {
  //     setPageNumber(pageNumber + 1);
  //   } else {
  //     setPageStatuss(true);
  //   }
  // };

  // const handleClickBack = () => {
  //   if (pageNumber - 1 > 0) {
  //     setPageNumber(pageNumber - 1);
  //     setPageStatuss(false);
  //   }
  // };

  //Backward Arrow Button
  // const handleClickBack = () => {
  // if (pageNumber - 1 > 0) {
  //   setPageNumber(() => pageNumber - 1);
  //   setPageBackWard(true);
  //   setPageForward(false);
  //   // setPageNumber(pageNumber - 1);
  //   dispatch(
  //     GetSupplierWfsOngoingOrders({
  //       pageNumber: pageNumber - 1,
  //       pageSize: pageSize,
  //     })
  //   );
  // }
  // if (pageNumber > 0) {
  //   setPageBackWard(true);
  //   setPageForward(false);
  //   setPageNumber(() => pageNumber - 1);
  //   // setPageNumber(pageNumber - 1);
  //   dispatch(
  //     GetSupplierWfsOngoingOrders({
  //       pageNumber: pageNumber,
  //       // pageSize: pageSize,
  //     })
  //   );
  // }
  // };
  //Foewqrd Arrow Button
  // const handleClickForward = () => {
  // if (pageNumber + 1 > 0) {
  //   setPageNumber(() => pageNumber + 1);
  //   setPageBackWard(false);
  //   setPageForward(true);
  //   dispatch(
  //     GetSupplierWfsOngoingOrders({
  //       pageNumber: pageNumber,
  //       pageSize: pageSize,
  //     })
  //   );
  // }
  // if (pageNumber + 1> 0) {
  //   setPageNumber(() => pageNumber + 1);

  //   dispatch(
  //     GetSupplierWfsOngoingOrders({
  //       pageNumber: pageNumber,
  //       // pageSize: pageSize,
  //     })
  //   );
  // }
  // };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
  };

  const handleStartSubmission = (props) => {
    CustomConsole("----------Start Submission-----------");
    setOpenStartSubmissionDialog(true);
    setOrderId(props.OrderId);
    dispatch(PutSupplierOngoingStartSubmission({ order_id: props.OrderId }));
  };

  const handleBack = () => {
    const url = `/dashboard/deliveries`;
    history.push(url);
  };
  return (
    <Material.Box
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      {/* {supplierDeliveries.getSupplierWfsOngoingOrders.length > 0 ? (
        <SupplierWFSTable
          data={supplierDeliveries.getSupplierWfsOngoingOrders}
          handleStartSubmission={handleOpenStartSubmissionDialog}
          // setPageNumbers={setPageNumbers}
          // pageNumbers={pageNumbers}
        />
      ) : (
        <Material.Typography>No Ongoing Orders Left</Material.Typography>
      )} */}
      {skuWfsDetails?.emptyCanDetails !== null &&
      skuWfsDetails?.emptyCanDetails !== undefined &&
      skuWfsDetails?.emptyCanDetails?.length > 0 ? (
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            overflow: "auto",
            height: "420px",
          }}
        >
          <>
            {skuWfsDetails?.emptyCanDetails?.map((data, key) => {
              return (
                <WfsAllOrderDetailsCard
                  key={key}
                  data={data}
                  OrderId={data.ec_order_id}
                  NewConatinerQty={data.order_details.order_qty}
                  EmptyCans={data.order_details.return_qty}
                  NoofDamagedCans={data.order_details.damaged_qty}
                  NoofExpiredCans={data.order_details.expired_qty}
                  OrderStatus={data.ec_order_status}
                  container_material={data.order_details.container_material}
                  container_capacity={data.order_details.container_capacity}
                  Otp={data.otp}
                  OrderAssignedTo={data.assigned_wfs_details.wfs_incharge_name}
                  Address={data.assigned_wfs_details.wfs_incharge_name}
                  OrderStarted={data.order_details.is_submit_started}
                  OrderFinished={data.order_details.is_submit_finished}
                  OrderCreatedAt={data.created_at}
                  handleStart={handleStartSubmission}
                  //  handleStartSubmission={handleOpenStartSubmissionDialog}
                  // setPageNumbers={setPageNumbers}
                  // pageNumbers={pageNumbers}
                />
              );
            })}
          </>
        </Material.Box>
      ) : (
        ""
      )}
      {/* <WfsAllOrderDetailsCard /> */}
      <SupplierStartSubmissionDialog
        openStartSubmissionDialog={openStartSubmissionDialog}
        handleClose={handleOkDialog}
        storeStartSubmissionData={storeStartSubmissionData}
      />
      {/* <Material.Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
          }}
        >
          
          <Material.Button onClick={handleClickBack}>
            <ArrowBackIos /> Prev
          </Material.Button>
          <Material.Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "40px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber}
          </Material.Typography>
          
          <Material.Button disabled={pageStatuss} onClick={handleClickForward}>
            Next <ArrowForwardIos />
          </Material.Button>
        </Material.Box>
        <Material.Box sx={{ display: "flex" }}>
          <Material.Box>
            <Material.Button variant="outlined" onClick={handleBack}>
              {" "}
              <Material.Typography sx={{ fontSize: "30px" }}>
                🔙
              </Material.Typography>
            </Material.Button>
          </Material.Box>
          <Material.Box>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel>Page size</InputLabel>
              <Select
                value={pageSize}
                label="Page size"
                onChange={handleChangePageSize}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
                <MenuItem value={1000}>1000</MenuItem>
              </Select>
            </FormControl>
          </Material.Box>
        </Material.Box>
      </Material.Box> */}
    </Material.Box>
  );
}

export default OngoingOrders;
