import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { INFLUENCER_TYPE } from "../../Components/AppMeta/appMetaConfig";
import { useDispatch } from "react-redux";
import { GetInfluencerRequests } from "../Redux/Actions/adminInfluencerAction";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";

function AdminInfluencersSearchCard({
  pageNumberReload,
  influencerData,
  setInfluencer,
  setDetailsCardFlag,
  setPageNumberReload,
  setSearchRestrictFlag,
  setTableview,
}) {
  const dispatch = useDispatch();
  const [influencerType, setInfluencerType] = React.useState(
    INFLUENCER_TYPE.USER_TYPE_INFLUENCER
  );

  //flags
  const [searchBtnTriggger, setSearchBtnTrigger] = React.useState(false);

  React.useEffect(() => {
    dispatch(GetInfluencerRequests(0, influencerType));
    setInfluencer(influencerType);
    setSearchRestrictFlag(false);
    setSearchBtnTrigger(true);
    setDetailsCardFlag(false);
    setTableview(true);
  }, []);

  React.useEffect(() => {
    CustomConsole(influencerData);
    if (searchBtnTriggger && influencerData.error) {
      // toast.error(influencerData.msg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setSearchBtnTrigger(false);
    }
  }, [influencerData]);

  const searchInfluencers = () => {
    if (influencerType !== "") {
      setPageNumberReload(1);
      CustomConsole("------Hitting Search------");
      dispatch(GetInfluencerRequests(0, influencerType));
      setInfluencer(influencerType);
      setSearchRestrictFlag(false);
      setSearchBtnTrigger(true);
      setDetailsCardFlag(false);
      setTableview(true);
    }
  };
  const handleChangeInInfluencerType = (e) => {
    setInfluencerType(e.target.value);
    setPageNumberReload(1);
    CustomConsole("------Hitting Search------");
    dispatch(GetInfluencerRequests(0, e.target.value));
    setInfluencer(e.target.value);
    setSearchRestrictFlag(false);
    setSearchBtnTrigger(true);
    setDetailsCardFlag(false);
    setTableview(true);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControl sx={{ m: 1, minWidth: 220 }}>
          <InputLabel id="select-label-influencer">Influencer Type</InputLabel>
          <Select
            labelId="select-label-influencer"
            label="Influencer Type"
            value={influencerType}
            // onChange={(e) => {
            //   setInfluencerType(e.target.value);
            // }}
            onChange={handleChangeInInfluencerType}
            id="select-influencer"
          >
            <MenuItem value={INFLUENCER_TYPE.USER_TYPE_INFLUENCER}>
              Current Influencers
            </MenuItem>
            <MenuItem value={INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REQUESTED}>
              Requested
            </MenuItem>
            <MenuItem value={INFLUENCER_TYPE.USER_TYPE_INFLUENCER_DISABLED}>
               Disabled
            </MenuItem>
            <MenuItem value={INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REJECTED}>
               Rejected
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* <Box sx={{ marginTop: "20px" }}>
            <Button onClick={searchInfluencers} variant="contained">
              Search
            </Button>
          </Box> */}
    </>
  );
}

export default AdminInfluencersSearchCard;
