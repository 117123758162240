import React from "react";
import * as Material from "@mui/material";
import { Typography } from "@mui/material";
import PlaceDistributorReturnOrderDialog from "../../Pages/AdminUsers/PlaceDistributorReturnOrderDialog";
import { PostRequestSlotForFilling } from "../../Pages/Redux/Actions/supliersAction";
import CustomConsole from "../../CustomConsole";

function SupplierReturnCanStockDetailsCard(props) {
  const handlePlaceReturnCanOrder = () => {
    CustomConsole("-------handleReturnCan---------");
    props.handlePlaceReturnCanOrder();
  };

  return (
    <>
      <Material.Paper sx={{ padding: "10px" }} elevation={15}>
        {Object.keys(props.supplierDeliveries.distStock).length ? (
          <>
            <Material.Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
            >
              <Material.Button
                variant="contained"
                onClick={handlePlaceReturnCanOrder}
              >
                Submit
              </Material.Button>
            </Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Material.Grid container spacing={2}>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    Container Material
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.container_material}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    Container Capacity
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.container_capacity}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    Physically Damaged
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.physically_damage}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    QR/Bar code Damage
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.qr_bar_code_damage}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    Valid Containers
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.valid_containers}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    Filled Can Qty.
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.filled_cans_qty}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    Water Expired Can
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.water_expired_can_qty}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                    Replace Can Qty.
                  </Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {props.supplierDeliveries.distStock.replace_can_qty}
                </Material.Grid>
                <Material.Grid item md={3} sm={12} xs={6}>
                  <Material.Typography
                    sx={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Return Can Submitting
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid
                  item
                  md={3}
                  sm={12}
                  xs={6}
                  sx={{ textAlign: "left" }}
                >
                  {
                    props.supplierDeliveries.distStock
                      .return_containers_submitting
                  }
                </Material.Grid>
              </Material.Grid>
            </Material.Box>
          </>
        ) : null}
      </Material.Paper>
    </>
  );
}

export default SupplierReturnCanStockDetailsCard;
