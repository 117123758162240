import React from "react";
import * as Material from "@mui/material";
import Html5QrcodePlugin1 from "../../../Pages/wfsHome/HtmlQrcodePlugin1";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PostQrBarcodeSwapRequest } from "../../../Pages/Redux/Actions/canSwapingAction";
import CustomConsole from "../../../CustomConsole";

function DeliveriesScanInValidCansDialog(props) {
  const dispatch = useDispatch();
  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);

  const [radioEmptyOrFilledCansScan, setRadioEmptyOrFilledCansScan] =
    React.useState("EmptyCans");
  const [scanOption, setScanOption] = React.useState("scanner");
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdFilled, setQrBarcodeIdFilled] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedQrCodeFilledCans, setScannedQrCodeFilledCans] =
    React.useState("");
  CustomConsole(scannedQrCode);
  const [scanCount, setScanCount] = React.useState(0);
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [scannedBarCodeFilledCans, setScannedBarCodeFilledCans] =
    React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [decodedResultsFilledCans, setDecodedResultsFilledCans] =
    React.useState([]);
  CustomConsole(decodedResultsFilledCans);
  const [qrBarcodeIdBulk, setQrBarcodeIdBulk] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [qrBarcodeIdNewFilledCans, setQrBarcodeIdNewFilledCans] =
    React.useState("");
  const [scannedId, setScannedId] = React.useState("");
  const [emptyCanradio, setEmptyCanradio] = React.useState(false);
  const [filledCanradio, setFilledCanradio] = React.useState(false);
  const [checkedScanEptyCans, setCheckedScanEptyCans] = React.useState(false);
  const [checkedScanFilledCans, setCheckedScanFilledCans] =
    React.useState(false);
  const validation =
    qrBarcodeId !== qrBarcodeIdFilled ? qrBarcodeId : qrBarcodeIdFilled;
  const validationFilled =
    qrBarcodeId !== qrBarcodeIdFilled ? qrBarcodeIdFilled : qrBarcodeId;
  CustomConsole(checkedScanEptyCans);
  CustomConsole(checkedScanFilledCans);

  CustomConsole(qrBarcodeIdBulk);
  CustomConsole(scannedId);
  CustomConsole(decodedResults);

  React.useEffect(() => {}, [scanOption]);
  React.useEffect(() => {}, [value]);
  React.useEffect(() => {
    CustomConsole(decodedResults);
  }, [decodedResults]);

  React.useEffect(() => {
    CustomConsole("-----------scannedQrCode 1------------");
    CustomConsole(scannedQrCode);
    CustomConsole(decodedResults);
    if (
      (radioEmptyOrFilledCansScan === "EmptyCans" &&
        value === 0 &&
        scanOption === "camera" &&
        scannedQrCode.trim() !== "" &&
        !decodedResults.includes(scannedQrCode) &&
        scannedQrCode &&
        !decodedResultsFilledCans.find((item) => item === scannedQrCode)) ||
      (radioEmptyOrFilledCansScan === "EmptyCans" &&
        value === 0 &&
        scanOption === "camera" &&
        scannedBarCode.trim() !== "" &&
        !decodedResults.includes(scannedBarCode) &&
        scannedBarCode &&
        !decodedResultsFilledCans.find((item) => item === scannedBarCode))
    ) {
      CustomConsole("-----------scannedQrCode------------");
      setDecodedResults((prev) => [...prev, scannedQrCode || scannedBarCode]);
    } else {
      CustomConsole("-----------Already scannedQrCode------------");
      // toast.error("Already Scanned Empty Can", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    CustomConsole("-----------Filled scannedQrCode------------");
    if (
      (radioEmptyOrFilledCansScan === "FilledCans" &&
        value === 0 &&
        scanOption === "camera" &&
        scannedQrCodeFilledCans.trim() !== "" &&
        !decodedResultsFilledCans.includes(scannedQrCodeFilledCans) &&
        scannedQrCodeFilledCans &&
        !decodedResults.find((item) => item === scannedQrCodeFilledCans)) ||
      (radioEmptyOrFilledCansScan === "FilledCans" &&
        value === 0 &&
        scanOption === "camera" &&
        scannedBarCodeFilledCans.trim() !== "" &&
        !decodedResultsFilledCans.includes(scannedBarCodeFilledCans) &&
        scannedBarCodeFilledCans &&
        !decodedResults.find((item) => item === scannedBarCodeFilledCans))
    ) {
      CustomConsole("-----------scannedQrCode------------");
      setDecodedResultsFilledCans((prev) => [
        ...prev,
        scannedQrCodeFilledCans || scannedBarCodeFilledCans,
      ]);
    } else {
      CustomConsole("-----------Already scannedQrCode------------");
      // toast.error("Already Scanned Filled Can", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
  }, [scannedQrCodeFilledCans, scannedBarCodeFilledCans]);

  //   React.useEffect(() => {
  //     CustomConsole(qrBarcodeId);
  //     // setScannedQrCode(qrBarcodeId);
  //     const qrBarcodeIds = qrBarcodeId;
  //     if (
  //       qrBarcodeId === qrBarcodeIds &&
  //       qrBarcodeIds.length &&
  //       qrBarcodeIds.length > 7
  //     ) {
  //       return setDecodedResults((prev) => [...prev, qrBarcodeIds]);
  //     }
  //   }, [qrBarcodeId]);

  //   React.useEffect(() => {
  //     if (
  //       radioEmptyOrFilledCansScan === "EmptyCans" &&
  //       value === 1 &&
  //       scanOption === "camera"
  //     ) {
  //       CustomConsole(scannedQrCode);
  //       CustomConsole(scannedBarCode);
  //       CustomConsole("-------------EmptyCans single 2---------------");
  //       if (scannedQrCode !== "" || scannedBarCode !== "") {
  //         const reqObj = {
  //           qr_barcode: {
  //             empty: [scannedQrCode || scannedBarCode],
  //             filled: [],
  //           },
  //           order_id: "BTORD-27322",
  //         };
  //         CustomConsole(reqObj);
  //         dispatch(PostQrBarcodeSwapRequest(reqObj));
  //         setDecodedResultsFilledCans([]);
  //         setDecodedResults([]);
  //       }
  //     }
  //   }, [scannedQrCode, scannedBarCode]);

  //   React.useEffect(() => {
  //     if (
  //       radioEmptyOrFilledCansScan === "FilledCans" &&
  //       value === 1 &&
  //       scanOption === "camera"
  //     ) {
  //       CustomConsole(scannedQrCodeFilledCans);
  //       CustomConsole(scannedBarCodeFilledCans);
  //       if (scannedQrCodeFilledCans !== "" || scannedBarCodeFilledCans !== "") {
  //         const reqObj = {
  //           qr_barcode: {
  //             empty: [],
  //             filled: [scannedQrCodeFilledCans || scannedBarCodeFilledCans],
  //           },
  //           order_id: "BTORD-27322",
  //         };
  //         CustomConsole(reqObj);
  //         dispatch(PostQrBarcodeSwapRequest(reqObj));
  //         setDecodedResultsFilledCans([]);
  //         setDecodedResults([]);
  //       }
  //     }
  //   }, [scannedQrCodeFilledCans, scannedBarCodeFilledCans]);

  //   React.useEffect(() => {
  //     CustomConsole(qrBarcodeId);
  //     CustomConsole(decodedResultsFilledCans);
  //     CustomConsole(qrBarcodeIdFilled);
  //     if (
  //       qrBarcodeIdNew === qrBarcodeId &&
  //       qrBarcodeId.length &&
  //       qrBarcodeIdNew.length > 6
  //     ) {
  //       CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
  //       CustomConsole(qrBarcodeIdNew);
  //       CustomConsole(qrBarcodeId);
  //       CustomConsole("==== Data Tab0 ====");
  //       CustomConsole(qrBarcodeId);
  //       if (
  //         qrBarcodeId.trim() !== "" &&
  //         !decodedResults.includes(qrBarcodeId) &&
  //         qrBarcodeId !== qrBarcodeIdFilled
  //       ) {
  //         setScannedQrCode(decodedResults[0]);
  //         setDecodedResults((prev) => [...prev, qrBarcodeId]);
  //         setScannedBarCode(qrBarcodeId);
  //       } else {
  //         // setDecodedResults([]);
  //         CustomConsole("----------Already Scanned Empty Can----------");
  //         toast.error("Already Scanned Empty Can", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }

  //       setTimeout(() => {
  //         setQrBarcodeIdNew("");
  //         setQrBarcodeId("");
  //       }, 300);
  //     }
  //   }, [
  //     scannedBarCodeFilledCans,
  //     scannedQrCodeFilledCans,
  //     qrBarcodeId,
  //     qrBarcodeIdNew,
  //   ]);

  React.useEffect(() => {
    CustomConsole(qrBarcodeId);
    CustomConsole(decodedResults);
    CustomConsole(qrBarcodeIdFilled);
    if (
      qrBarcodeIdNewFilledCans === qrBarcodeIdFilled &&
      qrBarcodeIdFilled.length &&
      qrBarcodeIdNewFilledCans.length > 6 &&
      value === 0
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNewFilledCans);
      CustomConsole(qrBarcodeIdFilled);
      CustomConsole("==== Data Tab0 ====");
      CustomConsole(qrBarcodeIdFilled);

      if (
        qrBarcodeIdFilled.trim() !== "" &&
        !decodedResultsFilledCans.includes(qrBarcodeIdFilled) &&
        qrBarcodeIdFilled &&
        !decodedResults.find((item) => item === qrBarcodeIdFilled)
      ) {
        setScannedQrCodeFilledCans(decodedResults[0]);
        setDecodedResultsFilledCans((prev) => [...prev, qrBarcodeIdFilled]);
        setScannedBarCodeFilledCans(qrBarcodeIdFilled);
      } else {
        // setDecodedResults([]);
        CustomConsole("-------------Already Scanned This Container--------");
        toast.error("Already Scanned This Container", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      setTimeout(() => {
        setQrBarcodeIdNewFilledCans("");
        setQrBarcodeIdFilled("");
      }, 300);
    } else if (
      qrBarcodeIdNewFilledCans === qrBarcodeIdFilled &&
      qrBarcodeIdFilled.length &&
      qrBarcodeIdNewFilledCans.length > 6 &&
      value === 1
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNewFilledCans);
      CustomConsole(qrBarcodeIdFilled);
      CustomConsole("==== Data Tab0 ====");
      CustomConsole(qrBarcodeIdFilled);

      //   if (
      //     qrBarcodeIdFilled.trim() !== "" &&
      //     !decodedResultsFilledCans.includes(qrBarcodeIdFilled)
      //   ) {
      //     setScannedQrCodeFilledCans(decodedResults[0]);
      //     setDecodedResultsFilledCans((prev) => [...prev, qrBarcodeIdFilled]);
      //     setScannedBarCodeFilledCans(qrBarcodeIdFilled);
      //   } else {
      //     // setDecodedResults([]);
      //     CustomConsole("-------------Already Scanned Filled Can--------");
      //     toast.error("Already Scanned Filled Can", {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "colored",
      //     });
      //   }
      const reqObj = {
        qr_barcode: {
          empty: [],
          filled: [qrBarcodeIdFilled],
        },
        order_id: props.orderid,
      };
      CustomConsole(reqObj);
      dispatch(PostQrBarcodeSwapRequest(reqObj));

      setTimeout(() => {
        setQrBarcodeIdNewFilledCans("");
        setQrBarcodeIdFilled("");
      }, 300);
    }
    //Empty Can
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6 &&
      value === 0
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      CustomConsole("==== Data Tab0 ====");
      CustomConsole(qrBarcodeId);
      CustomConsole(decodedResults);
      if (
        qrBarcodeId.trim() !== "" &&
        !decodedResults.includes(qrBarcodeId) &&
        qrBarcodeId &&
        !decodedResultsFilledCans.find((item) => item === qrBarcodeId)
      ) {
        setScannedQrCode(decodedResults[0]);
        setDecodedResults((prev) => [...prev, qrBarcodeId]);
        setScannedBarCode(qrBarcodeId);
      } else {
        // setDecodedResults([]);
        CustomConsole("----------Already Scanned This Container----------");
        toast.error("Already Scanned This Container", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    } else if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6 &&
      value === 1
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      CustomConsole("==== Data Tab0 ====");
      CustomConsole(qrBarcodeId);
      //   if (qrBarcodeId.trim() !== "" && !decodedResults.includes(qrBarcodeId)) {
      //     setScannedQrCode(decodedResults[0]);
      //     setDecodedResults((prev) => [...prev, qrBarcodeId]);
      //     setScannedBarCode(qrBarcodeId);
      //   } else {
      //     // setDecodedResults([]);
      //     CustomConsole("----------Already Scanned Empty Can----------");
      //     toast.error("Already Scanned Empty Can", {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "colored",
      //     });
      //   }
      const reqObj = {
        qr_barcode: {
          empty: [qrBarcodeId],
          filled: [],
        },
        order_id: props.orderid,
      };
      CustomConsole(reqObj);
      dispatch(PostQrBarcodeSwapRequest(reqObj));

      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [
    qrBarcodeId,
    qrBarcodeIdNew,
    qrBarcodeIdFilled,
    qrBarcodeIdNewFilledCans,
  ]);

  const handleChangeRadioEmptyOrFilledCansScan = (event) => {
    CustomConsole(
      "------------------handleChangeRadioEmptyOrFilledCansScan--------------"
    );
    setRadioEmptyOrFilledCansScan(event.target.value);
    setScanOption("scanner");
  };
  const handleCloseDialog = () => {
    props.handleCloseDialog();
    setDecodedResultsFilledCans([]);
    setDecodedResults([]);
  };

  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };
  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    setValue(newValue);
    setDecodedResultsFilledCans([]);
    setDecodedResults([]);
    setScanOption("scanner");
  };

  const onNewScanResultFilledCans = (decodedText, decodedResult) => {
    CustomConsole("-------------FilledCans---------------");

    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);

    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeFilledCans(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCodeFilledCans(decodedText);
      CustomConsole(scanCount);
    }
    CustomConsole(decodedText);
    CustomConsole(decodedResults);
    CustomConsole(decodedResultsFilledCans);
    CustomConsole(scannedBarCodeFilledCans);
    CustomConsole(scannedQrCodeFilledCans);
    // if (
    //   radioEmptyOrFilledCansScan === "FilledCans" &&
    //   value === 0 &&
    //   scanOption === "camera"
    // ) {
    //   CustomConsole("-------------Filled bulk---------------");
    //   setDecodedResultsFilledCans((prev) => [...prev, decodedText]);
    // }
    if (
      radioEmptyOrFilledCansScan === "FilledCans" &&
      value === 1 &&
      scanOption === "camera"
    ) {
      CustomConsole("-------------FilledCans single---------------");
      if (decodedText !== "") {
        const reqObj = {
          qr_barcode: {
            empty: [],
            filled: [decodedText],
          },
          order_id: props.orderid,
        };
        CustomConsole(reqObj);
        dispatch(PostQrBarcodeSwapRequest(reqObj));
        setDecodedResultsFilledCans([]);
        setDecodedResults([]);
      }
    }
  };

  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("-------------EmptyCans---------------");
    CustomConsole(radioEmptyOrFilledCansScan);
    CustomConsole(value);
    CustomConsole(scanOption);
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    CustomConsole(decodedResults);
    // if (
    //   radioEmptyOrFilledCansScan === "EmptyCans" &&
    //   value === 0 &&
    //   scanOption === "camera" &&
    //   !decodedResults.includes(decodedText)
    // ) {
    //   CustomConsole("-------------EmptyCans bulk---------------");
    //   setDecodedResults((prev) => [...prev, decodedText]);
    // }
    if (
      radioEmptyOrFilledCansScan === "EmptyCans" &&
      value === 1 &&
      scanOption === "camera"
    ) {
      CustomConsole("-------------EmptyCans single---------------");
      if (decodedText !== "") {
        const reqObj = {
          qr_barcode: {
            empty: [decodedText],
            filled: [],
          },
          order_id: props.orderid,
        };
        CustomConsole(reqObj);
        dispatch(PostQrBarcodeSwapRequest(reqObj));
        setDecodedResultsFilledCans([]);
        setDecodedResults([]);
      }
    }
  };

  //   const onNewScanResultSingle = (decodedText, decodedResult) => {
  //     CustomConsole("-------------inScan---------------");
  //     CustomConsole("App [result]", decodedResult);
  //     CustomConsole(decodedText);
  //     if (decodedResult.result.format.formatName === "QR_CODE") {
  //       setScannedQrCode(decodedText);
  //       CustomConsole(scanCount);
  //     }
  //     if (decodedResult.result.format.formatName === "CODE_128") {
  //       setScannedBarCode(decodedText);
  //       CustomConsole(scanCount);
  //     }

  //     setDecodedResults(decodedText);
  //   };
  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
    // setTimeout(() => {
    //   setQrBarcodeId("");
    // }, 500);
  };
  const handleQrBarcodeIdFromScannerFilledCans = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeIdFilled(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNewFilledCans) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNewFilledCans(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
    // setTimeout(() => {
    //   setQrBarcodeId("");
    // }, 500);
  };
  const handleSendRequest = (qrBarcodeId) => {
    CustomConsole("------handleSendRequest-----");
    // const qrBarcodeIds = { qrBarcodeId };
    // setDecodedResults((prev) => [...prev, qrBarcodeIds]);
    // props.handleSendRequest();

    const reqObj = {
      qr_barcode: {
        empty: decodedResults,
        filled: decodedResultsFilledCans,
      },
      order_id: props.orderid,
    };

    CustomConsole(reqObj);
    dispatch(PostQrBarcodeSwapRequest(reqObj));
    setDecodedResultsFilledCans([]);
    setDecodedResults([]);
    props.setOpenScanInValidCsnsDialog(false);
  };

  const handleDeleteFilledCan = (props) => {
    CustomConsole("You clicked the delete icon Filled Can.");
    CustomConsole(props);
    setDecodedResultsFilledCans((prev) => prev.filter((obj) => obj !== props));
  };

  const handleDeleteEmptyCan = (props) => {
    CustomConsole("You clicked the delete icon Empty Can.");
    CustomConsole(props);
    setDecodedResults((prev) => prev.filter((obj) => obj !== props));
  };
  return (
    <Material.Dialog open={props.openScanInValidCsnsDialog}>
      <Material.DialogContent>
        {/* <Material.Box sx={{ display: "flex" }}>
          <Material.FormControlLabel
            control={<Material.Checkbox defaultChecked />}
            label="Scan Empty Cans"
            checked={checkedScanEptyCans}
            onChange={handleChangeCheckEmptyCans}
          />
          <Material.FormControlLabel
            control={<Material.Checkbox />}
            label="Scan Filled Cans"
            checked={checkedScanFilledCans}
            onChange={handleChangeCheckFilledCans}
          />
        </Material.Box> */}
        <Material.Box sx={{ display: "flex" }}>
          <Material.FormControl sx={{ display: "flex" }}>
            <Material.FormLabel>
              Select Empty Or Filled Cans{" "}
            </Material.FormLabel>
            <Material.RadioGroup
              row
              value={radioEmptyOrFilledCansScan}
              onChange={handleChangeRadioEmptyOrFilledCansScan}
            >
              <Material.FormControlLabel
                value="EmptyCans"
                control={<Material.Radio />}
                label="Empty Cans"
                // onChange={(event) => setEmptyCanradio(event.target.value)}
              />
              <Material.FormControlLabel
                value="FilledCans"
                control={<Material.Radio />}
                label="Filled Cans"
                // onChange={(event) => setFilledCanradio(event.target.value)}
              />
            </Material.RadioGroup>
          </Material.FormControl>
        </Material.Box>
        {/* <Material.Box>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            sx={{ bgcolor: "#D6E3F6", color: "white" }}
          >
            <Tab label="Bulk Scan" />
            <Tab label="One by One Scan" />
          </Tabs>
        </Material.Box> */}
        {radioEmptyOrFilledCansScan === "FilledCans" ? (
          <>
            <Material.Box>
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                sx={{ bgcolor: "#D6E3F6", color: "white" }}
              >
                <Tab label="Bulk Scan" />
                <Tab label="One by One Scan" />
              </Tabs>
            </Material.Box>
            <Material.Box>
              <Material.FormControl sx={{ marginTop: "15px" }}>
                <Material.FormLabel>Select Scanning Option</Material.FormLabel>
                <Material.RadioGroup
                  defaultValue="scanner"
                  value={scanOption}
                  name="radio-buttons-group"
                  sx={{ display: "flex", flexDirection: "row" }}
                  onChange={handleSelectScanOption}
                >
                  <Material.FormControlLabel
                    value="scanner"
                    control={<Material.Radio />}
                    label="Scanner Device"
                  />
                  <Material.FormControlLabel
                    value="camera"
                    control={<Material.Radio />}
                    label="Camera"
                  />
                </Material.RadioGroup>
              </Material.FormControl>
              <Material.Box>
                {scanOption === "scanner" ? (
                  <Material.TextField
                    onChange={handleQrBarcodeIdFromScannerFilledCans}
                    label="QR/Barcode:"
                    value={qrBarcodeIdFilled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : (
                  <Html5QrcodePlugin1
                    fps={1}
                    qrbox={200}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResultFilledCans}
                  />
                )}
              </Material.Box>
            </Material.Box>
          </>
        ) : (
          radioEmptyOrFilledCansScan === "EmptyCans" && (
            <>
              <Material.Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  centered
                  sx={{ bgcolor: "#D6E3F6", color: "white" }}
                >
                  <Tab label="Bulk Scan" />
                  <Tab label="One by One Scan" />
                </Tabs>
              </Material.Box>
              <Material.Box>
                <Material.FormControl sx={{ marginTop: "15px" }}>
                  <Material.FormLabel>
                    Select Scanning Option
                  </Material.FormLabel>
                  <Material.RadioGroup
                    defaultValue="scanner"
                    value={scanOption}
                    name="radio-buttons-group"
                    sx={{ display: "flex", flexDirection: "row" }}
                    onChange={handleSelectScanOption}
                  >
                    <Material.FormControlLabel
                      value="scanner"
                      control={<Material.Radio />}
                      label="Scanner Device"
                    />
                    <Material.FormControlLabel
                      value="camera"
                      control={<Material.Radio />}
                      label="Camera"
                    />
                  </Material.RadioGroup>
                </Material.FormControl>
                <Material.Box>
                  {scanOption === "scanner" ? (
                    <Material.TextField
                      onChange={handleQrBarcodeIdFromScanner}
                      label="QR/Barcode:"
                      value={qrBarcodeId}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : (
                    <Html5QrcodePlugin1
                      fps={1}
                      qrbox={200}
                      disableFlip={false}
                      qrCodeSuccessCallback={onNewScanResult}
                    />
                  )}
                </Material.Box>
              </Material.Box>
            </>
          )
        )}
        <Material.Box sx={{ margin: "5px" }}>
          <Material.Typography sx={{ fontWeight: "bold" }}>
            Empty Can Qr/Barcode Id's :
          </Material.Typography>
          <Material.Box
            sx={{
              display: "flex",
              //   flexDirection: "row",
              //   justifyContent:"space-around",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {decodedResults !== undefined &&
            decodedResults !== null &&
            decodedResults.length > 0 ? (
              <>
                {decodedResults?.map((data, index) => {
                  return (
                    <Material.Chip
                      key={index}
                      label={data}
                      //   variant="outlined"
                      onDelete={() => handleDeleteEmptyCan(data)}
                    />
                  );
                })}
              </>
            ) : (
              <Material.Typography sx={{ color: "red" }}>
                No More Empty Qr/Barcode's Scanned
              </Material.Typography>
            )}
            {/* <Material.Button variant="contained">Link</Material.Button> */}
          </Material.Box>
        </Material.Box>
        <Material.Box sx={{ margin: "5px" }}>
          <Material.Typography sx={{ fontWeight: "bold" }}>
            Filled Can Qr/Barcode Id's :
          </Material.Typography>
          <Material.Box
            sx={{
              display: "flex",
              //   flexDirection: "row",
              //   justifyContent:"space-around",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {decodedResultsFilledCans !== undefined &&
            decodedResultsFilledCans !== null &&
            decodedResultsFilledCans.length > 0 ? (
              <>
                {decodedResultsFilledCans?.map((data, index) => {
                  return (
                    <Material.Chip
                      key={index}
                      label={data}
                      //   variant="outlined"
                      onDelete={() => handleDeleteFilledCan(data)}
                    />
                  );
                })}
              </>
            ) : (
              <Material.Typography sx={{ color: "red" }}>
                No More Filled Qr/Barcode's Scanned
              </Material.Typography>
            )}

            {/* <Material.Button variant="contained">Link</Material.Button> */}
          </Material.Box>
        </Material.Box>
        {value === 0 && (
          <Material.Box
            sx={{
              margin: "10px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Material.Button
              variant="contained"
              onClick={() => handleSendRequest(qrBarcodeId)}
            >
              Send Request
            </Material.Button>
          </Material.Box>
        )}
        <Material.Box sx={{ display: "flex", justifyContent: "center" }}>
          <Material.Button
            variant="contained"
            color="error"
            onClick={handleCloseDialog}
          >
            close
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default DeliveriesScanInValidCansDialog;
