import { Add, Close, LocalShipping, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import WaterCanImg from "../../../Images/WaterCanImg.png";
import OrderButtonSku from "../../../Components/Buttons/orderButton";
import ImageLoader from "../../SupplierDeliveries/ImageLoader";

function ProductsDetailsDialog(props) {
  const [emptyQty, setEmptyQty] = React.useState(0);
  const [filledQty, setFilledQty] = React.useState(0);
  const [damagedQty, setDamagedQty] = React.useState(0);
  const handleCloseTransferProductDialog = () => {
    props.handleCloseTransferProductDialog();
  };
  const handleSubmitTransferProductToVehicle = () => {
    props.handleSubmitTransferProductToVehicle({
      empty: emptyQty,
      filled: filledQty,
      damaged: damagedQty,
      capacity: props.productStockDialog?.data?.capacity,
      material: props.productStockDialog?.data?.material,
      setDamagedQty: setDamagedQty,
      setFilledQty: setFilledQty,
      setEmptyQty: setEmptyQty,
    });
  };

  const handleAddEmptyQty = () => {
    if (emptyQty < props.productStockDialog?.data?.empty) {
      setEmptyQty(parseInt(emptyQty + 1));
    }
  };

  const handleAddFilled = () => {
    if (filledQty < props.productStockDialog?.data?.filled) {
      setFilledQty(parseInt(filledQty + 1));
    }
  };
  const handleAddDamaged = () => {
    if (damagedQty < props.productStockDialog?.data?.damaged) {
      setDamagedQty(parseInt(damagedQty + 1));
    }
  };

  const handleChangeEmpty = (event) => {
    if (event.target.value <= props.productStockDialog?.data?.empty) {
      setEmptyQty(
        parseInt(
          event.target.value >= 0 && event.target.value <= 999
            ? parseInt(event.target.value)
            : parseInt(emptyQty)
        )
      );
    }
  };
  const handleChangeFilled = (event) => {
    if (event.target.value <= props.productStockDialog?.data?.filled) {
      setFilledQty(
        parseInt(
          event.target.value >= 0 && event.target.value <= 999
            ? parseInt(event.target.value)
            : parseInt(filledQty)
        )
      );
    }
  };
  const handleChangeDamaged = (event) => {
    if (event.target.value <= props.productStockDialog?.data?.damaged) {
      setDamagedQty(
        parseInt(
          event.target.value >= 0 && event.target.value <= 999
            ? parseInt(event.target.value)
            : parseInt(damagedQty)
        )
      );
    }
  };
  return (
    <Dialog open={props.openAvailableProductsTransfer}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => handleCloseTransferProductDialog()}
            variant="contained"
            color="error"
            size="small"
          >
            <Close />
          </Button>
        </Box>
        <Paper
          sx={{
            padding: "10px",
            borderRadius: "10px",
            bgcolor: "#F4F6F7",
          }}
          elevation={5}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // bgcolor: "#CCD1D1",
              bgcolor: "#ECF0F1",
              pl: "5px",
              gap: "10px",
              // pr: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#21618C",
                fontWeight: "bold",
              }}
            >
              {props.productStockDialog?.data?.capacity +
                "-" +
                props.productStockDialog?.data?.material}
            </Typography>
            {/* <Typography
              sx={{
                display: "flex",
                textAlign: "end",
                fontWeight: "bold",
                color: "black",
              }}
            >
              ({"20_Liter"} - {"PET"})
            </Typography> */}
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ mt: "40px" }}>
              <ImageLoader
                filename={props.productStockDialog?.availableProductsImg}
              />
              {/* <img
                src={
                  props.productStockDialog?.availableProductsImg[0]
                    ?.product_details?.image
                }
                alt="img"
                style={{
                  height: "120px", // Set the desired  height
                  width: "60px", // Set the desired width
                  objectFit: "cover",
                  mt: "10px",
                }}
              /> */}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        mt: "8px",
                      }}
                    >
                      Empty :
                    </Typography>
                  </Box>
                  <OrderButtonSku
                    handelAddQuantity={() => handleAddEmptyQty()}
                    handelMinusQuantity={() =>
                      setEmptyQty(emptyQty > 0 ? emptyQty - 1 : 0)
                    }
                    handelQuantityChange={(event) => handleChangeEmpty(event)}
                    inputValue={emptyQty}
                    // disabled={false}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        mt: "8px",
                      }}
                    >
                      Filled :
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <OrderButtonSku
                      handelAddQuantity={() => handleAddFilled()}
                      handelMinusQuantity={() =>
                        setFilledQty(filledQty > 0 ? filledQty - 1 : 0)
                      }
                      handelQuantityChange={(event) =>
                        handleChangeFilled(event)
                      }
                      inputValue={filledQty}
                      // disabled={false}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        mt: "8px",
                      }}
                    >
                      Damaged :
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <OrderButtonSku
                      handelAddQuantity={() => handleAddDamaged()}
                      handelMinusQuantity={() =>
                        setDamagedQty(damagedQty > 0 ? damagedQty - 1 : 0)
                      }
                      handelQuantityChange={(event) =>
                        handleChangeDamaged(event)
                      }
                      inputValue={damagedQty}
                      // disabled={false}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: "10px",
                }}
              >
                <Box>
                  <Button
                    onClick={() => handleSubmitTransferProductToVehicle()}
                    variant="contained"
                  >
                    Transfer to <LocalShipping />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

export default ProductsDetailsDialog;
