import React from "react";
import * as Material from "@mui/material";

function CrateQrBarc0deFilterDialog(props) {
  const handleFilter = () => {
    props.handleFilter();
  };
  const handleCloseFilter = () => {
    props.handleCloseFilter();
  };
  return (
    // <Material.Box>
    <Material.Dialog open={props.openFilter}>
      <Material.DialogTitle>Filter Based On Options</Material.DialogTitle>
      <Material.DialogContent>
        <Material.Box sx={{ display: "flex", flexDirection: "column" }}>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Is Re-Generated</Material.InputLabel>
            <Material.Select
              label="Regenerated"
              onChange={props.handleFilter}
              name="where_is_regenerated"
              value={props.filterValues.where_is_regenerated}
              size="small"
            >
              <Material.MenuItem value={true}>Regenerated</Material.MenuItem>
              <Material.MenuItem value={false}>Newly Generated</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Qr/BarCode</Material.InputLabel>
            <Material.Select
              label="Qr/BarCode"
              value={props.filterValues.where_is_qr}
              onChange={props.handleFilter}
              name="where_is_qr"
              size="small"
            >
              <Material.MenuItem value={true}>Qr Code</Material.MenuItem>
              <Material.MenuItem value={false}>Bar Code</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.TextField
            sx={{ m: 1 }}
            value={props.filterValues.where_start_number}
            label="Start Number"
            name="where_start_number"
            onChange={props.handleFilter}
            size="small"
          />
          <Material.TextField
            sx={{ m: 1 }}
            label="End Number"
            name="where_end_numbe"
            value={props.filterValues.where_end_numbe}
            onChange={props.handleFilter}
            size="small"
          />
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort By Crated At</Material.InputLabel>
            <Material.Select
              label="Sort By Crated At"
              size="small"
              value={props.filterValues.sort_by_created_at}
              name="sort_by_created_at"
              onChange={props.handleFilter}
            >
              <Material.MenuItem value="ASC">Recent First</Material.MenuItem>
              <Material.MenuItem value="DESC">Old first</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort By Start Number</Material.InputLabel>
            <Material.Select
              label="Sort By Start Number"
              name="sort_by_start_number"
              size="small"
              value={props.filterValues.sort_by_start_number}
              onChange={props.handleFilter}
            >
              <Material.MenuItem value="ASC">ASC</Material.MenuItem>
              <Material.MenuItem value="DESC">DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort By End Number</Material.InputLabel>
            <Material.Select
              label="Sort By End Number"
              value={props.filterValues.sort_by_end_number}
              name="sort_by_end_number"
              size="small"
              onChange={props.handleFilter}
            >
              <Material.MenuItem value="ASC">ASC</Material.MenuItem>
              <Material.MenuItem value="DESC">DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.TextField
            sx={{ m: 1 }}
            label="Sort Crate Id"
            value={props.filterValues.sort_by_crate_id}
            name="sort_by_crate_id"
            onChange={props.handleFilter}
            size="small"
          />
        </Material.Box>
      </Material.DialogContent>
      <Material.DialogActions>
        <Material.Button
          variant="contained"
          color="error"
          onClick={handleCloseFilter}
          size="small"
        >
          close
        </Material.Button>
        <Material.Button
          variant="contained"
          onClick={props.handelClearFilter}
          size="small"
        >
          Clear filter
        </Material.Button>
        <Material.Button
          variant="contained"
          onClick={props.handelSubmitFilter}
          size="small"
        >
          filter
        </Material.Button>
      </Material.DialogActions>
    </Material.Dialog>
    // </Material.Box>
  );
}

export default CrateQrBarc0deFilterDialog;
