import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./Pages/Authentication/Login";
import Dashboard from "./Pages/Common/Dashboard";
import Authentication from "./Pages/Authentication/Authentication";
import ProtectedRoute from "./Pages/ProtectedRoute/ProtectedRoute";
import React from "react";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/authenticate">
            <Authentication />
          </Route>
          <ProtectedRoute path="/dashboard">
            <Dashboard />
          </ProtectedRoute>
          <Route exact path="/">
            <Redirect exact from="/" to="dashboard" />
          </Route>
          <Route path="*">
            <Redirect from="/" to="dashboard" />
          </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
