import * as React from "react";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { GetIotDevice } from "../Redux/Actions/adminDevicesIotAction";
import Tooltip from "@mui/material/Tooltip";
import CustomConsole from "../../CustomConsole";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#59A6D6",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function IotDevicesTable({
  IotDevices,
  setSelectedDeviceData,
  scrollToBottom,
  setDevicesFlag,
}) {
  const dispatch = useDispatch();
  const iotDeviceData = useSelector((state) => state.IotDeviesData);
  const [tableBtnClicked, setTableButtonClicked] = React.useState(false);
  const [deviceTableFlag, setDeviceTableFlag] = React.useState(false);
  const [selecetedDeviceId, setSelectedDeviceId] = React.useState("");

  React.useEffect(() => {
    CustomConsole("iot devices data in table");
    CustomConsole(iotDeviceData);
    iotDeviceData.length &&
      setSelectedDeviceData(iotDeviceData.IoTDeviceDataSingle);
  }, [iotDeviceData]);

  const handleDeviceListClick = (deviceId) => {
    CustomConsole(deviceId);
    setDevicesFlag(true);
    setTableButtonClicked(true);
    setDeviceTableFlag(true);
    setSelectedDeviceId(deviceId);
    scrollToBottom();
  };

  React.useEffect(() => {
    if (tableBtnClicked) {
      dispatch(GetIotDevice(selecetedDeviceId));
      setDeviceTableFlag(true);
      setTableButtonClicked(false);
    }
  }, [tableBtnClicked]);

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "500px", width: "fit-content" }}
    >
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
              No
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
              Device ID
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {IotDevices.map((data, key) => (
            <Tooltip title="Click to Edit" placement="left-start">
              <StyledTableRow
                key={data.deviceid}
                onClick={() => handleDeviceListClick(data.deviceid)}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#036bfc",
                  },
                }}
              >
                <StyledTableCell align="left" component="th" scope="row">
                  {key + 1}
                </StyledTableCell>
                <StyledTableCell align="left">{data.deviceid}</StyledTableCell>
              </StyledTableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
