import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  data: {},
  activeCansInMarket: {},
  currentDepositeAmount: {},
  deliveryData: {},
  ordersData: {},
  transactionData: {},
  usersData: {},
  currentWalletAmount: 0,
  orderTiming: [],
  supplierOrdersData: [],
  revenueSalesBusiinesvsConsumerData: [],
  revenueB2BB2CData: [],
  supplierList: [],
  driverList: [],
  driverOrdersData: {},
  b2b_b2c_revenue: [],
  b2b_b2b_quantity: [],
  b2c_quantity_revenue: {},
};

const adminHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GETTING_GRAPH_DATA: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        data: action.payload.data,
        activeCansInMarket: action.payload.data.active_cans_in_market,
        currentDepositeAmount: action.payload.data.current_deposit_amount,
        deliveryData: action.payload.data.delivery_data,
        ordersData: action.payload.data.orders_data,
        transactionData: action.payload.data.transactions_data,
        usersData: action.payload.data.users_data,
        currentWalletAmt: action.payload.data.current_wallet_amount,
      };
    }
    case actionType.GETTING_ORDER_TIMING: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        orderTiming: action.payload.data,
      };
    }
    case actionType.GET_B2B_B2C_REVENUE: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        b2b_b2c_revenue: action.payload.data,
      };
    }
    case actionType.GET_B2B_QUANTITY_REVENUE: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        b2b_b2b_quantity: action.payload.data,
      };
    }
    case actionType.GET_B2C_QUANTITY_REVENUE: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        b2c_quantity_revenue: action.payload.data,
      };
    }
    case actionType.GETTING_ACTIVE_SUPPLIER_LIST_GRAPH_DATA: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        supplierList: action.payload.data,
      };
    }
    case actionType.GET_SUPPLIER_DELIVERY_AGENT_LIST: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        driverList: action.payload.data,
      };
    }
    case actionType.GETTING_REVENUE_SALES_B2B_B2C_GRAPH_DATA: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        revenueSalesBusiinesvsConsumerData: action.payload.data,
      };
    }
    case actionType.GETTING_SUPPLIER_ORDERS_DATA: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        supplierOrdersData: action.payload.data,
      };
    }
    case actionType.GETTING_DRIVER_ORDERS_DATA: {
      CustomConsole("GETTING_DRIVER_ORDERS_DATA");
      CustomConsole(action.payload.data);
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        driverOrdersData: action.payload.data,
      };
    }
    case actionType.GETTING_REVENUE_B2B_B2C_GRAPH_DATA: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        revenueB2BB2CData: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default adminHomeReducer;
