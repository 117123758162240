import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetWithdrawallist } from "../Redux/Actions/AdminWithdrawalListAction";
import AdminWithdrawalOrderList from "./AdminWithdrawalOrderList";
import AdminWithdrawalOrderDetails from "./AdminWithdrawalOrderDetails";
import DistributorDetailsDialog from "./DistributorDetailsDialog";
import Pagination from "@mui/material/Pagination";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

function AdminWithdrawal() {
  const dispatch = useDispatch();
  const adminWithdrawallistData = useSelector(
    (state) => state.withdrawalOrderlistAdmin
  );

  CustomConsole(adminWithdrawallistData);
  const [withdrawalOrderlist, setWithdrawalOrderlist] = React.useState([]);
  const [withdrawalOrderDetails, setWithdrawalOrderDetails] = React.useState(
    []
  );
  const [changeMinusBtn, setChangeMinusBtn] = React.useState(false);
  const [changePlusBtn, setChangePlusBtn] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);
  const [returnCanOrderId, setReturnCanOrderId] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [adminRole, setAdminRoleId] = React.useState("");
  const [dialogFlag, setdialogFlag] = React.useState(false);
  const [issueDialog, setIssueFlag] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);
  // React.useEffect(() => {
  //   dispatch(
  //     GetWithdrawallist({
  //       page_number: pageNumber - 1,
  //       page_size: pageSize,
  //     })
  //   );
  // }, []);

  React.useEffect(() => {
    CustomConsole(adminWithdrawallistData);
  }, [adminWithdrawallistData]);

  React.useEffect(() => {
    CustomConsole(adminWithdrawallistData);
    if (adminWithdrawallistData.withdrawalList.length > 0) {
      setWithdrawalOrderlist(adminWithdrawallistData.withdrawalList);
    }
  }, [adminWithdrawallistData]);
  CustomConsole(withdrawalOrderlist);
  // React.useEffect(() => {
  //   if (pageChange - 1) {
  //     dispatch(
  //       GetWithdrawallist({ page_number: pageNumber - 1, page_size: pageSize })
  //     );
  //     setChangeMinusBtn(true);
  //     setChangePlusBtn(true);
  //   }
  // }, [pageNumber]);
  // CustomConsole(pageNumber);
  CustomConsole("-----------f");

  // React.useEffect(() => {
  //   if (adminWithdrawallistData.withdrawalList.length > 0) {
  //     setPageStatus(false);
  //   } else {
  //     setPageStatus(true);
  //   }
  // });
  const handlePageChange = (event, value) => {
    setPageNumber(value);
    setPageChange(true);
  };

  const handleChangeMinus = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setChangeMinusBtn(true);
  };

  const handleChangePlus = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
    setChangePlusBtn(true);
  };

  const onClickScroll = useRef(null);
  const scroll = () => {
    onClickScroll.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {ADMIN_ROLES.BW_ADMIN === adminRole ||
      ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
      ADMIN_ROLES.BW_CSLEAD === adminRole ||
      ADMIN_ROLES.BW_CS === adminRole ||
      ADMIN_ROLES.BW_OPS === adminRole ||
      ADMIN_ROLES.BW_OPSHEAD === adminRole ||
      ADMIN_ROLES.BW_SALES === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ||
      ADMIN_ROLES.BW_FINANCEHEAD === adminRole ? (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <AdminWithdrawalOrderList
              onClickScroll={onClickScroll}
              withdrawalOrderlist={withdrawalOrderlist}
              withdrawalOrderDetails={withdrawalOrderDetails}
              setWithdrawalOrderDetails={setWithdrawalOrderDetails}
              scroll={scroll}
              returnCanOrderId={returnCanOrderId}
              setReturnCanOrderId={setReturnCanOrderId}
              dialogFlag={dialogFlag}
              setdialogFlag={setdialogFlag}
              issueDialog={issueDialog}
              setIssueFlag={setIssueFlag}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              setChangeMinusBtn={setChangeMinusBtn}
              setChangePlusBtn={setChangePlusBtn}
              pageStatus={pageStatus}
              setPageStatus={setPageStatus}
              pageChange={pageChange}
              setPageChange={setPageChange}
              adminRole={adminRole}
              ADMIN_ROLES={ADMIN_ROLES}
            />

            {/* <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
              <Button onClick={handleChangeMinus}>
                <ArrowBackIos />
              </Button>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Typography>
              <Button disabled={pageStatus} onClick={handleChangePlus}>
                <ArrowForwardIos />
              </Button>
            </Box> */}
          </Grid>

          {/* <Grid item md={12} sm={12} xs={12} ref={onClickScroll}>
            <AdminWithdrawalOrderDetails
              withdrawalOrderDetails={withdrawalOrderDetails}
              adminWithdrawallistData={adminWithdrawallistData}
              ref={onClickScroll}
              scroll={scroll}
              pageNumber={pageNumber}
              returnCanOrderId={returnCanOrderId}
              setReturnCanOrderId={setReturnCanOrderId}
              dialogFlag={dialogFlag}
              setdialogFlag={setdialogFlag}
              issueDialog={issueDialog}
              setIssueFlag={setIssueFlag}
              pageSize={pageSize}
            />
          </Grid> */}
        </Grid>
      ) : (
        <Box sx={{ marginTop: "60px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </>
    // </Box >
  );
}

export default AdminWithdrawal;
