import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
export const QRstyles = {
  QRStyleHeading: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "9px",
    gap: "3px",
    color: "#0a83c4",
  },
  QRStyleValue: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "9px",
    gap: "3px",
  },
  QRStyleflx: {
    display: "flex",
    flexcontainer: "flex-grow",
  },
  ToggleGroup:{
    marginTop:"3em"
  },
  ScanButton:{
    background:appColors.commonBtnColor.background,
    color:"white",
    borderRadius:"10px"
  },
  LinkButton:{
    background:appColors.commonBtnColor.background,
    color:"white",
    borderRadius:"10px"
  },
  ButtonAlignment:{
 
  }
};
