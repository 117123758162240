import React from "react";
import * as Material from "@mui/material";

function ReGenrateQrBarcodeCrateDialog(props) {

  const handleCloseReGenrate = () => {
    props.handleCloseReGenrate();
  };
  const handleSubmitReGenrate = () => {
    props.handleSubmitReGenrate();
  };
  return (
    <Material.Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        },
      }}
      open={props.openReGenrateQrBarcodeCrate}
    >
      <Material.DialogContent
        sx={{
          bgcolor: "#F5EEF8",
        }}
      >
        <Material.Box sx={{ display: "flex", flexDirection: "column" }}>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Select Qr/Barcode</Material.InputLabel>
            <Material.Select
              value={props.reGenerateQrBarcode.is_qr}
              id="outlined-select-currency-native"
              onChange={props.handelRegenerateQrBarCode}
              label="Select Qr/Barcode"
              name="is_qr"
              size="small"
            >
              <Material.MenuItem value={true}>Qr Code</Material.MenuItem>
              <Material.MenuItem value={false}>Bar Code</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.TextField
            value={props.reGenerateQrBarcode.qty}
            label="Enter Quantity"
            name="qty"
            sx={{ margin: "10px" }}
            onChange={props.handelRegenerateQrBarCode}
            size="small"
          />
        </Material.Box>
        <Material.Box>
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Material.Button
              variant="contained"
              color="error"
              onClick={handleCloseReGenrate}
              size="small"
            >
              close
            </Material.Button>
            <Material.Button
              variant="contained"
              onClick={handleSubmitReGenrate}
              size="small"
            >
              submit
            </Material.Button>
          </Material.Box>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default ReGenrateQrBarcodeCrateDialog;
