import { Autocomplete, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AvailablePincodes } from "../../Pages/Redux/Actions/pincodesAction";

export default function AutocompleteAddressFields({
  label,
  key_name,
  setValue,
  variant,
  onSelect,
  value,
 
}) {
  const [availableZoneNames, setAvailableZoneNames] = useState([]);
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    if (setValue) {
      setValue(searchValue);
    }
  }, [searchValue]);
  const fetchZoneSuggestions = useCallback(
    async (input) => {
      console.log("Input value:", input); // Log the input value
      if (input) {
        try {
          const result = await AvailablePincodes({
            [key_name]: input,
            page_number: 1,
            page_size: 20,
            get: key_name,
          });

          console.log("API Response:", result); // Log the API response

          if (result?.data) {
            console.log(
              "All Zone Names:",
              result.data.map((zone) => zone.zone_name)
            );

            const filteredZoneNames = result?.data
              ?.map((zone) => zone[key_name])
              .filter((name) => name);
            // .filter(
            //   (name, index, self) =>
            //     name &&
            //     self.indexOf(name) === index &&
            //     name.toUpperCase().startsWith(input.toUpperCase())
            // );

            console.log("Filtered Zone Names:", filteredZoneNames); // Log filtered zone names
            setAvailableZoneNames(filteredZoneNames);
          } else {
            console.log("No data found in the response."); // Log if no data
          }
        } catch (error) {
          console.error("Error fetching zone suggestions:", error);
        }
      } else {
        console.log("Input is empty, resetting zone names."); // Log when input is empty
        setAvailableZoneNames([]);
      }
    },
    [setAvailableZoneNames]
  );
  useEffect(() => {
    const timeoutId = setTimeout(() => fetchZoneSuggestions(searchValue), 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue, fetchZoneSuggestions]);

  console.log("label........");

  console.log(label);
  return (
    <Autocomplete
      freeSolo
      // openOnFocus
      options={availableZoneNames}
      value={searchValue}
      // open={!!value}
      //   open={autocompleteOpen && !!value}
      // onOpen={() => setAutocompleteOpen(true)}
      // onClose={() => setAutocompleteOpen(false)}
      onChange={(event, newValue) => {
        if (onSelect) {
          onSelect(newValue);
        }
        setSearchValue(newValue || "");
      }}
      // clearIcon={<></>}
      // getOptionLabel={(option) => option}
      onInputChange={(event, newInputValue) => {
        setSearchValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant || "outlined"}
          margin="normal"
          size="small"
          sx={{
            marginTop: "0px",
            padding: "0px",
          }}
        />
      )}
    />
  );
}
