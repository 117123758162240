import actionType from "../Actions/actionType";
const initialState = {
  error: true,
  orderDetails: [],
  creditDetails: [],
};
const buyBackOrderReduser = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.GET_BUYBACK_ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        orderDetails: action.payload.data,
      };
    case actionType.GET_CREDIT_NOTE_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        creditDetails: action.payload.data,
      };
    default:
      return state;
  }
};
export default buyBackOrderReduser;
