import React from "react";
import * as Material from "@mui/material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  IconButton,
} from "@mui/material";
import ReassignFilter from "./ReassignFilter";
import { styled } from "@mui/material/styles";
import OrderDetails from "./OrderDetails";
import CustomConsole from "../../CustomConsole";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import ReAssignStatusTable from "./ReAssignStatusTable";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSupplierOnGoingAssignedSlotAndAgent,
  GetSupplierPincodes,
  GetAssignDeliveryAgent,
  PostSupplierAssignDeliveryAgent,
} from "../Redux/Actions/supliersAction";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#FBFAF7",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: "7px",
}));

function ReassignSupplier() {
  const supplierDeliveries = useSelector(
    (state) =>
      state.supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
  );
  const supplierPincodes = useSelector(
    (state) => state.supplierDeliveries.supplierPincodes
  );
  const assigningAdents = useSelector(
    (state) => state.supplierDeliveries.getAssignDeliveryAgent
  );

  const assignError = useSelector((state) => state.supplierDeliveries);

  const [agentDri, setAgentDri] = React.useState("");
  const [pageSizeC, setPageSizeCatch] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [area, setArea] = React.useState("");
  const [selectedPincode, setSelectedPincode] = React.useState("");
  

  const [
    ressignAssignStatusPincodeSwitch,
    setRessignAssignStatusPincodeSwitch,
  ] = React.useState(false);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const pageSize = [25, 50, 100, 200, 300];

  const [orders, setOrders] = React.useState([]);
  const [supplierPinCodes, setSupplierPincodes] = React.useState([]);
  const [assigningTo, setAssigningAgent] = React.useState("");
  const [allAgents, setAgents] = React.useState([]);
  const [slectedOrder, setSelectedOrder] = React.useState("");
  const [findSuccessAssignFlag, setFindSuccessAssignFlag] =
    React.useState(false);
  const dispatch = useDispatch();

  const handleRessignAssignStatusPincodeSwitch = (event) => {
    setRessignAssignStatusPincodeSwitch(event.target.checked);
  };

  const handleReassign = () => {
    if (assigningTo && slectedOrder) {
      const reqObj = {
        delivery_agent_id: assigningTo,
        delivery_id: slectedOrder,
      };
      CustomConsole(reqObj);
      dispatch(PostSupplierAssignDeliveryAgent(reqObj));
      setFindSuccessAssignFlag(true);
    }
  };

  const handlePageNumberInc = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handlePageNumberDec = () => {
    setPageNumber((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
  };

  React.useEffect(() => {
    CustomConsole("------Console for suppler API Objects-----");
    CustomConsole(supplierDeliveries);
    if (supplierDeliveries) {
      setOrders(supplierDeliveries);
    } else if (!supplierDeliveries) {
      setOrders([]);
    }
  }, [supplierDeliveries]);

  React.useEffect(() => {
    dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
    dispatch(GetSupplierPincodes());
    dispatch(GetAssignDeliveryAgent());
  }, []);

  React.useEffect(() => {
    CustomConsole("-----Supplier pincodes-----");
    CustomConsole(supplierPincodes);
    if (supplierPincodes) {
      setSupplierPincodes(supplierPincodes);
    }
  }, [supplierPincodes]);

  React.useEffect(() => {
    if (
      assignError.assigDeliveryAgenterror === false &&
      findSuccessAssignFlag
    ) {
      CustomConsole("----------After Assigning -------");
      CustomConsole(assignError);
      setFindSuccessAssignFlag(false);
      setSelectedOrder("");
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          page_number: pageNumber,
          page_size: pageSizeC,
          pincode: selectedPincode,
          area: area,
          agent_id: agentDri,
        })
      );
    }
  }, [assignError]);

  React.useEffect(() => {
    if (assigningAdents) setAgents(assigningAdents);
  }, [assigningAdents]);

  React.useEffect(() => {
    CustomConsole(`pagenumber is ${pageNumber}`);
    dispatch(
      GetSupplierOnGoingAssignedSlotAndAgent({
        page_number: pageNumber,
        page_size: pageSizeC,
        pincode: selectedPincode,
        area: area,
      })
    );
  }, [pageNumber, pageSizeC]);

  return (
    <Box
      mt={5}
      p={0}
      sx={{ display: "flex", flexDirection: "column", mt: "60px" }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Material.Typography
          sx={{
            color:
              ressignAssignStatusPincodeSwitch === false ? "#30A2F3" : "black",
          }}
        >
          Re-Assign Orders
        </Material.Typography>
        <AntSwitch
          checked={ressignAssignStatusPincodeSwitch}
          onChange={handleRessignAssignStatusPincodeSwitch}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Material.Typography
          sx={{
            color:
              ressignAssignStatusPincodeSwitch === true ? "#30A2F3" : "black",
          }}
        >
          Status
        </Material.Typography>
      </Stack>
      {ressignAssignStatusPincodeSwitch === false ? (
        <Grid container spacing={{ md: 1, xs: 0.2 }}>
          <Grid item xs={12} md={6} xl={6}>
            <ReassignFilter
              area={area}
              setArea={setArea}
              selectedPincode={selectedPincode}
              setSelectedPincode={setSelectedPincode}
              supplierPinCodes={supplierPinCodes}
              setAgentDri={setAgentDri}
              agentDri={agentDri}
              pageSizeC={pageSizeC}
            />
          </Grid>
          <Grid item xs={12} mt={0} md={6} xl={6}>
            <Item variant="elevation" elevation={5}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <FormControl
                  variant="standard"
                  sx={{ mb: 1, minWidth: 180, maxWidth: 200 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Re-assign To
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={assigningTo}
                    onChange={(e) => setAssigningAgent(e.target.value)}
                    label="Agent"
                    size="small"
                                     >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {allAgents.map((obj, key) => {
                      return (
                        <MenuItem key={key} value={obj.driver_id}>
                          {obj.driver_profile?.fullname}-
                          {obj.driver_profile?.phone}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReassign}
                >
                  Assign
                </Button>
              </Stack>
            </Item>
            <Paper elevation={5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <FormControl variant="standard" sx={{ minWidth: 70 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Size
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      variant="standard"
                      value={pageSizeC}
                      onChange={(e) => {
                        setPageSizeCatch(e.target.value);
                      }}
                      label="Size"
                      size="small"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {pageSize.map((obj, idx) => {
                        return (
                          <MenuItem key={idx} value={obj}>
                            {obj}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <IconButton
                      type="button"
                      color="primary"
                      onClick={handlePageNumberDec}
                    >
                      <FirstPageIcon sx={{ fontSize: "25px" }} />
                    </IconButton>

                    <span>{pageNumber + 1}</span>
                    <IconButton
                      type="button"
                      color="primary"
                      onClick={handlePageNumberInc}
                      disabled={orders && orders.length !== pageSizeC}
                    >
                      <LastPageIcon sx={{ fontSize: "25px" }} />
                    </IconButton>
                  </Stack>
                </Stack>
                {/* <Stack direction={"row"} alignItems="center">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="All"
                    />
                  </FormGroup>
                </Stack> */}
              </Stack>
            </Paper>{" "}
          </Grid>
          {/* <Grid item xs={12} md={6} xl={6}>
            {" "}
            <Paper elevation={5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <FormControl variant="standard" sx={{ minWidth: 70 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Size
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      variant="standard"
                      value={pageSizeC}
                      onChange={(e) => {
                        setPageSizeCatch(e.target.value);
                      }}
                      label="Size"
                      size="small"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {pageSize.map((obj, idx) => {
                        return (
                          <MenuItem key={idx} value={obj}>
                            {obj}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <FirstPageIcon
                      sx={{ color: "#3498DB", fontSize: "25px" }}
                    />
                    <span>1</span>
                    <LastPageIcon sx={{ color: "#3498DB", fontSize: "25px" }} />
                  </Stack>
                </Stack>
                <Stack direction={"row"} alignItems="center">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="All"
                    />
                  </FormGroup>
                </Stack>
              </Stack>
            </Paper>{" "}
          </Grid> */}
          <Grid item xs={12} mt={0.5}>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems={{ xs: "stretch", sm: "stretch", md: "center" }}
            >
              {orders && orders.length ? (
                orders.map((obj, key) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6} minWidth={"50%"}>
                      <OrderDetails
                        key={key}
                        orders={obj}
                        setSelectedOrder={setSelectedOrder}
                        slectedOrder={slectedOrder}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  No Order Found! — <strong>Try Different Input!</strong>
                </Alert>
              )}
            </Grid>
            {/* <Box
          
              sx={{
                height: { xs: "60vh", md: "60vh" },
                maxWidth:'100%'
              }}
            >
              


            </Box> */}
          </Grid>
        </Grid>
      ) : (
        <ReAssignStatusTable />
      )}
    </Box>
  );
}

export default ReassignSupplier;
