import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";

function LinkOrderToOrderScanningProcessDialog(props) {
  const [scanOrderId, setScanOrderId] = React.useState("");
  const handleLinkOrder = () => {
    props.handleLinkOrder({ scanOrderId: scanOrderId });
  };
  const handlCloseDialogOrdertoOrder = () => {
    props.handlCloseDialogOrdertoOrder();
  };
  return (
    <Dialog open={props.openOrderToOrderScanningDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              onClick={handlCloseDialogOrdertoOrder}
              variant="contained"
              color="error"
              size="small"
            >
              <Close />
            </Button>
          </Box>
          <TextField
            size="small"
            label="Enter Order Id"
            value={scanOrderId}
            onChange={(event) => setScanOrderId(event.target.value)}
            sx={{
              borderTopLeftRadius: "50%",
              borderBottomLeftRadius: "50%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ fontWeight: "bold" }}>
                    {props.orderIdStartingLetters}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ mt: 2 }}
            onClick={handleLinkOrder}
          >
            Link
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default LinkOrderToOrderScanningProcessDialog;
