/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialProfileState = {
  error: true,
  isLoading: true,
  filePath: "",
  fileName: "",
  isFileUploaded: false,
  msg: "",
  imagePath: "",
  poUploadData: [],
  appImages: {},
};

const getFilesReducer = (state = initialProfileState, action) => {
  // CustomConsole(action);
  switch (action.type) {
    case actionType.GET_FILES:
      return {
        ...state,
        error: false,
        filePath: action.payload,
        isLoading: false,
      };
    case actionType.GET_IMAGE_FILES:
      return {
        ...state,
        error: false,
        imagePath: action.payload,
        isLoading: false,
      };
    case actionType.UPLOAD_FILE:
      return {
        ...state,
        error: false,
        data: action.payload,
        fileName: action.payload.data.filename,
        isFileUploaded: action.payload.statusFlag,
      };
    case actionType.UPLOAD_FILE_GIFT_CARD:
      return {
        ...state,
        error: false,
        data: action.payload,
        fileName: action.payload.data.filename,
        isFileUploaded: action.payload.statusFlag,
      };
    case actionType.UPLOAD_PO_FILE:
      return {
        ...state,
        error: false,
        poUploadData: action.payload.data,
        fileName: action.payload.data.filename,
        isFileUploaded: action.payload.statusFlag,
      };
    case actionType.CLEAR_FILES_DATA:
      return {
        ...initialProfileState,
        // validatedData: action.payload.data,
      };
    case actionType.STORE_AND_GET_IMAGE_FILES:
      console.log("action.payload....................");
      console.log(action.payload);
      return {
        ...state,
        appImages: {
          ...state.appImages,
          [action.payload.data.fileName]: action.payload.data.content,
        },
      };
    default:
      return state;
  }
};

export default getFilesReducer;
