import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Card,
  CardContent,
  DialogTitle,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  BookPdwOrder,
  SubscriptionOrderPWD,
} from "../Redux/Actions/adminBookingAction";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Alert from "@mui/material/Alert";
import { Calendar } from "react-multi-date-picker";
import DialogActions from "@mui/material/DialogActions";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import Checkbox from "@mui/material/Checkbox";
import OTPLessDeliveryConfirmationDialog from "./OTPLessDeliveryConfirmationDialog";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const styles = {
  LostCansStyleHeading: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "10px",
    gap: "10px",
    color: "#0a83c4",
    fontWeight: "bold",
  },
  LostCansStyleValue: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "10px",
    gap: "10px",
  },
};
const days = [
  { name: "SUN", value: 0 },
  { name: "MON", value: 1 },
  { name: "TUE", value: 2 },
  { name: "WED", value: 3 },
  { name: "THUR", value: 4 },
  { name: "FRI", value: 5 },
  { name: "SAT", value: 6 },
];
export default function PdwOrderDialog(props) {
  const dispatch = useDispatch();
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate);
  const [openChangeAddress, setOpenChangeAddress] = React.useState(false);
  const [addressId, setAddressId] = React.useState(
    props.currentAddress.address_id
  );
  const [selectedDay, setSelectedDay] = React.useState(2);
  const [daysArr, setDaysArr] = React.useState([]);

  const toggleDay = (day) => {
    if (daysArr.includes(day)) {
      setDaysArr(daysArr.filter((selected) => selected !== day));
    } else {
      setDaysArr([...daysArr, day]);
    }
  };

  React.useEffect(() => {
    CustomConsole("----Selected Days----");
    CustomConsole("----Selected Days----");
    CustomConsole("----Selected Days----");
    CustomConsole("----Selected Days----");
    CustomConsole("----Selected Days----");
    CustomConsole(daysArr);
  }, [daysArr]);

  CustomConsole("address");
  CustomConsole("address");
  CustomConsole(props.currentAddress);
  var date = moment();
  const todayDate = moment().format("DD MMMM YYYY");
  const [deliveryDate, setDeliveryDate] = React.useState(todayDate);
  var maxDate = moment(date).add(7, "d");
  // CustomConsole(maxDate._d);
  const today = new Date();
  // maxDate.setDate(maxDate.getDate() + 6);
  today.setDate(today.getDate() + 1);
  // CustomConsole(`maxDate: ${maxDate}`);
  CustomConsole(`today: ${today}`);
  // CustomConsole(`maxDate.getDate(): ${maxDate.getDate()}`);
  var dt = new Date();
  const [edited, setEdited] = React.useState(false);
  // let createdDate = moment(new Date()).utc().format();
  let createdDate = moment(today).utc().format();

  const userUpdates = useSelector((state) => state.userDetailsUpdate);
  const [deliveryDatesArray, setDeliveryDatesArray] = React.useState([]);
  const array = [moment(today).tz("Asia/Kolkata").format("YYYY-MM-DD")];
  const [alignment, setAlignment] = React.useState("single");

  const handleChangeOrderType = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  React.useEffect(() => {
    if (alignment === "subscription") {
      props.setIsSubscriptionOrder(true);
      setIsTodayDelivery(false);
      setPayment("");
      setDeliveryDatesArray([]);
      setSelectedDays("");
    } else if (alignment === "single") {
      props.setIsSubscriptionOrder(false);
    }
  }, [alignment]);

  const userOption = [
    {
      value: "1",
      option: "Everyday",
    },
    {
      value: "2",
      option: "Once in two days",
    },
    {
      value: "3",
      option: "Once in three days",
    },
    {
      value: "4",
      option: "Once in four days",
    },
    {
      value: "5",
      option: "Once in five days",
    },
    {
      value: "6",
      option: "Once in six days",
    },
    {
      value: "7",
      option: "Once in seven days",
    },
  ];

  const bottles = [
    {
      id: 0,
      name: "20 Litres Water Can",
      price: props.twentyLtrPrice,
      type: '"Per Bottle"',
    },
  ];
  const [noOfEmptyCansUserSelected, setNoOfEmptyCansUserSelected] =
    React.useState(0);
  const [emptyCansUserSelected, setEmptyCansUserSelected] = React.useState(0);
  const [material, setMaterial] = React.useState("");
  const [payment, setPayment] = React.useState("");
  const bookingDetails = useSelector((state) => state.adminBookingData);

  const [selectedBottles, setSelectedBottles] = React.useState([]);
  const [selectedQty, setSelectedQty] = React.useState(props.min);

  const [newContainerQty, setNewContainerQty] = React.useState(0);
  const [extraContainers, setExtraContainers] = React.useState(0.0);
  const [existingNewCans, setExistingNewCans] = React.useState(0);
  const [existingEmptyCans, setExistingEmptyCans] = React.useState(0);
  const [totalExistingcans, setTotalExistingCans] = React.useState(0);
  const [onGoingNewCans, setOnGoingNewCans] = React.useState(0);
  const [selectedDays, setSelectedDays] = React.useState(0);

  const [finalPrice, setFinalPrice] = React.useState(0.0);
  const [orderPrice, setOrderPrice] = React.useState(0.0);
  const [containerPrice, setContainerPrice] = React.useState(0.0);
  const [totalDeposit, setTotalDeposit] = React.useState(0.0);

  const [isExtraChargesAdded, setIsExtraChargesAdded] = React.useState(true);
  // For Same Day Delivery Charges
  const [sameDayDeliveryCharges, setSameDayDeliveryCharges] =
    React.useState(0.0);
  const [totalSameDayDeliveryCharges, setTotalSameDayDeliveryCharges] =
    React.useState(0.0);

  // Without OTP
  const [withoutOtpChecked, setWithoutOtpChecked] = React.useState(false);
  const [otpDeliveryStatus, setOtpDeliveryStatus] = React.useState(100);
  const [confirmationDialog, setConfirmationDialog] = React.useState(false);
  const [isTodayDelivery, setIsTodayDelivery] = React.useState(true);

  const handleWithoutOTPChange = (event) => {
    CustomConsole(event.target.checked);
    setWithoutOtpChecked(event.target.checked);
  };

  React.useEffect(() => {
    CustomConsole(withoutOtpChecked);
    if (withoutOtpChecked === true) {
      setOtpDeliveryStatus(200);
      setConfirmationDialog(true);
    } else {
      setOtpDeliveryStatus(100);
    }
  }, [withoutOtpChecked]);

  React.useEffect(() => {
    props.setPostpaidFinalPrice(finalPrice);
  }, [finalPrice]);
  React.useEffect(() => {
    CustomConsole(props);
  }, []);

  // on this component render setting the default container material as "PET"
  React.useEffect(() => {
    props.depositData.map((material) => {
      if (material.container_material === "PET") {
        setMaterial(material.container_material);
      }
    });
  }, []);

  React.useEffect(() => {}, [
    existingEmptyCans,
    onGoingNewCans,
    existingNewCans,
    totalExistingcans,
    newContainerQty,
  ]);
  React.useEffect(() => {
    CustomConsole(`noOfEmptyCans: ${noOfEmptyCansUserSelected}`);
  }, [noOfEmptyCansUserSelected]);

  React.useEffect(() => {
    setSelectedBottles([]);
    setSelectedBottles((prev) => [
      ...prev,
      {
        ...bottles[0],
        totalPrice: parseInt(props.min) * bottles[0].price,
        quantity: parseInt(props.min),
      },
    ]);
  }, [props.min, props.max]);
  React.useEffect(() => {
    CustomConsole("--- In UseEffect Material ---");
    CustomConsole(props.stockData);
    var isMaterialFound = false;
    var extstNew = 0;
    var exstEmpt = 0;
    var exstOg = 0;
    var exstTot = 0;
    props.depositData.map((data, key) => {
      if (data.container_material === material) {
        setContainerPrice(data.amount);
      }
    });

    props.userStockData.map((data, key) => {
      if (data.container_material === material) {
        CustomConsole(`material ${material}`);
        isMaterialFound = true;
        CustomConsole(material);
        CustomConsole(data);
        exstEmpt = data.filled_container_qty - data.empty_containers_submitting;
        exstOg =
          data.ongoing_ordered_containers - data.empty_containers_submitting;
        extstNew = data.new_containers_to_provide - exstOg;
        exstTot = extstNew + exstEmpt;
      }
    });
    if (!isMaterialFound) {
      extstNew = 0;
      exstEmpt = 0;
      exstOg = 0;
      exstTot = 0;
    }
    CustomConsole(`extstNew: ${extstNew}`);
    CustomConsole(`exstEmpt: ${exstEmpt}`);
    CustomConsole(`exstOg: ${exstOg}`);
    CustomConsole(`exstTot: ${exstTot}`);
    setExistingNewCans(extstNew);
    setTotalExistingCans(exstTot);

    setExistingEmptyCans(exstEmpt);
    setOnGoingNewCans(exstOg);
    // if (props.wantGenie === "Yes" && props.subscribe === "Yes") {
    //   setNoOfEmptyCansUserSelected(exstEmpt);
    // }

    handleContainerCalculations({
      orderQty: selectedQty,
      existingNew: extstNew,
      existingEmpt: exstEmpt,
      existingTotal: exstTot,
      isEmptyChanged: false,
      isInc: false,
    });
    updateDeliveryCharges(moment(), isExtraChargesAdded);
  }, [material]);

  React.useEffect(() => {
    let totalPrice = extraContainers * containerPrice;
    setTotalDeposit(totalPrice);

    setFinalPrice(parseFloat(orderPrice) + parseFloat(totalPrice));
  }, [extraContainers, containerPrice]);

  React.useEffect(() => {
    CustomConsole(bookingDetails);
    if (
      payment !== 2 &&
      moment(deliveryDate).format("MM/DD/YYYY") ===
        moment().format("MM/DD/YYYY") &&
      props.isSubscriptionOrder === false
    ) {
      bookingDetails.setupConfigData.map((data) => {
        // CustomConsole(data);
        if (data.key === "single_order_extra_charges") {
          setSameDayDeliveryCharges(data.value[0].v);
          setIsExtraChargesAdded(true);
        }
      });
    } else {
      setSameDayDeliveryCharges(0);
      setIsExtraChargesAdded(false);
    }
  }, [bookingDetails, deliveryDate, props.isSubscriptionOrder]);
  const handleChange = (event) => {
    setMaterial(event.target.value);
  };

  // For Handling same day delivery charges for post paid order if postpaid order same
  // day delivery charges will not add.
  const handleChangePayment = (event) => {
    CustomConsole(event.target.value);
    setPayment(event.target.value);
    props.setPaymentType(event.target.value);
    setIsExtraChargesAdded(true);
    //event.target.value === 2 means postpaid payment method
    if (event.target.value === 2) {
      setSameDayDeliveryCharges(0);
      setIsExtraChargesAdded(false);
    } else {
      if (
        moment(deliveryDate).format("MM/DD/YYYY") ===
          moment().format("MM/DD/YYYY") &&
        props.isSubscriptionOrder === false
      ) {
        setIsExtraChargesAdded(true);
        bookingDetails.setupConfigData.map((data, key) => {
          CustomConsole(data);
          if (data.key === "single_order_extra_charges") {
            setSameDayDeliveryCharges(data.value[0].v);
          }
        });
      } else {
        setSameDayDeliveryCharges(0);
        setIsExtraChargesAdded(false);
      }
    }
  };

  const handleClose = () => {
    props.setOpenPdwOrderDialog(false);
    props.setShowPincodeToEnter(false);
    props.setBookOrderBtn(false);
    props.setCheckAvailability(false);
    props.setOrderBtn(false);
  };
  const [value, setValue] = React.useState(date);

  React.useEffect(() => {
    CustomConsole("-------- useEffect: totalSameDayDeliveryCharges ----------");
    CustomConsole(totalSameDayDeliveryCharges);
    // setFinalPrice(()=>parseFloat(finalPrice) + parseFloat(totalSameDayDeliveryCharges))
  }, [totalSameDayDeliveryCharges]);
  const updateDeliveryCharges = (dd, isAdminEnabled) => {
    CustomConsole("---- updateDeliveryCharges ----");
    CustomConsole(moment(dd).format("MM/DD/YYYY"));
    if (
      isAdminEnabled === true &&
      moment(dd).format("MM/DD/YYYY") === moment().format("MM/DD/YYYY")
    ) {
      // Same Day
      CustomConsole("---- Same Day ----");
      CustomConsole(`Total Charges: ${sameDayDeliveryCharges * selectedQty}`);
      let tdc = sameDayDeliveryCharges * selectedQty;
      setTotalSameDayDeliveryCharges(() => tdc);
      CustomConsole(`---- FP: ${parseFloat(finalPrice) + parseFloat(tdc)} --`);
      // if(isUpdatedFromDeliveryDate===true){
      //   setFinalPrice(()=>parseFloat(finalPrice) + parseFloat(tdc))
      // }else{
      //   setFinalPrice(()=>parseFloat(finalPrice) + parseFloat(sameDayDeliveryCharges))
      // }
    } else {
      // Same Day
      CustomConsole("---- Other Day ----");
      CustomConsole(`Total Charges: 0`);
      // if(isUpdatedFromDeliveryDate===true && totalSameDayDeliveryCharges !==0){
      //   setFinalPrice(()=>parseFloat(finalPrice) - parseFloat(totalSameDayDeliveryCharges))
      //   CustomConsole(`---- FP: ${parseFloat(finalPrice) - parseFloat(totalSameDayDeliveryCharges)} --`);
      // }
      setTotalSameDayDeliveryCharges(0);
    }
  };
  const handleSwitchSameDayDelivery = (event) => {
    CustomConsole(
      "-------------- handleSwitchSameDayDelivery ------------------"
    );
    if (event.target.checked) {
      CustomConsole("--- true: checked ---");
      setIsExtraChargesAdded(true); //Send true to set the SameDay Delivery Charges or else set it to false
      CustomConsole(deliveryDate);
      updateDeliveryCharges(deliveryDate, true);
    } else if (event.target.checked === false) {
      setIsExtraChargesAdded(false); //Send true to set the SameDay Delivery Charges or else set it to false
      setTotalSameDayDeliveryCharges(0);
    }
  };
  const handleChangeDate = (newValue) => {
    if (newValue !== undefined && newValue !== null) {
      CustomConsole(newValue.$d);
      setDeliveryDate(() => moment(newValue.$d).format("DD MMMM YYYY"));

      // If trying to place order for tomorrow
      CustomConsole(todayDate);
      CustomConsole(deliveryDate);
      CustomConsole(moment(newValue.$d).format("DD MMMM YYYY"));
      let selectedDate = moment(newValue.$d).format("DD MMMM YYYY");
      CustomConsole(`selectedDate ${selectedDate}`);

      // To check weather user selected date is today or not
      if (selectedDate === todayDate) {
        CustomConsole("Selected Today");
        setIsTodayDelivery(true);
        setSelectedQty(props.min);
        setSelectedBottles((obj) => [{ ...obj[0], quantity: 2 }]);
      } else {
        CustomConsole("Not Selected today");
        setIsTodayDelivery(false);
      }
      // When Delivery Date is not Today, disable SameDayDeliveryCharges and Disable it.
      updateDeliveryCharges(newValue.$d, isExtraChargesAdded);
    } else {
      setDeliveryDate(null);
    }
  };

  React.useEffect(() => {
    CustomConsole(`isTodayDelivery ${isTodayDelivery}`);
    // if (isTodayDelivery === false) {
    if (selectedQty < props.min) {
      setSelectedBottles((obj) => [{ ...obj[0], quantity: 2 }]);
      setSelectedQty(props.min);
    }
  }, [isTodayDelivery]);

  const onChangeQuantity = (e, id) => {
    let reg = /^[0-9]*$/;

    CustomConsole(reg);
    var qty = parseInt(e.target.value);
    CustomConsole(qty);

    if (!reg.test(qty)) {
      qty = 0;
    }

    if (qty >= props.min && qty <= props.max) {
      setSelectedQty(qty);
      let temp = [...selectedBottles];
      CustomConsole("---onchange temp value---");
      CustomConsole(temp);
      let index = temp.findIndex((x) => x.id === id);
      let temp2 = temp[index];
      temp2 = { ...temp2, quantity: qty };
      temp[index] = temp2;
      setSelectedBottles([...temp]);
    }
  };

  const increOrDecre = (id, type) => {
    // CustomConsole("-------- Before ------------ ");
    CustomConsole(type);
    let temp = [...selectedBottles];
    CustomConsole(temp);
    let index = temp.findIndex((x) => x.id === id);
    let temp2 = temp[index];
    if (type === "incre") {
      if (temp2.quantity > `${props.max}` - 1) {
        // setAlert(true);
        return 0;
      }
      CustomConsole("Incre-decre");
      CustomConsole("Incre-decre");
      CustomConsole("Incre-decre");
      CustomConsole("Incre-decre");
      temp2 = { ...temp2, quantity: temp2.quantity + 1 };
      CustomConsole(`increment: ${temp2.quantity}`);
      setSelectedQty(temp2.quantity);
    } else {
      CustomConsole("Incre-decre1");
      CustomConsole("Incre-decre1");
      CustomConsole("Incre-decre1");
      CustomConsole("Incre-decre1");
      CustomConsole("Incre-decre1");
      if (
        temp2.quantity - 1 > `${props.min}` - 1 ||
        (deliveryDate &&
          moment(deliveryDate).format("MM/DD/YYYY") ===
            moment().format("MM/DD/YYYY") &&
          temp2.quantity - 1 >= 1 &&
          props.isSubscriptionOrder === false)
      ) {
        CustomConsole("Incre-decre2");
        CustomConsole("Incre-decre2");
        CustomConsole("Incre-decre2");
        CustomConsole("Incre-decre2");
        temp2 = { ...temp2, quantity: temp2.quantity - 1 };
        CustomConsole(`Decrement: ${temp2.quantity}`);
        setSelectedQty(temp2.quantity);
      }
    }
    temp[index] = temp2;
    setSelectedBottles([...temp]);
    CustomConsole("----printing filled containers-----");
    // CustomConsole(filledContainers);
    CustomConsole("------selected Quantity----");
    CustomConsole(selectedQty);
  };

  const handleContainerCalculations = (object) => {
    CustomConsole(`------- handleContainerCalculations ------`);
    CustomConsole(object);
    var selectEcqty = 0;
    var newCont = 0;
    var extraCont = 0;
    if (object.isEmptyChanged) {
      if (object.isInc) {
        selectEcqty = noOfEmptyCansUserSelected + 1;
      } else {
        selectEcqty = noOfEmptyCansUserSelected - 1;
      }
    }
    CustomConsole(`selectEcqty 1 : ${selectEcqty}`);
    if (object.orderQty > object.existingTotal) {
      if (!object.isEmptyChanged) {
        selectEcqty = object.existingEmpt;
      }
    } else {
      if (object.orderQty > object.existingEmpt) {
        if (!object.isEmptyChanged) {
          selectEcqty = object.existingEmpt;
        }
      } else {
        if (!object.isEmptyChanged) {
          selectEcqty = object.orderQty;
        }
      }
    }
    newCont = object.orderQty - selectEcqty;
    if (newCont > object.existingNew) {
      newCont = object.existingNew;
    }
    extraCont = object.orderQty - selectEcqty - newCont;
    CustomConsole(`object.orderQty: ${object.orderQty}`);
    CustomConsole(`newCont: ${newCont}`);
    CustomConsole(`extraCont: ${extraCont}`);
    CustomConsole(`selectEcqty 2 : ${selectEcqty}`);
    setNoOfEmptyCansUserSelected(selectEcqty);
    setNewContainerQty(newCont);
    setExtraContainers(extraCont);
  };

  React.useEffect(() => {
    CustomConsole("-----in haldle use effect--");

    CustomConsole(`existingNewCans: ${existingNewCans}`);
    CustomConsole(`existingEmptyCans: ${existingEmptyCans}`);
    CustomConsole(`selectedQty: ${selectedQty}`);
    CustomConsole(`totalExistingcans: ${totalExistingcans}`);

    handleContainerCalculations({
      orderQty: selectedQty,
      existingNew: existingNewCans,
      existingEmpt: existingEmptyCans,
      existingTotal: totalExistingcans,
      isEmptyChanged: false,
      isInc: false,
    });
    updateDeliveryCharges(deliveryDate, isExtraChargesAdded);
  }, [selectedQty]);

  let total = 0;
  selectedBottles.forEach((s) => {
    total += s.quantity * s.price;
  });

  React.useEffect(() => {
    const convF = parseFloat(total) * (parseFloat(props.convFee) / 100);
    const fP = parseFloat(total);
    setFinalPrice(fP.toFixed());
    setOrderPrice(fP.toFixed());
  }, [total]);

  const handleReturnMinus = () => {
    if (noOfEmptyCansUserSelected > 0) {
      // handleContainerCalculations({isEmptyChanged:true,isInc:false});
      handleContainerCalculations({
        orderQty: selectedQty,
        existingNew: existingNewCans,
        existingEmpt: existingEmptyCans,
        existingTotal: totalExistingcans,
        isEmptyChanged: true,
        isInc: false,
      });
      // setNoOfEmptyCansUserSelected(noOfEmptyCansUserSelected - 1);
    } else {
      toast.dark("You Can't Submit the Empty Cans less than Zero!!!", {
        position: "bottom-right",
        autoClose: 1000,
        type: "error",
      });
    }
  };

  const handleReturnPlus = () => {
    // CustomConsole(`----- handlePlus ------`)
    if (
      !(noOfEmptyCansUserSelected + 1 > existingEmptyCans) &&
      !(noOfEmptyCansUserSelected + 1 > selectedQty)
    ) {
      // CustomConsole('--- Selected Cans are Within Limit ---')
      // handleContainerCalculations({isEmptyChanged:true,isInc:true});
      handleContainerCalculations({
        orderQty: selectedQty,
        existingNew: existingNewCans,
        existingEmpt: existingEmptyCans,
        existingTotal: totalExistingcans,
        isEmptyChanged: true,
        isInc: true,
      });
    } else {
      toast.dark(
        "You Can't Submit the Empty Cans More than Existing/Ordering Quantity!!!",
        {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        }
      );
    }
  };
  CustomConsole(props.lowWalletBalance);
  const handleBookNow = () => {
    if (payment !== "" && deliveryDate !== "") {
      CustomConsole("Booknow");
      CustomConsole(payment);
      CustomConsole(props.currentAddress.service_area);
      CustomConsole(props.currentAddress);
      var momentDate = moment();
      var momentToday = moment.utc(momentDate).format();
      // var startDateSingleOrder = momentToday + "";
      const dateMoment = moment.utc(deliveryDate).format();
      const endDateMoment = dateMoment + "";
      var twlqty = 0.0;
      var twlprice = 0.0;
      var twltprice = 0.0;

      selectedBottles.forEach((s) => {
        if (s.name.includes("20 Litres")) {
          twlqty = s.quantity;
          twlprice = s.price;
          twltprice = twlqty * twlprice;
        }
        total += s.quantity * s.price;
      });
      const paymentMethod =
        payment === 0
          ? "RAZORPAY"
          : payment === 1
          ? "PAYSHARP"
          : payment === 2
          ? "POSTPAID"
          : "WATERWALLET";
      CustomConsole("delivery date check");

      CustomConsole(deliveryDate);
      CustomConsole("Current address Check");
      CustomConsole("Current address Check");

      CustomConsole(props.currentAddress);

      const deliveryAddress = {
        address_id: props.currentAddress.address_id,
        address_line1: props.currentAddress.address_line1,
        address_line2: props.currentAddress.address_line2,
        address_line3: props.currentAddress.address_line3,
        contact_name: props.currentAddress.contact_name,
        contact_phone: props.currentAddress.contact_phone,
        delivery_timing_option: props.currentAddress.delivery_timing_option,
        is_primary: props.currentAddress.is_primary,
        landmark: props.currentAddress.landmark,
        latitude: props.currentAddress.latitude,
        longitude: props.currentAddress.longitude,
        pincode: props.currentAddress.pincode,
        service_area: props.currentAddress.service_area,
        user_id: props.currentAddress.user_id,
      };
      const depositObjData = {
        container_capacity: "20_Liters",
        container_material: material,
        container_quantity: extraContainers,
        deposit_amount: totalDeposit,
        user_id: props.currentAddress.user_id,
      };
      const reqObj = {
        // areaname: props.currentAddress.service_area.toLowerCase(),
        areaname: props.currentAddress.service_area,
        consumer_id: props.consumerId,
        consumer_name: props.consumerName,
        conv_fee: 0,
        delivery_address: deliveryAddress,
        deposit_price: totalDeposit,
        discount: 0,
        end_date: moment(deliveryDate).tz("Asia/Kolkata").format("YYYY-MM-DD"),
        // end_date: isTodayDelivery
        //   ? deliveryDate
        //   : moment(deliveryDate).add(1, "days").format("DD MM YYYY"),
        // end_date: endDateMoment,
        gateway_fee: 0,
        latitude: "0",
        longitude: "0.0",
        order_terms: "0.0",
        order_type: "200",
        packaged_drinking_water: {
          empty_container_qty: noOfEmptyCansUserSelected,
          fivehndml_capacity_amt: 0,
          fivehndml_capacity_qty: 0,
          fivehndml_capacity_totamt: 0,
          fiveltr_capacity_amt: 0,
          fiveltr_capacity_qty: 0,
          fiveltr_capacity_totamt: 0,
          is_inscan_completed: false,
          is_order_started: false,
          is_outscan_completed: false,
          is_verify_otp: false,
          new_container_qty: newContainerQty,
          delivery_otp_status: otpDeliveryStatus,
          oneltr_capacity_amt: 0,
          oneltr_capacity_qty: 0,
          oneltr_capacity_totamt: 0,
          received_empty_cans: 0,
          selected_container_capacity: "20_Liters",
          selected_container_material: material,
          submitted_filled_cans: 0,
          twenty_ltr_capacity_amt: twlprice,
          // twenty_ltr_capacity_amt: 40,
          twenty_ltr_capacity_totamt: twltprice,
          // twenty_ltr_capacity_totamt: 40,
          twentyltr_capacity_qty: twlqty,
          twohndml_capacity_amt: 0,
          twohndml_capacity_qty: 0,
          twohndml_capacity_totamt: 0,
          twoltr_capacity_amt: 0,
          twoltr_capacity_qty: 0,
          twoltr_capacity_totamt: 0,
          is_extra_charges_added: isExtraChargesAdded,
          current_same_day_delivery_charge:
            isExtraChargesAdded === true ? sameDayDeliveryCharges : 0.0,
          total_delivery_charges: totalSameDayDeliveryCharges,
          order_type:
            isExtraChargesAdded === true && props.isSubscriptionOrder === false
              ? 700
              : isExtraChargesAdded === false &&
                props.isSubscriptionOrder === false
              ? 700
              : 0,
        },
        payment_type: payment === 2 ? "300" : "100",
        payment_method:
          payment === 0
            ? "RAZORPAY"
            : payment === 1
            ? "PAYSHARP"
            : payment === 2
            ? "POSTPAID"
            : "WATERWALLET",
        paysharphandle: "0",
        pincode: props.currentAddress.pincode,
        pipe_length: 0,
        pipe_length_price: 0,
        postpaidrequest: "0",
        road_width: 0,
        start_date: moment().tz("Asia/Kolkata").format("YYYY-MM-DD"),
        tanker_type: "3",
        total_trips: "1",
        trip_rate:
          parseFloat(finalPrice) + parseFloat(totalSameDayDeliveryCharges),
        trips_per_day: "1",
        weekdays: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
        deposit_details: props.isSubscriptionOrder ? depositObjData : undefined,
      };

      const subscriptionDetailsObj = {
        is_subscribed: props.isSubscriptionOrder,
        // repeat_days: subscriptionRepeatDays,
        is_post_paid: false,
        po_file: "",
        is_paused: false,
        // repeat_days_array: edited ? props.formatedDate : arrayNew,
        // repeat_days_array: props.formatedDate,
        // repeat_days_array: deliveryDatesArray,
        repeat_days: daysArr,
        repeat_days_array: [],
      };
      CustomConsole(props.walletBalance);
      CustomConsole(finalPrice);
      CustomConsole(orderPrice);
      CustomConsole(reqObj);
      if (props.isSubscriptionOrder === true) {
        if (payment === 3 && props.walletBalance > finalPrice) {
          if (daysArr.length > 0) {
            dispatch(
              SubscriptionOrderPWD({
                order_details: reqObj,
                subscription_details: subscriptionDetailsObj,
              })
            );
            props.setOpenPdwOrderDialog(false);
          } else {
            toast.error("Please Select days for delivery", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        } else {
          toast.error("Low Wallet Balance For this Order", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        CustomConsole(paymentMethod);
        CustomConsole(reqObj);
        dispatch(BookPdwOrder(reqObj, paymentMethod, depositObjData));
        props.setOpenPdwOrderDialog(false);
        props.setWalletTrigger(true);
        if (paymentMethod === "POSTPAID") {
          props.setPostpaidFlag(true);
        } else {
          props.setPostpaidFlag(false);
        }
      }
    } else if (deliveryDate === "") {
      toast.error("Please Select Delivery Day", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      toast.error("Please Select Payment Method", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleChangeOption = (event) => {
    setSelectedDays(event.target.value);

    // CustomConsole(userSelect);
    CustomConsole(event.target.value);
  };
  const [arrayNew, setArrayNew] = React.useState([]);
  const [subscriptionRepeatDays, setSubscriptionRepeatDays] = React.useState(
    []
  );
  const [formatedDate, setFormatedDate] = React.useState([]);

  React.useEffect(() => {
    CustomConsole(selectedDays);
    CustomConsole(createdDate);
    CustomConsole(moment(today).utc().format());

    CustomConsole(deliveryDatesArray);

    for (var i = 0; i <= 29; i++) {
      let nextDate = moment(createdDate).add(selectedDays, "d");
      // CustomConsole(nextDate._d);
      array.push(moment(nextDate._d).tz("Asia/Kolkata").format("YYYY-MM-DD"));
      createdDate = nextDate;
    }
    setDeliveryDatesArray(array);
    setFormatedDate(array);
    CustomConsole(array);
    setSubscriptionRepeatDays([parseInt(selectedDays)]);
  }, [selectedDays]);

  const editedArray = [];

  React.useEffect(() => {
    if (edited) {
      CustomConsole("Edited");
      CustomConsole(formatedDate);
      let start = 0;
      let end = 10;
      for (var i = 0; i < 30; i++) {
        // CustomConsole(formatedDate[0].slice(start, end));
        if (formatedDate[0].slice(start, end) !== "") {
          editedArray.push(formatedDate[0].slice(start, end));
          start = start + 11;
          end = end + 11;
        }
        setFormatedDate(editedArray);
        setDeliveryDatesArray(editedArray);
      }
      CustomConsole(editedArray);
    }
    setEdited(false);
  }, [edited]);

  React.useEffect(() => {
    setTotalSameDayDeliveryCharges(0);
    let tdc = sameDayDeliveryCharges * selectedQty;
    setTotalSameDayDeliveryCharges(() => tdc);
  }, [sameDayDeliveryCharges]);

  const handleCloseChangeAddress = () => {
    setOpenChangeAddress(false);
  };
  const [choosenAddressWhileBooking, setChoosenAddressWhileBooking] =
    React.useState(props.currentAddress);

  const handleClickChooseAddress = () => {
    // setAddressId("");
    setOpenChangeAddress(true);
  };

  const handleCardClick = (data, id) => {
    CustomConsole(data);
    CustomConsole(id);
    setAddressId(id);
    setChoosenAddressWhileBooking(data);
    // setAddressChanged(true);
  };

  const handleChangeAddress = () => {
    props.setCurrentAddress(choosenAddressWhileBooking);
    toast.dark("Address successfully changed", {
      position: "bottom-right",
      autoClose: 1000,
      type: "success",
    });
    setOpenChangeAddress(false);
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation(); // Prevents closing the dialog when clicking inside the dialog
  };
  React.useEffect(() => {
    CustomConsole("---------------->>>>>>Grand Total<<<<<<<<<<<<<<----------");
    CustomConsole(finalPrice);
    CustomConsole(totalSameDayDeliveryCharges);
    props.setOrderPrice(
      () => parseFloat(finalPrice) + parseFloat(totalSameDayDeliveryCharges)
    );
  }, [finalPrice, sameDayDeliveryCharges, totalSameDayDeliveryCharges]);

  const handleInputChange = (e) => {
    e.preventDefault();
  };

  const handlePostpaidOrderDate = (value) => {
    if (value === 0) {
      setSelectedDay(value);
      setDeliveryDate(moment().format("DD MMMM YYYY"));
    } else if (value === 1) {
      setSelectedDay(value);
      setDeliveryDate(moment().add(1, "day").format("DD MMMM YYYY"));
    }
  };

  React.useEffect(() => {
    // let prevDate = deliveryDate;
    if (payment !== 2) {
      setDeliveryDate(moment().format("DD MMMM YYYY"));
    } else if (payment === 2) {
      setDeliveryDate("");
      setSelectedDay(2);
    }
  }, [payment]);

  React.useEffect(() => {
    CustomConsole(`----->>>>>>Selected Day ${selectedDay}`);
    if (selectedDay === 1) {
      CustomConsole(`----->>>>>>inside the condition ${selectedDay}`);
      setSelectedBottles((obj) => [{ ...obj[0], quantity: 2 }]);
      setIsTodayDelivery(false);
    } else if (selectedDay === 0) {
      setIsTodayDelivery(true);
    }
  }, [selectedDay]);

  React.useEffect(() => {
    CustomConsole(`----->>>>>>Inside the delivery flag<<<<<-----`);
    CustomConsole(isTodayDelivery);
  }, [isTodayDelivery, selectedDay]);

  return (
    <div>
      <Dialog
        // onBackdropClick="false"
        open={props.openPdwOrderDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                ORDER TYPE
              </Typography>
            </Box>
            <Box>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChangeOrderType}
                aria-label="Platform"
              >
                <ToggleButton value="single">Single</ToggleButton>
                <ToggleButton
                  value="subscription"
                  disabled={props.subscriptionExists}
                >
                  Subscription
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Purchase cart
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "50px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Material
                </Typography>
              </Box>
              <Box>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-label">
                    Select material
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={material}
                    label="Select material"
                    onChange={handleChange}
                  >
                    {props.depositData.map((material) => {
                      return (
                        <MenuItem value={material.container_material}>
                          {material.container_material}
                        </MenuItem>
                      );
                    })}
                    {/* <MenuItem value={"PET"}>PET</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {props.isSubscriptionOrder ? (
              <>
                <Alert severity="info" sx={{ marginTop: "10px" }}>
                  {" "}
                  Your subscription order for today can only be edited upto 1
                  AM. Kindly ensure changes to your order are made prior to that
                </Alert>
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    marginTop: "10px",
                    backgroundColor: "whitesmoke",
                    borderRadius: "25px",
                  }}
                >
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    How often you want us to deliver cans?
                  </Typography>
                  <FormControl onChange={handleChangeOption}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      {userOption.map((data) => {
                        return (
                          <FormControlLabel
                            value={data.value}
                            control={<Radio />}
                            label={data.option}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box> */}
              </>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "50px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Order
                </Typography>
              </Box>
              {selectedBottles.map((b, key) => {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        sx={{ borderRadius: "50px" }}
                        onClick={() => increOrDecre(b.id, "decre")}
                      >
                        -
                      </Button>
                    </Box>
                    <Box>
                      <TextField
                        sx={{ margin: "5px", width: "50px" }}
                        size="small"
                        value={b.quantity}
                        onChange={(e) => onChangeQuantity(e, b.id)}
                        inputProps={{ style: { fontSize: 12 } }}
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="outlined"
                        sx={{ borderRadius: "50px" }}
                        onClick={() => increOrDecre(b.id, "incre")}
                      >
                        +
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "50px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Return
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "50px" }}
                    onClick={handleReturnMinus}
                  >
                    -
                  </Button>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bolder",
                      color: "black",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    {noOfEmptyCansUserSelected}&nbsp;&nbsp;
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "50px" }}
                    onClick={handleReturnPlus}
                  >
                    +
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Payment method
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {props.isSubscriptionOrder === false && (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 220 }}>
                      <InputLabel id="demo-simple-select-label">
                        Select payment method
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payment}
                        label="Select payment method"
                        onChange={handleChangePayment}
                      >
                        <MenuItem value={0}>Razorpay</MenuItem>
                        <MenuItem value={1}>Paysharp(UPI)</MenuItem>
                        <MenuItem value={2}>Postpaid</MenuItem>
                        <MenuItem value={3}>Water wallet</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
                {props.isSubscriptionOrder === true && (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 220 }}>
                      <InputLabel id="demo-simple-select-label">
                        Select payment method
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payment}
                        label="Select payment method"
                        onChange={handleChangePayment}
                      >
                        <MenuItem value={3}>Water wallet</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </Box>
              {payment === 3 && props.lowWalletBalance ? (
                <Box sx={{ padding: "10px", width: "300px" }}>
                  <Alert severity="warning">
                    Low wallet balance order will go to payment pending.
                  </Alert>
                </Box>
              ) : payment === 3 ? (
                <Box sx={{ padding: "10px", width: "300px" }}>
                  <Alert severity="info">
                    Amount will be deducted from the consumers wallet.
                  </Alert>
                </Box>
              ) : null}
            </Box>
            {props.isSubscriptionOrder === false && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                {payment !== 2 ? (
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        minDate={date}
                        maxDate={maxDate._d}
                        label="Select Delivery date"
                        inputFormat="DD/MM/YYYY"
                        value={deliveryDate}
                        onChange={handleChangeDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : payment === 2 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid ",
                        padding: "5px 15px 5px 15px",
                        borderRadius: "10px",
                        color: selectedDay === 0 ? "#fff" : "#5DADE2",
                        cursor: "pointer",
                        "&: hover": {
                          backgroundColor: selectedDay === 0 ? "" : "#D0F1F9",
                        },
                        backgroundColor: selectedDay === 0 ? "#34495E " : "",
                        borderColor: selectedDay === 0 ? "#34495E " : "#5DADE2",
                      }}
                      onClick={() => handlePostpaidOrderDate(0)}
                    >
                      Today
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid ",
                        padding: "5px 15px 5px 15px",
                        borderRadius: "10px",
                        color: selectedDay === 1 ? "#fff" : "#5DADE2",
                        cursor: "pointer",
                        "&: hover": {
                          backgroundColor: selectedDay === 1 ? "" : "#D0F1F9",
                        },
                        backgroundColor: selectedDay === 1 ? "#34495E " : "",
                        borderColor: selectedDay === 1 ? "#34495E " : "#5DADE2",
                      }}
                      onClick={() => handlePostpaidOrderDate(1)}
                    >
                      Tomorrow
                    </Box>
                  </Box>
                ) : null}
              </Box>
            )}

            {props.isSubscriptionOrder ? (
              <Box
                sx={{
                  cursor: "pointer",
                  // display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                  marginTop: "10px",
                  border: "1px solid black",
                  borderRadius: "20px",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Select Days For Delivery
                </Typography>
                {/* <Calendar
                  minDate={today}
                  multiple
                  value={deliveryDatesArray}
                  // maxDate={dt}
                  onChange={(array) => {
                    setEdited(true);
                    CustomConsole(array);
                    CustomConsole(array.join());
                    setFormatedDate([array.join()]);
                  }}
                /> */}
                <Grid container spacing={2}>
                  {days.map((day) => (
                    <Grid item key={day.name}>
                      <Paper
                        elevation={3}
                        style={{
                          backgroundColor: daysArr.includes(day.value)
                            ? "lightblue"
                            : "white",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleDay(day.value)}
                      >
                        {day.name}
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              ""
            )}
            <Box>
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Price details
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // gap: "30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    <Typography sx={styles.LostCansStyleHeading}>
                      Can price
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={styles.LostCansStyleHeading}>
                      Can requiring deposit
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={styles.LostCansStyleHeading}>
                      Deposit per can
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={styles.LostCansStyleHeading}>
                      Total deposit to be paid
                    </Typography>
                  </Box>
                  {moment(deliveryDate).format("MM/DD/YYYY") ===
                  moment().format("MM/DD/YYYY") ? (
                    <Box>
                      <Typography sx={styles.LostCansStyleHeading}>
                        Same Day Delivery Charges
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box>
                    <Typography sx={styles.LostCansStyleHeading}>
                      Grand Total
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    {" "}
                    <Typography sx={styles.LostCansStyleValue}>
                      ₹{total}
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography sx={styles.LostCansStyleValue}>
                      {extraContainers}
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography sx={styles.LostCansStyleValue}>
                      {containerPrice}
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography sx={styles.LostCansStyleValue}>
                      {totalDeposit}
                    </Typography>
                  </Box>
                  {moment(deliveryDate).format("MM/DD/YYYY") ===
                  moment().format("MM/DD/YYYY") ? (
                    <Box>
                      {" "}
                      <Typography sx={styles.LostCansStyleValue}>
                        ₹{totalSameDayDeliveryCharges}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box>
                    {" "}
                    <Typography sx={styles.LostCansStyleValue}>
                      ₹
                      {parseFloat(finalPrice) +
                        parseFloat(totalSameDayDeliveryCharges)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              {/* <Box
                sx={{
                  background: "rgb(208,221,242)",
                  backgroundImage:
                    "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
                  padding: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={handleClickChooseAddress}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  SELECTED ADDRESS
                </Typography>
                {props.currentAddress.contact_name !== null && (
                  <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
                    <span style={{ fontWeight: "bold" }}>Name</span>:{" "}
                    {props.currentAddress.contact_name}
                  </Typography>
                )}
                {props.currentAddress.address_line1 !== null && (
                  <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
                    <span style={{ fontWeight: "bold" }}>Address</span>:{" "}
                    {props.currentAddress.address_line1},{" "}
                    {props.currentAddress.address_line2},
                    {props.currentAddress.address_line3}
                  </Typography>
                )}
                {props.currentAddress.city !== null && (
                  <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
                    <span style={{ fontWeight: "bold" }}>City</span>:{" "}
                    {props.currentAddress.city}
                  </Typography>
                )}

                {props.currentAddress.pincode !== null && (
                  <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
                    <span style={{ fontWeight: "bold" }}>Pincode</span>:{" "}
                    {props.currentAddress.pincode}
                  </Typography>
                )}

                {props.currentAddress.service_area !== null && (
                  <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
                    <span style={{ fontWeight: "bold" }}>Area</span>:{" "}
                    {props.currentAddress.service_area}
                  </Typography>
                )}

                {props.currentAddress.contact_phone !== null && (
                  <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
                    <span style={{ fontWeight: "bold" }}>Mobile No</span>:{" "}
                    {props.currentAddress.contact_phone}
                  </Typography>
                )}
              </Box> */}
              {/* <Box>
                <Button onClick={handleClickChooseAddress} variant="contained">
                  Change delivery address
                </Button>
              </Box> */}
              {/* {openChangeAddress && (
                <>
                  <Dialog
                    open={openChangeAddress}
                    onClose={handleCloseChangeAddress}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Your Addresses
                    </DialogTitle>
                    <DialogContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          gap: "20px",
                        }}
                      >
                        {" "}
                        {userAddressAdmin.address.map((data, key) => {
                          return (
                            <Card
                              sx={{
                                minWidth: 275,
                                cursor: "pointer",
                                "&:hover": {
                                  boxShadow: "none",
                                  backgroundColor: "#2196f3",
                                },
                                backgroundColor:
                                  addressId === data.address_id
                                    ? "#2196f3"
                                    : "",
                              }}
                              key={key}
                              onClick={() =>
                                handleCardClick(data, data.address_id)
                              }
                            >
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {addressId === data.address_id && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <CheckBoxRoundedIcon color="success" />
                                    </Box>
                                  )}

                                  <Box>
                                    {data.is_primary ? (
                                      <Typography
                                        sx={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                          textAlign: "right",
                                        }}
                                      >
                                        {data.contact_name}
                                        <span
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {" "}
                                          * Primary
                                        </span>
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {data.contact_name}
                                      </Typography>
                                    )}
                                  </Box>
                                  <Box>
                                    <Typography>
                                      {data.address_line1}
                                    </Typography>
                                    <Typography>
                                      {data.address_line2}
                                    </Typography>
                                    <Typography>
                                      {data.address_line3}
                                    </Typography>
                                    <Typography>{data.pincode}</Typography>
                                    <Typography>{data.service_area}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography>
                                      {data.contact_phone}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{ display: "flex", gap: "10px" }}
                                  ></Box>
                                </Box>
                              </CardContent>
                            </Card>
                          );
                        })}
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseChangeAddress}>Back</Button>
                      <Button onClick={handleChangeAddress} autoFocus>
                        Change
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )} */}
            </Box>
            <Box>
              {moment(deliveryDate).format("MM/DD/YYYY") ===
                moment().format("MM/DD/YYYY") &&
              payment !== 2 &&
              !props.isSubscriptionOrder ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", margin: "10px" }}>
                  {" "}
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "15px",
                      marginTop: "7px",
                    }}
                  >
                    Same Day Delivery Charges{" "}
                  </Typography>{" "}
                  <FormControlLabel
                    control={<Switch checked={isExtraChargesAdded} />}
                    label={isExtraChargesAdded ? "Enable" : "Disable"}
                    onChange={handleSwitchSameDayDelivery}
                    sx={{ marginLeft: "5px" }}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={withoutOtpChecked}
                    onChange={handleWithoutOTPChange}
                  />
                }
                label="Deliver this order without OTP"
              />
            </Box>

            <OTPLessDeliveryConfirmationDialog
              withoutOtpChecked={withoutOtpChecked}
              setWithoutOtpChecked={setWithoutOtpChecked}
              confirmationDialog={confirmationDialog}
              setConfirmationDialog={setConfirmationDialog}
            />
            <Box sx={{ padding: "10px", display: "flex", gap: "20px" }}>
              <Box>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
              {userUpdates.address.length !== 0 ? (
                <Box>
                  <Button variant="contained" onClick={handleBookNow}>
                    Book now
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
