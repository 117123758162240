/**
 * @author vinay kumar
 * @description Wfs User Detail Card
 * @copyright Bookwater tech pvt ltd
 * @Date 01-08-2023
 */
// This UI Complete ReDesign Done by Vinayimport React from "react";
import React from "react";
import * as Material from "@mui/material";

function WfsUserDetailsCard(props) {
  return (
    <Material.Box>
      <Material.Card
        sx={{
          borderRadius: "20px",
          margin: "10px",
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
          },
          borderColor: "black",
        }}
        elevation={5}
      >
        <Material.CardContent>
          <Material.Typography sx={{ fontWeight: "500", marginBottom: "1rem" }}>
            Water Filling Station Incharge Details
          </Material.Typography>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <DetailItem label="Name" value={props.fullnName} />
            <DetailItem label="Phone" value={props.phoneNumber} />
            <DetailItem label="Email" value={props.emaiAddress} />
          </Material.Box>
        </Material.CardContent>
      </Material.Card>
    </Material.Box>
  );
}

const DetailItem = ({ label, value }) => (
  <Material.Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
    }}
  >
    <Material.Typography sx={{ fontWeight: "bold", color: "blue" }}>
      {label}:
    </Material.Typography>
    <Material.Typography>{value}</Material.Typography>
  </Material.Box>
);

export default WfsUserDetailsCard;
