import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  users: [],
  rolesList: [],
  userData: [],
};

const addUserAdminReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action.type);
  CustomConsole(action.payload);
  switch (action.type) {
    case actionType.ADMIN_ADD_USER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        users: action.payload.user,
      };
    case actionType.SEARCH_USER_ADMIN:
      CustomConsole("---magic moments---");
      CustomConsole(action.payload);
      CustomConsole(action.payload.users);
      CustomConsole(action.payload.users[0]);
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        users: action.payload.users,
        userData: action.payload.users,
      };
    case actionType.GET_ROLES_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        rolesList: action.payload.data,
      };
    case actionType.CLEAR_DATA:
      return {
        ...initialState,
        // validatedData: action.payload.data,
      };
    default:
      return state;
  }
};

export default addUserAdminReducer;
