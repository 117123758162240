import { Alert, Box, Grid } from "@mui/material";
import React from "react";
import AllOrdersTable from "./AllOrdersTable";
import EditOrderDetailsDialog from "./EditOrderDetailsDialog";
import SearchOrderCard from "./SearchOrdersCard";
import ViewOrderDetailsCard from "./ViewOrderDetailsCard";
import OrdersCard from "../../Components/Cards/OrdersCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";
import {
  deliveryStatus,
  ordersStatus,
  ORDER_TYPE,
} from "../../Components/AppMeta/appMetaConfig";
import moment from "moment-timezone";
import { GetSearchOrderDetails } from "../Redux/Actions/ordersAdminAction";
import OrderSearchCard from "./OrderSearchCard";
export default function OrdersAdmin() {
  const dispatch = useDispatch();
  const [isEditOrderDetailsDialog, setIsEditOrderDetailsDialog] =
    React.useState(false);

  const [orderDetails, setOrderDetails] = React.useState({});
  const [newOrderDetails, setNewOrderDetails] = React.useState([]);
  const [searchOrderBtn, setSearchOrderbtn] = React.useState(false);
  const [allOrders, setAllOrders] = React.useState([]);
  const [pageReferesh, setpageReferesh] = React.useState(false);
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [disableBtn, setdisableBtn] = React.useState(false);
  const [orderStatus, setOrderStatus] = React.useState("");
  const [deliveryStatusVal, setDeliveryStatus] = React.useState("");
  const [searchBased, setSearchBased] = React.useState(false);
  const [orderType, setOrderType] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("");
  const [distNum, setDistNum] = React.useState("");
  const [orderFlag, setOrderFlag] = React.useState(false);
  const [searchOrderFlag, setSearchOrderFlag] = React.useState(false);
  const [openOrdersCard, setOpenOrdersCard] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tableView, setTableView] = React.useState(true);
  const [openSingleOrder, setOpenSingleOrder] = React.useState(false);
  const [openSingleOrderDetails, setOpenSingleOrderDetails] =
    React.useState(false);

  const [toggleVal, setToggleVal] = React.useState("Date");
  const [tabvalue, setTabvalue] = React.useState("Orders");
  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");

  // Storing the search order req obj here to hit the search order API incase required for update/refresh
  const [tempReqobj, setTempReqObj] = React.useState({});

  const [pageSize, setPageSize] = React.useState(100);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    CustomConsole(paginationModel);
    setSearchOrderFlag(true);
  }, [paginationModel]);
  // This function will be called when delivery slot update is made.
  /**
   * @author Pravesh
   * @date 29/05/23
   * @description  This function will be called when delivery slot update is made.
   */
  const updateDeliveryFuncCall = () => {
    CustomConsole(tempReqobj);
    CustomConsole("-----search orders 1----");
    let reqObjTwo = {
      sort_order: sortOrder ? sortOrder : undefined,
    };
    // dispatch(GetSearchOrderDetails(tempReqobj, reqObjTwo));
  };
  React.useEffect(() => {
    // dispatch(GetSetUpConfigAction());
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  React.useEffect(() => {
    if (orderFlag) {
      setTimeout(() => {
        updateDeliveryFuncCall();
        setOrderFlag(false);
      }, 1500);
    }
  }, [orderFlag]);

  React.useEffect(() => {
    CustomConsole(allOrderData.newallOrderDetails);
    if (Object.keys(allOrderData.newallOrderDetails).length) {
      setNewOrderDetails(allOrderData.newallOrderDetails);
    } else {
      setNewOrderDetails([]);
    }
  }, [allOrderData]);

  return (
    <>
      <Grid container spacing={2}>
        {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
        ADMIN_ROLES.BW_ADMIN === adminRole ||
        ADMIN_ROLES.BW_CSHEAD === adminRole ||
        ADMIN_ROLES.BW_CSLEAD === adminRole ||
        ADMIN_ROLES.BW_OPSHEAD === adminRole ||
        ADMIN_ROLES.BW_CS === adminRole ||
        ADMIN_ROLES.BW_OPS === adminRole ||
        ADMIN_ROLES.BW_FINANCE === adminRole ||
        ADMIN_ROLES.BW_FINANCEHEAD === adminRole ||
        ADMIN_ROLES.BW_SALES === adminRole ||
        ADMIN_ROLES.BW_SALESHEAD === adminRole ||
        ADMIN_ROLES.BW_QA === adminRole ||
        ADMIN_ROLES.BW_QAHEAD === adminRole ||
        ADMIN_ROLES.BW_IVML === adminRole ? (
          <>
            {" "}
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              // sx={{ maxWidth: { md: "95%", xs: "350px" } }}
              p={0}
            >
              <Card
                sx={{
                  marginTop: "50px",
                  padding: "0px",
                  // maxWidth: { md: "95%", xs: "100%" },
                }}
              >
                <OrderSearchCard
                  setIsEditOrderDetailsDialog={setIsEditOrderDetailsDialog}
                  setSearchOrderbtn={setSearchOrderbtn}
                  setAllOrders={setAllOrders}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  setdisableBtn={setdisableBtn}
                  orderStatusDelivery={orderStatus}
                  sortOrder={sortOrder}
                  distNum={distNum}
                  searchOrderFlag={searchOrderFlag}
                  setSearchOrderFlag={setSearchOrderFlag}
                  setDistNum={setDistNum}
                  setSortOrder={setSortOrder}
                  setOrderStatus={setOrderStatus}
                  setDeliveryStatus={setDeliveryStatus}
                  deliveryStatusVal={deliveryStatusVal}
                  setOrderDetails={setOrderDetails}
                  orderDetails={orderDetails}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  setOpenOrdersCard={setOpenOrdersCard}
                  setSearchBased={setSearchBased}
                  searchBased={searchBased}
                  setOrderType={setOrderType}
                  orderType={orderType}
                  tableView={tableView}
                  setTableView={setTableView}
                  setOpenSingleOrder={setOpenSingleOrder}
                  tabvalue={tabvalue}
                  setTabvalue={setTabvalue}
                  updateDeliveryFuncCall={updateDeliveryFuncCall}
                  setTempReqObj={setTempReqObj}
                  pageSize={pageSize}
                  paginationModel={paginationModel}
                  setOpenSingleOrderDetails={setOpenSingleOrderDetails}
                />
                {/* <SearchOrderCard
                setIsEditOrderDetailsDialog={setIsEditOrderDetailsDialog}
                setSearchOrderbtn={setSearchOrderbtn}
                setAllOrders={setAllOrders}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                setdisableBtn={setdisableBtn}
                orderStatusDelivery={orderStatus}
                sortOrder={sortOrder}
                distNum={distNum}
                searchOrderFlag={searchOrderFlag}
                setSearchOrderFlag={setSearchOrderFlag}
                setDistNum={setDistNum}
                setSortOrder={setSortOrder}
                setOrderStatus={setOrderStatus}
                setDeliveryStatus={setDeliveryStatus}
                deliveryStatusVal={deliveryStatusVal}
                setOrderDetails={setOrderDetails}
                orderDetails={orderDetails}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setOpenOrdersCard={setOpenOrdersCard}
                setSearchBased={setSearchBased}
                searchBased={searchBased}
                setOrderType={setOrderType}
                orderType={orderType}
                tableView={tableView}
                setTableView={setTableView}
                setOpenSingleOrder={setOpenSingleOrder}
                toggleVal={toggleVal}
                setToggleVal={setToggleVal}
                updateDeliveryFuncCall={updateDeliveryFuncCall}
                setTempReqObj={setTempReqObj}
                pageSize={pageSize}
                paginationModel={paginationModel}
              /> */}
                {orderDetails &&
                openSingleOrderDetails &&
                Object.keys(orderDetails).length > 0 &&
                tabvalue === "Orders" ? (
                  <OrdersCard
                    data={orderDetails}
                    openSingleOrderDetails={openSingleOrderDetails}
                    setOpenSingleOrderDetails={setOpenSingleOrderDetails}
                    clickFun={setIsEditOrderDetailsDialog}
                  />
                ) : null}
              </Card>
            </Grid>
            {/*<Grid
              item
              md={12}
              xs={12}
              sm={12}
              sx={{ maxWidth: { md: "95%", xs: "350px" } }}
            >
               {openOrdersCard && tabvalue === "Orders" ? (
                <AllOrdersTable
                  allOrders={allOrders}
                  setOrderDetails={setOrderDetails}
                  newOrderDetails={newOrderDetails}
                  setNewOrderDetails={setNewOrderDetails}
                  setIsEditOrderDetailsDialog={setIsEditOrderDetailsDialog}
                  pageReferesh={pageReferesh}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  disableBtn={disableBtn}
                  setdisableBtn={setdisableBtn}
                  setOrderStatus={setOrderStatus}
                  setSortOrder={setSortOrder}
                  setDistNum={setDistNum}
                  orderStatusDelivery={orderStatus}
                  sortOrder={sortOrder}
                  distNum={distNum}
                  setDeliveryStatus={setDeliveryStatus}
                  setSearchOrderFlag={setSearchOrderFlag}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  tableView={tableView}
                  setTableView={setTableView}
                  orderDetails={orderDetails}
                  openSingleOrder={openSingleOrder}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  otherFilterObj={tempReqobj}
                />
              ) : (
                ""
              )} 
            </Grid>*/}
            {/* <Grid container direction="column"> */}
            {/* <Grid item lg={12}> */}
            <Box>
              {isEditOrderDetailsDialog === true && (
                <EditOrderDetailsDialog
                  isEditOrderDetailsDialog={isEditOrderDetailsDialog}
                  allOrderData={allOrderData}
                  setIsEditOrderDetailsDialog={setIsEditOrderDetailsDialog}
                  orderDetails={orderDetails}
                  newOrderDetails={newOrderDetails}
                  setOrderDetails={setOrderDetails}
                  adminRole={adminRole}
                  updateDeliveryFuncCall={updateDeliveryFuncCall}
                  setOrderFlag={setOrderFlag}
                />
              )}

              {/* {isEditOrderDetailsDialog &&
              orderDetails !== undefined &&
              allOrderData.error === false ? (
        
              ) : null}  */}
            </Box>
            {/* </Grid>
          </Grid> */}
          </>
        ) : (
          <Box sx={{ marginTop: "50px" }}>
            <Alert severity="error">Access Denied</Alert>
          </Box>
        )}
      </Grid>
    </>
  );
}
