/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * @date
 * @version
 */
import React from "react";
import * as Material from "@mui/material";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import {
  GetAvailableProducts,
  LinkCrateAndProduct,
} from "../../Pages/Redux/Actions/SKUProductDetailsAction";
import CustomConsole from "../../CustomConsole";
function LinkCrateDialog(props) {
  const dispatch = useDispatch();
  const [selectedCrate, setSelectedCrate] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState("");
  CustomConsole(selectedCrate);
  CustomConsole(selectedProduct);
  //function for handling crate dialog open close operation
  const handleCancle = () => {
    props.setOpenLinkCrateDialog(false);
  };

  //function for handling crate change operation
  const handleChangeCrate = (event) => {
    CustomConsole(event.target.value);
    setSelectedCrate(event.target.value);
  };

  const handleChangeProduct = (event) => {
    CustomConsole(event.target.value);
    setSelectedProduct(event.target.value);
  };

  const handelLinkProduct = () => {
    const reqObj = {
      crate_id: selectedCrate.id,
      crate_name: selectedCrate.name,
      product_id: selectedProduct.id,
      product_capacity: selectedProduct.capacity,
      product_material: selectedProduct.material,
    };
    dispatch(LinkCrateAndProduct(reqObj));
    setTimeout(() => {
      dispatch(GetAvailableProducts());
    }, [1000]);
    handleCancle();
    setSelectedCrate("");
    setSelectedProduct("");
  };

  CustomConsole(props.productAvailableDetails);
  CustomConsole(props.crateDetails);
  return (
    <Material.Box>
      <Material.Dialog open={props.openLinkCrateDialog}>
        <Material.DialogTitle>
          <Material.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
            LINK CRATE WITH PRODUCT
          </Material.Typography>
        </Material.DialogTitle>
        <Material.DialogContent>
          <Material.Box
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl variant="filled" sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-filled-label">
                Select Product*
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedProduct}
                onChange={handleChangeProduct}
                size="small"
              >
                {props.productAvailableDetails?.map((data, index) => (
                  <MenuItem value={data.product_details} key={index}>
                    <div>
                      <span>
                        {" "}
                        <strong>{data.product_details.name}</strong>
                      </span>
                      <br />
                      <small>
                        Capacity:
                        {data.product_details.capacity}/
                      </small>
                      <small>
                        Material:
                        {data.product_details.material}
                      </small>
                    </div>
                  </MenuItem>
                ))}{" "}
              </Select>
            </FormControl>

            <FormControl variant="filled" sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-filled-label">
                Select Crate*
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedCrate}
                onChange={handleChangeCrate}
                size="small"
              >
                {props.crateDetails?.map((data, index) => (
                  <MenuItem value={data} key={index}>
                    {data.name}
                  </MenuItem>
                ))}{" "}
              </Select>
            </FormControl>
          </Material.Box>
        </Material.DialogContent>
        <Material.DialogActions>
          <Material.Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleCancle}
          >
            Close
          </Material.Button>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handelLinkProduct}
          >
            Link CRATE
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </Material.Box>
  );
}

export default LinkCrateDialog;
