import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  wfsDetails: [],
  wfsBatchDetails: [],
  createBatchError: true,
  editBatchError: true,
  cancelBatchError: true,
  groupProductError: true,
  linkProductError: true,
  emptyCanError: true,
  emptyCanDetails: [],
  completeOrderError: true,
  editOrderError: true,
  acceptError: true,
  rejectError: true,
  assignAgentError: true,
  editWareHouseError: true,
};

const SkuWfsDetailsReduser = (state = initialState, action) => {
  switch (action.type) {
    case actionType.WFS_DETAILS_SKU:
      return {
        ...state,
        error: action.payload.error,
        wfsDetails: action.payload.data,
      };
    case actionType.WFS_BATCH_DETAILS_SKU:
      return {
        ...state,
        error: action.payload.error,
        wfsBatchDetails: action.payload.data,
      };
    case actionType.CREATE_WFS_BATCH_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        createBatchError: action.payload.error,
      };
    case actionType.EDIT_WFS_BATCH_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        editBatchError: action.payload.error,
      };
    case actionType.CANCEL_WFS_BATCH_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        cancelBatchError: action.payload.error,
      };
    case actionType.GROUP_PRODUCT_WFS_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        groupProductError: action.payload.error,
      };
    case actionType.CANCEL_GROUP_PRODUCT_WFS_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
      case actionType.CANCEL_GROUPING_PRODUCT:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
        };
    case actionType.LINK_QRLESS_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        linkProductError: action.payload.error,
      };
    case actionType.WFS_EMPTY_CAN_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        emptyCanError: action.payload.error,
      };
    case actionType.WFS_EMPTYCAN_DETAILS_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        emptyCanDetails: action.payload.data,
      };
    case actionType.WFS_COMPLETE_ORDER_OTP:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        completeOrderError: action.payload.error,
      };
    case actionType.EDIT_WFS_EMPTYCAN_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        editOrderError: action.payload.error,
      };
    case actionType.WFS_EMPTY_CAN_ACCEPT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        acceptError: action.payload.error,
      };
    case actionType.WFS_EMPTY_CAN_REJECT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        rejectError: action.payload.error,
      };
    case actionType.ASIGN_EMPTYCAN_AGENT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        assignAgentError: action.payload.error,
      };
    case actionType.EDIT_WAREHOUSE_EMPTYCAN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        editWareHouseError: action.payload.error,
      };
    default:
      return state;
  }
};

export default SkuWfsDetailsReduser;
