import { Box } from "@mui/system";
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import { useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { useSelector } from "react-redux";
import { PutWfsQrlinkAction } from "../Redux/Actions/wfsOnGoingBatchAction";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";

function WfsOngoingBatchCardDialog({
  showingbatchDialogFlag,
  // handleClose,
  setShowingbatchDialogFlag,
  setScanQrcodebtn,
  scanQrcodebtn,
}) {
  CustomConsole(showingbatchDialogFlag);

  const handleClickNo = () => {
    CustomConsole("-----");
    setShowingbatchDialogFlag(false);
  };

  const handleQrYes = () => {
    setScanQrcodebtn(!scanQrcodebtn);
    setShowingbatchDialogFlag(false);
  };

  //    React.useEffect(()=>{
  //     // if(scanQrCodeDetails !==""){
  //     //     dispatch(PutWfsQrlinkAction(scanQrCodeDetails));
  //         setScanQrcodebtn(true)
  //         // setScanQrCodeDetails("")
  //         setShowingbatchDialogFlag(true)
  //     // }
  //    },[scanQrCodeDetails])

  // const handleClose = () => {
  //     setShowingbatchDialogFlag(false)
  // }

  return (
    <Box>
      <Dialog
        open={showingbatchDialogFlag}
        onClose={showingbatchDialogFlag}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start Linking Containers to Batch
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClickNo}>
            No
          </Button>
          <Button variant="outlined" onClick={handleQrYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Box>
            {scanQrcodebtn === true ? (
                <Html5QrcodePlugin1
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResultQrCode}
                />
            ) : ("")}
            <ResultContainerPlugin1
                results={decodedResults}
                setScanCount={setScanCount}
            />
            </Box> */}
    </Box>
  );
}
export default WfsOngoingBatchCardDialog;
