import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { GetSuppliersForPincode } from "../Redux/Actions/adminPoTrackPostpaid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReassignSupplierDialog({
  open,
  setOpen,
  setSupplierSelect,
  setSupplierName,
}) {
  const dispatch = useDispatch();
  let supplierDetails = useSelector((state) => state.poTrackDetails.suppliers);
  const [supplierArr, setSupplierArr] = React.useState([]);
  const [selectedSupp, setSelectedSupp] = React.useState(0);
  const [suppName, setSuppName] = React.useState("");
  const [pincode, setPincode] = React.useState("");

  const handleClose = () => {
    setOpen(false);
    setSupplierSelect((prev) => prev);
  };

  const handleSupplierChange = (data) => {
    setSelectedSupp(() => data.supplier_id);
    setSuppName(() => data.fullname);
  };

  const assignSupplier = () => {
    setSupplierSelect(selectedSupp);
    setSupplierName(suppName);
    setOpen(false);
  };
  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length === 6) {
      dispatch(GetSuppliersForPincode(e.target.value));
   
    }
  };

  React.useEffect(() => {
    CustomConsole("-----Suppliers-----");
    CustomConsole(supplierDetails);
    setSupplierArr(supplierDetails);
  }, [supplierDetails]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Assign Supplier</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Box sx={{ marginTop: "10px" }}>
            <TextField
              id="outlined-basic"
              type="text"
              label="Pincode"
              variant="outlined"
              value={pincode}
              inputProps={{
                maxLength: 6,
              }}
              onChange={handlePincodeChange}
            />
          </Box>
          <Box>
            {supplierArr ? (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  {supplierArr.map((data, index) => (
                    <Card
                      sx={{
                        marginTop: "5px",
                        marginBottom: "3px",
                        backgroundColor:
                          selectedSupp === data.supplier_id
                            ? "#2196f3"
                            : "#EBF1F4",
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "#2196f3",
                        },
                      }}
                      onClick={() => handleSupplierChange(data)}
                      key={index}
                    >
                      <CardContent>
                        <Typography sx={{ fontWeight: "600" }}>
                          Supplier Name: {data.fullname}
                        </Typography>
                        <Typography>Phone Number:{data.phone}</Typography>
                        <Typography>
                          Container to be Delivered:
                          {data.delivery_containers}
                        </Typography>
                        <Typography>
                          Empty container with distributor:
                          {data.empty_container_qty}
                        </Typography>
                        <Typography>
                          Filled container with distributor:
                          {data.filled_container_qty}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </FormControl>
              </Box>
            ) : (
              " "
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {selectedSupp !== 0 ? (
          <Button onClick={assignSupplier}>Assign</Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default ReassignSupplierDialog;
