import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Paper, Switch, TextField } from "@mui/material";
import * as Material from "@mui/material";
import Divider from "@mui/material/Divider";
import CustomConsole from "../../CustomConsole";
import {
  ordersStatus,
  ADMIN_ROLES,
  deliveryStatus,
} from "../../Components/AppMeta/appMetaConfig";
import Editdistibuter from "./Editdistibuter";
import {
  CansRollBackForOrder,
  GetCansRollBack,
} from "../Redux/Actions/userDetailsAdminAction";
import { PutCansRollBack } from "../Redux/Actions/userDetailsAdminAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  AddDeliverySlots,
  ChangeDeliveryDate,
  GetNewSearchOrderDetails,
  GetOrderDeliverySlots,
  GetViewOrderDetails,
} from "../Redux/Actions/ordersAdminAction";
// import moment from "moment";
import moment from "moment-timezone";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ConstructionOutlined } from "@mui/icons-material";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const styles = {
  typographyStylesHeading: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  typographyStylesRes: {
    fontSize: "13px",
  },
};
const orderStatus = (order_status) => {
  switch (order_status) {
    case ordersStatus.ORDER_STATUS_NEW_REQUEST:
      return "NEW REQUEST";
    case ordersStatus.ORDER_STATUS_CANCELLED:
      return "CANCELLED";
    case ordersStatus.ORDER_STATUS_ACCEPTED:
      return "ACCEPTED ";
    case ordersStatus.ORDER_STATUS_REJECTED:
      return "REJECTED";
    case ordersStatus.ORDER_STATUS_DELIVERED:
      return "DELIVERED";
    default:
      return "";
  }
};
export default function OrderDetailsPaper({
  orderDetails,
  newOrderDetails,
  setpageReferesh,
  adminRole,
  updateDeliveryFuncCall,
  setOrderFlag,
  isOrderStarted,
  setIsOrderStarted,
  editBtnFlag,
  seteditBtnFlag,
}) {
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);
  const [orderedDate, setOrderedDate] = React.useState("");
  const [orderedTime, setOrderedTime] = React.useState("");
  const [deliveryOn, setDeliveryOn] = React.useState("");
  const [switchRollBacks, setSwitchRollBacks] = React.useState(true);
  const [isrevertbackenableds, setIsrevertbackenableds] = React.useState(false);
  const [switchChangeRoll, setSwitchChangeRoll] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [orderId, setOrderId] = React.useState("");
  const dispatch = useDispatch();
  // const [editBtnFlag, seteditBtnFlag] = React.useState(false);
  const [deliverySlotFrom, setDeliverySlotFrom] = React.useState("");
  const [deliverySlotTo, setDeliverySlotTo] = React.useState("");
  const [deliverySlotFromRailway, setDeliverySlotFromRailway] =
    React.useState("");
  const [deliverySlotToRailway, setDeliverySlotToRailway] = React.useState("");
  const [startFromAMPM, setStartFromAMPM] = React.useState("");
  const [endFromAMPM, setEndFromAMPM] = React.useState("");
  const [fromTimeError, setFromTimeError] = React.useState(false);
  const [toTimeError, setToTimeError] = React.useState(false);
  const [timeDifference, setTimeDifference] = React.useState(0);
  const [selectedSlot, setSelectedSlot] = React.useState("");
  const [changeSlot, setChangeSlot] = React.useState(false);
  // const [isOrderStarted, setIsOrderStarted] = React.useState(false);
  const [dateToastFlag, setDateChangeFlag] = React.useState(false);
  const dateMin = moment().add(1, "day").format("DD MMMM YYYY");

  const dateMinPostpaid = moment();
  var date = moment();
  var maxDate = moment(date).add(7, "d");
  const [changeDeliveryDate, setChangeDeliveryDate] = React.useState(
    moment().add(1, "day").format("DD MMMM YYYY")
  );
  const handleChangeDeliveryDate = (newValue) => {
    if (newValue !== null && newValue !== null) {
      setChangeDeliveryDate(moment(newValue.$d).format("DD MMMM YYYY"));
    } else {
      setChangeDeliveryDate(null);
    }
  };
  const changeDeliveryDispatch = () => {
    if (changeDeliveryDate) {
      CustomConsole(changeDeliveryDate);
      let reqObj = {
        order_id: newOrderDetails[0]?.order_id,
        delivery_date: changeDeliveryDate,
      };
      CustomConsole(reqObj);
      dispatch(ChangeDeliveryDate(reqObj));
      setDateChangeFlag(true);
    } else {
      toast.error("Choose a date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handelEditBtn = () => {
    seteditBtnFlag(true);
  };
  React.useEffect(() => {
    CustomConsole(newOrderDetails);
    let orderedOn = moment
      .utc(newOrderDetails[0]?.order_date)
      .format("DD-MM-YYYY");
    CustomConsole(orderedOn);
    setOrderedDate(orderedOn);
    const date = moment.utc(newOrderDetails[0]?.order_date);
    const time = date.local().format("LT");
    CustomConsole(time);
    setOrderedTime(time);
    let momentEndDate = moment
      .utc(newOrderDetails[0]?.end_date, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    CustomConsole(momentEndDate);
    setDeliveryOn(momentEndDate);
  }, [newOrderDetails]);
  React.useEffect(() => {
    CustomConsole(
      "----------------------------Ordered date------------------------------"
    );
    CustomConsole(orderedDate);
  }, [orderedDate]);
  React.useEffect(() => {
    CustomConsole(
      "-------------------------Delivery slots--------------------------"
    );
    CustomConsole(orderDetailsData);
    CustomConsole(orderStatus(newOrderDetails[0]?.order_status));
    // if (orderDetailsData.orderDetails) {
    //   setIsOrderStarted(
    //     orderDetailsData.orderDetails.packaged_drinking_water.is_order_started
    //   );
    // }
    if (!orderDetailsData.error && dateToastFlag) {
      toast.success(orderDetailsData.deliveryDateChangeMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setDateChangeFlag(false);
    }
  }, [orderDetailsData]);
  // const handleRollBackSwitch = (event) => {
  //   const reqobj = {
  //     user_id: userId,
  //     is_revert_back_enabled: isrevertbackenableds,
  //   };
  //   CustomConsole(isrevertbackenableds);
  //   CustomConsole(userId);
  //   setSwitchChangeRoll(true);
  //   setIsrevertbackenableds(true);
  //   setUserId(newOrderDetails[0]?.delivery_address.user_id);
  //   if (event.target.checked) {
  //     setSwitchRollBacks(true);
  //     dispatch(GetCansRollBack(userId));
  //     dispatch(PutCansRollBack(reqobj));
  //     setIsrevertbackenableds(false);
  //   } else if (event.target.checked === false) {
  //     setSwitchRollBacks(false);
  //     dispatch(GetCansRollBack(userId));
  //     dispatch(PutCansRollBack(reqobj));
  //     setIsrevertbackenableds(true);
  //   }
  // };
  const handleClickRollBack = () => {
    const reqObj = {
      order_id: newOrderDetails[0]?.order_id,
    };
    dispatch(CansRollBackForOrder(reqObj));
  };
  React.useEffect(() => {
    CustomConsole(newOrderDetails);
    setUserId(newOrderDetails[0]?.delivery_address.user_id);
  }, [newOrderDetails]);
  const handleChangeFromTime = (event) => {
    CustomConsole(event.target.value);
    setDeliverySlotFrom(event.target.value);
    CustomConsole(startFromAMPM);
  };
  function convertToRailwayTime(timeString) {
    CustomConsole(timeString);
    const [hours, minutes] = timeString.split(" ")[0].split(":");
    const suffix = timeString.split(" ")[1];
    let railwayHours = parseInt(hours);
    if (suffix === "PM" && railwayHours !== 12) {
      railwayHours += 12;
    } else if (suffix === "AM" && railwayHours === 12) {
      railwayHours = 0;
    }
    const railwayTimeString = `${railwayHours}:${minutes} ${suffix}`;
    CustomConsole(railwayTimeString);
    CustomConsole(`Railway time : ${railwayHours}`);
    setDeliverySlotFrom(railwayHours);
    setDeliverySlotTo(railwayHours);
    // return railwayTimeString;
  }
  React.useEffect(() => {
    CustomConsole(`deliverySlotFrom : ${deliverySlotFrom}`);
    CustomConsole(`deliverySlotTo : ${deliverySlotTo}`);
    CustomConsole(`deliverySlotFromRailway : ${deliverySlotFromRailway}`);
    CustomConsole(`deliverySlotToRailway : ${deliverySlotToRailway}`);
    if (startFromAMPM === "PM" && endFromAMPM === "PM") {
      const difference =
        parseInt(deliverySlotToRailway) - parseInt(deliverySlotFromRailway);
      CustomConsole(`Difference: ${difference}`);
      setTimeDifference(difference);
    } else if (startFromAMPM === "AM" && endFromAMPM === "AM") {
      const difference = parseInt(deliverySlotTo) - parseInt(deliverySlotFrom);
      CustomConsole(`Difference: ${difference}`);
      setTimeDifference(difference);
    } else if (startFromAMPM === "AM" && endFromAMPM === "PM") {
      const difference =
        parseInt(deliverySlotToRailway) - parseInt(deliverySlotFrom);
      CustomConsole(`Difference: ${difference}`);
      setTimeDifference(difference);
    } else if (startFromAMPM === "PM" && endFromAMPM === "AM") {
      const difference =
        parseInt(deliverySlotTo) - parseInt(deliverySlotFromRailway);
      CustomConsole(`Difference: ${difference}`);
      setTimeDifference(difference);
    }
  }, [
    deliverySlotFrom,
    deliverySlotTo,
    deliverySlotToRailway,
    deliverySlotFromRailway,
  ]);
  const handleChangeToTime = (event) => {
    CustomConsole(event.target.value);
    setDeliverySlotTo(event.target.value);
  };
  React.useEffect(() => {
    if (startFromAMPM === "AM") {
      const regex = /^1[0-1]|[7-9]$|^12$/;
      CustomConsole(regex.test(deliverySlotFrom));
      if (regex.test(deliverySlotFrom)) {
        setFromTimeError(false);
      } else {
        setFromTimeError(true);
      }
    } else if (startFromAMPM === "PM") {
      // const regex = /^[1-7]|1[0-1]$/;
      const regex = /^[1-6]$|^7(?!.)/;
      CustomConsole(regex.test(deliverySlotFrom));
      // convertToRailwayTime(`${deliverySlotFrom}:00 PM`);
      if (regex.test(deliverySlotFrom)) {
        setFromTimeError(false);
        // Converting to railway time
        const timeString = `${deliverySlotFrom}:00 PM`;
        const [hours, minutes] = timeString.split(" ")[0].split(":");
        const suffix = timeString.split(" ")[1];
        let railwayHours = parseInt(hours);
        if (suffix === "PM" && railwayHours !== 12) {
          railwayHours += 12;
        } else if (suffix === "AM" && railwayHours === 12) {
          railwayHours = 0;
        }
        const railwayTimeString = `${railwayHours}:${minutes} ${suffix}`;
        CustomConsole(railwayTimeString);
        CustomConsole(`Railway time : ${railwayHours}`);
        // setDeliverySlotFrom(railwayHours);
        setDeliverySlotFromRailway(railwayHours);
      } else {
        setFromTimeError(true);
      }
    }
  }, [startFromAMPM, deliverySlotFrom]);
  React.useEffect(() => {
    if (endFromAMPM === "AM") {
      // const regex = /^(1[0-1]|[8-9])$/;
      const regex = /^(?:8|9|10|11)$/;
      CustomConsole(regex.test(deliverySlotTo));
      if (regex.test(deliverySlotTo)) {
        setToTimeError(false);
      } else {
        setToTimeError(true);
      }
    } else if (endFromAMPM === "PM") {
      // const regex = /^[1-9]|10$/;
      const regex = /^[1-9]$|^10(?!.)/;
      CustomConsole(regex.test(deliverySlotTo));
      // convertToRailwayTime(`${deliverySlotTo}:00 PM`);
      if (regex.test(deliverySlotTo)) {
        setToTimeError(false);
        // Converting to railway time
        const timeString = `${deliverySlotTo}:00 PM`;
        const [hours, minutes] = timeString.split(" ")[0].split(":");
        const suffix = timeString.split(" ")[1];
        let railwayHours = parseInt(hours);
        if (suffix === "PM" && railwayHours !== 12) {
          railwayHours += 12;
        } else if (suffix === "AM" && railwayHours === 12) {
          railwayHours = 0;
        }
        const railwayTimeString = `${railwayHours}:${minutes} ${suffix}`;
        CustomConsole(railwayTimeString);
        CustomConsole(`Railway time : ${railwayHours}`);
        // setDeliverySlotTo(railwayHours);
        setDeliverySlotToRailway(railwayHours);
      } else {
        setToTimeError(true);
      }
    }
  }, [endFromAMPM, deliverySlotTo]);
  const handleUpdateSlots = () => {
    const reqObj = {
      order_id: newOrderDetails[0]?.order_id,
      delivery_slot_timings: `${deliverySlotFrom} ${startFromAMPM}-${deliverySlotTo} ${endFromAMPM}`,
      // supplier_id: orderDetails.supplier_id,
    };
    CustomConsole(reqObj);
    if (timeDifference < 3 && timeDifference > 0) {
      dispatch(AddDeliverySlots(reqObj));
    } else {
      toast.error("Time differance should be less than three hours", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleChangeDeliverySlot = (event) => {
    CustomConsole(event.target.value);
    setSelectedSlot(event.target.value);
  };
  const handleClickChangeSlot = () => {
    setChangeSlot(!changeSlot);
  };
  const handleUpdateDeliverySlot = () => {
    const reqObj = {
      order_id: newOrderDetails[0]?.order_id,
      delivery_slot_timings: selectedSlot,
      supplier_id: newOrderDetails[0]?.supplier_id,
    };
    dispatch(AddDeliverySlots(reqObj));
    setTimeout(() => {
      // dispatch(GetViewOrderDetails(newOrderDetails[0]?.order_id));
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 30,
        page_number: 1,
        order_ids: [newOrderDetails[0]?.order_id],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
      updateDeliveryFuncCall();
    }, 1000);
  };
  return (
    <>
      {
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              fontWeight: "bold",
              flexWrap: "wrap",
            }}
          >
            <Paper sx={{ height: "340px", width: "500px" }} elevation={20}>
              <Box>
                {orderDetailsData.deliveries[0] &&
                orderDetailsData.deliveries[0].delivery_otp ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      OTP :{" "}
                    </Material.Typography>

                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      {newOrderDetails[0]?.delivery_otp}
                    </Material.Typography>
                  </Box>
                ) : null}

                {newOrderDetails[0]?.packaged_drinking_water !== undefined &&
                newOrderDetails[0]?.packaged_drinking_water.delivery_slots !==
                  undefined &&
                newOrderDetails[0]?.packaged_drinking_water.delivery_slots !==
                  null &&
                newOrderDetails[0]?.packaged_drinking_water.delivery_slots ? (
                  <>
                    <Box>
                      {Object.keys(
                        newOrderDetails[0]?.packaged_drinking_water
                          .delivery_slots
                      ).length ? (
                        <Box
                          sx={{
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            marginBottom: "10px",
                            // marginTop: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "#152057",
                              color: "white",
                              // paddingTop: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                // marginBottom: "10px",
                                padding: "5px",
                              }}
                            >
                              Selected Delivery Slots
                            </Typography>
                          </Box>
                          <Box sx={{ padding: "10px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Material.Typography
                                sx={{
                                  display: "flex",
                                  textAlign: "start",
                                  color: "blue",
                                  fontWeight: "bold",
                                }}
                              >
                                Consumer selected :{" "}
                              </Material.Typography>

                              <Material.Typography
                                sx={{
                                  display: "flex",
                                  textAlign: "end",
                                  fontWeight: "bold",
                                }}
                              >
                                {newOrderDetails[0]?.packaged_drinking_water
                                  .delivery_slots
                                  .consumer_selected_delivery_slot !== ""
                                  ? newOrderDetails[0]?.packaged_drinking_water
                                      .delivery_slots
                                      .consumer_selected_delivery_slot
                                  : "None"}
                              </Material.Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Material.Typography
                                sx={{
                                  display: "flex",
                                  textAlign: "start",
                                  color: "blue",
                                  fontWeight: "bold",
                                }}
                              >
                                Admin selected :{" "}
                              </Material.Typography>

                              <Material.Typography
                                sx={{
                                  display: "flex",
                                  textAlign: "end",
                                  fontWeight: "bold",
                                }}
                              >
                                {newOrderDetails[0]?.packaged_drinking_water
                                  .delivery_slots
                                  .admin_selected_delivery_slot !== ""
                                  ? newOrderDetails[0]?.packaged_drinking_water
                                      .delivery_slots
                                      .admin_selected_delivery_slot
                                  : "None"}
                              </Material.Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Material.Typography
                                sx={{
                                  display: "flex",
                                  textAlign: "start",
                                  color: "blue",
                                  fontWeight: "bold",
                                }}
                              >
                                Distributor selected :{" "}
                              </Material.Typography>

                              <Material.Typography
                                sx={{
                                  display: "flex",
                                  textAlign: "end",
                                  fontWeight: "bold",
                                }}
                              >
                                {newOrderDetails[0]?.packaged_drinking_water
                                  .delivery_slots
                                  .supplier_selected_delivery_slot !== ""
                                  ? newOrderDetails[0]?.packaged_drinking_water
                                      .delivery_slots
                                      .supplier_selected_delivery_slot
                                  : "None"}
                              </Material.Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Box>
                              {ordersStatus.ORDER_STATUS_ACCEPTED ===
                                newOrderDetails[0]?.order_status && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={handleClickChangeSlot}
                                      sx={appColors.commonBtnColor}
                                    >
                                      Change Delivery slot
                                    </Button>
                                  </Box>
                                  {changeSlot === true ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Box>
                                        <FormControl
                                          sx={{ m: 1, minWidth: 190 }}
                                          size="small"
                                        >
                                          <InputLabel id="demo-select-small">
                                            Choose delivery slot
                                          </InputLabel>
                                          <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={selectedSlot}
                                            label="Choose delivery slot"
                                            onChange={handleChangeDeliverySlot}
                                          >
                                            {orderDetailsData.slotsList.map(
                                              (slot) => {
                                                return (
                                                  <MenuItem
                                                    value={`${slot.startTimeSlot}-${slot.endTimeSlot}`}
                                                  >
                                                    {slot.startTimeSlot}-
                                                    {slot.endTimeSlot}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                      {selectedSlot !== "" ? (
                                        <Box>
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleUpdateDeliverySlot}
                                            sx={appColors.commonBtnColor}
                                          >
                                            Update
                                          </Button>
                                        </Box>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              )}
                            </Box>
                            <Box>
                              {newOrderDetails[0]?.order_status === 200 &&
                              newOrderDetails[0]?.packaged_drinking_water
                                ?.order_type !== 700 ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "5px",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                >
                                  {" "}
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DesktopDatePicker
                                      minDate={
                                        newOrderDetails[0]?.payment_type === 300
                                          ? dateMinPostpaid
                                          : dateMin
                                      }
                                      maxDate={maxDate._d}
                                      label="Change Delivery Date"
                                      inputFormat="DD/MM/YYYY"
                                      value={changeDeliveryDate}
                                      onChange={handleChangeDeliveryDate}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          {...params}
                                          inputProps={{
                                            ...params.inputProps,
                                            readOnly: true,
                                          }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  <Button
                                    sx={{
                                      ...appColors.commonBtnColor,
                                      color: "whitesmoke",
                                    }}
                                    onClick={changeDeliveryDispatch}
                                  >
                                    Change Date
                                  </Button>
                                </Box>
                              ) : null}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ padding: "10px" }}>
                          <Typography sx={{ textAlign: "center" }}>
                            Distributor has not choosen delivery slot.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ textAlign: "center" }}>
                      Distributor has not choosen delivery slot.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
            {/* <Paper sx={{ margin: "10px" }}>
              <Box>
                <Box
                  sx={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginBottom: "10px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Delivery Address
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Delivery Contact Name :{" "}
                    </Material.Typography>

                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      {orderDetails.delivery_address.contact_name}
                    </Material.Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Address :{" "}
                    </Material.Typography>

                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      {`${orderDetails.delivery_address.address_line1}, ${orderDetails.delivery_address.address_line2}, ${orderDetails.delivery_address.address_line3}`}
                    </Material.Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Pincode :{" "}
                    </Material.Typography>

                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      {orderDetails.delivery_address.pincode}
                    </Material.Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Service Area :{" "}
                    </Material.Typography>

                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      {orderDetails.delivery_address.service_area}
                    </Material.Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Contact Number :{" "}
                    </Material.Typography>

                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                      }}
                    >
                      {orderDetails.delivery_address.contact_phone}
                    </Material.Typography>
                  </Box>
                </Box>
              </Box>
            </Paper> */}
            {/* {orderDetailsData?.orderDetails?.order_status === 400 ? (
              ""
            ) : ( */}
            {/* <Paper sx={{ margin: "10px" }}> */}
            {/* <Box> */}
            {/* {orderDetails.payment_status === 100 &&
                  orderDetailsData?.orderDetails?.order_status === 400 &&
                  orderDetails.order_status ===
                    ordersStatus.ORDER_STATUS_NEW_REQUEST ? (
                    " "
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: "20px",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Order RollBack Cans
                      </Typography>
                      <FormControlLabel
                        label="Enable"
                        control={<Switch checked={switchRollBacks} />}
                        onChange={handleRollBackSwitch}
                      />
                    </Box>
                  )} */}
            {/* <Box>
                    {orderDetailsData.deliveries[0] &&
                    orderDetailsData.deliveries[0].delivery_otp ? (
                      <Box
                        style={{
                          color: "green",
                          fontSize: "13px",
                          fontWeight: 600,
                        }}
                      >
                        OTP:{orderDetailsData.deliveries[0].delivery_otp}
                      </Box>
                    ) : null}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        gap: "10px",
                        alignItems: "center",
                        margin: "10px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Supplier Name:
                        <span style={{ fontWeight: "bold" }}>
                          {orderDetails.supplier_name}
                        </span>
                      </Typography>
                      {orderStatus(orderDetails.order_status) === "ACCEPTED " &&
                      !isOrderStarted &&
                      (ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                        ADMIN_ROLES.BW_ADMIN === adminRole ||
                        ADMIN_ROLES.BW_CSHEAD === adminRole ||
                        ADMIN_ROLES.BW_SALESHEAD === adminRole ||
                        ADMIN_ROLES.BW_OPSHEAD === adminRole ||
                        ADMIN_ROLES.BW_CS === adminRole ||
                        ADMIN_ROLES.BW_OPS === adminRole) ? (
                        <Box>
                          <Button
                            variant="contained"
                            onClick={handelEditBtn}
                            size="small"
                            sx={appColors.commonBtnColor}
                          >
                            Reassign
                          </Button>
                        </Box>
                      ) : (
                        " "
                      )}
                      {orderDetailsData?.orderDetails?.order_status !== 400 && (
                        <Button>
                          <RefreshIcon
                            onClick={() =>
                              dispatch(
                                GetViewOrderDetails(orderDetails.order_id)
                              )
                            }
                          ></RefreshIcon>
                        </Button>
                      )}
                    </Box>
                  </Box> */}
            {/* <Box> */}
            {/* <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}> */}
            {/* <Box>
                        {ordersStatus.ORDER_STATUS_ACCEPTED ===
                          orderDetails.order_status && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <Box>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={handleClickChangeSlot}
                                sx={appColors.commonBtnColor}
                              >
                                Change Delivery slot
                              </Button>
                            </Box>
                            {changeSlot === true ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Box>
                                  <FormControl
                                    sx={{ m: 1, minWidth: 190 }}
                                    size="small"
                                  >
                                    <InputLabel id="demo-select-small">
                                      Choose delivery slot
                                    </InputLabel>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-select-small"
                                      value={selectedSlot}
                                      label="Choose delivery slot"
                                      onChange={handleChangeDeliverySlot}
                                    >
                                      {orderDetailsData.slotsList.map(
                                        (slot) => {
                                          return (
                                            <MenuItem
                                              value={`${slot.startTimeSlot}-${slot.endTimeSlot}`}
                                            >
                                              {slot.startTimeSlot}-
                                              {slot.endTimeSlot}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                                {selectedSlot !== "" ? (
                                  <Box>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={handleUpdateDeliverySlot}
                                      sx={appColors.commonBtnColor}
                                    >
                                      Update
                                    </Button>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        )}
                      </Box> */}

            {/* {orderDetailsData?.orderDetails?.order_status !== 400 && (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // padding: "10px",
                            }}
                          >
                            <Button
                              onClick={handleClickRollBack}
                              size="small"
                              variant="contained"
                              sx={appColors.commonBtnColor}
                            >
                              Revert/roll back cans
                            </Button>
                          </Box>
                        </Box>
                      )} */}
            {/* </Box> */}
            {/* <Box>
                  {orderDetailsData?.orderDetails?.order_status === 200 &&
                  orderDetailsData?.orderDetails?.packaged_drinking_water
                    ?.order_type !== 700 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "1em",
                        marginBottom: "1em",
                      }}
                    >
                      {" "}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          minDate={
                            orderDetails.payment_type === 300
                              ? dateMinPostpaid
                              : dateMin
                          }
                          maxDate={maxDate._d}
                          label="Change Delivery Date"
                          inputFormat="DD/MM/YYYY"
                          value={changeDeliveryDate}
                          onChange={handleChangeDeliveryDate}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Button
                        sx={{
                          ...appColors.commonBtnColor,
                          color: "whitesmoke",
                        }}
                        onClick={changeDeliveryDispatch}
                      >
                        Change Date
                      </Button>
                    </Box>
                  ) : null}
                </Box> */}
            {/* </Box> */}
            {/* </Box> */}
            {/* </Paper> */}
            {/* )} */}
          </Box>
        </Box>
      }
      {editBtnFlag ? (
        <>
          <Editdistibuter
            seteditBtnFlag={seteditBtnFlag}
            editBtnFlag={editBtnFlag}
            orderDetails={orderDetails}
            newOrderDetails={newOrderDetails}
            setOrderFlag={setOrderFlag}
            setpageReferesh={setpageReferesh}
          />
        </>
      ) : (
        " "
      )}
    </>
  );
}
