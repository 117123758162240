import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  Box,
  Button,
} from "@mui/material";
import moment from "moment";
import React from "react";

function DateRangeFilterDialog(props) {
  const [fromDate, setFromDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));
  const handleCloseDateRange = () => {
    props.handleCloseDateRange();
  };

  const handleGetDateRange = () => {
    props.handleGetDateRange({ fromDate: fromDate, toDate: toDate });
  };
  return (
    <Dialog open={props.openDateRangeFilterDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
          <Button
            size="small"
            variant="contained"
            color="error"
            sx={{ minWidth: "10px" }}
            onClick={handleCloseDateRange}
          >
            <Close size="small" />
          </Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">
              From date
            </InputLabel>
            <Input
              value={fromDate}
              onChange={(event) => setFromDate(event.target.value)}
              type="date"
              startAdornment={<InputAdornment position="start" />}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">To date</InputLabel>
            <Input
              value={toDate}
              onChange={(event) => setToDate(event.target.value)}
              type="date"
              startAdornment={<InputAdornment position="start" />}
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={handleGetDateRange}>
            Get Data
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DateRangeFilterDialog;
