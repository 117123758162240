/**
 * @author vinay kumar
 * @description  Consomer Delivery planning 
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.40
 * @Date 04-08-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import AllOdersToDeliveriesCad from "../../Components/Cards/OrdersToDeliverCards/AllOdersToDeliveriesCad";
import PincodeBaseDeliveries from "../../Components/Cards/OrdersToDeliverCards/PincodeBaseDeliveries";
import DeliverysData from "./DeliverysData";
import PincodeBaseOrders from "./PincodeBaseOrders";

function ConsumerDeliveriesMain() {
  return (
    <Material.Box sx={{ marginTop: "60px" }}>
      {/* <Material.Box sx={{ margin: "10px" }}>
        <DeliverysData />
      </Material.Box>
      <Material.Box sx={{ margin: "10px" }}>
        <PincodeBaseOrders/>
      </Material.Box> */}
      <Material.Grid container spacing={2}>
        <Material.Grid md={6}>
          <Material.Box sx={{ margin: "10px" }}>
            <DeliverysData />
          </Material.Box>
          <Material.Box sx={{ margin: "10px" }}>
            <PincodeBaseOrders />
          </Material.Box>
        </Material.Grid>
      </Material.Grid>
    </Material.Box>
  );
}

export default ConsumerDeliveriesMain;
