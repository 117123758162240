import { Box, Dialog, DialogContent, TextField, Button } from "@mui/material";
import React from "react";

function WithdrawalRequestBehalfConsumerDialog(props) {
  const [enterQtyRequest, setEnterQtyRequest] = React.useState(
    props.expiredCansWithConsumer ? props.expiredCansWithConsumer : 0
  );
  const handleCloseDialogReturnRequest = () => {
    props.handleCloseDialogReturnRequest();
  };
  const handleSubmitDialogReturnRequest = () => {
    props.handleSubmitDialogReturnRequest(enterQtyRequest);
  };

  const handleChangeEnterReturnQty = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setEnterQtyRequest(() => event.target.value);
  };
  return (
    <Dialog
      open={props.openWithdrawalRequestDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        },
      }}
    >
      <DialogContent>
        Enter Return Can Request Quantity
        <Box sx={{ mt: 2 }}>
          <TextField
            size="small"
            label="Enter Quantity"
            autoFocus
            fullWidth
            value={enterQtyRequest}
            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangeEnterReturnQty, // Attach the input event handler
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-evenly",
            // gap: "40px",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseDialogReturnRequest}
          >
            close
          </Button>
          <Button variant="contained" onClick={handleSubmitDialogReturnRequest}>
            submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default WithdrawalRequestBehalfConsumerDialog;
