import React from "react";
import CustomConsole from "../../CustomConsole";
import { styles } from "../wfsCanOrders/cardStyles";
import { Orderstyles } from "../AdminWFSCanOrders/cardStyles";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Paper,
  Button,
} from "@mui/material";
import { BorderColor } from "@mui/icons-material";
import EditReturnCanOrderDialog from "../AdminWithdrawalHistory/EditReturnCanOrderDialog";
import {
  GetWithdrawallist,
  PutEditReturnCanOrder,
  PutEditReturnCanOrderDistriToWfs,
  PutWithdrawalDetailsReject,
} from "../Redux/Actions/AdminWithdrawalListAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import moment from "moment";

function DistributorReturnOrderDetails(props) {
  const dispatch = useDispatch();
  CustomConsole(props);
  const [openEditReturnCanDialog, setOpenEditReturnCanDialog] =
    React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  React.useEffect(() => {
    setOrderId(props.data.return_can_order_id);
  }, [props?.data?.return_can_order_id]);

  const handleOpenEditDialog = () => {
    setOpenEditReturnCanDialog(true);
  };
  const handleCloseReturnDialog = () => {
    setOpenEditReturnCanDialog(false);
  };
  const orderStatusFun = (order_status) => {
    CustomConsole(order_status);
    switch (order_status) {
      case returnCanOrderStatus.CONSUMER_ACCEPTED:
        return "CONSUMER_ACCEPTED";
      case returnCanOrderStatus.APPROVED:
        return "ONGOING";
      case returnCanOrderStatus.REFUND:
        return "REFUND";
      case returnCanOrderStatus.DELIVERED:
        return "DELIVERED";
      case returnCanOrderStatus.REJECTED:
        return "CANCELLED";
      case returnCanOrderStatus.PENDING:
        return "PENDING";
      default:
        return "";
    }
  };
  const handleSubmitReturnDialog = (props) => {
    console.log(props);
    const reqObj = {
      product_qty: props.qty,
    };
    CustomConsole(reqObj);
    if (reqObj.product_qty > 0) {
      dispatch(PutEditReturnCanOrderDistriToWfs(orderId, reqObj));
      setOpenEditReturnCanDialog(false);
      setTimeout(() => {
        dispatch(
          GetWithdrawallist({
            page_number: props.pageNumber,
            page_size: props.pageSize,
          })
        );
      }, 1000);
    } else {
      toast.dark("Edit minimum quantity 1", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };

  const handleCancelorder = () => {
    console.log(props.data.return_can_order_id);
    dispatch(PutWithdrawalDetailsReject(props.data.return_can_order_id));
    setTimeout(() => {
      dispatch(
        GetWithdrawallist({
          page_number: props.pageNumber,
          page_size: props.pageSize,
        })
      );
    }, 1000);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "20px",
      }}
    >
      <Paper elevation={24}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={Orderstyles.orderCardBox}>
            <Card sx={Orderstyles.orderCard}>
              <Box sx={Orderstyles.orderCardHearder}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box sx={Orderstyles.BoxInsideContent}>
                    <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                      Order ID: {props.data.return_can_order_id}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <CardContent
                //   onClick={() => handelDetailClick()}
                sx={{ cursor: "pointer" }}
              >
                <Box
                  sx={{
                    marginBottom: ".5rem",
                    borderBottom: "2px solid gray",
                    borderBottomStyle: "dashed",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography sx={Orderstyles.TypograpyCommon1}>
                      Quantity:{" "}
                      <span style={{ color: "#068fff" }}>
                        {props.data.container_qty}
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginBottom: ".5rem",
                    justifyContent: "space-between",
                    borderBottom: "2px solid gray",
                    borderBottomStyle: "dashed",
                    textAlign: "start",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Status:
                    <span style={{ color: "#068fff" }}>
                      {orderStatusFun(props.data.order_status)}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginBottom: ".5rem",
                    justifyContent: "space-between",
                    borderBottom: "2px solid gray",
                    borderBottomStyle: "dashed",
                    textAlign: "start",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Product:{" "}
                    <span style={{ color: "#068fff" }}>
                      {props.data.container_capacity}-
                      {props.data.container_material}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginBottom: ".5rem",
                    justifyContent: "space-between",
                    borderBottom: "2px solid gray",
                    borderBottomStyle: "dashed",
                    textAlign: "start",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Otp:{" "}
                    <span style={{ color: "#068fff" }}>
                      {props.data.return_can_otp}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginBottom: ".5rem",
                    justifyContent: "space-between",
                    borderBottom: "2px solid gray",
                    borderBottomStyle: "dashed",
                    textAlign: "start",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Created At:{" "}
                    <span style={{ color: "#068fff" }}>
                      {/* {moment
                        .utc(props.data.created_at)
                        .tz("Asia/Kolkata")
                        .add(5, "hours")
                        .add(30, "minutes")
                        .format("DD-MM-YYYY HH:mm")} */}
                      {props.data.created_at}
                    </span>
                  </Typography>
                </Box>
                {props.data.order_status === returnCanOrderStatus.PENDING ||
                props.data.order_status === returnCanOrderStatus.APPROVED ? (
                  <Box
                    sx={{
                      marginBottom: ".5rem",
                      justifyContent: "space-between",
                      // borderBottom: "2px solid gray",
                      // borderBottomStyle: "dashed",
                      textAlign: "start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleOpenEditDialog}
                      >
                        <BorderColor />
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleCancelorder}
                        color="error"
                      >
                        cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Paper>
      <EditReturnCanOrderDialog
        openEditReturnCanDialog={openEditReturnCanDialog}
        handleCloseReturnDialog={handleCloseReturnDialog}
        handleSubmitReturnDialog={handleSubmitReturnDialog}
        containerQty={props.data.container_qty}
      />
    </Box>
  );
}

export default DistributorReturnOrderDetails;
