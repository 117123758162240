/**
 * @author vinay kumar
 * @description Completed Derliveries Details page
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 27-11-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import SupplierCompletedDeliveriesCard from "../../Components/Cards/SupplierCompletedDeliveriesCard";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import {
  GetCompletedDeliveries,
  GetSupplierCompletedDeliveriesNew,
} from "../Redux/Actions/supliersAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConsolidatedDialog from "../AdminPoTrackPostPaid/ConsolidatedDialog";
import * as MuiIcons from "@mui/icons-material";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function CompletedDeliveries() {
  const graphData = useSelector((state) => state.homePageGraph);
  const [selectedDriver, setSelectedDriver] = React.useState(0);

  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [testArr, setTestArr] = React.useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(supplierDeliveries);
  // React.useEffect(() => {
  //   dispatch(
  //     GetSupplierCompletedDeliveriesNew({
  //       pageNumber: pageNumber,
  //       pageSize: pageSize,
  //     })
  //   );
  // }, []);
  React.useEffect(() => {
    dispatch(
      GetSupplierCompletedDeliveriesNew({
        page_number: pageNumber - 1,
        page_size: pageSize,
      })
    );
  }, [pageNumber, pageSize]);
  const handleCopyAddress = (props) => {
    CustomConsole("------------handleCopyAddress------------------");
    const address_compile = ` ${props.delivery_address.contact_name},${props.delivery_address.contact_phone},${props.delivery_address.address_line1},
    ${props.delivery_address.address_line2},
    ${props.delivery_address.address_line3},
    ${props.delivery_address.service_area}-
    ${props.delivery_address.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };
  const handleViewDetails = (props) => {
    const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${props.order_id}`;
    history.push(url);
  };
  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.delivery_address.contact_phone !== "") {
      CustomConsole(props.delivery_address.contact_phone);
      const telUrl = `tel:${props.delivery_address.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleLocation = (props) => {
    CustomConsole("------------handleLocation------------------");
    CustomConsole(props.delivery_address.latitude);
    CustomConsole(props.delivery_address.longitude);

    if (
      props.delivery_address.latitude !== undefined &&
      props.delivery_address.latitude !== null &&
      props.delivery_address.longitude !== undefined &&
      props.delivery_address.longitude !== null &&
      parseFloat(props.delivery_address.latitude) > 0 &&
      parseFloat(props.delivery_address.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props.delivery_address.latitude},${props.delivery_address.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };
  const handleSearchPageSize = () => {
    dispatch(
      GetSupplierCompletedDeliveriesNew({
        page_number: pageNumber - 1,
        page_size: pageSize,
      })
    );
  };
  let result = [];
  function toCSV(items) {
    if (items) {
      CustomConsole(items);
      items.map((a) => {
        CustomConsole(a);
        result.push(a);
        // result.push(a);
      });
    }
    setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };
  return (
    <Material.Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: { xs: "360px", md: "100%", sm: "650px" },
      }}
    >
      <Material.Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {parseInt(localStorage.getItem("roleId")) ==
        ADMIN_ROLES.BW_SUPPLIERMANAGER ? (
          <>
            {!graphData.error &&
              graphData.driverList &&
              graphData.driverList.length > 0 && (
                <Material.FormControl sx={{ m: 1, width: "250px" }}>
                  <Material.InputLabel>Drivers</Material.InputLabel>
                  <Material.Select
                    defaultValue={selectedDriver}
                    value={selectedDriver}
                    onChange={(event) => {
                      CustomConsole(event);
                      setSelectedDriver(event.target.value);
                    }}
                    label="Drivers"
                  >
                    <Material.MenuItem value={0}>None</Material.MenuItem>
                    {graphData.driverList.map((driver, index) => {
                      return (
                        <Material.MenuItem key={index} value={driver.driver_id}>
                          {driver?.driver_profile?.fullname} -{" "}
                          {driver?.driver_profile?.phone}
                        </Material.MenuItem>
                      );
                    })}
                  </Material.Select>
                </Material.FormControl>
              )}
          </>
        ) : (
          ""
        )}
        <Material.Box
          sx={{ maxWidth: "120px", maxHeight: "10px", margin: "5px" }}
        >
          <Material.TextField
            size="small"
            value={pageSize}
            label="Page size"
            // onChange={(e) => {
            //   setPageSize(e.target.value);
            //   // setSearchOrderFlag(true);
            // }}

            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          />
        </Material.Box>
        <Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.IconButton
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
              />
            </Material.IconButton>
            <Material.Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {pageNumber}
            </Material.Typography>
            <Material.IconButton
              sx={{ maxHeight: "50px" }}
              onClick={() => {
                if (
                  supplierDeliveries?.getSupplierCompletedDeliveriesNew?.length
                ) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color: supplierDeliveries?.getSupplierCompletedDeliveriesNew
                    ?.length
                    ? "blue"
                    : "gray",
                }}
              />
            </Material.IconButton>
          </Material.Box>
        </Material.Box>
        <CSVLink {...csvLink}>
          {" "}
          <DownloadIcon
            sx={{ fontSize: 35 }}
            onClick={() =>
              toCSV(supplierDeliveries.getSupplierCompletedDeliveriesNew)
            }
          />
        </CSVLink>
      </Material.Box>
      {supplierDeliveries.getSupplierCompletedDeliveriesNew !== null &&
      supplierDeliveries.getSupplierCompletedDeliveriesNew !== undefined &&
      supplierDeliveries.getSupplierCompletedDeliveriesNew.length > 0 ? (
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            maxHeight: "550px",
            overflow: "auto",
          }}
        >
          {supplierDeliveries.getSupplierCompletedDeliveriesNew.map(
            (data, key) => {
              return (
                <SupplierCompletedDeliveriesCard
                  data={data}
                  key={key}
                  handleCopyAddress={handleCopyAddress}
                  handleLocation={handleLocation}
                  handlePhone={handlePhone}
                  handleViewDetails={handleViewDetails}
                />
              );
            }
          )}
        </Material.Box>
      ) : (
        <Material.Typography sx={{ fontWeight: "bold", color: "red" }}>
          No more completed deliveries left!
        </Material.Typography>
      )}
    </Material.Box>
  );
}

export default CompletedDeliveries;
