/**
 * @author vinay kumar
 * @description Supplier Company Profile
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 29-06-2023
 */
// Importing the Libraries and Other Files

import React, { useEffect, useState } from "react";
import * as Material from "@mui/material";
import SuplierwarehouseCard from "../../Components/Cards/SuplierwarehouseCard";
import { useDispatch, useSelector } from "react-redux";
import { GetSupplierCompanyProfile } from "../Redux/Actions/supliersAction";
import TextField from "@mui/material/TextField";
import CustomConsole from "../../CustomConsole";
import Autocomplete from "@mui/material/Autocomplete";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import AddorUpdateWarehouseDialog from "../../Components/DialogBoxes/SupplierDialogBox/AddorUpdateWarehouseDialog";

function WareHouseList(props) {
  const dispatch = useDispatch();
  const [openAddWarehouse, setOpenAddWarehouse] = useState(false);
  const warehouses = useSelector((state) => state.userDetailsUpdate);
  // const editAddressFlag = useSelector((state) => state.userDetailsUpdate);

  const [searchedWarehouseName, setSearchedWarehouseName] = useState("");
  const [searchedPicode, setSearchedPicode] = useState();
  const [filteredWarehouses, setFilteredWarehouses] = useState();

  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);

  useEffect(() => {
    let filtered = warehouses?.address?.filter((item) => {
      if (!searchedWarehouseName && !searchedPicode) {
        return item;
      } else if (
        searchedWarehouseName &&
        item.contact_name
          ?.toLocaleUpperCase()
          .startsWith(searchedWarehouseName?.toLocaleUpperCase())
      ) {
        return item;
      } else if (
        searchedPicode &&
        item.pincode?.toString()?.startsWith(searchedPicode?.toString())
      ) {
        return item;
      }
    });
    setFilteredWarehouses(filtered);
  }, [searchedPicode, searchedWarehouseName, warehouses]);

  React.useEffect(() => {
    dispatch(GetFilteredUserAddressAdmin({ user_id: props.supplierId }));
  }, []);
  //refresh API for refresh api
  React.useEffect(() => {
    CustomConsole(warehouses.editAddressFlag);
    if (warehouses.editAddressFlag == false) {
      dispatch(GetFilteredUserAddressAdmin({ user_id: props.supplierId }));

      warehouses.editAddressFlag = true;
    }
  }, [warehouses.editAddressFlag]);
  const addWarehouse = () => {
    setOpenAddWarehouse(true);
  };
  return (
    <Material.Box sx={{ width: "100%" }}>
      <Material.Box>
        <Material.Grid container sx={{ alignItems: "center" }}>
          <Material.Grid item md={3} sm={12} xs={12}>
            <Material.TextField
              margin="normal"
              // fullWidth
              value={searchedWarehouseName}
              onChange={(e) => {
                setSearchedWarehouseName(e.target.value);
              }}
              name="Warehouse"
              label="Search by Name"
              autoComplete="Warehouse"
            />
          </Material.Grid>
          <Material.Grid item md={3} sm={12} xs={12}>
            <Material.TextField
              margin="normal"
              // fullWidth
              onChange={(e) => {
                setSearchedPicode(e.target.value);
              }}
              value={searchedPicode}
              name="pincode"
              label="Search by Pincode"
              autoComplete="pincode"
            />
          </Material.Grid>
          <Material.Grid item md={3} sm={12} xs={12}>
            <Material.Button variant="contained" onClick={() => addWarehouse()}>
              Add Warehouse
            </Material.Button>
          </Material.Grid>
        </Material.Grid>
        <Material.Box
          sx={{ display: "flex", flexDirection: "column", gap: 3, pt: 2 }}
        >
          {filteredWarehouses?.map((warehouse) => (
            <SuplierwarehouseCard
              supplierIntegerId={props.supplierIntegerId}
              warehouseDetails={warehouse}
              data={supplierDeliveries.getSupplerCompanyProfile}
              supplierId={props.supplierId}
            />
          ))}
        </Material.Box>
      </Material.Box>
      {openAddWarehouse && (
        <AddorUpdateWarehouseDialog
          supplierId={props.supplierId}
          supplierIntegerId={props.supplierIntegerId}
          isOpen={openAddWarehouse}
          handleClose={() => setOpenAddWarehouse(false)}
        />
      )}
    </Material.Box>
  );
}

export default WareHouseList;
