import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import GameActionCard from "./GameActionCard";
import styles from "./campaignDetailsStyle";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { PlayAGame } from "../Redux/Actions/gamificationActions";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

import CustomConsole from "../../CustomConsole";
function CampaignDetailsFormView(props) {
  const dispatch = useDispatch();
  const [userReferral, setUserReferral] = React.useState("");
  const [promoterEmail, setPromoterEmail] = React.useState("");
  const [salesRepEmail, setSalesRepEmail] = React.useState("");
  const [selectedCampaign, setSelectedCampaign] = React.useState({ name: "-" });
  const [campStatus, setCampStatus] = React.useState(true);
  const [selectedGameAction, setSelectedGameAction] = React.useState({
    name: "-",
    description: "-",
    reward_points: "-",
    action_points: "-",
  });
  const gamificationStore = useSelector((state) => state.gamificationReducer);
  React.useEffect(() => {
    CustomConsole("-- GameActionCard PlayAGame Response ---");
    CustomConsole(gamificationStore.playAGame);
    if (
      !gamificationStore.error &&
      gamificationStore.playAGame.responseId &&
      gamificationStore.playAGame.responseId.length
    ) {
      setUserReferral("");
      setPromoterEmail("");
    }
  }, [gamificationStore.playAGame]);
  React.useEffect(() => {
    CustomConsole("-- props --");
    CustomConsole(props);
    if (props.data && props.data.length) {
      setSelectedCampaign(() => props.data[0]);
      if (
        props.data[0].userActionsAndRewards &&
        props.data[0].userActionsAndRewards.length
      ) {
        setSelectedGameAction(() => props.data[0].userActionsAndRewards[0]);
      }
    }
  }, [props]);
  React.useEffect(() => {
    CustomConsole("-- selectedCampaign --");
    CustomConsole(selectedCampaign);
    CustomConsole("-- selectedGameAction --");
    CustomConsole(selectedGameAction);
    if (selectedCampaign.endDate) {
      CustomConsole(selectedCampaign.endDate);
      CustomConsole(moment());
      CustomConsole(
        moment(selectedCampaign.endDate).isSameOrAfter(moment(), "day")
      );
      const isToday = moment(selectedCampaign.endDate).isSameOrAfter(
        moment(),
        "day"
      );
      setCampStatus(isToday);
    }
  }, [selectedGameAction, selectedCampaign]);
  const handleUserGameAction = () => {
    CustomConsole("--- handleUserGameAction ---");
    CustomConsole(selectedGameAction);
    CustomConsole(selectedCampaign);
    let gameActionObject = {
      sales_rep_email: salesRepEmail,
      promoter_email: promoterEmail,
      action_id: selectedGameAction.action_id || "",
      reward_id: selectedGameAction.reward_id || "",
      consumer_referral: userReferral,
      campaign_id: selectedCampaign.campaignId,
      fromDate: selectedCampaign.startDate,
      toDate: selectedCampaign.endDate,
    };

    if (props.salesRep.length) {
      gameActionObject.sales_rep_email = props.salesRep + "@bookwater.com";
    }
    CustomConsole("-- Hit API here --");
    CustomConsole(gameActionObject);
    props.setOpenProgressBar(true);
    dispatch(PlayAGame(gameActionObject));
  };
  const handleOnCampaignNameChange = (event) => {
    CustomConsole("-- handleOnCampaignNameChange ---");
    CustomConsole(event);
    const selectedCampData = props.data.find(
      (data) => data.name === event.target.value
    );
    CustomConsole(selectedCampData);
    if (selectedCampData && Object.keys(selectedCampData).length) {
      setSelectedCampaign(selectedCampData);
      if (
        selectedCampData.userActionsAndRewards &&
        selectedCampData.userActionsAndRewards.length
      ) {
        setSelectedGameAction(() => selectedCampData.userActionsAndRewards[0]);
      } else {
        const emptyObject = {
          name: "-",
          description: "-",
          reward_points: "-",
          action_points: "-",
        };
        setSelectedGameAction(emptyObject);
      }
    }
  };
  const handleOnGameActionChange = (event) => {
    CustomConsole("-- handleOnGameActionChange --");
    CustomConsole(event);
    if (
      selectedCampaign &&
      selectedCampaign.userActionsAndRewards &&
      selectedCampaign.userActionsAndRewards.length
    ) {
      const selectedGameData = selectedCampaign.userActionsAndRewards.find(
        (data) => data.name === event.target.value
      );
      if (selectedGameData && Object.keys(selectedGameData).length) {
        setSelectedGameAction(selectedGameData);
      }
    }
  };
  return (
    <Material.Paper sx={formStyles.paperStyle} elevation={24}>
      <Material.Typography sx={formStyles.headerText}>
        BookWater Campaigns
      </Material.Typography>
      <Material.Box sx={formStyles.mainBox}>
        <Material.Box sx={formStyles.mainBox2}>
          <Material.FormControl variant="standard" fullWidth>
            <Material.InputLabel
              sx={[
                formStyles.inputLabel,
                { color: campStatus ? "blue" : "red" },
              ]}
            >
              Campaign Name{" "}
              {selectedCampaign.endDate
                ? "(" +
                  moment(selectedCampaign.endDate).format("DD/MM/YYYY") +
                  ")"
                : ""}
            </Material.InputLabel>
            <Material.Select
              defaultValue={selectedCampaign.name}
              sx={formStyles.formSelect}
              value={selectedCampaign.name}
              label="Campaign Name"
              onChange={handleOnCampaignNameChange}
              startAdornment={
                <Material.InputAdornment position="start">
                  <MuiIcons.Campaign sx={{ color: "#96009E" }} />
                </Material.InputAdornment>
              }
            >
              {props.data &&
                props.data.length &&
                props.data.map((campDetails, index) => {
                  return (
                    <Material.MenuItem key={index} value={campDetails.name}>
                      {campDetails.name}
                    </Material.MenuItem>
                  );
                })}
            </Material.Select>
          </Material.FormControl>

          <Material.FormControl variant="standard" fullWidth>
            <Material.InputLabel sx={formStyles.inputLabel}>
              Game Action
            </Material.InputLabel>
            <Material.Select
              sx={formStyles.formSelect}
              defaultValue={selectedGameAction.name}
              value={selectedGameAction.name}
              label="Game Action"
              onChange={handleOnGameActionChange}
              startAdornment={
                <Material.InputAdornment position="start">
                  <MuiIcons.Sports sx={{ color: "green" }} />
                </Material.InputAdornment>
              }
            >
              {selectedCampaign &&
                selectedCampaign.userActionsAndRewards &&
                selectedCampaign.userActionsAndRewards.length &&
                selectedCampaign.userActionsAndRewards.map(
                  (gameDetails, index) => {
                    return (
                      <Material.MenuItem key={index} value={gameDetails.name}>
                        {gameDetails.name}
                      </Material.MenuItem>
                    );
                  }
                )}
            </Material.Select>
          </Material.FormControl>
        </Material.Box>
        <Material.Box sx={formStyles.mainBox3}>
          <Material.Box sx={formStyles.mainBox4}>
            <Material.Box sx={formStyles.contentBox}>
              <Material.Typography sx={formStyles.questionText}>
                Game Description:
              </Material.Typography>
              <Material.Typography sx={formStyles.answerText}>
                {selectedGameAction.description}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={formStyles.contentBox}>
              <Material.Typography sx={formStyles.questionText}>
                Reward Points:
              </Material.Typography>
              <Material.Typography sx={formStyles.answerText}>
                {selectedGameAction.reward_points}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={formStyles.contentBox}>
              <Material.Typography sx={formStyles.questionText}>
                Sales Points:
              </Material.Typography>
              <Material.Typography sx={formStyles.answerText}>
                {selectedGameAction.action_points}
              </Material.Typography>
            </Material.Box>
          </Material.Box>

          <Material.Box sx={formStyles.boxContent}>
            {localStorage.getItem("roleId") &&
            parseInt(localStorage.getItem("roleId")) !==
              ADMIN_ROLES.BW_PROMOTER ? (
              <Material.FormControl variant="standard">
                <Material.InputLabel sx={formStyles.inputLabel}>
                  Promoter Email
                </Material.InputLabel>
                <Material.Select
                  sx={formStyles.formSelect}
                  value={promoterEmail}
                  label="Promoter Email"
                  onChange={(event) => setPromoterEmail(event.target.value)}
                  startAdornment={
                    <Material.InputAdornment position="start">
                      <MuiIcons.MarkEmailUnreadTwoTone />
                    </Material.InputAdornment>
                  }
                >
                  {selectedCampaign &&
                    selectedCampaign.promoterEmails &&
                    selectedCampaign.promoterEmails.length &&
                    selectedCampaign.promoterEmails.map(
                      (promoEmails, index) => {
                        return (
                          <Material.MenuItem key={index} value={promoEmails}>
                            {promoEmails}
                          </Material.MenuItem>
                        );
                      }
                    )}
                </Material.Select>
              </Material.FormControl>
            ) : (
              <Material.FormControl variant="standard">
                <Material.InputLabel sx={formStyles.inputLabel}>
                  Sales Rep. Email
                </Material.InputLabel>
                <Material.Select
                  sx={formStyles.formSelect}
                  value={salesRepEmail}
                  label="Sales Rep. Email"
                  onChange={(event) => setSalesRepEmail(event.target.value)}
                  startAdornment={
                    <Material.InputAdornment position="start">
                      <MuiIcons.MarkEmailUnreadTwoTone />
                    </Material.InputAdornment>
                  }
                >
                  {selectedCampaign &&
                    selectedCampaign.salesRepEmails &&
                    selectedCampaign.salesRepEmails.length &&
                    selectedCampaign.salesRepEmails.map(
                      (salesRepEmails, index) => {
                        return (
                          <Material.MenuItem key={index} value={salesRepEmails}>
                            {salesRepEmails}
                          </Material.MenuItem>
                        );
                      }
                    )}
                </Material.Select>
              </Material.FormControl>
            )}
            {campStatus && (
              <Material.TextField
                varient="standard"
                label="Referral Code"
                value={userReferral}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <MuiIcons.SubtitlesOutlined />
                    </Material.InputAdornment>
                  ),
                }}
                onChange={(event) =>
                  setUserReferral(
                    event.target.value.toUpperCase().replace(/\s/g, "")
                  )
                }
                error={userReferral.length > 7 ? false : true}
                sx={formStyles.rewardText}
              />
            )}
          </Material.Box>
        </Material.Box>
        {campStatus && (
          <Material.Button
            disabled={userReferral.length > 7 ? false : true}
            variant="contained"
            sx={[
              formStyles.rewardBtn,
              {
                backgroundImage:
                  userReferral.length > 7
                    ? "linear-gradient(#021636,#0c4aad)"
                    : "gray",
              },
            ]}
            startIcon={<MuiIcons.EmojiEventsOutlined />}
            onClick={handleUserGameAction}
          >
            Reward User
          </Material.Button>
        )}
      </Material.Box>
    </Material.Paper>
  );
}

export default CampaignDetailsFormView;

const formStyles = {
  formSelect: {
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  inputLabel: { fontSize: "14px", fontWeight: "bold", color: "blue" },
  mainBox4: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
  },
  mainBox3: {
    marginTop: "10px",
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
    alignItems: "center",
    gap: "15px",
  },
  rewardText: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "blue",
  },
  rewardBtn: {
    marginTop: { xs: "15px", sm: "15px", md: "25px", lg: "25px" },
    "&:hover": {
      backgroundImage: "linear-gradient(#6b8df2,#021636)",
    },
  },
  mainBox2: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
    gap: "20px",
  },
  contentBox: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
  questionText: {
    textAlign: "start",
    color: "blue",
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  answerText: {
    textAlign: "center",
    color: "black",
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  mainBox: {
    ddisplay: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "15px",
    width: "100%",
  },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  boxContent: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
    justifyContent: "center",
    gap: "15px",
  },
  headerText: {
    color: "brown",
    fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" },
    fontWeight: "bold",
    textAlign: "start",
  },
};
