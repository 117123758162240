import { Box, Grid, TextField, Button } from "@mui/material";
import * as Material from "@mui/material";
import * as IconName from "react-icons/fc";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomConsole from "../../CustomConsole";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminRolesTable from "./AdminRolesTable";
import AdminUsers from "../AdminUsers/AdminUsers";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@mui/styles";
import { GetRolesList } from "../Redux/Actions/addUserAdminAction";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { GetUserBasedName } from "../Redux/Actions/userDetailsAdminAction";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "azure",
    color: "black",
    fontWeight: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function SuperAdmin() {
  const classes = useStyles();
  const history = useHistory();
  const superAdminUpdates = useSelector((state) => state.superAdminUpdates);
  const appMetaConfig = useSelector((state) => state.adminBookingData);
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const [userDetails, setUserDetails] = React.useState([]);
  CustomConsole(updateUserDetails);
  CustomConsole(addUserAdminDetails);
  React.useEffect(() => {
    dispatch(GetRolesList());
  }, []);
  React.useEffect(() => {
    CustomConsole("------User Search details-----");
    CustomConsole(updateUserDetails);
    if (updateUserDetails.userNames) {
      setUserDetails(updateUserDetails.userNames);
    } else {
      setUserDetails([]);
    }
  }, [updateUserDetails.userNames]);
  React.useEffect(() => {
    if (addUserAdminDetails.rolesList.length > 0) {
      setRoles(addUserAdminDetails.rolesList);
    }
  }, [addUserAdminDetails]);

  const [departmentList, setDepartmentList] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [value, setValue] = React.useState("1");
  const [selectedRoleName, setSelectedRoleName] = React.useState("");
  const [selectedRole, setSelectedRole] = React.useState("");
  const [searchName, setSearchName] = React.useState("");
  const [searchEmail, setSearcEmail] = React.useState("bookwater.com");
  const [pageNumber, setPageNumber] = React.useState(0);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
  }, []);
  // React.useEffect(() => {}, [pageSize]);
  React.useEffect(() => {
    CustomConsole(superAdminUpdates);
    if (superAdminUpdates.adminRoleList) {
      setRoles(superAdminUpdates.adminRoleList);
    }
  }, [superAdminUpdates]);

  React.useEffect(() => {
    CustomConsole(appMetaConfig);
    if (appMetaConfig.setupConfigData.length > 0) {
      appMetaConfig.setupConfigData.map((data, key) => {
        if (data.key === "userDomainType") {
          setDepartmentList(data.value);
        }
      });
    }
  }, [appMetaConfig]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRoles = (event) => {
    setPageNumber(0);
    setSearcEmail("");
    setSearchName("");
    CustomConsole(event.target.value);
    setSelectedRole(event.target.value);
    dispatch(GetUserBasedName({ role_id: event.target.value, page_number: 0 }));
    roles.map((role) => {
      if (role.role_id === event.target.value) {
        CustomConsole(role.role_name);
        setSelectedRoleName(role.role_name);
      }
    });
  };
  const handleSearchName = () => {
    setPageNumber(0);
    setSelectedRole("");
    setSearcEmail("");
    setSelectedRoleName("");
    dispatch(GetUserBasedName({ name: searchName, page_number: 0 }));
  };
  const handleSearchEmail = () => {
    setPageNumber(0);
    setSearchName("");
    setSelectedRole("");
    setSelectedRoleName("");
    dispatch(GetUserBasedName({ email: searchEmail, page_number: 0 }));
  };
  const handleRedirecttoUsers = (phone) => {
    CustomConsole("------------handleRedirecttoUsers--------------");
    const url = `/dashboard/UserDetailsMain?phoneNumber=${phone}`;
    history.push(url);
  };
  React.useEffect(() => {
    if (searchName) {
      dispatch(GetUserBasedName({ name: searchName, page_number: pageNumber }));
    } else if (searchEmail) {
      dispatch(
        GetUserBasedName({ email: searchEmail, page_number: pageNumber })
      );
    } else if (selectedRole) {
      dispatch(
        GetUserBasedName({ role_id: selectedRole, page_number: pageNumber })
      );
    }
  }, [pageNumber]);

  const pageNation = (val) => {
    if (val === 0) {
      setPageNumber((prev) => (prev - 1 < 0 ? 0 : prev - 1));
    } else {
      setPageNumber((prev) => prev + 1);
    }
  };

  // const handleChangePageSize = (event) => {
  //   setPageSize(event.target.value);
  // };
  return (
    <Box>
      {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ? (
        <>
          {" "}
          <Container
            sx={{
              marginTop: "50px",
              padding: "0px",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <AdminUsers />
              </Grid>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      sx={{ background: "#D0D3D4" }}
                      className={classes.tabUi}
                    >
                      <Tab label="Roles List" value="1" />
                      <Tab label="Active Users" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Grid item xs={12}>
                      <AdminRolesTable
                        roles={roles}
                        departmentList={departmentList}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        padding: "10px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        label="Search Name"
                        value={searchName}
                        InputProps={{
                          style: {
                            borderRadius: "30px",
                            // border: "2px solid blue",
                          },
                          endAdornment: (
                            <Material.InputAdornment position="end">
                              <IconName.FcSearch
                                size="30px"
                                onClick={handleSearchName}
                              />
                            </Material.InputAdornment>
                          ),
                        }}
                        onChange={(event) => setSearchName(event.target.value)}
                      />
                      <FormControl sx={{ minWidth: 250 }}>
                        <InputLabel id="demo-select-small">
                          Select role to add
                        </InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={selectedRole}
                          label="Select role to add"
                          onChange={handleChangeRoles}
                        >
                          {roles.map((role) => {
                            return (
                              <MenuItem value={role.role_id}>
                                {role.role_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <TextField
                        label="Search Email"
                        value={searchEmail}
                        InputProps={{
                          style: {
                            borderRadius: "30px",
                            // border: "2px solid gray",
                          },
                          endAdornment: (
                            <Material.InputAdornment position="end">
                              <IconName.FcSearch
                                size="30px"
                                onClick={handleSearchEmail}
                              />
                            </Material.InputAdornment>
                          ),
                        }}
                        onChange={(event) => setSearcEmail(event.target.value)}
                      />
                    </Box>
                    <Box>
                      {userDetails.length > 0 ? (
                        <>
                          <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                              <TableHead>
                                <TableRow></TableRow>
                                <TableRow>
                                  <StyledTableCell align="center" size="small">
                                    <strong>Name</strong>
                                  </StyledTableCell>
                                  <StyledTableCell align="center" size="small">
                                    <strong>Phone</strong>
                                  </StyledTableCell>
                                  <StyledTableCell align="center" size="small">
                                    <strong>Email</strong>
                                  </StyledTableCell>
                                  <StyledTableCell align="center" size="small">
                                    <strong>Role</strong>
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userDetails.map((data) => (
                                  <StyledTableRow
                                    // key={row.ec_order_id}
                                    // onClick={() => {
                                    //   settingOrderId(row.ec_order_id);
                                    // }}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                      cursor: "pointer",
                                      "&:hover": {
                                        boxShadow: "none",
                                        backgroundColor: "#036bfc",
                                      },
                                    }}
                                  >
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      {data.fullname}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      {data.phone}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      {data.email}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      {data.role_id === ADMIN_ROLES.BW_ADMIN
                                        ? "Admin"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_SUPERADMIN
                                        ? "Super Admin"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_CONSUMER
                                        ? "Consumer"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_WFSADMIN
                                        ? "WaterFilling Station"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_DISTRIBUTER
                                        ? "Distributor"
                                        : data.role_id === ADMIN_ROLES.BW_CS
                                        ? "Customer Support"
                                        : data.role_id === ADMIN_ROLES.BW_CSHEAD
                                        ? "Customer Support Head"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_FINANCE
                                        ? "Finance"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_FINANCEHEAD
                                        ? "Finance Head"
                                        : data.role_id === ADMIN_ROLES.BW_OPS
                                        ? "Operations"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_OPSHEAD
                                        ? "Operation Head"
                                        : data.role_id === ADMIN_ROLES.BW_QA
                                        ? "QA"
                                        : data.role_id === ADMIN_ROLES.BW_QAHEAD
                                        ? "QA HEAD"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_DELIVERYAGENT
                                        ? "Delivey Agent"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_SUPPLIERMANAGER
                                        ? "Warehouse Manager"
                                        : data.role_id ===
                                          ADMIN_ROLES.BW_SUPPLIER_LOADERS
                                        ? "Loader"
                                        : ""}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      size="small"
                                    >
                                      <Material.Button
                                        variant="contained"
                                        onClick={() =>
                                          handleRedirecttoUsers(data.phone)
                                        }
                                      >
                                        view Details
                                      </Material.Button>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : null}
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Container>
          <Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", margin: "30px" }}
            >
              <Button onClick={() => pageNation(0)}>
                <ArrowBackIos />
              </Button>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber + 1}
              </Typography>
              <Button
                onClick={() => pageNation(1)}
                disabled={!userDetails.length}
              >
                <ArrowForwardIos />
              </Button>
            </Box>
            {/* <Material.Box>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Page size</InputLabel>
                <Select
                  value={pageSize}
                  label="Page size"
                  onChange={handleChangePageSize}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                </Select>
              </FormControl>
            </Material.Box> */}
          </Box>
        </>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Box>
  );
}

export default SuperAdmin;
