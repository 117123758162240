/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 25-01-2024
 */
import React from "react";
import * as Material from "@mui/material";
import Table from "@mui/material/Table";
import CustomConsole from "../../CustomConsole";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { ordersStatusWFSCan } from "../../Components/AppMeta/appMetaConfig";
import {
  GetOngoingOrdersWfsInAdmin,
  StartSubmissinOngoingWfsAdmin,
} from "../Redux/Actions/adminWfsCanOrdersAction";
import { useDispatch } from "react-redux";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
function WfsOrdersTableView(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  //function for redirecting to single order page
  const handleCellClick = (params) => {
    if (params.includes("ECON")) {
      const url = `/dashboard/WfsSingleOrder?order_id=${params}`;
      history.push(url);
    }
  };
  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={appColors.commonLayoutColor}>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap", fontSize: "16px" }}
                colSpan={12}
              >
                <strong>Empty can Orders</strong>
              </TableCell>
            </TableRow>
            <TableRow sx={appColors.commonLayoutColor}>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>S.no</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Order ID</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Order Status</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Received Can</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Submitted Cans</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Expired Can</strong>
              </TableCell>
              <TableCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                <strong>Order Qty</strong>
              </TableCell>

              <TableCell size="small" align="center">
                <strong>Capacity</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>Material</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data?.map((data, index) => (
              <>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor:
                      data.order_details.is_submit_started === false
                        ? "#4FFFB0"
                        : "#fff",
                  }}
                  key={index}
                  onClick={() => handleCellClick(data.ec_order_id)}
                >
                  <TableCell size="small" align="start">
                    {index + 1}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.ec_order_id}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_status}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details?.outscanned_cans}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details?.inscanned_cans}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details?.expired_qty}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details?.order_qty}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details?.container_capacity}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {data.order_details?.container_material}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default WfsOrdersTableView;
