import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { GetLostCansList } from "../Redux/Actions/adminLostCansAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ConsumerCanList } from "../Redux/Actions/adminLostCansAction";
import { DistributorCanList } from "../Redux/Actions/adminLostCansAction";
import CustomConsole from "../../CustomConsole";

function FilterDialog({
  filterDialogFlag,
  setFilterDialogFlag,
  handleCloses,
  handleClickApply,
  setUserType,
  userType,
  pageNumber,
  // setChecked,
  setSelected,
  setDisableCans,
  setCheckedItems,
}) {
  const dispatch = useDispatch();
  const lostCansDetails = useSelector((state) => state.adminLostCansData);
  CustomConsole(lostCansDetails);

  const handleApply = () => {
    dispatch(GetLostCansList(userType, pageNumber - 1));
    setSelected([]);
    setDisableCans([]);
    setFilterDialogFlag(false);
    setCheckedItems([]);
    // setChecked(false);
  };

  

  return (
    <Box>
      <Dialog
        open={filterDialogFlag}
        onClose={handleCloses}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Lost Container Details of</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event) => setUserType(event.target.value)}
              >
                <FormControlLabel value="WFS" control={<Radio />} label="WFS" />
                <FormControlLabel
                  value="CONSUMER"
                  control={<Radio />}
                  label="Consumer"
                />
                <FormControlLabel
                  value="DISTRIBUTOR"
                  control={<Radio />}
                  label="Distributor"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-around",}}>
            {/* <Button variant="contained" onClick={handleReset}>Reset</Button> */}
            <Button variant="contained" onClick={handleApply}>
              Apply
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default FilterDialog;
