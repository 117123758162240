import React from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { typeOfBlocking } from "../../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../../CustomConsole";
import { orderType } from "../../../Components/AppMeta/appMetaConfig";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  BlockBooking,
  GetBlockedBookings,
} from "../../Redux/Actions/appSettingsAction";
import { AvailablePincodes } from "../../Redux/Actions/pincodesAction";
import moment from "moment/moment";

function BookingBlock() {
  const dispatch = useDispatch();
  const blockedBookingData = useSelector(
    (state) => state.appSettingsDetails.blockedBookings
  );

  const [blockingMethods, setBlockingMethods] = React.useState(200);
  const [bookingTypes, setBookingTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [allAvailableCountries, setAllAvailableCountries] = React.useState([]);
  const [allAvailablePincodes, setAllAvailablePincodes] = React.useState([]);
  const [allAvailableDistricts, setAllAvailableDistricts] = React.useState([]);
  const [allAvailableAreas, setAllAvailableAreas] = React.useState([]);
  const [allAvailableStates, setAllAvailableStates] = React.useState();
  const [pincodes, setPincodes] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [daterange, setDateRange] = React.useState({});

  const [reason, setReason] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [area, setArea] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("INDIA");
  const [order, setOrder] = React.useState(100);
  const [fromDate, setFromDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));

  const nameBlockingMethods = (type) => {
    switch (type) {
      case 100:
        return "Date Range";
      case 200:
        return "Booking Type";
      case 300:
        return "Pincodes";
      case 301:
        return "Areas";
      case 302:
        return "Districts";
      case 303:
        return "States";
      case 304:
        return "Countries";
      default:
        return false;
    }
  };

  const orderTypeName = (type) => {
    switch (type) {
      case 100:
        return "Insta Order";
      case 200:
        return "Postpaid";
      case 300:
        return "Subscription";
      case 400:
        return "Regular";

      default:
        return false;
    }
  };

  const onSubmitBookingBlock = (e) => {
    e.preventDefault();
    const reqObj = reqObjFun(blockingMethods);

    dispatch(BlockBooking(reqObj));
  };

  const handleDelete = (item) => {
    const reqObj = deleteObjFun(blockingMethods, item);

    dispatch(BlockBooking(reqObj));
  };

  const handleFromDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setFromDate(moment(newValue.$d).format("YYYY-MM-DD"));
    } else {
      return false;
    }
  };

  const handleToDate = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      return setToDate(moment(newValue.$d).format("YYYY-MM-DD"));
    } else {
      return false;
    }
  };

  const reqObjFun = (type) => {
    switch (type) {
      case 100:
        return {
          date_range: {
            from_date: fromDate,
            to_date: toDate,
          },
        };
      case 200:
        return {
          booking_types: [
            {
              type: order,
              reason: reason,
            },
          ],
        };
      case 300:
        return {
          pincodes: [
            {
              pincode: pincode,
              country: country,
              reason: reason,
            },
          ],
        };
      case 301:
        return {
          areas: [
            {
              pincode: pincode,
              area: area,
              country: country,
              state: state,
              reason: reason,
            },
          ],
        };
      case 302:
        return {
          districts: [
            {
              country: country,
              district: district,
              state: state,
              reason: reason,
            },
          ],
        };
      case 303:
        return {
          states: [
            {
              country: country,
              state: state,
              reason: reason,
            },
          ],
        };
      case 304:
        return {
          countries: [
            {
              country: country,
              reason: reason,
            },
          ],
        };
      default:
        return null;
    }
  };

  const deleteObjFun = (type, item) => {
    switch (type) {
      case 100:
        return {
          date_range: {
            ...item,
            is_remove: true,
          },
        };
      case 200:
        return {
          booking_types: [
            {
              ...item,
              is_remove: true,
            },
          ],
        };
      case 300:
        return {
          pincodes: [
            {
              ...item,
              is_remove: true,
            },
          ],
        };
      case 301:
        return {
          areas: [
            {
              ...item,
              is_remove: true,
            },
          ],
        };
      case 302:
        return {
          districts: [
            {
              ...item,
              is_remove: true,
            },
          ],
        };
      case 303:
        return {
          states: [
            {
              ...item,
              is_remove: true,
            },
          ],
        };
      case 304:
        return {
          countries: [
            {
              ...item,
              is_remove: true,
            },
          ],
        };
      default:
        return null;
    }
  };
  const getCountries = async () => {
    const all_available_countries = await AvailablePincodes({
      // pincode: value,
      page_number: 1,
      page_size: 100,
      get: "country",
    });
    setAllAvailableCountries(all_available_countries?.data);
  };
  const getPincodes = async (params) => {
    const all_available_pincodes = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "pincode",
    });
    setAllAvailablePincodes(all_available_pincodes?.data);
  };
  const getStates = async (params) => {
    const all_available_states = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 100,
      get: "state_name",
    });
    setAllAvailableStates(all_available_states?.data);
  };
  const getDistricts = async (params) => {
    const all_Districts = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 100,
      get: "district",
    });
    setAllAvailableDistricts(all_Districts?.data);
  };
  const getAreas = async (params) => {
    const all_areas = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "locality",
    });
    setAllAvailableAreas(all_areas?.data);
  };
  React.useEffect(() => {
    dispatch(GetBlockedBookings());
    getCountries();
  }, []);
  React.useEffect(() => {
    setState("");
    setDistrict("");
    setPincode("");
    setArea("");
    if (country) {
      getStates({ country });
      getDistricts({ country });
      getPincodes({ country });
      getAreas({ country });
    }
  }, [country]);

  React.useEffect(() => {
    setDistrict("");
    setPincode("");
    setArea("");
    if (state) {
      getDistricts({ country, state_name: state });
      getPincodes({ country, state_name: state });
      getAreas({ country, state_name: state });
    }
  }, [state]);
  React.useEffect(() => {
    setPincode("");
    setArea("");
    if (district) {
      getPincodes({ country, state_name: state, district });
      getAreas({ country, state_name: state, district });
    }
  }, [district]);
  React.useEffect(() => {
    setArea("");
    if (pincode) {
      getAreas({ country, state_name: state, district, pincode });
    }
  }, [pincode]);

  React.useEffect(() => {
    CustomConsole("-----Blocked Booking Data----");
    CustomConsole(blockedBookingData);
    setBookingTypes(() =>
      blockedBookingData?.booking_types ? blockedBookingData.booking_types : []
    );

    setCountries(() =>
      blockedBookingData?.countries ? blockedBookingData.countries : []
    );
    setPincodes(() =>
      blockedBookingData?.pincodes ? blockedBookingData.pincodes : []
    );
    setAreas(() => (blockedBookingData?.areas ? blockedBookingData.areas : []));
    setDistricts(() =>
      blockedBookingData?.districts ? blockedBookingData.districts : []
    );
    setStates(() =>
      blockedBookingData?.states ? blockedBookingData.states : []
    );
    setDateRange(() =>
      blockedBookingData?.date_range ? blockedBookingData.date_range : {}
    );
  }, [blockedBookingData]);

  return (
    <Box>
      <Box sx={{ width: "12rem" }}>
        <FormControl fullWidth>
          <InputLabel id="select-label-booking-methods">
            Block Booking
          </InputLabel>
          <Select
            labelId="select-label-booking-methods"
            id="select-booking-methods"
            value={blockingMethods}
            label="Block Booking"
            onChange={(e) => setBlockingMethods(e.target.value)}
            required
          >
            {Object.keys(typeOfBlocking).map((key, idx) => {
              return (
                <MenuItem key={idx} value={typeOfBlocking[key]}>
                  {nameBlockingMethods(typeOfBlocking[key])}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <Typography
          variant="h6"
          sx={{ textAlign: "left", marginBottom: "5px" }}
        >
          Blocked Bookings
        </Typography>
        {blockingMethods === typeOfBlocking.pincode ? (
          <Stack
            direction="row"
            spacing={1}
            columnGap={1}
            rowGap={1}
            mb={2}
            sx={{ overflow: "auto", flexWrap: "wrap" }}
          >
            {pincodes.map((item, idx) => {
              return (
                <Chip
                  key={idx}
                  label={item.pincode}
                  color="warning"
                  onDelete={() => handleDelete(item)}
                />
              );
            })}
          </Stack>
        ) : null}
        {blockingMethods === typeOfBlocking.bookingType ? (
          <Stack
            direction="row"
            spacing={1}
            columnGap={1}
            rowGap={1}
            mb={2}
            sx={{ overflow: "auto", flexWrap: "wrap" }}
          >
            {bookingTypes.map((item, idx) => {
              return (
                <Chip
                  key={idx}
                  label={orderTypeName(item.type)}
                  color="warning"
                  onDelete={() => handleDelete(item)}
                />
              );
            })}
          </Stack>
        ) : null}
        {blockingMethods === typeOfBlocking.areas ? (
          <Stack
            direction="row"
            spacing={1}
            columnGap={1}
            rowGap={1}
            mb={2}
            sx={{ overflow: "auto", flexWrap: "wrap" }}
          >
            {areas.map((item, idx) => {
              return (
                <Chip
                  key={idx}
                  label={item.area}
                  color="warning"
                  onDelete={() => handleDelete(item)}
                />
              );
            })}
          </Stack>
        ) : null}
        {blockingMethods === typeOfBlocking.districts ? (
          <Stack
            direction="row"
            spacing={1}
            columnGap={1}
            rowGap={1}
            mb={2}
            sx={{ overflow: "auto", flexWrap: "wrap" }}
          >
            {districts.map((item, idx) => {
              return (
                <Chip
                  key={idx}
                  label={item.district}
                  color="warning"
                  onDelete={() => handleDelete(item)}
                />
              );
            })}
          </Stack>
        ) : null}
        {blockingMethods === typeOfBlocking.states ? (
          <Stack
            direction="row"
            spacing={1}
            columnGap={1}
            rowGap={1}
            mb={2}
            sx={{ overflow: "auto", flexWrap: "wrap" }}
          >
            {states.map((item, idx) => {
              return (
                <Chip
                  key={idx}
                  label={item.state}
                  color="warning"
                  onDelete={() => handleDelete(item)}
                />
              );
            })}
          </Stack>
        ) : null}
        {blockingMethods === typeOfBlocking.countries ? (
          <Stack
            direction="row"
            spacing={1}
            columnGap={1}
            rowGap={1}
            mb={2}
            sx={{ overflow: "auto", flexWrap: "wrap" }}
          >
            {countries.map((item, idx) => {
              return (
                <Chip
                  key={idx}
                  label={item.country}
                  color="warning"
                  onDelete={() => handleDelete(item)}
                />
              );
            })}
          </Stack>
        ) : null}
        <Box sx={{ float: "left" }}>
          {blockingMethods === typeOfBlocking.dateRange &&
          Object.keys(daterange).length > 0 ? (
            <Chip
              label={`From: ${daterange?.from_date} To: ${daterange?.to_date}`}
              color="warning"
              onDelete={() => handleDelete(daterange)}
            />
          ) : null}
        </Box>
      </Box>
      <form onSubmit={onSubmitBookingBlock}>
        <Grid container mt={1} spacing={2}>
          {blockingMethods === typeOfBlocking.dateRange ? (
            <Grid item md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  label="From"
                  value={fromDate}
                  onChange={handleFromDate}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      required
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  label="To"
                  value={toDate}
                  onChange={handleToDate}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      required
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          ) : null}

          {blockingMethods === typeOfBlocking.bookingType ? (
            <Grid item md={4}>
              <Box sx={{ width: "12rem" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-order-type">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="select-label-order-type"
                    id="select-order-type"
                    value={order}
                    label="Order Type"
                    required
                    onChange={(e) => setOrder(e.target.value)}
                  >
                    {Object.keys(orderType).map((key, idx) => {
                      return (
                        <MenuItem key={idx} value={orderType[key]}>
                          {orderTypeName(orderType[key])}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          ) : null}

          {/* For Country dropdown  */}
          {blockingMethods === typeOfBlocking.areas ||
          blockingMethods === typeOfBlocking.districts ||
          blockingMethods === typeOfBlocking.states ||
          blockingMethods === typeOfBlocking.pincode ||
          blockingMethods === typeOfBlocking.countries ? (
            <Grid item md={4}>
              {/* <TextField
                id="outlined-basic"
                label="Country"
                variant="outlined"
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              /> */}
              <Box sx={{ width: "12rem" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-country">Country</InputLabel>
                  <Select
                    labelId="select-label-country"
                    id="Select-country"
                    value={country}
                    label="Country"
                    required
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {allAvailableCountries?.map((item, idx) => {
                      return (
                        <MenuItem key={item} value={item.country}>
                          {item.country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          ) : null}

          {/* For States */}
          {blockingMethods === typeOfBlocking.areas ||
          blockingMethods === typeOfBlocking.districts ||
          blockingMethods === typeOfBlocking.pincode ||
          blockingMethods === typeOfBlocking.states ? (
            <Grid item md={4}>
              <Box sx={{ width: "12rem" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-State">State</InputLabel>
                  <Select
                    labelId="select-label-State"
                    id="Select-State"
                    value={state}
                    label="State"
                    required
                    onChange={(e) => setState(e.target.value)}
                  >
                    {allAvailableStates?.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item.state_name}>
                          {item.state_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          ) : null}

          {/* District Select */}
          {blockingMethods === typeOfBlocking.districts ||
          blockingMethods === typeOfBlocking.areas ||
          blockingMethods === typeOfBlocking.pincode ? (
            <Grid item md={4}>
              {/* <TextField
                id="outlined-basic"
                label="District"
                variant="outlined"
                required
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              /> */}
              <FormControl fullWidth>
                <InputLabel id="select-label-District">District</InputLabel>
                <Select
                  labelId="select-label-District"
                  id="Select-District"
                  value={district}
                  label="District"
                  required
                  onChange={(e) => setDistrict(e.target.value)}
                >
                  {allAvailableDistricts?.map((item, idx) => {
                    return (
                      <MenuItem key={item} value={item.district}>
                        {item.district}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          {/* For Pincodes dropdown */}
          {blockingMethods === typeOfBlocking.areas ||
          blockingMethods === typeOfBlocking.pincode ? (
            <Grid item md={4}>
              <Box sx={{ width: "12rem" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-pincode">Pincode</InputLabel>
                  <Select
                    labelId="select-label-pincode"
                    id="Select-pincode"
                    value={pincode}
                    label="Pincode"
                    required
                    onChange={(e) => setPincode(e.target.value)}
                  >
                    {allAvailablePincodes?.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item.pincode}>
                          {item.pincode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          ) : null}

          {blockingMethods === typeOfBlocking.areas ? (
            <Grid item md={4}>
              {/* <TextField
                id="outlined-basic"
                label="Area"
                variant="outlined"
                required
                value={area}
                onChange={(e) => setArea(e.target.value)}
              /> */}
              <FormControl fullWidth>
                <InputLabel id="select-label-Area">Area</InputLabel>
                <Select
                  labelId="select-label-Area"
                  id="Select-Area"
                  value={area}
                  label="Area"
                  required
                  onChange={(e) => setArea(e.target.value)}
                >
                  {allAvailableAreas?.map((item, idx) => {
                    return (
                      <MenuItem key={item} value={item.locality}>
                        {item.locality}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item md={4}>
            <TextField
              id="outlined-multiline-flexible"
              label="Reason.."
              variant="outlined"
              required
              multiline
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box sx={{ float: "right" }}>
          <Button variant="contained" type="submit">
            Block
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default BookingBlock;
