import React from "react";
import * as Material from "@mui/material";
import WfsUserDetailsCard from "../../Components/Cards/WfsUserDetailsCard";
import {
  GetAssignedWfsDetails,
  GetUserStockDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { useDispatch, useSelector } from "react-redux";
import WfsStockDetailsCard from "../../Components/Cards/WfsStockDetailsCard";
import CustomConsole from "../../CustomConsole";

function UserStockDetails(props) {
  const dispatch = useDispatch();
  const wfsStockDetails = useSelector((state) => state.userDetailsUpdate);
  CustomConsole(wfsStockDetails.assignedWfsStock);
  React.useEffect(() => {
    dispatch(GetAssignedWfsDetails(props.userId));
    return () => {
      wfsStockDetails.assignedWfsStock = [];
    };
  }, []);
  return (
    <Material.Box>
      <WfsStockDetailsCard wfsStockDetails={wfsStockDetails} />
    </Material.Box>
  );
}

export default UserStockDetails;
