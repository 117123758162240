/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import CustomConsole from "../../CustomConsole";
// import {
//   EditOrderUser,
//   GetPwdPartircularOrder,
// } from "../../../Redux/actions/editOrderAction";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  EditOrderUser,
  GetNewSearchOrderDetails,
  GetViewOrderDetails,
  LessReturnOrder,
} from "../Redux/Actions/ordersAdminAction";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";
import ConfirmationDialog from "./ConfirmationDialog";

export default function EditOrderDialog(props) {
  const dispatch = useDispatch();
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);
  const newAllOrderDetails = allOrderData?.newallOrderDetails || [];

  // Example: Check the first element if it exists
  if (newAllOrderDetails.length > 0) {
    const firstOrderDetail = newAllOrderDetails[0];
    console.log(firstOrderDetail.areaname);
  }
  console.log(allOrderData, "testing-purpose");

  const [editOrderFlag, setEditOrderFlag] = React.useState(false);
  const [depositDetails, setDepositDetails] = React.useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setDepositDetails(null);
  };

  React.useEffect(() => {
    CustomConsole(props);
    CustomConsole("---------------------------------------------------------");
    CustomConsole(props.userStock);
  }, [props]);

  React.useEffect(() => {
    CustomConsole(`subOrderAmount ${props.subOrderAmount}`);
    CustomConsole(`subOrderId ${props.subOrderId}`);
  }, [props.subOrderAmount, props.subOrderId]);

  React.useEffect(() => {
    CustomConsole(props.userStock);
  }, [props.userStock]);
  const [orderedCans, setOrderedCans] = React.useState(0);
  const [cansToReturn, setCansToReturn] = React.useState(0);

  React.useEffect(() => {
    // CustomConsole(props.productorderDetails);
    console.log("------setproduct details-----");
    console.log(props.productorderDetails);
    CustomConsole(props.productorderDetails.return);
    setOrderedCans(props.productorderDetails.order_qty);
    setCansToReturn(props.productorderDetails.return);
  }, [props.productorderDetails]);

  const orderedCanMax = props.productorderDetails.order_qty;

  const currentOngoingOrders =
    props.userStock.ongoing_ordered_containers -
    props.productorderDetails.order_qty;
  const canToReturnMax =
    props.userStock.total_deposited_containers_qty -
    props.userStock.ongoing_ordered_containers +
    props.productorderDetails.received_empty;

  const currentEmptySubmittingCanCount =
    props.userStock.empty_containers_submitting -
    props.productorderDetails.received_empty;

  const returnCanMax =
    props.userStock.filled_container_qty - currentEmptySubmittingCanCount;

  const userExpectedStock1 =
    parseInt(props.userStock.filled_container_qty) +
    parseInt(currentOngoingOrders) -
    parseInt(currentEmptySubmittingCanCount);
  // const userNewStock = userExpectedStock1 + orderedCans - cansToReturn;

  CustomConsole(userExpectedStock1);
  CustomConsole(orderedCans);
  CustomConsole(cansToReturn);
  CustomConsole(
    parseInt(userExpectedStock1) +
      parseInt(orderedCans) -
      parseInt(cansToReturn)
  );
  const [userNewStock, setUserNewStock] = React.useState(
    parseInt(userExpectedStock1) +
      parseInt(orderedCans) -
      parseInt(cansToReturn)
  );
  CustomConsole(`currentOngoingOrders ${currentOngoingOrders}`);
  CustomConsole(
    `currentEmptySubmittingCanCount ${currentEmptySubmittingCanCount}`
  );
  CustomConsole(`userExpectedStock1 ${userExpectedStock1}`);
  CustomConsole(`userNewStock ${userNewStock}`);

  CustomConsole("returnCanMax Here..........................");
  CustomConsole(returnCanMax);
  CustomConsole("-------------------");
  CustomConsole(currentOngoingOrders);
  CustomConsole(canToReturnMax);

  // const returnCanMax =
  //   props.userStock.filled_container_qty -
  //   props.userStock.empty_containers_submitting;

  const ongoingOrderdCans = props.userStock.ongoing_ordered_containers;
  const emptyContainerSubmitting = props.userStock.empty_containers_submitting;
  const filledContainerQty = props.userStock.filled_container_qty;

  const handleEditConfirm = () => {
    setEditOrderFlag(true);
  };

  // Confirm deposit and hit LessReturnOrder
  const handleConfirmDeposit = () => {
    const reqObj = {
      order_id: props.orderId,
      products: [
        {
          capacity: props.productorderDetails.capacity,
          material: props.productorderDetails.material,
          quantity: orderedCans,
          return: cansToReturn,
          is_crate: props.productorderDetails.is_crate,
        },
      ],
    };
    dispatch(LessReturnOrder(reqObj)); // Dispatch the LessReturnOrder action
    handleCloseConfirmationDialog();
  };

  React.useEffect(() => {
    if (editOrderFlag === true) {
      // if (extraCansToDeposit > 0) {
      //   props.setPaymentMethodDialog(true);
      // }
      // const reqObj = {
      //   return_can_qty: cansToReturn,
      //   order_can_qty: orderedCans,
      //   edit_reason: props.editOrderReason,
      //   user_id: props.userId,
      // };

      console.log("----props for is_crate----");
      console.log(props);

      const reqObj = {
        order_id: props.orderId,
        products: [
          {
            capacity: props.productorderDetails.capacity,
            material: props.productorderDetails.material,
            quantity: orderedCans,
            return: cansToReturn,
            is_crate: props.productorderDetails.is_crate,
          },
        ],
      };
      // const filteredProducts = newAllOrderDetails
      // .map(orderDetail =>
      //   orderDetail.products?.filter(
      //     product =>
      //       product.capacity === props.productorderDetails.capacity &&
      //       product.material === props.productorderDetails.material
      //   ) || []  // Return empty array if products is undefined
      // );

      // Logging the filtered products

      CustomConsole(reqObj);
      // dispatch(EditOrderUser(props.orderId, reqObj));
      // dispatch(EditOrderUser(reqObj));
      dispatch(EditOrderUser(reqObj)).then((response) => {
        if (
          response.error &&
          response.deposit_details &&
          response.deposit_details.quantity > 0
        ) {
          // If deposit details exist, open the deposit dialog
          setDepositDetails(response.deposit_details);
          setOpenConfirmationDialog(true);
        }
      });
      setTimeout(() => {
        // dispatch(GetViewOrderDetails(props.orderId));
        const reqObj = {
          delivery_from_date: moment().format("YYYY-MM-DD"),
          delivery_to_date: moment().format("YYYY-MM-DD"),
          page_size: 30,
          page_number: 1,
          order_ids: [props.orderId],
        };
        dispatch(GetNewSearchOrderDetails(reqObj));
      }, 500);
      setEditOrderFlag(false);
      props.setIsEditOrderDialogOpen(false);
      props.setIsEditOrderReasonChoosen(false);
      props.setOrderFlag(true);
    }
  }, [editOrderFlag]);

  const handleBack = () => {
    props.setIsEditOrderDialogOpen(false);
    props.setIsReasonChoosen(false);
    props.setIsEditOrderReasonChoosen(false);
    // setOrderedCans(props.productorderDetails.order_qty);
    // setCansToReturn(props.productorderDetails.return);
  };

  // const [currentOngoingCanCount, setCurrentOngoingCanCount] = React.useState(0);
  // const [currentEmptySubmittingCanCount, setCurrentEmptySubmittingCanCount] =
  //   React.useState(0);
  // const [userExpected, setUserExpected] = React.useState(0);
  // const [userNewStock, setUserNewStock] = React.useState(0);

  // React.useEffect(() => {
  //   setCurrentOngoingCanCount(ongoingOrderdCans - orderedCans);
  //   setCurrentEmptySubmittingCanCount(emptyContainerSubmitting - cansToReturn);
  //   setUserExpected(
  //     filledContainerQty +
  //       currentOngoingCanCount -
  //       currentEmptySubmittingCanCount
  //   );
  //   setUserNewStock(userExpected + orderedCans + cansToReturn);
  // }, [orderedCans, cansToReturn]);

  // React.useEffect(() => {
  //   CustomConsole(`currentOngoingCanCount: ${currentOngoingCanCount}`);
  //   CustomConsole(
  //     `currentEmptySubmittingCanCount: ${currentEmptySubmittingCanCount}`
  //   );
  //   CustomConsole(`userExpected: ${userExpected}`);
  //   CustomConsole(`userNewStock: ${userNewStock}`);
  // }, [
  //   currentOngoingCanCount,
  //   currentEmptySubmittingCanCount,
  //   userExpected,
  //   userNewStock,
  // ]);

  React.useEffect(() => {
    CustomConsole(`min ${props.min}`);
    CustomConsole(`max ${props.max}`);
  }, [props.min, props.max]);

  const [userExpectedStock, setUserExpectedStock] = React.useState(0);
  const [returnMinus, setReturnMinus] = React.useState(false);
  const [orderPlus, setOrderPlus] = React.useState(false);

  const [extraDepositAmount, setExtraDepositAmount] = React.useState(0);
  const [extraCansToDeposit, setExtraCansToDeposit] = React.useState(0);

  // React.useEffect(() => {
  //   CustomConsole("setting user expected stock");
  //   CustomConsole(ongoingOrderdCans + orderedCans - cansToReturn);
  //   // setUserExpectedStock(ongoingOrderdCans + orderedCans - cansToReturn);
  //   setUserNewStock(
  //     parseInt(userExpectedStock1) +
  //       parseInt(orderedCans) -
  //       parseInt(cansToReturn)
  //   );
  //   if (orderedCans < cansToReturn) {
  //     setCansToReturn(cansToReturn - 1);
  //   }
  // }, [cansToReturn, orderedCans]);

  React.useEffect(() => {
    CustomConsole(`ongoingOrdersCans: ${ongoingOrderdCans}`);
    CustomConsole(`userDeposited: ${props.cansUserDeposited}`);
    CustomConsole(`userExpectedStock: ${userExpectedStock}`);
    CustomConsole(`userNewStock ${userNewStock}`);
    CustomConsole(
      `Extra stock user have to pay ${userNewStock - props.cansUserDeposited}`
    );
    CustomConsole("000000000000000000000");

    CustomConsole(`currentOngoingOrders ${currentOngoingOrders}`);
    CustomConsole(
      `currentEmptySubmittingCanCount ${currentEmptySubmittingCanCount}`
    );
    CustomConsole(`userExpectedStock1 ${userExpectedStock1}`);
    CustomConsole(`userNewStock ${userNewStock}`);

    // setExtraCansToDeposit(userNewStock - 3);
    setExtraCansToDeposit(userNewStock - props.cansUserDeposited);

    if (userNewStock > props.cansUserDeposited) {
      CustomConsole(
        "user stock will be greater than the actual stock they have"
      );
      // if (returnMinus) {
      //   setOrderedCans(orderedCans - 1);
      //   setReturnMinus(false);
      // }
      CustomConsole(props.cansInUse);
      if (
        orderPlus &&
        props.cansInUse !== 0 &&
        cansToReturn < props.cansInUse &&
        returnMinus === false
      ) {
        CustomConsole("coming inside this");
        setCansToReturn(cansToReturn + 1);
        setOrderPlus(false);
      }
    }
  }, [userNewStock]);

  React.useEffect(() => {
    CustomConsole(props.depositAmountPerCan);
    CustomConsole(
      `extraDepositAmount ${props.depositAmountPerCan * extraCansToDeposit}`
    );
    setExtraDepositAmount(props.depositAmountPerCan * extraCansToDeposit);
  }, [extraCansToDeposit]);

  const handlePlusOrderCan = () => {
    setOrderPlus(true);
    if (orderedCans >= orderedCanMax) {
      toast.dark("You have reached maximum limit for this order", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    } else {
      setOrderedCans(orderedCans + 1);
    }
  };

  const handleMinusOrderCan = () => {
    if (
      props.orderData[0].packaged_drinking_water &&
      props.orderData[0].packaged_drinking_water.order_type &&
      parseInt(props.orderData[0].packaged_drinking_water.order_type) !== 700
    ) {
      if (props.min < orderedCans) {
        setOrderedCans(orderedCans - 1);
      } else {
        toast.dark("You have reached minimum limit for this order", {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
      }
    } else {
      if (orderedCans - 1 >= 1) {
        setOrderedCans(orderedCans - 1);
      } else {
        toast.dark("You have reached minimum limit for this order", {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
      }
    }
  };

  const handlePlusReturnCan = () => {
    // CustomConsole(cansToReturn);
    // CustomConsole(props.cansInUse);
    // props.orderData.packaged_drinking_water.empty_container_qty
    if (cansToReturn < returnCanMax) {
      setCansToReturn(cansToReturn + 1);
    } else {
      toast.dark("Cannot return more than what you posses", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const [changeInReturnCan, setChangeInReturnCan] = React.useState(false);
  const handleMinusReturnCan = () => {
    CustomConsole(userExpectedStock);
    // setReturnMinus(true);
    setOrderPlus(false);
    if (cansToReturn === 0) {
      toast.dark("Reached maximum limit", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    } else if (cansToReturn >= 0) {
      CustomConsole("reducing return cans");
      setCansToReturn(cansToReturn - 1);
      setChangeInReturnCan(true);
    }
  };
  // payment releated

  // handle payment

  return (
    <div>
      <Dialog
        open={props.isEditOrderDialogOpen}
        onClose={handleBack}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}
            >
              Edit your order
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  <RemoveCircleRoundedIcon
                    color="success"
                    onClick={handleMinusOrderCan}
                    sx={{ fontSize: 40, cursor: "pointer" }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Cans Ordered(Units): {orderedCans}
                  </Typography>
                </Box>
                <Box>
                  <AddCircleRoundedIcon
                    color="success"
                    sx={{ fontSize: 40, cursor: "pointer" }}
                    onClick={handlePlusOrderCan}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  <RemoveCircleRoundedIcon
                    onClick={handleMinusReturnCan}
                    color="success"
                    sx={{ fontSize: 40, cursor: "pointer" }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Cans being returned(units): {cansToReturn}
                  </Typography>
                </Box>
                <Box>
                  <AddCircleRoundedIcon
                    color="success"
                    sx={{ fontSize: 40, cursor: "pointer" }}
                    onClick={handlePlusReturnCan}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              {extraDepositAmount > 0 && changeInReturnCan === true ? (
                <>
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Editing order as such will require you to pay{" "}
                    <strong>
                      deposit price of {extraDepositAmount}
                    </strong> for {extraCansToDeposit} can.
                  </Alert>
                </>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={handleEditConfirm}
                size="small"
                sx={appColors.commonBtnColor}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                onClick={handleBack}
                color="error"
                size="small"
              >
                close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={openConfirmationDialog}
        handleClose={handleCloseConfirmationDialog}
        handleConfirm={handleConfirmDeposit}
        title="Deposit Required"
        content={`You need to deposit for product: ${depositDetails?.capacity} - ${depositDetails?.material} with a quantity of ${depositDetails?.quantity}.`}
      />
    </div>
  );
}
