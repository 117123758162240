/**
 * @author vinay kumar
 * @description Driver Deliveries Main
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 24-08-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import DeriverDeliveries from "./DeriverDeliveries";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import CustomConsole from "../../CustomConsole";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@mui/styles";
import CompletedDeliveries from "../SupplierDeliveries/CompletedDeliveries";
import ReturnCanOrders from "../SupplierOrders/ReturnCanOrders";
import ReplacementCansOrders from "../SupplierOrders/ReplacementCansOrders";
import CurrentDayDeliveiesNew from "../SupplierDeliveries/CurrentDayDeliveiesNew";
import DeliveriesMain from "../SupplierDeliveries/DeliveriesMain";

const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
});

function DriverDeliveriesMain() {
  const [tabValue, setTabValue] = React.useState("TodayOrders");

  const classes = useStyles();

  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Material.Box
      sx={{ marginTop: "40px", maxWidth: { xs: "380px", md: "120%" } }}
    >
      {/* <TabContext value={tabValue} variant="scrollable" scrollButtons={false}>
                <TabList
          onChange={handleChangeTabValue}
          className={classes.tabUi}
          sx={{
            backgroundColor: "#BEDEF3",
            color: "black",
          }}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab
            label="DELIVER ORDERS"
            value="TodayOrders"
            style={{ minWidth: { xs: "340px" } }}
          />
          <Tab label="Return Can Orders" value="ReturnCanOrders" />
          <Tab label="Replacement Orders" value="ReplacementOrders" />
                  </TabList>
        
        <TabPanel value="TodayOrders">
          {" "}
                    <CurrentDayDeliveiesNew />
        </TabPanel>
        <TabPanel value="ReturnCanOrders">
          <ReturnCanOrders />
        </TabPanel>
        <TabPanel value="ReplacementOrders">
          <ReplacementCansOrders />
        </TabPanel>
        </TabContext> */}
      <DeliveriesMain/>
    </Material.Box>
  );
}

export default DriverDeliveriesMain;
