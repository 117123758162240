import actionType from "./actionType";
import useAxios from "../../Axios/useAxios";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";

export const EditOrderUser = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/orders/${orderId}/editOrder`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole("Response edit order");
        CustomConsole(response);
        dispatch({
          type: actionType.EDIT_ORDER_USER,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
