import React from "react";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetWithdrawallistOrderid } from "../Redux/Actions/AdminWithdrawalListAction";
import CustomConsole from "../../CustomConsole";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import AssignDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AssignDeliveryAgentDialog";
import {
  GetSupplierDeliveryAgentDetails,
  PostSupplierAssignDeliveryAgentReturnOrReplacement,
} from "../Redux/Actions/supliersAction";
import {
  CompleteBuyBackOrderDetails,
  EditBuyBackOrderDetails,
  GetBuyBackOrderDetails,
} from "../Redux/Actions/buyBackOrdersAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  ArrowBack,
  Call,
  LocalShipping,
  Place,
  Refresh,
} from "@mui/icons-material";
import { GetQrBarcodeSwapList } from "../Redux/Actions/canSwapingAction";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { toast } from "react-toastify";
import moment from "moment";
import useProductImages from "../../Components/Custom/useProductImages";
import ImageViewer from "../../Components/Custom/imageLoader";
import ConformationDialog from "../../Components/Dialog/conformationDialog";

function BuyBackProductSingleOrder() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");
  CustomConsole(orderId);
  const adminWithdrawallistData = useSelector(
    (state) => state.withdrawalOrderlistAdmin
  );
  const consumerbuyBackOrderDetails = useSelector(
    (state) => state.buyBackOrderDetails.orderDetails
  );
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(adminWithdrawallistData);
  const [buyBackOrderData, setBuyBackOrderData] = React.useState([]);
  const [buyBackOrderDetails, setBuyBackOrderDetails] = React.useState([]);
  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [orderCompleteDetails, setOrderCompleteDetails] = React.useState({
    order_id: "",
    products: [],
  });
  const [userDeliverLocation, setUserDeliverLocation] = React.useState({
    latitude: "",
    longitude: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [adminRole, setAdminRoleId] = React.useState("");

  //function to hit api when onmount
  React.useEffect(() => {
    dispatch(
      GetBuyBackOrderDetails({
        order_id: orderId,
      })
    );
  }, []);

  //function to get role id
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  //function to insert order details in state
  React.useEffect(() => {
    // CustomConsole(adminWithdrawallistData)
    if (consumerbuyBackOrderDetails?.length > 0) {
      consumerbuyBackOrderDetails?.map((data) => {
        setBuyBackOrderData(data);
        setOrderCompleteDetails({
          ...orderCompleteDetails,
          products: data?.order_details?.products,
          order_id: data?.order_details?.order_id,
        });
      });
    } else {
      setBuyBackOrderData([]);
    }
  }, [consumerbuyBackOrderDetails]);

  //jsx ui function
  const orderStatusFun = (order_status) => {
    CustomConsole(order_status);
    switch (order_status) {
      case returnCanOrderStatus.CONSUMER_ACCEPTED:
        return "CONSUMER ACCEPTED";
      case returnCanOrderStatus.APPROVED:
        return "APPROVED";
      case returnCanOrderStatus.REFUND:
        return "REFUND";
      case returnCanOrderStatus.CONSUMER_REJECTED:
        return "CONSUMER REJECTED";
      case returnCanOrderStatus.DELIVERED:
        return "DELIVERED";
      case returnCanOrderStatus.REJECTED:
        return "REJECTED";
      case returnCanOrderStatus.PENDING:
        return "PENDING";
      default:
        return "";
    }
  };

  //function to set jsx ui for address details
  React.useEffect(() => {
    setUserDeliverLocation({
      latitude: buyBackOrderData?.delivery_address?.latitude,
      longitude: buyBackOrderData?.delivery_address?.longitude,
    });
    const OrderDetails = [
      {
        type: "Consumer Name",
        payload: buyBackOrderData?.delivery_address?.contact_name,
      },
      {
        type: "Contact Phone",
        payload: buyBackOrderData?.delivery_address?.contact_phone,
      },
      { type: "Order Id", payload: buyBackOrderData?.order_details?.order_id },
      {
        type: "Status",
        payload: orderStatusFun(buyBackOrderData?.status),
      },
      {
        type: "Booking At",
        payload: moment
          .utc(buyBackOrderData.created_at)
          .tz("Asia/Kolkata")
          .add(5, "hours")
          .add(30, "minutes")
          .format("DD-MMM-YYYY"),
      },
      {
        type: "Delivery Agent Name",
        payload:
          buyBackOrderData?.driver_profile?.fullname !== ""
            ? buyBackOrderData?.driver_profile?.fullname
            : "--",
      },
      {
        type: "Delivery Agent Phone",
        payload:
          buyBackOrderData?.driver_profile?.phone !== ""
            ? buyBackOrderData?.driver_profile?.phone
            : "unknown",
      },
    ];

    setBuyBackOrderDetails(OrderDetails);
  }, [buyBackOrderData]);

  //function to reload a page
  const refreshOrderDetails = () => {
    // dispatch(GetWithdrawallistOrderid(orderId));
    window.location.reload(true);
  };

  //function to handel back options
  const handleBack = () => {
    CustomConsole("handleBack");
    window.history.back();
  };

  //function to assign delivery agent
  const handleOpenAssignDeliveryAgentDialog = (props) => {
    CustomConsole(props);
    CustomConsole("---handleOpenAssign-------");
    dispatch(GetSupplierDeliveryAgentDetails());
    setOpenAssignDeliveryAgentDialog(true);
    // CustomConsole(replacementOrderId);
  };

  //function to close assign delivery agent dialog
  const handleCloses = () => {
    setOpenAssignDeliveryAgentDialog(false);
  };

  //function to assign delivery agent
  const handleAssignDeliveryAgent = (props) => {
    CustomConsole(props);
    const reqObj = {
      delivery_agent_id: props.driver_id,
      order_id: buyBackOrderData?.order_details?.order_id,
    };
    CustomConsole(reqObj);
    dispatch(EditBuyBackOrderDetails(reqObj)).then((data) => {
      console.log(data.error);
      if (data.error === false) {
        dispatch(
          GetBuyBackOrderDetails({
            order_id: orderId,
          })
        );
      }
    });
    setOpenAssignDeliveryAgentDialog(false);
  };

  //function to submit order details
  const handleSubmitReturn = () => {
    dispatch(CompleteBuyBackOrderDetails(orderCompleteDetails)).then((data) => {
      if (data?.error === false) {
        setConfirmDialog(false);
        dispatch(
          GetBuyBackOrderDetails({
            order_id: orderId,
          })
        );
      }
    });
  };

  const handleGMapButton = () => {
    CustomConsole(userDeliverLocation);
    if (
      userDeliverLocation.latitude !== undefined &&
      userDeliverLocation.latitude !== null &&
      userDeliverLocation.longitude !== undefined &&
      userDeliverLocation.longitude !== null &&
      parseFloat(userDeliverLocation.latitude) > 0 &&
      parseFloat(userDeliverLocation.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${userDeliverLocation.latitude},${userDeliverLocation.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    console.log(props);
    if (props?.delivery_address?.contact_phone !== "") {
      CustomConsole(props?.delivery_address?.contact_phone);
      const telUrl = `tel:${props?.delivery_address?.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // Function to handle adding quantity (insert if first time)
  const handelAddQty = (product) => {
    console.log(product);
    setOrderCompleteDetails((prevDetails) => {
      const productExists = prevDetails.products.find(
        (data) =>
          data.container_capacity === product.container_capacity &&
          data.container_material === product.container_material
      );
      console.log(productExists);
      if (productExists) {
        return {
          ...prevDetails,
          products: prevDetails.products.map((data) =>
            data.container_capacity === product.container_capacity &&
            data.container_material === product.container_material &&
            data.received_qty + 1 <= data?.order_qty
              ? { ...data, received_qty: data.received_qty + 1 }
              : data
          ),
        };
      } else {
        return {
          ...prevDetails,
          products: [...prevDetails.products, { ...product, received_qty: 1 }],
        };
      }
    });
  };

  // Function to handle reducing quantity (insert if first time)
  const handelReduseQty = (product) => {
    setOrderCompleteDetails((prevDetails) => {
      const productExists = prevDetails.products.find(
        (data) =>
          data.container_capacity === product.container_capacity &&
          data.container_material === product.container_material
      );
      console.log("jjfjj");

      if (productExists) {
        // If product exists and qty is greater than 1, reduce the quantity
        return {
          ...prevDetails,
          products: prevDetails.products.map((data) =>
            data.container_capacity === product.container_capacity &&
            data.container_material === product.container_material &&
            data.received_qty - 1 > 0
              ? { ...data, received_qty: data.received_qty - 1 }
              : data
          ),
        };
      } else {
        return {
          ...prevDetails,
          products: [...prevDetails.products, { ...product, received_qty: 1 }],
        };
      }
    });
  };

  // Function to handle manual quantity change
  const handelQtyChange = (product, value) => {
    let newQty = value === "" ? "" : parseInt(value); // Set to 0 if the value is empty (from backspace)

    // Prevent negative or NaN values
    if (isNaN(newQty) || newQty < 0) {
      newQty = 0;
    }

    console.log(newQty);

    setOrderCompleteDetails((prevDetails) => ({
      ...prevDetails,
      products: prevDetails.products.map((data) =>
        data.container_capacity === product.container_capacity &&
        data.container_material === product.container_material &&
        newQty <= data?.order_qty
          ? { ...data, received_qty: newQty }
          : data
      ),
    }));
  };

  //hook to download images
  //hook to download images
  const { images, isLoading, error } = useProductImages(
    orderCompleteDetails?.products,
    "booking"
  );
  return (
    <Box sx={{ marginTop: "50px", ml: "5px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          xs={12}
          component={Paper}
          elevation={20}
          // sx={{ pr: "10px", pt: "10px" }}
        >
          <Box>
            {/* ORDER DETAILS */}
            <Box
              sx={{
                bgcolor: "#D6EAF8",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleBack}
                  sx={{
                    // backgroundColor: "#34f7b3",
                    color: "black",
                    borderRadius: "20px",
                  }}
                >
                  <ArrowBack />
                </Button>
              </Box>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px", mt: "3px" }}
              >
                ORDER DETAILS
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={refreshOrderDetails}
                  sx={{
                    // backgroundColor: "#d8f59f",
                    color: "black",
                    borderRadius: "100px",
                  }}
                >
                  <Refresh />
                </Button>
              </Box>
            </Box>

            <Divider
              sx={{
                height: `${1}px`, // Adjust the thickness as needed
                borderStyle: "dashed",
                border: "1px solid black",
              }}
            />
            <Box sx={{ bgcolor: "#F4F6F7", p: "10px" }}>
              {buyBackOrderDetails.map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "#717D7E",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {data.payload}
                    </Typography>
                  </Box>
                );
              })}
              <Box>
                {/* CONSUMER ADDRESS */}
                <Box
                  sx={{
                    bgcolor: "#D6EAF8",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {ADMIN_ROLES.BW_SUPPLIERMANAGER === adminRole ||
                  ADMIN_ROLES.BW_DISTRIBUTER === adminRole ? (
                    <Box>
                      <Button
                        disabled={parseInt(buyBackOrderData.status) !== 200}
                        variant="outlined"
                        size="small"
                        onClick={handleOpenAssignDeliveryAgentDialog}
                        sx={{
                          // backgroundColor: "#34f7b3",
                          color: "black",
                          borderRadius: "20px",
                        }}
                      >
                        Assign
                        <LocalShipping />
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      mt: "3px",
                      mr: "40px",
                    }}
                  >
                    ADDRESS
                  </Typography>
                  <Box>
                    <Button
                      disabled={parseInt(buyBackOrderData.order_status) === 200}
                      variant="outlined"
                      size="small"
                      onClick={handleGMapButton}
                      sx={{
                        color: "blue",
                        borderRadius: "50px",
                      }}
                    >
                      <Place sx={{ color: "red" }} /> Maps
                    </Button>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: `${1}px`, // Adjust the thickness as needed
                    borderStyle: "dashed",
                    border: "1px solid black",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: "#F4F6F7",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>
                    {buyBackOrderData?.delivery_address !== undefined &&
                    buyBackOrderData?.delivery_address !== null &&
                    buyBackOrderData?.delivery_address.address_line1 !=
                      undefined &&
                    buyBackOrderData?.delivery_address.address_line1 != ""
                      ? buyBackOrderData?.delivery_address.address_line1
                      : "--"}
                    ,
                  </Typography>
                  <Typography>
                    {buyBackOrderData?.delivery_address !== undefined &&
                    buyBackOrderData?.delivery_address !== null &&
                    buyBackOrderData?.delivery_address.address_line2 !=
                      undefined &&
                    buyBackOrderData?.delivery_address.address_line2 != ""
                      ? buyBackOrderData?.delivery_address.address_line2
                      : "--"}
                    ,
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      {buyBackOrderData?.delivery_address !== undefined &&
                      buyBackOrderData?.delivery_address !== null &&
                      buyBackOrderData?.delivery_address.service_area !=
                        undefined &&
                      buyBackOrderData?.delivery_address.service_area != ""
                        ? buyBackOrderData?.delivery_address.service_area
                        : "--"}{" "}
                      -
                    </Typography>
                    <Typography>
                      {buyBackOrderData?.delivery_address !== undefined &&
                      buyBackOrderData?.delivery_address !== null &&
                      buyBackOrderData?.delivery_address.pincode != undefined &&
                      buyBackOrderData?.delivery_address.pincode != ""
                        ? buyBackOrderData?.delivery_address.pincode
                        : "--"}
                    </Typography>
                    <Box>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handlePhone(buyBackOrderData)}
                        sx={{
                          color: "white",
                          borderRadius: "50px",
                          bgcolor: "#239B56",
                        }}
                      >
                        <Call />
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ bgcolor: "#D6EAF8" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      RECEIVING DETAILS
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      height: `${1}px`, // Adjust the thickness as needed
                      borderStyle: "dashed",
                      border: "1px solid black",
                    }}
                  />
                  <Box sx={{ display: "flex" }}>
                    <Typography sx={{ m: 1, color: "red" }}>Note:</Typography>
                    <Checkbox defaultChecked disabled />
                    <Typography sx={{ m: 1 }}>Receiving</Typography>
                  </Box>
                  {/* Empty RECEIVING */}
                  <Box
                    sx={{
                      bgcolor: "#F4F6F7",

                      // justifyContent: "space-around",
                      pt: "10px",
                      gap: 1,
                      marginBottom: "1rem",
                      width: "100%",

                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {orderCompleteDetails?.products?.map((data) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderRadius: "1rem",
                            boxShadow: 5,
                            padding: "1rem 1rem 0 0",
                            alignItems: "center",
                            flexDirection: { xs: "row" },
                            marginBottom: ".5rem",
                            minWidth: { xs: "100%", md: "40%" },
                          }}
                        >
                          <Box
                            sx={{
                              width: "5rem",
                            }}
                          >
                            <ImageViewer filename={data?.image} />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: { xs: "column", sm: "row" },
                              marginBottom: ".5rem",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`${data?.container_capacity}/${data?.container_material}`}
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                  }}
                                >
                                  {`Order Qty = (${data?.order_qty})`}
                                </Typography>
                              </Box>
                            </Box>
                            <div style={{ margin: ".5rem" }}></div>
                            <OrderButtonSku
                              handelAddQuantity={() => handelAddQty(data)}
                              handelMinusQuantity={() => handelReduseQty(data)}
                              handelQuantityChange={(event) =>
                                handelQtyChange(data, event.target.value)
                              }
                              inputValue={parseInt(data?.received_qty)}
                            />
                            <div style={{ marginBottom: ".5rem" }}></div>
                            <Divider />
                          </Box>
                        </Box>
                      </>
                    ))}
                  </Box>
                  {buyBackOrderData?.status === 200 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        onClick={() => setConfirmDialog(true)}
                      >
                        submit
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <AssignDeliveryAgentDialog
        openAssignDeliveryAgentDialog={openAssignDeliveryAgentDialog}
        agentListata={supplierDeliveries.getSupplerSupplierDeliveryAgentList}
        handleClickAssignDeliveryAgent={handleAssignDeliveryAgent}
        hadleClose={handleCloses}
      />
      <ConformationDialog
        setConfirmDialog={setConfirmDialog}
        confirmDialog={confirmDialog}
        dialogMsg="Are you certain that the entered quantities are accurate and verified?"
        handelProceed={handleSubmitReturn}
      />
    </Box>
  );
}

export default BuyBackProductSingleOrder;
