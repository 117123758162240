/**
 * @author Lebansty Valan
 * @date 08/05/2023
 * @description  Card component to reuse.
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { ordersStatus } from "../../Components/AppMeta/appMetaConfig";
import { DELIVERY_TYPE } from "../../Components/AppMeta/appMetaConfig";
import { styles } from "../Cards/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import CustomConsole from "../../CustomConsole";
import moment from "moment-timezone";

function OrdersCard({ data, clickFun }) {
  const history = useHistory();

  const orderStatus = (order_status) => {
    switch (order_status) {
      case ordersStatus.ORDER_STATUS_NEW_REQUEST:
        return "NEW REQUEST";
      case ordersStatus.ORDER_STATUS_CANCELLED:
        return "CANCELLED";
      case ordersStatus.ORDER_STATUS_ACCEPTED:
        return "ACCEPTED ";
      case ordersStatus.ORDER_STATUS_REJECTED:
        return "REJECTED";
      case ordersStatus.ORDER_STATUS_DELIVERED:
        return "DELIVERED";
      default:
        return "";
    }
  };
  const dateConverter = (dateText) => {
    const momentDate = moment(dateText);
    const formattedDate = momentDate.format("DD-MM-YYYY");
    return formattedDate;
  };

  const endDateConverter = (endDate) => {
    CustomConsole(endDate);
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    return momentEndDate;
  };
  const timeSeperator = (dateTime) => {
    const date = moment.utc(dateTime);
    const time = date.local().format("LT");
    return time;
  };
  const orderedDateConverter = (date) => {
    let orderedOn = moment.utc(date).format("DD-MM-YYYY");
    return orderedOn;
  };
  CustomConsole(data);

  const handleReDirectToUserPage = () => {
    CustomConsole("-----------handleReDirectToUserPage----------");
    // Redirecting to users page with the users selected consumer
    const url = `/dashboard/users?phoneNumber=${data.delivery_address.contact_phone}`;
    history.push(url);
  };

  return (
    <Card
      sx={{
        marginTop: "10px",

        "&:hover": {
          boxShadow:
            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
        },

        // opacity:openAcco===idx?0:1,
      }}
      elevation={10}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: data.delivery_details?.delivery_otp
              ? "space-between"
              : "flex-end",
            alignItems: "center",
          }}
        >
          {data.delivery_details?.delivery_otp ? (
            <span style={{ color: "green", fontSize: "13px", fontWeight: 600 }}>
              OTP:{data.delivery_details.delivery_otp}
            </span>
          ) : null}
          {data.packaged_drinking_water.order_type ===
          DELIVERY_TYPE.INSTA_DELIVERY ? (
            <>
              {/* <FcInTransit
              style={{ fontSize: "25px", marginRight: "10px" }}
            /> */}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#5DADE2",
                }}
              >
                {" "}
                Insta Delivery Order
              </span>
            </>
          ) : data.payment_type === 300 ? (
            <>
              {/* <FcInTransit
              style={{ fontSize: "25px", marginRight: "10px" }}
            />{" "} */}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#5DADE2",
                }}
              >
                {" "}
                Postpaid Order
              </span>
            </>
          ) : data.packaged_drinking_water.is_subscribed_order ? (
            <>
              {/* <FcInTransit
              style={{ fontSize: "25px", marginRight: "10px" }}
            /> */}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#5DADE2",
                }}
              >
                {" "}
                Subscription Order
              </span>
            </>
          ) : (
            <span
              style={{
                fontSize: "12px",
                fontWeight: 600,
                color: "#5DADE2",
              }}
            >
              {" "}
              Regular Order
            </span>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              md={6}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={styles.cardStyleHeading}>
                  Order Id:
                  <span style={styles.cardStyleValue}>{data.order_id}</span>
                </Typography>

                <Typography sx={styles.cardStyleHeading}>
                  Order status:
                  <span
                    style={{
                      ...styles.cardStyleValue,
                      color:
                        data.order_status === ordersStatus.ORDER_STATUS_ACCEPTED
                          ? "green"
                          : data.order_status ===
                            ordersStatus.ORDER_STATUS_CANCELLED
                          ? "red"
                          : data.order_status ===
                            ordersStatus.ORDER_STATUS_NEW_REQUEST
                          ? "#CBCD42"
                          : "black",
                    }}
                  >
                    {orderStatus(data.order_status)}
                  </span>
                </Typography>

                <Typography sx={styles.cardStyleHeading}>
                  Order Quantity:
                  <span style={styles.cardStyleValue}>
                    {data.packaged_drinking_water.twentyltr_capacity_qty}
                  </span>
                </Typography>

                <Typography sx={styles.cardStyleHeading}>
                  Date of booking:
                  <span style={styles.cardStyleValue}>
                    {dateConverter(data.order_date)}
                  </span>
                </Typography>
                <Typography sx={styles.cardStyleHeading}>
                  Delivery On:
                  <span style={styles.cardStyleValue}>
                    {endDateConverter(data.end_date)}
                  </span>
                </Typography>
                <Typography sx={styles.cardStyleHeading}>
                  Payment Type:
                  <span style={styles.cardStyleValue}>
                    {data.payment_type === 100 ? "Prepaid" : "Postpaid"}
                  </span>
                </Typography>
                <Typography sx={styles.cardStyleHeading}>
                  Ordred Time:
                  <span style={styles.cardStyleValue}>
                    {timeSeperator(data.order_date)}
                  </span>
                </Typography>
                <Typography sx={styles.cardStyleHeading}>
                  Discount:
                  <span style={styles.cardStyleValue}>
                    {data.packaged_drinking_water.discount_price}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {/* <Typography sx={styles.cardStyleHeading}>
                Area:
                <span style={styles.cardStyleValue}>
                  {data.delivery_address.service_area}
                </span>
              </Typography> */}
                <Typography sx={styles.cardStyleHeading}>
                  Pincode:
                  <span style={styles.cardStyleValue}>
                    {data.delivery_address.pincode}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    ...styles.cardStyleHeading,
                    "&:hover": {
                      boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
                    },
                  }}
                  onClick={handleReDirectToUserPage}
                >
                  Consumer Name:
                  <span style={styles.cardStyleValue}>
                    {data.consumer_name}
                  </span>
                </Typography>
                <Typography sx={styles.cardStyleHeading}>
                  Amount:
                  <span style={styles.cardStyleValue}>{data.final_price}</span>
                </Typography>

                <Typography sx={styles.cardStyleHeading}>
                  Area:
                  <span style={styles.cardStyleValue}>
                    {data.delivery_address.service_area}
                  </span>
                </Typography>

                <Typography sx={styles.cardStyleHeading}>
                  Supplier:
                  <span style={styles.cardStyleValue}>
                    {data.supplier_name}
                  </span>
                </Typography>
                <Typography sx={styles.cardStyleHeading}>
                  Payment Status:
                  <span style={styles.cardStyleValue}>
                    {data.payment_status === 100 ? "Pending" : "Done"}
                  </span>
                </Typography>
                {/* <Typography sx={styles.cardStyleHeading}>
                  Ordered Date:
                  <span style={styles.cardStyleValue}>
                    {orderedDateConverter(data.order_date)}
                  </span>
                </Typography> */}
                {data.packaged_drinking_water.delivery_slots !== undefined ? (
                  <>
                    <Typography sx={styles.cardStyleHeading}>
                      Delivery Slot:
                      <span style={styles.cardStyleValue}>
                        {data.packaged_drinking_water.delivery_slots
                          .admin_selected_delivery_slot !== undefined &&
                        data.packaged_drinking_water.delivery_slots
                          .admin_selected_delivery_slot !== null &&
                        data.packaged_drinking_water.delivery_slots
                          .admin_selected_delivery_slot
                          ? data.packaged_drinking_water.delivery_slots
                              .admin_selected_delivery_slot
                          : data.packaged_drinking_water.delivery_slots
                              .supplier_selected_delivery_slot !== undefined &&
                            data.packaged_drinking_water.delivery_slots
                              .supplier_selected_delivery_slot !== null &&
                            data.packaged_drinking_water.delivery_slots
                              .supplier_selected_delivery_slot
                          ? data.packaged_drinking_water.delivery_slots
                              .supplier_selected_delivery_slot
                          : "-"}
                      </span>
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ textAlign: "center" }}>
                      Distributor has not choosen delivery slot.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {data.order_status === ordersStatus.ORDER_STATUS_CANCELLED ? null : (
            <Button variant="contained" onClick={() => clickFun(true)}>
              Edit/Update
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default OrdersCard;
