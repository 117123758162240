import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme,
  CardContent,
} from "@mui/material";
import WaterWallet from "./WaterWallet";
import PendingAmount from "./PendingAmount";
import { GetProfileDetails } from "../../../Pages/Redux/Actions/userDetailsAdminAction";
import { grey, blue } from "@mui/material/colors";
import { styled } from "@mui/system";

// Wrapper for the entire Payment Clearance section
const PaymentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: grey[50],
  overflowY: "auto",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

// Responsive title
const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
  fontWeight: "bold",
  color: blue[800],
  fontSize: "1.1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

const PaymentClearance = ({ userId, waterBalance }) => {
  const dispatch = useDispatch();
  // const { userProfileData } = useSelector((state) => state.userDetailsUpdate);
  // const { consumer } = userProfileData;
  // const isB2C = consumer?.is_b2c;

  const walletAmount = useSelector(
    (state) => state.userDetailsUpdate.walletAmount
  );
  const pendingAmount = useSelector((state) => state.orders?.pendingAmount);

  const theme = useTheme();

  useEffect(() => {
    dispatch(GetProfileDetails(userId));
  }, [userId]);

  return (
    <PaymentWrapper>
      <StyledTitle variant="h6">Payment Clearance</StyledTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={5}
            sx={{
              borderRadius: 2,
              transition: "0.3s",
              "&:hover": {
                boxShadow: theme.shadows[12],
                transform: "scale(1.03)",
              },
            }}
          >
            <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: blue[700],
                }}
              >
                Water Wallet
              </Typography>
              <Grid container spacing={1}>
                {/* Display WaterWallet Component */}
                <Grid item xs={12}>
                  <WaterWallet
                    walletAmount={walletAmount}
                    userId={userId}
                    waterBalance={waterBalance}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
        {/* Pending Amount Section - Only for B2B */}
        {/* {!isB2C && ( */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={5}
            sx={{
              borderRadius: 2,
              transition: "0.3s",
              "&:hover": {
                boxShadow: theme.shadows[12],
                transform: "scale(1.03)",
              },
            }}
          >
            <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: blue[700],
                  marginBottom: "19px",
                }}
              >
                Pending Amount
              </Typography>
              <Grid container spacing={1}>
                {/* Display PendingAmount Component */}
                <Grid item xs={12}>
                  <PendingAmount
                    pendingAmount={pendingAmount}
                    userId={userId}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
        {/* Note Section - Condition for displaying the note */}
        {(walletAmount > 0 || pendingAmount > 0) && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                // mt: 3,
                textAlign: "center",
                fontSize: { xs: "0.85rem", sm: "1rem" },
                p: { xs: 1, sm: 1 },
                backgroundColor: grey[100],
                borderRadius: 2,
              }}
            >
              Note: Kindly clear/remove the Water Wallet/PendingAmount
            </Typography>
          </Grid>
        )}
      </Grid>
    </PaymentWrapper>
  );
};

export default PaymentClearance;
