import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Typography } from "@mui/material";
import CustomConsole from "../../CustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShowMoreDetailsDialog({
  setOpenShowDetailsDialog,
  openShowDetailsDialog,
  updatedByDetails,
}) {
  const handleClose = () => {
    setOpenShowDetailsDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openShowDetailsDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Updated User Details"}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
              <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
              <Typography sx={{ fontWeight: "bold" }}>phone</Typography>
            </Box>
            <Box>
              <Typography>
                {updatedByDetails?.updated_user_details?.email !== null
                  ? updatedByDetails?.updated_user_details?.email
                  : "- - -"}
              </Typography>
              <Typography>
                {updatedByDetails?.updated_user_details?.fullname !== null
                  ? updatedByDetails?.updated_user_details?.fullname
                  : "- - -"}
              </Typography>
              <Typography>
                {updatedByDetails?.updated_user_details?.phone !== null
                  ? updatedByDetails?.updated_user_details?.phone
                  : "- - -"}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
