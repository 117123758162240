import actionType from "../Actions/actionType";

import CustomConsole from "../../../CustomConsole";
const initialState = {
  error: true,
  msg: "",
  updateIotMsg: "",
  addIotMsg: "",
  IoTDevicesData: [],
  IoTDeviceDataSingle: [],
};

const adminIotDevices = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.GET_IOT_DEVICES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        IoTDevicesData: action.payload.data,
      };
    case actionType.GET_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        IoTDeviceDataSingle: action.payload.data,
      };
    case actionType.UPDATE_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.data.error,
        updateIotMsg: action.payload.data.msg,
      };
    case actionType.ADD_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        addIotMsg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default adminIotDevices;
