/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author
 * @description Add product dialog box
 * @date
 * @version
 */
import React from "react";
import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import {
  UploadFile,
  getFilesImages,
} from "../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TextFieldReusable from "../TextFiled/textField";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import { UpdateSkuProductDetails } from "../../Pages/Redux/Actions/SKUProductDetailsAction";
import ConformationDialog from "./conformationDialog";
import CustomConsole from "../../CustomConsole";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
function AddnewProductDialog(props) {
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);
  const [productName, setProductName] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [skuQQty, setSkuQQty] = React.useState([]);
  const [hsnCode, setHsnCode] = React.useState("");
  const [emptyhsnCode, setEmptyHsnCode] = React.useState("");
  const [emptycgstPercentage, setEmptyCgstPercentage] = React.useState("");
  const [emptysgstPercentage, setEmptySgstPercentage] = React.useState("");
  const [emptyigstPercentage, setEmptyIgstPercentage] = React.useState("");
  const [cgstPercentage, setCgstPercentage] = React.useState("");
  const [sgstPercentage, setSgstPercentage] = React.useState("");
  const [igstPercentage, setIgstPercentage] = React.useState("");
  const [minimumBookQty, setMinimumBookQty] = React.useState("");
  const [maximumBookQty, setMaximumBookQty] = React.useState("");
  const [orderType, setOrderType] = React.useState(400);
  const [deliveryCharges, setDeliveryCharges] = React.useState("");
  const [refunTypeSelectedRefill, setRefunTypeSelectedRefill] =
    React.useState(0);
  const [refunTypeSelectedOneTime, setRefunTypeSelectedOneTime] =
    React.useState(0);
  const [oneTimeSale, setOneTimeSale] = React.useState({
    mrp: 1,
    sp: 1,
    deposit: 0,
    refund_type: 100,
    refund_amount: refunTypeSelectedOneTime,
  });
  const [refillSale, setRefillSale] = React.useState({
    mrp: 1,
    sp: 1,
    deposit: 0,
    refund_type: 100,
    refund_amount: refunTypeSelectedRefill,
  });
  const [description, setDescription] = React.useState("");
  const [uploadNewProductFile, setUploadNewProductFile] = React.useState("");
  const [newProductUrl, setNewProductUrl] = React.useState("");
  const [isEpValid, setIsValid] = React.useState(false);
  const [uploadProdcutBtn, setUploadProdcutBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [buttonFlag, setButtonFlag] = React.useState(true);
  const [bookingType, setBookingType] = React.useState(1);
  const [isActive, setIsActive] = React.useState(true);
  const uploadNewProduct = React.useRef(null);
  const [deleteProduct, setDeleteProduct] = React.useState(false);
  const updateFlag = Object.keys(props.updateProductDetails).length > 0;
  const [crateDetails, setCrateDetails] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(true);
  const [isFillingProduct, setIsFillingProduct] = React.useState(true);
  const dialogMsg = "Are You sure to delete selected Product";
  CustomConsole(props.updateProductDetails);
  CustomConsole(props.updateCrateDetails);
  //useEffect for setting up initial data in state
  React.useEffect(() => {
    const setSaleValues =
      (saleType) =>
      ({ mrp, sp, deposit, refund_type, refund_amount }) => ({
        mrp: mrp === 0 ? 1 : mrp,
        sp: sp === 0 ? 1 : sp,
        deposit: deposit === 0 ? 1 : deposit,
        refund_type: refund_type === 0 ? 100 : refund_type,
        refund_amount:
          refund_amount === 0
            ? saleType === "one_time"
              ? refunTypeSelectedOneTime
              : refunTypeSelectedRefill
            : refund_amount,
      });

    if (updateFlag) {
      const {
        name,
        capacity,
        material,
        life_span,
        amount,
        sku_qty,
        hsn_code,
        cgst_perc,
        sgst_perc,
        min_book_qty,
        igst_perc,
        max_book_qty,
        order_type,
        delivery_charges,
        description,
        one_time_sale,
        refill,
        booking_type,
        is_active,
        image,
        is_qr,
        is_filling_product,
        empty_sgst_perc,
        empty_cgst_perc,
        empty_hsn_code,
        empty_igst_perc,
      } = props.updateProductDetails;
      CustomConsole("is_qr" + is_qr);
      setProductName(name);
      setCapacity(capacity);
      setMaterial(material);
      setLifeSpan(life_span);
      setAmount(amount);
      setSkuQQty(sku_qty);
      setHsnCode(hsn_code);
      setEmptyIgstPercentage(empty_igst_perc);
      setEmptySgstPercentage(empty_sgst_perc);
      setEmptyCgstPercentage(empty_cgst_perc);
      setEmptyHsnCode(empty_hsn_code);
      setCgstPercentage(cgst_perc);
      setSgstPercentage(sgst_perc);
      setMinimumBookQty(min_book_qty);
      setIgstPercentage(igst_perc);
      setMaximumBookQty(max_book_qty);
      setOrderType(order_type === 0 ? orderType : order_type);
      setDeliveryCharges(delivery_charges);
      setDescription(description);
      setOneTimeSale(setSaleValues("one_time")(one_time_sale));
      setRefillSale(setSaleValues("refill")(refill));
      setBookingType(booking_type);
      setIsActive(is_active === undefined ? true : is_active);
      setNewProductUrl(image);
      setCrateDetails(props.updateCrateDetails);
      setIsChecked(is_qr);
      setIsFillingProduct(is_filling_product);
    }
  }, [props.updateProductDetails]);

  //@Removed/console.log(refillSale);
  //function for handling file upload opreation
  const handleUploadNewProduct = () => {
    uploadNewProduct.current.click();
  };

  //variable for different Refund types
  const refundTypes = [
    {
      label: "WALLET",
      value: 100,
    },
    {
      label: "WATER DROPS",
      value: 200,
    },
    {
      label: "None",
      value: 0,
    },
  ];

  let reqObj = {
    description: description,
    delivery_charges: deliveryCharges,
    order_type: orderType,
    max_book_qty: maximumBookQty,
    min_book_qty: minimumBookQty,
    igst_perc: igstPercentage,
    sgst_perc: sgstPercentage,
    cgst_perc: cgstPercentage,
    hsn_code: hsnCode,
    empty_hsn_code: emptyhsnCode,
    empty_igst_perc: emptyigstPercentage,
    empty_cgst_perc: emptycgstPercentage,
    empty_sgst_perc: emptysgstPercentage,
    sku_qty: skuQQty,
    amount: amount,
    life_span: lifeSpan,
    material: material,
    capacity: capacity,
    name: productName,
    image: newProductUrl,
    booking_type: bookingType,
    one_time_sale: oneTimeSale,
    refill: refillSale,
    is_active: isActive,
    is_qr: isChecked,
    is_filling_product: isFillingProduct,
  };
  //function for updating state with selected order type
  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  //function for updating state with selected order type
  const handelRefundTypeChanged = (event, data) => {
    //@Removed/console.log(event.target.value);
    if (data === "Refill") {
      setRefillSale((prev) => ({ ...prev, refund_type: event.target.value }));
    } else {
      setOneTimeSale((prev) => ({ ...prev, refund_type: event.target.value }));
    }
  };
  ////useeffect for handling file upload opreation
  React.useEffect(() => {
    if (uploadNewProductFile !== "") {
      dispatch(UploadFile(uploadNewProductFile));
      setUploadProdcutBtn(true);
      setUploadNewProductFile("");
      setUploadFlags(true);
    }
  }, [uploadNewProductFile]);

  //useEffect for uploading file
  React.useEffect(() => {
    if (fileHandler.isFileUploaded === true && uploadProdcutBtn === true) {
      CustomConsole("New Product file uploaded file here");
      setNewProductUrl(fileHandler.fileName);
      setUploadProdcutBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("New Product File uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);
  //function for handling closing dialog
  const handleCancle = () => {
    props.handleCancle();
    setProductName("");
    setCapacity("");
    setMaterial("");
    setLifeSpan("");
    setAmount("");
    setSkuQQty([]);
    setHsnCode("");
    setEmptyIgstPercentage("");
    setEmptySgstPercentage("");
    setEmptyCgstPercentage("");
    setEmptyHsnCode("");
    setCgstPercentage("");
    setSgstPercentage("");
    setMinimumBookQty("");
    setIgstPercentage("");
    setMaximumBookQty("");
    setOrderType("");
    setDeliveryCharges("");
    setDescription("");
    setUploadNewProductFile("");
    setIsActive(true);
    props.setUpdateProductDetails("");
  };

  //function for handling addProduct operation
  const handleAddProduct = () => {
    CustomConsole(reqObj);
    if (!updateFlag) {
      props.handleAddProduct(reqObj);
    } else {
      reqObj.container_id = props.updateProductDetails.id;
      reqObj.crate_details = crateDetails;
      dispatch(UpdateSkuProductDetails(reqObj));
    }
  };

  const handleChange = (event) => {
    CustomConsole(event.target.value);
    setBookingType(event.target.value);
  };

  //function for deleting account
  const handelProceedDelete = () => {
    reqObj.container_id = props.updateProductDetails.id;
    reqObj.is_active = false;
    dispatch(UpdateSkuProductDetails(reqObj));
  };

  //useEffect for getting filee images
  React.useEffect(() => {
    CustomConsole("I am Hitted for getting file images");
    CustomConsole(newProductUrl);
    if (updateFlag && newProductUrl !== "") {
      dispatch(getFilesImages(newProductUrl));
    }
  }, [newProductUrl]);
  // Function to handle the change in Checkbox state
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  //function to handel check box change filling process
  const handleCheckboxChangeFilling = (event) => {
    setIsFillingProduct(event.target.checked);
  };
  return (
    <Box>
      <Dialog open={props.openAddOrUpdateProduct}>
        <DialogTitle>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            ADD/UPDATE PRODUCT
            {updateFlag && newProductUrl !== "" ? (
              <img
                style={{ height: "50px", width: "100px" }}
                src={fileHandler.imagePath}
                alt="BookWater"
              />
            ) : null}{" "}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="success"
                    />
                  }
                  label="Product With Qr/BarCode"
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFillingProduct}
                      onChange={handleCheckboxChangeFilling}
                      color="success"
                    />
                  }
                  label="Filling Product"
                />
              </Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextFieldReusable
                label="Product Name"
                type="text"
                value={productName}
                isRequired={true}
                setDataForState={setProductName}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Capacity"
                type="text"
                value={capacity}
                isRequired={true}
                setDataForState={setCapacity}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Material"
                type="text"
                value={material}
                isRequired={true}
                setDataForState={setMaterial}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Life Span"
                type="number"
                value={lifeSpan}
                isRequired={true}
                setDataForState={setLifeSpan}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            {/* <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Price"
                type="number"
                value={amount}
                isRequired={true}
                setDataForState={setAmount}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid> */}
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="SKU Quantity"
                type="number"
                value={skuQQty}
                isRequired={true}
                setDataForState={setSkuQQty}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="HSN Code"
                type="text"
                value={hsnCode}
                isRequired={true}
                setDataForState={setHsnCode}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="CGST %"
                type="number"
                value={cgstPercentage}
                isRequired={true}
                setDataForState={setCgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="SGST %"
                type="number"
                value={sgstPercentage}
                isRequired={true}
                setDataForState={setSgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="IGST %"
                type="number"
                value={igstPercentage}
                isRequired={true}
                setDataForState={setIgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Minimum Book Quantity"
                type="number"
                value={minimumBookQty}
                isRequired={true}
                setDataForState={setMinimumBookQty}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Maximum Book Quantity"
                type="number"
                value={maximumBookQty}
                isRequired={true}
                setDataForState={setMaximumBookQty}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty HSN Code"
                type="text"
                value={emptyhsnCode}
                isRequired={true}
                setDataForState={setEmptyHsnCode}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty CGST %"
                type="number"
                value={emptycgstPercentage}
                isRequired={true}
                setDataForState={setEmptyCgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty SGST %"
                type="number"
                value={emptysgstPercentage}
                isRequired={true}
                setDataForState={setEmptySgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty IGST %"
                type="number"
                value={emptyigstPercentage}
                isRequired={true}
                setDataForState={setEmptyIgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                id="outlined-select-currency-native"
                select
                sx={{ width: { sm: "90%", xs: "100%" } }}
                size="small"
                label="Refund Type"
                defaultValue={orderType}
                SelectProps={{
                  native: true,
                }}
                helperText="Please select Refund Type"
                onChange={handleOrderTypeChange}
              >
                {props.orderTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Delivery Charges"
                type="number"
                value={deliveryCharges}
                isRequired={true}
                setDataForState={setDeliveryCharges}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid> */}
            <Grid item md={12} sm={12} xs={12}>
              <TextFieldReusable
                label="Description"
                type="text"
                value={description}
                isRequired={true}
                setDataForState={setDescription}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={<Radio />}
                  checked={bookingType == 1}
                  value={1}
                  label="Refill"
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={<Radio />}
                  checked={bookingType == 2}
                  value={2}
                  label="One time sale"
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={<Radio />}
                  checked={bookingType == 3}
                  value={3}
                  label="Both"
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            {bookingType == 1 || bookingType == 3 ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography>Refill Sale</Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextFieldReusable
                    label="MRP"
                    type="Number"
                    value={refillSale.mrp}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                  <TextFieldReusable
                    label="Sale Price"
                    type="Number"
                    value={refillSale.sp}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                  <TextFieldReusable
                    label="Deposit"
                    type="Number"
                    value={refillSale.deposit}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    sx={{
                      width: { sm: "90%", xs: "100%" },
                      marginTop: ".6rem",
                    }}
                    size="small"
                    label="Refund Type"
                    defaultValue={refillSale.refund_type}
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(event) =>
                      handelRefundTypeChanged(event, "Refill")
                    }
                  >
                    {refundTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextFieldReusable
                    label="Refund Amount"
                    type="Number"
                    value={refillSale.refund_amount}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                </Box>
              </Grid>
            ) : null}
            {bookingType == 2 || bookingType == 3 ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography>One Time Sale</Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextFieldReusable
                    label="MRP"
                    type="Number"
                    value={oneTimeSale.mrp}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                  <TextFieldReusable
                    label="Sale Price"
                    type="Number"
                    value={oneTimeSale.sp}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                  {/* <TextFieldReusable
                    label="Deposit"
                    type="Number"
                    value={oneTimeSale.deposit}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  /> */}
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    sx={{
                      width: { sm: "90%", xs: "100%" },
                      marginTop: ".6rem",
                    }}
                    size="small"
                    label="Refund Type"
                    defaultValue={oneTimeSale.refund_type}
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(event) =>
                      handelRefundTypeChanged(event, "one_time")
                    }
                  >
                    {refundTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextFieldReusable
                    label="Refund Amount"
                    type="Number"
                    value={oneTimeSale.refund_amount}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                </Box>
              </Grid>
            ) : null}
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button
                onClick={handleUploadNewProduct}
                size="small"
                variant="contained"
              >
                {fileHandler.isFileUploaded !== undefined &&
                fileHandler.isFileUploaded === false
                  ? "Upload Image"
                  : "Uploaded Successfully"}
                <input
                  type="file"
                  ref={uploadNewProduct}
                  style={{ display: "none" }}
                  onChange={(e) => setUploadNewProductFile(e.target.files[0])}
                />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleCancle}
            //   sx={{
            //     background: appColors.commonBtnColor.background,
            //     borderRadius: "20px",
            //   }}
          >
            Close
          </Button>

          <Button
            variant="contained"
            size="small"
            disabled={description.length < 15}
            onClick={handleAddProduct}
          >
            {updateFlag ? "Update" : "Add Product"}
          </Button>
          {updateFlag ? (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setConfirmDialog(true)}
            >
              Delete
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <ConformationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        dialogMsg={dialogMsg}
        handelProceed={handelProceedDelete}
      />
    </Box>
  );
}

export default AddnewProductDialog;
