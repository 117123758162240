import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import moment from "moment";
import TransactionDetailsTable from "./TransactionDetailsTable";
import CustomConsole from "../../CustomConsole";
import * as Material from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetAllTransactionDetails } from "../Redux/Actions/transactionDetailsAction";
import transitions from "@material-ui/core/styles/transitions";
import { CSVLink } from "react-csv";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FirstPage, LastPage } from "@mui/icons-material";
function TransactionPage() {
  const history = useHistory();
  // For order
  const paymentMethodOrder = [
    {
      type: 0,
      method: "None",
    },
    { type: 1001, method: "WALLET" },
    { type: 1002, method: "RAZORPAY" },
    { type: 1003, method: "PAYSHARP+UPI" },
  ];
  const paymentStatusOrder = [
    {
      type: 0,
      status: "None",
    },
    { type: 1, status: "SUCCESS" },
    { type: 2, status: "REFUND" },
    { type: 3, status: "FAILED" },
  ];
  const transactionTypes = {
    ORDERS: 101,
    WATER_DROPS: 102,
    DEPOSITS: 103,
    WALLET: 104,
  };
  const pageSizeValues = [25, 50, 100, 250, 500, 1000, 2500, 5000, 10000];

  const dispatch = useDispatch();
  const transactionDetails = useSelector((state) => state.transactiondetails);
  const [value, setValue] = React.useState([
    dayjs("2022-04-17"),
    dayjs("2022-04-21"),
  ]);
  // React.useEffect(() => {
  //   const reqObj = {};
  //   dispatch(GetAllTransactionDetails(reqObj));
  // }, []);
  const [transactionType, setTransactionType] = React.useState(
    transactionTypes.ORDERS
  );

  const today = moment().add(7, "days").format("YYYY-MM-DD");

  const [ascDesc, setAscDesc] = React.useState("DESC");
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(pageSizeValues[0]);
  const [emailPhone, setEmailPhone] = React.useState("");
  const [orderId, setOrderId] = React.useState("");

  const [changeRadioOptionsTransaction, setChangeRadioOptionsTransaction] =
    React.useState("Phone/Email");
  const [transactionId, setTransactionId] = React.useState("");
  const [fromDateValue, setFromDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [toDateValue, setToDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [fromValue, setFromValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [deposited, setDeposited] = React.useState(1);
  const [toValue, setToValue] = React.useState(moment().format("YYYY-MM-DD"));
  const [paymentMethodArr, setPaymentMethodArr] = React.useState([]);
  const [paymentStatusArr, setPaymentStatusArr] = React.useState([]);
  const [paymentMethod, setPaymentMethod] = React.useState(0);
  const [paymentStatus, setPaymentStatus] = React.useState(0);
  const [transactionData, setTransactionData] = React.useState([]);
  const [waterDropTrans, setWaterDropTrans] = React.useState([]);
  const [depositTrans, setDepositTrans] = React.useState([]);
  const [search, setSearch] = React.useState(false);
  const [csvFileData, setCsvFileData] = React.useState([]);

  //Click on Order Id Redirecting to orders Page for perticular Order
  const handleOrderTransaction = (order_id) => {
    const url = `/dashboard/orders?order_id=${order_id}`;
    history.push(url);
  };

  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename: "Report",
    data: csvFileData,
  };
  function toCSV(transactionData) {
    const result = [];
    if (transactionData && transactionData.length) {
      result.push(transactionData);
    }
    // result.push(pincodeDetails)
    setCsvFileData(result);
  }
  // For water drop
  const paymentStatusWaterDrop = [
    {
      type: 0,
      status: "None",
    },
    { type: 4, status: "REWARDED" },

    {
      type: 5,
      status: "PROMO DISCOUNT",
    },
    {
      type: 6,
      status: "CONSUMER PAID",
    },
    {
      type: 7,
      status: "CASHBACK REWARDS",
    },
    {
      type: 8,
      status: "MARKETING REWARDS",
    },
  ];
  // For deposit
  const paymentStatusDeposit = [
    {
      type: 0,
      status: "None",
    },
    {
      type: 1,
      status: "SUCCESS",
    },
    {
      type: 3,
      status: "FAILED",
    },
  ];

  // For wallet
  const paymentMethodWallet = [
    {
      type: 0,
      status: "None",
    },
    {
      type: 1002,
      method: "RAZORPAY",
    },
    {
      type: 1003,
      method: "PAYSHARP + UPI",
    },
  ];

  const paymentStatusWallet = [
    {
      type: 0,
      status: "None",
    },
    {
      type: 1,
      status: "SUCCESS",
    },
    {
      type: 2,
      status: "REFUNDS",
    },
    {
      type: 3,
      status: "FAILED",
    },
  ];

  const handleChangeFrom = (e) => {
    setFromDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    CustomConsole(momentDate);
    setFromValue(momentDate);
  };

  const handleChangeTo = (e) => {
    setToDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    CustomConsole(momentDate);
    setToValue(momentDate);
  };

  const handleChange = (event) => {
    setTransactionType(event.target.value);
  };

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleChangePaymentStatus = (event) => {
    setPaymentStatus(event.target.value);
  };
  const handleChangeDeposits = (event) => {
    setDeposited(event.target.value);
  };
  React.useEffect(() => {
    if (transactionType === 101) {
      setPaymentMethodArr(paymentMethodOrder);
      setPaymentStatusArr(paymentStatusOrder);
    } else if (transactionType === 102) {
      setPaymentStatusArr(paymentStatusWaterDrop);
      setPaymentMethodArr([]);
    } else if (transactionType === 103) {
      setPaymentStatusArr(paymentStatusDeposit);
      setPaymentMethodArr([]);
    } else if (transactionType === 104) {
      setPaymentMethodArr(paymentMethodWallet);
      setPaymentStatusArr(paymentStatusWallet);
    }
  }, [transactionType]);

  const handleChangeEmailPhone = (event) => {
    setEmailPhone(event.target.value);
  };

  const handleChangeOrderId = (event) => {
    setOrderId(event.target.value);
  };

  const handleChangeTransactionId = (event) => {
    setTransactionId(event.target.value);
  };

  const handleClickTransactionDetails = () => {
    let reqObj = {
      from_date: fromDateValue,
      to_date: toDateValue,
      // deposited: transactionType === 103 ? deposited : 0,
      transaction_type: transactionType,
      ascDesc: ascDesc.toLowerCase(),
      page_number: pageNumber,
      page_size: pageSize,
    };
    if (transactionType === 103) {
      reqObj.deposited = deposited;
    }
    if (
      orderId !== undefined &&
      orderId !== null &&
      orderId !== "" &&
      orderId !== "undefined" &&
      orderId.length
    ) {
      reqObj.order_id = orderId;
    }
    if (transactionId && transactionId.length) {
      reqObj.transaction_id = transactionId;
    }
    if (emailPhone && emailPhone.length) {
      reqObj.email_phone = emailPhone;
    }
    CustomConsole("paymentMethod: ", paymentMethod);
    if (paymentMethod) {
      reqObj.payment_method = paymentMethod;
    }
    CustomConsole("paymentStatus: ", paymentStatus);
    if (paymentStatus) {
      reqObj.payment_status = paymentStatus;
    }
    CustomConsole("----Req Obj---");
    CustomConsole(reqObj);
    dispatch(GetAllTransactionDetails(reqObj));
    setEmailPhone("");
    setTransactionId("");
    setOrderId("");
    setSearch(true);
  };
  // Hit Api Call While changing page number and page size
  React.useEffect(() => {
    handleClickTransactionDetails();
  }, [pageSize, pageNumber]);

  React.useEffect(() => {
    CustomConsole("----Transactions-----");
    CustomConsole(transactionDetails);

    if (
      transactionDetails.transactionDetails &&
      transactionDetails.transactionDetails.order_wallet_transactions &&
      Object.keys(
        transactionDetails.transactionDetails.order_wallet_transactions
      ).length
    ) {
      setTransactionData(
        transactionDetails.transactionDetails.order_wallet_transactions
      );
      setWaterDropTrans([]);
      setDepositTrans([]);
    } else {
      setTransactionData([]);
    }

    if (
      transactionDetails.transactionDetails &&
      transactionDetails.transactionDetails.water_drops_transactions &&
      Object.keys(
        transactionDetails.transactionDetails.water_drops_transactions
      ).length
    ) {
      CustomConsole("----setting water drop transaction----");
      CustomConsole(
        transactionDetails.transactionDetails.water_drops_transactions
      );
      setWaterDropTrans(
        transactionDetails.transactionDetails.water_drops_transactions
      );
      setTransactionData([]);
      setDepositTrans([]);
    } else {
      setWaterDropTrans([]);
    }

    if (
      transactionDetails.transactionDetails &&
      transactionDetails.transactionDetails.deposit_transactions &&
      Object.keys(transactionDetails.transactionDetails.deposit_transactions)
        .length
    ) {
      CustomConsole("----setting deposite transaction----");
      CustomConsole(transactionDetails.transactionDetails.deposit_transactions);
      setDepositTrans(
        transactionDetails.transactionDetails.deposit_transactions
      );
      setWaterDropTrans([]);
      setTransactionData([]);
    } else {
      setDepositTrans([]);
    }

    setSearch(false);
  }, [transactionDetails]);

  return (
    <Box sx={{ marginTop: "70px" }}>
      <Paper elevation={23}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Material.Typography
            sx={{
              fontWeight: "bold",
              fontSize: "16px",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            Filter options
          </Material.Typography>
          <Box
            sx={{
              display: "flex",
              // flexDirection: { xs: "column", sm: "row" },
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px" }}>
              <TextField
                label="Transactions from"
                variant="standard"
                value={fromDateValue}
                type="date"
                onChange={handleChangeFrom}
                max={today}
              />
              <TextField
                label="Transactions till"
                variant="standard"
                value={toDateValue}
                type="date"
                onChange={handleChangeTo}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel>Transaction Type</InputLabel>
                <Select
                  value={transactionType}
                  label="Transaction Type"
                  onChange={handleChange}
                  variant="standard"
                >
                  <MenuItem value={transactionTypes.ORDERS}>ORDERS</MenuItem>
                  <MenuItem value={transactionTypes.WATER_DROPS}>
                    WATER-DROPS
                  </MenuItem>
                  <MenuItem value={transactionTypes.DEPOSITS}>DEPOSIT</MenuItem>
                  <MenuItem value={transactionTypes.WALLET}>WALLET</MenuItem>
                </Select>
              </FormControl>

              {paymentMethodArr.length ? (
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                    value={paymentMethod}
                    label="Payment Method"
                    onChange={handleChangePaymentMethod}
                    variant="standard"
                  >
                    {paymentMethodArr.map((data, index) => {
                      return (
                        <MenuItem value={data.type} key={index}>
                          {data.method}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
            </Box>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel>Payment Status</InputLabel>
              <Select
                value={paymentStatus}
                label="Payment Status"
                onChange={handleChangePaymentStatus}
                variant="standard"
              >
                {paymentStatusArr.map((data, index) => {
                  return (
                    <MenuItem value={data.type} key={index}>
                      {data.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {transactionType === 103 && (
              <FormControl sx={{ minWidth: 80 }} size="small">
                <InputLabel>Deposits</InputLabel>
                <Select
                  value={deposited}
                  label="Deposits"
                  onChange={handleChangeDeposits}
                  variant="standard"
                >
                  <MenuItem value={1}>DEPOSITED</MenuItem>;
                  <MenuItem value={2}>UN-DEPOSITED</MenuItem>;
                </Select>
              </FormControl>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "10px",
              marginBottom: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl
              variant="standard"
              sx={{ height: "100%", width: "150px" }}
            >
              <InputLabel>Search type</InputLabel>
              <Select
                value={changeRadioOptionsTransaction}
                label="Search Type"
                onChange={(event) => {
                  setChangeRadioOptionsTransaction(event.target.value);
                }}
              >
                {["Phone/Email", "Order ID", "Transaction ID"].map(
                  (data, index) => {
                    return (
                      <MenuItem key={index} value={data}>
                        {data}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
            {changeRadioOptionsTransaction === "Phone/Email" ? (
              <TextField
                label="Email/Phone"
                variant="standard"
                value={emailPhone}
                onChange={handleChangeEmailPhone}
                // disabled={orderId !== "" || transactionId !== ""}
              />
            ) : changeRadioOptionsTransaction === "Order ID" ? (
              <TextField
                label="Order ID"
                variant="standard"
                value={orderId}
                onChange={handleChangeOrderId}
                // disabled={transactionId !== "" || emailPhone !== ""}
              />
            ) : (
              <TextField
                label="Transaction ID"
                variant="standard"
                value={transactionId}
                onChange={handleChangeTransactionId}
                // disabled={orderId !== "" || emailPhone !== ""}
              />
            )}
            <Button
              variant="contained"
              size="small"
              sx={{
                height: "30px",
                width: "70px",
                backgroundImage: "linear-gradient(to top,blue, brown)",
              }}
              onClick={handleClickTransactionDetails}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box sx={{ padding: "10px", marginTop: "10px" }}>
        {/* <TransactionDetailsTable
          transactionData={transactionData}
          waterDropTrans={waterDropTrans}
          depositTrans={depositTrans}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        /> */}
        <Paper sx={{ padding: "10px" }}>
          <Material.Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Material.Typography
              sx={{ fontWeight: "bold", fontSize: "16px", color: "brown" }}
            >
              Transaction details
            </Material.Typography>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <FormControl
              variant="standard"
              sx={{ height: "100%", width: "80px" }}
            >
              <InputLabel>Page Size</InputLabel>
              <Select
                value={pageSize}
                label="Page Size"
                onChange={(e) => {
                  setPageSize(e.target.value);
                  // CustomConsole(e.target.value);
                }}
              >
                {pageSizeValues.map((size, index) => {
                  return (
                    <MenuItem key={index} value={size}>
                      {size}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* </Material.Box> */}
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Material.IconButton
                disabled={pageNumber - 1 < 0}
                onClick={() => {
                  if (pageNumber - 1 >= 0) {
                    setPageNumber(() => pageNumber - 1);
                  }
                }}
              >
                <FirstPage
                  sx={{ color: pageNumber - 1 >= 0 ? "blue" : "gray" }}
                />
              </Material.IconButton>
              <Material.Typography
                sx={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {pageNumber + 1}
              </Material.Typography>
              <Material.IconButton
                disabled={!(transactionData.length === pageSize)}
                sx={{ maxHeight: "50px" }}
                onClick={() => {
                  setPageNumber(() => pageNumber + 1);
                }}
              >
                <LastPage
                  sx={{
                    color:
                      transactionData.length === pageSize && pageNumber + 1 > 0
                        ? "blue"
                        : "gray",
                  }}
                />
              </Material.IconButton>
            </Material.Box>
            {/* <CSVLink {...csvLink}>
              <SimCardDownloadRoundedIcon
                sx={{ height: "50px", width: "30px" }}
                // sx={{ ...styles.reportDownloadIcon }}
                onClick={() => toCSV(transactionData)}
              />
            </CSVLink> */}
          </Material.Box>
          {transactionData && transactionData.length ? (
            <TableContainer component={Paper} sx={{ maxHeight: "600px" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {[
                      "S.NO",
                      "Transaction ID",
                      "User name",
                      "User phone",
                      "Order ID",
                      "Amount",
                      "Transaction time",
                      "Payment status",
                      "Payment type",
                      "Reason",
                      "Updated by",
                    ].map((data, index) => {
                      return (
                        <TableCell sx={styles.tableHeader} key={index}>
                          {data}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData && transactionData.length
                    ? transactionData.map((transactionData, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell>
                              {transactionData.transaction_id}
                            </TableCell>
                            <TableCell>
                              {transactionData.consumer_info.fullname}
                            </TableCell>
                            <TableCell>
                              {transactionData.consumer_info.phone}
                            </TableCell>
                            <TableCell
                              sx={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              // onClick={() => {
                              //   handleOrderTransaction(
                              //     transactionData.order_id
                              //   );
                              // }}
                            >
                              {transactionData.order_id}
                            </TableCell>
                            <TableCell>{transactionData.amount}</TableCell>
                            <TableCell>
                              {" "}
                              {transactionData.updated_at
                                ? transactionData.updated_at
                                : transactionData.created_at}
                            </TableCell>
                            <TableCell>
                              {transactionData.payment_status}
                            </TableCell>
                            <TableCell>
                              {transactionData.payment_type}
                            </TableCell>
                            <TableCell>
                              {transactionData.reason
                                ? transactionData.reason
                                : transactionData.refund_reason
                                ? transactionData.refund_reason
                                : "-"}
                            </TableCell>

                            <TableCell>
                              {`${
                                transactionData.updated_by.user_name
                                  ? transactionData.updated_by?.user_name
                                  : "-"
                              } ${
                                transactionData.updated_by?.user_email
                                  ? "( " +
                                    transactionData.updated_by.user_email +
                                    " )"
                                  : "-"
                              }`}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}

          {depositTrans && depositTrans.length ? (
            <TableContainer component={Paper} sx={{ maxHeight: "600px" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {[
                      "S.No",
                      "Order ID",
                      "User name",
                      "User phone",
                      "Amount",
                      "Transaction time",
                    ].map((data, index) => {
                      return (
                        <TableCell sx={styles.tableHeader} key={index}>
                          {data}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {depositTrans && depositTrans.length
                    ? depositTrans.map((transactionData, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell
                              sx={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              // onClick={() => {
                              //   handleOrderTransaction(
                              //     transactionData.order_id
                              //   );
                              // }}
                            >
                              {transactionData.order_id}
                            </TableCell>
                            <TableCell>
                              {transactionData.consumer_info.fullname}
                            </TableCell>
                            <TableCell>
                              {transactionData.consumer_info.phone}
                            </TableCell>
                            <TableCell>{transactionData.amount}</TableCell>
                            <TableCell>{transactionData.updated_at}</TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          {waterDropTrans && waterDropTrans.length ? (
            <TableContainer component={Paper} sx={{ maxHeight: "600px" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={{}}>
                    {[
                      "S.No",
                      "Order ID",
                      "User name",
                      "User phone",
                      "Waterdrops",
                      "Transaction time",
                      "Reason",
                      "Updated by",
                    ].map((data, index) => {
                      return (
                        <TableCell sx={styles.tableHeader} key={index}>
                          {data}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {waterDropTrans && waterDropTrans.length
                    ? waterDropTrans.map((transactionData, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell
                              sx={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              // onClick={() => {
                              //   handleOrderTransaction(
                              //     transactionData.order_id
                              //   );
                              // }}
                            >
                              {transactionData.order_id}
                            </TableCell>
                            <TableCell>
                              {transactionData.consumer_info.fullname}
                            </TableCell>
                            <TableCell>
                              {transactionData.consumer_info.phone}
                            </TableCell>
                            <TableCell>{transactionData.water_drops}</TableCell>
                            <TableCell>{transactionData.updated_at}</TableCell>
                            <TableCell>{transactionData.reason}</TableCell>
                            <TableCell>
                              {`${
                                transactionData.updated_by_info?.fullname
                                  ? transactionData.updated_by_info?.fullname
                                  : "-"
                              } (${
                                transactionData.updated_by_info?.email
                                  ? "( " +
                                    transactionData.updated_by_info.email +
                                    " )"
                                  : "-"
                              })`}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Paper>
      </Box>
    </Box>
  );
}

export default TransactionPage;

const styles = {
  tableHeader: {
    fontWeight: "bold",
  },
};
