/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 25-01-2024
 */
import React from "react";
import CustomConsole from "../../CustomConsole";
import * as MuiIcons from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import FilterListIcon from "@mui/icons-material/FilterList";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { ordersStatus } from "../AppMeta/appMetaConfig";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
function CrediNoteSettlementTable({
  pageNumber,
  disableBtn,
  setChangeInPage,
  setPageSize,
  pageSize,
  settlementDetails,
  setPageNumber,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);

  //function for handling view icon
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };

  //function for handling edit product details
  // const handleFilterChange = (e) => {
  //   CustomConsole(e.target.value);
  //   // props.setQueryParams((prev) => ({ ...prev, page_size: e.target.value }));
  // };
  const handleFilterChange = (event) => {
    setPageSize(event.target.value);
  };

  const orderStatus = (order_status) => {
    switch (order_status) {
      case 200:
        return "SETTLED";
      case 100:
        return "PENDING";
      case 300:
        return "CANCELLED";
      default:
        return "";
    }
  };
  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: 5,
          marginTop: "1rem",
          padding: "1rem",
          width: { xs: "80vw", sm: "45vw", md: "40vw", lg: "50vw" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          {" "}
          {/* <Button
            onClick={() => setOpenFilterDialog(true)}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Button> */}
          <FormControl sx={{ minWidth: 100 }} size="small">
            <InputLabel>Page size</InputLabel>
            <Select
              value={pageSize}
              size="small"
              sx={{ height: "2.2rem" }}
              label="Page size"
              onChange={handleFilterChange}
              name="page_size"
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
              />
            </IconButton>
            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {pageNumber}
            </Typography>
            <IconButton
              sx={{ maxHeight: "50px" }}
              onClick={() => {
                if (true) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color:
                    pageSize == settlementDetails?.length ? "blue" : "gray",
                }}
              />
            </IconButton>
          </Box>
          {/* <CSVLink {...csvLink}>
            <SimCardDownloadRoundedIcon
              // sx={{ ...styles.reportDownloadIcon }}
              onClick={() => toCSV(props.pincodeData)}
            />
          </CSVLink> */}
        </Box>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={appColors.commonLayoutColor}>
                <TableCell size="small" align="start">
                  <strong>S.no</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Credit Note Id</strong>
                </TableCell>

                <TableCell size="small" align="center">
                  <strong> Invoice Id</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Status</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Settled Amount</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Created at</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settlementDetails?.map((data, index) => (
                <>
                  <TableRow
                    sx={{ "& > *": { borderBottom: "unset" } }}
                    key={index}
                  >
                    <TableCell size="small" align="start">
                      {index + 1}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {data?.details?.credit_id}
                    </TableCell>

                    <TableCell size="small" align="center">
                      {data?.details?.invoice_id}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {orderStatus(data?.status)}
                    </TableCell>
                    <TableCell size="small" align="center">
                      ₹ {data?.details?.settled_amount}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {data?.created_at?.slice(0, 10)}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default CrediNoteSettlementTable;
