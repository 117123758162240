/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Web View Booking Page
 *
 */

import { Box, Paper, TextField, Button, MenuItem } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AvailablePincodes } from "../../../Pages/Redux/Actions/pincodesAction";
import { GetFilteredUserAddressAdmin } from "../../../Pages/Redux/Actions/userDetailsAdminAction";
import {
  GetSupplierDetailsList,
  WareHouseDetailsList,
} from "../../../Pages/Redux/Actions/supplierVehiclesActions";

function WareHouseSearchField() {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = React.useState("INDIA");
  const wareHouseDetails = useSelector(
    (state) => state.supplierDeliveries.wareHouseDetails
  );
  const supplierDetails = useSelector(
    (state) => state.supplierDeliveries.supplierList
  );
  const [warehouseFilteredState, setWarehouseFilteredState] =
    React.useState("Tamil Nadu");
  const [warehouseFilterCity, setWareFilterhouseCity] = React.useState({
    name: "CHENNAI",
  });
  const [districts, setDistricts] = React.useState();
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = useState();
  const [selectedWareHouse, setSelectedWarehouse] = React.useState();
  const [selectedSupplier, setSelectedSupplier] = React.useState();
  //function to get countries details
  React.useEffect(() => {
    // getCountries();
  }, []);

  //function to get State wise Details
  React.useEffect(() => {
    if (selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      //   getStates({ country: selectedCountry });
    }
  }, [selectedCountry]);

  //function to get Distric details

  React.useEffect(() => {
    if (warehouseFilteredState && selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      //   getDistricts({
      //     country: selectedCountry,
      //     state: warehouseFilteredState,
      //   });
    }
  }, [warehouseFilteredState]);

  //function to get WareHouse Details details
  React.useEffect(() => {
    //   getDistricts({ country: selectedCountry });
    GetWareHouseDetailsList({
      name: warehouseFilterCity.name,
    });
  }, []);

  // Auto-select the first warehouse when wareHouseDetails is available or changes
  React.useEffect(() => {
    if (wareHouseDetails?.length) {
      console.log("wareHouseDetails[0]");
      console.log(wareHouseDetails[0]);
      setSelectedWarehouse({
        contact_name: wareHouseDetails[0].contact_name,
        pincode: wareHouseDetails[0].pincode,
        id: 0,
        address_id: wareHouseDetails[0].address_id,
        user_id: wareHouseDetails[0].user_id,
      });
    }
  }, [wareHouseDetails]);

  React.useEffect(() => {
    if (supplierDetails?.length) {
      // Filter out suppliers where supplier_name is null
      const validSuppliers = supplierDetails.filter(
        (supplier) => supplier.supplier_name !== null
      );

      // If there are valid suppliers, select the first one
      if (validSuppliers.length) {
        setSelectedSupplier({
          contact_name: validSuppliers[0].supplier_name,
          user_id: validSuppliers[0].user_id,
        });
      }
    }
  }, [supplierDetails]);

  //function to get WareHouse Details details based on address_id
  React.useEffect(() => {
    console.log(warehouseFilterCity);
    if (selectedSupplier && selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      GetWareHouseDetails({
        user_id: selectedSupplier?.user_id,
      });
    }
  }, [selectedSupplier]);

  const getCountries = async () => {
    const all_available_countries = await AvailablePincodes({
      // pincode: value,
      page_number: 1,
      page_size: 100,
      get: "country",
    });
    setCountries(all_available_countries?.data);
    // setAllAvailableCountries(all_available_countries?.data);
  };
  const getStates = async (params) => {
    const all_states = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "state_name",
    });
    setStates(all_states?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };
  const getDistricts = async (params) => {
    const all_Districts = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "district",
    });
    setDistricts(all_Districts?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };

  //function handel selected city
  const handelSelectedCity = (event, newValue) => {
    setWareFilterhouseCity(newValue);
  };

  //function to download warehouse details List
  function GetWareHouseDetailsList(data) {
    console.log("WareHouseDetails");
    console.log(data);
    // dispatch(WareHouseDetailsList());
    dispatch(GetSupplierDetailsList());
  }

  //function to download warehouse details
  function GetWareHouseDetails(data) {
    console.log("WareHouseDetails");
    console.log(data);
    dispatch(
      GetFilteredUserAddressAdmin({
        user_id: data.user_id,
      })
    );
  }
  return (
    <Box>
      {/* Top Bar for Filter & Search Options */}
      <Paper elevation={12} sx={{ padding: "5px" }}>
        <Box
          sx={{
            mt: "10px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {/* <Autocomplete
            disabled={!wareHouseDetails?.length}
            id="country-select-demo"
            sx={{ width: 210 }}
            options={wareHouseDetails?.map((item, index) => {
              return {
                contact_name: item.contact_name,
                pincode: item.pincode,
                id: index,
                address_id: item.address_id,
                user_id: item.user_id,
              };
            })}
            value={selectedWareHouse} // Set the value of Autocomplete to the selected warehouse
            defaultValue={wareHouseDetails?.length ? wareHouseDetails[0] : null} // Set the default value to selectedWarehouse name
            autoHighlight
            getOptionLabel={(nameSearch) =>
              `${nameSearch.contact_name} ${nameSearch.pincode}`
            }
            onChange={(_, newValue) => {
              setSelectedWarehouse(newValue || null); // Update the selected warehouse when user changes
            }}
            renderOption={(props, nameSearch) => (
              <Box component="li" {...props}>
                {nameSearch.contact_name} - {nameSearch.pincode}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={
                  wareHouseDetails?.length
                    ? "Search Warehouse"
                    : "No warehouses Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // Disable autocomplete and autofill
                }}
              />
            )}
          /> */}

          <Autocomplete
            disabled={!supplierDetails?.length}
            id="country-select-demo"
            sx={{ width: 210 }}
            options={supplierDetails
              ?.filter((item) => item?.supplier_name !== null) // Filter out null supplier names
              .map((item) => ({
                contact_name: item.supplier_name,
                user_id: item.user_id,
              }))}
            value={selectedSupplier} // Set the value of Autocomplete to the selected supplier
            isOptionEqualToValue={(option, value) =>
              option.user_id === value?.user_id
            }
            autoHighlight
            getOptionLabel={(option) => option.contact_name || ""} // Handle case where contact_name is null
            onChange={(_, newValue) => {
              setSelectedSupplier(newValue || null); // Update the selected supplier when user changes
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.contact_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={
                  supplierDetails?.length
                    ? "Search Supplier"
                    : "No Supplier Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // Disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default WareHouseSearchField;
