/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./Reducers/RootReducer";
//Remove it before production
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};
const middlewares = [thunk];

export const store = createStore(
  RootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);
