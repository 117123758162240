import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const SearchAreasByPincode = (pincode) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/searchAreasbyPincode`, {
        pincode: pincode,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PINCODE_AREAS,
          payload: response.data,
        });
        CustomConsole(response);
        CustomConsole(response.data.data.length);
        if (response.data.data.length <= 0) {
          CustomConsole("no available toast");
          toast.dark("Enter proper pincode", {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const BooknowOrderDetails = (data) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/TM/search_tankers/${data.tankerType}/pincode/${data.pincode}/id/${data.phone}?status=false&price_range=&tds_range=&capacity_range=&supplier_rating=&area_name=${data.area}`
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_BOOKNOW_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetDepositDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/getDepositDetails?user_id=${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_DEPOST_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetUserDepositDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  CustomConsole(`userId ${userId}`);
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/getUserStockDetails?user_id=${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_USER_DEPOST_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetSetUpConfigAction = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/setupconfig/appmeta`);
      CustomConsole("----setup response-----");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_CONFIG_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

export const BookPdwOrder = (bookingDetails, paymentMethod, depositDetails) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole("BookPackagedDrinkingWater");
  CustomConsole(bookingDetails);
  CustomConsole(paymentMethod);
  CustomConsole(depositDetails);
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/OM/PackagedDrinkingorders`,
        bookingDetails
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole("BookPackagedDrinkingWater RESPONSE: ");
        CustomConsole(response);
        if (paymentMethod === "POSTPAID") {
          dispatch({
            type: actionType.BOOK_POSTPAID,
            payload: { data: response.data, statusFlag: true },
          });
        } else {
          dispatch({
            type: actionType.BOOK_PWD_ORDER,
            payload: { data: response.data, statusFlag: true },
          });
        }

        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        const orderDetails = {
          order_id: response.data.order_id[0],
          packaged_drinking_water: response.data.packaged_drinking_water,
          payment_method: paymentMethod,
          supplier_id: "" + response.data.supplier_id,
          deposit_details: depositDetails,
        };

        CustomConsole("----Hello there--------");
        CustomConsole(response.data.order_id[0]);
        CustomConsole(orderDetails);
        // if (
        //   paymentMethod === "PAYSHARP" ||
        //   paymentMethod === "RAZORPAY" ||
        //   paymentMethod === "WATERWALLET"
        // ) {
        //   // AssignSupplierForPDW(orderDetails);
        //   try {
        //     let response = await api.post(`/OM/AssignSupplier`, orderDetails);
        //     if (response.status === httpStatus.SUCCESS) {
        //       CustomConsole("AssignSupplierForPDW RESPONSE: ");
        //       CustomConsole(response);
        //       dispatch({
        //         type: actionType.BOOK_PWD_ORDER,
        //         payload: { data: response.data, statusFlag: true },
        //       });

        //     } else {
        //       CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        //     }
        //   } catch (error) {
        //     CustomConsole("Error: " + JSON.stringify(error));
        //     toast.dark(error.response.data.msg, {
        //       position: "bottom-right",
        //       autoClose: 3000,
        //       type: "error",
        //     });
        //   }
        // } else {
        //   toast.dark(`Post Paid Enabled For Order : ${orderDetails.order_id}`, {
        //     position: "bottom-right",
        //     autoClose: 3000,
        //     type: "success",
        //   });
        // }
      } else {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.BOOK_POSTPAID,
        payload: { data: error.response.data, statusFlag: true },
      });
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const SubscriptionOrderPWD = (p) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole("SubscriptionOrderPWD");

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/subscribeOrder`, p);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole("SubscriptionOrderPWD RESPONSE: ");
        CustomConsole(response);
        dispatch({
          type: actionType.BOOK_PDW_SUBSCRIBE_ORDER,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        toast.dark(`Unable to process the subscription`, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const UpdateSubscriptionOrder = (p) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole("UpdateSubscriptionOrder");

  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/updateSubscribeOrder`, p);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole("UpdateSubscriptionorder RESPONSE: ");
        CustomConsole(response);
        dispatch({
          type: actionType.UPDATE_SUBSCRIPTION_ORDER,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        toast.dark(`Unable to process the subscription`, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark("Error in updating", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
