import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { GetSupplierDetailsReplacement } from "../Redux/Actions/adminReplacementOrderAction";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";


function AcceptDialog({ open, setOpen, orderDetails, setSupplierTable }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [pincode, setPincode] = React.useState("");


  const handleClose = () => {
    setOpen(false);
  };

  const getSupplierDetails = () => {
    CustomConsole(pincode);
    if (pincode.length === 6) {
      const reqObj = {
        pincode: pincode,
        container_qty:orderDetails.replace_can_qty,
        capacity: orderDetails.container_capacity,
        material: orderDetails.container_material,
      };
      CustomConsole(reqObj);
      dispatch(GetSupplierDetailsReplacement(reqObj));
      setSupplierTable(true);
    }

    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Enter the pincode."}
        </DialogTitle>
        <DialogContent>
          <TextField
            type="number"
            label="Enter Pincode"
            variant="outlined"
            sx={{
              marginTop: "8px",
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 6);
            }}
            onChange={(e) => {
              setPincode(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={getSupplierDetails}>
            Apply
          </Button>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AcceptDialog;
