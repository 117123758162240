import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CustomConsole from "../../CustomConsole";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetPoTrackDetails } from "../Redux/Actions/adminPoTrackPostpaid";
import { GetUserBasedName } from "../Redux/Actions/userDetailsAdminAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PoTrackFilterDialog({
  open,
  setOpen,
  pageSize,
  setPageSize,
  poTrack,
  setPoTrack,
  orderType,
  setOrderType,
  setPhone,
  phone,
  sort,
  setSort,
  setFilterApply,
  potrackErr,
  setPoTrackErr,
  pageNumber,
}) {
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.userDetailsUpdate.userNames);
  const [getName, setGetName] = React.useState([]);
  const [nameSearch, setNameSearch] = React.useState([]);
  const [getPhone, setGetPhone] = React.useState("");

  const applyFilter = () => {
    setFilterApply(true);
  };

  const handlePageSize = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    e.target.value = numericValue;
    setPageSize(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    e.target.value = numericValue;
    setPhone(e.target.value);
  };

  const handleClose = () => {
    let reqObj = {
      page_size: "",
      page_number: pageNumber,
      pp_track_id: "",
      order_status: "",
      sort: "",
      phone: "",
    };
    dispatch(GetPoTrackDetails(reqObj));
    setSort("");
    setPhone("");
    setOrderType("");
    setPoTrack("");
    setPageSize("");
    setNameSearch([]);
    setOpen(false);
  };

  const handlePoTrack = (e) => {
    setPoTrack(e.target.value);
    let value = e.target.value;
    const regex = /^BWPP/;
    CustomConsole(regex.test(value));

    setPoTrackErr(!regex.test(value));
  };

  React.useEffect(() => {
    if (getName && getName.length) {
      const timerId = setTimeout(() => {
        CustomConsole(getName);
        const reqObj = {
          name: getName,
          ignore_app_loading_spinner:true
        };
        dispatch(GetUserBasedName(reqObj));
      }, 200);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [getName]);

  React.useEffect(() => {
    CustomConsole("------User Search details-----");
    CustomConsole(userName);
    if (userName) {
      setNameSearch(userName);
    } else {
      setNameSearch([]);
    }
  }, [userName]);

  React.useEffect(() => {
    CustomConsole("-----Address----");
    CustomConsole(getPhone);
    if (getPhone) {
      setPhone(getPhone);
    }
  }, [getPhone]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Filter"}</DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={{ xs: 1, sm: 2 }}
            useFlexGap
            flexWrap="wrap"
            justifyContent="space-between"
            mt={2}
          >
            <TextField
              size="small"
              InputLabelProps={{ shrink: true }}
              label="Page size"
              value={pageSize}
              onChange={handlePageSize}
            />
            <TextField
              size="small"
              InputLabelProps={{ shrink: true }}
              label="PO Track Id"
              value={poTrack}
              error={potrackErr}
              onChange={handlePoTrack}
            />
          </Stack>

          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={{ xs: 1, sm: 2 }}
            useFlexGap
            flexWrap="wrap"
            justifyContent="space-between"
            mt={2}
          >
            <FormControl size="small" sx={{ width: "210px" }}>
              <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={orderType}
                label="Order Type"
                onChange={(e) => setOrderType(e.target.value)}
              >
                <MenuItem value={100}>Requested</MenuItem>
                <MenuItem value={200}>Accepted</MenuItem>
                <MenuItem value={300}>Rejected</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: "210px" }}>
              <InputLabel id="demo-simple-select-label">Sort</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sort}
                label="Sort"
                onChange={(e) => setSort(e.target.value)}
              >
                <MenuItem value={"asc"}>ASC</MenuItem>
                <MenuItem value={"desc"}>DESC</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={{ xs: 1, sm: 2 }}
            useFlexGap
            flexWrap="wrap"
            justifyContent="space-between"
            mt={2}
          >
            <Autocomplete
              id="select-user-name"
              sx={{ width: 210 }}
              options={nameSearch}
              autoHighlight
              getOptionLabel={(nameSearch) =>
                `${nameSearch.fullname}-${
                  nameSearch.email ? nameSearch.email : ""
                }`
              }
              onChange={(_, newValue) => {
                setGetPhone(newValue ? newValue.phone : ""); // Set the selected description as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.fullname} {nameSearch.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Name"
                  value={setGetName}
                  onChange={(e) => {
                    setGetName(e.target.value);
                  }}
                />
              )}
            />

            <TextField
              size="small"
              InputLabelProps={{ shrink: true }}
              label="Phone"
              value={phone}
              inputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handlePhoneChange, // Attach the input event handler
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Clear</Button>
          <Button onClick={applyFilter}>Apply</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PoTrackFilterDialog;
