import { toast } from "react-toastify";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";

export const ValidateExpired = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/QSM/expired/product/details`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.VALIDATE_EXPIRED,
          payload: response.data,
        });
        // toast.success(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        // toast.error(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const ClearDataRefershBatch = () => ({
  type: actionType.CLEAR_DATA_REFRESH_BATCH,
});
export const LinkValidCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/QSM/scan/wfs_batch`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_VALID_CANS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const LinkInscanEmptyValidCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/QSM/inscan/ec_order`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_EMPTY_INSCAN_VALID_CANS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const LinkOutscanEmptyValidCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/QSM/outscan/ec_order`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_EMPTY_OUTSCAN_VALID_CANS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const ClearValidatedData = () => {
  return {
    type: actionType.CLEAR_VALIDATED_DATA,
    data: {
      error: true,
      msg: "",
      validatedData: [],
      validatedDataOrder: [],
    },
  };
};
