export const styles = {
    cardStyleHeading: {
      color: "#03a5fc",
      fontWeight: "bold",
      padding: "5px",
   
    },
    cardStyleValue: {
  
      marginLeft: "10px",
      color: "black",
      fontWeight: "500",
      textAlign: "right"
    },
    CardHeading:{
      fontWeight: "bold",
      fontSize: 14, backgroundImage:
      "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
    }
  };