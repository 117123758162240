import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import CustomConsole from "../../CustomConsole";

export default function PostpaidOrdersTable({
  postpaidList,
  handleOrderListClick,
  paginationModel,
  setPaginationModel,
  pageNumber,
}) {
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: appColors.commonLayoutColor.background, // Set the desired background color here
            color: "black", // Set the desired text color here
            position: "sticky",
            marginTop: "0 !important",
          },
        },
      },
    },
  });
  const rows = postpaidList.map((item, index) => ({
    id: index,
    order_id: item.order_id,
    order_price: item.order_price,
    user_id: item.user_id,
    status: item.status,
    created_at: item.created_at,
    filename: item.filename,
    paid_amount: item.paid_amount,
    pending_amount: item.pending_amount,
    delivery_date: item.delivery_date,
    consumer_name: item.consumer_name,
  }));

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      valueGetter: (params) =>
        pageNumber > 1 ? pageNumber * 10 + (params.id + 1) - 10 : params.id + 1,
    },
    {
      field: "order_id",
      headerName: "Order ID",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
    {
      field: "order_price",
      headerName: "Order Price",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
    // {
    //   field: "user_id",
    //   headerName: "User ID",
    //   width: 150,
    //   valueFormatter: (params) => params.value || "---",
    // },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
    // {
    //   field: "filename",
    //   headerName: "Filename",
    //   width: 150,
    //   valueFormatter: (params) => params.value || "---",
    // },
    {
      field: "paid_amount",
      headerName: "Paid Amount",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
    {
      field: "pending_amount",
      headerName: "Pending Amount",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
    {
      field: "delivery_date",
      headerName: "Delivery Date",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
    {
      field: "consumer_name",
      headerName: "Consumer Name",
      width: 150,
      valueFormatter: (params) => params.value || "---",
    },
  ];
  return (
    <div style={{ maxWidth: "150Vw" }}>
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          page={paginationModel.page}
          onRowClick={(params) => {
            const rowData = params.row;
            handleOrderListClick(
              rowData.order_id,
              rowData.status,
              rowData.order_price,
              rowData.user_id,
              rowData.postpaid_charges
            );
          }}
          sx={{ cursor: "pointer" }}
          hideFooter={true}
        />
      </ThemeProvider>
    </div>
  );
}
