import React, { useState } from "react";
import { paymentBlockingCodes } from "../../../Components/AppMeta/appMetaConfig";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../../CustomConsole";
import {
  BlockPayments,
  GetBlockedPayments,
} from "../../Redux/Actions/appSettingsAction";

function PaymentBlocking() {
  const dispatch = useDispatch();
  const blockedPaymentData = useSelector(
    (state) => state.appSettingsDetails.blockedPayments
  );
  const [selectedPayment, setSelectedPayment] = useState(103);
  const [reason, setReason] = useState("");

  const handleDelete = (type) => {
    const reqObj = {
      type: type,
      reason: "remove..",
      is_blocked: false,
    };
    dispatch(BlockPayments(reqObj));
  };

  const namePaymentMethod = (type) => {
    switch (type) {
      case 100:
        return "All paysharp payments";
      case 101:
        return "Paysharp UPI";
      case 102:
        return "Paysharp QR";
      case 103:
        return "Paysharp Search UPI";
      case 104:
        return "Virtual Bank";
      case 200:
        return "RazorPay";
      case 300:
        return "Wallet";
      default:
        return false;
    }
  };

  const blockPaymentFun = (e) => {
    e.preventDefault();
    const reqObj = {
      type: selectedPayment,
      reason: reason,
      is_blocked: true,
    };
    dispatch(BlockPayments(reqObj));
  };

  React.useEffect(() => {
    CustomConsole("------Blocked Payments-----");
    CustomConsole(blockedPaymentData);
  }, [blockedPaymentData]);

  React.useEffect(() => {
    dispatch(GetBlockedPayments());
  }, []);

  return (
    <Box>
      <Typography variant="h6" sx={{ textAlign: "left", marginBottom: "5px" }}>
        Blocked Payments
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        columnGap={1}
        rowGap={1}
        mb={2}
        sx={{ overflow: "auto", flexWrap: "wrap" }}
      >
        {blockedPaymentData.map((item) => {
          return (
            <Chip
              label={namePaymentMethod(item.type)}
              color="warning"
              onDelete={() => handleDelete(item.type)}
            />
          );
        })}
      </Stack>
      <form onSubmit={blockPaymentFun}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Box
          // sx={{
          //   width: "25%",
          // }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-label-payment-methods">
                Payment Methods
              </InputLabel>
              <Select
                labelId="select-label-payment-methods"
                id="select-payment-methods"
                value={selectedPayment}
                label="Payment Methods"
                onChange={(e) => setSelectedPayment(e.target.value)}
                required
              >
                {Object.keys(paymentBlockingCodes).map((key) => {
                  return (
                    <MenuItem value={paymentBlockingCodes[key]}>
                      {namePaymentMethod(paymentBlockingCodes[key])}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <TextField
            id="outlined-multiline-flexible"
            label="Reason.."
            variant="outlined"
            multiline
            value={reason}
            required
            onChange={(e) => setReason(e.target.value)}
          />
          <Button variant="contained" type="submit">
            Block
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default PaymentBlocking;
