import React, { useState } from "react";
import { Typography, Button, Box, Snackbar } from "@mui/material";
import { useDispatch } from "react-redux";
import DeleteUserDialog from "../../Dialog/DeleteUserDialog";
import { green } from "@mui/material/colors";
import { DeleteUser } from "../../../Pages/Redux/Actions/userDetailsAdminAction";

const Complete = ({ user_id, stepColors = [] }) => {
  const dispatch = useDispatch();

  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [userDeleted, setUserDeleted] = useState(false);

  const handleOpenDialog = () => {
    setDeleteUserDialog(true);
  };

  const handleCloseDialog = () => {
    setDeleteUserDialog(false);
  };

  const handleDelete = () => {
    if (user_id) {
      dispatch(DeleteUser(user_id))
        .then(() => {
          setUserDeleted(true);
          setSuccessMessage("User successfully deleted.");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          handleCloseDialog();
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
          setSuccessMessage("Failed to delete user.");
        });
    } else {
      console.error("No user_id provided");
    }
  };

  const firstFiveStepsCompleted =
    stepColors.length >= 4 &&
    stepColors.slice(0, 4).every((color) => color === green[500]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: 1, sm: 2 }, 
        height: "100%",
        justifyContent: "flex-start",
      }}
    >
      {firstFiveStepsCompleted ? (
        userDeleted ? (
          <Typography
            color="success.main"
            sx={{ mt: 2, fontSize: { xs: "0.875rem", sm: "1rem" } }}
          >
            {successMessage}
          </Typography>
        ) : (
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleOpenDialog}
              sx={{
                mb: 2,
                fontSize: { xs: "0.75rem", sm: "0.875rem" }, 
                padding: { xs: "6px 12px", sm: "8px 16px" }, 
              }}
            >
              Delete User
            </Button>
            <DeleteUserDialog
              deleteUserDialog={deleteUserDialog}
              handleClose={handleCloseDialog}
              handleDelete={handleDelete}
            />
          </Box>
        )
      ) : (
        <Typography
          color="error"
          sx={{
            mt: 2,
            fontSize: { xs: "0.875rem", sm: "1rem" }, 
            textAlign: "center",
          }}
        >
          Note: Please complete all previous steps before proceeding to delete.
        </Typography>
      )}

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
      />
    </Box>
  );
};

export default Complete;
