import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Trips from "./Trips";
import VehicleTrips from "./VehicleTrips";
import VehicleChecklist from "./VehicleChecklist";
import { useLocation, useHistory } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import EmployeesAttendance from "./TripPlanning/EmployeesAttendance";
import { useSelector, useDispatch } from "react-redux";
import {
  GetFilteredUserAddressAdmin,
  GetUserAddressAdminById,
} from "../Redux/Actions/userDetailsAdminAction";
import {
  EmployeesPunchInOut,
  GetPunchDetails,
} from "../Redux/Actions/supplierVehiclesActions";
import { PunchInModel } from "./PunchInModel";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const useHashValue = () => {
  const location = useLocation();
  const [hashValue, setHashValue] = useState(location.hash.substring(1));

  useEffect(() => {
    setHashValue(location.hash.substring(1));
  }, [location]);

  return hashValue;
};
const TabValues = {
  Employees: 1,
  Vehicles: 2,
};
const TripsMain = () => {
  const dispatch = useDispatch();

  const hashValue = useHashValue();
  const query = useQuery();

  const [openPunchInModel, setOpenPunchInModel] = useState(false);
  const history = useHistory();
  const vehicleManagement = useSelector((state) => state.vehicleManagement);

  useEffect(() => {
    if (hashValue === "punch_in") {
      setOpenPunchInModel(true);
    } else {
      setOpenPunchInModel(false);
    }
  }, [hashValue]);
  useEffect(() => {
    if (vehicleManagement) {
      if (
        !vehicleManagement.punchDetails ||
        !vehicleManagement.punchDetails.in_time ||
        vehicleManagement.punchDetails.out_time
      ) {
        history.push({ hash: "#punch_in", query });
      } else {
        history.push({ hash: "", query });
      }
    }
  }, [vehicleManagement.punchDetails]);
  useEffect(() => {
    let tab = query.get("tab");
    console.log();
    if (tab) {
      setValue(TabValues[tab]);
    }
  }, [query]);
  useEffect(() => {
    dispatch(GetPunchDetails());
  }, []);
  const onPunchIn = () => {
    history.push({ hash: "" });
  };
  const navigateToTab = (value) => {
    history.push({ search: `?tab=${value}` });
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ marginTop: "50px", width: "100%" }}>
      {/* <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Vehicles" {...a11yProps(0)} />
          <Tab label="All Trips" {...a11yProps(1)} />
          <Tab label="Resources" {...a11yProps(2)} />
        </Tabs>
      </AppBar> */}
      {/* <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      > */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        <VehicleTrips />
      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        <Trips />
      </TabPanel>
      <TabPanel value={value} index={TabValues.Employees} dir={theme.direction}>
        <EmployeesAttendance setTab={navigateToTab} />
      </TabPanel>
      <TabPanel value={value} index={TabValues.Vehicles} dir={theme.direction}>
        <VehicleTrips TabValues={TabValues} setTab={navigateToTab} />
      </TabPanel>

      {openPunchInModel && (
        <PunchInModel open={openPunchInModel} onPunchIn={() => {}} />
      )}
      {/* </SwipeableViews> */}
    </Box>
  );
};

export default TripsMain;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
