import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const GetEmptyCanOrders = (page, pageSize) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/admin/EcOrderList?page_number=${page}&page_size=${pageSize}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_EMPTY_CAN_ORDER,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetOrderIdDetails = (oId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/admin/EcOrderDetails/${oId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_EMPTY_CAN_BY_ORDER,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const ToRejectEmptyCanReq = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/admin/supplierToWFSOrder/Reject`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REJECT_EMPTY_CAN_REQ,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      CustomConsole(error);
    }
  };
};

export const GetWFSDetails = (obj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/wfs/getwfsDetails?page_number=${obj.page_number}&order_id=${obj.order_id}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WFS_DETAILS_TO_REJECT,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const ToAcceptEmptyCanReq = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      CustomConsole(reqObj);
      let response = await api.put(
        `/OM/admin/supplierToWFSOrder/Accept`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ACCEPT_EMPTY_CAN_REQ,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

// export const PutEditEmptyCanOrder = (reqObj) => {
//   const httpStatus = {
//     SUCCESS: 200,
//     BAD_REQUEST: 400,
//   };

//   let api = useAxios();
//   return async (dispatch) => {
//     try {
//       let response = await api.put(`/OM/wfs/edit/order`, reqObj);
//       CustomConsole(response);
//       if (response.status === httpStatus.SUCCESS) {
//         dispatch({
//           type: actionType.PUT_EDIT_WFS_ORDER,
//           payload: response.data,
//         });

//         toast.success(response.data.msg, {
//           position: "bottom-right",
//           autoClose: 2000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       } else {
//         toast.error(response.data.msg, {
//           position: "bottom-right",
//           autoClose: 2000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       }
//     } catch (error) {
//       toast.error(error.response.data.msg, {
//         position: "bottom-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
//     }
//   };
// };
