import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSuppliersForPincode,
  PostpaidAcceptReject,
  GetPoTrackDetails,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AssignSupplier({ open, setOpen, postPaidTrack }) {
  let supplierDetails = useSelector((state) => state.poTrackDetails.suppliers);
  const poDetails = useSelector((state) => state.poTrackDetails);
  const dispatch = useDispatch();
  const [pincode, setPincode] = React.useState("");
  const [supplierSelect, setSupplierSelect] = React.useState(0);
  const [poAcceptFlag, setPoAcceptFlag] = React.useState(false);
  const [supplierArr, setSupplierArr] = React.useState([]);

  React.useEffect(() => {
    CustomConsole("-----Suppliers-----");
    CustomConsole(supplierDetails);
    setSupplierArr(supplierDetails ? supplierDetails : []);
  }, [supplierDetails]);

  React.useEffect(() => {
    if (poAcceptFlag && poDetails.error === false) {
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setPoAcceptFlag(false);
      let reqObj = {
        page_size: "",
        page_number: "",
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));
      setOpen(false);
    } else if (poAcceptFlag) {
      toast.error("Error in Accepting...", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setPoAcceptFlag(false);
    }
  }, [poDetails]);

  const handleClose = () => {
    setOpen(false);
    setPincode("");
    setSupplierArr([]);
  };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length === 6) {
      dispatch(GetSuppliersForPincode(e.target.value));
      setSupplierSelect(0);
    }
  };

  const handleSupplierChange = (data) => {
    setSupplierSelect(() => data.supplier_id);
  };

  // React.useEffect(()=>{
  //  if(supplierSelect !==0){

  //  }
  // },[supplierSelect])

  const acceptPO = () => {
    let reqObj = {
      pp_track_id: postPaidTrack,
      is_accepted: true,
      supplier_id: supplierSelect,
    };
    if (reqObj.is_accepted && reqObj.pp_track_id && supplierSelect !== 0) {
      dispatch(PostpaidAcceptReject(reqObj));
      setPoAcceptFlag(true);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Assign Supplier</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{ marginTop: "10px" }}>
              <TextField
                id="outlined-basic"
                type="text"
                label="Pincode"
                variant="outlined"
                value={pincode}
                inputProps={{
                  maxLength: 6,
                }}
                onChange={handlePincodeChange}
              />
            </Box>
            <Box>
              {supplierArr ? (
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    {supplierArr.map((data, index) => (
                      <Card
                        sx={{
                          marginTop: "5px",
                          marginBottom: "3px",
                          backgroundColor:
                            supplierSelect === data.supplier_id
                              ? "#2196f3"
                              : "#EBF1F4",
                          "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#2196f3",
                          },
                        }}
                        onClick={() => handleSupplierChange(data)}
                        key={index}
                      >
                        <CardContent>
                          <Typography sx={{ fontWeight: "600" }}>
                            Supplier Name: {data.fullname}
                          </Typography>
                          <Typography>Phone Number:{data.phone}</Typography>
                          <Typography>
                            Container to be Delivered:
                            {data.delivery_containers}
                          </Typography>
                          <Typography>
                            Empty container with distributor:
                            {data.empty_container_qty}
                          </Typography>
                          <Typography>
                            Filled container with distributor:
                            {data.filled_container_qty}
                          </Typography>
                          {/* <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                            display: "inline-flex",
                            flexWrap: "wrap",
                          }}
                          component="nav"
                          aria-label="folders"
                          onClick={() => handleSupplierChange(data)}
                          key={index}
                        >
                          <ListItem
                            button
                            sx={{
                              display: "inline-flex",
                              flexWrap: "wrap",
                              backgroundColor:
                                supplierSelect === data.supplier_id
                                  ? "#2196f3"
                                  : "",
                              "&:hover": {
                                boxShadow: "none",
                                backgroundColor: "#2196f3",
                              },
                            }}
                          >
                          
                          </ListItem>
                          <Divider />
                        </List> */}
                        </CardContent>
                      </Card>
                    ))}
                  </FormControl>
                </Box>
              ) : (
                " "
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {supplierSelect !== 0 ? (
            <Button onClick={acceptPO}>Assign</Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AssignSupplier;
