import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SettlementRequests } from "../Redux/Actions/adminSettlementAction.js";
import { toast } from "react-toastify";
import { GetBankDetailsIfsc } from "../Redux/Actions/bankDetailsAction.js";
import CustomConsole from "../../CustomConsole";

function RedeemAmountToAccount({
  openRedeem,
  setOpenRedeem,
  userId,
  accountDetails,
}) {
  const dispatch = useDispatch();
  //   const influencerReq = useSelector((state) => state.referAndEarnDetails);
  const settlementsDetails = useSelector((state) => state.settlementsDetails);
  const [influencerPan, setInfluencerPan] = React.useState("");
  const [influencerIfscCode, setInfluencerIfscCode] = React.useState("");
  const [influencerBankName, setInfluencerBankName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [influencerAccountNumber, setInfluencerAccountNumber] =
    React.useState("");
  const [reEnterAccountNum, setReEnterAccountNum] = React.useState("");
  // const userDetails = useSelector((state) => state.referAndEarnDetails);
  const bankIfscDet = useSelector((state) => state.bankDetailsIfsc);
  const profileDetails = useSelector((state) => state.userProfile);

  //helper text for fields
  const [accountNumHt, setAccountNumHt] = React.useState("");
  const [reaccountNumberHt, setReAccountNumHt] = React.useState("");
  const [panNumberHt, setPanNumberHt] = React.useState("");
  const [ifscHt, setIfscHt] = React.useState("");

  //flags
  const [panNumEr, setPanNumEr] = React.useState(false);
  const [ifscEr, setIfscEr] = React.useState(false);
  const [settleToast, setSettleToast] = React.useState(false);
  const [waterDrop, setWaterDrop] = React.useState(0);
  const alphabetPattern = /^[a-zA-Z ]*$/;

  const handlePanChange = (e) => {
    const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(
      e.target.value.toUpperCase()
    );
    setPanNumEr(!isValidPanNumber);
    setInfluencerPan(e.target.value);
    if (!isValidPanNumber) {
      setPanNumberHt("Enter Valid PAN");
    } else {
      setPanNumberHt("");
    }
  };

  const handleIfscChange = (e) => {
    const isValidIfscCode = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(e.target.value);
    setIfscEr(!isValidIfscCode);
    setInfluencerIfscCode(e.target.value);
    if (!isValidIfscCode) {
      setIfscHt("Enter Valid IFSC");
    } else {
      setIfscHt("");
    }
  };

  const handleChangeAccount = (e) => {
    setInfluencerAccountNumber(e.target.value);
    if (e.target.value !== reEnterAccountNum) {
      setReAccountNumHt("Confirm Account Number Mismatched.");
    }
    if (e.target.value === reEnterAccountNum) {
      setReAccountNumHt("");
    }
    if (e.target.value.length < 9 || e.target.value.length > 18) {
      setAccountNumHt(
        "Account Number Must have Min: 9-Digits & Max: 18-Digits"
      );
    } else {
      setAccountNumHt("");
    }
  };

  const handleChangeReAccount = (e) => {
    setReEnterAccountNum(e.target.value);
    if (e.target.value !== influencerAccountNumber) {
      setReAccountNumHt("Confirm Account Number Mismatched.");
    } else if (e.target.value.length < 9 || e.target.value.length > 18) {
      setReAccountNumHt(
        "Account Number Must have Min: 9-Digits & Max: 18-Digits"
      );
    } else {
      setReAccountNumHt("");
    }
  };

  const handleClose = () => {
    setOpenRedeem(false);
    setInfluencerPan("");
    setInfluencerIfscCode("");
    setInfluencerBankName("");
    setInfluencerAccountNumber("");
    setReEnterAccountNum("");
  };

  React.useEffect(() => {
    CustomConsole(settlementsDetails);
    if (!settlementsDetails.requestError && settleToast) {
      toast.dark(settlementsDetails.msg, {
        type: "success",
        position: "bottom-right",
        autoClose: 5000,
      });
      setSettleToast(false);
    }
    if (settlementsDetails.requestError && settleToast) {
      toast.dark(settlementsDetails.msg, {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
      setSettleToast(false);
    }
  }, [settlementsDetails]);

  // React.useEffect(() => {
  //   if (
  //     userDetails.bk_details &&
  //     Object.keys(userDetails.bk_details).length > 0
  //   ) {
  //     setInfluencerPan(userDetails.bk_details.consumer_profile.pan_number);
  //     setInfluencerIfscCode(
  //       userDetails.bk_details.consumer_profile.IFSC_number
  //     );
  //     setInfluencerBankName(userDetails.bk_details.consumer_profile.bank_name);
  //     setInfluencerAccountNumber(
  //       userDetails.bk_details.consumer_profile.account_number
  //     );
  //     setReEnterAccountNum(
  //       userDetails.bk_details.consumer_profile.account_number
  //     );
  //   }

  //   CustomConsole(userDetails.bk_details);
  // }, [userDetails]);

  //   React.useEffect(() => {
  //     CustomConsole("-----Bank Details-----");
  //     CustomConsole(profileDetails.consumer);

  //     if (
  //       profileDetails.consumer &&
  //       Object.keys(profileDetails.consumer).length > 0
  //     ) {
  //       setInfluencerPan(profileDetails.consumer.pan_number);
  //       setInfluencerIfscCode(profileDetails.consumer.IFSC_number);
  //       setInfluencerBankName(profileDetails.consumer.bank_name);
  //       setInfluencerAccountNumber(profileDetails.consumer.account_number);
  //       setReEnterAccountNum(profileDetails.consumer.account_number);
  //     }
  //   }, []);

  React.useEffect(() => {
    CustomConsole(bankIfscDet);
    if (
      bankIfscDet.branchDetails &&
      Object.keys(bankIfscDet.branchDetails).length > 0
    ) {
      setInfluencerBankName(bankIfscDet.branchDetails.BANK);
    }
  }, [bankIfscDet]);

  const getIfscBankDetails = () => {
    if (!ifscEr && influencerIfscCode !== "") {
      dispatch(GetBankDetailsIfsc(influencerIfscCode));
    }
  };

  const settlementRequest = () => {
    if (!ifscEr && !panNumEr && influencerAccountNumber === reEnterAccountNum && waterDrop) {
      const reqObj = {
        pan_number: influencerPan,
        bank_name: influencerBankName,
        ifsc_number: influencerIfscCode,
        account_number: influencerAccountNumber,
        user_id: userId,
        water_drops:parseInt(waterDrop)
      };
      CustomConsole(reqObj);
      dispatch(SettlementRequests(reqObj));
      setOpenRedeem(false);
      setSettleToast(true);
    } else {
      CustomConsole("---Condition failed---");
    }
  };
  React.useEffect(() => {
    if (accountDetails && accountDetails.length > 0) {
      setAccountNumber(accountDetails[0].account_id);
    }
  }, [accountDetails]);

  React.useEffect(() => {
    if (accountDetails && accountNumber) {
      const output = accountDetails?.find((obj) => {
        return obj.account_id === accountNumber;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      // setSelectedStock(output);
      if (output) {
        // setSingleSelectedAccountData(output);
        setInfluencerAccountNumber(output.account_number);
        setInfluencerBankName(output.bank_name);
        setReEnterAccountNum(output.account_number);
        setInfluencerIfscCode(output.ifsc_code);
      } else {
        // setSingleSelectedAccountData({});
      }
    }
  }, [accountDetails, accountNumber]);
  const handleChangeACcount = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setWaterDrop(inputValue);
    }
  };

  return (
    <Dialog
      open={openRedeem}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      {/* <DialogTitle textAlign="center" id="alert-dialog-title">
        To Be Transfered To This Account
      </DialogTitle> */}
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Typography sx={{ mt: 2 }}>
            {" "}
            To Be Transfered To This Account
          </Typography>
          <Box>
            <FormControl sx={{ m: 1, width: 220 }} size="small">
              <InputLabel>Select A/c Number</InputLabel>
              <Select
                value={accountNumber}
                label="Select A/c Number"
                onChange={handleChangeACcount}
              >
                {accountDetails?.map((data) => {
                  return (
                    <MenuItem value={data.account_id}>
                      {data.account_number}/{data.bank_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <TextField
            margin="dense"
            id="name"
            label="Enter your IFSC code*"
            type="text"
            fullWidth
            value={influencerIfscCode}
            onChange={handleIfscChange}
            FormHelperTextProps={{ style: { color: "red" } }}
            helperText={ifscHt}
          />
          <Button variant="contained" onClick={getIfscBankDetails}>
            Verify
          </Button>
        </Box>
        <TextField
          margin="dense"
          id="name"
          label="Enter your BANK Name*"
          type="text"
          fullWidth
          onKeyDown={(e) => {
            if (!alphabetPattern.test(e.key)) {
              e.preventDefault();
            }
          }}
          value={influencerBankName}
          onChange={(e) => {
            setInfluencerBankName(e.target.value);
          }}
        />

        <TextField
          margin="dense"
          id="name"
          label="Enter your PAN number*"
          InputProps={{
            inputProps: {
              style: { textTransform: "uppercase" },
            },
          }}
          type="text"
          fullWidth
          value={influencerPan}
          onChange={handlePanChange}
          FormHelperTextProps={{ style: { color: "red" } }}
          helperText={panNumberHt}
        />

        <TextField
          margin="dense"
          id="name"
          label="Enter your BANK Name*"
          type="text"
          fullWidth
          onKeyDown={(e) => {
            if (!alphabetPattern.test(e.key)) {
              e.preventDefault();
            }
          }}
          value={influencerBankName}
          onChange={(e) => {
            setInfluencerBankName(e.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="name"
          label="Enter your Account number*"
          type="number"
          fullWidth
          value={influencerAccountNumber}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 18);
          }}
          helperText={accountNumHt}
          FormHelperTextProps={{ style: { color: "red" } }}
          onChange={handleChangeAccount}
        />
        <TextField
          margin="dense"
          id="name"
          label="Re-enter your account number*"
          type="number"
          fullWidth
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 18);
          }}
          helperText={reaccountNumberHt}
          FormHelperTextProps={{ style: { color: "red" } }}
          value={reEnterAccountNum}
          onChange={handleChangeReAccount}
        />
        <TextField
          label="Enter number of water drops"
          type="number"
          margin="dense"
          fullWidth
          value={waterDrop}
          onChange={handleInputChange}
          InputProps={{
            inputProps: {
              pattern: "\\d*",
              inputMode: "numeric",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={settlementRequest} variant="contained">
          Send Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RedeemAmountToAccount;
