/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 18-01-2024
 */
import React from "react";
import * as Material from "@mui/material";
import glassbottle from "../../Images/glassbottle.jpg";
import Rectangle384 from "../../Images/Rectangle 384.png";
import paperCrate from "../../Images/paperCrate.webp";
import { useDispatch } from "react-redux";
import { DeleteCrateWithProduct } from "../../Pages/Redux/Actions/SKUProductDetailsAction";

import CustomConsole from "../../CustomConsole";
function ParticularProductCrateCard(props) {
  const dispatch = useDispatch();
  CustomConsole(props.productCrateDetails);
  //function for handling delete function
  const handelDeleteFunction = (data) => {
    const reqObj = {
      crate_id: data.id,
      crate_name: data.name,
      product_id: props.productCrateDetails.product_details.id,
      product_capacity: props.productCrateDetails.product_details.capacity,
      product_material: props.productCrateDetails.product_details.material,
    };
    dispatch(DeleteCrateWithProduct(reqObj));
    props.onClose();
  };
  return (
    <Material.Box>
      {props.productCrateDetails.crate_details?.map((data, index) => (
        <Material.Paper
          sx={{ margin: "10px", padding: "10px", width: "350px" }}
          elevation={20}
          key={index}
        >
          <Material.Box
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Material.Box>
              <img
                src={Rectangle384}
                alt="image"
                style={{ height: "90px", width: "60px" }}
              />
            </Material.Box>
            <Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Crate Name :
                </Material.Typography>
                <Material.Typography sx={{ fontSize: "14px" }}>
                  {data.name}
                </Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Price :
                </Material.Typography>
                <Material.Typography sx={{ fontSize: "14px" }}>
                  {data.price}
                </Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Capacity :
                </Material.Typography>
                <Material.Typography sx={{ fontSize: "14px" }}>
                  {data.capacity}
                </Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Description
                </Material.Typography>
                <Material.Typography sx={{ fontSize: "14px" }}>
                  {data.description}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box sx={{ display: "flex", justifyContent: "center" }}>
            <Material.Button
              size="small"
              color="error"
              variant="outlined"
              onClick={() => handelDeleteFunction(data)}
            >
              Delete
            </Material.Button>
          </Material.Box>
        </Material.Paper>
      ))}
    </Material.Box>
  );
}

export default ParticularProductCrateCard;
