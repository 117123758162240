import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { CreateBanners } from "../Redux/Actions/adminBannersAction";
import { ToastContainer, toast } from "react-toastify";
import { UploadFile, getFiles } from "../Redux/Actions/fileHandlingAction";
import { Stack } from "@mui/system";
import PreviewIcon from "@mui/icons-material/Preview";
import CustomConsole from "../../CustomConsole";

export default function AddBannerDetailsDialog({
  isAddBannerDetailsDialog,
  setIsAddBannerDetailsDialog,
  bannerTypeList,
  setBannerDetailsFilterFlag,
}) {
  const dispatch = useDispatch();
  const bannersData = useSelector((state) => state.adminBannersData);
  const fileUploadDetails = useSelector((state) => state.fileHandler);
  const bookingDetails = useSelector((state) => state.adminBookingData);
  CustomConsole(bookingDetails);

  React.useEffect(() => {
    CustomConsole(bannerTypeList);
  }, [bannerTypeList]);

  const [bannerType, setBannerType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [bannerHeader, setBannerHeader] = React.useState("");
  const [expiryHrs, setExpiryHrs] = React.useState(0);
  const [displayOrder, setDisplayOrder] = React.useState(0);
  const [minScreenFile, setMinScreenFile] = React.useState("");
  const [maxScreenFile, setMaxScreenFile] = React.useState("");
  const [appPage, setAppPage] = React.useState("");
  const [appPagesmeta, setAppPagesmeta] = React.useState([]);
  CustomConsole(appPagesmeta);
  CustomConsole(appPage);

  //fileupload ref
  const minScreenFileRef = React.useRef(null);
  const maxScreenFileRef = React.useRef(null);

  //image upload flags
  const [minScreenFlag, setMinScreenFlag] = React.useState(false);
  const [maxScreenFlag, setMaxScreenFlag] = React.useState(false);

  const [addNewBannerFlag, setAddNewBannerFlag] = React.useState(false);
  const [status, setStatus] = React.useState(false);

  React.useEffect(() => {
    if (addNewBannerFlag) {
      const reqObj = {
        banner_type: bannerType,
        message: message,
        banner_header: bannerHeader,
        expiry_date: expiryHrs,
        status: status,
        display_order: displayOrder,
        min_screen_width_file: minScreenFile,
        max_screen_width_file: maxScreenFile,
        app_page: appPage,
      };
      CustomConsole(reqObj);
      dispatch(CreateBanners(reqObj));
      setTimeout(() => {
        setBannerDetailsFilterFlag(true);
      }, 1000);
    }
  }, [addNewBannerFlag]);

  React.useEffect(() => {
    if (bannersData.error === false && addNewBannerFlag === true) {
      toast.success(bannersData.createBannersMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAddNewBannerFlag(false);
      setIsAddBannerDetailsDialog(false);
    }
  }, [bannersData]);
  React.useEffect(() => {
    CustomConsole(bookingDetails);
    bookingDetails.setupConfigData.map((data) => {
      CustomConsole(data);
      if (data.key === "consumer_app_pages") {
        setAppPagesmeta(data.value);
      }
    });
  }, [bookingDetails]);

  React.useEffect(() => {
    CustomConsole(fileUploadDetails);
    if (minScreenFlag && fileUploadDetails.isFileUploaded === true) {
      setMinScreenFile(fileUploadDetails.fileName);
      setMinScreenFlag(false);
    }
    if (maxScreenFlag && fileUploadDetails.isFileUploaded === true) {
      setMaxScreenFile(fileUploadDetails.fileName);
      setMaxScreenFlag(false);
    }
  }, [fileUploadDetails]);

  const handleClose = () => {
    setIsAddBannerDetailsDialog(false);
  };

  const handleAddBtn = () => {
    if (
      bannerType !== "" &&
      message !== "" &&
      bannerHeader !== "" &&
      expiryHrs !== 0 &&
      displayOrder !== 0 &&
      appPage !== ""
    ) {
      setAddNewBannerFlag(true);
    } else {
      CustomConsole("enter name to proceed");
      toast.error("Please enter the fields required", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleChange = (event) => {
    setBannerType(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
  };

  const handlingMinScreenSizeFile = () => {
    minScreenFileRef.current.click();
  };

  const minFileChange = (e) => {
    // CustomConsole(e.target.files[0]);
    // dispatch(UploadFile(e.target.files[0]));
    // setMinScreenFlag(true);
    const file = e.target.files[0];
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(fileExtension)) {
        dispatch(UploadFile(file));
        setMinScreenFlag(true);
        // setLoading(true);
      } else {
        toast.dark("Please upload only images", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
        e.target.value = "";
        // setLoading(false);
      }
    }
  };
  const maxFileChange = (e) => {
    // CustomConsole(e.target.files[0]);
    // dispatch(UploadFile(e.target.files[0]));
    // setMaxScreenFlag(true);
    const file = e.target.files[0];
    if (file) {
      // Get the file extension
      const fileExtensionMax = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(fileExtensionMax)) {
        dispatch(UploadFile(file));
        setMaxScreenFlag(true);
        // setLoading(true);
      } else {
        toast.dark("Please upload only image", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
        e.target.value = "";
        // setLoading(false);
      }
    }
  };

  const handlingMaxScreenSizeFile = () => {
    maxScreenFileRef.current.click();
  };

  const fileView = (id) => {
    if (id === "fun-1" && minScreenFile !== "") {
      CustomConsole("Fun-1");
      dispatch(getFiles(minScreenFile));
    }
    if (id === "fun-2" && maxScreenFile !== "") {
      CustomConsole("Fun-2");
      dispatch(getFiles(maxScreenFile));
    }
  };

  const handleChangeDispalayOrder = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setDisplayOrder(() => event.target.value);
  };

  const handleChangeExpiryHours = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setExpiryHrs(() => event.target.value);
  };
  return (
    <div>
      <Dialog
        open={isAddBannerDetailsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ minWidth: 220 }}>
                  <InputLabel id="demo-simple-select-label">
                    Banner type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bannerType}
                    label="Banner type"
                    onChange={handleChange}
                  >
                    {bannerTypeList.map((data) => {
                      return (
                        <MenuItem value={data.banner_type_id}>
                          {data.banner_type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Box>
                  <TextField
                    id="outlined-basic"
                    label="Message"
                    variant="outlined"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Box>
                  <TextField
                    id="outlined-basic"
                    label="Banner header"
                    variant="outlined"
                    value={bannerHeader}
                    onChange={(event) => setBannerHeader(event.target.value)}
                  />
                </Box>

                <Box>
                  <TextField
                    id="outlined-basic"
                    label="Expiry Hours"
                    variant="outlined"
                    value={expiryHrs}
                    // onChange={(event) => setExpiryHrs(event.target.value)}
                    inputProps={{
                      maxLength: 10, // Optional: You can set the maximum length if needed
                      onInput: handleChangeExpiryHours, // Attach the input event handler
                    }}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Box>
                  <TextField
                    id="outlined-basic"
                    label="Display Order"
                    variant="outlined"
                    value={displayOrder}
                    // onChange={(event) => setDisplayOrder(event.target.value)}
                    inputProps={{
                      maxLength: 10, // Optional: You can set the maximum length if needed
                      onInput: handleChangeDispalayOrder, // Attach the input event handler
                    }}
                  />
                </Box>

                <Box>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch />}
                      label="Status"
                      onChange={handleChangeStatus}
                      checked={status}
                    />
                  </FormGroup>
                </Box>
              </Stack>

              <Box>
                <Stack direction="column" spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      type="file"
                      ref={minScreenFileRef}
                      onChange={minFileChange}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#3DB1C4",
                        ":hover": {
                          backgroundColor: "#4094A2",
                        },
                      }}
                      size="small"
                      onClick={handlingMinScreenSizeFile}
                    >
                      Upload image for small screen size
                    </Button>
                    {minScreenFile !== "" ? (
                      <PreviewIcon
                        sx={{
                          marginLeft: "20px",
                          marginTop: "2px",
                        }}
                        onClick={() => {
                          fileView("fun-1");
                        }}
                      />
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      type="file"
                      ref={maxScreenFileRef}
                      onChange={maxFileChange}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#3DB1C4",
                        ":hover": {
                          backgroundColor: "#4094A2",
                        },
                      }}
                      size="small"
                      onClick={handlingMaxScreenSizeFile}
                    >
                      Upload image for max screen size
                    </Button>
                    {maxScreenFile !== "" ? (
                      <PreviewIcon
                        sx={{
                          marginLeft: "20px",
                          marginTop: "2px",
                        }}
                        onClick={() => {
                          fileView("fun-2");
                        }}
                      />
                    ) : null}
                  </Box>
                </Stack>
              </Box>
              <Box>
                <FormControl sx={{ minWidth: 220 }}>
                  <InputLabel>App Pages</InputLabel>
                  <Select
                    value={appPage}
                    label="App Pages"
                    onChange={(event) => setAppPage(event.target.value)}
                  >
                    {appPagesmeta.map((data) => {
                      return <MenuItem value={data.k}>{data.v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box>
              <Button variant="outlined" size="small" onClick={handleAddBtn}>
                Add Banner
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
