import React from "react";
import CustomConsole from "../../CustomConsole";
import * as Material from "@mui/material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Grid,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { RedoTwoTone } from "@mui/icons-material";
import {
  LinkValidCans,
  // ValidateScannedCodes,
} from "../Redux/Actions/wfsBatchLinkingAction";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import moment from "moment";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import WfsOngoingBatchCardDialog from "./WfsOngoingBatchCardDialog";
import {
  GetWfsOngoingBatch,
  PutStartBatchAction,
  PutStopBatchAction,
  PutWfsQrlinkAction,
  WfsEditBatchAction,
} from "../Redux/Actions/wfsOnGoingBatchAction";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import EditBatchDetailsDilaog from "./EditBatchDetailsDilaog";
import { PutWfsEditBatchDetails } from "../Redux/Actions/adminWfsCanOrdersAction";
import {
  EditWfsBatchSku,
  LinkQrLessProductToBatch,
} from "../Redux/Actions/skuWfsDetailsAction";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "azure",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function WfsBatchTableView(props) {
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const dispatch = useDispatch();
  const wfsBatchDetails = useSelector((state) => state.wfsOngoingBatchDetails);
  const [singleSelectedData, setSingleSelectedData] = React.useState("");
  const [valueOrderId, setValueOrderId] = React.useState("");
  const [showingbatchDialogFlag, setShowingbatchDialogFlag] =
    React.useState(false);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [scanQrcodebtn, setScanQrcodebtn] = React.useState(false);
  const [scanQrCodeDetails, setScanQrCodeDetails] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [scanCount, setScanCount] = React.useState(0);
  const [selectedBatch, setSelectedBatch] = React.useState("");
  const [scanOption, setScanOption] = React.useState("scanner");
  const [value, setValue] = React.useState(0);
  const [updateDetails, setUpdateDetails] = React.useState({});
  const [openEditBatchDialog, setOpenEditBatchDialog] = React.useState(false);
  const [batchQuantity, setBatchQuantity] = React.useState(0);
  const validatedData = useSelector((state) => state.wfsBatchBulkLinking);

  // const [invalidData, setInvalidData] = React.useState(() =>
  //   localStorage.getItem("invalid_data") &&
  //   localStorage.getItem("invalid_data").length
  //     ? JSON.parse(localStorage.getItem("invalid_data"))
  //     : []
  // );
  // const [otherValidData, setOtherValidData] = React.useState(() =>
  //   localStorage.getItem("other_valid_data") &&
  //   localStorage.getItem("other_valid_data").length
  //     ? JSON.parse(localStorage.getItem("other_valid_data"))
  //     : []
  // );
  const [validData, setValidData] = React.useState(() =>
    localStorage.getItem("valid_data") &&
    localStorage.getItem("valid_data").length
      ? JSON.parse(localStorage.getItem("valid_data"))
      : []
  );
  const [currentScannedCodes, setCurrentScannedCodes] = React.useState(() =>
    localStorage.getItem("current_scanned_codes") &&
    localStorage.getItem("current_scanned_codes").length
      ? JSON.parse(localStorage.getItem("current_scanned_codes"))
      : []
  );
  const [showScannedPositionValid, setShowScannedPositionValid] =
    React.useState(false);
  const [showScannedPositionInvalid, setShowScannedPositionInvalid] =
    React.useState(false);
  const [validDataToApi, setValidDataToApi] = React.useState();
  CustomConsole(scanQrCodeDetails);

  CustomConsole(scanQrCodeDetails);
  React.useEffect(() => {}, [singleSelectedData]);
  const handleChangeOrderId = (event) => {
    const selectedOrdersData = props.data.find(
      (data) => data.batch_id === event.target.value
    );
    CustomConsole(selectedOrdersData);
    setSingleSelectedData(selectedOrdersData);
    setValueOrderId(event.target.value);
  };
  React.useEffect(() => {
    CustomConsole("validateData");
    CustomConsole(validatedData);
    if (validatedData?.error === false) {
      setValidData(validatedData?.validatedData?.user_valid_data);
      // setInvalidData(validatedData?.validatedData?.invalid_data);
      // setOtherValidData(validatedData?.validatedData?.other_valid_data);
    }
  }, [validatedData]);

  // React.useEffect(() => {
  //   localStorage.setItem("valid_data", JSON.stringify(validData));
  //   localStorage.setItem("invalid_data", JSON.stringify(invalidData));
  //   localStorage.setItem("other_valid_data", JSON.stringify(otherValidData));
  // }, [validData, invalidData, otherValidData]);
  React.useEffect(() => {
    // localStorage.setItem("valid_data", JSON.stringify(validData));
    // localStorage.setItem("invalid_data", JSON.stringify(invalidData));
    // localStorage.setItem("other_valid_data", JSON.stringify(otherValidData));
  }, [validData]);

  React.useEffect(() => {
    CustomConsole("ValidData");
    CustomConsole(validData);
    const transformedArray = validData?.map((item) => ({
      id: item.id,
    }));
    setValidDataToApi(transformedArray);
  }, [validData]);

  React.useEffect(() => {
    CustomConsole("validDataToApi");
    CustomConsole(validDataToApi);
  }, [validDataToApi]);

  const handleCreateBatchCard = (ongoingBatch) => {
    CustomConsole(ongoingBatch);
  };

  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScanQrCodeDetails(decodedText);
      if (value === 1) {
        const newScannedCode = { id: decodedText };
        setCurrentScannedCodes((prev) => [...prev, newScannedCode]);
      }
    }
  };
  React.useEffect(() => {
    dispatch(GetWfsOngoingBatch(pageNumber - 1, wfsDetails?.data?.wfs_id));
  }, [scanQrCodeDetails]);

  CustomConsole();

  React.useEffect(() => {
    // const reqObj = {
    //   batch_id: singleSelectedData?.batch_id,
    //   qr_id: scanQrCodeDetails,
    // };
    // if (scanQrCodeDetails !== "" && value === 0) {
    //   dispatch(PutWfsQrlinkAction(reqObj));
    //   setScanQrcodebtn(false);
    //   setScanQrCodeDetails("");
    //   setShowingbatchDialogFlag(false);
    // }
    const reqObj = {
      is_inscan: true,
      order_id: selectedBatch.batch_id,
      data: [{ id: scanQrCodeDetails }],
    };
    CustomConsole(reqObj);
    dispatch(LinkValidCans(reqObj));
    setTimeout(() => {
      setCurrentScannedCodes([]);
      setValidData([]);
      // setInvalidData([]);
      setValidDataToApi([]);
      localStorage.removeItem("valid_data");
      localStorage.removeItem("invalid_data");
      localStorage.removeItem("current_scanned_codes");
    }, 1000);
  }, [scanQrCodeDetails]);
  //CustomConsole(wfsBatchDetails);

  const handleLinkQr = (batch_id) => {
    CustomConsole(batch_id);
    setSelectedBatch(batch_id);
    setShowingbatchDialogFlag(true);
  };

  const [startBatchBtn, setStartBatchBtn] = React.useState(false);
  const [stopBatchBtn, setStopBatchBtn] = React.useState(false);
  const handleStartBatch = (batch_id) => {
    const reqObj = {
      batch_id: batch_id,
    };
    dispatch(PutStartBatchAction(reqObj));
    setStartBatchBtn(true);
  };

  const handleStopBatch = (batch_id) => {
    const reqObj = {
      batch_id: batch_id,
    };
    dispatch(PutStopBatchAction(reqObj));
    setStopBatchBtn(true);
  };

  // const handleCloseDialogs = () =>{
  //   setShowingbatchDialogFlag(false)
  // }
  React.useEffect(() => {
    CustomConsole("hi iam running");
    if (wfsBatchDetails.errorStartBatch === false && startBatchBtn === true) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      setStartBatchBtn(false);
      wfsBatchDetails.errorStartBatch = true;
    }
    if (wfsBatchDetails.errorStopBatch === false && stopBatchBtn === true) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      setStopBatchBtn(false);
      wfsBatchDetails.errorStartBatch = true;
    }
  }, [wfsBatchDetails.errorStartBatch, wfsBatchDetails.errorStopBatch]);
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");

  React.useEffect(() => {
    CustomConsole("currentScannedCodes");
    CustomConsole(currentScannedCodes);
    if (currentScannedCodes.length) {
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScannedCodes)
      );
    }
  }, [currentScannedCodes]);

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      // setDecodedResults((prev) => [...prev, qrBarcodeId]);
      setScanQrCodeDetails(qrBarcodeId);
      if (value === 1) {
        const newScannedCode = { id: qrBarcodeId };
        setCurrentScannedCodes((prev) => [...prev, newScannedCode]);
      }
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);

  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };
  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };

  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    setValue(newValue);
  };

  const handleVerifyScanned = () => {
    const reqObj = {
      is_inscan: true,
      order_id: singleSelectedData?.batch_id,
      data: currentScannedCodes,
    };
    CustomConsole(reqObj);
    // dispatch(ValidateScannedCodes(reqObj));
  };
  const [selectedPosition, setSelectedPosition] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [deselectedItems, setDeselectedItems] = React.useState([]);

  const handleItemClick = (scannedPosition, item) => {
    setSelectedPosition((prevSelectedPositions) => {
      // Toggle selection for scanned positions
      if (prevSelectedPositions.includes(scannedPosition)) {
        // If already selected, remove from the array
        return prevSelectedPositions.filter(
          (selectedPosition) => selectedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array
        return [...prevSelectedPositions, scannedPosition];
      }
    });

    setSelectedItems((prevSelectedItems) => {
      // Toggle selection for items
      const isSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.scannedPosition === scannedPosition
      );

      if (isSelected) {
        // If already selected, remove from the array (deselect)
        setDeselectedItems((prevDeselectedItems) => {
          // Make sure it's not repeated in the deselected array
          return [
            ...prevDeselectedItems,
            ...(prevDeselectedItems.includes(item) ? [] : [item]),
          ];
        });

        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.scannedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array (select)
        CustomConsole("Getting in else");
        setDeselectedItems((prevDeselectedItems) =>
          prevDeselectedItems.filter(
            (deselectedItem) =>
              deselectedItem.scannedPosition !== scannedPosition
          )
        );
        return [...prevSelectedItems, item];
      }
    });
  };
  React.useEffect(() => {
    CustomConsole("selectedPosition");
    CustomConsole(selectedPosition);
    CustomConsole("selectedItems");
    CustomConsole(selectedItems);
    CustomConsole("deselectedItems");
    CustomConsole(deselectedItems);
    setValidData(deselectedItems);
  }, [selectedPosition]);

  const handleLinkValidCans = () => {
    const reqObj = {
      is_inscan: true,
      order_id: singleSelectedData?.batch_id,
      data: validDataToApi,
    };
    CustomConsole(reqObj);
    dispatch(LinkValidCans(reqObj));
    setTimeout(() => {
      setCurrentScannedCodes([]);
      setValidData([]);
      // setInvalidData([]);
      setValidDataToApi([]);
      localStorage.removeItem("valid_data");
      localStorage.removeItem("invalid_data");
      localStorage.removeItem("current_scanned_codes");
    }, 1000);
  };

  const handleEditBatch = (selectedbatch) => {
    CustomConsole(selectedbatch);
    setUpdateDetails(selectedbatch);
    setOpenEditBatchDialog(true);
    // setIsUpdate(true);
  };
  const handlebatch = (props) => {
    CustomConsole("-----------handlebatch----------------");
    if (
      props.batchId !== "" &&
      props.material !== "" &&
      props.lineId !== "" &&
      props.capacity !== "" &&
      props.batchQuantity !== ""
    ) {
      const reqObj = {
        batch_id: props.batchId,
        quantity: props.batchQuantity,
      };
      CustomConsole(reqObj);
      dispatch(EditWfsBatchSku(reqObj));
      setOpenEditBatchDialog(false);
      setTimeout(() => {
        dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
      }, 1500);
    } else {
      CustomConsole("-----filll---------");
      toast.error("Please Fill All Fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleClose = () => {
    CustomConsole("------------handleClose-------------");
    setOpenEditBatchDialog(false);
  };

  // const onNewScanResultQrCode = (decodedText, decodedResult) => {
  //   CustomConsole(`Qr code scanned ${decodedText}`);
  //   if (decodedResult.result.format.formatName === "QR_CODE") {
  //     setScanQrCodeDetails(decodedText);
  //   }
  // };
  // React.useEffect(() => {
  //   const reqObj = {
  //     batch_id: singleSelectedData?.batch_id,
  //     qr_id: scanQrCodeDetails,
  //   };
  //   if (scanQrCodeDetails !== "") {
  //     dispatch(PutWfsQrlinkAction(reqObj));
  //     setScanQrcodebtn(false);
  //     setScanQrCodeDetails("");
  //     setShowingbatchDialogFlag(false);
  //   }
  // }, [scanQrCodeDetails]);

  // const handleLinkQr = (selectedBatch.batch_id) => {
  //   setSelectedBatch(selectedBatch.batch_id);
  //   setShowingbatchDialogFlag(true);
  // };
  // const [startBatchBtn, setStartBatchBtn] = React.useState(false);
  // const [stopBatchBtn, setStopBatchBtn] = React.useState(false);
  // const handleStartBatch = (selectedBatch.batch_id) => {
  //   const reqObj = {
  //     batch_id: selectedBatch.batch_id,
  //   };
  //   dispatch(PutStartBatchAction(reqObj));
  //   setStartBatchBtn(true);
  // };

  // const handleStopBatch = (selectedBatch.batch_id) => {
  //   const reqObj = {
  //     batch_id: selectedBatch.batch_id,
  //   };
  //   dispatch(PutStopBatchAction(reqObj));
  //   setStopBatchBtn(true);
  // };

  // const handleCloseDialogs = () => {
  //   setShowingbatchDialogFlag(false);
  // };
  // React.useEffect(() => {
  //   CustomConsole(wfsBatchDetails);
  //   if (wfsBatchDetails.errorStartBatch === false && startBatchBtn === true) {
  //     dispatch(GetWfsOngoingBatch(pageNumber - 1));
  //     setStartBatchBtn(false);
  //   }
  //   if (wfsBatchDetails.errorStopBatch === false && stopBatchBtn === true) {
  //     dispatch(GetWfsOngoingBatch(pageNumber - 1));
  //     setStopBatchBtn(false);
  //   }
  // }, [wfsBatchDetails]);
  // // handleQrBarcodeIdFromScanner
  // const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  // const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  // React.useEffect(() => {
  //   if (
  //     qrBarcodeIdNew === qrBarcodeId &&
  //     qrBarcodeId.length &&
  //     qrBarcodeIdNew.length > 6
  //   ) {
  //     CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
  //     CustomConsole(qrBarcodeIdNew);
  //     CustomConsole(qrBarcodeId);
  //     // setDecodedResults((prev) => [...prev, qrBarcodeId]);
  //     setScanQrCodeDetails(qrBarcodeId);
  //     setTimeout(() => {
  //       setQrBarcodeIdNew("");
  //       setQrBarcodeId("");
  //     }, 300);
  //   }
  // }, [qrBarcodeId, qrBarcodeIdNew]);
  // const handleQrBarcodeIdFromScanner = (event) => {
  //   CustomConsole(event.target.value);
  //   setQrBarcodeId(event.target.value);
  //   setTimeout(() => {
  //     if (event.target.value !== qrBarcodeIdNew) {
  //       CustomConsole("-- set data --");
  //       setQrBarcodeIdNew(event.target.value);
  //     } else {
  //       CustomConsole("--- data is set ---");
  //     }
  //   }, 500);
  // };
  // React.useEffect(() => {}, [scanOption]);
  // const handleSelectScanOption = (event) => {
  //   CustomConsole("-- handleSelectScanOption --");
  //   CustomConsole(event.target.value);
  //   setScanOption(event.target.value);
  //   if (event.target.value === "camera") {
  //     // setScanQrCodeDetailBtn(true);
  //     // setScanQrFlag(true);
  //     // setUnMountFlag(true);
  //   } else {
  //     // setScanQrCodeDetailBtn(false);
  //     // setScanQrFlag(false);
  //     // setUnMountFlag(false);
  //   }
  // };

  //function for handling batch qty change
  const handelBatchChange = (e) => {
    let { value } = e.target;
    let numericValue = value.replace(/\D/g, "");
    value = numericValue;
    setBatchQuantity(value);
  };

  //function for handling qr less product submit
  const handelLinkQrLessSubmit = () => {
    const reqObj = {
      batch_id: selectedBatch.batch_id,
      quantity: batchQuantity,
    };
    dispatch(LinkQrLessProductToBatch(reqObj));
  };
  return (
    <Material.Box>
      {props.data.length > 0 ? (
        <>
          {/* <Box sx={{ margin: "10px" }}>
                <Material.TextField
                  label="Search By Order Id"
                  value={searchOrderId}
                  onChange={(event) => setSearchOrderId(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <Material.InputAdornment position="start">
                        <Material.Typography
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          RP
                        </Material.Typography>
                      </Material.InputAdornment>
                    ),
                    endAdornment: (
                      <Material.InputAdornment position="end">
                        <IconName.FcSearch
                          cursor="pointer"
                          size="30px"
                          onClick={handleViewDetails}
                        />
                      </Material.InputAdornment>
                    ),
                    style: { borderRadius: "50px" },
                  }}
                />
              </Box> */}
          <Box sx={{ maxWidth: "100vw", overflow: "auto" }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell
                      align="center"
                      colSpan={16}
                    //   sx={styles.CardHeading}
                      size="medium"
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Replacement Order List
                      </Typography>
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="center" size="small">
                      <strong>Batch Id</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <strong>Quantity</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <strong>Capacity</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <strong>Material</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <strong>Created At</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      {" "}
                      <strong>Status</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      {" "}
                      <strong>Process</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      {" "}
                      <strong>Linked Can Qty</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      {" "}
                      <strong>Edit</strong>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, key) => (
                    <StyledTableRow
                      key={key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "#036bfc",
                        },
                      }}
                      //   onClick={() => {
                      //     getOrderDetails(row.replace_can_req_id);
                      //   }}
                    >
                      <StyledTableCell align="center" size="small">
                        {row.batch_id}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.container_capacity}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.container_material}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row.quantity}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row?.created_at?.slice(0, 10)}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {row?.is_batch_started === true &&
                        row?.is_batch_stopped === true &&
                        row?.linked_container_qty !== 0
                          ? "Completed"
                          : row?.is_batch_started === true &&
                            row?.is_batch_stopped === false
                          ? "Ongoing"
                          : row?.is_batch_started === false &&
                            row?.is_batch_stopped === false
                          ? "Yet to start"
                          : row?.is_batch_started === true &&
                            row?.is_batch_stopped === true &&
                            row?.linked_container_qty === 0
                          ? "Cancelled"
                          : "Started"}
                      </StyledTableCell>

                      <StyledTableCell align="center" size="small">
                        <Material.Box>
                          {row?.linked_container_qty !== row?.quantity &&
                          row?.is_batch_started === false &&
                          row?.is_batch_stopped === false &&
                          row?.quantity !== row?.linked_container_qty ? (
                            <Material.Button
                              sx={{
                                margin: "10px",
                                ":hover": {
                                  backgroundColor: "black",
                                },
                              }}
                              variant="contained"
                              onClick={() => handleLinkQr(row)}
                            >
                              {" "}
                              {row?.product_details?.is_qr
                                ? "Link QR"
                                : "Link Product"}
                            </Material.Button>
                          ) : (
                            ""
                          )}
                        </Material.Box>

                        <Material.Box>
                          {row?.is_batch_started !== true &&
                          row?.linked_container_qty === row?.quantity ? (
                            <Material.Button
                              sx={{
                                margin: "10px",
                                ":hover": {
                                  backgroundColor: "black",
                                },
                              }}
                              variant="contained"
                              size="small"
                              onClick={() => handleStartBatch(row.batch_id)}
                            >
                              Start Batch
                            </Material.Button>
                          ) : (
                            ""
                          )}
                        </Material.Box>
                        <Material.Box>
                          {row?.is_batch_started === true &&
                          row?.is_batch_stopped === false &&
                          row?.linked_container_qty === row?.quantity ? (
                            <Material.Button
                              sx={{
                                margin: "10px",
                                ":hover": {
                                  backgroundColor: "black",
                                },
                              }}
                              onClick={() => handleStopBatch(row.batch_id)}
                              variant="contained"
                              size="small"
                            >
                              Stop Batch
                            </Material.Button>
                          ) : (
                            ""
                          )}
                        </Material.Box>
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {" "}
                        {row?.linked_container_qty}
                      </StyledTableCell>
                      <StyledTableCell align="center" size="small">
                        {" "}
                        {(row?.is_batch_started === true &&
                          row?.is_batch_stopped === false) ||
                          (row?.is_batch_started === false &&
                            row?.is_batch_stopped === false && (
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  ":hover": {
                                    backgroundColor: "black",
                                  },
                                }}
                                onClick={() => handleEditBatch(row)}
                              >
                                Edit
                              </Button>
                            ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <b>No Data available</b>
      )}
      {showingbatchDialogFlag ? (
        <WfsOngoingBatchCardDialog
          showingbatchDialogFlag={showingbatchDialogFlag}
          // handleClose={handleCloseDialogs}
          setShowingbatchDialogFlag={setShowingbatchDialogFlag}
          setScanQrcodebtn={setScanQrcodebtn}
          scanQrcodebtn={scanQrcodebtn}
        />
      ) : (
        ""
      )}
      {openEditBatchDialog && (
        <EditBatchDetailsDilaog
          openEditBatchDialog={openEditBatchDialog}
          updateDetails={updateDetails}
          handlebatch={handlebatch}
          handleClose={handleClose}
          wfsBatchDetails={wfsBatchDetails}
        />
      )}
      {/* {scanQrcodebtn ? (
        <Material.Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            padding: "10px",
          }}
        >
          <Material.FormControl sx={{ marginTop: "15px" }}>
            <Material.FormLabel>Select Scanning Option</Material.FormLabel>
            <Material.RadioGroup
              defaultValue="scanner"
              value={scanOption}
              name="radio-buttons-group"
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={handleSelectScanOption}
            >
              <Material.FormControlLabel
                value="scanner"
                control={<Material.Radio />}
                label="Scanner Device"
              />
              <Material.FormControlLabel
                value="camera"
                control={<Material.Radio />}
                label="Camera"
              />
            </Material.RadioGroup>
          </Material.FormControl>
          {scanOption === "camera" ? (
            <Html5QrcodePlugin1
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResultQrCode}
            />
          ) : (
            <Material.TextField
              onChange={handleQrBarcodeIdFromScanner}
              label="QR/Barcode:"
              value={qrBarcodeId}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </Material.Paper>
      ) : (
        ""
      )} */}
      <Material.Dialog open={scanQrcodebtn}>
        <Material.DialogContent>
          <>
            {selectedBatch?.product_details?.is_qr ? (
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  padding: "10px",
                }}
              >
                <Tabs value={value} onChange={handleChange} centered>
                  <Tab label="Link one by one" />
                  {/* <Tab label="Link Bulk" /> */}
                </Tabs>
                <FormControl sx={{ marginTop: "15px" }}>
                  <FormLabel>Select Scanning Option</FormLabel>
                  <RadioGroup
                    defaultValue="scanner"
                    value={scanOption}
                    name="radio-buttons-group"
                    sx={{ display: "flex", flexDirection: "row" }}
                    onChange={handleSelectScanOption}
                  >
                    <FormControlLabel
                      value="scanner"
                      control={<Radio />}
                      label="Scanner Device"
                    />
                    <FormControlLabel
                      value="camera"
                      control={<Radio />}
                      label="Camera"
                    />
                  </RadioGroup>
                </FormControl>
                {scanOption === "camera" ? (
                  <>
                    <Html5QrcodePlugin1
                      fps={10}
                      qrbox={250}
                      disableFlip={false}
                      qrCodeSuccessCallback={onNewScanResultQrCode}
                    />

                    {/* Card for valid data */}
                    {value === 1 && (
                      <>
                        {" "}
                        {validatedData?.validatedData?.user_valid_data
                          ?.length && currentScannedCodes.length !== 0 ? (
                          <Card
                            sx={{
                              minWidth: 275,
                              backgroundColor: "#32a850",
                              margin: "10px",
                            }}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <Typography>
                                Valid Cans Scanned: {validData?.length}
                              </Typography>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  setShowScannedPositionValid(
                                    !showScannedPositionValid
                                  )
                                }
                                endIcon={
                                  showScannedPositionValid ? (
                                    <KeyboardArrowDownRoundedIcon />
                                  ) : (
                                    <KeyboardArrowUpRoundedIcon />
                                  )
                                }
                              >
                                Show scanned position
                              </Button>
                              {showScannedPositionValid && (
                                <>
                                  <Typography>Scanned Position</Typography>
                                  <Grid container spacing={2}>
                                    {validatedData?.validatedData?.user_valid_data?.map(
                                      (item) => (
                                        <Grid item key={item.scannedPosition}>
                                          <Paper
                                            style={{
                                              width: "50px", // Adjust the width and height as needed
                                              height: "50px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              cursor: "pointer",
                                              background:
                                                selectedPosition.includes(
                                                  item.scannedPosition
                                                )
                                                  ? "red"
                                                  : "white",
                                              opacity:
                                                selectedPosition.includes(
                                                  item.scannedPosition
                                                )
                                                  ? 0.7
                                                  : 1,
                                            }}
                                            elevation={3}
                                            onClick={() =>
                                              handleItemClick(
                                                item.scannedPosition,
                                                item
                                              )
                                            }
                                          >
                                            {item.scannedPosition}
                                          </Paper>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={handleLinkValidCans}
                                  size="small"
                                >
                                  Link valid cans
                                </Button>
                              </Box>
                            </CardContent>
                          </Card>
                        ) : (
                          ""
                        )}
                        {/* {invalidData?.length ? (
                    <Card sx={{ minWidth: 275, backgroundColor: "#a83232" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          Invalid Cans Scanned: {invalidData?.length}
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            setShowScannedPositionInvalid(
                              !showScannedPositionInvalid
                            )
                          }
                          endIcon={
                            showScannedPositionInvalid ? (
                              <KeyboardArrowDownRoundedIcon />
                            ) : (
                              <KeyboardArrowUpRoundedIcon />
                            )
                          }
                        >
                          Show scanned position
                        </Button>
                        {showScannedPositionInvalid && (
                          <>
                            {" "}
                            <Typography>Scanned Position</Typography>
                            <Grid container spacing={2}>
                              {invalidData.map((item) => (
                                <Grid item key={item.scannedPosition}>
                                  <Paper
                                    style={{
                                      width: "50px", // Adjust the width and height as needed
                                      height: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    elevation={3}
                                  >
                                    {item.scannedPosition}
                                  </Paper>
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )} */}
                        {/* {otherValidData?.length ? (
                    <Card sx={{ minWidth: 275, backgroundColor: "#eb9534" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          Other valid Cans Scanned: {invalidData?.length}
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            setShowScannedPositionInvalid(
                              !showScannedPositionInvalid
                            )
                          }
                          endIcon={
                            showScannedPositionInvalid ? (
                              <KeyboardArrowDownRoundedIcon />
                            ) : (
                              <KeyboardArrowUpRoundedIcon />
                            )
                          }
                        >
                          Show scanned position
                        </Button>
                        {showScannedPositionInvalid && (
                          <>
                            {" "}
                            <Typography>Scanned Position</Typography>
                            <Grid container spacing={2}>
                              {otherValidData.map((item) => (
                                <Grid item key={item.scannedPosition}>
                                  <Paper
                                    style={{
                                      width: "50px", // Adjust the width and height as needed
                                      height: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    elevation={3}
                                  >
                                    {item.scannedPosition}
                                  </Paper>
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )} */}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TextField
                      onChange={handleQrBarcodeIdFromScanner}
                      label="QR/Barcode:"
                      value={qrBarcodeId}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {value === 1 && (
                      <>
                        <Typography
                          sx={{ textAlign: "center", padding: "10px" }}
                        >
                          Current Scanned: {currentScannedCodes.length}
                        </Typography>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleVerifyScanned}
                        >
                          Verify Current Scanned
                        </Button>
                        {validatedData?.validatedData?.user_valid_data
                          ?.length && currentScannedCodes.length !== 0 ? (
                          <Card
                            sx={{
                              minWidth: 275,
                              backgroundColor: "#32a850",
                              margin: "10px",
                            }}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <Typography>
                                Valid Cans Scanned: {validData?.length}
                              </Typography>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  setShowScannedPositionValid(
                                    !showScannedPositionValid
                                  )
                                }
                                endIcon={
                                  showScannedPositionValid ? (
                                    <KeyboardArrowDownRoundedIcon />
                                  ) : (
                                    <KeyboardArrowUpRoundedIcon />
                                  )
                                }
                              >
                                Show scanned position
                              </Button>
                              {showScannedPositionValid && (
                                <>
                                  <Typography>Scanned Position</Typography>
                                  <Grid container spacing={2}>
                                    {validatedData?.validatedData?.user_valid_data?.map(
                                      (item) => (
                                        <Grid item key={item.scannedPosition}>
                                          <Paper
                                            style={{
                                              width: "50px", // Adjust the width and height as needed
                                              height: "50px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              cursor: "pointer",
                                              background:
                                                selectedPosition.includes(
                                                  item.scannedPosition
                                                )
                                                  ? "red"
                                                  : "white",
                                              opacity:
                                                selectedPosition.includes(
                                                  item.scannedPosition
                                                )
                                                  ? 0.7
                                                  : 1,
                                            }}
                                            elevation={3}
                                            onClick={() =>
                                              handleItemClick(
                                                item.scannedPosition,
                                                item
                                              )
                                            }
                                          >
                                            {item.scannedPosition}
                                          </Paper>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={handleLinkValidCans}
                                  size="small"
                                >
                                  Link valid cans
                                </Button>
                              </Box>
                            </CardContent>
                          </Card>
                        ) : (
                          ""
                        )}

                        {/* Card for invalid cans  */}

                        {/* {invalidData?.length ? (
                    <Card sx={{ minWidth: 275, backgroundColor: "#a83232" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          Invalid Cans Scanned: {invalidData?.length}
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            setShowScannedPositionInvalid(
                              !showScannedPositionInvalid
                            )
                          }
                          endIcon={
                            showScannedPositionInvalid ? (
                              <KeyboardArrowDownRoundedIcon />
                            ) : (
                              <KeyboardArrowUpRoundedIcon />
                            )
                          }
                        >
                          Show scanned position
                        </Button>
                        {showScannedPositionInvalid && (
                          <>
                            {" "}
                            <Typography>Scanned Position</Typography>
                            <Grid container spacing={2}>
                              {invalidData.map((item) => (
                                <Grid item key={item.scannedPosition}>
                                  <Paper
                                    style={{
                                      width: "50px", // Adjust the width and height as needed
                                      height: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    elevation={3}
                                  >
                                    {item.scannedPosition}
                                  </Paper>
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )} */}

                        {/* Card for other valid cans */}

                        {/* {otherValidData?.length ? (
                    <Card sx={{ minWidth: 275, backgroundColor: "#eb9534" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          Other valid Cans Scanned: {invalidData?.length}
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            setShowScannedPositionInvalid(
                              !showScannedPositionInvalid
                            )
                          }
                          endIcon={
                            showScannedPositionInvalid ? (
                              <KeyboardArrowDownRoundedIcon />
                            ) : (
                              <KeyboardArrowUpRoundedIcon />
                            )
                          }
                        >
                          Show scanned position
                        </Button>
                        {showScannedPositionInvalid && (
                          <>
                            {" "}
                            <Typography>Scanned Position</Typography>
                            <Grid container spacing={2}>
                              {otherValidData.map((item) => (
                                <Grid item key={item.scannedPosition}>
                                  <Paper
                                    style={{
                                      width: "50px", // Adjust the width and height as needed
                                      height: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    elevation={3}
                                  >
                                    {item.scannedPosition}
                                  </Paper>
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )} */}
                      </>
                    )}
                    {/* <Box sx={{ marginTop: "10px" }}>
                <Button
                  onClick={() => {
                    setCurrentScannedCodes([]);
                    setValidData([]);
                    setInvalidData([]);
                    setOtherValidData([]);
                    // setValidDataToApi([]);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Link From Start
                </Button>
              </Box> */}
                  </>
                )}
              </Paper>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  Enter Quantity to link Qrless product to batch
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                    gap: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Quantity"
                    name="quantity"
                    size="small"
                    value={batchQuantity}
                    onChange={handelBatchChange}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handelLinkQrLessSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
          </>
        </Material.DialogContent>
        <Material.DialogActions>
          <Button
            size="small"
            color="error"
            onClick={() => setScanQrcodebtn(false)}
            variant="contained"
          >
            Close
          </Button>
        </Material.DialogActions>
      </Material.Dialog>
      <ResultContainerPlugin1
        results={decodedResults}
        setScanCount={setScanCount}
        scanType={scanOption}
      />
    </Material.Box>
  );
}

export default WfsBatchTableView;
