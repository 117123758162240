import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box, style } from "@mui/system";
import React from "react";
import AcceptDialog from "./AcceptDialog";
import { styles } from "./styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import {
  EditReplacementOrder,
  GetOrderDetailsById,
  GetReplacementOrders,
  RejectingReplacementOrder,
} from "../Redux/Actions/adminReplacementOrderAction";
import { getFiles } from "../Redux/Actions/fileHandlingAction";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  ordersStatus,
  replacementCanOrdersStatus,
} from "../../Components/AppMeta/appMetaConfig";
import { Edit } from "@mui/icons-material";
import EditReplacementOrderDialog from "./EditReplacementOrderDialog";
import { PutEditReturnCanOrder } from "../Redux/Actions/AdminWithdrawalListAction";
import { toast } from "react-toastify";

function AdminReplacementOrderCard({
  orderDetailsCard,
  setRejectToast,
  scrollDownRef,
  setSupplierTable,
  pincode,
  setPincode,
}) {
  const [orderDetails, setOrderDetails] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openRejectDialog, setRejectDialog] = React.useState(false);
  const [reasons, setReasons] = React.useState([]);
  const dispatch = useDispatch();
  const [appMeta, setAppMeta] = React.useState([]);
  const [openEditReplacementCanDialog, setOpenEditReplacementCanDialog] =
    React.useState(false);
  const [reasonSelected, setReasonSelected] = React.useState("");
  const [otherReasons, setOtherReasons] = React.useState("");
  const [filesToDownload, setFilesToDownload] = React.useState([]);

  const adminBookingMeta = useSelector((state) => state.adminBookingData);

  React.useEffect(() => {
    if (orderDetailsCard.length > 0) {
      setOrderDetails(orderDetailsCard[0]);
      setFilesToDownload(orderDetailsCard[0].file_name);
      CustomConsole(orderDetailsCard);
    }
  }, [orderDetailsCard]);

  React.useEffect(() => {
    CustomConsole(adminBookingMeta);
    setAppMeta(adminBookingMeta.setupConfigData);
    if (adminBookingMeta.setupConfigData.length > 0) {
      // settingReasons();
      adminBookingMeta.setupConfigData.map((data) => {
        // CustomConsole(value);
        if (data.key === "admin_replacement_reject_reasons") {
          CustomConsole(data.value);
          setReasons(data.value);
        }
      });
    }
  }, [adminBookingMeta]);
  React.useEffect(() => {}, [orderDetails]);

  const openDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRejectDialog(false);
  };

  const openRejectDialogue = () => {
    setRejectDialog(true);
  };

  // const settingReasons = () => {
  //   CustomConsole("------------getting here------------");
  //   CustomConsole(appMeta);
  //   if (appMeta.length > 0) {
  //     let reasonsToReturn = appMeta.filter((val) => {
  //       return val.key === "admin_replacement_reject_reasons";
  //     });
  //     CustomConsole(reasonsToReturn[0].value);
  //     setReasons(reasonsToReturn[0].value);
  //     CustomConsole(reasonsToReturn);
  //   }
  // };

  React.useEffect(() => {
    CustomConsole("---------------------------------------");
    CustomConsole(reasons);
  }, [reasons]);
  const rejectOrder = () => {
    if (reasonSelected !== 999 && reasonSelected !== "") {
      const reqObj = {
        order_id: orderDetails.order_id,
        reject_reason: `${reasonSelected}`,
      };
      CustomConsole(reqObj);
      dispatch(RejectingReplacementOrder(reqObj));
      setRejectToast(true);
      setRejectDialog(false);
    }

    if (reasonSelected === 999 && otherReasons !== "") {
      const reqObj = {
        order_id: orderDetails.order_id,
        reject_reason: `${otherReasons}`,
      };
      CustomConsole(reqObj);
      dispatch(RejectingReplacementOrder(reqObj));
      setRejectToast(true);
      setRejectDialog(false);
    }
  };

  const downloadingFiles = () => {
    if (filesToDownload.length > 0) {
      filesToDownload.forEach((value) => {
        dispatch(getFiles(value));
      });
    }
    CustomConsole(filesToDownload);
  };
  const orderStatus = (order) => {
    switch (order) {
      case replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED:
        return "NEW REQUEST";
      case replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED:
        return "ACCEPTED";
      case replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_REJECTED:
        return "REJECTED ";
      case replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED:
        return "STARTED";
      case replacementCanOrdersStatus.CAN_REPLACE_ORDER_FINISHED:
        return "DELIVERED";
      default:
        return "";
    }
  };
  const openDialogReAssign = () => {
    setOpen(true);
  };
  const handleCloseReplacementDialog = () => {
    setOpenEditReplacementCanDialog(false);
  };
  const handleSubmitReplacementDialog = (props) => {
    const reqObj = {
      product_qty: props.qty,
    };
    CustomConsole(reqObj);
    if (reqObj.product_qty > 0) {
      dispatch(EditReplacementOrder(orderDetails.order_id, reqObj));
      setOpenEditReplacementCanDialog(false);
      setTimeout(() => {
        dispatch(GetOrderDetailsById(orderDetails.order_id));
      }, 1000);
    } else {
      toast.dark("Edit minimum quantity 1", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };

  const handleOpenEditReplaceDialog = () => {
    setOpenEditReplacementCanDialog(true);
  };
  return (
    <>
      <Card ref={scrollDownRef}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                md={6}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={styles.cardStyleHeading}>
                    Order ID:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.order_id}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    OTP:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.otp}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    Capacity:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.container_capacity}
                    </span>
                  </Typography>

                  <Typography sx={styles.cardStyleHeading}>
                    Replacement Can Request:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.replace_can_qty}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    Cans Accepted:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.accepted_can_qty}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    cans Exchanged:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.exchanged_can_qty}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    Supplier Assigned:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.supplier_details?.supplier_name}
                    </span>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    overflowWrap: "break-word",
                  }}
                >
                  <Typography sx={styles.cardStyleHeading}>
                    Reasons:
                    <span style={styles.cardStyleValue}>
                      {}
                      {orderDetails.replacement_reason === "100"
                        ? "Can is damaged"
                        : orderDetails.replacement_reason === "200"
                        ? "Sleeve already opened"
                        : orderDetails.replacement_reason === "300"
                        ? "Impure water delivered"
                        : orderDetails.replacement_reason === "400"
                        ? "Water smells bad"
                        : orderDetails.replacement_reason === "500"
                        ? "Regected"
                        : "Regected"}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    Updated Date:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.updated_at}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    Material:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.container_material}
                    </span>
                  </Typography>
                  <Typography sx={styles.cardStyleHeading}>
                    Status:
                    <span style={styles.cardStyleValue}>
                      {orderStatus(orderDetails.order_status)}
                    </span>
                  </Typography>
                  {orderDetails.file_name ? (
                    <Typography sx={styles.cardStyleHeading}>
                      Evidence Files:&nbsp;
                      {orderDetails.file_name.length > 0 ? (
                        <span
                          onClick={downloadingFiles}
                          style={{
                            color: "#44ADF1",
                            fontWeight: "300",
                            cursor: "pointer",
                          }}
                        >
                          Download All
                        </span>
                      ) : (
                        <span>No Files to Download</span>
                      )}
                    </Typography>
                  ) : null}
                  <Typography sx={styles.cardStyleHeading}>
                    Replacement For:
                    <span style={styles.cardStyleValue}>
                      {orderDetails.name}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {orderDetails.order_status === 100 ||
          orderDetails.order_status === 200 ||
          orderDetails.order_status === 400 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Box>
                <Button
                  variant="contained"
                  onClick={handleOpenEditReplaceDialog}
                >
                  <Edit />
                  Edit
                </Button>
              </Box>
              <Box>
                {orderDetails.order_status === 100 ||
                orderDetails.order_status === 200 ? (
                  <Button variant="contained" onClick={openDialog}>
                    {orderDetails.order_status === 100
                      ? "Accept"
                      : orderDetails.order_status === 200
                      ? "Re-Assign"
                      : ""}
                  </Button>
                ) : (
                  ""
                )}
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#F01111",
                    "&:hover": {
                      backgroundColor: "#BE2828",
                    },
                  }}
                  onClick={openRejectDialogue}
                >
                  Reject
                </Button>
              </Box>
            </Box>
          ) : (
            ""
          )}
        </CardContent>
      </Card>

      <Dialog
        open={openRejectDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select the reason for rejection
          </DialogContentText>
          <Box sx={{ minWidth: 120, marginTop: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Reason</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reasonSelected}
                label="Reason"
                onChange={(e) => {
                  setReasonSelected(e.target.value);
                }}
              >
                {reasons
                  ? reasons.map((value, key) => {
                      return (
                        <MenuItem key={key} value={value.k}>
                          {value.v}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
            {reasonSelected === 999 ? (
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <TextField
                  fullWidth
                  type="text"
                  label="Enter the reason"
                  onChange={(e) => {
                    setOtherReasons(e.target.value);
                  }}
                />
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button autoFocus variant="contained" onClick={rejectOrder}>
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <AcceptDialog
        setPincode={setPincode}
        pincode={pincode}
        setOpen={setOpen}
        open={open}
        orderDetails={orderDetails}
        setSupplierTable={setSupplierTable}
      />
      <EditReplacementOrderDialog
        openEditReplacementCanDialog={openEditReplacementCanDialog}
        handleSubmitReplacementDialog={handleSubmitReplacementDialog}
        handleCloseReplacementDialog={handleCloseReplacementDialog}
        containerQty={orderDetails.replace_can_qty}
      />
    </>
  );
}

export default AdminReplacementOrderCard;
