/**
 * @author vinay kumar
 * @description Transfer Can Orders
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.52
 * @Date 03-10-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSupplierTansferCanOrder,
  PutSupplierTransferCansVerifyOtp,
} from "../Redux/Actions/supliersAction";
import { toast } from "react-toastify";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import DistributorTransferCanOrderCansCard from "../../Components/Cards/DistributorTransferCanOrderCansCard";
import SupplierOrdersEnterOtpDialog from "../../Components/DialoBoxes/SupplierOrdersEnterOtpDialog";
import TransferCansInScanDialog from "../../Components/DialogBoxes/SupplierDialogBox/TransferCansInScanDialog";
import TransferCansCamareaDialog from "../../Components/DialogBoxes/SupplierDialogBox/TransferCansCamareaDialog";
import TransferCanSFormView from "./TransferCanSFormView";
import TransferCanOrderTableView from "./TransferCanOrderTableView";
import { transferCansStatus } from "../../Components/AppMeta/appMetaConfig";

function TransFerCanOrders() {
  const [openInScanReasonDialog, setOpenInScanReasonDialog] =
    React.useState(false);
  const [apiData, setApiData] = React.useState({});
  const [submitOtpBtn, setSubmitOtpBtn] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [enterOtpOpenDialog, setEnterOtpOpenDialog] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const [openCamera, setOpenCamera] = React.useState(false);
  const [transferCanOrdersView, setTransferCanOrdersView] =
    React.useState("form");
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageStatuss, setPageStatuss] = React.useState(false);

  const [openReturnCanCameraDialog, setOpenReturnCanCameraDialog] =
    React.useState(false);

  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(supplierDeliveries);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      GetSupplierTansferCanOrder({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  }, []);
  React.useEffect(() => {
    if (
      supplierDeliveries.errorTransferCansVerifyOtp === false &&
      submitOtpBtn === true
    ) {
      dispatch(
        GetSupplierTansferCanOrder({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setSubmitOtpBtn(false);
    }
  }, [supplierDeliveries]);

  React.useEffect(() => {
    dispatch(
      GetSupplierTansferCanOrder({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  }, [pageSize]);

  React.useEffect(() => {
    if (transferCanOrdersView === "form") {
      dispatch(
        GetSupplierTansferCanOrder({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }
    if (transferCanOrdersView === "table") {
      dispatch(
        GetSupplierTansferCanOrder({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }
    if (transferCanOrdersView === "card") {
      dispatch(
        GetSupplierTansferCanOrder({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }
  }, [pageNumber]);

  React.useEffect(() => {
    if (
      supplierDeliveries !== undefined &&
      supplierDeliveries.getSupplierTransferCanOrders !== undefined &&
      supplierDeliveries.getSupplierTransferCanOrders.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  }, [pageNumber]);

  const transferCansStatusShow = (order_status) => {
    CustomConsole(order_status);
    switch (order_status) {
      case transferCansStatus.TRANSFER_CAN_ORDER_CREATED:
        return "ORDER_CREATED";
      case transferCansStatus.TRANSFER_CAN_ORDER_CONFIRMED:
        return "ORDER_CONFIRMED";
      case transferCansStatus.TRANSFER_CAN_ORDER_INSCAN_COMPLETED:
        return "ORDER_INSCAN_COMPLETED";
      case transferCansStatus.TRANSFER_CAN_ORDER_REJECTED:
        return "ORDER_REJECTED";
      default:
        return "";
    }
  };

  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenReturnCanCameraDialog(false);
  };

  const handleClickOrder = (props) => {
    CustomConsole(props);
    CustomConsole(props.ordeer_id);
    setOrderId(props.ordeer_id);
    if (
      props.is_submit_started === false &&
      props.is_submit_finished === false
    ) {
      setEnterOtpOpenDialog(true);
    } else {
      setOpenReturnCanCameraDialog(true);
    }
  };
  const handleCloseDialog = () => {
    CustomConsole("---- handleCloseDialog -----");
    setEnterOtpOpenDialog(false);
  };
  const handleSubmitOtp = (props) => {
    CustomConsole("---- handleSubmitOtp -----");
    CustomConsole(props);
    setEnterOtpOpenDialog(false);
    const reqObj = {
      order_id: orderId,
      otp: enterOtpTextfied,
    };
    CustomConsole(reqObj);
    if (otpValidationError === false) {
      dispatch(PutSupplierTransferCansVerifyOtp(reqObj));
      setEnterOtpTextfied("");
      setSubmitOtpBtn(true);
      // setTimeout(() => {
      //   dispatch(
      //     GetSupplierTansferCanOrder({
      //       page_number: pageNumber,
      //       page_size: pageSize,
      //     })
      //   );
      // }, 1000);
    } else {
      toast.error("Enter proper OTP", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleInScan = (props) => {
    CustomConsole(props);
    setApiData(props);
    setOpenInScanReasonDialog(false);
    setOpenReturnCanCameraDialog(true);
  };
  const handleClose = (props) => {
    CustomConsole("---------------handleClose---------------");
    setOpenInScanReasonDialog(false);
    props.setIsFilledCan("");
  };

  const handleOrdersView = (event) => {
    setTransferCanOrdersView(event.target.value);
    if (transferCanOrdersView === "form") {
      dispatch(
        GetSupplierTansferCanOrder({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setPageNumber(0);
      setPageSize(25);
    } else if (transferCanOrdersView === "table") {
      dispatch(
        GetSupplierTansferCanOrder({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setPageNumber(0);
      setPageSize(25);
    } else if (transferCanOrdersView === "card") {
      dispatch(
        GetSupplierTansferCanOrder({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setPageNumber(0);
      setPageSize(25);
    }
  };
  const handleClickForward = () => {
    CustomConsole("---handleClickForward -----");
    CustomConsole("--pageNumber---:" + pageNumber);
    CustomConsole("--supplierDeliveries.length---:" + supplierDeliveries.length);
    CustomConsole(JSON.stringify(supplierDeliveries));
    if (pageNumber + 1 > 0) {
      setPageNumber(() => pageNumber + 1);
    }
    // setPageStatuss(false);
  };

  const handleClickBack = () => {
    if (pageNumber > 0) {
      setPageBackWard(true);
      setPageForward(false);
      setPageNumber(() => pageNumber - 1);
      // setPageNumber(pageNumber - 1);
    }
  };

  return (
    <Material.Box>
      {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "flex-end", xs: "center" },
          flexWrap: "wrap",
        }}
      >
        {/* <Material.Box
            sx={{
              margin: "10px",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              alignItems: "center",
            }}
          > */}

        <Material.FormControl>
          <Material.InputLabel>Ui View</Material.InputLabel>
          <Material.Select
            variant="standard"
            sx={{ fontSize: "14px", fontWeight: "bold", width: "80px" }}
            value={transferCanOrdersView}
            label="Ui View"
            onChange={handleOrdersView}
          >
            <Material.MenuItem value="form">Form</Material.MenuItem>
            <Material.MenuItem value="table">Table</Material.MenuItem>
            <Material.MenuItem value="card">Card</Material.MenuItem>
          </Material.Select>
        </Material.FormControl>
        {/* </Material.Box> */}

        {/* </Material.Box> */}

        <Material.Box>
          <Material.FormControl sx={{ minWidth: 120 }} size="small">
            <Material.InputLabel id="demo-select-small-label">
              Page size
            </Material.InputLabel>
            <Material.Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              variant="standard"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            >
              <Material.MenuItem value={25}>25</Material.MenuItem>
              <Material.MenuItem value={50}>50</Material.MenuItem>
              <Material.MenuItem value={100}>100</Material.MenuItem>
              <Material.MenuItem value={500}>500</Material.MenuItem>
              {/* <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem> */}
            </Material.Select>
          </Material.FormControl>
        </Material.Box>

        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // margin: "10px",
          }}
        >
          {/* Click on Backward Arrow Button */}
          <Material.Button onClick={handleClickBack}>
            <ArrowBackIos />
          </Material.Button>
          <Material.Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "20px",
              height: "30px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber + 1}
          </Material.Typography>
          {/* Click on Forward Arrow Button */}
          <Material.Button disabled={pageStatuss} onClick={handleClickForward}>
            <ArrowForwardIos />
          </Material.Button>
        </Material.Box>
      </Box>
      {/* </Box> */}
      {supplierDeliveries.getSupplierTransferCanOrders !== undefined &&
      supplierDeliveries.getSupplierTransferCanOrders !== null &&
      supplierDeliveries.getSupplierTransferCanOrders.length > 0 ? (
        <>
          {transferCanOrdersView === "form" ? (
            <TransferCanSFormView
              data={supplierDeliveries.getSupplierTransferCanOrders}
              handleClickOrder={handleClickOrder}
              transferCansStatusShow={transferCansStatusShow}
            />
          ) : transferCanOrdersView === "table" ? (
            <TransferCanOrderTableView
              data={supplierDeliveries?.getSupplierTransferCanOrders}
              handleReturnCanEnterOTP={handleClickOrder}
              transferCansStatusShow={transferCansStatusShow}
            />
          ) : (
            <Material.Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              {" "}
              {supplierDeliveries.getSupplierTransferCanOrders !== undefined &&
              supplierDeliveries.getSupplierTransferCanOrders !== null &&
              supplierDeliveries.getSupplierTransferCanOrders.length > 0 ? (
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    overflow: "auto",
                    height: "500px",
                  }}
                >
                  {supplierDeliveries.getSupplierTransferCanOrders.map(
                    (data, key) => {
                      return (
                        <DistributorTransferCanOrderCansCard
                          SingleData={data}
                          handleClickOrder={handleClickOrder}
                          transferCansStatusShow={transferCansStatusShow}
                          errorr={supplierDeliveries.errorTransferCansVerifyOtp}
                          verifyMsg={
                            supplierDeliveries.msgTransferCansVerifyOtp
                          }
                        />
                      );
                    }
                  )}
                </Material.Box>
              ) : (
                <Material.Card elevation={24} sx={{ marginTop: "20px" }}>
                  <Material.Typography sx={{ fontWeight: "bold" }}>
                    No more Transfer Orders Can!
                  </Material.Typography>
                </Material.Card>
              )}
            </Material.Box>
          )}
        </>
      ) : (
        <Material.Box sx={{ marginTop: "20px" }}>
          <Material.Typography color="error">
            No more Transfer Orders Can!
          </Material.Typography>
        </Material.Box>
      )}
      <Material.Box
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        {/* {supplierDeliveries.getSupplierTransferCanOrders !== undefined &&
        supplierDeliveries.getSupplierTransferCanOrders !== null &&
        supplierDeliveries.getSupplierTransferCanOrders.length > 0 ? (
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              overflow: "auto",
              height: "500px",
            }}
          >
            {supplierDeliveries.getSupplierTransferCanOrders.map(
              (data, key) => {
            return (
              <DistributorTransferCanOrderCansCard
                SingleData={data}
                handleClickOrder={handleClickOrder}
                errorr={supplierDeliveries.errorTransferCansVerifyOtp}
                verifyMsg={supplierDeliveries.msgTransferCansVerifyOtp}
              />
            );
          }
            )}
        </Material.Box>
      ) : (
        <Material.Card elevation={24} sx={{ marginTop: "20px" }}>
          <Material.Typography sx={{ fontWeight: "bold" }}>
            No more Transfer Orders Can!
          </Material.Typography>
        </Material.Card>
      )} */}
        {enterOtpOpenDialog ? (
          <SupplierOrdersEnterOtpDialog
            isDialogOpen={enterOtpOpenDialog}
            handleClose={handleCloseDialog}
            handleSubmitOtp={handleSubmitOtp}
            otpValidationError={otpValidationError}
            setOtpValidationError={setOtpValidationError}
            enterOtpTextfied={enterOtpTextfied}
            setEnterOtpTextfied={setEnterOtpTextfied}
          />
        ) : null}
        {/* <TransferCansInScanDialog
        openInScanReasonDialog={openInScanReasonDialog}
        handleInScan={handleInScan}
        handleClose={handleClose}
      /> */}
        <TransferCansCamareaDialog
          openCamera={openCamera}
          openReturnCanCameraDialog={openReturnCanCameraDialog}
          returnCanOrderId={orderId}
          setOpenCamera={setOpenCamera}
          setOpenReturnCanCameraDialog={setOpenReturnCanCameraDialog}
          apiData={apiData}
          handleClose={handleCloseCameraDialog}
        />
      </Material.Box>
    </Material.Box>
  );
}

export default TransFerCanOrders;
