/**
 * @author vinay kumar
 * @description Edit Address Dialog
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CustomConsole from "../../CustomConsole";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select, Typography } from "@mui/material";
import CanIcon from "../../Images/CanIcon.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { GetUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import { SearchAreasByPincode } from "../Redux/Actions/adminBookingAction";
import * as IconName from "react-icons/fc";
import * as Material from "@mui/material";
import DiscountRoundedIcon from "@mui/icons-material/DiscountRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { BatteryFull } from "@mui/icons-material";

export default function EditAddressDialog({
  isEditAddressDialogOpen,
  setIsEditAddressDialogOpen,
  address,
  handleEditAddressSave,
  handleDeleteAddress,
  userId,
  // discount,
  // setDiscount,
  // customPrice,
  // setCustomPrice,
}) {
  const handleClose = () => {
    setIsEditAddressDialogOpen(false);
  };
  const userUpdates = useSelector((state) => state.userDetailsUpdate);
  const bookingDetails = useSelector((state) => state.adminBookingData);

  const delivery = [
    {
      value: "Delivery Time (6 AM- 6 PM)",
    },
    {
      value: "24 hours",
    },
  ];

  const [name, setName] = React.useState(address.contact_name);
  const [mobileNum, setMobileNum] = React.useState(address.contact_phone);
  const [addressLine1, setAddressLine1] = React.useState(address.address_line1);
  const [addressLine2, setAddressLine2] = React.useState(address.address_line2);
  const [addressLine3, setAddressLine3] = React.useState(address.address_line3);
  const [landmark, setLandmark] = React.useState(address.landmark);
  const [pincode, setPincode] = React.useState(address.pincode);
  const [cansCapacity, setCansCapacity] = React.useState(
    address.details?.stock_capacity?.cans
      ? address.details?.stock_capacity?.cans
      : 0
  );
  const [bottleCapacity, setVBottlesCapacity] = React.useState(
    address.details?.stock_capacity?.bottles
      ? address.details?.stock_capacity?.bottles
      : 0
  );
  const [cratesCapacity, setCratesCapacity] = React.useState(
    address.details?.stock_capacity?.crates
      ? address.details?.stock_capacity?.crates
      : 0
  );
  const [emailError, setEmailError] = React.useState(false);
  const [addressemail, setaddressemail] = React.useState(address.email);
  const [deliveryTime, setDeliveryTime] = React.useState(
    address.delivery_timing_option
  );
  const [isPrimaryAddress, setIsPrimaryAddress] = React.useState(
    address.is_primary
  );
  const [discount, setDiscount] = React.useState(address.discount);
  const [customPrice, setCustomPrice] = React.useState(address.custom_price);
  const [saveBtn, setSaveBtn] = React.useState(false);

  const [availableAreasFlag, setAvailableAreasFlag] = React.useState(false);
  const [areasAvailable, setAvailableArea] = React.useState(null);
  const [selectArea, setSelectArea] = React.useState(false);
  const [areaValue, setAreaValue] = React.useState(address.service_area);
  const [isBilling, setIsBilling] = React.useState(address.is_billing_address);

  const dispatch = useDispatch();

  React.useEffect(() => {
    CustomConsole(bookingDetails);
    if (
      availableAreasFlag &&
      !bookingDetails.error &&
      bookingDetails.availableAreas.length > 0
    ) {
      const area = bookingDetails.availableAreas[0].Name;
      CustomConsole(area);
      setAreaValue(area);
      setAvailableArea(bookingDetails.availableAreas);
      setAvailableAreasFlag(false);
      setSelectArea(true);
    } else {
      setAvailableAreasFlag(false);
      setSelectArea(false);
      setAvailableArea("");
    }
  }, [bookingDetails]);

  React.useEffect(() => {
    if (userUpdates.editUserAddressMsg !== "" && saveBtn === true) {
      // toast.success(userUpdates.editUserAddressMsg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setSaveBtn(false);
      dispatch(GetUserAddressAdmin(userId));
      setIsEditAddressDialogOpen(false);
    }
  }, [userUpdates]);
  // save  Address Hitting Api Here
  const handleSaveAddress = () => {
    CustomConsole("---- handleSaveAddress ----");
    CustomConsole("save address");
    setSaveBtn(true);
    if (
      !bookingDetails?.error &&
      addressLine1?.trim() !== "" &&
      addressLine2?.trim() !== "" &&
      name?.trim() !== "" &&
      mobileNum?.trim() !== "" &&
      landmark?.trim() !== "" &&
      pincode?.trim() !== "" &&
      areaValue?.trim() !== "" &&
      !emailError
    ) {
      CustomConsole("--- Conditions satisfied ----");
      const data = {
        address_line1: addressLine1,
        address_line2: addressLine2,
        email: addressemail,
        address_line3: addressLine3,
        contact_name: name,
        contact_phone: mobileNum,
        details: {
          stock_capacity: {
            cans: parseInt(cansCapacity),
            bottles: parseInt(bottleCapacity),
            crates: parseInt(cratesCapacity),
          },
        },
        // delivery_timing_option: deliveryTime,
        is_primary: isPrimaryAddress,
        landmark: landmark,
        pincode: pincode,
        status: true,
        service_area: areaValue,
        discount: discount,
        // custom_price: customPrice,
        is_billing_address: isBilling,
      };
      CustomConsole(data);
      handleEditAddressSave(data, address.address_id, address.user_id);
    } else {
      toast.error("Please Fill all Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const findingAreas = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length === 6) {
      dispatch(SearchAreasByPincode(e.target.value));
      setAvailableAreasFlag(true);
    } else {
      setAvailableArea("");
      setAreaValue("");
    }
  };

  const areaValueChange = (e) => {
    CustomConsole("---- areaValueChange ----");
    if (!bookingDetails.error) {
      setAreaValue(e.target.value);
    } else {
      setAreaValue("");
    }
  };
  const handleAddressEmailChange = (event) => {
    setaddressemail(event.target.value);
    validateEmail(event.target.value);
  };

  const validateEmail = (value) => {
    // Basic email validation
    if (value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleBlur = () => {
    validateEmail(addressemail);
  };
  return (
    <div>
      <Dialog
        open={isEditAddressDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* Edi Address Here */}
        <DialogContent>
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                Edit Your Address
              </Typography>
              <TextField
                label="Full Name*"
                size="small"
                value={name}
                onChange={(event) => setName(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <IconName.FcManager size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Mobile Number*"
                size="small"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                value={mobileNum}
                onChange={(event) => setMobileNum(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <IconName.FcPhoneAndroid size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                value={addressemail}
                onChange={handleAddressEmailChange}
                onBlur={handleBlur}
                error={emailError}
                helperText={
                  emailError ? "Please enter a valid email address" : ""
                }
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <EmailOutlinedIcon size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Address line 1*"
                size="small"
                value={addressLine1}
                onChange={(event) => setAddressLine1(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <IconName.FcHome size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Address line 2"
                required
                size="small"
                value={addressLine2}
                onChange={(event) => setAddressLine2(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <IconName.FcHome size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Address line 3"
                size="small"
                value={addressLine3}
                onChange={(event) => setAddressLine3(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <IconName.FcHome size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Landmark*"
                size="small"
                value={landmark}
                onChange={(event) => setLandmark(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <IconName.FcHome size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Pincode*"
                size="small"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                }}
                value={pincode}
                onChange={findingAreas}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <IconName.FcHome size="30px" />
                    </Material.InputAdornment>
                  ),
                }}
              />
              {areasAvailable ? (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select area*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={areaValue}
                    onChange={areaValueChange}
                    label="Select area"
                  >
                    {areasAvailable.map((area, key) => {
                      return (
                        <MenuItem key={key + 1} value={area.Name}>
                          {area.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
              {parseInt(localStorage.getItem("roleId")) !==
                ADMIN_ROLES.BW_OPS &&
              parseInt(localStorage.getItem("roleId")) !==
                ADMIN_ROLES.BW_OPSHEAD ? (
                <>
                  <TextField
                    label="Discount percentages"
                    required
                    size="small"
                    value={discount}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setDiscount(event.target.value);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <Material.InputAdornment position="start">
                          <DiscountRoundedIcon size="30px" color="success" />
                        </Material.InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Cans Capacity"
                    size="small"
                    value={cansCapacity}
                    type="number"
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 &&
                        event.target.value <= 9999
                      ) {
                        setCansCapacity(event.target.value);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <Material.InputAdornment
                          position="start"
                          sx={{ mt: "5px" }}
                        >
                          <img src={CanIcon} alt="" sizes="20px" />
                        </Material.InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Bottles Capacity"
                    size="small"
                    value={bottleCapacity}
                    type="number"
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 &&
                        event.target.value <= 9999
                      ) {
                        setVBottlesCapacity(event.target.value);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <Material.InputAdornment
                          position="start"
                          sx={{ mt: "5px" }}
                        >
                          <BatteryFull size="30px" />
                        </Material.InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={{ width: "240px" }}
                    label="Crates Capacity"
                    size="small"
                    value={cratesCapacity}
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 &&
                        event.target.value <= 9999
                      ) {
                        setCratesCapacity(event.target.value);
                      }
                    }}
                  />
                  {/* <TextField
                    label="Custom price"
                    required
                    size="small"
                    value={customPrice}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setCustomPrice(event.target.value);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <Material.InputAdornment position="start">
                          <TuneRoundedIcon size="30px" color="success" />
                        </Material.InputAdornment>
                      ),
                    }}
                  /> */}
                </>
              ) : null}

              {/* <TextField
                id="outlined-select-currency"
                select
                label="Select"
                helperText="Please select your delivery time"
                value={deliveryTime}
                size="small"
                onChange={(event) => setDeliveryTime(event.target.value)}
              >
                {delivery.map((option, key) => (
                  <MenuItem key={key} value={key}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField> */}
              <FormControlLabel
                control={<Checkbox checked={isPrimaryAddress} />}
                label="Mark as primary address"
                onChange={(event) => setIsPrimaryAddress(event.target.checked)}
              />
              <FormControlLabel
                control={<Checkbox checked={isBilling} />}
                label="Mark as billing address"
                onChange={(event) => setIsBilling(event.target.checked)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleSaveAddress}
              >
                Save
              </Button>
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}
