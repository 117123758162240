import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { styles } from "./postpaidCardStyles";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  GetWalletDetails,
  PostpaidOrder,
  RejectPostpaidOrder,
  SelectSuppiersPostpaid,
  UpdatePostpaidPrice,
  AssignSupplier,
  GetPostpaidOrders,
} from "../Redux/Actions/adminPostpaidOrdersAction";
import { ToastContainer, toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/system";
import { getFiles } from "../Redux/Actions/fileHandlingAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";
export default function PostpaidDetailsCard({
  orderDetails,
  postpaidPaymentMethods,
  scrollRef,
  notConfirmOdr,
  setCardVisible,
  setAcceptSuccessMsg,
  orderby,
  status,
  emailPhone,
  pageNumber,
  adminRoles,
}) {
  const dispatch = useDispatch();
  const postpaidDetails = useSelector((state) => state.postpaidOrderAdmin);
  const setUpConfigData = useSelector((state) => state.adminBookingData);
  const assingningSupplierResponse = useSelector(
    (state) => state.postpaidOrderAdmin.orderDetails
  );
  const [supplierFlag, setSupplierFlag] = React.useState(false);
  const fileHandler = useSelector((state) => state.fileHandler);
  const [rejectOrderFlag, setRejectOrderFlag] = React.useState(false);
  const [rejectBtn, setRejectBtn] = React.useState(false);
  const [updateBtn, setUpdateBtn] = React.useState(false);
  const [selectSupplierFlag, setSelectSupplierFlag] = React.useState(false);
  const [selectSupplierBtn, setSelectSupplierBtn] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const [trackId, setTrackId] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [userId, setUserId] = React.useState("");
  const [walletBalance, setWalletBalance] = React.useState(null);
  const [dialogueOpen, setDialogueOpen] = React.useState(false);
  const [acceptBookingFlag, setAcceptBookingFlag] = React.useState(false);

  CustomConsole(adminRoles);

  React.useEffect(() => {
    CustomConsole(Object.keys(orderDetails).length);
    if (Object.keys(orderDetails).length !== 0) {
      setOrderId(orderDetails.order_id);
      setTrackId(orderDetails.trackid);
      setUserId(orderDetails.user_id);
    }
  }, [orderDetails]);
  React.useEffect(() => {
    if (userId !== "") {
      dispatch(GetWalletDetails(userId));
    }
  }, [userId]);
  React.useEffect(() => {
    CustomConsole(postpaidDetails);
    if (Object.keys(postpaidDetails.walletDetails).length > 0) {
      setWalletBalance(postpaidDetails.walletDetails.amount);
    }
    if (rejectBtn === true && postpaidDetails.error === false) {
      toast.success(postpaidDetails.rejectPostpaidMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setRejectBtn(false);
      dispatch(GetPostpaidOrders(emailPhone, orderby, status, pageNumber - 1));
      dispatch(PostpaidOrder(orderDetails.order_id));
    }
    if (rejectBtn === true && postpaidDetails.error === true) {
      CustomConsole("error");
      toast.error(postpaidDetails.rejectPostpaidMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setRejectBtn(false);
    }

    if (selectSupplierBtn === true && postpaidDetails.error === false) {
      toast.success("Successfully got suppliers", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSelectSupplierBtn(false);
    }
    if (postpaidDetails.error === false && updateBtn === true) {
      toast.success(postpaidDetails.priceUpdateMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateBtn(false);
      dispatch(PostpaidOrder(orderDetails.order_id));
      dispatch(GetWalletDetails(userId));
      // setPrice("");
      setPaymentMethod("");
    }
  }, [postpaidDetails]);

  React.useEffect(() => {
    if (rejectOrderFlag === true) {
      const req = {
        order_id: orderId,
        status: "REJECTED",
        status_id: "2",
      };
      dispatch(RejectPostpaidOrder(req));
      setRejectOrderFlag(false);
    }
  }, [rejectOrderFlag]);

  React.useEffect(() => {
    if (selectSupplierFlag === true) {
      dispatch(SelectSuppiersPostpaid(orderId));
      setSelectSupplierFlag(false);
    }
  }, [selectSupplierFlag]);

  const handleRejectPostpaid = () => {
    setRejectOrderFlag(true);
    setRejectBtn(true);
  };

  const handleSelectSupplier = () => {
    setSelectSupplierFlag(true);
    setSupplierFlag(true);
  };

  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [supplier, setSupplier] = React.useState("");

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleChangeSupplier = (event) => {
    setSupplier(event.target.value);
  };
  const handleUpdatePrice = () => {
    if (
      paymentMethod !== "" &&
      price > 0 &&
      parseInt(orderDetails.pending_amount) >= parseInt(price)
    ) {
      const reqObj = {
        amount: price,
        calc: 1,
        order_id: orderId,
        payment_method: paymentMethod,
        trackid: trackId,
      };
      dispatch(UpdatePostpaidPrice(reqObj));
      setPrice(null);
      setUpdateBtn(true);
    } else {
      toast.error("Please select Proper Payment Method and Price to update", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handlePriceChange = (event) => {
    if (event.target.value >= 0) {
      setPrice(event.target.value);
    } else {
      toast.error("Enter Proper Price", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  React.useEffect(() => {
    CustomConsole(fileHandler);
  }, [fileHandler]);

  CustomConsole(orderDetails);
  const downloadPO = () => {
    CustomConsole("download PO");
    dispatch(getFiles(orderDetails.filename));
  };

  const dialogueFun = () => {
    setDialogueOpen(true);
  };

  const handleCloseDialogue = () => {
    setDialogueOpen(false);
    CustomConsole(orderId);
  };

  const assignSupplier = () => {
    if (notConfirmOdr[0]) {
      CustomConsole(notConfirmOdr[0]);
      const reqObj = {
        deposit_details: {
          container_capacity:
            notConfirmOdr[0].packaged_drinking_water
              .selected_container_capacity,
          container_material:
            notConfirmOdr[0].packaged_drinking_water
              .selected_container_material,
          container_quantity: 0,
          deposit_amount: 0,
          user_id: notConfirmOdr[0].delivery_address.user_id,
        },
        order_id: orderId,
        packaged_drinking_water: {
          empty_container_qty:
            notConfirmOdr[0].packaged_drinking_water.empty_container_qty,
          fivehndml_capacity_amt: 0,
          fivehndml_capacity_qty: 0,
          fivehndml_capacity_totamt: 0,
          fiveltr_capacity_amt: 0,
          fiveltr_capacity_qty: 0,
          fiveltr_capacity_totamt: 0,
          is_inscan_completed: false,
          is_order_started: false,
          is_outscan_completed: false,
          is_verify_otp: false,
          new_container_qty:
            notConfirmOdr[0].packaged_drinking_water.new_container_qty,
          oneltr_capacity_amt: 0,
          oneltr_capacity_qty: 0,
          oneltr_capacity_totamt: 0,
          received_empty_cans:
            notConfirmOdr[0].packaged_drinking_water.received_empty_cans,
          selected_container_capacity:
            notConfirmOdr[0].packaged_drinking_water
              .selected_container_capacity,
          selected_container_material:
            notConfirmOdr[0].packaged_drinking_water
              .selected_container_material,
          submitted_filled_cans:
            notConfirmOdr[0].packaged_drinking_water.submitted_filled_cans,
          twenty_ltr_capacity_amt:
            notConfirmOdr[0].packaged_drinking_water.twenty_ltr_capacity_amt,
          twenty_ltr_capacity_totamt:
            notConfirmOdr[0].packaged_drinking_water.twenty_ltr_capacity_totamt,
          twentyltr_capacity_qty:
            notConfirmOdr[0].packaged_drinking_water.twentyltr_capacity_qty,
          twohndml_capacity_amt: 0,
          twohndml_capacity_qty: 0,
          twohndml_capacity_totamt: 0,
          twoltr_capacity_amt: 0,
          twoltr_capacity_qty: 0,
          twoltr_capacity_totamt: 0,
        },
        payment_method: "POSTPAID",
        supplier_id: supplier,
      };
      CustomConsole(reqObj);
      dispatch(AssignSupplier(reqObj));
      setAcceptBookingFlag(true);
    }

    setDialogueOpen(false);
    setSupplierFlag(false);
  };

  React.useEffect(() => {
    if (
      assingningSupplierResponse.error === false &&
      acceptBookingFlag === true
    ) {
      CustomConsole(assingningSupplierResponse);
      const req = {
        order_id: assingningSupplierResponse.order_id,
        status: "ACCEPTED",
        status_id: "1",
      };
      dispatch(RejectPostpaidOrder(req));
      setAcceptBookingFlag(false);

      dispatch(GetPostpaidOrders(emailPhone, orderby, status, pageNumber - 1));
      setAcceptSuccessMsg(true);
      setCardVisible(false);
    }
  }, [assingningSupplierResponse]);

  return (
    <>
      <Paper ref={scrollRef} elevation={24}>
        <Card>
          <CardContent>
            <Box>
              {/* //from here */}
              <Box>
                <Container>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="start"
                    sx={{
                      textAlign: "start",
                    }}
                    spacing={2}
                  >
                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Order ID:
                          <span style={styles.stockDataStyleValue}>
                            {orderDetails.order_id}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Order price:
                          <span style={styles.stockDataStyleValue}>
                            {orderDetails.final_order_price}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Postpaid %:
                          <span style={styles.stockDataStyleValue}>
                            {orderDetails.postpaid_perc}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Postpaid charges:
                          <span style={styles.stockDataStyleValue}>
                            {orderDetails.postpaid_charges}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Final order price:
                          <span style={styles.stockDataStyleValue}>
                            {orderDetails.final_order_price}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Requested Date:
                          <span style={styles.stockDataStyleValue}>
                            {Object.keys(orderDetails).length !== 0
                              ? moment(orderDetails.created_at)
                                  .tz("Asia/Kolkata")
                                  .format("DD-MM-YYYY")
                              : ""}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Updated Date:
                          <span style={styles.stockDataStyleValue}>
                            {Object.keys(orderDetails).length !== 0
                              ? moment(orderDetails.updated_at)
                                  .tz("Asia/Kolkata")
                                  .format("DD-MM-YYYY")
                              : ""}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box sx={styles.stockDataStyleHeading}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Status:
                          <span style={styles.stockDataStyleValue}>
                            {orderDetails.status}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item md={4}>
                        <Box sx={styles.stockDataStyleHeading}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {Object.keys(orderDetails).length !== 0 ? (
                              <>
                                <Button
                                  variant="contained"
                                  onClick={downloadPO}
                                  size="small"
                                  sx={appColors.commonBtnColor}
                                >
                                  PO&nbsp;&nbsp;&nbsp;
                                  <DownloadForOfflineRoundedIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                              </>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              ></Box>
            </Box>
          </CardContent>
          {orderDetails.status === "ACCEPTED" ? (
            <>
              {" "}
              {adminRoles === ADMIN_ROLES.BW_SUPERADMIN ||
              adminRoles === ADMIN_ROLES.BW_ADMIN ||
              adminRoles === ADMIN_ROLES.BW_CS ||
              adminRoles === ADMIN_ROLES.BW_CSHEAD ||
              adminRoles === ADMIN_ROLES.BW_FINANCE ||
              adminRoles === ADMIN_ROLES.BW_FINANCEHEAD ? (
                <>
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={styles.updateStyle}>Update price</span>
                    </Typography>
                  </Box>
                  <CardContent>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box sx={styles.stockDataStyleHeading}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Transaction ID:
                            <span style={styles.stockDataStyleValue}>
                              {orderDetails.trackid}
                            </span>
                          </Typography>
                        </Box>
                        <Box sx={styles.stockDataStyleHeading}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Total price:
                            <span style={styles.stockDataStyleValue}>
                              {orderDetails.final_order_price}
                            </span>
                          </Typography>
                        </Box>
                        <Box sx={styles.stockDataStyleHeading}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Paid:
                            <span style={styles.stockDataStyleValue}>
                              {orderDetails.paid_amount}
                            </span>
                          </Typography>
                        </Box>
                        {orderDetails.pending_amount > 0 ? (
                          <Box sx={styles.stockDataStyleHeading}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Pending price:
                              <span style={styles.stockDataStyleValue}>
                                {orderDetails.pending_amount}
                              </span>
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box sx={styles.stockDataStyleHeading}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Wallet balance:
                            <span style={styles.stockDataStyleValue}>
                              {walletBalance}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {orderDetails.pending_amount > 0 ? (
                      <Box
                        sx={{
                          padding: "10px",
                          // display: "flex",
                          // alignItems: "center",
                          // justifyContent: "center",
                          // gap: "20px",
                        }}
                      >
                        <Box sx={{ padding: "10px" }}>
                          <TextField
                            id="outlined-number"
                            label="Price"
                            value={price}
                            type="number"
                            onChange={handlePriceChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                        <Box sx={{ padding: "10px" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Payment method
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={paymentMethod}
                              label="Payment method"
                              onChange={handleChange}
                            >
                              {postpaidPaymentMethods
                                ? postpaidPaymentMethods.map((data, key) => {
                                    return (
                                      <MenuItem key={key + 1} value={data.v}>
                                        {data.v}
                                      </MenuItem>
                                    );
                                  })
                                : null}
                            </Select>
                          </FormControl>
                          <Box sx={{ padding: "10px" }}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={handleUpdatePrice}
                            >
                              Update
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}
                  </CardContent>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Card>
      </Paper>

      {orderDetails.status === "PENDING" ? (
        <>
          {adminRoles === ADMIN_ROLES.BW_ADMIN ||
          adminRoles === ADMIN_ROLES.BW_SUPERADMIN ||
          adminRoles === ADMIN_ROLES.BW_CS ||
          adminRoles === ADMIN_ROLES.BW_CSHEAD ||
          adminRoles === ADMIN_ROLES.BW_CSLEAD ||
          adminRoles === ADMIN_ROLES.BW_FINANCEHEAD ? (
            <Box
              sx={{
                marginTop: "10px",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleRejectPostpaid}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleSelectSupplier}
              >
                Select supplier
              </Button>
            </Box>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {postpaidDetails.suppliers.length > 0 &&
      orderDetails.status === "PENDING" &&
      supplierFlag === true ? (
        <>
          {" "}
          <Box sx={{ padding: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select suppliers
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={supplier}
                label="Select suppliers"
                onChange={handleChangeSupplier}
              >
                {postpaidDetails.suppliers.map((data, key) => {
                  return (
                    <MenuItem
                      key={data.id + key}
                      onClick={dialogueFun}
                      value={data.id}
                    >
                      {data.supplier_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </>
      ) : null}
      <ToastContainer />
      <Dialog
        open={dialogueOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to implement this action?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogue}>Back</Button>
          <Button onClick={assignSupplier}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
