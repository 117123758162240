/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 25-01-2024
 */

import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import CustomConsole from "../../CustomConsole";
import {
  GetOngoingOrdersWfsInAdmin,
  StartSubmissinOngoingWfsAdmin,
} from "../Redux/Actions/adminWfsCanOrdersAction";
import { useDispatch, useSelector } from "react-redux";
import { Orderstyles } from "./cardStyles";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
export default function WfsOngoingOrders({
  data,
  setPgVisible,
  selectedOrderType,
}) {
  CustomConsole(data);

  const dispatch = useDispatch();

  //function for starting order
  const handleStartSubmission = (data) => {
    CustomConsole(data);
    setPgVisible(false);
    const reqobj = {
      order_id: data,
    };
    dispatch(StartSubmissinOngoingWfsAdmin(reqobj));
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
    }, 1000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        justifyContent: { sm: "space-between", xs: "space-around" },
        maxHeight: "550px",
        overflow: "auto",
      }}
    >
      {data.map((data, index) => (
        <Box sx={Orderstyles.orderCardBox} key={index}>
          <Card sx={Orderstyles.orderCard}>
            <Box sx={Orderstyles.orderCardHearder}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box sx={Orderstyles.BoxInsideContent}>
                  <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                    Order Id: {data.ec_order_id}
                  </Typography>
                  <Typography
                    sx={{ color: "white", fontSize: ".9rem", zIndex: 5 }}
                  >
                    <span></span>
                    {data.created_at}
                  </Typography>
                </Box>
              </Box>
              <Box sx={Orderstyles.labelDesign}>
                <Typography sx={Orderstyles.labelText}>
                  {data.ec_order_status == "100"
                    ? "Confirmed "
                    : data.ec_order_status == "200"
                    ? "Completed"
                    : data.ec_order_status == "501"
                    ? "Admin Accepted"
                    : "Admin Rejected"}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
              </Box>
            </Box>
            <CardContent
              //   onClick={() => handelDetailClick()}
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  marginBottom: ".5rem",
                  borderBottom: "2px solid gray",
                  borderBottomStyle: "dashed",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Order Otp:{" "}
                    <span style={{ color: "#068fff" }}>{data.otp}</span>
                  </Typography>
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Order status :{" "}
                    <span style={{ color: "#068fff" }}>
                      {data.order_status}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: ".5rem",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  borderBottomStyle: "dashed",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Empty Submitting:{" "}
                    <span style={{ color: "#068fff" }}>
                      {data.order_details.return_qty}
                    </span>
                  </Typography>
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    New Cans:{" "}
                    <span style={{ color: "#068fff" }}>
                      {data.order_details.new_qty}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Damaged Cans:{" "}
                    <span style={{ color: "#068fff" }}>
                      {data.order_details.damaged_qty}
                    </span>
                  </Typography>
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Received Cans:{" "}
                    <span style={{ color: "#068fff" }}>
                      {data.order_details.submitted_return_cans}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Expired Cans:
                    <span style={{ color: "#068fff" }}>
                      {data.order_details.expired_qty}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: ".5rem",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  borderBottomStyle: "dashed",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Capacity:
                    <span style={{ color: "#068fff" }}>
                      {data.order_details.container_capacity}
                    </span>
                  </Typography>
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Material :
                    <span style={{ color: "#068fff" }}>
                      {data.order_details.container_material}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={Orderstyles.TypograpyCommon1}>
                    Order Placed By:
                    <span style={{ color: "#068fff" }}>
                      {data.is_admin === true ? "Admin" : "Wfs"}
                    </span>
                  </Typography>
                </Box>
              </Box>
              {data?.order_details?.crates?.length > 0 && (
                <>
                  {data?.order_details?.crates?.map((data, index) => (
                    <Box
                      sx={{
                        marginBottom: ".5rem",
                        justifyContent: "space-between",
                        borderBottom: "2px solid gray",
                        borderBottomStyle: "dashed",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography sx={Orderstyles.TypograpyCommon1}>
                          Crate Capacity:
                          <span style={{ color: "#068fff" }}>
                            {data.container_capacity}
                          </span>
                        </Typography>
                        <Typography sx={Orderstyles.TypograpyCommon1}>
                          Crate Material :
                          <span style={{ color: "#068fff" }}>
                            {data.container_material}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={Orderstyles.TypograpyCommon1}>
                          Order Qty:
                          <span style={{ color: "#068fff" }}>
                            {data.order_qty}
                          </span>
                        </Typography>
                        <Typography sx={Orderstyles.TypograpyCommon1}>
                          Return Qty:
                          <span style={{ color: "#068fff" }}>
                            {data.return_qty}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              {(data.order_details.is_submit_started === false &&
                data.ec_order_status == "100") ||
              (data.order_details.is_submit_started === false &&
                data.ec_order_status == "501") ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleStartSubmission(data.ec_order_id)}
                    >
                      Start Submission
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Typography
                  sx={{
                    textAlign: "center",
                    borderRadius: "10px",
                    padding: "3px",
                    color: "white",
                    background: "rgb(67,135,245)",
                  }}
                >
                  {data?.ec_order_status === 502 ? "Rejected" : "Order Started"}
                </Typography>
              )}
            </CardActions>
          </Card>
        </Box>
      ))}
    </Box>
  );
}
