import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import * as Material from "@mui/material";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { UsersStyles } from "./UsersStyles";

export default function UserStockDetailsTable({ data }) {
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate);
  const [distStock, setDistStock] = React.useState({});

  React.useEffect(() => {
    if (
      userAddressAdmin.distStock &&
      Object.keys(userAddressAdmin.distStock).length
    ) {
      setDistStock(userAddressAdmin.distStock);
    } else {
      setDistStock({});
    }
  }, [userAddressAdmin]);
  return (
    <>
      <Paper sx={UsersStyles.cardshadow}>
        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "20px",
          }}
        >
          <Material.Grid container spacing={2}>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Container Material
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.container_material}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Container Capacity
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.container_capacity}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Deposit Amount
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.current_deposit_amount}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Deposit Containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.total_deposited_containers_qty}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Undeposited Containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.total_undeposited_containers_qty}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Filled containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.filled_container_qty}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Containers left
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.new_containers_to_provide}
            </Material.Grid>
            <Material.Grid item md={3} sm={12} xs={6}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Empty Containers
              </Typography>
            </Material.Grid>
            <Material.Grid
              item
              md={3}
              sm={12}
              xs={6}
              sx={{ textAlign: "left" }}
            >
              {data.empty_container_qty}
            </Material.Grid>
          </Material.Grid>
        </Material.Box>
      </Paper>
    </>
  );
}
