import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import { styles } from "./lostCansCardStyles";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";

const label = { inputProps: { "aria-label": "Switch demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundImage:
      "radial-gradient(rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
    fontWeight: "600",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function LostCansCard({
  data,
  setPhone,
  setNoOfCans,
  setSelected,
  selected,
  setDisableCans,
  disableCans,
  index,
  checkedItems,
  setCheckedItems,
  // checked,
  // setChecked
}) {
  // const [checked, setChecked] = React.useState(false);

  const lostCansDetails = useSelector((state) => state.adminLostCansData);
  const handleChange = (event, index, data) => {
    CustomConsole(index);
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = event.target.checked;
    setCheckedItems(newCheckedItems);

    if (event.target.checked === true) {
      setSelected([
        ...selected,
        { no_of_cans: data.no_of_cans, phone: data.phone },
      ]);

      setDisableCans([...disableCans, data.phone]);
    } else if (event.target.checked === false) {
      CustomConsole(event.target.checked);
      CustomConsole(selected);

      selected.map((selectedData, index) => {
        if (selectedData.phone === data.phone) {
          CustomConsole(index);
          selected.splice(index, 1);
        }
      });

      disableCans.map((val, idx) => {
        if (val === data.phone) {
          disableCans.splice(idx, 1);
        }
      });
    }
    if (event.target.checked === true) {
      setPhone(data.phone);
      setNoOfCans(data.no_of_cans);
    }
  };

  React.useEffect(() => {
    CustomConsole(selected);
  }, [selected]);
  return (
    <Card
      sx={{
        backgroundColor: checkedItems[index] && "#dfedf7",
        // width: { lg: "100%", md: "100%", sm: 500, xs: 320 },
        maxWidth: { xs: 340, lg: 1, md: 1, sm: 1 },
      }}
    >
      <CardContent>
        {/* <Box sx={{ marginLeft: "300px" }}>
          {" "}
          <Checkbox
            checked={checkedItems[index] || false}
            onChange={(event) => handleChange(event, index)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography sx={styles.LostCansStyleHeading}>
                Number of Cans
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.LostCansStyleHeading}>
                User name
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.LostCansStyleHeading}>
                User phone
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.LostCansStyleHeading}>
                User email
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box>
              {" "}
              <Typography sx={styles.LostCansStyleValue}>
                {data.no_of_cans}
              </Typography>
            </Box>
            <Box>
              {" "}
              <Typography sx={styles.LostCansStyleValue}>
                {data.fullname}
              </Typography>
            </Box>
            <Box>
              {" "}
              <Typography sx={styles.LostCansStyleValue}>
                {data.phone}
              </Typography>
            </Box>
            <Box>
              {" "}
              <Typography sx={styles.LostCansStyleValue}>
                {data.email}
              </Typography>
            </Box>
          </Box>
        </Box> */}

        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Can Qty</StyledTableCell>
                <StyledTableCell>Select</StyledTableCell>
              </TableRow>
            </TableHead>
            <Tooltip title="Select to edit details" placement="left-start">
              <TableBody>
                {lostCansDetails.lostCansList.map((data, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{data.fullname}</StyledTableCell>
                    <StyledTableCell>{data.phone}</StyledTableCell>
                    <StyledTableCell>{data.email}</StyledTableCell>
                    <StyledTableCell>{data.no_of_cans}</StyledTableCell>
                    <StyledTableCell>
                      <Checkbox
                        checked={checkedItems[index] || false}
                        onChange={(event) => handleChange(event, index, data)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}{" "}
              </TableBody>
            </Tooltip>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
