/**
 * @author Gowtham Prasath
 * @description New Container Details page
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 14-12-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import Rectangle384 from "../../Images/Rectangle 384.png";
import paperCrate from "../../Images/paperCrate.webp";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import FilterListIcon from "@mui/icons-material/FilterList";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import { getFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch } from "react-redux";
import CrateFilterDialog from "../DialogBoxes/SkuFilterDialog/crateFilterDialog";
function NewCrateDetailsCard(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [openCrateFilter, setOpenCrateFilter] = React.useState(false);

  //function for handling pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //function for handling rows opreation
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //function for handling collapse functionality
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };

  //function for handling crate edit dialog
  const handleClickEditCrate = (data) => {
    props.handleClickEdit(data, "crate", "");
  };

  //function for handling filter option
  const handelFilterOption = (data) => {
    props.setCrateFilterDetails({
      activeStatus: data.active,
      capacity: data.sortByCapacity,
      sort_by_name: data.sortByName,
      sort_by_price: data.sortByPrice,
      sort_by_created_at: data.sortByCreatedAt,
    });
  };

  //function for closing filter dialog
  const handleCloseFilter = () => {
    setOpenCrateFilter(false);
  };

  //function for converting date formate
  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    return momentEndDate;
  };
  return (
    <Material.Box sx={{ marginTop: "1rem" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Material.Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <Material.Button
            onClick={() => setOpenCrateFilter(true)}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Material.Button>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={props.crateDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Material.Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={appColors.commonLayoutColor}>
                <TableCell />
                <TableCell size="small">
                  <strong>S.no</strong>
                </TableCell>
                <TableCell size="small">
                  <strong>Name</strong>
                </TableCell>
                <TableCell size="small">
                  <strong>Capacity</strong>
                </TableCell>
                <TableCell size="small">
                  <strong>Price</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Image</strong>
                </TableCell>
                <TableCell size="small">
                  <strong>Edit</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.crateDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((data, index) => (
                  <>
                    <TableRow
                      sx={{ "& > *": { borderBottom: "unset" } }}
                      key={index}
                    >
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handelIcon(index)}
                        >
                          {openFlag ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell size="small">{index + 1}</TableCell>
                      <TableCell size="small">{data.name}</TableCell>
                      <TableCell size="small">{data.capacity}</TableCell>
                      <TableCell size="small">{data.price}</TableCell>
                      <TableCell size="small" align="center">
                        {data.image !== "" ? (
                          <DownloadIcon
                            onClick={() => {
                              dispatch(getFiles(data.image));
                            }}
                          />
                        ) : (
                          <FileDownloadOffIcon />
                        )}
                      </TableCell>
                      <TableCell size="small" align="center">
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Material.Button
                            sx={{ height: "25px", width: "50px" }}
                            variant="contained"
                            onClick={() => handleClickEditCrate(data)}
                            size="small"
                          >
                            Edit
                          </Material.Button>
                        </Material.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        {open === index && openFlag === true ? (
                          <Material.Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow sx={appColors.commonLayoutColor}>
                                  <TableCell size="small">
                                    <strong>HSN</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>CGST%</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>SGST%</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>IGST%</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>created_at</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={data.hsn_code}>
                                  <TableCell size="small">
                                    {data.hsn_code}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.cgst_perc}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.sgst_perc}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.igst_perc}
                                  </TableCell>
                                  <TableCell size="small">
                                    {endDateConverter(data.created_at)}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Material.Box>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CrateFilterDialog
        isOpen={openCrateFilter}
        onClose={handleCloseFilter}
        handelFilterOption={handelFilterOption}
      />
      {/* <Material.Paper
        sx={{
          minWidth: { xs: "290px" },
          marginTop: "30px",
          borderRadius: "10px",
        }}
        elevation={20}
      >
        <Material.Grid container spacing={2} sx={{ padding: "1rem" }}>
          <Material.Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            md={2}
            xs={12}
          >
            {" "}
            <img
              src={Rectangle384}
              alt="icon"
              style={{
                height: "80px",
                width: "80px",
                marginTop: "1px",
              }}
            />
          </Material.Grid>
          <Material.Grid md={5} xs={12} sx={{ paddingLeft: { xs: "20px" } }}>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Crate Id :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.id}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Crate Name :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.name}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Sale Price :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.price}
              </Material.Typography>
            </Material.Box>

            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Capacity :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.capacity}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Material :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                GLASS Bottle
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Cgst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.cgst_perc}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Igst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.igst_perc}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Sgst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.sgst_perc}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Hsn Code :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.hsn_code}
              </Material.Typography>
            </Material.Box>
          </Material.Grid>
          <Material.Grid md={5} xs={12} sx={{ paddingLeft: { xs: "20px" } }}>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Created by :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                TbUjhM7nr
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                updated by :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.created_by}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Cgst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                100 %
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Igst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                90 %
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Sgst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                100 %
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Created At :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                22-12-2023
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Updated At :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                22-12-2023
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Description
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.description}
              </Material.Typography>
            </Material.Box>
          </Material.Grid>
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Material.Box>
              <Material.Button
                sx={{ margin: "2px" }}
                variant="contained"
                onClick={()=>handleClickEditCrate(props.data)}
                size="small"
              >
                Edit
              </Material.Button>
            </Material.Box>
            <Material.Box>
              <Material.Button
                color="error"
                sx={{ margin: "2px" }}
                variant="contained"
                size="small"
                // onClick={handleOpenProductCrate}
              >
                Remove Crate
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.Grid>
      </Material.Paper> */}
    </Material.Box>
  );
}

export default NewCrateDetailsCard;
