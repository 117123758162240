/**
 * @author vinay kumar
 * @description Subscription user Detail Card
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay

import * as React from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomConsole from "../../CustomConsole";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  AddMoneyToWallet,
  AddWaterDrops,
  GetProfileDetails,
  GetSubscriptionOrderDetails,
  GetUserAddressAdmin,
  GetWalletDetails,
  PostReduceWaterWallet,
  UpdateGstDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  BooknowOrderDetails,
  GetDepositDetails,
  GetSetUpConfigAction,
  GetUserDepositDetails,
  SearchAreasByPincode,
} from "../Redux/Actions/adminBookingAction";
import PdwOrderDialog from "./PdwOrderDialog";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { UploadPoFile } from "../Redux/Actions/fileHandlingAction";
import { TroubleshootOutlined } from "@mui/icons-material";
import SubscriptionDetailsDialog from "./SubcriptionDetailsDialog";
import PostpaidPoUpload from "./PostpaidPoUploadDialog";
import { useHistory } from "react-router-dom";
import ReplacementDialogue from "./ReplacementDialogue";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { UsersStyles } from "./UsersStyles";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import * as Material from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import { PayThroughWallet } from "../Redux/Actions/adminOrderPayAction";
import { GetUserWaterDrops } from "../Redux/Actions/transactionsActions";
import { GetBillingAddress } from "../Redux/Actions/userDetailsAdminAction";
import moment from "moment";

const Input = styled("input")({
  display: "none",
});
export default function SubscriptionDetailsCard({
  adminUsers,
  setOpenPdwOrderDialog,
  openPdwOrderDialog,
  setBookOrderBtn,
  bookOrderBtn,
  setCheckAvailability,
  checkAvailability,
  walletBalance,
  userRoleId,
  adminRoles,
  userProfileDetails,
  userId,
  postPaidFlag,
  setPostpaidFlag,
}) {
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const orderDetails = useSelector((state) => state.adminBookingData);
  const theme = createTheme();
  const dispatch = useDispatch();
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate); //billingAddress[0]
  const userUpdates = useSelector((state) => state.userDetailsUpdate);
  const bookingDetails = useSelector((state) => state.adminBookingData);
  const fileHandler = useSelector((state) => state.fileHandler);
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);

  const [profileData, setProfileData] = React.useState({});
  const [billname, setBillname] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [updateGstDetailsBtn, setUpdateGstDetailsBtn] = React.useState(false);
  const [gstFieldError, setGstFieldError] = React.useState(false);
  const [subscriptionFlag, setSubscriptionFlag] = React.useState(false);
  // const [userId, setUserId] = React.useState("");
  const [subscriptionBtn, setSubscriptionBtn] = React.useState(false);
  const [area, setArea] = React.useState("");
  const [showPincodeToEnter, setShowPincodeToEnter] = React.useState(false);
  const [showSelectArea, setShowSelectArea] = React.useState(false);
  const [pincode, setPincode] = React.useState("");
  const [phone, setPhone] = React.useState("");
  // const [openPdwOrderDialog, setOpenPdwOrderDialog] = React.useState(false);
  const [pdwData, setPwdData] = React.useState({});
  const [canPrice, setCanPrice] = React.useState(0);
  const [min, setMin] = React.useState("");
  const [max, setMax] = React.useState("");
  const [postPaidPercentage, setPostPaidPercentage] = React.useState(0);
  const [postpaidFinalPrice, setPostpaidFinalPrice] = React.useState(0);
  const [value, setValue] = React.useState([]);
  const [postpaidPercentageValue, setPostpaidPercentageValue] = React.useState(
    []
  );
  const [postpaidCharges, setPostpaidCharges] = React.useState(0);
  const [isSubscriptionOrder, setIsSubscriptionOrder] = React.useState(false);
  const [subscriptionExists, setSubscriptionExists] = React.useState(false);
  const [currentAddress, setCurrentAddress] = React.useState({});
  const [addressId, setAddressId] = React.useState(currentAddress.address_id);

  const [consumerName, setConsumerName] = React.useState("");
  const [consumerArea, setConsumerArea] = React.useState("");
  const [consumerId, setConsumerId] = React.useState("");
  const [accordionExpand, setAccordianExpand] = React.useState(false);

  //State for Replacement request.
  const [requestDialogOpen, setRequestDialogOpen] = React.useState(false);
  const [userStockData, setUserStockData] = React.useState([]);
  const [containerCapacity, setContainerCapacity] = React.useState([]);
  const [containerMaterial, setContainerMaterial] = React.useState([]);
  const [replacementReasons, setReplacementReasons] = React.useState("");
  const [toastFlag, setToastFlag] = React.useState(false);

  const [poFile, setPoFile] = React.useState("");
  const [poFileUrl, setPoFileUrl] = React.useState("");
  const [amountToAdd, setAmountToAdd] = React.useState(null);
  const [amountToReduceWaterWallet, setAmountToReduceWaterWallet] =
    React.useState(null);

  const [addMoneyBtn, setAddMoneyBtn] = React.useState(false);
  const [addWalletBtn, setAddWalletBtn] = React.useState(false);
  const [orderBtn, setOrderBtn] = React.useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] =
    React.useState(false);
  const [subscriptionData, setSubscriptionData] = React.useState({});
  const [depositData, setDepositData] = React.useState([]);
  const [stockData, setStockData] = React.useState([]);
  const [postpaidInitiated, setPostpaidInitiated] = React.useState(false);
  const [walletTrigger, setWalletTrigger] = React.useState(false);
  const [orderPrice, setOrderPrice] = React.useState("");
  const [paymentType, setPaymentType] = React.useState("");
  const [lowWalletBalance, setLowWalletBalance] = React.useState(false);
  const [is_b2b, setIs_b2b] = React.useState(false);
  const [is_b2c, setIs_b2c] = React.useState(false);
  const [waterDropsToAdd, setWaterDropsToAdd] = React.useState(null);
  const [reasonForWaterDrop, setReasonForWaterDrop] = React.useState("");
  const [reasonForReduceWaterWallet, setReasonForReduceWaterWallet] =
    React.useState("");
  const [reduceWalletTransActionId, setReduceWalletTransActionId] =
    React.useState("");
  const history = useHistory();

  const [checkAvailabilityClicked, setCheckAvailabilityClicked] =
    React.useState(false);

  const adminTransactionDetails = useSelector(
    (state) => state.adminTransactionDetails
  );
  const handleChangeArea = (event) => {
    setArea(event.target.value);
  };
  const handleUpdateGstBillName = () => {
    // CustomConsole(billname.trim() !== "");
    if (gstFieldError === false) {
      dispatch(
        UpdateGstDetails({
          billing_name: billname,
          gst,
          userId,
          is_b2c: !is_b2b,
        })
      );
    } else {
      toast.error("Please Enter Valid Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  React.useEffect(() => {
    dispatch(GetUserWaterDrops(userId));
  }, []);

  React.useEffect(() => {
    CustomConsole("---------User Details------");
    CustomConsole(addUserAdminDetails.users);
    if (addUserAdminDetails.users) {
      let reqObj = {
        phone: addUserAdminDetails.users[0].phone,
        userId: "",
        // addUserAdminDetails.users[0].id,
      };
      dispatch(GetBillingAddress(reqObj));
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (walletBalance < orderPrice) {
      setLowWalletBalance(true);
    } else {
      setLowWalletBalance(false);
    }
  }, [orderPrice, walletBalance]);

  React.useEffect(() => {
    CustomConsole("----hitting----userstock----");
    CustomConsole(userUpdates);
    if (userUpdates.userStockData.length > 0) {
      setUserStockData(userUpdates.userStockData);
    }
    if (userUpdates.error === false && toastFlag) {
      toast.success(userUpdates.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setToastFlag(false);
      setRequestDialogOpen(false);
    }
    if (userUpdates.error === true && toastFlag) {
      toast.error(userUpdates.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setToastFlag(false);
      setRequestDialogOpen(false);
    }
  }, [userUpdates]);
  // React.useEffect(() => {
  //   CustomConsole(adminUsers);

  //   adminUsers.map((data, key) => {
  //     setPhone(data.phone);
  //     // setUserId(data.id);
  //     dispatch(GetProfileDetails(data.id));
  //     dispatch(GetDepositDetails(data.id));
  //     dispatch(GetUserAddressAdmin(data.id));
  //     setConsumerName(data.fullname);
  //     setConsumerId(data.consumer_id);
  //   });
  // }, [adminUsers]);
  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileHandler.error === false && postpaidInitiated === true) {
      if (fileHandler.poUploadData.error === false) {
        history.push("/dashboard/postpaid");
        window.location.reload();
      }
    }
  }, [fileHandler]);

  React.useEffect(() => {
    CustomConsole("-------Consumer Profile Details-------");
    CustomConsole(profileData);
    if (profileData && Object.keys(profileData).length) {
      CustomConsole(profileData.is_b2c);
      setIs_b2c(profileData.is_b2c ? profileData.is_b2c : false);
    }
  }, [profileData]);

  React.useEffect(() => {
    CustomConsole("----->>>>>This is the b2c enable<<<<<-----");

    setIs_b2b(!is_b2c);
  }, [is_b2c]);

  React.useEffect(() => {
    CustomConsole("----->>>>>This is the b2c enable<<<<<-----");

    CustomConsole(is_b2b);
  }, [is_b2b]);

  React.useEffect(() => {
    CustomConsole("-------Booking Details-------");
    CustomConsole(orderDetails);
    if (walletTrigger === true) {
      CustomConsole(orderDetails.bookingOrderId);
      const data = {
        transaction_ts: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        amount: orderPrice,
        order_id: orderDetails.bookingOrderId,
        payment_method: "WATERWALLET",
        payment_type: "PREPAID",
        user_id: userId,
      };

      CustomConsole(walletBalance >= orderPrice);
      CustomConsole(paymentType);
      CustomConsole(orderDetails.bookingOrderId);
      if (
        walletBalance >= orderPrice &&
        paymentType === 3 &&
        orderDetails.bookingOrderId !== ""
      ) {
        CustomConsole(data);
        dispatch(PayThroughWallet(data));
      } else {
        CustomConsole("Low balance");
      }
      setWalletTrigger(false);
    }
  }, [orderDetails.bookingOrderId]);
  // React.useEffect(()=>{
  //   dispatch(GetProfileDetails(userId));
  // },[])
  React.useEffect(() => {
    CustomConsole("----- updateUserDetails.userProfileData ------");
    CustomConsole(updateUserDetails);
    setBillname(updateUserDetails.userProfileData.consumer?.billing_name);
    setGst(updateUserDetails.userProfileData.consumer?.gst);
    setProfileData(() => {
      return updateUserDetails.userProfileData.consumer;
    });
  }, [updateUserDetails.userProfileData]);
  React.useEffect(() => {}, [profileData]);
  const [packagedrinkingwaterData, setPackagedDrinkingWaterData] =
    React.useState({});

  React.useEffect(() => {
    CustomConsole(bookingDetails);
    CustomConsole(bookingDetails.availableAreas.length);
    if (bookingDetails.subscriptionOrderError === false) {
      dispatch(GetSubscriptionOrderDetails(userId));
    }
    if (Object.keys(bookingDetails.postPaidBookingData).length) {
      // CustomConsole("orderId");
      // CustomConsole(bookingDetails.postPaidBookingData.order_id[0]);
      if (bookingDetails.postPaidBookingData.error === false) {
        setPostpaidInitiated(true);
      } else {
        setPostpaidInitiated(false);
      }
    } else {
      setPostpaidInitiated(false);
    }

    if (bookingDetails.error === false) {
      if (Object.keys(bookingDetails.booknowOrderData).length > 0) {
        CustomConsole("Setting PDW Object here");
        setPackagedDrinkingWaterData(
          bookingDetails.booknowOrderData.packagedrinkingwater
        );
      }

      if (bookingDetails.setupConfigData.length) {
        bookingDetails.setupConfigData.map((data) => {
          if (data.key === "cansminmax") {
            setValue(data.value);
          } else if (data.key === "postpaidcharges") {
            setPostpaidPercentageValue(data.value);
          } else if (data.key === "replacementReasons") {
            setReplacementReasons(data.value);
          }
        });
      }
    }
  }, [bookingDetails]);

  React.useEffect(() => {
    CustomConsole(packagedrinkingwaterData);
    CustomConsole("PWD Object set use Effect");
    if (Object.keys(packagedrinkingwaterData).length > 0 && orderBtn === true) {
      if (
        packagedrinkingwaterData.twenty_ltr_amount !== 0 &&
        packagedrinkingwaterData.twenty_ltr_amount !== null
      ) {
        CustomConsole("setting PDW data");
        setPwdData(bookingDetails.booknowOrderData);
        setCanPrice(
          bookingDetails.booknowOrderData.packagedrinkingwater
            .twenty_ltr_amount -
            bookingDetails.booknowOrderData.packagedrinkingwater
              .packagedDrinkingwaterUserDiscount.twenty_ltr_discount
        );
        setOpenPdwOrderDialog(true);
        setOrderBtn(false);
      } else {
        CustomConsole("Not available for this area");
        toast.error("Not available for this pincode area", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      CustomConsole(packagedrinkingwaterData);
    }
  }, [packagedrinkingwaterData]);

  React.useEffect(() => {
    CustomConsole("setting min and max cans values");
    CustomConsole(value);
    if (value.length) {
      setMax(value[2].v);
      setMin(value[3].v);
    }
    CustomConsole(postpaidPercentageValue);
    if (postpaidPercentageValue.length) {
      setPostPaidPercentage(postpaidPercentageValue[0].v);
    }
  }, [value, postpaidPercentageValue]);

  React.useEffect(() => {
    CustomConsole(postPaidPercentage);
    setPostpaidCharges(postpaidFinalPrice - (0 * postPaidPercentage) / 100);
  }, [postPaidPercentage]);

  React.useEffect(() => {
    CustomConsole(postpaidCharges);
  }, [postpaidCharges]);

  React.useEffect(() => {
    CustomConsole(userUpdates);
    if (userUpdates.address.length) {
      CustomConsole("Storing user address");
      // If User has primary address marked that address will be set ad current address.
      // If not unmarked primary address will be set as current address.
      const primaryAddress = userUpdates.address.find(
        (data) => data.is_primary === true
      );
      CustomConsole(primaryAddress);
      const addressToStore = primaryAddress
        ? primaryAddress
        : userUpdates.address.find((data) => data.is_primary === false);

      CustomConsole(addressToStore);
      // setCurrentAddress(addressToStore);
    }
    userUpdates.accounts.map((data) => {
      // setUserId(data.user_id);
    });
    CustomConsole(Object.keys(userUpdates.subscriptionDetails).length);
    CustomConsole(Object.keys(userUpdates.subscriptionDetails).length);
    CustomConsole(Object.keys(userUpdates.subscriptionDetails).length);
    CustomConsole(Object.keys(userUpdates.subscriptionDetails).length);
    if (Object.keys(userUpdates.subscriptionDetails).length !== 0) {
      setSubscriptionExists(true);
    } else {
      setSubscriptionExists(false);
    }
    if (userUpdates.updateMsgSubscription !== "" && subscriptionBtn === true) {
      CustomConsole("subscription msg");
      toast.success(userUpdates.updateMsgSubscription, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setSubscriptionBtn(false);
      CustomConsole(userUpdates.subscriptionDetailsError);
      if (userUpdates.subscriptionDetailsError === false) {
        setSubscriptionData(userUpdates.subscriptionDetails);
        setDepositData(userUpdates.userDepositData);
        setStockData(userUpdates.userStockData);
        setOpenSubscriptionDialog(true);
      }
    }
  }, [userUpdates]);

  // React.useEffect(() => {
  //   if (updateGstDetailsBtn) {
  //     dispatch(UpdateGstDetails(billname, gst));
  //   }
  // }, [updateGstDetailsBtn]);

  const handleChangeGstNum = (event) => {
    CustomConsole(event.target.value.split(""));
    setGst(event.target.value);
    if (event.target.value.split("").length === 0) {
      CustomConsole("---inside the condition---");
      setGstFieldError(false);
    } else {
      let reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      var result = reg.test(event.target.value);
      CustomConsole(result);
      setGstFieldError(!result);
    }
  };

  const handleSubscription = () => {
    setSubscriptionFlag(true);
    setSubscriptionBtn(true);
  };

  React.useEffect(() => {
    if (subscriptionFlag === true) {
      dispatch(GetSubscriptionOrderDetails(userId));
      setSubscriptionFlag(false);
    }
  }, [subscriptionFlag]);

  const handleBookOrder = () => {
    CustomConsole(currentAddress);
    apiCall();
    setShowPincodeToEnter(true);
    setBookOrderBtn(true);
  };

  // const handleCheckAvailability = () => {
  //   if (pincode !== "") {
  //     CustomConsole("Search area API");
  //     CustomConsole(userId);
  //     setCheckAvailability(true);

  //     dispatch(SearchAreasByPincode(pincode));
  //     dispatch(GetUserAddressAdmin(userId));
  //     dispatch(GetDepositDetails());
  //     dispatch(GetUserDepositDetails(userId));
  //     dispatch(GetSetUpConfigAction());
  //   } else {
  //     CustomConsole("Enter pincode");
  //   }
  // };

  const apiCall = () => {
    dispatch(GetUserAddressAdmin(userId));
    dispatch(GetDepositDetails());
    dispatch(GetUserDepositDetails(userId));
    dispatch(GetSetUpConfigAction());
  };
  const handleSingleOrder = () => {
    setIsSubscriptionOrder(false);
    const reqObj = {
      tankerType: 3,
      pincode: pincode,
      phone: phone,
      status: false,
      area: area,
    };
    CustomConsole(reqObj);
    // setOpenPdwOrderDialog(true);
    if (area !== "") {
      dispatch(BooknowOrderDetails(reqObj));
      setOrderBtn(true);
    } else {
      toast.error("Select area", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleSubscriptionOrder = () => {
    setIsSubscriptionOrder(true);
    const reqObj = {
      tankerType: 3,
      pincode: pincode,
      phone: phone,
      status: false,
      area: area,
    };
    CustomConsole(reqObj);
    // setOpenPdwOrderDialog(true);
    if (area !== "") {
      dispatch(BooknowOrderDetails(reqObj));
      setOrderBtn(true);
    } else {
      toast.error("Select area", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  React.useEffect(() => {
    if (poFile !== "") {
      const reqObj = {
        order_id: bookingDetails.postPaidBookingData.order_id,
        order_price: postpaidFinalPrice,
        postpaid_perc: 0,
        postpaid_charges: postpaidCharges,
        final_order_price: postpaidFinalPrice,
        user_id: userId,
        payment_type: "POSTPAID",
        status_id: "0",
        status: "PENDING",
        conv_fee: "0.00",
        conv_charges: "0.00",
        poFile: poFileUrl,
      };
      dispatch(UploadPoFile(reqObj));
    }
  }, [poFileUrl]);

  React.useEffect(() => {
    // CustomConsole(poFile);
    // CustomConsole(poFile.name);
    if (poFile !== undefined || poFile !== "") {
      setPoFileUrl(poFile);
    }
  }, [poFile]);

  const replacementDialogData = () => {
    //Replacement dialogue open and data sending
    userStockData.forEach((value) => {
      setContainerCapacity((capacity) => [
        ...capacity,
        value.container_capacity,
      ]);
      setContainerMaterial((material) => [
        ...material,
        value.container_material,
      ]);
    });
  };

  const openRepDialog = () => {
    //Replacement dialogue open and data sending
    setRequestDialogOpen(true);
    replacementDialogData();
  };

  React.useEffect(() => {
    CustomConsole(userUpdates);
    if (userUpdates.addWalleterror === false && addWalletBtn === true) {
      // setAccordianExpand(false);
      setAmountToAdd("");
      dispatch(GetWalletDetails(userId));
      setAddWalletBtn(false);
    }
  }, [userUpdates]);

  const handleAddWalletAmount = () => {
    CustomConsole("Add money to wallet");
    setAddMoneyBtn(!addMoneyBtn);
    setAddWalletBtn(true);
    CustomConsole(amountToAdd);
    const reqObj = {
      user_id: userId,
      amount: amountToAdd,
      refund_reason: 101,
    };

    if (parseInt(amountToAdd) > 0) {
      dispatch(AddMoneyToWallet(reqObj));
    } else {
      toast.error("Amount should be greater than zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleChangeAmountToAdd = (event) => {
    setAmountToAdd(event.target.value);
  };
  const handleChangeAmountToReduceWaterWallet = (event) => {
    setAmountToReduceWaterWallet(event.target.value);
  };

  // handling on address select
  const handleAddressClick = (data) => {
    CustomConsole(data);
    if (data.pincode !== "") {
      CustomConsole("Search area API");
      CustomConsole(userId);
      setPostpaidInitiated(false);
      // dispatch(SearchAreasByPincode(data.pincode));
      // dispatch(GetUserAddressAdmin(userId));
      dispatch(GetDepositDetails());
      dispatch(GetUserDepositDetails(userId));
      dispatch(GetSetUpConfigAction());
      setCurrentAddress(data);
    } else {
      CustomConsole("Enter pincode");
    }
    setIsSubscriptionOrder(false);
    const reqObj = {
      tankerType: 3,
      pincode: data.pincode,
      phone: phone,
      status: false,
      area: data.service_area,
    };
    CustomConsole(reqObj);
    // setOpenPdwOrderDialog(true);
    if (data.service_area !== "") {
      dispatch(BooknowOrderDetails(reqObj));
      setOrderBtn(true);
    } else {
      toast.error("Select area", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  /**
   * @author vinay kumar
   * @description Add Water Drops to perticular User
   * @params
   * @copyright Bookwater tech pvt ltd
   */
  // This UI Complete ReDesign Done by Vinay
  const handleAddWaterDrops = () => {
    CustomConsole("------------handleAddWaterDrops-------------");
    if (reasonForWaterDrop !== "" && waterDropsToAdd !== "") {
      const reqObj = {
        user_id: userId,
        reason: reasonForWaterDrop,
        water_drops: parseInt(waterDropsToAdd),
      };
      CustomConsole(reqObj);
      dispatch(AddWaterDrops(reqObj));
      setTimeout(() => {
        dispatch(GetUserWaterDrops(userId));
      }, 1000);
      setWaterDropsToAdd("");
      setReasonForWaterDrop("");
    } else {
      toast.error("Please Fill All Data", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleReduceWalletAmount = () => {
    CustomConsole("------------handleReduceWalletAmount-------------");
    if (
      amountToReduceWaterWallet !== "" &&
      reasonForReduceWaterWallet !== "" &&
      reduceWalletTransActionId !== ""
    ) {
      const reqObj = {
        user_id: userId,
        reason: reasonForReduceWaterWallet,
        amount: parseInt(amountToReduceWaterWallet),
        trans_id: reduceWalletTransActionId,
      };
      CustomConsole(reqObj);
      dispatch(PostReduceWaterWallet(reqObj));
      setAmountToReduceWaterWallet("");
      setReasonForReduceWaterWallet("");
      setReduceWalletTransActionId("");
      setTimeout(() => {
        if (userUpdates.reduceWalleterror === false) {
          dispatch(GetWalletDetails(userId));
        }
      }, 1000);
    } else {
      toast.error("Please Fill All Data", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleChangeWaterDropsToAdd = (event) => {
    setWaterDropsToAdd(event.target.value);
  };
  const handleChangeReasonForRefund = (event) => {
    setReasonForWaterDrop(event.target.value);
  };
  const handleChangeReasonForReduceWaterWallet = (event) => {
    setReasonForReduceWaterWallet(event.target.value);
  };
  return (
    <Box>
      <Paper elevation={24} sx={{ marginTop: "10px" }}>
        <Card sx={UsersStyles.cardshadow}>
          <CardContent>
            <Box>
              <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="md">
                  <CssBaseline />
                  <Box
                  // sx={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   alignItems: "center",
                  // }}
                  >
                    <Box
                      component="form"
                      //   onSubmit={handleSubmit}
                      noValidate
                      // sx={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   alignItems: "center",
                      //   justifyContent: "center",
                      //   gap: "20px",
                      //   padding: "10px",
                      // }}
                    >
                      <Material.Grid container>
                        <Material.Grid
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                          sx={{ marginTop: "20px" }}
                          item
                          md={6}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <Typography>Mark as B2B</Typography>
                            <Switch
                              checked={is_b2b}
                              onChange={() => setIs_b2b((prev) => !prev)}
                              color="success"
                            />
                          </Box>
                          {/* <Material.Grid item md={3}> */}
                          <TextField
                            size="small"
                            label="Bill name"
                            value={billname}
                            onChange={(event) =>
                              setBillname(event.target.value)
                            }
                            sx={{ margin: "10px" }}
                          />
                          {/* </Material.Grid>
                      <Material.Grid item md={3}> */}
                          <TextField
                            size="small"
                            label="GST"
                            value={gst}
                            error={gstFieldError}
                            onChange={handleChangeGstNum}
                            helperText={
                              gstFieldError && "Enter proper GST number"
                            }
                            sx={{ margin: "10px" }}
                          />
                          {/* </Material.Grid> */}

                          <Material.Grid
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item
                            md={12}
                          >
                            <Box>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  marginTop: "10px",
                                  background:
                                    appColors.commonBtnColor.background,
                                }}
                                // onClick={() => setUpdateGstDetailsBtn(true)}
                                onClick={() => handleUpdateGstBillName()}
                              >
                                Update
                              </Button>
                            </Box>
                          </Material.Grid>
                          {updateUserDetails.billingAddress &&
                          updateUserDetails.billingAddress.length ? (
                            <Card sx={{ p: 2 }}>
                              <Box>
                                <Typography sx={{ fontWeight: "600" }}>
                                  Billing Address
                                </Typography>
                              </Box>
                              <Material.Stack direction={"column"}>
                                <Typography>
                                  {
                                    updateUserDetails.billingAddress[0]
                                      .contact_name
                                  }
                                </Typography>
                                <Typography>
                                  {
                                    updateUserDetails.billingAddress[0]
                                      .address_line1
                                  }
                                </Typography>
                                <Typography>
                                  {
                                    updateUserDetails.billingAddress[0]
                                      .address_line2
                                  }
                                </Typography>
                                <Typography>
                                  {
                                    updateUserDetails.billingAddress[0]
                                      .address_line3
                                  }
                                </Typography>
                                <Typography>
                                  {updateUserDetails.billingAddress[0].pincode}
                                </Typography>
                                <Typography>
                                  {
                                    updateUserDetails.billingAddress[0]
                                      .service_area
                                  }
                                </Typography>
                                <Typography>
                                  {
                                    updateUserDetails.billingAddress[0]
                                      .contact_phone
                                  }
                                </Typography>
                              </Material.Stack>
                            </Card>
                          ) : null}
                        </Material.Grid>
                        {adminRoles === ADMIN_ROLES.BW_ADMIN ||
                        adminRoles === ADMIN_ROLES.BW_SUPERADMIN ||
                        adminRoles === ADMIN_ROLES.BW_FINANCEHEAD ||
                        adminRoles === ADMIN_ROLES.BW_CSHEAD ||
                        adminRoles === ADMIN_ROLES.BW_CS ||
                        adminRoles === ADMIN_ROLES.BW_CSLEAD ? (
                          <Material.Grid item md={6}>
                            <Box>
                              <Box
                                sx={{
                                  background:
                                    appColors.commonLayoutColor.background,
                                  backgroundImage:
                                    appColors.commonLayoutColor.backgroundImage,
                                  border: "1px solid #ccc",
                                  borderRadius: "10px",
                                  margin: "20px",
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Wallet Balance : ₹{walletBalance}
                                </Typography>
                                {/* {adminRoles === ADMIN_ROLES.BW_ADMIN ||
                                adminRoles === ADMIN_ROLES.BW_SUPERADMIN ||
                                adminRoles === ADMIN_ROLES.BW_FINANCEHEAD ||
                                adminRoles === ADMIN_ROLES.BW_CSHEAD ? (
                                  <>
                                    <Box>
                                      <Accordion
                                      // expanded={accordionExpand}
                                      // onClick={() => setAccordianExpand(true)}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography
                                            sx={{
                                              textAlign: "center",
                                              fontWeight: "bold",
                                            }}
                                            variant="button"
                                            display="block"
                                            gutterBottom
                                          >
                                            Add money
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <Box>
                                              <TextField
                                                id="outlined-basic"
                                                label="Enter amount"
                                                variant="outlined"
                                                color="secondary"
                                                // type="number"
                                                onKeyDown={(e) => {
                                                  if (
                                                    !/^([0-9]\b)+$/.test(e.key)
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                  if (e.key === "Backspace") {
                                                    setAmountToAdd(
                                                      (preAmount) =>
                                                        preAmount.slice(0, -1)
                                                    );
                                                  }
                                                }}
                                                value={amountToAdd}
                                                // sx={{ backgroundColor: "white" }}
                                                size="small"
                                                onChange={
                                                  handleChangeAmountToAdd
                                                }
                                              />
                                            </Box>
                                            <Box>
                                              <Button
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                  background:
                                                    appColors.commonBtnColor
                                                      .background,
                                                }}
                                                onClick={handleAddWalletAmount}
                                              >
                                                Add Now
                                              </Button>
                                            </Box>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Box>
                                  </>
                                ) : null} */}
                                {adminRoles === ADMIN_ROLES.BW_ADMIN ||
                                adminRoles === ADMIN_ROLES.BW_SUPERADMIN ||
                                adminRoles === ADMIN_ROLES.BW_FINANCEHEAD ||
                                adminRoles === ADMIN_ROLES.BW_CSHEAD ? (
                                  <>
                                    <Box>
                                      <Accordion
                                      // expanded={accordionExpand}
                                      // onClick={() => setAccordianExpand(true)}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography
                                            sx={{
                                              textAlign: "center",
                                              fontWeight: "bold",
                                            }}
                                            variant="button"
                                            display="block"
                                            gutterBottom
                                          >
                                            Reduce Water Wallet
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <Box sx={{m:1}}>
                                              <TextField
                                                id="outlined-basic"
                                                label="Enter amount"
                                                variant="outlined"
                                                color="secondary"
                                                // type="number"
                                                onKeyDown={(e) => {
                                                  if (
                                                    !/^([0-9]\b)+$/.test(e.key)
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                  if (e.key === "Backspace") {
                                                    setAmountToReduceWaterWallet(
                                                      (preAmount) =>
                                                        preAmount.slice(0, -1)
                                                    );
                                                  }
                                                }}
                                                value={
                                                  amountToReduceWaterWallet
                                                }
                                                // sx={{ backgroundColor: "white" }}
                                                size="small"
                                                onChange={
                                                  handleChangeAmountToReduceWaterWallet
                                                }
                                              />
                                            </Box>
                                            <Box  sx={{m:1}}>
                                              <TextField
                                                id="outlined-basic"
                                                label="Reason For Reduce Wallet *"
                                                variant="outlined"
                                                color="secondary"
                                                value={
                                                  reasonForReduceWaterWallet
                                                }
                                                size="small"
                                                onChange={
                                                  handleChangeReasonForReduceWaterWallet
                                                }
                                              />
                                              <Box sx={{m:1}}>
                                                <TextField
                                                  id="outlined-basic"
                                                  label="Transaction Id*"
                                                  variant="outlined"
                                                  color="secondary"
                                                  value={
                                                    reduceWalletTransActionId
                                                  }
                                                  size="small"
                                                  onChange={(event) =>
                                                    setReduceWalletTransActionId(
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              </Box>
                                            </Box>
                                            <Box > 
                                              <Button
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                  background:
                                                    appColors.commonBtnColor
                                                      .background,
                                                }}
                                                onClick={
                                                  handleReduceWalletAmount
                                                }
                                              >
                                                Reduce Now
                                              </Button>
                                            </Box>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Box>
                                  </>
                                ) : null}
                              </Box>
                            </Box>
                            <Box>
                              <Box
                                sx={{
                                  background:
                                    appColors.commonLayoutColor.background,
                                  backgroundImage:
                                    appColors.commonLayoutColor.backgroundImage,
                                  border: "1px solid #ccc",
                                  borderRadius: "10px",
                                  margin: "20px",
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Water Drops :{" "}
                                  {
                                    adminTransactionDetails.waterDropsData
                                      .water_drops
                                  }
                                </Typography>
                                {adminRoles === ADMIN_ROLES.BW_ADMIN ||
                                adminRoles === ADMIN_ROLES.BW_SUPERADMIN ||
                                adminRoles === ADMIN_ROLES.BW_CSHEAD ? (
                                  <Box>
                                    <Accordion
                                    // expanded={accordionExpand}
                                    // onClick={() => setAccordianExpand(true)}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography
                                          sx={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                          variant="button"
                                          display="block"
                                          gutterBottom
                                        >
                                          Add Water Drops
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <Box>
                                            <TextField
                                              id="outlined-basic"
                                              label="Reason For Refund *"
                                              variant="outlined"
                                              color="secondary"
                                              // type="number"
                                              // onKeyDown={(e) => {
                                              //   if (!/^([0-9]\b)+$/.test(e.key)) {
                                              //     e.preventDefault();
                                              //   }
                                              //   if (e.key === "Backspace") {
                                              //     setAmountToAdd((preAmount) =>
                                              //       preAmount.slice(0, -1)
                                              //     );
                                              //   }
                                              // }}
                                              value={reasonForWaterDrop}
                                              // sx={{ backgroundColor: "white" }}
                                              size="small"
                                              onChange={
                                                handleChangeReasonForRefund
                                              }
                                            />
                                          </Box>
                                          <Box>
                                            <TextField
                                              id="outlined-basic"
                                              label="Enter Water Drops *"
                                              variant="outlined"
                                              color="secondary"
                                              type="number"
                                              onInput={(e) => {
                                                e.target.value = Math.max(
                                                  0,
                                                  parseInt(e.target.value)
                                                )
                                                  .toString()
                                                  .slice(0, 10);
                                              }}
                                              // onKeyDown={(e) => {
                                              //   if (!/^([0-9]\b)+$/.test(e.key)) {
                                              //     e.preventDefault();
                                              //   }
                                              //   if (e.key === "Backspace") {
                                              //     setAmountToAdd((preAmount) =>
                                              //       preAmount.slice(0, -1)
                                              //     );
                                              //   }
                                              //  }}
                                              value={waterDropsToAdd}
                                              // sx={{ backgroundColor: "white" }}
                                              size="small"
                                              onChange={
                                                handleChangeWaterDropsToAdd
                                              }
                                            />
                                          </Box>
                                          <Box>
                                            <Button
                                              variant="contained"
                                              size="small"
                                              sx={{
                                                background:
                                                  appColors.commonBtnColor
                                                    .background,
                                              }}
                                              onClick={handleAddWaterDrops}
                                            >
                                              Add Water Drops
                                            </Button>
                                          </Box>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          </Material.Grid>
                        ) : (
                          ""
                        )}

                        <Material.Grid item md={12}>
                          <Card elevation={10} sx={{ margin: "10px" }}>
                            <CardContent>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "space-around",
                                  gap: "10px",
                                }}
                              >
                                {adminRoles === ADMIN_ROLES.BW_SALES ||
                                adminRoles === ADMIN_ROLES.BW_SALESHEAD ||
                                adminRoles === ADMIN_ROLES.BW_OPS ||
                                adminRoles === ADMIN_ROLES.BW_OPSHEAD ? null : (
                                  <Material.Grid item md={4}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      sx={{
                                        background:
                                          appColors.commonBtnColor.background,
                                      }}
                                      onClick={handleSubscription}
                                    >
                                      Subscription details
                                    </Button>
                                  </Material.Grid>
                                )}

                                {/* <Material.Grid item md={4}>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      background:
                                        appColors.commonBtnColor.background,
                                    }}
                                    onClick={handleBookOrder}
                                  >
                                    Book order
                                  </Button>
                                </Material.Grid> */}
                                <Material.Grid item md={4}>
                                  {adminRoles === ADMIN_ROLES.BW_ADMIN ||
                                  adminRoles === ADMIN_ROLES.BW_SUPERADMIN ||
                                  adminRoles === ADMIN_ROLES.BW_CS ||
                                  adminRoles === ADMIN_ROLES.BW_CSHEAD ||
                                  adminRoles === ADMIN_ROLES.BW_CSLEAD ? (
                                    <Box>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          background:
                                            appColors.commonBtnColor.background,
                                        }}
                                        onClick={openRepDialog}
                                      >
                                        Replacement Request
                                      </Button>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </Material.Grid>
                              </Box>
                              {/* </Material.Grid> */}
                            </CardContent>
                          </Card>
                        </Material.Grid>
                      </Material.Grid>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "20px",
                          flexDirection: "column",
                        }}
                      >
                        <ReplacementDialogue
                          requestDialogOpen={requestDialogOpen}
                          setRequestDialogOpen={setRequestDialogOpen}
                          replacementReasons={replacementReasons}
                          containerMaterial={containerMaterial}
                          containerCapacity={containerCapacity}
                          setContainerMaterial={setContainerMaterial}
                          setContainerCapacity={setContainerCapacity}
                          setToastFlag={setToastFlag}
                          userId={userId}
                        />

                        <Paper elevation={24}>
                          <Box sx={{ padding: "10px" }}>
                            <Button
                              variant="contained"
                              sx={{
                                background: appColors.commonBtnColor.background,
                              }}
                              onClick={handleBookOrder}
                            >
                              Book order
                            </Button>
                          </Box>
                          {/* User Address for booking*/}
                          {bookOrderBtn && (
                            <>
                              {userAddressAdmin.address.length > 0 ? (
                                <>
                                  {" "}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                      gap: "20px",
                                      padding: "10px",
                                    }}
                                  >
                                    {" "}
                                    <Box sx={{ padding: "10px" }}>
                                      <Typography
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        PLEASE SELECT ADDRESS TO BOOK
                                      </Typography>
                                    </Box>
                                    {userAddressAdmin.address.map(
                                      (data, key) => {
                                        return (
                                          <Paper elevation={22}>
                                            <Card
                                              sx={{
                                                minWidth: 275,
                                                cursor: "pointer",
                                                "&:hover": {
                                                  boxShadow: "none",
                                                  backgroundColor: "#2196f3",
                                                },
                                                backgroundColor:
                                                  addressId === data.address_id
                                                    ? "#2196f3"
                                                    : "",
                                              }}
                                              key={key}
                                              onClick={() =>
                                                handleAddressClick(
                                                  data,
                                                  data.address_id
                                                )
                                              }
                                            >
                                              <CardContent>
                                                <Box>
                                                  <Typography textAlign="left">
                                                    Cans: {data.existing_cans}
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "10px",
                                                  }}
                                                >
                                                  {addressId ===
                                                    data.address_id && (
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "flex-end",
                                                      }}
                                                    >
                                                      <CheckBoxRoundedIcon color="success" />
                                                    </Box>
                                                  )}

                                                  <Box>
                                                    {data.is_primary ? (
                                                      <Typography
                                                        sx={{
                                                          fontSize: "18px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {data.contact_name}
                                                        <span
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {" "}
                                                          * Primary
                                                        </span>
                                                      </Typography>
                                                    ) : (
                                                      <Typography
                                                        sx={{
                                                          fontSize: "18px",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {data.contact_name}
                                                      </Typography>
                                                    )}
                                                  </Box>
                                                  <Box>
                                                    <Typography>
                                                      {data.address_line1}
                                                    </Typography>
                                                    <Typography>
                                                      {data.address_line2}
                                                    </Typography>
                                                    <Typography>
                                                      {data.address_line3}
                                                    </Typography>
                                                    <Typography>
                                                      {data.pincode}
                                                    </Typography>
                                                    <Typography>
                                                      {data.service_area}
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Typography>
                                                      {data.contact_phone}
                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      gap: "10px",
                                                    }}
                                                  ></Box>
                                                </Box>
                                              </CardContent>
                                            </Card>
                                          </Paper>
                                        );
                                      }
                                    )}
                                  </Box>
                                </>
                              ) : (
                                <Box sx={{ padding: "10px" }}>
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Please add address to Book
                                  </Typography>
                                </Box>
                              )}
                            </>
                          )}
                        </Paper>
                        {/* {bookOrderBtn && (
                          <>
                            {" "}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <Box>
                                <TextField
                                  type="number"
                                  id="outlined-basic"
                                  label="Pincode"
                                  variant="outlined"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  value={pincode}
                                  onChange={(event) =>
                                    setPincode(event.target.value)
                                  }
                                />
                              </Box>
                              <Box>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={handleCheckAvailability}
                                >
                                  Check availability
                                </Button>
                              </Box>
                            </Box>
                          </>
                        )} */}
                        {bookingDetails.availableAreas.length > 0 &&
                          checkAvailability === true && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              {/* <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Select area
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={area}
                                  label="Select area"
                                  onChange={handleChangeArea}
                                >
                                  {bookingDetails.availableAreas.map((area) => {
                                    return (
                                      <MenuItem value={area.Name}>
                                        {area.Name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl> */}
                              {/* {area !== "" && (
                                <>
                                  <Box>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={handleSingleOrder}
                                    >
                                      Single Order
                                    </Button>
                                  </Box>
                                  {Object.keys(userUpdates.subscriptionDetails)
                                    .length === 0 && (
                                    <Box>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleSubscriptionOrder}
                                      >
                                        Subscription Order
                                      </Button>
                                    </Box>
                                  )}
                                </>
                              )} */}
                            </Box>
                          )}
                      </Box>
                    </Box>
                    {/* {Object.keys(bookingDetails.postPaidBookingData).length >
                      0 && postPaidFlag === true ? (
                      <Box>
                        <Box>
                          <InputLabel htmlFor="import-button">
                            <Input
                              id="import-button"
                              type="file"
                              onChange={(e) => setPoFile(e.target.files[0])}
                            />
                            <Tooltip title="Upload PO file">
                              <FileUploadRoundedIcon
                                sx={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </InputLabel>
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )} */}
                    {postpaidInitiated && postPaidFlag === true ? (
                      <PostpaidPoUpload
                        postpaidInitiated={postpaidInitiated}
                        setPostpaidInitiated={setPostpaidInitiated}
                        setPoFile={setPoFile}
                        setBookOrderBtn={setBookOrderBtn}
                        setCheckAvailability={setCheckAvailability}
                        setArea={setArea}
                        postPaidFlag={postPaidFlag}
                        setPostpaidFlag={setPostpaidFlag}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          </CardContent>
        </Card>
      </Paper>
      {openPdwOrderDialog ? (
        <>
          <PdwOrderDialog
            walletBalance={walletBalance}
            setOpenPdwOrderDialog={setOpenPdwOrderDialog}
            openPdwOrderDialog={openPdwOrderDialog}
            pdwData={pdwData}
            twentyLtrPrice={canPrice}
            depositData={bookingDetails.depositData}
            userStockData={bookingDetails.userStockData}
            min={min}
            max={max}
            isSubscriptionOrder={isSubscriptionOrder}
            setIsSubscriptionOrder={setIsSubscriptionOrder}
            currentAddress={currentAddress}
            setCurrentAddress={setCurrentAddress}
            consumerName={consumerName}
            consumerArea={consumerArea}
            consumerId={consumerId}
            setShowPincodeToEnter={setShowPincodeToEnter}
            setPostpaidFinalPrice={setPostpaidFinalPrice}
            setBookOrderBtn={setBookOrderBtn}
            bookOrderBtn={bookOrderBtn}
            checkAvailability={checkAvailability}
            setCheckAvailability={setCheckAvailability}
            pincode={pincode}
            area={area}
            orderBtn={orderBtn}
            setOrderBtn={setOrderBtn}
            setPostpaidFlag={setPostpaidFlag}
            setWalletTrigger={setWalletTrigger}
            setOrderPrice={setOrderPrice}
            setPaymentType={setPaymentType}
            subscriptionExists={subscriptionExists}
            lowWalletBalance={lowWalletBalance}
          />
        </>
      ) : (
        ""
      )}

      {openSubscriptionDialog ? (
        <>
          <SubscriptionDetailsDialog
            openSubscriptionDialog={openSubscriptionDialog}
            setOpenSubscriptionDialog={setOpenSubscriptionDialog}
            subscriptionData={subscriptionData}
            depositData={bookingDetails.depositData}
            stockData={stockData}
            userId={userId}
          />
        </>
      ) : (
        ""
      )}
      <ToastContainer />
    </Box>
  );
}
