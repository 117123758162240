import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomConsole from "../../CustomConsole";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { PutPromoCodeUpdateAction } from "../Redux/Actions/promocodeAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetAllPromoCodeListAction } from "../Redux/Actions/promocodeAction";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { Radio, RadioGroup } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PromoTableDialog({
  promoTableDialogFlag,
  setPromoTableDialogFlag,
  setPromoId,
  promoId,
  updateData,
  setUpdateData,
  pageNumber,
  promoExpired,
  promoCodeType,
  promoOfferType,
  promoCreatedAt,
  setUpdateClicked,
  updateClicked,
}) {
  const [updatepromCodetype, setUpdatepromCodetype] = React.useState(0);
  const [updatemaxUsagelimit, setUpdatemaxUsagelimit] = React.useState(1);
  const [updatepromOffertype, setUpdatepromOffertype] = React.useState(0);
  const [updateswitchpromoExpired, setUpdateswitchpromoExpired] =
    React.useState(false);
  const [updatechangeSwitchExpired, setUpdatechangeSwitchExpired] =
    React.useState(false);
  const [updatePromoLabel, setUpdatePromoLabel] = React.useState("");
  const [changeInPromoLabel, setChangeInPromoLabel] = React.useState(false);
  const [updateminimumConditonUse, setUpdateminimumConditonUse] =
    React.useState("");
  const [updatepromoOfferValue, setupdatepromoOfferValue] = React.useState("");
  const [updatevalidityInHours, setupdatevalidityInHours] = React.useState("");
  const [updatepromoDisClaimer, setupdatepromoDisClaimer] = React.useState("");
  const [promoOffertypeappMea, setPromoOffertypeappMea] = React.useState([]);
  const [promoCodeTypeappMeta, setPromoCodeTypeappMeta] = React.useState([]);
  const [updatepromoExpiredFromValue, setUpdatepromoExpiredFromValue] =
    React.useState(() => moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
  const [updatepromoExpiredToValue, setUpdatepromoExpiredToValue] =
    React.useState(() => moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
  const [updatevalidityInDays, setUpdateValidityInDays] = React.useState(0);

  const [updatepromoBtn, setUpdatepromoBtn] = React.useState(false);
  const [dateError, setDateError] = React.useState(
    moment(updatepromoExpiredToValue).isBefore(
      moment(updatepromoExpiredFromValue)
    )
  );
  const [updateValidity, setUpdateValidity] = React.useState("inHours");
  const [confirmDel, setConfirmDel] = React.useState(false);
  const bookingDetails = useSelector((state) => state.adminBookingData);

  const dispatch = useDispatch();
  const adminPromoCodeData = useSelector((state) => state.promocodeAdmin);
  // CustomConsole(adminPromoCodeData)

  React.useEffect(() => {
    bookingDetails.setupConfigData.map((data) => {
      CustomConsole(data);
      if (data.key === "promo_offer_types") {
        setPromoOffertypeappMea(data.value);
      }
      if (data.key === "promo_code_types") {
        setPromoCodeTypeappMeta(data.value);
      }
    });
  }, [bookingDetails]);

  React.useEffect(() => {
    CustomConsole("changing state");
    CustomConsole(moment().format());
    CustomConsole(updatepromoExpiredToValue);
    CustomConsole(updatepromoExpiredFromValue);
    CustomConsole(
      moment(updatepromoExpiredToValue).isBefore(
        moment(updatepromoExpiredFromValue)
      )
    );
    setDateError(() =>
      moment(updatepromoExpiredToValue).isBefore(
        moment(updatepromoExpiredFromValue)
      )
    );
  }, [updatepromoExpiredToValue, updatepromoExpiredFromValue]);

  React.useState(() => {
    CustomConsole(dateError);
  }, [dateError]);

  React.useEffect(() => {
    CustomConsole(adminPromoCodeData);
    CustomConsole(adminPromoCodeData.promoSingleDetails.promo_code_type);
    CustomConsole(adminPromoCodeData.promoSingleDetails.promo_offer_type);
    if (adminPromoCodeData.error === false && updateClicked) {
      if (adminPromoCodeData.promoSingleDetails.valid_till > 0) {
        setUpdateValidity("inHours");
      } else {
        setUpdateValidity("inDate");
      }
      setUpdatePromoLabel(adminPromoCodeData.promoSingleDetails.promo_label);

      setUpdatepromCodetype(
        adminPromoCodeData.promoSingleDetails.promo_code_type
      );

      CustomConsole(adminPromoCodeData.promoSingleDetails.promo_offer_type);
      setUpdatepromOffertype(
        adminPromoCodeData.promoSingleDetails.promo_offer_type
      );
      setUpdateminimumConditonUse(
        adminPromoCodeData.promoSingleDetails.min_charges_to_use
      );
      setupdatepromoOfferValue(
        adminPromoCodeData.promoSingleDetails.promo_offer_value
      );
      setupdatevalidityInHours(
        adminPromoCodeData.promoSingleDetails.valid_till
      );
      setUpdatemaxUsagelimit(
        adminPromoCodeData.promoSingleDetails.max_usage_limit
      );
      setUpdateswitchpromoExpired(
        adminPromoCodeData.promoSingleDetails.is_expired
      );
      setupdatepromoDisClaimer(
        adminPromoCodeData.promoSingleDetails.promo_disclaimer
      );
      setUpdatepromoExpiredFromValue(
        adminPromoCodeData.promoSingleDetails.start_date
      );
      setUpdatepromoExpiredToValue(
        adminPromoCodeData.promoSingleDetails.expiry_date
      );

      setUpdateClicked(false);
    }
  }, [adminPromoCodeData]);

  const validationForDates = () => {
    // updatepromoExpiredToValue
    // updatepromoExpiredFromValue

    let today = moment().format("YYYY-MM-DD");
    let compare = moment(updatepromoExpiredFromValue).format("YYYY-MM-DD");
    CustomConsole(today);
    CustomConsole(compare);
    // if the todays date is before *from* date this will give true
    if (moment(today).isSameOrBefore(compare)) {
      return true;
    } else {
      return false;
    }
  };

  const handlePromoCodetype = (event) => {
    CustomConsole(event.target.value);
    setUpdatepromCodetype(event.target.value);
  };

  const handleMaxUsagelimit = (event) => {
    if (event.target.value >= 0) {
      setUpdatemaxUsagelimit(event.target.value);
    }
    if (event.target.value >= 1) {
      setUpdatemaxUsagelimit(event.target.value);
    } else {
      toast.error(" Please Enter Minimum One ", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleupdatevalidityInHours = (event) => {
    setupdatevalidityInHours(event.target.value);
  };

  const handleUpdateminimumConditonUse = (event) => {
    if (event.target.value >= 0) {
      setUpdateminimumConditonUse(event.target.value);
    }
  };

  const handleUpdateValidityInDays = (event) => {
    setUpdateValidityInDays(event.target.value);
  };

  const handleupdatepromoOfferValue = (event) => {
    if (event.target.value >= 0) {
      setupdatepromoOfferValue(event.target.value);
    }
  };
  const clickPromoOffertype = (event) => {
    setUpdatepromOffertype(event.target.value);
  };
  const clickClose = () => {
    setPromoTableDialogFlag(false);
  };

  const handlePromoExpiredSwitch = (event) => {
    setUpdatechangeSwitchExpired(true);
    if (event.target.checked) {
      setUpdateswitchpromoExpired(true);
    } else if (event.target.checked === false) {
      setUpdateswitchpromoExpired(false);
    }
  };
  // const [promoStartDate, setPromoStartDate] = React.useState(() => moment());

  // const [promoEndDate, setpromoEndDate] = React.useState(() => moment());
  const handleChangeUpdatePromoExpiredFrom = (newValue) => {
    CustomConsole("---- handleChangeUpdatePromoExpiredFrom ----");
    CustomConsole(newValue);
    CustomConsole();
    if (newValue != null && newValue.$d != null) {
      // setPromoStartDate(() => moment(newValue.$d));
      setUpdatepromoExpiredFromValue(
        moment(newValue.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      );
      CustomConsole(newValue.$d);
    }
  };

  const handleChangeUpdatePromoExpiredTo = (newValue) => {
    CustomConsole("---- handleChangeUpdatePromoExpiredTo ----");
    CustomConsole(newValue);
    if (newValue != null && newValue.$d != null) {
      // setpromoEndDate(() => moment(newValue.$d));
      setUpdatepromoExpiredToValue(
        moment(newValue.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      );
      CustomConsole(newValue.$d);
    }
  };
  React.useEffect(() => {
    CustomConsole(adminPromoCodeData);
    if (
      (adminPromoCodeData.msg === adminPromoCodeData.updatePromoerror) ===
        false &&
      updatepromoBtn === true
    ) {
      CustomConsole("---updated successfully----");
      dispatch(
        GetAllPromoCodeListAction(
          pageNumber - 1,
          promoExpired,
          promoCodeType,
          promoOfferType,
          promoCreatedAt
        )
      );
      setUpdatepromoBtn(false);
    }
  }, [adminPromoCodeData]);

  const handleUpdate = () => {
    setUpdatepromoBtn(true);
    CustomConsole(promoId);
    CustomConsole(validationForDates());
    CustomConsole("Hours selected and to date error true");
    CustomConsole(dateError && updateValidity === "inHours");
    CustomConsole("Date selected and to date error");
    CustomConsole(!dateError && updateValidity === "inDate");
    CustomConsole(updatepromOffertype);
    if (
      promoTableDialogFlag === true &&
      updatePromoLabel !== "" &&
      updatepromoDisClaimer !== "" &&
      updatepromOffertype !== "" &&
      updatepromoOfferValue !== "" &&
      updatepromoExpiredFromValue !== "" &&
      // updatepromoExpiredToValue !== "" &&
      updatevalidityInDays !== "" &&
      updatepromCodetype !== "" &&
      updatemaxUsagelimit !== "" &&
      updateminimumConditonUse !== "" &&
      updateswitchpromoExpired !== "" &&
      validationForDates()
    ) {
      let reqObj = {
        promo_label: changeInPromoLabel ? updatePromoLabel : undefined,
        promo_disclaimer: updatepromoDisClaimer,
        promo_offer_type: updatepromOffertype,
        promo_offer_value: updatepromoOfferValue,
        promo_code_type: updatepromCodetype,
        valid_till:
          parseInt(updatevalidityInDays) * 24 +
            parseInt(updatevalidityInHours) || undefined,
        expiry_date: updatepromoExpiredToValue || undefined,
        start_date: updatepromoExpiredFromValue,
        max_usage_limit: updatemaxUsagelimit,
        min_charges_to_use: updateminimumConditonUse,
        is_expired: updateswitchpromoExpired,
      };
      if (
        updatepromOffertype === 300 &&
        updatepromoOfferValue >= updateminimumConditonUse
      ) {
        toast.error("Promocode value is higher than min.order", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        CustomConsole(reqObj);
        if (updateValidity === "inHours") {
          dispatch(PutPromoCodeUpdateAction(promoId, reqObj));
          CustomConsole("updateValidity === 'inHours'");
          setPromoId(promoId);
          setPromoTableDialogFlag(false);
        } else if (updateValidity === "inDate" && !dateError) {
          CustomConsole("updateValidity === 'inDate' && !dateError");
          reqObj.valid_till = undefined;
          dispatch(PutPromoCodeUpdateAction(promoId, reqObj));
          CustomConsole(reqObj);
          setPromoId(promoId);
          setPromoTableDialogFlag(false);
        } else if (updateValidity === "inDate" && dateError) {
          toast.error("Choose valid date", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }

      // dispatch(PutPromoCodeUpdateAction(promoId, reqObj));
      // CustomConsole("Done!!!!!!!");
      // setPromoId(promoId);
      // setPromoTableDialogFlag(false);
      // CustomConsole(reqObj);
    } else if (!validationForDates()) {
      toast.error("Choose valid date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      let reqObj = {
        promo_label: updatePromoLabel,
        promo_disclaimer: updatepromoDisClaimer,
        promo_offer_type: updatepromOffertype,
        promo_offer_value: updatepromoOfferValue,
        promo_code_type: updatepromCodetype,
        valid_till:
          parseInt(updatevalidityInDays) * 24 +
            parseInt(updatevalidityInHours) || undefined,
        expiry_date: updatepromoExpiredToValue || undefined,
        start_date: updatepromoExpiredFromValue,
        max_usage_limit: updatemaxUsagelimit,
        min_charges_to_use: updateminimumConditonUse,
        is_expired: updateswitchpromoExpired,
      };
      CustomConsole(reqObj);
      toast.error("Fill all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDelete = () => {
    let reqObj = {
      is_expired: true,
    };
    CustomConsole(reqObj);
    dispatch(PutPromoCodeUpdateAction(promoId, reqObj));
    setPromoId(promoId);
    setUpdatepromoBtn(true);
    setConfirmDel(false);
    setPromoTableDialogFlag(false);
  };
  CustomConsole(promoId);

  const handleUpdateValidity = (event) => {
    setUpdateValidity(event.target.value);
  };

  const handleClose = () => {
    setPromoTableDialogFlag(false);
  };

  const handleChangePromoLabel = (event) => {
    setUpdatePromoLabel(event.target.value);
    setChangeInPromoLabel(true);
  };
  return (
    <Box>
      <Dialog
        open={promoTableDialogFlag}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { bottom: "30px" } }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Update Promo Code Here
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label="Promo Label"
              variant="outlined"
              disabled
              value={updatePromoLabel}
              onChange={handleChangePromoLabel}
            />
            <FormControl sx={{ minWidth: "210px", margin: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                Promo Code Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={updatepromCodetype}
                label="Promo Code Type"
                onChange={handlePromoCodetype}
                disabled
              >
                {promoCodeTypeappMeta.map((data) => {
                  return <MenuItem value={data.k}>{data.v}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ minWidth: "210px", margin: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                Promo Offer Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={updatepromOffertype}
                label="Promo Offer Type"
                disabled
                onChange={clickPromoOffertype}
              >
                {promoOffertypeappMea.map((data) => {
                  return <MenuItem value={data.k}>{data.v}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <TextField
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label="Minimum order Price"
              helperText="Consumer min. order price"
              variant="outlined"
              disabled
              value={updateminimumConditonUse}
              InputLabelProps={{ shrink: true }}
              onChange={handleUpdateminimumConditonUse}
            />
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label=" Promo Offer Value"
              disabled
              variant="outlined"
              value={updatepromoOfferValue}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleupdatepromoOfferValue}
            />
            {updatepromCodetype === 100 ? (
              ""
            ) : (
              <TextField
                sx={{ margin: "10px" }}
                id="outlined-basic"
                label="Max Usage Limit"
                variant="outlined"
                type="number"
                value={updatemaxUsagelimit}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleMaxUsagelimit}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Promo Validity Start Date"
                inputFormat="DD/MM/YYYY"
                // minDate={moment().subtract(0, 'days')}
                disablePast
                value={updatepromoExpiredFromValue}
                onChange={handleChangeUpdatePromoExpiredFrom}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: "10px" }}>
            <Box>
              <FormControl>
                <RadioGroup
                  value={updateValidity}
                  onChange={handleUpdateValidity}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Validity In Hours"
                    value="inHours"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Validity To Date "
                    value="inDate"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                margin: "10px",
                marginLeft: "80px",
                marginTop: "20px",
                alignContent: "end",
              }}
            >
              <FormControlLabel
                sx={{ margin: "10px" }}
                control={<Switch checked={updateswitchpromoExpired} />}
                label={updateswitchpromoExpired ? "Expired" : "unExpired"}
                onChange={handlePromoExpiredSwitch}
              />
            </Box>
          </Box>
          {updateValidity === "inHours" ? (
            <Box>
              <TextField
                sx={{ margin: "10px" }}
                id="outlined-basic"
                label=" Validity In Days"
                variant="outlined"
                disabled
                type="number"
                value={updatevalidityInDays}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handleUpdateValidityInDays}
              />
              <TextField
                sx={{ margin: "10px" }}
                id="outlined-basic"
                label=" Validity In Hours"
                disabled
                variant="outlined"
                type="number"
                value={updatevalidityInHours}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handleupdatevalidityInHours}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: {
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                justifyContent: "center",
                gap: "10px",
                maxWidth: "450px",
                marginLeft: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Promo Validity End Date"
                  inputFormat="DD/MM/YYYY"
                  // minDate={moment().subtract(0, 'days')}
                  value={updatepromoExpiredToValue}
                  disablePast
                  onChange={handleChangeUpdatePromoExpiredTo}
                  minDate={updatepromoExpiredFromValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={dateError}
                      helperText={"Select More Than One Day From Start Date"}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          )}

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              fullWidth
              sx={{ margin: "10px" }}
              id="outlined-basic"
              label=" Promo Disclaimer"
              multiline
              disabled
              rows={4}
              variant="outlined"
              value={updatepromoDisClaimer}
              onChange={(event) => setupdatepromoDisClaimer(event.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
          <Button variant="contained" color="error" onClick={clickClose}>
            close
          </Button>
          <Button variant="contained" color="success" onClick={handleUpdate}>
            Update
          </Button>
          {/* {!updateswitchpromoExpired ? ( */}
          <Button
            variant="contained"
            color="error"
            onClick={() => setConfirmDel(true)}
          >
            Delete
          </Button>
          {/* ) : null} */}
        </DialogActions>
        <Dialog
          open={confirmDel}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setConfirmDel(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete this promocode?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDel(false)}>Disagree</Button>
            <Button onClick={handleDelete}>Agree</Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </Box>
  );
}

export default PromoTableDialog;
