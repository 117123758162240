/**
 * @author Gowtham Prasath
 * @description Place Emppty Can orders page
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PlaceOrderEmptyCans } from "../../Pages/Redux/Actions/adminLineDetailsAction";
import { GetListofCrates } from "../../Pages//Redux/Actions/SKUProductDetailsAction";
import { InputAdornment } from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import CustomConsole from "../../CustomConsole";
import {
  EditEmptyCanOrdersSku,
  WfsEmptyCanOrdersSku,
} from "../../Pages/Redux/Actions/skuWfsDetailsAction";
export default function EmptyCanOrderEdit({ open, onClose, editDetails }) {
  const dispatch = useDispatch();
  const wfsLineDetails = useSelector((state) => state.adminWfsLineData);
  const [productName, setProductName] = React.useState("");
  const [productData, setProductDate] = React.useState({});
  const [crateName, setCrateName] = React.useState("");
  const [crateEditData, setCrateEditData] = React.useState({});
  const [returnOrderQty, setReturnOrderQty] = React.useState(0);
  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    order_qty: 1,
    return_qty: 1,
    crates: [],
  });
  const [crateRequriedChangeQty, setCrateRequriedChangeQty] = React.useState(0);
  const [crateReturnChangeQty, setCrateReturnChangeQty] = React.useState(0);
  const [editOrderObject, setEditOrderObject] = React.useState({
    update_data: [],
  });
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const crateDetails = skuContainerDetails.cateDetails;

  //useEffect for getting list available crates
  React.useEffect(() => {
    setOrderParams((prev) => ({
      ...prev,
      container_material: editDetails?.order_details?.container_material,
      container_capacity: editDetails?.order_details?.container_capacity,
      order_qty: editDetails?.order_details?.order_qty,
      return_qty: editDetails?.order_details?.return_qty,
      crates: editDetails?.order_details?.crates,
    }));
  }, [editDetails]);

  //function for handling selected product
  const handleSelectedProduct = (data) => {
    setProductName(data.target.value);
    const product = data.target.value;
    let duplicateElement = skuContainerDetails.getAvailableProducts.find(
      (data) => data.product_details.name === product
    );
    setProductDate(duplicateElement);
    if (Object.keys(duplicateElement).length > 0) {
      setOrderParams((prev) => ({
        ...prev,
        container_material: duplicateElement.product_details.material,
        container_capacity: duplicateElement.product_details.capacity,
        order_qty: 0,
        return_qty: 0,
      }));
    }
  };

  // Function for handling order qty minus action
  const handelMinusQuantity = (type) => {
    if (orderParams.order_qty > 1) {
      const newOrderQty = orderParams.order_qty - 1;
      let newReturnQty = 0;
      if (editDetails?.is_admin === true) {
        newReturnQty = orderParams.return_qty - 1;
      }
      if (
        editDetails?.is_admin === false &&
        newOrderQty < orderParams.return_qty
      ) {
        newReturnQty = orderParams.return_qty - 1;
      }
      setOrderParams((prev) => ({
        ...prev,
        order_qty: prev.order_qty - 1,
        return_qty: newReturnQty === 0 ? prev.return_qty : prev.return_qty - 1,
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === orderParams.container_material &&
          entry.container_capacity === orderParams.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    editDetails?.order_details?.order_qty - newOrderQty,
                  empty_qty:
                    newReturnQty === 0 &&
                    entry.empty_qty === editDetails?.order_details?.return_qty
                      ? 0
                      : newReturnQty > 0
                      ? editDetails?.order_details?.return_qty - newReturnQty
                      : entry.empty_qty,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: orderParams.container_material,
              container_capacity: orderParams.container_capacity,
              order_qty: editDetails?.order_details?.order_qty - newOrderQty,
              empty_qty:
                newReturnQty === 0 &&
                editDetails?.order_details?.return_qty ===
                  orderParams.return_qty
                  ? 0
                  : newReturnQty > 0
                  ? editDetails?.order_details?.return_qty - newReturnQty
                  : editDetails.order_details.return_qty,
            },
          ],
        }));
      }
    }
  };

  // Function for handling order qty plus action
  const handelAddQuantity = (type) => {
    if (editDetails?.order_details?.order_qty > orderParams.order_qty) {
      const newOrderQty = orderParams.order_qty + 1;
      let newReturnQty = 0;
      if (editDetails?.is_admin === true) {
        newReturnQty = orderParams.return_qty + 1;
      }
      setOrderParams((prev) => ({
        ...prev,
        order_qty: parseInt(prev.order_qty) + 1,
        return_qty: newReturnQty === 0 ? prev.return_qty : prev.return_qty + 1,
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === orderParams.container_material &&
          entry.container_capacity === orderParams.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    editDetails?.order_details?.order_qty - newOrderQty,
                  empty_qty:
                    newReturnQty === 0
                      ? entry.empty_qty
                      : editDetails?.order_details?.return_qty - newReturnQty,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: orderParams.container_material,
              container_capacity: orderParams.container_capacity,
              order_qty: editDetails?.order_details?.order_qty - newOrderQty,
              empty_qty:
                newReturnQty === 0 &&
                editDetails?.order_details?.order_qty === undefined
                  ? 0
                  : editDetails?.order_details?.return_qty - newReturnQty,
            },
          ],
        }));
      }
    }
  };
  React.useEffect(() => {
    CustomConsole(orderParams.order_qty);
    CustomConsole(orderParams.return_qty);
    CustomConsole(editDetails?.order_details?.order_qty);
    setEditOrderObject((prev) => ({
      ...prev,
      update_data: [
        // ...prev.update_data,
        {
          container_material: orderParams.container_material,
          container_capacity: orderParams.container_capacity,
          order_qty:
            editDetails?.order_details?.order_qty - orderParams.order_qty,
          empty_qty:
            editDetails?.order_details?.return_qty - orderParams.return_qty,
        },
      ],
    }));
    // const qtyValue = editDetails?.order_details?.crates.find(
    //   (crate) => crate.id === crateEditData.id
    // );
    // CustomConsole(qtyValue);
    // setEditOrderObject((prev) => ({
    //   ...prev,
    //   update_data: [
    //     // ...prev.update_data,
    //     {
    //       container_material: crateEditData.container_material,
    //       container_capacity: crateEditData.container_capacity,
    //       order_qty: qtyValue?.order_qty - crateRequriedChangeQty,
    //       empty_qty: qtyValue?.return_qty - crateReturnChangeQty,
    //     },
    //   ],
    // }));
  }, [orderParams.return_qty, orderParams.order_qty]);

  React.useEffect(() => {
    CustomConsole(crateRequriedChangeQty);
    CustomConsole(crateReturnChangeQty);
    const qtyValue = editDetails?.order_details?.crates.find(
      (crate) => crate.id === crateEditData.id
    );
    CustomConsole(qtyValue?.order_qty);
    CustomConsole(qtyValue?.return_qty);
    setEditOrderObject((prev) => ({
      ...prev,
      update_data: [
        // ...prev.update_data,
        {
          container_material: crateEditData.container_material,
          container_capacity: crateEditData.container_capacity,
          order_qty: qtyValue?.order_qty - orderParams.order_qty,
          empty_qty: qtyValue?.return_qty - orderParams.return_qty,
        },
      ],
    }));
  }, [crateEditData]);

  // Function for handling order qty plus action
  const handelQuantityRequriedChange = (event, label, data) => {
    CustomConsole(label);
    CustomConsole(data);
    // Extract the input value as a number
    if (
      /^(?!0\d*$)\d*\.?\d*$/.test(event.target.value) &&
      event.target.value <= editDetails?.order_details?.order_qty
    ) {
      // const inputValue = event.target.value;
      // const numericValue = /^(?!0\d*$)\d*\.?\d*$/.test(inputValue);
      // const numericValue = inputValue.replace(/\D/g, "");
      // const numericValue = inputValue.replace(`^(?!0\d*$)\d*\.?\d*$`);
      // const numericValue = inputValue.replace(/[^0-9]/g, "");
      // event.target.value = numericValue;
      switch (label) {
        case "Requried":
          setOrderParams((prev) => ({
            ...prev,
            order_qty: event.target.value,
          }));
          setOrderParams((prev) => ({
            ...prev,
            return_qty: event.target.value,
          }));
          break;
        case "Empty Can":
          setOrderParams((prev) => ({
            ...prev,
            return_qty: event.target.value,
          }));
          setOrderParams((prev) => ({
            ...prev,
            order_qty: event.target.value,
          }));
          break;
        default:
          break;
      }
    }
    // if (
    //   orderParams.order_qty >= 0 &&
    //   event.target.value >= 0 &&
    //   event.target.value <= editDetails?.order_details?.order_qty
    // ) {
    //   setOrderParams((prev) => ({
    //     ...prev,
    //     order_qty: event.target.value,
    //   }));
    // } else {
    //   toast.error("Won't Allow More Than Order Quantity", {
    //     position: "bottom-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }
    // if (orderParams.order_qty >= 0) {
    //   setOrderParams((prev) => ({
    //     ...prev,
    //     return_qty: event.target.value,
    //   }));
    // }
  };

  // Function for handling return qty change
  const handelReturnQty = (event) => {
    // Extract the input value as a number
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    if (
      event.target.value >= 0 &&
      event.target.value <=
        editDetails?.order_details?.return_qty - orderParams.return_qty
    ) {
      setOrderParams((prev) => ({
        ...prev,
        return_qty: event.target.value,
      }));
      setReturnOrderQty(event.target.value);
    } else {
      toast.error("Won't Allow More Than Empty Quantity", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    // setEditOrderObject((prev) => ({
    //   ...prev,
    //   update_data: [
    //     ...prev.update_data,
    //     {
    //       container_material: orderParams.container_material,
    //       container_capacity: orderParams.container_capacity,
    //       order_qty:
    //         event.target.value === 0
    //           ? editDetails?.order_qty
    //           : editDetails?.order_details?.order_qty - event.target.value,
    //       empty_qty:
    //         editDetails?.order_details?.return_qty - event.target.value,
    //     },
    //   ],
    // }));
  };

  // Function for handling order qty minus action for return quantity
  const handelMinusReturnQuantity = (type) => {
    if (orderParams.return_qty >= 1) {
      const newReturnQty = orderParams.return_qty - 1;
      let newOrderQty = 0;
      CustomConsole(newOrderQty);
      if (editDetails?.is_admin === true || editDetails?.is_admin === false) {
        newOrderQty = orderParams.order_qty - 1;
      }
      setOrderParams((prev) => ({
        ...prev,
        return_qty: prev.return_qty - 1,
        order_qty:
          editDetails?.order_details?.order_qty === 0
            ? prev.order_qty
            : prev.order_qty - 1,
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === orderParams.container_material &&
          entry.container_capacity === orderParams.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    newOrderQty === 0 &&
                    entry.order_qty === editDetails?.order_details?.order_qty
                      ? 0
                      : newOrderQty > 0
                      ? editDetails?.order_details?.order_qty - newOrderQty
                      : entry.order_qty,
                  empty_qty:
                    editDetails?.order_details?.return_qty - newReturnQty,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: orderParams.container_material,
              container_capacity: orderParams.container_capacity,
              order_qty:
                editDetails?.order_details?.order_qty === 0
                  ? 0
                  : newOrderQty > 0
                  ? editDetails?.order_details?.order_qty - newOrderQty
                  : editDetails?.order_details?.order_qty,
              empty_qty: editDetails?.order_details?.return_qty - newReturnQty,
            },
          ],
        }));
      }
    }
  };

  // Function for handling order qty plus action for return quantity
  const handelAddReturnQuantity = (type) => {
    if (editDetails?.order_details?.return_qty > orderParams.return_qty) {
      const newReturnQty = orderParams.return_qty + 1;
      let newOrderQty = 0;
      if (editDetails?.is_admin === true) {
        newOrderQty = orderParams.order_qty + 1;
      }
      setOrderParams((prev) => ({
        ...prev,
        return_qty: parseInt(prev.return_qty) + 1,
        order_qty: newOrderQty === 0 ? prev.order_qty : prev.order_qty + 1,
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === orderParams.container_material &&
          entry.container_capacity === orderParams.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    newOrderQty === 0
                      ? entry.order_qty
                      : editDetails?.order_details?.order_qty - newOrderQty,
                  empty_qty:
                    editDetails?.order_details?.return_qty - newReturnQty,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: orderParams.container_material,
              container_capacity: orderParams.container_capacity,
              order_qty:
                newOrderQty === 0
                  ? editDetails?.order_qty
                  : editDetails?.order_details?.order_qty - newOrderQty,
              empty_qty: editDetails?.order_details?.return_qty - newReturnQty,
            },
          ],
        }));
      }
    }
  };
  //function for deleting product detiails
  const deleteProductDetails = (event) => {
    setOrderParams((prev) => ({
      ...prev,
      container_material: "",
      container_capacity: "",
    }));
  };

  //function for handling crate adding
  const handelSelectedCrate = () => {
    let crateData = crateDetails.find((data) => data.name === crateName);
    let duplicateElement = orderParams.crates.filter(
      (data) => data.name === crateName
    );
    CustomConsole(crateData);
    if (Object.keys(crateData).length > 0 && duplicateElement.length === 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: [
          ...prev.crates,
          { name: crateData.name, order_qty: 0, capacity: crateData.capacity },
        ],
      }));
    }
  };

  //function for adding crate name
  const handelChangeSelectedCrate = (e) => {
    setCrateName(e.target.value);
  };

  // function for handling crate adding
  const handelAddCrateQuantity = (data) => {
    let newReturnValue = 0;
    let newOrderValue = 0;
    const qtyValue = editDetails?.order_details?.crates.find(
      (crate) => crate.id === data.id
    );

    if (
      (editDetails?.is_admin === true &&
        data.return_qty < qtyValue.return_qty) ||
      qtyValue.order_qty > data.order_qty
    ) {
      const addQtyValue = orderParams?.crates.find(
        (crate) => crate.id === data.id
      );

      // Set the new value based on the user type
      if (editDetails?.is_admin === true) {
        newReturnValue = addQtyValue.return_qty + 1;
        newOrderValue = addQtyValue.return_qty + 1;
      } else {
        newOrderValue = addQtyValue.order_qty + 1;
        newReturnValue =
          addQtyValue.return_qty === 0 ? 0 : addQtyValue.return_qty + 1;
      }

      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) =>
          crate.id === data.id
            ? {
                ...crate,
                order_qty:
                  editDetails?.is_admin === true
                    ? parseInt(newReturnValue)
                    : parseInt(newOrderValue),
                return_qty: qtyValue.return_qty === 0 ? 0 : newReturnValue,
              }
            : crate
        ),
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === data.container_material &&
          entry.container_capacity === data.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    editDetails?.is_admin === false
                      ? qtyValue.order_qty - newOrderValue
                      : qtyValue.return_qty - newReturnValue,
                  empty_qty: qtyValue.return_qty - newReturnValue,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: data.container_material,
              container_capacity: data.container_capacity,
              order_qty:
                editDetails?.is_admin === false
                  ? qtyValue.order_qty - newOrderValue
                  : qtyValue.return_qty - newReturnValue,
              empty_qty: qtyValue.return_qty - newReturnValue,
            },
          ],
        }));
      }
    }
  };

  // function for handling crate subtraction
  const handelMinusCrateQuantity = (data) => {
    let newReturnValue = 0;
    let newOrderValue = 0;
    //this will contain intial quantity
    const qtyValue = editDetails?.order_details?.crates.find(
      (crate) => crate.id === data.id
    );
    CustomConsole(qtyValue.order_qty);
    CustomConsole(data.order_qty);
    if (
      (editDetails?.is_admin === true && data.return_qty > 0) ||
      data.order_qty >= 1
    ) {
      //current changed value
      const addQtyValue = orderParams?.crates.find(
        (crate) => crate.id === data.id
      );

      // Set the new value based on the user type
      if (editDetails?.is_admin === true) {
        newReturnValue = addQtyValue.return_qty - 1;
      } else {
        newOrderValue = addQtyValue.order_qty - 1;
        newReturnValue =
          addQtyValue.return_qty === 0 ? 0 : addQtyValue.return_qty - 1;
      }

      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) =>
          crate.id === data.id
            ? {
                ...crate,
                order_qty:
                  editDetails?.is_admin === true
                    ? newReturnValue
                    : newOrderValue,
                return_qty: qtyValue.return_qty === 0 ? 0 : newReturnValue,
              }
            : crate
        ),
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === data.container_material &&
          entry.container_capacity === data.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    editDetails?.is_admin === false
                      ? qtyValue.order_qty - newOrderValue
                      : qtyValue.return_qty - newReturnValue,
                  empty_qty: qtyValue.return_qty - newReturnValue,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: data.container_material,
              container_capacity: data.container_capacity,
              order_qty:
                editDetails?.is_admin === false
                  ? qtyValue.order_qty - newOrderValue
                  : qtyValue.return_qty - newReturnValue,
              empty_qty: qtyValue.return_qty - newReturnValue,
            },
          ],
        }));
      }
    }
  };

  //function for handling crate adding
  const handelCrateQuantityChange = (data, event, label) => {
    CustomConsole(data);
    CustomConsole(label);
    setCrateEditData(data);
    // Extract the input value as a number
    // const inputValue = parseInt(e.target.value, 10);
    const qtyValue = editDetails?.order_details?.crates.find(
      (crate) => crate.id === data.id
    );
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    switch (label) {
      case "Requried":
        setOrderParams((prev) => ({
          ...prev,
          crates: prev.crates.map((crate) => {
            if (crate.id === data.id) {
              return { ...crate, order_qty: event.target.value };
            }
            return crate;
          }),
        }));
        setCrateRequriedChangeQty(event.target.value);
        break;
      case "Return":
        setOrderParams((prev) => ({
          ...prev,
          crates: prev.crates.map((crate) =>
            crate.id === data.id
              ? {
                  ...crate,
                  order_qty:
                    editDetails?.is_admin === true
                      ? event.target.value
                      : event.target.value,
                  return_qty:
                    qtyValue.return_qty === 0 ? 0 : event.target.value,
                }
              : crate
          ),
        }));
        setCrateReturnChangeQty(event.target.value);
        break;
      default:
        break;
    }
    // // if (event.target.value >= 0) {
    // setOrderParams((prev) => ({
    //   ...prev,
    //   crates: prev.crates.map((crate) => {
    //     if (crate.id === data.id) {
    //       return { ...crate, order_qty: event.target.value };
    //     }
    //     return crate;
    //   }),
    // }));
    // // }
    // setOrderParams((prev) => ({
    //   ...prev,
    //   crates: prev.crates.map((crate) =>
    //     crate.id === data.id
    //       ? {
    //           ...crate,
    //           order_qty:
    //             editDetails?.is_admin === true
    //               ? event.target.value
    //               : event.target.value,
    //           return_qty: qtyValue.return_qty === 0 ? 0 : event.target.value,
    //         }
    //       : crate
    //   ),
    // }));

    // setEditOrderObject((prev) => ({
    //   ...prev,
    //   update_data: [
    //     // ...prev.update_data,
    //     {
    //       container_material: data.container_material,
    //       container_capacity: data.container_capacity,
    //       order_qty: qtyValue.order_qty - data.order_qty,
    //       empty_qty: qtyValue.return_qty - data.return_qty,
    //     },
    //   ],
    // }));
  };

  //function for handling crate adding
  const handelAddReturnCrateQuantity = (data) => {
    let newReturnValue = 0;
    let newOrderValue = 0;
    const qtyValue = editDetails?.order_details?.crates.find(
      (crate) => crate.id === data.id
    );
    if (
      (editDetails?.is_admin === true &&
        data.return_qty < qtyValue.return_qty) ||
      qtyValue.return_qty > data.return_qty
    ) {
      const addQtyValue = orderParams?.crates.find(
        (crate) => crate.id === data.id
      );

      // Set the new value based on the user type
      if (editDetails?.is_admin === true) {
        newReturnValue = addQtyValue.return_qty + 1;
        newOrderValue = addQtyValue.return_qty + 1;
      } else {
        newOrderValue = addQtyValue.order_qty + 1;
        newReturnValue =
          addQtyValue.return_qty === 0 ? 0 : addQtyValue.return_qty + 1;
      }

      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) =>
          crate.id === data.id
            ? {
                ...crate,
                order_qty:
                  editDetails?.is_admin === true
                    ? parseInt(newReturnValue)
                    : parseInt(newOrderValue),
                return_qty: qtyValue.return_qty === 0 ? 0 : newReturnValue,
              }
            : crate
        ),
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === data.container_material &&
          entry.container_capacity === data.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    editDetails?.is_admin === false
                      ? qtyValue.order_qty - newOrderValue
                      : qtyValue.return_qty - newReturnValue,
                  empty_qty: qtyValue.return_qty - newReturnValue,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: data.container_material,
              container_capacity: data.container_capacity,
              order_qty:
                editDetails?.is_admin === false
                  ? qtyValue.order_qty - newOrderValue
                  : qtyValue.return_qty - newReturnValue,
              empty_qty: qtyValue.return_qty - newReturnValue,
            },
          ],
        }));
      }
    }
  };
  //function for handling crate adding
  const handelMinusReturnCrateQuantity = (data) => {
    let newReturnQty = 0;
    let newOrderValue = 0;
    const qtyValue = editDetails?.order_details?.crates.find(
      (crate) => crate.id === data.id
    );

    if (
      (editDetails?.is_admin === true && data.return_qty >= 1) ||
      data.return_qty > 0
    ) {
      const addQtyValue = orderParams?.crates.find(
        (crate) => crate.id === data.id
      );

      // Set the new value based on the user type
      if (editDetails?.is_admin === true) {
        newReturnQty = addQtyValue.return_qty - 1;
      } else {
        newOrderValue =
          addQtyValue.order_qty === 0 ? 0 : addQtyValue.order_qty - 1;
        newReturnQty = addQtyValue.return_qty - 1;
      }

      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) =>
          crate.id === data.id
            ? {
                ...crate,
                order_qty:
                  editDetails?.is_admin === true ? newReturnQty : newOrderValue,
                return_qty: newReturnQty,
              }
            : crate
        ),
      }));

      // Check if the entry already exists in update_data
      const existingEntryIndex = editOrderObject.update_data.findIndex(
        (entry) =>
          entry.container_material === data.container_material &&
          entry.container_capacity === data.container_capacity
      );

      if (existingEntryIndex !== -1) {
        // Entry exists, update the existing entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: prev.update_data.map((entry, index) =>
            index === existingEntryIndex
              ? {
                  ...entry,
                  order_qty:
                    editDetails?.is_admin === false
                      ? qtyValue.order_qty - newOrderValue
                      : qtyValue.return_qty - newReturnQty,
                  empty_qty: qtyValue.return_qty - newReturnQty,
                }
              : entry
          ),
        }));
      } else {
        // Entry doesn't exist, add a new entry
        setEditOrderObject((prev) => ({
          ...prev,
          update_data: [
            ...prev.update_data,
            {
              container_material: data.container_material,
              container_capacity: data.container_capacity,
              order_qty:
                editDetails?.is_admin === false
                  ? qtyValue.order_qty - newOrderValue
                  : qtyValue.return_qty - newReturnQty,
              empty_qty: qtyValue.return_qty - newReturnQty,
            },
          ],
        }));
      }
    }
  };

  //function for handling crate adding
  const handelCrateReturnQuantity = (data, event) => {
    CustomConsole(
      "----------------handelCrateReturnQuantity-------------------"
    );
    CustomConsole(data);
    setCrateEditData(data);
    // Extract the input value as a number
    // const inputValue = parseInt(e.target.value, 10);
    const qtyValue = editDetails?.order_details?.crates.find(
      (crate) => crate.id === data.id
    );
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    if (event.target.value >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.id === data.id) {
            return { ...crate, return_qty: event.target.value };
          }
          return crate;
        }),
      }));
    }
    setEditOrderObject((prev) => ({
      ...prev,
      update_data: [
        // ...prev.update_data,
        {
          container_material: data.container_material,
          container_capacity: data.container_capacity,
          order_qty: qtyValue.order_qty - event.target.value,
          empty_qty: qtyValue.return_qty - event.target.value,
        },
      ],
    }));
  };

  //const delete crate details
  const deleteCrateDetails = (index) => {
    setOrderParams((prev) => {
      const newCrates = [...prev.crates];
      newCrates.splice(index, 1);
      return { ...prev, crates: newCrates };
    });
  };

  //function for selecting address id
  const handleSelectedAddress = (e) => {
    setOrderParams((prev) => ({ ...prev, address_id: e.target.value }));
  };

  //function for handling order function
  const handelSubmitOrder = () => {
    // const qtyValue = editDetails?.order_details?.crates.find(
    //   (crate) => crate.id === crateEditData.id
    // );
    // const editOrderObject = [
    //   {
    //     container_material: orderParams.container_material,
    //     container_capacity: orderParams.container_capacity,
    //     order_qty:
    //       editDetails?.order_details?.order_qty - orderParams.order_qty,
    //     empty_qty:
    //       editDetails?.order_details?.return_qty - orderParams.return_qty,
    //   } || {
    //     container_material: qtyValue?.container_material,
    //     container_capacity: qtyValue?.container_capacity,
    //     order_qty: qtyValue?.order_qty - orderParams.order_qty,
    //     empty_qty: qtyValue?.return_qty - orderParams.return_qty,
    //   },
    // ];
    dispatch(EditEmptyCanOrdersSku(editDetails?.ec_order_id, editOrderObject));
    onClose();
  };

  CustomConsole(editOrderObject);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignContent="center"
            >
              <Grid item xs={12}>
                <Typography
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  Edit Product Details
                </Typography>

                {orderParams.container_capacity !== "" ? (
                  <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>
                          {orderParams.container_capacity}/
                          {orderParams.container_material}
                        </Typography>
                        {/* <DeleteIcon
                          color="error"
                          onClick={() => deleteProductDetails()}
                        /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: { xs: "column", md: "row" },
                          marginTop: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                            gap: "20px",
                            marginTop: "1rem",
                          }}
                        >
                          <Typography>Required&nbsp;&nbsp;</Typography>
                          <OrderButtonSku
                            handelAddQuantity={() =>
                              handelAddQuantity(orderParams)
                            }
                            handelMinusQuantity={() =>
                              handelMinusQuantity(productData)
                            }
                            handelQuantityChange={(event) =>
                              handelQuantityRequriedChange(
                                event,
                                // productData,
                                "Requried",
                                orderParams.order_qty
                              )
                            }
                            inputValue={orderParams.order_qty}
                            disabled={true}
                          />
                          {/* <TextField
                            value={orderParams.order_qty}
                            label="Requried"
                            // onChange={handelQuantityRequriedChange}
                            InputProps={{
                              maxLength: 10, // Optional: You can set the maximum length if needed
                              onInput: (event) =>
                                handelQuantityRequriedChange(event, "Requried"), // Attach the input event handler
                            }}
                          /> */}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            marginTop: "1rem",
                            gap: "15px",
                          }}
                        >
                          <Typography>Empty Can&nbsp;&nbsp;</Typography>
                          <OrderButtonSku
                            handelAddQuantity={handelAddReturnQuantity}
                            handelMinusQuantity={handelMinusReturnQuantity}
                            handelQuantityChange={(event) =>
                              handelQuantityRequriedChange(event, "Empty Can")
                            }
                            inputValue={orderParams.return_qty}
                            // disabled={true}
                          />
                          {/* <TextField
                            value={orderParams.return_qty}
                            label="Empty Can"
                            // onChange={handelReturnQty}
                            InputProps={{
                              maxLength: 10, // Optional: You can set the maximum length if needed
                              onInput: (event) =>
                                handelQuantityRequriedChange(
                                  event,
                                  "Empty Can"
                                ), // Attach the input event handler
                            }}
                          /> */}
                        </Box>
                      </Box>
                    </CardContent>
                    <Box
                      sx={{
                        width: "100%",
                        bgcolor: "#C9EEFF",
                        height: "30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ marginLeft: "1rem" }}>
                        Order Qty:{orderParams.order_qty}
                      </Typography>
                      <Typography sx={{ marginRight: "1rem" }}>
                        Empty Qty:{orderParams.return_qty}
                      </Typography>
                    </Box>
                  </Card>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  Edit Crate Details
                </Typography>
                {orderParams?.crates?.length > 0 ? (
                  <>
                    {orderParams.crates.map((data, index) => (
                      <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>
                              {data.container_capacity}/
                              {data.container_material}
                            </Typography>
                            {/* <DeleteIcon
                              color="error"
                              onClick={() => deleteCrateDetails(index)}
                            /> */}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              flexDirection: "column",
                              gap: "20px",
                              marginTop: "1rem",
                            }}
                          >
                            {editDetails?.is_admin === false && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  marginTop: "1rem",
                                }}
                              >
                                <Typography>Required&nbsp;&nbsp;</Typography>
                                <OrderButtonSku
                                  handelAddQuantity={() =>
                                    handelAddCrateQuantity(data)
                                  }
                                  handelMinusQuantity={() =>
                                    handelMinusCrateQuantity(data)
                                  }
                                  handelQuantityChange={(event) =>
                                    handelCrateQuantityChange(
                                      data,
                                      event,
                                      "Requried"
                                    )
                                  }
                                  inputValue={
                                    editDetails?.is_admin === true
                                      ? data.return_qty
                                      : data.order_qty
                                  }
                                  // disabled={false}
                                />
                                {/* <TextField
                                  value={data.order_qty}
                                  label="Requried"
                                  // onChange={handelReturnQty}
                                  InputProps={{
                                    maxLength: 10, // Optional: You can set the maximum length if needed
                                    onInput: handelCrateQuantityChange(data), // Attach the input event handler
                                  }}
                                /> */}
                              </Box>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "column",
                                gap: "20px",
                                marginTop: "1rem",
                              }}
                            >
                              <Typography>Return&nbsp;&nbsp;</Typography>
                              <OrderButtonSku
                                handelAddQuantity={() =>
                                  handelAddReturnCrateQuantity(data)
                                }
                                handelMinusQuantity={() =>
                                  handelMinusReturnCrateQuantity(data)
                                }
                                handelQuantityChange={(event) =>
                                  handelCrateQuantityChange(
                                    data,
                                    event,
                                    "Return"
                                  )
                                }
                                inputValue={data.return_qty}
                                // disabled={false}
                              />
                              {/* <TextField
                                value={data.return_qty}
                                label="Return"
                                // onChange={handelReturnQty}
                                InputProps={{
                                  maxLength: 10, // Optional: You can set the maximum length if needed
                                  onInput: (event) =>
                                    handelCrateQuantityChange(data, event), // Attach the input event handler
                                }}
                              /> */}
                            </Box>
                          </Box>
                        </CardContent>
                        <Box
                          sx={{
                            width: "100%",
                            bgcolor: "#C9EEFF",
                            height: "30px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {editDetails?.is_admin === false && (
                            <Typography sx={{ marginLeft: "1rem" }}>
                              Order Qty:{data.order_qty}
                            </Typography>
                          )}
                          <Typography sx={{ marginRight: "1rem" }}>
                            Return Qty:{data.return_qty}
                          </Typography>
                        </Box>
                      </Card>
                    ))}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Close
        </Button>
        <Button size="small" variant="contained" onClick={handelSubmitOrder}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
    // </Paper>
  );
}
