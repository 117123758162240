/**
 * @author vinay kumar
 * @description Order Search Card
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.49
 * @Date 11-09-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import * as IconName from "react-icons/fc";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CustomConsole from "../../CustomConsole";
import TabPanel from "@mui/lab/TabPanel";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { ORDER_TYPE } from "../../Components/AppMeta/appMetaConfig";

import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { GetSearchOrderDetails } from "../Redux/Actions/ordersAdminAction";
import { Grid, Paper, Stack, Switch } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { RegectReasonDetails } from "../Redux/Actions/ordersAdminAction";
import { ordersStatus } from "../../Components/AppMeta/appMetaConfig";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import ViewOrderDetailsCard from "./ViewOrderDetailsCard";
import AdminWithdrawal from "../AdminWithdrawalHistory/AdminWithdrawal";
import EmptyCanOrder from "../AdminEmptyCanOrders/EmptyCanOrder";
import AdminReplacementOrder from "../AdminReplacementOrder/AdminReplacementOrder";
import PostpaidOrders from "../AdminPostpaid/PostpaidOrders";
import { toast } from "react-toastify";
import { styles } from "./styles";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import TransferCanOrderList from "./TransferCanOrderList";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  DialogActions,
} from "@mui/material";
import RegularOrders from "./RegularOrders";
import { Padding } from "@mui/icons-material";
import AdminRecycleOrdersMain from "../AdminRecycleCanOrders/AdminRecycleOrdersMain";
import BuyBackOrderMain from "../BuyBackProducts/BuyBackProductsMain";
import CreditNoteDetailsTabel from "../../Components/Tables/CreditNoteDetailsTabel";
import CreditNoteDetailsMain from "../BuyBackProducts/CreditNotesMain";

function OrderSearchCard({
  setSearchOrderbtn,
  setAllOrders,
  pageNumber,
  setdisableBtn,
  setPageNumber,
  orderStatusDelivery,
  sortOrder,
  distNum,
  setSearchOrderFlag,
  searchOrderFlag,
  setDistNum,
  setSortOrder,
  setOrderStatus,
  setDeliveryStatus,
  deliveryStatusVal,
  setOpenDialog,
  setOpenOrdersCard,
  setIsEditOrderDetailsDialog,
  orderDetails,
  setOrderDetails,
  openDialog,
  searchBased,
  setSearchBased,
  setOrderType,
  orderType,
  tableView,
  setTableView,
  setOpenSingleOrder,
  tabvalue,
  setTabvalue,
  setTempReqObj,
  pageSize,
  paginationModel,
  setOpenSingleOrderDetails,
}) {
  //   const [tabvalue, setTabvalue] = React.useState("Orders");

  const handleChangeTabValue = (event, newValue) => {
    setTabvalue(newValue);
    switch (newValue) {
      case "Orders":
        setOpenOrdersCard(true);
        setOpenSingleOrder(false);
      default:
        setOpenSingleOrder(false);
        break;
    }
  };
  const [adminRole, setAdminRoleId] = React.useState("");
  const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const dispatch = useDispatch();
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumberFromOrders = searchParams.get("phoneNumber");

  React.useEffect(() => {
    CustomConsole(phoneNumberFromOrders);
    setEmailPhone(phoneNumberFromOrders);
    if (phoneNumberFromOrders !== null) {
      CustomConsole("-----------phoneNumberFromOrders--------------");
      // handleSearchOrder();

      //     let reqObj = {
      //   email_phone: phoneNumberFromOrders,
      //   from_date: fromValue,
      //   to_date: toValue,
      //   order_status: "",
      //   delivery_status: "",
      //   sortorder: sortOrder ? sortOrder : "ASC",
      //   orderType: "",
      //   pageNum: 0,
      //   isBooking: false,
      //   pincode: "",
      //   page_size: pageSize,
      // };
      // dispatch(GetSearchOrderDetails(reqObj));
    } else {
      setSearchOrderFlag(false);
    }
  }, [phoneNumberFromOrders]);
  // const currentDate = new Date();
  // CustomConsole(currentDate);
  const [fromValue, setFromValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [toValue, setToValue] = React.useState(moment().format("YYYY-MM-DD"));
  const [emailPhone, setEmailPhone] = React.useState("");
  // const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));

  const [pincodeFil, setPincodeFil] = React.useState("");
  //state for the value of dates
  const [fromDateValue, setFromDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [toDateValue, setToDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const today = moment().add(7, "days").format("YYYY-MM-DD");

  CustomConsole(moment().format("YYYY/MM/DD"));
  const handleEmailPhoneChange = (event) => {
    CustomConsole(event.target.value);
    setEmailPhone(event.target.value);
  };
  const handleChangeFrom = (e) => {
    setFromDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    CustomConsole(momentDate);
    setFromValue(momentDate);
  };

  const handleChangeTo = (e) => {
    setToDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    CustomConsole(momentDate);
    setToValue(momentDate);
  };

  const toogleSwitch = (e) => {
    CustomConsole(e.target.value);
    setTabvalue(e.target.value);
    switch (e.target.value) {
      case "Orders":
        setOpenOrdersCard(true);
        setOpenSingleOrder(false);
        break;
      default:
        setOpenSingleOrder(false);
        break;
    }
  };

  const handleSearchOrder = () => {
    // setDistNum("");
    // setSortOrder("");
    // setOrderStatus(deliveryStatus.DELIVERY_STATUS_SCHEDULED);
    // setDeliveryStatus(deliveryStatus.DELIVERY_STATUS_SCHEDULED);
    setSearchOrderFlag(true);

    setPageNumber(1);
  };

  // React.useEffect(() => {
  //   let reqObj = {
  //     email_phone: "",
  //     from_date: fromValue,
  //     to_date: toValue,
  //     order_status: "",
  //     delivery_status: "",
  //     orderType: "",
  //     pageNum: 0,
  //     isBooking: false,
  //     pincode: "",
  //     page_size: pageSize,
  //   };
  //   let reqObjTwo = {
  //     sort_order: sortOrder ? sortOrder : undefined,
  //   };
  //   CustomConsole("-----search filter 2----");
  //   dispatch(GetSearchOrderDetails(reqObj, reqObjTwo));
  //   setTempReqObj(reqObj);
  // }, []);

  React.useEffect(() => {
    CustomConsole(deliveryStatusVal);
    CustomConsole(orderStatusDelivery);
    let reqObj = {
      email_phone: emailPhone || phoneNumberFromOrders || "",
      from_date: fromValue,
      to_date: toValue,
      order_status: orderStatusDelivery ? orderStatusDelivery : "",
      delivery_status: deliveryStatusVal ? deliveryStatusVal : "",
      sortorder: sortOrder ? sortOrder : undefined,
      orderType: orderType,
      isBooking: searchBased,
      pincode: pincodeFil,
      page_size: pageSize,
      pageNum: pageNumber - 1,
    };
    let reqObjTwo = {
      sort_order: sortOrder ? sortOrder : undefined,
    };
    if (
      searchOrderFlag === true &&
      reqObj.from_date !== "Invalid date" &&
      reqObj.to_date !== "Invalid date"
    ) {
      CustomConsole(reqObj);
      CustomConsole("-----search filter 1----");
      // dispatch(GetSearchOrderDetails(reqObj, reqObjTwo));
      setTempReqObj(reqObj);
      setSearchOrderFlag(false);
      setSearchOrderbtn(true);
    } else if (
      searchOrderFlag &&
      (reqObj.from_date === "Invalid date" || reqObj.to_date === "Invalid date")
    ) {
      toast.error("Please Choose The Date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSearchOrderFlag(false);
    }
  }, [searchOrderFlag, pageNumber, pageSize]);

  React.useEffect(() => {
    CustomConsole(allOrderData);
    setAllOrders(allOrderData.allOrderDetails);
    if (
      allOrderData.allOrderDetails &&
      allOrderData.allOrderDetails.length === 0
    ) {
      setdisableBtn(true);
    }
    if (
      allOrderData.allOrderDetails &&
      allOrderData.allOrderDetails.length > 0
    ) {
      setdisableBtn(false);
    }
  }, [allOrderData]);

  React.useEffect(() => {
    // dispatch(RegectReasonDetails());
  }, []);

  // const openDialogFun = () => {
  //   setOpenDialog(true);
  //   CustomConsole("Changed open dialog");
  // };

  const closeDialogFun = () => {
    setOpenDialog(false);
  };

  const clearAll = () => {
    setPageNumber(1);
    setOrderType("");
    setOrderStatus("");
    setSortOrder("");
    setSearchOrderFlag(true);
    setPincodeFil("");
    setOpenDialog(false);
  };

  const applyingFilter = () => {
    setPageNumber(1);
    setSearchOrderFlag(true);
    setOpenDialog(false);
  };

  function handleKeyDownNum(event) {
    // Allow only numeric keys and some special keys
    const allowedKeys = ["Backspace", "Delete", "Tab", "Escape", "Enter"];
    if (!allowedKeys.includes(event.key) && isNaN(parseInt(event.key))) {
      event.preventDefault();
    }
  }

  return (
    <Material.Box sx={{ maxWidth: "100%" }} p={0}>
      {/* <Box sx={{ width: "100%", typography: "body1" }}> */}
      <Grid container spacing={2} paddingRight={0} marginRight={0}>
        <Grid item md={12} xs={12} sm={12}>
          <TabContext
            value={tabvalue}
            variant="scrollable"
            scrollButtons={false}
          >
            <TabList
              onChange={handleChangeTabValue}
              sx={{ bgcolor: "#AED6F1" }}
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab label="Regular" value="Orders" />
              <Tab label="WFS" value="EmptyCanOrders" />
              <Tab label="Return" value="ReturnCanOrders" />
              <Tab label="Replacement" value="ReplacementCanOrders" />
              {/* <Tab label="PostPaid Orders" value="PostPaidOrders" /> */}
              <Tab label="Transfer Cans" value="TransferOrderList" />
              <Tab label="Recycle Cans" value="RecycleOrders" />
              <Tab label="Buy Back Orders" value="Buybackorders" />
              <Tab label="Credit Note Details" value="CreditNoteDetails" />
            </TabList>

            <TabPanel value="Orders" sx={{ padding: 0 }}>
              <RegularOrders
                key={tabvalue}
                setIsEditOrderDetailsDialog={setIsEditOrderDetailsDialog}
              />
              {/* <Box
                sx={{
                  display: "flex",
                  // gap: "10px",
                  flexWrap: "wrap",
                  padding: "10px",
                  justifyContent: "space-around",
                }}
              >
                <Box sx={{ marginTop: "10px" }}>
                  <FormControl>

                    <RadioGroup
                      row
                      value={searchBased}
                      onChange={(e) => {
                        setSearchBased(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Booking"
                      />

                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Delivery"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <label
                    htmlFor="fromDay"
                    style={{ fontSize: "13px", fontWeight: "600" }}
                  >
                    From:
                  </label>

                  <input
                    type="date"
                    id="fromDay"
                    name="from"
                    value={fromDateValue}
                    onChange={handleChangeFrom}
                    style={{
                      backgroundColor: "#AED6F1",

                      border: 0,

                      padding: "10px",

                      fontFamily: "serif",

                      fontSize: "16px",
                      fontWeight: "500",
                      borderRadius: "10px",
                      // marginLeft: "5px",
                    }}
                    max={today}
                  />
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <label
                    htmlFor="toDay"
                    style={{ fontSize: "13px", fontWeight: "600" }}
                  >
                    To:
                  </label>
                  <input
                    type="date"
                    id="toDay"
                    name="to"
                    value={toDateValue}
                    onChange={handleChangeTo}
                    style={{
                      backgroundColor: "#AED6F1",
                      border: 0,
                      padding: "10px",
                      fontFamily: "serif",
                      fontSize: "16px",
                      fontWeight: "500",
                      borderRadius: "10px",
                      // marginLeft: "5px",
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    //   sx={appColors.commonBtnColor}
                    onClick={handleSearchOrder}
                    sx={{
                      borderBottomLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  >
                    Search
                  </Button>
                </Box>
                <Box>
                  <Box sx={{ marginTop: "6px", margin: { xs: "10px" } }}>
                    <Material.TextField
                      label="Phone/Email"
                      variant="outlined"
                      value={emailPhone}
                      onChange={handleEmailPhoneChange}
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <IconName.FcSearch
                              cursor="pointer"
                              size="30px"
                              onClick={handleSearchOrder}
                            />
                          </Material.InputAdornment>
                        ),
                        style: { borderRadius: "50px" },
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <ViewOrderDetailsCard
                    setIsEditOrderDetailsDialog={setIsEditOrderDetailsDialog}
                    orderDetails={orderDetails}
                    setOrderDetails={setOrderDetails}
                    setOpenSingleOrder={setOpenSingleOrder}
                    setOpenSingleOrderDetails={setOpenSingleOrderDetails}
                  />
                </Box>
              </Box> */}
            </TabPanel>
            <TabPanel value="ReturnCanOrders">
              {" "}
              <AdminWithdrawal />
            </TabPanel>
            <TabPanel value="EmptyCanOrders">
              {" "}
              <EmptyCanOrder />
            </TabPanel>
            <TabPanel value="ReplacementCanOrders">
              {" "}
              <AdminReplacementOrder />
            </TabPanel>
            <TabPanel value="PostPaidOrders">
              <PostpaidOrders />
            </TabPanel>
            <TabPanel value="TransferOrderList">
              <TransferCanOrderList />
            </TabPanel>
            <TabPanel value="RecycleOrders">
              <AdminRecycleOrdersMain />
            </TabPanel>
            <TabPanel value="Buybackorders">
              <BuyBackOrderMain />
            </TabPanel>
            <TabPanel value="CreditNoteDetails">
              <CreditNoteDetailsMain />
            </TabPanel>
            {/* </Material.Paper> */}
          </TabContext>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={closeDialogFun}>
        <DialogContent>
          <h4 style={{ textAlign: "center" }}>Order Filter</h4>
          <Stack direction="row" alignItems="center" spacing={3}>
            {/* <Box>
              <FormControl
                sx={{
                  minWidth: "210px",
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Delivery Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={deliveryStatusVal}
                  label="Delivery Status"
                  onChange={(e) => {
                    setDeliveryStatus(e.target.value);
                  }}
                >
                  <MenuItem value={deliveryStatus.DELIVERY_STATUS_SCHEDULED}>
                    Scheduled
                  </MenuItem>
                  <MenuItem value={deliveryStatus.DELIVERY_STATUS_STARTED}>
                    Started
                  </MenuItem>
                  <MenuItem value={deliveryStatus.DELIVERY_STATUS_DELIVERED}>
                    Delivered
                  </MenuItem>
                  <MenuItem value={deliveryStatus.DELIVERY_STATUS_CANCELLED}>
                    Cancelled
                  </MenuItem>
                </Select>
              </FormControl>
            </Box> */}

            {/* <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Sort By Created Date
                </FormLabel>
                <RadioGroup
                  row
                  name="radio-buttons-group"
                  aria-labelledby="demo-radio-buttons-group-label"
                  onChange={(e) => {
                    setSortOrder(e.target.value);
                  }}
                  value={sortOrder}
                >
                  <FormControlLabel
                    value="DESC"
                    control={<Radio />}
                    label="DESC"
                  />
                  <FormControlLabel
                    value="ASC"
                    control={<Radio />}
                    label="ASC"
                  />
                </RadioGroup>
              </FormControl>
            </Box> */}
          </Stack>
          <Stack direction="column" sx={{ marginTop: "10px" }} spacing={3}>
            <Box>
              <FormControl
                sx={{
                  minWidth: "210px",
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Order Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orderStatusDelivery}
                  label="Order Status"
                  onChange={(e) => {
                    setOrderStatus(e.target.value);
                  }}
                >
                  <MenuItem value={ordersStatus.ORDER_STATUS_NEW_REQUEST}>
                    New Request
                  </MenuItem>
                  <MenuItem value={ordersStatus.ORDER_STATUS_CANCELLED}>
                    Order Cancelled
                  </MenuItem>
                  <MenuItem value={ordersStatus.ORDER_STATUS_ACCEPTED}>
                    Order Accepted
                  </MenuItem>
                  <MenuItem value={ordersStatus.ORDER_STATUS_REJECTED}>
                    Order Rejected
                  </MenuItem>
                  <MenuItem value={ordersStatus.ORDER_STATUS_DELIVERED}>
                    Order Delivered
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl
                sx={{
                  minWidth: "210px",
                }}
              >
                <InputLabel>Order Type</InputLabel>
                <Select
                  value={orderType}
                  label="Order Status"
                  onChange={(e) => {
                    setOrderType(e.target.value);
                  }}
                >
                  <MenuItem value={ORDER_TYPE.SUBSCRIPTION_ORDER}>
                    Subscription Order
                  </MenuItem>
                  <MenuItem value={ORDER_TYPE.INSTA_DELIVERY_ORDER}>
                    Insta Order
                  </MenuItem>
                  <MenuItem value={ORDER_TYPE.POST_PAID_ORDER}>
                    Postpaid Order
                  </MenuItem>
                  <MenuItem value={ORDER_TYPE.REGULAR_ORDER}>
                    Regular Order
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <TextField
                id="outlined-number"
                size="small"
                label="Pincode"
                value={pincodeFil}
                inputProps={{
                  maxLength: 6,
                }}
                onKeyDown={handleKeyDownNum}
                onChange={(e) => {
                  setPincodeFil(e.target.value);
                }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearAll}>Clear All</Button>
          <Button onClick={closeDialogFun}>Close</Button>
          <Button
            // sx={{ float: "right", margin: "10px" }}
            onClick={applyingFilter}
            variant="contained"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      {/* </Box> */}
    </Material.Box>
  );
}

export default OrderSearchCard;
