import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  addPromoerror :"",
  updatePromoerror:"",
  promoAddlist: {},
  promoUpdate: {},
  promoSingleDetails: {},
  promAllDetails: [],
};

const AdminPromoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.POST_PROMO_ADD:
      CustomConsole(action);
      return {
        ...state,
        addPromoerror: action.payload.error,
        msg: action.payload.msg,
        promoAddlist: action.payload.data,
      };
    case actionType.PUT_PROMO_UPDATE:
      return {
        ...state,
        updatePromoerror: action.payload.error,
        msg: action.payload.msg,
        promoUpdate: action.payload.data,
      };
    case actionType.GET_PROMO_SINGLE_DETAIL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        promoSingleDetails: action.payload.data,
      };
    case actionType.GET_ALL_PROMO_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        promAllDetails: action.payload.data,
      };

    default:
      return state;
  }
};
export default AdminPromoReducer;
