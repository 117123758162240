/**
 * @author vinay kumar
 * @description Wfs Completed Batch Card
 * @copyright Bookwater tech pvt ltd
 * @Date 01-08-2023
 */
// This UI Complete ReDesign Done by Vinay

import React from "react";
import * as Material from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { getFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import WfsCreateBatchDialog from "../Dialog/wfsCreateBatch";
import {
  CancelWfsBatchSku,
  EditWfsBatchSku,
  GetWfsBatchDetailsSku,
  GetWfsDetailsSku,
} from "../../Pages/Redux/Actions/skuWfsDetailsAction";
import ConformationDialog from "../Dialog/conformationDialog";
import { Orderstyles } from "../../Pages/AdminWFSCanOrders/cardStyles";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";

function WfsCompletedBatchCard(props) {
  const dispatch = useDispatch();
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const [batchDetails, setBatchDetails] = React.useState("");
  const [createBatchDialog, setCreateBatchDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [quantity, setQuantity] = React.useState(0);

  // CustomConsole(props)
  const handleEdit = () => {
    CustomConsole("------------handleEdit-----------");
    CustomConsole(props);
    props.handleEdit(props);
  };

  //function for editing batch detaisl
  const handleEditBatch = (data) => {
    CustomConsole(data.quantity);
    setCreateBatchDialog(true);
    setBatchDetails(data.batch_id);
    setQuantity(data.quantity);
  };

  //function for closing create batch dialog
  const handelCloseCreateBatchDialog = () => {
    setCreateBatchDialog(false);
  };

  //function for updating batch details
  const handelUpdateBatch = (quantity) => {
    const reqObj = {
      batch_id: batchDetails,
    };
    if (parseInt(props.quantity) - quantity > 0) {
      reqObj.quantity = parseInt(props.quantity) - quantity;
    } else {
      toast.error("Please Enter valid quantity");
    }
    dispatch(EditWfsBatchSku(reqObj));
  };

  //function for handling cancel batch
  const handleCancelBatch = (data) => {
    setBatchDetails(data);
    setConfirmDialog(true);
  };

  //function for handling cancel batch function
  const handelProceedCancelBatch = () => {
    const reqObj = {
      batch_id: batchDetails,
      reason: "Testing",
    };
    dispatch(CancelWfsBatchSku(reqObj));
    setTimeout(() => {
      dispatch(GetWfsBatchDetailsSku(props.batchFilter));
    }, 1000);
  };

  //useEffect for refreshing data
  React.useEffect(() => {
    if (skuWfsDetails.cancelBatchError === false) {
      setConfirmDialog(false);
    }
  }, [skuWfsDetails.cancelBatchError]);

  CustomConsole(props);
  return (
    <Material.Box
      sx={{ display: "flex", flexDirection: "row", marginTop: ".5rem" }}
    >
      <Material.Box sx={Orderstyles.orderCardBox}>
        <Material.Card sx={Orderstyles.orderCard}>
          <Material.Box sx={Orderstyles.orderCardHearder}>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Material.Box sx={Orderstyles.BoxInsideContent}>
                <Material.Typography sx={{ color: "white", fontSize: ".9rem" }}>
                  Batch Id: {props.batch_id}
                </Material.Typography>
                <Material.Typography
                  sx={{ color: "white", fontSize: ".9rem", zIndex: 5 }}
                >
                  <span></span>
                  Created At:
                  {moment(props.manufacturing_date).format("DD/MM/YYYY")}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={Orderstyles.labelDesign}>
              <Material.Typography sx={Orderstyles.labelText}>
                {props.is_batch_started === true &&
                props.is_batch_stopped === true &&
                props.linked_container_qty !== 0
                  ? "Completed"
                  : props.is_batch_started === true &&
                    props.is_batch_stopped === false
                  ? "Ongoing"
                  : props.is_batch_started === false &&
                    props.is_batch_stopped === false
                  ? "Yet to start"
                  : props.is_batch_started === true &&
                    props.is_batch_stopped === true &&
                    props.linked_container_qty === 0
                  ? "Cancelled"
                  : "Started"}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </Material.Typography>
            </Material.Box>
          </Material.Box>
          <Material.CardContent
            //   onClick={() => handelDetailClick()}
            sx={{ cursor: "pointer" }}
          >
            <Material.Box
              sx={{
                marginBottom: ".5rem",
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
              }}
            >
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                  Quantity:{" "}
                  <span style={{ color: "#068fff" }}>{props.quantity}</span>
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            {props.is_batch_started === true &&
            props.is_batch_stopped === true &&
            props.linked_container_qty !== 0 ? (
              <Material.Box
                sx={{
                  marginBottom: ".5rem",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  borderBottomStyle: "dashed",
                }}
              >
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                    Start Time:{" "}
                    <span style={{ color: "#068fff" }}>
                      {moment(props.batch_start_time)
                        .tz("Asia/Kolkata")
                        .format("HH:MM:SS")}
                    </span>
                  </Material.Typography>
                  <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                    Stop Time:{" "}
                    <span style={{ color: "#068fff" }}>
                      {moment(props.batch_stop_time)
                        .tz("Asia/Kolkata")
                        .format("HH:MM:SS")}
                    </span>
                  </Material.Typography>
                </Material.Box>
              </Material.Box>
            ) : null}
            <Material.Box
              sx={{
                marginBottom: ".5rem",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                borderBottomStyle: "dashed",
              }}
            >
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  justifyContent: "space-between",
                }}
              >
                <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                  Capacity:
                  <span style={{ color: "#068fff" }}>
                    {props.container_capacity}
                  </span>
                </Material.Typography>
                <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                  Material :
                  <span style={{ color: "#068fff" }}>
                    {props.container_material}
                  </span>
                </Material.Typography>
              </Material.Box>
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  justifyContent: "space-between",
                }}
              >
                <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                  Quality Report:
                  {props.Quality_Report !== null ? (
                    <span
                      style={{ color: "#068fff", cursor: "pointer" }}
                      onClick={() =>
                        props.Quality_Report !== "" &&
                        dispatch(getFiles(props.Quality_Report))
                      }
                    >
                      &nbsp;
                      <VisibilityRoundedIcon />
                    </span>
                  ) : (
                    "-"
                  )}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            {props.mft_date !== "" && (
              <Material.Box
                sx={{
                  marginBottom: ".5rem",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  borderBottomStyle: "dashed",
                }}
              >
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "flex-start",
                  }}
                >
                  <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                    MFG date:{" "}
                    <span style={{ color: "#068fff" }}>
                      {props.mft_date !== null ? (
                        <>
                          {moment(props.mft_date)
                            .tz("Asia/Kolkata")
                            .format("DD/MMM/YYYY")}
                        </>
                      ) : (
                        "-"
                      )}
                    </span>
                  </Material.Typography>
                  <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                    Expiry date:{" "}
                    {props.exp_date !== null ? (
                      <>
                        {" "}
                        <span style={{ color: "#068fff" }}>
                          {moment(props.exp_date)
                            .tz("Asia/Kolkata")
                            .format("DD/MMM/YYYY")}
                        </span>{" "}
                      </>
                    ) : (
                      "-"
                    )}
                  </Material.Typography>
                  <Material.Box sx={{ display: "flex" }}>
                    <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                      PH:{" "}
                      <span style={{ color: "#068fff" }}>
                        {" "}
                        {props.ph !== null ? <>{props.ph}</> : "-"}
                      </span>
                    </Material.Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Material.Typography sx={Orderstyles.TypograpyCommon1}>
                      TDS:{" "}
                      <span style={{ color: "#068fff" }}>
                        {" "}
                        {props.tds !== null ? <>{props.tds}</> : "-"}
                      </span>
                    </Material.Typography>
                  </Material.Box>
                </Material.Box>
              </Material.Box>
            )}
          </Material.CardContent>
          <Material.CardActions>
            <Material.Box
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              {props.is_batch_started === true &&
              props.is_batch_stopped === true &&
              props.linked_container_qty !== 0 ? (
                <Material.Button
                  onClick={handleEdit}
                  variant="contained"
                  size="small"
                >
                  Edit
                </Material.Button>
              ) : (
                ""
              )}
              &nbsp;&nbsp;&nbsp;
              {(props.is_batch_started === true &&
                props.is_batch_stopped === false) ||
              props.is_batch_started === false ? (
                <Material.Button
                  onClick={() => handleEditBatch(props)}
                  variant="contained"
                  size="small"
                >
                  Edit Batch
                </Material.Button>
              ) : (
                ""
              )}
              &nbsp;&nbsp;&nbsp;
              {(props.is_batch_started === true &&
                props.is_batch_stopped === false) ||
              props.is_batch_started === false ? (
                <Material.Button
                  onClick={() => handleCancelBatch(props.batch_id)}
                  variant="contained"
                  size="small"
                  color="error"
                >
                  Cancel Batch
                </Material.Button>
              ) : (
                ""
              )}
            </Material.Box>
          </Material.CardActions>
        </Material.Card>
      </Material.Box>

      <WfsCreateBatchDialog
        open={createBatchDialog}
        onClose={handelCloseCreateBatchDialog}
        type="edit"
        handelUpdateBatch={handelUpdateBatch}
        quantity={quantity}
        setQuantity={setQuantity}
      />
      <ConformationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        dialogMsg="Are you sure to cancel the batch"
        handelProceed={handelProceedCancelBatch}
      />
    </Material.Box>
  );
}

export default WfsCompletedBatchCard;
