import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  availableAreas: [],
  booknowOrderData: {},
  depositData: [],
  userStockData: [],
  setupConfigData: [],
  bookingConfirmMsg: "",
  subscriptionOrderError: false,
  subscriptionBookingConfirmMsg: "",
  postPaidBookingData: [],
  bookingOrderId: "",
  distStock: {},
};

const adminBookingReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_PINCODE_AREAS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        availableAreas: action.payload.data,
      };
    case actionType.GET_BOOKNOW_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        booknowOrderData: action.payload,
      };
    case actionType.GET_DEPOST_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        depositData: action.payload.data,
      };
    case actionType.GET_USER_DEPOST_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        userStockData: action.payload.data,
        distStock: action.payload.distStock,
      };
    case actionType.GET_CONFIG_DATA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        setupConfigData: action.payload.app_data,
      };
    case actionType.BOOK_PWD_ORDER:
      return {
        ...state,
        error: action.payload.data.error,
        bookingConfirmMsg: action.payload.data.msg,
        bookingOrderId: action.payload.data.order_id,
      };
    case actionType.BOOK_POSTPAID:
      return {
        ...state,
        error: action.payload.error,
        postPaidBookingData: action.payload.data,
      };
    case actionType.BOOK_PDW_SUBSCRIBE_ORDER:
      return {
        ...state,
        subscriptionOrderError: action.payload.error,
        subscriptionBookingConfirmMsg: action.payload.msg,
      };
    case actionType.UPDATE_SUBSCRIPTION_ORDER:
      return {
        ...state,
        error: action.payload.error,
        orderId: action.payload.data,
        subscriptionBookingConfirmMsg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default adminBookingReducer;
