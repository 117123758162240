import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";

function FilterDialog({
  handleClose,
  handleFilter,
  handleClearFilter,
  openRecycleFilterDialog,
  orderStatusSelect,
  filters,
  setFilters,
}) {
  return (
    <Box>
      <Dialog open={openRecycleFilterDialog}>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
            {" "}
            <Button
              variant="contained"
              onClick={handleClose}
              color="error"
              size="small"
            >
              <Close />
            </Button>
          </Box>
          <Typography
            sx={{
              marginBottom: "20px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Search
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ minWidth: 220 }}>
              <TextField
                value={filters?.phone}
                label="Search Phone"
                onChange={(e) =>
                  setFilters((prev) => {
                    return {
                      ...prev,
                      phone: parseInt(e.target.value) || "",
                    };
                  })
                }
              />
            </Box>
            <Box sx={{ minWidth: 220 }}>
              <TextField
                value={filters?.order_id?.toUpperCase()}
                label="Search Order Id"
                onChange={(e) =>
                  setFilters((prev) => {
                    return {
                      ...prev,
                      order_id: e.target.value || "",
                    };
                  })
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClearFilter}
              color="error"
            >
              Clear
            </Button>
            <Button
              disabled={!filters?.phone && !filters?.order_id}
              variant="contained"
              onClick={handleFilter}
            >
              Filter
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default FilterDialog;
