import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";

const Input = styled("input")({
  display: "none",
});
export default function PostpaidPoUpload({
  setPostpaidInitiated,
  postpaidInitiated,
  setPoFile,
  setBookOrderBtn,
  setCheckAvailability,
  setArea,
  postPaidFlag,
  setPostpaidFlag
}) {
  const handleClose = () => {
    setPostpaidInitiated(false);
  };
  const handleCancel = () => {
    window.location.reload();
    setBookOrderBtn(false);
    setCheckAvailability(false);
    setArea(false);
    setPostpaidInitiated(false);
    setPostpaidFlag(false);
  };
  return (
    <div>
      <Dialog
        open={postpaidInitiated}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Upload PO file for the Order
              </Typography>
            </Box>
            <Box>
              <InputLabel htmlFor="import-button">
                <Input
                  id="import-button"
                  type="file"
                  onChange={(e) => setPoFile(e.target.files[0])}
                />
                <Tooltip title="Upload PO file">
                  <FileUploadRoundedIcon sx={{ cursor: "pointer" }} />
                </Tooltip>
              </InputLabel>
            </Box>
            <Box>
              <Tooltip title="Order will not be placed without uploading PO file">
                <Button variant="contained" onClick={handleCancel}>
                  Cancel
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
