import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetNewPaymentSummary } from "../Redux/Actions/NewDashboardAction";
import CustomConsole from "../../CustomConsole";
import { DownloadForOffline } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";

function PaymentSummaryDashboard(props) {
  const dispatch = useDispatch();
  const NewDashboardData = useSelector((state) => state.newDashboardDetails);
  const [paymentNewSummaryData, setPaymentNewSummaryData] = React.useState([]);
  const [csvFileData, setCsvFileData] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));

  // React.useEffect(() => {
  //   dispatch(GetNewPaymentSummary({ from_date: fromDate, to_date: toDate }));
  // }, []);

  React.useEffect(() => {
    setPaymentNewSummaryData(NewDashboardData?.paymentNewSummary);
  }, [NewDashboardData]);

  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename: "PaymentReport",
    data: csvFileData,
  };
  function toCSV(paymentNewSummaryData) {
    const result = [];
    if (paymentNewSummaryData && paymentNewSummaryData.length) {
      for (const data of paymentNewSummaryData) {
        result.push({
          admin_refund_amount: data.admin_refund_amount,
          cancel_refund_amount: data.cancel_refund_amount,
          edit_refund_amount: data.edit_refund_amount,
          wallet_to_order: data.wallet_to_order,
          paysharp_to_order: data.paysharp_to_order,
          paysharp_to_wallet: data.paysharp_to_wallet,
          razorpay_to_order: data.razorpay_to_order,
          razorpay_to_wallet: data.razorpay_to_wallet,
        });
      }
      setCsvFileData(result);
    }
  }
  return (
    <TableContainer sx={{ border: "2px solid black", minHeight: "220px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "flex-end", xs: "center" },
          flexWrap: "wrap",
          gap: { md: "30px", xs: "0px", sm: "5px", lg: "30px" },
          // border: "1.4px solid black",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          PAYMENT SUMMARY
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: 16,
            // fontWeight: "bold",
          }}
        >
          ({props.fromDate} to {props.toDate})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          border: "1.4px solid black",
          bgcolor: "#CCD1D1",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Orders
        </Typography>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ bgcolor: "#EAEDED" }}>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", border: "1.4px solid black" }}
            >
              Wallet (&#x20B9;)
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", border: "1.4px solid black" }}
            >
              Paysharp (&#x20B9;)
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", border: "1.4px solid black" }}
            >
              Razorpay (&#x20B9;)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentNewSummaryData?.map((row) => (
            <TableRow sx={{ bgcolor: "#EAEDED" }}>
              <TableCell sx={{ border: "1.4px solid black" }} align="center">
                {parseFloat(row.wallet_to_order)
                  ? parseFloat(row.wallet_to_order)
                  : 0}
              </TableCell>
              <TableCell sx={{ border: "1.4px solid black" }} align="center">
                {parseFloat(row.paysharp_to_order)
                  ? parseFloat(row.paysharp_to_order)
                  : 0}
              </TableCell>
              <TableCell sx={{ border: "1.4px solid black" }} align="center">
                {parseFloat(row.razorpay_to_order)
                  ? parseFloat(row.razorpay_to_order)
                  : 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          border: "1.4px solid black",
          bgcolor: "#BDC3C7",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Wallet
        </Typography>
      </Box>
      <Table size="small">
        <TableHead sx={{ bgcolor: "#ECF0F1" }}>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", border: "1.4px solid black" }}
            >
              Paysharp (&#x20B9;)
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", border: "1.4px solid black" }}
            >
              Razorpay (&#x20B9;)
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", border: "1.4px solid black" }}
            >
              Refunds (&#x20B9;)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentNewSummaryData?.map((row) => (
            <TableRow sx={{ bgcolor: "#ECF0F1" }}>
              <TableCell align="center" sx={{ border: "1.4px solid black" }}>
                {parseFloat(row.paysharp_to_wallet)
                  ? parseFloat(row.paysharp_to_wallet)
                  : 0}
              </TableCell>
              <TableCell align="center" sx={{ border: "1.4px solid black" }}>
                {parseFloat(row.razorpay_to_wallet)
                  ? parseFloat(row.razorpay_to_wallet)
                  : 0}
              </TableCell>
              <TableCell align="center" sx={{ border: "1.4px solid black" }}>
                {Math.round(
                  parseFloat(
                    row.cancel_refund_amount ? row.cancel_refund_amount : 0
                  ) +
                    parseFloat(
                      row.edit_refund_amount ? row.edit_refund_amount : 0
                    ) +
                    parseFloat(
                      row.admin_refund_amount ? row.admin_refund_amount : 0
                    )
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PaymentSummaryDashboard;
