import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomConsole from "../../CustomConsole";
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { SearchUserAdmin } from "../Redux/Actions/addUserAdminAction";
import { GetUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import moment from "moment";
import {
  BooknowOrderDetails,
  GetUserDepositDetails,
} from "../Redux/Actions/adminBookingAction";
import {
  PlacePostPaid,
  GetPoTrackDetails,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { GetSuppliersForPincode } from "../Redux/Actions/adminPoTrackPostpaid";
import ReassignSupplierDialog from "./ReassignSupplierDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PlacePoOrder({
  open,
  setOpen,
  userPhone,
  supplierId,
  postPaidTrack,
  lastDate,
  poRemQty,
  distributerName,
  setSupplier,
}) {
  const dispatch = useDispatch();
  const referenceDate = dayjs(moment(lastDate).endOf("day").format());

  const addUserAdminDetails = useSelector(
    (state) => state.addUserAdminDetails.users
  );

  const userAddress = useSelector((state) => state.userDetailsUpdate.address);
  const bookingDetails = useSelector(
    (state) => state.adminBookingData.userStockData
  );
  const twtyLiterData = useSelector(
    (state) => state.adminBookingData.booknowOrderData
  );
  const poDetails = useSelector((state) => state.poTrackDetails);
  const supplierData = useSelector((state) => state.poTrackDetails.suppliers);

  const [userId, setUserId] = React.useState("");
  const [address, setAddress] = React.useState([]);
  const [addressCalled, setAddressCalled] = React.useState(false);

  //req objs
  const [orderDate, setOrderDate] = React.useState(
    moment().tz("Asia/Kolkata").format()
  );
  const [deliveryAddress, setDeliveryAddress] = React.useState({});
  const [orderQty, setOrderQty] = React.useState(2);
  const [returnQty, setReturnQty] = React.useState(0);
  const [cansCanPlace, setcansCanPlace] = React.useState(0);
  const [twentyLiterPrice, setTwtyLiterPrice] = React.useState(0);
  const [consumerName, setConsumerName] = React.useState("");
  const [orderPlaceFlag, setOrderPlaceFlag] = React.useState(false);
  const [orderToast, setOrderToast] = React.useState(false);
  const [consumerId, setConsumerId] = React.useState(0);
  const [getSupplierPin, setGetSupplierPin] = React.useState(0);
  const [reassignSupp, setReassignSupp] = React.useState(false);
  // const [supplierSelect, setSupplierSelect] = React.useState(0);
  const [supplierName, setSupplierName] = React.useState("");
  const [serviceArea, setServiceArea] = React.useState("");
  const [deliveryCharge, setDeliveryCharge] = React.useState(0);

  const handleClose = (boo) => {
    if (boo === 1) {
      setOrderPlaceFlag(true);
      setOpen(false);
    } else {
      setOpen(false);
      setSupplierName("");
      setSupplier(0);
    }
  };

  const shouldDisableDate = (date) => {
    return date.isAfter(referenceDate);
  };

  React.useEffect(() => {
    CustomConsole("---------Po details----------");
    CustomConsole(poDetails);
    CustomConsole(orderPlaceFlag);
    CustomConsole(poDetails.error);
    if (poDetails.error && orderToast) {
      toast.error(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setOrderToast(false);
    } else if (!poDetails.error && orderToast) {
      let reqObj = {
        page_size: "",
        page_number: "",
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));
      setOrderToast(false);
    }
  }, [poDetails]);

  React.useEffect(() => {
    CustomConsole("--------SupplierId--------");
    CustomConsole(supplierId);
    let reqObj = {
      areaname: deliveryAddress.service_area,
      consumer_id: consumerId,
      consumer_name: consumerName,
      conv_fee: 0,
      delivery_address: {
        address_id: deliveryAddress.address_id,
        address_line1: deliveryAddress.address_line1,
        address_line2: deliveryAddress.address_line2,
        address_line3: deliveryAddress.address_line3,
        contact_name: deliveryAddress.contact_name,
        contact_phone: deliveryAddress.contact_phone,
        delivery_timing_option: 0,
        is_primary: false,
        landmark: deliveryAddress.landmark,
        latitude: null,
        longitude: null,
        pincode: deliveryAddress.pincode,
        service_area: deliveryAddress.service_area,
        user_id: deliveryAddress.user_id,
      },
      deposit_price: 0,
      discount: 0,
      end_date: moment(orderDate).tz("Asia/Kolkata").format("YYYY-MM-DD"),
      gateway_fee: 0,
      latitude: "0",
      longitude: "0.0",
      order_terms: "0.0",
      order_type: "200",
      packaged_drinking_water: {
        empty_container_qty: returnQty,
        fivehndml_capacity_amt: 0,
        fivehndml_capacity_qty: 0,
        fivehndml_capacity_totamt: 0,
        fiveltr_capacity_amt: 0,
        fiveltr_capacity_qty: 0,
        fiveltr_capacity_totamt: 0,
        is_inscan_completed: false,
        is_order_started: false,
        is_outscan_completed: false,
        is_verify_otp: false,
        new_container_qty: orderQty - returnQty,
        delivery_otp_status: 100,
        oneltr_capacity_amt: 0,
        oneltr_capacity_qty: 0,
        oneltr_capacity_totamt: 0,
        received_empty_cans: 0,
        selected_container_capacity: "20_Liters",
        selected_container_material: "PET",
        submitted_filled_cans: 0,
        twenty_ltr_capacity_amt: orderQty * twentyLiterPrice,
        twenty_ltr_capacity_totamt: orderQty * twentyLiterPrice,
        twentyltr_capacity_qty: parseInt(orderQty),
        twohndml_capacity_amt: 0,
        twohndml_capacity_qty: 0,
        twohndml_capacity_totamt: 0,
        twoltr_capacity_amt: 0,
        twoltr_capacity_qty: 0,
        twoltr_capacity_totamt: 0,
        is_extra_charges_added: false,
        current_same_day_delivery_charge: 0,
        total_delivery_charges: 0,
        order_type: 0,
        pp_delivery_charge: deliveryCharge ? parseInt(deliveryCharge) : 0,
      },
      payment_type: "300",
      payment_method: "POSTPAID",
      paysharphandle: "0",
      pincode: deliveryAddress.pincode,
      pipe_length: 0,
      pipe_length_price: 0,
      postpaidrequest: "0",
      road_width: 0,
      start_date: moment().tz("Asia/Kolkata").format("YYYY-MM-DD"),
      tanker_type: "3",
      total_trips: "1",
      trip_rate: 30,
      trips_per_day: "1",
      supplier_id: supplierId,
      po_track_id: postPaidTrack,

      weekdays: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    };
    CustomConsole(reqObj);
    CustomConsole(orderPlaceFlag);
    CustomConsole(reqObj.consumer_id);
    CustomConsole(reqObj.consumer_name);
    CustomConsole(reqObj.twentyltr_capacity_qty);
    CustomConsole(cansCanPlace >= 0);
    if (
      orderPlaceFlag &&
      reqObj.consumer_id &&
      reqObj.consumer_name &&
      reqObj.packaged_drinking_water.twentyltr_capacity_qty >= 1 &&
      cansCanPlace >= 0 &&
      Object.keys(deliveryAddress).length &&
      !moment(lastDate)
        .tz("Asia/Kolkata")
        .isBefore(moment(orderDate).tz("Asia/Kolkata").startOf("day"))
    ) {
      CustomConsole(reqObj);
      dispatch(PlacePostPaid(reqObj));
      setDeliveryAddress({});
      setOrderPlaceFlag(false);
      setOrderToast(true);
      setSupplierName("");
      setSupplier(0);
    } else if (
      moment(lastDate)
        .tz("Asia/Kolkata")
        .isBefore(moment(orderDate).tz("Asia/Kolkata").startOf("day"))
    ) {
      toast.error("Error in date field", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setOrderPlaceFlag(false);
    }
  }, [
    deliveryAddress,
    orderQty,
    returnQty,
    supplierId,
    postPaidTrack,
    twentyLiterPrice,
    userId,
    consumerName,
    orderPlaceFlag,
    consumerId,
    deliveryCharge,
  ]);

  React.useEffect(() => {
    CustomConsole(deliveryAddress);
    if (deliveryAddress && Object.keys(deliveryAddress).length) {
      let reqObj = {
        tankerType: "3",
        pincode: deliveryAddress.pincode,
        phone: userPhone,
        area: deliveryAddress.service_area,
      };
      dispatch(BooknowOrderDetails(reqObj));
      dispatch(GetUserDepositDetails(deliveryAddress.user_id));

      setGetSupplierPin(() => deliveryAddress.pincode);
      setServiceArea(() => deliveryAddress.service_area);
    }
  }, [deliveryAddress]);

  React.useEffect(() => {
    CustomConsole("----------Booking Details-------------");
    CustomConsole(bookingDetails);
    CustomConsole(twtyLiterData);
    if (bookingDetails.length && Object.keys(twtyLiterData).length > 0) {
      let totalDeposite =
        bookingDetails[0].total_deposited_containers_qty +
        bookingDetails[0].total_undeposited_containers_qty;

      let depositeUsed =
        bookingDetails[0].filled_container_qty +
        bookingDetails[0].ongoing_ordered_containers -
        bookingDetails[0].empty_containers_submitting +
        parseInt(orderQty ? orderQty : 0) -
        parseInt(returnQty ? returnQty : 0);
      CustomConsole(totalDeposite);
      CustomConsole(depositeUsed);
      CustomConsole(totalDeposite > depositeUsed);
      let cans = totalDeposite - depositeUsed;
      setcansCanPlace(() => cans);
    }
    if (twtyLiterData && Object.keys(twtyLiterData).length > 0) {
      setTwtyLiterPrice(() =>
        twtyLiterData?.packagedrinkingwater?.twenty_ltr_amount
          ? twtyLiterData.packagedrinkingwater.twenty_ltr_amount
          : 0
      );
    }
  }, [bookingDetails, twtyLiterData, orderQty, returnQty]);

  const handleOrderQtyChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    e.target.value = numericValue;
    CustomConsole(e.target.value);
    setOrderQty(() => e.target.value);
  };

  const handleReturnQtyChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    e.target.value = numericValue;

    setReturnQty(() => e.target.value);
  };

  const onChangeEndDate = (newValue) => {
    if (newValue != null && newValue.$d != null) {
      setOrderDate(() => moment(newValue.$d).tz("Asis/kolkata").format());
      CustomConsole(newValue.$d);
    }
  };

  const onChangeDeliveryCharge = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    const limitednumber = numericValue.slice(0, 9);
    e.target.value = limitednumber;
    setDeliveryCharge(e.target.value);
  };

  React.useEffect(() => {
    if (userPhone) {
      dispatch(SearchUserAdmin(userPhone));
    }
  }, [userPhone]);

  React.useEffect(() => {
    CustomConsole("---------UserId---------");
    CustomConsole(userId);
    if (userId) {
      CustomConsole("------Address Api in Place Order------");
      dispatch(GetUserAddressAdmin(userId));
      setAddressCalled(true);
    }
  }, [userId]);

  React.useEffect(() => {
    if (
      bookingDetails.length &&
      returnQty > bookingDetails[0].filled_container_qty
    ) {
      setReturnQty(() => bookingDetails[0].filled_container_qty);
    }
    if (returnQty > orderQty) {
      setReturnQty(() => orderQty);
    }
  }, [orderQty, returnQty, bookingDetails]);

  React.useEffect(() => {
    CustomConsole(userAddress);
    if (userAddress && addressCalled) {
      setAddress(userAddress);
      setAddressCalled(false);
    }
  }, [userAddress]);

  React.useEffect(() => {
    CustomConsole(addUserAdminDetails);
    CustomConsole("---------Setting Uid---------");
    if (addUserAdminDetails && addUserAdminDetails.length) {
      CustomConsole("---------Inside if Setting Uid---------");
      setUserId(addUserAdminDetails[0].id);
      setConsumerName(addUserAdminDetails[0].fullname);
      setConsumerId(addUserAdminDetails[0].consumer_id);
      // setAddressCalled(true)
      // addUserAdminDetails.users = [];
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (getSupplierPin) {
      dispatch(GetSuppliersForPincode(getSupplierPin));
    }
  }, [getSupplierPin]);

  React.useEffect(() => {
    CustomConsole("-----Data for setting transportation charge----");
    CustomConsole(supplierData.length);
    CustomConsole(supplierData);
    CustomConsole(supplierId);
    CustomConsole(serviceArea);
    if (supplierData && supplierData.length && supplierId && serviceArea) {
      CustomConsole("-----Filterin Out the area----");
      CustomConsole(supplierId);
      let serviceAreaDet = supplierData.filter(
        (obj) => obj.supplier_id === supplierId
      );
      CustomConsole(serviceAreaDet[0]?.supplier_availability_areas);
      let transportCharge =
        serviceAreaDet[0]?.supplier_availability_areas?.filter(
          (obj) => obj.area_name.toLowerCase() === serviceArea.toLowerCase()
        );
      if (transportCharge?.[0]?.transportation_charges) {
        CustomConsole(transportCharge?.[0]?.transportation_charges);
        CustomConsole(
          transportCharge?.[0]?.transportation_charges ??
            transportCharge.transportation_charges
        );
        setDeliveryCharge(
          () =>
            transportCharge?.[0]?.transportation_charges ??
            transportCharge.transportation_charges
        );
      }
    }
  }, [supplierId, supplierData, serviceArea]);

  React.useEffect(() => {
    CustomConsole("-----Seting Delivery Charge----");
    CustomConsole(twtyLiterData);
    CustomConsole(twentyLiterPrice);
    CustomConsole(deliveryCharge);
  }, [deliveryCharge, twentyLiterPrice, twtyLiterData]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent="center"
            spacing={2}
            mt={2}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date"
                inputFormat="DD/MM/YYYY"
                value={orderDate}
                // minDate={moment().subtract(0, 'days')}
                onChange={onChangeEndDate}
                disablePast
                renderInput={(params) => <TextField size="small" {...params} />}
                shouldDisableDate={shouldDisableDate}
              />
            </LocalizationProvider>
            <TextField
              size="small"
              label="Delivery Charge"
              value={deliveryCharge}
              onChange={onChangeDeliveryCharge}
            />
          </Stack>

          <Stack mt={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                sx={{ fontSize: "0.90rem", marginTop: "2px" }}
                id="demo-simple-select-label"
              >
                Delivery Address
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={deliveryAddress}
                label="Delivery Address"
                onChange={(e) => setDeliveryAddress(e.target.value)}
                sx={{ fontSize: "0.90rem" }}
              >
                {address.map((obj, idx) => {
                  return (
                    <MenuItem
                      value={obj}
                      sx={{ fontSize: "0.69rem" }}
                      key={idx}
                    >
                      {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>

          {twentyLiterPrice > 0 ? (
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={{ xs: 1, sm: 2 }}
              useFlexGap
              flexWrap="wrap"
              justifyContent="space-between"
              mt={2}
            >
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                label="Order Quantity"
                value={orderQty}
                error={cansCanPlace < 0}
                onChange={handleOrderQtyChange}
                helperText={
                  cansCanPlace >= 0
                    ? `You can order max ${cansCanPlace} can`
                    : `You should reduce ${Math.abs(cansCanPlace)} cans`
                }
              />
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                label="Return Quantity"
                value={returnQty}
                onChange={handleReturnQtyChange}
              />
            </Stack>
          ) : twentyLiterPrice < 1 && Object.keys(deliveryAddress).length ? (
            <Alert sx={{ marginTop: "10px" }} severity="error">
              Please Add/Change Address for Booking,Not available for this
              pincode area!!
            </Alert>
          ) : null}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
          >
            <Button
              variant="outlined"
              disabled={deliveryAddress.pincode ? false : true}
              onClick={() => setReassignSupp(true)}
            >
              Assign supplier
            </Button>
            <Typography>
              {supplierName ? supplierName : distributerName}
            </Typography>
          </Box>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"column"} alignItems={"start"} mt={2}>
              <Typography>
                Cans Deposited:{" "}
                {bookingDetails[0]?.total_deposited_containers_qty}
              </Typography>
              <Typography>
                Complimentary Cans:{" "}
                {bookingDetails[0]?.total_undeposited_containers_qty}
              </Typography>
              <Typography>
                Cans in use:{bookingDetails[0]?.filled_container_qty}
              </Typography>
            </Stack>
            <Stack direction={"column"} alignItems={"start"} mt={2}>
              <Typography>
                Balance cans available:
                {bookingDetails[0]?.new_containers_to_provide -
                  bookingDetails[0]?.ongoing_ordered_containers}
              </Typography>
              <Typography>
                Ongoing containers:
                {bookingDetails[0]?.ongoing_ordered_containers}
              </Typography>
              <Typography>
                PO qty left:
                {poRemQty}
              </Typography>
            </Stack>
          </Stack>

          <Box mt={2}>
            <Typography sx={{ fontWeight: "600" }}>
              Delivery charge: {orderQty * deliveryCharge}
            </Typography>
            <Typography sx={{ fontWeight: "600" }}>
              Total water price: {orderQty * twentyLiterPrice}
            </Typography>
            <Typography sx={{ fontWeight: "600" }}>
              Total price:{" "}
              {orderQty * twentyLiterPrice + orderQty * deliveryCharge}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => handleClose(1)}
            disabled={
              twentyLiterPrice < 1 && Object.keys(deliveryAddress).length
            }
          >
            Place Order
          </Button>
        </DialogActions>
      </Dialog>
      <ReassignSupplierDialog
        open={reassignSupp}
        setOpen={setReassignSupp}
        supplierSelect={supplierId}
        setSupplierSelect={setSupplier}
        setSupplierName={setSupplierName}
      />
    </div>
  );
}

export default PlacePoOrder;
