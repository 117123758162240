import * as React from "react";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";

import moment from "moment";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FirstPage, LastPage, Search } from "@mui/icons-material";
import { CSVLink } from "react-csv";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#59A6D6",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function IotLiveDataTable({
  iotLiveData,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  handelPageSizeSearch,
}) {
  const [csvFileData, setCsvFileData] = React.useState([]);
  //function to convert time strp to normal time
  const handelTimeStrap = (timestamp) => {
    return moment.unix(timestamp).format("D/MMM/YYYY h:mm:ss A");
  };

  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename: "SkuPincodeReport",
    data: csvFileData,
  };

  //function to dounload csv files iot data
  function toCSV(iotLiveData) {
    const result = [];
    if (iotLiveData && iotLiveData?.length) {
      const csvData = iotLiveData.map((item) => ({
        ...item,
        Date: moment.unix(item.timestamp).format("D/MMM/YYYY h:mm:ss A"),
      }));
      setCsvFileData(csvData);
    }
  }

  //function to handel filter change
  const handleFilterChange = () => {};
  //function to search for page size
  const handleSearchPageSize = () => {
    handelPageSizeSearch();
  };
  //function to handel onchange function
  const handelPageSizeChange = (e) => {
    if (e.target.value < 101) {
      setPageSize(e.target.value);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
        {" "}
        {/* <Button
            // onClick={() => props.setOpenPincodeFilter(true)}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Button> */}
        <Box
          sx={{
            maxWidth: "120px",
            maxHeight: "10px",
            // margin: { xs: 4 },
            // marginTop: 1,
          }}
        >
          <TextField
            size="small"
            value={pageSize}
            label="Page size"
            onChange={handelPageSizeChange}
            InputProps={{
              maxLength: 10,
              onInput: handleFilterChange,
              endAdornment: (
                <InputAdornment position="end">
                  <Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              if (pageNumber - 1 > 0) {
                setPageNumber(() => pageNumber - 1);
              }
            }}
          >
            <FirstPage sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }} />
          </IconButton>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            {pageNumber}
          </Typography>
          <IconButton
            sx={{ maxHeight: "50px" }}
            onClick={() => {
              if (pageSize == iotLiveData?.length) {
                setPageNumber(() => pageNumber + 1);
              }
            }}
          >
            <LastPage
              sx={{
                color: pageSize == iotLiveData?.length ? "blue" : "gray",
              }}
            />
          </IconButton>
        </Box>
        <CSVLink {...csvLink}>
          <SimCardDownloadRoundedIcon onClick={() => toCSV(iotLiveData)} />
        </CSVLink>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                S.No
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                Device ID
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                Date
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                TDS
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                Temperature
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                PH
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {iotLiveData?.length > 0 ? (
              iotLiveData?.map((item, index) => (
                <Tooltip key={item.DeviceID}>
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "none",
                        backgroundColor: "#036bfc",
                      },
                    }}
                  >
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">
                      {item?.DeviceID}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {handelTimeStrap(item?.timestamp)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item?.tdsBsensor}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item?.temp}</StyledTableCell>
                    <StyledTableCell align="left">6.5-7</StyledTableCell>
                  </StyledTableRow>
                </Tooltip>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={6}>
                  Data Not Found
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
