import * as React from "react";
import * as Material from "@mui/material";
import PropTypes from "prop-types";
import CustomConsole from "../../CustomConsole";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as MuiIcons from "@mui/icons-material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as IconName from "react-icons/fc";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import {
  GetDistributorReturnCanOrders,
  GetWfsAllOrders,
  GetWfsCompletedOrders,
  GetWfsOngoingOrders,
} from "../Redux/Actions/wfsOrdersAction";
import OngoingOrdersCard from "./OngoingOrdersCard";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button } from "@mui/material";
import CompletedOrdersCard from "./CompletedOrderCard";
import AllOrdersCard from "./AllOrdersCard";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import WfsOrderDetailFormView from "./WfsOrderDetailFormView";
import WfsOrdersTableView from "./WfsOrdersTableView";
import ReturnCanOrders from "./ReturnCanOrders";
import WfsOrdersFilterDialog from "./WfsOrdersFilterDialog";
import ReturnCanFormView from "./ReturnCanFormView";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import { ordersStatus } from "../../Components/AppMeta/appMetaConfig";
import RecycleOrdersMain from "./RecycleOrdersMain";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function WfsOrders() {
  const theme = useTheme();
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);

  const dispatch = useDispatch();
  const wfsOrderDetails = useSelector((state) => state.wfsOrderDetails);
  CustomConsole(wfsOrderDetails);
  const [pageNumber, setPageNumber] = React.useState(1);

  const [pageStatus, setPageStatus] = React.useState(false);
  const [wfsOrdersView, setWfsOrdersView] = React.useState("form");
  const [ordersOngoingData, setOrdersOngoingData] = React.useState([]);
  const [compltedData, setCompltedData] = React.useState([]);
  const [allOrdersData, setAllOrdersData] = React.useState([]);
  const [orderType, setOrderType] = React.useState("");
  const [searchOrderId, setSearchOrderId] = React.useState("");
  const [testArr, setTestArr] = React.useState([]);
  CustomConsole(ordersOngoingData);
  CustomConsole(compltedData);
  CustomConsole(allOrdersData);
  CustomConsole(wfsOrderDetails.returnCanOrders);
  const [openWfsCanOrdersFilterDialog, setOpenWfsCanOrdersFilterDialog] =
    React.useState(false);

  const [orderParamas, setOrderParams] = React.useState({
    sort_order: "DESC",
    page_size: 25,
    page_number: 0,
    user_phone: "",
    ec_order_id: "",
    address_id: "",
    from_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    is_updated_date: true,
    order_status: "501",
  });
  React.useEffect(() => {
    dispatch(GetDistributorReturnCanOrders());
  }, []);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   setPageStatus(false);
  //   if (orderType === "ONGOING_ORDERS") {
  //     dispatch(GetWfsOngoingOrders(pageNumber - 1));
  //   }
  //   if (orderType === "COMPLETED_ORDERS") {
  //     dispatch(GetWfsCompletedOrders(pageNumber - 1));
  //   }
  //   if (orderType === "ALL_ORDERS") {
  //     dispatch(GetWfsAllOrders(pageNumber - 1));
  //   }
  //   // if (newValue === 3) {
  //   //   dispatch(GetDistributorReturnCanOrders());
  //   // }
  //   setPageNumber(1);
  // };

  // React.useEffect(() => {
  //   CustomConsole(value);
  // }, [value]);
  // const handleChangeIndex = (index) => {
  //   CustomConsole(index);
  //   setValue(index);
  //   setPageNumber(1);
  // };

  React.useEffect(() => {
    dispatch(GetWfsEmptyCanDetailsSku(orderParamas));
  }, [orderParamas.page_number]);

  // React.useEffect(() => {
  //   if (wfsOrdersView === "form" && orderType === "ONGOING_ORDERS") {
  //     CustomConsole("-------ONGOING_ORDERS------------");
  //     dispatch(
  //       GetWfsOngoingOrders({
  //         page_number: pageNumber - 1,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "form" && orderType === "COMPLETED_ORDERS") {
  //     CustomConsole("-------COMPLETED_ORDERS------------");
  //     dispatch(
  //       GetWfsCompletedOrders({
  //         page_number: pageNumber - 1,
  //         page_size: pageSize,
  //       })
  //     );
  //   } else if (wfsOrdersView === "form" && orderType === "ALL_ORDERS") {
  //     CustomConsole("-------ALL_ORDERS------------");
  //     dispatch(
  //       GetWfsAllOrders({
  //         page_number: pageNumber - 1,
  //         page_size: pageSize,
  //       })
  //     );
  //   }
  // }, [pageSize]);

  // React.useEffect(() => {
  //   setPageNumber(pageNumber - 1);
  //   dispatch(GetWfsOngoingOrders(pageNumber - 1));
  //   dispatch(GetWfsCompletedOrders(pageNumber - 1));
  //   dispatch(GetWfsAllOrders(pageNumber - 1));
  // }, []);

  React.useEffect(() => {
    CustomConsole("wfsOrderdetails");
    CustomConsole(wfsOrderDetails);
    CustomConsole(wfsOrderDetails.ongoingOrders.length);
    CustomConsole(wfsOrderDetails.completedOrders.length);
    CustomConsole(wfsOrderDetails.allOrders.length);
    // if (
    //   (wfsOrderDetails.ongoingOrders.length === 0 &&
    //     wfsOrdersView === "form") ||
    //   wfsOrdersView === "table" ||
    //   wfsOrdersView === "card" ||
    //   orderType === "ONGOING_ORDERS"
    // ) {
    //   CustomConsole("coming inside this");
    //   setPageStatus(true);
    // }
    if (
      wfsOrderDetails !== undefined &&
      wfsOrderDetails.ongoingOrders !== undefined &&
      wfsOrderDetails.ongoingOrders.length > 0
    ) {
      setPageStatus(false);
    } else if (
      wfsOrderDetails !== undefined &&
      wfsOrderDetails.completedOrders !== undefined &&
      wfsOrderDetails.completedOrders.length > 0
    ) {
      setPageStatus(false);
    } else if (
      wfsOrderDetails !== undefined &&
      wfsOrderDetails.allOrders !== undefined &&
      wfsOrderDetails.allOrders.length > 0
    ) {
      setPageStatus(false);
    } else {
      setPageStatus(true);
    }
    // if (
    //   (wfsOrderDetails.completedOrders.length === 0 &&
    //     wfsOrdersView === "form") ||
    //   wfsOrdersView === "table" ||
    //   wfsOrdersView === "card" ||
    //   orderType === "COMPLETED_ORDERS"
    // ) {
    //   setPageStatus(true);
    // }
    // if (
    //   (wfsOrderDetails.completedOrders.length > 0 &&
    //     wfsOrdersView === "form") ||
    //   wfsOrdersView === "table" ||
    //   wfsOrdersView === "card" ||
    //   orderType === "COMPLETED_ORDERS"
    // ) {
    //   setPageStatus(false);
    // }

    // if (
    //   (wfsOrderDetails.allOrders.length === 0 && wfsOrdersView === "form") ||
    //   wfsOrdersView === "table" ||
    //   wfsOrdersView === "card" ||
    //   orderType === "ALL_ORDERS"
    // ) {
    //   setPageStatus(true);
    // }
    // if (
    //   wfsOrderDetails.allOrders.length > 0 ||
    //   wfsOrdersView === "form" ||
    //   wfsOrdersView === "table" ||
    //   wfsOrdersView === "card" ||
    //   orderType === "ALL_ORDERS"
    // ) {
    //   setPageStatus(false);
    // }
  }, [wfsOrderDetails]);

  React.useEffect(() => {}, [wfsOrderDetails]);

  const [decodedResults, setDecodedResults] = React.useState([]);
  const [scanCount, setScanCount] = React.useState(0);

  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    CustomConsole(decodedResult.result.format.formatName);

    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  // const handleButtonData = () => {
  //   CustomConsole("------------handleButtonData------------");

  // };

  const handleFilter = () => {
    setOpenWfsCanOrdersFilterDialog(true);
  };
  const handleFilterApply = (props) => {
    CustomConsole(props);
    // setOrderType(props);
    if (orderType === "") {
      dispatch(GetWfsEmptyCanDetailsSku(orderParamas));
    } else {
      dispatch(GetDistributorReturnCanOrders());
      setOpenWfsCanOrdersFilterDialog(false);
      setPageSize(100);
      setPageNumber(1);
    }
    // setOpenWfsCanOrdersFilterDialog(false);
  };
  const handleClose = () => {
    setOpenWfsCanOrdersFilterDialog(false);
  };
  const handleWfsOrderView = (event) => {
    setWfsOrdersView(event.target.value);
    setPageSize(100);
    setPageNumber(1);
    //     if (wfsOrdersView === "form") {
    //       dispatch(GetWfsOngoingOrders(pageNumber - 1));
    // setPageNumber(1);
    //     } else if (wfsOrdersView === "table") {
    //       dispatch(GetWfsOngoingOrders(pageNumber - 1));
    // setPageNumber(1);
    //     } else if (wfsOrdersView === "card") {
    //       dispatch(GetWfsOngoingOrders(pageNumber - 1));
    //     } else if (orderType === "RETURN_CAN_ORDERS") {
    //       dispatch(GetDistributorReturnCanOrders());
    // setPageNumber(1);
    //     }
  };
  const handleViewDetails = () => {
    if (searchOrderId !== "") {
      const url = `/dashboard/WfsSingleOrder?order_id=${
        "ECON" + searchOrderId
      }`;
      history.push(url);
    } else {
      toast.error("Please Select Order Id", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setOrderParams((prev) => ({
      ...prev,
      page_size: event.target.value,
    }));
  };

  const handleSearchPageSize = () => {
    dispatch(GetWfsEmptyCanDetailsSku(orderParamas));
  };
  let result = [];
  function toCSV(items) {
    if (items) {
      CustomConsole(items);
      items?.map((a) => {
        CustomConsole(a);
        result.push(a);
        // result.push(a);
      });
    }
    setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };

  //function for searching order Id
  const handelSearchOrderId = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setOrderParams((prev) => ({
      ...prev,
      ec_order_id: "ECON" + event.target.value,
      order_status: "",
    }));
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ marginTop: "50px" }}>
      {" "}
      <Box sx={{ bgcolor: "background.paper" }}>
        <AppBar
          position="static"
          sx={{
            color: "black",
            borderRadius: "100px",
            // background: appColors.commonLayoutColor.background,
            // backgroundImage: appColors.commonLayoutColor.backgroundImage,
            background: "#85C1E9",
            padding: "5px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            // variant="scrollable"
            aria-label="full width tabs example"
            TabIndicatorProps={{ style: { display: "none" } }} // Hide the bottom border
          >
            <Tab
              label="Wfs Orders"
              {...a11yProps(0)}
              sx={{
                ...(value === 0 && {
                  bgcolor: "#F7F9F9", // Change color when active
                  color: "black",
                }),
                borderRadius: 10,
              }}
            />

            <Tab
              label="Return Orders"
              {...a11yProps(1)}
              sx={{
                ...(value === 1 && {
                  bgcolor: "#F7F9F9", // Change color when active
                  color: "black",
                }),
                borderRadius: 10,
              }}
            />
            <Tab
              label="Recycle Orders"
              {...a11yProps(2)}
              sx={{
                ...(value === 2 && {
                  bgcolor: "#F7F9F9", // Change color when active
                  color: "black",
                }),
                borderRadius: 10,
              }}
            />
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box
              sx={{
                bgcolor: "#EBF5FB",
                // width: "100%",
                padding: "30px",
              }}
            >
              <Material.Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Material.Grid item md={3} xs={12}>
                  <Box sx={{ marginTop: { md: "26px" } }}>
                    <Material.TextField
                      size="small"
                      label="Search By Order Id"
                      value={orderParamas.ec_order_id}
                      onChange={handelSearchOrderId}
                      InputProps={{
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <IconName.FcSearch
                              cursor="pointer"
                              size="30px"
                              onClick={handleFilterApply}
                            />
                          </Material.InputAdornment>
                        ),
                        style: { borderRadius: "50px" },
                      }}
                    />
                  </Box>
                </Material.Grid>
                <Material.Grid item md={2} xs={6}>
                  <Box sx={{ margin: { xs: "10px" } }}>
                    <Material.InputLabel
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      View:
                    </Material.InputLabel>
                    <Material.Select
                      variant="standard"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        width: "120px",
                      }}
                      value={wfsOrdersView}
                      label="Ui View"
                      onChange={handleWfsOrderView}
                    >
                      <Material.MenuItem value="form">Form</Material.MenuItem>
                      <Material.MenuItem value="table">Table</Material.MenuItem>
                      <Material.MenuItem value="card">Card</Material.MenuItem>
                    </Material.Select>
                  </Box>
                </Material.Grid>
                <Material.Grid item md={2} xs={6}>
                  {" "}
                  <Material.Box
                    sx={{ maxWidth: "120px", maxHeight: "10px", margin: "5px" }}
                  >
                    <Material.TextField
                      size="small"
                      value={orderParamas.page_size}
                      label="Page size"
                      // onChange={(e) => {
                      //   setPageSize(e.target.value);
                      //   // setSearchOrderFlag(true);
                      // }}

                      InputProps={{
                        maxLength: 10, // Optional: You can set the maximum length if needed
                        onInput: handleChangePageSize, // Attach the input event handler
                        endAdornment: (
                          <Material.InputAdornment position="end">
                            <MuiIcons.Search
                              cursor="pointer"
                              size="30px"
                              onClick={handleSearchPageSize}
                            />
                          </Material.InputAdornment>
                        ),
                      }}
                    />
                  </Material.Box>
                </Material.Grid>
                <Material.Grid item md={2} xs={4}>
                  <Box sx={{ marginTop: { md: "29px" } }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleFilter}
                    >
                      <MuiIcons.FilterList /> Filter
                    </Button>
                  </Box>
                </Material.Grid>

                <Material.Grid item md={1} xs={4}>
                  <Box sx={{ mt: 2 }}>
                    <CSVLink {...csvLink}>
                      {" "}
                      <DownloadIcon
                        sx={{ fontSize: 35 }}
                        onClick={() =>
                          toCSV(
                            orderType === "ONGOING_ORDERS"
                              ? wfsOrderDetails?.ongoingOrders
                              : orderType === "COMPLETED_ORDERS"
                              ? wfsOrderDetails?.completedOrders
                              : orderType === "ALL_ORDERS"
                              ? wfsOrderDetails?.allOrders
                              : undefined
                          )
                        }
                      />
                    </CSVLink>
                  </Box>
                </Material.Grid>
                <Material.Grid item md={2} xs={4}>
                  <Material.Box>
                    <Material.Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Material.IconButton
                        onClick={() => {
                          if (orderParamas.page_number > 0) {
                            // setPageNumber(() => pageNumber - 1);
                            setOrderParams((prev) => ({
                              ...prev,
                              page_number: orderParamas.page_number - 1,
                            }));
                          }
                        }}
                      >
                        <MuiIcons.FirstPage
                          sx={{
                            color:
                              orderParamas.page_number > 0 ? "blue" : "gray",
                          }}
                        />
                      </Material.IconButton>
                      <Material.Typography
                        sx={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        {orderParamas.page_number + 1}
                      </Material.Typography>
                      <Material.IconButton
                        sx={{ maxHeight: "50px" }}
                        onClick={() => {
                          if (skuWfsDetails?.emptyCanDetails?.length) {
                            // setPageNumber(() => pageNumber + 1);
                            setOrderParams((prev) => ({
                              ...prev,
                              page_number: orderParamas.page_number + 1,
                            }));
                          }
                        }}
                      >
                        <MuiIcons.LastPage
                          sx={{
                            color: skuWfsDetails?.emptyCanDetails?.length
                              ? "blue"
                              : "gray",
                          }}
                        />
                      </Material.IconButton>
                    </Material.Box>
                  </Material.Box>
                </Material.Grid>
              </Material.Grid>

              <Material.Box>
                {wfsOrdersView === "form" ? (
                  <WfsOrderDetailFormView
                    data={skuWfsDetails?.emptyCanDetails}
                  />
                ) : wfsOrdersView === "table" ? (
                  <WfsOrdersTableView data={skuWfsDetails?.emptyCanDetails} />
                ) : wfsOrdersView === "card" &&
                  orderType !== "RETURN_CAN_ORDERS" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: 2,
                        height: 700,
                        overflow: "scroll",
                      }}
                    >
                      {skuWfsDetails?.emptyCanDetails?.map((data, key) => (
                        <OngoingOrdersCard
                          data={data}
                          key={key}
                          pageNumber={pageNumber}
                        />
                      ))}
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Material.Box>

              <WfsOrdersFilterDialog
                open={openWfsCanOrdersFilterDialog}
                orderType={orderType}
                onClose={handleClose}
                onSubmit={handleFilterApply}
                orderParams={orderParamas}
                setOrderParams={setOrderParams}
                wfsOrdersView={wfsOrdersView}
                setOrderType={setOrderType}
                // handleChangeOrderStatus={handleChangeOrderStatus}
              />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {wfsOrderDetails?.returnCanOrders !== undefined &&
            wfsOrderDetails?.returnCanOrders !== null &&
            wfsOrderDetails?.returnCanOrders.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                {wfsOrderDetails?.returnCanOrders?.map((data, key) => (
                  <ReturnCanOrders data={data} key={key} />
                ))}
              </Box>
            ) : (
              <Material.Box
                sx={{
                  // width: 300,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10x",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  No data available for this page
                </Typography>
              </Material.Box>
            )}
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <RecycleOrdersMain />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Box>
  );
}
