import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialOrdersState = {
  error: true,
  msg: "",
  ongoingBatch: [],
  batch_id: "",
  QrLinkDetails: {},
  startBatchDetails: {},
  stopBatchDetails: {},
  lineBatchDetails: [],
  errorStartBatch: true,
  errorStopBatch: true,
  lineDetailsError: false,
  lineDetailsMsg: "",
};

const wfsOngoingBatchReducer = (state = initialOrdersState, action) => {
  switch (action.type) {
    case actionType.GET_WFS_ONGOING_BATCH:
      return {
        ...state,
        error: action.payload.error,
        ongoingBatch: action.payload.data,
      };
    case actionType.PUT_WFS_CREATE_BATCH: 
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        batch_id: action.payload.batch_id,
      };
      case actionType.PUT_WFS_EDIT_BATCH: 
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // batch_id: action.payload.batch_id,
      };
    case actionType.PUT_WFS_QR_LINK:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        QrLinkDetails: action.payload.data,
      };
    case actionType.PUT_WFS_START_BATCH:
      return {
        ...state,
        errorStartBatch: action.payload.error,
        msg: action.payload.msg,
        startBatchDetails: action.payload.data,
      };
    case actionType.PUT_WFS_STOP_BATCH:
      return {
        ...state,
        errorStopBatch: action.payload.error,
        msg: action.payload.msg,
        stopBatchDetails: action.payload.data,
      };
    case actionType.GET_WFS_ONGOING_LINE_DETAILS:
      return {
        ...state,
        lineDetailsError: action.payload.error,
        lineDetailsMsg: action.payload.msg,
        lineBatchDetails: action.payload.data,
      };

    default:
      return state;
  }
};

export default wfsOngoingBatchReducer;
