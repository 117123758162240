import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { UpdateZoneForPincode } from "../Redux/Actions/skuPincodeAction";
import { AvailablePincodes } from "../Redux/Actions/pincodesAction";
import AutocompleteAddressFields from "../../Components/zoneComponents/AutocompleteAddressFields";

const GroupPincode = ({
  openDialog,
  setOpenDialog,
  selectedPincodes,
  setSelectedPincodes,
  availableZoneNames,
  setAvailableZoneNames,
}) => {
  const [zoneName, setZoneName] = useState("");
  const [zoneType, setZoneType] = useState("City");
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  // const [availableZoneNames, setAvailableZoneNames] = useState([]);
  const dispatch = useDispatch();

  const zoneTypeMapping = {
    100: "City",
    200: "Mandal/Post",
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setZoneName("");
    // setSelectedPincodes([]);
  };

  const handleSaveZone = async () => {
    const zoneTypeMapping = {
      City: 100,
      "Mandal/Post": 200,
    };

    const reqObj = {
      zone_name: zoneName,
      zone_type: zoneTypeMapping[zoneType],
      pincode: selectedPincodes.map(({ pincode }) => pincode),
    };

    try {
      await dispatch(UpdateZoneForPincode(reqObj));
      setSelectedPincodes([]);
    } catch (error) {
      console.error("Failed to update zone:", error);
    }

    handleCloseDialog();
  };

  return (
    <Box sx={{ mt: 2, p: 2 }}>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Group Pincode to Zone</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <AutocompleteAddressFields
                  key_name="zone_name"
                  label="Zone Name"
                  setValue={setZoneName}
                  value={zoneName}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    marginTop: "0px",
                    padding: "0px",
                  }}
                  margin="normal"
                >
                  <InputLabel id="zone-type-label">Zone Type</InputLabel>
                  <Select
                    labelId="zone-type-label"
                    value={zoneType}
                    onChange={(e) => setZoneType(e.target.value)}
                    label="Zone Type"
                  >
                    <MenuItem value="City">City</MenuItem>
                    <MenuItem value="Mandal/Post">Mandal/Post</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Typography variant="h6">Selected Pincodes:</Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S.no</TableCell>
                    <TableCell>Pincode</TableCell>
                    <TableCell>Locality</TableCell>
                    <TableCell>Zone Name</TableCell>
                    <TableCell>Zone Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPincodes.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.pincode}</TableCell>
                      <TableCell>{row.locality}</TableCell>
                      <TableCell>{row.zone_name}</TableCell>
                      <TableCell>
                        {zoneTypeMapping[row.zone_type] || ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveZone} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GroupPincode;
