//Creating this component for empty can orders page.

import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import DistributerEcoTable from "./DistributerEcoTable";
import EcodetailsCard from "./EcodetailsCard";
import WFSDetailsTable from "./WFSDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { ArrowBackIos } from "@mui/icons-material";
import { Typography } from "@mui/material";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { GetWFSDetails } from "../Redux/Actions/adminEmptyCanOrderAction";
import { Button } from "@mui/material";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import CustomConsole from "../../CustomConsole";

function EmptyCanOrder() {
  const [wfsDetails, setWfsDetails] = React.useState([]);
  const [wfsPageNumber, setWFSPageNumber] = React.useState(1);
  const [distributerPgNo, setDistributerPgNo] = React.useState(1);
  const [orderId, setOrderId] = React.useState("");

  const [pageNumber, setPageNumber] = React.useState(1);
  CustomConsole(pageNumber);
  const [arrowBackwordBtn, setArrowBackwordBtn] = React.useState(false);

  const [arrowForwordBtn, setArrowForwordBtn] = React.useState(false);
  const [pageChange, setPageChange] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);
  const [emptDetCard, setEmpDetcard] = React.useState(false);
  const [eccoDetailsCardPageFlag, setEccoDetailsCardPageFlag] =
    React.useState(false);

  CustomConsole(pageStatus);

  const dispatch = useDispatch();
  const acceptOrderWfsResponse = useSelector(
    (state) => state.emptyCanOrderData
  );
  CustomConsole(acceptOrderWfsResponse.wfsDetails);

  const canOrderDetail = useSelector(
    (state) => state.emptyCanOrderData.orderIdDetails
  );
  //condition rendering states
  const [wfsTable, setWFSTable] = React.useState(false);

  //scrolldown
  const scrollDownToDetailsCard = React.useRef(null);
  const scrollWFSTable = React.useRef(null);

  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const scrollToBottom = () => {
    scrollDownToDetailsCard.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToWfsTable = () => {
    scrollWFSTable.current?.scrollIntoView({ behavior: "smooth" });
  };
  CustomConsole(wfsTable);

  return (
    // <Container>
    <Box>
      {ADMIN_ROLES.BW_ADMIN === adminRole ||
      ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
      ADMIN_ROLES.BW_QA === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
      ADMIN_ROLES.BW_CSLEAD === adminRole ||
      ADMIN_ROLES.BW_OPSHEAD === adminRole ||
      ADMIN_ROLES.BW_CS === adminRole ||
      ADMIN_ROLES.BW_OPS === adminRole ||
      ADMIN_ROLES.BW_QAHEAD === adminRole ||
      ADMIN_ROLES.BW_IVML === adminRole ? (
        <Box>
          <Grid container>
            <Grid item md={12} lg={12}>
              <DistributerEcoTable
                setDistributerPgNo={setDistributerPgNo}
                distributerPgNo={distributerPgNo}
                setWFSPageNumber={setWFSPageNumber}
                setWFSTable={setWFSTable}
                scrollToBottom={scrollToBottom}
                setEmpDetcard={setEmpDetcard}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                scrollToWfsTable={scrollToWfsTable}
                scrollWFSTable={scrollWFSTable}
                wfsTable={wfsTable}
              />
            </Grid>
            {/* {emptDetCard ? (
              <Grid item md={12} lg={12}>
                <EcodetailsCard
                  setOrderId={setOrderId}
                  setWfsDetails={setWfsDetails}
                  setWFSTable={setWFSTable}
                  pageNumber={pageNumber}
                  setEccoDetailsCardPageFlag={setEccoDetailsCardPageFlag}
                  scrollBtm={scrollDownToDetailsCard}
                  scrollToWfsTable={scrollToWfsTable}
                  orderId={orderId}
                  wfsTable={wfsTable}
                />
              </Grid>
            ) : null} */}
            {/* {wfsDetails.length > 0 && wfsTable === true ? (
              <Grid item md={12}>
                <WFSDetailsTable
                  orderId={orderId}
                  distributerPgNo={distributerPgNo}
                  setPageNumber={setWFSPageNumber}
                  wfsDetails={wfsDetails}
                  setWFSTable={setWFSTable}
                  scrollWFSTable={scrollWFSTable}
                  setDistributerPgNo={setDistributerPgNo}
                  setEmpDetcard={setEmpDetcard}
                  setEccoDetailsCardPageFlag={setEccoDetailsCardPageFlag}
                />
              </Grid>
            ) : null} */}
          </Grid>
        </Box>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Box>
    // </Container>
  );
}

export default EmptyCanOrder;
