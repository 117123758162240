import React from "react";
import {
  Button,
  Card,
  CardContent,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  GetInfluencerRequests,
  GetInfluencerDetails,
} from "../Redux/Actions/adminInfluencerAction";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AdminInfluencersSearchCard from "./AdminInfluencersSearchCard";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: "0px",
  },

  tableContainer: {
    overflowX: "auto",
    "& .MuiTable-root": {
      width: "100%",
      tableLayout: "fixed",
    },
    "& .MuiTableCell-root": {
      padding: "20px",
    },
  },

  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#BECAE0",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00B7EB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AdminInfluencerRequestTable({
  setDetailsCardFlag,
  scrollDownToCard,
  setPageNumberReload,
  influencer,
  pageNumberReload,
  searchRestrictFlag,
  setSearchRestrictFlag,
  setInfluencer,
  setTableview,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const influencerData = useSelector((state) => state.influencerDetails);

  const [pageBtnDisable, setPageBtnDisable] = React.useState(false);

  // React.useEffect(() => {
  //   dispatch(GetInfluencerRequests(pageNumberReload-1,influencer));
  // }, []);

  React.useEffect(() => {
    CustomConsole("------Hitting Table------");
    if (searchRestrictFlag) {
      dispatch(GetInfluencerRequests(pageNumberReload - 1, influencer));
    }
  }, [pageNumberReload]);

  React.useEffect(() => {
    CustomConsole(influencerData);
    if (
      influencerData.influencerData &&
      influencerData.influencerData.length === 0
    ) {
      setPageBtnDisable(true);
    }
    if (
      influencerData.influencerData &&
      influencerData.influencerData.length > 0
    ) {
      setPageBtnDisable(false);
    }
  }, [influencerData]);

  const getInfluencerDetails = (id) => {
    dispatch(GetInfluencerDetails(id));
    setDetailsCardFlag(true);

    setTimeout(() => {
      scrollDownToCard();
    }, 500);
  };

  const pageFarward = () => {
    setPageNumberReload((page) => page + 1);
    setSearchRestrictFlag(true);
    setDetailsCardFlag(false);
  };

  const pageBackward = () => {
    if (pageNumberReload - 1 > 0) {
      setPageBtnDisable(false);
      setPageNumberReload((page) => page - 1);
      setSearchRestrictFlag(true);
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}
        >
          <AdminInfluencersSearchCard
            pageNumberReload={pageNumberReload}
            influencerData={influencerData}
            setInfluencer={setInfluencer}
            setDetailsCardFlag={setDetailsCardFlag}
            setPageNumberReload={setPageNumberReload}
            setSearchRestrictFlag={setSearchRestrictFlag}
            setTableview={setTableview}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // gap: "15px",
              // padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageBackward}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumberReload}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageFarward}
                disabled={pageBtnDisable}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box>
        </Box>
        {influencerData.influencerData &&
        influencerData.influencerData.length > 0 ? (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Full Name</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {influencerData.influencerData
                  ? influencerData.influencerData.map((roles, idx) => {
                      return (
                        <StyledTableRow
                          key={idx + 1}
                          className={classes.tableRow}
                          onClick={() => {
                            getInfluencerDetails(roles.id);
                          }}
                        >
                          <StyledTableCell align="center">
                            {roles.fullname}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {roles.phone}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <b>No Data</b>
        )}
      </CardContent>
    </Card>
  );
}

export default AdminInfluencerRequestTable;
