/**
 * @author vinay kumar
 * @description Supplier Company Profile
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 29-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import CompanyProfileCard from "../../Components/Cards/CompanyProfileCard";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import { GetSupplierCompanyProfile } from "../Redux/Actions/supliersAction";

function CompanyProfile() {
  const dispatch = useDispatch();

  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  React.useEffect(() => {
    dispatch(GetSupplierCompanyProfile());
  }, []);

  return (
    <Material.Box  sx={{width: "70Vw"}}>
      <Material.Typography>
        <CompanyProfileCard
          data={supplierDeliveries.getSupplerCompanyProfile}
        />
      </Material.Typography>
    </Material.Box>
  );
}

export default CompanyProfile;
