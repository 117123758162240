/**
 * @author vinay kumar
 * @description Previous & Completed Details Main Details page
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 27-11-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import CompletedDeliveries from "./CompletedDeliveries";
import CustomConsole from "../../CustomConsole";
import PreviousDeliveries from "./PreviousDeliveries";

function PrvAndCompletedDeliveries() {
  const [tabValuePrevCompleted, setTabValuePrevCompleted] =
    React.useState("Completed");
  const handleChangeTabValuePrevCompleted = (event, newValue) => {
    setTabValuePrevCompleted(newValue);
  };
  return (
    <Material.Box sx={{ marginTop: "60px" }}>
      <Material.Box sx={{ maxWidth: { xs: "390px", md: "120%" } }}>
        <TabContext
          value={tabValuePrevCompleted}
          variant="scrollable"
          scrollButtons={false}
        >
          <TabList
            onChange={handleChangeTabValuePrevCompleted}
            sx={{ bgcolor: "#AED6F1" }}
            variant="scrollable"
            scrollButtons={false}
          >
            <Tab label="Completed" value="Completed" />
            <Tab label="Previous" value="Previous" />
          </TabList>
          <TabPanel value="Completed">
            <CompletedDeliveries />
          </TabPanel>
          <TabPanel value="Previous">
            <PreviousDeliveries />
          </TabPanel>
        </TabContext>
      </Material.Box>
    </Material.Box>
  );
}

export default PrvAndCompletedDeliveries;
