import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WfsOngoingBatchCardDialog from "./WfsOngoingBatchCardDialog";
import CustomConsole from "../../CustomConsole";
import {
  PutWfsQrlinkAction,
  WfsEditBatchAction,
} from "../Redux/Actions/wfsOnGoingBatchAction";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { PutStartBatchAction } from "../Redux/Actions/wfsOnGoingBatchAction";
import { PutStopBatchAction } from "../Redux/Actions/wfsOnGoingBatchAction";
import { GetWfsOngoingBatch } from "../Redux/Actions/wfsOnGoingBatchAction";

import * as Material from "@mui/material";
import { color } from "@mui/system";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  CheckCircle,
  DocumentScanner,
  HighlightOff,
  RedoTwoTone,
} from "@mui/icons-material";
import {
  LinkValidCans,
  // ValidateScannedCodes,
} from "../Redux/Actions/wfsBatchLinkingAction";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import WfsBatchCameraDialogCanSwapScanInvalid from "./WfsBatchCameraDialogCanSwapScanInvalid";
import ApprovedOrRejectedCansCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ApprovedOrRejectedCansCanSwapDialog";
import ReturnCanCameraCanSwapScanIvalidCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReturnCanCameraCanSwapScanIvalidCansDialog";
import { GetQrBarcodeSwapList } from "../Redux/Actions/canSwapingAction";
import CanSwapOrderRequestDetailsDialog from "../../Components/DialogBoxes/CanSwapOrderRequestDetailsDialog";
import EditBatchDetailsDilaog from "./EditBatchDetailsDilaog";
import { LinkQrLessProductToBatch } from "../Redux/Actions/skuWfsDetailsAction";
import { DialogContent } from "@material-ui/core";

export default function WfsOngoingBatchCard({
  ongoingBatch,
  pageNumber,
  setCreateBatchFlag,
  createBatchFlag,
}) {
  const validatedData = useSelector((state) => state.wfsBatchBulkLinking);
  const wfsBatchDetails = useSelector((state) => state.wfsOngoingBatchDetails);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const [showingbatchDialogFlag, setShowingbatchDialogFlag] =
    React.useState(false);
  const [scanQrcodebtn, setScanQrcodebtn] = React.useState(false);
  const [openScanInValidCsnsDialog, setOpenScanInValidCsnsDialog] =
    React.useState(false);
  const [openApprovedRejectedCans, setOpenApprovedRejectedCans] =
    React.useState(false);
  const [updateDetails, setUpdateDetails] = React.useState({});
  const [scanQrCodeDetails, setScanQrCodeDetails] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [openEditBatchDialog, setOpenEditBatchDialog] = React.useState(false);

  const [scanCount, setScanCount] = React.useState(0);
  const [currentBatchId, setCurrentBatchId] = React.useState("");
  const [scanOption, setScanOption] = React.useState("scanner");
  const [value, setValue] = React.useState(1);
  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);
  const [selectedBatch, setSelectedBatch] = React.useState({});
  // const [invalidData, setInvalidData] = React.useState(() =>
  //   localStorage.getItem("invalid_data")
  //     ? JSON.parse(localStorage.getItem("invalid_data"))
  //     : []
  // );
  // const [otherValidData, setOtherValidData] = React.useState(() =>
  //   localStorage.getItem("other_valid_data")
  //     ? JSON.parse(localStorage.getItem("other_valid_data"))
  //     : []
  // );
  const [validData, setValidData] = React.useState(() =>
    localStorage.getItem("valid_data")
      ? JSON.parse(localStorage.getItem("valid_data"))
      : []
  );
  const [currentScannedCodes, setCurrentScannedCodes] = React.useState([]);
  const [showScannedPositionValid, setShowScannedPositionValid] =
    React.useState(false);
  const [showScannedPositionInvalid, setShowScannedPositionInvalid] =
    React.useState(false);
  const [validDataToApi, setValidDataToApi] = React.useState();
  const [locallyScannedStored, setLocallyScannedStored] = React.useState([]);
  const [codesToVerify, setCodesToVerify] = React.useState([]);
  const [batchQuantity, setBatchQuantity] = React.useState(0);
  CustomConsole(scanQrCodeDetails);
  const dispatch = useDispatch();

  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);

  const [batchValidatedData, setBatchValidatedData] = React.useState(() =>
    localStorage.getItem("validatedData")
      ? JSON.parse(localStorage.getItem("validatedData"))
      : []
  );

  React.useEffect(() => {
    setCurrentScannedCodes(() =>
      localStorage.getItem("current_scanned_codes")
        ? JSON.parse(localStorage.getItem("current_scanned_codes"))
        : []
    );
  }, []);

  // React.useEffect(() => {
  //   CustomConsole("validateData");
  //   CustomConsole(validatedData);
  //   if (validatedData?.error === false) {
  //     let batchId = currentBatchId;
  //     const storedData =
  //       JSON.parse(localStorage.getItem("validatedData")) || [];
  //     const isBatchIdAlreadyStored = storedData.some(
  //       (entry) => entry.batchId === currentBatchId
  //     );

  //     // Update or create a new entry in the local storage array
  //     if (currentBatchId !== "" && isBatchIdAlreadyStored === false) {
  //       const newData = {
  //         batchId,
  //         validatedData: validatedData?.validatedData,
  //       };

  //       const updatedData = [...storedData, newData];
  //       setBatchValidatedData(updatedData);
  //       localStorage.setItem("validatedData", JSON.stringify(updatedData));
  //     }

  //     setValidData(validatedData?.validatedData?.user_valid_data);
  //     // setInvalidData(validatedData?.validatedData?.invalid_data);
  //     // setOtherValidData(validatedData?.validatedData?.other_valid_data);
  //   }
  // }, [validatedData]);

  React.useEffect(() => {
    CustomConsole("validateData");
    CustomConsole(validatedData);
    if (validatedData?.error === false) {
      let batchId = currentBatchId;
      const storedData = localStorage.getItem("validatedData")
        ? JSON.parse(localStorage.getItem("validatedData"))
        : [];
      const isBatchIdAlreadyStored = storedData.some(
        (entry) => entry.batchId === currentBatchId
      );

      // Update or create a new entry in the local storage array
      if (currentBatchId !== "") {
        if (!isBatchIdAlreadyStored) {
          // Batch ID is not already stored, create a new entry
          const newData = {
            batchId,
            validatedData: validatedData?.validatedData,
          };
          const updatedData = [...storedData, newData];
          setBatchValidatedData(updatedData);
          const vd = updatedData ? JSON.stringify(updatedData) : [];
          localStorage.setItem("validatedData", vd);
        } else {
          // Batch ID is already stored, update the existing entry
          const updatedData = storedData.map((entry) =>
            entry.batchId === currentBatchId
              ? { ...entry, validatedData: validatedData?.validatedData }
              : entry
          );
          setBatchValidatedData(updatedData);
          const vd = updatedData ? JSON.stringify(updatedData) : [];
          localStorage.setItem("validatedData", vd);
        }
      }

      setValidData(validatedData?.validatedData?.user_valid_data);
      // setInvalidData(validatedData?.validatedData?.invalid_data);
      // setOtherValidData(validatedData?.validatedData?.other_valid_data);
    }
  }, [validatedData]);

  const [ownerDetails, setOwnerDetails] = React.useState([]);

  React.useEffect(() => {
    CustomConsole("batchValidatedData");
    CustomConsole(batchValidatedData);
    batchValidatedData?.map((data) => {
      if (data.validatedData.other_valid_data) {
        if (parseInt(data?.owner_type) === 1) {
          if (data?.ownerDetails[0]?.consumer_details) {
            const ownerData = [
              {
                key: "Owner Type",
                value: "Customer",
              },
              {
                key: "Name",
                value: data?.ownerDetails[0].consumer_details?.fullname,
              },
              {
                key: "Phone number",
                value: data?.ownerDetails[0].consumer_details?.phone
                  ? data?.consumer_details?.phone
                  : "-",
              },
            ];
            setOwnerDetails(ownerData);
          }
        } else if (parseInt(data?.owner_type) === 2) {
          if (data?.ownerDetails[0]?.supplier_details) {
            const ownerData = [
              {
                key: "Owner Type",
                value: "Distributor",
              },
              {
                key: "Name",
                value: data.ownerDetails[0].supplier_details.fullname,
              },

              {
                key: "Phone number",
                value: data.ownerDetails[0].supplier_details.phone
                  ? data.ownerDetails[0].supplier_details.phone
                  : "-",
              },
            ];
            setOwnerDetails(ownerData);
          }
        } else if (parseInt(data?.owner_type) === 3) {
          if (data?.ownerDetails[0]?.wfs_details) {
            const ownerData = [
              {
                key: "Filling Station",
                value: data.ownerDetails[0].wfs_details.company_name,
              },

              {
                key: "Owner name",
                value: data.ownerDetails[0].wfs_details.owner_name,
              },
              {
                key: "Phone number",
                value: data.ownerDetails[0].wfs_details.owner_phone,
              },
            ];
            setOwnerDetails(ownerData);
          }
        }
        if (
          (parseInt(data.ownerType) === 1 || parseInt(data.ownerType) === 2) &&
          data.ownerDetails[0].assigned_to_details
        ) {
          const ownerData = [
            {
              key: "Owner Type",
              value:
                parseInt(data.ownerType) === 1 ? "Customer" : "Distributor",
            },
            {
              key: "Name",
              value: data.ownerDetails[0].assigned_to_details.fullname,
            },

            {
              key: "Phone number",
              value: data.ownerDetails[0].assigned_to_details.phone
                ? data.ownerDetails[0].assigned_to_details.phone
                : "-",
            },
          ];
          CustomConsole("ownerData: ");
          CustomConsole(ownerData);
          setOwnerDetails(ownerData);
        }
      }
    });
  }, [batchValidatedData]);

  React.useEffect(() => {
    CustomConsole("locally scanned stored");
    CustomConsole(locallyScannedStored);
    locallyScannedStored?.map((data) => {
      if (ongoingBatch.batch_id === data.orderId) {
        setCodesToVerify(data.scannedCodes);
      }
    });
  }, [locallyScannedStored]);

  // React.useEffect(() => {
  //   // localStorage.setItem("valid_data", JSON.stringify(validData));
  //   // localStorage.setItem("invalid_data", JSON.stringify(invalidData));
  //   // localStorage.setItem("other_valid_data", JSON.stringify(otherValidData));
  // }, [validData, invalidData, otherValidData]);
  React.useEffect(() => {
    // localStorage.setItem("valid_data", JSON.stringify(validData));
    // localStorage.setItem("invalid_data", JSON.stringify(invalidData));
    // localStorage.setItem("other_valid_data", JSON.stringify(otherValidData));
  }, [validData]);

  React.useEffect(() => {
    CustomConsole("ValidData");
    CustomConsole(validData);
    const transformedArray = validData?.map((item) => ({
      id: item.id,
    }));
    setValidDataToApi(transformedArray);
  }, [validData]);

  React.useEffect(() => {
    CustomConsole("validDataToApi");
    CustomConsole(validDataToApi);
  }, [validDataToApi]);

  const handleCreateBatchCard = (ongoingBatch) => {
    CustomConsole(ongoingBatch);
  };

  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScanQrCodeDetails(decodedText);
      // if (value === 1) {
      //   const newScannedCode = { id: decodedText };
      //   setCurrentScannedCodes((prev) => [...prev, newScannedCode]);
      // }
      if (value === 0) {
        const existingOrder = currentScannedCodes.find(
          (order) => order.orderId === ongoingBatch.batch_id
        );

        // Create a new scanned code object
        CustomConsole("existingOrder");
        CustomConsole(existingOrder);
        const newScannedCode = { id: decodedText };
        if (existingOrder) {
          // If the order exists, update its scannedCodes array
          setCurrentScannedCodes((prev) => {
            return prev.map((order) =>
              order.orderId === ongoingBatch.batch_id &&
              order.scannedCodes.length < ongoingBatch.quantity
                ? {
                    ...order,
                    scannedCodes: [...order.scannedCodes, newScannedCode],
                  }
                : order
            );
          });
        } else {
          // If the order doesn't exist, create a new order object
          setCurrentScannedCodes((prev) => [
            ...prev,
            {
              orderId: ongoingBatch.batch_id,
              scannedCodes: [newScannedCode],
            },
          ]);
        }
      }
    }
  };

  React.useEffect(() => {
    if (scanQrCodeDetails !== "" && value === 1) {
      CustomConsole("Hitting one by one link");
      // dispatch(PutWfsQrlinkAction(reqObj));
      const reqObj = {
        is_inscan: true,
        order_id: ongoingBatch.batch_id,
        data: [{ id: scanQrCodeDetails }],
      };
      CustomConsole(reqObj);
      dispatch(LinkValidCans(reqObj));
      if (scanOption === "camera") {
        setScanQrcodebtn(false);
      }
      setScanQrCodeDetails("");
      setShowingbatchDialogFlag(false);
      setTimeout(() => {
        dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      }, 1200);
    }
  }, [scanQrCodeDetails]);
  CustomConsole(wfsBatchDetails);

  const handleLinkQr = (data) => {
    CustomConsole(currentScannedCodes);
    setCurrentBatchId(data.batch_id);
    setShowingbatchDialogFlag(true);
    setSelectedBatch(data);
  };

  const [startBatchBtn, setStartBatchBtn] = React.useState(false);
  const [stopBatchBtn, setStopBatchBtn] = React.useState(false);
  const handleStartBatch = (data) => {
    const reqObj = {
      batch_id: data.batch_id,
    };
    dispatch(PutStartBatchAction(reqObj));
    setStartBatchBtn(true);
    setSelectedBatch(data);
  };

  const handleStopBatch = (data) => {
    const reqObj = {
      batch_id: data.batch_id,
    };
    dispatch(PutStopBatchAction(reqObj));
    setStopBatchBtn(true);
    setSelectedBatch(data);
  };

  // const handleCloseDialogs = () =>{
  //   setShowingbatchDialogFlag(false)
  // }
  React.useEffect(() => {
    CustomConsole("hi iam running");
    if (wfsBatchDetails.errorStartBatch === false && startBatchBtn === true) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      setStartBatchBtn(false);
      wfsBatchDetails.errorStartBatch = true;
    }
    if (wfsBatchDetails.errorStopBatch === false && stopBatchBtn === true) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      setStopBatchBtn(false);
      wfsBatchDetails.errorStartBatch = true;
    }
  }, [wfsBatchDetails.errorStartBatch, wfsBatchDetails.errorStopBatch]);
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [codesToValidate, setCodesToValidate] = React.useState([]);

  React.useEffect(() => {
    CustomConsole("currentScannedCodes");
    CustomConsole(currentScannedCodes);
    if (currentScannedCodes.length) {
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScannedCodes)
      );
      setLocallyScannedStored(() =>
        localStorage.getItem("current_scanned_codes")
          ? JSON.parse(localStorage.getItem("current_scanned_codes"))
          : []
      );
      currentScannedCodes.map((data) => {
        CustomConsole(data);
        if (ongoingBatch.batch_id === data.orderId) {
          setCodesToValidate(data.scannedCodes);
        }
      });
    }
  }, [currentScannedCodes]);

  //useEffect for hitting batch details api on mount
  React.useEffect(() => {
    dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
  }, []);
  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      // setDecodedResults((prev) => [...prev, qrBarcodeId]);
      setScanQrCodeDetails(qrBarcodeId);
      // if (value === 1) {
      //   const newScannedCode = { id: qrBarcodeId };
      //   setCurrentScannedCodes((prev) => [...prev, newScannedCode]);
      // }

      // storing with order_id in local storage
      if (value === 0) {
        const existingOrder = currentScannedCodes.find(
          (order) => order.orderId === ongoingBatch.batch_id
        );

        // Create a new scanned code object
        CustomConsole("existingOrder");
        CustomConsole(existingOrder);
        CustomConsole(ongoingBatch.quantity);
        const newScannedCode = { id: qrBarcodeId };
        if (existingOrder) {
          // If the order exists, update its scannedCodes array
          setCurrentScannedCodes((prev) => {
            return prev.map((order) =>
              order.orderId === ongoingBatch.batch_id &&
              order.scannedCodes.length < ongoingBatch.quantity
                ? {
                    ...order,
                    scannedCodes: order.scannedCodes.some(
                      (code) => code.id === qrBarcodeId
                    )
                      ? order.scannedCodes // If duplicate, don't add the code
                      : [...order.scannedCodes, newScannedCode],
                  }
                : order
            );
          });
        } else {
          // If the order doesn't exist, create a new order object
          setCurrentScannedCodes((prev) => [
            ...prev,
            {
              orderId: ongoingBatch.batch_id,
              scannedCodes: [newScannedCode],
            },
          ]);
        }
      }
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);

  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };
  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };

  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    setValue(newValue);
    if (newValue === 0) {
      CustomConsole("getting");
      setScanOption("scanner");
    }
  };

  const handleVerifyScanned = () => {
    const reqObj = {
      is_inscan: true,
      order_id: ongoingBatch.batch_id,
      data: codesToValidate,
    };
    CustomConsole(reqObj);
    // dispatch(ValidateScannedCodes(reqObj));
  };

  const [selectedPosition, setSelectedPosition] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [deselectedItems, setDeselectedItems] = React.useState([]);

  const handleItemClick = (scannedPosition, item) => {
    CustomConsole(item);
    CustomConsole(scannedPosition);
    setSelectedPosition((prevSelectedPositions) => {
      // Toggle selection for scanned positions
      if (prevSelectedPositions.includes(scannedPosition)) {
        // If already selected, remove from the array
        return prevSelectedPositions.filter(
          (selectedPosition) => selectedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array
        return [...prevSelectedPositions, scannedPosition];
      }
    });

    setSelectedItems((prevSelectedItems) => {
      // Toggle selection for items
      const isSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.scannedPosition === scannedPosition
      );

      if (isSelected) {
        // If already selected, remove from the array (deselect)
        setDeselectedItems((prevDeselectedItems) => {
          // Make sure it's not repeated in the deselected array
          return [
            ...prevDeselectedItems,
            ...(prevDeselectedItems.includes(item) ? [] : [item]),
          ];
        });

        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.scannedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array (select)
        CustomConsole("Getting in else");
        setDeselectedItems((prevDeselectedItems) =>
          prevDeselectedItems.filter(
            (deselectedItem) =>
              deselectedItem.scannedPosition !== scannedPosition
          )
        );
        return [...prevSelectedItems, item];
      }
    });
  };

  React.useEffect(() => {
    CustomConsole("selectedPosition");
    CustomConsole(selectedPosition);
    CustomConsole("selectedItems");
    CustomConsole(selectedItems);
    CustomConsole("deselectedItems");
    CustomConsole(deselectedItems);
    setValidData(deselectedItems);
  }, [selectedPosition]);

  const handleLinkValidCans = () => {
    const reqObj = {
      is_inscan: true,
      order_id: ongoingBatch.batch_id,
      data: codesToValidate,
    };
    CustomConsole(reqObj);
    dispatch(LinkValidCans(reqObj));
    setTimeout(() => {
      setCurrentScannedCodes([]);
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      setValidData([]);
      // setInvalidData([]);
      setValidDataToApi([]);
      setCodesToValidate([]);
      localStorage.removeItem("valid_data");
      localStorage.removeItem("invalid_data");
      localStorage.removeItem("current_scanned_codes");
      dispatch();
    }, 1000);
  };

  const handleSendRequest = (props) => {
    setOpenScanInValidCsnsDialog(false);
    const reqObj = {
      scan_type: props.scan_type,
      qr_code: props.qr_code,
      // order_id: orderId,
    };
    CustomConsole(reqObj);
  };
  const handleCloseDialogInvalidCans = () => {
    setOpenScanInValidCsnsDialog(false);
  };
  const handleScanInValidCans = (data) => {
    setOpenScanInValidCsnsDialog(true);
    setSelectedBatch(data);
  };
  const handleApprovedOrRejectedCans = (data) => {
    if (data.batch_id && data.batch_id.length) {
      setOpenApprovedRejectedCans(true);
    }
  };

  const handleCloseApprovedRejectedDialog = () => {
    setOpenApprovedRejectedCans(false);
  };
  const handleLinkApprovedQrs = (props) => {
    CustomConsole("-- handleLinkApprovedQrs --");
    CustomConsole(props);
    if (props.approved_empty.length) {
      //Link the approved empty
      const reqObj = {
        order_id: props.order_id,
        data: props.approved_empty,
      };
      CustomConsole(reqObj);
      dispatch(LinkValidCans(reqObj));
    }
    if (props.approved_filled.length) {
      //Link the approved filled
      const reqObj = {
        order_id: props.order_id,
        data: props.approved_filled,
      };
      CustomConsole(reqObj);
      dispatch(LinkValidCans(reqObj));
    }
  };

  const handleEditBatch = (selectedbatch) => {
    CustomConsole(selectedbatch);
    setUpdateDetails(selectedbatch);
    setOpenEditBatchDialog(true);
    setSelectedBatch(selectedbatch);
    // setIsUpdate(true);
  };
  const handlebatch = (props) => {
    CustomConsole("-----------handlebatch----------------");
    if (
      props.batchId !== "" &&
      props.material !== "" &&
      props.lineId !== "" &&
      props.capacity !== "" &&
      props.batchQuantity !== ""
    ) {
      const reqObj = {
        batch_id: props.batchId,
        container_material: props.material,
        line_id: props.lineId,
        container_capacity: props.capacity,
        batch_qty: props.batchQuantity,
      };
      CustomConsole(reqObj);
      dispatch(WfsEditBatchAction(reqObj));
      setOpenEditBatchDialog(false);
      setTimeout(() => {
        dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      }, 1200);
    } else {
      CustomConsole("-----filll---------");
      toast.error("Please Fill All Fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleClose = () => {
    CustomConsole("------------handleClose-------------");
    setOpenEditBatchDialog(false);
  };

  //function for handling batch qty change
  const handelBatchChange = (e) => {
    let { value } = e.target;
    let numericValue = value.replace(/\D/g, "");
    value = numericValue;
    setBatchQuantity(value);
  };

  //function for handling qr less product submit
  const handelLinkQrLessSubmit = () => {
    const reqObj = {
      batch_id: selectedBatch.batch_id,
      quantity: batchQuantity,
    };
    dispatch(LinkQrLessProductToBatch(reqObj));
  };

  CustomConsole(wfsBatchDetails);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: 2,
      }}
    >
      {wfsBatchDetails?.ongoingBatch.map((data, key) => (
        <Card
          sx={{
            minWidth: 275,
            cursor: "pointer",
            ":hover": { backgroundColor: "#c3e4fa" },
          }}
          onClick={() => handleCreateBatchCard(ongoingBatch)}
          elevation={15}
        >
          <CardContent>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Batch ID :{" "}
                </Material.Typography>
                <Material.Typography>{data.batch_id}</Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Cans in batch :{" "}
                </Material.Typography>

                <Material.Typography>{data.quantity}</Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Created at :{" "}
                </Material.Typography>

                <Material.Typography>
                  {data?.created_at?.slice(0, 10)}
                </Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Linked Can Qty :{" "}
                </Material.Typography>

                <Material.Typography>
                  {data.linked_container_qty}
                </Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Capacity :{" "}
                </Material.Typography>

                <Material.Typography>
                  {data.container_capacity}
                </Material.Typography>
              </Material.Box>
              <Material.Box sx={{ display: "flex" }}>
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Material :{" "}
                </Material.Typography>

                <Material.Typography>
                  {data.container_material}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </CardContent>
          <Material.Box sx={{ display: "flex", justifyContent: "center" }}>
            <Material.Box>
              <Material.Box>
                {data?.linked_container_qty !== data?.quantity &&
                data?.is_batch_started === false &&
                data?.is_batch_stopped === false ? (
                  <Material.Button
                    sx={{
                      margin: "10px",
                      ":hover": {
                        backgroundColor: "black",
                      },
                    }}
                    variant="contained"
                    onClick={() => handleLinkQr(data)}
                  >
                    {" "}
                    {data?.product_details?.is_qr ? "Link QR" : "Link Product"}
                  </Material.Button>
                ) : (
                  ""
                )}
              </Material.Box>

              <Material.Box>
                {data?.is_batch_started !== true &&
                data?.linked_container_qty === data?.quantity ? (
                  <Material.Button
                    sx={{
                      margin: "10px",
                      ":hover": {
                        backgroundColor: "black",
                      },
                    }}
                    variant="contained"
                    onClick={() => handleStartBatch(data)}
                  >
                    Start Batch
                  </Material.Button>
                ) : (
                  ""
                )}
              </Material.Box>
              <Material.Box>
                {data?.is_batch_started === true &&
                data?.is_batch_stopped === false &&
                data?.linked_container_qty === data?.quantity ? (
                  <Material.Button
                    sx={{
                      margin: "10px",
                      ":hover": {
                        backgroundColor: "black",
                      },
                    }}
                    onClick={() => handleStopBatch(data)}
                    variant="contained"
                  >
                    Stop Batch
                  </Material.Button>
                ) : (
                  ""
                )}
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ mt: 1.5 }}>
              {(data?.is_batch_started === true &&
                data?.is_batch_stopped === false) ||
              (data?.is_batch_started === false &&
                data?.is_batch_stopped === false) ? (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    ":hover": {
                      backgroundColor: "black",
                    },
                  }}
                  onClick={() => handleEditBatch(data)}
                >
                  Edit
                </Button>
              ) : (
                ""
              )}
            </Material.Box>
          </Material.Box>
          {/* <Box>
          {ongoingBatch.linked_container_qty === ongoingBatch.quantity ? (
            ""
          ) : (
            <Button
              sx={{
                margin: "10px",
                ":hover": {
                  backgroundColor: "black",
                },
              }}
              variant="contained"
              onClick={handleLinkQr}
            >
              {" "}
              Link QR{" "}
            </Button>
          )}
        </Box>

        <Box>
          {ongoingBatch.is_batch_started !== true &&
          ongoingBatch.linked_container_qty === ongoingBatch.quantity ? (
            <Button
              sx={{
                margin: "10px",
                ":hover": {
                  backgroundColor: "black",
                },
              }}
              variant="contained"
              onClick={handleStartBatch}
            >
              Start Batch
            </Button>
          ) : (
            ""
          )}
        </Box>
        <Box>
          {ongoingBatch.is_batch_started === true &&
          ongoingBatch.linked_container_qty === ongoingBatch.quantity ? (
            <Button
              sx={{
                margin: "10px",
                ":hover": {
                  backgroundColor: "black",
                },
              }}
              onClick={handleStopBatch}
              variant="contained"
            >
              Stop Batch
            </Button>
          ) : (
            ""
          )}
        </Box> */}
          <Material.Box>
            {" "}
            <Material.Button
              variant="contained"
              onClick={() => handleScanInValidCans(data)}
              sx={{ backgroundColor: "blue", color: "white", margin: "10px" }}
            >
              <DocumentScanner /> in-valid cans
            </Material.Button>
          </Material.Box>
          <Material.Button
            variant="outlined"
            onClick={() => handleApprovedOrRejectedCans(data)}
            sx={{
              backgroundColor: "black",
              color: "white",
              margin: "10px",
            }}
          >
            Can Swap Requests
          </Material.Button>
        </Card>
      ))}
      {/* <ApprovedOrRejectedCansCanSwapDialog
        openApprovedRejectedCans={openApprovedRejectedCans}
        handleCloseApprovedRejectedDialog={handleCloseApprovedRejectedDialog}
        data={canSwapDeatails.getQrBarcodeSwapList}
      /> */}
      {openEditBatchDialog && (
        <EditBatchDetailsDilaog
          openEditBatchDialog={openEditBatchDialog}
          updateDetails={updateDetails}
          handlebatch={handlebatch}
          handleClose={handleClose}
          wfsBatchDetails={wfsBatchDetails}
        />
      )}
      {selectedBatch?.batch_id &&
      selectedBatch?.batch_id.length &&
      openApprovedRejectedCans ? (
        <CanSwapOrderRequestDetailsDialog
          open={openApprovedRejectedCans}
          handleClose={handleCloseApprovedRejectedDialog}
          order_id={selectedBatch?.batch_id}
          handleLink={handleLinkApprovedQrs}
        />
      ) : null}
      <ReturnCanCameraCanSwapScanIvalidCansDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        setOpenScanInValidCsnsDialog={setOpenScanInValidCsnsDialog}
        handleSendRequest={handleSendRequest}
        handleCloseDialog={handleCloseDialogInvalidCans}
        order_id={selectedBatch.batch_id}
      />
      {showingbatchDialogFlag ? (
        <WfsOngoingBatchCardDialog
          showingbatchDialogFlag={showingbatchDialogFlag}
          // handleClose={handleCloseDialogs}
          setShowingbatchDialogFlag={setShowingbatchDialogFlag}
          setScanQrcodebtn={setScanQrcodebtn}
          scanQrcodebtn={scanQrcodebtn}
        />
      ) : (
        ""
      )}

      {/* {scanQrcodebtn === true ? (
        <Html5QrcodePlugin1
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResultQrCode}
        />
      ) : (
        ""
      )} */}

      <Dialog open={scanQrcodebtn}>
        <DialogContent>
          <>
            {selectedBatch?.product_details?.is_qr ? (
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  padding: "10px",
                }}
              >
                <Tabs value={value} onChange={handleChange} centered>
                  {/* <Tab label="Link Bulk" /> */}
                  <Tab label="Link one by one" />
                </Tabs>
                <FormControl sx={{ marginTop: "15px" }}>
                  <FormLabel>Select Scanning Option</FormLabel>
                  <RadioGroup
                    defaultValue="scanner"
                    value={scanOption}
                    name="radio-buttons-group"
                    sx={{ display: "flex", flexDirection: "row" }}
                    onChange={handleSelectScanOption}
                  >
                    <FormControlLabel
                      value="scanner"
                      control={<Radio />}
                      label="Scanner Device"
                    />
                    {value === 1 && (
                      <FormControlLabel
                        value="camera"
                        control={<Radio />}
                        label="Camera"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                {scanOption === "camera" ? (
                  <>
                    <Html5QrcodePlugin1
                      fps={10}
                      qrbox={250}
                      disableFlip={false}
                      qrCodeSuccessCallback={onNewScanResultQrCode}
                    />

                    {/* Card for valid data */}
                    {value === 0 && (
                      <>
                        <Box sx={{ padding: "10px" }}>
                          {locallyScannedStored.map((data) => {
                            return (
                              <>
                                {data.orderId === ongoingBatch.batch_id && (
                                  <Chip
                                    label={`Current locally scanned: ${
                                      data.orderId === ongoingBatch.batch_id
                                        ? data.scannedCodes.length
                                        : ""
                                    }`}
                                    color="success"
                                  />
                                )}
                              </>
                            );
                          })}
                        </Box>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleVerifyScanned}
                        >
                          Verify Current Scanned
                        </Button>

                        {batchValidatedData?.map((data) => {
                          return (
                            <>
                              {data.batchId === ongoingBatch.batch_id && (
                                <Card
                                  sx={{
                                    minWidth: 275,
                                    backgroundColor: "#32a850",
                                    margin: "10px",
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography>
                                      Valid Cans Scanned:{" "}
                                      {
                                        data.validatedData.user_valid_data
                                          ?.length
                                      }
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        setShowScannedPositionValid(
                                          !showScannedPositionValid
                                        )
                                      }
                                      endIcon={
                                        showScannedPositionValid ? (
                                          <KeyboardArrowDownRoundedIcon />
                                        ) : (
                                          <KeyboardArrowUpRoundedIcon />
                                        )
                                      }
                                    >
                                      Show scanned position
                                    </Button>
                                    {showScannedPositionValid && (
                                      <>
                                        <Typography>
                                          Scanned Position
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {data.validatedData.user_valid_data?.map(
                                            (item) => (
                                              <Grid
                                                item
                                                key={item.scannedPosition}
                                              >
                                                <Paper
                                                  style={{
                                                    width: "50px", // Adjust the width and height as needed
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    cursor: "pointer",
                                                    background:
                                                      selectedPosition.includes(
                                                        item.scannedPosition
                                                      )
                                                        ? "red"
                                                        : "white",
                                                    opacity:
                                                      selectedPosition.includes(
                                                        item.scannedPosition
                                                      )
                                                        ? 0.7
                                                        : 1,
                                                  }}
                                                  elevation={3}
                                                  onClick={() =>
                                                    handleItemClick(
                                                      item.scannedPosition,
                                                      item
                                                    )
                                                  }
                                                >
                                                  {item.scannedPosition}
                                                </Paper>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={handleLinkValidCans}
                                        size="small"
                                      >
                                        Link valid cans
                                      </Button>
                                    </Box>
                                  </CardContent>
                                </Card>
                              )}
                            </>
                          );
                        })}
                        {/* Card for invalid cans  */}
                        {batchValidatedData.map((data) => {
                          return (
                            <>
                              {data.batchId === ongoingBatch.batch_id && (
                                <Card
                                  sx={{
                                    minWidth: 275,
                                    backgroundColor: "#a83232",
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography sx={{ textAlign: "center" }}>
                                      Invalid Cans Scanned:{" "}
                                      {data.validatedData.invalid_data?.length}
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        setShowScannedPositionInvalid(
                                          !showScannedPositionInvalid
                                        )
                                      }
                                      endIcon={
                                        showScannedPositionInvalid ? (
                                          <KeyboardArrowDownRoundedIcon />
                                        ) : (
                                          <KeyboardArrowUpRoundedIcon />
                                        )
                                      }
                                    >
                                      Show scanned position
                                    </Button>
                                    {showScannedPositionInvalid && (
                                      <>
                                        {" "}
                                        <Typography>
                                          Scanned Position
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {data.validatedData.invalid_data?.map(
                                            (item) => (
                                              <Grid
                                                item
                                                key={item.scannedPosition}
                                              >
                                                <Paper
                                                  style={{
                                                    width: "50px", // Adjust the width and height as needed
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                  elevation={3}
                                                >
                                                  {item.scannedPosition}
                                                </Paper>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                  </CardContent>
                                </Card>
                              )}
                            </>
                          );
                        })}

                        {/* Card for other other valid cans */}
                        {batchValidatedData.map((data) => {
                          return (
                            <>
                              {data.batchId === ongoingBatch.batch_id && (
                                <Card
                                  sx={{
                                    minWidth: 275,
                                    backgroundColor: "#eb9534",
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography sx={{ textAlign: "center" }}>
                                      Other valid Cans Scanned:{" "}
                                      {
                                        data.validatedData.other_valid_data
                                          ?.length
                                      }
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        setShowScannedPositionInvalid(
                                          !showScannedPositionInvalid
                                        )
                                      }
                                      endIcon={
                                        showScannedPositionInvalid ? (
                                          <KeyboardArrowDownRoundedIcon />
                                        ) : (
                                          <KeyboardArrowUpRoundedIcon />
                                        )
                                      }
                                    >
                                      Show scanned position
                                    </Button>
                                    {showScannedPositionInvalid && (
                                      <>
                                        {" "}
                                        <Typography>
                                          Scanned Position
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {data.validatedData.other_valid_data?.map(
                                            (item) => (
                                              <Grid
                                                item
                                                key={item.scannedPosition}
                                              >
                                                <Paper
                                                  style={{
                                                    width: "50px", // Adjust the width and height as needed
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                  elevation={3}
                                                >
                                                  {item.scannedPosition}
                                                </Paper>
                                                <Box>
                                                  {ownerDetails.map(
                                                    (data, index) => {
                                                      return (
                                                        <Box
                                                          key={index}
                                                          sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "row",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              color: "white",
                                                              fontSize: {
                                                                xs: "14",
                                                                md: "16px",
                                                              },
                                                              fontWeight: 500,
                                                              // marginRight: "15px",
                                                              textAlign: "left",
                                                              m: 1,
                                                            }}
                                                          >
                                                            {data.key}:
                                                          </Typography>
                                                          <Typography
                                                            sx={{
                                                              color: "white",
                                                              fontSize: {
                                                                xs: "12",
                                                                md: "14px",
                                                              },
                                                              fontWeight: 400,
                                                              textAlign:
                                                                "right",
                                                              m: 1,
                                                            }}
                                                          >
                                                            {data.value}
                                                          </Typography>
                                                        </Box>
                                                      );
                                                    }
                                                  )}
                                                </Box>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                  </CardContent>
                                </Card>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                    {/* <Box sx={{ marginTop: "10px" }}>
                <Button
                  onClick={() => {
                    setOpenLinkFromStartDialog(true);
                    // setCurrentScannedCodes([]);
                    // setValidData([]);
                    // setInvalidData([]);
                    // setOtherValidData([]);
                    // setValidDataToApi([]);
                  }}
                  variant="contained"
                  size="small"
                  color="error"
                >
                  Link From Start
                </Button>
              </Box> */}
                  </>
                ) : (
                  <>
                    <TextField
                      onChange={handleQrBarcodeIdFromScanner}
                      label="QR/Barcode:"
                      value={qrBarcodeId}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {value === 0 && (
                      <>
                        <Box sx={{ padding: "10px" }}>
                          {locallyScannedStored.map((data) => {
                            return (
                              <>
                                {data.orderId === ongoingBatch.batch_id && (
                                  <Chip
                                    label={`Current locally scanned: ${
                                      data.orderId === ongoingBatch.batch_id
                                        ? data.scannedCodes.length
                                        : ""
                                    }`}
                                    color="success"
                                  />
                                )}
                              </>
                            );
                          })}
                        </Box>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleVerifyScanned}
                        >
                          Verify Current Scanned
                        </Button>

                        {batchValidatedData?.map((data) => {
                          return (
                            <>
                              {data.batchId === ongoingBatch.batch_id && (
                                <Card
                                  sx={{
                                    minWidth: 275,
                                    backgroundColor: "#32a850",
                                    margin: "10px",
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography>
                                      Valid Cans Scanned:{" "}
                                      {
                                        data.validatedData.user_valid_data
                                          ?.length
                                      }
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        setShowScannedPositionValid(
                                          !showScannedPositionValid
                                        )
                                      }
                                      endIcon={
                                        showScannedPositionValid ? (
                                          <KeyboardArrowDownRoundedIcon />
                                        ) : (
                                          <KeyboardArrowUpRoundedIcon />
                                        )
                                      }
                                    >
                                      Show scanned position
                                    </Button>
                                    {showScannedPositionValid && (
                                      <>
                                        <Typography>
                                          Scanned Position
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {data.validatedData.user_valid_data?.map(
                                            (item) => (
                                              <Grid
                                                item
                                                key={item.scannedPosition}
                                              >
                                                <Paper
                                                  style={{
                                                    width: "50px", // Adjust the width and height as needed
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    cursor: "pointer",
                                                    background:
                                                      selectedPosition.includes(
                                                        item.scannedPosition
                                                      )
                                                        ? "red"
                                                        : "white",
                                                    opacity:
                                                      selectedPosition.includes(
                                                        item.scannedPosition
                                                      )
                                                        ? 0.7
                                                        : 1,
                                                  }}
                                                  elevation={3}
                                                  onClick={() =>
                                                    handleItemClick(
                                                      item.scannedPosition,
                                                      item
                                                    )
                                                  }
                                                >
                                                  {item.scannedPosition}
                                                </Paper>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={handleLinkValidCans}
                                        size="small"
                                      >
                                        Link valid cans
                                      </Button>
                                    </Box>
                                  </CardContent>
                                </Card>
                              )}
                            </>
                          );
                        })}
                        {/* Card for invalid cans  */}
                        {batchValidatedData.map((data) => {
                          return (
                            <>
                              {data.batchId === ongoingBatch.batch_id && (
                                <Card
                                  sx={{
                                    minWidth: 275,
                                    backgroundColor: "#a83232",
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography sx={{ textAlign: "center" }}>
                                      Invalid Cans Scanned:{" "}
                                      {data.validatedData.invalid_data?.length}
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        setShowScannedPositionInvalid(
                                          !showScannedPositionInvalid
                                        )
                                      }
                                      endIcon={
                                        showScannedPositionInvalid ? (
                                          <KeyboardArrowDownRoundedIcon />
                                        ) : (
                                          <KeyboardArrowUpRoundedIcon />
                                        )
                                      }
                                    >
                                      Show scanned position
                                    </Button>
                                    {showScannedPositionInvalid && (
                                      <>
                                        {" "}
                                        <Typography>
                                          Scanned Position
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {data.validatedData.invalid_data?.map(
                                            (item) => (
                                              <Grid
                                                item
                                                key={item.scannedPosition}
                                              >
                                                <Paper
                                                  style={{
                                                    width: "50px", // Adjust the width and height as needed
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                  elevation={3}
                                                >
                                                  {item.scannedPosition}
                                                </Paper>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                  </CardContent>
                                </Card>
                              )}
                            </>
                          );
                        })}

                        {/* Card for other other valid cans */}
                        {batchValidatedData.map((data) => {
                          return (
                            <>
                              {data.batchId === ongoingBatch.batch_id && (
                                <Card
                                  sx={{
                                    minWidth: 275,
                                    backgroundColor: "#eb9534",
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography sx={{ textAlign: "center" }}>
                                      Other valid Cans Scanned:{" "}
                                      {
                                        data.validatedData.other_valid_data
                                          ?.length
                                      }
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        setShowScannedPositionInvalid(
                                          !showScannedPositionInvalid
                                        )
                                      }
                                      endIcon={
                                        showScannedPositionInvalid ? (
                                          <KeyboardArrowDownRoundedIcon />
                                        ) : (
                                          <KeyboardArrowUpRoundedIcon />
                                        )
                                      }
                                    >
                                      Show scanned position
                                    </Button>
                                    {showScannedPositionInvalid && (
                                      <>
                                        {" "}
                                        <Typography>
                                          Scanned Position
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {data.validatedData.other_valid_data?.map(
                                            (item) => (
                                              <Grid
                                                item
                                                key={item.scannedPosition}
                                              >
                                                <Paper
                                                  style={{
                                                    width: "50px", // Adjust the width and height as needed
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                  elevation={3}
                                                >
                                                  {item.scannedPosition}
                                                </Paper>
                                                <Box>
                                                  {ownerDetails.map(
                                                    (data, index) => {
                                                      return (
                                                        <Box
                                                          key={index}
                                                          sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "row",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              color: "white",
                                                              fontSize: {
                                                                xs: "14",
                                                                md: "16px",
                                                              },
                                                              fontWeight: 500,
                                                              // marginRight: "15px",
                                                              textAlign: "left",
                                                              m: 1,
                                                            }}
                                                          >
                                                            {data.key}:
                                                          </Typography>
                                                          <Typography
                                                            sx={{
                                                              color: "white",
                                                              fontSize: {
                                                                xs: "12",
                                                                md: "14px",
                                                              },
                                                              fontWeight: 400,
                                                              textAlign:
                                                                "right",
                                                              m: 1,
                                                            }}
                                                          >
                                                            {data.value}
                                                          </Typography>
                                                        </Box>
                                                      );
                                                    }
                                                  )}
                                                </Box>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                  </CardContent>
                                </Card>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                    {/* {value === 0 && (
                <Box sx={{ marginTop: "10px" }}>
                  <Button
                    onClick={() => {
                      setOpenLinkFromStartDialog(true);
                      // setCurrentScannedCodes([]);
                      // setValidData([]);
                      // setInvalidData([]);
                      // setOtherValidData([]);
                      // setValidDataToApi([]);
                    }}
                    variant="contained"
                    size="small"
                    color="error"
                  >
                    Link From Start
                  </Button>
                </Box>
              )} */}
                  </>
                )}
              </Paper>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  Enter Quantity to link Qrless product to batch
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                    gap: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Quantity"
                    name="quantity"
                    size="small"
                    value={batchQuantity}
                    onChange={handelBatchChange}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handelLinkQrLessSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="error"
            onClick={() => setScanQrcodebtn(false)}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ResultContainerPlugin1
        results={decodedResults}
        setScanCount={setScanCount}
        scanType={scanOption}
      />
      {openLinkFromStartDialog && (
        <Box>
          <Dialog
            open={openLinkFromStartDialog}
            onClose={openLinkFromStartDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want link from start?"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={() => setOpenLinkFromStartDialog(false)}>
                No
              </Button>
              <Button
                onClick={() => {
                  setCurrentScannedCodes([]);
                  setValidData([]);
                  // setInvalidData([]);
                  // setOtherValidData([]);
                  setBatchValidatedData([]);
                  localStorage.removeItem("validatedData");
                  localStorage.removeItem("current_scanned_codes");
                  setLocallyScannedStored([]);
                  setOpenLinkFromStartDialog(false);
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      <ToastContainer />
    </Box>
  );
}
