/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description BookWater App Authentication Page With Login & SignUP Components
 * @date 13/02/23
 * @version 0.0.1 - Initial Release
 */

import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  GetAvailableProducts,
  GetListofCrates,
} from "../Redux/Actions/SKUProductDetailsAction";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import GroupProductWithCrate from "./groupProductwithCrate";
import ProductCapColor from "./ProductCapColor";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MarkProductStatus from "./MarkProductStatus";
import DamagedHistory from "./DamagedHistory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      // style={{ width: "85vw" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function WfsGroupProductsSku() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = React.useState(2);
  const [productStatustab, setProductStatustab] = useState("history");

  const [crateFilterDetails, setCrateFilterDetails] = React.useState({
    activeStatus: true,
    capacity: "DESC",
    sort_by_name: "ASC",
    sort_by_price: "ASC",
    sort_by_created_at: "DESC",
  });
  //useEffect for getiing available products
  // React.useState(() => {
  //   dispatch(GetAvailableProducts());
  //   dispatch(GetListofCrates(crateFilterDetails));
  // }, []);
  //fuction for handling value change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //function for setting up index value
  const handleChangeIndex = (index) => {
    // setValue(index);
  };

  return (
    <Grid container sx={{ marginTop: "60px" }}>
      <Grid item xs={12}>
        <AppBar
          position="static"
          sx={{
            color: "white",
            borderRadius: "10px",
            background: "#6495ED",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            // variant="scrollable"
            aria-label="full width tabs example"
          >
            <Tab label="Group Products" {...a11yProps(0)} />
            <Tab label="Cancel Group" {...a11yProps(1)} />
            <Tab label="Cap Color" {...a11yProps(2)} />
            <Tab label="Product Status" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {value === 0 && (
            <Box
              value={value}
              index={0}
              dir={theme.direction}
              sx={{
                marginTop: "1rem",
              }}
            >
              <GroupProductWithCrate type="group" />
            </Box>
          )}
          {value === 1 && (
            <Box
              value={value}
              index={1}
              dir={theme.direction}
              sx={{
                marginTop: "1rem",
              }}
            >
              <GroupProductWithCrate type="cancel" />
            </Box>
          )}
          {value === 2 && (
            <Box
              value={value}
              index={2}
              dir={theme.direction}
              sx={{
                marginTop: "1rem",
                marginBottom: "20px",
              }}
            >
              {/* <GroupProductWithCrate type="cancel" />
               */}
              <ProductCapColor />
            </Box>
          )}
          {value === 3 && (
            <Box
              value={value}
              index={2}
              dir={theme.direction}
              sx={{
                marginTop: "1rem",
                marginBottom: "20px",
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={productStatustab}
                exclusive
                onChange={(e) => setProductStatustab(e.target.value)}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value="history">History</ToggleButton>
                <ToggleButton value="mark">Mark Damage</ToggleButton>
              </ToggleButtonGroup>
              {productStatustab == "history" ? (
                <DamagedHistory />
              ) : (
                <MarkProductStatus />
              )}
            </Box>
          )}
        </SwipeableViews>
      </Grid>
    </Grid>
  );
}

export default WfsGroupProductsSku;
