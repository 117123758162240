import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import {
  ToAcceptEmptyCanReq,
  GetEmptyCanOrders,
  GetWFSDetails,
} from "../Redux/Actions/adminEmptyCanOrderAction";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Pagination,
} from "@mui/material";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { ArrowBackIos } from "@mui/icons-material";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Typography } from "@mui/material";
import { GetOrderIdDetails } from "../Redux/Actions/adminEmptyCanOrderAction";
import { styles } from "./ecoStyles";
import { WfsEmptyCanAcceptOrdersSku } from "../Redux/Actions/skuWfsDetailsAction";
import CustomConsole from "../../CustomConsole";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "azure",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function WFSDetailsTable({
  distributerPgNo,
  // setPageNumber,
  wfsDetails,
  orderId,
  setWFSTable,
  scrollWFSTable,
  setEmpDetcard,
  setOpenWFSOrdersDialog,
  handleCloseWFSOrderDialog,
}) {
  const canOrderDetail = useSelector(
    (state) => state.emptyCanOrderData.orderIdDetails
  );
  const [open, setOpen] = React.useState(false);
  const [wfsId, setWfsId] = React.useState("");
  const [toastFlag, setToastFlag] = React.useState(false);
  const [currentPgNum, setCurrentPgNum] = React.useState(1);
  const [pageNumbers, setPageNumbers] = React.useState(1);

  const [arrowBackwordBtn, setArrowBackwordBtn] = React.useState(false);
  const [arrowForwordBtn, setArrowForwordBtn] = React.useState(false);
  const [pageChange, setPageChange] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);

  const dispatch = useDispatch();
  const acceptOrderWfsResponse = useSelector(
    (state) => state.emptyCanOrderData
  );

  React.useEffect(() => {
    if (toastFlag === true && acceptOrderWfsResponse.error === false) {
      toast.success(acceptOrderWfsResponse.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(GetEmptyCanOrders(distributerPgNo - 1));
      dispatch(GetOrderIdDetails(orderId));

      setToastFlag(false);
      setWFSTable(false);
    }
    if (toastFlag === true && acceptOrderWfsResponse.error === true) {
      toast.error(acceptOrderWfsResponse.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setToastFlag(false);
    }
  }, [acceptOrderWfsResponse]);

  const acceptOrderWfs = () => {
    const reqObj = {
      wfs_id: wfsId,
    };
    CustomConsole(reqObj);
    CustomConsole("in");
    if (reqObj.order_id !== "" && reqObj.wfs_id !== "") {
      CustomConsole("incondition");
      // dispatch(ToAcceptEmptyCanReq(obj));
      dispatch(WfsEmptyCanAcceptOrdersSku(orderId, reqObj));
      setOpen(false);
      setToastFlag(true);
      handleCloseWFSOrderDialog();
    }

    // dispatch(ToAcceptEmptyCanReq(obj));
  };

  const handleClickOpen = (id) => {
    setWfsId(id);
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handlepageChange = (event, value) => {
    CustomConsole(value);
    setCurrentPgNum(value);
  };

  React.useEffect(() => {
    if (pageNumbers > 0) {
      const obj1 = {
        page_number: pageNumbers - 1,
        order_id: orderId,
      };
      CustomConsole(obj1);
      dispatch(GetWFSDetails(obj1));
    }
  }, [pageNumbers]);

  const clickForword = () => {
    if (pageNumbers > 0) {
      setPageNumbers(pageNumbers + 1);
    }
    setArrowForwordBtn(true);
  };

  const clickBackword = () => {
    CustomConsole(pageNumbers);
    if (pageNumbers > 1) {
      setPageNumbers(pageNumbers - 1);
    }
    setArrowBackwordBtn(true);
  };

  return (
    <>
      {wfsDetails ? (
        <>
          <Box sx={{ maxWidth: { xs: 300, lg: 1, md: 1, sm: 1 } }}>
            <TableContainer ref={scrollWFSTable} component={Paper}>
              <Table aria-label="customized table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={5}
                      sx={styles.TableHeading}
                      size="medium"
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        WFS Details List
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="center">
                      Company Name
                    </StyledTableCell>
                    <StyledTableCell align="center">Pincode</StyledTableCell>
                    <StyledTableCell align="center">
                      Filled Containers
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Ongoing Containers
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Empty Containers
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wfsDetails.map((row, idx) => (
                    <StyledTableRow
                      onClick={() => {
                        handleClickOpen(row.wfs_id);
                      }}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "#036bfc",
                        },
                      }}
                      key={idx}
                    >
                      <StyledTableCell align="center">
                        {row.company_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.pincode}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.filled_containers}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.ongoing_containers}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.empty_containers}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "30px",
            }}
          >
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={clickBackword}
            >
              <ArrowBackIos />
            </Button>

            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                background:
                  "radial-gradient(rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumbers}
            </Typography>

            <Button
              sx={{
                cursor: "pointer",
              }}
              disabled={pageStatus}
              onClick={clickForword}
            >
              <ArrowForwardIos />
            </Button>
          </Box>

          {/* <Pagination
            count={2}
            page={currentPgNum}
            onChange={handlepageChange}
            color="primary"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "15px",
            }}
          /> */}

          <Dialog
            open={open}
            onClose={handleClickClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you want to accept ?"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleClickClose}>Disagree</Button>
              <Button onClick={acceptOrderWfs} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
}

export default WFSDetailsTable;
