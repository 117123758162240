import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  RadioGroup,
  Radio,
} from "@mui/material";
import * as Material from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { GetSupplierAssignUnassigedPincodeSlotAgent } from "../Redux/Actions/supliersAction";

function StatusTable({ setTableView, tableview }) {
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const [radioValues, setRadioValues] = React.useState("ALL");
  const [radioSlotValues, setRadioSlotValues] = React.useState("Selected");
  const [radioAgentValues, setRadioAgentValues] = React.useState("Selected");

  React.useEffect(() => {
    dispatch(GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 0 }));
  }, []);

  const handleRadioAgentValues = (e) => {
    setRadioAgentValues(e.target.value);
    if (e.target.value === "Selected") {
      dispatch(
        GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 400 })
      );
    } else if (e.target.value === "UnSelected") {
      dispatch(
        GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 300 })
      );
    }
  };
  const handleRadioSlotValues = (e) => {
    setRadioSlotValues(e.target.value);
    if (e.target.value === "Selected") {
      dispatch(
        GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 200 })
      );
    } else if (e.target.value === "UnSelected") {
      dispatch(
        GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 100 })
      );
    }
  };
  const handleRadioValues = (e) => {
    setRadioValues(e.target.value);
    if (e.target.value === "ALL") {
      dispatch(GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 0 }));
    }
    if (e.target.value === "AGENT") {
      dispatch(
        GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 400 })
      );
      setRadioAgentValues("Selected");
    }
    if (e.target.value === "SLOT") {
      dispatch(
        GetSupplierAssignUnassigedPincodeSlotAgent({ assign_code: 200 })
      );
      setRadioSlotValues("Selected");
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box component={Paper} elevation={3} width={"85%"}>
          <FormControl>
            <RadioGroup
              row
              size="small"
              value={radioValues}
              onChange={handleRadioValues}
            >
              <FormControlLabel value="ALL" control={<Radio />} label="ALL" />

              <FormControlLabel value="SLOT" control={<Radio />} label="SLOT" />
              <FormControlLabel
                value="AGENT"
                control={<Radio />}
                label="AGENT"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {/* <ToggleButtonGroup
          color="primary"
          value={tableview}
          exclusive
          onChange={() => {
            setTableView((prev) => !prev);
          }}
          aria-label="Platform"
          size="small"
        >
          <ToggleButton value={true}>
            <TableViewIcon />
          </ToggleButton>
        </ToggleButtonGroup> */}
      </Box>
      {radioValues === "SLOT" ? (
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            size="small"
            value={radioSlotValues}
            onChange={handleRadioSlotValues}
          >
            <FormControlLabel
              value="Selected"
              control={<Radio />}
              label="Selected"
            />
            <FormControlLabel
              value="UnSelected"
              control={<Radio />}
              label="Un-Selected"
            />
          </RadioGroup>
        </FormControl>
      ) : (
        radioValues === "AGENT" && (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              size="small"
              value={radioAgentValues}
              onChange={handleRadioAgentValues}
            >
              <FormControlLabel
                value="Selected"
                control={<Radio />}
                label="Selected"
              />
              <FormControlLabel
                value="UnSelected"
                control={<Radio />}
                label="Un-Selected"
              />
            </RadioGroup>
          </FormControl>
        )
      )}
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ bgcolor: "#30A2F3" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Pincode</TableCell>
              <TableCell sx={{ color: "white" }}>Area</TableCell>
              <TableCell sx={{ color: "white" }} align="right">
                Total Orders
              </TableCell>
              {/* <TableCell sx={{ color: "white" }} align="right">
                Order Type
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierDeliveries.getSupplierAssignUnassigedPincodeSlotAgent !==
              undefined &&
            supplierDeliveries.getSupplierAssignUnassigedPincodeSlotAgent !==
              null &&
            supplierDeliveries.getSupplierAssignUnassigedPincodeSlotAgent
              .length > 0 ? (
              <>
                {supplierDeliveries.getSupplierAssignUnassigedPincodeSlotAgent.map(
                  (row) => (
                    <TableRow
                      // key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.pincode}
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px" }}>
                        {row.areaname}
                      </TableCell>
                      <TableCell align="right">{row.total_orders}</TableCell>
                      {/* <TableCell align="right">{row.fat}</TableCell> */}
                    </TableRow>
                  )
                )}
              </>
            ) : (
              <Material.Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                No Data Found !!
              </Material.Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default StatusTable;
