import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch } from "react-redux";
// import { EditOrderUser } from "../../../Redux/actions/editOrderAction";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { EditOrderUser } from "../Redux/Actions/adminEditOrderAction";
import {
  GetNewSearchOrderDetails,
  GetViewOrderDetails,
} from "../Redux/Actions/ordersAdminAction";
// import { GetPdwPaymentPendingOrders } from "../../../Redux/actions/ordersAction";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";

import CustomConsole from "../../CustomConsole";
export default function EditPaymentDialog(props) {
  CustomConsole("-------EditPaymentDialog---------");
  CustomConsole(props);
  const dispatch = useDispatch();
  const editPaymentDetails = useSelector((state) => state.editOrderDetails);
  const [editConfirmFlag, setEditConfirmFlag] = React.useState(false);
  const [editedPaymentMethod, setEditedPaymentMethod] = React.useState("");

  React.useEffect(() => {
    CustomConsole("-----edit payment details----");
    CustomConsole(props.currentPaymentMethod);
    CustomConsole(props.paymentType);
    CustomConsole(editPaymentDetails);
    if (editPaymentDetails.error === false && editConfirmFlag === true) {
      toast.dark(editPaymentDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "success",
      });
      setEditConfirmFlag(false);
      // dispatch(GetViewOrderDetails(props.orderId));
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 30,
        page_number: 1,
        order_ids: [props.orderId],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
      props.setIsEditPaymentDialogOpen(false);
      props.setIsPaymentReasonChoosen(false);
    } else if (editPaymentDetails.error === true && editConfirmFlag === true) {
      toast.dark(editPaymentDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
      setEditConfirmFlag(false);
      props.setIsEditPaymentDialogOpen(false);
      props.setIsPaymentReasonChoosen(false);
    }
  }, [editPaymentDetails]);

  const handleCancel = () => {
    props.setIsEditPaymentDialogOpen(false);
    props.setIsPaymentReasonChoosen(false);
    props.setCustomReasonPay("");
  };

  const handlePaymentEditConfirm = () => {
    setEditConfirmFlag(true);
    props.setCustomReasonPay("");
  };

  const handleChangePaymentMethod = (event) => {
    CustomConsole(event.target.value);
    setEditedPaymentMethod(event.target.value);
  };

  React.useEffect(() => {
    const reqObj = {
      payment_method: editedPaymentMethod,
      edit_reason:
        props.editPaymentReason === "999"
          ? props.customReasonPay
          : props.editPaymentReason,
      user_id: props.userId,
    };
    if (editConfirmFlag === true) {
      dispatch(EditOrderUser(props.orderId, reqObj));
    }
  }, [editConfirmFlag]);
  return (
    <div>
      <Dialog
        open={props.isEditPaymentDialogOpen}
        // onClose={props.isEditPaymentDialogOpen}
      >
        <DialogContent>
          <Box>
            <Box sx={{ padding: "10px" }}>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                Choose your payment method
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <FormControl onChange={handleChangePaymentMethod}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {props.currentPaymentMethod === "RAZORPAY" ||
                  props.currentPaymentMethod === "PAID THROUGH RAZORPAY" ? (
                    <>
                      <FormControlLabel
                        value="WATERWALLET"
                        control={<Radio />}
                        label="Water wallet"
                      />
                      <FormControlLabel
                        value="PAYSHARP"
                        control={<Radio />}
                        label="UPI"
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {props.currentPaymentMethod === "PAYSHARP" && (
                    <>
                      {" "}
                      <FormControlLabel
                        value="RAZORPAY"
                        control={<Radio />}
                        label="Razorpay"
                      />
                      <FormControlLabel
                        value="WATERWALLET"
                        control={<Radio />}
                        label="Water wallet"
                      />
                    </>
                  )}

                  {props.currentPaymentMethod === "WALLET" ||
                  props.currentPaymentMethod === "WATERWALLET" ? (
                    <>
                      <FormControlLabel
                        value="RAZORPAY"
                        control={<Radio />}
                        label="Razorpay"
                      />
                      <FormControlLabel
                        value="PAYSHARP"
                        control={<Radio />}
                        label="UPI"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                padding: "10px",
              }}
            >
              <Box>
                <Button variant="contained" size="small" onClick={handleCancel}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handlePaymentEditConfirm}
                  sx={appColors.commonBtnColor}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
