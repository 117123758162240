import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  GetContainerDepositDetails,
  UpdateContainerDepositDetails,
} from "../Redux/Actions/adminContainerDetailsAction";
import { ToastContainer, toast } from "react-toastify";
import { styles } from "./containerDetailsCardStyles";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";

const label = { inputProps: { "aria-label": "Switch demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundImage: appColors.commonLayoutColor.backgroundImage,
    fontWeight: "600",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function DetailsEditTable({ containerDetails }) {
  const dispatch = useDispatch();
  const adminContainerData = useSelector((state) => state.adminContainerData);
  const [disabledEdit, setDisabledEdit] = React.useState(true);
  const [material, setMaterial] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  const [lifeSpan, setLifeSpan] = React.useState(0);
  const [updateDetailsFlag, setUpdateDetailsFlag] = React.useState(false);
  React.useEffect(() => {
    CustomConsole(adminContainerData);
    if (adminContainerData.error === false) {
      adminContainerData.containerDetails.map((data) => {
        if (data.container_material === "PET") {
          setAmount(data.amount);
          setLifeSpan(data.life_span);
          setIsActive(data.is_active);
        }
      });
    }

    if (adminContainerData.error === false && updateDetailsFlag === true) {
      toast.success(adminContainerData.updateMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateDetailsFlag(false);
      dispatch(GetContainerDepositDetails());
    }
  }, [adminContainerData]);

  const handleTableClick = (material) => {
    setMaterial(material);
    if (material === "PET") {
      setDisabledEdit(false);
    } else {
      CustomConsole("Can edit only pet containers");
    }
  };

  const handleChangeSwitch = (event) => {
    CustomConsole(material);
    if (material === "PET") {
      setIsActive(event.target.checked);
    }
  };

  const handleUpdateDetails = () => {
    const reqObj = {
      twenty_ltrs: {
        pet: { amount: amount, is_active: isActive, life_span: lifeSpan },
      },
    };
    dispatch(UpdateContainerDepositDetails(reqObj));
    setUpdateDetailsFlag(true);
  };
  return (
    <Box
      sx={{
        marginTop: { lg: "4em", sm: "1em" },
        width: { lg: 1, md: 1, sm: 1, xs: 320 },
        overflow: "auto",
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontSize: { xs: "12px" } }}>
                Material
              </StyledTableCell>
              <StyledTableCell sx={{ fontSize: { xs: "12px" } }}>
                Price
              </StyledTableCell>
              <StyledTableCell sx={{ fontSize: { xs: "12px" } }}>
                Life span
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontSize: { xs: "12px" } }}>
                Availability
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <Tooltip title="Select to edit details" placement="left-start">
            <TableBody>
              {containerDetails &&
                containerDetails.length &&
                containerDetails.map((row, key) => (
                  <StyledTableRow
                    key={key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "none",
                        backgroundColor: "#56b1ee",
                      },
                    }}
                    onClick={() => handleTableClick(row.container_material)}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={styles.FontSize}
                    >
                      {row.container_material}
                    </StyledTableCell>
                    {row.container_material === "PET" ? (
                      <StyledTableCell>
                        {" "}
                        <TextField
                          id="outlined-basic"
                          label="Price"
                          variant="outlined"
                          size="small"
                          sx={{ width: { lg: 1, md: 1, xs: "4em" } }}
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 3);
                          }}
                          value={amount}
                          onChange={(event) => setAmount(event.target.value)}
                          // disabled={
                          //   row.container_material === "PET" ? disabledEdit : true
                          // }
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          label="Price"
                          variant="outlined"
                          size="small"
                          disabled={
                            row.container_material === "PET"
                              ? disabledEdit
                              : true
                          }
                        />
                      </StyledTableCell>
                    )}
                    {row.container_material === "PET" ? (
                      <StyledTableCell>
                        {" "}
                        <TextField
                          id="outlined-basic"
                          label="Life span"
                          variant="outlined"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          type="number"
                          sx={{ width: { lg: 1, md: 1, xs: "4em" } }}
                          size="small"
                          value={lifeSpan}
                          onChange={(event) => setLifeSpan(event.target.value)}
                          disabled={
                            row.container_material === "PET"
                              ? disabledEdit
                              : true
                          }
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>
                        {" "}
                        <TextField
                          id="outlined-basic"
                          label="Life span"
                          variant="outlined"
                          size="small"
                          disabled={
                            row.container_material === "PET"
                              ? disabledEdit
                              : true
                          }
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      {" "}
                      <Switch
                        {...label}
                        checked={
                          row.container_material === "PET"
                            ? isActive
                            : row.is_active
                        }
                        onChange={handleChangeSwitch}
                        disabled={
                          row.container_material === "PET" ? disabledEdit : true
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Tooltip>
        </Table>
      </TableContainer>
      <Box sx={{ padding: "10px" }}>
        <Button
          variant="contained"
          size="small"
          sx={appColors.commonBtnColor}
          onClick={handleUpdateDetails}
        >
          Update Details
        </Button>
      </Box>
      <ToastContainer />
    </Box>
  );
}
