import React from "react";
import * as Material from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import CustomConsole from "../../CustomConsole";
import { Button } from "@mui/base";

function QrLessDialog(props) {
  CustomConsole(props);
  const [count, setCount] = React.useState();

  const [productsQty, setProductsQty] = React.useState([]);
  CustomConsole(productsQty);
  React.useEffect(() => {
    setProductsQty([
      { products: props.qrlessData.order_qty * props.qrlessData.sku_qty },
    ]);
  }, [props]);

  const hadleSubmit = () => {
    props.hadleSubmit({
      order_id: props.orderid,
      capacity: props.qrlessData.capacity,
      material: props.qrlessData.material,
      quantity: parseInt(count),
    });
    setCount("");
  };
  const handleClose = () => {
    props.handleClose();
    setCount("");
  };
  const handleChangeQuantity = (event) => {
    setCount(event.target.value);
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setCount(() => event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (
      event.key === "-" || // Disallow the '-' key
      (event.key === "0" &&
        event.target.selectionStart === 0 &&
        event.target.selectionEnd === 0) // Disallow '0' at the beginning
    ) {
      event.preventDefault();
    }
  };
  return (
    <Material.Dialog open={props.openQrlessDialog}>
      <Material.DialogContent>
        <Material.Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
          <Material.CardContent>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
              }}
            >
              <Material.Typography sx={{ fontWeight: "bold", color: "blue" }}>
                {props.orderid}
              </Material.Typography>
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Product Qty:{" "}
                {props.qrlessData.order_qty * props.qrlessData.sku_qty}
              </Material.Typography>
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Received Empty: {props.qrlessData.received_empty}
              </Material.Typography>
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Submitted Filled: {props.qrlessData.submitted_filled}
              </Material.Typography>
            </Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "1rem",
              }}
            >
              {/* <Material.TextField
                label="Enter Quantity"
                size="small"
                autoFocus
                value={count}
                onChange={handleChangeQuantity}
                InputLabelProps={{ shrink: true }}
              /> */}

              <Material.Box sx={{ m: 2 }}>
                <Material.Autocomplete
                  sx={{ width: 220 }}
                  options={productsQty}
                  autoHighlight
                  getOptionLabel={(productsQty) => `${productsQty.products}`}
                  onChange={(_, newValue) => {
                    setCount(newValue ? newValue.products : ""); // Set the selected description as the value
                  }}
                  renderOption={(props, productsQty) => (
                    <Material.Box
                      component="li"
                      key={productsQty.products}
                      // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {productsQty.products}
                    </Material.Box>
                  )}
                  renderInput={(params) => (
                    <Material.TextField
                      {...params}
                      size="small"
                      InputLabelProps={{ style: { backgroundColor: "white" } }}
                      label="Enter Or Select Quantity"
                      value={count}
                      key={params.products}
                      onKeyDown={handleInputKeyDown}
                      inputProps={{
                        maxLength: 10, // Optional: You can set the maximum length if needed
                        onInput: handleInputChange, // Attach the input event handler
                        ...params.inputProps,
                        autoComplete: "off",
                        style: {
                          backgroundColor: "lightyellow",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  )}
                />
              </Material.Box>
            </Material.Box>
          </Material.CardContent>
          <Material.Box
            sx={{
              width: "100%",
              bgcolor: "#F0FFFF",
              height: "30px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Material.Typography sx={{ marginLeft: "1rem" }}>
              Order Qty:{count}
            </Material.Typography>
          </Material.Box>
        </Material.Card>
      </Material.DialogContent>
      <Material.DialogActions>
        <Material.Button variant="contained" onClick={hadleSubmit}>
          submit
        </Material.Button>
        <Material.Button
          variant="contained"
          color="error"
          onClick={handleClose}
        >
          close
        </Material.Button>
      </Material.DialogActions>
    </Material.Dialog>
  );
}

export default QrLessDialog;
