import * as React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import CustomConsole from "../../CustomConsole";
import {
  GetDeliverySlotsList,
  GetNewSearchOrderDetails,
  GetSearchOrderDetails,
  // GetOrderDeliverySlots,
  GetViewOrderDetails,
  ToGetOtp,
} from "../Redux/Actions/ordersAdminAction";
import { useSelector } from "react-redux";
// import Stack from "@mui/material/Stack";
// import LinearProgress from "@mui/material/LinearProgress";
import { ordersStatus } from "../../Components/AppMeta/appMetaConfig";
import { CSVLink } from "react-csv";
// import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { DELIVERY_TYPE } from "../../Components/AppMeta/appMetaConfig";
import "../Common/drawer.css";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { FcInTransit } from "react-icons/fc";
import moment from "moment-timezone";
import { styles } from "./styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { createTheme } from "@mui/material/styles";
import "./tableHeader.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const useStyles = makeStyles(() => ({
  // root: {
  //   flexGrow: 1,
  //   padding: "0px",
  // },

  // tableContainer: {
  //   overflowX: "auto",
  //   "& .MuiTable-root": {
  //     width: "100%",
  //     tableLayout: "fixed",
  //   },
  //   maxHeight: "90vh",
  //   "& .MuiTableCell-root": {
  //     padding: "10px",
  //   },
  // },
  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#036bfc",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: "rgb(208,221,242)",
    backgroundImage:
      "radial-gradient(circle, rgba(248,238,194,1)  0%, rgba(246,244,234,1) 100%)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AllOrdersTable({
  allOrders,
  setOrderDetails,
  setNewOrderDetails,
  newOrderDetails,
  setIsEditOrderDetailsDialog,
  pageReferesh,
  setPageNumber,
  pageNumber,
  disableBtn,
  setdisableBtn,
  setOrderStatus,
  setSortOrder,
  setDistNum,
  orderStatusDelivery,
  sortOrder,
  setSearchOrderFlag,
  distNum,
  deliveryStatusOrders,
  setDeliveryStatus,
  openDialog,
  setOpenDialog,
  tableView,
  setTableView,
  pageSize,
  setPageSize,
  paginationModel,
  setPaginationModel,
  otherFilterObj,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);

  const [selectedOrderId, setSelectedOrderId] = React.useState("");
  const [tableOrdersBtn, setTableOrdersBtn] = React.useState(false);
  const [openAcco, setOpenAcco] = React.useState(null);
  const [testArr, setTestArr] = React.useState([]);
  const [newToggValue, setNewToggValue] = React.useState({});
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const [newToggNo, setNewToggNo] = React.useState(0);
  const classes = useStyles();

  React.useEffect(() => {
    CustomConsole(allOrders);
  }, [allOrders]);

  const handleOrderListClick = (orderId) => {
    CustomConsole(orderId);
    setSelectedOrderId(orderId);
    setTableOrdersBtn(true);
    setOrderDetails(orderDetailsData.orderDetails);
    setNewOrderDetails(orderDetailsData.newallOrderDetails);
    // dispatch(GetOrderDeliverySlots(orderId));
    dispatch(GetDeliverySlotsList());
    setIsEditOrderDetailsDialog(true);
  };

  const handleCellClick = (params) => {
    if (params.field === "consumer_name" && params.row.consumer_phone) {
      // CustomConsole(params.row.consumer_name);

      CustomConsole(params.row.delivery_address.contact_phone);
      // Redirecting to users page with the users selected consumer
      const url = `/dashboard/UserDetailsMain?phoneNumber=${params.row.consumer_phone}`;
      history.push(url);
    }
    if (params.field === "consumer_phone" && params.row.consumer_phone) {
      // CustomConsole(params.row.consumer_name);
      CustomConsole(params.row.delivery_address.contact_phone);
      // Redirecting to users page with the users selected consumer
      const url = `/dashboard/UserDetailsMain?phoneNumber=${params.row.consumer_phone}`;
      history.push(url);
    }
  };
  const handleOrderListContactName = (contactname) => {
    const url = `/dashboard/UserDetailsMain?phoneNumber=${contactname}`;
    history.push(url);
  };
  React.useEffect(() => {
    CustomConsole(".........This is the data you are searching.........");
    CustomConsole(orderDetailsData);
    setOrderDetails(orderDetailsData.orderDetails);
    setNewOrderDetails(orderDetailsData.newallOrderDetails);
    // const totalOrderQty = orderDetailsData.allOrderDetails?.reduce((acc, curr) => {
    //   if (curr.order_qty) {
    //     acc += curr.ackaged_drinking_water
    //     ?.twentyltr_capacity_qty;
    //   }
    //   if (curr.products) {
    //     acc += curr.products.reduce((subAcc, subCurr) => {
    //       return subAcc + subCurr.quantity;
    //     }, 0);
    //   }
    //   return acc;

    // }, 0);
    // CustomConsole(totalOrderQty)
  }, [orderDetailsData]);

  React.useEffect(() => {
    if (tableOrdersBtn === true) {
      CustomConsole("Hitting view order details api...");
      // dispatch(GetViewOrderDetails(selectedOrderId));
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 30,
        page_number: 1,
        order_ids: [selectedOrderId],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
      // dispatch(ToGetOtp(selectedOrderId));
      setTableOrdersBtn(false);
    }
  }, [tableOrdersBtn]);

  React.useEffect(() => {}, []);

  const orderStatus = (order_status) => {
    switch (order_status) {
      case ordersStatus.ORDER_STATUS_NEW_REQUEST:
        return "NEW REQUEST";
      case ordersStatus.ORDER_STATUS_CANCELLED:
        return "CANCELLED";
      case ordersStatus.ORDER_STATUS_ACCEPTED:
        return "ACCEPTED ";
      case ordersStatus.ORDER_STATUS_REJECTED:
        return "REJECTED";
      case ordersStatus.ORDER_STATUS_DELIVERED:
        return "DELIVERED";
      default:
        return "";
    }
  };
  const orderStatusLabels = {
    100: "New Request",
    200: "Accepted",
    300: "Rejected",
    400: "Cancelled",
    500: "Delivered",
  };

  const getOrderStatusColor = (status) => {
    switch (status) {
      case 100:
        return "blue";
      case 200:
        return "orange";
      case 300:
        return "brown";
      case 400:
        return "red";
      case 500:
        return "green";
      default:
        return "black";
    }
  };
  let result = [];
  function toCSV(items) {
    if (items) {
      items.map((data) => {
        // CustomConsole("AAAAA: ");
        // CustomConsole(data);
        result.push({
          order_id: data.order_id,
          twenty_ltr_quantity:
            data.packaged_drinking_water.twentyltr_capacity_qty,
          order_price: data.order_price,
          order_type: data.order_type,
          pincode: data.pincode,
          area_name: data.areaname,
          booking_date: data.booking_date,
          delivery_date: data.delivery_date,
          payment_method: data.payment_method,
          delivery_slot: data.packaged_drinking_water.delivery_slots
            ? data.packaged_drinking_water.delivery_slots
                .admin_selected_delivery_slot
              ? data.packaged_drinking_water.delivery_slots
                  .admin_selected_delivery_slot
              : data.packaged_drinking_water.delivery_slots
                  .supplier_selected_delivery_slot
              ? data.packaged_drinking_water.delivery_slots
                  .supplier_selected_delivery_slot
              : "-"
            : "-",
          order_status:
            data.order_status == 100
              ? "NEW REQUEST"
              : data.order_status == 200
              ? "ONGOING"
              : data.order_status == 400
              ? "CANCELLED"
              : data.order_status == 500
              ? "DELIVERED"
              : "REJECTED",
          consumer_name: data.consumer_name,
          consumer_phone: data.consumer_phone,
          delivery_otp: data.delivery_otp,
          supplier_name: data.supplier_name,
        });
        // result.push(a.packaged_drinking_water);
        // result.push(a.delivery_address);
        // result.push(a.payment_details);
        // result.push(a.packaged_drinking_water.delivery_slots);
      });
    }
    setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };
  const pageNumberForward = () => {
    if (allOrders.length > 0) {
      setPageNumber(pageNumber + 1);
      setSearchOrderFlag(true);
    }
  };
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      setdisableBtn(false);
      setSearchOrderFlag(true);
    }
  };

  const dateConverter = (dateText) => {
    const momentDate = moment(dateText);
    const formattedDate = momentDate.format("DD-MM-YYYY");
    return formattedDate;
  };

  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    return momentEndDate;
  };

  const openAccoFun = (index) => {
    if (index !== openAcco) {
      setOpenAcco(index);
    } else if (index === openAcco) {
      setOpenAcco(null);
    }
  };
  const handelIcon = (event, index) => {
    event.stopPropagation();
    setOpen(index);
    setOpenFlag(!openFlag);
  };

  /**
   * @author Lebansty Valan
   * @date 03/05/23
   * @description This function is to convert the moment date to local time-zone date.
   * @param {date:string}
   * @return {orderedOn}
   */
  const orderedDateConverter = (date) => {
    let orderedOn = moment.utc(date).format("DD-MM-YYYY");
    return orderedOn;
  };

  /**
   * @author Lebansty Valan
   * @date 03/05/23
   * @description This function is to seperete the time from the moment date.
   * @param {dateTime:string}
   * @return {time}
   */
  const timeSeperator = (dateTime) => {
    const date = moment.utc(dateTime);
    const time = date.local().format("LT");
    return time;
  };

  // New Table Variables and functions
  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 70,
      valueGetter: (params) =>
        pageNumber > 1 ? pageNumber * 10 + params.row.id - 10 : params.row.id,
    },
    { field: "order_id", headerName: "Order ID", width: 150 },
    {
      field: "delivery_otp",
      headerName: "OTP",
      width: 120,
      valueGetter: (params) => params.row.delivery_otp || "---",
      renderCell: (params) => (
        <div
          style={{
            color:
              params.row.packaged_drinking_water !== undefined &&
              params.row.packaged_drinking_water.delivery_otp_status !==
                undefined &&
              parseInt(
                params.row.packaged_drinking_water.delivery_otp_status
              ) === 200
                ? "red"
                : "green",
            textDecorationLine:
              params.row.packaged_drinking_water !== undefined &&
              params.row.packaged_drinking_water.delivery_otp_status !==
                undefined &&
              parseInt(
                params.row.packaged_drinking_water.delivery_otp_status
              ) === 200
                ? "line-through"
                : "none",
          }}
        >
          {params.row.delivery_otp || "---"}
        </div>
      ),
    },
    { field: "pincode", headerName: "Pincode", width: 120 },
    {
      field: "areaname",
      headerName: "Service Area",
      width: 150,
    },

    { field: "order_type", headerName: "Order Type", width: 150 },
    {
      field: "order_status",
      headerName: "Order Status",
      width: 200,
      valueGetter: (params) =>
        orderStatusLabels[params.row.order_status] || "---",
      renderCell: (params) => (
        <div
          style={{
            color: getOrderStatusColor(params.row.order_status),
          }}
        >
          {orderStatusLabels[params.row.order_status] || "---"}
        </div>
      ),
    },
    {
      field: "consumer_name",
      headerName: "Customer Name",
      width: 150,
      valueGetter: (params) => params.row.consumer_name || "---",
      renderCell: (params) => (
        <div
          style={{
            textDecorationLine: params.row.consumer_name ? "underline" : "none",
            color: "blue",
          }}
        >
          {params.row.consumer_name || "---"}
        </div>
      ),
    },
    {
      field: "consumer_phone",
      headerName: "Customer Phone",
      width: 150,
      valueGetter: (params) => params.row.consumer_phone || "---",
      renderCell: (params) => (
        <div
          style={{
            textDecorationLine: params.row.consumer_phone
              ? "underline"
              : "none",
            color: "blue",
          }}
        >
          {params.row.consumer_phone || "---"}
        </div>
      ),
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      width: 150,
      valueGetter: (params) => params.row.supplier_name || "---",
    },
    {
      field: "delivery_slot",
      headerName: "Delivery Slot",
      width: 150,
      valueGetter: (params) =>
        params?.row?.packaged_drinking_water?.delivery_slots
          ?.admin_selected_delivery_slot ||
        params?.row?.packaged_drinking_water?.delivery_slots
          ?.supplier_selected_delivery_slot ||
        "---",
      // params.row.packaged_drinking_water.delivery_slots !== undefined &&
      // params.row.packaged_drinking_water.delivery_slots !== null &&
      // params.row.packaged_drinking_water.delivery_slots
      //   .admin_selected_delivery_slot !== undefined &&
      // params.row.packaged_drinking_water.delivery_slots
      //   .admin_selected_delivery_slot !== null &&
      // params.row.packaged_drinking_water.delivery_slots
      //   .admin_selected_delivery_slot
      //   ? params.row.packaged_drinking_water.delivery_slots
      //       .admin_selected_delivery_slot
      //   : params.row.packaged_drinking_water.delivery_slots
      //       .supplier_selected_delivery_slot !== undefined &&
      //     params.row.packaged_drinking_water.delivery_slots
      //       .supplier_selected_delivery_slot !== null &&
      //     params.row.packaged_drinking_water.delivery_slots
      //       .supplier_selected_delivery_slot
      //   ? params.row.packaged_drinking_water.delivery_slots
      //       .supplier_selected_delivery_slot
      //   : "-",
    },
    { field: "order_price", headerName: "Amount", width: 120 },
    {
      field: "isWaterDropUsed",
      headerName: "Discount Type",
      width: 150,
      valueGetter: (params) =>
        params.row.packaged_drinking_water?.isWaterDropUsed
          ? "Water drop"
          : params.row.packaged_drinking_water?.promo_label
          ? `Promo code(${params.row.packaged_drinking_water?.promo_label})`
          : "---",
    },
    {
      field: "order_quantity",
      headerName: "Order Quantity",
      width: 150,
      valueGetter: (params) =>
        params.row.packaged_drinking_water.twentyltr_capacity_qty,
    },
    {
      field: "delivery_name",
      headerName: "Delivery Name",
      width: 150,
      valueGetter: (params) => params.row.delivery_address.contact_name,
    },
    {
      field: "delivery_phone",
      headerName: "Delivery Phone",
      width: 150,
      valueGetter: (params) => params.row.delivery_address.contact_phone,
    },
    {
      field: "booking_date",
      headerName: "Booking Date",
      width: 150,
      valueGetter: (params) => params.row.booking_date,
    },
    {
      field: "delivery_date",
      headerName: "Delivery Date",
      width: 150,
      valueGetter: (params) => params.row.delivery_date,
    },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      width: 150,
      valueGetter: (params) =>
        params.row.payment_details?.transaction_id || "---",
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      width: 150,
      valueGetter: (params) =>
        params.row?.payment_type === 300
          ? "POSTPAID"
          : params.row.payment_details?.payment_type || "---",
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      width: 150,
      valueGetter: (params) =>
        params.row.payment_details?.payment_method || "---",
    },
    {
      field: "PaymentStatus",
      headerName: "Payment Status",
      width: 150,
      valueGetter: (params) =>
        params.row.payment_details?.payment_status || "---",
    },
    {
      field: "PaymentCreated",
      headerName: "Payment Created",
      width: 150,
      valueGetter: (params) =>
        moment(params.row.payment_details?.created_at).format("DD-MM-YYYY"),
    },
  ];

  const rows =
    allOrders?.length > 0 &&
    allOrders.map((obj, index) => {
      return { ...obj, id: index + 1 };
    });

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: appColors.commonLayoutColor.background, // Set the desired background color here
            color: "black", // Set the desired text color here
            position: "sticky",
            marginTop: "0 !important",
          },
        },
      },
    },
  });
  const themeTable = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: appColors.commonLayoutColor.background, // Set the desired background color here
            color: "black", // Set the desired text color here
            position: "sticky",
            marginTop: "0 !important",
          },
        },
      },
    },
  });

  const options = {
    customToolbar: () => {
      return (
        // <CustomToolbar />
        <Box>fgv</Box>
      );
    },
  };

  const openDialogFun = () => {
    setOpenDialog(true);
    CustomConsole("Changed open dialog");
  };

  const handleSearchPageSize = () => {
    // const reqObj = {
    //   page_size: pageSize,
    //   pageNum: pageNumber - 1,
    // };
    // dispatch(GetSearchOrderDetails(reqObj));
    setSearchOrderFlag(true);
  };
  const handleCellClickSOrderId = () => {};

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };

  const handleToggOnChange = (value, toggNo) => {
    // setNewToggValue(() => value);
    setNewToggNo(toggNo);
    setNewToggValue(() => ({
      [toggNo]: value,
    }));
  };

  const ToggleSort = ({ value, onChange, toggNo }) => {
    return (
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        onChange={(e) => onChange(e.target.value, toggNo)}
        aria-label="Platform"
        size="small"
        sx={{
          "& .MuiToggleButton-root": {
            padding: "2px 2px",
            fontSize: "0.64rem",
          },
          "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-of-type)": {
            borderLeft: "1px solid blue",
          },
          "& .MuiToggleButtonGroup-groupedHorizontal:not(:last-of-type)": {
            borderRight: "1px solid blue",
          },
          "& .Mui-selected": {
            backgroundColor: "#D6DEBA",
            color: "black",
            "&:hover": {
              backgroundColor: "#f50057",
            },
          },
          border: "1px solid blue",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <ToggleButton value="ASC">ASC</ToggleButton>
        <ToggleButton value="DESC">DESC</ToggleButton>
      </ToggleButtonGroup>
    );
  };

  //sort_order_id=&sort_pincode=&sort_area=&sort_order_quantity=
  //&sort_amount=&sort_booking_date=&sort_delivery_date=&sort_payment_method=&sort_order_status=

  React.useEffect(() => {
    let reqObj_one = otherFilterObj;
    let reqObj_two = {};
    switch (newToggNo) {
      case 1:
        reqObj_two.sort_order_id = newToggValue[1];
        break;
      case 2:
        reqObj_two.sort_order_quantity = newToggValue[2];
        break;
      case 3:
        reqObj_two.sort_pincode = newToggValue[3];
        break;
      case 4:
        reqObj_two.sort_area = newToggValue[4];
        break;
      case 5:
        reqObj_two.sort_order_status = newToggValue[5];
        break;
      case 6:
        reqObj_two.sort_amount = newToggValue[6];
        break;
      case 7:
        reqObj_two.sort_booking_date = newToggValue[7];
        break;
      case 8:
        reqObj_two.sort_delivery_date = newToggValue[8];
        break;
      case 9:
        reqObj_two.sort_payment_method = newToggValue[9];
        break;

      default:
        break;
    }
    if (otherFilterObj?.sort_order) {
      reqObj_two = {};
    }
    // dispatch(GetSearchOrderDetails(reqObj_one, reqObj_two));
  }, [newToggNo, newToggValue, otherFilterObj]);

  return (
    <>
      <Box
      // sx={{
      //   maxHeight: "75Vh",
      //   overflow: "auto",
      //   // padding: "10px",
      // }}
      >
        {allOrders && allOrders.length && !tableView ? (
          allOrders.map((data, idx) => {
            return (
              <Card
                sx={{
                  marginTop: "10px",

                  "&:hover": {
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  },

                  // opacity:openAcco===idx?0:1,
                }}
                elevation={10}
                key={idx}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: data.delivery_otp
                        ? "space-between"
                        : "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {data.delivery_otp ? (
                      <span
                        style={{
                          color:
                            data.packaged_drinking_water !== undefined &&
                            data.packaged_drinking_water.delivery_otp_status !==
                              undefined &&
                            parseInt(
                              data.packaged_drinking_water.delivery_otp_status
                            ) === 200
                              ? "red"
                              : "green",
                          fontSize: "13px",
                          fontWeight: 600,
                          textDecorationLine:
                            data.packaged_drinking_water !== undefined &&
                            data.packaged_drinking_water.delivery_otp_status !==
                              undefined &&
                            parseInt(
                              data.packaged_drinking_water.delivery_otp_status
                            ) === 200
                              ? "line-through"
                              : "none",
                        }}
                      >
                        OTP:{data.delivery_otp}
                      </span>
                    ) : null}

                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#5DADE2",
                      }}
                    >
                      {data.order_type}
                    </span>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        md={6}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={styles.cardStyleHeading}>
                            Order Id:
                            <span style={styles.cardStyleValue}>
                              {data.order_id}
                            </span>
                          </Typography>

                          <Typography
                            sx={{
                              ...styles.cardStyleHeading,
                              alignItems: "center",
                            }}
                          >
                            Order status:
                            <span
                              style={{
                                ...styles.cardStyleValue,
                                color:
                                  data.order_status ===
                                  ordersStatus.ORDER_STATUS_ACCEPTED
                                    ? "green"
                                    : data.order_status ===
                                      ordersStatus.ORDER_STATUS_CANCELLED
                                    ? "red"
                                    : data.order_status ===
                                      ordersStatus.ORDER_STATUS_NEW_REQUEST
                                    ? "#CBCD42"
                                    : "black",
                              }}
                            >
                              {orderStatus(data.order_status)}
                            </span>
                          </Typography>

                          <Typography sx={styles.cardStyleHeading}>
                            Order Quantity:
                            <span style={styles.cardStyleValue}>
                              {
                                data.packaged_drinking_water
                                  .twentyltr_capacity_qty
                              }
                            </span>
                          </Typography>

                          {openAcco === idx ? (
                            <>
                              <Typography sx={styles.cardStyleHeading}>
                                Date of booking:
                                <span style={styles.cardStyleValue}>
                                  {dateConverter(data.order_date)}
                                </span>
                              </Typography>
                              <Typography sx={styles.cardStyleHeading}>
                                Delivery On:
                                <span style={styles.cardStyleValue}>
                                  {endDateConverter(data.delivery_date)}
                                </span>
                              </Typography>
                              <Typography sx={styles.cardStyleHeading}>
                                Payment Type:
                                <span style={styles.cardStyleValue}>
                                  {data.payment_type === 100
                                    ? "Prepaid"
                                    : "Postpaid"}
                                </span>
                              </Typography>
                              <Typography sx={styles.cardStyleHeading}>
                                Ordred Time:
                                <span style={styles.cardStyleValue}>
                                  {timeSeperator(data.order_date)}
                                </span>
                              </Typography>
                              <Typography sx={styles.cardStyleHeading}>
                                Discount:
                                {data.packaged_drinking_water
                                  ?.discount_price ? (
                                  <span style={styles.cardStyleValue}>
                                    {
                                      data.packaged_drinking_water
                                        ?.discount_price
                                    }
                                  </span>
                                ) : (
                                  <span style={styles.cardStyleValue}>-</span>
                                )}
                              </Typography>
                            </>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          {/* <Typography sx={styles.cardStyleHeading}>
                            Area:
                            <span style={styles.cardStyleValue}>
                              {data.delivery_address.service_area}
                            </span>
                          </Typography> */}
                          <Typography sx={styles.cardStyleHeading}>
                            Pincode:
                            <span style={styles.cardStyleValue}>
                              {data.delivery_address.pincode}
                            </span>
                          </Typography>
                          <Typography
                            sx={{ ...styles.cardStyleHeading }}
                            onClick={() => {
                              CustomConsole("--- test---");
                              handleCellClick({
                                field: "consumer_name",
                                row: {
                                  delivery_address: data.delivery_address,
                                },
                              });
                            }}
                          >
                            Consumer Name:
                            <span style={styles.cardStyleValue}>
                              {data.consumer_name}
                            </span>
                          </Typography>
                          <Typography sx={styles.cardStyleHeading}>
                            Amount:
                            <span style={styles.cardStyleValue}>
                              {data.order_price}
                            </span>
                          </Typography>

                          {openAcco === idx ? (
                            <>
                              <Typography sx={styles.cardStyleHeading}>
                                Area:
                                <span style={styles.cardStyleValue}>
                                  {data.delivery_address.service_area}
                                </span>
                              </Typography>

                              <Typography sx={styles.cardStyleHeading}>
                                Supplier:
                                <span style={styles.cardStyleValue}>
                                  {data.supplier_name}
                                </span>
                              </Typography>
                              <Typography sx={styles.cardStyleHeading}>
                                Payment Status:
                                <span style={styles.cardStyleValue}>
                                  {data.payment_status === 0
                                    ? "PAID"
                                    : data.payment_status === 100
                                    ? "PENDING"
                                    : "FAILED"}
                                </span>
                              </Typography>
                              <Typography sx={styles.cardStyleHeading}>
                                Contact Number:
                                <span style={styles.cardStyleValue}>
                                  {data.delivery_address.contact_phone}
                                </span>
                              </Typography>
                              {/* <Typography sx={styles.cardStyleHeading}>
                                Ordered Date:
                                <span style={styles.cardStyleValue}>
                                  {orderedDateConverter(data.order_date)}
                                </span>
                              </Typography> */}
                              {data.packaged_drinking_water.delivery_slots !==
                              undefined ? (
                                <>
                                  <Typography sx={styles.cardStyleHeading}>
                                    Delivery Slot:
                                    <span style={styles.cardStyleValue}>
                                      {data.packaged_drinking_water
                                        .delivery_slots
                                        .admin_selected_delivery_slot !==
                                        undefined &&
                                      data.packaged_drinking_water
                                        .delivery_slots
                                        .admin_selected_delivery_slot !==
                                        null &&
                                      data.packaged_drinking_water
                                        .delivery_slots
                                        .admin_selected_delivery_slot
                                        ? data.packaged_drinking_water
                                            .delivery_slots
                                            .admin_selected_delivery_slot
                                        : data.packaged_drinking_water
                                            .delivery_slots
                                            .supplier_selected_delivery_slot !==
                                            undefined &&
                                          data.packaged_drinking_water
                                            .delivery_slots
                                            .supplier_selected_delivery_slot !==
                                            null &&
                                          data.packaged_drinking_water
                                            .delivery_slots
                                            .supplier_selected_delivery_slot
                                        ? data.packaged_drinking_water
                                            .delivery_slots
                                            .supplier_selected_delivery_slot
                                        : "-"}
                                    </span>
                                  </Typography>
                                </>
                              ) : (
                                <Box sx={{ padding: "10px" }}>
                                  <Typography sx={{ textAlign: "center" }}>
                                    Distributor has not choosen delivery slot.
                                  </Typography>
                                </Box>
                              )}
                            </>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* {openAcco === idx ? (
                    <Box
                      style={{
                        height: openAcco === idx ? "auto" : "0",
                        transitionDuration: "0.2s",
                        overflow: "hidden",
                        transitionDelay: "0.5s",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            md={6}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            ></Box>
                          </Grid>
                          <Grid item md={6}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            ></Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      style={{
                        height: "0",
                        transitionDelay: "0.5s",
                        transitionDuration: "0.2s",
                        overflow: "hidden",
                      }}
                    />
                  )} */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    {data.order_status ===
                      ordersStatus.ORDER_STATUS_NEW_REQUEST ||
                    data.order_status === ordersStatus.ORDER_STATUS_DELIVERED ||
                    data.order_status === ordersStatus.ORDER_STATUS_ACCEPTED ||
                    data.order_status ===
                      ordersStatus.ORDER_STATUS_CANCELLED ? (
                      <Button
                        variant="contained"
                        onClick={() => handleOrderListClick(data.order_id)}
                        size="small"
                        sx={appColors.commonBtnColor}
                      >
                        Edit/Update
                      </Button>
                    ) : (
                      ""
                    )}

                    <Button
                      onClick={() => {
                        openAccoFun(idx);
                      }}
                      variant="outlined"
                    >
                      {openAcco === idx ? (
                        <>
                          <span>Hide</span> <KeyboardArrowDownIcon />
                        </>
                      ) : (
                        <>
                          <span>More</span> <KeyboardArrowUpIcon />
                        </>
                      )}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            );
          })
        ) : tableView ? (
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { md: "flex-end", xs: "center" },
                flexWrap: { xs: "wrap" },
                padding: "5px",
                marginTop: "5px",
              }}
            >
              <Box sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    border: "1px solid #1FA6F9",
                    "&:hover": {
                      backgroundColor: "#D1E9F7",
                      color: "black",
                    },
                  }}
                  onClick={openDialogFun}
                >
                  Filter&nbsp;
                  <FilterAltIcon />
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageNumberBackward}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Box>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Typography>
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageNumberForward}
                  disabled={disableBtn}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>
              <Box
                sx={{
                  maxWidth: "120px",
                  maxHeight: "10px",
                  // margin: { xs: 4 },
                  marginBottom: 4,
                }}
              >
                <Material.TextField
                  size="small"
                  value={pageSize}
                  label="Page size"
                  // onChange={(e) => {
                  //   setPageSize(e.target.value);
                  //   // setSearchOrderFlag(true);
                  // }}
                  InputProps={{
                    maxLength: 10, // Optional: You can set the maximum length if needed
                    onInput: handleChangePageSize, // Attach the input event handler
                    endAdornment: (
                      <Material.InputAdornment position="end">
                        <MuiIcons.Search
                          cursor="pointer"
                          size="30px"
                          onClick={handleSearchPageSize}
                        />
                      </Material.InputAdornment>
                    ),
                  }}
                />
              </Box>
              <CSVLink {...csvLink}>
                {" "}
                <DownloadIcon
                  sx={{ fontSize: 35 }}
                  onClick={() => toCSV(allOrders)}
                />
              </CSVLink>
            </Box>
            {allOrders && allOrders.length > 0 ? (
              <>
                {/* <Box sx={{ margin: "10px" }}>
                <Material.TextField
                  label="Search By Order Id"
                  value={searchOrderId}
                  onChange={(event) => setSearchOrderId(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <Material.InputAdornment position="start">
                        <Material.Typography
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          RP
                        </Material.Typography>
                      </Material.InputAdornment>
                    ),
                    endAdornment: (
                      <Material.InputAdornment position="end">
                        <IconName.FcSearch
                          cursor="pointer"
                          size="30px"
                          onClick={handleViewDetails}
                        />
                      </Material.InputAdornment>
                    ),
                    style: { borderRadius: "50px" },
                  }}
                />
              </Box> */}
                <Box
                  sx={{
                    maxWidth: {
                      md: "150vw",
                      xs: "350px",
                      lg: "150vw",
                      sm: "150vw",
                    },
                    overflow: "auto",
                  }}
                >
                  {/* <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
                    <Table aria-label="customized table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center" size="small">
                            <strong>S.no</strong>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Order ID</strong>
                              <ToggleSort
                                value={newToggValue[1]}
                                onChange={handleToggOnChange}
                                toggNo={1}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>OTP</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Order Quantity</strong>
                              <ToggleSort
                                value={newToggValue[2]}
                                onChange={handleToggOnChange}
                                toggNo={2}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Pincode</strong>
                              <ToggleSort
                                value={newToggValue[3]}
                                onChange={handleToggOnChange}
                                toggNo={3}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Service Area</strong>
                              <ToggleSort
                                value={newToggValue[4]}
                                onChange={handleToggOnChange}
                                toggNo={4}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Order Type</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="Large">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Order Status</strong>
                              <ToggleSort
                                value={newToggValue[5]}
                                onChange={handleToggOnChange}
                                toggNo={5}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Consumer Name</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Consumer Number</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Supplier Name</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Delivery Slot</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Amount</strong>
                              <ToggleSort
                                value={newToggValue[6]}
                                onChange={handleToggOnChange}
                                toggNo={6}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Discount Type</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Delivery Name</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Delivery Phone</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Booking Date</strong>
                              <ToggleSort
                                value={newToggValue[7]}
                                onChange={handleToggOnChange}
                                toggNo={7}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Delivery Date</strong>
                              <ToggleSort
                                value={newToggValue[8]}
                                onChange={handleToggOnChange}
                                toggNo={8}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Transaction Id</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Payment Type</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Payment Method</strong>
                              <ToggleSort
                                value={newToggValue[9]}
                                onChange={handleToggOnChange}
                                toggNo={9}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Payment Status</strong>
                          </StyledTableCell>

                          <StyledTableCell align="center" size="small">
                            <strong>Payment Created At</strong>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allOrders?.map((row, key) => (
                          <StyledTableRow
                            key={key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                              "&:hover": {
                                boxShadow: "none",
                                backgroundColor: "#DAD6D5",
                              },
                            }}
                            onClick={() => {
                              handleOrderListClick(row.order_id);
                            }}
                          >
                            <StyledTableCell align="center" size="small">
                              {pageNumber > 1
                                ? pageNumber * 10 + (key + 1) - 10
                                : key + 1}
                            </StyledTableCell>
                            <StyledTableCell>{row.order_id}</StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_otp || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {
                                row.packaged_drinking_water
                                  .twentyltr_capacity_qty
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address?.pincode}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address?.service_area}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="arge">
                              {row.order_type}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                ...styles.cardStyleValue,
                                color:
                                  row.order_status ===
                                  ordersStatus.ORDER_STATUS_ACCEPTED
                                    ? "green"
                                    : row.order_status ===
                                      ordersStatus.ORDER_STATUS_CANCELLED
                                    ? "red"
                                    : row.order_status ===
                                      ordersStatus.ORDER_STATUS_NEW_REQUEST
                                    ? "#CBCD42"
                                    : "black",
                              }}
                              align="center"
                              size="Large"
                            >
                              {orderStatus(row.order_status)}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                textDecorationLine: row.consumer_name
                                  ? "underline"
                                  : "none",
                                color: "blue",
                              }}
                              onClick={() => {
                                handleOrderListContactName(row.consumer_phone);
                              }}
                              align="center"
                              size="small"
                            >
                              {row.consumer_name}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                textDecorationLine: row.consumer_phone
                                  ? "underline"
                                  : "none",
                                color: "blue",
                              }}
                              onClick={() => {
                                handleOrderListContactName(row.consumer_phone);
                              }}
                              align="center"
                              size="small"
                            >
                              {row.consumer_phone}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.supplier_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.packaged_drinking_water?.delivery_slots
                                ?.admin_selected_delivery_slot ||
                                row.packaged_drinking_water?.delivery_slots
                                  ?.supplier_selected_delivery_slot}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.order_price}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.packaged_drinking_water?.isWaterDropUsed
                                ? "Water drop"
                                : row.packaged_drinking_water?.promo_label
                                ? `Promo code(${row.packaged_drinking_water?.promo_label})`
                                : "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address.contact_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address.contact_phone}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.booking_date}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_date}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.payment_details?.transaction_id || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row?.payment_type === 300
                                ? "POSTPAID"
                                : row.payment_details?.payment_type || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.payment_details?.payment_method || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.payment_details?.payment_status || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {moment(row.payment_details?.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer> */}
                  {/* <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
                    <Table aria-label="customized table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center" size="small">
                            <strong>S.no</strong>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Order ID</strong>
                              <ToggleSort
                                value={newToggValue[1]}
                                onChange={handleToggOnChange}
                                toggNo={1}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>OTP</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Order Quantity</strong>
                              <ToggleSort
                                value={newToggValue[2]}
                                onChange={handleToggOnChange}
                                toggNo={2}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Pincode</strong>
                              <ToggleSort
                                value={newToggValue[3]}
                                onChange={handleToggOnChange}
                                toggNo={3}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Service Area</strong>
                              <ToggleSort
                                value={newToggValue[4]}
                                onChange={handleToggOnChange}
                                toggNo={4}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Order Type</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="Large">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Order Status</strong>
                              <ToggleSort
                                value={newToggValue[5]}
                                onChange={handleToggOnChange}
                                toggNo={5}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Consumer Name</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Consumer Number</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Supplier Name</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Delivery Slot</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Amount</strong>
                              <ToggleSort
                                value={newToggValue[6]}
                                onChange={handleToggOnChange}
                                toggNo={6}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Discount Type</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Delivery Name</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Delivery Phone</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Booking Date</strong>
                              <ToggleSort
                                value={newToggValue[7]}
                                onChange={handleToggOnChange}
                                toggNo={7}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Delivery Date</strong>
                              <ToggleSort
                                value={newToggValue[8]}
                                onChange={handleToggOnChange}
                                toggNo={8}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Transaction Id</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Payment Type</strong>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <strong>Payment Method</strong>
                              <ToggleSort
                                value={newToggValue[9]}
                                onChange={handleToggOnChange}
                                toggNo={9}
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <strong>Payment Status</strong>
                          </StyledTableCell>

                          <StyledTableCell align="center" size="small">
                            <strong>Payment Created At</strong>
                          </StyledTableCell>
                       
                          <StyledTableCell align="center">
                            <strong>Products details</strong>
                            <TableRow>
                              <StyledTableCell align="center" size="small">
                                <strong>Material</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center" size="small">
                                <strong>Capacity</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center" size="small">
                                <strong>Order Qty</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center" size="small">
                                <strong>Return Qty</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center" size="small">
                                <strong>SKU</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center" size="small">
                                <strong>Final Price</strong>
                              </StyledTableCell>
                            </TableRow>
                          </StyledTableCell>

                      
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allOrders?.map((row, key) => (
                          <StyledTableRow
                            key={key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                              "&:hover": {
                                boxShadow: "none",
                                backgroundColor: "#DAD6D5",
                              },
                            }}
                            onClick={() => {
                              handleOrderListClick(row.order_id);
                            }}
                          >
                            <StyledTableCell align="center" size="small">
                              {pageNumber > 1
                                ? pageNumber * 10 + (key + 1) - 10
                                : key + 1}
                            </StyledTableCell>
                            <StyledTableCell>{row.order_id}</StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_otp || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {
                                row.packaged_drinking_water
                                  .twentyltr_capacity_qty
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address?.pincode}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address?.service_area}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="arge">
                              {row.order_type}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                ...styles.cardStyleValue,
                                color:
                                  row.order_status ===
                                  ordersStatus.ORDER_STATUS_ACCEPTED
                                    ? "green"
                                    : row.order_status ===
                                      ordersStatus.ORDER_STATUS_CANCELLED
                                    ? "red"
                                    : row.order_status ===
                                      ordersStatus.ORDER_STATUS_NEW_REQUEST
                                    ? "#CBCD42"
                                    : "black",
                              }}
                              align="center"
                              size="Large"
                            >
                              {orderStatus(row.order_status)}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                textDecorationLine: row.consumer_name
                                  ? "underline"
                                  : "none",
                                color: "blue",
                              }}
                              onClick={() => {
                                handleOrderListContactName(row.consumer_phone);
                              }}
                              align="center"
                              size="small"
                            >
                              {row.consumer_name}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                textDecorationLine: row.consumer_phone
                                  ? "underline"
                                  : "none",
                                color: "blue",
                              }}
                              onClick={() => {
                                handleOrderListContactName(row.consumer_phone);
                              }}
                              align="center"
                              size="small"
                            >
                              {row.consumer_phone}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.supplier_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.packaged_drinking_water?.delivery_slots
                                ?.admin_selected_delivery_slot ||
                                row.packaged_drinking_water?.delivery_slots
                                  ?.supplier_selected_delivery_slot}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.order_price}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.packaged_drinking_water?.isWaterDropUsed
                                ? "Water drop"
                                : row.packaged_drinking_water?.promo_label
                                ? `Promo code(${row.packaged_drinking_water?.promo_label})`
                                : "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address.contact_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_address.contact_phone}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.booking_date}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.delivery_date}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.payment_details?.transaction_id || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row?.payment_type === 300
                                ? "POSTPAID"
                                : row.payment_details?.payment_type || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.payment_details?.payment_method || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {row.payment_details?.payment_status || "---"}
                            </StyledTableCell>
                            <StyledTableCell align="center" size="small">
                              {moment(row.payment_details?.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.products.map((data) => (
                                <StyledTableRow>
                                  <StyledTableCell >
                                    {data.material}
                                  </StyledTableCell>
                                  <StyledTableCell >
                                    {data.capacity}
                                  </StyledTableCell>
                                  <StyledTableCell >
                                    {data.quantity}
                                  </StyledTableCell>
                                  <StyledTableCell >
                                    {data.return}
                                  </StyledTableCell>
                                  <StyledTableCell >
                                    {data.sku_qty}
                                  </StyledTableCell>
                                  <StyledTableCell >
                                    {data.final_price}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer> */}
                  <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                    <Table aria-label="collapsible table" stickyHeader>
                      <TableHead>
                        <TableRow sx={appColors.commonLayoutColor}>
                          <TableCell />
                          <TableCell size="small" align="start">
                            <strong>S.no</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Order Id</strong>
                            <ToggleSort
                              value={newToggValue[1]}
                              onChange={handleToggOnChange}
                              toggNo={1}
                            />
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>OTP</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Pincode</strong>
                            <ToggleSort
                              value={newToggValue[3]}
                              onChange={handleToggOnChange}
                              toggNo={3}
                            />
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Service Area</strong>
                            <ToggleSort
                              value={newToggValue[4]}
                              onChange={handleToggOnChange}
                              toggNo={4}
                            />
                          </TableCell>
                       
                          <TableCell size="small" align="start">
                            <strong>Order Type</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Order Status</strong>
                            <ToggleSort
                              value={newToggValue[5]}
                              onChange={handleToggOnChange}
                              toggNo={5}
                            />
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Consumer Name</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Consumer Phone</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Supplier Name</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Delivery Slot</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Order Price</strong>
                            <ToggleSort
                              value={newToggValue[6]}
                              onChange={handleToggOnChange}
                              toggNo={6}
                            />
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Discount Type</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Delivery Name</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Delivery Phone</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Booking Date</strong>
                            <ToggleSort
                              value={newToggValue[7]}
                              onChange={handleToggOnChange}
                              toggNo={7}
                            />
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Delivery Date</strong>
                            <ToggleSort
                              value={newToggValue[8]}
                              onChange={handleToggOnChange}
                              toggNo={8}
                            />
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Transaction Id</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Payment Type</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Payment Method</strong>
                            <ToggleSort
                              value={newToggValue[9]}
                              onChange={handleToggOnChange}
                              toggNo={9}
                            />
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Payment Status</strong>
                          </TableCell>
                          <TableCell size="small" align="start">
                            <strong>Payment Created At</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allOrders?.map((data, index) => (
                          <>
                            <TableRow
                              sx={{
                                "& > *": {
                                  borderBottom: "unset",
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                  "&:hover": {
                                    boxShadow: "none",
                                    backgroundColor: "#DAD6D5",
                                  },
                                },
                              }}
                              key={index}
                              onClick={() => {
                                handleOrderListClick(data.order_id);
                              }}
                            >
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={(event) => handelIcon(event, index)}
                                  sx={{
                                    "& > *": { borderBottom: "unset" },
                                    bgcolor: "#152057",
                                    color: "white",
                                  }}
                                >
                                  {openFlag ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell size="small" align="start">
                                {index + 1}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.order_id}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.delivery_otp}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.delivery_address?.pincode}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.delivery_address?.service_area}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.order_type}
                              </TableCell>
                              <TableCell
                                // size="small"
                                // align="start"
                                style={{
                                  ...styles.cardStyleValue,
                                  color:
                                    data.order_status ===
                                    ordersStatus.ORDER_STATUS_ACCEPTED
                                      ? "green"
                                      : data.order_status ===
                                        ordersStatus.ORDER_STATUS_CANCELLED
                                      ? "red"
                                      : data.order_status ===
                                        ordersStatus.ORDER_STATUS_NEW_REQUEST
                                      ? "#CBCD42"
                                      : "black",
                                  align: "center",
                                  size: "small",
                                }}
                              >
                                {orderStatus(data.order_status)}
                              </TableCell>
                              <TableCell
                                size="small"
                                align="start"
                                style={{
                                  textDecorationLine: data.consumer_name
                                    ? "underline"
                                    : "none",
                                  color: "blue",
                                }}
                                onClick={() => {
                                  handleOrderListContactName(
                                    data.consumer_phone
                                  );
                                }}
                              >
                                {data.consumer_name}
                              </TableCell>
                              <TableCell
                                size="small"
                                align="start"
                                style={{
                                  textDecorationLine: data.consumer_phone
                                    ? "underline"
                                    : "none",
                                  color: "blue",
                                }}
                                onClick={() => {
                                  handleOrderListContactName(
                                    data.consumer_phone
                                  );
                                }}
                              >
                                {data.consumer_phone}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.supplier_name}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.packaged_drinking_water?.delivery_slots
                                  ?.admin_selected_delivery_slot ||
                                  data.packaged_drinking_water?.delivery_slots
                                    ?.supplier_selected_delivery_slot}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.order_price}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.packaged_drinking_water?.isWaterDropUsed
                                  ? "Water drop"
                                  : data.packaged_drinking_water?.promo_label
                                  ? `Promo code(${data.packaged_drinking_water?.promo_label})`
                                  : "---"}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.delivery_address.contact_name}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.delivery_address.contact_phone}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.booking_date}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.delivery_date}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.payment_details?.transaction_id || "---"}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.payment_type === 300
                                  ? "POSTPAID"
                                  : data.payment_details?.payment_type || "---"}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.payment_details?.payment_method || "---"}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {data.payment_details?.payment_status || "---"}
                              </TableCell>
                              <TableCell size="small" align="start">
                                {moment(
                                  data.payment_details?.created_at
                                ).format("DD-MM-YYYY")}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={12}
                              >
                                {/* <Collapse in={open} timeout="auto" unmountOnExit > */}
                                {open === index && openFlag === true ? (
                                  <Material.Box sx={{ margin: 1 }}>
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow
                                          sx={{
                                            "& > *": { borderBottom: "unset" },
                                            bgcolor: "#5DADE2",
                                          }}
                                        >
                                          <TableCell size="small">
                                            <strong> Material</strong>
                                          </TableCell>
                                          <TableCell size="small">
                                            <strong>Capacity</strong>
                                          </TableCell>
                                          <TableCell size="small">
                                            <strong>Order Qty</strong>
                                          </TableCell>
                                          <TableCell size="small">
                                            <strong>Return Qty</strong>
                                          </TableCell>
                                          <TableCell size="small">
                                            <strong>SKU </strong>
                                          </TableCell>
                                          <TableCell size="small">
                                            <strong>Price</strong>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell size="small">
                                            {
                                              data.packaged_drinking_water
                                                .selected_container_material
                                            }
                                          </TableCell>
                                          <TableCell size="small">
                                            {
                                              data.packaged_drinking_water
                                                .selected_container_capacity
                                            }
                                          </TableCell>
                                          <TableCell size="small">
                                            {
                                              data.packaged_drinking_water
                                                .twentyltr_capacity_qty
                                            }
                                          </TableCell>
                                          <TableCell size="small">
                                            {
                                              data.packaged_drinking_water
                                                .empty_container_qty
                                            }
                                          </TableCell>
                                          <TableCell size="small">
                                            {1}
                                          </TableCell>
                                          <TableCell size="small">
                                            {
                                              data.packaged_drinking_water
                                                .twenty_ltr_capacity_amt
                                            }
                                          </TableCell>
                                        </TableRow>
                                        {data.products?.map((data) => {
                                          return (
                                            <TableRow
                                            // key={data.product_details.id}
                                            >
                                              <TableCell size="small">
                                                {data.material}
                                              </TableCell>
                                              <TableCell size="small">
                                                {data.capacity}
                                              </TableCell>
                                              <TableCell size="small">
                                                {data.quantity}
                                              </TableCell>
                                              <TableCell size="small">
                                                {data.return}
                                              </TableCell>
                                              <TableCell size="small">
                                                {data.sku_qty}
                                              </TableCell>
                                              <TableCell size="small">
                                                {data.product_price}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  </Material.Box>
                                ) : (
                                  ""
                                )}
                                {/* </Collapse> */}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            ) : (
              <b>No Data available</b>
            )}
            {/* {allOrders && allOrders.length ? (
              <ThemeProvider theme={themeTable}>
                <Box
                  sx={{
                    maxHeight: "500px",
                    // maxWidth:{xs:"350px",sm:"450px", md:"600px",lg:"100%"},
                    maxWidth: { md: "100Vw", xs: "160Vw" },
                    overflow: "auto",
                  }}
                >
                  <DataGrid
                    // {...allOrders}
                    rows={rows}
                    columns={columns}
                    sx={{ cursor: "pointer" }}
                    onCellClick={handleCellClick}
                    onRowClick={(params) => {
                      const orderId = params.row.order_id;
                      const orderStatus = params.row.order_status;
                      // if (
                      //   orderStatus === ordersStatus.ORDER_STATUS_NEW_REQUEST ||
                      //   orderStatus === ordersStatus.ORDER_STATUS_DELIVERED ||
                      //   orderStatus === ordersStatus.ORDER_STATUS_ACCEPTED ||
                      //   orderStatus === ordersStatus.ORDER_STATUS_ACCEPTED ||
                      //   orderStatus === ordersStatus.ORDER_STATUS_CANCELLED
                      // ) {
                      handleOrderListClick(orderId);
                      // }
                    }}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    options={options}
                    hideFooter={true}
                  />
                </Box>
              </ThemeProvider>
            ) : (
              ""
            )} */}

            {/* <Box>
              <Button>Filter</Button>
            </Box> */}
          </div>
        ) : (
          <Avatar
            sx={{
              bgcolor: "#a20000",
              width: "100%",
              marginBottom: "1em",
              fontSize: "1em",
            }}
            variant="rounded"
          >
            No further Orders
          </Avatar>
        )}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
          marginTop: "5px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={disableBtn}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel>Page size</InputLabel>
            <Select
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box> */}
    </>
  );
}
