/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

 import React from "react";
 import { Route, Redirect } from "react-router-dom";
 import CustomConsole from "../../CustomConsole";
 const ProtectedRoute = ({ children, ...rest }) => { 
   // CustomConsole(children)
   // CustomConsole(...rest)
   return (
     <Route
       {...rest}
       render={({ location }) =>
         localStorage.getItem("token") || sessionStorage.getItem("token") ? (
           children
         ) : (
           <Redirect 
             to={{ 
               pathname: "/authenticate",
               state: { from: location },
             }}
           />
         )
       }
     />
   );
 };
 export default ProtectedRoute;
 