import { Box } from "@mui/material";
import React from "react";
import Login from "./Login";

function Authentication() {
  return (
    <Box>
      <Login />
    </Box>
  );
}

export default Authentication;
