import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { toast } from "react-toastify";

import CustomConsole from "../../CustomConsole";
export default function EditOrderReasonDialog(props) {
  const handleClose = () => {
    props.setEditOrderClicked(false);
    setOtherReason("");
  };
  const [isOtherReason, setIsOtherReason] = React.useState(false);
  const [OtherReason, setOtherReason] = React.useState("");
  const handleReasonChange = (event) => {
    CustomConsole(event.target.value);
    if (event.target.value === "Other Reasons") {
      setOtherReason(event.target.value);
    }
    else {
      props.setEditOrderReason(event.target.value);
      setOtherReason("");
    }
  };

  React.useEffect(() => {
    if (OtherReason === "Other Reasons") {
      setIsOtherReason(true);
    } else {
      setIsOtherReason(false);
    }

  }, [props.editOrderReason, OtherReason]);

  const handleContinue = () => {
    if (props.editOrderReason.trim() !== "") {
      props.setIsEditOrderReasonChoosen(true);
      props.setEditOrderClicked(false);
    } else {
      toast.dark("Please Select Valid Reason", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  return (
    <div>
      <Dialog
        open={props.editOrderClicked}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                Select reason for editing order
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <FormControl onChange={handleReasonChange}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {props.editOrderReasonValues.map((reason, key) => {
                    return (
                      <FormControlLabel
                        value={reason.v}
                        control={<Radio />}
                        label={reason.v}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ padding: "10px" }}>
              {isOtherReason && (
                <Box>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Other reasons"
                    multiline
                    onChange={((e) => props.setEditOrderReason(e.target.value))}
                    maxRows={4}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Alert severity="info">
                Any refund will be credited back to your water wallet
              </Alert>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button variant="contained" onClick={handleClose}>
                  Close
                </Button>
              </Box>
              <Box>
                <Button variant="contained" onClick={handleContinue} sx={appColors.commonBtnColor}>
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
