import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import { AddNewRoles, EditAdminRoles } from "../Redux/Actions/superAdminAction";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

function CreateRoles({
  openAddNewDlg,
  setOpenAddNewDlg,
  setAddRoleFlag,
  roleName,
  setRoleName,
  editRole,
  roleId,
  setEditRoleFlag,
  departmentList,
}) {
  const dispatch = useDispatch();
  const superAdminUpdates = useSelector((state) => state.superAdminUpdates);

  //validating regex
  const [rolesValid, setRolesValid] = React.useState(true);
  const [department, setDepartment] = React.useState("");
  const [description, setDescription] = React.useState("");

  React.useEffect(() => {
    CustomConsole(superAdminUpdates);
  }, [superAdminUpdates]);
  const handleClose = () => {
    setOpenAddNewDlg(false);
    setRolesValid(true);
  };

  const addingNewRole = () => {
    const reqObj = {
      role_name: roleName,
      domain: department,
      roles_description: description,
    };
    if (
      reqObj.role_name !== "" &&
      rolesValid &&
      department !== "" &&
      description !== ""
    ) {
      dispatch(AddNewRoles(reqObj));
      setAddRoleFlag(true);
    } else {
      toast.error("Enter proper details to add", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleChangeRole = (e) => {
    //to start BW- as input
    // const reg=/^[a-zA-Z]{2}-[a-zA-Z]+$/;
    const reg = /^[bB][wW]-[A-Za-z]+$/;
    setRolesValid(reg.test(e.target.value));
    setRoleName(e.target.value);
  };

  const editingRole = () => {
    CustomConsole(roleId);
    CustomConsole(roleName);
    const reqObj = {
      role_name: roleName,
      role_id: roleId,
      domain: department,
      roles_description: description,
    };
    if (reqObj.role_name !== "" && rolesValid && reqObj.role_id !== "") {
      dispatch(EditAdminRoles(reqObj));
      setEditRoleFlag(true);
    }
  };

  const handleChangeDepartment = (event) => {
    setDepartment(event.target.value);
  };
  return (
    <Dialog
      open={openAddNewDlg}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {!editRole ? "Enter The Role Name." : "Update Role Name"}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Box sx={{ minWidth: 200, padding: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Department</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={department}
                label="Deparment"
                onChange={handleChangeDepartment}
              >
                {departmentList.map((value) => {
                  return <MenuItem value={value.k}>{value.v}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <TextField
              type="text"
              value={roleName}
              label="Role name"
              helperText="Input must start with BW, followed by a hyphen, and then one or more letters in upper or lowercase"
              variant="outlined"
              sx={{
                marginTop: "8px",
              }}
              error={!rolesValid}
              onChange={handleChangeRole}
            />
          </Box>
          <Box>
            <TextField
              type="text"
              value={description}
              label="Description"
              helperText="Description should be within 100 characters"
              variant="outlined"
              sx={{
                marginTop: "8px",
              }}
              multiline
              maxRows={4}
              inputProps={{ maxLength: 100 }}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {!editRole ? (
          <Button variant="contained" onClick={addingNewRole} autoFocus>
            Add
          </Button>
        ) : (
          <Button onClick={editingRole} variant="contained">
            Update
          </Button>
        )}
        <Button autoFocus onClick={handleClose}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateRoles;
