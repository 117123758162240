import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Paper, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetContainerDepositDetailsList } from "../Redux/Actions/adminContainerDetailsAction";
import ContainerDetailsCard from "./ContainerDetailsCard";
import Pagination from "@mui/material/Pagination";
import ContainerDeteailsTable from "./ContainerDeteailsTable";
import { Container } from "@mui/system";
import ContainerFilterDialog from "./ContainerFilterDialog";
import { useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import CustomConsole from "../../CustomConsole";

export default function ContainerDetailsView() {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [capacity, setCapacity] = React.useState("20_Liters");
  const [material, setMaterial] = React.useState("PET");
  const [filterDialogFlag, setFilterDialogFlag] = React.useState(false)
  const [isActive, setIsActive] = React.useState(true)
  const [forDisable, setForDisable] = React.useState(false);
  CustomConsole(isActive)

  const [filetAt, setFiletAt] = React.useState("DESC")
  CustomConsole(filetAt)
  const [filterDetailDialog, setFilterDetailDialog] = React.useState(false)

  const dispatch = useDispatch();
  const adminContainerData = useSelector((state) => state.adminContainerData);

  React.useEffect(() => {
    CustomConsole(adminContainerData);
  }, [adminContainerData]);

  const handleChange = (event) => {
    CustomConsole(event.target.value);
    setCapacity(event.target.value);
  };

  const handleChangeMaterial = (event) => {
    CustomConsole(event.target.value);
    setMaterial(event.target.value);
  };
  const handleFilterDialog = () => {
    setFilterDialogFlag(true)
    // handleCloseDialog()
  }


  const handleCloseDialog = () => {
    setFilterDialogFlag(false)
  }

  // React.useEffect(()=>{
  //   if(filterDialogFlag){
  //     dispatch(GetContainerDepositDetailsList())
  //     setFilterDialogFlag(false)
  //setFilterDetailDialog(false)
  //   }

  // },[filterDialogFlag])
  const clickFilerDetailsDetails = () => {
    setPageNumber(1);
    dispatch(GetContainerDepositDetailsList(capacity, material, pageNumber - 1, isActive, filetAt));
    setFilterDialogFlag(false);
    setFilterDetailDialog(false);
    handleCloseDialog();
  }

  const handleSubmit = () => {
    dispatch(
      GetContainerDepositDetailsList(capacity, material, pageNumber - 1, isActive, filetAt)
    );
    CustomConsole("clicked");
  };
  React.useEffect(() => {

    dispatch(
      GetContainerDepositDetailsList(capacity, material, pageNumber - 1, isActive, filetAt)
    );
  }, [pageNumber]);

  // const handleChangePage = (event, value) => {
  //   CustomConsole(value);
  //   setPageNumber(value);
  // };

  CustomConsole(adminContainerData.depositedList.length)
  CustomConsole(filterDialogFlag)

  const pageChangeForward = () => {
    if (adminContainerData.depositedList.length > 0) {
      setPageNumber(pageNumber + 1);
    }
    if (adminContainerData.depositedList.length === 0) {
      setForDisable(true);
    }
  }

  const pageChangeBackward = () => {
    if ((pageNumber - 1) >= 1) {
      setPageNumber(pageNumber - 1);
      setForDisable(false);
    }
  }


  return (
    <>
      <Box sx={{display:"flex",justifyContent:"flex-end",padding:-1,marginTop:{lg:"3em",},gap:"1em"}}>
        <Typography>
        Container Capacity: <strong>{capacity}</strong> 
        </Typography>
        <Typography>
        Container Material: <strong> {material}</strong>
        </Typography>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleFilterDialog}
        >
          Filter
        </Button>

      </Box>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={12}>


              <FormControl
                sx={{ m: 1, minWidth: 250 }}
              >
                <InputLabel id="demo-simple-select-label">
                  Capacity
                </InputLabel>
                <Select

                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={capacity}
                  label="Capacity"
                  onChange={handleChange}
                >
                  <MenuItem value={"20_Liters"}>20_Liters</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12}>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="demo-simple-select-label">
                  Material
                </InputLabel>
                <Select

                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={material}
                  label="Capacity"
                  onChange={handleChangeMaterial}
                >
                  <MenuItem value={"PET"}>PET</MenuItem>
                  <MenuItem value={"PVC"}>PVC</MenuItem>
                  <MenuItem value={"POLYCARBONATE"}>POLYCARBONATE</MenuItem>
                  <MenuItem value={"COPPER"}>COPPER</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12}>
              <Button variant="contained" size="small" onClick={handleSubmit}>
                Submit
              </Button>
            </Grid> */}
      </Grid>


      <Grid container>
        <Grid item md={12}>
          {filterDialogFlag === true ? (<ContainerFilterDialog
            filterDialogFlag={filterDialogFlag}
            setFilterDialogFlag={setFilterDialogFlag}
            // handleClose={handleCloseDialog}
            setFiletAt={setFiletAt}
            setIsActive={setIsActive}
            clickFilerDetailsDetails={clickFilerDetailsDetails}
            capacity={capacity}
            setCapacity={setCapacity}
            material={material}
            setMaterial={setMaterial}
          />
          ) : (" ")}


          {adminContainerData.error === false &&
            adminContainerData.depositedList.length ? (
            // zero was corrected by removing && and ternary operator was used.
            <>
              <ContainerDeteailsTable handleSubmit={handleSubmit} data={adminContainerData.depositedList} />
              {/* {adminContainerData.depositedList.map((data, key) => {
                return <ContainerDetailsCard data={data} />;
              })} */}

            </>
          ) : null}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              padding: "10px",
              marginTop: "10px"
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer"
                }}
                onClick={pageChangeBackward}
              // disabled={enableBakBtn}
              >
                <ArrowBackIosIcon />
              </Button>

            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom

              sx={{ marginLeft: "10px", marginRight: "10px", backgroundImage:"radial-gradient(circle, rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)", color: "white", padding: "5px", width: "40px", height: "35px", borderRadius: "15px", textAlign: "center", }}

            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer"
                }}
                onClick={pageChangeForward}
                disabled={forDisable}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>




    // <Box>
    //   <Paper elevation={24}>
    //     <Card sx={{ minWidth: 375 }}>
    //       <CardContent>
    //         <Box>
    //           <Box sx={{ padding: "10px" }}>
    //             <FormControl fullWidth>
    //               <InputLabel id="demo-simple-select-label">
    //                 Capacity
    //               </InputLabel>
    //               <Select
    //                 labelId="demo-simple-select-label"
    //                 id="demo-simple-select"
    //                 value={capacity}
    //                 label="Capacity"
    //                 onChange={handleChange}
    //               >
    //                 <MenuItem value={"20_Liters"}>20_Liters</MenuItem>
    //               </Select>
    //             </FormControl>
    //           </Box>
    //           <Box sx={{ padding: "10px" }}>
    //             <FormControl fullWidth>
    //               <InputLabel id="demo-simple-select-label">
    //                 Material
    //               </InputLabel>
    //               <Select
    //                 labelId="demo-simple-select-label"
    //                 id="demo-simple-select"
    //                 value={material}
    //                 label="Capacity"
    //                 onChange={handleChangeMaterial}
    //               >
    //                 <MenuItem value={"PET"}>PET</MenuItem>
    //                 <MenuItem value={"PVC"}>PVC</MenuItem>
    //                 <MenuItem value={"POLYCORBONATE"}>POLYCORBONATE</MenuItem>
    //                 <MenuItem value={"COPPER"}>COPPER</MenuItem>
    //               </Select>
    //             </FormControl>
    //           </Box>
    //           <Box sx={{ padding: "10px" }}>
    //             <Button variant="contained" size="small" onClick={handleSubmit}>
    //               Submit
    //             </Button>
    //           </Box>
    //         </Box>
    //       </CardContent>
    //     </Card>
    //   </Paper>
    // <Box>
    //   {adminContainerData.error === false &&
    //     adminContainerData.depositedList.length ? ( 
    //       // zero was corrected by removing && and ternary operator was used.
    //       <>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             padding: "10px",
    //             marginTop: "10px",
    //           }}
    //         >
    //           <Pagination
    //             count={2}
    //             page={pageNumber}
    //             onChange={handleChangePage}
    //             color="primary"
    //           />
    //         </Box>

    //         <ContainerDeteailsTable data={adminContainerData.depositedList}/>
    //         {/* {adminContainerData.depositedList.map((data, key) => {
    //           return <ContainerDetailsCard data={data} />;
    //         })} */}
    //       </>
    //     ):null}
    // </Box>
    // </Box>
  );
}
