import { Cancel, Verified } from "@mui/icons-material";
import { Box, Button, ThemeProvider, createTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";

function TransferCanOrderTableView(props) {
  const [data, setData] = React.useState("");
  CustomConsole(data);

  const handleReturnCanEnterOTP = (object) => {
    CustomConsole(object);
    props.handleReturnCanEnterOTP({
      order_id: object.order_id,
      is_submit_started: object.is_submit_started,
      is_submit_finished: object.is_submit_finished,
    });
  };

  const headerData = [
    { field: "fullName", headerName: "FullName", width: 130 },
    { field: "OrderId", headerName: "Order Id", width: 130 },

    {
      field: "EnterOtp",
      headerName: "Process",
      width: 100,
      renderCell: (params) => {
        CustomConsole("ufjffiuhlihil");
        CustomConsole(params);
        return (
          <Button
            sx={{
              backgroundColor: "green",
              "&:hover": { backgroundColor: "black" },
            }}
            variant="contained"
            onClick={() =>
              handleReturnCanEnterOTP({
                order_id: params.row.OrderId,
                is_submit_started: params.row.is_submit_started,
                is_submit_finished: params.row.is_submit_finished,
              })
            }
          >
            {params.row.is_submit_started === false &&
            params.row.is_submit_finished === false
              ? "Enter Otp"
              : params.row.is_submit_started === true &&
                params.row.is_submit_finished === false
              ? "In-scan"
              : null}
          </Button>
        );
      },
    },
    { field: "TotalOrderQty", headerName: "Total Order Qty", width: 150 },
    { field: "OrderStatus", headerName: "Order Status", width: 150 },
    { field: "Material", headerName: "Material", width: 150 },
    { field: "Capacity", headerName: "Capacity", width: 100 },
  ];
  React.useEffect(() => {
    let transferCanDataTemp = [];
    props.data.map((data, key) => {
      CustomConsole(data);
      transferCanDataTemp[key] = {
        id: key + 1,
        fullName: data.fullname,
        OrderId: data.transfer_cans_order_id,
        TotalOrderQty: data.total_container_qty,
        OrderStatus: props.transferCansStatusShow(data.order_status),
        Material: data.container_material,
        Capacity: data.container_capacity,
        is_submit_finished: data.is_submit_finished,
        is_submit_started: data.is_submit_started,
      };
    });
    setData(transferCanDataTemp);
  }, []);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#a3a1e6",
            color: "black",
            fontWeight: "bold",
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });
  return (
    <Box
      sx={{
        height: 520,
        maxWidth: { xs: "350px", sm: "400px", md: "90%", lg: "94%" },
        overflow: "auto",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={data}
          columns={headerData}
          autoHeight
          hideFooter={true}
          sx={{
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </ThemeProvider>
    </Box>
  );
}

export default TransferCanOrderTableView;
