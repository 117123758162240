import React from "react";
import * as Material from "@mui/material";
import Html5QrcodePlugin1 from "../../../Pages/wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../../../Pages/wfsHome/ResultContainerPlugin1";
import { useDispatch } from "react-redux";
import { PutSupplierReturnInScanCans } from "../../../Pages/Redux/Actions/supliersAction";
import { GetDistributorReturnCanOrders } from "../../../Pages/Redux/Actions/wfsOrdersAction";
import CustomConsole from "../../../CustomConsole";
function WfsReturnCanCameraDialog(props) {
  CustomConsole(props);
  const dispatch = useDispatch();
  const [openQrorCanDamageDialog, setOpenQrorCanDamageDialog] =
    React.useState(false);
  const [damageOrFineCans, setDamageOrFineCans] = React.useState("IsDamages");
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [link, setLink] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [returnInOutScanBtn, setReturnInOutScanBtn] = React.useState(false);
  const [returnSubmitOtpBtn, setReturnSubmitOtpBtnBtn] = React.useState(false);

  const [selectCanFilledOrNot, setSelectCanFilledOrNot] = React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [scanCount, setScanCount] = React.useState(0);
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  // const [openCamera, setOpenCamera] = React.useState(false);
  const [apiData, setApiData] = React.useState({});
  const [openReturnCanCameraDialog, setOpenReturnCanCameraDialog] =
    React.useState(false);
  const [scanOption, setScanOption] = React.useState("scanner");

  React.useEffect(() => {
    //Scanned Qr Code
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    //Scanned Bar Code
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  const handleHitApi = () => {
    CustomConsole("---- handleHitApi ----");
    props.setOpenCamera(false);
    dispatch(
      PutSupplierReturnInScanCans({
        order_id: props.returnCanOrderId,
        qr_barcode_id1: scannedQrCode || scannedBarCode,
      })
    );
    setReturnInOutScanBtn(true);
    // props.apiData.setIsFilledCan("")
    setTimeout(() => {
      dispatch(GetDistributorReturnCanOrders());
    }, 500);

    setOpenQrorCanDamageDialog(false);
    props.setOpenReturnCanCameraDialog(false);
    setScanQrCodeDetailBtn(false);

    setScannedQrCodeDetails("");
    setScannedQrCode("");
    setScannedBarCode("");
    // setLinkingDialogOpen(false);
    // setScanCount(0);
    setDecodedResults([]);
    // setLink(false);
    // setLinkQrBarcodeBtn(false);
  };
  React.useEffect(() => {
    CustomConsole("--- link useeffect ---");
    // CustomConsole(link);
    if (scannedQrCode !== "" || scannedBarCode !== "") {
      CustomConsole("-- User Click on handleLink");
      handleHitApi();
    }
  }, [scannedQrCode, scannedBarCode]);
  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  React.useEffect(() => {
    CustomConsole(`Scan count: ${scanCount}`);
  }, [scanCount]);
  const handleClose = () => {
    CustomConsole("-------------onClose------------");
    props.handleClose();
  };
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      // if (props.openQrBarCodeCamera === true) {
      setScannedQrCode(qrBarcodeId);
      setDecodedResults((prev) => [...prev, qrBarcodeId]);
      // } else {
      //   setScanQrCodeDetailBtn(true);
      //   setScannedQrCodeDetails(qrBarcodeId);
      //   setDecodedResults([]);
      // }

      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);
  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };
  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };
  return (
    <Material.Box>
      <Material.Dialog
        open={props.openReturnCanCameraDialog}
        onClose={handleClose}
      >
        <Material.DialogContent sx={{ width: "400px", height: "400px" }}>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              padding: "10px",
            }}
          >
            <Material.FormControl sx={{ marginTop: "15px" }}>
              <Material.FormLabel>Select Scanning Option</Material.FormLabel>
              <Material.RadioGroup
                defaultValue="scanner"
                value={scanOption}
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={handleSelectScanOption}
              >
                <Material.FormControlLabel
                  value="scanner"
                  control={<Material.Radio />}
                  label="Scanner Device"
                />
                <Material.FormControlLabel
                  value="camera"
                  control={<Material.Radio />}
                  label="Camera"
                />
              </Material.RadioGroup>
            </Material.FormControl>
            {scanOption === "camera" ? (
              <Material.Box>
                <Html5QrcodePlugin1
                  fps={1}
                  qrbox={200}
                  disableFlip={false}
                  qrCodeSuccessCallback={onNewScanResult}
                />
              </Material.Box>
            ) : (
              <Material.TextField
                onChange={handleQrBarcodeIdFromScanner}
                label="QR/Barcode:"
                value={qrBarcodeId}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Material.Box>

          {/* {props.openQrBarCodeCamera === true && scanOption === "camera" ? (
            <Material.Box>
              <Html5QrcodePlugin1
                fps={1}
                qrbox={200}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            </Material.Box>
          ) : null} */}

          {/* {props.openCamera === true ? (
            <Material.Box>
              <Html5QrcodePlugin1
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            </Material.Box>
          ) : null} */}
          <ResultContainerPlugin1
            results={decodedResults}
            setScanCount={setScanCount}
          />
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default WfsReturnCanCameraDialog;
