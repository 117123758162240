/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

 import React from "react";
 import * as Material from "@mui/material";
 import Box from "@mui/material/Box";

 function NotFound() {
   return (
     <Box>
       <Material.Toolbar />
       <h1> Page Not Found (404)</h1>
     </Box>
   );
 }
 
 export default NotFound;
 