import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  products: {},
  cartCalulations: {},
  validProducts: [],
  inValidvalidProducts: [],
  validatingErr: true,
  userStock: [],
  bookingResp: {},
  bookingErr: true,
  couponCodeData: [],
  error: false,
  msg: "",
  subsValidatingErr: true,
  subsCartCalulations: {},
  subsValidProducts: [],
  subscriptionResp: {},
  subsData: [],
  subsUpdateErr: false,
  subsUpdateMsg: "",
  subsEditErr: false,
  subsEditMsg: "",
};

const adminBookingSkuReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_PRODUCTS_ON_ADDRESS_ID:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        products: action.payload?.data ? action.payload.data : {},
        userStock: action.payload?.data?.user_stock
          ? action.payload.data.user_stock
          : [],
      };
    case actionType.VALIDATING_PRODUCT:
      return {
        ...state,
        validatingErr: action.payload.error,
        msg: action.payload.msg,
        cartCalulations: action.payload?.data ? action.payload.data : {},
        validProducts: action.payload?.data.valid_products
          ? action.payload.data.valid_products
          : [],
        inValidvalidProducts: action.payload?.data.invalid_products
          ? action.payload.data.invalid_products
          : [],
      };
    case actionType.BOOK_NOW_API:
      return {
        ...state,
        bookingResp: action.payload.data ? action.payload.data : {},
        bookingErr: action.payload.error,
      };
    case actionType.CONSMUER_COUPON_CODE:
      return {
        ...state,
        couponCodeData: action.payload?.data ? action.payload.data : [],
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.VALIDATING_PRODUCT_SUBSCRIPTION:
      return {
        ...state,
        subsValidatingErr: action.payload.error,
        msg: action.payload.msg,
        subsCartCalulations: action.payload?.data ? action.payload.data : {},
        subsValidProducts: action.payload?.data.valid_products
          ? action.payload.data.valid_products
          : [],
      };
    case actionType.SUBSCRIPTION_BOOKING:
      return {
        ...state,
        subscriptionResp: action.payload ? action.payload : {},
      };
    case actionType.GET_SUBSCRIPTION_DATA:
      return {
        ...state,
        subsData: action.payload.data ? action.payload.data : [],
      };
    case actionType.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subsUpdateErr: action.payload.error,
        subsUpdateMsg: action.payload.msg,
      };
    case actionType.EDIT_SUBSCRIPTION:
      return {
        ...state,
        subsEditErr: action.payload.error,
        subsEditMsg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default adminBookingSkuReducer;
