import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";

import CustomConsole from "../../../CustomConsole";
export const GetUserDetails = (phoneNumber) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  //   9566205411
  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/users?email_phone=${phoneNumber}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USER_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetOngoingOrdersWfsInAdmin = (pageNumber, userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  const errorResponse = {
    error: true,
    data: [],
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/wfsOngoingOrders?user_id=${userId}&page_number=${pageNumber}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ONGOING_ORDER_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Catch block error");
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch({
        type: actionType.GET_ONGOING_ORDER_ADMIN,
        payload: errorResponse,
      });
    }
  };
};
export const GetCompletedOrdersWfsInAdmin = (pageNumber, userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  const errorResponse = {
    error: true,
    data: [],
  };
  let api = useAxios();

  CustomConsole(pageNumber);
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/wfsCompletedOrders?user_id=${userId}&page_number=${pageNumber}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_COMPLETED_ORDER_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_COMPLETED_ORDER_ADMIN,
        payload: errorResponse,
      });
    }
  };
};
export const GetAllOrdersWfsInAdmin = (pageNumber, userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  const errorResponse = {
    error: true,
    data: [],
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/wfsAllOrders?user_id=${userId}&page_number=${pageNumber}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ALL_ORDER_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_ALL_ORDER_ADMIN,
        payload: errorResponse,
      });
    }
  };
};

export const StartSubmissinOngoingWfsAdmin = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  //   9566205411
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/startWfsOngoingOrder`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_START_SUBMISSION_ONGOING_ADMIN,
          payload: response,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.PUT_START_SUBMISSION_ONGOING_ADMIN,
        payload: error.response,
      });
    }
  };
};
export const GetWfsCompletedBatchDetails = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  //   9566205411
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/getWfsCompletedBatchDetails`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WFS_COMPLETED_BATCH_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const PutWfsEditBatchDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/editBatchDetails`, reqObj);
      CustomConsole(response);
      CustomConsole("---- hccjyc----");
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole("--- success ---");
        CustomConsole(response.data);
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch({
          type: actionType.PUT_WFS_EDIT_BATCH_DETAILS,
          payload: response.data,
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};
