import actionType from "../Actions/actionType";

const initialState = {
  error: true,
  msg: "",
  actionTriger: true,
  punchDetails: {},
  vehicleTrips: [],
};
const vehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.EMPLOYEE_PUNCHIN_DETAILS:
      return {
        ...state,
        punchDetails: action.payload.data,
        actionTriger: false,
      };
    case actionType.VEHICLE_CURRENT_TRIPS:
      return {
        ...state,
        vehicleTrips: action.payload.data,
      };
    default:
      return state;
  }
};
export default vehiclesReducer;
