/**
 * @author vinay kumar
 * @description Distributor View Details
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay

import React, { useEffect } from "react";
import CustomConsole from "../../CustomConsole";
import {
  Button,
  Card,
  Box,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SearchAreasByPincode } from "../Redux/Actions/adminBookingAction";
import {
  AddDeliveryArea,
  GetDistributorDetails,
} from "../Redux/Actions/appSettingsAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function DistributorViewDetails({ distriNum, adminRole }) {
  const [pincode, setPincode] = React.useState("");
  const [area, setArea] = React.useState("");
  const [transportCharge, setTransportCharge] = React.useState("");
  const [addFields, setAddFields] = React.useState(false);
  const [updateFlag, setUpdateFlag] = React.useState(false);

  const dispatch = useDispatch();
  const pincodeDetails = useSelector(
    (state) => state.adminBookingData.availableAreas
  );
  const updatedResponse = useSelector((state) => state.appSettingsDetails);

  const handleChangePincode = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length === 6) {
      dispatch(SearchAreasByPincode(e.target.value));
      setAddFields(true);
      setUpdateFlag(true);
    } else {
      setArea("");
    }
  };

  const pincodeEntry = () => {
    CustomConsole("--- pdincodeEntry ----");
    if (area === "" || transportCharge === "") {
      CustomConsole("---Erreor---");
      toast.error("Fill all fields & Enter Proper Pincode", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    CustomConsole(pincode.toString().length);
    CustomConsole(area);
    CustomConsole(transportCharge);
    CustomConsole(distriNum);
    if (
      pincode.toString().length === 6 &&
      area !== "" &&
      transportCharge !== "" &&
      distriNum !== ""
    ) {
      CustomConsole("----All details in-----");
      let reqObj = {
        area_name: [area],
        phone: distriNum,
        pincode: pincode,
        transportation_charges: transportCharge,
      };
      CustomConsole(reqObj);
      dispatch(AddDeliveryArea(reqObj));
    }
  };

  React.useEffect(() => {
    if (updatedResponse.error === false && updateFlag === true) {
      CustomConsole(updatedResponse);
      toast.success(updatedResponse.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAddFields(false);
      setPincode("");
      setArea("");
      setTransportCharge("");
      setUpdateFlag(false);
      dispatch(GetDistributorDetails(distriNum));
    }
  }, [updatedResponse]);

  const handleChangeTransportCharges = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "" || (Number(inputValue) >= 0 && !isNaN(inputValue))) {
      setTransportCharge(e.target.value);
    }
  };
  React.useEffect(() => {
    if (pincodeDetails && pincodeDetails.length === 0) {
      setArea("");
    }
  }, [pincodeDetails]);
  return (
    <>
      {ADMIN_ROLES.BW_CS === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
      ADMIN_ROLES.BW_OPS === adminRole ||
      ADMIN_ROLES.BW_OPSHEAD === adminRole ||
      ADMIN_ROLES.BW_FINANCE === adminRole ||
      ADMIN_ROLES.BW_FINANCEHEAD === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ||
      ADMIN_ROLES.BW_SALES === adminRole ? (
        ""
      ) : (
        <Box>
          <Typography
            sx={{ fontSize: "18", fontWeight: "bold", color: "blue" }}
            display="block"
            gutterBottom
          >
            Assign delivery area
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
              m: 1,
            }}
          >
            <Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                // spacing={0.5}
              >
                <TextField
                  sx={{ width: "150px" }}
                  id="outlined-number"
                  required
                  size="small"
                  label="Pincode"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onKeyDown={(e) =>
                    (e.key === "e" || e.key === "-" || e.key === "+") &&
                    e.preventDefault()
                  }
                  value={pincode}
                  onChange={handleChangePincode}
                />
              </Stack>
            </Box>

            <>
              <Box>
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="area-select-small" required>
                    Area
                  </InputLabel>
                  <Select
                    labelId="area-select-small"
                    id="area-select-small"
                    label="Area"
                    style={{ width: 210 }}
                    value={area}
                    onChange={(e) => {
                      setArea(e.target.value);
                    }}
                  >
                    {pincodeDetails.map((option) => (
                      <MenuItem key={option.Name} value={option.Name}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <TextField
                  sx={{ width: "150px" }}
                  id="outlined-number"
                  required
                  size="small"
                  label="Transport charges"
                  type="number"
                  onKeyDown={(e) =>
                    (e.key === "e" || e.key === "-" || e.key === "+") &&
                    e.preventDefault()
                  }
                  value={transportCharge}
                  onChange={handleChangeTransportCharges}
                />
              </Box>
            </>

            <Box>
              <Button onClick={pincodeEntry} variant="contained">
                Add area
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default DistributorViewDetails;
