import React from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  GetOrderDetailsById,
  ReplacementQrlessInScan,
} from "../Redux/Actions/adminReplacementOrderAction";
import CustomConsole from "../../CustomConsole";
import { replacementCanOrdersStatus } from "../../Components/AppMeta/appMetaConfig";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import ReplacementReasonDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReplacementReasonDialog";
import AssignDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AssignDeliveryAgentDialog";
import ReplacementCameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReplacementCameraDialog";
import SupplierOrdersEnterOtpDialog from "../../Components/DialoBoxes/SupplierOrdersEnterOtpDialog";
import {
  CompleteReplacementOrder,
  GetSupplierDeliveryAgentDetails,
  InscanQRBarcode,
  PostSupplierAssignDeliveryAgentReturnOrReplacement,
  PutLinkInScanQrBarCode,
  PutSupplierOutScanFilledCans,
  PutSupplierReplacemtVerifyOtp,
} from "../Redux/Actions/supliersAction";
import ReplacementCameraCanSwapScanInvalidCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReplacementCameraCanSwapScanInvalidCansDialog";
import {
  ArrowBack,
  Call,
  CheckCircle,
  DocumentScanner,
  HighlightOff,
  LocalShipping,
  Place,
  Refresh,
} from "@mui/icons-material";
import ApprovedOrRejectedCansCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ApprovedOrRejectedCansCanSwapDialog";
import ReturnCanApprovedRejectedCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReturnCanApprovedRejectedCanSwapDialog";
import DeliveriesScanInValidCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/DeliveriesScanInValidCansDialog";
import { GetQrBarcodeSwapList } from "../Redux/Actions/canSwapingAction";
import CanSwapOrderRequestDetailsDialog from "../../Components/DialogBoxes/CanSwapOrderRequestDetailsDialog";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import ReplcementQrlessInScanDialog from "./ReplcementQrlessInScanDialog";
function ReplacementSingleOrder() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const replecementDetails = useSelector(
    (state) => state.replacementOrderAdmin.orderbyDetails
  );
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);
  CustomConsole(replecementDetails);
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");
  CustomConsole(orderId);
  CustomConsole(ADMIN_ROLES);
  const [replacementOrderDetails, setReplacementOrderDetails] = React.useState(
    []
  );
  const [replacementCanOrderData, setReplacementCanOrderData] = React.useState(
    []
  );

  const [enterOtpOpenDialog, setEnterOtpOpenDialog] = React.useState(false);
  const [userDeliverLocation, setUserDeliverLocation] = React.useState({
    latitude: "",
    longitude: "",
  });
  const [openCameraDialog, setOpenCameraDialog] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scanCount, setScanCount] = React.useState(0);
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [openQrBarCodeCamera, setOpenQrBarCodeCamera] = React.useState(false);
  const [openOutScanCamera, setOpenOutScanCamera] = React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [replacementInScanBtn, setReplacementInScanBtn] = React.useState(false);
  const [openInScanReasonDialog, setOpenInScanReasonDialog] =
    React.useState(false);
  const [replacementOrderId, setReplacementOrderId] = React.useState("");
  const [openReplacementCanFilter, setOpenReplacementCanFilter] =
    React.useState(false);
  const [openApprovedRejectedCans, setOpenApprovedRejectedCans] =
    React.useState(false);
  const [replacementCanView, setReplacementCanView] = React.useState("form");
  const [reasonData, setReasonData] = React.useState({});
  CustomConsole(reasonData);
  const [openScanInValidCsnsDialog, setOpenScanInValidCsnsDialog] =
    React.useState(false);
  const [
    openReturnApprovedRejectedDialog,
    setOpenReturnApprovedRejectedDialog,
  ] = React.useState(false);

  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [replacementOutScanBtn, setReplacementOutScanBtn] =
    React.useState(false);
  const [openReplacementQrlessDialog, setOpenReplacementQrlessDialog] =
    React.useState(false);
  const [replacementSubmitOtpBtn, setReplacementSubmitOtpBtn] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);

  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [link, setLink] = React.useState(false);
  const [adminRole, setAdminRoleId] = React.useState("");
  CustomConsole("-----------------------");
  CustomConsole(adminRole);
  CustomConsole(ADMIN_ROLES);

  const inQrBarcodeAdmin = true;

  CustomConsole(supplierDeliveries);
  CustomConsole(supplierDeliveries.getSupplerSupplierDeliveryAgentList);
  React.useEffect(() => {
    dispatch(GetOrderDetailsById(orderId));
  }, []);
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);
  React.useEffect(() => {
    // CustomConsole(adminWithdrawallistData)
    if (Object.keys(replecementDetails).length > 0) {
      replecementDetails?.map((data) => {
        CustomConsole(data);
        setReplacementOrderDetails(data);
      });
    } else {
      setReplacementOrderDetails([]);
    }
  }, [replecementDetails]);

  React.useEffect(() => {
    console.log(replacementOrderDetails);
    setUserDeliverLocation({
      latitude: replacementOrderDetails.latitude,
      longitude: replacementOrderDetails.longitude,
    });
    const ReplacementData = [
      { type: "Order Id", payload: replacementOrderDetails.order_id },
      { type: "Consumer Name", payload: replacementOrderDetails.name },
      {
        type: "Consumer Number",
        payload: replacementOrderDetails.contact_phone
          ? replacementOrderDetails.contact_phone
          : "--",
      },
      {
        type: "Status",
        payload:
          replacementOrderDetails.order_status ===
            replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
          replacementOrderDetails.replace_can_qty !==
            replacementOrderDetails.accepted_can_qty
            ? "Started"
            : replacementOrderDetails.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
            ? "Accepted"
            : "Completed",
      },
      {
        type: "Replace Can Qty",
        payload: replacementOrderDetails.replace_can_qty,
      },

      {
        type: "Received Can Qty",
        payload: replacementOrderDetails.accepted_can_qty,
      },
      {
        type: "Exchanged Can Qty",
        payload: replacementOrderDetails.exchanged_can_qty,
      },
      {
        type: "Product",
        payload:
          replacementOrderDetails.container_capacity +
          "-" +
          replacementOrderDetails.container_material,
      },
      {
        type: "Created At",
        payload: replacementOrderDetails.created_at,
      },
      {
        type: "Updated At",
        payload: replacementOrderDetails.updated_at,
      },
      {
        type: "Driver Name",
        payload:
          replacementOrderDetails.driver_details !== undefined &&
          replacementOrderDetails.driver_details !== null &&
          replacementOrderDetails.driver_details.drivers_name != undefined
            ? replacementOrderDetails.driver_details.drivers_name
            : "--",
      },
      {
        type: "Driver phone",
        payload:
          replacementOrderDetails.driver_details !== undefined &&
          replacementOrderDetails.driver_details !== null &&
          replacementOrderDetails.driver_details.drivers_phone != undefined
            ? replacementOrderDetails.driver_details.drivers_phone
            : "--",
      },
    ];
    CustomConsole(ReplacementData);
    setReplacementCanOrderData(ReplacementData);
  }, [replacementOrderDetails]);

  const handleDataButtonClick = () => {
    CustomConsole("-------- handleDataButtonClick ----------");
    switch (replacementOrderDetails.order_status) {
      case 200:
        CustomConsole("---- Admin Accepted Order ----");
        // Open OTP Dialog Box
        setEnterOtpOpenDialog(true);
        break;
      case 400:
        if (
          replacementOrderDetails.details !== undefined &&
          replacementOrderDetails.details !== null &&
          replacementOrderDetails.details?.in?.length ===
            replacementOrderDetails.replace_can_qty &&
          replacementOrderDetails.details?.out?.length !==
            replacementOrderDetails.replace_can_qty
        ) {
          CustomConsole("-------- OutScans --------");
          // Set OutScan Related Functionality Here
          setOpenOutScanCamera(true);
          setOpenQrBarCodeCamera(false);
          setOpenCameraDialog(true);
        } else if (
          replacementOrderDetails.details !== undefined &&
          replacementOrderDetails.details !== null &&
          replacementOrderDetails.details?.out?.length ===
            replacementOrderDetails.replace_can_qty &&
          replacementOrderDetails.details?.in?.length ===
            replacementOrderDetails.replace_can_qty
        ) {
          const reqObj = {
            is_complete: true,
          };
          dispatch(
            CompleteReplacementOrder(replacementOrderDetails.order_id, reqObj)
          );
          setTimeout(() => {
            dispatch(GetOrderDetailsById(orderId));
          }, 1000);
        } else if (
          replacementOrderDetails.details !== undefined &&
          replacementOrderDetails.details !== null &&
          replacementOrderDetails.details?.is_qr === false
        ) {
          setOpenReplacementQrlessDialog(true);
        } else {
          CustomConsole("---- Inscan ----");
          // Set Inscan Related Functionality Here
          setOpenOutScanCamera(false);
          // setOpenQrBarCodeCamera(true);
          // setOpenCameraDialog(true);
          setOpenInScanReasonDialog(true);
        }
        break;
      case 500:
        CustomConsole("---  Order Completed ----");
        break;
      default:
        CustomConsole("---- Invalid Order Status ----");
        break;
    }
  };

  React.useEffect(() => {
    dispatch(
      GetQrBarcodeSwapList({
        status: 200,
        page_number: 1,
        page_size: 100,
        order_id: replacementOrderDetails.order_id,
      })
    );
  }, []);
  const refreshOrderDetails = () => {
    // dispatch(GetOrderDetailsById(orderId));
    window.location.reload(true);
  };

  const handleSubmitOtp = () => {
    CustomConsole("---- handleSubmitOtp -----");

    setEnterOtpOpenDialog(false);
    const reqObj = {
      otp: enterOtpTextfied,
    };

    dispatch(PutSupplierReplacemtVerifyOtp({ orderId, reqObj }));
    //   setReplacementSubmitOtpBtn(true);
    setEnterOtpTextfied("");
    setTimeout(() => {
      dispatch(GetOrderDetailsById(orderId));
    }, 500);
  };
  const handleCloseDialog = () => {
    CustomConsole("---- handleCloseDialog -----");
    setEnterOtpOpenDialog(false);
  };

  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenCameraDialog(false);
    setOpenOutScanCamera(false);
    setOpenQrBarCodeCamera(false);
  };
  const handleBack = () => {
    CustomConsole("---handleBack---");
    CustomConsole(adminRole);
    CustomConsole(ADMIN_ROLES);
    window.history.back();
  };

  const handleOpenAssignDeliveryAgentDialog = (props) => {
    CustomConsole(props);
    CustomConsole("---handleOpenAssign-------");
    dispatch(GetSupplierDeliveryAgentDetails());
    setReplacementOrderId(props);
    setOpenAssignDeliveryAgentDialog(true);
    CustomConsole(replacementOrderId);
  };

  const handleCloses = () => {
    setOpenAssignDeliveryAgentDialog(false);
  };
  const handleAssignDeliveryAgent = (props) => {
    CustomConsole(replacementOrderId);
    CustomConsole(props);
    const reqObj = {
      delivery_agent_id: props.driver_id,
      order_id: replacementOrderDetails.order_id,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierAssignDeliveryAgentReturnOrReplacement(reqObj));
    setOpenAssignDeliveryAgentDialog(false);
    setTimeout(() => {
      dispatch(GetOrderDetailsById(orderId));
    }, 500);
  };

  const handleCloseReasonDialog = () => {
    setOpenInScanReasonDialog(false);
  };
  const handleInScan = (props) => {
    CustomConsole(props);
    setReasonData(props);
    setOpenInScanReasonDialog(false);
    if (props !== "") {
      setOpenQrBarCodeCamera(true);
      setOpenCameraDialog(true);
    } else {
      CustomConsole("---------selectttttt---------");
    }
  };
  const handleSendRequest = (props) => {
    setOpenScanInValidCsnsDialog(false);
    const reqObj = {
      scan_type: props.scan_type,
      qr_code: props.qr_code,
      order_id: orderId,
    };
    CustomConsole(reqObj);
  };
  const handleCloseDialogInvalidCans = () => {
    setOpenScanInValidCsnsDialog(false);
  };
  const handleScanInValidCans = () => {
    setOpenScanInValidCsnsDialog(true);
  };

  const onNewScanResult = (props) => {
    CustomConsole(props);
  };
  const handleReturnApprovedOrRejectedCans = () => {
    setOpenApprovedRejectedCans(true);
  };

  const handleCloseReturnApprovedRejectedDialog = () => {
    setOpenReturnApprovedRejectedDialog(false);
  };
  const handleCloseApprovedRejectedDialog = () => {
    setOpenApprovedRejectedCans(false);
  };
  const handleLinkEmptyCans = (props) => {
    CustomConsole("------------handleLinkEmptyCans-------------");
    CustomConsole(props);
    const reqObj = {
      qr_code: props.approvedEmpty,
      order_id: replacementOrderDetails.order_id,
    };
    dispatch(InscanQRBarcode(reqObj));
  };
  const handleLinkApprovedEmptyCans = (props) => {
    CustomConsole("------------handleLinkApprovedEmptyCans-------------");
    CustomConsole(props);
    const reqObj = {
      qr_code: props.approvedFilledids,
      order_id: replacementOrderDetails.order_id,
    };
    dispatch(InscanQRBarcode(reqObj));
  };
  const handleLinkApprovedQrs = (props) => {
    CustomConsole("-- handleLinkApprovedQrs --");
    CustomConsole(props);
    if (props.approved_empty.length) {
      for (const data of props.approved_empty) {
        CustomConsole("----------approvedEmpty-----------");
        const reqObj = {
          is_filled_can: true || undefined,
          order_id: props.order_id,
          qr_barcode_id1: data,
          qr_barcode_id2: "",
          type_of_can: 1004,
        };
        CustomConsole(reqObj);
        setTimeout(() => {
          dispatch(PutLinkInScanQrBarCode(reqObj));
        }, 1000);
      }
    } else if (props.approved_filled.length) {
      for (const data of props.approved_filled) {
        CustomConsole("----------approvedfilled-----------");
        const reqObj = {
          order_id: props.order_id,
          qr_id: data,
        };
        setTimeout(() => {
          dispatch(PutSupplierOutScanFilledCans(reqObj));
        }, 1000);
      }
    }

    setTimeout(() => {
      dispatch(GetOrderDetailsById(orderId));
    }, 500);
    // if (props.approved_empty.length) {
    //   //Link the approved empty
    //   const reqObj = {
    //     order_id: props.order_id,
    //     qr_code: props.approved_empty,
    //   };
    //   CustomConsole(reqObj);
    //   dispatch(InscanQRBarcode(reqObj));
    // }

    //@ToDo: Check the API for Linking the filled cans and proceed further
  };
  const handleGMapButton = () => {
    CustomConsole(userDeliverLocation);
    if (
      userDeliverLocation.latitude !== undefined &&
      userDeliverLocation.latitude !== null &&
      userDeliverLocation.longitude !== undefined &&
      userDeliverLocation.longitude !== null &&
      parseFloat(userDeliverLocation.latitude) > 0 &&
      parseFloat(userDeliverLocation.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${userDeliverLocation.latitude},${userDeliverLocation.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.contact_phone !== "") {
      CustomConsole(props.contact_phone);
      const telUrl = `tel:${props.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleSubmitInscnQrless = (props) => {
    const reqObj = {
      reuseable: props.reuseable,
      nonReuseble: props.nonReuseble,
    };
    console.log(reqObj);
    dispatch(ReplacementQrlessInScan(replacementOrderDetails.order_id, reqObj));
    setOpenReplacementQrlessDialog(false);

    setTimeout(() => {
      dispatch(GetOrderDetailsById(orderId));
    }, 500);
  };
  const handleCloseReplaceQrless = () => {
    setOpenReplacementQrlessDialog(false);
  };
  return (
    <Box sx={{ marginTop: "50px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          xs={12}
          component={Paper}
          elevation={20}
          // sx={{ pr: "10px", pt: "10px" }}
        >
          <Box>
            {/* ORDER DETAILS */}
            <Box
              sx={{
                bgcolor: "#778E9E",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleBack}
                  sx={{
                    // backgroundColor: "#34f7b3",
                    color: "white",
                    borderRadius: "20px",
                  }}
                >
                  <ArrowBack />
                </Button>
              </Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  mt: "3px",
                  color: "white",
                }}
              >
                ORDER DETAILS
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={refreshOrderDetails}
                  sx={{
                    // backgroundColor: "#d8f59f",
                    color: "white",
                    borderRadius: "100px",
                  }}
                >
                  <Refresh />
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handlePhone(replacementOrderDetails)}
                  sx={{
                    color: "white",
                    borderRadius: "50px",
                    bgcolor: "#239B56",
                    width: "10px",
                  }}
                >
                  <Call />
                </Button>
              </Box>
            </Box>

            <Divider
              sx={{
                height: `${1}px`, // Adjust the thickness as needed
                borderStyle: "dashed",
                border: "1px solid black",
              }}
            />
            <Box
              sx={{
                bgcolor: "#F4F6F7",
                pt: "10px",
                pb: "10px",
                pl: "5px",
                pr: "5px",
              }}
            >
              {replacementCanOrderData.map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "#717D7E",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {data.payload}
                    </Typography>
                  </Box>
                );
              })}
              <Box>
                {/* CONSUMER ADDRESS */}
                <Box
                  sx={{
                    bgcolor: "#778E9E",
                    display: "flex",
                    justifyContent: "space-around",
                    // gap: "20px",
                  }}
                >
                  <Box>
                    {replacementOrderDetails.order_status !== 200 ||
                    replacementOrderDetails.order_status !== 400 ||
                    ADMIN_ROLES.BW_DELIVERYAGENT === adminRole ? (
                      <Button
                        // disabled={
                        //   replacementOrderDetails.order_status === 200 ||
                        //   replacementOrderDetails.order_status === 400 ||
                        //   ADMIN_ROLES.BW_DELIVERYAGENT === adminRole
                        // }
                        variant="outlined"
                        size="small"
                        onClick={handleOpenAssignDeliveryAgentDialog}
                        sx={{
                          backgroundColor: "white",
                          color: "black",
                          borderRadius: "20px",
                        }}
                      >
                        Assign
                        <LocalShipping />
                      </Button>
                    ) : (
                      <Button></Button>
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      mt: "3px",
                      color: "white",
                      mr: "40px",
                    }}
                  >
                    ADDRESS
                  </Typography>
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleGMapButton}
                      sx={{
                        backgroundColor: "white",
                        color: "blue",
                        borderRadius: "50px",
                      }}
                    >
                      <Place sx={{ color: "red" }} /> Maps
                    </Button>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: `${1}px`, // Adjust the thickness as needed
                    borderStyle: "dashed",
                    border: "1px solid black",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: "#F4F6F7",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>
                    {replacementOrderDetails.consumer_address !== undefined &&
                    replacementOrderDetails.consumer_address !== null &&
                    replacementOrderDetails.consumer_address.address_line1 !=
                      undefined &&
                    replacementOrderDetails.consumer_address.address_line1 != ""
                      ? replacementOrderDetails.consumer_address.address_line1
                      : "--"}
                    ,
                  </Typography>
                  <Typography>
                    {replacementOrderDetails.consumer_address !== undefined &&
                    replacementOrderDetails.consumer_address !== null &&
                    replacementOrderDetails.consumer_address.address_line2 !=
                      undefined &&
                    replacementOrderDetails.consumer_address.address_line2 != ""
                      ? replacementOrderDetails.consumer_address.address_line2
                      : "--"}
                    ,
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      {replacementOrderDetails.consumer_address !== undefined &&
                      replacementOrderDetails.consumer_address !== null &&
                      replacementOrderDetails.consumer_address.service_area !=
                        undefined &&
                      replacementOrderDetails.consumer_address.service_area !=
                        ""
                        ? replacementOrderDetails.consumer_address.service_area
                        : "--"}{" "}
                      -
                    </Typography>
                    <Typography>
                      {replacementOrderDetails.consumer_address !== undefined &&
                      replacementOrderDetails.consumer_address !== null &&
                      replacementOrderDetails.consumer_address.pincode !=
                        undefined &&
                      replacementOrderDetails.consumer_address.pincode != ""
                        ? replacementOrderDetails.consumer_address.pincode
                        : "--"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <Box sx={{ margin: "10px" }}>
                {replacementOrderDetails.order_status ===
                  replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED ||
                replacementOrderDetails.order_status ===
                  replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED ? (
                  <Button
                    onClick={handleDataButtonClick}
                    variant="contained"
                    size="small"
                    sx={{
                      background: appColors.commonBtnColor.background,
                      color: "white",
                      borderRadius: "10px",
                    }}
                  >
                    {replacementOrderDetails.order_status ===
                      replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
                    replacementOrderDetails.details !== undefined &&
                    replacementOrderDetails.details !== null &&
                    replacementOrderDetails.details?.is_qr !== undefined &&
                    replacementOrderDetails.details?.is_qr !== null &&
                    replacementOrderDetails.details?.is_qr === false
                      ? "complete"
                      : replacementOrderDetails.order_status ===
                          replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
                        replacementOrderDetails.details !== undefined &&
                        replacementOrderDetails.details !== null &&
                        replacementOrderDetails.replace_can_qty !==
                          replacementOrderDetails.details?.in?.length
                      ? "In-Scan"
                      : replacementOrderDetails.order_status ===
                          replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
                        replacementOrderDetails.details !== undefined &&
                        replacementOrderDetails.details !== null &&
                        replacementOrderDetails.replace_can_qty ===
                          replacementOrderDetails.details?.in?.length &&
                        replacementOrderDetails.details?.out?.length !==
                          replacementOrderDetails.replace_can_qty
                      ? "Out-Scan"
                      : replacementOrderDetails.details !== undefined &&
                        replacementOrderDetails.details !== null &&
                        replacementOrderDetails.details?.out?.length ===
                          replacementOrderDetails.replace_can_qty &&
                        replacementOrderDetails.details?.in?.length ===
                          replacementOrderDetails.replace_can_qty
                      ? "Complete"
                      : replacementOrderDetails.order_status ===
                        replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
                      ? "Enter OTP"
                      : ""}
                  </Button>
                ) : null}
              </Box>
              {/* <Box>
                {" "}
                <Button
                  variant="contained"
                  onClick={handleScanInValidCans}
                  sx={{
                    backgroundColor: "blue",
                    color: "white",
                    margin: "10px",
                  }}
                >
                  <DocumentScanner /> in-valid cans
                </Button>
              </Box>
              <Button
                variant="outlined"
                onClick={handleReturnApprovedOrRejectedCans}
                sx={{
                  backgroundColor: "#2C3E50",
                  color: "white",
                  margin: "10px",
                  borderRadius: "10px",
                  ":hover": {
                    backgroundColor: "#73AFEB",
                    color: "black",
                  },
                }}
              >
                Approved
                <CheckCircle
                  fontSize="20px"
                  sx={{ bgcolor: "green", color: "white" }}
                />
                Cans
              </Button> */}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* {(replacementOrderDetails.delivery_agent_id === undefined ||
        replacementOrderDetails.delivery_agent_id === null ||
        parseInt(replacementOrderDetails.delivery_agent_id) === 0) &&
      parseInt(replacementOrderDetails.order_status) === 200 ? (
        <Box>
          <Button
            variant="contained"
            sx={{
              background: appColors.commonBtnColor.background,
              color: "white",
              borderRadius: "10px",
            }}
            onClick={handleOpenAssignDeliveryAgentDialog}
          >
            Assign Delivery Agent
          </Button>
        </Box>
      ) : (
        ""
      )} */}

      {/* <ReturnCanApprovedRejectedCanSwapDialog
        openReturnApprovedRejectedDialog={openReturnApprovedRejectedDialog}
        handleCloseReturnApprovedRejectedDialog={
          handleCloseReturnApprovedRejectedDialog
        }
        data={canSwapDeatails.getQrBarcodeSwapList}
      /> */}
      {/* <ApprovedOrRejectedCansCanSwapDialog
        openApprovedRejectedCans={openApprovedRejectedCans}
        handleCloseApprovedRejectedDialog={handleCloseApprovedRejectedDialog}
        data={canSwapDeatails.getQrBarcodeSwapList}
        handleLinkApprovedEmptyCans={handleLinkApprovedEmptyCans}
        handleLinkEmptyCans={handleLinkEmptyCans}
      /> */}
      {openApprovedRejectedCans &&
      replacementOrderDetails.order_id &&
      replacementOrderDetails.order_id.length ? (
        <CanSwapOrderRequestDetailsDialog
          open={openApprovedRejectedCans}
          order_id={replacementOrderDetails.order_id}
          handleClose={handleCloseApprovedRejectedDialog}
          handleLink={handleLinkApprovedQrs}
        />
      ) : null}
      {enterOtpOpenDialog ? (
        <SupplierOrdersEnterOtpDialog
          isDialogOpen={enterOtpOpenDialog}
          handleClose={handleCloseDialog}
          handleSubmitOtp={handleSubmitOtp}
          otpValidationError={otpValidationError}
          setOtpValidationError={setOtpValidationError}
          enterOtpTextfied={enterOtpTextfied}
          setEnterOtpTextfied={setEnterOtpTextfied}
        />
      ) : null}
      <ReplacementCameraDialog
        openCameraDialog={openCameraDialog}
        setOpenCameraDialog={setOpenCameraDialog}
        openQrBarCodeCamera={openQrBarCodeCamera}
        openOutScanCamera={openOutScanCamera}
        orderId={orderId}
        handleClose={handleCloseCameraDialog}
        setReplacementInScanBtn={setReplacementInScanBtn}
        setReplacementOutScanBtn={setReplacementOutScanBtn}
        setScanQrCodeDetailBtn={setScanQrCodeDetailBtn}
        pageNumber={pageNumber}
        reasonData={reasonData}
        pageSize={pageSize}
        replacementOrderDetails={replacementOrderDetails}
      />
      <AssignDeliveryAgentDialog
        openAssignDeliveryAgentDialog={openAssignDeliveryAgentDialog}
        agentListata={supplierDeliveries?.getSupplerSupplierDeliveryAgentList}
        handleClickAssignDeliveryAgent={handleAssignDeliveryAgent}
        hadleClose={handleCloses}
      />
      <ReplacementReasonDialog
        openInScanReasonDialog={openInScanReasonDialog}
        handleInScan={handleInScan}
        handleClose={handleCloseReasonDialog}
      />
      {/* <ReplacementCameraCanSwapScanInvalidCansDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        handleSendRequest={handleSendRequest}
        handleCloseDialog={handleCloseDialogInvalidCans}
        onNewScanResult={onNewScanResult}
      /> */}
      <DeliveriesScanInValidCansDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        // handleSendRequest={handleSendRequest}
        setOpenScanInValidCsnsDialog={setOpenScanInValidCsnsDialog}
        handleCloseDialog={handleCloseDialogInvalidCans}
        orderid={replacementOrderDetails.order_id}
      />
      {openReplacementQrlessDialog && (
        <ReplcementQrlessInScanDialog
          openReplacementQrlessDialog={openReplacementQrlessDialog}
          handleSubmitInscnQrless={handleSubmitInscnQrless}
          handleCloseReplaceQrless={handleCloseReplaceQrless}
        />
      )}
    </Box>
  );
}

export default ReplacementSingleOrder;
