/**
 * @author vinay kumar
 * @description Supplier Settings Main
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 29-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import CustomConsole from "../../CustomConsole";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import CompanyProfile from "./CompanyProfile";
import SupplierUserStock from "./SupplierUserStock";
import DeliveryAgent from "./DeliveryAgent";
import SupervisedUserCircle from "@mui/icons-material/SupervisedUserCircle";
import MoreSettings from "./MoreSettings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "85vw" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function SettingsMain() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Material.Box sx={{ marginTop: "50px", width: { md: "80Vw", xs: "85Vw" } }}>
      <Box sx={{ bgcolor: "background.paper" }}>
        <AppBar
          position="static"
          sx={{
            color: "white",
            borderRadius: "10px",
            width: { md: "75Vw", xs: "90Vw" },
            // background: appColors.commonLayoutColor.background,
            // backgroundImage: appColors.commonLayoutColor.backgroundImage,
            background: "#6495ED",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            // variant="scrollable"
            aria-label="full width tabs example"
          >
            <Tab label="Company Profile" {...a11yProps(0)} />
            {/* <Tab label="Stock" {...a11yProps(1)} /> */}
            {/* <Tab label="Delivery Agents" {...a11yProps(2)} /> */}
            <Tab label="More" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          // height="100vh"
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CompanyProfile />
          </TabPanel>
          {/* <TabPanel value={value} index={1} dir={theme.direction}>
            <SupplierUserStock />
          </TabPanel> */}
          {/* <TabPanel value={value} index={2} dir={theme.direction}>
            <DeliveryAgent />
          </TabPanel> */}
          <TabPanel value={value} index={1} dir={theme.direction}>
            <MoreSettings />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Material.Box>
  );
}

export default SettingsMain;
