import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomConsole from "../../CustomConsole";
import { Box } from "@mui/material";

export default function RequestListFilteredDialog(props) {
  const handleClose = () => {
    props.setOpenFilter(false);
  };

  const handleChangeOption1 = (e) => {
    props.setOrderBy(e.target.value);
  };
  const handleChangeOption2 = (e) => {
    props.setTsStatus(e.target.value);
  };

  const handleFiltered = () => {
    props.setFilterBtn(true);
    props.setOpenFilter(false);
  };
  return (
    <div>
      <Dialog
        open={props.openFilter}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Order By
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={props.orderBy}
                  onChange={handleChangeOption1}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="ASC"
                    control={<Radio />}
                    label="Ascending"
                  />
                  <FormControlLabel
                    value="DESC"
                    control={<Radio />}
                    label="Descending"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Order By
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={props.tsStatus}
                  onChange={handleChangeOption2}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="1001"
                    control={<Radio />}
                    label="Requested"
                  />
                  <FormControlLabel
                    value="1003"
                    control={<Radio />}
                    label="Request Rejected"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleFiltered} autoFocus>
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
