import React from "react";
import * as Material from "@mui/material";
import CanIcon from "../../Images/CanIcon.svg";
import * as MuiIcons from "@mui/icons-material";
import { Stack } from "@mui/material";

function CurrentDayDeliveriesCardNew(props) {
  const handleScaDetails = (event) => {
    event.stopPropagation();
    props.handleScaDetails(props.data);
  };
  const handleCopyAddress = (event) => {
    event.stopPropagation();
    props.handleCopyAddress(props.data);
  };
  const handleLocation = (event) => {
    event.stopPropagation();
    props.handleLocation(props.data);
  };
  const handlePhone = (event) => {
    event.stopPropagation();
    props.handlePhone(props.data);
  };
  const handleViewDetails = () => {
    props.handleViewDetails(props.data);
  };
  return (
    <Material.Box sx={{ display: "flex", flexDirection: "row" }}>
      <Material.Paper
        sx={{
          marginTop: "10px",
          // maxWidth: { xs: "100%", md: "100%", sm: "650px" },
          // maxWidth:"300px"
          width: { xs: 300, md: 320 },
          // margin: "5px",
        }}
        spacing={2}
        elevation={15}
        onClick={handleViewDetails}
      >
        <Material.Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "5px" }}
          // divider={<Material.Divider orientation="vertical" flexItem />}
        >
          <Material.Chip
            avatar={
              <Material.Avatar
                sx={{
                  color: "black",
                  bgcolor: "white",
                  fontWeight: "bold",
                }}
              >
                {props.data.payment_type === 300
                  ? "P"
                  : props.data.packaged_drinking_water.order_type === 700
                  ? "i"
                  : props.data.packaged_drinking_water.is_subscribed_order ===
                    true
                  ? "S"
                  : "R"}
              </Material.Avatar>
            }
            label={props.data.order_id}
            // onClick={handleViewDetails}
            sx={{
              bgcolor:
                props.data.payment_type === 300
                  ? "#6d147d"
                  : props.data.packaged_drinking_water.order_type === 700
                  ? "#EE4190"
                  : props.data.packaged_drinking_water.is_subscribed_order ===
                    true
                  ? "#3BC2C0"
                  : "#924EEF",
              color: "white",
            }}
          />

          {/* <Material.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
              gap: 1,
            }}
          >
            <img
              src={CanIcon}
              alt="icon"
              style={{
                height: "20px",
                width: "12px",
                marginTop: "3px",
              }}
            />
            <Material.Typography
              mt={0.2}
              sx={{ fontSize: "15px", fontWeight: 600 }}
            >
              : {props.data?.packaged_drinking_water?.twentyltr_capacity_qty}
            </Material.Typography>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Material.Avatar sx={{ width: 13, height: 20 }}>R</Material.Avatar>
            <Material.Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
              :
              {props.data.packaged_drinking_water !== undefined &&
                props.data.packaged_drinking_water !== null &&
                props.data.packaged_drinking_water.empty_container_qty}
            </Material.Typography>
          </Material.Box> */}
          <Material.Box sx={{ display: "flex", gap: "10px" }}>
            <MuiIcons.Call onClick={handlePhone} sx={{ fontSize: "25px" }} />
            <MuiIcons.Place
              onClick={handleLocation}
              color="primary"
              sx={{ maxHeight: "25px", marginLeft: "15px", color: "red" }}
            />
          </Material.Box>
          {/* <Material.Button
            variant="contained"
            onClick={handleViewDetails}
            sx={{
              fontSize: "10px",
              fontWeight: "bold",
              maxWidth: "40px",
            }}
          >
           
            view
          </Material.Button> */}
        </Material.Stack>
        <Material.Divider orientation="horizantal" flexItem />
        <Material.Box sx={{ paddingLeft: "7px" }}>
          <Material.Box>
            <Material.Typography
              sx={{
                fontSize: "12px",
                textAlign: "start",
                // fontWeight: "bold",
              }}
            >
              {props.data.delivery_address.contact_name},
              {props.data.delivery_address.contact_phone}{" "}
              {/* <MuiIcons.Call onClick={handlePhone} sx={{ fontSize: "15px" }} /> */}
            </Material.Typography>
            <Material.Typography
              sx={{ fontSize: "12px", textAlign: "start", overflow: "hidden" }}
            >
              {props.data.delivery_address.address_line1},
              {props.data.delivery_address.address_line2},
              {props.data.delivery_address.address_line3}
            </Material.Typography>
            {/* <Material.Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.data.delivery_address.address_line3}
            </Material.Typography> */}
          </Material.Box>
          <Material.Box sx={{ display: "flex" }}>
            <Material.Box>
              <Material.Typography
                sx={{
                  fontSize: "12px",
                  textAlign: "start",
                  paddingTop: "1px",
                }}
              >
                {props.data.delivery_address.landmark},
                {props.data.delivery_address.service_area},
                {props.data.delivery_address.pincode}{" "}
              </Material.Typography>
            </Material.Box>
            <Material.Box>
              {" "}
              <MuiIcons.ContentCopy
                onClick={handleCopyAddress}
                sx={{ fontSize: "19px", marginLeft: "15px" }}
              />{" "}
              {/* <MuiIcons.PinDrop
                onClick={handleLocation}
                color="primary"
                sx={{ maxHeight: "28px", marginLeft: "15px" }}
              /> */}
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box>
          <Material.Divider orientation="horizontal" flexItem />
          <Material.Stack
            direction={"row"}
            justifyContent="space-evenly"
            alignItems="center"
            // divider={<Material.Divider orientation="vertical" flexItem />}
            mt={0.5}
          >
            <MuiIcons.LocalShippingOutlined />
            <Material.Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.data?.driver_profile?.fullname || props.data?.tanker_id}
            </Material.Typography>
            <Material.Divider orientation="vertical" flexItem />
            <MuiIcons.DepartureBoardOutlined />
            <Material.Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.data.packaged_drinking_water.delivery_slots
                ? props.data.packaged_drinking_water.delivery_slots
                    .admin_selected_delivery_slot &&
                  props.data.packaged_drinking_water.delivery_slots
                    .admin_selected_delivery_slot.length
                  ? props.data.packaged_drinking_water.delivery_slots
                      .admin_selected_delivery_slot
                  : props.data.packaged_drinking_water.delivery_slots
                      .supplier_selected_delivery_slot &&
                    props.data.packaged_drinking_water.delivery_slots
                      .supplier_selected_delivery_slot.length
                  ? props.data.packaged_drinking_water.delivery_slots
                      .supplier_selected_delivery_slot
                  : "---"
                : "---"}
            </Material.Typography>
          </Material.Stack>
        </Material.Box>
      </Material.Paper>
    </Material.Box>
  );
}

export default CurrentDayDeliveriesCardNew;
