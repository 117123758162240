import { Box, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { useDispatch } from "react-redux";
import {
  GetPostpaidOrders,
  GetPostpaidOrdersbyPhone,
} from "../Redux/Actions/adminPostpaidOrdersAction";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import ImageButtonComp from "./ImageButton";
import TextField from "@mui/material/TextField";
import PostpaidDetailsCard from "./PostpaidDetailsCard";
import PostpaidOrdersTable from "./PostpaidOrdersTable";
import PostpaidOrderTableNew from "./PostpaidOrderTableNew.jsx";
import { Container } from "@mui/system";
import { toast } from "react-toastify";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import PostpaidPhoneOrdersTable from "./Filteredpostpaidtable";
import PaymentCardPostpaid from "./Paymentcard";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";

export default function PostpaidOrders() {
  const [adminRoles, setAdminRoles] = React.useState("");
  const dispatch = useDispatch();
  const setUpConfigData = useSelector((state) => state.adminBookingData);
  const postpaidOrderData = useSelector((state) => state.postpaidOrderAdmin);

  const [postpaidList, setPostpaidList] = React.useState([]);
  const [phonePostpaidList, setPhonePostpaidList] = React.useState([]);
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [phoneOrderDetails, setPhoneOrderDetails] = React.useState([]);
  const [cardVisible, setCardVisible] = React.useState(false);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [userId, setUserId] = React.useState("");
  const [request, setrequest] = React.useState([]);
  const [emailPhone, setEmailPhone] = React.useState("");
  const [acceptSuccessMsg, setAcceptSuccessMsg] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [butnFlag, setButnFlag] = React.useState(false);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [orderid, setorderid] = React.useState("");
  const [paybutton, setPaybutton] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [postpaidPaymentMethods, setPostpaidPaymentMethods] = React.useState(
    []
  );
  const [notConfirmOdr, setNotConfirmOdr] = React.useState([]);
  const [orderby, setorderby] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rejectBtn, setRejectBtn] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  React.useEffect(() => {
    CustomConsole(paginationModel);
  }, [paginationModel]);
  const scrollDownToDetailsCard = useRef(null);

  React.useEffect(() => {
    CustomConsole(parseInt(localStorage.getItem("roleId")));
    setAdminRoles(parseInt(localStorage.getItem("roleId")));
  }, []);
  React.useEffect(() => {
    if (setUpConfigData.error === false) {
      if (setUpConfigData.setupConfigData.length) {
        setUpConfigData.setupConfigData.map((data) => {
          if (data.key === "postpaid_payment_types") {
            setPostpaidPaymentMethods(data.value);
          }
        });
      }
    }
  }, [setUpConfigData]);
  React.useEffect(() => {
    let settlement = false;
    dispatch(
      GetPostpaidOrders(
        emailPhone,
        orderby,
        status,
        pageNumber - 1,
        settlement,
        paginationModel.pageSize
      )
    );
  }, []);

  React.useEffect(() => {
    if (paybutton === true) {
      selected.unshift({
        totalamount: totalPrice,
        payment_method: "WaterWallet",
      });
    }
  }, [paybutton]);

  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
  }, []);
  React.useEffect(() => {
    CustomConsole(orderDetails);
    scrollToBottom();
  }, [orderDetails]);

  React.useEffect(() => {
    let settlement = false;
    CustomConsole(postpaidOrderData);
    if (postpaidOrderData.allordersPostpaid.length > 0) {
      setPostpaidList(postpaidOrderData.allordersPostpaid);
    }
    if (postpaidOrderData.phoneordersPostpaid.length > 0) {
      setPhonePostpaidList(postpaidOrderData.phoneordersPostpaid);
      setUserId(postpaidOrderData.phoneordersPostpaid[0].user_id);
    }
    if (
      postpaidOrderData.acceptPostPaid.error === false &&
      acceptSuccessMsg === true
    ) {
      toast.success(postpaidOrderData.acceptPostPaid.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(
        GetPostpaidOrders(
          emailPhone,
          orderby,
          status,
          pageNumber - 1,
          settlement,
          paginationModel.pageSize
        )
      );
      setAcceptSuccessMsg(false);
    }
  }, [postpaidOrderData]);

  const scrollToBottom = () => {
    scrollDownToDetailsCard.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handelSearch = () => {
    if (butnFlag === false) {
      setButnFlag(true);
    } else {
      setButnFlag(false);
    }
    setEmailPhone("");
  };
  const handleEmailPhoneChange = (event) => {
    CustomConsole(event.target.value);
    setEmailPhone(event.target.value);
  };

  const handelphoneSearch = (e) => {
    e.preventDefault();
    if (emailPhone != "") {
      let settlement = true;
      dispatch(GetPostpaidOrdersbyPhone(emailPhone, settlement));
    } else {
      toast.error("Please Enter Phone no", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  CustomConsole(postpaidOrderData);
  return (
    <>
      {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SALES ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SALESHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCE ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCEHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPS ? (
        <Container
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
            // marginTop: "10px",
            gap: "20px",
            padding: "10px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              {/* <Button
                variant="contained"
                color="success"
                onClick={handelSearch}
                sx={appColors.commonBtnColor}
              >
                Select Multiple Payment Option
              </Button> */}
            </Grid>
            {butnFlag === true ? (
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  varient="h6"
                  sx={{ marginBottom: "1em", color: "#0f0399" }}
                >
                  <strong> Enter PostPaid Phone Number For Settlement</strong>
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="Phone No"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={emailPhone}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={handleEmailPhoneChange}
                />
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handelphoneSearch}
                  sx={{
                    marginLeft: "1em",
                    marginRight: "1em",
                    backgroundImage: appColors.commonBtnColor.background,
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="success"
                  onClick={handelSearch}
                >
                  Back
                </Button>
              </Grid>
            ) : (
              ""
            )}
            {postpaidOrderData.phoneordersPostpaid.length > 0 &&
            butnFlag === true ? (
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <PostpaidPhoneOrdersTable
                  phonePostpaidList={phonePostpaidList}
                  setPhoneOrderDetails={setPhoneOrderDetails}
                  phoneOrderDetails={phoneOrderDetails}
                  setNotConfirmOdr={setNotConfirmOdr}
                  setCardVisible={setCardVisible}
                  setCheckedItems={setCheckedItems}
                  checkedItems={checkedItems}
                  setorderid={setorderid}
                  orderid={orderid}
                  setSelected={setSelected}
                  selected={selected}
                  userId={userId}
                  setUserId={setUserId}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  setPaybutton={setPaybutton}
                  request={request}
                  setrequest={setrequest}
                  rejectBtn={rejectBtn}
                />
              </Grid>
            ) : (
              ""
            )}
            {postpaidOrderData.phoneordersPostpaid.length > 0 &&
            butnFlag === true ? (
              <Grid item xs={12} sm={12} md={12} lg={5}>
                <PaymentCardPostpaid
                  phonePostpaidList={phonePostpaidList}
                  userId={userId}
                  selected={selected}
                  setUserId={setUserId}
                  totalPrice={totalPrice}
                  request={request}
                  setPaymentMethod={setPaymentMethod}
                  paymentMethod={paymentMethod}
                  status={status}
                  emailPhone={emailPhone}
                  pageNumber={pageNumber}
                  orderby={orderby}
                  setTotalPrice={setTotalPrice}
                  adminRoles={adminRoles}
                  rejectBtn={rejectBtn}
                  setRejectBtn={setRejectBtn}
                />
              </Grid>
            ) : (
              ""
            )}
            {butnFlag === false ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={cardVisible === false ? 12 : 7}
              >
                <PostpaidOrdersTable
                  postpaidList={postpaidList}
                  setOrderDetails={setOrderDetails}
                  orderDetails={orderDetails}
                  setNotConfirmOdr={setNotConfirmOdr}
                  setCardVisible={setCardVisible}
                  setEmailPhone={setEmailPhone}
                  setorderby={setorderby}
                  setStatus={setStatus}
                  orderby={orderby}
                  status={status}
                  cardVisible={cardVisible}
                  emailPhone={emailPhone}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  handelSearch={handelSearch}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                />
              </Grid>
            ) : (
              ""
            )}

            {Object.keys(orderDetails).length !== 0 &&
            cardVisible === true &&
            butnFlag === false ? (
              <Grid
                item
                sx={{ marginTop: "20px", marginBottom: "10px" }}
                xs={12}
                sm={12}
                md={12}
                lg={5}
              >
                <PostpaidDetailsCard
                  orderDetails={orderDetails}
                  postpaidPaymentMethods={postpaidPaymentMethods}
                  notConfirmOdr={notConfirmOdr}
                  setCardVisible={setCardVisible}
                  setAcceptSuccessMsg={setAcceptSuccessMsg}
                  scrollRef={scrollDownToDetailsCard}
                  status={status}
                  orderby={orderby}
                  emailPhone={emailPhone}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  adminRoles={adminRoles}
                />
              </Grid>
            ) : null}
          </Grid>
        </Container>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </>
  );
}
