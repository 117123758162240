import { Close, QrCodeScanner } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import RecycleScanningDialog from "./RecycleScanningDialog";
import { useDispatch, useSelector } from "react-redux";
import { RECYCLE_STATUS_CODE } from "../../Components/AppMeta/appMetaConfig";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { GetRequestedRecycleOrderList } from "../Redux/Actions/RecycleOrdersAction";
import moment from "moment";

function RecycleSingleOrderDialog(props) {
  console.log(props);
  const recycleordersdetails = useSelector(
    (state) => state.recycleordersdetails
  );
  const orderDetails = recycleordersdetails?.recycleOrderList?.[0];
  const isScanningCompleted =
    orderDetails &&
    (orderDetails?.details?.physical_damage || 0) ===
      (orderDetails?.details?.scanned_physical_damage || 0) &&
    (orderDetails?.details?.expired || 0) ===
      (orderDetails?.details?.scanned_expired || 0) &&
    (orderDetails?.details?.qr_barcode_damage || 0) ===
      (orderDetails?.details?.scanned_qr_barcode_damage || 0);

  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   dispatch(
  //     GetRequestedRecycleOrderList({
  //       order_id: recycleordersdetails?.recycleOrderList[0]?.details?.order_id,
  //     })
  //   );
  // }, []);

  const [openRecyclescanningDialog, setOpenRecyclescanningDialog] =
    React.useState(false);
  const styless = {
    AdminStyleHeading: {
      display: "flex",
      alignItems: "flex-start",
      padding: "1px",
      gap: "3px",
      color: "#0a83c4",
      justifyContent: "space-between",
    },
  };
  const span = {
    spanStyle: {
      color: "black",
      fontWeight: "normal",
      marginRight: "30px",
    },
  };
  const orderStatus = (order_status) => {
    switch (order_status) {
      case RECYCLE_STATUS_CODE.FINISHED:
        return "FINISHED";
      case RECYCLE_STATUS_CODE.CANCELLED:
        return "CANCELLED";
      case RECYCLE_STATUS_CODE.REJECTED:
        return "REJECTED";
      case RECYCLE_STATUS_CODE.ACCEPTED:
        return "ACCEPTED";
      case RECYCLE_STATUS_CODE.REQUESTED:
        return "REQUESTED";
      case RECYCLE_STATUS_CODE.STARTED:
        return "STARTED";
      default:
        return "";
    }
  };
  const handleOpenRecycleScanningDialog = () => {
    setOpenRecyclescanningDialog(true);
  };
  const handleCloseRecycleScanningDialog = () => {
    setOpenRecyclescanningDialog(false);
  };
  const handleCloseRecycleSingleDialog = () => {
    props.handleCloseRecycleSingleDialog();
  };
  const handleAcceptOrReject = (data) => {
    props.handleAcceptOrReject({
      data: data,
      order_id: recycleordersdetails?.recycleOrderList[0]?.details?.order_id,
    });
  };
  return (
    <>
      <Dialog open={props.openRecycleSingleOrderDialog}>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseRecycleSingleDialog}
            >
              <Close />
            </Button>
          </Box>
          <Box>
            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 1,
                  fontWeight: "bold",
                  fontSize: { xs: "1em" },
                }}
              >
                Order ID
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;&nbsp;
                  {recycleordersdetails?.recycleOrderList[0]?.details?.order_id}
                </span>
              </Typography>
            </Box>
            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 1,
                  fontWeight: "bold",
                  fontSize: { xs: "1em" },
                }}
              >
                Product
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;&nbsp;
                  {recycleordersdetails?.recycleOrderList[0]?.details
                    ?.capacity +
                    "-" +
                    recycleordersdetails?.recycleOrderList[0]?.details
                      ?.material}
                </span>
              </Typography>
            </Box>
            {recycleordersdetails?.recycleOrderList[0]?.details
              ?.physical_damage ? (
              <Box sx={styless.AdminStyleHeading}>
                <Typography
                  sx={{
                    margin: 1,
                    fontWeight: "bold",
                    fontSize: { xs: "95%" },
                  }}
                >
                  Physical Damaged Qty
                  <span style={span.spanStyle}>
                    &nbsp;&nbsp;&nbsp;{" "}
                    {recycleordersdetails?.recycleOrderList[0]?.details
                      ?.scanned_physical_damage || 0}{" "}
                    /{" "}
                    {
                      recycleordersdetails?.recycleOrderList[0]?.details
                        ?.physical_damage
                    }
                  </span>
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            {recycleordersdetails?.recycleOrderList[0]?.details?.expired ? (
              <Box sx={styless.AdminStyleHeading}>
                <Typography
                  sx={{
                    margin: 1,
                    fontWeight: "bold",
                    fontSize: { xs: "95%" },
                  }}
                >
                  Expired Qty
                  <span style={span.spanStyle}>
                    &nbsp;&nbsp;{" "}
                    {recycleordersdetails?.recycleOrderList[0]?.details
                      ?.scanned_expired || 0}{" "}
                    /{" "}
                    {
                      recycleordersdetails?.recycleOrderList[0]?.details
                        ?.expired
                    }
                  </span>
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            {recycleordersdetails?.recycleOrderList[0]?.details
              ?.qr_barcode_damage ? (
              <Box sx={styless.AdminStyleHeading}>
                <Typography
                  sx={{
                    margin: 1,
                    fontWeight: "bold",
                    fontSize: { xs: "95%" },
                  }}
                >
                  Qr/Barcode Damaged Qty
                  <span style={span.spanStyle}>
                    &nbsp;&nbsp;{" "}
                    {recycleordersdetails?.recycleOrderList[0]?.details
                      ?.scanned_qr_barcode_damage || 0}{" "}
                    /
                    {
                      recycleordersdetails?.recycleOrderList[0]?.details
                        ?.qr_barcode_damage
                    }
                  </span>
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 1,
                  fontWeight: "bold",
                  fontSize: { xs: "95%" },
                }}
              >
                Status
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;&nbsp;
                  {orderStatus(
                    recycleordersdetails?.recycleOrderList[0]?.status
                  )}
                </span>
              </Typography>
            </Box>
            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 1,
                  fontWeight: "bold",
                  fontSize: { xs: "95%" },
                }}
              >
                Created On
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;&nbsp;
                  {moment(
                    recycleordersdetails?.recycleOrderList[0]?.created_at
                  ).format("DD-MM-YYYY")}
                </span>
              </Typography>
            </Box>
            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 1,
                  fontWeight: "bold",
                  fontSize: { xs: "95%" },
                }}
              >
                Updated By
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;&nbsp;
                  {
                    recycleordersdetails?.recycleOrderList[0]?.updated_user
                      ?.name
                  }
                </span>
              </Typography>
            </Box>
          </Box>
          {recycleordersdetails?.recycleOrderList[0]?.status ===
          RECYCLE_STATUS_CODE.REQUESTED ? (
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={() => handleAcceptOrReject("ACCEPT")}
              >
                Accept
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleAcceptOrReject("REJECT")}
              >
                Reject
              </Button>
            </Box>
          ) : (
            ""
          )}
          {!isScanningCompleted ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
              <Button
                variant="contained"
                onClick={handleOpenRecycleScanningDialog}
              >
                <QrCodeScanner />
                Scan
              </Button>
            </Box>
          ) : (
            ""
          )}
        </DialogContent>
        {openRecyclescanningDialog && (
          <RecycleScanningDialog
            singleOrderData={recycleordersdetails?.recycleOrderList[0]}
            setOpenRecyclescanningDialog={setOpenRecyclescanningDialog}
            qrType={props.recycleSingleOrderDetails.type}
            openRecyclescanningDialog={openRecyclescanningDialog}
            handleCloseRecycleScanningDialog={handleCloseRecycleScanningDialog}
          />
        )}
      </Dialog>
    </>
  );
}

export default RecycleSingleOrderDialog;
