import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DisableUserCans,
  GetLostCansList,
  SendNotificationUsers,
} from "../Redux/Actions/adminLostCansAction";
import LostCansCard from "./LostCansCard";
import { ToastContainer, toast } from "react-toastify";
import FilterDialog from "./FilterDialog";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import { styles } from "./lostCansCardStyles";
import CustomConsole from "../../CustomConsole";
export function LostCans() {
  const dispatch = useDispatch();
  const lostCansDetails = useSelector((state) => state.adminLostCansData);

  const [notifyUsersFlag, setNotifyUsersFlag] = React.useState(false);
  const [disableUserFlag, setDisableUserFlag] = React.useState(false);
  const [noOfCans, setNoOfCans] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [filterDialogFlag, setFilterDialogFlag] = React.useState(false);
  const [filterDetails, setFilterDetails] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [pageStatus, setPageStatus] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [changeBackwordBrn, setChangeBackwordBrn] = React.useState(false);
  const [changeForwardBtn, setChangeForwardBtn] = React.useState(false);
  const [userType, setUserType] = React.useState("WFS");
  // const [checked, setChecked] = React.useState(false);

  //to get the selected values for disable cans
  const [disableCans, setDisableCans] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [adminRolesLostCans, setadminRolesLostCans] = React.useState("");

  React.useEffect(() => {
    const rolesAdminlostcans = parseInt(
      window.localStorage.getItem("roleId"),
      10
    );
    if (rolesAdminlostcans) {
      setadminRolesLostCans(rolesAdminlostcans);
    }
  });

  React.useEffect(() => {
    if (notifyUsersFlag) {
      if (selected.length > 0) {
        const reqObj = {
          user_details: selected,
        };
        dispatch(SendNotificationUsers(reqObj));
      } else {
        toast.error("Select users to send notification", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setNotifyUsersFlag(false);
      }
    }
  }, [notifyUsersFlag]);

  React.useEffect(() => {
    CustomConsole("inside the function call");
    if (disableUserFlag && disableCans.length > 0) {
      let disableCanObj = {
        isWfs: false,
        user_phone: [],
      };
      disableCanObj.user_phone = disableCans;
      dispatch(DisableUserCans(disableCanObj));
    }
    if (disableUserFlag && disableCans.length === 0) {
      toast.error("Select users to diablecans", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setDisableUserFlag(false);
    }
  }, [disableUserFlag]);

  React.useEffect(() => {
    if (pageNumber) {
      dispatch(GetLostCansList(userType, pageNumber - 1));
      setChangeBackwordBrn(true);
      setChangeForwardBtn(true);
    }
  }, [pageNumber]);

  React.useEffect(() => {
    if (lostCansDetails.lostCansList.length > 0) {
      setPageStatus(false);
    } else {
      setPageStatus(true);
    }
  });

  React.useEffect(() => {
    CustomConsole(lostCansDetails);
    if (notifyUsersFlag === true && lostCansDetails.notifyUsersMsg !== "") {
      toast.success(lostCansDetails.notifyUsersMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setNotifyUsersFlag(false);
    }
    if (disableUserFlag === true && lostCansDetails.diableUserCansMsg !== "") {
      toast.success(lostCansDetails.diableUserCansMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setDisableCans([]);
      dispatch(GetLostCansList(userType, pageNumber - 1));
      setCheckedItems([]);
      setDisableUserFlag(false);
    }
  }, [lostCansDetails]);

  const handleNotifyUsers = () => {
    setNotifyUsersFlag(true);
  };

  const handleDisableCans = () => {
    setDisableUserFlag(true);
  };
  const handleFilter = () => {
    setFilterDialogFlag(true);
  };
  const handleCloseDialogs = () => {
    setFilterDialogFlag(false);
  };

  const handleChangeBack = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setChangeBackwordBrn(true);
  };

  const handleChangeForward = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
    setChangeForwardBtn(true);
  };

  React.useEffect(() => {
    CustomConsole(disableCans);
  }, [disableCans]);
  CustomConsole(phone);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // marginTop: "1em",
      }}
    >
      {/* <Box>
        {ADMIN_ROLES.BW_SUPERADMIN === adminRolesLostCans ||
          ADMIN_ROLES.BW_ADMIN === adminRolesLostCans ||
          ADMIN_ROLES.BW_OPSHEAD === adminRolesLostCans ||
          ADMIN_ROLES.BW_FINANCE === adminRolesLostCans ||
          ADMIN_ROLES.BW_FINANCEHEAD === adminRolesLostCans ? (
          <Button
            sx={{ margin: "30px" }}
            variant="contained"
            size="small"
            onClick={handleFilter}
          >
            Filter
          </Button>
        ) : (
          <Box sx={{ marginTop: "10px" }}>
            <Alert severity="error">Access Denied</Alert>
          </Box>
        )}
      </Box> */}
      {ADMIN_ROLES.BW_SUPERADMIN === adminRolesLostCans ||
      ADMIN_ROLES.BW_ADMIN === adminRolesLostCans ||
      ADMIN_ROLES.BW_OPSHEAD === adminRolesLostCans ||
      ADMIN_ROLES.BW_FINANCE === adminRolesLostCans ||
      ADMIN_ROLES.BW_FINANCEHEAD === adminRolesLostCans ||
      ADMIN_ROLES.BW_CS === adminRolesLostCans ||
      ADMIN_ROLES.BW_CSLEAD === adminRolesLostCans ||
      ADMIN_ROLES.BW_CSHEAD === adminRolesLostCans ||
      ADMIN_ROLES.BW_CSLEAD === adminRolesLostCans ||
      ADMIN_ROLES.BW_IVML === adminRolesLostCans ? (
        <>
          {lostCansDetails.error === false && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: { lg: "3em", sm: "-2em" },
                }}
              >
                {checkedItems ? (
                  <>
                    <Button
                      sx={styles.CommonButton}
                      size="small"
                      onClick={handleNotifyUsers}
                    >
                      Notify users
                    </Button>
                    <Button
                      sx={styles.CommonButton}
                      size="small"
                      onClick={handleDisableCans}
                    >
                      Disable cans
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <Button
                  variant="outlined"
                  sx={{ marginLeft: "1em" }}
                  size="small"
                  onClick={handleFilter}
                >
                  Filter
                </Button>
              </Box>
              <LostCansCard
                setNoOfCans={setNoOfCans}
                setPhone={setPhone}
                selected={selected}
                setSelected={setSelected}
                setDisableCans={setDisableCans}
                disableCans={disableCans}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                // checked={checked}
                // setChecked={setChecked}
              />
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
            <Button onClick={handleChangeBack}>
              <ArrowBackIos />
            </Button>

            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                background:
                  "radial-gradient(rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Button disabled={pageStatus} onClick={handleChangeForward}>
              <ArrowForwardIos />
            </Button>
          </Box>
        </>
      ) : (
        ""
      )}

      <ToastContainer />

      <FilterDialog
        filterDialogFlag={filterDialogFlag}
        setFilterDialogFlag={setFilterDialogFlag}
        handleCloses={handleCloseDialogs}
        setFilterDetails={setFilterDetails}
        //handleClickApply={handleClickApply}
        setUserType={setUserType}
        userType={userType}
        pageNumber={pageNumber}
        setSelected={setSelected}
        setDisableCans={setDisableCans}
        setCheckedItems={setCheckedItems}
        // setChecked={setChecked}
      />
    </Box>
  );
}
