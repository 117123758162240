import actionType from "../Actions/actionType";

import CustomConsole from "../../../CustomConsole";
const initialState = {
  error: true,
  msg: "",
  data: [],
  emptyCanOrders: [],
  orderIdDetails: {},
  wfsDetails: [],
};

const adminEmptyOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_EMPTY_CAN_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        emptyCanOrders: action.payload.data,
      };
    case actionType.GET_EMPTY_CAN_BY_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        orderIdDetails: action.payload.data,
      };
    case actionType.REJECT_EMPTY_CAN_REQ:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_WFS_DETAILS_TO_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        wfsDetails: action.payload.data,
      };
    case actionType.ACCEPT_EMPTY_CAN_REQ:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
// case actionType.PUT_EDIT_WFS_ORDER:
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     msg: action.payload.msg,
    //   };
    default:
      return state;
  }
};

export default adminEmptyOrderReducer;
