import actionType from "../Actions/actionType";

const initialState = {
  error: true,
  msg: "",
  recycleOrderList: [],
};

const RecycleOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_RECYCLE_ORDERS_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        recycleOrderList: action.payload.data,
      };

    default:
      return state;
  }
};
export default RecycleOrdersReducer;
