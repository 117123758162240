import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import React from "react";
import { styles } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "../Redux/Actions/fileHandlingAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";
import * as MuiIcons from "@mui/icons-material";
import {
  PostSattlementPaymentAdviceSenEmail,
  SettlementHistory,
} from "../Redux/Actions/adminSettlementAction";
import InfluencerSettlementDialog from "./InfluencerSettlementDialog";
import {
  GetAccountsDetails,
  GetUserAddressAdmin,
} from "../Redux/Actions/userDetailsAdminAction";
import { toast } from "react-toastify";

function AdminSettlementHistoryCard(props) {
  const [openSettlementDialog, setOpenSettlementDialog] = React.useState(false);
  const [addressDetails, setAddressDetails] = React.useState([]);
  const [accountDetails, setAccountDetails] = React.useState([]);
  const [settlementMasyerId, setSettlementMasyerId] = React.useState(0);
  const dispatch = useDispatch();
  CustomConsole(props.settlementData);
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);

  React.useEffect(() => {
    if (updateUserDetails) {
      setAccountDetails(updateUserDetails.accounts);
      setAddressDetails(updateUserDetails.address);
    }
  }, [updateUserDetails]);
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    props.setPageSizeHistory(() => event.target.value);
  };
  const handleOpenSettlementDialog = (uesrId, settleIid) => {
    setSettlementMasyerId(settleIid);
    dispatch(GetUserAddressAdmin(uesrId));
    dispatch(GetAccountsDetails(uesrId));
    setOpenSettlementDialog(true);
  };
  const handleSubmitSettlement = (props) => {
    // CustomConsole.log(props);
    const reqObj = {
      payment_mode: props.payment_mode,
      account_id: props.account_id,
      issued_from: props.issued_from,
      settle_id: settlementMasyerId,
      address_id: props.address_id,
      date: props.date,
    };
    if (
      props.payment_mode !== "" &&
      props.account_id !== "" &&
      props.issued_from !== "" &&
      settlementMasyerId > 0 &&
      props.address_id !== "" &&
      props.date !== ""
    ) {
      dispatch(PostSattlementPaymentAdviceSenEmail(reqObj));
      setOpenSettlementDialog(false);
    } else {
      toast.error("Please Fill All Data", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleCloseSettlement = () => {
    setOpenSettlementDialog(false);
  };
  //@Removed/console.log(props.settlementData);

  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#AED6F1",
          padding: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#1B2631",
            fontWeight: "600",
          }}
        >
          Settlement History
        </Typography>
      </Box> */}
      <Box
        sx={{
          maxHeight: "500px",
          overflow: "auto",
          padding: "10px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TextField
            size="small"
            sx={{ width: "120px" }}
            value={props.pageSizeHistory}
            label="Page size"
            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={props.handleSearchPageSize}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if (props.pageNumberHistory - 1 > 0) {
                  props.setPageNumberHistory(() => props.pageNumberHistory - 1);
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{
                  color: props.pageNumberHistory - 1 > 0 ? "blue" : "gray",
                }}
              />
            </IconButton>
            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {props.pageNumberHistory}
            </Typography>
            <IconButton
              sx={{ maxHeight: "50px" }}
              onClick={() => {
                if (
                  props.settlementData !== undefined &&
                  props.settlementData !== null &&
                  props.settlementData.length > 0
                ) {
                  props.setPageNumberHistory(() => props.pageNumberHistory + 1);
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color: props.settlementData?.length ? "blue" : "gray",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        {props.settlementData !== undefined &&
        props.settlementData !== null &&
        props.settlementData.length > 0 ? (
          <>
            {props.settlementData.map((val, idx) => {
              return (
                <Card
                  sx={{
                    marginTop: "10px",
                    // border:"1px solid black"
                  }}
                  elevation={10}
                  key={idx}
                >
                  <Box>
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {val.user_type === 2000
                          ? "Distributor"
                          : val.user_type === 1002
                          ? "Filling Station"
                          : val.user_type === 3000
                          ? "Consumer"
                          : "Not Identified"}
                      </Typography>
                    </Box>
                    {/* {val.user_type === ADMIN_ROLES.BW_WFSADMIN && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "10px",
                        background: "rgb(208,221,242)",
                        backgroundImage:
                          "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
                        borderRadius: "10px",
                      }}
                    >
                      <Box sx={{ textAlign: "left" }}>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>
                            Company name:
                          </span>
                          {val.company_name}
                        </Typography>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>
                            Owner name:
                          </span>{" "}
                          {val.owner_name}
                        </Typography>
                      </Box>
                      <Box sx={{ textAlign: "left" }}>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>
                            Owner phone:
                          </span>{" "}
                          {val.owner_phone}
                        </Typography>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>
                            Settled by:{" "}
                          </span>
                          {val.settled_by}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )} */}
                    {val.user_type === ADMIN_ROLES.BW_CONSUMER ||
                    val.user_type === ADMIN_ROLES.BW_DISTRIBUTER ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            padding: "10px",
                            background: "rgb(208,221,242)",
                            backgroundImage:
                              "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
                            borderRadius: "10px",
                          }}
                        >
                          <Box sx={{ textAlign: "left" }}>
                            <Typography>
                              <span style={{ fontWeight: "bold" }}>
                                User Name:
                              </span>{" "}
                              {val.fullname}
                            </Typography>
                            <Typography>
                              <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                              {val.email}
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: "left" }}>
                            <Typography>
                              <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                              {val.phone}
                            </Typography>
                            <Typography>
                              <span style={{ fontWeight: "bold" }}>
                                Settled by:
                              </span>{" "}
                              {val.settled_by}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  <CardContent>
                    <Box sx={styles.parentBox}>
                      <Box sx={styles.childBox}>
                        {/* <Typography sx={styles.childTypo}>
                      Total Cans: {val.total_wc_qty}
                    </Typography> */}
                        <Typography sx={styles.childTypo}>
                          Total Price: {val.price_breakup.total_price}
                        </Typography>
                        {val.user_type !== 1002 && val.user_type !== 3000 ? (
                          <Typography sx={styles.childTypo}>
                            Base Can Price:{" "}
                            {val.price_breakup.base_price.base_price_per_can}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      {val.user_type !== 1002 && val.user_type !== 3000 ? (
                        <Box sx={styles.childBox}>
                          <Typography sx={styles.childTypo}>
                            Total Loss Each:{" "}
                            {val.price_breakup.lt_base_price.loss_each}
                          </Typography>
                          <Typography sx={styles.childTypo}>
                            Total Profit Each:{" "}
                            {val.price_breakup.gt_base_price.profit_each}
                          </Typography>
                          <Typography sx={styles.childTypo}>
                            Amount Settled: {val.price_breakup.final_price}
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                    {val.user_type !== 1002 && val.user_type !== 3000 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          marginTop: "20px",
                          gap: "5px",
                        }}
                      >
                        <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                          Cans Sold More Than Base Price:
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "15px",
                              backgroundColor: "#9ECBDE",
                              color: "#5E6F6F",
                              padding: "10px",
                            }}
                          >
                            {val.user_type !== 1002 &&
                            val.user_type !== 3000 ? (
                              <Typography sx={{ fontSize: "13px" }}>
                                Total Cans: {val.price_breakup.base_price.cans}
                              </Typography>
                            ) : (
                              ""
                            )}

                            {/* <Typography sx={{ fontSize: "13px" }}>
                        Total Price:&#x20B9;10
                      </Typography> */}
                          </Box>
                        </Box>
                        <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                          Cans Sold More Than Base Price:
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "15px",
                              backgroundColor: "#9ECBDE",
                              color: "#5E6F6F",
                              padding: "10px",
                            }}
                          >
                            {val.user_type !== 1002 &&
                            val.user_type !== 3000 ? (
                              <Typography sx={{ fontSize: "13px" }}>
                                Total Cans:
                                {val.price_breakup.gt_base_price.profit_each}
                              </Typography>
                            ) : (
                              ""
                            )}

                            {/* <Typography sx={{ fontSize: "13px" }}>
                        Total Price:&#x20B9;10
                      </Typography> */}
                          </Box>
                        </Box>
                        <Box sx={{ fontWeight: "600", fontSize: "13px" }}>
                          Cans Sold More Than Base Price:
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "15px",
                              backgroundColor: "#9ECBDE",
                              color: "#5E6F6F",
                              padding: "10px",
                            }}
                          >
                            {val.user_type !== 1002 &&
                            val.user_type !== 3000 ? (
                              <Typography sx={{ fontSize: "13px" }}>
                                Total Cans:
                                {val.price_breakup.lt_base_price.cans}
                              </Typography>
                            ) : (
                              ""
                            )}

                            {/* <Typography sx={{ fontSize: "13px" }}>
                        Total Price:&#x20B9;10
                      </Typography> */}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        marginTop: "20px",
                      }}
                    >
                      {/* <Button
                        variant="contained"
                        onClick={() =>
                          val.attachment.sales_report !== undefined &&
                          val.attachment.sales_report !== "" &&
                          dispatch(getFiles(val.attachment.sales_report))
                        }
                      >
                        Download Sales Invoice
                      </Button> */}
                      <Button
                        variant="contained"
                        onClick={() =>
                          val.attachment.payment_receipt !== undefined &&
                          val.attachment.payment_receipt !== "" &&
                          dispatch(getFiles(val.attachment.payment_receipt))
                        }
                      >
                        <MuiIcons.Download />
                        Download Payment Receipt
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleOpenSettlementDialog(
                            val.user_id,
                            val.settlement_master_id
                          )
                        }
                      >
                        Notify
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
          </>
        ) : (
          "No More Transactions History"
        )}
        {/* {fakeData.map((val, idx) => {
          return (
            <Card sx={{ marginTop: "10px" }} elevation={10} key={idx}>
              {val}
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography>Settled On:</Typography>
                  </Box>
                  <Box>
                    <Typography>Settled By:</Typography>
                  </Box>
                  <Box>
                    <Typography>Name:mail</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  <Button variant="contained">Download Payment Reciept</Button>
                </Box>
              </CardContent>
            </Card>
          );
        })} */}
        <InfluencerSettlementDialog
          openSettlementDialog={openSettlementDialog}
          accountDetails={accountDetails}
          addressDetails={addressDetails}
          handleCloseSettlement={handleCloseSettlement}
          handleSubmitSettlement={handleSubmitSettlement}
        />
      </Box>
    </>
  );
}

export default AdminSettlementHistoryCard;
