import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomConsole from "../../CustomConsole";
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
import {
  PostpaidAcceptReject,
  GetPoTrackDetails,
  UpdatePostpaidOrder,
  DownloadChallanZip,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { toast } from "react-toastify";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import LooksOneIcon from "@mui/icons-material/LooksOne";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const rows = [
//     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//     { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
//   ];

function SubOrderDialog({
  open,
  setOpen,
  ordersData,
  trackId,
  isPoOrder,
  pageNumber,
  poQty,
  startDatePO,
  poStatus,
  poExpired,
  poProducts,
}) {
  const referenceDate = dayjs(moment(startDatePO).endOf("day").format());

  const fileUploadDetails = useSelector((state) => state.fileHandler);
  const poDetails = useSelector((state) => state.poTrackDetails);
  const productsAvailable = useSelector(
    (state) => state.newProductContainerDetails.getAvailableProducts
  );
  const uploadPoRef = React.useRef(null);
  const dispatch = useDispatch();
  const [isPoUploaded, setPoUploaded] = React.useState(false);
  const [poFile, setPoFIle] = React.useState("");
  const [poUploadFlag, setPoUploadFlag] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);

  const [poId, setPoId] = React.useState("");
  const [editEndDate, setEditEndDate] = React.useState(
    moment(startDatePO).add(1, "days").format()
  );
  const [confirmPODialog, setConfirmPODialog] = React.useState(false);
  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [orderQty, setOrderQty] = React.useState("");
  const [collectProducts, setCollectProducts] = React.useState([]);
  const [availableProd, setAvailableProd] = React.useState([]);
  const [productId, setProductId] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [irremoveProd, setIrremoveProd] = React.useState(new Set());
  const [saleType, setSaleType] = React.useState(1);

  const handleClose = () => {
    setOpen(false);
  };

  const triggerPoRef = () => {
    uploadPoRef.current.click();
  };

  const poUploadChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setPoUploaded(true);
  };

  // const submitPo = () => {
  //   let reqObj = {
  //     pp_track_id: trackId,
  //     po_filename: poFile,
  //   };
  //   if (reqObj.pp_track_id && reqObj.po_filename) {
  //     dispatch(PostpaidAcceptReject(reqObj));
  //     setPoUploadFlag(true);
  //   }
  // };

  const orderStatusLabels = {
    100: "New Request",
    200: "Accepted",
    300: "Rejected",
    400: "Cancelled",
    500: "Delivered",
  };

  const getOrderStatusColor = (status) => {
    switch (status) {
      case 100:
        return "blue";
      case 200:
        return "orange";
      case 300:
        return "brown";
      case 400:
        return "red";
      case 500:
        return "green";
      default:
        return "black";
    }
  };

  const rows =
    ordersData.length > 0 &&
    ordersData.map((obj, index) => {
      return { ...obj, id: index + 1 };
    });

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "order_id",
      headerName: "Order ID",
      width: 150,
    },
    {
      field: "delivery_date",
      headerName: "Delivery Date",
      width: 150,
      valueGetter: (params) =>
        moment(params.row.delivery_date).format("DD-MM-YYYY"),
    },
    {
      field: "order_status",
      headerName: "Order Status",
      width: 150,
      valueGetter: (params) =>
        orderStatusLabels[params.row.order_status] || "---",
      renderCell: (params) => (
        <div
          style={{
            color: getOrderStatusColor(params.row.order_status),
          }}
        >
          {orderStatusLabels[params.row.order_status] || "---"}
        </div>
      ),
    },
    {
      field: "twentyltrqty",
      headerName: "Quantity",
      width: 150,
      valueGetter: (params) => params.row.twentyltrqty,
    },
    {
      field: "settled",
      headerName: "Setttled",
      width: 150,
      valueGetter: (params) => (params.row.settled === true ? "Yes" : "No"),
    },
    {
      field: "servicearea",
      headerName: "Service Area",
      width: 150,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 150,
    },
    {
      field: "contact_phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "contact_person",
      headerName: "Name",
      width: 150,
    },
    {
      field: "final_price",
      headerName: "Price",
      width: 150,
    },
    {
      field: "delivery_challan",
      headerName: "Challan",
      width: 150,
      valueGetter: (params) => (params.row.delivery_challan ? "Yes" : "No"),
    },
  ];

  const cancelPostpaidOpenDig = () => {
    setConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const cancelCall = (trackId) => {
    let reqObj = {
      pp_track_id: trackId,
      is_fc: true,
    };
    if (reqObj.pp_track_id && reqObj.is_fc) {
      dispatch(PostpaidAcceptReject(reqObj));
      setConfirmDialog(false);
      setPoUploadFlag(true);
    }
  };

  const handleQuantityChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setOrderQty(() => event.target.value);
  };

  const openEditDialog = () => {
    setEditDialog(true);
  };

  const shouldDisableDate = (date) => {
    return date.isBefore(referenceDate);
  };

  const onChangeEndDate = (newValue) => {
    if (newValue != null && newValue.$d != null) {
      setEditEndDate(() => moment(newValue.$d).tz("Asia/kolkata").format());
      CustomConsole(newValue.$d);
    }
  };

  const submitEditPO = () => {
    let reqObj = {
      poFile: poFile,
      endDate: editEndDate,
      poId: poId,
      poTrack: trackId,
      products: collectProducts,
    };
    CustomConsole(reqObj);
    dispatch(UpdatePostpaidOrder(reqObj));
    setUpdateFlag(true);
  };

  const editValidation = () => {
    if (poId && !poFile) {
      toast.error("Upload PO file", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!editEndDate) {
      toast.error("Choose a date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!poId && poFile) {
      toast.error("Enter PO ID!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setConfirmPODialog(true);
    }
    setConfirmPODialog(true);
  };

  const downloadChallanZipFolder = (id) => {
    dispatch(DownloadChallanZip(id));
  };

  const handleMultipleProductAdd = (material, capacity, id) => {
    CustomConsole("-----inside---");
    CustomConsole(orderQty);
    if (orderQty !== "" && !isNaN(orderQty) && material && capacity) {
      const existingProductIndex = collectProducts.findIndex(
        (products) =>
          products.container_capacity === capacity &&
          products.container_material === material &&
          products.booking_type === parseInt(saleType)
      );
      CustomConsole("---Existing products---");
      CustomConsole(existingProductIndex);
      if (existingProductIndex !== -1) {
        const updatedProducts = collectProducts.filter(
          (obj, idx) => idx !== existingProductIndex
        );
        const updatedProductObj = {
          qty: parseFloat(orderQty),
          container_capacity: capacity,
          container_material: material,
          id: id,
          booking_type: parseInt(saleType),
        };
        console.log("---Setting 1---");
        return setCollectProducts([...updatedProducts, updatedProductObj]);
      } else {
        // Product does not exist, add a new entry
        const productObj = {
          qty: parseFloat(orderQty),
          container_capacity: capacity,
          container_material: material,
          id: id,
          booking_type: parseInt(saleType),
        };
        console.log("---Setting 2---");
        return setCollectProducts((prev) => [...prev, productObj]);
      }
    }
  };

  const removeProduct = (capacity, material, type) => {
    const existingProductIndex = collectProducts.findIndex(
      (products) =>
        products.container_capacity === capacity &&
        products.container_material === material &&
        products.booking_type === type
    );
    const updatedProducts = collectProducts.filter(
      (obj, idx) => idx !== existingProductIndex
    );
    console.log("---Setting 3---");
    return setCollectProducts([...updatedProducts]);
  };

  const handleEditDialogClose = () => {
    setEditDialog(false);
  };

  React.useEffect(() => {
    if (editDialog) {
      dispatch(GetAvailableProducts());
    }
  }, [editDialog]);

  React.useEffect(() => {
    if (productId) {
      const selectedProduct = availableProd.filter((obj) => {
        return obj.id === productId;
      });
      CustomConsole(selectedProduct);
      setSelectedProduct(() => selectedProduct[0]);
    }
  }, [productId]);

  React.useEffect(() => {
    if (productsAvailable) {
      let mappedProducts = productsAvailable.map((obj) => obj.product_details);
      setAvailableProd(() => (mappedProducts ? mappedProducts : []));
    }
  }, [productsAvailable]);

  React.useEffect(() => {
    if (poUploadFlag && poDetails.error === false) {
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setPoUploadFlag(false);
      let reqObj = {
        page_size: "",
        page_number: pageNumber,
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));
      setOpen(false);
    } else if (poUploadFlag) {
      toast.error("Error in Accepting...", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setPoUploadFlag(false);
    }

    if (updateFlag && !poDetails.poUpdateError) {
      let reqObj = {
        page_size: "",
        page_number: pageNumber,
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));
      setPoFIle("");
      setEditDialog(false);
      setConfirmPODialog(false);
      handleClose();
      setUpdateFlag(false);
    }
  }, [poDetails]);

  React.useEffect(() => {
    CustomConsole(fileUploadDetails);
    if (fileUploadDetails.isFileUploaded === true && isPoUploaded) {
      setPoFIle(fileUploadDetails.fileName);
      setPoUploaded(false);
      fileUploadDetails.isFileUploaded = false;
    }
  }, [fileUploadDetails]);

  React.useEffect(() => {
    if (poProducts && editDialog) {
      let arrayOfString = poProducts.map(
        (obj) =>
          `${obj.container_capacity}-${obj.container_material}-${obj.booking_type}`
      );

      setIrremoveProd(() => new Set(arrayOfString));
    }
  }, [poProducts, editDialog]);

  React.useEffect(() => {
    if (poProducts) {
      console.log("---Setting 4---");
      setCollectProducts(poProducts);
    }
  }, [poProducts]);

  React.useEffect(() => {
    console.log("----Order list of pdw----");
    console.log(ordersData);
  }, [ordersData]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {trackId}
            </Typography>
            {isPoOrder && poStatus === 200 ? (
              <Button
                sx={{
                  mr: 2,
                  color: "white",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
                onClick={openEditDialog}
              >
                Edit Postpaid
              </Button>
            ) : null}
            {!poExpired && poStatus === 200 ? (
              <>
                <Button
                  onClick={cancelPostpaidOpenDig}
                  sx={{ color: "white", fontWeight: 500, fontSize: "15px" }}
                >
                  Cancel Postpaid
                </Button>
              </>
            ) : null}

            {ordersData && ordersData.length > 0 && (
              <Button
                sx={{ color: "white", fontWeight: 500, fontSize: "15px" }}
                onClick={() => downloadChallanZipFolder(trackId)}
              >
                Download challan zip
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Stack
          direction="row"
          spacing={1}
          columnGap={1}
          rowGap={1}
          mb={1}
          mt={1}
          // sx={{ overflow: "auto", flexWrap: "wrap" }}
        >
          {poProducts.map((item, idx) => {
            return (
              <Chip
                key={idx}
                label={`${item.container_capacity}-${item.container_material}, Qty:${item.qty}`}
                color="primary"
                icon={item.booking_type === 2 ? <LooksOneIcon /> : null}
              />
            );
          })}
        </Stack>
        {ordersData && ordersData.length > 0 ? (
          <Box sx={{ height: 600, width: "100%", padding: "20px" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>No orders placed yet</Typography>
          </Box>
        )}
        {/* <Box>
        {isPoOrder ? (
          <Box>
            <Stack mt={2} p={2}>
              <input
                style={{ display: "none" }}
                type="file"
                ref={uploadPoRef}
                onChange={poUploadChange}
              />
              <Button
                variant="contained"
                size="small"
                sx={{
                  fontSize: "0.65rem",
                  backgroundColor: poFile ? "green" : "herit",
                  width: "20%",
                }}
                onClick={triggerPoRef}
              >
                {poFile ? "Po Uploaded" : "Upload Po"}
              </Button>
              <Box mt={2}>
                <Button variant="outlined" onClick={submitPo}>
                  Submit Po
                </Button>
              </Box>
            </Stack>
          </Box>
        ) : (
          ""
        )}
      </Box> */}
        <Dialog
          open={confirmDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeConfirmDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to cancel this Postpaid Order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmDialog}>Disagree</Button>
            <Button onClick={() => cancelCall(trackId)}>Agree</Button>
          </DialogActions>
        </Dialog>
        {/* ------Edit Dialog------ */}
        <Dialog
          open={editDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleEditDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Edit PO less order</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Stack
                direction="row"
                spacing={1}
                columnGap={1}
                rowGap={1}
                mb={2}
                sx={{ overflow: "auto", flexWrap: "wrap" }}
              >
                {collectProducts.map((item, idx) => {
                  return irremoveProd.has(
                    `${item.container_capacity}-${item.container_material}-${item.booking_type}`
                  ) ? (
                    <Chip
                      key={idx}
                      label={`${item.container_capacity}-${item.container_material}, Qty:${item.qty}`}
                      color="primary"
                      icon={item.booking_type === 2 ? <LooksOneIcon /> : null}
                    />
                  ) : (
                    <Chip
                      key={idx}
                      label={`${item.container_capacity}-${item.container_material}, Qty:${item.qty}`}
                      color="primary"
                      icon={item.booking_type === 2 ? <LooksOneIcon /> : null}
                      onDelete={() =>
                        removeProduct(
                          item.container_capacity,
                          item.container_material,
                          item.booking_type
                        )
                      }
                    />
                  );
                })}
              </Stack>

              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                flexWrap="wrap"
                justifyContent="space-between"
                mt={2}
              >
                <FormControl size="small" sx={{ width: "210px" }}>
                  <InputLabel
                    id="product-type-select-label"
                    sx={{ fontSize: "1rem", marginTop: "2px" }}
                  >
                    Products
                  </InputLabel>
                  <Select
                    labelId="product-type-select-label"
                    id="product-type-select"
                    value={productId}
                    label="Product"
                    onChange={(e) => setProductId(e.target.value)}
                  >
                    {availableProd.map((obj, idx) => {
                      return (
                        <MenuItem
                          value={obj.id}
                          sx={{ fontSize: "0.76rem" }}
                          key={idx}
                        >
                          {`${obj.capacity},${obj.material}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Total Quantity"
                  required
                  sx={{ width: "45%" }}
                  value={orderQty}
                  inputProps={{
                    maxLength: 10, // Optional: You can set the maximum length if needed
                    onInput: handleQuantityChange, // Attach the input event handler
                  }}
                  // helperText={
                  //   orderQty && selectedProduct?.sku_qty?.[0]
                  //     ? `Total Pack of ${orderQty}X${
                  //         selectedProduct.sku_qty[0]
                  //           ? selectedProduct.sku_qty[0]
                  //           : 0
                  //       }=${
                  //         selectedProduct.sku_qty[0]
                  //           ? selectedProduct.sku_qty[0] * orderQty
                  //           : 0
                  //       }`
                  //     : ""
                  // }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            handleMultipleProductAdd(
                              selectedProduct.material,
                              selectedProduct.capacity,
                              selectedProduct.id,
                              selectedProduct.booking_type
                            )
                          }
                        >
                          Add
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  // helperText={`${selectedProduct.container_material},${selectedProduct.container_capacity} for 1 quantity will be having ${selectedProduct.sku_qty} products`}
                />
              </Stack>

              <Stack mt={2}>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Sale Type</FormLabel> */}
                  <RadioGroup
                    row
                    aria-label="sale-type"
                    name="sale-type"
                    value={saleType}
                    onChange={(e) => setSaleType(e.target.value)}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Refill"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="One-time Sale"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>

              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                flexWrap="wrap"
                mt={2}
              >
                {/* <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Total Quantity"
                  required
                  value={editQty}
                  inputProps={{
                    maxLength: 10,
                    onInput: handleQuantityChange,
                  }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="DD/MM/YYYY"
                    value={editEndDate}
                    onChange={onChangeEndDate}
                    // minDate={moment().subtract(0, 'days')}

                    disablePast
                    renderInput={(params) => (
                      <TextField size="small" {...params} required />
                    )}
                    shouldDisableDate={shouldDisableDate}
                  />
                </LocalizationProvider>
                <TextField
                  size="small"
                  onChange={(e) => setPoId(e.target.value)}
                  value={poId}
                  InputLabelProps={{ shrink: true }}
                  label="PO ID"
                  required
                />
              </Stack>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                flexWrap="wrap"
                mt={2}
              >
                <Box>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    ref={uploadPoRef}
                    onChange={poUploadChange}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: "0.65rem",
                      backgroundColor: poFile ? "green" : "herit",
                      width: "100%",
                    }}
                    onClick={triggerPoRef}
                  >
                    {poFile ? "Po Uploaded" : "Upload Po"}
                  </Button>
                </Box>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose}>Disagree</Button>
            <Button onClick={editValidation}>Edit Order</Button>
          </DialogActions>
        </Dialog>
        {/* ------ */}
        <Dialog
          open={confirmPODialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setConfirmPODialog(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to edit this PO order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmPODialog(false)}>Disagree</Button>
            <Button onClick={submitEditPO}>Agree</Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </>
  );
}

export default SubOrderDialog;
