import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import CustomConsole from "../../CustomConsole";
import { useSelector } from "react-redux";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UserNameDialog({
  open,
  setOpen,
  setPhoneEmail,
  handleSearchUser,
  setTabValue,
  pageNumber,
  setPageNumber,
}) {
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);

  const [userDetails, setUserDetails] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickUserData = (phone) => {
    setPhoneEmail(phone);
    if (phone) {
      handleSearchUser();
      // setTabValue(1);
      setOpen(false);
    }
  };

  React.useEffect(() => {
    CustomConsole("------User Search details-----");
    CustomConsole(updateUserDetails);
    if (updateUserDetails.userNames) {
      setUserDetails(updateUserDetails.userNames);
    } else {
      setUserDetails([]);
    }
  }, [updateUserDetails.userNames]);

  const pageNation = (val) => {
    if (val === 0) {
      setPageNumber((prev) => (prev - 1 < 0 ? 0 : prev - 1));
    } else {
      setPageNumber((prev) => prev + 1);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth={"xs"}
    >
      <Box
        sx={{
          padding: "10px",
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography>Users list</Typography>
                </Box>
                <Material.Box>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Material.IconButton
                      onClick={() => {
                        if (pageNumber - 1 > 0) {
                          setPageNumber(() => pageNumber - 1);
                        }
                      }}
                    >
                      <MuiIcons.FirstPage
                        sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                      />
                    </Material.IconButton>
                    <Material.Typography
                      sx={{ fontSize: "15px", fontWeight: "bold" }}
                    >
                      {pageNumber}
                    </Material.Typography>
                    <Material.IconButton
                      sx={{ maxHeight: "50px" }}
                      onClick={() => {
                        if (userDetails?.length) {
                          setPageNumber(() => pageNumber + 1);
                        }
                      }}
                    >
                      <MuiIcons.LastPage
                        sx={{
                          color: userDetails.length ? "blue" : "gray",
                        }}
                      />
                    </Material.IconButton>
                  </Material.Box>
                </Material.Box>
              </Box>
            </ListSubheader>
          }
        >
          {userDetails !== undefined &&
          userDetails !== null &&
          userDetails.length > 0 ? (
            <>
              {userDetails.map((obj) => {
                return (
                  <ListItemButton
                    onClick={() => handleClickUserData(obj.phone)}
                  >
                    <ListItemText
                      primary={obj.fullname}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {obj.phone}
                          </Typography>
                          &nbsp;-&nbsp;
                          {obj.role_id === ADMIN_ROLES.BW_DISTRIBUTER
                            ? "SUPPLIER"
                            : [
                                obj.role_id === ADMIN_ROLES.BW_CONSUMER
                                  ? "CONSUMER"
                                  : [
                                      obj.role_id ===
                                      ADMIN_ROLES.BW_DELIVERYAGENT
                                        ? "DELIVERY AGENT"
                                        : [
                                            obj.role_id ===
                                            ADMIN_ROLES.BW_SUPERADMIN
                                              ? "SUPER ADMIN"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_ADMIN
                                              ? "ADMIN"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_DELIVERYAGENT
                                              ? "DELIVERY AGENT"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_WFSADMIN
                                              ? "WFS INCHARGE"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_FINANCE
                                              ? "Finance"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_CS
                                              ? "Customer Support"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_FINANCEHEAD
                                              ? "Finance Head"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_CSHEAD
                                              ? "Customer Support Head"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_OPS
                                              ? "Operation"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_OPSHEAD
                                              ? "Operation Head"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_QA
                                              ? "QA"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_QAHEAD
                                              ? "QA Head"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_SALES
                                              ? "Sales"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_SALESHEAD
                                              ? "Sales Head"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_CSLEAD
                                              ? "Customer Support Lead"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_IVML
                                              ? "Inventory Lead"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_SUPPLIERMANAGER
                                              ? "SUPPLIER MANAGER"
                                              : obj.role_id ===
                                                ADMIN_ROLES.BW_SUPPLIER_LOADERS
                                              ? "SUPPLIER LOADER"
                                              : "",
                                          ],
                                    ],
                              ]}
                        </React.Fragment>
                      }
                    />
                    {/* {`${obj.phone}, ${obj.email}, ${
                    obj.role_id === 3000
                      ? "Consumer"
                      : obj.role_id === 2000
                      ? "Distributer"
                      : ""
                  }`}
                </ListItemText> */}
                  </ListItemButton>
                );
              })}
            </>
          ) : (
            <Typography
              sx={{ color: "red", display: "flex", justifyContent: "center" }}
            >
              No Data Found
            </Typography>
          )}
        </List>
        {/* <Box sx={{ display: "flex", justifyContent: "center", margin: "30px" }}>
          <Button onClick={() => pageNation(0)}>
            <ArrowBackIos />
          </Button>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "40px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber + 1}
          </Typography>
          <Button onClick={() => pageNation(1)} disabled={!userDetails.length}>
            <ArrowForwardIos />
          </Button>
        </Box> */}
      </Box>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserNameDialog;
