// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: SubscriptionDateCard.jsx
// Author: Gowtham Prasath
// Modify: Lebansty Valan
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState } from "react";
import CustomConsole from "../../CustomConsole";
import { Card, Typography, Box, Button } from "@mui/material"; // Import MUI components

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

function SubscriptionSchedule({
  selectedDays,
  setSelectedDays,
  setNextDayDate,
  setUpdateCartShowProductDetails,
  editSubscriptionDialog,
}) {
  const [nextDeliveryDate, setNextDeliveryDate] = React.useState(null);
  const daysofDelivery = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  //Function for handling selected days
  const handleScheduleClick = (dayIndex) => {
    // Check if the day is already selected
    if (selectedDays.includes(dayIndex)) {
      // If selected, remove it from the array
      setSelectedDays(selectedDays.filter((index) => index !== dayIndex));
      setUpdateCartShowProductDetails(false);
    } else {
      // If not selected, add it to the array
      setSelectedDays([...selectedDays, dayIndex]);
      setUpdateCartShowProductDetails(false);
    }
  };

  //function for handling next delivery date

  React.useEffect(() => {
    const sortedArray = selectedDays.sort((a, b) => a - b);
    const currentDay = moment().day();
    CustomConsole(sortedArray[0] - currentDay);
    let graterDates = sortedArray.filter((day) => day > currentDay);
    let smallerDates = sortedArray.filter((day) => day < currentDay);
    let equalDates = sortedArray.filter((day) => day === currentDay);
    let finalArray = [...graterDates, ...smallerDates, ...equalDates];
    CustomConsole(finalArray);
    let diff = finalArray[0] - currentDay;

    if (diff < 0) {
      // If the first day in the sorted array is earlier in the week than today,
      // add 7 days to get the next occurrence.
      diff += 7;
    }
    if (diff === 0) {
      diff = 7;
    }
    if (diff > 0) {
      diff = diff;
    }
    CustomConsole(diff);
    setNextDeliveryDate(moment().add(diff, "days").format("DD MMM YYYY"));
  }, [selectedDays]);

  //Useeffect for setting default day selected
  React.useEffect(() => {
    const dayOfWeek = moment().day();
    CustomConsole("----Days of week---");
    CustomConsole(dayOfWeek);

    if (dayOfWeek === 6) {
      handleScheduleClick(0);
    } else {
      if (editSubscriptionDialog === false) {
        handleScheduleClick(dayOfWeek + 1);
      }
    }
  }, []);

  React.useEffect(() => {
    if (nextDeliveryDate) {
      setNextDayDate(nextDeliveryDate);
    }
  }, [nextDeliveryDate]);

  //useEffect to update selected date array

  return (
    <>
      <Typography
        sx={{
          fontWeight: "bold",
          color: "",
          fontSize: ".9rem",
          marginBottom: ".5rem",
        }}
      >
        Delivery Schedule
      </Typography>
      <Card
        sx={{
          width: "100%",
          boxShadow: "4",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff", // Replace with your actual color value
          borderRadius: ".7rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: "#7e7e7e", // Replace with your actual color value
            padding: ".5rem",
          }}
        >
          {/* <img alt="" src={iconList.CalenderIcon} /> Select days for delivery */}
        </Typography>
        <Box
          sx={{
            display: "flex",
            marginBottom: ".5rem",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            gap: "1rem",
            padding: ".5rem",
          }}
        >
          {daysofDelivery.map((day, index) => (
            <Button
              key={index}
              size="small"
              variant="outlined"
              onClick={() => handleScheduleClick(index)}
              sx={{
                height: "25px",
                backgroundColor: selectedDays.includes(index)
                  ? "#068fff"
                  : "#bfefff", // Replace with your actual color values
                color: selectedDays.includes(index) ? "#fff" : "#068fff", // Replace with your actual color values
                "&:hover": {
                  backgroundColor: selectedDays.includes(index)
                    ? "#068fff"
                    : "#bfefff",
                  color: selectedDays.includes(index) ? "#fff" : "#068fff",
                },
              }}
            >
              {day}
            </Button>
          ))}
        </Box>
      </Card>
      {selectedDays.length > 0 ? (
        <Box sx={{ margin: "1rem 1rem 0 1rem" }}>
          <Typography
            sx={{
              fontSize: ".8rem",
              color: "#7e7e7e",
            }}
          >
            <i>
              <span style={{ color: "red" }}>*</span>Your subscription starts
              from &nbsp;{nextDeliveryDate}
            </i>
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default SubscriptionSchedule;
