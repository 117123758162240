import React, { useState, useEffect } from "react";
import * as Material from "@mui/material";
import { IoCloseSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";
/**
 *
 * @param {}
 * @returns
 * it displays based on keys. if on added and on removed i passed. then it will not auto update. state must be passed manually
 */
const MultiSelectChip = ({ onChange, items, onAdded, onRemoved }) => {
  const setItems = () => {};
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [freeItems, setFreeItems] = useState([]);
  const onRemoveClicked = (index) => {
    if (onRemoved) {
      onRemoved(items[index].id);
    } else {
      items[index].is_selected = false;
      onRemoved(items[index].id);
      setItems([...items]);
    }
  };
  const onItemAdded = (index) => {
    if (onAdded) {
      onAdded(items[index].id);
    } else {
      let temp = items;
      temp[index].is_selected = true;
      setItems([...temp]);
    }
  };

  useEffect(() => {
    CustomConsole(items);
  }, [items]);
  useEffect(() => {
    const freeItems = items?.filter((item, index) => {
      item.index = index;
      if (!item.is_selected) {
        return item;
      }
    });
    setFreeItems(freeItems);
    const selectedItems = items?.filter((item, index) => {
      item.index = index;
      if (item.is_selected) {
        return item;
      }
    });
    setSelectedItems(selectedItems);
  }, [items]);
  CustomConsole(freeItems, "freeItems", selectedItems);
  return (
    <Material.Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
      {selectedItems?.map((item) => {
        return item.is_selected ? (
          <Material.Box
            sx={{
              p: "5px",
              borderRadius: "15px ",
              background: "grey",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Material.Box>{item.name}</Material.Box>
            <Material.Box onClick={() => onRemoveClicked(item.index)} pl={2}>
              <IoCloseSharp color="red" pl={2} />
            </Material.Box>
          </Material.Box>
        ) : (
          ""
        );
      })}
      <Material.Box sx={{ width: "100px" }}>
        <Material.FormControl fullWidth>
          <Material.InputLabel id="demo-simple-select-label">
            Add More
          </Material.InputLabel>
          <Material.Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Add More"
            value={selectedOption}
            size="small"
            IconComponent={null}
            onChange={(e) => {
              onItemAdded(e.target.value);
            }}
          >
            {freeItems.length ? (
              freeItems.map((item, index) => (
                <Material.MenuItem key={index} value={item.index}>
                  {item.name}
                </Material.MenuItem>
              ))
            ) : (
              <Material.MenuItem disabled key={""} value={""}>
                Not found
              </Material.MenuItem>
            )}
          </Material.Select>
        </Material.FormControl>
      </Material.Box>
    </Material.Box>
  );
};

export default MultiSelectChip;
