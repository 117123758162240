import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AddVehicle,
  GetVehiclesWithTrip,
  UpdateVehicle,
} from "../../../Pages/Redux/Actions/supplierVehiclesActions";
import { VehicleTypes } from "../../AppMeta/appMetaConfig";

const AddorUpdateSupplierVehicle = ({
  details,
  isUpdate,
  onClose,
  open = false,
}) => {
  const [newValues, setNewValues] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (details) {
      setNewValues({
        tanker_type: details.tanker_type,
        tanker_profile: details.tanker_profile,
        capacity: details.capacity,
        trips_per_day: details.trips_per_day,
        tanker_id: details.tanker_id,
      });
    }
  }, [details]);
  const submitValues = () => {
    if (details?.tanker_id) {
      dispatch(UpdateVehicle(newValues)).then((data) => {
        if (data) {
          dispatch(GetVehiclesWithTrip()).then((data) => {
            onClose(false);
          });
        }
      });
    } else {
      dispatch(AddVehicle(newValues)).then((data) => {
        if (data) {
          dispatch(GetVehiclesWithTrip()).then((data) => {
            onClose(false);
          });
        }
      });
    }
  };
  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle>
        {details?.tanker_id ? (
          <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
            Update Vehicle Details
          </Typography>
        ) : (
          <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
            Create Vehicle Details
          </Typography>
        )}
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitValues();
          }}
          style={{ width: "100%" }}
        >
          <Grid sx={{ width: "100%", p: 1 }} container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={details?.tanker_id ? true : false}
                required
                label={
                  newValues?.tanker_id
                    ? "Registration Number"
                    : "Registration Number Eg:TNXX..."
                }
                variant="filled"
                key={details?.tanker_id}
                value={newValues?.tanker_id}
                // helperText="Please Fill This"
                onChange={(e) =>
                  setNewValues((prev) => ({
                    ...prev,
                    tanker_id: e.target.value
                      ?.toLocaleUpperCase()
                      ?.replace(/[^a-zA-Z0-9]/g, ""),
                  }))
                }
              />
            </Grid>

            {/* Warehouse Dropdown */}
            {/* <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="outlined" fullWidth>
                <InputLabel id="demo-multiple-name-label">Warehouse</InputLabel>
                <Select
                  fullWidth
                  value={newValues?.tanker_type}
                  onChange={(e) =>
                    setNewValues((prev) => ({
                      ...prev,
                      tanker_type: e.target.value,
                    }))
                  }
                  label="Warehouse"
                >
                  {warehouses?.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="outlined" fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Vehicle Type
                </InputLabel>
                <Select
                  fullWidth
                  value={newValues?.tanker_type}
                  onChange={(e) =>
                    setNewValues((prev) => ({
                      ...prev,
                      tanker_type: e.target.value,
                    }))
                  }
                  label="Vehicle Type"
                >
                  {VehicleTypes?.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Make"
                variant="filled"
                // key={newValues?.tanker_profile}
                value={newValues?.tanker_profile?.make}
                onChange={(e) => {
                  let value = e.target.value?.toUpperCase();
                  setNewValues((prev) => ({
                    ...prev,
                    tanker_profile: {
                      ...prev.tanker_profile,
                      make: value,
                    },
                  }));
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Model"
                variant="filled"
                key={details?.model}
                value={newValues?.tanker_profile?.model}
                onChange={(e) =>
                  setNewValues((prev) => ({
                    ...prev,
                    tanker_profile: {
                      ...prev.tanker_profile,
                      model: e.target.value?.toLocaleUpperCase(),
                    },
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Year of Manufacture"
                variant="filled"
                key={details?.year_of_manufacture}
                value={newValues?.tanker_profile?.year_of_manufacture}
                onChange={(e) =>
                  setNewValues((prev) => ({
                    ...prev,
                    tanker_profile: {
                      ...prev.tanker_profile,
                      year_of_manufacture: e.target.value
                        ? parseInt(e.target.value)
                          ? parseInt(e.target.value)
                          : prev?.tanker_profile?.year_of_manufacture
                        : "",
                    },
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "100%" }} variant="outlined" fullWidth>
                <InputLabel id="demo-multiple-name-label">Fuel Type</InputLabel>
                <Select
                  fullWidth
                  value={newValues?.tanker_profile?.fuel_type}
                  onChange={(e) =>
                    setNewValues((prev) => ({
                      ...prev,
                      tanker_profile: {
                        ...prev.tanker_profile,
                        fuel_type: e.target.value?.toLocaleUpperCase(),
                      },
                    }))
                  }
                  label="Fuel Type"
                >
                  {[
                    { name: "PERTOL", value: "PERTOL" },
                    { name: "DIESEL", value: "DIESEL" },
                    { name: "ELECTRIC", value: "ELECTRIC" },
                    { name: "GAS", value: "GAS" },
                    { name: "HYBRID", value: "HYBRID" },
                  ]?.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Loading Capacity (20_Liter)"
                variant="filled"
                key={details?.capacity}
                value={newValues?.capacity}
                onChange={(e) =>
                  setNewValues((prev) => ({
                    ...prev,
                    capacity: e.target.value
                      ? parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : prev.capacity
                      : "",
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Trips Per day"
                variant="filled"
                key={details?.trips_per_day}
                value={newValues?.trips_per_day}
                onChange={(e) =>
                  setNewValues((prev) => ({
                    ...prev,
                    trips_per_day: e.target.value
                      ? parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : prev.trips_per_day
                      : "",
                  }))
                }
              />
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="demo-multiple-checkbox-label">Usage</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={newValues?.usage || []}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setNewValues((prev) => ({
                      ...prev,
                      usage:
                        typeof value === "string" ? value.split(",") : value,
                    }));
                  }}
                  label="Usage"
                  //   input={<OutlinedInput label="Usage" />}
                  renderValue={(selected) => selected.join(", ")}
                  //   MenuProps={MenuProps}
                >
                  {[
                    "Water Can Loading",
                    "Filling Trips",
                    "Deliveries,Internal",
                  ].map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={newValues?.usage?.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

            {/* Insurance Details */}
            {/* <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                  gap: 2,
                }}
              >
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Policy Number"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.policy_number}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev?.tanker_profile,
                          policy_number: e.target.value,
                        },
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Insurance Company"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.insurance_company}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          insurance_company: e.target.value,
                        },
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <DateSelector
                    label={"Insurance Expiry Date"}
                    onChange={(newValue) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          insurance_expiry_date:
                            moment(newValue).format("YYYY-MM-DD"),
                        },
                      }))
                    }
                    value={newValues?.tanker_profile?.insurance_expiry_date}
                  />
                </Grid>
              </Paper>
            </Grid> */}

            {/* Pollution Details */}
            {/* <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                  gap: 2,
                }}
              >
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Pollution Certificate Number"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.pollution_number}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          pollution_number: e.target.value,
                        },
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <DateSelector
                    label={" Expiry Date"}
                    onChange={(newValue) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          pollution_expiry_date:
                            moment(newValue).format("YYYY-MM-DD"),
                        },
                      }))
                    }
                    value={newValues?.tanker_profile?.pollution_expiry_date}
                  />
                </Grid>
              </Paper>
            </Grid> */}
            {/* Registration Details */}
            {/* <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                  gap: 2,
                }}
              >
                <Grid item md={12}>
                  <DateSelector
                    label={"Registration Date"}
                    onChange={(newValue) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          registration_date:
                            moment(newValue).format("YYYY-MM-DD"),
                        },
                      }))
                    }
                    value={newValues?.tanker_profile?.registration_date}
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Registration Authority"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.registration_authority}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        registration_authority: e.target.value,
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Registration State"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.registration_state}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        registration_state: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Paper>
            </Grid> */}
            {/* Fitness Certificate */}
            {/* <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                  gap: 2,
                }}
              >
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="FC Number"
                    variant="filled"
                    key={details?.fitness_certificate}
                    value={newValues?.fitness_certificate?.certificate_number}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        fitness_certificate: {
                          ...prev.fitness_certificate,
                          certificate_number: e.target.value,
                        },
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <DateSelector
                    label={"Expiry Date"}
                    onChange={(newValue) =>
                      setNewValues((prev) => ({
                        ...prev,
                        fitness_certificate: {
                          ...prev.fitness_certificate,
                          expiry_date: moment(newValue).format("YYYY-MM-DD"),
                        },
                      }))
                    }
                    value={newValues?.fitness_certificate?.expiry_date}
                  />
                </Grid>
              </Paper>
            </Grid> */}
            {/* Permit Details */}
            {/* <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                  gap: 2,
                }}
              >
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Permit Number"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.permit_number}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          permit_number: e.target.value,
                        },
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <DateSelector
                    label={"Permit Expiry Date"}
                    onChange={(newValue) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          permit_expiry_date:
                            moment(newValue).format("YYYY-MM-DD"),
                        },
                      }))
                    }
                    value={newValues?.tanker_profile?.permit_expiry_date}
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Permit Authority"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.permit_authority}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          permit_authority: e.target.value,
                        },
                      }))
                    }
                  />
                </Grid>
              </Paper>
            </Grid> */}
            {/* Road Tax Details */}
            {/* <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                  gap: 2,
                }}
              >
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Road Tax Amount Paid"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.road_tax_amount_paid}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,

                        road_tax_amount_paid: e.target.value,
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Payment Date"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.road_tax_payment_date}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        road_tax_payment_date: e.target.value,
                      }))
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    fullWidth
                    required
                    type="date"
                    label="Tax Expiry Date"
                    variant="filled"
                    key={details?.tanker_profile}
                    value={newValues?.tanker_profile?.tax_expiry_date}
                    onChange={(e) =>
                      setNewValues((prev) => ({
                        ...prev,
                        tanker_profile: {
                          ...prev.tanker_profile,
                          tax_expiry_date: moment(e.target.value).format(
                            "YYYY-MM-DD"
                          ),
                        },
                      }))
                    }
                  />
                </Grid>
              </Paper>
            </Grid> */}
          </Grid>
          <Box
            sx={{
              p: 2,
              display: "flex",
              width: "100%",
              justifyContent: "end",
              gap: 2,
            }}
            // item
            // xs={12}
          >
            <Button
              onClick={() => onClose()}
              sx={{ float: "right" }}
              variant="outlined"
              // type="submit"
            >
              Cancel
            </Button>

            <Button sx={{ float: "right" }} variant="contained" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddorUpdateSupplierVehicle;
