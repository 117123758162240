import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CustomConsole from "../../CustomConsole";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  GetWfsOngoingOrders,
  VerifyOtp,
} from "../Redux/Actions/wfsOrdersAction";
import { toast } from "react-toastify";
import { GetOrderIdDetails } from "../Redux/Actions/adminEmptyCanOrderAction";
import { CompleteEmptyCanOrderOtp } from "../Redux/Actions/skuWfsDetailsAction";

export default function EnterOTPDialog({
  openVerifyOtpDialog,
  setOpenVerifyOtpDialog,
  orderId,
  pageNumber,
}) {
  const dispatch = useDispatch();
  const [otp, setOtp] = React.useState("");

  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const handleClose = () => {
    setOpenVerifyOtpDialog(false);
  };

  const handleOtpChange = (event) => {
    const regex = /^[0-9]{4}$/;
    var result = regex.test(event.target.value);
    if (result === true) {
      CustomConsole("Proper");
      setOtpValidationError(false);
    } else {
      CustomConsole("Improper");
      setOtpValidationError(true);
    }
    if (event.target.value >= 0) {
      setOtp(event.target.value);
    }
  };
  const handleConfirm = () => {
    const reqObj = { order_id: orderId, otp: otp };
    if (otpValidationError === false) {
      dispatch(CompleteEmptyCanOrderOtp(reqObj));
      setOtp("");
      setOpenVerifyOtpDialog(false);
    } else {
      toast.error("Enter proper OTP", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div>
      <Dialog
        open={openVerifyOtpDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Verify OTP</DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "10px" }}>
            <TextField
              id="outlined-basic"
              inputProps={{
                pattern: "[0-9]*",
                inputMode: "numeric",
                maxLength: 4,
              }}
              //   type="number"
              label="Enter OTP"
              variant="outlined"
              value={otp}
              //   onChange={(event) => setOpt(event.target.value)}
              onChange={handleOtpChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
