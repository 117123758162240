/**
 * @author vinay kumar
 * @description Accounts Users Page
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { GetAccountsDetails } from "../Redux/Actions/userDetailsAdminAction";
import { useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";

import * as IconName from "react-icons/fc";
import UserTransactions from "./UserTransactions";

function AccountsUsers({ userRoleId, userIdA }) {
  const dispatch = useDispatch();
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate);
  const [adminRole, setAdminRoleId] = React.useState("");

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const bookingDetails = useSelector((state) => state.adminBookingData);

  React.useEffect(() => {
    dispatch(GetAccountsDetails(userIdA));
  }, []);

  return (
    <>
      {userRoleId === 3000 ? (
        <Box>
          <Box>
            {userAddressAdmin.accounts.map((data, key) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  <Box>
                    <Box sx={{ display: "flex", gap: "20px", padding: "10px" }}>
                      <IconName.FcLibrary size="30px" />
                      <Typography>Bank Name : </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {data.bank_name}
                      </Typography>
                    </Box>
                    <Box>
                      {/* <Typography>{data.account_name}</Typography> */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "20px",
                          padding: "10px",
                        }}
                      >
                        <IconName.FcKindle size="30px" />
                        <Typography>Account Number : </Typography>
                        <Typography>{data.account_number}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        padding: "10px",
                      }}
                    >
                      <IconName.FcLockLandscape size="30px" />
                      <Typography>IFSC Code : </Typography>
                      <Typography>{data.ifsc_code}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        padding: "10px",
                      }}
                    >
                      <IconName.FcLibrary size="30px" />
                      <Typography>Branch Name : </Typography>
                      <Typography>{data.branch_name}</Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              padding: "10px",
            }}
          >
            No Accounts details to show
          </Typography>
        </Box>
      )}
    </>
  );
}

export default AccountsUsers;
