import { toast } from "react-toastify";
import useAxios from "../../Axios/useAxios";
import CustomConsole from "../../../CustomConsole";
import actionType from "./actionType";

export const GetInfluencerRequests=(pgNum,infType)=>{
    const httpStatus = {
        SUCCESS: 200,
        BAD_REQUEST: 400,
      };
      let api =useAxios();
      return async(dispatch)=>{
        try {
            let response = await api.get(`/SSM/admin/userType/influencerRequestedList?page_number=${pgNum}&influencer_status=${infType}`);
            if(response.status===httpStatus.SUCCESS){
                dispatch({
                    type: actionType.GET_INFLUENCER_DETAILS,
                    payload: response.data,
                  });
            }
        } catch (error) {
            CustomConsole(error);
            // toast.error(error.response.data.msg, {
            //   position: "bottom-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "colored",
            // });
        }
      }
};

export const GetInfluencerDetails=(id)=>{
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api =useAxios();

  return async(dispatch)=>{
    try {
        let response =await api.get(`/SSM/admin/userType/influencerRequestedDetails?user_id=${id}`);
        if(response.status===httpStatus.SUCCESS){
            dispatch({
                type: actionType.GET_INFLUENCER_REQUESTED_DETAILS,
                payload: response.data,
              });
        }
    } catch (error) {
        CustomConsole(error);
        // toast.error(error.response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
    }
  }
}

export const EnableDisableInfluencer=(reqObj)=>{
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api =useAxios();

  return async(dispatch)=>{
    try {
        let response =await api.put(`/SSM/admin/referralCode/enableInfluencer`,reqObj);
        if(response.status===httpStatus.SUCCESS){
            dispatch({
                type: actionType.ENABLE_INFLUENCER,
                payload: response.data,
              });
        }
    } catch (error) {
        CustomConsole(error);
        // toast.error(error.response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
    }
  }
}