import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  getQrBarcodeSwapList: [],
  getQrBarcodeSwapCanByOrderId: [],
};

const canSwapReducers = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.POST_QRBARCODE_SWAP_REQUEST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        postQrBarcodeSwapRequest: action.payload.data,
      };
    case actionType.GET_QRBARCODE_SWAP_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getQrBarcodeSwapList: action.payload.data,
      };
    case actionType.GET_QRBARCODE_SWAP_CAN_BY_ORDERID:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getQrBarcodeSwapCanByOrderId: action.payload.data,
      };
      case actionType.PUT_QRBARCODE_SWAP_ACCEPT_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        putQrBarcodeSwapAcceptOrReject: action.payload.data,
      };
    default:
      return state;
  }
};
export default canSwapReducers;
