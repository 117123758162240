import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  allordersPostpaid: [],
  phoneordersPostpaid: [],
  postpaidOrder: {},
  rejectPostpaidMsg: "",
  suppliers: [],
  priceUpdateMsg: "",
  allpriceUpdateMsg:"",
  walletDetails: [],
  orders: {},
  orderDetails: "",
  acceptPostPaid:{},
 
};

const adminPostpaidOrdersAdmin = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_POSTPAID_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        allordersPostpaid: action.payload.postpaidrequest,
      };
    case actionType.POSTPAID_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        postpaidOrder: action.payload.postpaidrequest,
      };
    case actionType.REJECT_POSTPAID_ORDER:
      return {
        ...state,
        error: action.payload.error,
        rejectPostpaidMsg: action.payload.msg,
        acceptPostPaid:action.payload
      };
    case actionType.GET_SUPPLIER_ADMIN:                            
      return {
        ...state,
        error: action.payload.error,
        suppliers: action.payload.suppliers,
      };
    case actionType.UPDATE_POSTPAID_PRICE:
      return {
        ...state,
        error: action.payload.error,                
        priceUpdateMsg: action.payload.msg,
      };
    case actionType.GET_WALLET_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        walletDetails: action.payload.data,
      };
    case actionType.NOT_CONFIRMED_ORDER_DET:
      return {
        ...state,
        error: action.payload.error,
        orders: action.payload.orders,
      };
    case actionType.ASSIGNING_SUPPLIERS:
      return {
        ...state,
        error: action.payload.error,
        orderDetails: action.payload,
      }
      case actionType.GET_POSTPAID_ORDERS_BYPHONE:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
          phoneordersPostpaid: action.payload.postpaidrequest,
        };
        case actionType.UPDATE_ALLPOSTPAID_PRICE:
          return {
            ...state,
            error: action.payload.error,                
            allpriceUpdateMsg: action.payload.msg,
          };
      
    default:
      return state;
  }
};

export default adminPostpaidOrdersAdmin;
