import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as Material from "@mui/material";

import CustomConsole from "../../CustomConsole";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function QrDetailsDialog(props) {
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectionModelReqFilled, setselectionModelReqFilled] = React.useState(
    []
  );
  CustomConsole(selectionModelReqFilled);
  const [reqEmptyQrData, setReqEmptyQrData] = React.useState([]);
  const [reqFilledQrData, setReqFilledQrData] = React.useState([]);
  const [approvedEmptyQrData, setApprovedEmptyQrData] = React.useState([]);
  const [approvedFilledQrData, setApprovedFilledQrData] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectAllReqFilled, setselectAllReqFilled] = React.useState(false);

  const handleClose = () => {
    props.handleCloseDialog();
  };
  CustomConsole("-- props --");
  CustomConsole(props);
  React.useEffect(() => {
    let reqE = [];
    let reqF = [];
    let appE = [];
    let appF = [];
    if (!Object.keys(props.data).length) {
      handleCloseDialog();
    } else {
      if (
        !props.data.req_empty_details.length &&
        !props.data.req_filled_details.length &&
        !props.data.approved_empty_details.length &&
        !props.data.approved_filled_details.length
      ) {
        for (const data of props.getQrBarcodeSwapCanByQrId) {
          if (props.data.req_empty?.includes(data.qr_code)) {
            reqE.push(data);
          }
          if (props.data.req_filled?.includes(data.qr_code)) {
            reqF.push(data);
          }
          if (props.data.approved_empty?.includes(data.qr_code)) {
            appE.push(data);
          }
          if (props.data.approved_filled?.includes(data.qr_code)) {
            appF.push(data);
          }
        }
        CustomConsole("reqE: " + JSON.stringify(reqE));
        CustomConsole("reqF: " + JSON.stringify(reqF));
        CustomConsole("appE: " + JSON.stringify(appE));
        CustomConsole("appF: " + JSON.stringify(appF));
      } else {
        reqE = props.data.req_empty_details.sort(
          (item1, item2) => item1.qr_id - item2.qr_id
        );
        reqF = props.data.req_filled_details.sort(
          (item1, item2) => item1.qr_id - item2.qr_id
        );
        appE = props.data.approved_empty_details.sort(
          (item1, item2) => item1.qr_id - item2.qr_id
        );
        appF = props.data.approved_filled_details.sort(
          (item1, item2) => item1.qr_id - item2.qr_id
        );
      }

      setReqEmptyQrData(reqE);
      setReqFilledQrData(reqF);
      setApprovedEmptyQrData(appE);
      setApprovedFilledQrData(appF);
    }
  }, [props]);
  React.useEffect(() => {}, [
    reqEmptyQrData,
    reqFilledQrData,
    approvedEmptyQrData,
    approvedFilledQrData,
  ]);

  const handleChangeSelectAllCheckBox = (e) => {
    if (e.target.checked === true) {
      let newCheckedItems = reqEmptyQrData.map((data) => data.qr_code);
      setSelectAll(true);
      setSelectionModel(newCheckedItems);
    } else if (e.target.checked === false) {
      setSelectionModel([]);
      setSelectAll(false);
    }
  };
  const handleChangeSelectAllCheckBoxReqFilles = (e) => {
    CustomConsole("------------Select All Filled--------------");
    CustomConsole(reqFilledQrData);
    if (e.target.checked === true) {
      let newCheckedItems = reqFilledQrData.map((data) => data.qr_code);
      setselectAllReqFilled(true);
      setselectionModelReqFilled(newCheckedItems);
    } else if (e.target.checked === false) {
      setselectionModelReqFilled([]);
      setselectAllReqFilled(false);
    }
  };
  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };

  const handleReject = (orderId, reqId) => {
    CustomConsole("---------handleReject-------");
    props.handleReject({
      selectionModel: selectionModel,
      selectionModelReqFilled: selectionModelReqFilled,
      orderId: orderId,
      reqId: reqId,
    });
  };
  const handleApprove = (orderId, reqId) => {
    CustomConsole("---------handleApprove-------");
    props.handleApprove({
      selectionModel: selectionModel,
      selectionModelReqFilled: selectionModelReqFilled,
      orderId: orderId,
      reqId: reqId,
    });
  };

  const handleCheckBoxChage = (e, qrId) => {
    if (e.target.checked === true) {
      // props.handleCheckBoxChage(props.orders.order_id);
      setSelectionModel((prev) => [...prev, qrId]);
    } else if (e.target.checked === false) {
      setSelectionModel((prev) => prev.filter((obj) => obj !== qrId));
    }
  };
  const handleCheckBoxChageReqFilled = (e, qrId) => {
    if (e.target.checked === true) {
      // props.handleCheckBoxChage(props.orders.order_id);
      setselectionModelReqFilled((prev) => [...prev, qrId]);
    } else if (e.target.checked === false) {
      setselectionModelReqFilled((prev) => prev.filter((obj) => obj !== qrId));
    }
  };
  return (
    <Dialog
      fullScreen
      open={props.openCanSwapApproveRejectDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, fontSize: "16px", fontWeight: "bold" }}
            component="div"
          >
            Request Id: {props.data.id || "-"}
          </Typography>
          <Typography
            sx={{ ml: 2, flex: 1, fontSize: "16px", fontWeight: "bold" }}
            component="div"
          >
            Order Id: {props.data.order_id || "-"}
          </Typography>

          <IconButton
            sx={{
              color: "white",
              backgroundColor:
                props.data.status === 100
                  ? "blue"
                  : props.data.status === 200
                  ? "green"
                  : "red",
            }}
            onClick={handleClose}
          >
            {props.data.status === 100
              ? "P"
              : props.data.status === 200
              ? "A"
              : "R"}
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <Typography
        variant="h5"
        sx={{
          fontSize: { xs: "16px", md: "18px" },
          fontWeight: "bold",
          textAlign: "start",
          margingTop: "10px",
        }}
      >
        Can Swap Request Status:{" "}
        <span
          style={{
            color:
              props.data.status === 100
                ? "blue"
                : props.data.status === 200
                ? "green"
                : "red",
          }}
        >
          {props.data.status === 100
            ? "PENDING"
            : props.data.status === 200
            ? "APPROVED"
            : "REJECTED"}
        </span>
      </Typography> */}
      <Box>
        <Material.DialogContent>
          <Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderColor: "gray",
                padding: "10px",
                width: { xs: "65vW", md: "100%" },
              }}
            >
              {reqEmptyQrData !== undefined &&
              reqEmptyQrData !== null &&
              reqEmptyQrData.length > 0 ? (
                <>
                  <Material.Typography
                    variant="h5"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "bold",
                      textAlign: "start",
                      marginBottom: "5px",
                    }}
                  >
                    Requested Empty (In-Scan) :
                  </Material.Typography>
                  <Material.Paper
                    sx={{ width: { md: "100%", xs: "85Vw" }, overflow: "auto" }}
                  >
                    <Material.TableContainer sx={{ maxHeight: 440 }}>
                      <Material.Table size="small">
                        <Material.TableHead sx={{ bgcolor: "#1E6EE3" }}>
                          <Material.TableRow>
                            <Material.TableCell sx={{ color: "white" }}>
                              <Material.FormControlLabel
                                control={
                                  <Material.Checkbox
                                    color="success"
                                    checked={selectAll}
                                    onChange={handleChangeSelectAllCheckBox}
                                  />
                                }
                                // label="Select All"
                              />
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Qr/BarCode
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Capacity
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Material
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Order Id
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Name
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Phone
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Type
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Can Status
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              No of times Used
                            </Material.TableCell>
                          </Material.TableRow>
                        </Material.TableHead>
                        <Material.TableBody>
                          {reqEmptyQrData.map((row, index) => (
                            <Material.TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              // onClick={handleOpenSingleId}
                            >
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                <Material.FormControlLabel
                                  control={
                                    <Material.Checkbox
                                      checked={selectionModel.some(
                                        (id) => id === row.qr_code
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChage(e, row.qr_code)
                                      }
                                    />
                                  }
                                  // label="Select All"
                                />
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.qr_code}
                              </Material.TableCell>

                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row?.container_details?.capacity || "---"}{" "}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row?.container_details?.material || "---"}{" "}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.order_id ? row.order_id : "---"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.fullname}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.phone}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_type}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.can_status === true ? "Filled" : "Empty"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.no_of_times_used}
                              </Material.TableCell>
                            </Material.TableRow>
                          ))}
                        </Material.TableBody>
                      </Material.Table>
                    </Material.TableContainer>
                  </Material.Paper>
                </>
              ) : null}

              {reqFilledQrData !== undefined &&
              reqFilledQrData !== null &&
              reqFilledQrData.length > 0 ? (
                <>
                  <Material.Typography
                    variant="h5"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "bold",
                      textAlign: "start",
                      marginBottom: "5px",
                      marginTop: "15px",
                    }}
                  >
                    Requested Filled (Out-Scan) :
                  </Material.Typography>

                  <Material.Paper
                    sx={{
                      width: { md: "100%", xs: "85Vw" },
                      overflow: "auto",
                    }}
                  >
                    <Material.TableContainer sx={{ maxHeight: 440 }}>
                      <Material.Table size="small">
                        <Material.TableHead sx={{ bgcolor: "#1E6EE3" }}>
                          <Material.TableRow>
                            <Material.TableCell sx={{ color: "white" }}>
                              <Material.FormControlLabel
                                control={
                                  <Material.Checkbox
                                    color="success"
                                    checked={selectAllReqFilled}
                                    onChange={
                                      handleChangeSelectAllCheckBoxReqFilles
                                    }
                                  />
                                }
                                // label="Select All"
                              />
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Qr/BarCode
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Order Id
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Name
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Phone
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Type
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Can Status
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              No of times Used
                            </Material.TableCell>
                          </Material.TableRow>
                        </Material.TableHead>
                        <Material.TableBody>
                          {reqFilledQrData.map((row, index) => (
                            <Material.TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {CustomConsole(row)}
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                <Material.FormControlLabel
                                  control={
                                    <Material.Checkbox
                                      checked={selectionModelReqFilled.some(
                                        (id) => id === row.qr_code
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChageReqFilled(
                                          e,
                                          row.qr_code
                                        )
                                      }
                                    />
                                  }
                                />
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.qr_code}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.order_id ? row.order_id : "---"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.fullname}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.phone}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_type}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.can_status === true ? "Filled" : "Empty"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.no_of_times_used}
                              </Material.TableCell>
                            </Material.TableRow>
                          ))}
                        </Material.TableBody>
                      </Material.Table>
                    </Material.TableContainer>
                  </Material.Paper>
                </>
              ) : null}

              {approvedEmptyQrData !== undefined &&
              approvedEmptyQrData !== null &&
              approvedEmptyQrData.length > 0 ? (
                <>
                  <Material.Typography
                    variant="h5"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "bold",
                      textAlign: "start",
                      marginBottom: "5px",
                      marginTop: "15px",
                    }}
                  >
                    Approved Empty :
                  </Material.Typography>
                  <Material.Paper
                    sx={{ width: { md: "100%", xs: "85Vw" }, overflow: "auto" }}
                  >
                    <Material.TableContainer sx={{ maxHeight: 440 }}>
                      <Material.Table size="small">
                        <Material.TableHead sx={{ bgcolor: "#1E6EE3" }}>
                          <Material.TableRow>
                            <Material.TableCell sx={{ color: "white" }}>
                              Qr/BarCode
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Capacity
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Material
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Order Id
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Name
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Phone
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Type
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Can Status
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              No of times Used
                            </Material.TableCell>
                          </Material.TableRow>
                        </Material.TableHead>
                        <Material.TableBody>
                          {approvedEmptyQrData.map((row, index) => (
                            <Material.TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.qr_code}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row?.container_details?.capacity || "---"}{" "}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row?.container_details?.material || "---"}{" "}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.order_id ? row.order_id : "---"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.fullname}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.phone}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_type}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.can_status === true ? "Filled" : "Empty"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.no_of_times_used}
                              </Material.TableCell>
                            </Material.TableRow>
                          ))}
                        </Material.TableBody>
                      </Material.Table>
                    </Material.TableContainer>
                  </Material.Paper>
                </>
              ) : null}
              {approvedFilledQrData !== undefined &&
              approvedFilledQrData !== null &&
              approvedFilledQrData.length > 0 ? (
                <>
                  <Material.Typography
                    variant="h5"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "bold",
                      textAlign: "start",
                      marginBottom: "5px",
                      marginTop: "15px",
                    }}
                  >
                    Approved Filled :
                  </Material.Typography>
                  <Material.Paper
                    sx={{ width: { md: "100%", xs: "85Vw" }, overflow: "auto" }}
                  >
                    <Material.TableContainer sx={{ maxHeight: 440 }}>
                      <Material.Table size="small">
                        <Material.TableHead sx={{ bgcolor: "#1E6EE3" }}>
                          <Material.TableRow>
                            <Material.TableCell sx={{ color: "white" }}>
                              Qr/BarCode
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Order Id
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Name
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Phone
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Owner Type
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Can Status
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              No of times Used
                            </Material.TableCell>
                          </Material.TableRow>
                        </Material.TableHead>
                        <Material.TableBody>
                          {approvedFilledQrData.map((row, index) => (
                            <Material.TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.qr_code}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.order_id ? row.order_id : "---"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.fullname}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_details?.phone}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.owner_type}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.can_status === true ? "Filled" : "Empty"}
                              </Material.TableCell>
                              <Material.TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.no_of_times_used}
                              </Material.TableCell>
                            </Material.TableRow>
                          ))}
                        </Material.TableBody>
                      </Material.Table>
                    </Material.TableContainer>
                  </Material.Paper>
                </>
              ) : null}
            </Material.Box>
          </Material.Box>
        </Material.DialogContent>
        {props.data.status === 100 ? (
          <Material.DialogActions>
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                margin: "10px",
                width: { xs: "60vW", md: "100%" },
              }}
            >
              <Material.Button
                variant="contained"
                color="error"
                onClick={() => handleReject(props.data.order_id, props.data.id)}
              >
                Reject
              </Material.Button>
              <Material.Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleApprove(props.data.order_id, props.data.id)
                }
              >
                Approve
              </Material.Button>
            </Material.Box>
          </Material.DialogActions>
        ) : null}
      </Box>
    </Dialog>
  );
}

export default QrDetailsDialog;
