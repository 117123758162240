import { Cancel, Verified } from "@mui/icons-material";
import { Box, Button, ThemeProvider, createTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import { replacementCanOrdersStatus } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function ReplacementCanTableView(props) {
  const [data, setData] = React.useState("");
  const history = useHistory();
  CustomConsole(data);

  const handleOpenAssignDeliveryAgentDialog = (object) => {
    props.handleOpenAssignDeliveryAgentDialog(object.order_id);
  };

  const handleReturnCanEnterOTP = (object) => {
    CustomConsole(object);
    props.handleReturnCanEnterOTP({
      order_id: object.order_id,
      number_Of_cans: object.number_Of_cans,
      cans_recived: object.cans_recived,
      order_status: object.order_status,
    });
  };
  const handleViewDetails = (params) => {
    if (params.field === "OrderId" && params.row.OrderId) {
      const url = `/dashboard/ReplacementSingleOrder?order_id=${params.row.OrderId}`;
      history.push(url);
    }
  };

  const headerData = [
    { field: "OrderId", headerName: "Order Id", width: 130 },

    // {
    //   field: "EnterOtp",
    //   headerName: "Process",
    //   width: 100,
    //   renderCell: (params) => {
    //     CustomConsole("ufjffiuhlihil");
    //     CustomConsole(params);
    //     return (
    //       <Button
    //         sx={{
    //           backgroundColor: "green",
    //           "&:hover": { backgroundColor: "black" },
    //         }}
    //         variant="contained"
    //         onClick={() =>
    //           handleReturnCanEnterOTP({
    //             order_id: params.row.OrderId,
    //             number_Of_cans: params.row.replace_can_qty,
    //             cans_recived: params.row.accepted_can_qty,
    //             order_status: params.row.order_status,
    //           })
    //         }
    //       >
    //         {params.row.order_status ===
    //           replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
    //         params.row.replace_can_qty !== params.row.accepted_can_qty
    //           ? "In-Scan"
    //           : params.row.order_status ===
    //               replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
    //             params.row.replace_can_qty === params.row.accepted_can_qty
    //           ? "Out-Scan"
    //           : params.row.order_status ===
    //             replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
    //           ? "Enter OTP"
    //           : ""}
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "assignDeliveryAgent",
    //   headerName: "Assign Delivery Agent",
    //   width: 180,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         disabled={!params.row.DeliveryAgentId === 0}
    //         sx={{
    //           backgroundColor: "blue",
    //           "&:hover": { backgroundColor: "black" },
    //         }}
    //         variant="contained"
    //         onClick={() =>
    //           handleOpenAssignDeliveryAgentDialog({
    //             order_id: params.row.OrderId,
    //           })
    //         }
    //       >
    //         {params.row.DeliveryAgentId === 0
    //           ? "Assign Delivery Agent"
    //           : "Assigned"}
    //       </Button>
    //     );
    //   },
    // },
    { field: "replace_can_qty", headerName: "No Of Cans Qty", width: 150 },
    { field: "accepted_can_qty", headerName: "Received Can Qty", width: 150 },
    { field: "order_status", headerName: "Order Status", width: 150 },
    { field: "Material", headerName: "Material", width: 150 },
    { field: "Capacity", headerName: "Capacity", width: 100 },
    {
      field: "DeliveryAgentId",
      headerName: "Assigned Delivery Agent Id",
      width: 130,
    },
  ];
  React.useEffect(() => {
    let replacementCanDataTemp = [];
    props.data &&
      props.data !== undefined &&
      props.data !== null &&
      props.data.length > 0 &&
      props.data.map((data, key) => {
        CustomConsole(data);
        replacementCanDataTemp[key] = {
          id: key + 1,
          OrderId: data.order_id,
          // NoOfCan: data.replace_can_qty,
          // RecivedCanQty: data.accepted_can_qty,
          order_status:
            data.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
            data.replace_can_qty !== data.accepted_can_qty
              ? "Started"
              : data.order_status ===
                replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
              ? "Accepted"
              : "",
          Material: data.container_material,
          Capacity: data.container_capacity,
          DeliveryAgentId: data.delivery_agent_id,
          replace_can_qty: data.replace_can_qty,
          accepted_can_qty: data.accepted_can_qty,
        };
      });
    setData(replacementCanDataTemp);
  }, []);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#a3a1e6",
            color: "black",
            fontWeight: "bold",
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });
  return (
    <Box
      sx={{
        height: 520,
        maxWidth: { xs: "350px", sm: "400px", md: "100%", lg: "100%" },
        overflow: "auto",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={data}
          columns={headerData}
          onCellClick={handleViewDetails}
          autoHeight
          hideFooter={true}
          sx={{
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </ThemeProvider>
    </Box>
  );
}

export default ReplacementCanTableView;
