import React from "react";
import * as Material from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomConsole from "../../CustomConsole";

function SupplierQRorCanDamageDialog(props) {
  const [isCanDamaged, setIsCanDamaged] = React.useState(true);
  const [isCanDamagedValue, setIsCanDamagedValue] = React.useState("");
  const [isCanFilled, setIsCanFilled] = React.useState(false);
  const [selectCanDamagedType, setSelectCanDamagedType] = React.useState(0);
  const handleCancleInScan = () => {
    CustomConsole("---------handleCancleInScan-------");
    props.handleClose();
  };
  const handleContinueInScan = () => {
    CustomConsole("---------handleContinueInScan-------");
    props.handleSubmit({
      is_can_damaged: isCanDamaged,
      is_can_filled: isCanFilled,
      damage_type: selectCanDamagedType,
    });
  };
  const handleChangeRadio = (event) => {
    const value = event.target.value === "true";
    setIsCanDamaged(value);
    setIsCanDamagedValue(value);
  };

  return (
    <Material.Box>
      <Material.Dialog open={props.openQrorCanDamageDialog}>
        <Material.DialogTitle>
          Is the Can (or) the QR on the Can damaged
        </Material.DialogTitle>
        <Material.DialogContent>
          <Material.FormControl>
            <Material.RadioGroup
              value={isCanDamagedValue}
              onChange={handleChangeRadio}
            >
              <Material.FormControlLabel
                value={true}
                control={<Material.Radio />}
                label="Yes it is damaged"
              />
              <Material.FormControlLabel
                value={false}
                control={<Material.Radio />}
                label="No,the can is fine"
              />
            </Material.RadioGroup>
          </Material.FormControl>
          {isCanDamagedValue && (
            <Box sx={{ minWidth: 100, margin: "20px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Can Damaged Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectCanDamagedType}
                  label="Select Can Damaged Type"
                  onChange={(event) =>
                    setSelectCanDamagedType(event.target.value)
                  }
                >
                  <MenuItem value={101}>Physical Damaged</MenuItem>
                  <MenuItem value={102}>QR Code Damage</MenuItem>
                  <MenuItem value={103}>Bar Code Damage</MenuItem>
                  {/* <MenuItem value={104}>QR & Barcode Code Damage</MenuItem>
                  <MenuItem value={105}>Totally Damaged/Lost</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box
            sx={{ display: "flex", justifyContent: "center", margin: "10px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCanFilled}
                  onChange={() => setIsCanFilled(!isCanFilled)}
                />
              }
              label="Is the can is filled"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              gap: "10px",
            }}
          >
            <Material.Button variant="contained" onClick={handleCancleInScan}>
              Cancel In-Scan
            </Material.Button>
            <Material.Button variant="contained" onClick={handleContinueInScan}>
              Continue In-Scan
            </Material.Button>
          </Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default SupplierQRorCanDamageDialog;
