import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as Material from "@mui/material";
import moment from "moment";
import CachedIcon from "@mui/icons-material/Cached";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { GetQrBarcodeSwapList } from "../../Pages/Redux/Actions/canSwapingAction";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function CanSwapOrderRequestDetailsDialog(props) {
  const [status, setStatus] = React.useState(200);
  const canSwapStoreDeatails = useSelector((state) => state.canSwapingDetails);
  const dispatch = useDispatch();
  const [canSwapDetails, setCanSwapDetails] = React.useState([]);
  React.useEffect(() => {
    CustomConsole(canSwapStoreDeatails);
    CustomConsole(canSwapStoreDeatails.getQrBarcodeSwapList);
    if (canSwapStoreDeatails.getQrBarcodeSwapList.length > 0) {
      CustomConsole("--setCanSwapDetails---");
      setCanSwapDetails(canSwapStoreDeatails.getQrBarcodeSwapList);
    } else {
      setCanSwapDetails([]);
    }
  }, [canSwapStoreDeatails]);
  React.useEffect(() => {
    CustomConsole("-- canSwapDetails useEffect ---");
    CustomConsole(canSwapDetails);
  }, [canSwapDetails]);
  React.useEffect(() => {
    handleRefresh();
  }, [status]);
  const handleStatusChange = (event) => {
    CustomConsole(event);
    setStatus(event.target.value);
  };
  const handleLink = (data) => {
    CustomConsole(data);
    props.handleLink(data);
  };
  const handleRefresh = () => {
    CustomConsole("-- handleRefresh --");
    if (props.order_id) {
      //Hit the API
      dispatch(
        GetQrBarcodeSwapList({
          status: status,
          page_number: 1,
          page_size: 100,
          order_id: props.order_id,
        })
      );
    }
  };
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{ ml: 2, flex: 1, fontSize: "16px", fontWeight: "bold" }}
            component="div"
          >
            Order Id: {props?.order_id || "---"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ marginTop: "20px", width: "100Vw", height: "100Vh" }}>
        <Box
          sx={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            margin: "10px",
            padding: "20px",
          }}
        >
          <Material.FormControl>
            <Material.InputLabel>Status</Material.InputLabel>
            <Material.Select
              value={status}
              label="status"
              onChange={handleStatusChange}
            >
              <Material.MenuItem value={100}>PENDING</Material.MenuItem>
              <Material.MenuItem value={200}>ACCEPTED</Material.MenuItem>
              <Material.MenuItem value={300}>REJECTED</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.Button
            onClick={handleRefresh}
            variant="outlined"
            startIcon={<CachedIcon />}
          >
            Refresh
          </Material.Button>
        </Box>
        {/* Order Card */}
        <Material.TableContainer sx={{ maxHeight: 720 }}>
          <Material.Table stickyHeader aria-label="sticky table">
            <Material.TableHead sx={{ bgcolor: "#1E6EE3" }}>
              <Material.TableRow>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  S.No
                </Material.TableCell>
                {status === 200 ? (
                  <Material.TableCell
                    align="center"
                    sx={{ color: "blue", fontWeight: "bold" }}
                  >
                    Link
                  </Material.TableCell>
                ) : null}
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Request Id
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Order Id
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Status
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested By
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested Empty
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested Filled
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Approved Empty
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Approved Filled
                </Material.TableCell>
                {/* <Material.TableCell >
                  Requested by
                </Material.TableCell> */}
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Approved By
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested At
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Updated At
                </Material.TableCell>
              </Material.TableRow>
            </Material.TableHead>
            <Material.TableBody sx={{ color: "black" }}>
              {canSwapDetails.length > 0 ? (
                <>
                  {canSwapDetails.map((row, key) => (
                    <Material.TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {key + 1}
                      </Material.TableCell>

                      {row.status === 200 ? (
                        <Material.TableCell
                          component="th"
                          scope="row"
                          align="right"
                        >
                          <IconButton
                            sx={{
                              color: "blue",
                              fontWeight: "bold",
                              "&:hover": {
                                color: "red",
                              },
                            }}
                            onClick={() => {
                              handleLink(row);
                            }}
                          >
                            <QrCodeScannerIcon />
                          </IconButton>
                        </Material.TableCell>
                      ) : null}
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.id}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.order_id}
                      </Material.TableCell>
                      <Material.TableCell
                        align="right"
                        sx={{
                          color:
                            row.status === 100
                              ? "blue"
                              : row.status === 200
                              ? "green"
                              : "red",
                        }}
                      >
                        {row.status === 100
                          ? "Pending"
                          : row.status === 200
                          ? "Approved"
                          : "Rejected"}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.requested_by_user?.fullname}
                        <br />
                        {row.requested_by_user?.phone}
                      </Material.TableCell>

                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.req_empty?.length}
                        <br />
                        {row.req_empty?.length ? row.req_empty.join(", ") : ""}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.req_filled?.length}
                        <br />
                        {row.req_filled?.length
                          ? row.req_filled.join(", ")
                          : ""}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.approved_empty?.length}
                        <br />
                        {row.approved_empty?.length
                          ? row.approved_empty.join(", ")
                          : ""}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.approved_filled?.length}
                        <br />
                        {row.approved_filled?.length
                          ? row.approved_filled.join(", ")
                          : ""}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.approved_by_user?.fullname} <br />
                        {row.approved_by_user?.phone}
                      </Material.TableCell>

                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {moment(row.created_at).utc().format("DD-MM-YYYY LT")}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {moment(row.updated_at).utc().format("DD-MM-YYYY LT")}
                      </Material.TableCell>
                    </Material.TableRow>
                  ))}
                </>
              ) : (
                <Material.Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                  }}
                >
                  No Data Found !!
                </Material.Typography>
              )}
            </Material.TableBody>
          </Material.Table>
        </Material.TableContainer>
      </Box>
    </Dialog>
  );
}

export default CanSwapOrderRequestDetailsDialog;
