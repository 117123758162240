import { combineReducers } from "redux";
import addUserAdminReducer from "./addUserAdminReducer";
import adminAddUpdateWfsReducer from "./adminAddUpdateWfsReducer";
import adminBannersReducer from "./adminBannersReducers";
import adminBookingReducer from "./adminBookingReducer";
import adminContainerDetailsReducer from "./adminContainerDetailsReducer";
import adminIotDevices from "./adminDevicesIotReducer";
import adminLostCansReducer from "./adminLostCansReducer";
import adminPostpaidOrdersAdmin from "./adminPostpaidOrdersReducer";
import adminSettingsReducer from "./adminSettingsReducer";
import adminWfsCanOrdersReducers from "./adminWfsCanOrdersReducers";
import adminWfsLineDetailsReducer from "./adminWfsLineDetailsReducer";
import AdminWithdrawallistadmin from "./AdminWithdrawallistReducer";
import appSettingsReducers from "./appSettingsReducer";
import authReducers from "./authReducers";
import CustomConsole from "../../../CustomConsole";
import getFilesReducer from "./filesReducers";
import ordersAdminReducer from "./ordersAdminReducer";
import AdminPromoReducer from "./adminPromocodeReducer";

import userProfileReducer from "./profileReducer";
import userDetailsAdminReducer from "./userDetailsAdminReducer";
import wfsDetailsReducer from "./wfsDetailsReducer";
import wfsOngoingBatchReducer from "./wfsOngoingBatchReducer";
import wfsOrdersReducers from "./wfsOrdersReducers";
import adminEmptyOrderReducer from "./adminEmptyCanOrderReducer";
import adminQrBarcodeReducer from "./adminQrBarcodeReducer";
import adminEditOrderReducer from "./adminEditOrderReducer";
import adminReplacementReducer from "./adminReplacementOrderReducer";
import ReportAdminReducer from "./ReportReduser";
import superAdminReducer from "./superAdminReducer";
import adminInfluencerReducer from "./adminInfluencerReducer";
import adminHomeReducer from "./adminHomeReducer";
import adminOrderPayReducer from "./adminOrderPayReducer";
import adminSettlementReducer from "./adminSettlementReducer";
import pincodeServiceAreaReducer from "./pincodeAndServiceAdminReducer";
import bankDetailsReducer from "./bankDetailsReducer";
import adminTransactionReducer from "./adminTransactionsReducer";
import adminPoTrackPostpaidReducer from "./adminPoTrackPostpaidReducer";
import supplierReducer from "./supplierReducer";
import ConsumerDeliveriesPlanningReducer from "./ConsumerDeliveriesPlanningReducer";
import MarketingReducer from "./MarketingReducer";
import AdminSupplierDriverReducer from "./adminSuplierDriversReducer";
import gamificationReducer from "./gamificationReducer";
import invoiceGenerationReducer from "./invoiveAdminReducer";
import wfsBatchLinkingReducer from "./wfsBatchLinkingReducer";
import transactionDetailsReducer from "./transactionDetailsReducer";
import canSwapReducers from "./canSwapReducers";
import newProductContainerReducer from "./SKUProductContainerReducer";
import skuQrBarcodeReducer from "./skuQrBarCodeReduser";
import SkuPincodeReduser from "./skuPincodeReduser";
import SkuCoustomProductReduser from "./skuCoustomProductPrice";
import SkuWfsDetailsReduser from "./skuWfsDetailsReduser";
import ScanCrateSkuReduser from "./cratescanSkuReduser";

import adminBookingSkuReducer from "./adminSkuBookingReducer";
import loaderReducer from "./loaderReducer";
import spinnerReducer from "./spinner";
import NewDashboardReducer from "./NewDashboardReducer";
import vehiclesReducer from "./supplierVehiclesReducers";
import RecycleOrdersReducer from "./RecycleOrdersReducer";
import adminReportsReducer from "./adminReportsReducer";
import buyBackOrderReduser from "./OneTimeBuyBackReduser";
import accountClosureReducer from "./accountClosureReducer";
const RootReducer = combineReducers({
  authentications: authReducers,
  profile: userProfileReducer,
  wfsDetails: wfsDetailsReducer,
  fileHandler: getFilesReducer,
  wfsOrderDetails: wfsOrdersReducers,
  wfsOngoingBatchDetails: wfsOngoingBatchReducer,
  addUserAdminDetails: addUserAdminReducer,
  appSettingsDetails: appSettingsReducers,
  userDetailsUpdate: userDetailsAdminReducer,
  orderDetailsAdmin: ordersAdminReducer,
  postpaidOrderAdmin: adminPostpaidOrdersAdmin,
  IotDeviesData: adminIotDevices,
  adminLostCansData: adminLostCansReducer,
  adminSettingsData: adminSettingsReducer,
  adminWfsCanOrdersData: adminWfsCanOrdersReducers,
  adminContainerData: adminContainerDetailsReducer,
  adminBookingData: adminBookingReducer,
  adminAddUpdateWfsData: adminAddUpdateWfsReducer,
  adminBannersData: adminBannersReducer,
  adminWfsLineData: adminWfsLineDetailsReducer,

  withdrawalOrderlistAdmin: AdminWithdrawallistadmin,
  withdrawalOrderIdAdmin: AdminWithdrawallistadmin,

  emptyCanOrderData: adminEmptyOrderReducer,
  adminQrBarcodeData: adminQrBarcodeReducer,
  editOrderDetails: adminEditOrderReducer,
  replacementOrderAdmin: adminReplacementReducer,
  reportDetailsAdmin: ReportAdminReducer,
  superAdminUpdates: superAdminReducer,
  promocodeAdmin: AdminPromoReducer,
  influencerDetails: adminInfluencerReducer,
  homePageGraph: adminHomeReducer,
  paymentDetails: adminOrderPayReducer,
  settlementsDetails: adminSettlementReducer,
  pincodeAndServiceArea: pincodeServiceAreaReducer,
  // Bank IFSC verify reducer
  bankDetailsIfsc: bankDetailsReducer,
  // Transaction
  adminTransactionDetails: adminTransactionReducer,
  //Supplier Rootreducer
  supplierDeliveries: supplierReducer,
  vehicleManagement: vehiclesReducer,
  //BeatPlan RootReducer
  consumerDeliverPlanReducer: ConsumerDeliveriesPlanningReducer,
  marketingReducer: MarketingReducer,
  //Asmin Supplier Drivers
  adminSupplierDrivers: AdminSupplierDriverReducer,
  // PRO-INDIA <-> BookWater Gamification Related Store
  gamificationReducer: gamificationReducer,
  poTrackDetails: adminPoTrackPostpaidReducer,
  invoicegenerate: invoiceGenerationReducer,
  // Bulk linking
  wfsBatchBulkLinking: wfsBatchLinkingReducer,
  // Transaction details
  transactiondetails: transactionDetailsReducer,
  //Can Swap
  canSwapingDetails: canSwapReducers,
  //Sku
  newProductContainerDetails: newProductContainerReducer,

  //skuqrbarcode
  skuQrBarcodeDetails: skuQrBarcodeReducer,
  //Pincodes
  skuPincodeDetails: SkuPincodeReduser,
  //coustomproduct
  skuCoustomProductDetails: SkuCoustomProductReduser,
  //wfsdetails
  skuWfsDetails: SkuWfsDetailsReduser,
  //cratescan
  scanCrateDetails: ScanCrateSkuReduser,
  //sku Booking
  adminSkuBookingData: adminBookingSkuReducer,
  //loader
  adminLoader: loaderReducer,
  spinnerReducer: spinnerReducer,
  //New DashBoard Details
  newDashboardDetails: NewDashboardReducer,
  recycleordersdetails: RecycleOrdersReducer,
  adminReport: adminReportsReducer,

  //BuyBackOrderDetails
  buyBackOrderDetails: buyBackOrderReduser,
  orders: accountClosureReducer,
});

export default RootReducer;
