/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author
 * @description
 * @date
 * @version
 */
import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  getAvailableProducts: [],
  skuNewProduct: [],
  AddProductError: true,
  AddCrateError: true,
  skuNewCrate: "",
  cateDetails: [],
};
const newProductContainerReducer = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_AVAILABLE_PRODUCTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getAvailableProducts: action.payload.data,
      };
    case actionType.POST_ADD_NEW_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
        AddProductError: action.payload.error,
        msg: action.payload.msg,
        skuNewProduct: action.payload.data,
      };
    case actionType.POST_ADD_NEW_CRATE:
      return {
        ...state,
        AddCrateError: action.payload.error,
        error: action.payload.error,
        msg: action.payload.msg,
        skuNewCrate: action.payload.data,
      };
    case actionType.GET_AVAILABLE_CRATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        cateDetails: action.payload.data,
      };
    case actionType.LINK_PRODUCT_CRATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.DELETE_CRATE_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
        AddProductError: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};
export default newProductContainerReducer;
