import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomConsole from "../../CustomConsole";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./styles";
import { INFLUENCER_TYPE } from "../../Components/AppMeta/appMetaConfig";
import {
  EnableDisableInfluencer,
  GetInfluencerDetails,
  GetInfluencerRequests,
} from "../Redux/Actions/adminInfluencerAction";

import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
// import dayjs from "dayjs";

function AdminInfluencerCard({
  scrollDownRef,
  pageNumberReload,
  setDetailsCardFlag,
  influencer,
}) {
  const influencerData = useSelector((state) => state.influencerDetails);
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState({});
  const [dialog, setDialog] = useState(false);
  const [toastFlag, setToastFlag] = useState(false);
  const [validFrom, setValidFrom] = useState(moment().format("YYYY/MM/DD"));
  const [validTill, setValidTill] = useState(moment().format("YYYY/MM/DD"));
  const [active, setActive] = useState(true);
  const currentDate = moment().add(1, "day");

  React.useEffect(() => {
    setCardData(influencerData.requestedDetails);

    CustomConsole(influencerData);
    if (
      (!influencerData.requestedDetails?.consumer_profile?.valid_from &&
        !influencerData.requestedDetails?.consumer_profile?.valid_till) ||
      (influencerData.requestedDetails?.consumer_profile?.valid_till === "" &&
        influencerData.requestedDetails?.consumer_profile?.valid_from === "")
    ) {
      CustomConsole("IN If Condition");
      setValidFrom(null);
      setValidTill(null);
    }
    if (
      parseInt(influencerData.requestedDetails?.consumer_profile?.user_type) ===
        INFLUENCER_TYPE.USER_TYPE_INFLUENCER_DISABLED ||
      parseInt(influencerData.requestedDetails?.consumer_profile?.user_type) ===
        INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REQUESTED
    ) {
      setValidFrom(null);
      setValidTill(null);
    } else {
      CustomConsole("IN ELSE Condition");
      setValidFrom(
        influencerData.requestedDetails?.consumer_profile?.valid_from
      );
      setValidTill(
        influencerData.requestedDetails?.consumer_profile?.valid_till
      );
    }
    if (!influencerData.error && toastFlag) {
      toast.success(influencerData.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setToastFlag(false);
      dispatch(GetInfluencerDetails(cardData.id));

      CustomConsole("------Hitting Toast in Card------");
      dispatch(GetInfluencerRequests(pageNumberReload - 1, influencer));
      setDetailsCardFlag(false);
      setDialog(false);
    } else if (influencerData.error && toastFlag) {
      // toast.error(influencerData.msg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setToastFlag(false);
      setDialog(false);
    }
  }, [influencerData]);

  const openAcceptDialog = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const acceptEnableInfluencer = () => {
    CustomConsole(validFrom);
    CustomConsole(validTill);
    if (!validFrom || !validTill || validFrom === "" || validTill === "") {
      toast.error("Choose Date To Accept", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const reqObj = {
        user_id: cardData.id,
        user_type: `${INFLUENCER_TYPE.USER_TYPE_INFLUENCER}`,
        valid_from: validFrom,
        valid_till: validTill,
      };
      CustomConsole(reqObj);
      dispatch(EnableDisableInfluencer(reqObj));
      setToastFlag(true);
    }
  };

  const rejectEnableInfluencer = () => {
    const reqObj = {
      user_id: cardData.id,
      user_type: `${INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REJECTED}`,
    };
    dispatch(EnableDisableInfluencer(reqObj));
    setToastFlag(true);
  };

  const dateConverter = (dateText) => {
    const momentDate = moment(dateText);
    const formattedDate = momentDate.format("L");
    return formattedDate;
  };

  const handleValidFrom = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      setValidFrom(moment(newValue.$d).format("YYYY/MM/DD"));
    } else {
      setValidFrom(null);
    }
  };

  const handleValidTill = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      setValidTill(moment(newValue.$d).format("YYYY/MM/DD"));
    } else {
      setValidTill(null);
    }
  };

  const updatingValidity = () => {
    if (validFrom && validTill && validFrom !== "" && validTill !== "") {
      const reqObj = {
        user_id: cardData.id,
        user_type: `${INFLUENCER_TYPE.USER_TYPE_INFLUENCER}`,
        valid_from: validFrom,
        valid_till: validTill,
      };
      dispatch(EnableDisableInfluencer(reqObj));
      setToastFlag(true);
    } else {
      toast.error("Choose Date To Update", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // const enableDisableInfluencer = () => {
  //   if (parseInt(influencerType) === 113 || parseInt(influencerType) === 111) {
  //     const reqObj = {
  //       user_id: influencerId,
  //       user_type:
  //         parseInt(influencerType) === 113
  //           ? 111
  //           : parseInt(influencerType) === 111
  //           ? 113
  //           : "",
  //     };
  //     dispatch(InfluencerEnableDisable(reqObj));
  //     setInfluencerStatus((influencerStatus) => !influencerStatus);
  //     setTimeout(() => {
  //       dispatch(GetInfluencerRequests(pageNumberReload - 1, influencer));
  //       setDetailsCardFlag(false);
  //     }, 1000);
  //   }
  // };

  const deActivatingInfluencer = () => {
    const reqObj = {
      user_id: cardData.id,
      user_type: `${INFLUENCER_TYPE.USER_TYPE_INFLUENCER_DISABLED}`,
    };
    dispatch(EnableDisableInfluencer(reqObj));
    setActive(false);
    setToastFlag(true);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {Object.keys(cardData).length > 0 ? (
        <Card ref={scrollDownRef}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  md={6}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography sx={styles.cardStyleHeading}>
                      Full Name:
                      <span style={styles.cardStyleValue}>
                        {cardData.fullname}
                      </span>
                    </Typography>
                    <Typography sx={styles.cardStyleHeading}>
                      Email:
                      <span style={styles.cardStyleValue}>
                        {cardData.email}
                      </span>
                    </Typography>
                    <Typography sx={styles.cardStyleHeading}>
                      Phone:
                      <span style={styles.cardStyleValue}>
                        {cardData.phone}
                      </span>
                    </Typography>
                    <Typography sx={styles.cardStyleHeading}>
                      User Status:{" "}
                      <span style={styles.cardStyleValue}>
                        {cardData.is_active ? "Active" : "Inactive"}
                      </span>
                    </Typography>

                    <Typography sx={styles.cardStyleHeading}>
                      Referal Id:
                      <span style={styles.cardStyleValue}>
                        {cardData.referral_id}
                      </span>
                    </Typography>

                    <Typography sx={styles.cardStyleHeading}>
                      Registered On:
                      <span style={styles.cardStyleValue}>
                        {dateConverter(cardData.registered_on)}
                      </span>
                    </Typography>
                    <Typography sx={styles.cardStyleHeading}>
                      Updated At:
                      <span style={styles.cardStyleValue}>
                        {dateConverter(cardData.updated_at)}
                      </span>
                    </Typography>
                    <Typography sx={styles.cardStyleHeading}>
                      Updated By:
                      <span style={styles.cardStyleValue}>
                        {cardData.updated_by}
                      </span>
                    </Typography>
                    {parseInt(cardData.consumer_profile.user_type) ===
                    INFLUENCER_TYPE.USER_TYPE_INFLUENCER ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box sx={styles.cardStyleHeading}>Deactivate:</Box>
                        <Box>
                          <Switch
                            checked={active}
                            onChange={deActivatingInfluencer}
                            color="success"
                          />
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowWrap: "break-word",
                    }}
                  >
                    {cardData.consumer_profile.bank_name === "" ? null : (
                      <Typography sx={styles.cardStyleHeading}>
                        Bank Name:
                        <span style={styles.cardStyleValue}>
                          {cardData.consumer_profile.bank_name}
                        </span>
                      </Typography>
                    )}
                    {cardData.consumer_profile.pan_number === "" ? null : (
                      <Typography sx={styles.cardStyleHeading}>
                        PAN:
                        <span style={styles.cardStyleValue}>
                          {cardData.consumer_profile.pan_number}
                        </span>
                      </Typography>
                    )}
                    {cardData.consumer_profile.IFSC_number === "" ? null : (
                      <Typography sx={styles.cardStyleHeading}>
                        IFSC:
                        <span style={styles.cardStyleValue}>
                          {cardData.consumer_profile.IFSC_number}
                        </span>
                      </Typography>
                    )}
                    {cardData.consumer_profile.account_number === "" ? null : (
                      <Typography sx={styles.cardStyleHeading}>
                        Account Number:
                        <span style={styles.cardStyleValue}>
                          {cardData.consumer_profile.account_number}
                        </span>
                      </Typography>
                    )}
                    <Typography sx={styles.cardStyleHeading}>
                      User Type:
                      <span style={styles.cardStyleValue}>
                        {parseInt(cardData.consumer_profile.user_type) ===
                        INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REQUESTED
                          ? "Requested"
                          : parseInt(cardData.consumer_profile.user_type) ===
                            INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REJECTED
                          ? "Rejected"
                          : parseInt(cardData.consumer_profile.user_type) ===
                            INFLUENCER_TYPE.USER_TYPE_INFLUENCER
                          ? "Influencer"
                          : parseInt(cardData.consumer_profile.user_type) ===
                            INFLUENCER_TYPE.USER_TYPE_CONSUMER
                          ? "Consumer"
                          : parseInt(cardData.consumer_profile.user_type) ===
                            INFLUENCER_TYPE.USER_TYPE_INFLUENCER_DISABLED
                          ? "Disabled Influencer"
                          : ""}
                      </span>
                    </Typography>
                    <Box sx={{ marginTop: "10px" }}>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Valid From"
                            inputFormat="DD/MM/YYYY"
                            value={validFrom}
                            onChange={handleValidFrom}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onKeyDown={handleInputChange}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box sx={{ marginTop: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Valid To"
                            inputFormat="DD/MM/YYYY"
                            value={validTill}
                            onChange={handleValidTill}
                            disablePast={true}
                            renderInput={(params) => <TextField {...params} />}
                            onKeyDown={handleInputChange}
                            minDate={currentDate}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {parseInt(cardData.consumer_profile.user_type) ===
            INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REQUESTED ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#F01111",
                    "&:hover": {
                      backgroundColor: "#BE2828",
                    },
                  }}
                  onClick={rejectEnableInfluencer}
                >
                  Reject
                </Button>
                <Button variant="contained" onClick={openAcceptDialog}>
                  Accept
                </Button>
              </Box>
            ) : parseInt(cardData.consumer_profile.user_type) ===
                INFLUENCER_TYPE.USER_TYPE_INFLUENCER ||
              parseInt(cardData.consumer_profile.user_type) ===
                INFLUENCER_TYPE.USER_TYPE_INFLUENCER_DISABLED ||
              parseInt(cardData.consumer_profile.user_type) ===
                INFLUENCER_TYPE.USER_TYPE_INFLUENCER_REJECTED ? (
              <Button
                sx={{ marginTop: "20px" }}
                variant="contained"
                onClick={updatingValidity}
              >
                Update
              </Button>
            ) : null}
          </CardContent>
          <Dialog
            open={dialog}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <DialogContentText>
                Do you agree to accept this request?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Disagree
              </Button>
              <Button autoFocus onClick={acceptEnableInfluencer}>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      ) : null}
    </>
  );
}

export default AdminInfluencerCard;
