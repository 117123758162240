/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Webcam from "react-webcam";

import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Grid,
  RadioGroup,
  FormControl,
  Radio,
  Box,
  InputLabel,
  Paper,
  DialogContent,
  DialogTitle,
  Dialog,
  Select,
  MenuItem,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  NativeSelect,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BorderLinearProgress from "../../Components/ProgressBars/BorderLinearProgress";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import TocIcon from "@mui/icons-material/Toc";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  EndVehicleTrip,
  GetPunchinEmployees,
  GetUnassignedEmployees,
  GetVehicleCurrentStatus,
  LoadVehicle,
  StartVehicleTrip,
} from "../Redux/Actions/supplierVehiclesActions";
import {
  ADMIN_ROLES,
  employeeType,
  VehicleTypes,
} from "../../Components/AppMeta/appMetaConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import VehicleAssignOrders from "./TripPlanning/VehicleAssignOrders";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import CurrentDayDeliveiesNew from "../SupplierDeliveries/CurrentDayDeliveiesNew";
import {
  CameraAlt,
  Cancel,
  DriveFolderUpload,
  FlipCameraAndroid,
  PhotoCamera,
  Place,
} from "@mui/icons-material";
import { GetSupplierOnGoingAssignedSlotAndAgent } from "../Redux/Actions/supliersAction";

const Tabs = {
  trip_out: 0,
  assign_orders: 2,
  trip_close: 3,
  trip_End: 4,
  in_scan: 1,
  deliveries: 5,
};
const VehicleChecklist = ({
  open,
  isEnd,
  onClose,
  vehicleDetails,
  force_tab,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const vehicleManagement = useSelector((state) => state.vehicleManagement);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  console.log(vehicleDetails);
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedLoaders, setselectedLoaders] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [filled, setFilled] = useState(0);
  const [empty, setEmpty] = useState(0);
  const [availableEmployees, setAvailableEmployees] = useState();
  const [answers, setAnswers] = useState({});
  const [availableDrivers, setAvailableDrivers] = useState([]);
  const [availableLoaders, setAvailableLoaders] = useState([]);
  const [filterPincode, setFilterPincode] = useState("");
  const [searchOrderId, setSearchOrderId] = useState("");
  const [filterProduct, setFilterProduct] = useState("");
  const [loadedQty, setLoadedQty] = useState(0);
  const [assignByMap, setAssignByMap] = useState(false);
  const [vehicleCurrentStatus, setVehicleCurrentStatus] = useState({});
  const [tripStartChecklist, setTripStartChecklist] = useState([]);
  const [tripEndChecklist, setTripEndChecklist] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [IsLoading, setIsLoading] = useState(true);
  const [checkSheetFiles, setCheckSheetFiles] = useState({});
  const handleChange = (questionId, value) => {
    setAnswers({
      ...answers,
      [questionId]: value,
    });
  };
  const getCurrentStatus = () => {
    if (vehicleDetails.tanker_id) {
      dispatch(
        GetVehicleCurrentStatus({ tanker_id: vehicleDetails.tanker_id })
      ).then((data) => {
        setTripStartChecklist(data?.data?.checklist || []);
        setTripEndChecklist(data?.data?.end_trip_checklist || []);
        setVehicleCurrentStatus(data?.data?.trip_details || {});
      });
    }
  };
  useEffect(() => {
    if (vehicleCurrentStatus?.drivers?.[0]) {
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          page_number: 0,
          page_size: 100,
          agent_id: vehicleCurrentStatus?.drivers?.[0],
        })
      );
    }
  }, [vehicleCurrentStatus]);

  useEffect(() => {
    if (force_tab && force_tab === "deliveries") {
      setCurrentTab(Tabs?.deliveries);
    } else if (isEnd) {
      setCurrentTab(Tabs?.trip_End);
    } else if (
      vehicleCurrentStatus?.orders?.total &&
      vehicleCurrentStatus?.orders?.total ===
        vehicleCurrentStatus?.orders?.completed
    ) {
      setCurrentTab(Tabs?.trip_End);
    } else if (
      !vehicleCurrentStatus?.end_time &&
      vehicleCurrentStatus?.opening_check_list
    ) {
      setCurrentTab(Tabs?.in_scan);
    }
  }, [vehicleCurrentStatus]);

  useEffect(() => {
    getCurrentStatus();
  }, [vehicleDetails]);
  useEffect(() => {
    if (skuContainerDetails?.getAvailableProducts.length > 0) {
      // let qrLessProducts = skuContainerDetails?.getAvailableProducts?.filter(
      //   (data) => data.product_details.is_qr === false
      // );
      setAvailableProducts(skuContainerDetails?.getAvailableProducts);
    }
  }, [skuContainerDetails]);
  useEffect(() => {
    if (vehicleManagement.punchDetails) {
      dispatch(
        GetUnassignedEmployees({
          warehouse_id: vehicleManagement.punchDetails.warehouse_id,
        })
      ).then((data) => {
        const drivers = data?.data?.filter(
          (item) => item.role_id === ADMIN_ROLES.BW_DELIVERYAGENT
        );
        setAvailableDrivers(drivers);
        const loaders = data?.data?.filter(
          (item) => item.role_id === ADMIN_ROLES.BW_SUPPLIER_LOADERS
        );
        setAvailableEmployees(data?.data);
        setAvailableLoaders(loaders);
      });
    }
  }, [vehicleManagement.punchDetails]);
  const saveChecklist = () => {
    // Handle form submission with answers
    const checklist = tripStartChecklist?.map((item) => {
      return { ...item, answer: answers[item.question_id] };
    });
    const params = {
      tanker_id: vehicleDetails.tanker_id,
      warehouse_id: vehicleManagement.punchDetails.warehouse_id,
      drivers: [selectedDriver],
      opening_check_list: { checklist },
    };
    if (selectedLoaders?.length) {
      params.loaders = selectedLoaders;
    }

    dispatch(StartVehicleTrip(params)).then((data) => {
      if (data) {
        getCurrentStatus();
      }
    });

    console.log("Submitted answers:", answers);
  };
  const endTrip = () => {
    const checklist = tripEndChecklist?.map((item) => {
      return { ...item, answer: answers[item.question_id] };
    });
    const params = {
      tanker_id: vehicleDetails.tanker_id,

      closing_check_list: { checklist },
    };

    dispatch(EndVehicleTrip(params)).then((data) => {
      if (data) {
        onClose();
        getCurrentStatus();
      }
    });
  };
  const loadCans = async () => {
    let product = availableProducts[selectedProduct];
    if (!product) {
      return;
    }
    dispatch(
      LoadVehicle({
        tanker_id: vehicleDetails.tanker_id,
        qr_less: {
          material: product.product_details.material,
          capacity: product.product_details.capacity,
          filled,
          empty,
        },
        is_wh_in_scan: !IsLoading,
        warehouse_id: vehicleManagement.punchDetails.warehouse_id,
      })
    ).then((data) => {
      setSelectedProduct(null);
      setFilled(0);
      setEmpty(0);
      getCurrentStatus();
    });
  };
  useState(() => {
    const loaded_qty = vehicleDetails?.current_trip?.orders?.orders?.reduce(
      (acc, order) => {
        // Iterate over each product in the current order and accumulate the required quantity
        order.products.forEach((product) => {
          acc += product.required;
        });
        return acc;
      },
      0
    );
    dispatch(GetAvailableProducts());
  }, [vehicleDetails]);

  const handleMaps = () => {
    if (
      vehicleDetails?.trips?.[0]?.other_details?.current_location?.address
        ?.latitude !== undefined &&
      vehicleDetails?.trips?.[0]?.other_details?.current_location?.address
        ?.latitude !== null &&
      vehicleDetails?.trips?.[0]?.other_details?.current_location?.address
        ?.longitude !== undefined &&
      vehicleDetails?.trips?.[0]?.other_details?.current_location?.address
        ?.longitude !== null &&
      parseFloat(
        vehicleDetails?.trips?.[0]?.other_details?.current_location?.address
          ?.latitude
      ) > 0 &&
      parseFloat(
        vehicleDetails?.trips?.[0]?.other_details?.current_location?.address
          ?.longitude
      ) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${vehicleDetails?.trips?.[0]?.other_details?.current_location?.address?.latitude},${vehicleDetails?.trips?.[0]?.other_details?.current_location?.address?.longitude}`;
      // Redirect to the map URL
      // window.location.href = mapUrl;
      window.open(mapUrl, "_blank").focus();
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const handleRedirecttorecentorder = (props) => {
    const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${props}`;
    history.push(url);
  };

  return (
    <Dialog onClose={onClose} fullWidth fullScreen open={open}>
      <DialogTitle sx={{ padding: 0 }}>
        <Box sx={{ bgcolor: "#152057", p: 1, color: "white" }}>
          <Typography variant="h5" gutterBottom>
            {currentTab === Tabs?.trip_out
              ? "Trip Out Checklist"
              : currentTab === Tabs?.assign_orders
              ? "Assign Orders"
              : currentTab === Tabs?.in_scan
              ? "Load Products"
              : "Closing CheckSheet"}
          </Typography>
        </Box>
      </DialogTitle>
      <CloseIcon
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      />
      {/* TOP Vehicle Details */}
      {[Tabs.assign_orders, Tabs.in_scan].includes(currentTab) ? (
        <Box sx={{ width: "100%", mt: 1 }}>
          <Button
            onClick={() => setCurrentTab(Tabs.trip_End)}
            sx={{ float: "right" }}
            color="error"
            variant="contained"
          >
            Close Trip
          </Button>
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ width: "100%", p: 1 }}>
        <Paper elevation={12} sx={{ mb: 1, p: 1 }}>
          <Grid
            container
            spacing={{ md: 2, xs: 0 }}
            sx={{ bgcolor: "#D4EFDF", padding: "10px" }}
          >
            <Grid sx={{ fontWeight: "bold" }} item md={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}>Reg.No</Typography>
                <Typography>{vehicleDetails.tanker_id}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}>Capacity</Typography>
                <Typography>{vehicleDetails?.capacity}(SKU)</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}> Type</Typography>
                <Typography>
                  {vehicleDetails?.tanker_type
                    ? VehicleTypes?.find(
                        (item) => item.value === vehicleDetails?.tanker_type
                      )?.name
                    : "-"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}>Location</Typography>
                <Typography>
                  {vehicleDetails?.trips?.[0]?.other_details?.current_location
                    ?.address?.service_area || "--"}{" "}
                  <Place color="error" onClick={handleMaps} />
                </Typography>
              </Box>
            </Grid>
            <Grid sx={{ fontWeight: "bold" }} item md={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}>Model</Typography>
                <Typography>{vehicleDetails?.tanker_profile?.model}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}> Make</Typography>
                <Typography> {vehicleDetails?.tanker_profile?.make}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {" "}
                  Assigned Orders
                </Typography>
                <Typography>
                  {supplierDeliveries
                    .getSupplierAssignSlotAgentOngoingDeliveries?.length > 0
                    ? supplierDeliveries
                        .getSupplierAssignSlotAgentOngoingDeliveries?.length
                    : 0}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {" "}
                  Last Delivered Order
                </Typography>
                <Typography>
                  {vehicleCurrentStatus?.recent_order ? (
                    <>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleRedirecttorecentorder(
                            vehicleCurrentStatus?.recent_order
                          )
                        }
                      >
                        {vehicleCurrentStatus?.recent_order}
                      </Typography>
                    </>
                  ) : (
                    "--" || "--"
                  )}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container sx={{ bgcolor: "#D5DBDB", pl: 1 }}>
                <Grid sx={{ fontWeight: "bold" }} item xs={3}>
                  Driver
                </Grid>
                <Grid item xs={9} sx={{}}>
                  {vehicleCurrentStatus?.employees?.map((item) => {
                    if (item?.role_id == ADMIN_ROLES.BW_DELIVERYAGENT) {
                      return (
                        <Box>
                          {item?.driver_profile?.fullname}(
                          {item?.driver_profile?.phone})
                        </Box>
                      );
                    }
                    return <></>;
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{ bgcolor: "#F2F3F4", pl: 1 }}>
                <Grid sx={{ fontWeight: "bold" }} item xs={3}>
                  Loaders
                </Grid>
                <Grid item xs={9} sx={{}}>
                  {vehicleCurrentStatus?.employees?.map((item) => {
                    if (item?.role_id == ADMIN_ROLES.BW_SUPPLIER_LOADERS) {
                      return (
                        <Box>
                          {item?.driver_profile?.fullname}(
                          {item?.driver_profile?.phone})
                        </Box>
                      );
                    }
                    return <></>;
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* TRIP OUT */}
        <TabPanel value={currentTab} index={Tabs?.trip_out}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveChecklist();
              // e.preventDefault();
              // setCurrentTab(Tabs?.assign_orders);
              // onClose(false);
            }}
          >
            <Paper sx={{ overflow: "auto", maxHeight: "76vh" }}>
              <Paper
                sx={{
                  my: 2,
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    p: 2,
                  }}
                >
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-multiple-name-label">
                      {availableDrivers?.length
                        ? "Select Driver"
                        : "No Drivers Available"}
                    </InputLabel>
                    <Select
                      value={selectedDriver}
                      name="Driver"
                      required
                      label={
                        availableDrivers?.length
                          ? "Select Driver"
                          : "No Drivers Available"
                      }
                      onChange={(e) => setSelectedDriver(e.target.value)}
                      fullWidth
                    >
                      {availableDrivers?.map((driver) => {
                        return (
                          <MenuItem value={driver?.driver_id}>
                            {driver?.driver_profile?.fullname}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Paper>
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    p: 2,
                  }}
                >
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-multiple-checkbox-label">
                      {availableLoaders?.length
                        ? "Select Loaders"
                        : "No Loaders Available"}
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedLoaders}
                      disabled={!availableLoaders?.length}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        setselectedLoaders(value);
                      }}
                      label={
                        availableLoaders?.length
                          ? "Select Loaders"
                          : "No Loaders Available"
                      }
                      //   input={<OutlinedInput label="Loaders" />}
                      renderValue={(selected) => {
                        let loaders = availableLoaders.filter((loader) =>
                          selected?.includes(loader.driver_id)
                        );
                        return loaders
                          ?.map((loader) => loader?.driver_profile?.fullname)
                          .join(", ");
                      }}
                      //   MenuProps={MenuProps}
                    >
                      {availableLoaders?.map((loader, index) => (
                        <MenuItem key={index} value={loader?.driver_id}>
                          <Checkbox
                            checked={
                              selectedLoaders?.includes(loader?.driver_id)
                                ? true
                                : false
                            }
                          />
                          <ListItemText
                            primary={loader?.driver_profile?.fullname}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Paper>

              <Grid container spacing={2}>
                {/* Map through the questions array */}
                {tripStartChecklist.map((question, index) => (
                  <Grid item xs={12} key={question.question_id}>
                    <Paper
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        p: 2,
                      }}
                    >
                      <DynamicQuestionComponent
                        handleChange={handleChange}
                        key={index}
                        files={checkSheetFiles}
                        setFiles={setCheckSheetFiles}
                        index={index}
                        question={question}
                        currentAnswer={answers[question.question_id] || ""}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
            <Box>
              <Button
                sx={{ float: "right", mt: 2 }}
                variant="contained"
                color="primary"
                type="submit"
                // onClick={() => saveChecklist()}
              >
                Load Cans
              </Button>
            </Box>
          </form>
        </TabPanel>
        {/*Load Cans */}
        <TabPanel value={currentTab} index={Tabs?.in_scan}>
          <Box sx={{ width: "100%", mb: 1 }}>
            <Box
              sx={{
                width: "100%",
                mt: 1,
                mb: 1,
                display: "flex",
                justifyContent: "center",
              }}
              component={Paper}
              elevation={18}
            >
              <Stack direction="row" spacing={1} p={2} alignItems="center">
                <Typography
                  sx={{
                    color: IsLoading === false ? "#30A2F3" : "black",
                  }}
                >
                  Out - Scan
                </Typography>
                <AntSwitch
                  checked={IsLoading}
                  onChange={() => setIsLoading((prev) => !prev)}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography
                  sx={{
                    color: IsLoading === true ? "#30A2F3" : "black",
                  }}
                >
                  In - Scan
                </Typography>
              </Stack>
            </Box>
            <Paper
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                gap: 1,
                p: 1,
                pt: 2,
              }}
              elevation={18}
            >
              <FormControl
                key={selectedProduct}
                sx={{ width: 220 }}
                fullWidth
                size="small"
              >
                <InputLabel id="demo-select-small-label">
                  {selectedProduct === 0 || selectedProduct
                    ? "Change Product"
                    : "Select Product to Load"}
                </InputLabel>
                <Select
                  key={selectedProduct}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedProduct}
                  label={
                    selectedProduct === 0 || selectedProduct
                      ? "Change Product"
                      : "Select Product to Load"
                  }
                  onChange={(e) => setSelectedProduct(e.target.value)}
                  sx={{
                    color: "black",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                    },
                  }}
                >
                  {availableProducts?.map((option, index) => (
                    <MenuItem key={option.product_details.id} value={index}>
                      {option.product_details.name}/Material:
                      {option.product_details.material}/Capacity:
                      {option.product_details.capacity}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedProduct === 0 || selectedProduct ? (
                <>
                  <TextField
                    onChange={(e) =>
                      setFilled(
                        parseInt(e.target.value || 0) > 0
                          ? parseInt(e.target.value || 0)
                          : !e.target.value
                          ? e.target.value
                          : 0
                      )
                    }
                    value={filled}
                    required
                    size="small"
                    label="Filled"
                    variant="outlined"
                  />
                  <TextField
                    onChange={(e) =>
                      setEmpty(
                        parseInt(e.target.value || 0) > 0
                          ? parseInt(e.target.value || 0)
                          : !e.target.value
                          ? e.target.value
                          : 0
                      )
                    }
                    value={empty}
                    required
                    size="small"
                    label="Empty"
                    variant="outlined"
                  />
                </>
              ) : (
                <></>
              )}

              {(selectedProduct === 0 || selectedProduct) &&
              (filled || empty) ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <Button onClick={() => loadCans()} variant="contained">
                      {IsLoading ? "Load" : "Unload"}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Paper>
            <Paper sx={{ mt: "15px" }} elevation={18}>
              <Grid container>
                <Grid
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    border: "1.5px solid #D5DBDB",
                  }}
                  item
                  xs={8}
                >
                  Products
                </Grid>
                <Grid
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    border: "1.5px solid #D5DBDB",
                  }}
                  item
                  xs={2}
                >
                  Filled
                </Grid>
                <Grid
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    border: "1.5px solid #D5DBDB",
                  }}
                  item
                  xs={2}
                >
                  Empty
                </Grid>
                {vehicleCurrentStatus?.stock?.map((item) => {
                  return (
                    <>
                      <Grid
                        sx={{ pl: 1, border: "1.5px solid #D5DBDB" }}
                        item
                        xs={8}
                      >
                        {item.capacity} {item.material}
                      </Grid>
                      <Grid
                        sx={{
                          textAlign: "center",
                          border: "1.5px solid #D5DBDB",
                        }}
                        item
                        xs={2}
                      >
                        {item.filled}
                      </Grid>
                      <Grid
                        sx={{
                          textAlign: "center",
                          border: "1.5px solid #D5DBDB",
                        }}
                        item
                        xs={2}
                      >
                        {item.empty}
                      </Grid>
                    </>
                  );
                })}
                {!vehicleCurrentStatus?.stock?.length && (
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1.5px solid #D5DBDB",
                    }}
                  >
                    No Products Loaded
                  </Box>
                )}
              </Grid>
            </Paper>
          </Box>
          <Grid item xs={12} sx={{ float: "" }}>
            <Button
              onClick={() => setCurrentTab(Tabs?.assign_orders)}
              sx={{ float: "right" }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Assign Orders
            </Button>
          </Grid>
        </TabPanel>
        {/* Assign orders */}
        <TabPanel value={currentTab} index={Tabs?.assign_orders}>
          <VehicleAssignOrders
            vehicleCurrentStatus={vehicleCurrentStatus}
            assignByMap={assignByMap}
            setAssignByMap={setAssignByMap}
          />
          <Grid item xs={12} sx={{ float: "", mt: 2 }}>
            <Button
              onClick={() => {
                toast.success("Trip Started !", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                onClose();
              }}
              sx={{ float: "right" }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Start Trip
            </Button>
          </Grid>
        </TabPanel>
        {/* Closing Checksheet */}
        <TabPanel value={currentTab} index={Tabs?.trip_End}>
          <Paper sx={{ overflow: "auto", maxHeight: "76vh" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                endTrip();
                // e.preventDefault();
                // setCurrentTab(Tabs?.assign_orders);
                // onClose(false);
              }}
            >
              <Paper sx={{ overflow: "auto", maxHeight: "76vh" }}>
                <Grid container spacing={2}>
                  {/* Map through the questions array */}
                  {tripEndChecklist.map((question, index) => (
                    <Grid item xs={12} key={question.question_id}>
                      <Paper
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          p: 2,
                          ml: 1,
                          mr: 1,
                        }}
                        elevation={18}
                      >
                        <DynamicQuestionComponent
                          handleChange={handleChange}
                          key={index}
                          files={checkSheetFiles}
                          setFiles={setCheckSheetFiles}
                          index={index}
                          question={question}
                          currentAnswer={answers[question.question_id] || ""}
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
              <Box>
                <Button
                  sx={{ float: "right", mt: 2 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  // onClick={() => saveChecklist()}
                >
                  End Trip
                </Button>
              </Box>
            </form>
          </Paper>
          <Box>
            {/* <Button
              sx={{ float: "right", mt: 2 }}
              variant="contained"
              color="primary"
              onClick={() => {
                toast.success("Trip Closed Succesfully !", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                onClose();
              }}
            >
              End Trip
            </Button> */}
          </Box>
        </TabPanel>
        {/* deliveries of vehicles */}
        <TabPanel value={currentTab} index={Tabs?.deliveries}>
          {/* drivers */}
          {/* {vehicleCurrentStatus?.drivers?.[0]} */}
          <Box sx={{ px: 1 }}></Box>
          {vehicleCurrentStatus?.drivers && (
            <CurrentDayDeliveiesNew
              driver_id={vehicleCurrentStatus?.drivers?.[0]}
            />
          )}
          {/* call the deliveries component here */}
        </TabPanel>
      </Box>
    </Dialog>
  );
};

export default VehicleChecklist;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const DynamicQuestionComponent = ({
  question,
  handleChange,
  index,
  setFiles,
  files,
  currentAnswer,
}) => {
  const webcamRef = React.useRef(null);

  const dispatch = useDispatch();
  const [showWebcam, setShowWebcam] = useState(false);
  const [facingMode, setFacingMode] = useState("environment");

  const [imageSrc, setImageSrc] = useState(null);
  const handleCapture = () => {
    setShowWebcam(true);
  };
  const handleWebcamCapture = (webcamRef) => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setImageSrc(imageSrc);
      const file = dataURLToFile(imageSrc, "captured-image.jpg");
      handleImageChange({ target: { files: [file] } }, question.question_id);
      setShowWebcam(false);
    }
  };
  const dataURLToFile = (dataUrl, fileName) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };
  const switchCamera = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === "user" ? "environment" : "user"
    );
  };

  const handleImageChange = (e, question_id) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(UploadFile(file)).then((data) => {
        if (data.filename) handleChange(question.question_id, data.filename);
      });

      setFiles((prev) => ({ ...prev, [question_id]: file }));
      // setSelectedImage(file);
      // setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <Box>
      <Box>
        <Typography sx={{ color: "blue", mb: "10px" }}>
          {index + 1}.{question.question}
        </Typography>
      </Box>
      {/* Render different input fields based on answer_type */}
      {question.answer_type === "radio" ? (
        <Box>
          <FormControl>
            {/* // label={question.question} */}
            <input
              value={currentAnswer}
              type="text"
              required
              style={{
                height: "1px",
                width: "1px",
                border: "none",
                background: "transparent",
                padding: "0",
                margin: "0",
              }}
            />
            <RadioGroup
              value={currentAnswer}
              //   value={isCanDamagedValue}
              //   onChange={handleChangeRadio}
              onChange={(e) =>
                handleChange(question.question_id, e.target.value)
              }
            >
              <Box sx={{ display: "flex", ml: 2, flexWrap: "wrap" }}>
                {question?.options?.map((option) => {
                  return (
                    <FormControlLabel
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  );
                })}
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      ) : question.answer_type === "file" ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {showWebcam ? (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{ facingMode }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={() => handleWebcamCapture(webcamRef)}
                  >
                    <PhotoCamera />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => setShowWebcam(false)}
                  >
                    <Cancel />
                  </IconButton>
                  <IconButton color="default" onClick={switchCamera}>
                    <FlipCameraAndroid />
                  </IconButton>
                </div>
              </div>
            ) : (
              <Box sx={{ display: "flex", gap: 1 }}>
                <Box>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id={`image-upload${question.question_id}`}
                    type="file"
                    onChange={(e) => handleImageChange(e, question.question_id)}
                  />
                  <label htmlFor={`image-upload${question.question_id}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <DriveFolderUpload /> Upload
                    </Button>
                  </label>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCapture}
                >
                  <CameraAlt /> Capture
                </Button>
              </Box>
            )}
            <input
              value={files[question.question_id] || imageSrc}
              type="text"
              required
              style={{
                height: "1px",
                width: "1px",
                border: "none",
                background: "transparent",
                padding: "0",
                margin: "0",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {files[question.question_id] && (
              <Box>
                <img
                  src={URL.createObjectURL(files[question.question_id])}
                  alt="Selected"
                  width="100%"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ display: "flex", ml: 2 }}>
          <TextField
            required
            fullWidth
            key={question.question_id}
            label={question.question}
            value={currentAnswer}
            type={question.answer_type === "decimal" ? "number" : "text"}
            onChange={(e) => {
              let value = e.target.value;
              handleChange(question.question_id, value);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 47,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 28,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 4,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
