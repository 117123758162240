import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Html5QrcodePlugin1 from "../../wfsHome/HtmlQrcodePlugin1";

import { DAMAGE_REASON_CODES } from "../../../Components/AppMeta/appMetaConfig";
import { useDispatch, useSelector } from "react-redux";
import { GetCompleteQrDetails } from "../../Redux/Actions/adminQrBarcodeAction";
import { toast } from "react-toastify";
const TOAST_CONFIG = {
  position: "bottom-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
const MarkDamage = ({ handleSubmit, products }) => {
  const dispatch = useDispatch();
  const [damagedQty, setDamagedQty] = useState();
  const [damagedProducts, setDamagedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [scanOption, setScanOption] = useState("scanner");
  const [scanQrFlag, setScanQrFlag] = useState();
  const [selectedReason, setSelectedReason] = useState();
  const [productStatus, setProductStatus] = useState("");
  const [qrBarcodeId, setQrBarcodeId] = useState();
  // const onNewScanResultQrCode = (qr_code) => {
  // };
  useEffect(() => {
    console.log(productStatus);
  }, [productStatus]);
  useEffect(() => {
    if (qrBarcodeId && scanOption == "camera") {
      completeQrScanning(qrBarcodeId);
      setQrBarcodeId("");
    }
  }, [qrBarcodeId]);
  const onQrScan = (qrcode) => {
    setQrBarcodeId(qrcode);
  };
  const completeQrScanning = (qrcode) => {
    if (!productStatus) {
      setQrBarcodeId("");
      toast.warn("Please Select Product Filled / Empty", TOAST_CONFIG);
      return;
    }
    dispatch(GetCompleteQrDetails(qrcode)).then((data) => {
      if (data && !data.error && data.data) {
        if (data.data.qualityDetails?.[0].is_damaged_active) {
          toast.error(
            "This Product is already Marked as Damaged",
            TOAST_CONFIG
          );
          return;
        }

        setDamagedProducts((prev) => {
          let isQrExist = prev?.findIndex((item) => {
            return (
              item?.qualityDetails?.[0]?.qr_code ===
              data?.data?.qualityDetails?.[0]?.qr_code
            );
          });
          if (isQrExist < 0) {
            prev.unshift({
              ...data.data,
              is_filled: productStatus === "filled" ? true : false,
              qr_code: data?.data?.qualityDetails?.[0]?.qr_code,
              reason:
                scanOption !== "qrLess"
                  ? DAMAGE_REASON_CODES?.PhysicallyDamaged
                  : selectedReason,
            });
            toast.success("Product is added to list", TOAST_CONFIG);
          } else {
            toast.error("This Product is already Scanned", TOAST_CONFIG);
          }
          return JSON.parse(JSON.stringify(prev));
        });
      }
    });

    // onSubmitQr({ qrcode });
    setQrBarcodeId();
  };
  useEffect(() => {
    if (damagedProducts) {
    }
  }, [damagedProducts]);
  const addQrlessProducts = () => {
    if (!productStatus) {
      toast.warn("Please Select Product Filled / Empty", TOAST_CONFIG);
      return;
    }
    if (selectedProduct && damagedQty) {
      let data = {};
      setDamagedProducts((prev) => {
        let isQrExist = prev?.findIndex(
          (item) =>
            item?.qualityDetails?.[0]?.container_details?.capacity ===
              selectedProduct?.capacity &&
            item?.qualityDetails?.[0]?.container_details?.material ===
              selectedProduct?.material &&
            item.isQrLess === false
        );
        if (isQrExist < 0) {
          prev.unshift({
            qualityDetails: [
              {
                container_details: {
                  capacity: selectedProduct?.capacity,
                  material: selectedProduct?.material,
                  name: selectedProduct?.name,
                },
              },
            ],
            filled: productStatus === "filled" ? damagedQty : 0,
            empty: productStatus === "filled" ? 0 : damagedQty,
            physical_damaged_container_qty: selectedProduct?.is_qr
              ? 0
              : damagedQty || 0,
            qr_barcode_damaged_container_qty: selectedProduct?.is_qr
              ? damagedQty || 0
              : 0,
            isQrLess: true,
            reason: selectedProduct?.is_qr
              ? DAMAGE_REASON_CODES?.QrBarcodeDamaged
              : DAMAGE_REASON_CODES?.PhysicallyDamaged,
          });
        } else {
          prev[isQrExist].physical_damaged_container_qty =
            (prev[isQrExist].physical_damaged_container_qty || 0) +
            selectedProduct?.is_qr
              ? 0
              : damagedQty;
          prev[isQrExist].qr_barcode_damaged_container_qty =
            (prev[isQrExist].qr_barcode_damaged_container_qty || 0) +
            selectedProduct?.is_qr
              ? damagedQty
              : 0;
          prev[isQrExist].filled =
            (prev[isQrExist].filled || 0) +
            (productStatus === "filled" ? damagedQty : 0);
          prev[isQrExist].empty =
            (prev[isQrExist].empty || 0) +
            (productStatus === "filled" ? 0 : damagedQty);
        }
        return JSON.parse(JSON.stringify(prev));
      });
      setSelectedProduct();
      setDamagedQty();
    }
  };
  const submitDamaged = () => {
    handleSubmit({ products: damagedProducts });
    setDamagedProducts([]);
  };
  const ProductStatus = () => {
    return (
      <FormControl>
        <FormLabel
          id="product-status-select"
          sx={{ fontWeight: "bold", color: "blue" }}
        >
          Product Status
        </FormLabel>
        <RadioGroup
          value={productStatus}
          onChange={(e) => setProductStatus(e.target.value)}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          // sx={{ display: "flex",  }}
          row
        >
          <FormControlLabel value="filled" control={<Radio />} label="Filled" />
          <FormControlLabel value="empty" control={<Radio />} label="Empty" />
        </RadioGroup>
      </FormControl>
    );
  };
  return (
    <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Box sx={{ gap: 2, display: "flex" }}>
        <Box sx={{ alignItems: "center", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Paper elevation={10} sx={{ padding: 1, mx: 1 }}>
              <FormControl>
                <FormLabel sx={{ fontWeight: "bold", color: "blue" }}>
                  Select Scanning Option
                </FormLabel>
                <RadioGroup
                  defaultValue="scanner"
                  value={scanOption}
                  name="radio-buttons-group"
                  sx={{ display: "flex", flexDirection: "row" }}
                  onChange={(e) => setScanOption(e.target.value)}
                >
                  <FormControlLabel
                    value="scanner"
                    control={<Radio />}
                    label="Scanner Device"
                  />
                  <FormControlLabel
                    value="camera"
                    control={<Radio />}
                    label="Camera"
                  />
                  <FormControlLabel
                    value="qrLess"
                    control={<Radio />}
                    label="Qr Less"
                  />
                </RadioGroup>
              </FormControl>
            </Paper>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Paper elevation={10} sx={{ padding: 1, mx: 1 }}>
                <ProductStatus />
              </Paper>
              <Paper elevation={10} sx={{ padding: 1, mx: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  {scanOption === "camera" ? (
                    <Box
                      sx={{
                        width: "290px",
                      }}
                    >
                      {productStatus ? (
                        <Html5QrcodePlugin1
                          fps={1}
                          qrbox={200}
                          disableFlip={false}
                          qrCodeSuccessCallback={onQrScan}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>

                <Box
                  sx={{ display: "flex", justifyContent: "center", mt: "10px" }}
                >
                  <Box>
                    {scanOption === "scanner" ? (
                      <Box>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            completeQrScanning(qrBarcodeId);
                          }}
                        >
                          <Box
                            sx={{ fontWeight: "bold", color: "blue", mb: 1 }}
                          >
                            Marked as Physical Damage ...
                          </Box>
                          <TextField
                            name="qrCode"
                            autoFocus={true}
                            onChange={(e) =>
                              setQrBarcodeId(e.target.value?.toUpperCase())
                            }
                            label="QR/Barcode:"
                            key={qrBarcodeId}
                            value={qrBarcodeId}
                            size="small"
                            sx={{
                              height: "10px",
                              width: "200px",
                              marginBotton: "30px",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Button type="submit" style={{ display: "none" }} />{" "}
                        </form>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: "15px",
                  }}
                >
                  {scanOption === "qrLess" ? (
                    <Box
                      sx={{
                        gap: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Autocomplete
                        disabled={!products?.length}
                        id="product-select-demo"
                        sx={{ width: 210 }}
                        options={products}
                        key={selectedProduct}
                        value={selectedProduct} // Set the value of Autocomplete to the selected product
                        isOptionEqualToValue={(option, value) =>
                          option.capacity === value?.capacity &&
                          option.material === value?.material
                        }
                        autoHighlight
                        getOptionLabel={(option) => option.name || ""} // Handle case where name is null
                        onChange={(_, newValue) => {
                          setSelectedProduct(newValue || null); // Update the selected when user changes
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingX: 2,
                            }}
                          >
                            <Box>
                              <b>{option.name}</b> <br />
                            </Box>
                            <Box>
                              Capacity : {option.capacity} / Material :{" "}
                              {option.material}
                            </Box>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label={
                              products?.length
                                ? "Search product"
                                : "No Products Found"
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off", // Disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Box>
                          <TextField
                            key={damagedQty}
                            autoFocus
                            value={damagedQty}
                            onChange={(e) =>
                              setDamagedQty(parseInt(e.target.value) || "")
                            }
                            size="small"
                            type="text"
                            label={"Quantity"}
                          />
                        </Box>
                        <Box>
                          {damagedQty && selectedProduct ? (
                            <Button
                              variant="contained"
                              onClick={addQrlessProducts}
                            >
                              Add
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box></Box>
      </Box>
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
          }}
        >
          {damagedProducts?.length ? (
            <Box>
              <Button
                onClick={() => setDamagedProducts([])}
                color="warning"
                variant="contained"
              >
                Clear
              </Button>
            </Box>
          ) : (
            <></>
          )}
          {damagedProducts?.length ? (
            <Box>
              <Button onClick={submitDamaged} variant="contained">
                Confirm
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ bgcolor: "#7f8c8d " }}>
                <TableCell
                  sx={{
                    border: "1.5px solid #d5d8dc ",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Product
                </TableCell>
                <TableCell
                  sx={{
                    border: "1.5px solid #d5d8dc ",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Owner
                </TableCell>
                <TableCell
                  sx={{
                    border: "1.5px solid #d5d8dc ",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Qr Code
                </TableCell>
                <TableCell
                  sx={{
                    border: "1.5px solid #d5d8dc ",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  sx={{
                    border: "1.5px solid #d5d8dc ",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Damage Type
                </TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {damagedProducts?.map((row) => {
                return (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.qualityDetails?.[0]?.container_details?.capacity}{" "}
                      {row.qualityDetails?.[0]?.container_details?.material}
                    </TableCell>
                    <TableCell align="center">
                      {row.ownerDetails?.[0]?.assigned_to_details?.fullname}
                      <br />({row.ownerDetails?.[0]?.assigned_to_details?.phone}
                      )
                    </TableCell>
                    <TableCell align="center">
                      {row.qualityDetails?.[0]?.qr_code}
                    </TableCell>
                    <TableCell align="center">
                      {row.filled ? (
                        <>
                          Filled : {row.filled}
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {row.empty ? <>Empty : {row.empty}</> : <></>}
                    </TableCell>
                    <TableCell align="center">
                      {row.reason == DAMAGE_REASON_CODES?.PhysicallyDamaged
                        ? "Physical Damage"
                        : "Qr/ Barcode"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default MarkDamage;
