import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  influencerData: [],
  requestedDetails: {},
};

const adminInfluencerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_INFLUENCER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        influencerData: action.payload.data,
      };
    case actionType.GET_INFLUENCER_REQUESTED_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        requestedDetails: action.payload.data,
      };
    case actionType.ENABLE_INFLUENCER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default adminInfluencerReducer;
