import actionType from "../Actions/actionType";
const initialState = {
  error: true,
  viewOrderDetailsMsg: "",
  allOrderDetailsMsg: "",
  orderDetails: {},
  allOrderDetails: [],
  newallOrderDetails: [],
  rejectType: {},
  rejectTypeMsg: "",
  getPinCode: [],
  getPinCodeMsg: "",
  assignSupplier: {},
  assignSupplierMsg: "",
  rejectReason: {},
  rejectReasonMsg: "",
  invoiceDetails: {},
  deliveries: [],
  cancelTypeMsg: "",
  cancelDetails: {},
  deliverySlots: "",
  time: {},
  slotsList: [],
  deliveryDateChangeMsg: "",
  getTransferCansOrderList: [],
  getTransferCansSupplierList: [],
  putTransferCansAcceptReject: {},
  pdwOrderDetails: { orders: [], order_summary: {} },
  pdwOrderSummary: { orders: {}, products: [] },
};
const ordersAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_PDW_ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        pdwOrderDetails: action.payload,
      };
    case actionType.GET_PDW_ORDER_SUMMARY_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        pdwOrderSummary: action.payload,
      };
    case actionType.GET_SEARCH_ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        allOrderDetails: action.payload.data,
        allOrderDetailsMsg: action.payload.msg,
      };
    case actionType.GET_NEW_SEARCH_ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        newallOrderDetails: action.payload.data,
      };
    case actionType.GET_VIEW_ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        viewOrderDetailsMsg: action.payload.msg,
        orderDetails: action.payload.order_details,
      };
    case actionType.CANCEL_REASON_ORDER:
      return {
        ...state,
        error: action.payload.error,
        cancelTypeMsg: action.payload.msg,
        cancelDetails: action.payload,
      };
    case actionType.GET_PINCODE_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        getPinCodeMsg: action.payload.msg,
        getPinCode: action.payload.data,
      };
    case actionType.PUT_REGULAR_ORDERS_SUPPLIER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.POST_SUPPLIER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        assignSupplierMsg: action.payload.msg,
        assignSupplier: action.payload,
      };
    case actionType.Regect_Reason_Details:
      return {
        ...state,
        error: action.payload.error,
        rejectReasonMsg: action.payload.msg,
        rejectReason: action.payload,
      };
    case actionType.DOWNLOAD_EMAIL_INVOICE:
      return {
        ...state,
        error: action.payload.error,
        invoiceDetails: action.payload,
      };
    case actionType.TO_GET_OTP:
      return {
        ...state,
        error: action.payload.error,
        deliveries: action.payload.deliveries,
      };
    case actionType.EDIT_ORDER_USER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_DELIVERY_SLOTS_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        slotsList: action.payload.data,
      };
    case actionType.GET_DELIVERY_SLOTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        deliverySlots: action.payload.data,
      };
    case actionType.ADD_DELIVERY_SLOTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        time: action.payload.time,
      };
    case actionType.CHANGE_DELIVERY_DATE:
      return {
        ...state,
        error: action.payload.error,
        deliveryDateChangeMsg: action.payload.msg,
      };
    case actionType.GET_SUPPLIER_TRANSFER_CANS_ORDER_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getTransferCansOrderList: action.payload.data,
      };
    case actionType.GET_SUPPLIER_TRANSFER_CANS_SUPPLIER_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getTransferCansSupplierList: action.payload.data,
      };
    case actionType.PUT_TRANSFER_CANS_ACCEPT_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        putTransferCansAcceptReject: action.payload.data,
      };
    case actionType.CANCEL_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.POST_FORCE_COMPLETE_SCANNING:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};
export default ordersAdminReducer;
