import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomConsole from "../../CustomConsole";
import {
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  DownloadEmailInvoice,
  DownloadInvoice,
  GetNewSearchOrderDetails,
  GetViewOrderDetails,
} from "../Redux/Actions/ordersAdminAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { UploadFile, getFiles } from "../Redux/Actions/fileHandlingAction";
import { UploadEditedDeliveryChallan } from "../Redux/Actions/wfsOrdersAction";
import { ToastContainer } from "react-toastify";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
const styles = {
  typographyStylesHeading: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  typographyStylesRes: {
    fontSize: "13px",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDetailsPaper3({ orderDetails, newOrderDetails }) {
  const dispatch = useDispatch();
  CustomConsole("---Order details paper 3---");
  //function for getting roleid
  let storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
  const uploadDeliveryChallanFile = React.useRef(null);
  CustomConsole("---Order details paper 3---");
  CustomConsole("---Order details paper 3---");
  const fileUploadDetails = useSelector((state) => state.fileHandler);
  CustomConsole(orderDetails);
  const invoiceResponse = useSelector(
    (state) => state.orderDetailsAdmin.invoiceDetails
  );
  const otpResponse = useSelector((state) => state.orderDetailsAdmin);
  const uploadStatus = useSelector((state) => state.wfsOrderDetails);
  const [loading, setLoading] = React.useState(false);
  const currentDate = new Date();
  CustomConsole(currentDate);
  const paymentType = [
    {
      value: "Prepaid",
      label: "$",
    },
    {
      value: "Postpaid",
      label: "€",
    },
  ];
  const [fromValue, setFromValue] = React.useState(newOrderDetails[0]?.order_date);
  const [toValue, setToValue] = React.useState(moment());
  const [radio, setRadio] = React.useState("");
  const [toastFlag, setToastFlag] = React.useState(false);
  const [otp, setOtp] = React.useState(null);
  const [editDeliveryChallanFlag, setEditDeliveryChallanFlag] =
    React.useState(false);
  const [confirmPODialog, setConfirmDialog] = React.useState(false);
  const handleChangeFrom = (newValue) => {
    CustomConsole(newValue);
    setFromValue(newValue);
  };

  const handleChangeTo = (newValue) => {
    setToValue(newValue);
  };

  const invoiceDownload = () => {
    const reqObj = {
      order_id: newOrderDetails[0]?.order_id,
      radioType: radio,
    };
    CustomConsole(reqObj);
    dispatch(DownloadEmailInvoice(reqObj));
    setToastFlag(true);
  };

  const downloadInvoice = () => {
    dispatch(DownloadInvoice(newOrderDetails[0]?.order_id));
  };
  React.useEffect(() => {
    CustomConsole(invoiceResponse);
    if (toastFlag === true && invoiceResponse.error === false) {
      toast.success(invoiceResponse.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (toastFlag === true && invoiceResponse.error === true) {
      toast.error(invoiceResponse.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [invoiceResponse]);

  React.useEffect(() => {
    if (otpResponse.deliveries[0]) {
      setOtp(otpResponse.deliveries[0].delivery_otp);
    }
  }, [otpResponse]);

  //function for handling upload button
  const handelUploadDeliveryChallan = () => {
    setConfirmDialog(true);
  };

  //function for triggering upload challan file function
  const deliveryChallanUpload = () => {
    setConfirmDialog(false);
    uploadDeliveryChallanFile.current.click();
  };

  //function for uploading delivery challan
  const uploadDeliveryChallan = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
      if (allowedExtensions.includes(fileExtension)) {
        dispatch(UploadFile(file));
        setEditDeliveryChallanFlag(true);
        setLoading(true);
      } else {
        toast.dark("Please upload a valid file type(e.g- image or pdf)", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
        e.target.value = "";
        setLoading(false);
      }
    }
  };

  //useEffect for uploading an edited delivery challan
  React.useEffect(() => {
    if (
      fileUploadDetails.fileName !== "" &&
      newOrderDetails[0]?.order_id !== undefined &&
      editDeliveryChallanFlag
    ) {
      dispatch(
        UploadEditedDeliveryChallan(
          newOrderDetails[0]?.order_id,
          fileUploadDetails.fileName
        )
      );
      setEditDeliveryChallanFlag(false);
      setTimeout(() => {
        // dispatch(GetViewOrderDetails(newOrderDetails[0]?.order_id));
        const reqObj = {
          delivery_from_date: moment().format("YYYY-MM-DD"),
          delivery_to_date: moment().format("YYYY-MM-DD"),
          page_size: 30,
          page_number: 1,
          order_ids: [newOrderDetails[0]?.order_id],
        };
        dispatch(GetNewSearchOrderDetails(reqObj));
      }, [1000]);
      setConfirmDialog(false);
      if (!fileUploadDetails.error) {
        setLoading(false);
      }
    }
  }, [fileUploadDetails.fileName]);

  //useEffect for uploading an edited delivery challan
  React.useEffect(() => {
    if (confirmPODialog) {
      setTimeout(() => {
        // dispatch(GetViewOrderDetails(newOrderDetails[0]?.order_id));
        const reqObj = {
          delivery_from_date: moment().format("YYYY-MM-DD"),
          delivery_to_date: moment().format("YYYY-MM-DD"),
          page_size: 30,
          page_number: 1,
          order_ids: [newOrderDetails[0]?.order_id],
        };
        dispatch(GetNewSearchOrderDetails(reqObj));
      }, [200]);
      setConfirmDialog(false);
      uploadStatus.deliveryFileUploadStatus = true;
    }
  }, [uploadStatus]);

  //useEfeect for handling disagree
  const handelDisAgree = () => {
    setConfirmDialog(false);
  };

  CustomConsole(storeRoleId);
  return (
    <Card elevation={20}>
      <CardContent>
        <Box
          sx={{
            bgcolor: "#152057",
            color: "white",
            padding: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Invoice Details
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Email invoice to
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={radio}
                onChange={(e) => {
                  CustomConsole(e.target.value);
                  setRadio(e.target.value);
                }}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Customer"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Supplier"
                />
                <FormControlLabel value="2" control={<Radio />} label="Both" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={invoiceDownload}
              variant="contained"
              size="small"
              sx={appColors.commonBtnColor}
            >
              Email Invoice
            </Button>
            <Button
              onClick={downloadInvoice}
              variant="contained"
              size="small"
              sx={appColors.commonBtnColor}
            >
              Download Invoice
            </Button>
            {newOrderDetails[0]?.payment_type === 300 ? (
              <Button
                onClick={() => {
                  // if (newOrderDetails[0]??.delivery_challan !== "") {
                  dispatch(getFiles(newOrderDetails[0]?.delivery_challan));
                  // }
                }}
                variant="contained"
                size="small"
                sx={appColors.commonBtnColor}
              >
                Download Delivery Challan
              </Button>
            ) : (
              ""
            )}
            {newOrderDetails[0]?.payment_type === 300 ? (
              <>
                {ADMIN_ROLES.BW_SUPERADMIN === storeRoleId ||
                ADMIN_ROLES.BW_ADMIN === storeRoleId ||
                ADMIN_ROLES.BW_CSHEAD === storeRoleId ||
                ADMIN_ROLES.BW_CSLEAD === storeRoleId ||
                ADMIN_ROLES.BW_OPSHEAD === storeRoleId ||
                ADMIN_ROLES.BW_CS === storeRoleId ? (
                  <Box>
                    <LoadingButton
                      size="small"
                      endIcon={<FileUploadIcon />}
                      loading={loading}
                      // sx={appColors.commonBtnColor}
                      onClick={handelUploadDeliveryChallan}
                      loadingPosition="end"
                      variant="contained"
                    >
                      <span> Upload Delivery Challan</span>
                    </LoadingButton>
                  </Box>
                ) : null}
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </CardContent>
      <ToastContainer />
      <Dialog
        open={confirmPODialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handelDisAgree}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Re-upload Deliver Challan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelDisAgree}>Cancel</Button>
          <>
            <input
              style={{ display: "none" }}
              type="file"
              ref={uploadDeliveryChallanFile}
              onChange={uploadDeliveryChallan}
            />
            <Button onClick={deliveryChallanUpload}>Upload</Button>
          </>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
