export const styles = {
  cardStyleHeading: {
    color: "#03a5fc",
    fontWeight: "bold",
    // padding: "5px",
    fontFamily: "'Montserrat', sans-serif",
  },
  cardStyleValue: {
    marginLeft: "10px",
    color: "black",
    fontWeight: "500",
    textAlign: "right",
  },
};

export const Orderstyles = {
  orderCardBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "1rem",
  },
  orderCardHearder: {
    height: 72,
    backgroundColor: "#152057",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    padding: "1rem",
  },
  orderCard: {
    width: { xs: 300 },
    borderRadius: ".9rem",
    boxShadow: 10,
    backgroundColor: "#fff",
  },
  cardImageStyle: { marginRight: ".5rem", marginLeft: "1rem", width: "2rem" },
  BoxInsideContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  labelDesign: {
    backgroundColor: "white",
    width: "25px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    transform: "rotate(-53deg)",
    boxShadow: 5,
    borderRadius: "0px 10px 10px 10px ",
  },
  labelText: {
    whiteSpace: "nowrap",
    transform: "rotate(90deg)",
    fontSize: ".8rem",
    fontWeight: "bold",
    marginBottom: ".8rem",
    color: "#152057",
    marginRight: ".2rem",
  },
  AddressBox: {},
  TypograpyCommon1: {
    color: "gray",
    fontSize: ".9rem",
    fontWeight: "500",
  },
  TypograpyCommon2: { color: "gray", fontSize: ".8rem" },
  payButton: {
    backgroundColor: "#30a7ff",
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderTopRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    height: "25px",
    "&: hover": {
      backgroundColor: "#2FEAC5 ",
    },
  },
  cancelButton: {
    backgroundColor: "#ef7272",
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderTopRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    height: "25px",
    "&: hover": {
      backgroundColor: "#2FEAC5 ",
    },
  },
  viewButton: {
    backgroundColor: "#068fff",
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderTopRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    height: "25px",
    "&: hover": {
      backgroundColor: "#2FEAC5 ",
    },
  },
  orderFixed: {},
};
