import React, { useCallback } from "react";
import * as Material from "@mui/material";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { styles } from "./cardStyles";
import moment from "moment";
import BorderColor from "@mui/icons-material/BorderColor";
import Paper from "@mui/material/Paper";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import QrBarcodeLinkingDialog from "../AdminGenrateQRBarcode/QrBarcodeLinkingDialog";
import {
  GetProductCanColor,
  InScanEmptyCans,
  OutScanFilledCans,
} from "../Redux/Actions/adminQrBarcodeAction";

import EnterOTPDialog from "./EnterOTPDialog";
import {
  GetWfsOngoingOrders,
  PutEditEmptyCanOrder,
} from "../Redux/Actions/wfsOrdersAction";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  CheckCircle,
  Clear,
  ConnectingAirportsOutlined,
  DocumentScanner,
  HighlightOff,
  MarkEmailRead,
  PropaneRounded,
} from "@mui/icons-material";
import {
  ClearValidatedData,
  LinkInscanEmptyValidCans,
  LinkOutscanEmptyValidCans,
  LinkValidCans,
  ValidateExpired,
  // ValidateScannedCodes,
} from "../Redux/Actions/wfsBatchLinkingAction";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { reflow } from "@mui/material/transitions/utils";
import WfsOrderCameraCanSwapDialog from "./WfsOrderCameraCanSwapDialog";
import ApprovedOrRejectedCansCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ApprovedOrRejectedCansCanSwapDialog";
import DeliveriesScanInValidCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/DeliveriesScanInValidCansDialog";
import { GetQrBarcodeSwapList } from "../Redux/Actions/canSwapingAction";
import CanSwapOrderRequestDetailsDialog from "../../Components/DialogBoxes/CanSwapOrderRequestDetailsDialog";
import EditEmptyCanOrdersDialog from "./EditEmptyCanOrdersDialog";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import { styled } from "@mui/material/styles";
import WfsCrateScanDialog from "./wfsCrateScanDialog";
import {
  CrateInscanSku,
  CrateOutscanSku,
  QrlessProductInScan,
  QrlessProductOutScan,
} from "../Redux/Actions/skuCrateScan";
import EmptyCanOrderEdit from "../../Components/DialogBoxes/emptyCanEditOrder";
import { Stack } from "@mui/system";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ScannerRoundedIcon from "@mui/icons-material/ScannerRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import LinkOrderToOrderScanningProcessDialog from "../../Components/Dialog/LinkOrderToOrderScanningProcessDialog";
import {
  ClearDataRefersh,
  LinkProductsOrderToOrder,
} from "../Redux/Actions/supliersAction";
import ScanningDialog from "../../Components/Custom/ScanningDialog";
import Slide from "@mui/material/Slide";
import ProductCapColorDialog from "../WfsBatch/ProductCapColorDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WfsSingleOrder() {
  const isBatch = false;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const skuWfsDetails = useSelector(
    (state) => state.skuWfsDetails?.emptyCanDetails?.[0] || {}
  );
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(skuWfsDetails);
  const emptyCanOtp = useSelector((state) => state.skuWfsDetails);
  const scannedResult = useSelector((state) => state.scanCrateDetails);
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");

  const wfsOrderDetails = useSelector((state) => state.wfsOrderDetails);
  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);
  CustomConsole(wfsOrderDetails.orderIdDetails);
  const wfsDetails = useSelector((state) => state.emptyCanOrderData);
  CustomConsole(wfsDetails.orderIdDetails);
  const adminQrBarcodeData = useSelector((state) => state.adminQrBarcodeData);
  const validatedData = useSelector((state) => state.wfsBatchBulkLinking);

  const [isCameraOpen, setIsCameraOpen] = React.useState(false);
  const [openOrderToOrderScanningDialog, setOpenOrderToOrderScanningDialog] =
    React.useState("");
  const [openScanInValidCsnsDialog, setOpenScanInValidCsnsDialog] =
    React.useState(false);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [scannedQrCrateCode, setScannedQrCrateCode] = React.useState("");
  const [qrLessProductqty, setQrLessProductqty] = React.useState("");
  const [scanCount, setScanCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [btnName, setbtnName] = React.useState("");
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [openEditEmptyCanOrderDialog, setOpenEditEmptyCanOrderDialog] =
    React.useState(false);
  const [link, setLink] = React.useState(false);
  const [scanOption, setScanOption] = React.useState("scanner");
  const [value, setValue] = React.useState(0);
  const [orderQtySum, setOrderQtySum] = React.useState(0);
  const [openVerifyOtpDialog, setOpenVerifyOtpDialog] = React.useState(false);
  const [ordersPincodeSwitch, setOrdersPincodeSwitch] = React.useState(false);
  const [orderIdStartingLetters, setOrderIdStartingLetters] =
    React.useState("");
  const [scanOrderId, setScanOrderId] = React.useState("");
  // Bulk scanning
  const [currentScanned, setCurrentScanned] = React.useState({});
  const [cameraScannedCodes, setCameraScannedCodes] = React.useState([]);
  const [switchScanning, setSwitchScanning] = React.useState(false);
  const [openSwappedCanDialog, setOpenSwappedCanDialog] = React.useState(false);
  const [scannedCount, setScannedCount] = React.useState(0);

  const [decodedText, setDecodedText] = React.useState("");
  const [maxQtyScan, setMaxQtyScan] = React.useState(0);

  const [locallyScannedStored, setLocallyScannedStored] = React.useState([]);

  const [codesToVerify, setCodesToVerify] = React.useState([]);

  const [invalidData, setInvalidData] = React.useState(() =>
    localStorage.getItem("invalid_data") &&
    localStorage.getItem("invalid_data").length
      ? JSON.parse(localStorage.getItem("invalid_data"))
      : []
  );
  const [otherValidData, setOtherValidData] = React.useState(0);
  const [validData, setValidData] = React.useState(() =>
    localStorage.getItem("valid_data") &&
    localStorage.getItem("valid_data").length
      ? JSON.parse(localStorage.getItem("valid_data"))
      : []
  );
  const [validDataToApi, setValidDataToApi] = React.useState([]);

  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);
  const [openApprovedRejectedCans, setOpenApprovedRejectedCans] =
    React.useState(false);
  const [crateBtnName, setCrateBtnName] = React.useState("");
  const [returnQtySum, setReturnQtySum] = React.useState(0);
  const [submittedQtySum, setSubmittedQtySum] = React.useState(0);
  const [isCrateCameraOpen, setIsCrateCameraOpen] = React.useState(false);
  const [scanCrateOption, setScanCrateOption] = React.useState("scanner");
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [qrBarcodeIdBulk, setQrBarcodeIdBulk] = React.useState("");

  const [qrBarcodeIdCrate, setQrBarcodeIdCrate] = React.useState("");
  const [crateProductQty, setCrateProductQty] = React.useState();
  const [crateQty, setCrateQty] = React.useState(0);

  // Scanning new
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);

  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);

  const [canColorResp, setCanColorResp] = React.useState({
    error: true,
    msg: "",
  });
  const [openProductCapColorDialog, setOpenProductCapColorDialog] =
    React.useState(false);
  React.useEffect(() => {
    dispatch(
      GetQrBarcodeSwapList({
        status: 200,
        page_number: 1,
        page_size: 100,
        order_id: skuWfsDetails?.ec_order_id,
      })
    );
  }, []);

  const loadData = () => {
    //@Removed/console.log("Change in order id");
    // Assume `props.orderId` is the current order ID we need to check against
    const storedData = localStorage.getItem("current_scanned_codes");
    const data = storedData ? JSON.parse(storedData) : {};
    //@Removed/console.log("storedData");
    //@Removed/console.log(storedData);

    // Check if the current order ID from props matches the one stored in local storage
    if (data && data[skuWfsDetails?.ec_order_id]) {
      //@Removed/console.log("matching");
      setCurrentScanned(data);
      setScannedCount(data[skuWfsDetails?.ec_order_id].length);
    } else {
      // If not matching or not existing, reset the scanned codes
      //@Removed/console.log("not matching");
      setCurrentScanned({});
      setScannedCount(0);
      if (localStorage.getItem("current_scanned_codes")) {
        localStorage.removeItem("current_scanned_codes");
      }
    }
  };
  React.useEffect(() => {
    CustomConsole("currentScanned");
    CustomConsole(currentScanned);
    //@Removed/console.log(skuWfsDetails?.ec_order_id);
    // if (
    //   currentScanned[skuWfsDetails?.ec_order_id] &&
    //   currentScanned[skuWfsDetails?.ec_order_id].length > 0
    // ) {

    //   setScannedCount(currentScanned[skuWfsDetails?.ec_order_id].length);
    // }
    // const current_scanned =
    //   localStorage.getItem("current_scanned_codes") &&
    //   localStorage.getItem("current_scanned_codes").length
    //     ? JSON.parse(localStorage.getItem("current_scanned_codes"))
    //     : [];
    // console.log("current scanned in local");
    // console.log(current_scanned);
    //@Removed/console.log("currentScanned && Object.keys(currentScanned).length: ");
    //@Removed/console.log(currentScanned && Object.keys(currentScanned).length);
    if (currentScanned && Object.keys(currentScanned).length) {
      //@Removed/console.log("Setting the data in local storage!");
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScanned)
      );
    }
  }, [currentScanned]);

  React.useEffect(() => {
    CustomConsole("----after scanning----");
    CustomConsole(adminQrBarcodeData);
  }, [adminQrBarcodeData]);

  const handleLinkValidCans = () => {
    if (btnName === "Start Inscan") {
      const reqObj = {
        is_inscan: true,
        order_id: skuWfsDetails?.ec_order_id,
        data: validDataToApi,
      };
      dispatch(LinkInscanEmptyValidCans(reqObj));
      setTimeout(() => {
        dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
        dispatch(ClearValidatedData());
      }, 1000);
    } else if (btnName === "Start Outscan") {
      const reqObj = {
        is_inscan: false,
        order_id: skuWfsDetails?.ec_order_id,
        data: validDataToApi,
      };
      dispatch(LinkOutscanEmptyValidCans(reqObj));
      setTimeout(() => {
        dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
        dispatch(ClearValidatedData());
      }, 1000);
    }
    setTimeout(() => {
      setCurrentScanned({});
      setValidData([]);
      setInvalidData([]);
      setValidDataToApi([]);
      localStorage.removeItem("valid_data");
      localStorage.removeItem("invalid_data");
      localStorage.removeItem("current_scanned_codes");
    }, 1000);
  };

  //useEffect for checking inscan and outscan for product

  const [containerCapacity, setContainerCapacity] = React.useState("");
  const [containerMaterial, setContainerMaterial] = React.useState("");

  React.useEffect(() => {
    setContainerCapacity(skuWfsDetails?.order_details?.container_capacity);
    setContainerMaterial(skuWfsDetails?.order_details?.container_material);
    if (
      skuWfsDetails?.order_details?.is_submit_started === true &&
      skuWfsDetails?.order_details?.is_submit_finished === false
    ) {
      const pending_crate_inscan = skuWfsDetails?.order_details?.crates?.filter(
        (item) => item.return_qty != item.submitted_qty
      );
      if (
        skuWfsDetails?.is_admin === true ||
        skuWfsDetails?.order_details?.return_qty >
          skuWfsDetails?.order_details?.inscanned_cans
      ) {
        setbtnName("Start Inscan");
        setMaxQtyScan(
          skuWfsDetails?.order_details?.return_qty -
            skuWfsDetails?.order_details?.inscanned_cans
        );
        setScanQtyMax(
          skuWfsDetails?.order_details?.return_qty -
            skuWfsDetails?.order_details?.inscanned_cans
        );
      } else if (
        !pending_crate_inscan.length &&
        skuWfsDetails?.order_details?.return_qty ===
          skuWfsDetails?.order_details?.inscanned_cans &&
        skuWfsDetails?.order_details?.is_submit_finished === false
      ) {
        setbtnName("Start Outscan");
        setMaxQtyScan(
          skuWfsDetails?.order_details?.order_qty -
            skuWfsDetails?.order_details?.submitted_return_cans -
            skuWfsDetails?.order_details?.outscanned_cans
        );
        setScanQtyMax(
          skuWfsDetails?.order_details?.order_qty -
            skuWfsDetails?.order_details?.submitted_return_cans -
            skuWfsDetails?.order_details?.outscanned_cans
        );
      }
    } else if (
      skuWfsDetails?.order_details?.is_submit_finished === true &&
      skuWfsDetails?.order_details?.is_crate_submission_finished === true
    ) {
      setbtnName("Verify OTP");
      setIsCameraOpen(false);
    } else {
      setbtnName("");
      setIsCameraOpen(false);
    }
  }, [skuWfsDetails, submittedQtySum, returnQtySum, scannedResult]);

  //useEffect for checking inscan and outscan for crate
  React.useEffect(() => {
    if (
      skuWfsDetails?.order_details?.is_crate_submission_finished === false &&
      skuWfsDetails?.order_details?.is_submit_started === true
    ) {
      const pending_products_inscan =
        skuWfsDetails?.order_details?.inscanned_cans !=
        skuWfsDetails?.order_details?.return_qty;

      if (returnQtySum > submittedQtySum) {
        setCrateBtnName("Start Inscan");
      } else if (
        !pending_products_inscan &&
        returnQtySum === submittedQtySum &&
        orderQtySum > 0
      ) {
        setCrateBtnName("Start Outscan");
      }
    } else {
      setCrateBtnName("");
    }
  }, [skuWfsDetails, submittedQtySum, returnQtySum, scannedResult]);

  //useEffect for calculating inscan and outscan
  React.useEffect(() => {
    const calculateSums = () => {
      const returnQtySum = skuWfsDetails?.order_details?.crates?.reduce(
        (sum, crate) => sum + crate.return_qty,
        0
      );
      const submittedQtySum = skuWfsDetails?.order_details?.crates?.reduce(
        (sum, crate) => sum + crate.submitted_qty,
        0
      );
      const orderCrateSum = skuWfsDetails?.order_details?.crates?.reduce(
        (sum, crate) => sum + crate.order_qty,
        0
      );
      setReturnQtySum(returnQtySum);
      setSubmittedQtySum(submittedQtySum);
      setOrderQtySum(orderCrateSum);
    };
    calculateSums();
  }, [skuWfsDetails]);

  React.useEffect(() => {
    if (scannedQrCode !== "" || scannedBarCode !== "") {
      CustomConsole("-----------in Scan-----------------");
      if (btnName === "Start Inscan") {
        CustomConsole("-----------getting here 0-----------------");
        const reqObj = {
          is_inscan: true,
          order_id: skuWfsDetails?.ec_order_id,
          data: [{ id: scannedQrCode || scannedBarCode }],
        };
        CustomConsole(reqObj);
        dispatch(LinkInscanEmptyValidCans(reqObj));
        setScannedQrCode("");
        setScannedBarCode("");
        setLinkingDialogOpen(false);
        setScanCount(0);
        setDecodedResults([]);
        setLink(false);
        if (scanOption === "camera") {
          setIsCameraOpen(false);
        }
        setTimeout(() => {
          dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
        }, 1000);
      } else if (btnName === "Start Outscan") {
        // const reqObj = {
        //   order_id: orderId,
        //   qr_id: scannedQrCode,
        // };
        // CustomConsole("getting here 1");
        // dispatch(OutScanFilledCans(reqObj));
        const reqObj = {
          is_inscan: false,
          order_id: skuWfsDetails?.ec_order_id,
          data: [{ id: scannedQrCode || scannedBarCode }],
        };
        CustomConsole(reqObj);
        dispatch(LinkOutscanEmptyValidCans(reqObj));
        setScannedQrCode("");
        setScannedBarCode("");
        setLinkingDialogOpen(false);
        setScanCount(0);
        setDecodedResults([]);
        setLink(false);
        if (scanOption === "camera") {
          setIsCameraOpen(false);
        }
        setTimeout(() => {
          dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
        }, 1000);
      }
    }
  }, [scannedQrCode, scannedBarCode]);

  const startScanning = (details) => {
    //@Removed/console.log(containerCapacity);
    //@Removed/console.log(containerMaterial);
    //@Removed/console.log(btnName);
    //@Removed/console.log("details");
    //@Removed/console.log(details);
    // setOrderId(orderId)
    if (btnName === "Start Inscan") {
      if (
        (details.order_details.is_qr === true && details.is_admin) ||
        (details.order_details.container_material === "PET" &&
          details.order_details.container_capacity === "20_Liters")
      ) {
        setOpenScanningDialog(true);
        dispatch(ValidateExpired());
      } else {
        setQrLessProductDialog(true);
      }
    } else if (btnName === "Start Outscan") {
      if (
        details.order_details.is_qr === true ||
        (details.order_details.container_material === "PET" &&
          details.order_details.container_capacity === "20_Liters")
      ) {
        setOpenScanningDialog(true);
        // dispatch(ValidateExpired());
      } else {
        setQrLessProductDialog(true);
      }
    } else if (btnName === "Verify OTP") {
      setOpenVerifyOtpDialog(true);
    }
  };

  React.useEffect(() => {
    //@Removed/console.log("switchScanning");
    //@Removed/console.log(switchScanning);
    if (switchScanning) {
      loadData();
      setSwitchScanning(false);
    }
  }, [switchScanning]);

  //function for crate scanning option
  const StartCrateScanning = (orderId, type) => {
    CustomConsole("type");
    CustomConsole(btnName);
    CustomConsole(orderId);
    // setOrderId(orderId);
    setIsCrateCameraOpen(!isCrateCameraOpen);
    // setCrateProductQty()
  };

  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);

    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }

    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  const onScanAll = (decodedText) => {
    setDecodedText(decodedText);
  };

  const handleOpenLinkOrderToOrderDialog = (props) => {
    setOrderIdStartingLetters(props);
    setOpenOrderToOrderScanningDialog(true);
  };

  const handleLinkOrder = (props) => {
    const reqObj = {
      from_order_id: orderIdStartingLetters + props.scanOrderId,
      to_order_id: skuWfsDetails?.ec_order_id,
    };
    CustomConsole(reqObj);
    dispatch(LinkProductsOrderToOrder(reqObj));
    setOpenOrderToOrderScanningDialog(false);
    // setTimeout(() => {
    //   dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
    // }, 1500);
  };
  React.useEffect(() => {
    // if (!cameraScannedCodes.includes(decodedText) && decodedText !== "") {
    if (decodedText !== "") {
      setCameraScannedCodes((prev) => [...prev, decodedText]);
      setTimeout(() => {
        setDecodedText("");
      }, 300);
    }
    // }
  }, [decodedText]);

  React.useEffect(() => {
    //@Removed/console.log("btnName");
    //@Removed/console.log(btnName);
    if (btnName === "Start Outscan") {
      //@Removed/console.log("max Qty check");
      //@Removed/console.log(
      //   skuWfsDetails?.order_details?.order_qty -
      //     skuWfsDetails?.order_details?.submitted_return_cans -
      //     skuWfsDetails?.order_details?.outscanned_cans
      // );
      setMaxQtyScan(
        skuWfsDetails?.order_details?.order_qty -
          skuWfsDetails?.order_details?.submitted_return_cans -
          skuWfsDetails?.order_details?.outscanned_cans
      );
    } else if (btnName === "Start Inscan") {
      //@Removed/console.log("max Qty check");
      //@Removed/console.log(
      //   skuWfsDetails?.order_details?.return_qty -
      //     skuWfsDetails?.order_details?.inscanned_cans
      // );
      setMaxQtyScan(
        skuWfsDetails?.order_details?.return_qty -
          skuWfsDetails?.order_details?.inscanned_cans
      );
    }
  }, [btnName, skuWfsDetails]);

  React.useEffect(() => {
    //@Removed/console.log("cameraScannedCodes");
    //@Removed/console.log(cameraScannedCodes);
    // const newOrderId = skuWfsDetails?.ec_order_id;
    const code = cameraScannedCodes[cameraScannedCodes.length - 1];
    const state = skuWfsDetails && skuWfsDetails.ec_order_id ? true : false;
    if (cameraScannedCodes.length) {
      if (state === true) {
        //@Removed/console.log("-- Inside main state ---");
        //@Removed/console.log(code);
        const newOrderId = skuWfsDetails?.ec_order_id;
        // if (currentScanned && Object.keys(currentScanned).length) {
        //@Removed/console.log("currentScanned in scanner: ");
        //@Removed/console.log(currentScanned);
        //@Removed/console.log(currentScanned[newOrderId]);
        let qrCodeList =
          currentScanned &&
          Object.keys(currentScanned).length &&
          currentScanned[newOrderId]
            ? currentScanned[newOrderId]
            : [];
        //@Removed/console.log("qrCodeList: ");
        //@Removed/console.log(qrCodeList);
        if (qrCodeList.length && qrCodeList.includes(code)) {
          toast.error(`Can is scanned already!`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          //@Removed/console.log("qrCodeList.length <= maxQtyScan: ");
          //@Removed/console.log(qrCodeList.length <= maxQtyScan);
          if (qrCodeList.length < maxQtyScan) {
            qrCodeList = [...qrCodeList, code];
            setScannedCount(qrCodeList.length);
            let localStorageData = {};
            localStorageData[newOrderId] = qrCodeList;
            //@Removed/console.log("localStorageData: ");
            //@Removed/console.log(localStorageData);
            setCurrentScanned(() => localStorageData);

            toast.success("Successfully Scanned!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            // alert("WFS Order limit reached!");
            //@Removed/console.log("WFS Order limit reached!");
            toast.error(`Scan limit reached, please click submit button`, {
              position: "top-right",
              autoClose: 3500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      }
    }
  }, [cameraScannedCodes]);
  React.useEffect(() => {
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "" && btnName !== "Start Outscan") {
      setLinkingDialogOpen(true);
      // setIsCameraOpen(false);
    }

    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setIsCameraOpen(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);

      setScannedQrCode(qrBarcodeId);

      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
        setQrBarcodeIdBulk("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);

  const [qrBarcodeIdPrevBulk, setQrBarcodeIdPrevBulk] = React.useState("");
  React.useEffect(() => {
    //@Removed/console.log("qrBarcodeIdBulk");
    //@Removed/console.log(qrBarcodeIdBulk.length);
    //@Removed/console.log("qrBarcodeIdPrevBulk: ");
    //@Removed/console.log(qrBarcodeIdPrevBulk);
    //@Removed/console.log(qrBarcodeIdBulk && qrBarcodeIdBulk.length > 0);
    const state =
      qrBarcodeIdBulk &&
      qrBarcodeIdBulk.length > 0 &&
      skuWfsDetails &&
      skuWfsDetails.ec_order_id
        ? true
        : false;
    //@Removed/console.log("state: ", state);
    if (state === true) {
      //@Removed/console.log("-- Inside main state ---");
      //@Removed/console.log(qrBarcodeIdBulk);
      const newOrderId = skuWfsDetails?.ec_order_id;
      // if (currentScanned && Object.keys(currentScanned).length) {
      //@Removed/console.log("currentScanned in scanner: ");
      //@Removed/console.log(currentScanned);
      //@Removed/console.log(currentScanned[newOrderId]);
      let qrCodeList =
        currentScanned &&
        Object.keys(currentScanned).length &&
        currentScanned[newOrderId]
          ? currentScanned[newOrderId]
          : [];
      //@Removed/console.log("qrCodeList: ");
      //@Removed/console.log(qrCodeList);
      if (qrCodeList.length && qrCodeList.includes(qrBarcodeIdBulk)) {
        toast.error(`Can is scanned already!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        //@Removed/console.log("qrCodeList.length <= maxQtyScan: ");
        //@Removed/console.log(qrCodeList.length <= maxQtyScan);
        if (qrCodeList.length < maxQtyScan) {
          qrCodeList = [...qrCodeList, qrBarcodeIdBulk];
          setScannedCount(qrCodeList.length);
          let localStorageData = {};
          localStorageData[newOrderId] = qrCodeList;
          //@Removed/console.log("localStorageData: ");
          //@Removed/console.log(localStorageData);
          setCurrentScanned(() => localStorageData);

          toast.success("Successfully Scanned!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          //@Removed/console.log("WFS Order limit reached!");
          toast.error(`Scan limit reached, please click submit button`, {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
      setTimeout(() => {
        setQrBarcodeIdBulk("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [
    qrBarcodeIdBulk,
    // skuWfsDetails?.ec_order_id,
    // selectedbatch.quantity,
    // setCurrentScanned,
  ]);

  const handleQrBarcodeIdFromScanner = (event) => {
    //@Removed/console.log("scanned Code");
    //@Removed/console.log(event.target.value);
    setQrBarcodeId(event.target.value);
    if (value === 1) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdNew) {
          CustomConsole("-- set data --");
          setQrBarcodeIdNew(event.target.value);
        } else {
          CustomConsole("--- data is set ---");
        }
      }, 500);
    }

    if (value === 0) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdBulk) {
          CustomConsole("set code for bulk");
          setQrBarcodeIdBulk(event.target.value);
        } else {
          CustomConsole("---code not set for bulk ---");
        }
      }, 500);
    }
  };

  const handleVerifyScanned = () => {
    if (btnName === "Start Inscan") {
      const reqObj = {
        is_inscan: true,
        order_id: skuWfsDetails?.ec_order_id,
        data: codesToVerify,
      };
      CustomConsole(reqObj);
      // dispatch(ValidateScannedCodes(reqObj));
      setTimeout(() => {
        dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      }, 1000);
    } else if (btnName === "Start Outscan") {
      const reqObj = {
        is_inscan: false,
        order_id: skuWfsDetails?.ec_order_id,
        data: codesToVerify,
      };
      CustomConsole(reqObj);
      // dispatch(ValidateScannedCodes(reqObj));
      setTimeout(() => {
        dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      }, 1000);
    }
  };

  React.useEffect(() => {
    CustomConsole("validatedData");
    CustomConsole(validatedData);
    if (validatedData?.error === false) {
      // setValidData(validatedData?.validatedData?.user_valid_data);
      setValidateExpired(validatedData?.validatedExpired);
      setIsCameraOpen(false);
      setOtherValidData(
        validatedData?.validatedDataOrder?.other_valid_data?.length
      );
    }
  }, [validatedData]);

  React.useEffect(() => {
    //@Removed/console.log("validateExpired");
    //@Removed/console.log(validateExpired);
  }, [validateExpired]);

  React.useEffect(() => {
    //@Removed/console.log("otherValidData");
    //@Removed/console.log(otherValidData);
    if (otherValidData > 0) {
      setOpenSwappedCanDialog(true);
    } else {
      setOpenSwappedCanDialog(false);
    }
  }, [otherValidData]);

  React.useEffect(() => {
    const vd = validData ? JSON.stringify(validData) : [];
    const ivd = validData ? JSON.stringify(invalidData) : [];
    const ovd = validData ? JSON.stringify(otherValidData) : [];
  }, [validData, invalidData, otherValidData]);

  React.useEffect(() => {
    CustomConsole("ValidData");
    CustomConsole(validData);
    const transformedArray = validData?.map((item) => ({
      id: item.id,
    }));
    setValidDataToApi(transformedArray);
  }, [validData]);

  React.useEffect(() => {
    CustomConsole("validDataToApi");
    CustomConsole(validDataToApi);
  }, [validDataToApi]);

  const [ownerDetails, setOwnerDetails] = React.useState([]);

  React.useEffect(() => {
    CustomConsole("ownerDetails");
    CustomConsole(ownerDetails);
  }, [ownerDetails]);
  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
      // setUnMountFlag(true)
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setScanQrFlag(false)
    }
  };
  React.useEffect(() => {
    dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
  }, []);
  CustomConsole(orderId);

  const handleCloseDialog = () => {
    setIsCameraOpen(false);
  };
  const handleBack = () => {
    const url = `/dashboard/wfsDetails`;
    history.push(url);
  };

  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    setValue(newValue);
  };

  React.useEffect(() => {
    CustomConsole("----scanOption----");
    CustomConsole(scanOption);
  }, [scanOption]);

  const handleOrdersPincodeSwitch = (event) => {
    setOrdersPincodeSwitch(event.target.checked);
  };

  const handleScanInValidCans = () => {
    setOpenScanInValidCsnsDialog(true);
  };
  const handleSendRequest = (props) => {
    setOpenScanInValidCsnsDialog(false);
    const reqObj = {
      scan_type: props.scan_type,
      qr_code: props.qr_code,
      order_id: orderId,
    };
    CustomConsole(reqObj);
  };
  const handleCloseDialogInvalidCans = () => {
    setOpenScanInValidCsnsDialog(false);
  };

  const handleApprovedOrRejectedCans = () => {
    if (
      skuWfsDetails &&
      skuWfsDetails?.ec_order_id &&
      skuWfsDetails?.ec_order_id.length
    ) {
      setOpenApprovedRejectedCans(true);
    }
  };

  const handleCloseApprovedRejectedDialog = () => {
    setOpenApprovedRejectedCans(false);
    dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
  };
  const handleLinkApprovedQrs = (props) => {
    CustomConsole("-- handleLinkApprovedQrs --");
    CustomConsole(props);
    if (props.approved_empty.length) {
      //Link the approved empty
      let approvedEmpty = [];
      for (const data of props.approved_empty) {
        approvedEmpty.push({
          id: data,
        });
      }
      const reqObj = {
        is_inscan: true,
        order_id: props.order_id,
        data: approvedEmpty,
      };
      CustomConsole(reqObj);
      dispatch(LinkInscanEmptyValidCans(reqObj));
    }
    if (props.approved_filled.length) {
      let approvedFilled = [];
      for (const data of props.approved_filled) {
        approvedFilled.push({
          id: data,
        });
      }
      //Link the approved filled
      const reqObj = {
        is_inscan: false,
        order_id: props.order_id,
        data: approvedFilled,
      };
      CustomConsole(reqObj);
      dispatch(LinkOutscanEmptyValidCans(reqObj));
    }
  };
  const handleEditEmptyCan = () => {
    setOpenEditEmptyCanOrderDialog(true);
  };
  const handleClose = () => {
    setOpenEditEmptyCanOrderDialog(false);
  };
  const handleSubmitCans = (props) => {
    CustomConsole("--------handleSubmitCans-------------");
    const reqObj = {
      order_id: props.ec_order_id,
      order_qty: props.orderQty + props.emptyCanQty,
      empty_qty: props.emptyCanQty,
    };
    CustomConsole(reqObj);
    dispatch(PutEditEmptyCanOrder(reqObj));
    setOpenEditEmptyCanOrderDialog(false);
  };

  CustomConsole(skuWfsDetails);
  //function for setting crate close dialog
  const handleCloseCrateScanDialog = () => {
    setIsCrateCameraOpen(false);
  };

  //function for handling qr dialog
  const handleQrBarcodeIdFromScannerCrate = (event) => {
    let value = event.target.value;
    setScannedQrCrateCode((prevValues) => [...prevValues, value]);
  };
  //function for sumbmitting crate inscan outscan
  const handleSubmitCrateScan = (e) => {
    e.preventDefault();
    const concatenatedValues = scannedQrCrateCode.join("");
    if (scannedQrCrateCode !== "") {
      if (crateBtnName === "Start Inscan") {
        const reqObj = {
          order_id: skuWfsDetails?.ec_order_id,
          data: [
            {
              id: concatenatedValues,
              product_qty: crateQty,
            },
          ],
        };
        dispatch(CrateInscanSku(reqObj));
        setScannedQrCrateCode("");
        if (scanCrateOption !== "camera") {
          setIsCrateCameraOpen(false);
        }
      } else if (crateBtnName === "Start Outscan") {
        const reqObj = {
          is_inscan: false,
          order_id: skuWfsDetails?.ec_order_id,
          data: [{ id: concatenatedValues }],
        };
        dispatch(CrateOutscanSku(reqObj));
        setScannedQrCrateCode("");
        if (scanCrateOption !== "camera") {
          setIsCrateCameraOpen(false);
        }
      }
    }
  };

  //function for adding scan qr through camera
  const onNewScanCateResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (true) {
      if (decodedResult.result.format.formatName === "QR_CODE") {
        setScannedQrCrateCode(decodedText);
      }
      if (decodedResult.result.format.formatName === "CODE_128") {
        setScannedQrCrateCode(decodedText);
      }
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  //function for handel submit crate scan
  const handelCameraSubmit = () => {
    CustomConsole(scannedQrCrateCode);
  };

  //useEffect for handling crate inscan and outscan
  React.useEffect(() => {
    if (scannedQrCrateCode !== "" && scanCrateOption === "camera") {
      if (crateBtnName === "Start Inscan") {
        CustomConsole("-----------getting here 0-----------------");
        const reqObj = {
          order_id: skuWfsDetails?.ec_order_id,
          data: [
            {
              id: scannedQrCrateCode,
              product_qty: crateQty,
            },
          ],
        };
        dispatch(CrateInscanSku(reqObj));
        setScannedQrCrateCode("");
        if (scanCrateOption === "camera") {
          setIsCrateCameraOpen(false);
        }
      } else if (crateBtnName === "Start Outscan") {
        const reqObj = {
          is_inscan: false,
          order_id: skuWfsDetails?.ec_order_id,
          data: [{ id: scannedQrCrateCode }],
        };
        dispatch(CrateOutscanSku(reqObj));
        setScannedQrCrateCode("");
        if (scanCrateOption === "camera") {
          setIsCrateCameraOpen(false);
        }
      }
    }
  }, [scannedQrCrateCode]);

  //function for handling product qty
  const handelProductQty = (e) => {
    let { name, value, type, checked } = e.target;
    let numericValue = value.replace(/\D/g, "");
    value = numericValue;
    setCrateProductQty(value);
    setCrateQty(value);
  };

  //useEffect for refrshing data
  React.useEffect(() => {
    if (!scannedResult.crateInScanError) {
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      scannedResult.crateInScanError = true;
    }

    if (!scannedResult.crateOutScanError) {
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      scannedResult.crateOutScanError = true;
    }

    if (!emptyCanOtp.completeOrderError) {
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      emptyCanOtp.completeOrderError = true;
    }
    if (!emptyCanOtp.editOrderError) {
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      emptyCanOtp.editOrderError = true;
    }
    if (!scannedResult.qrLessInscanError) {
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      scannedResult.qrLessInscanError = true;
    }
    if (!scannedResult.qrLessOutscanError) {
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      scannedResult.qrLessOutscanError = true;
    }
  }, [
    scannedResult.crateInScanError,
    scannedResult.crateOutScanError,
    scannedResult.qrLessInscanError,
    scannedResult.qrLessOutscanError,
    emptyCanOtp.completeOrderError,
    emptyCanOtp.editOrderError,
  ]);

  const handleCloseCapColorDialog = () => {
    setOpenProductCapColorDialog(false);
    setCanColorResp({});
  };

  //function for handling qrless order qty
  const handelChangeQrlessProductqty = (e) => {
    let { name, value, type, checked } = e.target;
    let numericValue = value.replace(/\D/g, "");
    value = numericValue;
    setQrLessProductqty(value);
  };

  //function for handling qrless product submit
  const [qrLessProductDialog, setQrLessProductDialog] = React.useState(false);
  const handelQrlessSubmit = () => {
    const reqInScanObj = {
      order_id: skuWfsDetails?.ec_order_id,
      container_material: skuWfsDetails?.order_details?.container_material,
      container_capacity: skuWfsDetails?.order_details?.container_capacity,
      instock_qty: qrLessProductqty,
    };
    const reqOutScanObj = {
      order_id: skuWfsDetails?.ec_order_id,
      container_material: skuWfsDetails?.order_details?.container_material,
      container_capacity: skuWfsDetails?.order_details?.container_capacity,
      outstock_qty: qrLessProductqty,
    };
    if (btnName === "Start Inscan") {
      dispatch(QrlessProductInScan(reqInScanObj));
      setIsCameraOpen(false);
      setQrLessProductDialog(false);
    }
    if (btnName === "Start Outscan") {
      dispatch(QrlessProductOutScan(reqOutScanObj));
      setIsCameraOpen(false);
      setQrLessProductDialog(false);
    }
  };

  const handleSubmitScanned = () => {
    if (btnName === "Start Outscan") {
      const reqObj = {
        is_inscan: false,
        order_id: skuWfsDetails?.ec_order_id,
        data: currentScanned[skuWfsDetails?.ec_order_id].map((item) => ({
          id: item,
        })),
      };
      CustomConsole(reqObj);
      dispatch(LinkOutscanEmptyValidCans(reqObj));
      setTimeout(() => {
        dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
        dispatch(ClearValidatedData());
        localStorage.removeItem("current_scanned_codes");
        setCameraScannedCodes([]);
        // setCurrentScanned([]);
      }, 1500);
    } else if (btnName === "Start Inscan") {
      const reqObj = {
        is_inscan: false,
        order_id: skuWfsDetails?.ec_order_id,
        data: currentScanned[skuWfsDetails?.ec_order_id].map((item) => ({
          id: item,
        })),
      };
      CustomConsole(reqObj);
      dispatch(LinkInscanEmptyValidCans(reqObj));
      setTimeout(() => {
        dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
        dispatch(ClearValidatedData());
        localStorage.removeItem("current_scanned_codes");
        setCameraScannedCodes([]);
        // setCurrentScanned([]);
      }, 1500);
    }
  };

  const getProductColor = async (code) => {
    CustomConsole(getProductColor);
    const resp = await GetProductCanColor(code);
    CustomConsole(resp);
    setCanColorResp(resp);
  };

  React.useEffect(() => {
    if (Object.keys(requestObject).length) {
      //@Removed/console.log("request Object in parent");
      //@Removed/console.log(requestObject);
      if (btnName === "Start Inscan") {
        dispatch(LinkInscanEmptyValidCans(requestObject));
        if (
          skuWfsDetails?.order_details?.container_capacity === "20_Liters" &&
          skuWfsDetails?.order_details?.container_material === "PET" &&
          requestObject?.data?.length === 1
        ) {
          getProductColor(requestObject.data[0].id);
          setOpenProductCapColorDialog(true);
        }
      } else if (btnName === "Start Outscan") {
        dispatch(LinkOutscanEmptyValidCans(requestObject));
      }

      setTimeout(() => {
        dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
        localStorage.removeItem("current_scanned_codes");
        setCurrentScanned({});
        setScannedCount(0);
        setOpenScanningDialog(false);
        setRequestObject({});
      }, 2000);
    }
  }, [requestObject]);

  React.useEffect(() => {
    if (!supplierDeliveries.linkOrderToOrdererror) {
      dispatch(GetWfsEmptyCanDetailsSku({ ec_order_id: orderId }));
      dispatch(ClearDataRefersh());
    }
  }, [supplierDeliveries.linkOrderToOrdererror]);

  return (
    <Material.Box sx={{ marginTop: "40px" }}>
      {" "}
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          size="small"
          onClick={handleBack}
          sx={{ marginTop: "1rem" }}
        >
          Back
        </Button>
      </Box>
      <Material.Grid container gap={2} sx={{ marginTop: "1rem" }}>
        {" "}
        <Material.Grid xs={12} md={3}>
          <Card sx={{ boxShadow: 5 }}>
            <Typography
              sx={{ fontSize: "1rem", fontWeight: "bold", color: "blue" }}
            >
              Order Details
            </Typography>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Order Id:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.ec_order_id}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Order Status:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_status}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Updated At:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.updated_at}
                  </span>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Material.Grid>
        <Material.Grid xs={12} md={8}>
          <Card sx={{ boxShadow: 5 }}>
            <Typography
              sx={{ fontSize: "1rem", fontWeight: "bold", color: "blue" }}
            >
              Product Details
            </Typography>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Container Capacity:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.container_capacity}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Container Material:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.container_material}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Damaged Container:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.damaged_qty}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Expired Container:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.expired_qty}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Order Quantity:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.order_qty}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Submitted Filled:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.outscanned_cans}{" "}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Received Empty:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.inscanned_cans}
                  </span>
                </Typography>

                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Empty Qty:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.return_qty}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Received Return:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.submitted_return_cans}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Order Placed By:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.is_admin === true ? "Admin" : "Distributor"}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Product Type:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {skuWfsDetails?.order_details?.is_qr === true
                      ? "Qr Product"
                      : "Qr-Less Product"}
                  </span>
                </Typography>
              </Box>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Box>
                {skuWfsDetails?.order_status === "Admin Accepted" &&
                skuWfsDetails?.order_details?.is_submit_started === true ? (
                  <>
                    {btnName !== "" && (
                      <Box
                        sx={{
                          // marginTop: "10px",
                          display: "flex",
                          flexDirection: "column",
                          // padding: "10px",
                          alignItems: "center",
                          margin: "5px",
                        }}
                      >
                        <Button
                          // sx={{ height: "20px", width: "250px" }}
                          variant="contained"
                          size="small"
                          onClick={() => startScanning(skuWfsDetails)}
                        >
                          {btnName}
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Box>
              {skuWfsDetails?.order_details?.is_qr === true &&
              skuWfsDetails?.order_status === "Admin Accepted" &&
              skuWfsDetails?.order_details?.is_submit_started === true &&
              skuWfsDetails?.order_details?.return_qty ===
                skuWfsDetails?.order_details?.inscanned_cans &&
              skuWfsDetails?.is_admin === false &&
              skuWfsDetails?.order_details?.is_submit_finished === false ? (
                <Material.Box sx={{ m: 1 }}>
                  <Material.Button
                    size="small"
                    variant="contained"
                    onClick={() => handleOpenLinkOrderToOrderDialog("WFSB")}
                  >
                    Link Order Id
                  </Material.Button>
                </Material.Box>
              ) : (
                ""
              )}
            </CardActions>
          </Card>
        </Material.Grid>
        <Material.Grid xs={12} md={12}>
          {skuWfsDetails?.order_details?.crates?.length > 0 ? (
            <>
              <Card sx={{ boxShadow: 5 }}>
                <Typography
                  sx={{ fontSize: "1rem", fontWeight: "bold", color: "blue" }}
                >
                  Crate Details
                </Typography>
                {skuWfsDetails?.order_details?.crates?.map((data, index) => (
                  <CardContent key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        gap: 2,
                        borderBottom: "2px dashed gray",
                      }}
                    >
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Container Capacity:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.container_capacity}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Container Material:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.container_material}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Order Qty:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.order_qty}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Submitted Quantity:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.received_qty}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Return Quantity:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.return_qty}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Return Container Submitting:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.return_containers_submitting}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Received Qty:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.submitted_qty}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: ".9rem", fontWeight: "500" }}>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Submitted Return Qty:
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {data.submitted_return_cans}
                        </span>
                      </Typography>
                    </Box>
                  </CardContent>
                ))}
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Box>
                    {skuWfsDetails?.order_status === "Admin Accepted" &&
                    skuWfsDetails?.order_details?.is_submit_started === true ? (
                      <>
                        {crateBtnName !== "" && (
                          <Box
                            sx={{
                              // marginTop: "10px",
                              display: "flex",
                              flexDirection: "column",
                              // padding: "10px",
                              alignItems: "center",
                              margin: "5px",
                            }}
                          >
                            <Button
                              // sx={{ height: "20px", width: "250px" }}
                              variant="contained"
                              size="small"
                              onClick={() =>
                                StartCrateScanning(skuWfsDetails, "crate")
                              }
                            >
                              {crateBtnName}
                            </Button>
                          </Box>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </CardActions>
              </Card>
            </>
          ) : null}
        </Material.Grid>
      </Material.Grid>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {skuWfsDetails?.order_status === "Admin Accepted" &&
        skuWfsDetails?.order_details?.is_submit_started === true ? (
          <Box sx={{ mt: 0.6 }}>
            {/* <Button
              variant="contained"
              size="small"
              onClick={handleEditEmptyCan}
              sx={{ marginTop: "5px" }}
            >
              <BorderColor /> Edit
            </Button> */}
          </Box>
        ) : (
          ""
        )}

        {/* <Material.Box>
          {" "}
          <Material.Button
            variant="contained"
            onClick={handleScanInValidCans}
            size="small"
            sx={{ backgroundColor: "blue", color: "white", margin: "10px" }}
          >
            <DocumentScanner /> in-valid cans
          </Material.Button>
        </Material.Box> */}
        {/* <Material.Button
          variant="contained"
          size="small"
          onClick={handleApprovedOrRejectedCans}
          sx={{
            backgroundColor: "black",
            color: "white",
            margin: "10px",
          }}
        >
          Can Swap Requests
        </Material.Button> */}
      </Box>
      {/* </Box> */}
      {/* <ApprovedOrRejectedCansCanSwapDialog
        openApprovedRejectedCans={openApprovedRejectedCans}
        handleCloseApprovedRejectedDialog={handleCloseApprovedRejectedDialog}
        data={canSwapDeatails.getQrBarcodeSwapList}
      /> */}
      {skuWfsDetails &&
      skuWfsDetails?.ec_order_id &&
      skuWfsDetails?.ec_order_id.length &&
      openApprovedRejectedCans ? (
        <CanSwapOrderRequestDetailsDialog
          open={openApprovedRejectedCans}
          handleClose={handleCloseApprovedRejectedDialog}
          order_id={skuWfsDetails?.ec_order_id}
          handleLink={handleLinkApprovedQrs}
        />
      ) : null}
      {/* <WfsOrderCameraCanSwapDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        handleSendRequest={handleSendRequest}
        handleCloseDialog={handleCloseDialogInvalidCans}
      /> */}
      {/* {openEditEmptyCanOrderDialog && (
        <EditEmptyCanOrdersDialog
          openEditEmptyCanOrderDialog={openEditEmptyCanOrderDialog}
          orderDetails={skuWfsDetails}
          handleSubmitCans={handleSubmitCans}
          handleClose={handleClose}
        />
      )} */}
      <EmptyCanOrderEdit
        open={openEditEmptyCanOrderDialog}
        onClose={handleClose}
        editDetails={skuWfsDetails}
      />
      <DeliveriesScanInValidCansDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        setOpenScanInValidCsnsDialog={setOpenScanInValidCsnsDialog}
        handleSendRequest={handleSendRequest}
        handleCloseDialog={handleCloseDialogInvalidCans}
        orderid={skuWfsDetails?.ec_order_id}
      />
      {isCameraOpen ? (
        <Material.Dialog open={isCameraOpen} onClose={handleCloseDialog}>
          <Material.DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              padding: "10px",
            }}
          >
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Link Bulk" />
              {/* <Tab label="Link one by one" /> */}
            </Tabs>
            {(skuWfsDetails.is_admin === true &&
              skuWfsDetails.order_details?.is_qr === true &&
              btnName === "Start Inscan") ||
            (skuWfsDetails.order_details?.is_qr === true &&
              skuWfsDetails.order_details?.container_material === "PET") ||
            (skuWfsDetails.is_admin === false &&
              skuWfsDetails.order_details?.is_qr === true &&
              btnName === "Start Outscan") ? (
              <>
                <Material.Box
                  sx={{
                    marginTop: "15px",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={scanOption}
                    exclusive
                    onChange={handleSelectScanOption}
                    aria-label="Platform"
                  >
                    <ToggleButton value="scanner">
                      Scanner <ScannerRoundedIcon />
                    </ToggleButton>
                    <ToggleButton value="camera">
                      Camera <CameraAltRoundedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Material.Box>
                <>
                  {scanOption === "camera" ? (
                    <>
                      {value === 1 && (
                        <Html5QrcodePlugin1
                          fps={1}
                          qrbox={200}
                          disableFlip={false}
                          qrCodeSuccessCallback={onNewScanResult}
                        />
                      )}

                      {value === 0 && (
                        <Html5QrcodePlugin1
                          fps={1}
                          qrbox={200}
                          disableFlip={false}
                          qrCodeSuccessCallback={onScanAll}
                        />
                      )}

                      {value == 0 && (
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Material.Chip
                            label={`Scanned Items Count: ${scannedCount} / ${maxQtyScan} `}
                            color="success"
                          />
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleSubmitScanned}
                          >
                            Submit
                          </Button>
                        </Material.Box>
                      )}
                    </>
                  ) : scanOption === "scanner" ? (
                    <Material.Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {value === 1 && (
                        <TextField
                          onChange={handleQrBarcodeIdFromScanner}
                          label="QR/Barcode:"
                          value={qrBarcodeId}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}

                      {value == 0 && (
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <TextField
                            onChange={handleQrBarcodeIdFromScanner}
                            label="QR/Barcode:"
                            value={qrBarcodeId}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Material.Chip
                            label={`Scanned Items Count: ${scannedCount} / ${maxQtyScan}`}
                            color="success"
                          />
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleSubmitScanned}
                          >
                            Submit
                          </Button>
                        </Material.Box>
                      )}
                    </Material.Box>
                  ) : (
                    ""
                  )}
                  <ResultContainerPlugin1
                    results={decodedResults}
                    setScanCount={setScanCount}
                    scanType={scanOption}
                  />
                </>
              </>
            ) : (
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  m: "10px",
                }}
              >
                <Typography>Enter Qty </Typography>
                <TextField
                  onChange={handelChangeQrlessProductqty}
                  label="Enter Qty"
                  size="small"
                  value={qrLessProductqty}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />{" "}
                <Material.Button
                  onClick={handelQrlessSubmit}
                  size="small"
                  variant="contained"
                >
                  Submit
                </Material.Button>
              </Material.Box>
            )}
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Material.Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpenLinkFromStartDialog(true)}
                >
                  Reset
                </Button>
              </Material.Grid>
              <Material.Grid item>
                <Material.Button
                  onClick={() => setIsCameraOpen(false)}
                  size="small"
                  variant="contained"
                  color="error"
                >
                  Close
                </Material.Button>
              </Material.Grid>
            </Material.Grid>
          </Material.DialogActions>
        </Material.Dialog>
      ) : (
        ""
      )}
      {/* )} */}
      <Box>
        <EnterOTPDialog
          openVerifyOtpDialog={openVerifyOtpDialog}
          setOpenVerifyOtpDialog={setOpenVerifyOtpDialog}
          orderId={skuWfsDetails?.ec_order_id}
          pageNumber={pageNumber}
        />
      </Box>
      {openLinkFromStartDialog && (
        <Box>
          <Material.Dialog
            open={openLinkFromStartDialog}
            onClose={() => setOpenLinkFromStartDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle id="alert-dialog-title">
              {"Are you sure you want to link from the beginning?"}
            </Material.DialogTitle>
            <Material.DialogActions>
              <Button
                onClick={() => setOpenLinkFromStartDialog(false)}
                variant="outlined"
              >
                No
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={() => {
                  localStorage.removeItem("current_scanned_codes");
                  setCurrentScanned({});
                  setCameraScannedCodes([]);
                  setScannedCount(0);
                  setOpenLinkFromStartDialog(false);
                }}
                autoFocus
              >
                Yes
              </Button>
            </Material.DialogActions>
          </Material.Dialog>
        </Box>
      )}
      <WfsCrateScanDialog
        isCrateCameraOpen={isCrateCameraOpen}
        handleCloseDialog={handleCloseCrateScanDialog}
        handleQrBarcodeIdFromScannerCrate={handleQrBarcodeIdFromScannerCrate}
        scanCrateOption={scanCrateOption}
        setScanCrateOption={setScanCrateOption}
        handleSubmitCrateScan={handleSubmitCrateScan}
        onNewScanCateResult={onNewScanCateResult}
        qrBarcodeIdCrate={qrBarcodeIdCrate}
        handelCameraSubmit={handelCameraSubmit}
        handelProductQty={handelProductQty}
        crateProductQty={crateProductQty}
        is_inscan={crateBtnName === "Start Inscan" ? true : false}
        skuWfsDetails={skuWfsDetails}
      />
      {openOrderToOrderScanningDialog && (
        <LinkOrderToOrderScanningProcessDialog
          openOrderToOrderScanningDialog={openOrderToOrderScanningDialog}
          handlCloseDialogOrdertoOrder={() =>
            setOpenOrderToOrderScanningDialog(false)
          }
          handleLinkOrder={handleLinkOrder}
          orderIdStartingLetters={orderIdStartingLetters}
        />
      )}
      {openSwappedCanDialog && (
        <Material.Dialog
          open={openSwappedCanDialog}
          onClose={() => {
            setOpenSwappedCanDialog(false);
            //@Removed/console.log("validatedData");
            //@Removed/console.log();
            validatedData.validatedDataOrder = [];
            validatedData.validatedData = [];
            // dispatch({ type: "CLEAR_VALIDATED_DATA" });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogContent
            sx={{
              backgroundColor: "#e86d7b",
              // padding: 20,
            }}
          >
            <Material.Typography sx={{ fontWeight: "bold", color: "white" }}>
              No of cans swapped : {otherValidData}
            </Material.Typography>
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button
              variant="outlined"
              size="small"
              onClick={() => {
                setOpenSwappedCanDialog(false);
                validatedData.validatedDataOrder = [];
                validatedData.validatedData = [];
              }}
              autoFocus
            >
              Close
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>
      )}
      {/* {(skuWfsDetails.is_admin === true &&
        skuWfsDetails.order_details?.is_qr === true &&
        btnName === "Start Inscan") ||
      (skuWfsDetails.order_details?.is_qr === true &&
        skuWfsDetails.order_details?.container_material === "PET") ||
      (skuWfsDetails.is_admin === false &&
        skuWfsDetails.order_details?.is_qr === true &&
        btnName === "Start Outscan") ? ( */}
      <ScanningDialog
        setOpenScanningDialog={setOpenScanningDialog}
        openScanningDialog={openScanningDialog}
        scannedQrBarcode={scannedQrBarcode}
        setScannedQrBarcode={setScannedQrBarcode}
        currentScanned={currentScanned}
        setCurrentScanned={setCurrentScanned}
        scanQtyMax={scanQtyMax}
        scanOrderId={skuWfsDetails?.ec_order_id}
        scannedCount={scannedCount}
        setScannedCount={setScannedCount}
        setRequestObject={setRequestObject}
        setOpenLinkFromStartDialog={setOpenLinkFromStartDialog}
        validateExpired={validateExpired}
        isBatch={isBatch}
        setSubmitBtnFlg={setSubmitBtnFlg}
      />
      {openProductCapColorDialog && (
        <ProductCapColorDialog
          openProductCapColorDialog={openProductCapColorDialog}
          canColorResp={canColorResp}
          handleCloseCapColorDialog={handleCloseCapColorDialog}
        />
      )}
      {qrLessProductDialog && (
        <>
          {" "}
          <Material.Dialog
            open={qrLessProductDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setQrLessProductDialog(false)}
          >
            <Material.DialogContent>
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  m: "10px",
                }}
              >
                <Typography>Enter Qty </Typography>
                <TextField
                  onChange={handelChangeQrlessProductqty}
                  label="Enter Qty"
                  size="small"
                  value={qrLessProductqty}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />{" "}
                <Material.Button
                  onClick={handelQrlessSubmit}
                  size="small"
                  variant="contained"
                >
                  Submit
                </Material.Button>
              </Material.Box>
            </Material.DialogContent>
          </Material.Dialog>
        </>
      )}
    </Material.Box>
  );
}

export default WfsSingleOrder;
