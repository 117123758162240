/**
 * @author vinay kumar
 * @description Supplier Drivers List
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.46
 * @Date 26-06-2023
 */
import React from "react";
import * as Material from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeliveryAgentListCard from "../../Components/Cards/DeliveryAgentListCard";
import AddorUpdateDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AddorUpdateDeliveryAgentDialog";
import { GetSupplierDeliveryAgentDetailsAdmin } from "../Redux/Actions/supliersAction";
import { employeeType } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

function SupplierDriversList({ employee_type, operationType, ...props }) {
  const [singleData, setSingleData] = React.useState(null);
  const [openAddDeliveryAgentDialog, setOpenAddDeliveryAgentDialog] =
    React.useState(false);

  const [updateClicked, setUpdateClicked] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [deliveryId, setDeliveryId] = React.useState("");
  const [name, setName] = React.useState("");
  const adminSupplierDrivers = useSelector(
    (state) => state.adminSupplierDrivers
  );
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(adminSupplierDrivers);
  CustomConsole(props);
  const dispatch = useDispatch();
  React.useEffect(() => {
    // dispatch(GetSupplierDrivers({ user_id: props.userId }));
    CustomConsole("props.supplierId: " + props.supplierId);
    if (props.supplierId) {
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          supplier_id: props.supplierId,
          employee_type: employeeType.DRIVER,
        })
      );
    } else {
      CustomConsole("-- Supplier ID doen't exists --");
    }
  }, []);

  React.useEffect(() => {
    CustomConsole(supplierDeliveries);
  }, [supplierDeliveries]);
  const handleUpdateDeliveryAgent = (props) => {
    CustomConsole(props);
    CustomConsole(props.data);
    setSingleData(props.data);

    CustomConsole(deliveryId);
    CustomConsole(name);
    CustomConsole("------handleUpdateDeliveryAgent------");
    setOpenAddDeliveryAgentDialog(true);
    CustomConsole(props.deliver_Id);
    CustomConsole(props.fullname);
    setDeliveryId(props.deliver_Id);
    setName(props.fullname);
    setIsUpdate(true);
    // dispatch(GetSupplierDeliveryAgentDetailsAdmin({supplier_id:props.supplierId}));
  };
  const handleCancleDialog = (props) => {
    CustomConsole(props);
    CustomConsole("------handleCancleDialog------");
    setOpenAddDeliveryAgentDialog(false);
  };
  const handleOpenAddDeliveryAgent = () => {
    CustomConsole("------handleCancleDialog------");
    setIsUpdate(false);
    setSingleData(null);
    setOpenAddDeliveryAgentDialog(true);
  };
  const handleAddUpdateDialogClose = () => {
    setOpenAddDeliveryAgentDialog(false);
    setTimeout(() =>
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          supplier_id: props.supplierId,
          employee_type: employeeType.DRIVER,
        })
      )
    );
  };
  const handleRefreshDriverDetails = () => {
    dispatch(
      GetSupplierDeliveryAgentDetailsAdmin({
        supplier_id: props.supplierId,
        employee_type,
      })
    );
  };
  return (
    <Material.Box>
      <Material.Card sx={{ border: "2px solid blue" }}>
        <Material.CardContent>
          <Material.Typography sx={{ fontWeight: "bold" }}>
            Driver Details
          </Material.Typography>
          <Material.Box>
            <Material.Button
              variant="contained"
              onClick={handleOpenAddDeliveryAgent}
            >
              Add Delivery Agent
            </Material.Button>
            <Material.Button
              sx={{ marginLeft: "20px" }}
              variant="contained"
              onClick={handleRefreshDriverDetails}
            >
              Refresh
            </Material.Button>
          </Material.Box>
          {/* {adminSupplierDrivers.getSupplierDriversList.length > 0 ? (
            <>
              {adminSupplierDrivers.getSupplierDriversList.map((data, key) => {
                return (
                  <DeliveryAgentListCard
                    key={key}
                    deliver_Id={data.driver_id}
                    fullname={data.driver_profile.fullname}
                    phone={data.driver_profile.phone}
                    handleUpdateDeliveryAgent={handleUpdateDeliveryAgent}
                    data={data}
                  />
                );
              })}
            </>
          ) : (
            ""
          )} */}
          {supplierDeliveries.error === false &&
          supplierDeliveries.getSupplierDriverDataAdmin !== null &&
          supplierDeliveries.getSupplierDriverDataAdmin !== undefined ? (
            <>
              {/* {supplierDeliveries.grtSupplierDriverDataAdmin.length >
              0 ? ( */}
              <>
                {supplierDeliveries.getSupplierDriverDataAdmin.map(
                  (data, key) => {
                    return (
                      <DeliveryAgentListCard
                        key={key}
                        deliver_Id={data.driver_id}
                        fullname={data.driver_profile.fullname}
                        phone={data.driver_profile.phone}
                        data={data}
                        user_id={data.user_id}
                        handleUpdateDeliveryAgent={handleUpdateDeliveryAgent}
                        handleUpdate={handleRefreshDriverDetails}
                        isAdmin={true}
                      />
                    );
                  }
                )}
              </>
              {/* ) : (
                "No Data"
              )} */}
            </>
          ) : (
            "No Data Available"
          )}
        </Material.CardContent>
      </Material.Card>
      {openAddDeliveryAgentDialog ? (
        <AddorUpdateDeliveryAgentDialog
        supplierIntegerId={props.supplierIntegerId}

          employee_type={employee_type}
          operationType={operationType}
          openAddDeliveryAgentDialog={openAddDeliveryAgentDialog}
          setOpenAddDeliveryAgentDialog={setOpenAddDeliveryAgentDialog}
          setUpdateClicked={setUpdateClicked}
          updateClicked={updateClicked}
          isUpdate={isUpdate}
          handleCancle={handleCancleDialog}
          singleData={singleData}
          supplierId={props.supplierId}
          handleDialogClose={handleAddUpdateDialogClose}
          isAdmin={true}
          // handleAddDeliveryAgent={handleAddOrUpdateDeliveryAgent}
        />
      ) : (
        ""
      )}
    </Material.Box>
  );
}

export default SupplierDriversList;
