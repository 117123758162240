import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import GameApprovalCard from "./GameApprovalCard";
import styles from "./campaignApprovalStyles";
import UserDetailsByReferralDialog from "./UserDetailsByReferralDialog";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  ApproveGameAction,
  GetUserDetailsByReferral,
} from "../Redux/Actions/gamificationActions";
import ApprovalCampTableView from "./ApprovalCampTableView";

function CampaignApprovals(props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isUserClickInfo, setIsUserClickInfo] = React.useState(false);
  const dispatch = useDispatch();

  const gamificationStore = useSelector((state) => state.gamificationReducer);
  React.useEffect(() => {
    if (
      !gamificationStore.error &&
      isUserClickInfo &&
      gamificationStore.userDetails &&
      Object.keys(gamificationStore.userDetails).length
    ) {
      setIsUserClickInfo(false);
      setOpenDialog(true);
    }

    CustomConsole(gamificationStore.playAGame);
    CustomConsole(gamificationStore.userDetails);
  }, [gamificationStore.userDetails, gamificationStore.playAGame]);
  React.useEffect(() => {
    CustomConsole("--- props ---");
    CustomConsole(props);
  }, [props]);
  const handleApprovalButtonAction = (data) => {
    CustomConsole("-- handleApprovalButtonAction --");
    CustomConsole(DataTransferItem);

    props.setOpenProgressBar(true);
    dispatch(ApproveGameAction(data));
  };
  const handleUserDetailsButton = (data) => {
    CustomConsole("-- handleUserDetailsButton --");
    CustomConsole(data);
    if (data.user_referral && data.user_referral.length) {
      setIsUserClickInfo(true);

      // props.setOpenProgressBar(true);
      dispatch(GetUserDetailsByReferral({ referral_id: data.user_referral }));
    }
  };
  return (
    <Material.Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        // backgroundColor: props.indexValue % 2 === 0 ? "#fce4d4" : "#f5f7c6",
      }}
      // elevation={12}
    >
      <Material.Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {props.data && props.data.length ? (
          props.uiView === "card" ? (
            props.data.map((playedGameDetails, index) => {
              return (
                <GameApprovalCard
                  key={index}
                  data={playedGameDetails}
                  handleApprovalButtonAction={handleApprovalButtonAction}
                  handleUserDetailsButton={handleUserDetailsButton}
                />
              );
            })
          ) : (
            <ApprovalCampTableView
              data={props.data}
              handleApprovalButtonAction={handleApprovalButtonAction}
              handleUserDetailsButton={handleUserDetailsButton}
            />
          )
        ) : (
          ""
        )}
      </Material.Grid>
      {openDialog && (
        <UserDetailsByReferralDialog
          open={openDialog}
          data={gamificationStore.userDetails}
          handleClose={() => setOpenDialog(false)}
        />
      )}
    </Material.Box>
  );
}

export default CampaignApprovals;
