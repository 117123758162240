/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author vinay kumar
 * @description Supplier Company Profile
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 29-06-2023
 */
// Importing the Libraries and Other Files

import { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, MenuItem } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";

import {
  SupplierWarehouseStocksInScan,
  SupplierWarehouseStocksValidateScan,
  SupplierWarehouseStocksOutScan,
} from "../Redux/Actions/supliersAction";

import {
  GetFilteredUserAddressAdmin,
  GetUserAddressAdminById,
} from "../Redux/Actions/userDetailsAdminAction";

import WarehouseStockScaning from "../../Components/DialogBoxes/SupplierDialogBox/WarehouseStockScaning";
import WarehouseStocksCard from "../../Components/Cards/WarehouseStocksCard";
import { AvailablePincodes } from "../Redux/Actions/pincodesAction";
import CustomConsole from "../../CustomConsole";

function WarehouseStocks({ supplierId }) {
  const punchDetails = useSelector(
    (state) => state.vehicleManagement?.punchDetails
  );

  const [openCameraDialog, setOpenCameraDialog] = useState(false);
  const [warehouses, setwarehouses] = useState();
  const [districts, setDistricts] = useState();
  const [countries, setCountries] = useState([]);
  const [warehouseFilterCountry, setWarehouseFilterCountry] = useState("INDIA");
  const [selectedWarehouseIndex, setSelectedWarehouseIndex] = useState(null);
  const [warehouseFilterCity, setWareFilterhouseCity] = useState(null);
  const [scanType, setScanType] = useState("");
  const [filteredWarehouses, setFilteredWarehouses] = useState();

  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);
  const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);

  const dispatch = useDispatch();

  const getCountries = async () => {
    const all_available_countries = await AvailablePincodes({
      // pincode: value,
      page_number: 1,
      page_size: 100,
      get: "country",
    });
    setCountries(all_available_countries?.data);
    // setAllAvailableCountries(all_available_countries?.data);
  };
  const getDistricts = async (params) => {
    const all_Districts = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "district",
    });
    setDistricts(all_Districts?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };

  const warehouseFilters = () => {
    if (warehouseFilterCountry && warehouseFilterCity) {
      setSelectedWarehouseIndex(null);
      // if (warehouseFilterCountry && warehouseFilterCity) {
      dispatch(
        GetFilteredUserAddressAdmin({
          city: warehouseFilterCity,
          user_id: supplierId,
        })
      );
      // setFilteredWarehouses(filtered);
      // }
    } else {
      setFilteredWarehouses(null);
    }
  };
  const handleScanopen = ({ in_scan }) => {
    setScanType(in_scan ? "in" : "out");

    setOpenCameraDialog(true);
  };
  const onMoveClicked = ({ qrCodes, type, validate, qr_less }) => {
    //@Removed/console.log(qr_less);
    const warehouse_id = warehouses[selectedWarehouseIndex].address_id;
    if (validate) {
      dispatch(
        SupplierWarehouseStocksValidateScan({
          qr_codes: qrCodes,
          warehouse_id,
          type,
          validate_type: scanType,
        })
      );
    } else if (scanType === "in") {
      const payload = {
        qr_codes: qrCodes,
        warehouse_id,
        type,
        qr_less,
      };
      dispatch(SupplierWarehouseStocksInScan(payload));
    } else if (scanType === "out") {
      dispatch(
        SupplierWarehouseStocksOutScan({
          qr_codes: qrCodes,
          warehouse_id,
          qr_less,
          type,
        })
      );
    }
  };
  useEffect(() => {
    if (typeof selectedWarehouseIndex === "number" && warehouses.length) {
      let payload = {
        address_id: warehouses[selectedWarehouseIndex].address_id,
      };
      if (supplierId) {
        payload.user_id = supplierId;
      }
      dispatch(GetUserAddressAdminById(payload));
    } else {
      warehouseFilters();
    }
  }, [selectedWarehouseIndex]);

  useEffect(() => {
    warehouseFilters();
  }, [warehouseFilterCountry, warehouseFilterCity]);
  useEffect(() => {
    const serviceAreasText = Array.from(
      new Set(skuPincodeDetails.pincodeData?.map((item) => item.locality))
    );

    CustomConsole(serviceAreasText);

    if (!skuPincodeDetails.pincodeData) {
    } else {
    }
  }, [skuPincodeDetails]);
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    setWareFilterhouseCity("");
    setDistricts([]);
    if (warehouseFilterCountry) {
      getDistricts({ country: warehouseFilterCountry });
    }
  }, [warehouseFilterCountry]);
  useEffect(() => {
    setwarehouses(userDetailsUpdate.addresses);
    setFilteredWarehouses(userDetailsUpdate.addresses || []);
    if (userDetailsUpdate.addresses.length === 1) {
      setSelectedWarehouseIndex(0);
    }
    CustomConsole(userDetailsUpdate.addresses);
  }, [userDetailsUpdate.addresses]);

  useEffect(() => {
    if (supplierDeliveries.actionTriger === false) {
      if (typeof selectedWarehouseIndex === "number" && warehouses.length) {
        dispatch(
          GetUserAddressAdminById({
            address_id: warehouses[selectedWarehouseIndex].address_id,
            user_id: supplierId,
          })
        );
      } else {
        warehouseFilters();
        // setFilteredWarehouses(warehouses || []);
      }
      supplierDeliveries.actionTriger = true;
    }
  }, [supplierDeliveries.actionTriger]);
  useEffect(() => {
    if (punchDetails?.warehouse_id) {
      dispatch(
        GetUserAddressAdminById({
          address_id: punchDetails?.warehouse_id,
          user_id: supplierId,
        })
      );
    }
  }, [punchDetails?.warehouse_id]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item lg={3} md={6} sm={6} xs={6} sx={{ p: 1 }}>
            <Autocomplete
              key={warehouseFilterCountry}
              id="country-select-demo"
              // sx={{ width: 210 }}
              value={warehouseFilterCountry}
              // options={countries?.map((item, index) => {
              //   return { name: item.name, code: item.code, id: item.id };
              // })}
              options={countries || []}
              defaultValue={warehouseFilterCountry}
              autoHighlight
              getOptionLabel={(nameSearch) => `${warehouseFilterCountry} `}
              // defaultValue={countries[0]?.name}
              onChange={(_, newValue) => {
                setWarehouseFilterCountry(newValue?.country || null); // Set the selected  as the value
              }}
              renderOption={(props, item) => {
                return (
                  <MenuItem
                    onClick={() => setWarehouseFilterCountry(item.country)}
                    key={item}
                    value={item.country}
                  >
                    {item.country}
                  </MenuItem>
                  // <Box
                  //   component="li"
                  //   // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  //   {...props}
                  // >
                  //   {nameSearch?.country}
                  // </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={"Country"}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "country", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={6} sx={{ p: 1 }}>
            <Autocomplete
              id="country-select-demo"
              // sx={{ width: "max-content" }}
              options={districts?.map((item, index) => {
                return { name: item.district };
              })}
              // defaultValue={{ name: "Chennai", code: "TN05", id: 1 }}
              autoHighlight
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setWareFilterhouseCity(newValue?.name || null); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  autoComplete={false}
                  aria-autocomplete="none"
                  {...params}
                  size="small"
                  label={"City"}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Autocomplete
                disabled={!warehouses?.length}
                id="country-select-demo"
                sx={{ width: 210 }}
                options={warehouses?.map((item, index) => {
                  return {
                    name: item.contact_name,
                    pincode: item.pincode,
                    id: index,
                  };
                })}
                autoHighlight
                getOptionLabel={(nameSearch) =>
                  `${nameSearch.name} ${nameSearch.pincode}`
                }
                onChange={(_, newValue) => {
                  setSelectedWarehouseIndex(newValue ? newValue?.id : null); // Set the selected  as the value
                }}
                renderOption={(props, nameSearch) => (
                  <Box
                    component="li"
                    // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {nameSearch.name} - {nameSearch.pincode}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                      warehouses?.length
                        ? "Search Warehouse"
                        : "No warehouses Found"
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: null, // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              {selectedWarehouseIndex != null && (
                <Box sx={{ display: "flex", gap: 1, ml: 2 }}>
                  <Button
                    onClick={() => {
                      handleScanopen({ in_scan: true });
                    }}
                    variant="contained"
                  >
                    <QrCodeScannerIcon /> In{" "}
                  </Button>
                  <Button
                    onClick={() => handleScanopen({ in_scan: false })}
                    variant="contained"
                  >
                    <QrCodeScannerIcon /> Out
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, pt: 2 }}>
          {filteredWarehouses?.map((warehouse) => (
            <WarehouseStocksCard
              warehouseDetails={warehouse}
              data={supplierDeliveries.getSupplerCompanyProfile}
            />
          ))}
        </Box>
      </Box>
      {openCameraDialog && (
        <WarehouseStockScaning
          openCameraDialog={openCameraDialog}
          setOpenCameraDialog={setOpenCameraDialog}
          onMoveClicked={onMoveClicked}
          scanType={scanType}
          isDistributor={true}
        />
      )}
    </Box>
  );
}

export default WarehouseStocks;
