import React from "react";
import * as Material from "@mui/material";
import DriverProfileCard from "../../Components/Cards/DriverProfileCard";
import { useDispatch, useSelector } from "react-redux";
import { GetUserProfile } from "../Redux/Actions/profileAction";
import ScannerConnectDescriptionDialog from "../../Components/Dialog/ScannerConnectDescriptionDialog";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

function DriverProfile() {
  const dispatch = useDispatch();
  const [driverData, setDriverData] = React.useState({});
  const profileDetails = useSelector((state) => state.profile);
  CustomConsole(profileDetails);
  CustomConsole(profileDetails.getdriverDetails);
  React.useEffect(() => {
    dispatch(GetUserProfile());

    // setDriverData(profileDetails.driver)
  }, []);
  React.useEffect(() => {}, [profileDetails]);
  const [openQrDetailsDialog, setOpenQrDetailsDialog] = React.useState(false);
  const handleCloseQrDetailsDialog = () => {
    setOpenQrDetailsDialog(false);
  };
  return (
    <Material.Box sx={{ marginTop: "60px" }}>
      <DriverProfileCard
        profileDetails={profileDetails.getdriverDetails}
        userDetails={profileDetails.user}
      />
      <Material.Paper
        elevation={12}
        sx={{ marginTop: "20px", padding: "10px" }}
      >
        <Material.Typography
          sx={{ color: "blue", fontWeight: "bold", fontSize: "16px" }}
        >
          QR/Barcode Scanner Pairing Process
        </Material.Typography>
        <Material.Button
          variant="contained"
          sx={{ color: "white", fontWeight: "bold", marginTop: "10px" }}
          onClick={() => setOpenQrDetailsDialog(true)}
        >
          View
        </Material.Button>
      </Material.Paper>
      <ScannerConnectDescriptionDialog
        open={openQrDetailsDialog}
        handleClose={handleCloseQrDetailsDialog}
      />
    </Material.Box>
  );
}

export default DriverProfile;
