import React from "react";
import * as Material from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

import CustomConsole from "../../CustomConsole";
function ListOfAddedNewProductTable(props) {
  React.useEffect(() => {
    CustomConsole(props);
  }, [props]);
  const handleOpenSingleId = (data) => {
    props.handleOpenSingleId(data);
  };
  return (
    <Box>
      <Material.Paper
        sx={{
          marginTop: "20px",
          maxHeight: "80Vh",
          width: { md: "100%", xs: "85Vw" },
          overflow: "auto",
        }}
      >
        <Material.TableContainer sx={{ maxHeight: 720 }}>
          <Material.Table stickyHeader aria-label="sticky table">
            <Material.TableHead sx={{ bgcolor: "#1E6EE3" }}>
              <Material.TableRow>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  S.No
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Request Id
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Order Id
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Status
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested By
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested Empty
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested Filled
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Approved Empty
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Approved Filled
                </Material.TableCell>
                {/* <Material.TableCell >
                  Requested by
                </Material.TableCell> */}
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Approved By
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Requested At
                </Material.TableCell>
                <Material.TableCell
                  align="center"
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Updated At
                </Material.TableCell>
              </Material.TableRow>
            </Material.TableHead>
            <Material.TableBody sx={{ color: "black" }}>
              {props.data !== undefined &&
              props.data !== null &&
              props.data.length > 0 ? (
                <>
                  {props.data.map((row, key) => (
                    <Material.TableRow
                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => handleOpenSingleId(row)}
                    >
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {(props.pageNumber - 1) * props.pageSize + key + 1}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.id}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.order_id}
                      </Material.TableCell>
                      <Material.TableCell
                        align="right"
                        sx={{
                          color:
                            row.status === 100
                              ? "blue"
                              : row.status === 200
                              ? "green"
                              : "red",
                        }}
                      >
                        {row.status === 100
                          ? "Pending"
                          : row.status === 200
                          ? "Approved"
                          : "Rejected"}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.requested_by_user?.fullname}
                        <br />
                        {row.requested_by_user?.phone}
                      </Material.TableCell>

                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.req_empty?.length}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.req_filled?.length}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.approved_empty?.length}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.approved_filled?.length}
                      </Material.TableCell>
                      {/* <Material.TableCell component="th" scope="row" align="right">
                    {"Avinadhdevdistest"}
                  </Material.TableCell> */}
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {row.approved_by_user?.fullname} <br />
                        {row.approved_by_user?.phone}
                      </Material.TableCell>

                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {moment(row.created_at).utc().format("DD-MM-YYYY LT")}
                      </Material.TableCell>
                      <Material.TableCell
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {moment(row.updated_at).utc().format("DD-MM-YYYY LT")}
                      </Material.TableCell>

                      {/* <Material.TableCell align="right">{"500"}</Material.TableCell> */}
                    </Material.TableRow>
                  ))}
                </>
              ) : (
                <Material.Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                  }}
                >
                  No Data Found !!
                </Material.Typography>
              )}
            </Material.TableBody>
          </Material.Table>
        </Material.TableContainer>
      </Material.Paper>
    </Box>
  );
}

export default ListOfAddedNewProductTable;
