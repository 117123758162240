import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import PlaceRecycleOrderDialog from "./PlaceRecycleOrderDialog";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { Recycling } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  GetRequestedRecycleOrderList,
  PostRequestRecycleOrder,
} from "../Redux/Actions/RecycleOrdersAction";
import {
  ADMIN_ROLES,
  RECYCLE_STATUS_CODE,
} from "../../Components/AppMeta/appMetaConfig";
import RecycleFilterDialog from "./RecycleFilterDialog";
import { toast } from "react-toastify";
import moment from "moment";
function RecycleOrdersMain() {
  const recycleordersdetails = useSelector(
    (state) => state.recycleordersdetails
  );

  const dispatch = useDispatch();
  const [openPlaceRecycleDialog, setOpenPlaceRecycleDialog] =
    React.useState(false);
  const [orderStatusSelect, setOrderStatusSelect] = React.useState("");
  const [openRecycleFilterDialog, setOpenRecycleFilterDialog] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const pageSizeList = [25, 50, 100, 200, 500, 1000, 2500, 5000];

  React.useEffect(() => {
    hitGetOrdersListApi();
  }, [pageNumber, pageSize]);

  const orderStatus = (order_status) => {
    switch (order_status) {
      case RECYCLE_STATUS_CODE.FINISHED:
        return "FINISHED";
      case RECYCLE_STATUS_CODE.CANCELLED:
        return "CANCELLED";
      case RECYCLE_STATUS_CODE.REJECTED:
        return "REJECTED";
      case RECYCLE_STATUS_CODE.ACCEPTED:
        return "ACCEPTED";
      case RECYCLE_STATUS_CODE.REQUESTED:
        return "REQUESTED";
      case RECYCLE_STATUS_CODE.STARTED:
        return "STARTED";
      default:
        return "";
    }
  };

  const hitGetOrdersListApi = () => {
    dispatch(
      GetRequestedRecycleOrderList({
        page_number: pageNumber,
        page_size: pageSize,
        status: orderStatusSelect || undefined,
      })
    );
  };

  const handleOpenPlaceRecycleDialog = () => {
    setOpenPlaceRecycleDialog(true);
    dispatch(GetAvailableProducts());
  };

  const handleCloseRecycleDialog = () => {
    setOpenPlaceRecycleDialog(false);
  };
  const handleSubmitRecycleOrder = (props) => {
    console.log(props);
    const reqObj = {
      capacity: props.container_capacity,
      material: props.container_material,
      physical_damage: props.physical_damaged_qty,
      qr_barcode_damage: props.qrBarcode_damaged_qty,
      expired: props.expired_qty,
    };
    dispatch(PostRequestRecycleOrder(reqObj)).then((data) => {
      hitGetOrdersListApi();
    });
    props.setSelectedProductRecycle("");
    props.setOrderParams({});
    setOpenPlaceRecycleDialog(false);
  };

  const handleCloseRecycleFilter = () => {
    setOpenRecycleFilterDialog(false);
  };
  const handleFilterRecycleStatus = () => {
    hitGetOrdersListApi();
    setOpenRecycleFilterDialog(false);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
        <Button variant="contained" onClick={handleOpenPlaceRecycleDialog}>
          Place <Recycling />
          Recycle Order
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        {/* Filter */}
        <Box sx={{ mt: "5px" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => setOpenRecycleFilterDialog(true)}
          >
            Filter
          </Button>
        </Box>
        {/* Page Size */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "8px" }}>Page size:</InputLabel>
          <Select
            variant="standard"
            label="Page Size"
            value={pageSize}
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              width: "60px",
            }}
            onChange={(e) => {
              setPageSize(e.target.value);
            }}
          >
            {pageSizeList.map((data, key) => {
              return (
                <MenuItem value={data} key={key}>
                  {data}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        {/* Page Number */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "8px" }}>Page number:</InputLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstPageIcon
              sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            />
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {pageNumber}
            </Typography>
            <LastPageIcon
              sx={{
                color:
                  pageNumber > 0 &&
                  recycleordersdetails?.recycleOrderList?.length === pageSize
                    ? "blue"
                    : "gray",
              }}
              onClick={() => {
                if (
                  pageNumber > 0 &&
                  recycleordersdetails?.recycleOrderList?.length === pageSize
                ) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#c6d0e3" }}>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                }}
                colSpan={3}
                align="center"
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
            </TableRow>
            <TableRow sx={{ bgcolor: "#c6d0e3" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                  padding: "0px",
                }}
              >
                S.no
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                  padding: "0px",
                }}
              >
                Order Id
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Products
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                Physical Damaged
              </TableCell>
              <TableCell
                sx={{
                  padding: "5px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Qr/Barcode Damaged
              </TableCell>
              <TableCell
                sx={{
                  padding: "5px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Expired
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Created At
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Updated By
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Updated By Role
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recycleordersdetails.recycleOrderList !== undefined &&
            recycleordersdetails?.recycleOrderList !== null &&
            recycleordersdetails?.recycleOrderList.length > 0 ? (
              <>
                {recycleordersdetails?.recycleOrderList?.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.order_id}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.capacity + "-" + data.details.material}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.physical_damage}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.qr_barcode_damage}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.expired}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {orderStatus(data.status)}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {moment(data.created_at).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.updated_user.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.updated_user.role_id === ADMIN_ROLES.BW_ADMIN
                          ? "ADMIN"
                          : "SUPER ADMIN"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              "No Data Found"
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PlaceRecycleOrderDialog
        openPlaceRecycleDialog={openPlaceRecycleDialog}
        handleSubmitRecycleOrder={handleSubmitRecycleOrder}
        handleCloseRecycleDialog={handleCloseRecycleDialog}
        setOrderStatusSelect={setOrderStatusSelect}
      />
      {openRecycleFilterDialog && (
        <RecycleFilterDialog
          openRecycleFilterDialog={openRecycleFilterDialog}
          setOrderStatusSelect={setOrderStatusSelect}
          orderStatusSelect={orderStatusSelect}
          handleClose={handleCloseRecycleFilter}
          handleFilter={handleFilterRecycleStatus}
        />
      )}
    </Box>
  );
}

export default RecycleOrdersMain;
