import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomConsole from "../../CustomConsole";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  UpdateTransportCharge,
  GetDistributorDetails,
  RemoveTransportCharge,
} from "../Redux/Actions/appSettingsAction";
import { toast } from "react-toastify";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

export default function AssignedAreasCard({
  pwdPincodeAreas,
  distriNum,
  adminRole,
}) {
  const [areaName, setAreaName] = React.useState("");
  const [charge, setCharge] = React.useState("");
  const [id, setId] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [agreeDialog, setAgreeDialog] = React.useState(false);

  //toast
  const [deleteToast, setDeleteToast] = React.useState(false);
  const [chargeToast, setChargeToast] = React.useState(false);

  const dispatch = useDispatch();
  const appSettingsDetails = useSelector((state) => state.appSettingsDetails);

  React.useEffect(() => {
    if (deleteToast === true && appSettingsDetails.error === false) {
      toast.success(appSettingsDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(appSettingsDetails);
      setDeleteToast(false);
    }
    CustomConsole("in---");
    CustomConsole(appSettingsDetails);
    CustomConsole(chargeToast);
    if (chargeToast === true && appSettingsDetails.error === false) {
      toast.success(appSettingsDetails.updatingMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChargeToast(false);
    }
  }, [appSettingsDetails]);

  const updateTransCharge = (data) => {
    CustomConsole(data);
    setAreaName(data.area_name);
    setCharge(data.transportation_charges);
    setId(data.id);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setAgreeDialog(false);
  };
  const updateTransChargeArea = () => {
    CustomConsole(
      `The area is ${areaName} and the charge is ${charge} and the id is ${id}`
    );
    const reqObj = {
      id: id,
      transportation_charges: charge,
    };
    if (reqObj.id !== "" && reqObj.transportation_charges !== "") {
      dispatch(UpdateTransportCharge(reqObj));
      setOpenDialog(false);
      setChargeToast(true);
      dispatch(GetDistributorDetails(distriNum));
    }
  };

  pwdPincodeAreas.sort((a, b) => a.id - b.id);

  const deleteBtn = (data) => {
    setId(data.id);
    setAgreeDialog(true);
  };

  const removeTransCharge = () => {
    const reqObj = {
      id: id,
    };

    if (reqObj.id !== "") {
      dispatch(RemoveTransportCharge(reqObj));
      dispatch(GetDistributorDetails(distriNum));
      setDeleteToast(true);
    }
    setAgreeDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          gap: "20px",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            //   backgroundColor: "#41638A",
            //   color: "white",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                gap: "30px",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "18", fontWeight: "bold" }}
                  variant="button"
                  display="block"
                  gutterBottom
                >
                  Assigned Areas
                </Typography>
              </Box>
              {pwdPincodeAreas.map((data, key) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                    key={key + 1}
                  >
                    <Box sx={{ width: "100px" }}>
                      <Typography sx={{ textAlign: "center" }}>
                        {data.area_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{data.transportation_charges}</Typography>
                    </Box>
                    {ADMIN_ROLES.BW_CS === adminRole ||
                    ADMIN_ROLES.BW_CSHEAD === adminRole ||
                    ADMIN_ROLES.BW_CSLEAD === adminRole ||
                    ADMIN_ROLES.BW_OPS === adminRole ||
                    ADMIN_ROLES.BW_OPSHEAD === adminRole ||
                    ADMIN_ROLES.BW_FINANCE === adminRole ||
                    ADMIN_ROLES.BW_FINANCEHEAD === adminRole ? (
                      ""
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-between",
                          alignItems: "space-evenly",
                        }}
                      >
                        <DeleteIcon
                          onClick={() => {
                            deleteBtn(data);
                          }}
                        />
                        <EditIcon
                          onClick={() => {
                            updateTransCharge(data);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>
          <b>{areaName}</b>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            variant="standard"
            type="number"
            onKeyDown={(e) =>
              (e.key === "e" || e.key === "-" || e.key === "+") &&
              e.preventDefault()
            }
            label="Transport Charge"
            value={charge}
            inputProps={{ pattern: "[0-9]*" }}
            onChange={(e) => {
              setCharge(e.target.value);
            }}
          />
          <Button
            variant="contained"
            sx={{
              float: "right",
              marginTop: "8px",
            }}
            onClick={updateTransChargeArea}
          >
            Update
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={agreeDialog} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={removeTransCharge}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
