import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TimerIcon from "@mui/icons-material/Timer";
import CustomConsole from "../../CustomConsole";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { Divider, FormControl, InputLabel, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AssigningDialog({open, setOpen}) {
    const [assignType, setAssignType] = React.useState("slot");
    const [age, setAge] = React.useState("");


    
    const slot = [
        "10:00pm - 12:00pm",
        "12:00pm-2:00pm",
        "2:00pm-4:00pm",
        "4:00pm-6:00pm",
        "6:00pm-8:00pm",
      ];
      const agent = [
        {
          id: 1,
          name: "Lebansty",
          phone: "8668198181",
        },
        {
          id: 2,
          name: "Leb",
          phone: "8668198182",
        },
        {
          id: 3,
          name: "Valan",
          phone: "8668198183",
        },
        {
          id: 4,
          name: "Vettel",
          phone: "8668198184",
        },
        {
          id: 5,
          name: "Max Vestappan",
          phone: "8668198185",
        },
      ];

      const handleChange = (event) => {
        setAge(event.target.value);
      };
    
      const handleChangeAssignTog = (event, newAlignment) => {
        CustomConsole("------Alignment------");
        CustomConsole(newAlignment);
        setAssignType(newAlignment);
      };
  
  
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>Select slot/agent</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <ToggleButtonGroup
                color="primary"
                value={assignType}
                exclusive
                onChange={handleChangeAssignTog}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value="slot">
                  <TimerIcon />
                </ToggleButton>

                <ToggleButton value="agent">
                  <DeliveryDiningIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              {assignType === "slot" ? (
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Slot
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Slot"
                    size="small"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {slot.map((obj, key) => {
                      return (
                        <MenuItem key={key} value={obj.id}>
                          {obj}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Agent
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Agent"
                    size="small"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {agent.map((obj, key) => {
                      return (
                        <MenuItem key={key} value={obj.id}>
                          {obj.name}-{obj.phone}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
  )
}

export default AssigningDialog