import React from "react";
import * as Material from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomConsole from "../../../CustomConsole";

function SupplierReturnCanFilter(props) {
  CustomConsole(props);
  const [deliveryAgent, setDeliveryAgent] = React.useState("");

  const handleClose = () => {
    props.handleClose();
  };
  const handleFilter = () => {
    props.handleFilter({ delivery_agent_id: deliveryAgent });
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openReturnCanFilter}>
        <Material.DialogContent>
          <Material.Typography sx={{ marginBottom: "20px" }}>
            Based On Delivery Agent
          </Material.Typography>
          <Material.Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel>Select Delivery Agent</InputLabel>
              <Select
                value={deliveryAgent}
                label="Select Delivery Agent"
                onChange={(event) => setDeliveryAgent(event.target.value)}
              >
                {props.agentListata !== null &&
                  props.agentListata !== undefined &&
                  props.agentListata.length > 0 &&
                  props.agentListata.map((data) => {
                  return (
                    <MenuItem value={data.driver_id}>
                      {data.driver_profile.fullname}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "10px",
            }}
          >
            <Material.Button variant="contained" onClick={handleClose}>
              Close
            </Material.Button>
            <Material.Button variant="contained" onClick={handleFilter}>
              Filter
            </Material.Button>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default SupplierReturnCanFilter;
