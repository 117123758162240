/**
 * @author vinay kumar
 * @description Admin WareHouse Details
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.149
 * @Date 11-04-2024
 */
// Importing the Libraries and Other Files

import React from "react";
import SettingsMain from "../SupplierWarehouse/WarehouseMain";
import { Dialog, DialogContent, Button, Box, Typography } from "@mui/material";
import { ClearRounded } from "@mui/icons-material";

function WareHouseDetailsDialog(props) {
  //on Close Call back function
  const handleCloseWareHouseDialog = () => {
    props.handleCloseWareHouseDialog();
  };
  return (
    <Dialog open={props.openWarehouseDialog} fullScreen>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Dialog Box  closing button */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: { md: "10px", xs: "8px" },
              }}
            >
              <Typography sx={{ color: "blue" }}>Supplier Name:</Typography>
              <Typography>{props.fullName}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <Typography sx={{ color: "blue" }}>Supplier Phone:</Typography>
              <Typography>{props.phone}</Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseWareHouseDialog}
          >
            <ClearRounded />
          </Button>
        </Box>
        {/* Here Re-Using Component From The Distributor Web App */}
        <SettingsMain
          supplierId={props.suplierId}
          supplierIntegerId={props.supplierIntegerId}
        />
      </DialogContent>
    </Dialog>
  );
}

export default WareHouseDetailsDialog;
