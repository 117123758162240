import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export default function DistributorDetailsCard({handleViewDistrubutorDetails,setDistributorPhoneNum}) {
  const [distributorNum, setDistributorNum] = React.useState("");

  const handleViewDetailsBtn = () => {
    handleViewDistrubutorDetails(distributorNum)
    setDistributorPhoneNum(distributorNum)
  }
  return (
    <>
    
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "18", fontWeight: "bold" }}
            variant="button"
            display="block"
            gutterBottom
          >
            Packaged water
          </Typography>
          <Box>
            <TextField
              size="small"
              label="Distributor Number"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(
                  0,
                  parseInt(e.target.value)
                )
                  .toString()
                  .slice(0, 10);
              }}
              value={distributorNum}
              onChange={(event) => setDistributorNum(event.target.value)}
            />
          </Box>
          <Box>
            <Button variant="contained" size="small" onClick={handleViewDetailsBtn}>
              View Distributor Details
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
   
    </>
  );
}
