import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CustomConsole from "../../CustomConsole";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { AcceptingReplacementOrder, GetOrderDetailsById } from "../Redux/Actions/adminReplacementOrderAction";
import { useDispatch } from "react-redux";
import { AssignSupplier } from "../Redux/Actions/ordersAdminAction";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AdminReplacementSupplierTable({
  replacementSupplier,
  replecementDetails,
  setAcceptToast,
}) {
  CustomConsole(replecementDetails);
  const [openAcceptDialog, setOpenAcceptDialog] = React.useState(false);
  const [supplierId, setSupplierId] = React.useState("");

  const dispatch = useDispatch();

  const openDialog = (id) => {
    CustomConsole(id);
    setSupplierId(id);
    setOpenAcceptDialog(true);
  };

  const handleClose = () => {
    setOpenAcceptDialog(false);
  };

  const acceptingReplacementOrder = () => {
    const reqobj = {
      user_id: supplierId,
      order_id: replecementDetails[0].order_id,
    };
    dispatch(AssignSupplier(reqobj));
    setOpenAcceptDialog(false);
setTimeout(() => {
      dispatch(GetOrderDetailsById(replecementDetails[0].order_id));
    }, 1000);
    // if (replecementDetails[0].order_status === 100) {
    //   const reqObj = {
    //     order_id: replecementDetails[0].order_id,
    //     supplier_id: supplierId,
    //   };
    //   if (reqObj.order_id !== "" && reqObj.supplier_id !== "") {
    //     dispatch(AcceptingReplacementOrder(reqObj));
    //     setAcceptToast(true);
    //   }
    //   CustomConsole(reqObj);
    //   setOpenAcceptDialog(false);
    // } else {
    //   const reqobj = {
    //     user_id: supplierId,
    //     order_id: replecementDetails[0].order_id,
    //   };
    //   dispatch(AssignSupplier(reqobj));
    // }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: { lg: 1, md: 1, sm: 320, xs: 320 } }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" size="small">
                S.no
              </StyledTableCell>
              <StyledTableCell align="center">Full Name</StyledTableCell>
              <StyledTableCell align="center">
                {" "}
                Empty Containers
              </StyledTableCell>
              <StyledTableCell align="center">
                Filled Containers
              </StyledTableCell>
              <StyledTableCell align="center">
                Delivery Containers
              </StyledTableCell>
              <StyledTableCell align="center">Phone</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {replacementSupplier.map((row, idx) => (
              <StyledTableRow
                onClick={() => {
                  openDialog(row.id);
                }}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#036bfc",
                  },
                }}
                key={idx + 1}
              >
                <StyledTableCell align="center">{idx + 1}</StyledTableCell>
                <StyledTableCell align="center">{row.fullname}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.empty_container_qty}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.filled_container_qty}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.delivery_containers}
                </StyledTableCell>
                <StyledTableCell align="center">{row.phone}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* dialog */}
      <Dialog
        open={openAcceptDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you agree to accept the order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button autoFocus onClick={acceptingReplacementOrder}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminReplacementSupplierTable;
