import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { ToastContainer, toast } from "react-toastify";
import {
  AddDistributor,
  AppSettings,
  GetDistributorDetails,
  UpdateAppSettings,
} from "../Redux/Actions/appSettingsAction";
import Alert from "@mui/material/Alert";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
export default function Pwdsettingpage() {
  const dispatch = useDispatch();
  const appSettingsDetails = useSelector((state) => state.appSettingsDetails);
  const [twntyLtrMin, setTwntyLtrMin] = React.useState(0);
  const [twntyLtrMax, setTwntyLtrMax] = React.useState(0);
  const [updateFlag, setUpdateFlag] = React.useState(false);
  // let dispatch =useDispatch();

  // React.useEffect(()=>{
  //   dispatch(AppSettings());
  // },[twntyLtrMin])

  React.useEffect(() => {
    dispatch(AppSettings());
  }, []);

  const handleUpdateSettings = (reqObj) => {
    if (
      reqObj.twenty_ltr_min < reqObj.twenty_ltr_max &&
      reqObj.twenty_ltr_min !== 0 &&
      reqObj.twenty_ltr_max !== 0
    ) {
      dispatch(UpdateAppSettings(reqObj));
      setUpdateFlag(true);
    } else {
      toast.error(
        "Min value should be lesser than max value and not equal / values cannot be zero",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
    CustomConsole(reqObj);
  };
  const handleChange20LtrMin = (event) => {
    CustomConsole(event.target.value);
    setTwntyLtrMin(event.target.value);
  };
  const handleChange20LtrMax = (event) => {
    CustomConsole(event.target.value);
    setTwntyLtrMax(event.target.value);
  };
  React.useEffect(() => {
    CustomConsole(updateFlag);
    if (updateFlag) {
      let intervel = setInterval(() => {
        dispatch(AppSettings());
      }, 1000);
      return () => clearInterval(intervel);
    }
  }, [appSettingsDetails]);

  React.useEffect(() => {
    CustomConsole(appSettingsDetails);
    if (Object.keys(appSettingsDetails.admin_settings).length) {
      setTwntyLtrMin(appSettingsDetails.admin_settings.twenty_ltr_min);
      CustomConsole("---Look----");
      CustomConsole(appSettingsDetails.admin_settings.twenty_ltr_min);
      setTwntyLtrMax(appSettingsDetails.admin_settings.twenty_ltr_max);
    }
    if (appSettingsDetails.pdw_pincodes_areanames.length) {
      // CustomConsole(appSettingsDetails.pdw_pincodes_areanames);
      appSettingsDetails.pdw_pincodes_areanames.map((data) => {
        CustomConsole(data);
      });
      // setPwdPincodeAreas(appSettingsDetails.pdw_pincodes_areanames);
      // setShowPwdPincodeAreas(true);
    }
    if (appSettingsDetails.error === false && updateFlag === true) {
      toast.success(appSettingsDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateFlag(false);
      dispatch(AppSettings());
    }
  }, [appSettingsDetails]);

  CustomConsole("Run...");
  const handleUpdateBtn = () => {
    let reqObj = appSettingsDetails.admin_settings;

    if (reqObj.id) {
      delete reqObj.id;
    }
    reqObj.twenty_ltr_max = parseInt(twntyLtrMax);

    reqObj.twenty_ltr_min = parseInt(twntyLtrMin);
    // const reqObj = {
    //   active_from: appSettingsDetails.admin_settings.active_from,
    //   c_fine_percent: appSettingsDetails.admin_settings.c_fine_percent,
    //   cancellation_fee: appSettingsDetails.admin_settings.cancellation_fee,
    //   cancel_sub_order_timing:
    //     appSettingsDetails.admin_settings.cancel_sub_order_timing,
    //   cod_conv_fee: appSettingsDetails.admin_settings.cod_conv_fee,
    //   conv_cgst_percent: appSettingsDetails.admin_settings.conv_cgst_percent,
    //   conv_gst_code: appSettingsDetails.admin_settings.conv_gst_code,
    //   conv_sgst_percent: appSettingsDetails.admin_settings.conv_sgst_percent,
    //   five_ltr_max: appSettingsDetails.admin_settings.five_ltr_max,
    //   created_at: appSettingsDetails.admin_settings.created_at,
    //   disable_consumer_can_resubmission:
    //     appSettingsDetails.admin_settings.disable_consumer_can_resubmission,
    //   disable_distributor_can_resubmission:
    //     appSettingsDetails.admin_settings.disable_distributor_can_resubmission,
    //   disable_wfs_can_resubmission:
    //     appSettingsDetails.admin_settings.disable_wfs_can_resubmission,
    //   extra_charges_singleday:
    //     appSettingsDetails.admin_settings.extra_charges_singleday,
    //   five_ltr_min: appSettingsDetails.admin_settings.five_ltr_min,
    //   fivehnd_ml_max: appSettingsDetails.admin_settings.fivehnd_ml_max,
    //   fivehnd_ml_min: appSettingsDetails.admin_settings.fivehnd_ml_min,
    //   gateway_fee: appSettingsDetails.admin_settings.gateway_fee,
    //   gatewayfee_cgst_percent:
    //     appSettingsDetails.admin_settings.gatewayfee_cgst_percent,
    //   gatewayfee_gst_code:
    //     appSettingsDetails.admin_settings.gatewayfee_gst_code,
    //   gatewayfee_sgst_percent:
    //     appSettingsDetails.admin_settings.gatewayfee_sgst_percent,
    //   ltb_conv_fee_percent:
    //     appSettingsDetails.admin_settings.ltb_conv_fee_percent,
    //   ltb_order_accept_timing:
    //     appSettingsDetails.admin_settings.ltb_order_accept_timing,
    //   minimum_wallet_amount:
    //     appSettingsDetails.admin_settings.minimum_wallet_amount,
    //   notify_consumer_can_resubmission:
    //     appSettingsDetails.admin_settings.notify_consumer_can_resubmission,
    //   notify_distributor_can_resubmission:
    //     appSettingsDetails.admin_settings.notify_distributor_can_resubmission,
    //   notify_wfs_can_resubmission:
    //     appSettingsDetails.admin_settings.notify_wfs_can_resubmission,
    //   one_ltr_max: appSettingsDetails.admin_settings.one_ltr_max,
    //   one_ltr_min: appSettingsDetails.admin_settings.one_ltr_min,
    //   order_block_end_time:
    //     appSettingsDetails.admin_settings.order_block_end_time,
    //   order_block_start_time:
    //     appSettingsDetails.admin_settings.order_block_start_time,
    //   otp_less_delivery_disclaimer:
    //     appSettingsDetails.admin_settings.otp_less_delivery_disclaimer,
    //   pdw_cgst_percent: appSettingsDetails.admin_settings.pdw_cgst_percent,
    //   pdw_gst_code: appSettingsDetails.admin_settings.pdw_gst_code,
    //   pdw_sgst_percent: appSettingsDetails.admin_settings.pdw_sgst_percent,
    //   postpaidcharges: appSettingsDetails.admin_settings.postpaidcharges,
    //   postpaidconvfee: appSettingsDetails.admin_settings.postpaidconvfee,
    //   s_fine_percent: appSettingsDetails.admin_settings.s_fine_percent,
    //   sb_conv_fee_percent:
    //     appSettingsDetails.admin_settings.sb_conv_fee_percent,
    //   st_cgst_percent: appSettingsDetails.admin_settings.st_cgst_percent,
    //   st_gst_code: appSettingsDetails.admin_settings.st_gst_code,
    //   st_sgst_percent: appSettingsDetails.admin_settings.st_sgst_percent,
    //   timeframe: appSettingsDetails.admin_settings.timeframe,
    //   twenty_ltr_max: parseInt(twntyLtrMax),
    //   twenty_ltr_min: parseInt(twntyLtrMin),
    //   two_ltr_max: appSettingsDetails.admin_settings.two_ltr_max,
    //   two_ltr_min: appSettingsDetails.admin_settings.two_ltr_min,
    //   twohnd_ml_max: appSettingsDetails.admin_settings.twohnd_ml_max,
    //   twohnd_ml_min: appSettingsDetails.admin_settings.twohnd_ml_min,
    //   wt_cgst_percent: appSettingsDetails.admin_settings.wt_cgst_percent,
    //   wt_gst_code: appSettingsDetails.admin_settings.wt_gst_code,
    //   wt_sgst_percent: appSettingsDetails.admin_settings.wt_sgst_percent,
    //   otp_less_delivery_disclaimer:
    //     appSettingsDetails.admin_settings.otp_less_delivery_disclaimer,
    //   max_allowed_discount_percentage:
    //     appSettingsDetails.admin_settings.max_allowed_discount_percentage,
    //   extra_charges_hsn_code:
    //     appSettingsDetails.admin_settings.extraChargesHSNCode,
    //   extra_charges_cgst_percent:
    //     appSettingsDetails.admin_settings.extraChargesCGST,
    //   extra_charges_sgst_percent:
    //     appSettingsDetails.admin_settings.extraChargesSGST,
    //   base_watercan_price: appSettingsDetails.admin_settings.base_watercan_price,
    //   fillingstation_settlement_price: appSettingsDetails.admin_settings.fillingstation_settlement_price,
    //   distributor_settlement_price: appSettingsDetails.admin_settings.distributor_settlement_price,
    // };
    handleUpdateSettings(reqObj);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        gap: "20px",
        marginTop: "2em",
      }}
    >
      {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ? (
        <>
          <Card
            sx={{
              minWidth: 275,
              //   backgroundColor: "#41638A",
              //   color: "white",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  gap: "30px",
                }}
              >
                <Box>
                  <Typography
                    sx={{ fontSize: "18", fontWeight: "bold" }}
                    variant="button"
                    display="block"
                    gutterBottom
                  >
                    Packaged drinking water settings
                  </Typography>
                </Box>
                {/* 20 liter min max */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Typography sx={{ textAlign: "center" }}>
                      20 Liters
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      size="small"
                      label="20ltr Min"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={twntyLtrMin}
                      onChange={handleChange20LtrMin}
                    />
                  </Box>
                  <Box>
                    <TextField
                      size="small"
                      label="20ltr Max"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={twntyLtrMax}
                      onChange={handleChange20LtrMax}
                    />
                  </Box>
                </Box>
                {/* 5 liter min max */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Typography sx={{ textAlign: "center" }}>
                      5 Liters
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="5ltr Min"
                      defaultValue={0}
                    />
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="5ltr Max"
                      defaultValue={0}
                    />
                  </Box>
                </Box>
                {/* 2 liters min max */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Typography sx={{ textAlign: "center" }}>
                      2 Liters
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="2ltr Min"
                      defaultValue={0}
                    />
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="2ltr Max"
                      defaultValue={0}
                    />
                  </Box>
                </Box>
                {/* 1 liter min max */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Typography sx={{ textAlign: "center" }}>
                      1 Liters
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="1ltr Min"
                      defaultValue={0}
                    />
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="1ltr Max"
                      defaultValue={0}
                    />
                  </Box>
                </Box>
                {/* 500ml min max */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Typography sx={{ textAlign: "center" }}>500 ML</Typography>
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="500ml Min"
                      defaultValue={0}
                    />
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="500ml Max"
                      defaultValue={0}
                    />
                  </Box>
                </Box>
                {/* 200 ml min and max */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Typography sx={{ textAlign: "center" }}>200 ML</Typography>
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="200ml Min"
                      defaultValue={0}
                    />
                  </Box>
                  <Box>
                    <TextField
                      disabled
                      size="small"
                      label="200ml Max"
                      defaultValue={0}
                    />
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    // sx={{ backgroundColor: "white", color: "black" }}
                    onClick={handleUpdateBtn}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </>
      ) : (
        <Box sx={{ marginTop: "10px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Box>
  );
}
