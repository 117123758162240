/**
 * @author vinay kumar
 * @description Delivery Card
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 21-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { Card, CardContent } from "@mui/material";
import { replacementCanOrdersStatus } from "../AppMeta/appMetaConfig";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import * as MuiIcons from "@mui/icons-material";
import CustomConsole from "../../CustomConsole";

function ReplacementCanorderCard(props) {
  CustomConsole(props);
  const handleButtonClick = () => {
    CustomConsole("----handlechangeEnterOtp------");
    props.handleButtonClick(props);
  };

  const handleOpenAssignDeliveryAgentDialog = () => {
    props.handleOpenAssignDeliveryAgentDialog(props.order_id);
  };

  // const handleSubmitOtp = (props) => {
  //   CustomConsole("----handleSubmitOtp---------")
  //   props.handleSubmitingOtp(props.order_id)
  // setEnterOtpOpenDialog(false);

  // };

  const [displayData, setDisplayData] = React.useState([
    { header: "Order Id :", data: props.order_id },
    { header: "Number Of Cans :", data: props.number_Of_cans },
    { header: "Cans Received:", data: props.cans_recived },
    { header: "Cans Exchanged :", data: props.cans_exachanged },
    { header: "Container Capacity:", data: props.container_capacity },
    { header: "Container Material:", data: props.container_mayerial },
    { header: "Order Status:", data: props.order_status },
  ]);
  React.useEffect(() => {
    setDisplayData(() => [
      { header: "Order Id :", data: props.order_id },
      { header: "Number Of Cans :", data: props.number_Of_cans },
      { header: "Cans Received:", data: props.cans_recived },
      { header: "Cans Exchanged :", data: props.cans_exachanged },
      { header: "Container Capacity:", data: props.container_capacity },
      { header: "Container Material:", data: props.container_mayerial },
      { header: "Order Status:", data: props.order_status },
    ]);
    CustomConsole(displayData);
    displayData?.map((content, key) => {
      CustomConsole(content.header);
      CustomConsole(content.data);
    });
  }, []);
  const handleViewDetails = () => {
    props.handleViewDetails(props.order_id);
  };
  const handleLocation = (event) => {
    event.stopPropagation();
    props.handleLocation(props);
  };
  const handlePhone = (event) => {
    event.stopPropagation();
    props.handlePhone(props);
  };
  const handleCopyAddress = (event) => {
    event.stopPropagation();
    props.handleCopyAddress(props);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Paper
        sx={{
          borderRadius: "10px",
          bgcolor: "#FBFCFC",
          marginTop: "10px",
          // maxWidth: { xs: "100%", md: "100%", sm: "650px" },
          // maxWidth:"300px"
          width: { xs: 300, md: 320 },
          // height: "245px",
          padding: "10px",
          // margin: "5px",
        }}
        spacing={2}
        elevation={15}
        onClick={handleViewDetails}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "5px" }}
          // divider={<Divider orientation="vertical" flexItem />}
        >
          <Chip
            sx={{
              bgcolor: "#E74C3C",
              color: "white",
              fontSize: "17px",
            }}
            label={props.order_id}
            // onClick={handleViewDetails}
          />
          <Box sx={{ display: "flex", gap: "10px" }}>
            <MuiIcons.Call onClick={handlePhone} sx={{ fontSize: "25px" }} />
            <MuiIcons.Place
              onClick={handleLocation}
              color="primary"
              sx={{ maxHeight: "25px", marginLeft: "15px", color: "red" }}
            />
          </Box>
        </Stack>
        {/* <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Chip
            label={props.order_id}
            sx={{
              bgcolor: "#E74C3C",
              color: "white",
              fontSize: "17px",
            }}
          />
        </Box> */}
        <Divider orientation="horizantal" flexItem />

        <Box sx={{ paddingLeft: "7px" }}>
          <Box sx={{ display: "flex", gap: "20px", mt: "10px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                textAlign: "start",
                // fontWeight: "bold",
              }}
            >
              Product :
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                // textAlign: "start",
                fontWeight: "bold",
              }}
            >
              {props.container_capacity + "-" + props.container_mayerial}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "start",
                // fontWeight: "bold",
              }}
            >
              Order Qty :
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                // textAlign: "start",
                fontWeight: "bold",
              }}
            >
              {props.number_Of_cans}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "start",
                fontWeight: "bold",
              }}
            >
              {props.delivery_address.contact_name},
              {props.delivery_address.contact_phone}{" "}
              {/* <MuiIcons.Call onClick={handlePhone} sx={{ fontSize: "15px" }} /> */}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", textAlign: "start", overflow: "hidden" }}
            >
              {props.delivery_address.address_line1},
              {props.delivery_address.address_line2},
              {props.delivery_address.address_line3}
            </Typography>
            {/* <Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.delivery_address.address_line3}
            </Typography> */}
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  textAlign: "start",
                  paddingTop: "1px",
                }}
              >
                {props.delivery_address.landmark},
                {props.delivery_address.service_area},
                {props.delivery_address.pincode}{" "}
              </Typography>
            </Box>
            <Box>
              {" "}
              <MuiIcons.ContentCopy
                onClick={handleCopyAddress}
                sx={{ fontSize: "19px", marginLeft: "15px" }}
              />{" "}
              {/* <MuiIcons.PinDrop
                onClick={handleLocation}
                color="primary"
                sx={{ maxHeight: "28px", marginLeft: "15px" }}
              /> */}
            </Box>
          </Box>
        </Box>
        <Divider orientation="horizantal" flexItem />
        <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
          <Button
            size="small"
            variant="contained"
            onClick={handleViewDetails}
            sx={{ borderRadius: "20%" }}
          >
            View Details
          </Button>
        </Box>
      </Paper>
    </Box>
    // <Box sx={{ display: "flex", flexDirection: "row" }}>
    //   <Card
    //     sx={{
    //       borderRadius: "20px",
    //       margin: "10px",
    //       minWidth: 300,
    //       maxHeight: 450,
    //       background: "#dfedf7",
    //       "&:hover": {
    //         boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
    //       },
    //     }}
    //     elevation={24}
    //   >
    //     <CardContent>

    //       <Typography
    //         sx={{
    //           color: "blue",
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           alignItems: "flex-start",
    //           textAlign: "start",
    //         }}
    //       >
    //         OrderId :
    //         <span
    //           style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
    //         >
    //           {props.order_id}
    //         </span>
    //       </Typography>
    //       <Typography
    //         sx={{
    //           color: "blue",
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           alignItems: "flex-start",
    //           textAlign: "start",
    //         }}
    //       >
    //         Number Of Cans :{" "}
    //         <span
    //           style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
    //         >
    //           {props.number_Of_cans}
    //         </span>
    //       </Typography>
    //       <Typography
    //         sx={{
    //           color: "blue",
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           alignItems: "flex-start",
    //           textAlign: "start",
    //         }}
    //       >
    //         Cans Received:{" "}
    //         <span
    //           style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
    //         >
    //           {props.cans_recived}
    //         </span>
    //       </Typography>
    //       <Typography
    //         sx={{
    //           color: "blue",
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           alignItems: "flex-start",
    //           textAlign: "start",
    //         }}
    //       >
    //         Cans Exchanged :{" "}
    //         <span
    //           style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
    //         >
    //           {props.cans_exachanged}
    //         </span>
    //       </Typography>

    //       <Typography
    //         sx={{
    //           color: "blue",
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           alignItems: "flex-start",
    //           textAlign: "start",
    //         }}
    //       >
    //         Container Capacity :{" "}
    //         <span
    //           style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
    //         >
    //           {props.container_capacity}
    //         </span>
    //       </Typography>
    //       <Typography
    //         sx={{
    //           color: "blue",
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           alignItems: "flex-start",
    //           textAlign: "start",
    //         }}
    //       >
    //         Container Material :{" "}
    //         <span
    //           style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
    //         >
    //           {props.container_mayerial}
    //         </span>
    //       </Typography>
    //       <Typography
    //         sx={{
    //           color: "blue",
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           alignItems: "flex-start",
    //           textAlign: "start",
    //         }}
    //       >
    //         Order Status :{" "}
    //         <span
    //           style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
    //         >
    //           {props.order_status ===
    //             replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED &&
    //           props.number_Of_cans !== props.cans_recived
    //             ? "Started"
    //             : props.order_status ===
    //               replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
    //             ? "Accepted"
    //             : ""}
    //         </span>
    //       </Typography>
    //       <Box sx={{ margin: "10px" }}>
    //         <Button variant="contained" onClick={handleViewDetails}>
    //           view Details
    //         </Button>
    //       </Box>

    //     </CardContent>
    //   </Card>
    // </Box>
  );
}

export default ReplacementCanorderCard;
