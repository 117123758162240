import { Box, Dialog, DialogContent, Button } from "@mui/material";
import React from "react";
import TransferProductVehicleToVehicleMain from "../SupplierWarehouse/TripPlanning/TransferProductVehicleToVehicleMain";

import { Close } from "@mui/icons-material";

function VehiclesDetailsDialog(props) {
  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };
  return (
    <Dialog open={props.openVehiclesDialog} fullScreen>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleCloseDialog}
          >
            <Close />
          </Button>
        </Box>
        <TransferProductVehicleToVehicleMain
          supplier_number={props.supplier_number}
        />
      </DialogContent>
    </Dialog>
  );
}

export default VehiclesDetailsDialog;
