import React, { useState } from "react";
import * as Material from "@mui/material";
import { toast } from "react-toastify";
// import DeliveryCard from "../../Components/Cards/ReplacementCanorderCard";
import { useDispatch, useSelector } from "react-redux";
import * as IconName from "react-icons/fc";
import * as MuiIcons from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import CustomConsole from "../../CustomConsole";
import {
  GetAssignDeliveryAgent,
  GetSupplierDeliveryAgentDetails,
  GetSupplierDeliveryAgentDetailsAdmin,
  GetSupplierReplacementCanOrders,
  PostSupplierAssignDeliveryAgentReturnOrReplacement,
  PutLinkInScanQrBarCode,
  PutSupplierOutScanFilledCans,
  PutSupplierReplacemtVerifyOtp,
} from "../Redux/Actions/supliersAction";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ReplacementCanorderCard from "../../Components/Cards/ReplacementCanorderCard";
import SupplierOrdersEnterOtpDialog from "../../Components/DialoBoxes/SupplierOrdersEnterOtpDialog";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import QrBarcodeLinkingDialog from "../AdminGenrateQRBarcode/QrBarcodeLinkingDialog";
import { Card, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReplacementCameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReplacementCameraDialog";
import AssignDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AssignDeliveryAgentDialog";
import SupplierReplacementFilter from "../../Components/DialogBoxes/SupplierDialogBox/SupplierReplacementFilter";
import ReplacementFormView from "./ReplacementFormView";
import ReplacementCanTableView from "./ReplacementCanTableView";
import ReplacementReasonDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReplacementReasonDialog";
import { replacementCanOrdersStatus } from "../../Components/AppMeta/appMetaConfig";

function ReplacementCansOrders() {
  const [enterOtpOpenDialog, setEnterOtpOpenDialog] = React.useState(false);
  const [openCameraDialog, setOpenCameraDialog] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scanCount, setScanCount] = React.useState(0);
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [openQrBarCodeCamera, setOpenQrBarCodeCamera] = React.useState(false);
  const [openOutScanCamera, setOpenOutScanCamera] = React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [replacementInScanBtn, setReplacementInScanBtn] = React.useState(false);
  const [testArr, setTestArr] = React.useState([]);
  const [openInScanReasonDialog, setOpenInScanReasonDialog] =
    React.useState(false);
  const [replacementOrderId, setReplacementOrderId] = React.useState("");
  const [openReplacementCanFilter, setOpenReplacementCanFilter] =
    React.useState(false);
  const [replacementCanView, setReplacementCanView] = React.useState("card");
  const [reasonData, setReasonData] = React.useState({});
  CustomConsole(reasonData);

  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [replacementOutScanBtn, setReplacementOutScanBtn] =
    React.useState(false);
  const [replacementSubmitOtpBtn, setReplacementSubmitOtpBtn] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [link, setLink] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const [searchByOrderId, setSearchByOrderId] = React.useState("");
  const inQrBarcodeAdmin = true;
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(supplierDeliveries);
  CustomConsole(supplierDeliveries.getSupplerSupplierDeliveryAgentList);

  React.useEffect(() => {
    CustomConsole(orderId);
  }, [orderId]);

  const refreshOrderDetails = () => {
    dispatch(
      GetSupplierReplacementCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  };
  React.useEffect(() => {
    if (
      supplierDeliveries.replacementSubmitOtperror === false &&
      replacementSubmitOtpBtn === true
    ) {
      dispatch(
        GetSupplierReplacementCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setReplacementSubmitOtpBtn(false);
    }
    if (
      supplierDeliveries.replacementInScanerror === false &&
      replacementInScanBtn === true
    ) {
      dispatch(
        GetSupplierReplacementCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setReplacementInScanBtn(false);
    }
    if (
      supplierDeliveries.replacementInScanerror === false &&
      replacementOutScanBtn === true
    ) {
      dispatch(
        GetSupplierReplacementCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setReplacementOutScanBtn(false);
    }
    //pagination enable and disable button
    if (
      supplierDeliveries !== undefined &&
      supplierDeliveries.getSupplierReplacementCanOrders !== undefined &&
      supplierDeliveries.getSupplierReplacementCanOrders.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  }, [supplierDeliveries]);

  // React.useEffect(() => {
  //   if (pageNumber) {
  //     CustomConsole("=== pageNumber ===:" + pageNumber);
  //     dispatch(
  //       GetSupplierReplacementCanOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //       })
  //     );
  //     setPageBackWard(true);
  //     setPageForward(true);
  //   }
  // }, [pageNumber]);
  React.useEffect(() => {
    if (replacementCanView === "form") {
      dispatch(
        GetSupplierReplacementCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }
    if (replacementCanView === "table") {
      dispatch(
        GetSupplierReplacementCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }
    if (replacementCanView === "card") {
      dispatch(
        GetSupplierReplacementCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }
  }, [pageNumber]);

  React.useEffect(() => {
    //Scanned Qr Code
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    //Scanned Bar Code
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    if (scannedQrCode !== "" || scannedBarCode !== "") {
      const reqObj = {
        is_filled_can: false,
        order_id: orderId,
        qr_barcode_id1: scannedQrCode || scannedBarCode,
        qr_barcode_id2: "",
        type_of_can: 1005,
      };
      CustomConsole(reqObj);
      dispatch(PutLinkInScanQrBarCode(reqObj));
      setReplacementInScanBtn(true);
      setScannedQrCode("");
      setScannedBarCode("");
      // setLinkingDialogOpen(false);
      // setScanCount(0);
      setDecodedResults([]);
      // setLink(false);
      // setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    const reqObj = {
      order_id: orderId,
      qr_id: scannedQrCodeDetails,
    };
    if (scannedQrCodeDetails !== "") {
      dispatch(PutSupplierOutScanFilledCans(reqObj));
      setOpenOutScanCamera(false);
      setReplacementOutScanBtn(true);
      setScanQrCodeDetailBtn(false);
      setScannedQrCodeDetails("");
    }
  }, [scannedQrCodeDetails]);

  const handleDataButtonClick = (props) => {
    CustomConsole("-------- handleDataButtonClick ----------");
    CustomConsole(props);
    CustomConsole(props.order_id);
    setOrderId(props.order_id);
    CustomConsole(orderId);
    switch (props.order_status) {
      case 200:
        CustomConsole("---- Admin Accepted Order ----");
        // Open OTP Dialog Box
        setEnterOtpOpenDialog(true);
        break;
      case 400:
        if (props.cans_recived === props.number_Of_cans) {
          CustomConsole("-------- OutScans --------");
          // Set OutScan Related Functionality Here
          setOpenOutScanCamera(true);
          setOpenQrBarCodeCamera(false);
          setOpenCameraDialog(true);
        } else {
          CustomConsole("---- Inscan ----");
          // Set Inscan Related Functionality Here
          setOpenOutScanCamera(false);
          // setOpenQrBarCodeCamera(true);
          // setOpenCameraDialog(true);
          setOpenInScanReasonDialog(true);
        }
        break;
      case 500:
        CustomConsole("---  Order Completed ----");
        break;
      default:
        CustomConsole("---- Invalid Order Status ----");
        break;
    }
  };

  const handleSubmitOtp = (props) => {
    CustomConsole("---- handleSubmitOtp -----");
    CustomConsole(props);
    CustomConsole(props.order_id);
    setOrderId(props.order_id);
    CustomConsole(orderId);
    setEnterOtpOpenDialog(false);
    const reqObj = {
      otp: enterOtpTextfied,
    };
    if (otpValidationError === false) {
      dispatch(PutSupplierReplacemtVerifyOtp({ orderId, reqObj }));
      setReplacementSubmitOtpBtn(true);
      setEnterOtpTextfied("");
    } else {
      toast.error("Enter proper OTP", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleCloseDialog = () => {
    CustomConsole("---- handleCloseDialog -----");
    setEnterOtpOpenDialog(false);
  };

  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenCameraDialog(false);
    setOpenOutScanCamera(false);
    setOpenQrBarCodeCamera(false);
  };
  const handleClickForward = () => {
    CustomConsole("---handleClickForward -----");
    CustomConsole("--pageNumber---:" + pageNumber);
    CustomConsole(
      "--supplierDeliveries.length---:" + supplierDeliveries.length
    );
    CustomConsole(JSON.stringify(supplierDeliveries));
    if (pageNumber + 1 > 0) {
      setPageNumber(() => pageNumber + 1);
    }
    // setPageStatuss(false);
  };

  const handleClickBack = () => {
    if (pageNumber > 0) {
      setPageBackWard(true);
      setPageForward(false);
      setPageNumber(() => pageNumber - 1);
      // setPageNumber(pageNumber - 1);
    }
  };
  const handleBack = () => {
    const url = `/dashboard/deliveries`;
    history.push(url);
  };

  const handleOpenAssignDeliveryAgentDialog = (props) => {
    CustomConsole(props);
    CustomConsole("---handleOpenAssign-------");
    dispatch(GetSupplierDeliveryAgentDetails());
    setReplacementOrderId(props);
    setOpenAssignDeliveryAgentDialog(true);
    CustomConsole(replacementOrderId);
  };

  const handleCloses = () => {
    setOpenAssignDeliveryAgentDialog(false);
  };
  const handleAssignDeliveryAgent = (props) => {
    CustomConsole(replacementOrderId);
    const reqObj = {
      delivery_agent_id: props.driver_id,
      order_id: replacementOrderId,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierAssignDeliveryAgentReturnOrReplacement(reqObj));
    setOpenAssignDeliveryAgentDialog(false);
    setTimeout(() => {
      dispatch(
        GetSupplierReplacementCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }, 500);
  };

  const handleClose = () => {
    CustomConsole("---------------handleClose------------");
    setOpenReplacementCanFilter(false);
  };
  const handleFilter = (props) => {
    CustomConsole("---------------handleFilter------------");
    CustomConsole(props);
    dispatch(
      GetSupplierReplacementCanOrders({
        pageNumber: pageNumber - 1,
        delivery_agent_id: props.delivery_agent_id,
      })
    );
    setOpenReplacementCanFilter(false);
  };

  const handleOpenFilter = () => {
    setOpenReplacementCanFilter(true);
    dispatch(GetAssignDeliveryAgent());
  };

  const handleReplacementOrderView = (event) => {
    setReplacementCanView(event.target.value);
    setPageSize(25);
    setPageNumber(0);
  };
  const handleCloseReasonDialog = () => {
    setOpenInScanReasonDialog(false);
  };
  const handleInScan = (props) => {
    CustomConsole(props);
    setReasonData(props);
    setOpenInScanReasonDialog(false);
    if (props !== "") {
      setOpenQrBarCodeCamera(true);
      setOpenCameraDialog(true);
    } else {
      CustomConsole("---------selectttttt---------");
    }
  };

  const handleViewDetails = (props) => {
    const url = `/dashboard/ReplacementSingleOrder?order_id=${props}`;
    history.push(url);
  };
  const handleSearchOrder = () => {
    CustomConsole("------------handleSearchOrder------------");
    if (searchByOrderId !== "") {
      const url = `/dashboard/ReplacementSingleOrder?order_id=${
        "RP" + searchByOrderId
      }`;
      history.push(url);
    } else {
      toast.error("Please Enter Order ID", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleLocation = (props) => {
    CustomConsole("------------handleLocation------------------");
    CustomConsole(props.delivery_address.latitude);
    CustomConsole(props.delivery_address.longitude);

    if (
      props.delivery_address.latitude !== undefined &&
      props.delivery_address.latitude !== null &&
      props.delivery_address.longitude !== undefined &&
      props.delivery_address.longitude !== null &&
      parseFloat(props.delivery_address.latitude) > 0 &&
      parseFloat(props.delivery_address.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props.delivery_address.latitude},${props.delivery_address.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.delivery_address.contact_phone !== "") {
      CustomConsole(props.delivery_address.contact_phone);
      const telUrl = `tel:${props.delivery_address.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };

  const handleSearchPageSize = () => {
    dispatch(
      GetSupplierReplacementCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  };
  function toCSV(orders) {
    let result = [];
    const items = JSON.parse(
      JSON.stringify(supplierDeliveries.getSupplierReplacementCanOrders)
    );
    if (items) {
      CustomConsole(items);
      items.map((a) => {
        CustomConsole(a);
        a.order_status =
          a.order_status ===
          replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
            ? "Accepted"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_REJECTED
            ? "Rejected"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_FINISHED
            ? "Finished"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED
            ? "Requested"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED
            ? "Started"
            : "";
        a.delivery_address = ` ${a.delivery_address?.address_line1}, ${
          a.delivery_address?.address_line2
        },${a.delivery_address?.address_line3}, ${
          a.delivery_address?.landmark
        }, ${a.delivery_address?.service_area}, ${
          a.delivery_address?.pincode
        }${" "}`;
        result.push(a);

        // result.push(a);
      });
    }
    setDownloadData(items);
    // setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: downloadData,
  };

  const handleCopyAddress = (props) => {
    const address_compile = ` ${props.delivery_address.contact_name},${props.delivery_address.contact_phone},${props.delivery_address.address_line1},
    ${props.delivery_address.address_line2},
    ${props.delivery_address.address_line3},
    ${props.delivery_address.service_area}-
    ${props.delivery_address.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };
  return (
    <Material.Box>
      <Material.Box
        sx={{
          display: "flex",
          justifyContent: { md: "flex-end", xs: "center" },
          flexWrap: "wrap",
        }}
      >
        {" "}
        {/* <Material.Box>
          <Material.Button variant="outlined" onClick={handleBack}>
            {" "}
            <Material.Typography sx={{ fontSize: "30px" }}>
              🔙
            </Material.Typography>
          </Material.Button>
</Material.Box> */}
        <Material.Box sx={{ marginTop: "10px", width: { xs: "170px" } }}>
          <Material.TextField
            //   sx={{ borderRadius: "30px" }}
            size="small"
            // sx={{ maxWidth: "200px" }}
            label="Search Order"
            variant="outlined"
            value={searchByOrderId}
            onChange={(event) => setSearchByOrderId(event.target.value)}
            InputProps={{
              startAdornment: (
                <Material.InputAdornment position="start">
                  <Material.Typography sx={{ fontWeight: "bold" }}>
                    RP
                  </Material.Typography>
                </Material.InputAdornment>
              ),
              endAdornment: (
                <Material.InputAdornment position="end">
                  <IconName.FcSearch size="30px" onClick={handleSearchOrder} />
                </Material.InputAdornment>
              ),
              style: { borderRadius: "50px" },
              maxLength: 10, // Optional: You can set the maximum length if needed
              // onInput: handlePhoneChange, // Attach the input event handler
            }}
          />
        </Material.Box>
        <Material.Box sx={{ margin: "10px" }}>
          <Material.Button variant="outlined" onClick={refreshOrderDetails}>
            🔃
          </Material.Button>
        </Material.Box>
        <Material.Box sx={{ margin: "10px" }}>
          <Material.Button variant="contained" onClick={handleOpenFilter}>
            Filter
          </Material.Button>
        </Material.Box>
        {/* <Material.Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
alignItems: "center",
            flexWrap:"wrap"
          }}
        > */}
        <Material.FormControl>
          <Material.InputLabel>Ui View</Material.InputLabel>
          <Material.Select
            variant="standard"
            sx={{ fontSize: "14px", fontWeight: "bold", width: "80px" }}
            value={replacementCanView}
            label="Ui View"
            onChange={handleReplacementOrderView}
          >
            <Material.MenuItem value="form">Form</Material.MenuItem>
            <Material.MenuItem value="table">Table</Material.MenuItem>
            <Material.MenuItem value="card">Card</Material.MenuItem>
          </Material.Select>
        </Material.FormControl>
        <Material.Box
          sx={{ maxWidth: "120px", maxHeight: "10px", margin: "5px" }}
        >
          <Material.TextField
            size="small"
            value={pageSize}
            label="Page size"
            // onChange={(e) => {
            //   setPageSize(e.target.value);
            //   // setSearchOrderFlag(true);
            // }}

            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          />
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // margin: "10px",
          }}
        >
          <Material.Button onClick={handleClickBack}>
            <ArrowBackIos />
          </Material.Button>
          <Material.Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "20px",
              height: "30px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber + 1}
          </Material.Typography>

          <Material.Button disabled={pageStatuss} onClick={handleClickForward}>
            <ArrowForwardIos />
          </Material.Button>
        </Material.Box>
        <Material.Box sx={{ m: 1 }}>
          <CSVLink {...csvLink}>
            {" "}
            <DownloadIcon
              sx={{ fontSize: 35 }}
              onClick={() =>
                toCSV(supplierDeliveries.getSupplierReplacementCanOrders)
              }
            />
          </CSVLink>
        </Material.Box>
        {/* </Material.Box> */}
      </Material.Box>
      {supplierDeliveries.getSupplierReplacementCanOrders !== undefined &&
      supplierDeliveries.getSupplierReplacementCanOrders !== null &&
      supplierDeliveries.getSupplierReplacementCanOrders.length > 0 ? (
        <>
          {replacementCanView === "form" ? (
            <ReplacementFormView
              data={supplierDeliveries?.getSupplierReplacementCanOrders}
              handleReturnCanEnterOTP={handleDataButtonClick}
              handleOpenAssignDeliveryAgentDialog={
                handleOpenAssignDeliveryAgentDialog
              }
            />
          ) : replacementCanView === "table" ? (
            <ReplacementCanTableView
              data={supplierDeliveries?.getSupplierReplacementCanOrders}
              handleReturnCanEnterOTP={handleDataButtonClick}
              handleOpenAssignDeliveryAgentDialog={
                handleOpenAssignDeliveryAgentDialog
              }
            />
          ) : replacementCanView === "card" ? (
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                overflow: "auto",
                // height: "500px",
              }}
            >
              {supplierDeliveries.getSupplierReplacementCanOrders !== null &&
              supplierDeliveries.getSupplierReplacementCanOrders !==
                undefined &&
              supplierDeliveries.getSupplierReplacementCanOrders.length > 0 ? (
                <>
                  {" "}
                  {supplierDeliveries.getSupplierReplacementCanOrders.map(
                    (data, key) => {
                      return (
                        <ReplacementCanorderCard
                          key={key}
                          order_id={data.order_id}
                          number_Of_cans={data.replace_can_qty}
                          cans_recived={data.accepted_can_qty}
                          cans_exachanged={data.exchanged_can_qty}
                          container_capacity={data.container_capacity}
                          container_mayerial={data.container_material}
                          delivery_agent_id={data.delivery_agent_id}
                          order_status={data.order_status}
                          handleButtonClick={handleDataButtonClick}
                          handleOpenAssignDeliveryAgentDialog={
                            handleOpenAssignDeliveryAgentDialog
                          }
                          delivery_address={data.delivery_address}
                          handleViewDetails={handleViewDetails}
                          handlePhone={handlePhone}
                          handleLocation={handleLocation}
                          handleCopyAddress={handleCopyAddress}
                        />
                      );
                    }
                  )}
                </>
              ) : (
                <Material.Typography sx={{ fontWeight: "bold" }}>
                  Replacement Can Orders Left
                </Material.Typography>
              )}
            </Material.Box>
          ) : (
            ""
          )}
        </>
      ) : (
        <Material.Box sx={{ marginTop: "20px" }}>
          <Material.Typography color="error">
            Replacement Can Orders Left
          </Material.Typography>
        </Material.Box>
      )}
      <Material.Box
      // sx={{
      //   display: "flex",
      //   flexDirection: "row",
      //   justifyContent: "space-around",
      //   flexWrap: "wrap",
      //   overflow: "auto",
      //   height: "500px",
      // }}
      >
        {/* {supplierDeliveries.getSupplierReplacementCanOrders.length > 0 ? (
          <>
            {" "}
            {supplierDeliveries.getSupplierReplacementCanOrders.map(
              (data, key) => {
                return (
                  <ReplacementCanorderCard
                    key={key}
                    order_id={data.order_id}
                    number_Of_cans={data.replace_can_qty}
                    cans_recived={data.accepted_can_qty}
                    cans_exachanged={data.exchanged_can_qty}
                    container_capacity={data.container_capacity}
                    container_mayerial={data.container_material}
                    delivery_agent_id={data.delivery_agent_id}
                    order_status={data.order_status}
                    handleButtonClick={handleDataButtonClick}
                    handleOpenAssignDeliveryAgentDialog={
                      handleOpenAssignDeliveryAgentDialog
                    }
                  />
                );
              }
            )}
          </>
        ) : (
          <Material.Typography sx={{ fontWeight: "bold" }}>
            Replacement Can Orders Left
          </Material.Typography>
        )} */}
        {enterOtpOpenDialog ? (
          <SupplierOrdersEnterOtpDialog
            isDialogOpen={enterOtpOpenDialog}
            handleClose={handleCloseDialog}
            handleSubmitOtp={handleSubmitOtp}
            otpValidationError={otpValidationError}
            setOtpValidationError={setOtpValidationError}
            enterOtpTextfied={enterOtpTextfied}
            setEnterOtpTextfied={setEnterOtpTextfied}
          />
        ) : null}
        <ReplacementCameraDialog
          openCameraDialog={openCameraDialog}
          setOpenCameraDialog={setOpenCameraDialog}
          openQrBarCodeCamera={openQrBarCodeCamera}
          openOutScanCamera={openOutScanCamera}
          orderId={orderId}
          handleClose={handleCloseCameraDialog}
          setReplacementInScanBtn={setReplacementInScanBtn}
          setReplacementOutScanBtn={setReplacementOutScanBtn}
          setScanQrCodeDetailBtn={setScanQrCodeDetailBtn}
          pageNumber={pageNumber}
          reasonData={reasonData}
          pageSize={pageSize}
        />
        <AssignDeliveryAgentDialog
          openAssignDeliveryAgentDialog={openAssignDeliveryAgentDialog}
          agentListata={supplierDeliveries.getSupplerSupplierDeliveryAgentList}
          handleClickAssignDeliveryAgent={handleAssignDeliveryAgent}
          hadleClose={handleCloses}
        />
        <SupplierReplacementFilter
          openReplacementCanFilter={openReplacementCanFilter}
          agentListata={supplierDeliveries.getAssignDeliveryAgent}
          handleFilter={handleFilter}
          handleClose={handleClose}
        />
        <ReplacementReasonDialog
          openInScanReasonDialog={openInScanReasonDialog}
          handleInScan={handleInScan}
          handleClose={handleCloseReasonDialog}
        />
        {/* {openQrBarCodeCamera === true ? (
        <>
          {" "}
          <Html5QrcodePlugin1
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult}
          />
        </>
      ) : (
        ""
      )}
      {openOutScanCamera === true ? (
        <>
          {" "}
          <Html5QrcodePlugin1
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResultQrCode}
          />
        </>
      ) : (
        ""
      )}

      <ResultContainerPlugin1
        results={decodedResults}
        setScanCount={setScanCount}
      /> */}
        {/* <Material.Box>
        <QrBarcodeLinkingDialog
          setLinkingDialogOpen={setLinkingDialogOpen}
          linkingDialogOpen={linkingDialogOpen}
          scannedQrCode={scannedQrCode}
          scannedBarCode={scannedBarCode}
          setLink={setLink}
          scanCount={scanCount}
          setLinkQrBarcodeBtn={setLinkQrBarcodeBtn}
          inQrBarcodeAdmin={inQrBarcodeAdmin}
        />
      </Material.Box> */}
      </Material.Box>
    </Material.Box>
  );
}

export default ReplacementCansOrders;
