import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const GetIotDevices = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/getdevices`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_IOT_DEVICES,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetIotDevice = (deviceId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/getdevice/${deviceId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_IOT_DEVICE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdateIotDevice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/updatetankerdevice`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_IOT_DEVICE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const AddIotDevice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/admin/addIoTtoWfsLine`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_IOT_DEVICE,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (response.status === httpStatus.BAD_REQUEST) {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
