import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import moment from "moment";
import React from "react";

function InfluencerSettlementDialog(props) {
  const [addressnamePhone, setAddressnamePhone] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [paymentMode, setPaymentMode] = React.useState("");
  const [issudeFrom, setIssudeFrom] = React.useState("");
  const [fromDate, setFromDate] = React.useState(moment().format("YYYY-MM-DD"));

  React.useEffect(() => {
    if (props.addressDetails && props.addressDetails.length > 0) {
      setAddressnamePhone(() => props.addressDetails[0].address_id);
    }
  }, [props.addressDetails]);

  React.useEffect(() => {
    if (props.accountDetails && props.accountDetails.length > 0) {
      setAccountNumber(props.accountDetails[0].account_id);
    }
  }, [props.accountDetails]);

  const handleChangeACcount = (event) => {
    setAccountNumber(event.target.value);
  };
  const handleChangeAddressNamePhone = (event) => {
    setAddressnamePhone(event.target.value);
  };
  const handleSubmitSettlement = () => {
    props.handleSubmitSettlement({
      issued_from: issudeFrom,
      payment_mode: paymentMode,
      account_id: accountNumber,
      address_id: addressnamePhone,
      date: fromDate,
    });
  };
  const handleCloseSettlement = () => {
    props.handleCloseSettlement();
  };
  return (
    <Dialog open={props.openSettlementDialog}>
      <DialogContent>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ mb: 1 }}
              onClick={handleCloseSettlement}
            >
              <Close />
            </Button>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <TextField
              label="Issude From"
              size="small"
              value={issudeFrom}
              onChange={(event) => setIssudeFrom(event.target.value)}
            />
            <TextField
              label="Payment Mode"
              size="small"
              value={paymentMode}
              onChange={(event) => setPaymentMode(event.target.value)}
            />
            <FormControl sx={{ width: 230 }} size="small">
              <InputLabel>Select Address</InputLabel>
              <Select
                value={addressnamePhone}
                label="Select Address"
                onChange={handleChangeAddressNamePhone}
              >
                {props.addressDetails?.map((data) => {
                  return (
                    <MenuItem value={data.address_id}>
                      {data.contact_name}: {data.pincode}-{data.service_area}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 230 }} size="small">
              <InputLabel>Select A/c Number</InputLabel>
              <Select
                value={accountNumber}
                label="Select A/c Number"
                onChange={handleChangeACcount}
              >
                {props.accountDetails?.map((data) => {
                  return (
                    <MenuItem value={data.account_id}>
                      {data.account_number}/{data.bank_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              label="Select Date"
              variant="standard"
              value={fromDate}
              type="date"
              onChange={(e) => setFromDate(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={() => handleSubmitSettlement()}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default InfluencerSettlementDialog;
