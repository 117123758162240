import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import WfsOngoingOrders from "./WfsOngoingOrders";
import WfsCompletedOrders from "./WfsCompletedOrders";
import WfsAllOrders from "./WfsAllOrders";
import SearchOrderCard from "./SearchOrderCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GetWfsCompletedBatchDetails } from "../Redux/Actions/adminWfsCanOrdersAction";
import Pagination from "@mui/material/Pagination";
import CustomConsole from "../../CustomConsole";
import {
  GetAllOrdersWfsInAdmin,
  GetCompletedOrdersWfsInAdmin,
  GetOngoingOrdersWfsInAdmin,
  GetUserDetails,
  PutWfsEditBatchDetails,
} from "../Redux/Actions/adminWfsCanOrdersAction";
import { toast } from "react-toastify";
import { Alert, Button, Grid } from "@mui/material";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { fontSize } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  GetWfsOngoingBatch,
  WfsCreateBatchAction,
} from "../Redux/Actions/wfsOnGoingBatchAction";
import { useLocation } from "react-router-dom";
import WfsUserDetailsCard from "../../Components/Cards/WfsUserDetailsCard";
import CreateBarchDialog from "../../Components/DialogBoxes/WfsCanOrdersDialog/CreateBarchDialog";
import WfsCompletedBatchDetails from "./WfsCompletedBatchDetails";
import {
  GetAssignedWfsDetails,
  GetUserStockDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import UserStockDetails from "./UserStockDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import WfsDetailsCard from "../../Components/Cards/WaterFillingStationDetails";
import WfsEmptyCanOrderList from "./WFSemptyCanOrders";

//User & Orders & Batch Details
function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "85vw" }}
      role="TabPanel1"
      hidden={value !== index}
      id={`full-width-TabPanel1-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel1.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps1(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-TabPanel1-${index}`,
  };
}

//Orders Details

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

//Batch Details

function TabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "85vw" }}
      role="TabPanel2"
      hidden={value !== index}
      id={`full-width-TabPanel2-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-TabPanel2-${index}`,
  };
}

// Main Component
export default function WfsCanOrdersAdmin() {
  // State Hooks
  const theme = useTheme();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [userId, setUserId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [searchOrderFlag, setSearchOrderFlag] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loadingWfsFlag, setLoadingWfsFlag] = useState(false);
  const [userDetailShow, setUserDetailShow] = useState(false);
  const [disablePage, setDisablePage] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);
  const [pgNation, setPgNation] = useState(false);
  const [pgVisible, setPgVisible] = useState(false);
  const [startsubmissionmsg, setStartsubmissionmsg] = useState("");
  const [adminRoles, setAdminRoles] = useState("");
  const [showDetCard, setShowDetCard] = useState(false);
  const [editOpenDialog, setEditOpenDialog] = useState(false);
  const [isCreateBatchDialogOpen, setIsCreateBatchDialogOpen] = useState(false);
  const [firstTabvalue, setFirstTabvalue] = useState("1");
  const [batchDetailsTabvalue1, setBatchDetailsTabvalue1] = useState("1");
  const [pageTitle, setPageTitle] = useState("");
  const wfsStockDetails = useSelector((state) => state.userDetailsUpdate);
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  // Redux
  const dispatch = useDispatch();
  const adminWfsCanOrdersData = useSelector(
    (state) => state.adminWfsCanOrdersData
  );
  const wfsBatchDetails = useSelector((state) => state.wfsOngoingBatchDetails);
  const wfsDetails = useSelector((state) => state.wfsDetails);

  // Location
  const { pathname } = useLocation();

  // Redirecting from orders page and getting the phone number from URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumberFromOrders = searchParams.get("phoneNumber");
  const wfsId = searchParams.get("wfs");
  CustomConsole(wfsStockDetails);
  //function for handling index operation
  const handleChangeIndex1 = (index) => {
    setValue1(index);
  };
  // Setting the phone number and hitting the API for search user with the phone number from URL(Redirecting from orders page)
  useEffect(() => {
    setPhoneNumber(phoneNumberFromOrders);
    if (phoneNumberFromOrders !== null) {
      searchBtn(phoneNumberFromOrders);
    }
  }, [phoneNumberFromOrders]);

  // Toast on disable page
  useEffect(() => {
    if (disablePage === true) {
      toast.error("No orders left", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [disablePage]);

  // Setting roles on start submission message and adminWfsCanOrdersData changes
  useEffect(() => {
    setStartsubmissionmsg(adminWfsCanOrdersData.startSubmissionOngoing.msg);
    const rolesAdmin = parseInt(window.localStorage.getItem("roleId"), 10);
    if (rolesAdmin) {
      setAdminRoles(rolesAdmin);
    }
    return () => {
      adminWfsCanOrdersData.userDetails = [];
    };
  }, [adminWfsCanOrdersData]);

  // Check if userDetails is available and show user details card
  useEffect(() => {
    if (
      adminWfsCanOrdersData.error === false &&
      adminWfsCanOrdersData.userDetails !== undefined &&
      adminWfsCanOrdersData.userDetails.length > 0
    ) {
      setUserId(adminWfsCanOrdersData.userDetails[0].id);
      setLoadingWfsFlag(true);
      setShowDetCard(true);
    } else {
      setShowDetCard(false);
    }

    if (adminWfsCanOrdersData.error === true && searchOrderFlag) {
      toast.error(adminWfsCanOrdersData.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setSearchOrderFlag(false);
    }
  }, [adminWfsCanOrdersData]);

  // Check if there are no orders left or pagination should be visible
  useEffect(() => {
    if (adminWfsCanOrdersData.ordersCommon.length === 0 && disableFlag) {
      setDisablePage(true);
    } else {
      setDisablePage(false);
    }

    if (adminWfsCanOrdersData.ordersCommon.length > 0 && pgNation) {
      setPgVisible(true);
    }
  }, [adminWfsCanOrdersData, disableFlag]);

  // Handle search button click
  const searchBtn = (data) => {
    if (data !== "") {
      dispatch(GetUserDetails(data));
      setSearch(true);
      setUserDetailShow(true);
      setSearchOrderFlag(true);
      setPgNation(true);
      setPageNumber(1);
      setShowDetCard(true);
    } else {
      toast.error("Enter the phone number", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // State for search
  const [search, setSearch] = useState(false);

  // Fetch WFS orders data based on selected tab, page number, and user ID
  const wfsCanOrderData = () => {
    if (userId !== "") {
      if (value === 0) {
        dispatch(GetAssignedWfsDetails(userId));
        setSearch(false);
        setSearchOrderFlag(false);
        setPgVisible(false);
      }
      if (value === 4) {
        setSearchOrderFlag(false);
        setPgVisible(false);
      }
    }
  };

  // Handle change in tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPageNumber(1);
    setDisableFlag(false);
    if (newValue === 0) {
      searchBtn(phoneNumberFromOrders);
    }
  };

  // Fetch data when page number, tab value, or user ID changes
  useEffect(() => {
    wfsCanOrderData();
  }, [pageNumber, value, userId]);

  // Handle creating batch for admin
  const handleCreateBatchadmin = (props) => {
    if (
      props.Batch_Quantity !== "" &&
      props.Capacity !== "" &&
      props.Material !== "" &&
      props.LineId !== ""
    ) {
      const reqObj = {
        batch_qty: props.Batch_Quantity,
        container_capacity: props.Capacity,
        container_material: props.Material,
        line_id: props.LineId,
      };
      dispatch(WfsCreateBatchAction(reqObj));
    } else {
      toast.error("Fill All the Fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // Handle change in the first tab
  const handleChangeFirstTab = (event, newValue) => {
    setFirstTabvalue(newValue);
  };

  // Handle change in batch details tab
  const handleChangeBatchDetailsTab = (event, newValue) => {
    setBatchDetailsTabvalue1(newValue);
  };

  const handelBackWfsDetails = () => {
    history.push("/dashboard/wfsAllDetails");
    wfsStockDetails.assignedWfsStock = [];
    skuWfsDetails.wfsBatchDetails = [];
  };

  CustomConsole(wfsStockDetails);
  // JSX Structure
  return (
    <>
      {/* Search Order Card */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
          maxWidth: { xs: "100%" },
        }}
      >
        {/* {ADMIN_ROLES.BW_SUPERADMIN === adminRoles ||
        ADMIN_ROLES.BW_ADMIN === adminRoles ||
        ADMIN_ROLES.BW_QA === adminRoles ||
        ADMIN_ROLES.BW_QAHEAD === adminRoles ||
        ADMIN_ROLES.BW_CSHEAD === adminRoles ||
        ADMIN_ROLES.BW_CSLEAD === adminRoles ||
        ADMIN_ROLES.BW_OPS === adminRoles ||
        ADMIN_ROLES.BW_OPSHEAD === adminRoles ? (
          <SearchOrderCard
            searchBtn={searchBtn}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            setSearch={setSearch}
            wfsCanOrderData={wfsCanOrderData}
          />
        ) : (
          ""
        )} */}
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          bgcolor: "background.paper",
          maxWidth: { xs: "100%" },
        }}
      >
        {ADMIN_ROLES.BW_SUPERADMIN === adminRoles ||
        ADMIN_ROLES.BW_ADMIN === adminRoles ||
        ADMIN_ROLES.BW_QA === adminRoles ||
        ADMIN_ROLES.BW_QAHEAD === adminRoles ||
        ADMIN_ROLES.BW_CSHEAD === adminRoles ||
        ADMIN_ROLES.BW_CSLEAD === adminRoles ||
        ADMIN_ROLES.BW_OPS === adminRoles ||
        ADMIN_ROLES.BW_OPSHEAD === adminRoles ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: ".5rem",
                marginTop: ".5rem",
              }}
            >
              <Button
                component="label"
                size="small"
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={handelBackWfsDetails}
              >
                Back
              </Button>
            </Box>
            {/* App Bar with Tabs */}
            <AppBar
              position="static"
              sx={{
                color: "white",
                borderRadius: "10px",
                background: "#6495ED",
              }}
              variant="scrollable"
              scrollButtons={false}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="white"
                scrollButtons={false}
                variant="fullWidth"
                // variant="scrollable"
                aria-label="full width tabs example"
              >
                <Tab
                  label="User Details"
                  {...a11yProps(0)}
                  style={{ flexGrow: 1 }}
                />
                <Tab label="Orders" {...a11yProps(1)} style={{ flexGrow: 1 }} />
                <Tab
                  label="Batch Details"
                  {...a11yProps(2)}
                  style={{ flexGrow: 1 }}
                />
              </Tabs>
            </AppBar>

            {/* Swipeable Views for Tab Content */}
            {/* <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex1}
            > */}
            {/* User Details Tab */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              {adminWfsCanOrdersData.userDetails?.length > 0 ? (
                <Box>
                  {adminWfsCanOrdersData.userDetails.map(
                    ({ id, fullname, phone, email }) => (
                      <WfsUserDetailsCard
                        key={id}
                        fullnName={fullname}
                        phoneNumber={phone}
                        emaiAddress={email}
                      />
                    )
                  )}
                </Box>
              ) : (
                ""
              )}

              {Object.keys(wfsStockDetails.assignedWfsAddress).length > 0 ? (
                <Box>
                  <WfsDetailsCard
                    key={wfsStockDetails.assignedWfsAddress.owner_phone}
                    fullnName={wfsStockDetails.assignedWfsAddress.company_name}
                    phoneNumber={wfsStockDetails.assignedWfsAddress.owner_phone}
                    pincode={wfsStockDetails.assignedWfsAddress.pincode}
                  />
                </Box>
              ) : (
                ""
              )}
              <>
                <UserStockDetails userId={userId} />
              </>
            </TabPanel>

            {/* Orders Tab */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Grid container spacing={2}>
                <>
                  <WfsEmptyCanOrderList
                    userId={userId}
                    phoneNumberFromOrders={phoneNumberFromOrders}
                  />
                </>
              </Grid>
            </TabPanel>

            {/* Batch Details Tab */}
            <TabPanel value={value} index={2} dir={theme.direction}>
              {userDetailShow ? (
                <WfsCompletedBatchDetails
                  phoneNumber={phoneNumber}
                  wfsId={wfsId}
                />
              ) : (
                ""
              )}
            </TabPanel>
            {/* </SwipeableViews> */}
          </>
        ) : (
          <Box sx={{ marginTop: "10px" }}>
            <Alert severity="error">Access Denied</Alert>
          </Box>
        )}
      </Box>

      {/* Edit Batch Dialog */}
      {/* {editOpenDialog ? (
        <CompletedBatchEditDialog
          editOpenDialog={editOpenDialog}
          handleSubmit={handleSubmitEditData}
          handleClose={handleCloseDialog}
        />
      ) : (
        ""
      )} */}

      {/* Create Batch Dialog */}
      {isCreateBatchDialogOpen ? (
        <CreateBarchDialog
          isCreateBatchDialogOpen={isCreateBatchDialogOpen}
          wfsBatchDetails={wfsBatchDetails}
          handleCreatebatchAdmin={handleCreateBatchadmin}
        />
      ) : (
        ""
      )}
    </>
  );
}
