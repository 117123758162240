import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
// import CustomConsole from "../../../CustomConsole";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { GetUserBasedName } from "../Redux/Actions/userDetailsAdminAction";
import {
  GetBusinessUsers,
  GetOrdersToConsolidate,
} from "../Redux/Actions/adminPoTrackPostpaid";
import ConsolidatePoDialog from "./ConsolidatePoDialog";
import CommonSettleDialog from "./CommonSettleDialog";
import { SearchUserAdmin } from "../Redux/Actions/addUserAdminAction";
import CustomConsole from "../../CustomConsole";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { GetCreditNoteDetails } from "../Redux/Actions/buyBackOrdersAction";

function ConsolidatePOSettle() {
  const dispatch = useDispatch();
  const histroy = useHistory();
  const userName = useSelector((state) => state.userDetailsUpdate.userNames);
  const poDetails = useSelector(
    (state) => state.poTrackDetails.consolidatePOOrders
  );
  const businessUsersData = useSelector(
    (state) => state.poTrackDetails.businessUsers
  );

  const [selected, setSelected] = React.useState(new Set());
  const [poOrders, setPoOrders] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [creditDetails, setCreditDetails] = React.useState([]);
  const [nameSearch, setNameSearch] = React.useState([]);
  const [getName, setGetName] = React.useState("");
  const [getPhone, setGetPhone] = React.useState("");
  const [selectnumber, setSelectNumber] = React.useState("");
  const [orderIdArr, setOrderIdArr] = React.useState([]);
  const [totalSettledOrderAmt, setTotalSetttledOrderAmt] = React.useState(0);
  const [totalUnSettledOrderAmt, setTotalUnSetttledOrderAmt] =
    React.useState(0);
  const [customerName, setCustomername] = React.useState("");
  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const [selectedOrderDet, setSelectedOrders] = React.useState([]);
  const [settleDialogOpen, setSettleDialogOpen] = React.useState(false);
  const [businessusers, setBusinessUsers] = React.useState([]);
  const [totalCreditAmt, setTotalCreditAmt] = React.useState(0);
  const calculateSettledOrders = (orders) => {
    const settledUnsettledCount = orders.reduce(
      (accumulator, currentValue) => {
        if (currentValue.settled) {
          accumulator.settled++;
        } else {
          accumulator.unSettled++;
        }
        return accumulator;
      },
      { settled: 0, unSettled: 0 }
    );
    return settledUnsettledCount;
  };

  const calculateSettledAmount = (orders) => {
    const settledUnsettledAmt = orders.reduce(
      (accumulator, currentValue) => {
        if (currentValue.settled) {
          CustomConsole(currentValue.final_price);
          accumulator.settledAmt +=
            currentValue.final_price +
            currentValue.ppDeliveryCharge * currentValue.twentyltrqty;
        } else {
          accumulator.unSettledAmt +=
            currentValue.final_price +
            currentValue.ppDeliveryCharge * currentValue.twentyltrqty;
        }
        return accumulator;
      },
      { settledAmt: 0, unSettledAmt: 0 }
    );
    return settledUnsettledAmt;
  };

  const handleSelect = (id, orderDetails) => {
    const newSelected = new Set(selected);
    CustomConsole(newSelected);
    let orderArray = orderDetails.map((obj) => obj.order_id);
    CustomConsole("-----Order array----");
    CustomConsole(orderArray);
    if (newSelected.has(id)) {
      newSelected.delete(id);
      setOrderIdArr((prevOrders) =>
        prevOrders.filter((values) => !orderArray.includes(values))
      );
    } else {
      newSelected.add(id);
      setOrderIdArr((orders) => [...orders, ...orderArray]);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.has(id);

  const selectAllFn = () => {
    if (selected.size === poOrders.length) {
      setSelected(new Set());
      setOrderIdArr([]);
    } else {
      const allSelect = new Set(poOrders.map((obj) => obj.pp_track_id));

      setSelected(allSelect);
      setOrderIdArr(() => {
        return poOrders.reduce((acc, item) => {
          return acc.concat(
            item.order_details.map((details) => details.order_id)
          );
        }, []);
      });
    }
  };

  const handleFromDate = (newValue) => {
    if (newValue !== null && newValue !== null) {
      setFromDate(moment(newValue.$d).format("YYYY-MM-DD"));
    } else {
      setFromDate(null);
    }
  };

  const handleToDate = (newValue) => {
    if (newValue !== null && newValue !== null) {
      setToDate(moment(newValue.$d).format("YYYY-MM-DD"));
    } else {
      setToDate(null);
    }
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (getPhone) {
      dispatch(GetOrdersToConsolidate(getPhone, fromDate, toDate));
      dispatch(SearchUserAdmin(getPhone));
      dispatch(
        GetCreditNoteDetails({
          phone: getPhone,
          order_status: 100,
          page_size: 100,
        })
      ).then((data) => {
        console.log("......data");
        console.log(data);
        if (!data?.error) {
          setCreditDetails(data?.data);
        }
      });
    }
  };

  const handleSelectUser = (e) => {
    setSelectNumber(e.target.value);
    setGetPhone(e.target.value);
  };

  const getOrderDetails = () => {
    setOrderDialogOpen(true);

    // dispatch(GetViewOrderDetails(orderIdArr));
  };

  React.useEffect(() => {
    dispatch(GetBusinessUsers());
  }, []);

  React.useEffect(() => {
    CustomConsole("------User Search details-----");
    CustomConsole(userName);
    if (userName) {
      setNameSearch(userName);
    } else {
      setNameSearch([]);
    }
  }, [userName]);

  React.useEffect(() => {
    if (getName && getName.length) {
      const timerId = setTimeout(() => {
        CustomConsole(getName);
        const reqObj = {
          name: getName,
          ignore_app_loading_spinner: true,
        };
        dispatch(GetUserBasedName(reqObj));
      }, 200);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [getName]);

  React.useEffect(() => {
    CustomConsole("------Po data----");
    CustomConsole(poDetails);

    if (poDetails) {
      setPoOrders(poDetails);
      setSelected(new Set());
      let totalSettled = poDetails.reduce((acc, item) => {
        return (
          acc +
          item.order_details.reduce((subAcc, subItem) => {
            if (subItem.settled) {
              return (
                subAcc +
                subItem.final_price +
                subItem.ppDeliveryCharge * subItem.twentyltrqty
              );
            }
            return subAcc;
          }, 0)
        );
      }, 0);
      let totalUnsettled = poDetails.reduce((acc, item) => {
        return (
          acc +
          item.order_details.reduce((subAcc, subItem) => {
            if (!subItem.settled) {
              return (
                subAcc +
                subItem.final_price +
                subItem.ppDeliveryCharge * subItem.twentyltrqty
              );
            }
            return subAcc;
          }, 0)
        );
      }, 0);
      setTotalSetttledOrderAmt(totalSettled);

      setTotalUnSetttledOrderAmt(totalUnsettled);

      setCustomername(poDetails[0]?.consumername);
    } else {
      setPoOrders([]);
    }
  }, [poDetails]);

  React.useEffect(() => {
    if (businessUsersData) {
      CustomConsole("---------Users businesss----");
      CustomConsole(businessUsersData);
      setBusinessUsers(businessUsersData);
    }
  }, [businessUsersData]);

  React.useEffect(() => {
    CustomConsole("-----Changing order id-----");
    CustomConsole(orderIdArr);
  }, [orderIdArr]);

  React.useEffect(() => {
    if (selected && selected.size > 0) {
      const selectedOrders = poDetails
        .filter((orders) => selected.has(orders.pp_track_id))
        .flatMap((order) => order.order_details);
      setSelectedOrders(selectedOrders);
    }
  }, [selected, poDetails]);

  //function to handel settlement page
  const handelSetlementPage = () => {
    histroy.push("/dashboard/postpaidsettlement");
  };

  //useEffect to calculate settlement amount
  React.useEffect(() => {
    console.log("creditDetails................");
    console.log(creditDetails);
    if (creditDetails?.length) {
      const totalCredit = creditDetails.reduce((acc, value) => {
        const amount = parseFloat(value.pending_amount);
        return !isNaN(amount) ? acc + amount : acc;
      }, 0); // Initialize acc to 0

      console.log("totalCredit...........");
      console.log(totalCredit);
      setTotalCreditAmt(totalCredit);
    }
  }, [creditDetails]);
  return (
    <Box sx={{ position: "relative" }}>
      {poOrders.length > 0 ? (
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Card sx={{ padding: "10px", backgroundColor: "#D4EBF6" }}>
                Customer name: <strong>{customerName}</strong>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card sx={{ padding: "10px", backgroundColor: "#D4EBF6" }}>
                Total unsettled amt. &#8377;:{" "}
                <strong>{totalUnSettledOrderAmt.toFixed(2)}</strong>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card sx={{ padding: "10px", backgroundColor: "#D4EBF6" }}>
                Total settled amt. &#8377;:{" "}
                <strong>{totalSettledOrderAmt.toFixed(2)}</strong>
              </Card>
            </Grid>
            {totalCreditAmt > 0 && (
              <Grid item md={4}>
                <Card sx={{ padding: "10px", backgroundColor: "#D4EBF6" }}>
                  Available Credit Balance. &#8377;:{" "}
                  <strong>{totalCreditAmt}</strong>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      ) : null}

      <form onSubmit={(e) => onSubmitSearch(e)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  label="From"
                  value={fromDate}
                  onChange={handleFromDate}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  label="To"
                  value={toDate}
                  onChange={handleToDate}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="select-business-label">Select User</InputLabel>
              <Select
                labelId="select-business-label"
                id="select-business-user-small"
                value={selectnumber}
                label="Select User"
                onChange={handleSelectUser}
              >
                {businessusers.map((obj, idx) => {
                  return (
                    <MenuItem key={idx} value={obj.phone}>
                      {obj.fullname}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <Autocomplete
              id="select-user-name-consolidate"
              sx={{ width: 210 }}
              options={nameSearch}
              autoHighlight
              getOptionLabel={(nameSearch) => `${nameSearch.fullname}`}
              onChange={(_, newValue) => {
                setGetPhone(newValue ? newValue.phone : ""); // Set the selected description as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  key={nameSearch.phone}
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.fullname} {nameSearch.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  key={params.phone}
                  label="Name"
                  value={setGetName}
                  onChange={(e) => {
                    setGetName(e.target.value);
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Box>
          <Box>
            <Divider sx={{ color: "black" }} orientation="vertical" flexItem />
          </Box>
          <Box>
            {" "}
            <Button
              // onClick={() => setSettleDialogOpen(true)}
              onClick={() => handelSetlementPage()}
              variant="outlined"
            >
              Settlement
            </Button>
          </Box>
        </Box>
      </form>

      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Sl.no</TableCell>
                <TableCell align="left">Customer</TableCell>
                <TableCell align="left">PO</TableCell>
                <TableCell align="left">Supplier</TableCell>
                <TableCell align="left">Start Date</TableCell>
                <TableCell align="left">End Date</TableCell>
                <TableCell align="left">Delivered Orders</TableCell>
                <TableCell align="left">Settlement</TableCell>
                <TableCell align="left">Total Settled Amt.</TableCell>
                <TableCell align="left">Total Unsettled Amt.</TableCell>
                <TableCell align="left">
                  <Checkbox
                    indeterminate={
                      selected.size > 0 && selected.size < poOrders.length
                    }
                    checked={selected.size === poOrders.length}
                    onChange={selectAllFn}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {poOrders.map((row, idx) => (
                <TableRow
                  key={row.pp_track_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {idx + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.consumername}
                  </TableCell>
                  <TableCell align="left">{row.pp_track_id}</TableCell>
                  <TableCell align="left">{row.supplier_name}</TableCell>
                  <TableCell align="left">
                    {moment(row.start_date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {moment(row.end_date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {row.order_details ? row.order_details.length : 0}
                  </TableCell>
                  <TableCell align="left">
                    <Typography>
                      Settled Orders:
                      {calculateSettledOrders(row.order_details)?.settled}
                    </Typography>
                    <Typography>
                      Unsettled Orders:
                      {calculateSettledOrders(row.order_details)?.unSettled}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {calculateSettledAmount(
                      row.order_details
                    )?.settledAmt.toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    {calculateSettledAmount(
                      row.order_details
                    )?.unSettledAmt.toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    <Checkbox
                      checked={isSelected(row.pp_track_id)}
                      onChange={() =>
                        handleSelect(row.pp_track_id, row.order_details)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {orderIdArr && orderIdArr.length > 0 ? (
        <Fab
          sx={{ mr: 1, position: "fixed", bottom: 16, right: 16 }}
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          onClick={getOrderDetails}
        >
          Get Details
        </Fab>
      ) : null}
      <ConsolidatePoDialog
        open={orderDialogOpen}
        setOpen={setOrderDialogOpen}
        selectedOrders={selectedOrderDet}
        poTrack={selected}
      />
      <CommonSettleDialog
        open={settleDialogOpen}
        setOpen={setSettleDialogOpen}
      />
    </Box>
  );
}

export default ConsolidatePOSettle;
