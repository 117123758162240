import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAvailableProducts } from "../../Redux/Actions/SKUProductDetailsAction";

const CreateTrip = ({ open = false, setOpen }) => {
  const [qrlessAvailableProducts, setQrlessAvailableProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);

  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (skuContainerDetails?.getAvailableProducts.length > 0) {
  //     let qrLessProducts = skuContainerDetails?.getAvailableProducts?.filter(
  //       (data) => data.product_details.is_qr === false
  //     );
  //     console.log(qrLessProducts);
  //     setQrlessAvailableProducts(qrLessProducts);
  //   }
  // }, [skuContainerDetails]);
  // useEffect(() => {
  //   supplierDeliveries.warehouseStockMovement = {};
  //   // if (openCameraDialog) {
  //   dispatch(GetAvailableProducts());
  //   // }
  // }, [supplierDeliveries.warehouseStockMovement]);
  return (
    <Dialog sx={{ width: "100%" }} open={open}>
      <DialogTitle>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          Create Trip
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
            <FormControl sx={{ width: 220 }} size="small">
              <InputLabel id="demo-select-small-label">
                Select Product
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedProduct}
                label="Select Product"
                onChange={(e) => setSelectedProduct(e.target.value)}
                sx={{
                  color: "black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                  },
                }}
              >
                {qrlessAvailableProducts?.map((option) => (
                  <MenuItem
                    key={option.product_details.id}
                    value={option.product_details.id}
                  >
                    {option.product_details.name}/Material:
                    {option.product_details.material}/Capacity:
                    {option.product_details.capacity}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex" }}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Filled Qty"
              />
              <Button onClick={() => setSelectedProduct()}>Add</Button>
            </Box>
          </Box>
          <Box>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="demo-multiple-name-label">
                Select Delivery Slot
              </InputLabel>
              <Select
                value={0}
                name="Delivery Slot"
                required
                label="Select Delivery Slot"
                fullWidth
              >
                <MenuItem value={0}>12:00-15:00</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTrip;
