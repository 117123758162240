import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Chip, Divider, Link } from "@mui/material";
import { Call, ContentCopy, Place } from "@mui/icons-material";

export default function BuyBackOrderDetailsCard({
  buyBackOrderDetails,
  handlePhone,
  handleLocation,
  handleCopyAddress,
  handleViewDetails
}) {
  const cardStyles = {
    cardBody: {
      display: "flex",
      justifyContent: "space-evenly",
      gap: 2,
      flexWrap: "wrap",
      fontSize: "1rem",
    },
    headerContent: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: ".5rem",
    },
    productStyles: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      height: "3rem",
    },
  };

  return (
    <>
      <Box sx={cardStyles.cardBody}>
        {buyBackOrderDetails?.map((data) => (
          <Card sx={{ width: "18rem", boxShadow: 5, borderRadius: "1rem" }}>
            <CardContent>
              <Box sx={cardStyles.headerContent}>
                <Box
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  <Chip
                    label={data?.order_details?.order_id}
                    // onClick={handleViewDetails}
                    sx={{
                      bgcolor: "#924EEF",
                      color: "white",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Call
                    onClick={() => handlePhone(data)}
                    sx={{ fontSize: "25px" }}
                  />
                  <Place
                    onClick={() => handleLocation(data)}
                    color="primary"
                    sx={{ maxHeight: "25px", marginLeft: "15px", color: "red" }}
                  />
                </Box>
              </Box>
              <Divider />
              <Box>
                <Box sx={cardStyles.productStyles}>
                  <Typography sx={{ fontSize: ".9rem" }}>
                    Product :{" "}
                    {`${data?.order_details?.products[0]?.container_capacity}-${data?.order_details?.products[0]?.container_material}`}
                  </Typography>
                  <Typography sx={{ fontSize: ".9rem" }}>
                    Quantity : {data?.order_details?.products[0]?.order_qty}
                  </Typography>
                </Box>
                {data?.order_details?.products?.length > 1 && (
                  <Box>
                    <Link>
                      View {data?.order_details?.products?.length} Products
                    </Link>
                  </Box>
                )}
              </Box>
              <Divider />
              <Box sx={{ paddingLeft: "7px" }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: ".9rem",
                      textAlign: "start",
                      // fontWeight: "bold",
                    }}
                  >
                    {data?.delivery_address?.contact_name},
                    {data?.delivery_address?.contact_phone}{" "}
                    {/* <MuiIcons.Call onClick={handlePhone} sx={{ fontSize: "15px" }} /> */}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: ".9rem",
                      textAlign: "start",
                      overflow: "hidden",
                    }}
                  >
                    {data?.delivery_address?.address_line1},
                    {data?.delivery_address?.address_line2},
                    {data?.delivery_address?.address_line3}
                  </Typography>
                  {/* <Typography sx={{ fontSize: ".9rem", textAlign: "start" }}>
              {data.delivery_address.address_line3}
            </Typography> */}
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: ".9rem",
                        textAlign: "start",
                        paddingTop: "1px",
                      }}
                    >
                      {data?.delivery_address?.landmark},
                      {data?.delivery_address?.service_area},
                      {data?.delivery_address?.pincode}{" "}
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <ContentCopy
                      onClick={() => handleCopyAddress(data)}
                      sx={{ fontSize: "19px", marginLeft: "15px" }}
                    />{" "}
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <CardActions
              sx={{ display: "flex", justifyContent: "center",  }}
            >
              <Box>
                <Button
                  size="small"
                  variant="contained"
                    onClick={()=>handleViewDetails(data?.order_details?.order_id)}
                  sx={{ borderRadius: "20%" }}
                >
                  View Details
                </Button>
              </Box>
            </CardActions>
          </Card>
        ))}
      </Box>
    </>
  );
}
