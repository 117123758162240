import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";

function RemoveWaterDropsDialog(props) {
  const [reasonForRemoveWaterDrops, setReasonForRemoveWaterDrops] =
    React.useState("");
  const [enterWaterDrops, setEnterWaterDrops] = React.useState(null);

  const handleRemoveWaterDrops = () => {
    CustomConsole("------------handleRemoveWaterDrops-------------");
    props.handleRemoveWaterDrops({
      enterWaterDrops: enterWaterDrops,
      setEnterWaterDrops: setEnterWaterDrops,
      reasonForRemoveWaterDrops: reasonForRemoveWaterDrops,
      setReasonForRemoveWaterDrops: setReasonForRemoveWaterDrops,
    });
  };

  const handleCloseRemoveWaterDrops = () => {
    props.handleCloseRemoveWaterDrops();
  };

  return (
    <Material.Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        },
      }}
      open={props.openRemoveWaterDropsDialog}
    >
      <Material.DialogContent>
        <Material.Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          REMOVE WATER DROPS
        </Material.Typography>
        <Material.Box
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Material.TextField
            id="outlined-basic"
            label="Enter Water Drops"
            variant="outlined"
            color="secondary"
            onKeyDown={(e) => {
              if (!/^([0-9]\b)+$/.test(e.key)) {
                e.preventDefault();
              }
              if (e.key === "Backspace") {
                setEnterWaterDrops((preAmount) => preAmount.slice(0, -1));
              }
            }}
            value={enterWaterDrops}
            size="small"
            onChange={(event) => setEnterWaterDrops(event.target.value)}
          />

          <Material.TextField
            id="outlined-basic"
            label="Reason For Removing Water Drops *"
            variant="outlined"
            color="secondary"
            value={reasonForRemoveWaterDrops}
            size="small"
            onChange={(event) => setReasonForRemoveWaterDrops(event.target.value)}
          />
        </Material.Box>
        <Material.Box
          sx={{ display: "flex", gap: "10px", m: 1, justifyContent: "center" }}
        >
          <Material.Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleCloseRemoveWaterDrops}
          >
            Close
          </Material.Button>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handleRemoveWaterDrops}
          >
            REMOVE Now
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default RemoveWaterDropsDialog;
