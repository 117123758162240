import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  CancelOrderDetails,
  CancelResonDetails,
  GetNewSearchOrderDetails,
  GetViewOrderDetails,
} from "../Redux/Actions/ordersAdminAction";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
export default function CancelReasonCard({
  CancelFilterDialog,
  setCancelFilterDialog,
  orderDetails,
  newOrderDetails,
  Canceldata,
  setOrderFlag,
}) {
  const dispatch = useDispatch();
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);
  const [RejectOrderFlag, setRejectOrderFlag] = React.useState(true);
  const [orderId, setOrderId] = React.useState(newOrderDetails[0]?.order_id);
  const [change, setChange] = React.useState({
    cancel_reason: "",
    cancel_reason_type: "",
  });
  CustomConsole(change);
  const handleOtherReasonChange = (e) => {
    // setChange({
    //   cancel_reason: e.target.value,
    //   cancel_reason_type: change.cancel_reason_type,
    // });
    setChange(e.target.value);
  };
  // React.useEffect(() => {
  //   CustomConsole(orderDetailsData);
  //   if (
  //     RejectOrderFlag === true &&
  //     orderDetailsData.cancelDetails.error === false
  //   ) {
  //     dispatch(GetViewOrderDetails(orderId));
  //     setRejectOrderFlag(false);
  //   }
  // }, [orderDetailsData]);
  const handleClose = (event) => {
    event.preventDefault();
    setCancelFilterDialog(false);
    setChange("");
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   setRejectOrderFlag(true);
  //   if (RejectOrderFlag === true) {
  //     if (
  //       change.cancel_reason_type === "999" &&
  //       change.cancel_reason === "Other Reasons"
  //     ) {
  //       toast.dark("Enter Reason for canceling", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         type: "error",
  //       });
  //     } else {
  //       dispatch(CancelResonDetails(orderId, change));
  //       setTimeout(() => {
  //         dispatch(GetViewOrderDetails(orderId));
  //       }, 1000);
  //       setCancelFilterDialog(false);
  //       setOrderFlag(true);
  //     }
  //   }
  //   setCancelFilterDialog(false);
  // };
  const handleSubmit = (event) => {
    event.preventDefault();
    // setRejectOrderFlag(true);
    const reqObj = {
      order_id: orderId,
      reason: change,
    };
    CustomConsole(reqObj);
    dispatch(CancelOrderDetails(reqObj));
    setTimeout(() => {
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 30,
        page_number: 1,
        order_ids: [orderId],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
    }, 1000);
    setCancelFilterDialog(false);
    // setOrderFlag(true);

    setCancelFilterDialog(false);
    setChange("");
  };
  const handelChange = (event) => {
    setChange({
      cancel_reason: event.target.name,
      cancel_reason_type: event.target.value,
    });
  };

  return (
    <div>
      <Dialog
        open={CancelFilterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Box>
              <TextField
                type="text"
                label="Enter Cancel Reason"
                helperText="Enter Minimum 5 characters"
                size="small"
                onChange={handleOtherReasonChange}
              />
            </Box>

            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={handleClose}
                sx={{ marginRight: "1rem" }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleSubmit}
              >
                Cancel Order
              </Button>
            </Box>
          </Box>
          {/* <Box
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Box>
              <FormControl onChange={handelChange}>
                <FormLabel>CancelREASON</FormLabel>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  {Canceldata.map((data, index) => (
                    <FormControlLabel
                      key={index}
                      value={data.k}
                      control={<Radio />}
                      label={data.v}
                      name={data.v}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              {change.cancel_reason_type === "999" ? (
                <TextField
                  type="text"
                  label="Enter Reason"
                  size="small"
                  onChange={handleOtherReasonChange}
                />
              ) : null}
            </Box>

            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={handleClose}
                sx={{ marginRight: "1rem" }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleSubmit}
              >
                Cancel Order
              </Button>
            </Box>
          </Box> */}
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
