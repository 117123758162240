import React from "react";
import * as Material from "@mui/material";
import userLogo from "../../Components/Assests/Images/userLogo.png";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";
import { toast } from "react-toastify";
import {
  DeleteUser,
  DisableUserAdmin,
  EnableUserAdmin,
  GetProfileDetails,
  UpdateUserDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { useDispatch } from "react-redux";
import { BrowserUpdated, DeleteOutlined } from "@mui/icons-material";
import DeleteUserDialog from "../../Components/Dialog/DeleteUserDialog";

function OtherUsers(props) {
  const dispatch = useDispatch();
//   const [props.isSwitchOn,  props.setIsSwitchOn] = React.useState(true);
  const [changeInSwitch, setChangeInSwitch] = React.useState(false);
  const [distributorType, setDistributorType] = React.useState("");
  const [updateUserBtn, setUpdateUserBtn] = React.useState(false);
  const [customPrice, setCustomPrice] = React.useState("");
  const [twntyLtrDiscount, setTwntyLtrDiscount] = React.useState(0);
  const [deleteUserDialog, setDeleteUserDialog] = React.useState(false);
  React.useEffect(() => {
    CustomConsole("--------updateUserDetails---------");
    CustomConsole(props.updateUserDetails);
    // CustomConsole(updateUserDetails.userProfileData.length);
    // CustomConsole(updateUserDetails.userProfileData.consumer);
    if (props.updateUserDetails.error === false) {
      //   setIsrevertbackenabled(props.updateUserDetails.is_revert_back_enabled);
      // CustomConsole(updateUserDetails.userProfileData.length);
      // CustomConsole(Object.keys(updateUserDetails.userProfileData).length);
      if (
        props.updateUserDetails.userProfileData !== undefined &&
        props.updateUserDetails.userProfileData !== null &&
        Object.keys(props.updateUserDetails.userProfileData).length > 0
      ) {
        CustomConsole("--------updateUserDetails---------");
        CustomConsole(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        CustomConsole(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        setTwntyLtrDiscount(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        setCustomPrice(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        CustomConsole("--------custom_twentyltr_price---------");
      }
      setUpdateUserBtn(false);
      // setUpdateFlagCheck(false);
    }
    if (props.updateUserDetails.error === false && updateUserBtn === true) {
      CustomConsole("Profile");

      CustomConsole("Profile");
      dispatch(GetProfileDetails(props.userId));
      setUpdateUserBtn(false);
    }
    // setTwntyLtrDiscount("");
    // setCustomPrice("");
  }, [props.updateUserDetails]);
  React.useEffect(() => {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var result = reg.test(props.email);
    var emailResult = false;
    if (props.roleId === 3000) {
      let regEmail = /^[^@]+@bookwater\.com$/;
      emailResult = regEmail.test(props.email);
    }
    if (updateUserBtn === true) {
      CustomConsole(parseInt(twntyLtrDiscount) < parseInt(customPrice));
      if (
        props.phone.length === 10 &&
        result === true &&
        props.fullname.trim() !== "" &&
        emailResult === false
      ) {
        dispatch(
          UpdateUserDetails(
            props.fullname,
            props.phone,
            props.email,
            props.roleId,
            twntyLtrDiscount || 0,
            props.userId,
            distributorType || undefined,
            customPrice
          )
        );
      } else {
        toast.error("Please update valid details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUpdateUserBtn(false);
      }

      setUpdateUserBtn(false);
    }
  }, [updateUserBtn]);
  React.useEffect(() => {
    if (changeInSwitch === true && props.isSwitchOn === true) {
      toast.success(props.updateUserDetails.enableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
    if (changeInSwitch === true && props.isSwitchOn === false) {
      toast.success(props.updateUserDetails.disableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
  }, [props.updateUserDetails]);
  const handleUpdateUserDetails = () => {
    setUpdateUserBtn(true);
    setTimeout(() => {
      dispatch(GetProfileDetails(props.userId));
    }, 1000);
  };
  const handleCloseDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };
  const handleDeleteUserDialog = () => {
    dispatch(DeleteUser(props.userId));
    props.setIsUserDetailsOpen(false);
    setDeleteUserDialog(false);
  };
  const handleDeleteUser = () => {
    // dispatch(DeleteUser(userId));
    // setIsUserDetailsOpen(false);
    setDeleteUserDialog(true);
  };
  const handleChangeSwitch = (event) => {
    setChangeInSwitch(true);
    if (event.target.checked) {
       props.setIsSwitchOn(true);
      dispatch(EnableUserAdmin(props.userId));
    } else if (event.target.checked === false) {
       props.setIsSwitchOn(false);
      dispatch(DisableUserAdmin(props.userId));
    }
  };
  return (
    <Material.Box>
      <Material.Grid container spacing={1}>
        <Material.Grid item md={3.5} lg={3.5} xs={12}>
          <Material.Box sx={{ mb: 1, ml: 1, mr: 1 }}>
            <Material.Box>
              <Material.Paper sx={{ borderRadius: "10px", p: "5px" }}>
                <Material.Box>
                  <Material.Box
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src={userLogo}
                      alt=""
                    />
                  </Material.Box>
                </Material.Box>
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Material.TextField
                    label="FullName"
                    size="small"
                    value={props.fullname}
                    onChange={(event) => props.setFullname(event.target.value)}
                    // sx={{ mb: "10px", width: "250px" }}
                  />
                  <Material.TextField
                    label="Phone"
                    size="small"
                    value={props.phone}
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onChange={(event) => props.setPhone(event.target.value)}
                    // sx={{ mb: "10px", width: "250px" }}
                  />
                  <Material.TextField
                    label="Email"
                    size="small"
                    value={props.email}
                    onChange={(event) => props.setEmail(event.target.value)}
                    // sx={{ mb: "10px", width: "250px" }}
                  />
                </Material.Box>
                <Material.Box
                  sx={{
                    border: "2px solid black",
                    // width: "250px",
                    mt: 2,
                    ml: { xs: 3.4, md: 0 },
                  }}
                >
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {" "}
                    <Material.Box>
                      <Material.Typography
                        sx={{
                          // fontSize: "18px",
                          // fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        User
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box>
                      {parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_ADMIN ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_SUPERADMIN ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_CSHEAD ? (
                        <Material.FormControlLabel
                          control={<Material.Switch checked={props.isSwitchOn} />}
                          label={
                            props.isSwitchOn ? (
                              <Material.Typography
                                sx={{
                                  color: "black",
                                }}
                              >
                                Active
                              </Material.Typography>
                            ) : (
                              <Material.Typography
                                sx={{
                                  color: "black",
                                }}
                              >
                                InActive
                              </Material.Typography>
                            )
                          }
                          onChange={handleChangeSwitch}
                        />
                      ) : (
                        ""
                      )}
                    </Material.Box>
                  </Material.Box>
                </Material.Box>
                <Material.Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    m: 2,
                  }}
                >
                  <Material.Button
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={handleUpdateUserDetails}
                  >
                    <BrowserUpdated />
                    Update
                  </Material.Button>
                  <Material.Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={handleDeleteUser}
                  >
                    <DeleteOutlined />
                    Delete User
                  </Material.Button>
                </Material.Box>
              </Material.Paper>
            </Material.Box>
          </Material.Box>
        </Material.Grid>
        <Material.Grid item md={8.5} lg={8.5} xs={12}></Material.Grid>
      </Material.Grid>
      {deleteUserDialog && (
        <DeleteUserDialog
          deleteUserDialog={deleteUserDialog}
          handleDelete={handleDeleteUserDialog}
          handleClose={handleCloseDeleteUserDialog}
        />
      )}
    </Material.Box>
  );
}

export default OtherUsers;
