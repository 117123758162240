import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import TimerIcon from "@mui/icons-material/Timer";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CanIcon from "../../Images/CanIcon.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PinDropIcon from "@mui/icons-material/PinDrop";
import CallIcon from "@mui/icons-material/Call";
import { toast } from "react-toastify";

// import DirectionsIcon from "@mui/icons-material/Directions";
// import { toast } from "react-toastify";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: (theme.palette.mode = "#FFFFFF"),
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // maxWidth:{md:"50%"},
  color: theme.palette.text.secondary,
  marginBottom: "7px",
  // border: "2px solid #FDEBD0",
  borderRadius: "10px",
  margin: "7px",
  // flex:1,
  // width:{xs:'100%',sm:'100%',md:"50%",xl:"50%"},
}));

function OrderDetails({ orders, setSelectedOrder, slectedOrder }) {
  const orderTypeColor = (type) => {
    switch (type) {
      case "I":
        return "#EE4190";

      case "S":
        return "#3BC2C0";
      case "P":
        return "#6d147d";
      default:
        return "#924EEF";
    }
  };

  const findOrderType = (pay, order, subs) => {
    if (pay === 300) return "P";
    if (order == 700) return "I";
    if (subs === true) return "S";
    return "R";
  };

  const deliverySlotFind = (admin, supplier) => {
    if (admin) return admin;
    if (supplier) return supplier;
    return "";
  };

  const handleCallClick = (phone) => {
    const telUrl = `tel:${phone}`;
    window.location.href = telUrl;
  };

  const handleCopyAddress = (address) => {
    CustomConsole("------------handleCopyAddress------------------");
    const address_compile = ` ${address.address_line1},
    ${address.address_line2},
    ${address.address_line3},
    ${address.service_area}-
    ${address.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };

  const handleLocation = (lat, long) => {
    CustomConsole("------------handleLocation------------------");
    if (lat && long) {
      const mapUrl = `https://www.google.com/maps?q=${lat},${long}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.error("Location not found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        //theme: "colored",
      });
    }
  };

  const handleChangeCheckBox = (e, id) => {
    if (e.target.checked === true) {
      setSelectedOrder(id);
    } else if (e.target.checked === false) {
      setSelectedOrder("");
    }
  };

  return (
    <Box>
      <Item elevation={6}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Chip
            avatar={
              <Avatar
                sx={{
                  background: "white",
                  fontSize: "12px",
                }}
              >
                {findOrderType(
                  orders.payment_type,
                  orders.packaged_drinking_water.order_type,
                  orders.packaged_drinking_water.is_subscribed_order
                )}
              </Avatar>
            }
            sx={{
              backgroundColor: orderTypeColor(
                findOrderType(
                  orders.payment_type,
                  orders.packaged_drinking_water.order_type,
                  orders.packaged_drinking_water.is_subscribed_order
                )
              ),
              color: "white",
            }}
            variant=""
            label={`${orders.order_id}`}
          />

          <Checkbox
            checked={slectedOrder === orders.delivery_id}
            onChange={(e) => handleChangeCheckBox(e, orders.delivery_id)}
            color="success"
            size="small"
          />
        </Stack>
        <Divider orientation="horizontal" flexItem />
        <Box mt={0.5} mb={0.5} sx={{ paddingLeft: "7px" }}>
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
                textAlign: "start",
                // fontWeight: "bold",
              }}
            >
              {orders.delivery_address.contact_name},{" "}
              {orders.delivery_address.contact_phone}
              <CallIcon onClick={handleCallClick} sx={{ fontSize: "15px" }} />
            </Typography>
            <Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {orders.delivery_address.address_line1}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  textAlign: "start",
                  paddingTop: "1px",
                }}
              >
                {orders.delivery_address.address_line2},{" "}
                {orders.delivery_address.address_line3},
                {orders.delivery_address.service_area}-
                {orders.delivery_address.pincode}
              </Typography>
            </Box>
            <Box>
              {" "}
              <ContentCopyIcon
                onClick={() => handleCopyAddress(orders.delivery_address)}
                sx={{ fontSize: "19px", marginLeft: "15px" }}
              />{" "}
              <IconButton
                type="button"
                color="primary"
                onClick={() =>
                  handleLocation(
                    orders.delivery_address?.latitude,
                    orders.delivery_address?.longitude
                  )
                }
              >
                <PinDropIcon sx={{ fontSize: "19px", marginLeft: "15px" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Divider orientation="horizontal" flexItem />
        <Stack
          direction={"row"}
          justifyContent="space-evenly"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          // mt={0.5}
        >
          <Typography>
            {" "}
            <Badge color="warning">
              <DeliveryDiningIcon />
            </Badge>
            &nbsp;{orders.driver_profile?.fullname || orders.tanker_id}
          </Typography>
          <Typography>
            <Badge color="warning">
              <TimerIcon sx={{ fontSize: "20px" }} />
            </Badge>
            &nbsp;
            {deliverySlotFind(
              orders.packaged_drinking_water.delivery_slots
                .admin_selected_delivery_slot,
              orders.packaged_drinking_water.delivery_slots
                .supplier_selected_delivery_slot
            )}
          </Typography>
        </Stack>
      </Item>
    </Box>
  );
}

export default OrderDetails;
