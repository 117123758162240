

/**
 * @author vinay kumar
 * @description RollBack Cans Dialog Box
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 18-07-2023
 */
// Importing the Libraries and Other Files
import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../../CustomConsole";

function RollbackCansDialog(props) {
  const handleYes = () => {
    CustomConsole("----handleYes-----");
    props.handleYes(props);
  };
  const handleNo = () => {
    CustomConsole("----handleNo-----");
    props.handleNo(props);
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openRollBackDialog}>
        <Material.DialogTitle>
          <Material.Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
            Do You Want To RollBack Your Inscan?
          </Material.Typography>
        </Material.DialogTitle>
        <Material.DialogContent>
          <Material.Box>
            <Material.Typography>
              Performing this action means you have to inScan all the cans
              again.
            </Material.Typography>
            <Material.Typography sx={{ textAlign: "center" }}>
              Are you sure want to continue?
            </Material.Typography>
          </Material.Box>
        </Material.DialogContent>
        <Material.DialogActions>
          <Material.Button variant="outlined" onClick={handleNo}>
            No
          </Material.Button>
          <Material.Button variant="outlined" onClick={handleYes}>
            Yes
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </Material.Box>
  );
}

export default RollbackCansDialog;
