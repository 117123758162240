import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { url } from "../apis";
import CustomConsole from "../../../CustomConsole";
import React from "react";
import { toast } from "react-toastify";

export const LinkQRBarcode = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/scanToLinkQrBarcode`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_QR_BARCODE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          //   toast.dark("User Added Successfully", {
          //     position: "bottom-right",
          //     autoClose: 3000,
          //     type: "success",
          //   });
        }
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const InScanEmptyCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/inScanCans`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.IN_SCAN_EMPTY,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          //   toast.dark("User Added Successfully", {
          //     position: "bottom-right",
          //     autoClose: 3000,
          //     type: "success",
          //   });
        }
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const OutScanFilledCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/outScanCans`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.OUT_SCAN_FILLED,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          //   toast.dark("User Added Successfully", {
          //     position: "bottom-right",
          //     autoClose: 3000,
          //     type: "success",
          //   });
        }
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const GetDetailsFromQrcode = (qrCode) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  const reqObj = {
    // qr_barcode_id: "BW3Q00446",
    qr_barcode_id: qrCode,
  };
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/getQrBarCodeData`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_QR_DETAILS,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark("Qr Details Generated Successfully", {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          //   toast.dark("User Added Successfully", {
          //     position: "bottom-right",
          //     autoClose: 3000,
          //     type: "success",
          //   });
        }
      } else {
        toast.dark("Unable to fetch container owner details", {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Unable to fetch container owner details", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const GetCompleteQrDetails = (qrCode) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  const reqObj = {
    // qr_barcode_id: "BW3Q00446",
    qr_barcode_id: qrCode,
  };
  return async (dispatch) => {
    try {
      let response = await api.get(`/QSM/container/details?id=${qrCode}`);
      if (response.status === httpStatus.SUCCESS) {
        const data = {
          qualityDetails: response.data.qualityDetails
            ? response.data.qualityDetails
            : [],
          ownerDetails: response.data.ownerDetails
            ? response.data.ownerDetails
            : [],
          wfsBatchDetails: response.data.wfs_batch_details
            ? response.data.wfs_batch_details
            : {},
          ownerType: response.data.ownerType ? response.data.ownerType : 0,
          productlinkedCrateSetails: response.data.crate_track_details
            ? response.data.crate_track_details
            : [],
        };
        CustomConsole(data);
        const resp = { error: false, msg: response.data.msg, data: data };
        CustomConsole("resp: ");
        CustomConsole(resp);
        dispatch({
          type: actionType.GET_CONTAINER_DETAILS,
          payload: resp,
        });
        return resp;
      } else {
        toast.dark("Unable to fetch container owner details", {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Unable to fetch container owner details", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const GetCompleteCrateQrDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/QSM/crate/qr/details`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_CRATE_DETAILS,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: response.data.error === true ? "error" : "success",
        });
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const ReturnCompleteCrateQrDetails = async (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  try {
    let response = await api.put(`/QSM/crate/qr/details`, reqObj);
    if (response.status === httpStatus.SUCCESS) {
      return response.data;
    } else {
      toast.dark(response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  } catch (error) {
    CustomConsole(error);
    toast.dark(error.response.data.msg, {
      position: "bottom-right",
      autoClose: 3000,
      type: "error",
    });
  }
  return;
};
export const GenerateQrCode = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/generateQrCodes`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GENERATE_QR_CODE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Please check inputs and Try again!!", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const GenerateBarcodes = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/generateBarCodes`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GENERATE_BAR_CODE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Unable to fetch container owner details", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const RegenerateQrCode = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  //   const reqObj = { is_qr_barcode: true, quantity: 2 };

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/reGenerateQrBarCodes `, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REGENERATE_QRBAR_CODE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Unable to fetch container owner details", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const GetQrList = (pageNumber, ascDsc, seriesType, qrBarCode) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  //   const reqObj = { is_qr_barcode: true, quantity: 2 };

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/admin/getQrBarcodeList?is_qr_barcode=${qrBarCode}&series_type=${seriesType}&date=${ascDsc}&page_number=${pageNumber}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_QR_LIST,
          payload: response.data,
        });
        // if (response.data.msg) {
        //   toast.dark(response.data.msg, {
        //     position: "bottom-right",
        //     autoClose: 3000,
        //     type: "success",
        //   });
        // }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const GetProductCanColor = async (qrBarcode) => {
  let api = useAxios();
  try {
    let response = await api.get(
      `/QSM/product/for-cap-color?qr_barcode=${qrBarcode}`
    );
    CustomConsole(response);
    return response.data;
  } catch (error) {
    CustomConsole(error);
    return { error: true, msg: "Please scan valid product!" };
  }
};
