export const styles = {
  cardStyleHeading: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "5px",
    gap: "10px",
    color: "blue",
    fontWeight: "bold",
  },
  cardStyleValue: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "5px",
    gap: "10px",
  },
};
