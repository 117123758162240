/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author
 * @description Add product dialog box
 * @date
 * @version
 */
import React from "react";
import * as Material from "@mui/material";
import { Box } from "@mui/system";
import { UploadFile } from "../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TextFieldReusable from "../TextFiled/textField";
import {
  AddNewCrateSku,
  GetListofCrates,
  UpdateCrateDetails,
} from "../../Pages/Redux/Actions/SKUProductDetailsAction";
import ConformationDialog from "./conformationDialog";
import CustomConsole from "../../CustomConsole";

function AddnewCrateDialog(props) {
  const dispatch = useDispatch();
  const skuCrateDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const fileHandler = useSelector((state) => state.fileHandler);
  const [productName, setProductName] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [cgstPercentage, setCgstPercentage] = React.useState("");
  const [sgstPercentage, setSgstPercentage] = React.useState("");
  const [igstPercentage, setIgstPercentage] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [uploadNewCrateFile, setUploadCrateFile] = React.useState("");
  const [newCrateUrl, setNewCrateUrl] = React.useState("");
  const [isEpValid, setIsValid] = React.useState(false);
  const [uploadCrateBtn, setUploadCrateBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);

  const uploadNewCrate = React.useRef(null);

  const updateFlag = Object.keys(props.updateProductDetails).length > 0;
  const [confirmDialog, setConfirmDialog] = React.useState(false);

  let crateObj = {
    description: description,
    igst_perc: igstPercentage,
    sgst_perc: sgstPercentage,
    cgst_perc: cgstPercentage,
    hsn_code: hsnCode,
    price: amount,
    // material: material,
    capacity: capacity,
    name: productName,
    image: newCrateUrl,
  };
  const dialogMsg = "Are you sure to delete selected Product";

  //useEffext for adding crate deatails for update crate operation
  React.useEffect(() => {
    if (updateFlag) {
      setProductName(props.updateProductDetails.name);
      setCapacity(props.updateProductDetails.capacity);
      setAmount(props.updateProductDetails.price);
      setHsnCode(props.updateProductDetails.hsn_code);
      setCgstPercentage(props.updateProductDetails.cgst_perc);
      setSgstPercentage(props.updateProductDetails.sgst_perc);
      setIgstPercentage(props.updateProductDetails.igst_perc);
      setDescription(props.updateProductDetails.description);
    }
  }, [props.updateProductDetails]);

  //function for handling file upload opreation
  const handleUploadNewCrate = () => {
    uploadNewCrate.current.click();
  };
  //useEffect for handling file upload operation
  React.useEffect(() => {
    if (uploadNewCrateFile !== "") {
      dispatch(UploadFile(uploadNewCrateFile));
      setUploadCrateBtn(true);
      setUploadCrateFile("");
      setUploadFlags(true);
    }
  }, [uploadNewCrateFile]);

  //file handler
  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileHandler.isFileUploaded === true && uploadCrateBtn === true) {
      setNewCrateUrl(fileHandler.fileName);
      setUploadCrateBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("New Product File uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);

  //function for handling closing dialog
  //function for handling closing dialog
  const handleCancle = () => {
    props.handleCancle();
    setProductName("");
    setCapacity("");
    setAmount("");
    setHsnCode("");
    setCgstPercentage("");
    setSgstPercentage("");
    setIgstPercentage("");
    setDescription("");
    setConfirmDialog(false);
    props.setUpdateProductDetails("");
  };

  //function for uploading crate deatails
  const handleAddProduct = () => {
    if (!updateFlag) {
      dispatch(AddNewCrateSku(crateObj));
    } else {
      crateObj.id = props.updateProductDetails.id;
      crateObj.is_active = props.updateProductDetails.is_active;
      crateObj.is_deleted = props.updateProductDetails.is_deleted;
      crateObj.created_at = props.updateProductDetails.created_at;
      crateObj.updated_at = props.updateProductDetails.updated_at;
      dispatch(UpdateCrateDetails(crateObj));
    }
  };

  //useEffect for getting crate details
  CustomConsole(skuCrateDetails);
  React.useEffect(() => {
    if (!skuCrateDetails.AddCrateError) {
      dispatch(GetListofCrates(props.crateFilterDetails));
      handleCancle();
    }
  }, [skuCrateDetails.AddCrateError]);

  //function for deleting crate details
  const handelProceedDelete = () => {
    crateObj.id = props.updateProductDetails.id;
    crateObj.is_active = false;
    crateObj.is_deleted = props.updateProductDetails.is_deleted;
    crateObj.created_at = props.updateProductDetails.created_at;
    crateObj.updated_at = props.updateProductDetails.updated_at;
    dispatch(UpdateCrateDetails(crateObj));
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openAddOrUpdateCrateProduct}>
        <Material.DialogTitle>
          {/* {props.isUpdate ? (
          <Material.Typography
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Update Delivery Agent Here
          </Material.Typography>
        ) : (
          <Material.Typography
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Create Delivery Agent Here
          </Material.Typography>
        )} */}
          <Material.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
            ADD/UPDATE CRATE
          </Material.Typography>
        </Material.DialogTitle>
        <Material.DialogContent>
          <Material.Grid container spacing={2}>
            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Crate Name"
                type="text"
                value={productName}
                isRequired={true}
                setDataForState={setProductName}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Crate Capacity"
                type="number"
                value={capacity}
                isRequired={true}
                setDataForState={setCapacity}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Price"
                type="number"
                value={amount}
                isRequired={true}
                setDataForState={setAmount}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>

            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="HSN Code"
                type="number"
                value={hsnCode}
                isRequired={true}
                setDataForState={setHsnCode}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="CGST %"
                type="number"
                value={cgstPercentage}
                isRequired={true}
                setDataForState={setCgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="SGST %"
                type="number"
                value={sgstPercentage}
                isRequired={true}
                setDataForState={setSgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>
            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="IGST %"
                type="number"
                value={igstPercentage}
                isRequired={true}
                setDataForState={setIgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>

            <Material.Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Description"
                type="text"
                value={description}
                isRequired={true}
                setDataForState={setDescription}
                setIsValid={setIsValid}
                isHintRequired={true}
              />
            </Material.Grid>
            <Material.Grid
              item
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="center"
            >
              <Material.Button
                onClick={handleUploadNewCrate}
                variant="contained"
                size="small"
                // disabled={
                //   fileHandler.isFileUploaded !== undefined &&
                //   fileHandler.isFileUploaded === false
                //     ? false
                //     : true
                // }
              >
                {fileHandler.isFileUploaded !== undefined &&
                fileHandler.isFileUploaded === false
                  ? "Upload Crate"
                  : "Uploaded Successfully"}
                <input
                  type="file"
                  ref={uploadNewCrate}
                  style={{ display: "none" }}
                  onChange={(e) => setUploadCrateFile(e.target.files[0])}
                />
              </Material.Button>
            </Material.Grid>
          </Material.Grid>
        </Material.DialogContent>
        <Material.DialogActions>
          <Material.Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleCancle}
          >
            Close
          </Material.Button>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handleAddProduct}
            disabled={description.length < 15}
          >
            {updateFlag ? "Update" : "Add Crate"}
          </Material.Button>
          {updateFlag ? (
            <Material.Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setConfirmDialog(true)}
            >
              Delete
            </Material.Button>
          ) : null}
        </Material.DialogActions>
        <ConformationDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          dialogMsg={dialogMsg}
          handelProceed={handelProceedDelete}
        />
      </Material.Dialog>
    </Material.Box>
  );
}

export default AddnewCrateDialog;
