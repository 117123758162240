import actionType from "../Actions/actionType";

const initialState = {
  error: true,
  msg: "",
  pincodeData: [],
  addPincodeError: true,
  addPincodeData: {},
  updatePincodeError: true,
  updatePincodeData: {},
  deleteLocalityError: true,
  deleteLocalityData: {},
  deleteProductError: true,
  deleteProductData: {},
};

const SkuPincodeReduser = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_AVAILABLE_PINCODES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        pincodeData: action.payload.data,
      };
    case actionType.ADD_PINCODE_SERVICEAREA_SKU:
      return {
        ...state,
        addPincodeError: action.payload.error,
        msg: action.payload.msg,
        addPincodeData: action.payload.data,
      };
    case actionType.UPDATE_PINCODE_SKU:
      return {
        ...state,
        updatePincodeError: action.payload.error,
        msg: action.payload.msg,
        updatePincodeData: action.payload.data,
      };
    case actionType.DELETE_LOCALITY:
      return {
        ...state,
        deleteLocalityError: action.payload.error,
        msg: action.payload.msg,
        deleteLocalityData: action.payload.data,
      };
    case actionType.DELETE_PRODUCT_LOCALITY:
      return {
        ...state,
        deleteProductError: action.payload.error,
        msg: action.payload.msg,
        deleteProductData: action.payload.data,
      };
    default:
      return state;
  }
};

export default SkuPincodeReduser;
