import actionType from "../Actions/actionType";

const initialState = {
  error: true,
  msg: "",
  reports: [], // List of available reports
  // summaryReports: [], // Order summary reports
  // canAgeReports: [],  // Can age report
};

const adminReportsReducer = (state = initialState, action) => {
  console.log("report reducer triggered");
  console.log(action.type);
  console.log(actionType.GET_ADMIN_REPORTS);
  switch (action.type) {
    case actionType.GET_ADMIN_REPORTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        reports: action.payload.data,
      };
    // case actionType.GET_SUMMARY_REPORTS:
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     msg: action.payload.msg,
    //     summaryReports: action.payload.data, // Update with order summary data
    //   };
    // case actionType.GET_CAN_AGE_REPORTS:
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     msg: action.payload.msg,
    //     canAgeReports: action.payload.data, // Update with can age data
    //   };

    default:
      return state;
  }
};

export default adminReportsReducer;
