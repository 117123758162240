/**
 * @author Gowtham Prasath
 * @description Place Emppty Can orders page
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PlaceOrderEmptyCans } from "../../Pages/Redux/Actions/adminLineDetailsAction";
import { GetListofCrates } from "../../Pages//Redux/Actions/SKUProductDetailsAction";
import { InputAdornment } from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import CustomConsole from "../../CustomConsole";
export default function DistributorOrderCard({ userId }) {
  const dispatch = useDispatch();
  const wfsLineDetails = useSelector((state) => state.adminWfsLineData);
  const [productName, setProductName] = React.useState("");
  const [crateName, setCrateName] = React.useState("");
  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    user_id: userId,
    order_qty: 0,
    return_qty: 0,
    crates: [],
  });

  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const crateDetails = skuContainerDetails.cateDetails;

  //useEffect for getting list available crates
  React.useEffect(() => {
    dispatch(
      GetListofCrates({
        activeStatus: true,
        capacity: "ASC",
        sort_by_name: "ASC",
        sort_by_price: "ASC",
        sort_by_created_at: "ASC",
      })
    );
  }, []);

  //function for handling selected product
  const handleSelectedProduct = (data) => {
    setProductName(data.target.value);
    const product = data.target.value;
    let duplicateElement = skuContainerDetails.getAvailableProducts.find(
      (data) => data.product_details.name === product
    );
    CustomConsole(duplicateElement);
    if (Object.keys(duplicateElement).length > 0) {
      setOrderParams((prev) => ({
        ...prev,
        container_material: duplicateElement.product_details.material,
        container_capacity: duplicateElement.product_details.capacity,
      }));
    }
  };

  CustomConsole(orderParams);

  //function for handling orderqty minus action
  const handelMinusQuantity = () => {
    if (orderParams.order_qty >= 1) {
      setOrderParams((prev) => ({
        ...prev,
        order_qty: orderParams.order_qty - 1,
      }));
    }
  };
  //function for handling orderqty plus action
  const handelAddQuantity = () => {
    setOrderParams((prev) => ({
      ...prev,
      order_qty: orderParams.order_qty + 1,
    }));
  };

  //function for handling orderqty plus action
  const handelQuantityChange = (event) => {
    // Extract the input value as a number
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        order_qty: inputValue,
      }));
    }
  };

  //function for deleting product detiails
  const deleteProductDetails = (event) => {
    setOrderParams((prev) => ({
      ...prev,
      container_material: "",
      container_capacity: "",
    }));
  };

  //function for handling crate adding
  const handelSelectedCrate = () => {
    let crateData = crateDetails.find((data) => data.name === crateName);
    let duplicateElement = orderParams.crates.filter(
      (data) => data.name === crateName
    );
    CustomConsole(crateData);
    if (Object.keys(crateData).length > 0 && duplicateElement.length === 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: [
          ...prev.crates,
          { name: crateData.name, order_qty: 0, capacity: crateData.capacity },
        ],
      }));
    }
  };

  //function for adding crate name
  const handelChangeSelectedCrate = (e) => {
    setCrateName(e.target.value);
  };
  CustomConsole(orderParams);

  //function for handling crate adding
  const handelAddCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) =>
        crate.name === data.name
          ? { ...crate, order_qty: crate.order_qty + 1 }
          : crate
      ),
    }));
  };
  //function for handling crate adding
  const handelMinusCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) => {
        if (crate.name === data.name && crate.order_qty > 0) {
          // Update the order_qty for the matching crate
          return { ...crate, order_qty: crate.order_qty - 1 };
        }
        // Return the original crate object if it doesn't match the condition
        return crate;
      }),
    }));
  };
  //function for handling crate adding
  const handelCrateQuantityChange = (data, e) => {
    // Extract the input value as a number
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.name === data.name) {
            return { ...crate, order_qty: inputValue };
          }
          return crate;
        }),
      }));
    }
  };

  //const delete crate details
  const deleteCrateDetails = (index) => {
    setOrderParams((prev) => {
      const newCrates = [...prev.crates];
      newCrates.splice(index, 1);
      return { ...prev, crates: newCrates };
    });
  };
  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignContent="center"
        >
          <Grid item md={6} xs={12}>
            <Typography sx={{ display: "flex", justifyContent: "flex-start" }}>
              Select Product
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                id="outlined-select-currency-native"
                select
                sx={{
                  width: { sm: "100%", xs: "100%" },
                  marginTop: ".6rem",
                }}
                size="small"
                value={orderParams.name}
                onChange={handleSelectedProduct}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="" disabled>
                  Select Capacity
                </option>
                {skuContainerDetails.getAvailableProducts.map((option) => (
                  <option
                    key={option.product_details.id}
                    value={option.product_details.name}
                  >
                    {option.product_details.name}/Material:
                    {option.product_details.material}/Capacity:
                    {option.product_details.capacity}
                  </option>
                ))}
              </TextField>
            </Box>
            {orderParams.container_capacity !== "" ? (
              <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>
                      {productName}/{orderParams.container_capacity}/
                      {orderParams.container_material}
                    </Typography>
                    <DeleteIcon
                      color="error"
                      onClick={() => deleteProductDetails()}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "row",
                      marginTop: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1rem",
                      }}
                    >
                      <Typography>Required&nbsp;&nbsp;</Typography>
                      <OrderButtonSku
                        handelAddQuantity={handelAddQuantity}
                        handelMinusQuantity={handelMinusQuantity}
                        handelQuantityChange={handelQuantityChange}
                        inputValue={orderParams.order_qty}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1rem",
                      }}
                    >
                      <Typography>Empty Can&nbsp;&nbsp;</Typography>
                      <OrderButtonSku
                        handelAddQuantity={handelAddQuantity}
                        handelMinusQuantity={handelMinusQuantity}
                        handelQuantityChange={handelQuantityChange}
                        inputValue={orderParams.order_qty}
                      />
                    </Box>
                  </Box>
                </CardContent>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "#C9EEFF",
                    height: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ marginLeft: "1rem" }}>
                    Order Qty:{orderParams.order_qty}
                  </Typography>
                  <Typography sx={{ marginRight: "1rem" }}>
                    Empty Qty:{orderParams.order_qty}
                  </Typography>
                </Box>
              </Card>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography sx={{ display: "flex", justifyContent: "flex-start" }}>
              Select Crate
            </Typography>
            <TextField
              id="outlined-select-currency-native"
              select
              sx={{
                width: { sm: "100%", xs: "100%" },
                marginTop: ".6rem",
              }}
              size="small"
              value={crateName}
              onChange={handelChangeSelectedCrate}
              SelectProps={{
                native: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handelSelectedCrate}
                    >
                      Add
                    </Button>
                  </InputAdornment>
                ),
              }}
            >
              <option>Select Crate</option>
              {crateDetails.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}/Capacity:{option.capacity}
                </option>
              ))}
            </TextField>
            {orderParams.crates.length > 0 ? (
              <>
                {orderParams.crates.map((data, index) => (
                  <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>
                          {data.name}/{data.capacity}
                        </Typography>
                        <DeleteIcon
                          color="error"
                          onClick={() => deleteCrateDetails(index)}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "1rem",
                          }}
                        >
                          <Typography>Required&nbsp;&nbsp;</Typography>
                          <OrderButtonSku
                            handelAddQuantity={() =>
                              handelAddCrateQuantity(data)
                            }
                            handelMinusQuantity={() =>
                              handelMinusCrateQuantity(data)
                            }
                            handelQuantityChange={(e) =>
                              handelCrateQuantityChange(data, e)
                            }
                            inputValue={data.order_qty}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "1rem",
                          }}
                        >
                          <Typography>Return&nbsp;&nbsp;</Typography>
                          <OrderButtonSku
                            handelAddQuantity={() =>
                              handelAddCrateQuantity(data)
                            }
                            handelMinusQuantity={() =>
                              handelMinusCrateQuantity(data)
                            }
                            handelQuantityChange={(e) =>
                              handelCrateQuantityChange(data, e)
                            }
                            inputValue={data.order_qty}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                    <Box
                      sx={{
                        width: "100%",
                        bgcolor: "#C9EEFF",
                        height: "30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ marginLeft: "1rem" }}>
                        Order Qty:{data.order_qty}
                      </Typography>
                      <Typography sx={{ marginRight: "1rem" }}>
                        Return Qty:{data.order_qty}
                      </Typography>
                    </Box>
                  </Card>
                ))}
              </>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    // </Paper>
  );
}
