import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

export default function AddDistributorCard({ handleAddDistributor,adminRole }) {
  const [distributorNum, setDistributorNum] = React.useState("");

  const addDistributorBtn = () => {
    const reqObj = {
      email_phone: distributorNum,
      tanker_id: "",
      tanker_type: 3,
    };
    handleAddDistributor(reqObj);
  };

  return (
    <>
    {ADMIN_ROLES.BW_SALES === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ? ("") : ( 
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "18", fontWeight: "bold" }}
            variant="button"
            display="block"
            gutterBottom
          >
            Packaged water
          </Typography>
          <Box>
            <TextField
              size="small"
              label="Distributor Number"
              value={distributorNum}
              onChange={(event) => setDistributorNum(event.target.value)}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={addDistributorBtn}
            >
              Add Distributor
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
    )}
    </>
  );
}
