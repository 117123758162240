/**
 * @author vinay kumar
 * @description Return Can Orders
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 26-06-2023
 */
// Importing the Libraries and Other Files

import React, { useState } from "react";
import * as Material from "@mui/material";
import * as IconName from "react-icons/fc";
import * as MuiIcons from "@mui/icons-material";
import {
  ADMIN_ROLES,
  replacementCanOrdersStatus,
} from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";
import ReturnCansOrdersCard from "../../Components/Cards/ReturnCansOrdersCard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetAssignDeliveryAgent,
  GetSupplierDeliveryAgentDetails,
  GetSupplierReturnCanOrders,
  PostSupplierAssignDeliveryAgentReturnOrReplacement,
  PutSupplierReturnCanVerifyOtp,
  PutSupplierReturnInScanCans,
} from "../Redux/Actions/supliersAction";

import SupplierReturnCanFilter from "../../Components/DialogBoxes/SupplierDialogBox/SupplierReturnCanFilter";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import SupplierOrdersEnterOtpDialog from "../../Components/DialoBoxes/SupplierOrdersEnterOtpDialog";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import SupplierQRorCanDamageDialog from "../../Components/DialoBoxes/SupplierQRorCanDamageDialog";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import QrBarcodeLinkingDialog from "../AdminGenrateQRBarcode/QrBarcodeLinkingDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReturnCanCameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReturnCanCameraDialog";
import AssignDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AssignDeliveryAgentDialog";
import ReturnCanFormView from "./ReturnCanFormView";
import ReturnCanOrdersTableView from "./ReturnCanOrdersTableView";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";

function ReturnCanOrders() {
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const [returnCanOrderId, setReturnCanOrderId] = React.useState("");
  const [enterOtpOpenDialog, setEnterOtpOpenDialog] = React.useState(false);

  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [openQrorCanDamageDialog, setOpenQrorCanDamageDialog] =
    React.useState(false);
  const [damageOrFineCans, setDamageOrFineCans] = React.useState("IsDamages");
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [link, setLink] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [returnInOutScanBtn, setReturnInOutScanBtn] = React.useState(false);
  const [returnSubmitOtpBtn, setReturnSubmitOtpBtnBtn] = React.useState(false);
  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [returnOrderId, setReturnOrderId] = React.useState("");
  const [selectCanFilledOrNot, setSelectCanFilledOrNot] = React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [testArr, setTestArr] = React.useState([]);
  const [scanCount, setScanCount] = React.useState(0);
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [openCamera, setOpenCamera] = React.useState(false);
  const [apiData, setApiData] = React.useState({});
  const [openReturnCanCameraDialog, setOpenReturnCanCameraDialog] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [openReturnCanFilter, setOpenReturnCanFilter] = React.useState(false);
  const [returnCanView, setReturnCanView] = React.useState("card");
  const [pageSize, setPageSize] = React.useState(25);
  const [deliveryAgent, setDeliveryAgent] = React.useState("");
  const [searchByOrderId, setSearchByOrderId] = React.useState("");
  const [adminRole, setAdminRoleId] = React.useState("");

  const inQrBarcodeAdmin = true;

  // React.useEffect(() => {
  //   dispatch(GetSupplierDeliveryAgentDetails());
  // }, []);

  React.useEffect(() => {
    CustomConsole(returnCanOrderId);
  }, [returnCanOrderId]);
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
    CustomConsole(supplierDeliveries);
    dispatch(
      GetSupplierReturnCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
    CustomConsole(ADMIN_ROLES);
    CustomConsole(adminRole);
    if (ADMIN_ROLES.BW_DELIVERYAGENT !== 2001) {
      dispatch(GetSupplierDeliveryAgentDetails());
    }
  }, []);

  React.useEffect(() => {
    // if (
    //   supplierDeliveries.returnSubmitOtperror === false &&
    //   returnSubmitOtpBtn === true
    // ) {
    //   dispatch(GetSupplierReturnCanOrders({ pageNumber: pageNumber - 1 }));
    //   setReturnSubmitOtpBtnBtn(false);
    // }
    if (
      supplierDeliveries.returnInOutScanerror === false &&
      returnInOutScanBtn === true
    ) {
      dispatch(
        GetSupplierReturnCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
      setReturnInOutScanBtn(false);
    }
    //pagination enable and disable
    if (
      supplierDeliveries !== undefined &&
      supplierDeliveries.getSupplerReturnCanOrders !== undefined &&
      supplierDeliveries.getSupplerReturnCanOrders.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  }, [supplierDeliveries]);

  React.useEffect(() => {
    //Scanned Qr Code
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    //Scanned Bar Code
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  // const handleHitApi = () => {
  //   CustomConsole("---- handleHitApi ----");
  //   setOpenCamera(false);
  //   CustomConsole(apiData);
  //   if (apiData) {
  //     dispatch(
  //       PutSupplierReturnInScanCans({
  //         damage_type: apiData.damage_type || undefined,
  //         is_damaged: apiData.is_can_damaged,
  //         is_filled_can: apiData.is_can_filled,
  //         order_id: returnCanOrderId,
  //         qr_barcode_id1: scannedQrCode || scannedBarCode || undefined,
  //         qr_barcode_id2: "",
  //       })
  //     );
  //     setReturnInOutScanBtn(true);
  //   }
  //   CustomConsole(apiData);
  //   setOpenQrorCanDamageDialog(false);
  //   setScanQrCodeDetailBtn(false);
  //   setScannedQrCodeDetails("");
  //   setScannedQrCode("");
  //   setScannedBarCode("");
  //   // setLinkingDialogOpen(false);
  //   // setScanCount(0);
  //   setDecodedResults([]);
  //   // setLink(false);
  //   // setLinkQrBarcodeBtn(false);
  // };
  // React.useEffect(() => {
  //   CustomConsole("--- link useeffect ---");
  //   // CustomConsole(link);
  //   if (scannedQrCode !== "" || scannedBarCode !== "") {
  //     CustomConsole("-- User Click on handleLink");
  //     handleHitApi();
  //   }
  // }, [scannedQrCode, scannedBarCode]);
  const handleButtonData = (props) => {
    CustomConsole("_______handleButtonData_______");
    CustomConsole(props);
    CustomConsole(props.order_id);
    setReturnCanOrderId(props.order_id);

    if (
      props.is_submit_started === false &&
      props.is_submit_finished === false
    ) {
      CustomConsole("------EnterOtp------------");
      setEnterOtpOpenDialog(true);
    } else if (
      props.is_submit_started === true &&
      props.is_submit_finished === false
    ) {
      CustomConsole("------Inscan------------");
      setOpenQrorCanDamageDialog(true);
      // setOpenCamera(true);
    }
  };

  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
  };
  // React.useEffect(() => {
  //   if (pageNumber) {
  //     CustomConsole("=== pageNumber ===:" + pageNumber);
  //     dispatch(GetSupplierReturnCanOrders({ page_number: pageNumber - 1 }));
  //     setPageBackWard(true);
  //     setPageForward(true);
  //   }
  // }, [pageNumber]);

  React.useEffect(() => {
    if (returnCanView === "form") {
      dispatch(
        GetSupplierReturnCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
          delivery_agent_id: deliveryAgent || undefined,
        })
      );
    }
    if (returnCanView === "table") {
      dispatch(
        GetSupplierReturnCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
          delivery_agent_id: deliveryAgent || undefined,
        })
      );
    }
    if (returnCanView === "card") {
      dispatch(
        GetSupplierReturnCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
          delivery_agent_id: deliveryAgent || undefined,
        })
      );
    }
  }, [pageNumber]);

  // React.useEffect(() => {
  //   if (returnCanView === "form") {
  //     dispatch(
  //       GetSupplierReturnCanOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //         delivery_agent_id: deliveryAgent || undefined,
  //       })
  //     );
  //   }
  //   if (returnCanView === "table") {
  //     dispatch(
  //       GetSupplierReturnCanOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //         delivery_agent_id: deliveryAgent || undefined,
  //       })
  //     );
  //   }
  //   if (returnCanView === "card") {
  //     dispatch(
  //       GetSupplierReturnCanOrders({
  //         page_number: pageNumber,
  //         page_size: pageSize,
  //         delivery_agent_id: deliveryAgent || undefined,
  //       })
  //     );
  //   }
  // }, [pageSize]);

  React.useEffect(() => {
    CustomConsole(`Scan count: ${scanCount}`);
  }, [scanCount]);
  // const onNewScanResult = (decodedText, decodedResult) => {
  //   CustomConsole("App [result]", decodedResult);
  //   CustomConsole(decodedText);
  //   if (decodedResult.result.format.formatName === "QR_CODE") {
  //     setScannedQrCode(decodedText);
  //     CustomConsole(scanCount);
  //   }
  //   if (decodedResult.result.format.formatName === "CODE_128") {
  //     setScannedBarCode(decodedText);
  //     CustomConsole(scanCount);
  //   }
  //   setDecodedResults((prev) => [...prev, decodedResult]);
  // };

  const handleSubmitOtp = (props) => {
    CustomConsole("---handleReturnSubmitotp--");
    CustomConsole(props);
    CustomConsole(returnCanOrderId);
    setEnterOtpOpenDialog(false);
    const reqObj = {
      return_can_otp: enterOtpTextfied,
    };
    CustomConsole(reqObj);
    dispatch(PutSupplierReturnCanVerifyOtp(returnCanOrderId, reqObj));
    setReturnSubmitOtpBtnBtn(true);
    setTimeout(() => {
      dispatch(
        GetSupplierReturnCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }, 500);
  };

  const handleCloseDialog = () => {
    CustomConsole("---- handleCloseDialog -----");
    setEnterOtpOpenDialog(false);
  };
  const handleClickForward = () => {
    CustomConsole("---handleClickForward -----");
    CustomConsole("--pageNumber---:" + pageNumber);
    CustomConsole(
      "--supplierDeliveries.length---:" + supplierDeliveries.length
    );
    CustomConsole(JSON.stringify(supplierDeliveries));
    if (pageNumber + 1 > 0) {
      setPageNumber(() => pageNumber + 1);
    }
    // setPageStatuss(false);
  };

  const handleClickBack = () => {
    if (pageNumber > 0) {
      setPageBackWard(true);
      setPageForward(false);
      setPageNumber(() => pageNumber - 1);
      // setPageNumber(pageNumber - 1);
    }
  };

  const handleSubmitQrorCanDamage = (props) => {
    CustomConsole("---- handleSubmitQrorCanDamage -----");
    CustomConsole(props);
    setApiData(props);
    CustomConsole(returnCanOrderId);
    CustomConsole(scannedQrCodeDetails);
    // setOpenQrorCanDamageDialog(false);
    // setOpenCamera(true);
    // setOpenReturnCanCameraDialog(true);
    if (
      props.damage_type === 101 ||
      props.damage_type === 102 ||
      props.damage_type === 103 ||
      props.is_can_damaged === false
    ) {
      setOpenCamera(true);
      setOpenReturnCanCameraDialog(true);
      setOpenQrorCanDamageDialog(false);
    } else if (props.damage_type === 104 || props.damage_type === 105) {
      CustomConsole("---- 104 & 105 -----");
      const reqParams = {
        damage_type: props.damage_type,
        is_damaged: props.is_can_damaged,
        is_filled_can: props.is_can_filled,
        order_id: returnCanOrderId,
        qr_barcode_id2: "",
      };
      CustomConsole(reqParams);
      dispatch(PutSupplierReturnInScanCans(reqParams));
      // setReturnInOutScanBtn(true);
      setOpenQrorCanDamageDialog(false);
      setTimeout(() => {
        dispatch(
          GetSupplierReturnCanOrders({
            page_number: pageNumber,
            page_size: pageSize,
          })
        );
      }, 300);
    }
  };
  const handleCloseQrorCanDamage = (props) => {
    CustomConsole("---- handleCloseQrorCanDamage -----");
    CustomConsole(props);
    setOpenQrorCanDamageDialog(false);
  };
  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenReturnCanCameraDialog(false);
  };
  const refreshOrderDetails = () => {
    dispatch(
      GetSupplierReturnCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  };
  const handleBack = () => {
    const url = `/dashboard/deliveries`;
    history.push(url);
  };
  const handleOpenAssignDeliveryAgentDialog = (props) => {
    CustomConsole(props);
    CustomConsole("---handleOpenAssign-------");
    dispatch(GetSupplierDeliveryAgentDetails());
    setReturnOrderId(props);
    setOpenAssignDeliveryAgentDialog(true);
    // CustomConsole(replacementOrderId);
  };
  const handleCloses = () => {
    setOpenAssignDeliveryAgentDialog(false);
  };
  const handleAssignDeliveryAgent = (props) => {
    const reqObj = {
      delivery_agent_id: props.driver_id,
      order_id: returnOrderId,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierAssignDeliveryAgentReturnOrReplacement(reqObj));
    setOpenAssignDeliveryAgentDialog(false);
    setTimeout(() => {
      dispatch(
        GetSupplierReturnCanOrders({
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }, 500);
  };

  const handleClose = () => {
    CustomConsole("---------------handleClose--------------");
    setOpenReturnCanFilter(false);
  };
  const handleFilter = (props) => {
    CustomConsole("----------------handleFilter----------------");
    CustomConsole(props);
    setDeliveryAgent(props.delivery_agent_id);
    dispatch(
      GetSupplierReturnCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
        delivery_agent_id: props.delivery_agent_id,
      })
    );
    setOpenReturnCanFilter(false);
  };
  const handleOpenFilter = () => {
    setOpenReturnCanFilter(true);
    dispatch(GetAssignDeliveryAgent());
  };
  const handleReturnOrderView = (event) => {
    setReturnCanView(event.target.value);
    setPageSize(25);
    setPageNumber(0);
  };
  const handleViewDetails = (props) => {
    const url = `/dashboard/ReturnCanSingleOrder?order_id=${props}`;
    history.push(url);
  };
  const handleSearchOrder = () => {
    CustomConsole("------------handleSearchOrder------------");
    if (searchByOrderId !== "") {
      const url = `/dashboard/ReturnCanSingleOrder?order_id=${
        "WTHDRWL" + searchByOrderId
      }`;
      history.push(url);
    } else {
      toast.error("Please Enter Order ID", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };
  const handleSearchPageSize = () => {
    dispatch(
      GetSupplierReturnCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
        delivery_agent_id: deliveryAgent || undefined,
      })
    );
  };

  let result = [];
  function toCSV(orders) {
    const items = JSON.parse(
      JSON.stringify(supplierDeliveries.getSupplerReturnCanOrders)
    );
    if (items) {
      CustomConsole(items);
      let data = items.map((a) => {
        CustomConsole(a);
        a.order_status =
          a.order_status ===
          replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
            ? "Accepted"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_REJECTED
            ? "Rejected"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_FINISHED
            ? "Finished"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED
            ? "Requested"
            : a.order_status ===
              replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED
            ? "Started"
            : "";
        a.delivery_address = ` ${a.delivery_address?.address_line1}, ${
          a.delivery_address?.address_line2
        },${a.delivery_address?.address_line3}, ${
          a.delivery_address?.landmark
        }, ${a.delivery_address?.service_area}, ${
          a.delivery_address?.pincode
        }${" "}`;
        result.push(a);

        // result.push(a);
      });
      setTestArr(result);
    }
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };

  const handleLocation = (props) => {
    CustomConsole("------------handleLocation------------------");
    CustomConsole(props?.delivery_address?.latitude);
    CustomConsole(props?.delivery_address?.longitude);

    if (
      props?.delivery_address?.latitude !== undefined &&
      props?.delivery_address?.latitude !== null &&
      props?.delivery_address?.longitude !== undefined &&
      props?.delivery_address?.longitude !== null &&
      parseFloat(props?.delivery_address?.latitude) > 0 &&
      parseFloat(props?.delivery_address?.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props?.delivery_address?.latitude},${props?.delivery_address?.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handlePhone = (props) => {
    console.log(props);
    console.log("------------handlePhone------------------");
    CustomConsole("------------handlePhone------------------");
    if (props?.delivery_address?.contact_phone !== "") {
      CustomConsole(props?.delivery_address?.contact_phone);
      console.log(props?.delivery_address?.contact_phone);
      const telUrl = `tel:${props?.delivery_address?.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleCopyAddress = (props) => {
    const address_compile = ` ${props?.delivery_address?.contact_name},${props?.delivery_address?.contact_phone},${props?.delivery_address?.address_line1},
    ${props?.delivery_address?.address_line2},
    ${props?.delivery_address?.address_line3},
    ${props?.delivery_address?.service_area}-
    ${props?.delivery_address?.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };
  return (
    <Material.Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <Material.Box
        sx={{
          display: "flex",
          justifyContent: { md: "flex-end", xs: "center" },
          flexWrap: "wrap",
        }}
      >
        <Material.Box
          sx={{
            marginTop: "10px",
          }}
        >
          <Material.Box sx={{ marginBottom: "10px", width: { xs: "190px" } }}>
            <Material.TextField
              //   sx={{ borderRadius: "30px" }}
              size="small"
              // sx={{ maxWidth: "200px" }}
              label="Search Order"
              variant="outlined"
              value={searchByOrderId}
              onChange={(event) => setSearchByOrderId(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <Material.Typography sx={{ fontWeight: "bold" }}>
                      WTHDRWL
                    </Material.Typography>
                  </Material.InputAdornment>
                ),
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <IconName.FcSearch
                      size="30px"
                      onClick={handleSearchOrder}
                    />
                  </Material.InputAdornment>
                ),
                style: { borderRadius: "50px" },
                maxLength: 10, // Optional: You can set the maximum length if needed
                // onInput: handlePhoneChange, // Attach the input event handler
              }}
            />
          </Material.Box>
          {/* <Material.Button variant="outlined" onClick={handleBack}>
            {" "}
            <Material.Typography sx={{ fontSize: "20px" }}>
              🔙
            </Material.Typography>
          </Material.Button> */}
        </Material.Box>
        <Material.Box sx={{ margin: "10px" }}>
          <Material.Button variant="outlined" onClick={refreshOrderDetails}>
            🔃
          </Material.Button>
        </Material.Box>
        <Material.Box sx={{ margin: "10px" }}>
          <Material.Button variant="contained" onClick={handleOpenFilter}>
            Filter
          </Material.Button>
        </Material.Box>

        <Material.Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
          }}
        >
          <Material.FormControl>
            <Material.InputLabel>Ui View</Material.InputLabel>
            <Material.Select
              variant="standard"
              sx={{ fontSize: "14px", fontWeight: "bold", width: "80px" }}
              value={returnCanView}
              label="Ui View"
              onChange={handleReturnOrderView}
            >
              <Material.MenuItem value="form">Form</Material.MenuItem>
              <Material.MenuItem value="table">Table</Material.MenuItem>
              <Material.MenuItem value="card">Card</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
        </Material.Box>
        <Material.Box
          sx={{ maxWidth: "120px", maxHeight: "10px", margin: "5px" }}
        >
          <Material.TextField
            size="small"
            value={pageSize}
            label="Page size"
            // onChange={(e) => {
            //   setPageSize(e.target.value);
            //   // setSearchOrderFlag(true);
            // }}

            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          />
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // margin: "10px",
          }}
        >
          <Material.Button onClick={handleClickBack}>
            <ArrowBackIos />
          </Material.Button>
          <Material.Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "20px",
              height: "30px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber + 1}
          </Material.Typography>

          <Material.Button disabled={pageStatuss} onClick={handleClickForward}>
            <ArrowForwardIos />
          </Material.Button>
        </Material.Box>
        <Material.Box sx={{ m: 1 }}>
          <CSVLink {...csvLink}>
            {" "}
            <DownloadIcon
              sx={{ fontSize: 35 }}
              onClick={() =>
                toCSV(supplierDeliveries.getSupplerReturnCanOrders)
              }
            />
          </CSVLink>
        </Material.Box>
      </Material.Box>
      <Material.Box>
        {supplierDeliveries.getSupplerReturnCanOrders !== undefined &&
        supplierDeliveries.getSupplerReturnCanOrders !== null &&
        supplierDeliveries.getSupplerReturnCanOrders.length > 0 ? (
          <>
            {returnCanView === "form" ? (
              <ReturnCanFormView
                data={supplierDeliveries?.getSupplerReturnCanOrders}
                handleReturnCanEnterOTP={handleButtonData}
                handleOpenAssignDeliveryAgentDialog={
                  handleOpenAssignDeliveryAgentDialog
                }
              />
            ) : returnCanView === "table" ? (
              <ReturnCanOrdersTableView
                data={supplierDeliveries?.getSupplerReturnCanOrders}
                handleReturnCanEnterOTP={handleButtonData}
                handleOpenAssignDeliveryAgentDialog={
                  handleOpenAssignDeliveryAgentDialog
                }
              />
            ) : returnCanView === "card" ? (
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  overflow: "auto",
                  // height: "570px",
                }}
              >
                {supplierDeliveries.getSupplerReturnCanOrders !== null &&
                supplierDeliveries.getSupplerReturnCanOrders !== undefined &&
                supplierDeliveries.getSupplerReturnCanOrders.length > 0 ? (
                  <>
                    {supplierDeliveries.getSupplerReturnCanOrders.map(
                      (data, key) => {
                        return (
                          <ReturnCansOrdersCard
                            key={key}
                            order_id={data.return_can_order_id}
                            number_Of_cans={data.container_qty}
                            is_submit_finished={data.is_submit_finished}
                            is_submit_started={data.is_submit_started}
                            cans_recived={data.received_container_qty}
                            cans_exachanged={data.exchanged_can_qty}
                            container_capacity={data.container_capacity}
                            container_mayerial={data.container_material}
                            order_status={data.order_status}
                            delivery_agent_id={data.delivery_agent_id}
                            handleReturnCanEnterOTP={handleButtonData}
                            delivery_address={data.delivery_address}
                            handleOpenAssignDeliveryAgentDialog={
                              handleOpenAssignDeliveryAgentDialog
                            }
                            handleViewDetails={handleViewDetails}
                            handlePhone={handlePhone}
                            handleLocation={handleLocation}
                            handleCopyAddress={handleCopyAddress}
                          />
                        );
                      }
                    )}
                  </>
                ) : (
                  <Material.Typography sx={{ fontWeight: "bold" }}>
                    Return Can Orders Left
                  </Material.Typography>
                )}
              </Material.Box>
            ) : (
              ""
            )}
          </>
        ) : (
          <Material.Box sx={{ marginTop: "20px" }}>
            <Material.Typography color="error">
              Return Can Orders Left
            </Material.Typography>
          </Material.Box>
        )}
      </Material.Box>
      <Material.Box
      // sx={{
      //   display: "flex",
      //   flexDirection: "row",
      //   justifyContent: "space-around",
      //   flexWrap: "wrap",
      //   overflow: "auto",
      //   height: "420px",
      // }}
      >
        {/* {supplierDeliveries.getSupplerReturnCanOrders.length > 0 ? (
          <>
            {supplierDeliveries.getSupplerReturnCanOrders.map((data, key) => {
              return (
                <ReturnCansOrdersCard
                  key={key}
                  order_id={data.return_can_order_id}
                  number_Of_cans={data.container_qty}
                  is_submit_finished={data.is_submit_finished}
                  is_submit_started={data.is_submit_started}
                  cans_recived={data.received_container_qty}
                  cans_exachanged={data.exchanged_can_qty}
                  container_capacity={data.container_capacity}
                  container_mayerial={data.container_material}
                  order_status={data.order_status}
                  delivery_agent_id={data.delivery_agent_id}
                  handleReturnCanEnterOTP={handleButtonData}
                  handleOpenAssignDeliveryAgentDialog={
                    handleOpenAssignDeliveryAgentDialog
                  }
                />
              );
            })}
          </>
        ) : (
          <Material.Typography sx={{ fontWeight: "bold" }}>
            Return Can Orders Left
          </Material.Typography>
        )} */}

        {enterOtpOpenDialog === true ? (
          <SupplierOrdersEnterOtpDialog
            isDialogOpen={enterOtpOpenDialog}
            handleClose={handleCloseDialog}
            handleSubmitOtp={handleSubmitOtp}
            enterOtpTextfied={enterOtpTextfied}
            setEnterOtpTextfied={setEnterOtpTextfied}
            setSelectCanFilledOrNot={setSelectCanFilledOrNot}
            selectCanFilledOrNot={selectCanFilledOrNot}
            setOtpValidationError={setOtpValidationError}
            otpValidationError={otpValidationError}
          />
        ) : (
          ""
        )}
        {openQrorCanDamageDialog === true ? (
          <SupplierQRorCanDamageDialog
            openQrorCanDamageDialog={openQrorCanDamageDialog}
            handleSubmit={handleSubmitQrorCanDamage}
            handleClose={handleCloseQrorCanDamage}
          />
        ) : (
          ""
        )}
        <ReturnCanCameraDialog
          openCamera={openCamera}
          openReturnCanCameraDialog={openReturnCanCameraDialog}
          returnCanOrderId={returnCanOrderId}
          setOpenCamera={setOpenCamera}
          setOpenReturnCanCameraDialog={setOpenReturnCanCameraDialog}
          apiData={apiData}
          handleClose={handleCloseCameraDialog}
          pageNumber={pageNumber}
          pageSize={pageSize}
        />

        <AssignDeliveryAgentDialog
          openAssignDeliveryAgentDialog={openAssignDeliveryAgentDialog}
          agentListata={supplierDeliveries?.getSupplerSupplierDeliveryAgentList}
          handleClickAssignDeliveryAgent={handleAssignDeliveryAgent}
          hadleClose={handleCloses}
        />
        <SupplierReturnCanFilter
          openReturnCanFilter={openReturnCanFilter}
          agentListata={supplierDeliveries.getAssignDeliveryAgent}
          handleFilter={handleFilter}
          handleClose={handleClose}
        />

        {/* {openCamera === true ? (
          
          <>
            {" "}
            <Html5QrcodePlugin1
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
          </>
        ) : (
          ""
        )} */}
        {/* {apiData.damage_type === 101 && openCamera === true ? (
        <Html5QrcodePlugin1
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
      ) : (
        ""
      )} */}
        {/* <ResultContainerPlugin1
          results={decodedResults}
          setScanCount={setScanCount}
        /> */}
        {/* <Material.Box>
          <QrBarcodeLinkingDialog
            setLinkingDialogOpen={setLinkingDialogOpen}
            linkingDialogOpen={linkingDialogOpen}
            scannedQrCode={scannedQrCode}
            scannedBarCode={scannedBarCode}
            setLink={setLink}
            scanCount={scanCount}
            setLinkQrBarcodeBtn={setLinkQrBarcodeBtn}
            inQrBarcodeAdmin={inQrBarcodeAdmin}
          />
        </Material.Box> */}
      </Material.Box>
    </Material.Box>
  );
}

export default ReturnCanOrders;
