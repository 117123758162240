import React from "react";
import * as Material from "@mui/material";
import { appColors } from "../../Assests/AppTheme/appThemeConst";
import CustomConsole from "../../../CustomConsole";

function CurrentDayDeliveriesEnterOtpDialog(props) {
  CustomConsole(props)
    const handleSubmitOTP = () => {
        CustomConsole("-- handleSubmitOTP ---");
        props.handleSubmitOTP(props);
      };
      const handleClose = () => {
        CustomConsole("-- handleClose ---");
        props.handleClose(props);
      };
    
      const handleChangeOTP = (event) => {
       CustomConsole("-- handleChangeOTP ---")
        let dataTemp = event.target.value + "";
        if (dataTemp.length <= 4) {
          props.setEnterOtpTextfied(event.target.value);
          if (dataTemp.length == 4) {
            props.setOtpValidationError(false);
          } else {
             props.setOtpValidationError(true);
          }
        } else {
          props.setOtpValidationError(true);
        }
    
        
      };
  return (
    <Material.Box sx={{ borderRadius: "30px" }}>
      <Material.Dialog
        open={props.openEnterOtpDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "30px",
            },
          },
        }}
      >
        <Material.DialogContent
          // sx={{
          //   backgroundImage: "linear-gradient(to right, blue, brown)",
          //   borderRadius: "30px",
          // }}
          sx={{
            background: "rgb(208,221,242)",
            backgroundImage:
              "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
          }}
        >
          <Material.Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "10px",
              // color: "white",
            }}
          >
            Enter OTP
          </Material.Typography>
          <Material.TextField
            variant="outlined"
            type="number"
            label="Enter Your OTP"
            value={props.enterOtpTextfied}
            onChange={handleChangeOTP}
            // inputProps={{
            //   min: 0,
            //   maxLength: 4,
            //   inputMode: "numeric",
            //   pattern: "[0-9]*",
            // }}
          />
          <Material.Box
            sx={{
              margin: "10px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Material.Button
              variant="contained"
              size="small"
              onClick={handleClose}
              sx={{
                background: appColors.commonBtnColor.background,
                borderRadius: "20px",
              }}
            >
              Cancel
            </Material.Button>
            <Material.Button
              variant="contained"
              size="small"
              onClick={handleSubmitOTP}
              sx={{
                marginRight: "10px",
                background: appColors.commonBtnColor.background,
                color: "white",
                borderRadius: "20px",
              }}
            >
              Submit
            </Material.Button>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default CurrentDayDeliveriesEnterOtpDialog;
