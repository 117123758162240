import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import NotInterestedRoundedIcon from "@mui/icons-material/NotInterestedRounded";
import CustomConsole from "../../CustomConsole";
import {
  DamageCansAccept,
  DamageCansReject,
  GetWithdrawallist,
  GetWithdrawallistOrderid,
} from "../Redux/Actions/AdminWithdrawalListAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function AccceptRejectCard({
  withdrawDet,
  setOpenDialogReturnCanSingleOrders,
  pageNumber,
}) {
  CustomConsole(withdrawDet);
  CustomConsole(pageNumber);
  const [physicallyDamaged, setPhysicallyDamaged] = React.useState(false);
  const [qrBarCodeDamage, setQrBarCodeDamage] = React.useState(false);
  const [lostCans, setLostCans] = React.useState(false);
  const [validCans, setValidCans] = React.useState(false);
  const [acceptRejectFlag, setAcceptRejectFlag] = React.useState(false);

  const dispatch = useDispatch();
  const adminWithdrawallistData = useSelector(
    (state) => state.withdrawalOrderlistAdmin
  );

  React.useEffect(() => {
    CustomConsole(withdrawDet);
    if (withdrawDet.physically_damage_can_qty > 0) {
      setPhysicallyDamaged(true);
    }
    if (withdrawDet.qr_bar_code_damage_can_qty > 0) {
      setQrBarCodeDamage(true);
    }
    if (withdrawDet.lost_cans > 0) {
      setLostCans(true);
    }
    if (withdrawDet.deposited_valid_cans > 0) {
      setValidCans(true);
    }
  }, [withdrawDet]);

  const acceptDamageCans = () => {
    CustomConsole("Accept");
    const reqObj = {
      is_physically_damage_can_checked: physicallyDamaged,
      is_qr_bar_code_damage_can_checked: qrBarCodeDamage,
      is_lost_cans_checked: lostCans,
      is_deposited_valid_cans_checked: validCans,
      user_id: withdrawDet.user_id,
    };
    CustomConsole(reqObj);
    dispatch(DamageCansAccept(withdrawDet.return_can_order_id, reqObj));
    setTimeout(() => {
      dispatch(
        GetWithdrawallist({
          page_number: pageNumber - 1,
          // page_size: pageSize,
        })
      );
    }, 500);
    setOpenDialogReturnCanSingleOrders(false);
    setAcceptRejectFlag(true);
  };

  const rejectDamageCans = () => {
    CustomConsole("Reject");
    const reqObj = {
      is_physically_damage_can_checked: physicallyDamaged,
      is_qr_bar_code_damage_can_checked: qrBarCodeDamage,
      is_lost_cans_checked: lostCans,
      is_deposited_valid_cans_checked: validCans,
      user_id: withdrawDet.user_id,
    };
    CustomConsole(reqObj);
    dispatch(DamageCansReject(withdrawDet.return_can_order_id, reqObj));
    setTimeout(() => {
      dispatch(
        GetWithdrawallist({
          page_number: pageNumber - 1,
          // page_size: pageSize,
        })
      );
    }, 500);
    setOpenDialogReturnCanSingleOrders(false);
    setAcceptRejectFlag(true);
  };

  React.useEffect(() => {
    CustomConsole(adminWithdrawallistData);
    CustomConsole(acceptRejectFlag);
    if (
      !adminWithdrawallistData.error &&
      acceptRejectFlag &&
      adminWithdrawallistData.msg !== ""
    ) {
      toast.success(adminWithdrawallistData.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(GetWithdrawallistOrderid(withdrawDet.return_can_order_id));
      setAcceptRejectFlag(false);
    } else if (adminWithdrawallistData.error && acceptRejectFlag) {
      toast.error(adminWithdrawallistData.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAcceptRejectFlag(false);
    }
  }, [adminWithdrawallistData]);

  return (
    <Box>
      <Card
        sx={{
          maxWidth: 275,
          backgroundColor: "#e6f6fa",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {withdrawDet.return_can_order_id}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>
                Please unmark the cans that you want to retain instead of your
                Bookwater Deposit. Also, kindly let us know wheather you accept
                the damage.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormGroup>
                {withdrawDet.physically_damage_can_qty > 0 && (
                  <FormControlLabel
                    checked={physicallyDamaged}
                    onChange={(event) =>
                      setPhysicallyDamaged(event.target.checked)
                    }
                    control={<Checkbox />}
                    label={`Physically damaged(${withdrawDet.physically_damage_can_qty})`}
                  />
                )}

                {withdrawDet.qr_bar_code_damage_can_qty > 0 && (
                  <FormControlLabel
                    checked={qrBarCodeDamage}
                    onChange={(event) =>
                      setQrBarCodeDamage(event.target.checked)
                    }
                    control={<Checkbox />}
                    label={`QR/Barcode Damaged(${withdrawDet.qr_bar_code_damage_can_qty})`}
                  />
                )}

                {withdrawDet.lost_cans > 0 && (
                  <FormControlLabel
                    checked={lostCans}
                    onChange={(event) => setLostCans(event.target.checked)}
                    control={<Checkbox />}
                    label={`Lost cans(${withdrawDet.lost_cans})`}
                  />
                )}

                {withdrawDet.deposited_valid_cans > 0 && (
                  <FormControlLabel
                    checked={validCans}
                    onChange={(event) => setValidCans(event.target.checked)}
                    control={<Checkbox />}
                    label={`Valid cans(${withdrawDet.deposited_valid_cans})`}
                  />
                )}
              </FormGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<CheckCircleOutlineRoundedIcon />}
                onClick={acceptDamageCans}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                size="small"
                startIcon={<NotInterestedRoundedIcon />}
                onClick={rejectDamageCans}
              >
                Reject
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AccceptRejectCard;
