import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialOrdersState = {
  error: true,
  msg: "",
  ongoingOrdersWfsAdmin: [],
  completedOrdersWfsAdmin: [],
  allOrdersWfsAdmin: [],
  ordersCommon: [],
  userDetails: [],
  startSubmissionOngoing: {},
  getwfsCompletedBatchDetails: [],
  putEdiBatchDetails: {},
  errorData: "",
  editBatchError: true,
};

const adminWfsCanOrdersReducers = (state = initialOrdersState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_ONGOING_ORDER_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        ongoingOrdersWfsAdmin: action.payload.data,
        ordersCommon: action.payload.data,
        errorResponse: action.payload,
      };
    case actionType.GET_COMPLETED_ORDER_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        completedOrdersWfsAdmin: action.payload.data,
        ordersCommon: action.payload.data,
        errorResponse: action.payload,
      };
    case actionType.GET_ALL_ORDER_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        allOrdersWfsAdmin: action.payload.data,
        ordersCommon: action.payload.data,
        errorResponse: action.payload,
      };
    case actionType.GET_USER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        userDetails: action.payload.users,
        msg: action.payload.msg,
      };
    case actionType.PUT_START_SUBMISSION_ONGOING_ADMIN:
      return {
        ...state,
        error: action.payload.data.error,
        startSubmissionOngoing: action.payload.data,
        msg: action.payload.data.msg,
      };
    case actionType.GET_WFS_COMPLETED_BATCH_DETAILS:
      return {
        ...state,
        error: action.payload.data.error,
        getwfsCompletedBatchDetails: action.payload.data,
        msg: action.payload.data.msg,
      };
    case actionType.PUT_WFS_EDIT_BATCH_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        editBatchError: action.payload.error,
        putEdiBatchDetails: action.payload,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default adminWfsCanOrdersReducers;
