import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Divider,
  Box,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  ConsumersCouponCodes,
  EditSubscriptionDetails,
  SubscriptionBooking,
  ValidatingProducts,
} from "../Redux/Actions/adminSkuBookingAction";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function CartCard({
  setCartProducts,
  cartProducts,
  userId,
  products,
  bookingType,
  addressId,
  otpLess,
  payMethod,
  orderDate,
  selectedCoup,
  setSelectedCoup,
  waterDrops,
  checks,
  selectedDays,
  edit,
  subscriptionId,
  setActiveStep,
}) {
  const dispatch = useDispatch();
  const validproducts = useSelector(
    (state) => state.adminSkuBookingData.subsValidProducts
  );
  const inValidproducts = useSelector(
    (state) => state.adminSkuBookingData.subsCartCalulations.invalid_products
  );
  const allData = useSelector(
    (state) => state.adminSkuBookingData.subsCartCalulations
  );
  const reducerData = useSelector(
    (state) => state.adminSkuBookingData.products
  );
  const subsBooking = useSelector(
    (state) => state.adminSkuBookingData.subscriptionResp
  );
  const loader = useSelector((state) => state.adminLoader.loading);
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const [inValidProducts, setInvalidProducts] = React.useState([]);
  const initailMount = React.useRef(true);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [bookingTrigger, setBookingTrigger] = React.useState(false);

  const orderPlaceFinalArr = (obj) => {
    let validArr = obj?.valid_products ? obj.valid_products : [];
    const isValidArr = validArr.every(
      (product) => product.quantity.is_valid && product.return.is_valid
    );
    if (!isValidArr || validArr.length === 0 || !obj.payment_method.is_valid) {
      return false;
    }
    const transformedData = obj.valid_products.reduce(
      (acc, product) => {
        acc.order_details.products.push({
          capacity: product.capacity,
          material: product.material,
          quantity: product.quantity.selected,
          return: product.return.selected,
          sku_qty: product.sku_qty,
        });
        return acc;
      },
      {
        user_id: userId,
        order_details: {
          products: [],
          is_auto_apply_coupon: checks.promo,
          is_auto_apply_water_drops: checks.waterDrops,
          is_otp_less: otpLess,
        },
        repeat_days: selectedDays,
        start_date: orderDate,
        address_id: addressId,
        is_forced_create: false,
      }
    );
    return transformedData;
  };

  const placeOrder = (orderObj) => {
    const reqParam = {
      user_phone: phoneNumber,
    };

    if (edit) {
      dispatch(EditSubscriptionDetails(orderObj, reqParam, subscriptionId));
    } else {
      dispatch(SubscriptionBooking(orderObj, reqParam));
    }
    setBookingTrigger(true);
  };

  React.useEffect(() => {
    if (initailMount.current) {
      initailMount.current = false;
    } else {
      let reqObj = {
        products: products,
        booking_type: parseInt(bookingType),
        address_id: addressId,
        coupon_code: selectedCoup,
        water_drops: waterDrops,
        payment_method: payMethod,
        is_otp_less: otpLess,
        delivery_date: orderDate,
      };

      CustomConsole(products);
      dispatch(ValidatingProducts(reqObj));
    }
  }, [waterDrops]);

  React.useEffect(() => {
    CustomConsole("-------Cart products----");
    CustomConsole(cartProducts);
  }, [cartProducts]);

  React.useEffect(() => {
    CustomConsole("-----before every loop----");
    CustomConsole(validproducts);

    const inValidProductInValid = validproducts?.filter(
      (product) => !product.quantity.is_valid || !product.return.is_valid
    );
    setCartProducts(() => (validproducts ? validproducts : []));

    setInvalidProducts((prev) =>
      inValidproducts && inValidProductInValid
        ? [...inValidProductInValid, ...inValidproducts]
        : []
    );
  }, [validproducts, inValidproducts]);

  React.useEffect(() => {
    CustomConsole("----Valid----Invalid----");
    CustomConsole(cartProducts);
    CustomConsole(inValidProducts);
  }, [cartProducts, inValidProducts]);

  React.useEffect(() => {
    if (allData) {
      const reqObj = {
        user_id: userId,
        final_water_price: allData?.final_price,
        can_count: 1,
        is_wallet: false,
      };

      dispatch(ConsumersCouponCodes(reqObj));
    }
  }, [allData]);

  React.useEffect(() => {
    CustomConsole("-----Coupon details---");
    CustomConsole(reducerData);
  }, [reducerData]);

  React.useEffect(() => {
    CustomConsole("-----Admin detail action----");
    CustomConsole(addUserAdminDetails);
    if (addUserAdminDetails.userData?.[0].phone) {
      setPhoneNumber(() => addUserAdminDetails.userData?.[0].phone);
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (subsBooking?.error === false && bookingTrigger) {
      setActiveStep((prev) => prev - 1);
      setBookingTrigger(false);
    }
  }, [subsBooking, bookingTrigger]);

  React.useEffect(() => {
    CustomConsole("-----After Booking----");
    CustomConsole(subsBooking);
  }, [subsBooking]);

  // React.useEffect(() => {
  //    //@Removed/console.log("----Seteled days---");
  //    //@Removed/console.log(selectedDays);
  // }, [selectedDays]);

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        backgroundColor: "#f0f5f9",
        width: "100%",
        maxWidth: 400,
        margin: "auto",
      }}
    >
      <CardContent>
        {cartProducts.length === 0 ? (
          <Typography variant="body1" align="center">
            No products to display
          </Typography>
        ) : (
          <Box>
            {inValidProducts.map((product, idx) => (
              <div key={idx}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{
                        fontFamily: "Roboto",
                        color: "#333",
                      }}
                    >
                      {product.material}-{product.capacity}- Invalid Product
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <IconButton
                      //   onClick={() => onRemoveProduct(product.id)}
                      aria-label="delete"
                      size="small"
                      sx={{ color: "#d32f2f" }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Grid>
                </Grid>

                <Divider />
              </div>
            ))}

            {cartProducts.map((product, idx) => (
              <div key={idx}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{
                        fontFamily: "Roboto",
                        color: "#333",
                      }}
                    >
                      {product.material}-{product.capacity}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <IconButton
                      //   onClick={() => onRemoveProduct(product.id)}
                      aria-label="delete"
                      size="small"
                      sx={{ color: "#10D847" }}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {product.quantity.selected}X{product.capacity} Water
                      Price:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      &#x20B9; {product.product_price}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {product.deposit.quantity}X{product.capacity} Can Price:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      &#x20B9; {product.deposit.price}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Total no:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {product.quantity.selected * product.sku_qty}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Subtotal:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      &#x20B9; {product.final_price}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Discount: </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{product.discount}</Typography>
                  </Grid>
                  {!product.return.is_valid ? (
                    <Grid item xs={6}>
                      <Typography variant="body1" color={"red"}>
                        you can return max {product.return.max_return} Quantity
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>

                <Divider />
              </div>
            ))}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <Typography variant="body1">Payment Method:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {allData?.payment_method.payment_method}
                </Typography>
                {!allData?.payment_method.is_valid ? (
                  <Typography variant="body1" color={"red"}>
                    Invalid Payment method
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              {orderPlaceFinalArr(allData) ? (
                <>
                  <Grid item xs={6}>
                    <Typography variant="body1">Discount: </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      &#x20B9;{allData.discount}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Grand Total:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      &#x20B9; {allData.final_price}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <Box sx={{ float: "right" }}>
                      <Button
                        disabled={loader}
                        onClick={() => placeOrder(orderPlaceFinalArr(allData))}
                      >
                        Subscribe
                      </Button>
                    </Box>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default CartCard;
