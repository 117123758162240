/**
 * @author Gowtham Prasath
 * @description All can Orders
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 07-02-2024
 */
// Importing the Libraries and Other Files

import { Box, Grid, Typography, Container } from "@mui/material";
import CustomConsole from "../../CustomConsole";
import * as Material from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import WfsDetailsTable from "./WfsDetailsTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { WfsList } from "../Redux/Actions/adminAddUpdateWfsAction";
import UpdateWfsDetailsCard from "./UpdateWfsDetailsCard";
import { ToastContainer, toast } from "react-toastify";
import { Avatar } from "@mui/material";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { GetWfsDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import AdminIotDevices from "../AdminDevices/AdminIotDevices";

function WfsDetailsSku() {
  const [alignment, setAlignment] = React.useState("1");

  React.useEffect(() => {
    CustomConsole(alignment);
    if (alignment === "2") {
      setAddWfsFlag(true);
    } else if (alignment === "1") {
      setAddWfsFlag(false);
      setOpenWfsTable(false);
    }
  }, [alignment]);
  const dispatch = useDispatch();
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const [available, setAvailable] = React.useState(true);
  const [pincode, setPincode] = React.useState(600018);
  const [wfsDetails, setWfsDetails] = React.useState({});
  const [showDetailsFlag, setShowDetailsFlag] = React.useState(false);
  const [alignmentWfs, setAlignmentWfs] = React.useState("WfsDetails");
  //ADD WFS Flag
  const [addWfsFlag, setAddWfsFlag] = React.useState(false);
  const [triggEffect, setTrigEffect] = React.useState(0);
  const [openwfsTable, setOpenWfsTable] = React.useState(false);
  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");
  const [search, setSearch] = React.useState(false);
  const [wfsFilters, setWfsFilters] = React.useState({
    page_number: 1,
    page_size: 25,
    wfs_id: "",
    wfs_name: "",
    wfs_owner_phone: "",
    incharge_phone: "",
    incharge_email: "",
    sort_created_at: "",
    sort_wfs_name: "",
    wfs_availability: "",
  });

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const handleClickWfs = () => {
    CustomConsole(pincode.length);
    setAddWfsFlag(false);
    dispatch(GetWfsDetailsSku(wfsFilters));
    setAddWfsFlag(false);
    setOpenWfsTable(true);
  };

  React.useEffect(() => {
    dispatch(GetWfsDetailsSku(wfsFilters));
    setAddWfsFlag(false);
    setOpenWfsTable(true);
  }, []);

  const onClickScroll = React.useRef(null);

  let addNewWfs = () => {
    setAddWfsFlag(true);
  };

  const handelCloseAddDialog = () => {
    setAddWfsFlag(false);
  };
  const handleChangeToggleWfs = (event, newAlignment) => {
    setAlignmentWfs(newAlignment);
  };

  CustomConsole(skuWfsDetails);
  return (
    <Box sx={{ marginTop: "4rem" }}>
      <Material.Box
        sx={{ display: "flex", justifyContent: "flex-start", m: 1 }}
      >
        <Material.ToggleButtonGroup
          color="primary"
          value={alignmentWfs}
          exclusive
          onChange={handleChangeToggleWfs}
          aria-label="Platform"
          size="small"
        >
          <Material.ToggleButton value="WfsDetails">
            Wfs Details
          </Material.ToggleButton>
          <Material.ToggleButton value="IotDevices">
            iot device Details
          </Material.ToggleButton>
        </Material.ToggleButtonGroup>
      </Material.Box>
      {alignmentWfs === "WfsDetails" ? (
        <>
          {ADMIN_ROLES.BW_ADMIN === adminRole ||
          ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
          ADMIN_ROLES.BW_SALESHEAD === adminRole ||
          ADMIN_ROLES.BW_OPSHEAD === adminRole ||
          ADMIN_ROLES.BW_IVML === adminRole ? (
            <Box>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Water Filling Station Details
                  </Typography>
                  <Button variant="contained" size="small" onClick={addNewWfs}>
                    Add New WFS
                  </Button>
                </Box>
              </Box>
              <Box>
                {skuWfsDetails.wfsDetails.length > 0 ? (
                  <WfsDetailsTable
                    setAddWfsFlag={setAddWfsFlag}
                    setWfsDetails={setWfsDetails}
                    setTrigEffect={setTrigEffect}
                    triggEffect={triggEffect}
                    wfsFilters={wfsFilters}
                    setWfsFilters={setWfsFilters}
                  />
                ) : null}
              </Box>
              <Box>
                <Box>
                  <UpdateWfsDetailsCard
                    wfsDetails={wfsDetails}
                    setWfsDetails={setWfsDetails}
                    triggEffect={triggEffect}
                    addWfsFlag={addWfsFlag}
                    onClickScroll={onClickScroll}
                    handleClickWfs={handleClickWfs}
                    showDetailsFlag={showDetailsFlag}
                    open={addWfsFlag}
                    onClose={handelCloseAddDialog}
                  />
                </Box>
                <ToastContainer />
                {/* </Box> */}
              </Box>
            </Box>
          ) : (
            <Box sx={{ marginTop: "10px" }}>
              <Alert severity="error">Access Denied</Alert>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <AdminIotDevices />
        </Box>
      )}
    </Box>
  );
}

export default WfsDetailsSku;
