import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";

function CanWapFilterDialog(props) {
  const [sortRequestId, setSortRequestId] = React.useState(
    props.filterData.sort_by_id || ""
  );
  const [sortByOrderId, setSortByOrderId] = React.useState(
    props.filterData.sort_by_order_id || ""
  );
  const [sortByUpdatedAt, setSortByUpdatedAt] = React.useState(
    props.filterData.sort_by_updated_at || ""
  );
  const [sortByCratedAt, setSortByCratedAt] = React.useState(
    props.filterData.sort_by_created_at || ""
  );
  const [orderId, setOrderId] = React.useState(props.filterData.order_id || "");
  const [status, setStatus] = React.useState(props.filterData.status || 100);
  const [reqId, setReqId] = React.useState(props.filterData.id || 0);
  React.useEffect(() => {
    CustomConsole("-- status --");
    CustomConsole(status);
  }, [status]);
  React.useEffect(() => {}, [sortRequestId]);
  React.useEffect(() => {}, [sortByOrderId]);
  React.useEffect(() => {}, [sortByUpdatedAt]);
  React.useEffect(() => {}, [sortByCratedAt]);
  React.useEffect(() => {}, [orderId]);
  React.useEffect(() => {}, [status]);
  React.useEffect(() => {}, [reqId]);
  const handleCloseFilter = () => {
    props.handleCloseFilter();
  };
  const handleFilterCanSwap = () => {
    let reqObj = {};
    if (parseInt(reqId)) {
      reqObj.id = parseInt(reqId);
    }
    if (status) {
      reqObj.status = status;
    }
    if (orderId) {
      reqObj.order_id = orderId;
    }
    if (sortByCratedAt) {
      reqObj.sort_by_created_at = sortByCratedAt;
    }
    if (sortByUpdatedAt) {
      reqObj.sort_by_updated_at = sortByUpdatedAt;
    }
    if (sortByOrderId) {
      reqObj.sort_by_order_id = sortByOrderId;
    }
    if (sortRequestId) {
      reqObj.sort_by_id = sortRequestId;
    }

    props.handleFilterCanSwap(reqObj);
  };
  const handleClearFilter = () => {
    setStatus(100);
    setSortRequestId("");
    setSortByOrderId("");
    setSortByUpdatedAt("");
    setSortByCratedAt("");
    setOrderId("");
    setReqId(0);
  };

  const handleChangeReqId = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setReqId(() => event.target.value);
  };
  return (
    <Material.Dialog open={props.openFilterCanSwap}>
      <Material.DialogTitle>Filter Based On Options</Material.DialogTitle>
      <Material.DialogContent>
        <Material.Box sx={{ display: "flex", flexDirection: "column" }}>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Status</Material.InputLabel>
            <Material.Select
              label="Status"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
            >
              <Material.MenuItem value={100}>PENDING</Material.MenuItem>
              <Material.MenuItem value={200}>ACCEPTED</Material.MenuItem>
              <Material.MenuItem value={300}>REJECTED</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.TextField
            sx={{ m: 1 }}
            value={orderId}
            onChange={(event) => setOrderId(event.target.value)}
            label="Order Id"
          />
          <Material.TextField
            sx={{ m: 1 }}
            value={reqId}
            type="number"
            // onChange={handleChangeReqId}
            label="Request Id"
            inputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangeReqId, // Attach the input event handler
            }}
          />

          {/* <Material.TextField sx={{ m: 1 }} label="End Number" /> */}
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort By Created At</Material.InputLabel>
            <Material.Select
              label="Sort By Created At"
              value={sortByCratedAt}
              onChange={(event) => setSortByCratedAt(event.target.value)}
            >
              <Material.MenuItem value={"ASC"}>ASC</Material.MenuItem>
              <Material.MenuItem value={"DESC"}>DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort By Updated At</Material.InputLabel>
            <Material.Select
              label="Sort By Updated At"
              value={sortByUpdatedAt}
              onChange={(event) => setSortByUpdatedAt(event.target.value)}
            >
              <Material.MenuItem value={"ASC"}>ASC</Material.MenuItem>
              <Material.MenuItem value={"DESC"}>DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort By Order Id</Material.InputLabel>
            <Material.Select
              label="Sort By Order Id"
              value={sortByOrderId}
              onChange={(event) => setSortByOrderId(event.target.value)}
            >
              <Material.MenuItem value={"ASC"}>ASC</Material.MenuItem>
              <Material.MenuItem value={"DESC"}>DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>

          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort By Request Id</Material.InputLabel>
            <Material.Select
              label="Sort By Request Id"
              value={sortRequestId}
              onChange={(event) => setSortRequestId(event.target.value)}
            >
              <Material.MenuItem value={"ASC"}>ASC</Material.MenuItem>
              <Material.MenuItem value={"DESC"}>DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
        </Material.Box>
      </Material.DialogContent>
      <Material.DialogActions>
        <Material.Button
          variant="contained"
          color="warning"
          onClick={handleClearFilter}
        >
          Clear all
        </Material.Button>
        <Material.Button
          variant="contained"
          color="error"
          onClick={handleCloseFilter}
        >
          close
        </Material.Button>
        <Material.Button variant="contained" onClick={handleFilterCanSwap}>
          filter
        </Material.Button>
      </Material.DialogActions>
    </Material.Dialog>
  );
}

export default CanWapFilterDialog;
