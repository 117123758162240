import * as React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styles } from "./cardStyles";
import Paper from "@mui/material/Paper";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import QrBarcodeLinkingDialog from "../AdminGenrateQRBarcode/QrBarcodeLinkingDialog";
import moment from "moment";
import {
  InScanEmptyCans,
  OutScanFilledCans,
} from "../Redux/Actions/adminQrBarcodeAction";
import { useDispatch, useSelector } from "react-redux";
import EnterOTPDialog from "./EnterOTPDialog";
import { GetWfsOngoingOrders } from "../Redux/Actions/wfsOrdersAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

export default function OngoingOrdersCard({ data, pageNumber }) {
  CustomConsole(data);
  const history = useHistory();
  const dispatch = useDispatch();
  const adminQrBarcodeData = useSelector((state) => state.adminQrBarcodeData);
  const [isCameraOpen, setIsCameraOpen] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [scanCount, setScanCount] = React.useState(0);

  const [decodedResults, setDecodedResults] = React.useState([]);
  const [btnName, setbtnName] = React.useState("");
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState(false);
  const [scanOption, setScanOption] = React.useState("scanner");
  const [orderId, setOrderId] = React.useState("");
  const [openVerifyOtpDialog, setOpenVerifyOtpDialog] = React.useState(false);

  React.useEffect(() => {
    CustomConsole("----after scanning----");
    CustomConsole(adminQrBarcodeData);
  }, [adminQrBarcodeData]);
  React.useEffect(() => {
    if (data.is_submit_started === true && data.is_submit_finished === false) {
      if (
        data.is_admin === true ||
        data.empty_containers_submitting !== data.submitted_containers
      ) {
        setbtnName("Start Inscan");
      } else if (
        data.empty_containers_submitting === data.submitted_containers &&
        data.is_submit_finished === false
      ) {
        setbtnName("Start Outscan");
      }
    } else if (data.is_submit_finished === true) {
      setbtnName("Verify OTP");
    }
  }, [data]);

  React.useEffect(() => {
    if (scannedQrCode !== "" || scannedBarCode !== "") {
      CustomConsole("-----------in Scan-----------------");
      if (btnName === "Start Inscan") {
        CustomConsole("-----------getting here 0-----------------");
        const reqObj = {
          order_id: orderId,
          qr_barcode_id1: scannedQrCode || scannedBarCode,
          qr_barcode_id2: "",
        };
        CustomConsole(reqObj);
        dispatch(InScanEmptyCans(reqObj));
        setScannedQrCode("");
        setScannedBarCode("");
        // setLinkingDialogOpen(false);
        // setScanCount(0);
        setDecodedResults([]);
        // setLink(false);
        setIsCameraOpen(false);
        setTimeout(() => {
          dispatch(GetWfsOngoingOrders(pageNumber - 1));
        }, 1000);
      } else if (btnName === "Start Outscan") {
        const reqObj = {
          order_id: orderId,
          qr_id: scannedQrCode,
        };
        CustomConsole("getting here 1");
        dispatch(OutScanFilledCans(reqObj));
        setScannedQrCode("");
        setScannedBarCode("");
        setLinkingDialogOpen(false);
        // setScanCount(0);
        setDecodedResults([]);
        setLink(false);
        setIsCameraOpen(false);
      }
    }
  }, [scannedQrCode, scannedBarCode]);

  const startScanning = (orderId) => {
    CustomConsole(btnName);
    CustomConsole(orderId);
    setOrderId(orderId);
    if (btnName !== "Verify OTP") {
      setIsCameraOpen(!isCameraOpen);
    } else {
      setOpenVerifyOtpDialog(true);
    }
  };

  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  React.useEffect(() => {
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "" && btnName !== "Start Outscan") {
      setLinkingDialogOpen(true);
      setIsCameraOpen(false);
    }
    // else if (btnName === "Start Outscan") {
    //   const reqObj = {
    //     order_id: orderId,
    //     qr_id: scannedQrCode,
    //   };
    //   CustomConsole("out scanned");
    //   dispatch(OutScanFilledCans(reqObj));
    //   setIsCameraOpen(false);
    //   setTimeout(() => {
    //     dispatch(GetWfsOngoingOrders(pageNumber - 1));
    //   }, 1000);
    // }
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setIsCameraOpen(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      setScannedQrCode(qrBarcodeId);
      setDecodedResults((prev) => [...prev, qrBarcodeId]);

      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);
  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };
  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };
  const handleCloseDialog = () => {
    setIsCameraOpen(false);
  };

  const handleViewDetails = () => {
    const url = `/dashboard/WfsSingleOrder?order_id=${data.ec_order_id}`;
    history.push(url);
  };
  return (
    <Paper elevation={24}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Order ID</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Order Qty</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>No of Damaged</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>No of Expired</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Can Capacity</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Can material</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Order Status</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Order placed By</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Order Created at</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Received Cans</Typography>
              </Box>
              <Box sx={styles.cardStyleHeading}>
                <Typography component={"span"}>Empty Cans</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>{data.ec_order_id}</Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>
                  {data.order_details?.order_qty}
                </Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>
                  {data.order_details?.damaged_qty}
                </Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>
                  {data.order_details?.expired_qty}
                </Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>
                  {data.order_details?.container_capacity}
                </Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>
                  {data.order_details?.container_material}
                </Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>{data.order_status}</Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>
                  {data.is_admin === true ? "Admin" : "BWD"}
                </Typography>
              </Box>

              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>{data.created_at}</Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography> {data.order_details?.outscanned_cans}</Typography>
              </Box>
              <Box sx={styles.cardStyleValue}>
                <Typography component={"span"}>
                  {data.order_details?.inscanned_cans}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Material.Box sx={{ margin: "10px" }}>
            <Material.Button variant="contained" onClick={handleViewDetails}>
              view Details
            </Material.Button>
          </Material.Box>
          {/* {btnName !== "" && (
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ height: "20px", width: "250px" }}
                  variant="contained"
                  size="small"
                  onClick={() => startScanning(data.ec_order_id)}
                >
                  {btnName}
                </Button>
                {isCameraOpen ? (
                  <Dialog open={isCameraOpen} onClose={handleCloseDialog}>
                    <DialogContent>
                      <>
                        <FormControl sx={{ marginTop: "15px" }}>
                          <FormLabel>Select Scanning Option</FormLabel>
                          <RadioGroup
                            defaultValue="scanner"
                            value={scanOption}
                            name="radio-buttons-group"
                            sx={{ display: "flex", flexDirection: "row" }}
                            onChange={handleSelectScanOption}
                          >
                            <FormControlLabel
                              value="scanner"
                              control={<Radio />}
                              label="Scanner Device"
                            />
                            <FormControlLabel
                              value="camera"
                              control={<Radio />}
                              label="Camera"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Box sx={{ padding: "10px" }}>
                          {isCameraOpen && scanOption === "camera" ? (
                            <Html5QrcodePlugin1
                              fps={10}
                              qrbox={250}
                              disableFlip={false}
                              qrCodeSuccessCallback={onNewScanResult}
                            />
                          ) : isCameraOpen && scanOption === "scanner" ? (
                            <TextField
                              onChange={handleQrBarcodeIdFromScanner}
                              label="QR/Barcode:"
                              value={qrBarcodeId}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          ) : (
                            ""
                          )}

                          <ResultContainerPlugin1
                            results={decodedResults}
                            setScanCount={setScanCount}
                            scanType={scanOption}
                          />
                        </Box>
                      </>
                    </DialogContent>
                  </Dialog>
                ) : (
                  ""
                )}
              </Box>
            )} */}

          {/* <Box sx={{ padding: "10px" }}>
              {isCameraOpen && scanOption === "camera" ? (
                <Html5QrcodePlugin1
                  fps={10}
                  qrbox={250}
                  disableFlip={false}
                  qrCodeSuccessCallback={onNewScanResult}
                />
              ) : isCameraOpen && scanOption === "scanner" ? (
                <TextField
                  onChange={handleQrBarcodeIdFromScanner}
                  label="QR/Barcode:"
                  value={qrBarcodeId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ) : (
                ""
              )}

              <ResultContainerPlugin1
                results={decodedResults}
                setScanCount={setScanCount}
                scanType={scanOption}
              />
            </Box> */}
        </CardContent>
        {/* <OpenCamera handleCameraResp={handleCameraResp} /> */}
      </Card>
      <Box>
        <EnterOTPDialog
          openVerifyOtpDialog={openVerifyOtpDialog}
          setOpenVerifyOtpDialog={setOpenVerifyOtpDialog}
          orderId={orderId}
          pageNumber={pageNumber}
        />
      </Box>

      {/* <Box>
        <QrBarcodeLinkingDialog
          setLinkingDialogOpen={setLinkingDialogOpen}
          linkingDialogOpen={linkingDialogOpen}
          scannedQrCode={scannedQrCode}
          scannedBarCode={scannedBarCode}
          setLink={setLink}
          scanCount={scanCount}
          setIsCameraOpen={setIsCameraOpen}
        />
      </Box> */}
    </Paper>
  );
}
