import { Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAdminBankdowntimeMsg,
  GetAdminBooknowMsg,
  GetAdminSetupSettings,
} from "../Redux/Actions/AdminSettingsAction";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AdminMainSettings from "./AdminMainSettings";

import CustomConsole from "../../CustomConsole";
import MessagesCard from "./MessagesCard";
import Alert from "@mui/material/Alert";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import AdminBanners from "../AdminBanners/AdminBanners";
import PromoCodemain from "../PromoCodes/PromoCodemain";
import AdminInfluencers from "../AdminInfluencers/AdminInfluencers";
import { LostCans } from "../AdminLostCans/LostCans";
import GiftCardsMain from "./GiftCardsMain";
export function Settings() {
  const dispatch = useDispatch();
  const settingsData = useSelector((state) => state.adminSettingsData);
  const [settingsFlag, setSettingsFlag] = React.useState(true);
  const [blockStart, setBlockStart] = React.useState(null);
  const [blockEnd, setBlockEnd] = React.useState(null);
  const [bookNowMsg, setBookNowMsg] = React.useState("");
  const [bankdowntimeMsg, setBankdowntimeMsg] = React.useState("");
  const [bookNowMsgChecked, setBookNowMsgChecked] = React.useState(false);
  const [bankdowntimeMsgChecked, setBankdowntimeMsgChecked] =
    React.useState(false);
  const [postpaidconvfee, setpostpaidconvfee] = React.useState(0);
  const [postpaidPercentage, setPostpaidPercentage] = React.useState(0);
  const [timeFrame, setTimeFrame] = React.useState(0);
  const [convFeeLTBooking, setConvFeeLTBooking] = React.useState(0);
  const [convFeeSTBooking, setConvFeeSTBooking] = React.useState(0);
  const [convFeeCGST, setConvFeeCGST] = React.useState(0);
  const [convFeeSGST, setConvFeeSGST] = React.useState(0);
  const [convFeeGSTCode, setConvFeeGSTCode] = React.useState(0);
  const [otpMsg, setOtpMsg] = React.useState("");

  const [pwtConvFeeCGST, setPwtConvFeeCGST] = React.useState(0);
  const [pwtConvFeeSGST, setPwtConvFeeSGST] = React.useState(0);
  const [pwtConvFeeIGST, setPwtConvFeeIGST] = React.useState(0);
  const [pwtConvFeeGSTCode, setPwtConvFeeGSTCode] = React.useState(0);

  const [gateWayFee, setGateWayFee] = React.useState(0);
  const [gatewayFeeCGST, setGatewayFeeCGST] = React.useState(0);
  const [gatewayFeeSGST, setGatewayFeeSGST] = React.useState(0);
  const [gateWayGSTCode, setGateWayGSTCode] = React.useState(0);

  const [supplierCancelFee, setSupplierCancelFee] = React.useState(0);
  const [consumerCancelFee, setConsumerCancelFee] = React.useState(0);
  const [supplierOrderAcceptanceInterval, setSupplierOrderAcceptanceInterval] =
    React.useState(0);
  const [consumerNotifyTime, setConsumerNotifyTime] = React.useState(0);
  const [consumerDisableTime, setConsumerDisabletTime] = React.useState(0);
  const [distributorNotifyTime, setDistributorNotifyTime] = React.useState(0);
  const [distributorDisableTime, setDistributorDisableTime] = React.useState(0);
  const [wfsNotifyTime, setWfsNotifyTime] = React.useState(0);
  const [wfsDisableTime, setWfsDisableTime] = React.useState(0);
  const [extraCharges, setextraCharges] = React.useState(0);
  const [minimumWallet, setminimumWallet] = React.useState(0);
  const [wtscgst, setwtsgst] = React.useState(0);
  const [wtgstcode, setwtgstcode] = React.useState(0);
  const [wtcgstpercent, setwtcgstpercent] = React.useState(0);
  const [twohndmlmin, settwohndmlmin] = React.useState(0);
  const [twohndmlmax, settwohndmlmax] = React.useState(0);
  const [twoltrmin, settwoltrmin] = React.useState(0);
  const [twoltrmax, settwoltrmax] = React.useState(0);
  const [twentyltrmin, settwentyltrmin] = React.useState(0);
  const [twentyltrmax, settwentyltrmax] = React.useState(0);
  const [stsgstpercent, setstsgstpercent] = React.useState(0);
  const [oneltrmin, setoneltrmin] = React.useState(0);
  const [oneltrmax, setoneltrmax] = React.useState(0);
  const [fivehndmlmin, setfivehndmlmin] = React.useState(0);
  const [fivehndmlmax, setfivehndmlmax] = React.useState(0);
  const [fiveltrmin, setfiveltrmin] = React.useState(0);
  const [fiveltrmax, setfiveltrmax] = React.useState(0);
  const [cancellationfee, setcancellationfee] = React.useState(0);
  const [stcgstpercent, setstcgstpercent] = React.useState(0);
  const [stgstcode, setstgstcode] = React.useState(0);
  const [isActive, setIsActive] = React.useState(true);
  const [minCanPrice, setMinCanPrice] = React.useState(0);
  const [referralRewardlimit, setReferralRewardlimit] = React.useState("");
  const [referralEachWaterDropWorth, setReferralEachWaterDropWorth] =
    React.useState("");
  const [referralWaterDrops, setReferralWaterDrops] = React.useState("");
  const [refferaltype, setRefferaltype] = React.useState("");
  const [extraChargesHSNCode, setExtraChargesHSNCode] = React.useState("");
  const [extraChargesCGST, setExtraChargesCGST] = React.useState("");
  const [extraChargesSGST, setExtraChargesSGST] = React.useState("");
  const [updateReferral, setUpdateReferral] = React.useState(false);
  const [baseWaterPrice, setbaseWaterPrice] = React.useState(0.0);
  const [distributorSettlement, setdistributorSettlement] = React.useState(0.0);
  const [wfsSettlement, setwfsSettlement] = React.useState(0.0);
  const [walletRechargeLmt, setWalletRechargeLmt] = React.useState(0);
  const [soldBy, setSoldBy] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [tabValueAdminSettings, setTabValueAdminSettings] =
    React.useState("MaiinSettings");
  React.useEffect(() => {
    if (settingsFlag) {
      // dispatch(GetAdminBooknowMsg());
      // dispatch(GetAdminBankdowntimeMsg());
      dispatch(GetAdminSetupSettings());
      setSettingsFlag(false);
    }
  }, [settingsFlag]);

  React.useEffect(() => {
    CustomConsole("----settings data----");
    CustomConsole(settingsData);
    if (settingsData.error === false) {
      // setBookNowMsg(settingsData.booknowMsg.message);
      // setBankdowntimeMsg(settingsData.bankdowntimeMsg.message);
      // setBookNowMsgChecked(settingsData.booknowMsg.status);
      // setBankdowntimeMsgChecked(settingsData.bankdowntimeMsg.status);
      //   appSettings
      setpostpaidconvfee(settingsData.adminSettings.postpaidconvfee);
      setPostpaidPercentage(settingsData.adminSettings.postpaidcharges);
      setTimeFrame(settingsData.adminSettings.timeframe);
      setConvFeeLTBooking(settingsData.adminSettings.ltb_conv_fee_percent);
      setConvFeeSTBooking(settingsData.adminSettings.sb_conv_fee_percent);
      setConvFeeCGST(settingsData.adminSettings.conv_cgst_percent);
      setConvFeeSGST(settingsData.adminSettings.conv_sgst_percent);
      setConvFeeGSTCode(settingsData.adminSettings.conv_gst_code);

      setPwtConvFeeCGST(settingsData.adminSettings.pdw_cgst_percent);
      setPwtConvFeeSGST(settingsData.adminSettings.pdw_sgst_percent);
      setPwtConvFeeIGST(settingsData.adminSettings.pdw_igst_percent);
      setPwtConvFeeGSTCode(settingsData.adminSettings.pdw_gst_code);

      setGateWayFee(settingsData.adminSettings.gateway_fee);
      setGatewayFeeCGST(settingsData.adminSettings.gatewayfee_cgst_percent);
      setGatewayFeeSGST(settingsData.adminSettings.gatewayfee_sgst_percent);
      setGateWayGSTCode(settingsData.adminSettings.gatewayfee_gst_code);

      setSupplierCancelFee(settingsData.adminSettings.s_fine_percent);
      setConsumerCancelFee(settingsData.adminSettings.c_fine_percent);

      setextraCharges(settingsData.adminSettings.extra_charges_singleday);
      setminimumWallet(settingsData.adminSettings.minimum_wallet_amount);
      setSupplierOrderAcceptanceInterval(
        settingsData.adminSettings.ltb_order_accept_timing
      );
      setConsumerNotifyTime(
        settingsData.adminSettings.notify_consumer_can_resubmission
      );
      setConsumerDisabletTime(
        settingsData.adminSettings.disable_consumer_can_resubmission
      );
      setDistributorNotifyTime(
        settingsData.adminSettings.notify_distributor_can_resubmission
      );
      setDistributorDisableTime(
        settingsData.adminSettings.disable_distributor_can_resubmission
      );
      setWfsNotifyTime(settingsData.adminSettings.notify_wfs_can_resubmission);
      setWfsDisableTime(
        settingsData.adminSettings.disable_wfs_can_resubmission
      );
      setBlockStart(settingsData.adminSettings.order_block_start_time);
      setBlockEnd(settingsData.adminSettings.order_block_end_time);

      setwtsgst(settingsData.adminSettings.wt_sgst_percent);
      setwtgstcode(settingsData.adminSettings.wt_gst_code);
      setwtcgstpercent(settingsData.adminSettings.wt_cgst_percent);
      settwohndmlmin(settingsData.adminSettings.twohnd_ml_min);
      settwohndmlmax(settingsData.adminSettings.twohnd_ml_max);
      settwoltrmin(settingsData.adminSettings.two_ltr_min);
      settwoltrmax(settingsData.adminSettings.two_ltr_max);
      settwentyltrmin(settingsData.adminSettings.twenty_ltr_min);
      settwentyltrmax(settingsData.adminSettings.twenty_ltr_max);
      setstsgstpercent(settingsData.adminSettings.st_sgst_percent);
      setoneltrmin(settingsData.adminSettings.one_ltr_min);
      setoneltrmax(settingsData.adminSettings.one_ltr_max);
      setfivehndmlmin(settingsData.adminSettings.fivehnd_ml_min);
      setfivehndmlmax(settingsData.adminSettings.fivehnd_ml_max);
      setfiveltrmin(settingsData.adminSettings.five_ltr_min);
      setfiveltrmax(settingsData.adminSettings.five_ltr_max);
      setcancellationfee(settingsData.adminSettings.cancellation_fee);
      setstcgstpercent(settingsData.adminSettings.st_cgst_percent);
      setstgstcode(settingsData.adminSettings.st_gst_code);
      setOtpMsg(settingsData.adminSettings.otp_less_delivery_disclaimer);
      setMinCanPrice(
        settingsData.adminSettings.max_allowed_discount_percentage
      );
      setExtraChargesHSNCode(settingsData.adminSettings.extra_charges_hsn_code);
      setExtraChargesCGST(
        settingsData.adminSettings.extra_charges_cgst_percent
      );
      setExtraChargesSGST(
        settingsData.adminSettings.extra_charges_sgst_percent
      );
      setWalletRechargeLmt(settingsData.adminSettings.wallet_alert_amount);
      // setAddress,setGstNumber,setPanNumber,setCompanyName,setSoldBy
      if (
        settingsData.adminSettings.sold_by !== undefined &&
        settingsData.adminSettings.sold_by !== null &&
        parseInt(settingsData.adminSettings.sold_by) === 1
      ) {
        setSoldBy(1);
        if (settingsData.adminSettings.sold_by_details !== undefined) {
          setPanNumber(() =>
            settingsData.adminSettings.sold_by_details.pan_no
              ? settingsData.adminSettings.sold_by_details.pan_no
              : ""
          );
          setGstNumber(() =>
            settingsData.adminSettings.sold_by_details.gst_no
              ? settingsData.adminSettings.sold_by_details.gst_no
              : ""
          );
          setCompanyName(() =>
            settingsData.adminSettings.sold_by_details.company_name
              ? settingsData.adminSettings.sold_by_details.company_name
              : ""
          );
          setAddress(() =>
            settingsData.adminSettings.sold_by_details.address
              ? settingsData.adminSettings.sold_by_details.address
              : ""
          );
        }
      } else {
        setSoldBy(2);
      }
      // referral

      // setReferralWaterDrops(settingsData.referralData.referral_water_drops);
      // setReferralRewardlimit(settingsData.referralData.reward_limit);
      // setRefferaltype(settingsData.referralData.referral_type);
      // setReferralEachWaterDropWorth(settingsData.referralData.each_drop_worth);
    }

    if (settingsData.referralError === false && updateReferral === false) {
      setReferralWaterDrops(settingsData.referralData.referral_water_drops);
      setReferralRewardlimit(settingsData.referralData.reward_limit);
      setRefferaltype(settingsData.referralData.referral_type);
      setReferralEachWaterDropWorth(settingsData.referralData.each_drop_worth);
      setUpdateReferral(false);
    }
    if (settingsData.referralError === true) {
      // setReferralWaterDrops("");
      // setReferralRewardlimit("");
      // // setRefferaltype("");
      // setReferralEachWaterDropWorth("");
    }
    setdistributorSettlement(
      settingsData?.adminSettings?.distributor_settlement_price
    );
    setbaseWaterPrice(settingsData?.adminSettings?.base_watercan_price);
    setwfsSettlement(
      settingsData?.adminSettings?.fillingstation_settlement_price
    );
  }, [settingsData]);
  const handleChangeTabValueAdminSettings = (event, newValue) => {
    setTabValueAdminSettings(newValue);
  };
  return (
    <Box sx={{ mt: "60px", padding: "0px" }}>
      <TabContext
        value={tabValueAdminSettings}
        variant="scrollable"
        scrollButtons={false}
        sx={{ padding: "0px" }}
      >
        <TabList
          onChange={handleChangeTabValueAdminSettings}
          // className={classes.tabUi}
          sx={{ bgcolor: "#E9F1F7", padding: "10px 0 0 0" }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Main Settings" value="MaiinSettings" />
          <Tab label="Banners" value="Banners" />
          <Tab label="Promo Code" value="PromoCode" />
          <Tab label="Influencer" value="Influncers" />
          <Tab label="Lost Cans" value="LostCans" />
          <Tab label="Gift Cards" value="GiftCards" />
        </TabList>

        <TabPanel value="MaiinSettings" sx={{ padding: "10px 0 0 0" }}>
          <Box>
            {parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_ADMIN ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SUPERADMIN ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_CSHEAD ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_CSLEAD ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_FINANCEHEAD ? (
              <>
                {" "}
                <Box>
                  <AdminMainSettings
                    postpaidconvfee={postpaidconvfee}
                    setpostpaidconvfee={setpostpaidconvfee}
                    postpaidPercentage={postpaidPercentage}
                    setPostpaidPercentage={setPostpaidPercentage}
                    timeFrame={timeFrame}
                    setTimeFrame={setTimeFrame}
                    convFeeLTBooking={convFeeLTBooking}
                    setConvFeeLTBooking={setConvFeeLTBooking}
                    convFeeSTBooking={convFeeSTBooking}
                    setConvFeeSTBooking={setConvFeeSTBooking}
                    convFeeCGST={convFeeCGST}
                    setConvFeeCGST={setConvFeeCGST}
                    convFeeSGST={convFeeSGST}
                    setConvFeeSGST={setConvFeeSGST}
                    convFeeGSTCode={convFeeGSTCode}
                    setConvFeeGSTCode={setConvFeeGSTCode}
                    pwtConvFeeCGST={pwtConvFeeCGST}
                    setPwtConvFeeCGST={setPwtConvFeeCGST}
                    pwtConvFeeSGST={pwtConvFeeSGST}
                    setPwtConvFeeSGST={setPwtConvFeeSGST}
                    pwtConvFeeIGST={pwtConvFeeIGST}
                    setPwtConvFeeIGST={setPwtConvFeeIGST}
                    pwtConvFeeGSTCode={pwtConvFeeGSTCode}
                    setPwtConvFeeGSTCode={setPwtConvFeeGSTCode}
                    gateWayFee={gateWayFee}
                    setGateWayFee={setGateWayFee}
                    gatewayFeeCGST={gatewayFeeCGST}
                    setGatewayFeeCGST={setGatewayFeeCGST}
                    gatewayFeeSGST={gatewayFeeSGST}
                    setGatewayFeeSGST={setGatewayFeeSGST}
                    gateWayGSTCode={gateWayGSTCode}
                    setGateWayGSTCode={setGateWayGSTCode}
                    supplierCancelFee={supplierCancelFee}
                    setSupplierCancelFee={setSupplierCancelFee}
                    consumerCancelFee={consumerCancelFee}
                    setConsumerCancelFee={setConsumerCancelFee}
                    supplierOrderAcceptanceInterval={
                      supplierOrderAcceptanceInterval
                    }
                    setSupplierOrderAcceptanceInterval={
                      setSupplierOrderAcceptanceInterval
                    }
                    consumerNotifyTime={consumerNotifyTime}
                    setConsumerNotifyTime={setConsumerNotifyTime}
                    consumerDisableTime={consumerDisableTime}
                    setConsumerDisabletTime={setConsumerDisabletTime}
                    distributorNotifyTime={distributorNotifyTime}
                    setDistributorNotifyTime={setDistributorNotifyTime}
                    distributorDisableTime={distributorDisableTime}
                    setDistributorDisableTime={setDistributorDisableTime}
                    wfsNotifyTime={wfsNotifyTime}
                    setWfsNotifyTime={setWfsNotifyTime}
                    wfsDisableTime={wfsDisableTime}
                    setWfsDisableTime={setWfsDisableTime}
                    blockStart={blockStart}
                    setBlockStart={setBlockStart}
                    setBlockEnd={setBlockEnd}
                    blockEnd={blockEnd}
                    setextraCharges={setextraCharges}
                    extraCharges={extraCharges}
                    setminimumWallet={setminimumWallet}
                    minimumWallet={minimumWallet}
                    settingsData={settingsData}
                    wtscgst={wtscgst}
                    wtgstcode={wtgstcode}
                    wtcgstpercent={wtcgstpercent}
                    twohndmlmin={twohndmlmin}
                    twohndmlmax={twohndmlmax}
                    twoltrmin={twoltrmin}
                    twoltrmax={twoltrmax}
                    twentyltrmin={twentyltrmin}
                    twentyltrmax={twentyltrmax}
                    stsgstpercent={stsgstpercent}
                    oneltrmin={oneltrmin}
                    oneltrmax={oneltrmax}
                    fivehndmlmin={fivehndmlmin}
                    fivehndmlmax={fivehndmlmax}
                    fiveltrmin={fiveltrmin}
                    fiveltrmax={fiveltrmax}
                    cancellationfee={cancellationfee}
                    stcgstpercent={stcgstpercent}
                    stgstcode={stgstcode}
                    setOtpMsg={setOtpMsg}
                    otpMsg={otpMsg}
                    isActive={isActive}
                    setIsActive={setIsActive}
                    referralRewardlimit={referralRewardlimit}
                    setReferralRewardlimit={setReferralRewardlimit}
                    referralEachWaterDropWorth={referralEachWaterDropWorth}
                    setReferralEachWaterDropWorth={
                      setReferralEachWaterDropWorth
                    }
                    referralWaterDrops={referralWaterDrops}
                    setReferralWaterDrops={setReferralWaterDrops}
                    refferaltype={refferaltype}
                    setRefferaltype={setRefferaltype}
                    minCanPrice={minCanPrice}
                    setMinCanPrice={setMinCanPrice}
                    extraChargesHSNCode={extraChargesHSNCode}
                    extraChargesCGST={extraChargesCGST}
                    extraChargesSGST={extraChargesSGST}
                    setExtraChargesHSNCode={setExtraChargesHSNCode}
                    setExtraChargesCGST={setExtraChargesCGST}
                    setExtraChargesSGST={setExtraChargesSGST}
                    setUpdateReferral={setUpdateReferral}
                    baseWaterPrice={baseWaterPrice}
                    setbaseWaterPrice={setbaseWaterPrice}
                    distributorSettlement={distributorSettlement}
                    setdistributorSettlement={setdistributorSettlement}
                    wfsSettlement={wfsSettlement}
                    setwfsSettlement={setwfsSettlement}
                    walletRechargeLmt={walletRechargeLmt}
                    setWalletRechargeLmt={setWalletRechargeLmt}
                    soldBy={soldBy}
                    setSoldBy={setSoldBy}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    panNumber={panNumber}
                    setPanNumber={setPanNumber}
                    gstNumber={gstNumber}
                    setGstNumber={setGstNumber}
                    address={address}
                    setAddress={setAddress}
                  />
                </Box>
              </>
            ) : (
              <Box sx={{ marginTop: "50px" }}>
                <Alert severity="error">Access Denied</Alert>
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value="Banners" sx={{ padding: "10px 0 0 0" }}>
          <AdminBanners />
        </TabPanel>
        <TabPanel value="PromoCode" sx={{ padding: "10px 0 0 0" }}>
          <PromoCodemain />
        </TabPanel>
        <TabPanel value="Influncers" sx={{ padding: "10px 0 0 0" }}>
          <AdminInfluencers />
        </TabPanel>
        <TabPanel value="LostCans" sx={{ padding: "10px 0 0 0" }}>
          <LostCans />
        </TabPanel>
        <TabPanel value="GiftCards" sx={{ padding: "10px 0 0 0" }}>
          <GiftCardsMain />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
