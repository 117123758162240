/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { getFiles } from "./fileHandlingActions";
import actionType from "./actionType";
import useAxios from "../../Axios/useAxios";
import CustomConsole from "../../../CustomConsole";
//Get the User Profile data
export const GetUserProfile = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.get(`/SCM/profile`);
    if (response.status === httpStatus.SUCCESS) {
      //    if(response.data.profile.consumer.profile_pic_url !==undefined && response.data.profile.consumer.profile_pic_url){
      //      await dispatch(getFiles(response.data.profile.consumer.profile_pic_url));
      //    }\
      
      dispatch({
        type: actionType.GET_USER_PROFILE,
        payload: response.data,
      });
    } else {
      // CustomConsole("ELSE: Response in GetUserProfile Action Error");
    }
  };
};

export const GetWfsDetails = () => {
    const httpStatus = {
      SUCCESS: 200,
      BAD_REQUEST: 400,
    };
    let api = useAxios();
    return async (dispatch) => {
      let response = await api.get(`/TM/admin/getAssignedWfsDetails`);
      if (response.status === httpStatus.SUCCESS) {
        //    if(response.data.profile.consumer.profile_pic_url !==undefined && response.data.profile.consumer.profile_pic_url){
        //      await dispatch(getFiles(response.data.profile.consumer.profile_pic_url));
        //    }
        dispatch({
          type: actionType.GET_WFS_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetUserProfile Action Error");
      }
    };
  };


