import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";

export const GetReplacementOrders = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/admin/replaceCans/getList`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_REPLACEMENT_CAN_ORDERS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetOrderDetailsById = (id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/admin/replaceCans/order/${id}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ORDER_USING_ID,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetSupplierDetailsReplacement = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/admin/replaceCans/supplierList?pincode=${reqObj.pincode}&container_capacity=${reqObj.capacity}&container_material=${reqObj.material}&container_qty=${reqObj.container_qty}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUPPLIER_DETAILS_REPLACE,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const AcceptingReplacementOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/OM/admin/replaceCans/acceptAndAssignSupplier`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ACCEPTING_REPLACEMENT_ORDER,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const RejectingReplacementOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    CustomConsole(reqObj);
    try {
      let response = await api.put(`/OM/admin/replaceCans/rejectOrder`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REJECTING_REPLECEMENT_ORDER,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const EditReplacementOrder = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    CustomConsole(reqObj);
    try {
      let response = await api.put(
        `OM/edit/replacementOrder/${orderId}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_REPLACEMENT_ORDER,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const ReplacementQrlessInScan = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    CustomConsole(reqObj);
    try {
      let response = await api.put(
        `OM/complete/replacement/${orderId}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REPLACEMENT_INSCAN_QRLESS,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
