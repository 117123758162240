import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { RequestReplacementCan } from "../Redux/Actions/userDetailsAdminAction";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";

function ReplacementDialogue({
  requestDialogOpen,
  setRequestDialogOpen,
  replacementReasons,
  containerMaterial,
  containerCapacity,
  setContainerCapacity,
  setContainerMaterial,
  setToastFlag,
  userId,
}) {
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate); //userAddressAdmin.address
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [material, setMaterial] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [otherReason, setOtherReason] = React.useState("");
  const [uploadingFiles, setUploadingFiles] = React.useState([]);
  const [addressId, setAddressId] = React.useState(0);
  const [productName, setProductName] = React.useState("");


  //flags

  const [openTextfield, setOpenTextfield] = React.useState(false);
  const [fileSetFlag, setFileSetFlag] = React.useState(false);

  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileSetFlag && !fileHandler.error) {
      setUploadingFiles([...uploadingFiles, fileHandler.fileName]);
      CustomConsole(uploadingFiles);
      setFileSetFlag(false);
    }
  }, [fileHandler]);

  React.useEffect(() => {
    CustomConsole("-------container capacity----------");
    CustomConsole(containerCapacity);
  }, [containerCapacity]);
  const handleClose = () => {
    setRequestDialogOpen(false);
    setContainerCapacity([]);
    setContainerMaterial([]);
  };

  const handleSetQuantity = (e) => {
    setQuantity(e.target.value);
  };
  const handleReasonChange = (e) => {
    if (e.target.value === 999) {
      setOpenTextfield(true);
      setReason(e.target.value);
    } else {
      setReason(e.target.value);
      setOpenTextfield(false);
    }
  };

  const submittingRequest = () => {
    if (quantity && parseInt(quantity) > 0) {
      let reqObj = {
        replace_can_qty: quantity,
        container_material: material,
        container_capacity: capacity,
        replacement_reason: `${reason}`,
        user_id: userId,
        file_names: uploadingFiles,
        address_id: addressId,
      };
      if (
        reqObj.replace_can_qty !== "" &&
        reqObj.container_material !== "" &&
        reqObj.container_capacity !== "" &&
        reason === 999 &&
        otherReason !== "" &&
        addressId
      ) {
        reqObj.replacement_reason = otherReason;

        dispatch(RequestReplacementCan(reqObj));
        setToastFlag(true);
      }
      if (
        reqObj.replace_can_qty !== "" &&
        reqObj.container_material !== "" &&
        reqObj.container_capacity !== "" &&
        reason !== 999
      ) {
        dispatch(RequestReplacementCan(reqObj));
        setToastFlag(true);
      }
    } else {
      toast.dark("Please enter proper replacement order quantity", {
        position: "bottom-right",
        autoClose: 3000,
        type: "info",
      });
    }
  };
  const uploadingImages = (e) => {
    if (uploadingFiles.length <= 4) {
      dispatch(UploadFile(e.target.files[0]));
      setFileSetFlag(true);
    } else {
      toast.error("Already uploaded 5 files", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const removeFile = (index) => {
    const newFileArray = [...uploadingFiles];
    newFileArray.splice(index, 1);
    setUploadingFiles(newFileArray);
  };

  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = skuContainerDetails.getAvailableProducts.filter(
      (data) => data.product_details.name === event.target.value
    );
    if (selectedDetails.length > 0) {
      setProductName(selectedDetails[0].product_details.name);
      setCapacity(selectedDetails[0].product_details.capacity);
      setMaterial(selectedDetails[0].product_details.material);
    }
  };

  return (
    <Dialog
      open={requestDialogOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle>Request for replacement</DialogTitle>
      <DialogContent>
        {/* <FormControl
          fullWidth
          sx={{
            marginTop: "10px",
          }}
        >
          <InputLabel id="demo-simple-select-label">
            Choose container material
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={material || ""}
            label="Choose container material"
            onChange={(e) => {
              setMaterial(e.target.value);
            }}
          >
            {containerMaterial
              ? containerMaterial.map((val, key) => {
                  return (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            marginTop: "10px",
          }}
        >
          <InputLabel id="demo-simple-select-label">
            Choose container capacity
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={capacity}
            label="Choose container capacity"
            onChange={(e) => {
              setCapacity(e.target.value);
            }}
          >
            {containerCapacity
              ? containerCapacity.map((val, key) => {
                  return (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  );
                })
              : null}
           
          </Select>
        </FormControl> */}
        <Box>
          <FormControl  fullWidth>
            <InputLabel>Product Details</InputLabel>
            <Select
              value={productName}
              label="Capacity"
              onChange={handleChangeSelectedProduct}
            >
              {skuContainerDetails.getAvailableProducts.map((data) => (
                <MenuItem
                  value={data.product_details.name}
                  key={data.product_details.id}
                >
                  <div>
                    <span>
                      {" "}
                      <strong>{data.product_details.name}</strong>
                    </span>
                    <br />
                    <small>
                      Capacity:
                      {data.product_details.capacity}/
                    </small>
                    <small>
                      Material:
                      {data.product_details.material}
                    </small>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Stack mt={2}>
          <FormControl fullWidth>
            <InputLabel sx={{ marginTop: "2px" }} id="demo-simple-select-label">
              Address
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Address"
              onChange={(e) => setAddressId(e.target.value)}
            >
              {userAddressAdmin?.address?.map((obj, idx) => {
                return (
                  <MenuItem
                    value={obj.address_id}
                    sx={{ fontSize: "0.69rem" }}
                    key={idx}
                  >
                    {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <TextField
          fullWidth
          sx={{
            marginTop: "10px",
          }}
          id="outlined-basic"
          label="Enter container quantity"
          variant="outlined"
          value={quantity}
          onKeyDown={(e) => {
            if (!/^([0-9]\b)+$/.test(e.key)) {
              e.preventDefault();
            }
            if (e.key === "Backspace") {
              setQuantity((preQty) => preQty.slice(0, -1));
            }
          }}
          onChange={handleSetQuantity}
        />
        <FormControl
          sx={{
            marginTop: "10px",
          }}
          fullWidth
        >
          <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Reason"
            value={reason || ""}
            onChange={handleReasonChange}
          >
            {replacementReasons
              ? replacementReasons.map((val, key) => {
                  return (
                    <MenuItem key={key} value={val.k}>
                      {val.v}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
        {openTextfield ? (
          <TextField
            fullWidth
            sx={{
              marginTop: "10px",
            }}
            id="outlined-basic"
            label="Enter the reason"
            variant="outlined"
            value={otherReason}
            onChange={(e) => {
              setOtherReason(e.target.value);
            }}
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              marginTop: "10px",
            }}
          >
            <Tooltip title="You can upload max of 5 files" arrow>
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "#3498DB",
                }}
              >
                Upload images
                <input hidden multiple type="file" onChange={uploadingImages} />
              </Button>
            </Tooltip>
          </Box>
          <Box>
            {uploadingFiles && uploadingFiles.length > 0
              ? uploadingFiles.map((val, idx) => {
                  return (
                    <Box
                      key={idx + 1}
                      sx={{
                        padding: "5px",
                        backgroundColor: "#7F8C8D",
                        borderRadius: "20px",
                        marginTop: "5px",
                        fontSize: "10px",
                        fontWeight: "700",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Uploaded {idx + 1}
                      <ClearIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          removeFile(idx);
                        }}
                      />
                    </Box>
                  );
                })
              : null}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={submittingRequest}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReplacementDialogue;
