import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import React from "react";

function CompleteScanningProcessDialog(props) {
  const [
    enterReasonForCompletingScanning,
    setEnterReasonForCompletingScanning,
  ] = React.useState("");

  const handleCloseScanning = () => {
    props.handleCloseScanning();
  };
  const handleCompleteScanning = () => {
    props.handleCompleteScanning({ reason: enterReasonForCompletingScanning });
  };
  return (
    <Dialog
      open={props.openCompleteScaningDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        },
      }}
    >
      <DialogContent>
        <Box>
          <Typography sx={{ mb: 2, fontWeight: "bold" }}>
            Enter Reason For Complete Scanning
          </Typography>
          <TextField
            fullWidth
            autoFocus
            type="text"
            helperText="Enter Minimum 5 characters"
            value={enterReasonForCompletingScanning}
            label="Enter Reason For Scanning"
            size="small"
            onChange={(event) =>
              setEnterReasonForCompletingScanning(event.target.value)
            }
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              m: 2,
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseScanning}
            >
              close
            </Button>
            <Button variant="contained" onClick={handleCompleteScanning}>
              Complete Scanning
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CompleteScanningProcessDialog;
