import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  loading: false,
  upDateLoading: false,
  error: null,
  data: null,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null,
      };
    case actionType.SET_UPDATE_LOADING:
      return {
        ...state,
        upDateLoading: action.payload,
        error: null,
      };

    default:
      return state;
  }
};

export default loaderReducer;
