import useAxios from "../../Axios/useAxios";
import { toast } from "react-toastify";

/**
 * @author : Sesuraj
 * @description this action doens't set any state. it just returns the item as it is. No reducers mapped to it
 * @Date : 2024-03-12
 * @param {}
 * @return {error, msg, data:[]}
 */
export const AvailablePincodes = async (params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  params.ignore_app_loading_spinner = true;
  // Remove empty keys from params
  const filteredReqObj = Object.fromEntries(
    Object.entries(params || {}).filter(([key, value]) => value !== "")
  );

  try {
    let response = await api.get(
      `/TM/pincode/filter?${new URLSearchParams(filteredReqObj)}`
    );
    return response.data;
  } catch (error) {
    // toast.dark(error.response.data.msg, {
    //   position: "bottom-right",
    //   autoClose: 5000,
    //   type: "info",
    // });
  }
  return [];
};
