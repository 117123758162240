import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CustomConsole from "../../CustomConsole";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StepLabel,
  Switch,
  Tab,
  TextField,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { GetProductsAddress } from "../Redux/Actions/adminSkuBookingAction";
import CartCard from "./CartCard";
import ProductsCard from "./ProductsCard";
import { GetUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import moment from "moment";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { SearchUserAdmin } from "../Redux/Actions/addUserAdminAction";
import SubscriptionSchedule from "./SubscriptionDateCard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = ["Choose the products", "Place Order"];

const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
});

function SubscriptionBooking({
  open,
  setOpen,
  editDialog,
  edit,
  editProducts,
  editAdrs,
  daysSubs,
  subscriptionId,
  discountChecks,
}) {
  const dispatch = useDispatch();

  const productDet = useSelector((state) => state.adminSkuBookingData);
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const userStockDet = useSelector(
    (state) => state.adminSkuBookingData.userStock
  );
  const addUserAdminDetails = useSelector(
    (state) => state.addUserAdminDetails.users
  );
  const [tabValue, setTabValue] = React.useState("1");
  const [products, setProducts] = React.useState([]);
  const [orderQty, setOrderQty] = React.useState(0);
  const [returnQty, setReturnQty] = React.useState(0);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [orderProducts, setOrderProducts] = React.useState([]);
  const [productId, setProductId] = React.useState("");
  const [addressProductData, setAddressProductData] = React.useState([]);
  const [cartProducts, setCartProducts] = React.useState([]);
  const [addressId, setAddressId] = React.useState("");
  const [otpLess, setOtpLess] = React.useState(false);
  const [orderDate, setOrderDate] = React.useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [userStock, setUserStock] = React.useState({});
  const [payMethod, setPayMethod] = React.useState(() => "WALLET");

  const [selectedCoup, setSelectedCoup] = React.useState("");
  const [waterDrops, setwaterDrops] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [totalNo, setTotalNo] = React.useState(0);
  const [returnErr, setReturnErr] = React.useState(false);
  const [userPhone, setUserPhone] = React.useState("");
  const [userStringId, setUserStringId] = React.useState("");
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [checks, setChecks] = React.useState({
    promo: true,
    waterDrops: true,
  });
  const [nextDeliveryDate, setNextDeliveryDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const userDetailStyle = {
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#9BC2C9",
    borderRadius: "10px",
  };

  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setCartProducts([]);
    setOrderProducts([]);
    setProducts([]);
    setTabValue(newValue);
  };

  const handleClose = () => {
    setCartProducts([]);
    setOrderProducts([]);
    setProducts([]);
    setOpen(false);
    setActiveStep(0);
    editDialog(false);
  };

  const addProductsCart = (material, capacity, orderCan, returnCan) => {
    let productFind = products.filter(
      (item) => item.capacity === capacity && item.material === material
    );

    if (orderCan !== "" && !isNaN(orderCan)) {
      const existingProductIndex = orderProducts.findIndex(
        (products) =>
          products.capacity === capacity && products.material === material
      );

      if (existingProductIndex !== -1) {
        const updatedProducts = orderProducts.filter(
          (obj, idx) => idx !== existingProductIndex
        );
        const updatedProductObj = {
          quantity: parseFloat(orderCan),
          capacity: capacity,
          material: material,
          return: returnCan ? parseFloat(returnCan) : 0,
          sku_qty: productFind[0].sku_qty[0],
        };

        setOrderProducts([...updatedProducts, updatedProductObj]);
      } else {
        // Product does not exist, add a new entry
        const productObj = {
          quantity: parseFloat(orderCan),
          capacity: capacity,
          material: material,
          return: returnCan ? parseFloat(returnCan) : 0,
          sku_qty: productFind[0].sku_qty[0],
        };

        setOrderProducts((prev) => [...prev, productObj]);
      }
    }
  };

  const handleAddressChange = (e) => {
    setAddressId(e.target.value);
  };

  const handleOrderQty = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6);
    }
    setOrderQty(inputValue);
  };

  const handleReturn = (e) => {
    let error = false;

    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6);
    }
    setReturnQty(inputValue);
    CustomConsole("----return---");
    CustomConsole(userStock[0].max_return);

    if (
      userStock[0] &&
      (userStock[0].max_return < inputValue ||
        orderQty * selectedProduct?.sku_qty?.[0] < inputValue)
    ) {
      error = true;
    } else if (!userStock[0] && inputValue > 0) {
      error = true;
    } else {
      error = false;
    }

    setReturnErr(error);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleChangeCheckBoxes = (e) => {
    const { name, checked } = e.target;
    CustomConsole(name);
    CustomConsole(checked);
    setChecks((prev) => ({ ...prev, [name]: checked }));
  };

  React.useEffect(() => {
    if (edit) {
      CustomConsole("----inside prohoibitted zone-----");
      setAddressId(editAdrs.address_id);
      setSelectedDays(daysSubs);
      setOrderProducts(editProducts);
      setChecks({ ...discountChecks });
    }
  }, [edit]);

  React.useEffect(() => {
    if (addUserAdminDetails[0]) {
      setUserStringId(addUserAdminDetails[0].id);
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (open && userPhone) {
      dispatch(SearchUserAdmin(userPhone));
    }
  }, [open, userPhone]);

  React.useEffect(() => {
    if (userStringId) {
      dispatch(GetUserAddressAdmin(userStringId));
    }
  }, [userStringId]);

  React.useEffect(() => {
    CustomConsole("----getting products---");
    CustomConsole(addressId);
    CustomConsole(open);
    if (open && addressId && !edit) {
      setOrderProducts([]);
    }
    if (open && addressId) {
      setCartProducts([]);

      setProducts([]);
      CustomConsole("----Hitting API----");
      dispatch(GetProductsAddress({ address_id: addressId }));
    }
  }, [open, addressId]);

  React.useEffect(() => {
    CustomConsole("----Product Data----");
    CustomConsole(productDet);
    setAddressProductData(() =>
      productDet?.products ? productDet.products : []
    );
  }, [productDet]);

  React.useEffect(() => {
    let productsToShow = [];

    CustomConsole("----Products filtering---");
    CustomConsole(editProducts);
    CustomConsole(addressProductData?.products);

    if (addressProductData?.products && editProducts && edit) {
      productsToShow = addressProductData?.products.filter((obj) =>
        editProducts.some(
          (item) =>
            item.capacity === obj.capacity && item.material === obj.material
        )
      );

      CustomConsole("selected products for postpaid");
      CustomConsole(productsToShow);
    } else {
      productsToShow = addressProductData?.products
        ? addressProductData.products
        : [];
    }

    if (tabValue === "1" && productsToShow) {
      setProducts(() =>
        productsToShow.filter(
          (item) => item.booking_type === 1 || item.booking_type === 3
        )
      );
    } else if (tabValue === "2" && productsToShow) {
      setProducts(() =>
        productsToShow.filter(
          (item) => item.booking_type === 2 || item.booking_type === 3
        )
      );
    }
  }, [tabValue, addressProductData, editProducts]);

  React.useEffect(() => {
    if (productId) {
      const selectedProduct = products.filter((obj) => {
        return obj.id === productId;
      });
      CustomConsole(selectedProduct);
      setSelectedProduct(() => selectedProduct[0]);
    }
  }, [productId, products]);

  React.useEffect(() => {
    setTotalNo(() =>
      orderQty && selectedProduct?.sku_qty?.[0]
        ? selectedProduct.sku_qty[0] * orderQty
        : 0
    );
  }, [selectedProduct, orderQty]);

  React.useEffect(() => {
    CustomConsole("-----Stock and selected order---");
    if (userStockDet && selectedProduct) {
      CustomConsole(userStockDet);
      CustomConsole(selectedProduct);

      let stockOfSelected = userStockDet.filter(
        (obj) =>
          obj.container_capacity === selectedProduct.capacity &&
          obj.container_material === selectedProduct.material
      );

      CustomConsole("---Selected prod---");
      CustomConsole(stockOfSelected);

      setUserStock(stockOfSelected);
    }
  }, [userStockDet, selectedProduct]);

  React.useEffect(() => {
    CustomConsole("----Products Change----");
    CustomConsole(products);
  }, [products]);

  React.useEffect(() => {
    CustomConsole("----Products----");
    CustomConsole(orderProducts);
    CustomConsole(editProducts);
  }, [orderProducts, editProducts]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Order Now
          </Typography>
          {/* <SearchName phone={setUserPhone} /> */}
        </Toolbar>
      </AppBar>
      <Box>
        {addUserAdminDetails && addUserAdminDetails?.[0] ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={2}
            mt={1}
          >
            <Grid xs={8} sm={8} md={4} xl={4}>
              <Typography sx={userDetailStyle}>
                Name: {addUserAdminDetails[0].fullname}
              </Typography>
            </Grid>
            <Grid xs={8} sm={8} md={4} xl={4}>
              <Typography sx={userDetailStyle}>
                Phone: {addUserAdminDetails[0].phone}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Box>
      <Box>
        <TabContext value={tabValue}>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <TabList onChange={handleTabChange} className={classes.tabUi}>
              <Tab label="Refill" value="1" />
              {/* <Tab label="One-Time Purchase" value="2" /> */}
            </TabList>
          </Box>
          {/* ------------Stepper------------- */}

          <Box sx={{ width: "100%", padding: 3 }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box p={2}>
                    <Grid
                      container
                      spacing={2}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"flex-start"}
                    >
                      {activeStep === 0 ? (
                        <>
                          <Grid item xs={12} sm={12} md={8} xl={8}>
                            <Grid
                              container
                              spacing={2}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"flex-start"}
                              gap={2}
                            >
                              <Grid item xs={6} sm={6} md={4} xl={4}>
                                <Box>
                                  {edit ? (
                                    <Box
                                      sx={{
                                        padding: "10px",
                                        border: "1px solid black",
                                      }}
                                    >
                                      {" "}
                                      <Typography
                                        sx={{ wordBreak: "break-word" }}
                                      >
                                        {editAdrs?.address_line1},
                                        {editAdrs?.address_line2},
                                      </Typography>
                                      <Typography>
                                        {editAdrs?.service_area},
                                        {editAdrs?.pincode}
                                      </Typography>{" "}
                                    </Box>
                                  ) : (
                                    <FormControl
                                      sx={{ width: 220 }}
                                      size="small"
                                    >
                                      <InputLabel>Select Address</InputLabel>
                                      <Select
                                        value={addressId}
                                        label="Select Address"
                                        onChange={handleAddressChange}
                                      >
                                        {updateUserDetails?.address?.map(
                                          (data, idx) => {
                                            return (
                                              <MenuItem
                                                key={idx}
                                                value={data.address_id}
                                              >
                                                {data.contact_name}:{" "}
                                                {data.pincode}-
                                                {data.service_area} &nbsp;
                                                &nbsp;
                                                {data.is_primary ? (
                                                  <>
                                                    <Chip
                                                      label={"Primary"}
                                                      sx={{
                                                        bgcolor: "green",
                                                        color: "white",
                                                      }}
                                                    />
                                                  </>
                                                ) : null}{" "}
                                                &nbsp;
                                                {data.is_billing_address ===
                                                true ? (
                                                  <>
                                                    <Chip
                                                      label={"Billing"}
                                                      sx={{
                                                        bgcolor: "red",
                                                        color: "white",
                                                      }}
                                                    />
                                                  </>
                                                ) : null}
                                                &nbsp;
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </FormControl>
                                  )}
                                </Box>
                              </Grid>

                              <Grid item xs={6} sm={5} md={4} xl={4}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={otpLess}
                                      onChange={(e) =>
                                        setOtpLess(e.target.checked)
                                      }
                                    />
                                  }
                                  label="OTP Less"
                                />
                              </Grid>

                              <Grid item xs={12} sm={12} md={12} xl={12}>
                                <Box
                                  sx={{
                                    borderRadius: 2,
                                    backgroundColor: "#f0f5f9",
                                    border: "1px solid #B7C2A0", // Border color

                                    padding: "16px", // Padding inside the box

                                    margin: "auto", // Center the box
                                  }}
                                >
                                  <Typography
                                    p={1}
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: 500,
                                      color: "GrayText",
                                      // textDecoration: "underline",
                                    }}
                                  >
                                    Choose products
                                  </Typography>
                                  <Grid container spacing={2} padding={3}>
                                    <Grid item xs={12} sm={12} md={3} xl={3}>
                                      <Box sx={{ width: "100%" }}>
                                        <FormControl
                                          size="small"
                                          sx={{ width: "90%" }}
                                        >
                                          <InputLabel
                                            id="product-type-select-label"
                                            sx={{
                                              fontSize: "1rem",
                                              marginTop: "2px",
                                            }}
                                          >
                                            Products
                                          </InputLabel>
                                          <Select
                                            labelId="product-type-select-label"
                                            id="product-type-select"
                                            value={productId}
                                            label="Product"
                                            onChange={(e) =>
                                              setProductId(e.target.value)
                                            }
                                          >
                                            {products.map((obj, idx) => {
                                              return (
                                                <MenuItem
                                                  value={obj.id}
                                                  sx={{ fontSize: "0.76rem" }}
                                                  key={idx}
                                                >
                                                  {`${obj.material}-${obj.capacity}`}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} xl={3}>
                                      <Box sx={{ width: "90%" }}>
                                        <TextField
                                          size="small"
                                          InputLabelProps={{ shrink: true }}
                                          label="Order qty"
                                          fullWidth
                                          value={orderQty}
                                          onChange={handleOrderQty}
                                          helperText={`Total number of quantity ${totalNo}`}
                                          inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} xl={3}>
                                      <Box sx={{ width: "90%" }}>
                                        <TextField
                                          size="small"
                                          InputLabelProps={{ shrink: true }}
                                          label="Return qty"
                                          fullWidth
                                          helperText={`You max return qty is ${
                                            userStock?.[0]?.max_return
                                              ? userStock[0].max_return
                                              : 0
                                          } and it should also be less than your order qty.`}
                                          value={returnQty}
                                          onChange={handleReturn}
                                          error={returnErr}
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                      <Box>
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            addProductsCart(
                                              selectedProduct.material,
                                              selectedProduct.capacity,
                                              orderQty,
                                              returnQty
                                            )
                                          }
                                        >
                                          Add
                                        </Button>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={checks.promo}
                                            onChange={handleChangeCheckBoxes}
                                            name="promo"
                                          />
                                        }
                                        label="Auto Apply Coupon Discounts"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={checks.waterDrops}
                                            onChange={handleChangeCheckBoxes}
                                            name="waterDrops"
                                          />
                                        }
                                        label="Auto Apply Water Drops"
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box mt={1} sx={{ width: "100%" }}>
                                  <ProductsCard
                                    products={orderProducts}
                                    setProducts={setOrderProducts}
                                    otpLess={otpLess}
                                    addressId={addressId}
                                    bookingType={tabValue}
                                    setCartProducts={setCartProducts}
                                    orderDate={orderDate}
                                    payMethod={payMethod}
                                    setPayMethod={setPayMethod}
                                    setwaterDrops={setwaterDrops}
                                    setSelectedCoup={setSelectedCoup}
                                    setActiveStep={setActiveStep}
                                    checks={checks}
                                    selectedDays={selectedDays}
                                    nextDeliveryDate={nextDeliveryDate}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} xl={4}>
                            <Grid container>
                              <Grid item md={12}>
                                <SubscriptionSchedule
                                  selectedDays={selectedDays}
                                  setSelectedDays={setSelectedDays}
                                  setNextDayDate={setNextDeliveryDate}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : null}

                      {activeStep === 1 ? (
                        <Grid item xs={12} sm={12} md={4} xl={4}>
                          <CartCard
                            setCartProducts={setCartProducts}
                            cartProducts={cartProducts}
                            userId={userStringId}
                            otpLess={otpLess}
                            addressId={addressId}
                            bookingType={tabValue}
                            products={orderProducts}
                            payMethod={payMethod}
                            orderDate={orderDate}
                            selectedCoup={selectedCoup}
                            setSelectedCoup={setSelectedCoup}
                            waterDrops={waterDrops}
                            setwaterDrops={setwaterDrops}
                            checks={checks}
                            selectedDays={selectedDays}
                            edit={edit}
                            subscriptionId={subscriptionId}
                            setActiveStep={setActiveStep}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>

          {/* ------------Stepper------------- */}
        </TabContext>
      </Box>
    </Dialog>
  );
}

export default SubscriptionBooking;
