import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { url } from "./../apis";
import React from "react";
import CustomConsole from "../../../CustomConsole";
import { ToastContainer, toast } from "react-toastify";

export const LogIn = (email_phone, password, storage) => {
  //   let api = useAxios();
  CustomConsole(email_phone, password);
  return (dispatch) => {
    axios
      .post(`${url}/SCM/login/admin`, {
        email_phone,
        password,
      })
      .then((res) => {
        CustomConsole("--------here----");
        if (!res.data.error) {
          if (storage === true) {
            localStorage.setItem("token", res.data.tkn);
            // sessionStorage.setItem("token", res.data.tkn);
          } else {
            sessionStorage.setItem("token", res.data.tkn);
          }
          // toast.dark(toastMessage.LOGIN_SUCESS_MESSAGE, {
          //   position: "bottom-right",
          //   autoClose: 5000,
          //   type: "success",
          // });
          dispatch({
            type: actionType.SIGN_IN,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        CustomConsole("ERROr: " + JSON.stringify(error.response));
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};
export const PromotersList = () => {
  return (dispatch) => {
    axios
      .get(`${url}/SSM/proIndia/promoter/list`)
      .then((res) => {
        CustomConsole("--------here----");
        if (!res.data.error) {
          dispatch({
            type: actionType.PROMOTERS_DETAILS_LIST,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        CustomConsole("ERROr: " + JSON.stringify(error.response));
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};
export const PromoterEmailVerification = (props) => {
  return (dispatch) => {
    axios
      .post(`${url}/SSM/proIndia/promoter/verifyEmail`, props)
      .then((res) => {
        CustomConsole("--------here----");
        if (!res.data.error) {
          dispatch({
            type: actionType.PROMOTER_EMAIL_VERIFY,
            payload: res.data,
          });
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        CustomConsole("ERROr: " + JSON.stringify(error.response));
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      });
  };
};
export const PromoterLogin = (props) => {
  return (dispatch) => {
    axios
      .post(`${url}/SSM/proIndia/promoter/login`, props)
      .then((res) => {
        CustomConsole("--------here----");
        if (!res.data.error) {
          CustomConsole(res.data);
          localStorage.setItem("token", res.data.tkn);
          localStorage.setItem("roleId", JSON.stringify(res.data.role_id));
          dispatch({
            type: actionType.PROMOTER_LOGIN,
            payload: res.data,
          });
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        CustomConsole("ERROr: " + JSON.stringify(error.response));
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      });
  };
};
//Supplier login here
export const PostLoginSupplier = (email_phone, password, storage) => {
  //   let api = useAxios();
  CustomConsole(email_phone, password);
  return (dispatch) => {
    axios
      .post(`${url}/SCM/login/supplier`, {
        email_phone,
        password,
      })
      .then((res) => {
        CustomConsole("--------Supplier here----");
        if (!res.data.error) {
          if (storage === true) {
            localStorage.setItem("token", res.data.tkn);
            // sessionStorage.setItem("token", res.data.tkn);
          } else {
            sessionStorage.setItem("token", res.data.tkn);
          }
          // toast.dark(toastMessage.LOGIN_SUCESS_MESSAGE, {
          //   position: "bottom-right",
          //   autoClose: 5000,
          //   type: "success",
          // });
          dispatch({
            type: actionType.POST_LOGIN_SUPPLIER,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        CustomConsole("ERROr: " + JSON.stringify(error.response));
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};

export const ResetPasswordEmailVerify = (email_phone, storage) => {
  //   let api = useAxios();
  CustomConsole(email_phone);

  return (dispatch) => {
    const errorObj = {
      error: true,
    };
    axios
      .post(`${url}/SCM/resetpass/user`, { email_phone })
      .then((res) => {
        // CustomConsole("forgotPasswordUserVerify Response:\n" + res);
        if (!res.data.error) {
          sessionStorage.setItem("checksum", res.data.checksum);
          toast.dark("Verification Code Sent Successfully", {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
          dispatch({
            type: actionType.RESET_PASSWORD_VERIFY,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        // CustomConsole("forgotPasswordUserVerify Error:\n" + error);
        error = error + "";
        if (error.includes("400")) {
          CustomConsole("Verify Catch if block");
          dispatch({
            type: actionType.RESET_PASSWORD_VERIFY,
            payload: errorObj,
          });
          toast.dark("User Not Registered", {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        } else {
          toast.dark("Un-Handled Error, please try after sometime", {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      });
  };
};

export const ForgotPasswordNewPassword = (new_password, verify_code) => {
  const checksum = sessionStorage.getItem("checksum");
  return (dispatch) => {
    axios
      .put(`${url}/SCM/resetpass/verify_user`, {
        checksum,
        new_password,
        verify_code,
      })
      .then((res) => {
        if (!res.data.error) {
          sessionStorage.removeItem("checksum");
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
          dispatch({
            type: actionType.FORGOT_PASSWORD_USER_VERIFY_OTP,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        toast.dark("Error in verifying please try again", {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};
