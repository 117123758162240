/**
 * @author Tahir Shaik
 * @description ProIndia & bookWater Marketing/Gamification Actions
 * @copyright Bookwater tech pvt ltd
 * @Date 19-09-2023
 */

import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";

/**
 * @author Tahir Shaik
 * @description TO get the list of the campaign details
 * @param {email, fromDate, toDate} props
 * @returns {error, msg, data}
 */
export const GetCampDetails = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/proIndia/campaign/details`, {
        params: props,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_CAMP_DETAILS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.GAMIFICATION_ERROR,
          payload: { error: true },
        });
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GAMIFICATION_ERROR,
        payload: { error: true },
      });
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
};

/**
 * @author Tahir Shaik
 * @description To get the list of games played by the users
 * @param {salesRepEmail, fromDate,toDate , rewardStatus, pageSize, pageNumber} props
 * @returns {error, msg, data}
 */
export const PlayedGames = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/proIndia/playedGames`, {
        params: reqObj,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PLAYED_GAME_DETAILS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.GAMIFICATION_ERROR,
          payload: { error: true },
        });
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GAMIFICATION_ERROR,
        payload: { error: true },
      });
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
};
/**
 * @author Tahir Shaik
 * @description User is going to play a game -> With a game action
 * @param {sales_rep_email, promoter_email, , campaign_id, action_id, reward_id, consumer_referral} props
 * @returns {error, msg, data}
 */
export const PlayAGame = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/proIndia/playGame`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PLAY_A_GAME,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        dispatch({
          type: actionType.GAMIFICATION_ERROR,
          payload: { error: true },
        });
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GAMIFICATION_ERROR,
        payload: { error: true },
      });
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
};

/**
 * @author Tahir Shaik
 * @description Accept/Reject the game played by user
 * @param {response_id, is_accepted, customerReward: { _Id: "",name: "",description: "",points: ""}} props
 * @returns {error, msg, data}
 */
export const ApproveGameAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/proIndia/reward/consumer`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GAME_APPROVE_REJECT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        dispatch({
          type: actionType.GAMIFICATION_ERROR,
          payload: { error: true },
        });
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GAMIFICATION_ERROR,
        payload: { error: true },
      });
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
};
/**
 * @author Tahir Shaik
 * @description To get user Details based on the consumer's referral_id
 * @param {referral_id} props
 * @returns {error, msg, data}
 */
export const GetUserDetailsByReferral = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/verify/referral`, {
        params: reqObj,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USER_WITH_REFERRAL,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.GAMIFICATION_ERROR,
          payload: { error: true },
        });
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GAMIFICATION_ERROR,
        payload: { error: true },
      });
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
};
