import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../../CustomConsole";

function ReplacementReasonDialog(props) {
  const handleInScan = () => {
    props.handleInScan({ selectReason });
  };
  const handleClose = () => {
    props.handleClose();
  };
  const [selectReason, setSelectReason] = React.useState(true);
  CustomConsole(selectReason);
  return (
    <Material.Box>
      <Material.Dialog open={props.openInScanReasonDialog}>
        <Material.DialogContent>
          <Material.FormControl>
            <Material.FormLabel>IS REUSABLE</Material.FormLabel>
            <Material.RadioGroup
              value={selectReason}
              onChange={(event) => setSelectReason(event.target.value)}
            >
              <Material.FormControlLabel
                value={true}
                control={<Material.Radio />}
                label="YES"
              />
              <Material.FormControlLabel
                value={false}
                control={<Material.Radio />}
                label="NO"
              />
            </Material.RadioGroup>
          </Material.FormControl>

          <Material.Box
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Material.Box sx={{ margin: "10px" }}>
              <Material.Button
                onClick={handleClose}
                variant="contained"
                color="error"
              >
                Close
              </Material.Button>
            </Material.Box>
            <Material.Box sx={{ margin: "10px" }}>
              <Material.Button onClick={handleInScan} variant="contained">
                In-Scan
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default ReplacementReasonDialog;
