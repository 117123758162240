/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 29-01-2024
 */
// Importing the Libraries and Other Files
import React from "react";
import * as Material from "@mui/material";
import AppBar from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomConsole from "../../CustomConsole";
import GentateQrBarCodeCrateDialog from "./GentateQrBarCodeCrateDialog";
import CrateQrBarcodeDetailsTable from "./CrateQrBarcodeDetailsTable";
import ReGenrateQrBarcodeCrateDialog from "./ReGenrateQrBarcodeCrateDialog";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import QrBarcodeLinkingDialog from "./QrBarcodeLinkingDialog";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import * as MuiIcons from "@mui/icons-material";
import CrateQrBarc0deFilterDialog from "./CrateQrBarc0deFilterDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateQrCodeForCrate,
  GetQrAndBarCodeListCrate,
  LinkQRBarCodeCrates,
  ReLinkQrBarCodeCrate,
  RegenerateCrateQrBarCode,
} from "../Redux/Actions/skuQrBarCodeAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { ReturnCompleteCrateQrDetails } from "../Redux/Actions/adminQrBarcodeAction";
import QrDetailsDialog from "./QrDetailsDialog";
import CrateQrBarCodeDetailsDialog from "./CrateQrBarCodeDetailsDialog";

function QrBarcodeCrates() {
  const dispatch = useDispatch();

  const QrBarCodeDetails = useSelector((state) => state.skuQrBarcodeDetails);
  const adminQrBarcodeData = useSelector((state) => state.adminQrBarcodeData);
  CustomConsole(adminQrBarcodeData);
  CustomConsole(QrBarCodeDetails);
  const [scanOption, setScanOption] = React.useState("scanner");
  const [openGenrateQrBarcodeCrate, setOpenGenrateQrBarcodeCrate] =
    React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [openCrateQrDetailsDialog, setOpenCrateQrDetailsDialog] =
    React.useState(false);
  const [completeQrCodeDetails, setCompleteQrCodeDetails] = React.useState({});
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [openQrDetailsDialog, setOpenQrDetailsDialog] = React.useState(false);
  const [scanQrFlag, setScanQrFlag] = React.useState(false);
  const [unMountFlag, setUnMountFlag] = React.useState(false);
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [openReGenrateQrBarcodeCrate, setREOpenGenrateQrBarcodeCrate] =
    React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(100);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [qrBarcodeIdNewCrate, setQrBarcodeIdNewCrate] = React.useState("");
  const [qrBarcodeIdCrate, setQrBarcodeIdCrate] = React.useState("");
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [scanCount, setScanCount] = React.useState(0);
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [link, setLink] = React.useState(false);
  const [selectType, setSelectType] = React.useState("");
  const [tabValueCrate, setTabValueCrate] = React.useState("0");
  const inQrBarcodeAdmin = true;
  const initialFormData = {
    crate_id: "",
    crate_name: "",
    qty: "",
    mft_details: {
      company_name: "",
      owner_name: "",
      owner_phone: "",
      address: {
        line1: "",
        line2: "",
        landmark: "",
        area: "",
        state: "",
        country: "",
        pincode: "",
      },
      other_details: {
        GST: "",
        PAN: "",
      },
    },
  };
  const initialFilterValues = {
    where_is_regenerated: false,
    where_is_qr: false,
    where_start_number: "",
    where_end_number: "",
    sort_by_created_at: "ASC",
    sort_by_start_number: "DESC",
    sort_by_end_number: "ASC",
    sort_by_crate_id: 1,
    page_number: pageNumber,
    page_size: 25,
  };

  const [generateCrateQRData, setgenerateCrateQRData] =
    React.useState(initialFormData);
  const [reGenerateQrBarcode, setreGenerateQrBarcode] = React.useState({
    is_qr: true,
    qty: 1,
  });
  const qrBarCodeData = useSelector((state) => state.skuQrBarcodeDetails);
  const [errors, setErrors] = React.useState({});
  // State to manage filter values
  const [filterValues, setFilterValues] = React.useState(initialFilterValues);
  // Update filter values when they change
  const handelRegenerateQrBarCode = (event) => {
    const { name, value } = event.target;
    setreGenerateQrBarcode((prevFilterValues) => ({
      ...prevFilterValues,
      [name]: value,
    }));
  };
  // Update filter values when they change
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      [name]: value,
    }));
  };
  //function for validation
  const validateForm = () => {
    const newErrors = {};
    const gstRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/;
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    const numberRegex = /^\d+$/;
    const isValidPhoneNumber = /^[6789]\d{9}$/;
    if (!numberRegex.test(generateCrateQRData.qty)) {
      newErrors.qty = "Enter Proper Quantity";
    }
    if (
      !generateCrateQRData.mft_details.company_name.trim() ||
      generateCrateQRData.mft_details.company_name.length < 5
    ) {
      newErrors.company_name = "Enter Proper Name";
    }
    if (
      !generateCrateQRData.mft_details.owner_name.trim() ||
      generateCrateQRData.mft_details.owner_name.length < 5
    ) {
      newErrors.owner_name = "Enter Proper Name";
    }
    if (!isValidPhoneNumber.test(generateCrateQRData.mft_details.owner_phone)) {
      newErrors.owner_phone = "Enter Proper Phone Number";
    }
    if (
      !generateCrateQRData.mft_details.address.pincode.trim() ||
      generateCrateQRData.mft_details.address.pincode.length < 6
    ) {
      newErrors.pincode = "Enter Proper Pin Code";
    }
    if (!gstRegex.test(generateCrateQRData.mft_details.other_details.GST)) {
      newErrors.GST = "Enter Proper GST Number";
    }
    if (!panCardRegex.test(generateCrateQRData.mft_details.other_details.PAN)) {
      newErrors.PAN = "Enter Proper PAN Number";
    }
    // Add additional validation for other fields as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  React.useEffect(() => {
    setOpenGenrateQrBarcodeCrate(false);
  }, []);

  //function for handling handelchange for crate details
  const handleChangeCrateQrGenerate = (event) => {
    const { name, value } = event.target;
    setgenerateCrateQRData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //function for handling manufacture details
  const handleMftDetailsChange = (event) => {
    const { name, value } = event.target;
    setgenerateCrateQRData((prevData) => ({
      ...prevData,
      mft_details: {
        ...prevData.mft_details,
        [name]: value,
      },
    }));
  };

  //function for handling mfg details
  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setgenerateCrateQRData((prevData) => ({
      ...prevData,
      mft_details: {
        ...prevData.mft_details,
        address: {
          ...prevData.mft_details.address,
          [name]: value,
        },
      },
    }));
  };

  //function for handling other details
  const handleOtherDetailsChange = (event) => {
    const { name, value } = event.target;

    setgenerateCrateQRData((prevData) => ({
      ...prevData,
      mft_details: {
        ...prevData.mft_details,
        other_details: {
          ...prevData.mft_details.other_details,
          [name]: value,
        },
      },
    }));
  };

  const handleQrGenerateCrate = () => {
    if (validateForm()) {
      dispatch(GenerateQrCodeForCrate(generateCrateQRData));
    } else {
    }
  };

  React.useEffect(() => {
    if (adminQrBarcodeData.error === false) {
      // setCompleteQrCodeDetails(adminQrBarcodeData.crateContainerDetails);
    }
  }, [adminQrBarcodeData]);

  //useFeefect for validating form data
  React.useEffect(() => {
    validateForm();
  }, [generateCrateQRData]);

  // React.useEffect(() => {
  //   CustomConsole("qrCode details");
  //   CustomConsole(qrCodeDetails);
  //   if (Object.keys(qrCodeDetails).length > 0 && unMountFlag === true) {
  //     setOpenQrDetailsDialog(true);
  //   }
  //   CustomConsole("component mounted");
  //   return () => {
  //     // Perform cleanup tasks here
  //     setUnMountFlag(false);
  //   };
  // }, [qrCodeDetails]);
  // completeQrCodeDetails
  React.useEffect(() => {
    CustomConsole("complete qrCode details");
    CustomConsole(completeQrCodeDetails);
    if (Object.keys(completeQrCodeDetails || {}).length > 0) {
      CustomConsole("----------------complete qrCode details-----------");
      setOpenCrateQrDetailsDialog(true);
    }
  }, [completeQrCodeDetails]);

  React.useEffect(() => {
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(tabValueCrate);
      CustomConsole(qrBarcodeId);
      if (
        tabValueCrate == "2" ||
        tabValueCrate == "2" ||
        tabValueCrate == "3"
      ) {
        CustomConsole("==== Data Tab2 ====");
        CustomConsole(qrBarcodeId);
        setScannedQrCode(decodedResults[0]);
        setDecodedResults((prev) => [...prev, qrBarcodeId]);
        setScannedBarCode(qrBarcodeId);
      }
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);

  React.useEffect(() => {
    if (
      qrBarcodeIdNewCrate === qrBarcodeIdCrate &&
      qrBarcodeIdCrate.length &&
      qrBarcodeIdNewCrate.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(tabValueCrate);
      CustomConsole(qrBarcodeIdCrate);
      // if (
      //   tabValueCrate == "0"
      // ) {
      CustomConsole("==== Data Tab2 ====");
      CustomConsole(qrBarcodeIdCrate);
      setScannedQrCodeDetails(qrBarcodeIdCrate);
      setDecodedResults([]);
      // }
      setTimeout(() => {
        setQrBarcodeIdNewCrate("");
        setQrBarcodeIdCrate("");
      }, 300);
    }
  }, [qrBarcodeIdCrate, qrBarcodeIdNewCrate]);

  React.useEffect(() => {
    if (link === true) {
      const reqObj = {
        id1: scannedQrCode,
        id2: scannedBarCode,
      };
      CustomConsole(reqObj);
      if (tabValueCrate == "2") {
        CustomConsole("--------Linking-------------");
        dispatch(LinkQRBarCodeCrates(reqObj));
      } else if (tabValueCrate == "3") {
        dispatch(ReLinkQrBarCodeCrate(reqObj));
      }

      setScannedQrCode("");
      setScannedBarCode("");
      setLinkingDialogOpen(false);
      // setScanCount(0);
      setDecodedResults([]);
      setLink(false);
      setLinkQrBarcodeBtn(false);
    }
  }, [link]);
  const handleTabChangeTabValueCrate = (event, newValue) => {
    setTabValueCrate(newValue);
  };

  const handleOpenGenrateQrBarcodeCrate = (event) => {
    CustomConsole(event.target.value);
    setSelectType(event.target.value);
    if (event.target.value == 10) {
      setOpenGenrateQrBarcodeCrate(true);
    } else if (event.target.value == 20) {
      setREOpenGenrateQrBarcodeCrate(true);
    }
  };
  const handleCloseCrateDilaog = () => {
    CustomConsole("----------handleCloseCrateDilaog-------------");
    setOpenGenrateQrBarcodeCrate(false);
    setgenerateCrateQRData(initialFormData);
  };
  const handleGenrateCrateDilaog = () => {
    CustomConsole("----------handleGenrateCrateDilaog-------------");
    setOpenGenrateQrBarcodeCrate(false);
  };

  //function for regenerating qr and bar code
  const handleSubmitReGenrate = () => {
    dispatch(RegenerateCrateQrBarCode(reGenerateQrBarcode));
    handleCloseReGenrate();
  };
  const handleCloseReGenrate = () => {
    setREOpenGenrateQrBarcodeCrate(false);
  };
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      //   setScanQrCodeDetailBtn(true);
      //   setScanQrFlag(true);
      //   setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };
  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
    // setScanQrFlag(false);
    // setScanOption("scanner");
  };
  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeDetails(decodedText);

      // dispatch(GetDetailsFromQrcode(decodedText));
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedQrCodeDetails(decodedText);
    }
    setScanQrFlag(false);
    setScanOption("scanner");
  };
  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };
  const handleQrBarcodeIdFromScannerCrate = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeIdCrate(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdCrate) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNewCrate(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleFilter = () => {
    setOpenFilter(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  //useEffect for getting qr bar code list
  React.useEffect(() => {
    dispatch(GetQrAndBarCodeListCrate());
    setScannedQrCodeDetails("");
  }, []);
  const getCrateDetails = async (reqObj) => {
    const crateDetails = await ReturnCompleteCrateQrDetails(reqObj);
    setCompleteQrCodeDetails(crateDetails?.data);
  };
  React.useEffect(() => {
    if (scannedQrCodeDetails !== "") {
      const reqObj = {
        // qr_barcode_id: "BW3Q00446",
        qr_barcode: [scannedQrCodeDetails],
        status: true,
      };
      getCrateDetails(reqObj);
      // dispatch(GetCompleteCrateQrDetails(reqObj));
      // setScanQrCodeDetailBtn(false);
      setScannedQrCodeDetails("");
    }
  }, [scannedQrCodeDetails]);

  //useEffect for refreshing data after generation qr and bar code
  React.useEffect(() => {
    if (!QrBarCodeDetails.generateQrError) {
      dispatch(GetQrAndBarCodeListCrate(filterValues));
      QrBarCodeDetails.generateQrError = true;
      handleCloseCrateDilaog();
    }
  }, [QrBarCodeDetails]);

  //function for handling filter operation
  const handelSubmitFilter = () => {
    dispatch(GetQrAndBarCodeListCrate(filterValues));
    handleCloseFilter();
  };

  //function for handling clear filter operation
  const handelClearFilter = () => {
    setFilterValues(initialFilterValues);
  };

  //useEffect for getting data for first time
  React.useEffect(() => {
    dispatch(GetQrAndBarCodeListCrate(filterValues));
  }, []);

  //function for changing page number
  React.useEffect(() => {
    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      page_number: pageNumber,
    }));
  }, [pageNumber]);

  //useEffect for hitting page number
  React.useEffect(() => {
    if (pageNumber >= 1 || filterValues.page_size > 25) {
      dispatch(GetQrAndBarCodeListCrate(filterValues));
    }
    // return () => setPageNumber(1);
  }, [filterValues.page_number, filterValues.page_size]);

  const handleCloseCrateDialog = () => {
    setOpenCrateQrDetailsDialog(false);
    setCompleteQrCodeDetails({});
  };
  return (
    <Material.Box>
      <Material.Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Material.FormControl>
          <Material.RadioGroup
            row
            value={tabValueCrate}
            onChange={handleTabChangeTabValueCrate}
          >
            <Material.FormControlLabel
              value="0"
              control={<Material.Radio />}
              label="SCAN QR or Barcode"
            />
            {parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_WFSADMIN ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_DISTRIBUTER ? (
              ""
            ) : (
              <Material.FormControlLabel
                value="1"
                control={<Material.Radio />}
                label="Generate"
              />
            )}
            {parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_DISTRIBUTER ? (
              ""
            ) : (
              <Material.FormControlLabel
                value="2"
                control={<Material.Radio />}
                label="Link"
              />
            )}
            {parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_DISTRIBUTER ? (
              ""
            ) : (
              <Material.FormControlLabel
                value="3"
                control={<Material.Radio />}
                label="Re-Link"
              />
            )}
          </Material.RadioGroup>
        </Material.FormControl>
      </Material.Box>
      {tabValueCrate === "1" ? (
        <Material.Box
          sx={{
            margin: "10px",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Material.Button
            value={10}
            variant="contained"
            onClick={handleOpenGenrateQrBarcodeCrate}
            size="small"
          >
            Generate Qr/BarCode Crate
          </Material.Button>
          <Material.Button
            value={20}
            variant="contained"
            onClick={handleOpenGenrateQrBarcodeCrate}
            size="small"
            // sx={{ bgcolor: selectType == 20 ? "#20c997" : "#0d6efd" }}
          >
            Re-Generate Qr/BarCode Crate
          </Material.Button>
        </Material.Box>
      ) : (
        <>
          {tabValueCrate === "0" ? (
            <>
              <Material.Box
                sx={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                <Material.FormControl>
                  <Material.FormLabel>
                    Select Scanning Option for crates
                  </Material.FormLabel>
                  <Material.RadioGroup
                    defaultValue="scanner"
                    value={scanOption}
                    name="radio-buttons-group"
                    sx={{ display: "flex", flexDirection: "row" }}
                    onChange={handleSelectScanOption}
                  >
                    <Material.FormControlLabel
                      value="scanner"
                      control={<Material.Radio />}
                      label="Scanner Device"
                    />
                    <Material.FormControlLabel
                      value="camera"
                      control={<Material.Radio />}
                      label="Camera"
                    />
                  </Material.RadioGroup>
                </Material.FormControl>

                {tabValueCrate === "0" && scanOption === "scanner" ? (
                  <Material.Box
                    sx={{ marginTop: "20px", marginBottom: "50px" }}
                  >
                    <Material.TextField
                      onChange={handleQrBarcodeIdFromScannerCrate}
                      label="QR/Barcode:"
                      value={qrBarcodeIdCrate}
                      size="small"
                      sx={{
                        height: "10px",
                        width: "200px",
                        marginBotton: "30px",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Material.Box>
                ) : (
                  ""
                )}
              </Material.Box>
            </>
          ) : (
            ""
          )}
          <>
            {tabValueCrate === "2" || tabValueCrate === "3" ? (
              <>
                <Material.Box
                  sx={{
                    margin: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  <Material.FormControl>
                    <Material.FormLabel>
                      Select Scanning Option for crates
                    </Material.FormLabel>
                    <Material.RadioGroup
                      defaultValue="scanner"
                      value={scanOption}
                      name="radio-buttons-group"
                      sx={{ display: "flex", flexDirection: "row" }}
                      onChange={handleSelectScanOption}
                    >
                      <Material.FormControlLabel
                        value="scanner"
                        control={<Material.Radio />}
                        label="Scanner Device"
                      />
                      <Material.FormControlLabel
                        value="camera"
                        control={<Material.Radio />}
                        label="Camera"
                      />
                    </Material.RadioGroup>
                  </Material.FormControl>

                  {(tabValueCrate === "2" && scanOption === "scanner") ||
                  (tabValueCrate === "3" && scanOption === "scanner") ? (
                    <Material.Box
                      sx={{ marginTop: "20px", marginBottom: "50px" }}
                    >
                      <Material.TextField
                        onChange={handleQrBarcodeIdFromScanner}
                        label="QR/Barcode:"
                        value={qrBarcodeId}
                        size="small"
                        sx={{
                          height: "10px",
                          width: "200px",
                          marginBotton: "30px",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Material.Box>
                  ) : (
                    ""
                  )}
                </Material.Box>
              </>
            ) : (
              ""
            )}
          </>
        </>
      )}
      <>
        {(scanOption === "camera" && tabValueCrate === "2") ||
        (scanOption === "camera" && tabValueCrate === "3") ? (
          <Material.Box
            sx={{
              maxWidth: "250px",
            }}
          >
            {" "}
            <Html5QrcodePlugin1
              fps={1}
              qrbox={200}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
          </Material.Box>
        ) : (
          ""
        )}
        {scanOption === "camera" && tabValueCrate === "0" ? (
          <Material.Box
            sx={{
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Html5QrcodePlugin1
              fps={1}
              qrbox={200}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResultQrCode}
            />
          </Material.Box>
        ) : (
          ""
        )}
        <ResultContainerPlugin1
          results={decodedResults}
          setScanCount={setScanCount}
          scanType={scanOption}
        />
      </>
      {/* {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
        (parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_SUPERADMIN && (
          <Material.Box
            sx={{ boxShadow: 5, paddingTop: "1rem", borderRadius: "10px" }}
          >
            <Material.Box
              sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
            >
              <Material.Button
                variant="contained"
                size="small"
                sx={{ height: "37px" }}
                onClick={handleOpenFilter}
              >
                Filter
              </Material.Button>
              <Material.FormControl sx={{ minWidth: 120 }} size="small">
                <Material.InputLabel>Page size</Material.InputLabel>
                <Material.Select
                  value={filterValues.page_size}
                  label="Page size"
                  onChange={handleFilterChange}
                  name="page_size"
                >
                  <Material.MenuItem value={25}>25</Material.MenuItem>
                  <Material.MenuItem value={50}>50</Material.MenuItem>
                  <Material.MenuItem value={100}>100</Material.MenuItem>
                  <Material.MenuItem value={500}>500</Material.MenuItem>
                </Material.Select>
              </Material.FormControl>
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Material.IconButton
                  onClick={() => {
                    if (pageNumber - 1 > 0) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <MuiIcons.FirstPage
                    sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                  />
                </Material.IconButton>
                <Material.Typography
                  sx={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  {pageNumber}
                </Material.Typography>
                <Material.IconButton
                  sx={{ maxHeight: "50px" }}
                  onClick={() => {
                    if (
                      filterValues.page_size ==
                      qrBarCodeData.careQrListData.length
                    ) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <MuiIcons.LastPage
                    sx={{
                      color:
                        filterValues.page_size <
                        qrBarCodeData.careQrListData.length
                          ? "blue"
                          : "gray",
                    }}
                  />
                </Material.IconButton>
              </Material.Box>
            </Material.Box>
            <CrateQrBarcodeDetailsTable />
          </Material.Box>
        ))} */}
      {parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_DISTRIBUTER ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_WFSADMIN ? (
        ""
      ) : (
        <Material.Box
          sx={{ boxShadow: 5, paddingTop: "1rem", borderRadius: "10px" }}
        >
          <Material.Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <Material.Button
              variant="contained"
              size="small"
              sx={{ height: "37px" }}
              onClick={handleOpenFilter}
            >
              Filter
            </Material.Button>
            <Material.FormControl sx={{ minWidth: 120 }} size="small">
              <Material.InputLabel>Page size</Material.InputLabel>
              <Material.Select
                value={filterValues.page_size}
                label="Page size"
                onChange={handleFilterChange}
                name="page_size"
              >
                <Material.MenuItem value={25}>25</Material.MenuItem>
                <Material.MenuItem value={50}>50</Material.MenuItem>
                <Material.MenuItem value={100}>100</Material.MenuItem>
                <Material.MenuItem value={500}>500</Material.MenuItem>
              </Material.Select>
            </Material.FormControl>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Material.IconButton
                onClick={() => {
                  if (pageNumber - 1 > 0) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                <MuiIcons.FirstPage
                  sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                />
              </Material.IconButton>
              <Material.Typography
                sx={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {pageNumber}
              </Material.Typography>
              <Material.IconButton
                sx={{ maxHeight: "50px" }}
                onClick={() => {
                  if (
                    filterValues.page_size ==
                    qrBarCodeData.careQrListData.length
                  ) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
              >
                <MuiIcons.LastPage
                  sx={{
                    color:
                      filterValues.page_size <
                      qrBarCodeData.careQrListData.length
                        ? "blue"
                        : "gray",
                  }}
                />
              </Material.IconButton>
            </Material.Box>
          </Material.Box>
          <CrateQrBarcodeDetailsTable />
        </Material.Box>
      )}
      {openCrateQrDetailsDialog === true &&
        completeQrCodeDetails.length > 0 && (
          // completeQrCodeDetails &&
          // (completeQrCodeDetails.qualityDetails.length ||
          //   completeQrCodeDetails.ownerDetails.length) &&
          <CrateQrBarCodeDetailsDialog
            open={openCrateQrDetailsDialog}
            handleClose={handleCloseCrateDialog}
            data={completeQrCodeDetails}
            // qrCodeDetails={qrCodeDetails}
          />
        )}
      <GentateQrBarCodeCrateDialog
        openGenrateQrBarcodeCrate={openGenrateQrBarcodeCrate}
        handleGenrateCrateDilaog={handleGenrateCrateDilaog}
        handleCloseCrateDilaog={handleCloseCrateDilaog}
        handleChangeCrateQrGenerate={handleChangeCrateQrGenerate}
        handleMftDetailsChange={handleMftDetailsChange}
        handleAddressChange={handleAddressChange}
        handleOtherDetailsChange={handleOtherDetailsChange}
        generateCrateQRData={generateCrateQRData}
        setgenerateCrateQRData={setgenerateCrateQRData}
        handleQrGenerateCrate={handleQrGenerateCrate}
        error={errors}
      />
      <ReGenrateQrBarcodeCrateDialog
        openReGenrateQrBarcodeCrate={openReGenrateQrBarcodeCrate}
        handleCloseReGenrate={handleCloseReGenrate}
        handleSubmitReGenrate={handleSubmitReGenrate}
        handelRegenerateQrBarCode={handelRegenerateQrBarCode}
        reGenerateQrBarcode={reGenerateQrBarcode}
      />
      <QrBarcodeLinkingDialog
        setLinkingDialogOpen={setLinkingDialogOpen}
        linkingDialogOpen={linkingDialogOpen}
        scannedQrCode={scannedQrCode}
        scannedBarCode={scannedBarCode}
        setLink={setLink}
        scanCount={scanCount}
        setLinkQrBarcodeBtn={setLinkQrBarcodeBtn}
        inQrBarcodeAdmin={inQrBarcodeAdmin}
      />
      <CrateQrBarc0deFilterDialog
        openFilter={openFilter}
        handleCloseFilter={handleCloseFilter}
        handleFilter={handleFilterChange}
        handelSubmitFilter={handelSubmitFilter}
        handelClearFilter={handelClearFilter}
        filterValues={filterValues}
      />
    </Material.Box>
  );
}

export default QrBarcodeCrates;
