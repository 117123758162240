import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { GetIotDevices } from "../Redux/Actions/adminDevicesIotAction";
import IotDevicesCard from "./IotDevicesCard";
import IotDevicesTable from "./IotDevicesTable";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CustomConsole from "../../CustomConsole";

export default function AdminIotDevices() {
  const [alignment, setAlignment] = React.useState("editIot");
  const [addNewDevice, setAddNewDevice] = React.useState(false);

  const dispatch = useDispatch();
  const IotDevicesGet = useSelector((state) => state.IotDeviesData);
  const [IotDevices, setIotDevices] = React.useState([]);
  const [selectedDeviceData, setSelectedDeviceData] = React.useState([]);

  const [manufacturingData, setManufacturingData] = React.useState("");
  const scrollDownToDetailsCard = useRef(null);
  const [roles, setroles] = React.useState("");

  React.useEffect(() => {
    dispatch(GetIotDevices());
    const roleid = parseInt(window.localStorage.getItem("roleId"), 10);
    if (roleid) {
      setroles(roleid);
    }
  }, []);

  React.useEffect(() => {
    CustomConsole(IotDevicesGet);
    setIotDevices(IotDevicesGet.IoTDevicesData);
    setSelectedDeviceData(IotDevicesGet.IoTDeviceDataSingle);
  }, [IotDevicesGet]);

  const scrollToBottom = () => {
    scrollDownToDetailsCard.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {ADMIN_ROLES.BW_SUPERADMIN === roles ||
      ADMIN_ROLES.BW_QAHEAD === roles ||
      ADMIN_ROLES.BW_ADMIN === roles ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              marginBottom: "40px",
            }}
          >
            <IotDevicesCard
              IotDevices={IotDevices}
              scrollRef={scrollDownToDetailsCard}
              selectedDeviceData={selectedDeviceData}
              addNewDevice={addNewDevice}
              alignment={alignment}
              setAlignment={setAlignment}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </>
  );
}
