import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomConsole from "../../CustomConsole";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import {
  UpdateSubscriptionDetails,
  ValidatingProducts,
} from "../Redux/Actions/adminSkuBookingAction";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.subscription_owner_details.name}
        </TableCell>
        <TableCell align="right">
          {row.subscription_details.next_delivery_date}
        </TableCell>
        <TableCell align="right">
          <Typography>
            {row?.order_details?.delivery_address?.address_line1},
            {row?.order_details?.delivery_address?.address_line2},
          </Typography>
          <Typography>
            {row?.order_details?.delivery_address?.service_area},
            {row?.order_details?.delivery_address?.pincode}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={() => {
              props.pauseFun(
                row.subscription_details.subscription_id,
                row.subscription_details.is_pause
              );
            }}
          >
            {row.subscription_details.is_pause ? "Resume" : "Pause"}
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              props.editSubs(
                row.order_details.products,
                row.order_details.delivery_address,
                row.subscription_details.repeat_days,
                row.subscription_details.subscription_id,
                row.order_details.is_auto_apply_coupon,
                row.order_details.is_auto_apply_water_drops,
                row.order_details.booking_type,
                row.subscription_details.next_delivery_date
              )
            }
          >
            Edit
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() =>
              props.stopFun(row.subscription_details.subscription_id)
            }
          >
            Stop
          </Button>
        </TableCell>
        <TableCell align="right">
          {row.order_details?.booking_type === 1 ? "Refill" : "One Time Sale"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Products
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Material</TableCell>
                    <TableCell>Capacity</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Return</TableCell>
                    <TableCell>Sku Qty.</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.order_details.products.map((productsRow, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{productsRow.material}</TableCell>
                      <TableCell>{productsRow.capacity}</TableCell>
                      <TableCell>{productsRow.quantity}</TableCell>
                      <TableCell>{productsRow.return}</TableCell>
                      <TableCell>{productsRow.sku_qty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function SubscriptionDataTable({
  phoneNumber,
  setOpen,
  editDialog,
  setEditproducts,
  setEditingAdrs,
  setSelectedDays,
  setSubscriptionId,
  setDiscountChecks,
  setOpenBookingDialog,
  editSubscriptionDialog,
  setEditSubscriptionDialog,
  setUpdateSubscriptonText,
  setBooktypeSub,
}) {
  const dispatch = useDispatch();
  const skuBookingData = useSelector((state) => state.adminSkuBookingData);
  const productSku = useSelector((state) => state.adminSkuBookingData);
  const [subscriptionData, setSubscription] = React.useState([]);
  const [updateCartObj, setUpdatecartObj] = React.useState({
    products: [],
    address_id: 0,
    booking_type: 1,
    coupon_code: "",
    water_drops: 0,
    return: 0,
    payment_method: "WALLET",
    secondary_payment_method: "",
    is_otp_less: true,
    delivery_date: "",
    is_extra_charges_disabled: false,
    is_auto_apply_water_drops: true,
    is_auto_apply_coupon: true,

    invalid_products: [],
  });

  const handlePauseSubscription = (id, status) => {
    const reqObj = {
      subscription_update_state: status ? 100 : 200,
      subscription_ids: [id],
    };

    const getParam = {
      user_phone: phoneNumber,
    };

    dispatch(UpdateSubscriptionDetails(reqObj, getParam));
  };

  const handleStopSubscription = (id) => {
    const reqObj = {
      subscription_update_state: 300,
      subscription_ids: [id],
    };

    const getParam = {
      user_phone: phoneNumber,
    };

    dispatch(UpdateSubscriptionDetails(reqObj, getParam));
  };

  const handleEditSubscription = (
    products,
    deliveryAddress,
    days,
    id,
    promo,
    waterDrop,
    booking_type,
    delivery_date
  ) => {
    CustomConsole("----delivery addresss setting----");
    CustomConsole(products);
    CustomConsole(deliveryAddress);
    CustomConsole(id);
    CustomConsole(booking_type);
    // setOpen(true);
    // editDialog(true);
    setEditSubscriptionDialog(true);
    setOpenBookingDialog(true);
    products.forEach((obj) => delete obj.crates);
    setEditproducts(products);
    setEditingAdrs(deliveryAddress);
    // const returnVal = productSku?.userStock?.filter(
    //   (data) =>
    //     data.container_capacity === product[0] &&
    //     data.container_material === product[1]
    // );
    setBooktypeSub(booking_type);
    setUpdatecartObj((prev) => ({ ...prev, products: products }));
    setUpdatecartObj((prev) => ({
      ...prev,
      address_id: deliveryAddress.address_id,
    }));
    setUpdatecartObj((prev) => ({ ...prev, booking_type: booking_type }));
    setUpdatecartObj((prev) => ({ ...prev, delivery_date: delivery_date }));
    setSelectedDays(days);
    setSubscriptionId(id);
    setDiscountChecks(() => ({ waterDrops: waterDrop, promo: promo }));
    setUpdateSubscriptonText(true);
  };
  React.useEffect(() => {
    if (updateCartObj.products.length > 0) {
      dispatch(ValidatingProducts(updateCartObj));
    }
  }, [updateCartObj]);

  React.useEffect(() => {
    CustomConsole("---Subs data---");
    CustomConsole(skuBookingData.subsData);
    if (skuBookingData.subsData) {
      setSubscription(skuBookingData.subsData);
    }
  }, [skuBookingData]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Customer</TableCell>
            <TableCell align="right">Next Delivery</TableCell>
            <TableCell align="right">Delivery Address</TableCell>
            <TableCell align="right">Pause</TableCell>
            <TableCell align="right">Edit</TableCell>
            <TableCell align="right">Stop</TableCell>
            <TableCell align="right">Booking Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionData.map((row, idx) => (
            <Row
              key={idx}
              row={row}
              pauseFun={handlePauseSubscription}
              stopFun={handleStopSubscription}
              editSubs={handleEditSubscription}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
