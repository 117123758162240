import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import {
  EnableUserAdmin,
  DisableUserAdmin,
  GetAssignedWfsDetails,
  DisableAssignWfsSwitch,
  GetProfileDetails,
  UpdateUserDetails,
  DeleteUser,
  UpdateUserRoleId,
} from "../Redux/Actions/userDetailsAdminAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import userLogo from "../../Components/Assests/Images/userLogo.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { DeleteOutlined, SyncRounded } from "@mui/icons-material";
import WfsLineDetails from "../AdminUsers/WfsLineDetails";
import AssignInchargeWfs from "../AdminUsers/AssignInchargeWfs";
import PlaceOrderToWFSDialog from "./PlaceOrderToWFSDialog";
import DeleteUserDialog from "../../Components/Dialog/DeleteUserDialog";
import WfsAdminEmptyCanOrderDialog from "../../Components/Dialog/wfsAdminEmptyCanOrder";
import { GetWfsLineDetails } from "../Redux/Actions/adminLineDetailsAction";
import { Box } from "@mui/system";

function WFSIncharge(props) {
  const dispatch = useDispatch();
  const [changeInSwitch, setChangeInSwitch] = React.useState(false);
  const [showAssignedWfs, setShowAssignedWfs] = React.useState(false);
  const [distributorType, setDistributorType] = React.useState("");
  const [updateUserBtn, setUpdateUserBtn] = React.useState(false);
  const [openPlaceOrderToWFSDialog, setOpenPlaceOrderToWFSDialog] =
    React.useState(false);
  const [wfsSwitchChange, setWfsSwitchChange] = React.useState(false);
  const [wfsId, setWfsId] = React.useState("");
  const [assignedWfsAddress, setAssignedWfsAddress] = React.useState({});
  const [assignedWfsStock, setAssignedWfsStock] = React.useState({});
  const [switchWFSDetails, setSwitchWFSDetails] = React.useState(true);
  const [customPrice, setCustomPrice] = React.useState("");
  const [twntyLtrDiscount, setTwntyLtrDiscount] = React.useState(0);
  const [stockCapacity, setStockCapacity] = React.useState("");
  const [deleteUserDialog, setDeleteUserDialog] = React.useState(false);
  const [selectedRoleId, setSelectedRoleId] = React.useState("");
  //   const [props.isSwitchOn, setIsSwitchOn] = React.useState(true);
  const [wfsInchargeAddress, setWfsInchargeAddress] = React.useState([
    { District: "" },
    { Pincode: "" },
    { State: "" },
    { Village: "" },
    { Country: "" },
  ]);

  // React.useState(() => {
  //   if(props.userId){
  //     dispatch(GetAssignedWfsDetails(props.userId));
  //   }
  
  // }, [props.userId]);
  React.useEffect(() => {
    CustomConsole("---------Line----------");
    CustomConsole(wfsId);
    CustomConsole(props.userId);
    dispatch(GetWfsLineDetails(wfsId));
  }, [props.userId, wfsId]);
  React.useEffect(() => {
    CustomConsole(props.updateUserDetails);
    if (props.updateUserDetails.error === false) {
      setAssignedWfsAddress(props.updateUserDetails.assignedWfsAddress);
      props.updateUserDetails.assignedWfsStock.map((data) => {
        setAssignedWfsStock(data);
      });
    }
  }, [props.updateUserDetails]);

  CustomConsole(props.updateUserDetails.assignedWfsAddress.pincode);
  React.useEffect(() => {
    setWfsId(props.updateUserDetails.assignedWfsAddress.wfs_id);
    CustomConsole("-----  updateUserDetails.assignedWfsAddress.address -------");
    CustomConsole(props.updateUserDetails.assignedWfsAddress.address);
    if (
      props.updateUserDetails.assignedWfsAddress.address !== undefined &&
      Object.keys(props.updateUserDetails.assignedWfsAddress.address).length
    ) {
      setWfsInchargeAddress(() => {
        return [
          AddressData(
            "District",
            props.updateUserDetails.assignedWfsAddress.address.District !==
              undefined
              ? props.updateUserDetails.assignedWfsAddress.address.District
              : "District Not Available!"
          ),
          AddressData(
            "Pincode",
            props.updateUserDetails.assignedWfsAddress.address.Pincode !==
              undefined
              ? props.updateUserDetails.assignedWfsAddress.address.Pincode
              : "Pincode Not Available!"
          ),
          AddressData(
            "State",
            props.updateUserDetails.assignedWfsAddress.address.State !==
              undefined
              ? props.updateUserDetails.assignedWfsAddress.address.State
              : "State Not Available!"
          ),
          AddressData(
            "Street",
            props.updateUserDetails.assignedWfsAddress.address.Street !==
              undefined
              ? props.updateUserDetails.assignedWfsAddress.address.Street
              : "Street  Not Available!"
          ),
          AddressData(
            "Village",
            props.updateUserDetails.assignedWfsAddress.address.Village !==
              undefined
              ? props.updateUserDetails.assignedWfsAddress.address.Village
              : "Village  Not Available!"
          ),
          AddressData(
            "Country",
            props.updateUserDetails.assignedWfsAddress.address.country !==
              undefined
              ? props.updateUserDetails.assignedWfsAddress.address.country
              : "Country  Not Available!"
          ),
        ];
      });
    } else {
      setWfsInchargeAddress([
        { District: "District Not found" },
        { Pincode: "Pincode Not found" },
        { State: "State Not found" },
        { Village: "Village Not found" },
        { Country: "Country Not found" },
      ]);
    }
  }, [props.updateUserDetails]);

  const handleWFSSwitch = (event) => {
    setWfsSwitchChange(true);
    if (event.target.checked) {
      setSwitchWFSDetails(true);

      //  dispatch(EnableAssignWfsSwitch(wfsId))
    } else if (event.target.checked === false) {
      setSwitchWFSDetails(false);
      dispatch(DisableAssignWfsSwitch(wfsId));
      setShowAssignedWfs(false);
      //   setrefershFlag(false);
    }
  };
  function createDataStock(name, calories) {
    return { name, calories };
  }

  const rowsStock = [
    createDataStock("Container material", assignedWfsStock.container_capacity),
    createDataStock("Container capacity", assignedWfsStock.container_material),
    createDataStock("Empty container", assignedWfsStock.empty_containers),
    createDataStock("Filled container", assignedWfsStock.filled_containers),
    createDataStock(
      "Physically damaged",
      assignedWfsStock.physically_damaged_containers
    ),
    createDataStock(
      "QR/Barcode damaged",
      assignedWfsStock.qr_bar_code_damaged_containers
    ),
    createDataStock("Ongoing containers", assignedWfsStock.ongoing_containers),
  ];

  function createData(name, calories) {
    return { name, calories };
  }

  const rows = [
    createData("WFS name", assignedWfsAddress.company_name),
    createData("Owner name", assignedWfsAddress.owner_name),
    createData("Owner phone", assignedWfsAddress.owner_phone),
    createData("Pincode", assignedWfsAddress.pincode),
  ];

  function AddressData(title, data) {
    return { title, data };
  }

  React.useEffect(() => {
    if (wfsSwitchChange === true && switchWFSDetails === true) {
      toast.success("No Enable Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setWfsSwitchChange(false);
    }
    if (wfsSwitchChange === true && switchWFSDetails === false) {
      toast.success(props.updateUserDetails.wfsDiasbleuserSitchMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setWfsSwitchChange(false);
    }
  }, [props.updateUserDetails]);
  React.useEffect(() => {
    if (changeInSwitch === true && props.isSwitchOn === true) {
      toast.success(props.updateUserDetails.enableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
    if (changeInSwitch === true && props.isSwitchOn === false) {
      toast.success(props.updateUserDetails.disableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
  }, [props.updateUserDetails]);
  React.useEffect(() => {
    CustomConsole("--------updateUserDetails---------");
    CustomConsole(props.updateUserDetails);
    // CustomConsole(updateUserDetails.userProfileData.length);
    // CustomConsole(updateUserDetails.userProfileData.consumer);
    if (props.updateUserDetails.error === false) {
      //   setIsrevertbackenabled(props.updateUserDetails.is_revert_back_enabled);
      // CustomConsole(updateUserDetails.userProfileData.length);
      // CustomConsole(Object.keys(updateUserDetails.userProfileData).length);
      if (
        props.updateUserDetails.userProfileData !== undefined &&
        props.updateUserDetails.userProfileData !== null &&
        Object.keys(props.updateUserDetails.userProfileData).length > 0
      ) {
        CustomConsole("--------updateUserDetails---------");
        CustomConsole(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        CustomConsole(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        setTwntyLtrDiscount(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        setCustomPrice(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        CustomConsole("--------custom_twentyltr_price---------");
      }
      setUpdateUserBtn(false);
      // setUpdateFlagCheck(false);
    }
    if (props.updateUserDetails.error === false && updateUserBtn === true) {
      CustomConsole("Profile");

      CustomConsole("Profile");
      dispatch(GetProfileDetails(props.userId));
      setUpdateUserBtn(false);
    }
    // setTwntyLtrDiscount("");
    // setCustomPrice("");
  }, [props.updateUserDetails]);
  React.useEffect(() => {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var result = reg.test(props.email);
    var emailResult = false;
    if (props.roleId === 3000) {
      let regEmail = /^[^@]+@bookwater\.com$/;
      emailResult = regEmail.test(props.email);
    }
    if (updateUserBtn === true) {
      CustomConsole(parseInt(twntyLtrDiscount) < parseInt(customPrice));
      if (
        props.phone.length === 10 &&
        result === true &&
        props.fullname.trim() !== "" &&
        emailResult === false
      ) {
        dispatch(
          UpdateUserDetails(
            props.fullname,
            props.phone,
            props.email,
            props.roleId,
            twntyLtrDiscount || 0,
            props.userId,
            distributorType || undefined,
            customPrice
          )
        );
      } else {
        toast.error("Please update valid details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUpdateUserBtn(false);
      }

      setUpdateUserBtn(false);
    }
  }, [updateUserBtn]);
  const handleShowAssignedWfs = () => {
    if (props.updateUserDetails.assignedWfsAddress.pincode !== undefined) {
      setShowAssignedWfs(true);
      if (props.refershFlag === true) {
        setSwitchWFSDetails(true);
      }
    } else {
      toast.error(
        "Water Filling Station Not Assigned to the incharge,Please Contact BookWater Consumer Support!!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  const handleChangeSwitch = (event) => {
    setChangeInSwitch(true);
    if (event.target.checked) {
      props.setIsSwitchOn(true);
      dispatch(EnableUserAdmin(props.userId));
    } else if (event.target.checked === false) {
      props.setIsSwitchOn(false);
      dispatch(DisableUserAdmin(props.userId));
    }
  };
  const handlePlaceOrderToWFS = () => {
    setOpenPlaceOrderToWFSDialog(true);
  };
  const handleClosePlaceOrder = () => {
    setOpenPlaceOrderToWFSDialog(false);
  };
  const handleUpdateUserDetails = () => {
    setUpdateUserBtn(true);
    setTimeout(() => {
      dispatch(GetProfileDetails(props.userId));
    }, 1000);
  };
  const handleCloseDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };
  const handleDeleteUserDialog = () => {
    dispatch(DeleteUser(props.userId));
    props.setIsUserDetailsOpen(false);
    setDeleteUserDialog(false);
  };
  const handleDeleteUser = () => {
    // dispatch(DeleteUser(userId));
    // setIsUserDetailsOpen(false);
    setDeleteUserDialog(true);
  };
  const handleChangeRoles = (event) => {
    CustomConsole(event.target.value);
    setSelectedRoleId(event.target.value);
  };
  const handleRoleIdUpdate = () => {
    const reqObj = {
      user_id: props.userId,
      role_id: selectedRoleId,
    };
    if (selectedRoleId !== "") {
      CustomConsole("---- handleRoleIdUpdate ----");
      CustomConsole(reqObj);
      // check Email!!!!
      const regex = /@bookwater\.com$/i;
      const testResp = regex.test(props.updatedMail);
      if (
        testResp &&
        selectedRoleId !== ADMIN_ROLES.BW_DELIVERYAGENT &&
        selectedRoleId !== ADMIN_ROLES.BW_CONSUMER &&
        selectedRoleId !== ADMIN_ROLES.BW_DISTRIBUTER
      ) {
        dispatch(UpdateUserRoleId(reqObj));
      } else if (
        selectedRoleId === ADMIN_ROLES.BW_DELIVERYAGENT ||
        selectedRoleId === ADMIN_ROLES.BW_CONSUMER ||
        selectedRoleId === ADMIN_ROLES.BW_DISTRIBUTER
      ) {
        dispatch(UpdateUserRoleId(reqObj));
      } else {
        toast.error("Please update Mail With Bookwater E-Mail", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      toast.error("Please select role ID to update", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <Material.Box>
      <Material.Grid container spacing={1}>
        <Material.Grid item md={3.5} lg={3.5} xs={12}>
          <Material.Box sx={{ mb: 1, ml: 1, mr: 1 }}>
            <Material.Box>
              <Material.Paper sx={{ borderRadius: "10px", p: "5px" }}>
                <Material.Box>
                  <Material.Box
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Material.Button
                      variant="outlined"
                      color="error"
                      //   sx={{
                      //     background: appColors.commonBtnColor.background,
                      //   }}
                      onClick={handlePlaceOrderToWFS}
                    >
                      Place Order
                    </Material.Button>
                  </Material.Box>
                  <Material.Box
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src={userLogo}
                      alt=""
                    />
                  </Material.Box>
                </Material.Box>
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Material.TextField
                    label="FullName"
                    size="small"
                    value={props.fullname}
                    onChange={(event) => props.setFullname(event.target.value)}
                    // sx={{ mb: "10px", width: "250px" }}
                  />
                  <Material.TextField
                    label="Phone"
                    size="small"
                    value={props.phone}
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onChange={(event) => props.setPhone(event.target.value)}
                    // sx={{ mb: "10px", width: "250px" }}
                  />
                  <Material.TextField
                    label="Email"
                    size="small"
                    value={props.email}
                    onChange={(event) => props.setEmail(event.target.value)}
                    // sx={{ mb: "10px", width: "250px" }}
                  />
                  {/* {props.roleId === 1002 ? (
                    <>
                      {props.userRoleId !== 3000 ? (
                        <>
                          {" "}
                          {parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_SUPERADMIN ? (
                            <Material.Box
                              className="drawer"
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              {props.roles.length > 0 && (
                                <Material.Box>
                                  <Material.FormControl
                                    sx={{ m: 1, minWidth: 200 }}
                                    size="small"
                                  >
                                    <Material.InputLabel id="demo-select-small">
                                      Select role to add
                                    </Material.InputLabel>
                                    <Material.Select
                                      labelId="demo-select-small"
                                      id="demo-select-small"
                                      value={selectedRoleId}
                                      label="Select role to add"
                                      onChange={handleChangeRoles}
                                    >
                                      {props.roles.map((role) => {
                                        return (
                                          <Material.MenuItem
                                            value={role.role_id}
                                          >
                                            {role.role_name}
                                          </Material.MenuItem>
                                        );
                                      })}
                                    </Material.Select>
                                  </Material.FormControl>
                                </Material.Box>
                              )}
                              <Material.Box>
                                <Material.Button
                                  onClick={handleRoleIdUpdate}
                                  size="small"
                                  variant="contained"
                                >
                                  Update Role
                                </Material.Button>
                              </Material.Box>
                            </Material.Box>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )} */}
                </Material.Box>
                <Material.Box
                  sx={{
                    border: "2px solid black",
                    // width: "250px",
                    padding:"5px",
                    pl:"20px",
                    mt: 2,
                    ml: { xs: 0, md: 0 },
                  }}
                >
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    {" "}
                    <Material.Box>
                      <Material.Typography
                        sx={{
                          // fontSize: "18px",
                          // fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        User
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box>
                      {parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_ADMIN ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_SUPERADMIN ||
                      parseInt(localStorage.getItem("roleId")) ===
                        ADMIN_ROLES.BW_CSHEAD ? (
                        <Material.FormControlLabel
                          control={
                            <Material.Switch checked={props.isSwitchOn} />
                          }
                          label={
                            props.isSwitchOn ? (
                              <Material.Typography
                                sx={{
                                  color: "black",
                                }}
                              >
                                Active
                              </Material.Typography>
                            ) : (
                              <Material.Typography
                                sx={{
                                  color: "black",
                                }}
                              >
                                InActive
                              </Material.Typography>
                            )
                          }
                          onChange={handleChangeSwitch}
                        />
                      ) : (
                        ""
                      )}
                    </Material.Box>
                  </Material.Box>
                </Material.Box>
                <Material.Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    m: 2,
                  }}
                >
                  <Material.Button
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={handleUpdateUserDetails}
                  >
                    {/* <SyncRounded /> */}
                    Update
                  </Material.Button>
                  <Material.Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={handleDeleteUser}
                  >
                    <DeleteOutlined />
                    Delete User
                  </Material.Button>
                </Material.Box>
              </Material.Paper>
            </Material.Box>
          </Material.Box>
        </Material.Grid>
        <Material.Grid item md={8.5} lg={8.5} xs={12}>
          <Material.Box>
            <Material.Box sx={{ mb: 1, ml: 1, mr: 1 }}>
              <Material.Paper sx={{ borderRadius: "10px", padding: "5px" }}>
                <Material.Box>
                  <Material.Typography sx={{ fontWeight: "bold" }}>
                    ASSIGN INCHARGE WFS
                  </Material.Typography>
                </Material.Box>
                <AssignInchargeWfs wfsId={wfsId} userId={props.userId} />
              </Material.Paper>
            </Material.Box>
            <Material.Box sx={{ mb: 1, mr: 1, ml: 1 }}>
              <Material.Paper sx={{ padding: "5px", borderRadius: "10px" }}>
                <Material.Box
                  sx={{ m: 1, display: "flex", justifyContent: "flex-end" }}
                >
                  <Material.Button
                    variant="contained"
                    //   sx={{
                    //     background: appColors.commonBtnColor.background,
                    //   }}
                    onClick={handleShowAssignedWfs}
                  >
                    Show Assigned WFS
                  </Material.Button>
                </Material.Box>
                {showAssignedWfs === true && switchWFSDetails === true ? (
                  <Material.Box>
                    <Material.Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        margin: "20px",
                      }}
                    >
                      <Material.Typography>
                        WFS Assign Details
                      </Material.Typography>

                      <Material.FormControlLabel
                        label="Enable"
                        control={<Material.Switch checked={switchWFSDetails} />}
                        onChange={(event) =>
                          handleWFSSwitch(
                            event,
                            props.updateUserDetails.assignedWfsAddress.wfs_id
                          )
                        }
                      />
                    </Material.Box>
                    {switchWFSDetails === false ? (
                      ""
                    ) : (
                      <Material.Box sx={{ marginTop: "10px", padding: "10px" }}>
                        <Material.TableContainer component={Material.Paper}>
                          <Material.Table aria-label="simple table">
                            <Material.TableBody>
                              {rows.map((row) => (
                                <Material.TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Material.TableCell
                                    component="th"
                                    scope="row"
                                  >
                                    {row.name}
                                  </Material.TableCell>
                                  <Material.TableCell align="right">
                                    {row.calories}
                                  </Material.TableCell>
                                </Material.TableRow>
                              ))}
                            </Material.TableBody>
                          </Material.Table>
                        </Material.TableContainer>
                      </Material.Box>
                    )}
                    {switchWFSDetails === false ? (
                      ""
                    ) : (
                      <Material.Box>
                      

                        {/* <Material.Box
                          sx={{ marginTop: "10px", padding: "10px" }}
                        >
                          <Material.TableContainer component={Material.Paper}>
                            <Material.Table aria-label="simple table">
                              <Material.TableBody>
                                {rowsStock.map((row) => (
                                  <Material.TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Material.TableCell
                                      component="th"
                                      scope="row"
                                    >
                                      {row.name}
                                    </Material.TableCell>
                                    <Material.TableCell align="right">
                                      {row.calories}
                                    </Material.TableCell>
                                  </Material.TableRow>
                                ))}
                              </Material.TableBody>
                            </Material.Table>
                          </Material.TableContainer>
                        </Material.Box> */}
                        <Material.Paper>
                          <Box>
                            <TableContainer component={Material.Paper} elevation={2}>
                              <Table size="small" aria-label="a dense table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      colSpan={12}
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Stock Details
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>
                                      S.no
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>
                                      Capacity
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Material
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Empty
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Filled
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      OnGoing
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Physically Damaged
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Qr Bar Code Damage
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {props.updateUserDetails?.assignedWfsStock?.map((row, index) => (
                                    <TableRow
                                      key={row.name}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {index + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        {" "}
                                        {row.container_capacity}
                                      </TableCell>
                                      <TableCell align="left">
                                        {" "}
                                        {row.container_material}
                                      </TableCell>
                                      <TableCell align="center">
                                        {" "}
                                        {row.empty_containers}
                                      </TableCell>
                                      <TableCell align="center">
                                        {" "}
                                        {row.filled_containers}
                                      </TableCell>
                                      <TableCell align="center">
                                        {" "}
                                        {row.ongoing_containers}
                                      </TableCell>
                                      <TableCell align="center">
                                        {" "}
                                        {row.physically_damaged_containers}
                                      </TableCell>
                                      <TableCell align="center">
                                        {" "}
                                        {row.qr_bar_code_damaged_containers}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Material.Paper>
                      </Material.Box>
                    )}
                    {switchWFSDetails === false ? (
                      ""
                    ) : (
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "10px",
                            padding: "10px",
                          }}
                        >
                          Address
                        </Material.Typography>

                        <Material.TableContainer component={Material.Paper}>
                          <Material.Table aria-label="simple table">
                            <Material.TableBody>
                              {wfsInchargeAddress.map((row) => (
                                <Material.TableRow
                                  key={row.title}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Material.TableCell
                                    component="th"
                                    scope="row"
                                  >
                                    {row.title}
                                  </Material.TableCell>
                                  <Material.TableCell align="right">
                                    {row.data}
                                  </Material.TableCell>
                                </Material.TableRow>
                              ))}
                            </Material.TableBody>
                          </Material.Table>
                        </Material.TableContainer>
                      </Material.Box>
                    )}
                  </Material.Box>
                ) : (
                  ""
                )}
                <Material.Box>
                  <Material.Box>
                    <Material.Typography sx={{ fontWeight: "bold" }}>
                      LINE DETAILS
                    </Material.Typography>
                  </Material.Box>
                  <WfsLineDetails wfsId={wfsId} userId={props.userId} />
                </Material.Box>
              </Material.Paper>
            </Material.Box>
            {/* <Material.Box sx={{ mb: 1, ml: 1, mr: 1 }}>
              <Material.Paper sx={{ borderRadius: "10px" }}></Material.Paper>
            </Material.Box> */}
            {/* <Material.Box sx={{ mb: 1, mr: 1 }}>
              <Material.Paper
                sx={{ ml: 1, borderRadius: "10px" }}
              ></Material.Paper>
            </Material.Box> */}
          </Material.Box>
        </Material.Grid>
      </Material.Grid>
      {openPlaceOrderToWFSDialog && (
        <WfsAdminEmptyCanOrderDialog
          open={openPlaceOrderToWFSDialog}
          onClose={handleClosePlaceOrder}
          userId={props.userId}
        />
      )}
      {deleteUserDialog && (
        <DeleteUserDialog
          deleteUserDialog={deleteUserDialog}
          handleDelete={handleDeleteUserDialog}
          handleClose={handleCloseDeleteUserDialog}
        />
      )}
    </Material.Box>
  );
}

export default WFSIncharge;
