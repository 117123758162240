import React, { useRef } from "react";
import * as Material from "@mui/material";
import * as MuiIcon from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CustomConsole from "../../CustomConsole";
import {
  GetOrderIdDetails,
  GetWFSDetails,
} from "../Redux/Actions/adminEmptyCanOrderAction";
import { useDispatch, useSelector } from "react-redux";

import SupplierStartSubmissionDialog from "../../Components/DialoBoxes/SupplierStartSubmissionDialog";
import { PutSupplierOngoingStartSubmission } from "../Redux/Actions/supliersAction";
import * as MuiIcons from "@mui/icons-material";
import EditEmptyCanOrdersDialog from "../wfsCanOrders/EditEmptyCanOrdersDialog";
import { PutEditEmptyCanOrder } from "../Redux/Actions/wfsOrdersAction";
import {
  GetWfsDetailsSku,
  GetWfsEmptyCanDetailsSku,
  WfsEmptyCanAcceptOrdersSku,
  WfsEmptyCanRejectOrdersSku,
} from "../Redux/Actions/skuWfsDetailsAction";
import moment from "moment";
import EmptyCanOrderEdit from "../../Components/DialogBoxes/emptyCanEditOrder";
import AssignDeliveryAgentDialog from "../../Components/Dialog/AssignDeliveryAgentDialog";
import AssignWareHouseEmptyCanDialog from "../../Components/Dialog/AssignWareHouseEmptyCan";

function SupplierWfsSingleOrderDetailsDialog(props) {
  CustomConsole(props);
  const dispatch = useDispatch();

  //   const props.singleIdData = useSelector(
  //     (state) => state.skuWfsDetails.emptyCanDetails[0]
  //   );

  const [orderDetails, setOrderDetails] = React.useState([]);
  const [wfsTable, setWFSTable] = React.useState(false);
  const [wareHouseDetails, setWareHouseDetails] = React.useState([]);
  const [assingedWfsDetails, setAssingedWfsDetails] = React.useState([]);
  const [orderedUserDetails, setOrderedUserDetails] = React.useState([]);
  const [createdUserDetails, setCreatedUserDetails] = React.useState([]);
  const [updatedUserDetails, setUpdatedUserDetails] = React.useState([]);
  const [openEditEmptyCanOrderDialog, setOpenEditEmptyCanOrderDialog] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [openStartSubmissionDialog, setOpenStartSubmissionDialog] =
    React.useState(false);
  const [assignAgentOpen, setAssignAgentOpen] = React.useState(false);
  const [editAddressOpen, setEditAddressOpen] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");

  React.useEffect(() => {
    if (props.singleIdData?.ec_order_id !== "") {
      let data = [
        { type: "EC Order Id", payload: props.singleIdData?.ec_order_id },
        {
          type: "Otp",
          payload: props.singleIdData?.otp,
        },
        {
          type: "Material",
          payload:
            props.singleIdData?.order_details !== undefined &&
            props.singleIdData?.order_details !== null
              ? props.singleIdData?.order_details?.container_material
              : 0,
        },
        {
          type: "Capacity",
          payload:
            props.singleIdData?.order_details !== undefined &&
            props.singleIdData?.order_details !== null
              ? props.singleIdData?.order_details?.container_capacity
              : 0,
        },
        {
          type: "Order Qty",
          payload:
            props.singleIdData?.order_details !== undefined &&
            props.singleIdData?.order_details !== null
              ? props.singleIdData?.order_details?.order_qty
              : 0,
        },
        {
          type: "Return Oty",
          payload:
            props.singleIdData?.order_details !== undefined &&
            props.singleIdData?.order_details !== null
              ? props.singleIdData?.order_details.return_qty
              : 0,
        },
        {
          type: "Damged Qty",
          payload:
            props.singleIdData?.order_details !== undefined &&
            props.singleIdData?.order_details !== null
              ? props.singleIdData?.order_details.damaged_qty
              : 0,
        },
        {
          type: "Expired Qty",
          payload:
            props.singleIdData?.order_details !== undefined &&
            props.singleIdData?.order_details !== null
              ? props.singleIdData?.order_details.expired_qty
              : 0,
        },
        {
          type: "Status",
          payload: props.singleIdData?.order_status,
        },
        { type: "Created At", payload: props.singleIdData?.created_at },
        {
          type: "Updated At",
          payload: props.singleIdData?.updated_at,
        },
      ];
      setOrderDetails(data);
      let WareHousedata = [
        {
          type: "InCharge Name",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_incharge_name
              : "-",
        },
        {
          type: "InCharge Phone",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_incharge_phone
              : "-",
        },
        {
          type: "Status",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_active_status
              : "-",
        },
        {
          type: "Address Line 1",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_address_line1
              : "-",
        },
        {
          type: "Address Line 2",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_address_line2
              : "-",
        },
        {
          type: "Address Line 3",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_address_line3
              : "-",
        },
        {
          type: "Service Area",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_service_area
              : "-",
        },
        {
          type: "Pincode",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_pincode
              : "-",
        },
        {
          type: "Country",
          payload:
            props.singleIdData?.warehouse_details !== undefined &&
            props.singleIdData?.warehouse_details !== null
              ? props.singleIdData?.warehouse_details?.warehouse_country
              : "-",
        },
      ];
      setWareHouseDetails(WareHousedata);
      let AssignedWfsdata = [
        {
          type: "WFS Name",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_name
              : "-",
        },
        {
          type: "Owner Name",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_owner_name
              : "-",
        },
        {
          type: "Owner Phone",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_owner_phone
              : "-",
        },
        {
          type: "WFS Address",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_address?.District
              : "-",
        },
        {
          type: "WFS Pincode",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_address?.Pincode
              : "-",
        },
        {
          type: "WFS InCharge Name",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_incharge_name
              : "-",
        },
        {
          type: "WFS InCharge Phone",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_incharge_phone
              : "-",
        },
        {
          type: "WFS InCharge Email",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.wfs_incharge_email
              : "-",
        },
        {
          type: "WFS I_Active",
          payload:
            props.singleIdData?.assigned_wfs_details !== undefined &&
            props.singleIdData?.assigned_wfs_details !== null
              ? props.singleIdData?.assigned_wfs_details?.is_active
              : "-",
        },
      ];
      setAssingedWfsDetails(AssignedWfsdata);
    }
  }, [props.singleIdData]);
  const handleCloseWFSOrderDialog = () => {
    props.handleCloseWFSOrderDialog();
  };

  //   const handleOkDialog = () => {
  //     CustomConsole("-----handleOkDialog-----------");
  //     setOpenStartSubmissionDialog(false);
  //     setTimeout(
  //       () => dispatch(GetOrderIdDetails(props.singleIdData?.ec_order_id)),
  //       1000
  //     );
  //   };
  //   const handleStart = () => {
  //     CustomConsole("----------Start Submission-----------");
  //     setOpenStartSubmissionDialog(true);
  //     dispatch(
  //       PutSupplierOngoingStartSubmission({
  //         order_id: props.singleIdData?.ec_order_id,
  //       })
  //     );
  //   };

  const handleEditEmptyCan = () => {
    setOpenEditEmptyCanOrderDialog(true);
  };
  const handleClose = () => {
    setOpenEditEmptyCanOrderDialog(false);
  };
  //   const handleSubmitCans = (props) => {
  //     CustomConsole("--------handleSubmitCans-------------");
  //     const reqObj = {
  //       order_id: props.ec_order_id,
  //       order_qty: props.orderQty + props.emptyCanQty,
  //       empty_qty: props.emptyCanQty,
  //     };
  //     CustomConsole(reqObj);
  //     dispatch(PutEditEmptyCanOrder(reqObj));
  //     setOpenEditEmptyCanOrderDialog(false);
  //     setTimeout(
  //       () => dispatch(GetOrderIdDetails(props.singleIdData?.ec_order_id)),
  //       1000
  //     );
  //   };

  //useEffect for refreshing data
  //   React.useEffect(() => {
  //     if (!skuWfsDetails.acceptError) {
  //       dispatch(
  //         GetWfsEmptyCanDetailsSku({
  //           ec_order_id: props.singleIdData?.ec_order_id,
  //         })
  //       );
  //       skuWfsDetails.acceptError = true;
  //     }
  //     if (!skuWfsDetails.rejectError) {
  //       dispatch(
  //         GetWfsEmptyCanDetailsSku({
  //           ec_order_id: props.singleIdData?.ec_order_id,
  //         })
  //       );
  //       skuWfsDetails.rejectError = true;
  //     }
  //     if (!skuWfsDetails.assignAgentError) {
  //       dispatch(
  //         GetWfsEmptyCanDetailsSku({
  //           ec_order_id: props.singleIdData?.ec_order_id,
  //         })
  //       );
  //       skuWfsDetails.assignAgentError = true;
  //     }
  //   }, [
  //     skuWfsDetails.acceptError,
  //     skuWfsDetails.rejectError,
  //     skuWfsDetails.assignAgentError,
  //   ]);

  //function for opening assign delivery agent dialog
  const handelAssignDeliveryAgent = () => {
    setAssignAgentOpen(!assignAgentOpen);
  };

  //function for opening assign Warehouse dialog
  const handelSetEditWareHouseOpen = () => {
    setEditAddressOpen(!editAddressOpen);
  };

  const handleEditEmptyCanDetails = (event) => {
    // event.stopPropagation();
    props.handleEditEmptyCanDetails();
    // props.setOpenEditEmptyCanOrderDialog(true)
  };
  const handleStart = (event) => {
    // event.stopPropagation();
    props.handleStart();
  };

  return (
    <Material.Dialog
      fullScreen
      open={props.openSupplierWFSOrdersDialog}
      onClose={handleCloseWFSOrderDialog}
    >
      <Material.AppBar sx={{ position: "relative" }}>
        <Material.Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseWFSOrderDialog}
          >
            <CloseIcon />
          </IconButton>
          <Material.Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
          >
            {props.singleIdData?.ec_order_id}
          </Material.Typography>
          <Material.Button
            autoFocus
            color="inherit"
            onClick={handleCloseWFSOrderDialog}
          >
            Close
          </Material.Button>
        </Material.Toolbar>
      </Material.AppBar>
      <Material.DialogContent>
        <Material.Grid container spacing={2}>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  ORDER DETAILS
                </Material.Typography>
              </Material.Box>
              {orderDetails?.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  WAREHOUSE DETAILS
                </Material.Typography>
              </Material.Box>
              {wareHouseDetails?.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  ASSIGNED WFS DETAILS
                </Material.Typography>
              </Material.Box>
              {assingedWfsDetails?.map((data) => {
                return (
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Material.Typography>
                    <Material.Typography>{data.payload}</Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Paper>
          </Material.Grid>
          <Material.Grid item md={4} xs={12}>
            <Material.Paper sx={{ padding: "10px" }}>
              <Material.Box sx={{ bgcolor: "#365A91" }}>
                <Material.Typography
                  sx={{ textAlign: "center", color: "white" }}
                >
                  CRATES DETAILS
                </Material.Typography>
              </Material.Box>
              {props.singleIdData?.order_details?.crates.length > 0 ? (
                <>
                  {props.singleIdData?.order_details?.crates?.map((data) => {
                    return (
                      <>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                            }}
                          >
                            Material
                          </Material.Typography>
                          <Material.Typography>
                            {data.container_material}
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                            }}
                          >
                            capacity
                          </Material.Typography>
                          <Material.Typography>
                            {data.container_capacity}
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                            }}
                          >
                            Order Qty
                          </Material.Typography>
                          <Material.Typography>
                            {data.order_qty}
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                            }}
                          >
                            Received Qty
                          </Material.Typography>
                          <Material.Typography>
                            {data.received_qty}
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                            }}
                          >
                            Submitted Qty
                          </Material.Typography>
                          <Material.Typography>
                            {data.submitted_qty}
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                            }}
                          >
                            Submitted Return Cans
                          </Material.Typography>
                          <Material.Typography>
                            {data.submitted_return_cans}
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                            }}
                          >
                            Return Container Submitting
                          </Material.Typography>
                          <Material.Typography>
                            {data.return_containers_submitting}
                          </Material.Typography>
                        </Material.Box>
                      </>
                    );
                  })}
                </>
              ) : (
                <Material.Box
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Material.Typography sx={{ color: "red" }}>
                    No Crates Found
                  </Material.Typography>
                </Material.Box>
              )}
            </Material.Paper>
          </Material.Grid>
        </Material.Grid>
        <Material.Grid item md={4} xs={12}>
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Material.Box>
              {props.singleIdData?.ec_order_status ===
              props.ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED ? (
                <Material.Box sx={{ marginTop: "10px" }}>
                  {props.singleIdData?.order_details?.is_submit_finished ===
                    false &&
                  props.singleIdData?.order_details?.is_submit_started ===
                    false ? (
                    <>
                      <Material.Button
                        onClick={handleStart}
                        variant="contained"
                        sx={{
                          borderTopRightRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      >
                        start Submission
                      </Material.Button>
                    </>
                  ) : (
                    <>
                      {/* {props.data ? ( */}
                      <Material.Box>
                        <Material.Typography
                          sx={{ fontWeight: "bold", color: "red", mt: "17px" }}
                        >
                          ORDER STARTED
                        </Material.Typography>
                      </Material.Box>
                      {/* ) : (
                    ""
                  )} */}
                    </>
                  )}
                </Material.Box>
              ) : (
                ""
              )}
            </Material.Box>
            <Material.Box sx={{ mt: 1.5 }}>
              <Material.Button
                variant="contained"
                size="small"
                onClick={handleEditEmptyCanDetails}
              >
                <MuiIcons.BorderColor /> Edit
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.Grid>
        {/* <SupplierStartSubmissionDialog
          openStartSubmissionDialog={openStartSubmissionDialog}
          handleClose={handleOkDialog}
          // storeStartSubmissionData={storeStartSubmissionData}
        /> */}
        {/* {openEditEmptyCanOrderDialog && (
          <EmptyCanOrderEdit
            open={openEditEmptyCanOrderDialog}
            editDetails={props.singleIdData}
            onClose={handleClose}
          />
        )} */}
        {/* {assignAgentOpen && (
          <AssignDeliveryAgentDialog
            open={assignAgentOpen}
            setOpen={setAssignAgentOpen}
            selectedData={props.singleIdData}
          />
        )} */}
        {/* {editAddressOpen && (
          <AssignWareHouseEmptyCanDialog
            open={editAddressOpen}
            setOpen={handelSetEditWareHouseOpen}
            selectedData={props.singleIdData}
          />
        )} */}
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default SupplierWfsSingleOrderDetailsDialog;
