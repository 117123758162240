/**
 * @author Tahir Shaik
 * @description All Marketing and Gamification related UI implementations will be in this page
 * @copyright  @2023 Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 16-09-2023
 */
// Importing the Libraries and Other Files
import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import CustomConsole from "../../CustomConsole";
import { toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import CampaignDetails from "./CampaignDetails";
import CampaignApprovals from "./CampaignApprovals";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCampDetails,
  PlayedGames,
} from "../Redux/Actions/gamificationActions";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import actionType from "../Redux/Actions/actionType";
import CustomLoaderDialog from "../../Components/Dialog/CustomLoaderDialog";
import CampaignDetailsFormView from "./CampaignDetailsFormView";
import CampDetailsTableView from "./CampDetailsTableView";
function MarketingMain() {
  const dispatch = useDispatch();
  const gamificationStore = useSelector((state) => state.gamificationReducer);

  const [campStartDate, setCampStartDate] = React.useState(() =>
    moment().format("YYYY-MM-DD")
  );
  const [campEndDate, setCampEndDate] = React.useState(() =>
    moment().format("YYYY-MM-DD")
  );
  const [salesRepEmail, setSalesRepEmail] = React.useState("");
  const [tabValue, setTabValue] = React.useState("1");
  const [pageSize, setPageSize] = React.useState(10);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [approvalStatus, setApprovalStatus] = React.useState("100");
  const [campDetailsView, setCampDetailsView] = React.useState("table");
  const [adminRole, setadminRole] = React.useState("");
  // const [campDetailsView, setCampDetailsView] = React.useState(true);
  const [openProgressBar, setOpenProgressBar] = React.useState(false);
  //TO always keep a track on the Logged-In User Role
  React.useEffect(() => {
    const rolesAdminlostcans = parseInt(
      window.localStorage.getItem("roleId"),
      10
    );
    if (rolesAdminlostcans) {
      setadminRole(rolesAdminlostcans);
    }
  });
  //On un-Mounting the Component clear the Store
  React.useEffect(() => {
    // Function to cleanup the Store
    const cleanup = () => {
      CustomConsole("-- Cleanup function ----");
      dispatch({
        type: actionType.GAME_APPROVE_REJECT,
        payload: { error: true, msg: "", data: [] },
      });
      dispatch({
        type: actionType.GET_CAMP_DETAILS,
        payload: { error: true, msg: "", data: [] },
      });
      dispatch({
        type: actionType.PLAYED_GAME_DETAILS,
        payload: { error: true, msg: "", data: [] },
      });
      dispatch({
        type: actionType.PLAY_A_GAME,
        payload: { error: true, msg: "", data: [] },
      });

      dispatch({
        type: actionType.GET_USER_WITH_REFERRAL,
        payload: { error: true, msg: "", data: [] },
      });
    };
    return () => {
      CustomConsole("-- cleanup function in return --");
      cleanup();
    };
  }, []);
  React.useEffect(() => {
    CustomConsole(openProgressBar);
  }, [openProgressBar]);
  React.useEffect(() => {
    if (!gamificationStore.error) {
      CustomConsole(gamificationStore.campDetails);
      CustomConsole(gamificationStore.playedGames);
      CustomConsole(gamificationStore.playAGame);
      CustomConsole(gamificationStore.userDetails);
      CustomConsole(gamificationStore.approvalStatu);
      setTimeout(() => {
        setOpenProgressBar(false);
      }, 500);
      //If use Rejects/Approved and data changed then Refresh the Data and clear the Store flag
      if (gamificationStore.approvalStatus) {
        getPlayedGamedAndCampDetails();
        dispatch({
          type: actionType.GAME_APPROVE_REJECT,
          payload: { error: true, msg: "", data: [] },
        });
      }
    } else {
      setTimeout(() => {
        setOpenProgressBar(false);
      }, 500);
    }
  }, [gamificationStore]);

  React.useEffect(() => {
    CustomConsole("pageSize: " + pageSize);
    CustomConsole("pageNumber: " + pageNumber);
    CustomConsole("tabValue:" + tabValue);
    CustomConsole("approvalStatus: " + approvalStatus);
    getPlayedGamedAndCampDetails();
    if (tabValue === "2") {
      setCampDetailsView("table");
    } else {
      setCampDetailsView("table");
    }
  }, [pageSize, pageNumber, tabValue, approvalStatus]);

  // React.useEffect(() => {
  //   CustomConsole("--on component mount --");
  //   getPlayedGamedAndCampDetails();
  // }, []);
  //Get the Campagin & PlayedGames Details based on the user Selection
  const getPlayedGamedAndCampDetails = () => {
    let is_valid = true;
    let reqObj = {
      fromDate: campStartDate,
      toDate: campEndDate,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    if (
      adminRole !== ADMIN_ROLES.BW_SALES &&
      adminRole !== ADMIN_ROLES.BW_SALESHEAD &&
      adminRole !== ADMIN_ROLES.BW_PROMOTER
    ) {
      if (!salesRepEmail.length) {
        is_valid = false;
      } else {
        if (tabValue === "1") {
          reqObj.email = salesRepEmail + "@bookwater.com";
        } else if (tabValue === "2") {
          reqObj.salesRepEmail = salesRepEmail + "@bookwater.com";
        }
      }
    }
    if (is_valid) {
      if (tabValue === "1") {
        setOpenProgressBar(true);
        reqObj.email = reqObj.email ? reqObj.email : "";
        reqObj.promoter_email = "";
        reqObj.salesRepEmail = "";
        reqObj.sort = 1;
        CustomConsole("--- Campaign Details API ---");
        dispatch(GetCampDetails(reqObj));
      } else if (tabValue === "2") {
        setOpenProgressBar(true);
        CustomConsole("--- Approval GameAction API ---");
        reqObj.rewardStatus = approvalStatus;
        dispatch(PlayedGames(reqObj));
      }
    }
  };
  const handleCampaignDetailsClick = () => {
    getPlayedGamedAndCampDetails();
  };
  return (
    <Material.Box sx={{ marginTop: "60px" }}>
      <Material.Paper
        elevation={12}
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-around",
          gap: "10px",
        }}
      >
        {adminRole !== ADMIN_ROLES.BW_SALES &&
          adminRole !== ADMIN_ROLES.BW_SALESHEAD &&
          adminRole !== ADMIN_ROLES.BW_PROMOTER && (
            <Material.FormControl variant="standard">
              <Material.InputLabel htmlFor="input-with-icon-adornment">
                Sales Representative Email
              </Material.InputLabel>
              <Material.Input
                sx={{ maxWidth: "250px", overflow: "auto" }}
                value={salesRepEmail}
                onChange={(event) => setSalesRepEmail(event.target.value)}
                startAdornment={
                  <Material.InputAdornment position="start">
                    <MuiIcons.AttachEmailOutlined />
                  </Material.InputAdornment>
                }
                endAdornment={
                  <Material.InputAdornment position="end">
                    @bookwater.com
                  </Material.InputAdornment>
                }
              />
            </Material.FormControl>
          )}
        {adminRole !== ADMIN_ROLES.BW_PROMOTER && (
          <>
            <Material.FormControl variant="standard">
              <Material.InputLabel htmlFor="input-with-icon-adornment">
                Campaigns end from
              </Material.InputLabel>
              <Material.Input
                value={campStartDate}
                onChange={(event) => setCampStartDate(event.target.value)}
                type="date"
                startAdornment={<Material.InputAdornment position="start" />}
              />
            </Material.FormControl>
            <Material.FormControl variant="standard">
              <Material.InputLabel htmlFor="input-with-icon-adornment">
                Campaigns end till
              </Material.InputLabel>
              <Material.Input
                value={campEndDate}
                onChange={(event) => setCampEndDate(event.target.value)}
                type="date"
                startAdornment={<Material.InputAdornment position="start" />}
              />
            </Material.FormControl>
          </>
        )}

        <Material.Button
          variant="contained"
          sx={{
            backgroundImage: "linear-gradient(#021636,#0c4aad)",
            "&:hover": {
              backgroundImage: "linear-gradient(#6b8df2,#021636)",
            },
          }}
          startIcon={<MuiIcons.DatasetOutlined />}
          onClick={handleCampaignDetailsClick}
        >
          Get Campaigns
        </Material.Button>
      </Material.Paper>

      <Material.Paper elevation={12} sx={{ marginTop: "20px" }}>
        <TabContext value={tabValue}>
          <Material.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(event, newValue) => setTabValue(newValue)}
              centered
            >
              <Material.Tab
                icon={<MuiIcons.AutorenewOutlined />}
                label="Current Campaigns"
                value="1"
              />
              <Material.Tab
                icon={<MuiIcons.ApprovalOutlined />}
                label="Approvals"
                value="2"
              />
            </TabList>
          </Material.Box>
          <Material.Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              gap: "10px",
            }}
          >
            <Material.Box
              sx={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  },
                  alignItems: "center",
                }}
              >
                <Material.InputLabel
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  View:
                </Material.InputLabel>
                <Material.Select
                  variant="standard"
                  sx={{ fontSize: "12px", fontWeight: "bold", width: "80px" }}
                  value={campDetailsView}
                  label="Ui View"
                  onChange={(event) => setCampDetailsView(event.target.value)}
                >
                  {tabValue === "1" && (
                    <Material.MenuItem value="form">Form</Material.MenuItem>
                  )}
                  <Material.MenuItem value="table">Table</Material.MenuItem>
                  <Material.MenuItem value="card">Card</Material.MenuItem>
                </Material.Select>
              </Material.Box>
              {tabValue === "2" ? (
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                      lg: "row",
                    },
                    alignItems: "center",
                  }}
                >
                  <Material.InputLabel
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Approval Status:
                  </Material.InputLabel>
                  <Material.Select
                    variant="standard"
                    sx={{ fontSize: "12px", fontWeight: "bold", width: "80px" }}
                    value={approvalStatus}
                    label="Approval Status"
                    onChange={(event) => setApprovalStatus(event.target.value)}
                  >
                    <Material.MenuItem value="100">Pending</Material.MenuItem>
                    <Material.MenuItem value="200">Approved</Material.MenuItem>
                    <Material.MenuItem value="300">Rejected</Material.MenuItem>
                  </Material.Select>
                </Material.Box>
              ) : (
                ""
              )}
            </Material.Box>
            <Material.Box
              sx={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  },
                  alignItems: "center",
                }}
              >
                <Material.InputLabel
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Page Size:
                </Material.InputLabel>
                <Material.Select
                  variant="standard"
                  sx={{ fontSize: "12px", fontWeight: "bold", width: "80px" }}
                  value={pageSize}
                  label="Page Size"
                  onChange={(event) => setPageSize(event.target.value)}
                >
                  <Material.MenuItem value="10">10</Material.MenuItem>
                  <Material.MenuItem value="25">25</Material.MenuItem>
                  <Material.MenuItem value="50">50</Material.MenuItem>
                </Material.Select>
              </Material.Box>
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Material.IconButton
                  onClick={() => {
                    if (pageNumber - 1 > 0) {
                      setPageNumber(() => pageNumber - 1);
                    }
                  }}
                >
                  <MuiIcons.FirstPage
                    sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                  />
                </Material.IconButton>
                <Material.Typography
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {pageNumber}
                </Material.Typography>
                <Material.IconButton
                  onClick={() => {
                    if (
                      (tabValue === "1" &&
                        gamificationStore.campDetails.length) ||
                      (tabValue === "2" && gamificationStore.playedGames.length)
                    ) {
                      setPageNumber(() => pageNumber + 1);
                    }
                  }}
                >
                  <MuiIcons.LastPage
                    sx={{
                      color:
                        (tabValue === "1" &&
                          gamificationStore.campDetails.length) ||
                        (tabValue === "2" &&
                          gamificationStore.playedGames.length)
                          ? "blue"
                          : "gray",
                    }}
                  />
                </Material.IconButton>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box sx={{ maxHeight: "650px", overflow: "auto" }}>
            <TabPanel value="1">
              <Material.Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                {gamificationStore.campDetails.length ? (
                  campDetailsView === "form" ? (
                    <CampaignDetailsFormView
                      setOpenProgressBar={setOpenProgressBar}
                      salesRep={salesRepEmail}
                      data={gamificationStore.campDetails}
                    />
                  ) : campDetailsView === "card" ? (
                    gamificationStore.campDetails.map(
                      (campaignDetails, index) => {
                        return (
                          <CampaignDetails
                            setOpenProgressBar={setOpenProgressBar}
                            key={index}
                            salesRep={salesRepEmail}
                            indexValue={index}
                            data={campaignDetails}
                          />
                        );
                      }
                    )
                  ) : (
                    <CampDetailsTableView
                      setOpenProgressBar={setOpenProgressBar}
                      salesRep={salesRepEmail}
                      data={gamificationStore.campDetails}
                    />
                    // <Material.Typography>
                    //   Table View is in progress
                    // </Material.Typography>
                  )
                ) : (
                  <Material.Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#ed4771",
                    }}
                  >
                    Data not found!
                  </Material.Typography>
                )}
              </Material.Box>
            </TabPanel>
            <TabPanel value="2">
              {gamificationStore.playedGames.length ? (
                <CampaignApprovals
                  uiView={campDetailsView}
                  setOpenProgressBar={setOpenProgressBar}
                  data={gamificationStore.playedGames}
                />
              ) : (
                <Material.Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#ed4771",
                  }}
                >
                  Data not found!
                </Material.Typography>
              )}
            </TabPanel>
          </Material.Box>
        </TabContext>
      </Material.Paper>
      {/* {openProgressBar && (
        <CustomLoaderDialog
          open={openProgressBar}
          handleClose={() => setOpenProgressBar(false)}
        />
      )} */}
    </Material.Box>
  );
}

export default MarketingMain;
