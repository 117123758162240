import Button from "@mui/material/Button";
import * as Material from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import CustomConsole from "../../CustomConsole";
import { Box } from "@mui/system";
import * as React from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { GetWithrawalDetailsAccept } from "../Redux/Actions/AdminWithdrawalListAction";

export default function DistributorDetailsDialog(props) {
  CustomConsole(props);
  const dispatch = useDispatch();
  const [changeMinusBtn, setChangeMinusBtn] = React.useState(false);
  const [changePlusBtn, setChangePlusBtn] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState(false);
  const [pageChange, setPageChange] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);

  React.useEffect(() => {
    if (pageNumber) {
      dispatch(
        GetWithrawalDetailsAccept(props.returnCanOrderId, pageNumber - 1)
      );
      setChangeMinusBtn(true);
      setChangePlusBtn(true);
    }
  }, [pageNumber]);

  React.useEffect(() => {
    CustomConsole(props.distDetails);
    if (props.distDetails.length > 0) {
      setPageStatus(false);
    } else {
      setPageStatus(true);
    }
  }, [props.distDetails]);
  React.useEffect(() => {
    CustomConsole(props.distDetails);
    props.distDetails.map((data) => {
      CustomConsole(data.company_name);
    });
  }, []);
  const [supplierId, setSupplierId] = React.useState("");
  const handelClose = () => {
    props.setDistributorDetailsFlag(false);
  };
  const handleChange = (event) => {
    // props.ClickDistributor(orderId)
    setSupplierId(event.target.value);
    //  CustomConsole(orderId)
    CustomConsole("------dis-----");
  };

  const clickAssign = (supplierId) => {
    if (supplierId !== "") {
      props.handleAssignSupplier({
        supplierId: supplierId,
        orderStatus: props.singleData.order_status,
      });
    } else {
      if (props.singleData.is_consumer === true) {
        toast.error("Please Select Supplier", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Please Select Water Filling Station", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const handleChangeMinus = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setChangeMinusBtn(true);
  };

  const handleChangePlus = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
    setPageStatus(true);
  };

  return (
    <div>
      <Dialog
        open={props.distributorDetailsFlag}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
              <Material.Button onClick={handleChangeMinus}>
                <ArrowBackIos />
              </Material.Button>
              <Material.Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Material.Typography>
              <Material.Button disabled={pageStatus} onClick={handleChangePlus}>
                <ArrowForwardIos />
              </Material.Button>
            </Box>

            <Box>
              <Box sx={{ maxWidth: "730px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {props.isConsumer === false ? "WFS List" : "Supplier List"}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={supplierId}
                    label={
                      props.isConsumer === false ? "WFS List" : "Supplier List"
                    }
                    onChange={handleChange}
                  >
                    {props.distDetails
                      ? props.distDetails.map((data) => {
                          return (
                            <MenuItem
                              value={
                                props.isConsumer === false
                                  ? data.wfs_master_id
                                  : data.user_id
                              }
                            >
                              {data.company_name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <TableContainer
                  component={Paper}
                  elevation={24}
                  sx={{ maxWidth: "750px", marginTop: "20px" }}
                >
                  <Table sx={{ maxWidth: "730px" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: "#2979ff" }}>
                          Order ID
                        </TableCell>
                        <TableCell sx={{ color: "#2979ff" }}>
                          Container Qty
                        </TableCell>
                        <TableCell align="right" sx={{ color: "#2979ff" }}>
                          Withdawal Qty
                        </TableCell>
                        <TableCell align="right" sx={{ color: "#2979ff" }}>
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.distDialogDetailsData
                        ? props.distDialogDetailsData.map((data) => (
                            <TableRow
                              key={data.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                marginTop: 20,
                                cursor: "pointer",
                                "&:hover": {
                                  boxShadow: "none",
                                  backgroundColor: "#2196f3",
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                {data.order_id}
                              </TableCell>
                              <TableCell>{data.container_qty}</TableCell>
                              <TableCell>{data.withdrawn_can_qty}</TableCell>
                              <TableCell>{data.amount}</TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => clickAssign(supplierId)}
            variant="contained"
            disableElevation
          >
            ASSIGN
          </Button>
          <Button
            onClick={handelClose}
            variant="contained"
            disableElevation
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
