import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";

function WFSFilterDialog(props) {
  return (
    <Material.Dialog open={props.openFilterWFSDialog}>
      <Material.DialogTitle>Filter Based On Options</Material.DialogTitle>
      <Material.DialogContent>
        <Material.Box sx={{ display: "flex", flexDirection: "column" }}>
          <Material.FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
            <Material.InputLabel>Sort Orders</Material.InputLabel>
            <Material.Select
              label="Sort Orders"
              //   value={status}
              //   onChange={(event) => setStatus(event.target.value)}
            >
              <Material.MenuItem value={"ASC"}>ASC</Material.MenuItem>
              <Material.MenuItem value={"DESC"}>DESC</Material.MenuItem>
            
            </Material.Select>
          </Material.FormControl>
          <Material.TextField
            sx={{ m: 1 }}
            // value={orderId}
            // onChange={(event) => setOrderId(event.target.value)}
            label="User Phone"
          />
          <Material.TextField
            sx={{ m: 1 }}
            // value={orderId}
            // onChange={(event) => setOrderId(event.target.value)}
            label="WFS Id"
          />
          <Material.TextField
            sx={{ m: 1 }}
            // value={reqId}
            type="number"
            // onChange={handleChangeReqId}
            label="Address Id"
            inputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              //   onInput: handleChangeReqId, // Attach the input event handler
            }}
          />

          {/* <Material.TextField sx={{ m: 1 }} label="End Number" /> */}
          <Material.FormControl>
            <Material.InputLabel>Sort By Request Id</Material.InputLabel>
            <Material.Select
              label="Sort By Request Id"
              //   value={sortRequestId}
              //   onChange={(event) => setSortRequestId(event.target.value)}
            >
              <Material.MenuItem value={"ASC"}>ASC</Material.MenuItem>
              <Material.MenuItem value={"DESC"}>DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
          <Material.FormControl>
            <Material.InputLabel>Sort By Request Id</Material.InputLabel>
            <Material.Select
              label="Sort By Request Id"
              //   value={sortRequestId}
              //   onChange={(event) => setSortRequestId(event.target.value)}
            >
              <Material.MenuItem value={"ASC"}>ASC</Material.MenuItem>
              <Material.MenuItem value={"DESC"}>DESC</Material.MenuItem>
            </Material.Select>
          </Material.FormControl>
        </Material.Box>
      </Material.DialogContent>
      <Material.DialogActions>
        <Material.Button
          variant="contained"
          color="warning"
          //   onClick={handleClearFilter}
        >
          Clear all
        </Material.Button>
        <Material.Button
          variant="contained"
          color="error"
          //   onClick={handleCloseFilter}
        >
          close
        </Material.Button>
        <Material.Button
          variant="contained"
          // onClick={handleFilterCanSwap}
        >
          filter
        </Material.Button>
      </Material.DialogActions>
    </Material.Dialog>
  );
}

export default WFSFilterDialog;
