import React from "react";
import * as Material from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomConsole from "../../CustomConsole";

function TransfoerCansCreateOrderDialog(props) {
  CustomConsole(props);
  const [material, setMaterial] = React.useState("PET");
  const [capacity, setCapacity] = React.useState("20_Liters");
  const [totalContainerQty, setTotalContainerQty] = React.useState("");

  const handleCloseDialog = () => {
    CustomConsole("--------handleCloseDialog------");
    props.handleCloseDialog();
  };
  const handleCreateOrder = () => {
    CustomConsole("--------handleCreateOrder------");
    props.handleCreateOrder({
      container_capacity: capacity,
      container_material: material,
      total_container_qty: totalContainerQty,
      setTotalContainerQty:setTotalContainerQty
    });
  };
  return (
    <Material.Box>
      <Material.Dialog open={props.openDialogCreateOrder}>
        <Material.DialogContent>
          <Box sx={{ minWidth: 120, margin: "10px" }}>
            <FormControl fullWidth>
              <InputLabel>Capacity</InputLabel>
              <Select
                value={capacity}
                label="Capacity"
                onChange={(event) => setCapacity(event.target.value)}
              >
                <MenuItem value={"20_Liters"}>20 Liters</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, margin: "10px" }}>
            <FormControl fullWidth>
              <InputLabel>Material</InputLabel>
              <Select
                value={material}
                label="Material"
                onChange={(event) => setMaterial(event.target.value)}
              >
                <MenuItem value={"PET"}>PET</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Material.Box sx={{ margin: "10px" }}>
            <Material.TextField
              label="Total Container Quantity"
              value={totalContainerQty}
              onChange={(event) => setTotalContainerQty(event.target.value)}
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "10px",
            }}
          >
            <Material.Button
              onClick={handleCloseDialog}
              variant="contained"
              color="error"
            >
              Close
            </Material.Button>
            <Material.Button onClick={handleCreateOrder} variant="contained">
              Create Order
            </Material.Button>
          </Material.Box>
        </Material.DialogContent>
      </Material.Dialog>
    </Material.Box>
  );
}

export default TransfoerCansCreateOrderDialog;
