import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CustomConsole from "../../CustomConsole";
export default function OTPLessDeliveryConfirmationDialog({
  withoutOtpChecked,
  setWithoutOtpChecked,
  confirmationDialog,
  setConfirmationDialog,
  otpDisclaimerMsg,
}) {
  const handleClose = () => {
    setConfirmationDialog(false);
  };
  const handleAgree = () => {
    setConfirmationDialog(false);
  };
  const handleDisagree = () => {
    setConfirmationDialog(false);
    setWithoutOtpChecked(false);
  };
  return (
    <div>
      <Dialog
        open={confirmationDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to continue with No OTP Delivery?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree}>Take me back to safety</Button>
          <Button onClick={handleAgree} autoFocus>
            Yes, I understand the risks
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
