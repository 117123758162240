import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
export const styles = {
  LostCansStyleHeading: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "10px",
    gap: "10px",
    color: "#0a83c4",
    fontWeight: "bold"
  },
  LostCansStyleValue: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "10px",
    gap: "10px",
  },
  AppbarStyle: {
    width: '100%',
    bgcolor: 'background.paper',
    marginTop: { lg: "5em", sm: "4em" },
    backgroundColor: "white",
    display: "flex",
    flexDirection: { lg: "column", sm: "row" }
  },
  ToggleButton: {
    borderRadius: "10px",
    borderStyle: "solid",
    borderImage: "linear-gradient(to right, #7BB5FF, #2B65AF) 1;",
  },
  TableHeading: {
    fontWeight: "600",
    backgroundImage:appColors.commonLayoutColor.backgroundImage,
    color: "black",
  },
  CommonButton: {
    textTransform: "none",
    backgroundColor: "white",
    fontFamily: "'Montserrat', sans-serif",
    color: "white",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    // marginLeft: "10px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#29358f",
      color: "black",
    },
    background: "rgb(67,135,245)",
    background:
      "radial-gradient(circle, rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
  },
  CustomToggleButton: {
    backgroundImage:
      "radial-gradient(rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
    color: "black"
  },
  ButtonGroup: {
    backgroundImage:
      "radial-gradient(rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
  },
  FontSize:{
    fontSize:{lg:"16px",xs:"10px"}
  },
  
    ButtonMain:{
      textTransform: "none",
    backgroundColor: "white",
    fontFamily: "'Montserrat', sans-serif",
    color: "black",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    // marginLeft: "10px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#29358f",
      color: "black",
    },
   
    background:appColors.commonLayoutColor.backgroundImage,
     marginBottom:{lg:".1em"}
    },
    ButtonMain1:{
      textTransform: "none",
    backgroundColor: "white",
    fontFamily: "'Montserrat', sans-serif",
    color: "white",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    // marginLeft: "10px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#29358f",
      color: "black",
    },
    background:appColors.commonBtnColor.background,
      marginBottom:{lg:".1em"}
    },
    ButtonMain2:{
      textTransform: "none",
    backgroundColor: "white",
    fontFamily: "'Montserrat', sans-serif",
    color: "white",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    // marginLeft: "10px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#29358f",
      color: "black",
    },
  
    background:
    appColors.commonBtnColor.background,
    },
    

};
