import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialOrdersState = {
  error: true,
  msg: "",
  ongoingOrders: [],
  completedOrders: [],
  allOrders: [],
  verifyOtpMsg: "",
  returnCanOrders: [],
  returnVerifyOtpMsg: "",
  deliveryFileUploadStatus: true,
};

const wfsOrdersReducers = (state = initialOrdersState, action) => {
  // CustomConsole(action);
  switch (action.type) {
    case actionType.GET_WFS_ONGOING_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        ongoingOrders: action.payload.data,
      };
    case actionType.GET_WFS_COMPLETED_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        completedOrders: action.payload.data,
      };
    case actionType.GET_WFS_ALL_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        allOrders: action.payload.data,
      };
    case actionType.VERIFY_OTP:
      return {
        ...state,
        error: action.payload.error,
        verifyOtpMsg: action.payload.msg,
      };
    case actionType.GET_RETURN_CAN_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        returnCanOrders: action.payload.data,
      };
    case actionType.PUT_RETURN_CAN_VERIFY_OTP:
      return {
        ...state,
        error: action.payload.error,
        returnVerifyOtpMsg: action.payload.msg,
      };
    case actionType.EDIT_DELIVERY_CHALLAN:
      return {
        ...state,
        deliveryFileUploadStatus: action.payload.error,
      };
    case actionType.PUT_EDIT_WFS_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.COMPLETE_DISTRIBUTOR_RETURN_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default wfsOrdersReducers;
