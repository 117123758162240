/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Typography,
  Grid,
  FormControl,
  Box,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  NativeSelect,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Stack,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import TocIcon from "@mui/icons-material/Toc";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  GetAvailableDeliverySlots,
  GetSupplierOnGoingAssignedSlotAndAgent,
  GetSupplierPincodes,
  GetSupplierUnassigedSlotAgent,
  PostSupplierAssignDeliveryAgent,
} from "../../Redux/Actions/supliersAction";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
import DistributorLocation from "../../../Components/Maps/distributerOrderLocation";
import AssignDeliverAgentViaMap from "../../../Components/DialogBoxes/mapAssignAgent/mapAssignAgent";

const VehicleAssignOrders = ({
  vehicleCurrentStatus,
  setAssignByMap,
  assignByMap,
}) => {
  // const deliveries = useSelector(
  //   (state) =>
  //     state.supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
  // );
  const [deliveries, setDeliveries] = useState([]);
  // const deliverySlots = useSelector(
  //   (state) => state.supplierDeliveries.getAvailableDeliverySlots
  // );
  const [deliverySlots, setDeliverySlots] = useState([]);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [assignAgentDialogOpen, setAssignAgentDialogOpen] = useState(false);
  const [selectedPincode, setSelectedPincode] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [assigningSwitch, setAssigningSwitch] = useState(false);
  const [expandedSlot, setExpandedSlot] = useState();
  const [supplierPinCodes, setSupplierPincodes] = React.useState([]);
  const supplierPincodes = useSelector(
    (state) => state.supplierDeliveries.supplierPincodes
  );
  const [coordinates, setCoordinates] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [currentCoordinates, setCurrentCoordinates] = React.useState([]);
  const [pincodesArea, setPincodesArea] = React.useState([]);
  useEffect(() => {
    dispatch(GetAvailableDeliverySlots()).then((data) => {
      setSelectedSlotIndex(null);
      setDeliverySlots(data?.data || []);
    });
    dispatch(GetSupplierPincodes());
  }, [assigningSwitch]);
  React.useEffect(() => {
    if (supplierPincodes) {
      setSupplierPincodes(supplierPincodes);
    }
  }, [supplierPincodes]);
  const getDeliveries = () => {
    if (assigningSwitch) {
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          // page_number: pageNumber,
          // page_size: pageSize,
          page_number: 0,
          page_size: 100,
          pincode: selectedPincode?.pincode,
          area: selectedArea,
        })
      ).then((data) => {
        setDeliveries(data?.data || []);
      });
    } else {
      dispatch(
        GetSupplierUnassigedSlotAgent({
          is_agent: 4,
          page_number: 0,
          page_size: 100,
          pincode: selectedPincode?.pincode,
          area: selectedArea,
        })
      ).then((data) => {
        setDeliveries(data?.data || []);
      });
    }
  };
  useEffect(() => {
    getDeliveries();
  }, [assigningSwitch, selectedPincode, selectedArea, assignByMap]);
  const assignOrder = async (
    slectedOrder,
    orderDetails,
    preventRefresh = false
  ) => {
    if (
      ((!assigningSwitch && selectedSlotIndex === null) ||
        selectedSlotIndex === undefined ||
        selectedSlotIndex === "") &&
      !orderDetails?.packaged_drinking_water?.delivery_slots
        ?.admin_selected_delivery_slot &&
      !orderDetails?.packaged_drinking_water?.delivery_slots
        ?.consumer_selected_delivery_slot &&
      !orderDetails?.packaged_drinking_water?.delivery_slots
        ?.supplier_selected_delivery_slot
    ) {
      toast.error("Please select a Delivery Slot", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    let assigningTo = vehicleCurrentStatus?.drivers?.[0];

    if (assigningTo && slectedOrder) {
      const reqObj = {
        is_today: true,
        delivery_agent_id: assigningTo,
        delivery_id: slectedOrder,
        tanker_id: vehicleCurrentStatus.tanker_id,
      };
      if (!assigningSwitch) {
        let slot = deliverySlots[selectedSlotIndex];
        if (slot) {
          reqObj.delivery_slot_timings = `${slot.startTimeSlot}-${slot.endTimeSlot}`;
        }
      }
      dispatch(PostSupplierAssignDeliveryAgent(reqObj)).then((data) => {
        if (!preventRefresh) {
          getDeliveries();
          setExpandedSlot();
        }
        setAssignAgentDialogOpen(false);
      });
    }
  };

  return (
    <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Paper elevation={3} sx={{ p: 1, width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            {/* <Paper > */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                Loaded Items
              </Typography>
            </Box>
            <Grid container>
              <Grid xs={6} item sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>Product</Typography>
              </Grid>
              {/* <Grid xs={3} item sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Orders
                </Typography>
              </Grid> */}
              <Grid xs={3} item sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Loaded
                </Typography>
              </Grid>
              {vehicleCurrentStatus?.stock?.map((item) => {
                return (
                  <>
                    <Grid xs={6} item sx={{ display: "flex" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {item.capacity} {item.material} :
                      </Typography>
                    </Grid>

                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                      {item.filled}
                    </Grid>
                  </>
                );
              })}
            </Grid>
            {/* </Paper> */}
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            p: 1,
            flexWrap: "wrap",
            width: "100%",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Pincodes</InputLabel>
                <Select
                  value={selectedPincode}
                  fullWidth
                  onChange={(e) => {
                    setSelectedArea("");
                    if (e.target.value) {
                      setSelectedPincode(
                        supplierPinCodes[e.target.value] || {}
                      );
                    } else {
                      setSelectedPincode(null);
                    }
                  }}
                  label="Pincodes"
                  size="small"
                >
                  <MenuItem value={null}>
                    <em>All</em>
                  </MenuItem>
                  {supplierPinCodes?.map((obj, key) => {
                    return (
                      <MenuItem value={key} key={key}>
                        {obj.pincode}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth variant="outlined" size="medium">
                <InputLabel>Area</InputLabel>
                <Select
                  fullWidth
                  value={selectedArea}
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                  label="Area"
                  size="small"
                >
                  <MenuItem value={null}>
                    <em>All</em>
                  </MenuItem>
                  {selectedPincode?.areas?.map((item, key) => {
                    return (
                      <MenuItem value={item} key={key}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            {/* <Box sx={{ width: "100%" }}>
              <FormControl variant="outlined" size="small">
                <InputLabel htmlFor="outlined-adornment-password">
                  Order Id
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={"text"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label=" Order Id"
                />
              </FormControl>
            </Box> */}
          </Box>

          {/* hidden since not implemented */}
          {false ? (
            <Box sx={{ width: "100%" }}>
              <FormControl sx={{ minWidth: "40px" }} size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Filter by Products
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Filter by Products"
                  onChange={() => {}}
                  fullWidth
                >
                  <MenuItem value={10}>20 Liter PET</MenuItem>
                  <MenuItem value={20}>500 Ml Glass</MenuItem>
                  <MenuItem value={30}>300 ML Glass</MenuItem>
                </Select>
              </FormControl>
            </Box>
          ) : (
            <></>
          )}
        </Paper>
        <TableContainer elevation={18} component={Paper}>
          <Box sx={{ float: "right", display: "flex", mt: 1.5 }}>
            <Box sx={{ p: 1 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  sx={{
                    color: assigningSwitch === false ? "#30A2F3" : "black",
                  }}
                >
                  Assign
                </Typography>
                <AntSwitch
                  checked={assigningSwitch}
                  onChange={() => setAssigningSwitch((prev) => !prev)}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography
                  sx={{
                    color: assigningSwitch === true ? "#30A2F3" : "black",
                  }}
                >
                  re-Assign
                </Typography>
              </Stack>
            </Box>
            <Paper
              onClick={() => {
                if (selectedSlotIndex || assigningSwitch) {
                  setAssignByMap((prev) => !prev);
                } else {
                  toast.error("Please select a Delivery Slot", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              }}
              sx={{ p: 1 }}
            >
              <AddLocationAltIcon />
            </Paper>
          </Box>

          {/* <Box>
            <Paper
              onClick={() => setAssignByMap(false)}
              sx={{ float: "right", p: 1 }}
            >
              <TocIcon />
            </Paper>
          </Box> */}
          <Box sx={{ display: "flex", gap: 1 }}>
            <Paper sx={{ float: "right", p: 1 }}>
              <FormControl key={selectedSlotIndex} fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Slot to Assign
                </InputLabel>
                <NativeSelect
                  error={
                    selectedSlotIndex == null || selectedSlotIndex == undefined
                  }
                  required
                  key={selectedSlotIndex}
                  value={selectedSlotIndex}
                  inputProps={{
                    name: "Slot to Assign",
                    id: "uncontrolled-native",
                  }}
                  onChange={(e) => setSelectedSlotIndex(e.target.value)}
                >
                  {" "}
                  <option color="red">Select Slot</option>
                  {deliverySlots?.map((slot, index) => (
                    <option value={index}>
                      {slot?.startTimeSlot}-{slot?.endTimeSlot}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Paper>
          </Box>
          {/* {!assignByMap ? ( */}
          <TableContainer sx={{ maxHeight: "65vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: 0,
                      py: 1,
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid black",
                    }}
                  >
                    Order Id
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: 0,
                      py: 1,
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid black",
                    }}
                  >
                    Pincode
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: 0,
                      py: 1,
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid black",
                    }}
                  >
                    Product
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: 0,
                      py: 1,
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid black",
                    }}
                  >
                    Qty
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: 0,
                      py: 1,
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid black",
                    }}
                  >
                    Vehicle
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deliveries?.map((orderDetails, orderIndex) => {
                  let productsData = [];
                  if (!orderDetails?.packaged_drinking_water?.is_invalid) {
                    productsData.push({
                      capacity:
                        orderDetails?.packaged_drinking_water
                          ?.selected_container_capacity,
                      image: orderDetails?.packaged_drinking_water?.image,
                      name: orderDetails?.packaged_drinking_water?.name,
                      material:
                        orderDetails?.packaged_drinking_water
                          ?.selected_container_material,
                      is_qr: true,
                      order_qty:
                        orderDetails?.packaged_drinking_water
                          ?.twentyltr_capacity_qty,
                      return:
                        orderDetails?.packaged_drinking_water
                          ?.empty_container_qty,

                      sku_qty: 1,

                      order_price:
                        orderDetails?.packaged_drinking_water
                          ?.twenty_ltr_capacity_totamt,
                      isInScanCompleted:
                        orderDetails?.packaged_drinking_water
                          ?.is_inscan_completed,
                      isOutScanCompleted:
                        orderDetails?.packaged_drinking_water
                          ?.is_outscan_completed,
                    });
                  }
                  if (orderDetails?.products?.length > 0) {
                    for (const data of orderDetails?.products) {
                      productsData.push({
                        capacity: data.capacity,
                        material: data.material,
                        is_qr: data.is_qr,
                        order_qty: data.quantity,
                        name: data.name,
                        return: data.return,
                        image: data.image,
                        is_invalid: true,
                        received_empty: data.consumer_submitted_empty
                          ? data.consumer_submitted_empty
                          : 0,
                        submitted_filled: data.consumer_received_filled
                          ? data.consumer_received_filled
                          : 0,
                        sku_qty: data.sku_qty,
                        order_price: data.product_price,
                      });
                    }
                  }

                  return (
                    <>
                      {productsData?.map((product, productIndex) => {
                        return (
                          <StyledTableRow
                            key={productIndex}
                            data-badge-content={
                              orderDetails?.payment_type === 300
                                ? "P"
                                : orderDetails?.packaged_drinking_water
                                    .order_type === 700
                                ? "i"
                                : orderDetails?.packaged_drinking_water
                                    .is_subscribed_order === true
                                ? "S"
                                : "R"
                            }
                            sx={{
                              bgcolor:
                                orderDetails?.payment_type === 300
                                  ? "rgba(109, 20, 125, 0.4)"
                                  : orderDetails?.packaged_drinking_water
                                      .order_type === 700
                                  ? "rgba(238, 65, 144, 0.4)"
                                  : orderDetails?.packaged_drinking_water
                                      .is_subscribed_order === true
                                  ? "rgba(59, 194, 192, 0.4)"
                                  : "rgba(146, 78, 239, 0.6)",
                              color: "white",
                            }}
                          >
                            {productIndex === 0 ? (
                              <>
                                <TableCell
                                  sx={{
                                    padding: 0,
                                    py: 1,
                                    textAlign: "center",
                                    border: "1px solid black",
                                  }}
                                  rowSpan={productsData?.length}
                                >
                                  {orderDetails?.order_id?.split("BTORD-")?.[1]}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: 0,
                                    py: 1,
                                    textAlign: "center",
                                    border: "1px solid black",
                                  }}
                                  rowSpan={productsData?.length}
                                >
                                  <Box>
                                    {orderDetails?.delivery_address?.pincode}
                                  </Box>
                                  <Box>
                                    {
                                      orderDetails?.delivery_address
                                        ?.service_area
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: 0,
                                    py: 1,
                                    textAlign: "center",
                                    border: "1px solid black",
                                  }}
                                >
                                  {product?.capacity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: 0,
                                    py: 1,
                                    textAlign: "center",
                                    border: "1px solid black",
                                  }}
                                >
                                  {product?.order_qty}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: 0,
                                    py: "1px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                  }}
                                  rowSpan={productsData?.length}
                                >
                                  {orderDetails?.delivery_agent_id ||
                                  orderDetails?.tanker_id ? (
                                    <Accordion
                                      onChange={() =>
                                        setExpandedSlot((prev) =>
                                          prev === orderIndex
                                            ? null
                                            : orderIndex
                                        )
                                      }
                                      expanded={expandedSlot === orderIndex}
                                      sx={{ padding: "0" }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        id="panel2-header"
                                        sx={{ p: 0, pl: "1px", margin: "0" }}
                                      >
                                        {expandedSlot !== orderIndex ? (
                                          <Typography
                                            sx={{
                                              color: `${
                                                orderDetails?.tanker_id ==
                                                vehicleCurrentStatus.tanker_id
                                                  ? "green"
                                                  : "red"
                                              }`,
                                              fontWeight: `${
                                                orderDetails?.tanker_id ==
                                                vehicleCurrentStatus.tanker_id
                                                  ? "bold"
                                                  : ""
                                              }`,
                                            }}
                                          >
                                            {orderDetails?.tanker_id ||
                                              orderDetails?.driver_profile
                                                ?.fullname}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </AccordionSummary>
                                      <AccordionDetails sx={{ padding: 1 }}>
                                        {expandedSlot === orderIndex ? (
                                          <>
                                            <Box sx={{ display: "flex" }}>
                                              Slot :
                                              <Typography>
                                                {orderDetails
                                                  ?.packaged_drinking_water
                                                  ?.delivery_slots
                                                  ? orderDetails
                                                      ?.packaged_drinking_water
                                                      ?.delivery_slots
                                                      ?.admin_selected_delivery_slot &&
                                                    orderDetails
                                                      ?.packaged_drinking_water
                                                      ?.delivery_slots
                                                      ?.admin_selected_delivery_slot
                                                      ?.length
                                                    ? orderDetails
                                                        ?.packaged_drinking_water
                                                        ?.delivery_slots
                                                        ?.admin_selected_delivery_slot
                                                    : orderDetails
                                                        ?.packaged_drinking_water
                                                        ?.delivery_slots
                                                        ?.supplier_selected_delivery_slot &&
                                                      orderDetails
                                                        ?.packaged_drinking_water
                                                        ?.delivery_slots
                                                        ?.supplier_selected_delivery_slot
                                                        ?.length
                                                    ? orderDetails
                                                        ?.packaged_drinking_water
                                                        ?.delivery_slots
                                                        ?.supplier_selected_delivery_slot
                                                    : "---"
                                                  : "---"}
                                              </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex" }}>
                                              Vehicle:
                                              <Typography>
                                                {orderDetails?.tanker_id || "-"}
                                              </Typography>
                                            </Box>
                                            <Button
                                              onClick={() =>
                                                assignOrder(
                                                  orderDetails?.delivery_id,
                                                  orderDetails
                                                )
                                              }
                                            >
                                              Re-Assign
                                            </Button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        assignOrder(
                                          orderDetails?.delivery_id,
                                          orderDetails
                                        )
                                      }
                                    >
                                      <Typography fontWeight={"bold"}>
                                        Assign
                                      </Typography>
                                    </Button>
                                  )}
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell
                                  sx={{
                                    padding: 0,
                                    py: 1,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                  }}
                                >
                                  {product?.capacity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: 0,
                                    py: 1,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                  }}
                                >
                                  {product?.order_qty}
                                </TableCell>
                              </>
                            )}
                          </StyledTableRow>
                        );
                      })}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ) : (
            <Box sx={{ height: "50vh" }}>
              <DistributorLocation
                data={deliveries}
                assignAgentDialogOpen={assignAgentDialogOpen}
                setAssignAgentDialogOpen={setAssignAgentDialogOpen}
                setOrderId={setOrderId}
                coordinates={coordinates}
                setCoordinates={setCoordinates}
                setCurrentCoordinates={setCurrentCoordinates}
              />
            </Box>
          )} */}
        </TableContainer>
      </Box>
      {assignAgentDialogOpen && (
        <AssignDeliverAgentViaMap
          open={assignAgentDialogOpen}
          onClose={setAssignAgentDialogOpen}
          orderId={orderId}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          currentCoordinates={currentCoordinates}
          assignOrder={assignOrder}
          type="distributor"
        />
      )}
      <MapAssignComponent
        open={assignByMap}
        onClose={() => setAssignByMap(false)}
        deliveries={deliveries}
        assignAgentDialogOpen={assignAgentDialogOpen}
        setAssignAgentDialogOpen={setAssignAgentDialogOpen}
        setOrderId={setOrderId}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        setCurrentCoordinates={setCurrentCoordinates}
      />
    </Box>
  );
};

export default VehicleAssignOrders;
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 47,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 28,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 4,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const MapAssignComponent = ({
  onClose,
  deliveries,
  assignAgentDialogOpen,
  setAssignAgentDialogOpen,
  setOrderId,
  coordinates,
  setCoordinates,
  setCurrentCoordinates,
  open,
}) => {
  return (
    <Dialog
      sx={{ maxHeight: "100vh" }}
      onClose={onClose}
      fullWidth
      fullScreen
      open={open}
    >
      <DialogTitle sx={{ padding: 0 }}>
        <Box sx={{ bgcolor: "#152057", p: "3px", color: "white" }}>
          <Typography variant="h5" gutterBottom>
            Assign orders
          </Typography>
        </Box>
      </DialogTitle>
      <CloseIcon
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      />
      <DistributorLocation
        data={deliveries}
        assignAgentDialogOpen={assignAgentDialogOpen}
        setAssignAgentDialogOpen={setAssignAgentDialogOpen}
        setOrderId={setOrderId}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        setCurrentCoordinates={setCurrentCoordinates}
      />
    </Dialog>
  );
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  position: "relative",
  "&::after": {
    content: "attr(data-badge-content)",
    position: "absolute",
    top: "1px",
    right: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "50%",
    textAlign: "center",
    width: "1.1rem",
    padding: "0 0 0.25em 0",
    height: "1.5rem",
    // padding: "1px 0.3em",
    fontSize: "1rem",
    display: "inline-block",
  },
}));
