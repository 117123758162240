import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { UpdateActiveDeactivateCans } from "../Redux/Actions/adminContainerDetailsAction";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { pink } from "@mui/material/colors";
import { Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../Common/drawer.css";
import { styles } from "./containerDetailsCardStyles";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

import CustomConsole from "../../CustomConsole";
function ContainerDeteailsTable({ data, handleSubmit }) {
  const dispatch = useDispatch();
  const adminContainerData = useSelector((state) => state.adminContainerData);
  const [activeDeactiveFlag, setActiveDeactiveFlag] = React.useState(false);

  React.useEffect(() => {
    CustomConsole(adminContainerData);
    if (adminContainerData.error === false && activeDeactiveFlag === true) {
      toast.success(adminContainerData.updateActiveDeactiveMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setActiveDeactiveFlag(false);
      handleSubmit();
    }
  }, [adminContainerData]);

  const handleActiveDeactive = (id, isActive) => {
    CustomConsole(id);
    const reqObj = {
      id: id,
      is_active: isActive ? false : true,
    };
    dispatch(UpdateActiveDeactivateCans(reqObj));

    setActiveDeactiveFlag(true);
  };

  return (
    <>
      {data.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 550,
            maxWidth: { lg: 1, md: 1, sm: 1, xs: 320 },
            marginTop: "1.2em",
          }}
          className="drawer"
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={styles.TableHeading}>
                  Life Span
                </TableCell>
                <TableCell align="center" sx={styles.TableHeading}>
                  Amount
                </TableCell>
                <TableCell align="center" sx={styles.TableHeading}>
                  Updated at
                </TableCell>
                <TableCell align="center" sx={styles.TableHeading}>
                  Status
                </TableCell>
                <TableCell align="center" sx={styles.TableHeading}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ? data.map((data, key) => {
                    data.created_at = data.created_at.split("T")[0];
                    return (
                      <TableRow
                        key={key + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{data.life_span}</TableCell>
                        <TableCell align="center">{data.amount}</TableCell>
                        <TableCell align="center">{data.created_at}</TableCell>
                        <TableCell align="center">
                          {data.is_active ? "Active" : "Inactive"}
                          <IconButton>
                            {data.is_active ? (
                              <CheckCircleRoundedIcon color="success" />
                            ) : (
                              <CancelRoundedIcon sx={{ color: pink[500] }} />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          {ADMIN_ROLES.BW_IVML !==
                          parseInt(window.localStorage.getItem("roleId")) ? (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() =>
                                handleActiveDeactive(
                                  data.container_master_id,
                                  data.is_active
                                )
                              }
                            >
                              {data.is_active ? "Deactivate" : "Activate"}
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <strong>No Data Found</strong>
      )}
    </>
  );
}

export default ContainerDeteailsTable;
