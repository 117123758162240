import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  customData: [],
  customDataError: true,
  deleteProductData: {},
  deleteProducterror: true,
  customDataErrorAddress: true,
  customDataAddress: {},
  deleteAddressError: true,
  deleteAddressData: {},
};

const SkuCoustomProductReduser = (state = initialState, action) => {
  switch (action.type) {
    case actionType.COUSTOM_PRODUCT_PRICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        customData: action.payload.data,
        customDataError: action.payload.error,
      };
    case actionType.DELETE_PRODUCT_DISCOUNT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        deleteProductData: action.payload.data,
        deleteProducterror: action.payload.error,
      };
    case actionType.COUSTOM_PRODUCT_PRICE_ADDRESS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        customDataAddress: action.payload.data,
        customDataErrorAddress: action.payload.error,
      };
    case actionType.REMOVE_PRODUCT_PRICE_ADDRESS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        deleteAddressData: action.payload.data,
        deleteAddressError: action.payload.error,
      };
    default:
      return state;
  }
};

export default SkuCoustomProductReduser;
