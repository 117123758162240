import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  replacementOrders: [],
  orderbyDetails: [],
  supplierDetails: [],
};

const adminReplacementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_REPLACEMENT_CAN_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        replacementOrders: action.payload.data,
      };
    case actionType.GET_ORDER_USING_ID:
      return {
        ...state,
        error: action.payload.error,
        orderbyDetails: action.payload.data,
      };
    case actionType.GET_SUPPLIER_DETAILS_REPLACE:
      return {
        ...state,
        error: action.payload.error,
        supplierDetails: action.payload.data,
      };
    case actionType.ACCEPTING_REPLACEMENT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.REJECTING_REPLECEMENT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.EDIT_REPLACEMENT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.REPLACEMENT_INSCAN_QRLESS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default adminReplacementReducer;
