import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";

export default function EditPaymentReasonDialog(props) {
  const handleClose = () => {
    props.setEditPaymentReason("");
    props.setEditPaymentClicked(false);
  };
  // const [isOtherReason, setIsOtherReason] = React.useState(false);
  const handleReasonChange = (event) => {
    if (event.target.value !== "999") {
      props.setCustomReasonPay("");
    }
    props.setEditPaymentReason(event.target.value);
  };

  // React.useEffect(() => {
  //   if (props.editPaymentReason === "999") {
  //     setIsOtherReason(true);
  //   } else {
  //     setIsOtherReason(false);
  //   }
  // }, [props.editPaymentReason]);

  const handleContinue = () => {
    if (
      (props.editPaymentReason === "999" && props.customReasonPay !== "") ||
      (props.editPaymentReason !== "999" && props.editPaymentReason !== "")
    ) {
      props.setIsPaymentReasonChoosen(true);
      props.setEditPaymentClicked(false);
    } else if (
      props.editPaymentReason === "999" &&
      props.customReasonPay === ""
    ) {
      toast.dark("Enter the reason to change payment", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    } else {
      toast.dark("Select Reason For Payment Change", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  return (
    <div>
      <Dialog
        open={props.editPaymentClicked}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                Select reason for editing payment
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <FormControl onChange={handleReasonChange}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {props.editPaymentReasonsValues.map((reason, key) => {
                    return (
                      <FormControlLabel
                        value={reason.k}
                        control={<Radio />}
                        name={reason.v}
                        label={reason.v}
                        key={key}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ padding: "10px" }}>
              {props.editPaymentReason === "999" ? (
                <Box>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Other reasons"
                    multiline
                    maxRows={4}
                    onChange={(e) => props.setCustomReasonPay(e.target.value)}
                  />
                </Box>
              ) : null}
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Alert severity="info">
                Any refund will be credited back to your water wallet
              </Alert>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button variant="contained" onClick={handleClose}>
                  Close
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleContinue}
                  sx={appColors.commonBtnColor}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
