import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CustomConsole from "../../CustomConsole";
import {
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateWfsDetails,
  AddNewWfs,
} from "../Redux/Actions/adminAddUpdateWfsAction";
import { ToastContainer, toast } from "react-toastify";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { UploadFile, getFiles } from "../Redux/Actions/fileHandlingAction";
import Switch from "@mui/material/Switch";
import moment from "moment/moment";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
export default function UpdateWfsDetailsCard({
  wfsDetails,
  onClickScroll,
  addWfsFlag,
  triggEffect,
  handleClickWfs,
  showDetailsFlag,
  open,
  onClose,
  setWfsDetails,
}) {
  const dispatch = useDispatch();
  const addUpdateDetails = useSelector((state) => state.adminAddUpdateWfsData);
  const fileUploadDetails = useSelector((state) => state.fileHandler);
  const [inchargeEmail, setInchargeEmail] = React.useState("");
  const [inchargePhone, setInchargePhone] = React.useState("");
  const [inchargeName, setInchargeName] = React.useState("");
  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [wfsId, setWfsId] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");
  const [ownerPhone, setOwnerPhone] = React.useState("");
  const [companyPanNumber, setCompanyPanNumber] = React.useState("");
  const [isiNumber, setIsiNumber] = React.useState("");
  const [fssaiNumber, setFssaiNumber] = React.useState("");
  const [gstInNumber, setGstInNumber] = React.useState("");
  const [ownerPanNumber, setOwnerPanNumber] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);

  //validation states

  const [companyPanEr, setCompanyPanEr] = React.useState(false);
  const [ownerPanEr, setOwnerPanEr] = React.useState(false);
  const [gstNumEr, setGstNumEr] = React.useState(false);
  const [aadharNumEr, setAAdharNumEr] = React.useState(false);
  // const [fssaiNumEr, setFssaiNumEr] = React.useState(false);

  //file

  const [fssaiFile, setFssaiiFile] = React.useState("");
  const [isiFile, setIsiFile] = React.useState("");
  const [gstInFile, setGstInFile] = React.useState("");
  const [compPanFile, setCompPanFile] = React.useState("");
  const [ownerPanFile, setOwnerPanFile] = React.useState("");
  const [aadharFile, setAadharFile] = React.useState("");

  //file set flags
  const [fssaiFileFlag, setFssaiiFileFlag] = React.useState(false);
  const [isiFileFlag, setIsiFileFlag] = React.useState(false);
  const [gstInFileFlag, setGstInFileFlag] = React.useState(false);
  const [compPanFileFlag, setCompPanFileFlag] = React.useState(false);
  const [ownerPanFileFlag, setOwnerPanFileFlag] = React.useState(false);
  const [aadharFileFlag, setAadharFileFlag] = React.useState(false);

  //tost flags
  const [addWfsToastFlag, setAddWfsToastFlag] = React.useState(false);
  // address

  const [doorNo, setDoorNo] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [village, setVillage] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [state, setState] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [country, setCountry] = React.useState("");

  const [dateFrom, setDateFrom] = React.useState(moment());
  const [dateTo, setDateTo] = React.useState(moment());

  //refs
  const uploadISIFileRef = React.useRef(null);
  const uploadFASSIFileRef = React.useRef(null);
  const uploadGSTINFileRef = React.useRef(null);
  const companyPANFileRef = React.useRef(null);
  const ownerPANFileRef = React.useRef(null);
  const upAadharFileRef = React.useRef(null);

  const setSwitchValue = (e) => {
    setIsSwitchOn(e.target.checked);
  };

  const companyPanValidation = (e) => {
    const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(e.target.value);
    setCompanyPanNumber(e.target.value);
    setCompanyPanEr(!isValidPanNumber);
  };

  const ownerPanValidation = (e) => {
    const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(e.target.value);
    setOwnerPanNumber(e.target.value);
    setOwnerPanEr(!isValidPanNumber);
  };

  const gstValidation = (e) => {
    const isValidGstNumber =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        e.target.value
      );
    setGstInNumber(e.target.value);
    setGstNumEr(!isValidGstNumber);
  };

  const aadharValidation = (e) => {
    const isValidAadharNumber = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(
      e.target.value
    );
    setAadharNumber(e.target.value);
    setAAdharNumEr(!isValidAadharNumber);
  };

  const fssaiValidation = (e) => {
    // const isValidateFssai = /^[0-9]{2}[A-Z]{3}[0-9]{5}[0-9]{1}[A-Z]{1}[0-9]{2}$/.test(e.target.value);
    setFssaiNumber(e.target.value);
    // setFssaiNumEr(!isValidateFssai);
  };

  React.useEffect(() => {
    CustomConsole("wfs details in card");
    CustomConsole(addUpdateDetails);
    if (addUpdateDetails.error === false && updateFlag === true) {
      toast.success(addUpdateDetails.updatedMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateFlag(false);
      handleClickWfs();
      onClose();
    }
    if (addWfsToastFlag && addUpdateDetails.error === false) {
      toast.success(addUpdateDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setWfsId("");
      setCompanyName("");
      setOwnerName("");
      setOwnerPhone("");
      setCompanyPanNumber("");
      setIsiNumber("");
      setFssaiNumber("");
      setGstInNumber("");
      setOwnerPanNumber("");
      setAadharNumber("");
      setIsSwitchOn(false);
      //address
      setDoorNo("");
      setStreet("");
      setVillage("");
      setDistrict("");
      setState("");
      setPincode("");
      setCountry("");

      setDateFrom(moment());
      setDateTo(moment());

      //files
      setFssaiiFile("");
      setIsiFile("");
      setGstInFile("");
      setCompPanFile("");
      setOwnerPanFile("");
      setAadharFile("");
      setAddWfsToastFlag(false);
      onClose();
    }
  }, [addUpdateDetails]);

  React.useEffect(() => {
    if (Object.keys(wfsDetails).length > 0) {
      CustomConsole("selected wfs");

      setWfsId(wfsDetails.wfs_master_id);
      setCompanyName(wfsDetails.company_name);
      setOwnerName(wfsDetails.owner_name);
      setOwnerPhone(wfsDetails.owner_phone);
      setCompanyPanNumber(wfsDetails.company_pan_number);
      setIsiNumber(wfsDetails.isi_number);
      setFssaiNumber(wfsDetails.fssai_number);
      setGstInNumber(wfsDetails.gstin_number);
      setOwnerPanNumber(wfsDetails.owner_pan_num);
      setAadharNumber(wfsDetails.owner_aadhar_num);
      setIsSwitchOn(wfsDetails.is_available);
      //address
      setDoorNo(wfsDetails.address.door_no);
      setStreet(wfsDetails.address.Street);
      setVillage(wfsDetails.address.Village);
      setDistrict(wfsDetails.address.District);
      setState(wfsDetails.address.State);
      setPincode(wfsDetails.address.Pincode);
      setCountry(wfsDetails.address.country);

      setInchargeEmail(wfsDetails.incharge_details.email);
      setInchargeName(wfsDetails.incharge_details.fullname);
      setInchargePhone(
        wfsDetails.incharge_details.phone !== ""
          ? wfsDetails.incharge_details.phone
          : "None"
      );

      setDateFrom(wfsDetails.fssi_from_date);
      setDateTo(wfsDetails.fssi_to_date);

      //files
      setFssaiiFile(wfsDetails.fssai_file);
      setIsiFile(wfsDetails.isi_file);
      setGstInFile(wfsDetails.gstin_file);
      setCompPanFile(wfsDetails.company_pan_file);
      setOwnerPanFile(wfsDetails.owner_pan_file);
      setAadharFile(wfsDetails.aadhar_file);
    }
  }, [wfsDetails, triggEffect]);

  React.useEffect(() => {
    CustomConsole("hi");
    if (addWfsFlag === false) {
      setWfsId("");
      setCompanyName("");
      setOwnerName("");
      setOwnerPhone("");
      setCompanyPanNumber("");
      setIsiNumber("");
      setFssaiNumber("");
      setGstInNumber("");
      setOwnerPanNumber("");
      setAadharNumber("");
      //address
      setDoorNo("");
      setStreet("");
      setVillage("");
      setDistrict("");
      setState("");
      setPincode("");
      setCountry("");
      setInchargeEmail("");
      setInchargeName("");
      setInchargePhone("None");
      setDateFrom(moment());
      setDateTo(moment());

      //files
      setFssaiiFile("");
      setIsiFile("");
      setGstInFile("");
      setCompPanFile("");
      setOwnerPanFile("");
      setAadharFile("");
      setWfsDetails({});
    }
  }, [open]);

  const handleUpdateDetails = () => {
    if (
      aadharFile !== "" &&
      district !== "" &&
      pincode !== "" &&
      state !== "" &&
      street !== "" &&
      village !== "" &&
      country !== "" &&
      country !== "" &&
      doorNo !== "" &&
      companyName !== "" &&
      compPanFile !== "" &&
      companyPanNumber !== "" &&
      fssaiFile !== "" &&
      fssaiNumber !== "" &&
      dateFrom !== "" &&
      dateTo !== "" &&
      gstInFile !== "" &&
      gstInNumber !== "" &&
      isiFile !== "" &&
      isiNumber !== "" &&
      aadharNumber !== "" &&
      ownerName !== "" &&
      ownerPanFile !== "" &&
      ownerPanNumber !== "" &&
      ownerPhone !== "" &&
      !aadharNumEr &&
      !gstNumEr &&
      !ownerPanEr &&
      !companyPanEr
    ) {
      let reqObj = {
        aadhar_file: aadharFile,
        address: {
          District: district,
          Pincode: pincode,
          State: state,
          Street: street,
          Village: village,
          country: country,
          door_no: doorNo,
        },
        company_name: companyName,
        company_pan_file: compPanFile,
        company_pan_number: companyPanNumber,
        fssai_file: fssaiFile,
        fssai_number: fssaiNumber,
        fssi_from_date: dateFrom,
        fssi_to_date: dateTo,
        gstin_file: gstInFile,
        gstin_number: gstInNumber,
        is_available: isSwitchOn,
        isi_file: isiFile,
        isi_number: isiNumber,
        owner_aadhar_num: aadharNumber,
        owner_name: ownerName,
        owner_pan_file: ownerPanFile,
        owner_pan_num: ownerPanNumber,
        owner_phone: ownerPhone,
        pincode: pincode,
        wfs_master_id: wfsId,
      };
      dispatch(UpdateWfsDetails(reqObj));
      CustomConsole(reqObj);
      setUpdateFlag(true);
    } else {
      let reqObj = {
        aadhar_file: aadharFile,
        address: {
          District: district,
          Pincode: pincode,
          State: state,
          Street: street,
          Village: village,
          country: country,
          door_no: doorNo,
        },
        company_name: companyName,
        company_pan_file: compPanFile,
        company_pan_number: companyPanNumber,
        fssai_file: fssaiFile,
        fssai_number: fssaiNumber,
        fssi_from_date: dateFrom,
        fssi_to_date: dateTo,
        gstin_file: gstInFile,
        gstin_number: gstInNumber,
        is_available: isSwitchOn,
        isi_file: isiFile,
        isi_number: isiNumber,
        owner_aadhar_num: aadharNumber,
        owner_name: ownerName,
        owner_pan_file: ownerPanFile,
        owner_pan_num: ownerPanNumber,
        owner_phone: ownerPhone,
        pincode: pincode,
        wfs_master_id: wfsId,
      };
      toast.error("Fill all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(reqObj);
    }
  };

  const fassaiFileChange = (e) => {
    const selectedFile = e.target.files[0];
    CustomConsole(selectedFile);
    dispatch(UploadFile(selectedFile));
    setFssaiiFileFlag(true);
  };
  const isiFileChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setIsiFileFlag(true);
  };
  const gstinFileChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setGstInFileFlag(true);
  };
  const compPANFileChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setCompPanFileFlag(true);
  };
  const ownerPANFileChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setOwnerPanFileFlag(true);
  };
  const aadharFileChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setAadharFileFlag(true);
  };

  const fassaiFileBtn = () => {
    uploadFASSIFileRef.current.click();
  };
  const isiFileBtn = () => {
    uploadISIFileRef.current.click();
  };
  const gstFilebtn = () => {
    uploadGSTINFileRef.current.click();
  };
  const compPANFileBtn = () => {
    companyPANFileRef.current.click();
  };
  const ownerPANFileBtn = () => {
    ownerPANFileRef.current.click();
  };
  const aadharFileBtn = () => {
    upAadharFileRef.current.click();
  };

  React.useEffect(() => {
    CustomConsole(fileUploadDetails);
    if (fssaiFileFlag === true && fileUploadDetails.isFileUploaded === true) {
      setFssaiiFile(fileUploadDetails.fileName);
      CustomConsole(fssaiFile);
      setFssaiiFileFlag(false);
    }
    if (fileUploadDetails.isFileUploaded === true && isiFileFlag === true) {
      setIsiFile(fileUploadDetails.fileName);
      CustomConsole(isiFile);
      setIsiFileFlag(false);
    }
    if (fileUploadDetails.isFileUploaded === true && gstInFileFlag === true) {
      setGstInFile(fileUploadDetails.fileName);
      setGstInFileFlag(false);
      CustomConsole(gstInFile);
    }
    if (fileUploadDetails.isFileUploaded === true && compPanFileFlag === true) {
      setCompPanFile(fileUploadDetails.fileName);
      setCompPanFileFlag(false);
      CustomConsole(compPanFile);
    }
    if (
      fileUploadDetails.isFileUploaded === true &&
      ownerPanFileFlag === true
    ) {
      setOwnerPanFile(fileUploadDetails.fileName);
      setOwnerPanFileFlag(false);
      CustomConsole(ownerPanFile);
    }
    if (fileUploadDetails.isFileUploaded === true && aadharFileFlag === true) {
      setAadharFile(fileUploadDetails.fileName);
      setAadharFileFlag(false);
    }
  }, [fileUploadDetails]);

  let createNewWfs = () => {
    if (
      addWfsFlag === true &&
      aadharFile !== "" &&
      district !== "" &&
      pincode !== "" &&
      state !== "" &&
      street !== "" &&
      village !== "" &&
      country !== "" &&
      country !== "" &&
      doorNo !== "" &&
      companyName !== "" &&
      compPanFile !== "" &&
      companyPanNumber !== "" &&
      fssaiFile !== "" &&
      fssaiNumber !== "" &&
      dateFrom !== "" &&
      dateTo !== "" &&
      gstInFile !== "" &&
      gstInNumber !== "" &&
      isiFile !== "" &&
      isiNumber !== "" &&
      aadharNumber !== "" &&
      ownerName !== "" &&
      ownerPanFile !== "" &&
      ownerPanNumber !== "" &&
      ownerPhone !== "" &&
      !aadharNumEr &&
      !gstNumEr &&
      !ownerPanEr &&
      !companyPanEr
    ) {
      CustomConsole("Working....");
      CustomConsole(dateFrom);
      CustomConsole(dateTo);
      let reqObj = {
        aadhar_file: aadharFile,
        address: {
          District: district,
          Pincode: pincode,
          State: state,
          Street: street,
          Village: village,
          country: country,
          door_no: doorNo,
        },
        company_name: companyName,
        company_pan_file: compPanFile,
        company_pan_number: companyPanNumber,
        fssai_file: fssaiFile,
        fssai_number: fssaiNumber,
        fssi_from_date: dateFrom ? dateFrom.format().split("T")[0] : "",
        fssi_to_date: dateTo ? dateTo.format().split("T")[0] : "",
        gstin_file: gstInFile,
        gstin_number: gstInNumber,
        isi_file: isiFile,
        isi_number: isiNumber,
        owner_aadhar_num: aadharNumber,
        owner_name: ownerName,
        owner_pan_file: ownerPanFile,
        owner_pan_num: ownerPanNumber,
        owner_phone: ownerPhone,
        pincode: pincode,
      };
      dispatch(AddNewWfs(reqObj));
      setAddWfsToastFlag(true);
      CustomConsole(reqObj);
    } else {
      CustomConsole("----Some field is empty-----");
      CustomConsole(dateFrom);
      CustomConsole(dateTo);
      let reqObj = {
        aadhar_file: aadharFile,
        address: {
          District: district,
          Pincode: pincode,
          State: state,
          Street: street,
          Village: village,
          country: country,
          door_no: doorNo,
        },
        company_name: companyName,
        company_pan_file: compPanFile,
        company_pan_number: companyPanNumber,
        fssai_file: fssaiFile,
        fssai_number: fssaiNumber,
        fssi_from_date: dateFrom ? dateFrom.format().split("T")[0] : "",
        fssi_to_date: dateTo ? dateTo.format().split("T")[0] : "",
        gstin_file: gstInFile,
        gstin_number: gstInNumber,
        isi_file: isiFile,
        isi_number: isiNumber,
        owner_aadhar_num: aadharNumber,
        owner_name: ownerName,
        owner_pan_file: ownerPanFile,
        owner_pan_num: ownerPanNumber,
        owner_phone: ownerPhone,
        pincode: pincode,
      };
      CustomConsole(reqObj);
      toast.error("Fill all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const alphabetPattern = /^[a-zA-Z]*$/;

  const disableFutureDates = (date) => {
    return date > new Date();
  };

  const fssaiFileDownload = () => {
    if (fssaiFile !== "") {
      dispatch(getFiles(fssaiFile));
    }
  };

  const isiDownload = () => {
    if (isiFile !== "") {
      dispatch(getFiles(isiFile));
    }
  };

  const gstInDownload = () => {
    if (gstInFile !== "") {
      CustomConsole(gstInFile);
      dispatch(getFiles(gstInFile));
    }
  };

  const companyPanDownload = () => {
    if (compPanFile !== "") {
      dispatch(getFiles(compPanFile));
    }
  };

  const ownerPanUpload = () => {
    if (ownerPanFile !== "") {
      dispatch(getFiles(ownerPanFile));
    }
  };

  const aadharFileDownload = () => {
    if (aadharFile !== "") {
      dispatch(getFiles(aadharFile));
    }
  };

  CustomConsole(wfsDetails);
  return (
    <Dialog open={open} onclose={onClose}>
      <DialogContent>
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Box sx={{ padding: "10px", marginBottom: "20px" }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {addWfsFlag
                    ? "ADD NEW WFS"
                    : "UPDATE WATER FILLING STATION DETAILS"}
                </Typography>
              </Box>
              {Object.keys(wfsDetails).length > 0 && (
                <Box sx={appColors.commonLayoutColor}>
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      INCHARGE DETAILS
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: "5px",
                    }}
                  >
                    <Box sx={{ padding: "3px" }}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Email</span>:{" "}
                        {inchargeEmail}
                      </Typography>
                    </Box>
                    <Box sx={{ padding: "3px" }}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                        {inchargeName}
                      </Typography>
                    </Box>
                    <Box sx={{ padding: "3px" }}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                        {inchargePhone}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  padding: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Company name*"
                  size="small"
                  variant="outlined"
                  value={companyName}
                  onChange={(event) => setCompanyName(event.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  label="Owner name*"
                  size="small"
                  variant="outlined"
                  value={ownerName}
                  onChange={(event) => setOwnerName(event.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="Owner phone*"
                  type="number"
                  variant="outlined"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  value={ownerPhone}
                  onChange={(event) => setOwnerPhone(event.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  padding: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Company PAN number*"
                  size="small"
                  variant="outlined"
                  value={companyPanNumber}
                  onChange={companyPanValidation}
                  error={companyPanEr}
                />
                <TextField
                  id="outlined-basic"
                  label="ISI number*"
                  size="small"
                  variant="outlined"
                  value={isiNumber}
                  onChange={(event) => setIsiNumber(event.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="FSSAI number*"
                  variant="outlined"
                  type="number"
                  value={fssaiNumber}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 14);
                  }}
                  onChange={fssaiValidation}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  padding: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="GSTIN number*"
                  variant="outlined"
                  size="small"
                  value={gstInNumber}
                  error={gstNumEr}
                  onChange={gstValidation}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="Owner PAN number*"
                  variant="outlined"
                  value={ownerPanNumber}
                  error={ownerPanEr}
                  onChange={ownerPanValidation}
                />
                <TextField
                  id="outlined-basic"
                  label="Aadhar number*"
                  size="small"
                  variant="outlined"
                  value={aadharNumber}
                  error={aadharNumEr}
                  onChange={aadharValidation}
                />
              </Box>
              <Box sx={{ padding: "10px" }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Address
                </Typography>
              </Box>
              <Grid container>
                <Grid container justifyContent="space-between" rowGap={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Door number*"
                      sx={{ width: "90%" }}
                      variant="outlined"
                      value={doorNo}
                      onChange={(event) => setDoorNo(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Street*"
                      sx={{ width: "90%" }}
                      variant="outlined"
                      value={street}
                      onChange={(event) => setStreet(event.target.value)}
                    />
                  </Grid>
                  <Grid md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Village*"
                      variant="outlined"
                      sx={{ width: "90%" }}
                      value={village}
                      onKeyDown={(event) => {
                        if (!alphabetPattern.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => setVillage(event.target.value)}
                    />{" "}
                  </Grid>
                  <Grid md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      sx={{ width: "90%" }}
                      size="small"
                      label="District*"
                      variant="outlined"
                      value={district}
                      onKeyDown={(event) => {
                        if (!alphabetPattern.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => setDistrict(event.target.value)}
                    />
                  </Grid>
                  <Grid md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="State*"
                      variant="outlined"
                      sx={{ width: "90%" }}
                      value={state}
                      onKeyDown={(event) => {
                        if (!alphabetPattern.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => setState(event.target.value)}
                    />
                  </Grid>
                  <Grid md={6} xs={12}>
                    <TextField
                      type="number"
                      id="outlined-basic"
                      sx={{ width: "90%" }}
                      size="small"
                      label="Pincode*"
                      variant="outlined"
                      value={pincode}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      onChange={(event) => setPincode(event.target.value)}
                    />
                  </Grid>{" "}
                  <Grid md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      sx={{ width: "90%" }}
                      label="Country*"
                      variant="outlined"
                      value={country}
                      onKeyDown={(event) => {
                        if (!alphabetPattern.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => setCountry(event.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid md={12} xs={12}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "1rem",
                      }}
                    >
                      Fassi Validity Date
                    </Typography>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Fssai from*"
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={dateFrom}
                        onChange={(newDate) => setDateFrom(newDate)}
                        shouldDisableDate={disableFutureDates}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid md={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Fssai To*"
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={dateTo}
                        onChange={(newDate) => setDateTo(newDate)}
                        renderInput={(props) => <TextField {...props} />}
                        minDate={moment()}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container rowGap={2}>
                  <Grid md={12}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "1rem",
                      }}
                    >
                      Document Upload
                    </Typography>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        ref={uploadFASSIFileRef}
                        type="file"
                        onChange={fassaiFileChange}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color={fssaiFile !== "" ? "success" : "primary"}
                        sx={{ width: "230px" }}
                        onClick={fassaiFileBtn}
                      >
                        {fssaiFile === ""
                          ? "Upload FASSAI file"
                          : "FASSAI uploaded"}
                      </Button>
                      <DownloadForOfflineRoundedIcon
                        color="primary"
                        sx={{
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                        onClick={fssaiFileDownload}
                      />
                    </Box>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        type="file"
                        ref={uploadISIFileRef}
                        onChange={isiFileChange}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color={isiFile !== "" ? "success" : "primary"}
                        sx={{ width: "230px" }}
                        onClick={isiFileBtn}
                      >
                        {isiFile === "" ? "Upload ISI FIle" : "ISI uploaded"}
                      </Button>
                      <DownloadForOfflineRoundedIcon
                        color="primary"
                        sx={{
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                        onClick={isiDownload}
                      />
                    </Box>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        type="file"
                        ref={uploadGSTINFileRef}
                        onChange={gstinFileChange}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color={gstInFile !== "" ? "success" : "primary"}
                        onClick={gstFilebtn}
                        sx={{ width: "230px" }}
                      >
                        {gstInFile === "" ? "Upload GSTIN " : "GSTIN uploaded"}
                      </Button>
                      <DownloadForOfflineRoundedIcon
                        color="primary"
                        sx={{
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                        onClick={gstInDownload}
                      />
                    </Box>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        type="file"
                        ref={companyPANFileRef}
                        onChange={compPANFileChange}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color={compPanFile !== "" ? "success" : "primary"}
                        onClick={compPANFileBtn}
                        sx={{ width: "230px" }}
                      >
                        {compPanFile === ""
                          ? "Upload Company PAN "
                          : "Company PAN uploaded"}
                      </Button>
                      <DownloadForOfflineRoundedIcon
                        color="primary"
                        sx={{
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                        onClick={companyPanDownload}
                      />
                    </Box>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        type="file"
                        ref={ownerPANFileRef}
                        onChange={ownerPANFileChange}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        onClick={ownerPANFileBtn}
                        color={ownerPanFile !== "" ? "success" : "primary"}
                        sx={{ width: "230px" }}
                      >
                        {ownerPanFile === ""
                          ? "Upload Owner Pan "
                          : "Owner PAN uploaded"}
                      </Button>

                      <DownloadForOfflineRoundedIcon
                        color="primary"
                        sx={{
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                        onClick={ownerPanUpload}
                      />
                    </Box>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        type="file"
                        ref={upAadharFileRef}
                        onChange={aadharFileChange}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        onClick={aadharFileBtn}
                        color={aadharFile !== "" ? "success" : "primary"}
                        sx={{ width: "230px" }}
                      >
                        {aadharFile === ""
                          ? "Upload Aadhar "
                          : "Aadhar uploaded"}
                      </Button>
                      <DownloadForOfflineRoundedIcon
                        color="primary"
                        sx={{
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                        onClick={aadharFileDownload}
                      />
                    </Box>{" "}
                  </Grid>
                  <Grid md={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "30px",
                      }}
                    >
                      {Object.keys(wfsDetails).length > 0 ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isSwitchOn}
                              onChange={setSwitchValue}
                              name="availability"
                            />
                          }
                          label="Currently Available"
                        />
                      ) : null}{" "}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {Object.keys(wfsDetails).length === 0 ? (
                  <Button
                    variant="contained"
                    onClick={createNewWfs}
                    size="small"
                  >
                    Create WFS
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleUpdateDetails}
                    size="small"
                  >
                    Update Details
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={onClose}
                  size="small"
                  color="error"
                >
                  close
                </Button>
              </Box>
            </Box>
          </Box>
        </>
        <ToastContainer />
        {/* </Paper> */}
      </DialogContent>
    </Dialog>
  );
}
