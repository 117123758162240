/**
 * @author vinay kumar
 * @description Supplier Drivers List
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.46
 * @Date 26-06-2023
 */
import React from "react";
import * as Material from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeliveryAgentListCard from "../../Components/Cards/DeliveryAgentListCard";
import AddorUpdateDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AddorUpdateDeliveryAgentDialog";
import { GetSupplierDeliveryAgentDetailsAdmin } from "../Redux/Actions/supliersAction";
import {
  ADMIN_ROLES,
  employeeType,
} from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

function SupplierDriversList({ supplier, employee_type, ...props }) {
  CustomConsole(props);
  const operationType =
    employee_type == employeeType.MANAGER
      ? { name: "Manager", plural: "Managers", employee_type }
      : employee_type == employeeType.DRIVER
      ? { name: "Driver", plural: "Drivers", employee_type }
      : employee_type == employeeType.LOADER
      ? { name: "Loader", plural: "Loaders", employee_type }
      : CustomConsole("Invalid Operation for Supplier Actions!");
  const [singleData, setSingleData] = React.useState(null);
  const [openAddDeliveryAgentDialog, setOpenAddDeliveryAgentDialog] =
    React.useState(false);

  const [updateClicked, setUpdateClicked] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [deliveryId, setDeliveryId] = React.useState("");
  const [name, setName] = React.useState("");
  const supplierDrivers = useSelector((state) => state.supplierDeliveries);
  CustomConsole(props);
  const dispatch = useDispatch();
  React.useEffect(() => {
    // dispatch(GetSupplierDrivers({ user_id: props.userId }));

    // dispatch(
    //   GetSupplierDeliveryAgentDetailsAdmin({
    //     supplier_id: props.supplierId,
    //     employee_type,
    //   })
    // );
    handleRefreshDriverDetails();
    // supplierDrivers.actionTriger = false;

    return () => {
      supplierDrivers.getSupplierDriverDataAdmin = [];
    };
  }, []);
  React.useEffect(async () => {
    // dispatch(GetSupplierDrivers({ user_id: props.userId }));
    // await Promise.resolve(() => {}, 1000);
    CustomConsole(supplierDrivers.actionTriger);
    if (supplierDrivers.actionTriger == false) {
      handleRefreshDriverDetails();

      supplierDrivers.actionTriger = true;
    }
  }, [supplierDrivers.actionTriger]);

  const handleUpdateDeliveryAgent = (props) => {
    CustomConsole(props);
    CustomConsole(props.data);
    setSingleData(props.data);

    CustomConsole(deliveryId);
    CustomConsole(name);
    CustomConsole("------handleUpdateDeliveryAgent------");
    setOpenAddDeliveryAgentDialog(true);
    CustomConsole(props.deliver_Id);
    CustomConsole(props.fullname);
    setDeliveryId(props.deliver_Id);
    setName(props.fullname);
    setIsUpdate(true);
  };
  const handleCancleDialog = (props) => {
    CustomConsole(props);
    CustomConsole("------handleCancleDialog------");
    setOpenAddDeliveryAgentDialog(false);
  };
  const handleOpenAddDeliveryAgent = () => {
    CustomConsole("------handleCancleDialog------");
    setIsUpdate(false);
    setSingleData(null);
    setOpenAddDeliveryAgentDialog(true);
  };
  const handleAddUpdateDialogClose = () => {
    setOpenAddDeliveryAgentDialog(false);
    handleRefreshDriverDetails();
  };
  const handleRefreshDriverDetails = () => {
    dispatch(
      GetSupplierDeliveryAgentDetailsAdmin({
        supplier_id: props.supplierId,
        employee_type,
      })
    );
  };
  return (
    <Material.Box>
      <Material.Card sx={{ border: "2px solid blue" }}>
        <Material.CardContent>
          <Material.Typography sx={{ fontWeight: "bold" }}>
            {operationType.plural} Details
          </Material.Typography>

          {[
            ADMIN_ROLES.BW_SUPPLIERMANAGER,
            ADMIN_ROLES.BW_DISTRIBUTER,
            ADMIN_ROLES.BW_ADMIN,
            ADMIN_ROLES.BW_SUPERADMIN,
            ADMIN_ROLES.BW_OPSHEAD,
          ].includes(parseInt(localStorage.getItem("roleId"))) && (
            <Material.Box>
              <Material.Button
                variant="contained"
                onClick={handleOpenAddDeliveryAgent}
              >
                Add {operationType.name}
              </Material.Button>
              <Material.Button
                sx={{ marginLeft: "20px" }}
                variant="contained"
                onClick={handleRefreshDriverDetails}
              >
                Refresh
              </Material.Button>
            </Material.Box>
          )}
          {supplierDrivers.error === false &&
          supplierDrivers.getSupplierDriverDataAdmin !== null &&
          supplierDrivers.getSupplierDriverDataAdmin !== undefined ? (
            <>
              {/* {supplierDrivers.grtSupplierDriverDataAdmin.length >
              0 ? ( */}
              <>
                {supplierDrivers?.getSupplierDriverDataAdmin?.map(
                  (data, key) => {
                    return (
                      <DeliveryAgentListCard
                        supplierId={props.supplierId}
                        operationType={operationType}
                        employee_type={employee_type}
                        key={key}
                        onRefresh={handleAddUpdateDialogClose}
                        user_id={data.user_id}
                        deliver_Id={data.driver_id}
                        fullname={data.driver_profile.fullname}
                        phone={data.driver_profile.phone}
                        data={data}
                        handleUpdateDeliveryAgent={handleUpdateDeliveryAgent}
                      />
                    );
                  }
                )}
              </>
              {/* ) : (
                "No Data"
              )} */}
            </>
          ) : (
            "No Data Available"
          )}
        </Material.CardContent>
      </Material.Card>
      {openAddDeliveryAgentDialog ? (
        <AddorUpdateDeliveryAgentDialog
          employee_type={employee_type}
          operationType={operationType}
          openAddDeliveryAgentDialog={openAddDeliveryAgentDialog}
          setOpenAddDeliveryAgentDialog={setOpenAddDeliveryAgentDialog}
          setUpdateClicked={setUpdateClicked}
          updateClicked={updateClicked}
          isUpdate={isUpdate}
          handleCancle={handleCancleDialog}
          singleData={singleData}
          supplierIntegerId={props.supplierId}
          supplierId={props.supplierId}
          handleDialogClose={handleAddUpdateDialogClose}
          // handleAddDeliveryAgent={handleAddOrUpdateDeliveryAgent}
        />
      ) : (
        ""
      )}
    </Material.Box>
  );
}

export default SupplierDriversList;
