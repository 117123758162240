/**
 * @author vinay kumar
 * @description Book Now
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 22-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SampleButton from "../../Components/Buttons/SampleButton";
import { PostRequestSlotForFilling } from "../Redux/Actions/supliersAction";
import { ConstructionOutlined } from "@mui/icons-material";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import DistributorOrderDialog from "../../Components/Dialog/distributorWfsOrderDialog";

/**
 * @author vinay kumar
 * @description placing new filled cans & returning emppty cans
 * @date 22/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 * @returns {booked New filled cans}
 */

function BookNowMain() {
  //set order Quantity
  const [orderQuantity, setOrderQty] = React.useState(0);
  const userDetailsUpdate = useSelector(
    (state) => state.userDetailsUpdate.address
  );
  const [openPlaceOrder, setOpenPlaceOrder] = React.useState(false);
  const [driverId, setDriverId] = React.useState("");
  CustomConsole(orderQuantity);
  //set empty cans
  const [emptyCansSubmittingForFilling, setEmptyCansSubmittingForFilling] =
    React.useState(0);
  const dispatch = useDispatch();
  /**
   * @author vinay kumar
   * @description place empty cans less than are equal to order Ouantity or (place both equal cans)
   * @date 22/06/23
   * @version 0.0.1 - Initial Release
   * @param {}
   * @returns {placing order}
   */
  const handleRequestSlotForFilling = () => {
    if (driverId !== "") {
      setOpenPlaceOrder(true);
    } else {
      toast.error("Please select ware house address to proceed", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleChangeOrderQuantity = (event) => {
    const value = event.target.value;

    // Check if the entered value is a positive number
    if ((/^\d*\.?\d*$/.test(value) && parseFloat(value) > 0) || value === "") {
      parseInt(emptyCansSubmittingForFilling) > parseInt(event.target.value)
        ? setOrderQty(orderQuantity)
        : setOrderQty(event.target.value);
    } else {
      toast.error("Please Edit More Than Zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  //useeffect for getting waterhousedetails
  React.useEffect(() => {
    dispatch(GetFilteredUserAddressAdmin());
  }, []);

  //function for handling assign delivery agent
  const handleSelectedProduct = (e) => {
    let r = e.target.value;
    // const userId = userDetailsUpdate.find((data) => {
    //   return data.address_id === e.target.value;
    // });
    setDriverId(e.target.value);
    CustomConsole(e.target.value);
  };

  //function for closing place order dialog
  const handelPlaceOrderClose = () => {
    setOpenPlaceOrder(false);
  };
  return (
    <Material.Box sx={{ marginTop: "60px" }}>
      <Material.Grid container spacing={2}>
        {/* Heading for booking note */}
        <Material.Grid item md={12}>
          <Material.Typography>
            You can place your order for booking here.
          </Material.Typography>
          <Material.Typography></Material.Typography>
        </Material.Grid>
        {/* Enter Order quantity here */}
        <Material.Grid item md={6} xs={12}>
          <Box sx={{ display: "flex" }}>
            <TextField
              id="outlined-select-currency-native"
              select
              sx={{
                width: { sm: "100%", xs: "100%" },
              }}
              size="small"
              value={driverId}
              onChange={handleSelectedProduct}
              SelectProps={{
                native: true,
              }}
            >
              <option value="" disabled>
                Select WareHouse
              </option>
              {userDetailsUpdate?.map((option) => (
                <option key={option.address_id} value={option.address_id}>
                  {option.contact_name}/{option.address_line1}/
                  {option.address_line2}/ {option.pincode}
                </option>
              ))}
            </TextField>
          </Box>
        </Material.Grid>
        {/* Enter Empty Cans quantity here */}

        {/* click and place order here */}
        <Material.Grid item md={6} xs={12}>
          <SampleButton
            title="Request Slot For Filling "
            size="large"
            handleClick={handleRequestSlotForFilling}
          />
        </Material.Grid>
      </Material.Grid>
      {openPlaceOrder && (
        <DistributorOrderDialog
          open={openPlaceOrder}
          onClose={handelPlaceOrderClose}
          userId={""}
          address={driverId}
        />
      )}
    </Material.Box>
  );
}

export default BookNowMain;
