import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  sales_other_products: [],
  sales_twenty_ltr_summary: {},
  paymentNewSummary: [],
  product_Summary: [],
  users_summary: {},
  deposits_summary: [],
  wallet_balance: 0,
};

const NewDashboardReducer = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_NEW_SALES_SUMMARY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        order_summary: action.payload.order_summary,
        sales_other_products: action.payload.other_products,
        sales_twenty_ltr_summary: action.payload.twenty_ltr_summary,
      };
    case actionType.GET_NEW_PAYMENT_SUMMARY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        paymentNewSummary: action.payload.data,
      };
    case actionType.GET_PRODUCT_SALES_SUMMARY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        product_Summary: action.payload.products,
        orderSummary: action.payload.orders,
      };
    case actionType.GET_DEPOSITS_USERS_WALLET_SUMMARY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        deposits_summary: action.payload.data.deposits,
        users_summary: action.payload.data.users,
        wallet_balance: action.payload.data.wallet_balance,
      };
    default:
      return state;
  }
};
export default NewDashboardReducer;
