import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import AdminInfluencerRequestTable from "./AdminInfluencerRequestTable";
import { useSelector } from "react-redux";
import { useState } from "react";
import AdminInfluencerCard from "./AdminInfluencerCard";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import AdminInfluencersSearchCard from "./AdminInfluencersSearchCard";
import CustomConsole from "../../CustomConsole";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AdminSettlement from "../AdminSettlement/AdminSettlement";

function AdminInfluencers() {
  const influencerData = useSelector((state) => state.influencerDetails);
  const [detailsCardFlag, setDetailsCardFlag] = useState(false);
  const scrollDownRef = React.useRef(null);
  const [pageNumberReload, setPageNumberReload] = useState(1);
  const [influencer, setInfluencer] = useState("");
  const [searchRestrictFlag, setSearchRestrictFlag] = useState(false);
  const [tableView, setTableview] = useState(false);

  const [alignmentInfluncer, setalignmentInfluncer] =
    React.useState("Influncer");

  const handleChangeInfluncer = (event, newAlignment) => {
    setalignmentInfluncer(newAlignment);
  };

  const scrollDownToCard = () => {
    scrollDownRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    CustomConsole(influencerData.influencerData);
  }, []);

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={alignmentInfluncer}
        exclusive
        onChange={handleChangeInfluncer}
      >
        <ToggleButton value="Influncer">Users</ToggleButton>
        <ToggleButton value="InfluncerSettlement">Settlements</ToggleButton>
      </ToggleButtonGroup>
      {alignmentInfluncer === "Influncer" ? (
        <>
          {parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_SUPERADMIN ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
          parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_SALESHEAD ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPS ||
          parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPSHEAD ||
          parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_FINANCEHEAD ||
          parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_FINANCE ? (
            <Container
              sx={{
                // marginTop: "10px",
                padding: "0px",
              }}
            >
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <AdminInfluencersSearchCard
                pageNumberReload={pageNumberReload}
                influencerData={influencerData}
                setInfluencer={setInfluencer}
                setDetailsCardFlag={setDetailsCardFlag}
                setPageNumberReload={setPageNumberReload}
                setSearchRestrictFlag={setSearchRestrictFlag}
                setTableview={setTableview}
              />
            </Grid> */}
                {/* {tableView ? ( */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AdminInfluencerRequestTable
                    setDetailsCardFlag={setDetailsCardFlag}
                    scrollDownToCard={scrollDownToCard}
                    setPageNumberReload={setPageNumberReload}
                    influencer={influencer}
                    pageNumberReload={pageNumberReload}
                    setSearchRestrictFlag={setSearchRestrictFlag}
                    searchRestrictFlag={searchRestrictFlag}
                    setInfluencer={setInfluencer}
                    setTableview={setTableview}
                  />
                </Grid>
                {/* ) : null} */}

                {detailsCardFlag && influencerData.requestedDetails ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AdminInfluencerCard
                      scrollDownRef={scrollDownRef}
                      pageNumberReload={pageNumberReload}
                      setDetailsCardFlag={setDetailsCardFlag}
                      influencer={influencer}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Container>
          ) : (
            <Box sx={{ marginTop: "50px" }}>
              <Alert severity="error">Access Denied</Alert>
            </Box>
          )}
        </>
      ) : (
        <AdminSettlement />
      )}
    </>
  );
}

export default AdminInfluencers;
