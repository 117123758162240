import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Paper } from "@mui/material";
import IotDeviceInfoCard1 from "./IotDeviceInfoCard1";
import CustomConsole from "../../CustomConsole";

export default function IotDevicesCard({
  selectedDeviceData,
  scrollRef,
  addNewDevice,
  IotDevices,
  alignment,
  setAlignment,
}) {
  return (
    <Paper elevation={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: { xs: "90vw", sm: "70vw", md: "60vw" },
          borderRadius: "10px",
        }}
      >
        <Box ref={scrollRef}>
          <IotDeviceInfoCard1
            selectedDeviceData={selectedDeviceData}
            addNewDevice={addNewDevice}
            IotDevices={IotDevices}
            alignment={alignment}
            setAlignment={setAlignment}
          />
        </Box>
      </Box>
    </Paper>
  );
}
