import React, { useState } from "react";
import * as Material from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddorUpdateWarehouseDialog from "../../Components/DialogBoxes/SupplierDialogBox/AddorUpdateWarehouseDialog";
import {
  GetWarehouseWithEmployees,
  GetSupplierDeliveryAgentDetailsAdmin,
} from "../../Pages/Redux/Actions/supliersAction";

function SuplierwarehouseCard({
  warehouseDetails,
  supplierIntegerId,
  supplierId,
}) {
  const [editWarehouse, setEditWarehouse] = useState(false);
  const warehouseDetailsEmployees = useSelector(
    (state) => state.supplierDeliveries.warehouseEmployees
  );
  const dispatch = useDispatch();

  const onWarehouseEdit = () => {
    // dispatch(
    //   GetWarehouseWithEmployees({ address_id: warehouseDetails.address_id })
    // );

    // dispatch(
    //   GetSupplierDeliveryAgentDetailsAdmin({
    //     // supplier_id: supplierId,
    //   })
    // );
    setEditWarehouse(true);
  };
  return (
    <Material.Box>
      <Material.Card
        elevation={24}
        sx={{
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
          },
        }}
      >
        <Material.CardContent>
          <Material.Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={2}
            alignItems={"center"}
          >
            <Material.Grid container>
              <Material.Grid
                item
                lg={3}
                md={6}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 2 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>Name: </Material.Box>
                <Material.Box>{warehouseDetails.contact_name}</Material.Box>
              </Material.Grid>
              <Material.Grid
                item
                lg={3}
                md={6}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 2 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>
                  Pin Code:{" "}
                </Material.Box>
                <Material.Box>{warehouseDetails.pincode}</Material.Box>
              </Material.Grid>
              <Material.Grid
                item
                lg={3}
                md={6}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 2 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>Area : </Material.Box>
                <Material.Box>{warehouseDetails.service_area}</Material.Box>
              </Material.Grid>
              <Material.Grid
                item
                lg={3}
                md={6}
                sm={12}
                xs={12}
                sx={{ display: "flex", gap: 2 }}
              >
                <Material.Box sx={{ fontWeight: "bold" }}>Phone </Material.Box>
                <Material.Box>{warehouseDetails.contact_phone}</Material.Box>
              </Material.Grid>
            </Material.Grid>
            <Material.Box>
              <Material.Button
                onClick={() => onWarehouseEdit()}
                variant="contained"
              >
                Edit
              </Material.Button>
            </Material.Box>
          </Material.Box>
          {editWarehouse && (
            <AddorUpdateWarehouseDialog
              supplierIntegerId={supplierIntegerId}
              warehouseDetails={warehouseDetailsEmployees}
              isOpen={editWarehouse}
              details={warehouseDetails}
              supplierId={supplierId}
              handleClose={() => setEditWarehouse(false)}
            />
          )}
        </Material.CardContent>
      </Material.Card>
    </Material.Box>
  );
}

export default SuplierwarehouseCard;
