import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialOrdersState = {
  error: true,
  msg: "",
  admin_settings: {},
  pdw_pincodes_areanames: [],
  packagedDrinkingWater: {},
  updatingMsg: "",
  blockedPayments: [],
  blockedBookings: {},
  appUpdateDetails:{}
};

const appSettingsReducers = (state = initialOrdersState, action) => {
  switch (action.type) {
    case actionType.GET_APP_SETTINGS:
      CustomConsole("---Store Updated-----");
      CustomConsole(action);
      return {
        ...state,
        error: action.payload.error,
        admin_settings: action.payload.admin_settings,
      };
    case actionType.UPDATE_APP_SETTINGS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.ADD_DISTRIBUTOR:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_DISTRIBUTOR_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        pdw_pincodes_areanames:
          action.payload.pdw_pincodes_areanames !== undefined
            ? action.payload.pdw_pincodes_areanames
            : [],
      };
    case actionType.ASSIGNING_DELIVERING_AREA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GETTING_PDW_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        packagedDrinkingWater: action.payload.packagedDrinkingwater,
      };
    case actionType.UPDATING_PDW_RATE:
      return {
        ...state,
        error: action.payload.error,
        updatingMsg: action.payload.msg,
      };
    case actionType.UPDATING_TRANS_RATE:
      return {
        ...state,
        error: action.payload.error,
        updatingMsg: action.payload.msg,
      };
    case actionType.REMOVING_TRANS_RATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_BLOCKED_PAYMENT_METHODS:
      return {
        ...state,
        error: action.payload.error,
        blockedPayments: action.payload.payment_methods,
      };
    case actionType.GET_BLOCKED_BOOKINGS:
      return {
        ...state,
        error: action.payload.error,
        blockedBookings: action.payload.bookings ? action.payload.bookings : {},
      };
      case actionType.ADMIN_APPUPDATE_STATUS:
        return {
          ...state,
          error: action.payload.error,
          appUpdateDetails: action.payload.consumer_updates ? action.payload.consumer_updates : {},
        };
    default:
      return state;
  }
};

export default appSettingsReducers;
