import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
// import React from "react";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const GetWfsLineDetails = (wfsId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/TM/getWfsLineDetails?wfs_id=${wfsId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WFS_LINE_DETAILS,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in Action Error");
        // toast.error(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      }
    } catch (error) {
      CustomConsole(error);
      //   toast.error("Error getting assigned WFS line details", {
      //     position: "bottom-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });
    }
  };
};

export const AddWfsLine = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/admin/addWfsLine`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_WFS_LINE,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error("Error getting assigned WFS line details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetWaterFillingStations = (pincode, filter, page_number) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/TM/getWaterFillingStations?date=${filter}&page_number=${page_number}&pincode=${pincode}&isAssignedWfs=0&availability=true&in_stock=0`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WATERFILLING_STATIONS,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error("Error getting assigned WFS details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const AssignWfsToUser = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/admin/assignInchargeToWfs`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ASSIGN_WFS_USER,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        CustomConsole("ELSE: Response in Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(
        "User Already Assigned to Other Water Filling Station, Please Disable Existing and Try Again!!!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
};
export const PlaceOrderEmptyCans = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/submitEmptyCansToWfsIncharge`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EMPTY_CANS_ORDER,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
