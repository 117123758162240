import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: "",
  msg: "",
  allData: [],
  singlePincodeData:[],
};

const pincodeServiceAreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADDING_NEW_PINCODE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GETTING_ACTIVE_PINCODES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        allData:action.payload.pincodeList,
      };
    case actionType.ADDING_NEW_SERVICEAREA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.SEARCH_AREA_PINCODES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        singlePincodeData:action.payload.data,
      };
    case actionType.EDIT_PINCODE_AREA_DATA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default pincodeServiceAreaReducer;
