import React from "react";
import * as Material from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {
  ShowImagesInApp,
  getFiles,
} from "../../Pages/Redux/Actions/fileHandlingAction";
import userLogo from "./../Assests/Images/userLogo.png";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";

function DriverProfileCard(props) {
  CustomConsole(props);
  CustomConsole(props.profileDetails);
  const profilePic = useSelector((state) => state.fileHandler);
  const dispatch = useDispatch();

  React.useEffect(() => {
    CustomConsole("---- data ----");
    CustomConsole(profilePic);
  }, [profilePic]);
  React.useEffect(() => {
    CustomConsole("---- data line driver profile card 19----");
    CustomConsole(props.profileDetails !== undefined);
    CustomConsole(props.profileDetails?.profile_pic_url !== undefined);
    CustomConsole(props.profileDetails?.profile_pic_url);
    CustomConsole(props.profileDetails);
    if (
      props.profileDetails !== undefined &&
      props.profileDetails?.profile_pic_url !== undefined &&
      props.profileDetails?.profile_pic_url.length
    ) {
      dispatch(ShowImagesInApp(props.profileDetails?.profile_pic_url));
    }
  }, [props.profileDetails]);
  return (
    <Material.Box>
      <Material.Card>
        <Material.CardContent>
          {/* <Material.Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          > */}
          {/* <Avatar
              alt="Remy Sharp"
              src={props.profileDetails?.profile_pic_url}
            /> */}
          <img
            style={{ height: "100px", width: "100px" }}
            src={
              profilePic.filePath !== undefined &&
              profilePic.filePath !== null &&
              profilePic.filePath !== ""
                ? profilePic.filePath
                : userLogo
            }
            alt=""
          />
          <Material.Box sx={{ marginLeft: "15px" }}>
            <Material.Box
              sx={{
                marginTop: "15px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",

                  textAlign: "center",
                }}
              >
                Delivery Agent ID:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.userDetails?.driver_id || "-"}
                </span>
              </Material.Typography>

              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  // alignItems: "center",
                  textAlign: "center",
                }}
              >
                Name :{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.profileDetails?.fullname}
                </span>
              </Material.Typography>
              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  alignItems: "flex-start",
                  textAlign: "center",
                }}
              >
                phone :{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.profileDetails?.phone}
                </span>
              </Material.Typography>
              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",

                  textAlign: "center",
                }}
              >
                Email:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.userDetails?.email || "-"}
                </span>
              </Material.Typography>
            </Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",

                  textAlign: "center",
                }}
              >
                Emergency phone :{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.profileDetails?.emergency_contact !== undefined &&
                  props.profileDetails?.emergency_contact !== null
                    ? props.profileDetails?.emergency_contact
                    : "-"}
                </span>
              </Material.Typography>
              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",

                  textAlign: "center",
                }}
              >
                Licence Number:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.profileDetails?.license_no
                    ? props.profileDetails?.license_no
                    : "-"}
                </span>
              </Material.Typography>
            </Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",

                  textAlign: "center",
                }}
              >
                Badge Number:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.profileDetails?.badge_no
                    ? props.profileDetails?.badge_no
                    : "-"}
                </span>
              </Material.Typography>
              <Material.Typography
                sx={{
                  fontWeight: "bold",
                  color: "blue",

                  textAlign: "center",
                }}
              >
                Address:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",

                    textAlign: "center",
                  }}
                >
                  {props.profileDetails?.address_line1
                    ? props.profileDetails?.address_line1
                    : "-"}
                  <br />
                  {props.profileDetails?.address_line1
                    ? props.profileDetails?.address_line2
                    : ""}
                  <br />
                  {props.profileDetails?.address_line1
                    ? props.profileDetails?.address_line3
                    : ""}
                  <br />
                  {props.profileDetails?.city ? props.profileDetails?.city : ""}
                  <br />
                  {props.profileDetails?.address_line1
                    ? props.profileDetails?.pincode
                    : ""}
                </span>
              </Material.Typography>
            </Material.Box>
          </Material.Box>
          {/* </Material.Box> */}
        </Material.CardContent>
      </Material.Card>
    </Material.Box>
  );
}

export default DriverProfileCard;
