import React from "react";
import BuyBackOrderDetailsTable from "../../Components/Tables/BuyBackOrderDetailsTabel";

export default function BuyBackOrderMain() {
  return (
    <>
      <BuyBackOrderDetailsTable page_size={3}/>
    </>
  );
}
