import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
import { ToastContainer, toast } from "react-toastify";

export const GetPostpaidOrders = (
  emailPhone,
  orderby,
  status,
  pageNumber,
  is_settlement,
  pageSize
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/admin/postpaidrequest?phoneNumber=${emailPhone}&status=${status}&orderby=${orderby}&pageNumber=${pageNumber}&is_settlement=${is_settlement}&pageSize=10`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_POSTPAID_ORDERS,
          payload: response.data,
        });
        CustomConsole(response);
        if (response.data.postpaidrequest.length === 0) {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const PostpaidOrder = (orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(orderId);
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/admin/postpaidrequest/${orderId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_ORDER,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const RejectPostpaidOrder = (req) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  CustomConsole(req);
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/updatepostpaid`, req);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REJECT_POSTPAID_ORDER,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      CustomConsole("ELSE: Response in reject postpaid Action Error");
      toast.error("Error in rejecting", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    <ToastContainer />;
  };
};

export const SelectSuppiersPostpaid = (orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  CustomConsole(orderId);
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/displaySuppliers/postpaidorders`, {
        order_id: orderId,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUPPLIER_ADMIN,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in select supplier Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error("Error in getting supplier list", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    <ToastContainer />;
  };
};
export const UpdatePostpaidPrice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/admin/updatepostpaidprice`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_POSTPAID_PRICE,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in select supplier Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error("Error in getting suppier list", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    <ToastContainer />;
  };
};

export const GetWalletDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("----GetWalletDetails--------");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `PM/payments/accounts/wallet?user_id=${userId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_WALLET_DETAILS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

export const NotConfirmedOrderDetails = (odr_Id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/pdwNotconfirmedorders?order_id=${odr_Id}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.NOT_CONFIRMED_ORDER_DET,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

export const AssignSupplier = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/AssignSupplier`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ASSIGNING_SUPPLIERS,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      }else{
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg ,{
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const GetPostpaidOrdersbyPhone = (phone, is_settlement) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/admin/postpaidrequest?phoneNumber=${phone}&is_settlement=${is_settlement}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_POSTPAID_ORDERS_BYPHONE,
          payload: response.data,
        });
        CustomConsole(response);
        if (response.data.postpaidrequest.length === 0) {
          toast.error("No Data Found", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error("Please enter Postpaid Number", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const UpdateAllPostpaidPrice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/admin/updateallpostpaidprice`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_ALLPOSTPAID_PRICE,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        CustomConsole("ELSE: Response in select supplier Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      if (error.response.data.msg != "") {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Payment alredy done", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
    <ToastContainer />;
  };
};
