import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";

import CustomConsole from "../../../CustomConsole";
export const PayThroughWallet = (data) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/payments/webhook/waterWalletPay`,
        data
      );
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(
          `Payment of Rs.${data.amount} for Order Id: ${data.order_id} successfully paid`,
          {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          }
        );
        dispatch({
          type: actionType.PAY_THROUGH_WALLET,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };
};
