import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  wfsLineDetails: [],
  addWfsLineMsg: "",
  allFillingStationsDetails: [],
  placeOrderMsg: "",
  emptyCanOrderId: "",
};

const adminWfsLineDetailsReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.GET_WFS_LINE_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        wfsLineDetails: action.payload.data,
      };
    case actionType.ADD_WFS_LINE:
      return {
        ...state,
        error: action.payload.error,
        addWfsLineMsg: action.payload.msg,
      };
    case actionType.GET_WATERFILLING_STATIONS:
      return {
        ...state,
        error: action.payload.error,
        allFillingStationsDetails: action.payload.data,
        msg: action.payload.msg
      };
    case actionType.EMPTY_CANS_ORDER:
      return {
        ...state,
        error: action.payload.error,
        placeOrderMsg: action.payload.msg,
        emptyCanOrderId: action.payload.ec_order_id,
      };

    default:
      return state;
  }
};

export default adminWfsLineDetailsReducer;
