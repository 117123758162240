import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import * as MuiIcons from "@mui/icons-material";
import TableBody from "@mui/material/TableBody";
import * as Material from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableHead from "@mui/material/TableHead";
import CustomConsole from "../../CustomConsole";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../Common/drawer.css";
import moment from "moment";
import WfsDetailsFilterDialog from "../../Components/Dialog/wfsFilterDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GetWfsDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";

export default function WfsDetailsTable({
  setWfsDetails,
  setAddWfsFlag,
  setTrigEffect,
  triggEffect,
  wfsFilters,
  setWfsFilters,
}) {
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  //dialoge
  const [wfsFilterDialog, setWfsFilterDialog] = React.useState(false);

  const handleTableClick = (wfsId) => {
    skuWfsDetails.wfsDetails.map((data) => {
      CustomConsole("--inside map--");
      if (data.wfs_master_id === wfsId) {
        CustomConsole(data);
        setWfsDetails(data);
        setTrigEffect(triggEffect + 1);
      }
    });
    setAddWfsFlag(true);
  };

  const handleClickOpen = () => {
    setWfsFilterDialog(true);
  };
  const handleClose = () => {
    setWfsFilterDialog(false);
  };

  // const filterApply = () => {
  //   handleClickWfs();
  //   setPageNumber(1);
  //   setOpen(false);
  // };

  //function for getting wfs details
  const handelWfsFilterSubmit = (data) => {
    dispatch(GetWfsDetailsSku(wfsFilters));
    handleClose();
  };
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setWfsFilters((prev) => ({ ...prev, page_size: event.target.value }));
  };
  const handleSearchPageSize = () => {
    dispatch(GetWfsDetailsSku(wfsFilters));
  };

  // React.useEffect(() => {
  //   dispatch(GetWfsDetailsSku(wfsFilters));
  // }, [wfsFilters.page_number]);
  return (
    <>
      <Card sx={{ boxShadow: 5 }}>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            alignContent: "center",
          }}
        >
          <Material.Button
            onClick={handleClickOpen}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Material.Button>
          <Material.TextField
            size="small"
            value={wfsFilters.page_size}
            sx={{ width: "100px" }}
            label="Page size"
            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          />

          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.IconButton
              size="small"
              onClick={() => {
                if (wfsFilters.page_number - 1 > 0) {
                  setWfsFilters((prev) => ({
                    ...prev,
                    page_number: prev.page_number - 1,
                  }));
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{ color: wfsFilters.page_number - 1 > 0 ? "blue" : "gray" }}
              />
            </Material.IconButton>
            <Material.Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {wfsFilters.page_number}
            </Material.Typography>
            <Material.IconButton
              size="small"
              onClick={() => {
                if (wfsFilters.page_size == skuWfsDetails.wfsDetails?.length) {
                  setWfsFilters((prev) => ({
                    ...prev,
                    page_number: prev.page_number + 1,
                  }));
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color: skuWfsDetails.wfsDetails?.length ? "blue" : "gray",
                }}
              />
            </Material.IconButton>
          </Material.Box>
        </Material.Box>
        {skuWfsDetails.wfsDetails.length > 0 ? (
          <Tooltip title="Click Edit to update WFS details">
            <CardContent>
              <TableContainer
                component={Paper}
                className="drawer"
                sx={{ maxHeight: { md: "90vh", xs: "60Vh" }, overflow: "auto" }}
              >
                <Table sx={{ width: 1 }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>S.no</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Company</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Owner</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Incharge
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>PAN</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Phone</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Created</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        View Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {skuWfsDetails.wfsDetails.map((row, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          marginTop: 20,
                          cursor: "pointer",
                          // "&:hover": {
                          //   background: "rgb(208,221,242)",
                          //   backgroundImage:
                          //     "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
                          // },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {key + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.company_name}
                        </TableCell>
                        <TableCell>{row.owner_name}</TableCell>
                        <TableCell>{row.incharge_details.fullname}</TableCell>
                        <TableCell>{row.company_pan_number}</TableCell>
                        <TableCell>{row.owner_phone}</TableCell>
                        <TableCell>
                          {moment(row.created_at).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0.5,
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() =>
                                handleTableClick(row.wfs_master_id)
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              disabled={row.incharge_details.phone === null}
                              onClick={() =>
                                history.push(
                                  `/dashboard/wfscanorders?phoneNumber=${row.incharge_details.phone}&wfs=${row.wfs_master_id}`
                                )
                              }
                            >
                              Orders
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Tooltip>
        ) : disableBtn ? (
          <b>No Data Available</b>
        ) : null}
      </Card>
      <WfsDetailsFilterDialog
        open={wfsFilterDialog}
        onClose={handleClose}
        onSubmit={handelWfsFilterSubmit}
        wfsFilters={wfsFilters}
        setWfsFilters={setWfsFilters}
      />
    </>
  );
}
