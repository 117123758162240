import React from "react";
import BuyBackOrderDetailsTable from "../../Components/Tables/BuyBackOrderDetailsTabel";
import CreditNoteDetailsTabel from "../../Components/Tables/CreditNoteDetailsTabel";

export default function CreditNoteDetailsMain() {
  return (
    <>
      <CreditNoteDetailsTabel page_size={3}/>
    </>
  );
}
