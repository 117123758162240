import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { RECYCLE_STATUS_CODE } from "../../Components/AppMeta/appMetaConfig";
import { Close } from "@mui/icons-material";

function RecycleFilterDialog(props) {
  const handleClose = () => {
    props.handleClose();
  };
  const handleFilter = () => {
    props.handleFilter();
  };
  const handleClearFilter = () => {
    props.setOrderStatusSelect("");
  };
  return (
    <Box>
      <Dialog open={props.openRecycleFilterDialog}>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
            {" "}
            <Button
              variant="contained"
              onClick={handleClose}
              color="error"
              size="small"
            >
              <Close />
            </Button>
          </Box>
          <Typography
            sx={{
              marginBottom: "20px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Filter Based On Status
          </Typography>
          <Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel>Order Status</InputLabel>
              <Select
                value={props.orderStatusSelect}
                label="Order Status"
                onChange={(event) =>
                  props.setOrderStatusSelect(event.target.value)
                }
              >
                <MenuItem value={RECYCLE_STATUS_CODE.REQUESTED}>
                  REQUESTED
                </MenuItem>
                <MenuItem value={RECYCLE_STATUS_CODE.ACCEPTED}>
                  ACCEPTED
                </MenuItem>
                <MenuItem value={RECYCLE_STATUS_CODE.STARTED}>STARTED</MenuItem>
                <MenuItem value={RECYCLE_STATUS_CODE.REJECTED}>
                  REJECTED
                </MenuItem>
                <MenuItem value={RECYCLE_STATUS_CODE.CANCELLED}>
                  CANCELLED
                </MenuItem>
                <MenuItem value={RECYCLE_STATUS_CODE.FINISHED}>
                  FINISHED
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClearFilter}
              color="error"
            >
              Clear
            </Button>
            <Button variant="contained" onClick={handleFilter}>
              {props.orderStatusSelect ? "Filter" : "Done"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default RecycleFilterDialog;
