import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: "",
  msg: "",
  adminRoleList: [],
  addingError: false,

  addingMsg: "",
};

const superAdminReducer = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.ADD_NEW_ADMIN_ROLE:
      return {
        ...state,
        addingError: action.payload.error,
        addingMsg: action.payload.msg,
      };
    case actionType.GET_ADMIN_ROLE_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        adminRoleList: action.payload.data,
      };
    case actionType.EDIT_ADMIN_ROLES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        adminRoleList: [],
      };
    default:
      return state;
  }
};

export default superAdminReducer;
