import { FileUpload } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MD5 } from "crypto-js";
import React, { useEffect } from "react";
import {
  employeeType,
  expense_type,
} from "../../Components/AppMeta/appMetaConfig";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { GetSupplierDeliveryAgentDetailsAdmin } from "../Redux/Actions/supliersAction";

function EmployeeAddCostDialog(props) {
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);

  const [selectedEmployeeName, setSelectedEmployeeName] = React.useState(null);
  const [selectedEmployeesDisignation, setSelectedEmployeesDisignation] =
    React.useState(null);
  const [selectedExpenseType, setSelectedExpenseType] = React.useState(null);
  const [selectedSupplierId, setSelectedSupplierId] = React.useState(null);
  const uploadPaymentRecipt = React.useRef(null);
  const [purpose, setPurpose] = React.useState("");
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState();
  const [selectMonth, setSelectMonth] = React.useState("");
  const [paidDate, setPaidDate] = React.useState(
    moment().tz("Asia/Kolkata").format("YYYY-MM-DD")
  );
  const [seqid, setSeqid] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [reciptupload, setReciptupload] = React.useState("");
  const [uploadReciptFile, setUploadReciptFile] = React.useState("");
  console.log(reciptupload);
  const [uploadReciptBtn, setUploadReciptBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [nextDueDate, setNextDueDate] = React.useState();
  const [reciptUrl, setReciptUrl] = React.useState("");
  const [designationList, setDesignationList] = React.useState([]);

  //function for handling file upload opreation
  const handleUploadRecipt = () => {
    uploadPaymentRecipt.current.click();
  };
  //useEffect for handling file upload operation
  React.useEffect(() => {
    if (uploadReciptFile !== "") {
      dispatch(UploadFile(uploadReciptFile));
      setUploadReciptBtn(true);
      setUploadReciptFile("");
      setUploadFlags(true);
    }
  }, [uploadReciptFile]);
  //file handler
  React.useEffect(() => {
    console.log(fileHandler);
    if (fileHandler.isFileUploaded === true && uploadReciptBtn === true) {
      setReciptUrl(fileHandler.fileName);
      setUploadReciptBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("Recipt  uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);

  React.useEffect(() => {
    console.log("---props----", props.singleOperationCostDetails);
    if (props.openEditSingleOperationCost) {
      console.log("---props----", props.singleOperationCostDetails);
      setToDate(
        moment(props.singleOperationCostDetails?.details?.to_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
      setFromDate(
        moment(props.singleOperationCostDetails?.details?.to_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
      setSelectedSupplierId({
        name: props.singleOperationCostDetails.supplier?.supplier_profile
          ?.company_name,
      });
      setSelectedEmployeeName({
        name: props.singleOperationCostDetails.employee_details?.driver_profile
          ?.fullname,
      });
      setSelectedEmployeesDisignation({
        name:
          props.singleOperationCostDetails.employee_details?.driver_profile
            ?.employee_type === 1
            ? "MANGER"
            : props.singleOperationCostDetails.employee_details?.driver_profile
                ?.employee_type === 2
            ? "DRIVER"
            : props.singleOperationCostDetails.employee_details?.driver_profile
                ?.employee_type === 3
            ? "LOADER"
            : "DRIVER",
      });
      setReciptUrl(props.singleOperationCostDetails.payment_receipt);
      setSelectedExpenseType(props.singleOperationCostDetails.expense_type);
      setAmount(props.singleOperationCostDetails.amount);
      setSeqid(props.singleOperationCostDetails.id);
      setPaidDate(
        moment(props.singleOperationCostDetails.paid_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
      setNextDueDate(
        moment(props.singleOperationCostDetails.due_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
      setPurpose(props.singleOperationCostDetails.details?.reason);
      setSelectMonth(props.singleOperationCostDetails.details?.month);
    }
  }, [props.openEditSingleOperationCost]);

  React.useEffect(() => {
    if (selectedEmployeesDisignation?.employee_type) {
      setSelectedEmployeeName(null);
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          // user_id: selectedSupplierId.user_id,
          supplier_id: selectedSupplierId?.supplier_id,
          employee_type: selectedEmployeesDisignation?.employee_type,
        })
      );
    }
  }, [selectedEmployeesDisignation]);

  React.useEffect(() => {
    if (selectedSupplierId?.supplier_id !== "") {
      const designationList = [
        { label: "MANAGERS", employee_type: 1 },
        { label: "DRIVERS", employee_type: 2 },
        { label: "LOADERS", employee_type: 3 },
      ];
      setDesignationList(designationList);
    }
  }, [selectedSupplierId?.supplier_id]);

  const handleCloseEmployeeAddDialog = () => {
    props.handleCloseEmployeeAddDialog();
  };

  const handleSubmitEmployee = () => {
    if (selectedEmployeeName?.driver_id) {
      props.handleSubmitEmployee({
        selectedExpenseType: selectedExpenseType,
        selectMonth: selectMonth,
        amount: amount,
        paidDate: paidDate,
        reciptUrl: reciptUrl,
        nextDueDate: nextDueDate,
        purpose: purpose,
        toDate: toDate,
        fromDate: fromDate,
        selectedEmployeeid: selectedEmployeeName?.driver_id,
        selectedSupplierid: selectedSupplierId?.supplier_id,
        seqid: seqid,
      });
    } else {
      toast.error("Please Select Employee Name", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <Dialog open={props.openAddEmployeeCostDialog}>
      <DialogContent>
        <Box>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "center", mb: "15px" }}>
            <Typography
              sx={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              {props.openEditSingleOperationCost === true ? "EDIT" : "ADD"}{" "}
              EMPLOYEE OPERATING COST
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              // justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Autocomplete
              disabled={!props.supplierDeliveries?.supplierList?.length}
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                (props.supplierDeliveries?.supplierList !== undefined &&
                  props.supplierDeliveries?.supplierList !== null &&
                  props.supplierDeliveries?.supplierList?.map((item, index) => {
                    return {
                      name:
                        item.supplier_name !== null ? item.supplier_name : "",
                      supplier_id: item.id,
                      user_id: item.user_id,
                    };
                  })) ||
                []
              }
              value={selectedSupplierId}
              autoHighlight
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedSupplierId(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    props.supplierDeliveries?.supplierList !== undefined &&
                    props.supplierDeliveries?.supplierList !== null &&
                    props.supplierDeliveries?.supplierList?.length > 0
                      ? "Select Supplier"
                      : "No Supplier Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              disabled={!selectedSupplierId?.supplier_id}
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                designationList?.map((item, index) => {
                  return {
                    name: item.label,
                    employee_type: item.employee_type,
                    id: index,
                  };
                }) || []
              }
              autoHighlight
              value={selectedEmployeesDisignation}
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedEmployeesDisignation(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box component="li" {...props}>
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    designationList?.length
                      ? "Select Designation"
                      : "No Designations Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              disabled={
                !props.supplierDeliveries.getSupplierDriverDataAdmin?.length
              }
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                props.supplierDeliveries.getSupplierDriverDataAdmin?.map(
                  (item, index) => {
                    return {
                      name: item.driver_profile?.fullname,
                      driver_id: item.driver_id,
                      id: index,
                    };
                  }
                ) || []
              }
              autoHighlight
              value={selectedEmployeeName}
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedEmployeeName(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    props.supplierDeliveries.getSupplierDriverDataAdmin?.length
                      ? "Select Employee"
                      : "No Employee Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <FormControl sx={{ width: 210 }} size="small">
              <InputLabel>Select Expense Type</InputLabel>
              <Select
                disabled={!selectedEmployeeName?.driver_id}
                value={selectedExpenseType}
                label="Select Expense Type"
                onChange={(event) => setSelectedExpenseType(event.target.value)}
              >
                <MenuItem value={expense_type.MONTHLY_SALARY}>
                  MONTHLY SALARY
                </MenuItem>
                <MenuItem value={expense_type.DAILY_WAGES}>
                  DAILY WAGES
                </MenuItem>
                <MenuItem value={expense_type.WEEKLY_SALARY}>
                  WEEKLY SALARY
                </MenuItem>
                <MenuItem value={expense_type.ADVANCED_SALARY}>
                  ADVANCED SALARY
                </MenuItem>
                <MenuItem value={expense_type.BONUS}>BONUS</MenuItem>
                <MenuItem value={expense_type.ALLOWANCES}>ALLOWANCES</MenuItem>
                <MenuItem value={expense_type.OTHERS}>OTHERS</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {selectedExpenseType === expense_type.MONTHLY_SALARY ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                MONTHLY SALARY
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <FormControl sx={{ width: 220 }} size="small">
                  <InputLabel>Select Month</InputLabel>
                  <Select
                    value={selectMonth}
                    label="Select Month"
                    onChange={(event) => setSelectMonth(event.target.value)}
                  >
                    <MenuItem value={1}>JAN</MenuItem>
                    <MenuItem value={2}>FEB</MenuItem>
                    <MenuItem value={3}>MARCH</MenuItem>
                    <MenuItem value={4}>APRIL</MenuItem>
                    <MenuItem value={5}>MAY</MenuItem>
                    <MenuItem value={6}>JUNE</MenuItem>
                    <MenuItem value={7}>JULY</MenuItem>
                    <MenuItem value={8}>AUG</MenuItem>
                    <MenuItem value={9}>SEP</MenuItem>
                    <MenuItem value={10}>OCT</MenuItem>
                    <MenuItem value={11}>NOV</MenuItem>
                    <MenuItem value={12}>DESC</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <Button
                  onClick={handleUploadRecipt}
                  // variant="contained"
                  size="small"
                >
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.DAILY_WAGES ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                DAILY WAGES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <Button
                  onClick={handleUploadRecipt}
                  // variant="contained"
                  size="small"
                >
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.WEEKLY_SALARY ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                WEEKLY WAGES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="From Date"
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type="date"
                  size="small"
                  label="To Date"
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <Button
                  onClick={handleUploadRecipt}
                  // variant="contained"
                  size="small"
                >
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.ADVANCED_SALARY ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                ADVANCED SALARY
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  // gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Reason"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button
                  onClick={handleUploadRecipt}
                  // variant="contained"
                  size="small"
                >
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.BONUS ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                BONUS
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Reason"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button
                  onClick={handleUploadRecipt}
                  // variant="contained"
                  size="small"
                >
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.ALLOWANCES ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                ALLOWANCES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Reason"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button
                  onClick={handleUploadRecipt}
                  // variant="contained"
                  size="small"
                >
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {selectedExpenseType === expense_type.OTHERS ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                OTHERS
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={handleCloseEmployeeAddDialog}
        >
          close
        </Button>
        <Button variant="contained" onClick={handleSubmitEmployee}>
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeAddCostDialog;
