/**
 * @author vinay kumar
 * @description Admin Deliceries Beat Plan
 * @copyright Bookwater tech pvt ltd
 * @Date 09-08-2023
 */

import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  getPincodeOrdersCount: [],
  getPincodeOrdersDetails:[],
  getBeatPlanWfsStockDetails:[]
};

const ConsumerDeliveriesPlanningReducer = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_PINCODE_ORDER_COUNTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getPincodeOrdersCount: action.payload.data,
      };
      case actionType.GET_PINCODE_ORDERS_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getPincodeOrdersDetails: action.payload.data,
      };
      case actionType.GET_BEAT_PLAN_WFS_STOCK_DETAILS:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
          getBeatPlanWfsStockDetails: action.payload.data,
        };
    default:
      return state;
  }
};
export default  ConsumerDeliveriesPlanningReducer
