export const styles = {
    stockDataStyleHeading: {
      // display: "flex",
      // alignItems: "flex-start",
      // justifyContent: "flex-start",
      padding: "0px",
      // gap: "10px",
      color: "#0a83c4",
      
     
    },
    stockDataStyleValue: {
      paddingLeft:"10px",
      fontWeight:"500",color:"black"
    },
    updateStyle:{
      padding:"10px",
      borderRadius:"10px",
      backgroundColor:"#59A6D6",
      borderRadius:"10px"
    }
  };
  