import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import OrderButtonSku from "../../Components/Buttons/orderButton";

function EditReplacementOrderDialog(props) {
  const [editReplacement, setEditReplacement] = React.useState({
    replace_qty: 0,
  });
  const handleCloseReplacementDialog = () => {
    props.handleCloseReplacementDialog();
  };
  const handleSubmitReplacementDialog = () => {
    props.handleSubmitReplacementDialog({
      qty: editReplacement.replace_qty,
    });
  };
  React.useEffect(() => {
    if (props.containerQty) {
      setEditReplacement(() => ({
        replace_qty: parseInt(props.containerQty),
      }));
    }
  }, [props.containerQty]);
  return (
    <Dialog open={props.openEditReplacementCanDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "20px" }}>
          <Button
            size="small"
            variant="contained"
            color="error"
            sx={{ maxHeight: "25px", minWidth: "14px" }}
            onClick={handleCloseReplacementDialog}
          >
            <Close />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ textAlign: "start" }}>
            Quantity : -&nbsp;&nbsp;
          </Typography>
          <OrderButtonSku
            handelAddQuantity={() =>
              setEditReplacement((prev) => ({
                ...prev,
                replace_qty: parseInt(editReplacement.replace_qty + 1),
              }))
            }
            handelMinusQuantity={() =>
              setEditReplacement((prev) => ({
                ...prev,
                replace_qty: parseInt(
                  editReplacement.replace_qty > 0
                    ? editReplacement.replace_qty - 1
                    : 0
                ),
              }))
            }
            handelQuantityChange={(event) =>
              setEditReplacement((prev) => ({
                ...prev,
                replace_qty:
                  event.target.value >= 0 && event.target.value <= 999
                    ? parseInt(event.target.value || 0)
                    : parseInt(prev.replace_qty || 0),
              }))
            }
            inputValue={parseInt(editReplacement.replace_qty)}
            // disabled={false}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={handleSubmitReplacementDialog}
          >
            submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditReplacementOrderDialog;
