

export const styles ={
    parentBox:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
    childBox:{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap:"20px"
    },
    childTypo:{
        
        color:"#4497B8",
        fontWeight:"600",
        fontFamily:"sans-serif",
    }
}