/**
 * @author Tahir Shaik
 * @description ProIndia & bookWater Marketing/Gamification reducer
 * @copyright Bookwater tech pvt ltd
 * @Date 19-09-2023
 */

import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  campDetails: [],
  playedGames: [],
  playAGame: { responseId: "" },
  userDetails: {},
  approvalStatus: false,
};

const gamificationReducer = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.GAMIFICATION_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case actionType.GET_CAMP_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        campDetails: action.payload.data,
      };
    case actionType.PLAYED_GAME_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        playedGames: action.payload.data,
      };
    case actionType.PLAY_A_GAME:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        playAGame: action.payload.data,
      };
    case actionType.GET_USER_WITH_REFERRAL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        userDetails: action.payload.data,
      };
    case actionType.GAME_APPROVE_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        approvalStatus: !action.payload.error,
      };
    default:
      return state;
  }
};
export default gamificationReducer;
