import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  qrDetails: "",
  generateQrError: true,
  careQrListData: [],
  generateQrMsg: "",
  regenerateBarMsg: "",
  linkingError: true,
  linkingMsg: "",
  reLinkingError: true,
  reLinkingMsg: "",
  pageStatus: false,
};

const skuQrBarcodeReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.GENERATE_QRCODE_CRATE:
      return {
        ...state,
        error: action.payload.error,
        generateQrError: action.payload.error,
        generateQrMsg: action.payload.msg,
      };
    case actionType.RELINK_QR_BARCODE_CRATE:
      return {
        ...state,
        reLinkingError: action.payload.error,
        reLinkingMsg: action.payload.msg,
      };
    case actionType.REGENERATE_CRATE_QRBAR_CODE:
      return {
        ...state,
        error: action.payload.error,
        regenerateBarMsg: action.payload.msg,
      };
    case actionType.CRATE_QRBARCODE_LIST:{
      return {
        ...state,
        error: action.payload.error,
        careQrListData: action.payload.data,
        pageStatus: action.payload.data.length < 25 ? true : false,
      };}
    case actionType.LINK_QR_BARCODE_CRATE:
      return {
        ...state,
        linkingError: action.payload.error,
        linkingMsg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default skuQrBarcodeReducer;
