import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import CustomConsole from "../../CustomConsole";

export default function QrDetailsListTables({ qrListData }) {
  // const { row } = props;
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    return momentEndDate;
  };

  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={appColors.commonLayoutColor}>
              <TableCell />
              <TableCell size="small">
                <strong>Start Number</strong>
              </TableCell>
              <TableCell size="small">
                <strong>End Number</strong>
              </TableCell>
              <TableCell size="small">
                <strong>Quantity</strong>
              </TableCell>
              <TableCell size="small">
                <strong>CreatedAt</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>Batch Number</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qrListData.map((row, index) => (
              <>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handelIcon(index)}
                    >
                      {openFlag ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell size="small">{row.start_number}</TableCell>
                  <TableCell size="small">{row.end_number}</TableCell>
                  <TableCell size="small">{row.quantity}</TableCell>
                  <TableCell size="small">
                    {endDateConverter(row.created_at)}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {row.batch_num}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    {/* <Collapse in={open} timeout="auto" unmountOnExit > */}
                    {open === index && openFlag === true ? (
                      <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          <strong>Details</strong>
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow sx={appColors.commonLayoutColor}>
                              <TableCell size="small">
                                <strong>Container Capacity</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Container Material</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Life Span</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Amount</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Manufacturer Name</strong>
                              </TableCell>
                              <TableCell size="small">
                                <strong>Manufacturer Address</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={row.batch_num}>
                              <TableCell size="small">
                                {row.container_capacity}
                              </TableCell>
                              <TableCell size="small">
                                {row.container_material}
                              </TableCell>
                              <TableCell size="small">
                                {row.life_span}
                              </TableCell>
                              <TableCell size="small">{row.amount}</TableCell>
                              <TableCell size="small">{row.mft_name}</TableCell>
                              <TableCell size="small">
                                {row.mft_address}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    ) : (
                      ""
                    )}
                    {/* </Collapse> */}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
