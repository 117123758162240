import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import TimerIcon from "@mui/icons-material/Timer";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CanIcon from "../../Images/CanIcon.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PinDropIcon from "@mui/icons-material/PinDrop";
import CallIcon from "@mui/icons-material/Call";
// import DirectionsIcon from "@mui/icons-material/Directions";
import { toast } from "react-toastify";
import { ORDER_TYPE } from "../../Components/AppMeta/appMetaConfig";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: (theme.palette.mode = "#FFFFFF"),
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: "7px",
  // border: "2px solid #FDEBD0",
  borderRadius: "10px",
}));

function OrderDetails(props) {
  CustomConsole(props);
  // const typoStyle = {
  //   overflow: "hidden",
  //   whiteSpace: "nowrap",
  //   textOverflow: "ellipsis",
  //   width: "80%", // Set a default width for larger screens
  //   maxWidth: "400px", // Set a maximum width for very large screens
  //   // Center the element horizontally
  //   fontSize: "14px", // Set a default font size
  // };
  // const [selectAgentCheckbox, setSelectAgentCheckbox] = React.useState(false);
  // CustomConsole(selectAgentCheckbox);
  const handleAgentCheckBoxChage = (e, id) => {
    CustomConsole("----------handleAgentCheckBoxChage------------");
    if (e.target.checked === true) {
      props.setDeliveryId(id);
    } else if (e.target.checked === false) {
      props.setDeliveryId("");
    }
    // setSelectAgentCheckbox(event.target.checked);
    // props.handleAgentCheckBoxChage(props.orders.delivery_id);
  };
  const orderTypeColor = (ordersType) => {
    if (ordersType.packaged_drinking_water?.is_subscribed_order === true) {
      return "#3BC2C0";
    }
    if (ordersType.payment_type === 300) {
      return "#6d147d";
    }
    if (ordersType.packaged_drinking_water?.order_type === 700) {
      return "#EE4190";
    } else {
      return "#924EEF";
    }
  };

  const orderType = (ordersType) => {
    if (ordersType.packaged_drinking_water?.is_subscribed_order === true) {
      return "S";
    }
    if (ordersType.payment_type === 300) {
      return "P";
    }
    if (ordersType.packaged_drinking_water?.order_type === 700) {
      return "i";
    } else {
      return "R";
    }
  };
  const handleCallClick = (phone) => {
    const telUrl = `tel:${props.orders?.delivery_address?.contact_phone}`;
    window.location.href = telUrl;
  };

  const handleCopyAddress = (address) => {
    CustomConsole("------------handleCopyAddress------------------");
    const address_compile = ` ${address.contact_name},${address.contact_phone},${address.address_line1},
    ${address.address_line2},
    ${address.address_line3},
    ${address.service_area}-
    ${address.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
    // navigator.clipboard.writeText(
    //   props?.orders?.delivery_address?.contact_name +
    //     "," +
    //     props?.orders?.delivery_address?.contact_phone +
    //     "," +
    //     props?.orders?.delivery_address?.address_line1 +
    //     "," +
    //     props?.orders?.delivery_address?.address_line2 +
    //     "," +
    //     props?.orders?.delivery_address?.address_line3 +
    //     "," +
    //     props?.orders?.delivery_address?.landmark +
    //     "," +
    //     props?.orders?.delivery_address?.service_area +
    //     "," +
    //     props?.orders?.delivery_address?.pincode
    // );
    // alert(
    //   props?.orders?.delivery_address?.contact_name +
    //     "," +
    //     props?.orders?.delivery_address?.contact_phone +
    //     "," +
    //     props?.orders?.delivery_address?.address_line1 +
    //     "," +
    //     props?.orders?.delivery_address?.address_line2 +
    //     "," +
    //     props?.orders?.delivery_address?.address_line3 +
    //     "," +
    //     props?.orders?.delivery_address?.landmark +
    //     "," +
    //     props?.orders?.delivery_address?.service_area +
    //     "," +
    //     props?.orders?.delivery_address?.pincode
    // );
  };

  const handleLocation = () => {
    CustomConsole("------------handleLocation------------------");
    if (
      props.orders.latitude !== undefined &&
      props.orders.latitude !== null &&
      props.orders.longitude !== undefined &&
      props.orders.longitude !== null &&
      parseFloat(props.orders.latitude) > 0 &&
      parseFloat(props.orders.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props.orders.latitude},${props.orders.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handleCheckBoxChage = (e, orderId) => {
    if (e.target.checked === true) {
      // props.handleCheckBoxChage(props.orders.order_id);
      props.setSelectionModel((prev) => [...prev, orderId]);
    } else if (e.target.checked === false) {
      props.setSelectionModel((prev) => prev.filter((obj) => obj !== orderId));
    }
  };

  return (
    <Box>
      <Item
        elevation={6}
        sx={{ maxWidth: { xs: 320, md: 520 }, margin: "5px" }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Chip
            sx={{
              backgroundColor: orderTypeColor(props.orders),
              color: "white",
            }}
            avatar={
              <Avatar
                sx={{
                  backgroundColor: "white",
                  fontSize: "12px",
                  color: "white",
                }}
              >
                {orderType(props.orders)}
              </Avatar>
            }
            color="info"
            variant="outlined"
            label={`${props.orders.order_id}`}
          />

         

          {props.orders.delivery_agent_id === 0 && props.assignType === 3 ? (
            <Checkbox
              color="success"
              size="small"
              // checked={selectAgentCheckbox}
              // onClick={handleAgentCheckBoxChage}
              checked={props.deliveryId === props.orders.delivery_id}
              onChange={(e) =>
                handleAgentCheckBoxChage(e, props.orders.delivery_id)
              }
            />
          ) : props.assignType === 2 ? (
            <Checkbox
              color="success"
              size="small"
              checked={props.selectionModel.some(
                (id) => id === props.orders.order_id
              )}
              onChange={(e) => handleCheckBoxChage(e, props.orders.order_id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          ) : (
            ""
          )}
        </Stack>
        <Divider orientation="horizontal" flexItem />
        <Box mt={0.5} mb={0.5} sx={{ paddingLeft: "7px" }}>
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
                textAlign: "start",
                // fontWeight: "bold",
              }}
            >
              {props.orders.delivery_address !== undefined &&
              props.orders.delivery_address !== null
                ? props.orders.delivery_address.contact_name
                : "--"}
              ,{" "}
              {props.orders.delivery_address !== undefined &&
              props.orders.delivery_address !== null
                ? props.orders.delivery_address.contact_phone
                : "--"}{" "}
              <CallIcon onClick={handleCallClick} sx={{ fontSize: "15px" }} />
            </Typography>
            <Typography sx={{ fontSize: "12px", textAlign: "start" }}>
              {props.orders.delivery_address !== undefined &&
              props.orders.delivery_address !== null
                ? props.orders.delivery_address.address_line1
                : "--"}
              ,
              {props.orders.delivery_address !== undefined &&
              props.orders.delivery_address !== null
                ? props.orders.delivery_address.address_line2
                : "--"}
              ,
              {props.orders.delivery_address !== undefined &&
              props.orders.delivery_address !== null
                ? props.orders.delivery_address.address_line3
                : "--"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  textAlign: "start",
                  paddingTop: "1px",
                }}
              >
                {props.orders.delivery_address !== undefined &&
                props.orders.delivery_address !== null
                  ? props.orders.delivery_address.landmark
                  : "--"}
                ,
                {props.orders.delivery_address !== undefined &&
                props.orders.delivery_address !== null
                  ? props.orders.delivery_address.service_area
                  : "--"}
                ,
                {props.orders.delivery_address !== undefined &&
                props.orders.delivery_address !== null
                  ? props.orders.delivery_address.pincode
                  : "--"}{" "}
              </Typography>
            </Box>
            <Box>
              {" "}
              <ContentCopyIcon
                // onClick={handleCopyAddress}
                onClick={() => handleCopyAddress(props.orders.delivery_address)}
                sx={{ fontSize: "19px", marginLeft: "15px" }}
              />{" "}
              <PinDropIcon
                onClick={handleLocation}
                color="primary"
                sx={{ fontSize: "19px", marginLeft: "15px" }}
              />
            </Box>
          </Box>
        </Box>
      </Item>
    </Box>
  );
}

export default OrderDetails;
