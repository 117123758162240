import { Cancel, Verified } from "@mui/icons-material";
import { Box, Button, ThemeProvider, createTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomConsole from "../../CustomConsole";

function ReturnCanOrdersTableView(props) {
  const history = useHistory();
  const [data, setData] = React.useState("");
  CustomConsole(data);

  const handleOpenAssignDeliveryAgentDialog = (object) => {
    props.handleOpenAssignDeliveryAgentDialog(object.order_id);
  };

  const handleReturnCanEnterOTP = (object) => {
    CustomConsole(object);
    props.handleReturnCanEnterOTP({
      order_id: object.order_id,
      is_submit_started: object.is_submit_started,
      is_submit_finished: object.is_submit_finished,
    });
  };
  const handleViewDetails = (params) => {
    // if (singleSelectedData.return_can_order_id) {
    //   const url = `/dashboard/ReturnCanSingleOrder?order_id=${singleSelectedData.return_can_order_id}`;
    //   history.push(url);
    // }

    if (params.field === "OrderId" && params.row.OrderId) {
      CustomConsole(params.row.order_id);
      const url = `/dashboard/ReturnCanSingleOrder?order_id=${params.row.OrderId}`;
      history.push(url);
    }
  };

  const headerData = [
    { field: "OrderId", headerName: "Order Id", width: 130 },

    // {
    //   field: "EnterOtp",
    //   headerName: "Process",
    //   width: 100,
    //   renderCell: (params) => {
    //     CustomConsole("ufjffiuhlihil");
    //     CustomConsole(params);
    //     return (
    //       <Button
    //         disabled={
    //           params.row.is_submit_started === true &&
    //           params.row.is_submit_finished === true
    //         }
    //         sx={{
    //           backgroundColor: "green",
    //           "&:hover": { backgroundColor: "black" },
    //         }}
    //         variant="contained"
    //         onClick={() =>
    //           handleReturnCanEnterOTP({
    //             order_id: params.row.OrderId,
    //             is_submit_started: params.row.is_submit_started,
    //             is_submit_finished: params.row.is_submit_finished,
    //           })
    //         }
    //       >
    //         {params.row.is_submit_started === false &&
    //         params.row.is_submit_finished === false
    //           ? "Enter Otp"
    //           : params.row.is_submit_started === true &&
    //             params.row.is_submit_finished === false
    //           ? "In-scan"
    //           : null}
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "assignDeliveryAgent",
    //   headerName: "Assign Delivery Agent",
    //   width: 180,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         disabled={!params.row.DeliveryAgentId === 0}
    //         sx={{
    //           backgroundColor: "blue",
    //           "&:hover": { backgroundColor: "black" },
    //         }}
    //         variant="contained"
    //         onClick={() =>
    //           handleOpenAssignDeliveryAgentDialog({
    //             order_id: params.row.OrderId,
    //           })
    //         }
    //       >
    //         {params.row.DeliveryAgentId === 0
    //           ? "Assign Delivery Agent"
    //           : "Assigned"}
    //       </Button>
    //     );
    //   },
    // },
    { field: "NoOfCan", headerName: "No Of Cans Qty", width: 150 },
    { field: "RecivedCanQty", headerName: "Received Can Qty", width: 150 },
    { field: "OrderStatus", headerName: "Order Status", width: 150 },
    { field: "Material", headerName: "Material", width: 150 },
    { field: "Capacity", headerName: "Capacity", width: 100 },
    {
      field: "DeliveryAgentId",
      headerName: "Assigned Delivery Agent Id",
      width: 130,
    },
  ];
  React.useEffect(() => {
    let returnCanDataTemp = [];
    props.data.map((data, key) => {
      CustomConsole(data);
      returnCanDataTemp[key] = {
        id: key + 1,
        OrderId: data.return_can_order_id,
        NoOfCan: data.container_qty,
        RecivedCanQty: data.received_container_qty,
        OrderStatus:
          data.order_status === returnCanOrderStatus.APPROVED &&
          data?.container_qty !== data?.received_container_qty
            ? "Ongoing"
            : data?.order_status === returnCanOrderStatus.REJECTED
            ? ""
            : "",
        Material: data.container_material,
        Capacity: data.container_capacity,
        DeliveryAgentId: data.delivery_agent_id,
        is_submit_finished: data.is_submit_finished,
        is_submit_started: data.is_submit_started,
      };
    });
    setData(returnCanDataTemp);
  }, []);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#a3a1e6",
            color: "black",
            fontWeight: "bold",
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });
  return (
    <Box
      sx={{
        height: 520,
        maxWidth: { xs: "350px", sm: "400px", md: "100%", lg: "100%" },
        overflow: "auto",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={data}
          columns={headerData}
          autoHeight
          onCellClick={handleViewDetails}
          hideFooter={true}
          sx={{
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </ThemeProvider>
    </Box>
  );
}

export default ReturnCanOrdersTableView;
