import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { url } from "../apis";
import React from "react";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";

//function for generating qr and bar code
export const GenerateQrCodeForCrate = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/products/crate/generate/qr/barcode`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GENERATE_QRCODE_CRATE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Please check inputs and Try again!!", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for linking crate qr and bar code details
export const LinkQRBarCodeCrates = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/products/crate/link/qr/barcode`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_QR_BARCODE_CRATE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          //   toast.dark("User Added Successfully", {
          //     position: "bottom-right",
          //     autoClose: 3000,
          //     type: "success",
          //   });
        }
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for regenrating qr and bar code
export const RegenerateCrateQrBarCode = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/products/crate/regenerate/qr/barcode`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REGENERATE_CRATE_QRBAR_CODE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const GetQrAndBarCodeListCrate = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  //   const reqObj = { is_qr_barcode: true, quantity: 2 };

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/products/crate/qr/barcode/details?where_is_regenerated=${reqObj.where_is_regenerated}&where_is_qr=${reqObj.where_is_qr}&sort_by_created_at=${reqObj.sort_by_created_at}&sort_by_start_number=${reqObj.sort_by_start_number}&sort_by_end_number=${reqObj.sort_by_end_number}&sort_by_crate_id=${reqObj.sort_by_crate_id}&page_number=${reqObj.page_number}&page_size=${reqObj.page_size}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        
        dispatch({
          type: actionType.CRATE_QRBARCODE_LIST,
          payload: response.data,
        });
        // if (response.data.msg) {
        //   toast.dark(response.data.msg, {
        //     position: "bottom-right",
        //     autoClose: 3000,
        //     type: "success",
        //   });
        // }
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for linking crate qr and bar code details
export const ReLinkQrBarCodeCrate = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/products/crate/re_link/qr/barcode`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.RELINK_QR_BARCODE_CRATE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else {
          //   toast.dark("User Added Successfully", {
          //     position: "bottom-right",
          //     autoClose: 3000,
          //     type: "success",
          //   });
        }
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
