import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  wfsData: [],
  updatedMsg: "",
};

const adminAddUpdateWfsReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.GET_WFS_DETAILS_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        wfsData: action.payload.data,
      };
    case actionType.UPDATE_WFS_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        updatedMsg: action.payload.msg,
      };

      case actionType.ADDING_NEW_WFS:
        return {
          ...state,
        error: action.payload.error,
        msg:action.payload.msg,
        }

    default:
      return state;
  }
};

export default adminAddUpdateWfsReducer;
