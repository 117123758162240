import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AdminReplacementOrderCard from "./AdminReplacementOrderCard";
import AdminReplacementOrderTable from "./AdminReplacementOrderTable";
import AdminReplacementSupplierTable from "./AdminReplacementSupplierTable";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import {
  GetReplacementOrders,
  GetOrderDetailsById,
} from "../Redux/Actions/adminReplacementOrderAction";
import Alert from "@mui/material/Alert";
import CustomConsole from "../../CustomConsole";

import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function AdminReplacementOrder() {
  const adminReplacementOrders = useSelector(
    (state) => state.replacementOrderAdmin
  );
  const replecementDetails = useSelector(
    (state) => state.replacementOrderAdmin.orderbyDetails
  );
  const replacementSupplier = useSelector(
    (state) => state.replacementOrderAdmin.supplierDetails
  );
  //states
  const [pageNumber, setPageNumber] = React.useState(1);
  const [currentOrderId, setCurrentOrderId] = React.useState("");

  const [replaceCard, setReplaceCard] = React.useState(false);
  const [supplierTable, setSupplierTable] = React.useState(false);

  const scrollDownRef = React.useRef(null);

  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");

  //flags
  const [acceptToast, setAcceptToast] = React.useState(false);
  const [rejectToast, setRejectToast] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  React.useEffect(() => {
    if (acceptToast === true && adminReplacementOrders.error === false) {
      toast.success(adminReplacementOrders.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAcceptToast(false);
      dispatch(GetReplacementOrders({ page_number: pageNumber - 1 }));
      dispatch(GetOrderDetailsById(currentOrderId));
      setSupplierTable(false);
    }
    if (rejectToast === true && adminReplacementOrders.error === false) {
      setRejectToast(false);
      dispatch(GetReplacementOrders({ page_number: pageNumber - 1 }));
      dispatch(GetOrderDetailsById(currentOrderId));
    }
  }, [adminReplacementOrders]);

  const scrollDownToCard = () => {
    scrollDownRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container
      sx={{
        padding: "0px",
      }}
    >
      {ADMIN_ROLES.BW_ADMIN === adminRole ||
      ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
      ADMIN_ROLES.BW_CS === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
      ADMIN_ROLES.BW_CSLEAD === adminRole ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={12}>
            <AdminReplacementOrderTable
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setCurrentOrderId={setCurrentOrderId}
              scrollDownToCard={scrollDownToCard}
              setReplaceCard={setReplaceCard}
              setSupplierTable={setSupplierTable}
            />
          </Grid>
          {replecementDetails.length > 0 && replaceCard ? (
            <Grid item md={12}>
              <AdminReplacementOrderCard
                orderDetailsCard={replecementDetails}
                setRejectToast={setRejectToast}
                scrollDownRef={scrollDownRef}
                setSupplierTable={setSupplierTable}
              />
            </Grid>
          ) : null}
          {replacementSupplier.length > 0 && supplierTable ? (
            <Grid item md={12}>
              <AdminReplacementSupplierTable
                replacementSupplier={replacementSupplier}
                replecementDetails={replecementDetails}
                setAcceptToast={setAcceptToast}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Container>
  );
}

export default AdminReplacementOrder;
