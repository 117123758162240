import React from "react";
import * as Material from "@mui/material";
import { Card } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import { ordersStatusWFSCan } from "../AppMeta/appMetaConfig";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import * as MuiIcons from "@mui/icons-material";
import { PutEditEmptyCanOrder } from "../../Pages/Redux/Actions/wfsOrdersAction";
import EditEmptyCanOrdersDialog from "../../Pages/wfsCanOrders/EditEmptyCanOrdersDialog";
import { useDispatch } from "react-redux";
import { GetSupplierWfsOngoingOrders } from "../../Pages/Redux/Actions/supliersAction";
import EmptyCanOrderEdit from "../DialogBoxes/emptyCanEditOrder";
import SupplierWfsSingleOrderDetailsDialog from "../../Pages/SupplierOrders/SupplierWfsSingleOrderDetailsDialog";
import CustomConsole from "../../CustomConsole";

function WfsAllOrderDetailsCard(props) {
  CustomConsole(props);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openSupplierWFSOrdersDialog, setOpenSupplierWFSOrdersDialog] =
    React.useState(false);
  const [openEditEmptyCanOrderDialog, setOpenEditEmptyCanOrderDialog] =
    React.useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const orderStatuswfsCansorder = (ec_order_status) => {
    switch (ec_order_status) {
      case ordersStatusWFSCan.EC_ORDER_CONFIRMED:
        return "CONFIRMED";
      case ordersStatusWFSCan.EC_ORDER_COMPLETED:
        return "COMPLETED";
      case ordersStatusWFSCan.EC_ORDER_CANCELLED:
        return "CANCELLED";
      case ordersStatusWFSCan.EC_ORDER_PAYMENT_PENDING:
        return "PAYMENT PENDING";
      case ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED:
        return "ADMIN ACCEPTED";
      case ordersStatusWFSCan.EC_ORDER_ADMIN_REJECTED:
        return "ADMIN REJECTED";
      default:
        return "";
    }
  };

  const handleStart = (event) => {
    CustomConsole("------------handleStart--------------");
    // event.stopPropagation();
    props.handleStart(props);
  };
  const handleCopyAddress = (code) => {
    CustomConsole("----handleCopyAddress----");
    CustomConsole(code);
    navigator.clipboard.writeText(code);
    setOpen(true);
  };
  const handleEditEmptyCan = (event) => {
    // event.stopPropagation();
    setOpenEditEmptyCanOrderDialog(true);
  };
  const handleCloseD = () => {
    setOpenEditEmptyCanOrderDialog(false);
  };
  const handleSubmitCans = (props) => {
    CustomConsole("--------handleSubmitCans-------------");
    const reqObj = {
      order_id: props.ec_order_id,
      order_qty: props.orderQty + props.emptyCanQty,
      empty_qty: props.emptyCanQty,
    };
    CustomConsole(reqObj);
    dispatch(PutEditEmptyCanOrder(reqObj));
    setOpenEditEmptyCanOrderDialog(false);
    setTimeout(() => {
      dispatch(
        GetSupplierWfsOngoingOrders({
          page_number: 0,
          page_size: 100,
        })
      );
    }, 1000);
  };
  const handleOpenWfsSingleOrderDetails = (event) => {
    CustomConsole("--------handleOpenWfsSingleOrderDetails-------------");
    event.stopPropagation();
    // setOpenSupplierWFSOrdersDialog(true);
  };

  const handleCloseWFSOrderDialog = () => {
    setOpenSupplierWFSOrdersDialog(false);
  };

  const handleOpenDialog = () =>{
    setOpenSupplierWFSOrdersDialog(true);
  }
  return (
    <Material.Box sx={{ display: "flex", flexDirection: "row" }}>
      <Material.Card
        elevation={24}
        sx={{ margin: "10px", minWidth: 330 }}
        // onClick={handleOpenWfsSingleOrderDetails}
      >
        <Material.CardContent>
          <Material.Box>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              OrderId:
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                  // alignItems: "flex-end",
                  textAlign: "end",
                  textDecoration:"underline",
                  color:"red"
                }}
                onClick={handleOpenDialog}
              >
                {props.OrderId}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                flexDirection: "column",
                alignItems: "flex-start",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              New Cans :{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                  alignItems: "flex-start",
                  textAlign: "start",
                }}
              >
                {props.NewConatinerQty}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                flexDirection: "column",
                alignItems: "flex-start",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Empty Cans :{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                  alignItems: "flex-start",
                  textAlign: "start",
                }}
              >
                {props.EmptyCans}
              </span>
            </Material.Typography>

            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              OTP :{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {props.Otp}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Damaged Cans:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {props.NoofDamagedCans}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Expired Cans:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {props.NoofExpiredCans}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Order Status:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {orderStatuswfsCansorder(props.OrderStatus)}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Order Assigned To:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {props.OrderAssignedTo}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Container Capacity:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {props.container_capacity}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Container Material:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {props.container_material}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Order Created At:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {props.OrderCreatedAt}
              </span>
            </Material.Typography>
            <Material.Typography
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                color: "blue",
                fontSize: "14px",
                fontWeight: "bold",
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              Address :{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {props.Address !== undefined && props.Address.Street}
                <br />
                {props.Address !== undefined && props.Address.Village}
                <br />
                {props.Address !== undefined && props.Address.District}
                <br />
                {props.Address !== undefined && props.Address.State}
                <br />
                {props.Address !== undefined && props.Address.Pincode}
                <br />
              </span>
              <Tooltip title="Copy Address">
                <IconButton>
                  <ContentCopy
                    sx={{ background: "#3498DB" }}
                    onClick={() => {
                      handleCopyAddress(
                        props.Address.Street +
                          props.Address.Village +
                          props.Address.District +
                          props.Address.State +
                          props.Address.Pincode
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Material.Typography>
            <Material.Box
              sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <Material.Box>
                {props.OrderStatus ===
                ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED ? (
                  <Material.Box sx={{ marginTop: "10px" }}>
                    {props.OrderFinished === false &&
                    props.OrderStarted === false ? (
                      <>
                        <Material.Button
                          onClick={handleStart}
                          variant="contained"
                          sx={{
                            borderTopRightRadius: "20px",
                            borderBottomLeftRadius: "20px",
                          }}
                        >
                          start Submission
                        </Material.Button>
                      </>
                    ) : (
                      <>
                        {/* {props.data ? ( */}
                        <Material.Box>
                          <Material.Typography
                            sx={{ fontWeight: "bold", color: "red" }}
                          >
                            ORDER ALREADY STARTED
                          </Material.Typography>
                        </Material.Box>
                        {/* ) : (
                    ""
                  )} */}
                      </>
                    )}
                  </Material.Box>
                ) : (
                  ""
                )}
              </Material.Box>
              <Material.Box sx={{ mt: 1.5 }}>
                <Material.Button
                  variant="contained"
                  size="small"
                  onClick={handleEditEmptyCan}
                >
                  <MuiIcons.BorderColor /> Edit
                </Material.Button>
              </Material.Box>
            </Material.Box>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Address Copied
              </Alert>
            </Snackbar>
          </Material.Box>
        </Material.CardContent>
      </Material.Card>
      {openEditEmptyCanOrderDialog && (
        <EmptyCanOrderEdit
          open={openEditEmptyCanOrderDialog}
          onClose={handleCloseD}
          editDetails={props.data}
        />
      )}
      <SupplierWfsSingleOrderDetailsDialog
        openSupplierWFSOrdersDialog={openSupplierWFSOrdersDialog}
        singleIdData={props.data}
        handleCloseWFSOrderDialog={handleCloseWFSOrderDialog}
        ordersStatusWFSCan={ordersStatusWFSCan}
        handleStart={handleStart}
        handleEditEmptyCanDetails={handleEditEmptyCan}
        setOpenEditEmptyCanOrderDialog={setOpenEditEmptyCanOrderDialog}
      />
    </Material.Box>
  );
}

export default WfsAllOrderDetailsCard;
