/**
 * @author Tahir Shaik
 * @date 28/10/2023
 * @description TO show the complete Container Details for the Admin Users
 */
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import CustomConsole from "../../CustomConsole";
import { Close, CloudDownload } from "@mui/icons-material";
import bgImage from "./../../Components/Assests/Images/bw_bg.svg";
import moment from "moment";
import { useDispatch } from "react-redux";

import { getFiles } from "../Redux/Actions/fileHandlingAction";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function QrDetailsDialog(props) {
  const dispatch = useDispatch();
  const [ownerDetails, setOwnerDetails] = React.useState([]);
  const [qualityDetails, setQualityDetails] = React.useState([]);
  const [wfsDetails, setWfsDetails] = React.useState([]);
  const [containerDetails, setContainerDetails] = React.useState([]);
  const [productLinkWithCrate, setProductLinkWithCrate] = React.useState([]);

  const [qualityReport, setQualityReport] = React.useState("");
  React.useEffect(() => {
    CustomConsole("-- QrDetailsDialog --");
    CustomConsole(props);
    if (props.data) {
      // Set the quality Details
      if (
        props.data.productlinkedCrateSetails &&
        props.data.productlinkedCrateSetails.length
      ) {
        CustomConsole(props.data.productlinkedCrateSetails);
        const data = [
          {
            key: "Product Linked With Crate",
            value: props.data.productlinkedCrateSetails.length ? "YES" : "NO",
          },
          {
            key: "Products Linked Crate Qty",
            value: props.data.productlinkedCrateSetails.length
              ? props.data.productlinkedCrateSetails.length
              : 0,
          },
        ];
        CustomConsole(data);
        setProductLinkWithCrate(data);
      } else {
        setProductLinkWithCrate([
          {
            key: "Product Linked With Crate",
            value: "NO",
          },
          {
            key: "Products Linked Crate Qty",
            value: "0",
          },
        ]);
      }
      if (
        props.data.qualityDetails &&
        props.data.qualityDetails.length &&
        props.data.qualityDetails[0].quality_details
      ) {
        const report = props.data.qualityDetails[0].quality_details
          .quality_report
          ? props.data.qualityDetails[0].quality_details.quality_report
          : "";
        if (report.length > 0) {
          setQualityReport(report);
        }
        let data = [
          {
            key: "TDS",
            value: props.data.qualityDetails[0].quality_details.tds
              ? props.data.qualityDetails[0].quality_details.tds
              : "-",
          },
          {
            key: "PH",
            value: props.data.qualityDetails[0].quality_details.ph
              ? props.data.qualityDetails[0].quality_details.ph
              : "-",
          },
          {
            key: "Mft. date",
            value: props.data.qualityDetails[0].quality_details.exp_date
              ? moment(
                  props.data.qualityDetails[0].quality_details.mft_date
                ).format("DD/MM/YYYY hh:mm:ss a")
              : "-",
          },
          {
            key: "Exp. date",
            value: props.data.qualityDetails[0].quality_details.exp_date
              ? moment(
                  props.data.qualityDetails[0].quality_details.exp_date
                ).format("DD/MM/YYYY hh:mm:ss a")
              : "-",
          },
          {
            key: "No.of times can used",
            value: props.data.qualityDetails[0].no_of_times_used
              ? props.data.qualityDetails[0].no_of_times_used
              : "0",
          },
          {
            key: "Report",
            value: report,
          },
        ];
        CustomConsole("-- data --");
        CustomConsole(data);
        setQualityDetails(data);
        if (props.data.qualityDetails[0].filled_wfs_details) {
          data = [
            {
              key: "Filling Station",
              value: props.data.qualityDetails[0].filled_wfs_details
                .company_name
                ? props.data.qualityDetails[0].filled_wfs_details.company_name
                : "-",
            },
            {
              key: "Status",
              value: props.data.qualityDetails[0].filled_wfs_details
                .is_available
                ? "Available"
                : "Un-Available",
            },
            {
              key: "Owner name",
              value: props.data.qualityDetails[0].filled_wfs_details
                .company_name
                ? props.data.qualityDetails[0].filled_wfs_details.owner_name
                : "-",
            },
            {
              key: "Phone number",
              value: props.data.qualityDetails[0].filled_wfs_details
                .company_name
                ? props.data.qualityDetails[0].filled_wfs_details.owner_phone
                : "-",
            },
            {
              key: "Pincode",
              value: props.data.qualityDetails[0].filled_wfs_details.pincode
                ? props.data.qualityDetails[0].filled_wfs_details.pincode
                : "-",
            },
          ];
          if (
            props.data.wfsBatchDetails &&
            Object.keys(props.data.wfsBatchDetails).length > 0
          ) {
            data.push({
              key: "Batch ID",
              value: props.data.wfsBatchDetails.batch_id
                ? props.data.wfsBatchDetails.batch_id
                : "-",
            });
            const fullname = props.data.wfsBatchDetails.fullname
              ? props.data.wfsBatchDetails.fullname
              : "";
            const phone = props.data.wfsBatchDetails.phone
              ? " , " + props.data.wfsBatchDetails.phone
              : "";
            const email = props.data.wfsBatchDetails.email
              ? " , " + props.data.wfsBatchDetails.email
              : "";
            const updatedByDetails = fullname + email + phone;
            CustomConsole("updatedByDetails: " + updatedByDetails);
            data.push({
              key: "Updated By",
              value: updatedByDetails.length ? updatedByDetails : "-",
            });
          }
          setWfsDetails(data);
        }
        if (props.data.qualityDetails[0].container_details) {
          data = [
            {
              key: "Capacity",
              value: props.data.qualityDetails[0].container_details?.capacity
                ? props.data.qualityDetails[0].container_details?.capacity
                : "-",
            },
            {
              key: "Material",
              value: props.data.qualityDetails[0].container_details?.material
                ? props.data.qualityDetails[0].container_details?.material
                : "-",
            },
            {
              key: "Life Span",
              value: props.data.qualityDetails[0].container_details?.life_span
                ? props.data.qualityDetails[0].container_details?.life_span
                : "--",
            },
          ];
          setContainerDetails(data);
        }
      }
      if (
        props.data.ownerType &&
        props.data.ownerDetails &&
        props.data.ownerDetails.length > 0
      ) {
        CustomConsole("props.data.ownerType: ");
        CustomConsole(props.data.ownerType);
        CustomConsole("props.data.ownerDetails: ");
        CustomConsole(props.data.ownerDetails);
        CustomConsole("parseInt(props.data.ownerType) === 1");
        CustomConsole(parseInt(props.data.ownerType) === 1);
        //Owner is consumer
        if (parseInt(props.data.ownerType) === 1) {
          if (props.data.ownerDetails[0].consumer_details) {
            const ownerData = [
              {
                key: "Owner Type",
                value: "Customer",
              },
              {
                key: "Name",
                value: props.data.ownerDetails[0].consumer_details.fullname,
              },
              {
                key: "Email",
                value: props.data.ownerDetails[0].consumer_details.email
                  ? props.data.ownerDetails[0].consumer_details.email
                  : "-",
              },
              {
                key: "Phone number",
                value: props.data.ownerDetails[0].consumer_details.phone
                  ? props.data.ownerDetails[0].consumer_details.phone
                  : "-",
              },
              {
                key: "Status",
                value: props.data.ownerDetails[0].consumer_details.user_status
                  ? "Active"
                  : "Inactive/Deleted",
              },
            ];
            CustomConsole("ownerData: ");
            CustomConsole(ownerData);
            setOwnerDetails(ownerData);
          }
        } else if (parseInt(props.data.ownerType) === 2) {
          //Owner is Distributor
          if (props.data.ownerDetails[0].supplier_details) {
            const ownerData = [
              {
                key: "Owner Type",
                value: "Distributor",
              },
              {
                key: "Name",
                value: props.data.ownerDetails[0].supplier_details.fullname,
              },
              {
                key: "Email",
                value: props.data.ownerDetails[0].supplier_details.email
                  ? props.data.ownerDetails[0].supplier_details.email
                  : "-",
              },
              {
                key: "Phone number",
                value: props.data.ownerDetails[0].supplier_details.phone
                  ? props.data.ownerDetails[0].supplier_details.phone
                  : "-",
              },
              {
                key: "Status",
                value: props.data.ownerDetails[0].supplier_details.user_status
                  ? "Active"
                  : "Inactive/Deleted",
              },
            ];
            CustomConsole("ownerData: ");
            CustomConsole(ownerData);
            setOwnerDetails(ownerData);
          }
        } else if (parseInt(props.data.ownerType) === 3) {
          CustomConsole("----hgfjh----");
          if (props.data.ownerDetails[0].wfs_details) {
            const ownerData = [
              {
                key: "Filling Station",
                value: props.data.ownerDetails[0].wfs_details.company_name,
              },
              {
                key: "Status",
                value: props.data.ownerDetails[0].wfs_details.is_available
                  ? "Available"
                  : "Un-Available",
              },
              {
                key: "Owner name",
                value: props.data.ownerDetails[0].wfs_details.owner_name,
              },
              {
                key: "Phone number",
                value: props.data.ownerDetails[0].wfs_details.owner_phone,
              },
              {
                key: "Pincode",
                value: props.data.ownerDetails[0].wfs_details.pincode,
              },
            ];
            setOwnerDetails(ownerData);
          }
          //Owner is WFS
        }
        if (
          parseInt(props.data.ownerType) === 1 ||
          parseInt(props.data.ownerType) === 2 ||
          parseInt(props.data.ownerType) === 3
        ) {
          if (
            props.data.ownerDetails[0].is_filled_can !== undefined ||
            props.data.ownerDetails[0].is_filled_can != null
          ) {
            setQualityDetails((prev) => [
              ...prev,
              {
                key: "Container status",
                value: props.data?.qualityDetails?.[0]?.is_damaged_active
                  ? "Damaged"
                  : props.data.ownerDetails[0].is_filled
                  ? "Filled"
                  : "Empty",
              },
            ]);
          } else if (
            props.data.ownerDetails[0].is_filled !== undefined ||
            props.data.ownerDetails[0].is_filled != null
          ) {
            setQualityDetails((prev) => [
              ...prev,
              {
                key: "Container status",
                value: props.data?.qualityDetails?.[0]?.is_damaged_active
                  ? "Damaged"
                  : props.data.ownerDetails[0].is_filled
                  ? "Filled"
                  : "Empty",
              },
            ]);
          }
          if (
            props.data.ownerDetails[0].order_id !== undefined &&
            props.data.ownerDetails[0].order_id !== null &&
            props.data.ownerDetails[0].order_id.length
          ) {
            const containerFrom = props.data.ownerDetails[0].order_id.includes(
              "WTHDR"
            )
              ? "Return can"
              : props.data.ownerDetails[0].order_id.includes("RP")
              ? "Replacement Can"
              : props.data.ownerDetails[0].order_id.includes("BTORD")
              ? "Regular Can"
              : props.data.ownerDetails[0].order_id.includes("CSTORD")
              ? "Transferred Can"
              : "-";
            setQualityDetails((prev) => [
              ...prev,
              {
                key: "Can Type",
                value: containerFrom,
              },
            ]);
            if (containerFrom !== "-") {
              setQualityDetails((prev) => [
                ...prev,
                {
                  key: "Order ID",
                  value: props.data.ownerDetails[0].order_id,
                },
              ]);
            }
          } else if (props.data.ownerDetails[0].ec_order_id) {
            setQualityDetails((prev) => [
              ...prev,
              {
                key: "Order ID",
                value: props.data.ownerDetails[0].ec_order_id,
              },
            ]);
          }
          // Owner Details:
          if (
            (parseInt(props.data.ownerType) === 1 ||
              parseInt(props.data.ownerType) === 2) &&
            props.data.ownerDetails[0].assigned_to_details
          ) {
            const ownerData = [
              {
                key: "Owner Type",
                value: props.data?.qualityDetails?.[0]?.is_damaged_active
                  ? "-"
                  : parseInt(props.data.ownerType) === 1
                  ? "Customer"
                  : "Distributor",
              },
              {
                key: "Name",
                value: props.data.ownerDetails[0].assigned_to_details.fullname,
              },
              {
                key: "Email",
                value: props.data.ownerDetails[0].assigned_to_details.email
                  ? props.data.ownerDetails[0].assigned_to_details.email
                  : "-",
              },
              {
                key: "Phone number",
                value: props.data.ownerDetails[0].assigned_to_details.phone
                  ? props.data.ownerDetails[0].assigned_to_details.phone
                  : "-",
              },
              {
                key: "Status",
                value: props.data.ownerDetails[0].assigned_to_details
                  .user_status
                  ? "Active"
                  : "Inactive/Deleted",
              },
            ];
            CustomConsole("ownerData: ");
            CustomConsole(ownerData);
            setOwnerDetails(ownerData);
          }
        }
      }
    }
  }, [props]);
  React.useEffect(() => {
    CustomConsole("ownerDetails");
    CustomConsole(ownerDetails);
    CustomConsole("wfsDetails");
    CustomConsole(wfsDetails);
    CustomConsole("qualityDetails");
    CustomConsole(qualityDetails);
  }, [ownerDetails, wfsDetails, qualityDetails]);
  const handleDownloadFile = () => {
    if (qualityReport.length > 0) {
      dispatch(getFiles(qualityReport));
    }
  };
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#1b185c" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Container Details
          </Typography>
          <Button
            variant="contained"
            autoFocus
            color="inherit"
            sx={{ color: "red" }}
            onClick={props.handleClose}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          backgroundImage: `url(${bgImage})`,
          height: "100vH",
          width: "100vW",
          alignItems: "center",
          padding: "15px",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* Container Details */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: 2,
              borderColor: "gray",
              padding: "10px",
              width: { xs: "80vW", md: "100%" },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 500,
                textAlign: "center",
                m: 1,
              }}
            >
              Container Details:
            </Typography>
            {containerDetails.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 500,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>

                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
            {productLinkWithCrate.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 500,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>

                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          {/* Quality Details */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: 2,
              borderColor: "gray",
              padding: "10px",
              width: { xs: "80vW", md: "100%" },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 500,
                textAlign: "center",
                m: 1,
              }}
            >
              Quality Details:
            </Typography>
            {qualityDetails.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 500,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>
                  {data.key === "Report" ? (
                    <Button
                      disabled={qualityReport.length ? false : true}
                      variant="contained"
                      size="small"
                      onClick={handleDownloadFile}
                      startIcon={<CloudDownload />}
                    >
                      Download
                    </Button>
                  ) : (
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: "12", md: "14px" },
                        fontWeight: 400,
                        textAlign: "right",
                        m: 1,
                      }}
                    >
                      {data.value}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
          {/* Filling Station Details */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: 2,
              borderColor: "gray",
              padding: "10px",
              width: { xs: "80vW", md: "100%" },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 500,
                textAlign: "center",
                m: 1,
              }}
            >
              Filling Station Details:
            </Typography>
            {wfsDetails.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 500,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: 2,
              borderColor: "gray",
              padding: "15px",
              marginTop: "20px",
              width: { xs: "80vW", md: "50%" },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 500,
                textAlign: "center",
                m: 1,
              }}
            >
              Owner Details:
            </Typography>
            {ownerDetails.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 500,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default QrDetailsDialog;
