import * as React from "react";
import * as MuiIcon from "@mui/icons-material";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import CustomConsole from "../../CustomConsole";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { SideBarData } from "./SidebarData";
import AdminProfileDialog from "../../Components/Dialog/AdminProfileDialog.jsx";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import bwIcon from "../../Images/bwLogo.png";
import NotFound from "./NotFound";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import actionType from "../Redux/Actions/actionType";
import WfsHome from "../wfsHome/WfsHome";
import WfsOrders from "../wfsCanOrders/WfsOrders.jsx";
import { GetUserProfile, GetWfsDetails } from "../Redux/Actions/profileAction";
import WfsBatch from "../WfsBatch/WfsBatch";
import AdminUsers from "../AdminUsers/AdminUsers";
import AdminDistributors from "../AdminDistributors/AdminDistributors";
import OrdersAdmin from "../AdminOrders/Orders";
import PostpaidOrders from "../AdminPostpaid/PostpaidOrders";
import AdminIotDevices from "../AdminDevices/AdminIotDevices";
import { LostCans } from "../AdminLostCans/LostCans";
import { Settings } from "../AdminSettings/Settings";
import WfsCanOrdersAdmin from "../AdminWFSCanOrders/WfsCanOrdersAdmin";
import { ContainerDetails } from "../AdminContainerDetails/ContainerDetails";
// import AdminWithdrawal from "../AdminWithdrawalHistory/AdminWithdrawal";
import QRBarcode from "../AdminGenrateQRBarcode/QRBarcode";
import AddUpdateWFS from "../AdminAddUpdateWFS/AddUpdateWFS";
import AdminWithdrawal from "../AdminWithdrawalHistory/AdminWithdrawal";
import AdminBanners from "../AdminBanners/AdminBanners";
import EmptyCanOrder from "../AdminEmptyCanOrders/EmptyCanOrder";
import AdminReplacementOrder from "../AdminReplacementOrder/AdminReplacementOrder";
import Adminreports from "../Reports/Adminreports.jsx";
import Pwdsettingpage from "../Pwdsetting/Pwdsetting";
// import OrdersAdmin from "../OrdersAdmin/Orders";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import SuperAdmin from "../SuperAdmin/SuperAdmin";
import PromoCodemain from "../PromoCodes/PromoCodemain";
import "./drawer.css";
import AdminInfluencers from "../AdminInfluencers/AdminInfluencers";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import AdminSettlement from "../AdminSettlement/AdminSettlement";
import { SettingsBackupRestore } from "@mui/icons-material";
import Home from "../AdminHome/Home";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import DeliveriesMain from "../SupplierDeliveries/DeliveriesMain";
import OrdersMain from "../SupplierOrders/OrdersMain";
import BookNowMain from "../SupplierBookNow/BookNowMain";
import SettingsMain from "../SupplierSettings/SettingsMain";
import WareHouseMain from "../SupplierWarehouse/WarehouseMain.jsx";
import {
  GetSupplierCompanyProfile,
  GetSupplierUserCompanyProfile,
} from "../Redux/Actions/supliersAction";
import ConsumerDeliveriesMain from "../ConsumerDeliveries/ConsumerDeliveriesMain";
import MarketingMain from "../Marketing/MarketingMain";
import Postpaid from "../AdminPoTrackPostPaid/Postpaid";
import DriverProfile from "../DriverProfile/DriverProfile";
import DriverDeliveriesMain from "../DriverDeliveries/DriverDeliveriesMain";

import CurrentDayDeliveriesPerticularDetails from "../SupplierDeliveries/CurrentDayDeliveriesPerticularDetails";
import WfsSingleOrder from "../wfsCanOrders/WfsSingleOrder";
import ReturnCanSingleOrder from "../SupplierOrders/ReturnCanSingleOrder.jsx";
import BuyBackProductSingleOrder from "../SupplierOrders/BuyBackSingleOrder.jsx";
import ReplacementSingleOrder from "../SupplierOrders/ReplacementSingleOrder.jsx";
import TransactionsPage from "../AdminUsers/TransactionPage";
import AdminDistributorDetailsMain from "../AdminDistributorDetails/AdminDistributorDetailsMain.jsx";
import Assigning from "../SupplierAssigning/Assigning.jsx";
import ReassignSupplier from "../SupplierReassign/ReassignSupplier.jsx";
import PrvAndCompletedDeliveries from "../SupplierPreviousAndCompletedDeliveries/PrvAndCompletedDeliveries.jsx";
import AdminCanSwapMain from "../AdminCanSwap/AdminCanSwapMain.jsx";
import NewContainerDetailsMain from "../SkuContainerDetails/NewContainerDetailsMain.jsx";
import WfsDetailsSku from "../WfsDetailsNew/AddUpdateWFS.jsx";
import UserDetailsMain from "../UserDetails/UserDetailsMain.jsx";
import WfsGroupProductsSku from "../WfsGroupProducts/wfsGroupProductsMain.jsx";

import EmployeesWarehouseDash from "../SupplierWarehouse/EmployeesWarehouseDash.jsx";
import CustomLoaderDialog from "../../Components/Dialog/CustomLoaderDialog.jsx";
import AdmiNewDashboardMain from "../AdminNewDashboard/AdmiNewDashboardMain.jsx";
import TripsMain from "../SupplierWarehouse/TripsMain.jsx";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import {
  EmployeesPunchInOut,
  GetPunchDetails,
} from "../Redux/Actions/supplierVehiclesActions.js";
import { PunchInModel } from "../SupplierWarehouse/PunchInModel.jsx";
import OperationsLevelCostMain from "../AdminOpertionLevelCost/OperationsLevelCostMain.jsx";
import { GetAdminAppUpdateDetails } from "../Redux/Actions/appSettingsAction.js";
import AppForceUpdateDialog from "../../Components/Dialog/AppUpdateDialog.jsx";
import SettlementPagePostPaid from "../AdminPoTrackPostPaid/PoSettlementPage.jsx";
const drawerWidth = 240;

const styles = {
  button: {
    display: "flex",
    fontWeight: 700,
    "&:hover": {
      color: "black",
      borderRadius: "10px",
      backgroundColor: "#88c6f2",
    },
  },
};
const DISTRIPUTOR_ROLES = [
  ADMIN_ROLES.BW_DELIVERYAGENT,
  ADMIN_ROLES.BW_SUPPLIERMANAGER,
  ADMIN_ROLES.BW_SUPPLIER_LOADERS,
];
function Dashboard(props) {
  const { window } = props;
  const history = useHistory();
  const { pathname } = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openAdminProfileDialog, setOpenAdminProfileDialog] =
    React.useState(false);
  const [isLogOut, setIsLogOut] = React.useState(false);
  const [drawerData, setDrawerData] = React.useState([]);
  const [profileData, setProfileData] = React.useState({});
  const [roleId, setRoleId] = React.useState(0);
  const [bId, setBId] = React.useState(0);
  // const [fullName,setFullName]=React.useState("");

  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const [requestPunchin, setRequestPunchin] = React.useState(false);
  const spinnerState = useSelector((state) => state.spinnerReducer);
  const appUpdateDetails = useSelector(
    (state) => state.appSettingsDetails.appUpdateDetails
  );
  const punchDetails = useSelector(
    (state) => state.vehicleManagement.punchDetails
  );
  const [openProgressBar, setOpenProgressBar] = React.useState(true);
  const ProfileDetails = useSelector((state) => state.profile);
  const [forceUpdateOpen, setForceUpdateOpen] = React.useState(false);
  CustomConsole(ProfileDetails);
  // CustomConsole(supplierDeliveries.getSupplerCompanyProfile.company_name);
  React.useEffect(() => {
    if (spinnerState.isLoading) {
      setOpenProgressBar(true);
    } else {
      setTimeout(() => {
        setOpenProgressBar(false);
      }, 500);
    }
  }, [spinnerState]);
  React.useEffect(() => {
    if (!punchDetails || !punchDetails.in_time || punchDetails.out_time) {
      if (
        DISTRIPUTOR_ROLES.includes(parseInt(localStorage.getItem("roleId")))
      ) {
        setRequestPunchin(true);
      } else if (["/dashboard/trip_planning"].includes(pathname)) {
        setRequestPunchin(true);
      } else {
        setRequestPunchin(false);
      }
    } else {
      setRequestPunchin(false);
    }
  }, [punchDetails, pathname]);
  React.useEffect(() => {
    setRoleId(localStorage.getItem("roleId"));
  }, []);

  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
  }, []);

  React.useEffect(() => {
    if (parseInt(localStorage.getItem("roleId")) !== ADMIN_ROLES.BW_PROMOTER) {
      dispatch(GetSupplierUserCompanyProfile());
    }
  }, []);
  const logoutUser = () => {
    dispatch({
      type: actionType.SIGN_OUT,
    });
    setIsLogOut(true);
    localStorage.removeItem("roleId");
  };
  const handleLogout = () => {
    if (punchDetails?.warehouse?.warehouse_id && !punchDetails?.out_time) {
      dispatch(EmployeesPunchInOut({ type: "OUT" })).then((data) => {
        if (data) {
          setTimeout(() => {
            logoutUser();
          }, 100);
        }
      });
    } else {
      logoutUser();
    }
  };

  // React.useEffect(() => {
  //   CustomConsole(localStorage.getItem("roleId"));
  //   if (true) {
  //     setDrawerData(SideBarData.adminDashboard);
  //   } else if (parseInt(localStorage.getItem("roleId")) === 1001) {
  //     setDrawerData(SideBarData.wfsDashboard);
  //   } else {
  //     setDrawerData([]);
  //   }
  // }, []);

  React.useEffect(() => {
    CustomConsole(localStorage.getItem("roleId"));
    if (parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_WFSADMIN) {
      setDrawerData(SideBarData.wfsDashboard);
    } else if (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_PROMOTER
    ) {
      setDrawerData(SideBarData.promoterDashboard);
    } else if (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_DISTRIBUTER
    ) {
      setDrawerData(SideBarData.supplierDashboard);
    } else if (
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_DELIVERYAGENT ||
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_SUPPLIER_LOADERS
    ) {
      setDrawerData(SideBarData.driverDashBoard);
    } else if (
      parseInt(localStorage.getItem("roleId")) ===
      ADMIN_ROLES.BW_SUPPLIERMANAGER
    ) {
      setDrawerData(SideBarData.managerDashBoard);
    } else if (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCE ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCEHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPS ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_QA ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_QAHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SALESHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SALES ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_IVML
    ) {
      setDrawerData(SideBarData.adminDashboard);
    }
  }, []);

  React.useEffect(() => {
    if (ProfileDetails.user) {
      setProfileData(ProfileDetails.user);
    }
  }, [ProfileDetails]);

  React.useEffect(() => {
    dispatch(GetPunchDetails());

    dispatch(GetUserProfile());
    if (parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_WFSADMIN) {
      CustomConsole("Hitting profile and wfs details API");
      dispatch(GetWfsDetails());
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickSettings = () => {
    CustomConsole("-------------handleClickSettings--------------");
  };

  const [title, setTitle] = React.useState("Home");
  // React.useEffect(() => {
  //   dispatch(GetUserProfile());
  // }, []);
  // CustomConsole(window.location.pathname);
  const [reload, setReload] = React.useState(true);

  // React.useEffect(() => {
  //   if (reload) {
  //     const pageTitle = pathname.split("/dashboard/");
  //     // const pageTitleSupplier = pathname.split("/dashboard/");
  //     CustomConsole(pageTitle[1]);
  //     refreshDashboard(pageTitle[1]);
  //     // refershSupplierDashboard(pageTitleSupplier[1]);
  //     setReload(false);
  //   }
  // }, []);

  // // To Control the Page Heading on Click of Back & froward Button
  React.useEffect(() => {
    const pageTitle = pathname.split("/dashboard/");
    CustomConsole(pageTitle[1]);
    refreshDashboard(pageTitle[1]);
    dispatch(GetAdminAppUpdateDetails());
  }, [pathname]);

  //function to check app update dialog
  React.useEffect(() => {
    if (appUpdateDetails && Object.keys(appUpdateDetails).length) {
      if (
        appUpdateDetails?.admin_web?.admin_version >
          process.env.REACT_APP_VERSION &&
        appUpdateDetails?.admin_web?.update_mandatory === true
      ) {
        setForceUpdateOpen(true);
      }
    }
  }, [appUpdateDetails]);

  // const refershSupplierDashboard = (pageTitleSupplier) => {
  //   if (pageTitleSupplier === ) {
  //   } else if (pageTitleSupplier === "orders") {

  //   } else if (pageTitleSupplier === ) {
  //   } else if (pageTitleSupplier === "settings") {

  //   }
  // };
  const refreshDashboard = (pageTitle) => {
    CustomConsole(pageTitle);
    const is_admin =
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN;
    const is_supplier =
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_DISTRIBUTER;
    const is_driver =
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_DELIVERYAGENT ||
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_SUPPLIERMANAGER ||
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_SUPPLIER_LOADERS;
    const is_WfsAdmin =
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_WFSADMIN;
    switch (pageTitle) {
      case "Dashboard":
        setTitle("Dashboard");
        if (is_admin) {
          setBId(1);
        } else if (is_supplier) {
          setBId(0);
        } else if (is_driver) {
          setBId(0);
        }
        break;
      case "wfsHome":
        setTitle("Home");
        setBId(0);
        break;
      case "batchDetails":
        setTitle("Batch Details");
        setBId(2);
        break;
      case "groupDetails":
        setTitle("Product Grouping");
        if (is_supplier) {
          setBId(10);
        } else if (is_admin) {
          setBId(24);
        } else {
          setBId(4);
        }

        break;

      case "superadmin":
        setTitle("Super Admin");
        setBId(2);
        break;
      case "wfsDetails":
        setTitle("WFS Can Orders");
        setBId(1);
        break;
      // case "users":
      //   setTitle("Users");
      //   setBId(3);
      //   break;
      case "orders":
        setTitle("Orders");
        if (is_supplier) {
          setBId(4);
        } else {
          setBId(4);
        }
        break;
      case "containerDetails":
        setTitle("Container Details");
        setBId(5);
        break;
      case "postpaid":
        setTitle("Postpaid");
        setBId(6);
        break;
      case "Dashboard":
        setTitle("AdmiNewDashboardMain");
        setBId(26);
        break;
      case "wfscanorders":
        setTitle("WFS Can Orders");
        setBId(7);
        break;
      case "addUpdateWFS":
        setTitle("Add/Update WFS");
        setBId(8);
        break;
      case "warehouse":
        setTitle("Ware House");
        setBId(8);
        break;
      case "trip_planning":
        setTitle("Trip Planning");
        setBId(0);
        break;
      case "Trip Planning":
        setTitle("Trip Planning");
        setBId(8);
        break;
      case "devices":
        setTitle("Devices");
        setBId(9);
        break;
      case "report":
        setTitle("Reports");
        setBId(10);
        break;
      case "qrBarcodeDetails":
        setTitle("QR/Barcode Details");
        if (is_WfsAdmin) {
          setBId(3);
        } else if (is_supplier) {
          setBId(9);
        } else {
          setBId(11);
        }
        break;
      case "influencer":
        setTitle("Influencers");
        setBId(12);
        break;
      case "banners":
        setTitle("Banners");
        setBId(14);
        break;
      case "promocode":
        setTitle("Promo Code Settings");
        setBId(15);
        break;
      case "pwdsetting":
        setTitle("PDW Settings");
        setBId(16);
        break;
      case "CurrentDayDeliveriesPerticularDetails":
        setTitle("Delivery");
        setBId(1);
        break;
      case "ReturnCanSingleOrder":
        setTitle("Return Can Details");
        setBId(1);
        break;
      case "BuyBackProductSingleOrder":
        setTitle("Buy Back Product");
        setBId(1);
        break;
      case "ReplacementSingleOrder":
        setTitle("Replacement Details");
        setBId(1);
        break;
      case "assign_agent_slot":
        setTitle("Assign Order");
        setBId(2);
        break;
      case "re_assign/agent":
        setTitle("Re-Assign Orders");
        setBId(3);
        break;
      case "WfsSingleOrder":
        setTitle("Order Details");
        setBId(1);
        break;
      case "settings":
        setTitle("Settings");
        if (is_supplier) {
          setBId(7);
        } else {
          setBId(20);
        }
        break;
      case "deliveries":
      case "driverdeliveries":
        setTitle("Deliveries");
        if (is_driver) {
          // setTitle("Deliveries");
          setBId(1);
        } else {
          // setTitle("Deliveries");
          setBId(1);
        }

        break;
      case "driverprofile":
        setTitle("Profile");
        setBId(2);
        break;
      case "marketing":
        setTitle("Marketing");
        setBId(17);
        break;
      case "booknow":
        setTitle("Book Now");
        setBId(5);
        break;
      case "prevCompleted":
        setTitle("Prev & Completed");
        setBId(3);
        break;
      case "postpaidtrack":
        setTitle("PostPaid");
        setBId(18);
        break;
      case "postpaidsettlement":
        setTitle("Settlement");
        break;
      case "transactions":
        setTitle("Transactions");
        setBId(19);
        break;
      case "settlement":
        setTitle("Influncer Settlement");
        setBId(25);
        break;
      case "operations_level_cost":
        setTitle("Operations");
        setBId(25);
        break;
      case "canSwapadmin":
        setTitle("Can Swap");
        setBId(21);
        break;
      case "newContainerDetails":
        setTitle("SKU");
        setBId(22);
        break;
      case "wfsAllDetails":
        setTitle("WFS");
        setBId(23);
        break;
      case "UserDetailsMain":
        setTitle("Users");
        setBId(3);
        break;
      default:
        setTitle("Dashboard");
        setBId(1);
        break;
    }
  };

  const navigationClickHandler = (id, title, path) => {
    CustomConsole(`id ${id}`);
    CustomConsole(`title ${title}`);
    history.push(path);
    setMobileOpen(false);
    setTitle(title);
    setBId(id);
  };

  if (isLogOut) {
    return <Redirect to="authenticate" />;
  }
  const punchOutUser = () => {
    dispatch(EmployeesPunchInOut({ type: "OUT" })).then(() => {
      // history.push({ hash: "#punch_in" });
    });
  };

  const drawer = (
    <Box
      className="drawer"
      sx={{
        background: "#152057",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={bwIcon}
          alt="icon"
          style={{ height: "35px", width: "30px", marginTop: "5px" }}
        />
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          sx={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "bold",
            fontSize: "14px",
            textAlign: "center",
            margin: "5px",
            // color: "black",
            color: "white",
          }}
        >
          {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_WFSADMIN
            ? "Water filling station"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN
            ? "Admin"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS
            ? "Customer Support"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD
            ? "Customer Support Head"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD
            ? "Customer Support Lead"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_IVML
            ? "Inventory Management Lead"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_FINANCE
            ? "Finance"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_FINANCEHEAD
            ? "Finance Head"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPS
            ? "Operations"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_OPSHEAD
            ? "Operations Head"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SALES
            ? "Sales"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SALESHEAD
            ? "Sales Head"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_QA
            ? "Quality Analyst"
            : parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_QAHEAD
            ? "Quality Analyst Head"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SUPERADMIN
            ? "Super Admin"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_DISTRIBUTER
            ? "Distributor"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_DELIVERYAGENT
            ? "Delivery Agent"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SUPPLIERMANAGER
            ? "Warehouse Manager"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SUPPLIER_LOADERS
            ? "Loader"
            : parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_PROMOTER
            ? "BookWater Promoter"
            : ""}
        </Typography>
        <Box>
          {parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_DISTRIBUTER ? (
            <Typography sx={{ color: "white" }}>Welcome</Typography>
          ) : (
            ""
          )}
          <Typography sx={{ color: "white" }}>
            {parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_DISTRIBUTER
              ? supplierDeliveries?.getSupplerCompanyProfile?.fullname
              : ""}
          </Typography>
        </Box>
      </Box>
      {/* <Toolbar /> */}
      <hr style={{ borderRadius: "10px", color: "white" }} />
      <List>
        {drawerData.map((item, index) => (
          <ListItem key={item.title} sx={{ background: "#152057" }}>
            {/* disablePadding */}
            <ListItemButton
              sx={[
                styles.button,
                bId === item.id
                  ? {
                      fontWeight: "bold",
                      borderRadius: "10px",
                      backgroundColor: "#5561e6",
                      color: "white",
                    }
                  : {
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "10px",
                    },
              ]}
              onClick={() =>
                navigationClickHandler(item.id, item.title, item.path)
              }
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <Typography
                sx={{
                  // fontWeight: "bold",
                  fontFamily: "'Montserrat', sans-serif",
                }}
              >
                {item.title}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <hr style={{ borderRadius: "10px", color: "white" }} />
      <Box sx={{ padding: "10px", color: "white" }}>
        {/* Version 0.0.22, Staging: Version 0.0.151*/}
        {/* <Typography> Version: 0.0.166 </Typography> */}
        <Typography> Version: {process.env.REACT_APP_VERSION} </Typography>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          // background: appColors.commonLayoutColor.background,
          // backgroundImage: appColors.commonLayoutColor.backgroundImage,
          background: "#152057",
          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
            xs: "100%",
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexGrow: { sm: 1, md: 1, xs: 1 },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                textAlign: "center",
                fontFamily: "'Montserrat', sans-serif",
                color: "white",
                // color: "black",
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <MuiIcon.Settings
                    sx={{ fontSize: "35px" }}
                    {...bindTrigger(popupState)}
                  />
                  <Menu {...bindMenu(popupState)}>
                    {punchDetails &&
                    punchDetails.in_time &&
                    !punchDetails.out_time ? (
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          punchOutUser();
                        }}
                      >
                        <PersonPinCircleIcon sx={{ m: 1, fontSize: "30px" }} />
                        Punch Out
                      </MenuItem>
                    ) : (
                      <></>
                    )}

                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        setOpenAdminProfileDialog(true);
                      }}
                    >
                      <MuiIcon.AccountCircle sx={{ m: 1, fontSize: "30px" }} />{" "}
                      Profile
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      {" "}
                      <Logout sx={{ m: 1, fontSize: "30px" }} /> Logout
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          backgroundColor: "#29358f",
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          className="drawer"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className="drawer"
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* @author vinay kumar
     @description Adding Supplier Dashboard
   @copyright Bookwater tech pvt ltd
   @changes Login Supplier Crenditial open Supplier Dashboard
    @version 0.0.1
     @Date 12-06-2023 */}

      <Box
        component="main"
        sx={{
          padding: "1rem",
          minHeight: "100vh",
          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
            xs: "100%",
          },
        }}
      >
        {parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_WFSADMIN ? (
          <Switch>
            {/* <Route path="/dashboard" exact component={WfsHome} /> */}
            <Route
              path="/dashboard/WfsSingleOrder"
              exact
              component={WfsSingleOrder}
            />
            <Route path="/dashboard/wfsHome" exact component={WfsHome} />
            <Route path="/dashboard/wfsDetails" exact component={WfsOrders} />
            <Route path="/dashboard/batchDetails" exact component={WfsBatch} />
            <Route
              path="/dashboard/groupDetails"
              exact
              component={WfsGroupProductsSku}
            />
            <Route
              path="/dashboard/qrBarcodeDetails"
              exact
              component={QRBarcode}
            />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        ) : parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_DISTRIBUTER ? (
          <Switch>
            <Route path="/dashboard/home" exact component={Home} />
            <Route
              path="/dashboard/deliveries"
              exact
              component={DeliveriesMain}
            />
            <Route
              path="/dashboard/ReturnCanSingleOrder"
              exact
              component={ReturnCanSingleOrder}
            />
            <Route
              path="/dashboard/BuyBackProductSingleOrder"
              exact
              component={BuyBackProductSingleOrder}
            />
            <Route path="/dashboard/wfsDetails" exact component={WfsOrders} />
            <Route
              path="/dashboard/CurrentDayDeliveriesPerticularDetails"
              exact
              component={CurrentDayDeliveriesPerticularDetails}
            />
            <Route
              path="/dashboard/qrBarcodeDetails"
              exact
              component={QRBarcode}
            />
            <Route
              path="/dashboard/groupDetails"
              exact
              component={WfsGroupProductsSku}
            />
            <Route
              path="/dashboard/assign_agent_slot"
              exact
              component={Assigning}
            />
            <Route
              path="/dashboard/re_assign/agent"
              exact
              component={ReassignSupplier}
            />
            <Route
              path="/dashboard/ReplacementSingleOrder"
              exact
              component={ReplacementSingleOrder}
            />
            <Route path="/dashboard/orders" exact component={OrdersMain} />
            <Route path="/dashboard/booknow" exact component={BookNowMain} />
            <Route path="/dashboard/settings" exact component={SettingsMain} />
            <Route
              path="/dashboard/warehouse"
              exact
              component={WareHouseMain}
            />

            <Route
              path="/dashboard/trip_planning"
              exact
              component={TripsMain}
            />

            <Route
              path="/dashboard/prevCompleted"
              exact
              component={PrvAndCompletedDeliveries}
            />
            <Route
              path="/dashboard/transactions"
              exact
              component={TransactionsPage}
            />
            {/* <Route
              path="/dashboard/canSwap"
              exact
              component={SupplierOrAgentCanSwapMain}
            /> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        ) : parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_PROMOTER ? (
          <Switch>
            <Route
              path="/dashboard/marketing"
              exact
              component={MarketingMain}
            />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        ) : parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_DELIVERYAGENT ||
          parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_SUPPLIER_LOADERS ? (
          <Switch>
            <Route path="/dashboard/home" exact component={Home} />
            <Route
              path="/dashboard/deliveries"
              exact
              component={DriverDeliveriesMain}
            />
            <Route
              path="/dashboard/driverprofile"
              exact
              component={DriverProfile}
            />
            <Route
              path="/dashboard/CurrentDayDeliveriesPerticularDetails"
              exact
              component={CurrentDayDeliveriesPerticularDetails}
            />
            <Route
              path="/dashboard/ReturnCanSingleOrder"
              exact
              component={ReturnCanSingleOrder}
            />
            <Route
              path="/dashboard/BuyBackProductSingleOrder"
              exact
              component={BuyBackProductSingleOrder}
            />
            <Route
              path="/dashboard/ReplacementSingleOrder"
              exact
              component={ReplacementSingleOrder}
            />
            <Route
              path="/dashboard/prevCompleted"
              exact
              component={PrvAndCompletedDeliveries}
            />
            <Route
              path="/dashboard/warehouse"
              exact
              component={EmployeesWarehouseDash}
            />
            <Route
              path="/dashboard/groupDetails"
              exact
              component={WfsGroupProductsSku}
            />
            {/* <Route
          path="/dashboard/canSwap"
          exact
          component={SupplierOrAgentCanSwapMain}
        /> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        ) : parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_SUPPLIERMANAGER ? (
          <Switch>
            <Route
              path="/dashboard/trip_planning"
              exact
              component={TripsMain}
            />

            <Route path="/dashboard/home" exact component={Home} />
            <Route
              path="/dashboard/deliveries"
              exact
              component={DriverDeliveriesMain}
            />
            <Route
              path="/dashboard/driverprofile"
              exact
              component={DriverProfile}
            />
            <Route
              path="/dashboard/CurrentDayDeliveriesPerticularDetails"
              exact
              component={CurrentDayDeliveriesPerticularDetails}
            />
            <Route
              path="/dashboard/ReturnCanSingleOrder"
              exact
              component={ReturnCanSingleOrder}
            />
            <Route
              path="/dashboard/BuyBackProductSingleOrder"
              exact
              component={BuyBackProductSingleOrder}
            />
            <Route
              path="/dashboard/ReplacementSingleOrder"
              exact
              component={ReplacementSingleOrder}
            />
            <Route
              path="/dashboard/prevCompleted"
              exact
              component={PrvAndCompletedDeliveries}
            />
            <Route
              path="/dashboard/assign_agent_slot"
              exact
              component={Assigning}
            />
            <Route
              path="/dashboard/re_assign/agent"
              exact
              component={ReassignSupplier}
            />
            <Route
              path="/dashboard/warehouse"
              exact
              component={EmployeesWarehouseDash}
            />
            <Route
              path="/dashboard/groupDetails"
              exact
              component={WfsGroupProductsSku}
            />
            {/* <Route
      path="/dashboard/canSwap"
      exact
      component={SupplierOrAgentCanSwapMain}
    /> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        ) : (
          <Box>
            {parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_ADMIN ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SUPERADMIN ||
            parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_CSHEAD ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_CSLEAD ||
            parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_IVML ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_FINANCE ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_FINANCEHEAD ||
            parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_OPS ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_OPSHEAD ||
            parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_QA ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_QAHEAD ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SALESHEAD ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SALES ? (
              <>
                <Switch>
                  <Route path="/dashboard/home" exact component={Home} />
                  <Route path="/dashboard/users" exact component={AdminUsers} />
                  <Route
                    path="/dashboard/distributors"
                    exact
                    component={AdminDistributors}
                  />
                  <Route
                    path="/dashboard/orders"
                    exact
                    component={OrdersAdmin}
                  />

                  <Route
                    path="/dashboard/postpaid"
                    exact
                    component={PostpaidOrders}
                  />
                  <Route
                    path="/dashboard/devices"
                    exact
                    component={AdminIotDevices}
                  />
                  <Route
                    path="/dashboard/containerDetails"
                    exact
                    component={ContainerDetails}
                  />
                  <Route
                    path="/dashboard/newContainerDetails"
                    exact
                    component={NewContainerDetailsMain}
                  />
                  <Route
                    path="/dashboard/wfsAllDetails"
                    exact
                    component={WfsDetailsSku}
                  />
                  <Route
                    path="/dashboard/qrBarcodeDetails"
                    exact
                    component={QRBarcode}
                  />
                  <Route
                    path="/dashboard/groupDetails"
                    exact
                    component={WfsGroupProductsSku}
                  />
                  <Route
                    path="/dashboard/addUpdateWFS"
                    exact
                    component={AddUpdateWFS}
                  />
                  <Route
                    path="/dashboard/withdrawalHistory"
                    exact
                    component={AdminWithdrawal}
                  />
                  <Route
                    path="/dashboard/wfsCanorders"
                    exact
                    component={WfsCanOrdersAdmin}
                  />
                  <Route
                    path="/dashboard/banners"
                    exact
                    component={AdminBanners}
                  />
                  <Route
                    path="/dashboard/lostCans"
                    exact
                    component={LostCans}
                  />
                  <Route
                    path="/dashboard/UserDetailsMain"
                    exact
                    component={UserDetailsMain}
                  />
                  <Route
                    path="/dashboard/settings"
                    exact
                    component={Settings}
                  />
                  <Route
                    path="/dashboard/transactions"
                    exact
                    component={TransactionsPage}
                  />
                  <Route
                    path="/dashboard/emptycanorders"
                    exact
                    component={EmptyCanOrder}
                  />
                  <Route
                    path="/dashboard/replecementOrder"
                    exact
                    component={AdminReplacementOrder}
                  />
                  <Route
                    path="/dashboard/report"
                    exact
                    component={Adminreports}
                  />
                  <Route
                    path="/dashboard/pwdsetting"
                    exact
                    component={Pwdsettingpage}
                  />
                  <Route
                    path="/dashboard/superadmin"
                    exact
                    component={SuperAdmin}
                  />
                  <Route
                    path="/dashboard/promocode"
                    exact
                    component={PromoCodemain}
                  />
                  <Route
                    path="/dashboard/influencer"
                    exact
                    component={AdminInfluencers}
                  />
                  <Route
                    path="/dashboard/settlement"
                    exact
                    component={AdminSettlement}
                  />
                  <Route
                    path="/dashboard/operations_level_cost"
                    exact
                    component={OperationsLevelCostMain}
                  />
                  <Route
                    path="/dashboard/marketing"
                    exact
                    component={MarketingMain}
                  />
                  {/* <Route
                    path="/dashboard/distributorDetails"
                    exact
                    component={AdminDistributorDetailsMain}
                  /> */}

                  <Route
                    path="/dashboard/postpaidtrack"
                    exact
                    component={Postpaid}
                  />
                  <Route
                    path="/dashboard/postpaidsettlement"
                    exact
                    component={SettlementPagePostPaid}
                  />
                  <Route
                    path="/dashboard/canSwapadmin"
                    exact
                    component={AdminCanSwapMain}
                  />
                  <Route
                    path="/dashboard/AdmiNewDashboardMain"
                    exact
                    component={AdmiNewDashboardMain}
                  />
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </>
            ) : (
              ""
            )}
          </Box>
        )}

        {/* <Toolbar /> */}
      </Box>
      <AdminProfileDialog
        openAdminProfileDialog={openAdminProfileDialog}
        handleOnClose={() => setOpenAdminProfileDialog(false)}
        profileData={profileData}
      />
      {requestPunchin ? (
        <>
          <PunchInModel
            open={requestPunchin}
            // onPunchIn={onPunchIn}
          />
        </>
      ) : (
        <></>
      )}

      {forceUpdateOpen && (
        <AppForceUpdateDialog
          forceUpdateOpen={forceUpdateOpen}
          setForceUpdateOpen={setForceUpdateOpen}
        />
      )}

      {openProgressBar && (
        <CustomLoaderDialog
          open={openProgressBar}
          handleClose={() => setOpenProgressBar(false)}
        />
      )}
    </Box>
  );
}

Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Dashboard;
