import React from "react";
import {
  AppBar,
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CustomConsole from "../../CustomConsole";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import {
  GetInvoiceData,
  GetPriceBreakDown,
  ItemizedInvoice,
  ItemInvoiceProgressReset,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { useDispatch, useSelector } from "react-redux";
import ConsolidatedDialog from "./ConsolidatedDialog";
import moment from "moment/moment";
import { SettlementPostpaid } from "../Redux/Actions/adminPoTrackPostpaid";
import { SearchUserAdmin } from "../Redux/Actions/addUserAdminAction";
import {
  GetUserBasedName,
  GetWalletDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { toast } from "react-toastify";
import {
  GetPoTrackDetails,
  SendEmailPostpaidConsumer,
  DownloadChallanInvoiceZip,
} from "../Redux/Actions/adminPoTrackPostpaid";
import {
  InvoiceGeneration,
  InvoiceProgressReset,
} from "../Redux/Actions/invoiceAction";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { useReactToPrint } from "react-to-print";
import FolderZipSharpIcon from "@mui/icons-material/FolderZipSharp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import UserCreditNoteDialog from "../../Components/Dialog/creditNoteDialog";
import { GetCreditNoteDetails } from "../Redux/Actions/buyBackOrdersAction";
import { KeyboardBackspace } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SettlementPagePostPaid({
  open,
  setOpen,
  poTrackSet,
  hideSearchHeader,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const poDetails = useSelector((state) => state.poTrackDetails);
  const addUserAdminDetails = useSelector(
    (state) => state.addUserAdminDetails.users
  );
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const invoiceProgress = useSelector((state) => state.invoicegenerate);
  const userName = useSelector((state) => state.userDetailsUpdate.userNames);

  const [invoiceData, setInvoiceData] = React.useState([]);

  const [priceBreak, setPriceBreak] = React.useState({});
  const [selectedInvoice, setSelectedInvoice] = React.useState(0);
  const [openConsoliDialog, setOpenConsoliDialog] = React.useState(false);
  const [isSettled, setIsSettled] = React.useState(false);
  const [payConsId, setPayConsId] = React.useState("");
  const [walletBal, setWalletBal] = React.useState(0);
  const [cancelInvTost, setCancelInvTost] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState(null);
  const [itemIntervel, setItemIntervel] = React.useState(null);
  const [rotate, setRotate] = React.useState(false);
  const [rotateId, setRotateId] = React.useState(0);
  const [rotateItem, setRotateItem] = React.useState(false);
  const [rotateItemId, setRotateItemId] = React.useState(0);
  const [getPhone, setGetPhone] = React.useState("");
  const [getName, setGetName] = React.useState("");
  const [nameSearch, setNameSearch] = React.useState([]);
  const [searchOn, setSearchON] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [creditDialogOpen, setCreditDialogOpen] = React.useState(false);
  const [selectedCreditNotes, setSelectedCreditNotes] = React.useState([]);
  const [creditDetails, setCreditDetails] = React.useState([]);
  const pdfRef = React.useRef();

  const handleClose = () => {
    setOpen(false);
  };
  const checkBoxFun = (e, item) => {
    CustomConsole(item);
    if (e.target.checked === true) {
      let obj = {
        invoiceId: item.invoice_id,
      };
      setPriceBreak(obj);
      setSelectedInvoice(parseInt(item.invoice_id));
    } else if (e.target.checked === false) {
      setPriceBreak({});
      setSelectedInvoice(0);
    }
  };

  const getPriceBreak = () => {
    if (Object.keys(priceBreak).length) {
      dispatch(GetPriceBreakDown(priceBreak));
      setOpenConsoliDialog(true);
    }
  };

  const cancelInvoice = () => {
    let reqObj = {
      invoiceId: selectedInvoice,
      is_cancelled: true,
    };
    CustomConsole(reqObj);
    dispatch(SettlementPostpaid(reqObj)).then((resp) => {
      if (!resp.error) {
        setPriceBreak({});
      }
    });
    setCancelInvTost(true);
  };
  // --------------------Invoice Download-----------
  const downloadInvoice = (id) => {
    dispatch(InvoiceGeneration(id));
    setRotateId(id);
    setRotate(true);
  };

  const sendInvoiceEmail = (id) => {
    let reqObj = {
      invoiceId: id,
      user: payConsId,
    };
    // CustomConsole(reqObj);
    dispatch(SendEmailPostpaidConsumer(reqObj));
  };

  const downloadItemInvoice = (id) => {
    dispatch(ItemizedInvoice(id));
    setRotateItemId(id);
    setRotateItem(true);
  };

  const handleSearchBtn = () => {
    console.log("-----Inside----");
    setCreditDetails([]);
    setPriceBreak({});
    dispatch(SearchUserAdmin(getPhone)).then((data) => {
      console.log("-----Data from search user----");
      console.log(data);
      dispatch(GetInvoiceData(data.users[0].id));
      setUserId(data.users[0].id);
      dispatch(
        GetCreditNoteDetails({
          user_id: data.users[0].id,
          order_status: 100,
          page_size: 100,
        })
      ).then((data) => {
        if (!data?.error) {
          setCreditDetails(data?.data);
        }
      });
    });
    setSearchON(true);
  };

  const pdfDownload = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => CustomConsole("before printing..."),
    onAfterPrint: () => CustomConsole("after printing..."),
    removeAfterPrint: true,
  });

  const downloadDcInvoiceZip = (id, pdf) => {
    dispatch(DownloadChallanInvoiceZip(id, pdf));
  };

  // React.useEffect(() => {
  //   if (searchOn && payConsId) {
  //     CustomConsole("------User Id----");
  //     CustomConsole(payConsId);
  //     dispatch(GetInvoiceData(payConsId));
  //     // dispatch(ResetUserData());
  //     setSearchON(false);
  //   }
  // }, [payConsId, searchOn]);

  React.useEffect(() => {
    if (getName && getName.length) {
      const timerId = setTimeout(() => {
        CustomConsole(getName);
        const reqObj = {
          name: getName,
          ignore_app_loading_spinner: true,
        };
        dispatch(GetUserBasedName(reqObj));
      }, 200);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [getName]);

  React.useEffect(() => {
    CustomConsole("------User Search details-----");
    CustomConsole(userName);
    if (userName) {
      setNameSearch(userName);
    } else {
      setNameSearch([]);
    }
  }, [userName]);

  React.useEffect(() => {
    CustomConsole("-----Loading----Invoice---");
    CustomConsole(invoiceProgress);

    if (parseInt(invoiceProgress.progress) === 100) {
      const timerId = setTimeout(() => {
        CustomConsole("----Setting back to zero----");
        dispatch(InvoiceProgressReset(0));
      }, 200);
      setIntervalId(timerId);
    }
    if (parseInt(invoiceProgress.progress) === 0) {
      setRotate(false);
    }

    if (parseInt(invoiceProgress.invoiceGenerationProg) === 0) {
      setRotateItem(false);
    } else if (parseInt(invoiceProgress.invoiceGenerationProg) === 100) {
      const timerId = setTimeout(() => {
        CustomConsole("----Setting back to zero----");
        dispatch(ItemInvoiceProgressReset(0));
      }, 200);
      setItemIntervel(timerId);
    }

    return () => {
      if (intervalId) {
        clearTimeout(intervalId);
      }
      if (itemIntervel) {
        clearTimeout(itemIntervel);
      }
    };
  }, [invoiceProgress]);

  React.useEffect(() => {
    if (poTrackSet) {
      dispatch(GetInvoiceData(poTrackSet));
    }
  }, [poTrackSet]);

  React.useEffect(() => {
    CustomConsole(poDetails.invoiceData);
    if (poDetails.invoiceData) {
      setInvoiceData(poDetails.invoiceData);
    }
  }, [poDetails.invoiceData]);

  React.useEffect(() => {
    CustomConsole(addUserAdminDetails);
    CustomConsole("---------Setting Uid---------");
    if (addUserAdminDetails && addUserAdminDetails.length) {
      CustomConsole("---------Inside if Setting Uid---------");
      setPayConsId(addUserAdminDetails[0].id);

      // setAddressCalled(true)
      // addUserAdminDetails.users = [];
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (payConsId) {
      dispatch(GetWalletDetails(payConsId));
    }
  }, [payConsId]);

  React.useEffect(() => {
    if (updateUserDetails.walletAmount) {
      setWalletBal(
        () => updateUserDetails.walletAmount ?? updateUserDetails.walletAmount
      );
    }
  }, [updateUserDetails]);

  React.useEffect(() => {
    CustomConsole("----inside the toast Effect----- ");
    if (poDetails.error === false && cancelInvTost === true) {
      CustomConsole("----Inside the toast Effect if condition----- ");
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(GetInvoiceData(payConsId));
      setCancelInvTost(false);
    } else if (poDetails.error === true && cancelInvTost === true) {
      toast.error(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setCancelInvTost(false);
    }
    setCancelInvTost(false);
  }, [poDetails, payConsId]);

  //function to open credit dialog
  const handelCreditDetails = () => {
    if (creditDetails?.length > 0) {
      setCreditDialogOpen(true);
    } else {
      toast.error("Credit details not found for this user.", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            background: appColors.blueColor,
            color: appColors.white,
            boxShadow: 5,
            marginTop: "3.5rem",
            width: "100%",
            borderRadius: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              padding: ".5rem",
              gap: 1,
            }}
          >
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => history.push("/dashboard/postpaidtrack")}
                aria-label="close"
                sx={{ fontSize: "1rem" }}
              >
                <KeyboardBackspace /> Back
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexWrap: "wrap",

                gap: 1,
              }}
            >
              <FormControlLabel
                value="end"
                control={
                  <Switch
                    checked={isSettled}
                    onChange={() => {
                      setIsSettled((boo) => !boo);
                    }}
                    color="success"
                  />
                }
                label="Settled Orders"
                labelPlacement="end"
              />

              {!hideSearchHeader && (
                <Box>
                  <Autocomplete
                    id="select-user-name-consolidate"
                    sx={{ width: 220 }}
                    options={nameSearch}
                    autoHighlight
                    getOptionLabel={(nameSearch) => `${nameSearch.fullname}`}
                    onChange={(_, newValue) => {
                      setGetPhone(newValue ? newValue.phone : ""); // Set the selected description as the value
                    }}
                    renderOption={(props, nameSearch) => (
                      <Box
                        key={nameSearch.phone}
                        component="li"
                        // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {nameSearch.fullname} {nameSearch.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputLabelProps={{
                          style: { backgroundColor: "white" },
                        }}
                        key={params.phone}
                        placeholder="Enter Name"
                        value={setGetName}
                        onChange={(e) => {
                          setGetName(e.target.value);
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          style: {
                            backgroundColor: "white",
                            borderRadius: "4px",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              )}
              {!hideSearchHeader && (
                <Button
                  variant="outlined"
                  size="small"
                  color="success"
                  sx={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: ".9rem",
                    borderColor: "wheat",
                    borderWidth: "2px",
                    marginLeft: "5px",
                  }}
                  startIcon={<SearchIcon />}
                  onClick={handleSearchBtn}
                >
                  Search
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: ".5rem",
            boxShadow: 5,
            borderRadius: ".5rem",
          }}
        >
          <TableContainer style={{ maxHeight: "82vh" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead stickyHeader>
                <TableRow>
                  <TableCell>Invoice Id</TableCell>
                  <TableCell>DC Zip</TableCell>
                  <TableCell>DC PDF</TableCell>
                  <TableCell>Invoice Date</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Item Invoice</TableCell>
                  <TableCell>Mail Invoice</TableCell>
                  <TableCell>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData.map((obj, idx) => {
                  return obj.is_settled === isSettled ? (
                    <TableRow
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {obj.invoice_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {obj.invoice_status !== 300 ? (
                          <IconButton
                            onClick={() =>
                              downloadDcInvoiceZip(obj.invoice_id, false)
                            }
                          >
                            <FolderZipSharpIcon />
                          </IconButton>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {obj.invoice_status !== 300 ? (
                          <IconButton
                            onClick={() =>
                              downloadDcInvoiceZip(obj.invoice_id, true)
                            }
                          >
                            <PictureAsPdfIcon />
                          </IconButton>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {obj.invoice_date
                          ? moment(obj.invoice_date)
                              .tz("Asia/kolkata")
                              .format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {obj.invoice_status !== 300 ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            {/* <div style={{ display: "none" }}>
                              {" "}
                              <ForwardInvoiceGeneration ref={pdfRef} />
                            </div>
                            <Button
                              onClick={() =>
                                pdfDownload(null, () => pdfRef.current)
                              }
                            >
                              Download Invoice
                            </Button> */}
                            <IconButton
                              variant="outlined"
                              size="small"
                              onClick={() => downloadInvoice(obj.invoice_id)}
                            >
                              <DownloadIcon />
                            </IconButton>
                            {rotate && rotateId === obj.invoice_id ? (
                              <CircularProgress color="inherit" />
                            ) : null}
                          </Box>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell>
                        {obj.invoice_status !== 300 ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                downloadItemInvoice(obj.invoice_id)
                              }
                            >
                              <DownloadIcon />
                            </IconButton>
                            {rotateItem && rotateItemId === obj.invoice_id ? (
                              <CircularProgress color="inherit" />
                            ) : null}
                          </Box>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell>
                        {obj.invoice_status !== 300 ? (
                          <Button
                            type="small"
                            size="small"
                            variant="outlined"
                            onClick={() => sendInvoiceEmail(obj.invoice_id)}
                          >
                            Email Invoice
                          </Button>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {!isSettled && obj.invoice_status !== 300 ? (
                          <Box>
                            <Checkbox
                              onChange={(e) => checkBoxFun(e, obj)}
                              size="small"
                              checked={
                                selectedInvoice === parseInt(obj.invoice_id)
                              }
                            />
                          </Box>
                        ) : isSettled ? (
                          <span>Settled</span>
                        ) : obj.invoice_status === 300 ? (
                          <span>Cancelled</span>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ) : null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {openConsoliDialog && (
          <ConsolidatedDialog
            open={openConsoliDialog}
            setOpen={setOpenConsoliDialog}
            selectedInvoice={selectedInvoice}
            walletBal={walletBal}
            payConsId={payConsId}
            poTrackSet={poTrackSet}
            selectedCreditNotes={selectedCreditNotes}
            setSelectedCreditNotes={setSelectedCreditNotes}
            userId={userId}
            setPriceBreak={setPriceBreak}
          />
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          "& > :not(style)": { m: 1 },
        }}
      >
        {Object.keys(priceBreak).length && !isSettled ? (
          <Fab
            variant="extended"
            color="error"
            size="small"
            onClick={cancelInvoice}
          >
            Cancel
          </Fab>
        ) : null}
        {Object.keys(priceBreak).length && !isSettled ? (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={getPriceBreak}
          >
            Price Details
          </Fab>
        ) : null}
        {Object.keys(priceBreak).length && !isSettled ? (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={handelCreditDetails}
          >
            Credit Details
          </Fab>
        ) : null}
      </Box>
      <UserCreditNoteDialog
        open={creditDialogOpen}
        setOpen={setCreditDialogOpen}
        selectedCreditNotes={selectedCreditNotes}
        setSelectedCreditNotes={setSelectedCreditNotes}
        creditDetails={creditDetails}
      />
    </>
  );
}

export default SettlementPagePostPaid;
