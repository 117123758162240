import { Cancel, Verified } from "@mui/icons-material";
import { Box, Button, ThemeProvider, createTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

function ApprovalCampTableView(props) {
  const handleApproveRejectButton = (objData) => {
    let actionObject = JSON.parse(JSON.stringify(objData.data));
    actionObject.response_id = actionObject.responseId;
    actionObject.referral_id = actionObject.userReferralCode;
    if (objData.is_accepted) {
      actionObject.is_accepted = true;
      actionObject.rewardStatus = 300;
    } else {
      actionObject.is_accepted = false;
      actionObject.rewardStatus = 200;
    }
    CustomConsole("-- actionObject --");
    CustomConsole(actionObject);
    props.handleApprovalButtonAction(actionObject);
  };

  const headerData = [
    { field: "consumerName", headerName: "Customer Name", width: 130 },
    { field: "rewardPoints", headerName: "Reward Points", width: 100 },
    {
      field: "accept",
      headerName: "Accept",
      width: 100,
      renderCell: (params) => {
        CustomConsole("ufjffiuhlihil");
        CustomConsole(params);
        return (
          <Button
            disabled={
              (localStorage.getItem("roleId") &&
                parseInt(localStorage.getItem("roleId")) ===
                  ADMIN_ROLES.BW_PROMOTER) ||
              params.row.status !== "PENDING" ||
              !params.row.userReferral ||
              params.row.userReferral === "-" ||
              params.row.consumerPhone === "-"
                ? true
                : false
            }
            sx={{
              backgroundColor: "green",
              "&:hover": { backgroundColor: "black" },
            }}
            variant="contained"
            startIcon={<Verified />}
            onClick={() =>
              handleApproveRejectButton({
                data: params.row.gameDetails,
                is_accepted: true,
              })
            }
          >
            Accept
          </Button>
        );
      },
    },
    {
      field: "reject",
      headerName: "Reject",
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            disabled={
              params.row.status !== "PENDING" ||
              (localStorage.getItem("roleId") &&
                parseInt(localStorage.getItem("roleId")) ===
                  ADMIN_ROLES.BW_PROMOTER)
                ? true
                : false
            }
            sx={{
              backgroundColor: "red",
              "&:hover": { backgroundColor: "black" },
            }}
            variant="contained"
            startIcon={<Cancel />}
            onClick={() =>
              handleApproveRejectButton({
                data: params.row.gameDetails,
                is_accepted: false,
              })
            }
          >
            Reject
          </Button>
        );
      },
    },
    { field: "gameName", headerName: "Game Name", width: 130 },
    { field: "campName", headerName: "Campaign Name", width: 130 },
    { field: "campLocation", headerName: "Campaign Location", width: 130 },
    { field: "gameDescription", headerName: "Game Description", width: 130 },
    { field: "salesPoints", headerName: "Sales Points", width: 100 },
    { field: "promoterEmail", headerName: "Promoter Email", width: 130 },
    { field: "salesRepEmail", headerName: "Sales Rep. Email", width: 130 },
    { field: "startDate", headerName: "Start Date", width: 90 },
    { field: "endDate", headerName: "End Date", width: 90 },
    { field: "status", headerName: "Game Satus", width: 90 },
    { field: "userReferral", headerName: "Referral code", width: 90 },
    { field: "consumerEmail", headerName: "Customer Email", width: 130 },
    { field: "consumerPhone", headerName: "Customer Phone", width: 130 },
    { field: "id", headerName: "SNO", width: 70 },
  ];
  const rows = props.data.map((playedGameDetails, index) => {
    CustomConsole(index);
    return {
      id: index + 1,
      campName: playedGameDetails.name,
      campLocation:
        playedGameDetails.location + "," + playedGameDetails.subLocation,
      gameName: playedGameDetails.customerReward.name,
      gameDescription: playedGameDetails.customerReward.description,
      rewardPoints: playedGameDetails.customerReward.points,
      salesPoints: playedGameDetails.customerAction.points,
      promoterEmail: playedGameDetails.promoterEmail,
      salesRepEmail: playedGameDetails.salesRepEmail,
      startDate: moment(playedGameDetails.startDate).format("DD/MM/YYYY"),
      endDate: moment(playedGameDetails.endDate).format("DD/MM/YYYY"),
      status: playedGameDetails.rewardStatus
        ? parseInt(playedGameDetails.rewardStatus) === 100
          ? "PENDING"
          : parseInt(playedGameDetails.rewardStatus) === 200
          ? "APPROVED"
          : parseInt(playedGameDetails.rewardStatus) === 300
          ? "REJECTED"
          : "-"
        : "-",
      userReferral: playedGameDetails.userReferralCode
        ? playedGameDetails.userReferralCode
        : "-",
      gameDetails: playedGameDetails,
      consumerName: playedGameDetails.consumerDetails.name
        ? playedGameDetails.consumerDetails.name
        : "-",
      consumerEmail: playedGameDetails.consumerDetails.email
        ? playedGameDetails.consumerDetails.email
        : "-",
      consumerPhone: playedGameDetails.consumerDetails.phone
        ? playedGameDetails.consumerDetails.phone
        : "-",
    };
  });

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#a3a1e6",
            color: "black",
            fontWeight: "bold",
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });
  return (
    <Box
      sx={{
        height: 520,
        maxWidth: { xs: "300px", sm: "300px", md: "100%", lg: "100%" },
        overflow: "auto",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={rows}
          columns={headerData}
          autoHeight
          hideFooter={true}
          sx={{
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </ThemeProvider>
    </Box>
  );
}

export default ApprovalCampTableView;
