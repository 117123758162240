import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  Chip,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import CustomConsole from "../../CustomConsole";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { useDispatch } from "react-redux";
import { ValidatingProductsSubscription } from "../Redux/Actions/adminSkuBookingAction";
import { useSelector } from "react-redux";

const CustomButton = styled(Button)({
  backgroundColor: "#1976d2",
  color: "white",
  "&:hover": {
    backgroundColor: "#115293",
  },
});

function ProductsCard({
  products,
  setProducts,
  addressId,
  bookingType,
  otpLess,
  orderDate,
  payMethod,
  setPayMethod,
  setSelectedCoup,
  setwaterDrops,
  setActiveStep,
  checks,
  selectedDays,
  nextDeliveryDate,
}) {
  const dispatch = useDispatch();
  const productDet = useSelector((state) => state.adminSkuBookingData);
  const [validationTrigg, setValidationTrigg] = React.useState(false);
  const [enableMoveCart, setEnableCart] = React.useState(true);

  const validateProduct = () => {
    setSelectedCoup("");
    setwaterDrops(0);
    let reqObj = {
      products: products,
      booking_type: parseInt(bookingType),
      address_id: addressId,
      coupon_code: "",
      water_drops: 0,
      payment_method: payMethod,
      is_otp_less: otpLess,
      delivery_date: nextDeliveryDate,
      is_auto_apply_water_drops: checks.waterDrops,
      is_auto_apply_coupon: checks.promo,
    };

    CustomConsole(products);
    dispatch(ValidatingProductsSubscription(reqObj));
    setValidationTrigg(true);
  };

  React.useEffect(() => {
    CustomConsole("-----Booking reducer----");
    CustomConsole(productDet);
    if (validationTrigg === true && !productDet.subsValidatingErr) {
      productDet.subsValidatingErr = false;
      setValidationTrigg(false);
      setActiveStep(1);
    }
  }, [productDet]);

  React.useEffect(() => {
    if (selectedDays && selectedDays.length > 0) {
      setEnableCart(false);
    } else {
      setEnableCart(true);
    }
  }, [selectedDays]);

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        backgroundColor: "#f0f5f9",
        width: "100%",
        minWidth: 103,
        margin: "auto",
      }}
    >
      <CardContent>
        {products.length === 0 ? (
          <Typography variant="body1" align="center">
            No products to display
          </Typography>
        ) : (
          <Stack
            direction="row"
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={1}
            rowGap={1}
            mb={2}
            sx={{ overflow: "auto", flexWrap: "wrap" }}
          >
            {products.map((product, idx) => (
              <Chip
                key={idx}
                label={`${product.material}-${product.capacity} : (Ordering: ${product.quantity}, Returning: ${product.return})`}
                color="primary"
                onDelete={() =>
                  setProducts((prev) =>
                    prev.filter(
                      (item) =>
                        !(
                          item.capacity === product.capacity &&
                          item.material === product.material
                        )
                    )
                  )
                }
                sx={{ margin: "4px" }}
              />
            ))}
          </Stack>
        )}
        {products.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormControl size="small" sx={{ width: "40%" }}>
              <InputLabel
                id="product-type-select-label"
                sx={{ fontSize: "1rem", marginTop: "2px" }}
              >
                Payment Method
              </InputLabel>

              <Select
                labelId="product-type-select-label"
                id="product-type-select"
                value={payMethod}
                label="Payment Method"
                onChange={(e) => setPayMethod(e.target.value)}
              >
                <MenuItem value={"WALLET"}>WALLET</MenuItem>
              </Select>
            </FormControl>
            <CustomButton
              variant="contained"
              size="small"
              startIcon={<ShoppingCartCheckoutIcon />}
              onClick={validateProduct}
              disabled={enableMoveCart}
            >
              Move to cart
            </CustomButton>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default ProductsCard;
