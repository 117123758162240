import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import {
  ForgotPasswordNewPassword,
  LogIn,
  PromoterEmailVerification,
  PromoterLogin,
  PromotersList,
  ResetPasswordEmailVerify,
} from "../Redux/Actions/authAction";
import { Redirect } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { PostLoginSupplier } from "../Redux/Actions/authAction";
// import React from 'react'
import "../Common/drawer.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toast } from "react-toastify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { MailLock } from "@mui/icons-material";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.bookwater.com/">
        Bookwater
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentications);
  const [isLogin, setIsLogin] = React.useState(false);
  const [isEmailProper, setIsEmailProper] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [phoneOrEmail, setPhoneOrEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isEmailProperForVerify, setIsEmailProperForVerify] =
    React.useState(false);
  const [resetEmail, setResetEmail] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [verificationCodeSent, setVerificationCodeSent] = React.useState(false);
  const [isPasswordProper, setIsPasswordProper] = React.useState(false);
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [alignment, setAlignment] = React.useState("admin");
  const [promoterEmail, setPromoterEmail] = React.useState([]);
  const [selectedPromoterEmail, setSelectedPromoterEmail] = React.useState("");

  const [emailOtp, setEmailOTP] = React.useState("");
  const [isOtpSent, setIsOtpSent] = React.useState(false);
  const [timerCountDown, setTimerCountDown] = React.useState(30);

  // React.useEffect(() => {
  //   if (timerCountDown && isOtpSent) {
  //     setTimeout(() => {
  //       setTimerCountDown(() => timerCountDown - 1);
  //     }, 1000);
  //   }
  // }, [timerCountDown]);
  React.useEffect(() => {
    // Check if "Remember me" preference is stored
    const storedRememberMe = localStorage.getItem("rememberMe");
    if (storedRememberMe === "true") {
      setRememberMe(true);
      CustomConsole(localStorage.getItem("email"));
      CustomConsole(localStorage.getItem("password"));
      const storedEmail = localStorage.getItem("email");
      const storedPassword = localStorage.getItem("password");
      setEmail(storedEmail || "");
      setPassword(storedPassword || "");
    }
  }, []);
  React.useEffect(() => {
    if (alignment === "promoter") {
      dispatch(PromotersList());
    }
  }, [alignment]);
  /**
   * @author vinay kumar
   * @description Dispatch Supplier Login Api here
   * @copyright Bookwater tech pvt ltd
   * @Changes while login Hitting LoginSupplier Api
   * @version 0.0.1
   * @Date 12-06-2023
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (alignment === "admin") {
      // email.match(/@(.+)/)?.[1]
      CustomConsole(data.get("email").split("@")[1]);
      if (data.get("email").split("@")[1] === "bookwater.com") {
        dispatch(LogIn(data.get("email"), data.get("password")));
      } else {
        toast.error("Please enter a valid bookwater email address!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else if (alignment === "distributor") {
      if (data.get("email").split("@")[1] === "bookwater.com") {
        toast.error(
          "Please enter a valid distributor or delivery agent email/phone!",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else {
        dispatch(PostLoginSupplier(data.get("email"), data.get("password")));
      }
    } else if (alignment === "promoter") {
      // dispatch(PostLoginSupplier(data.get("email"), data.get("password")));
      CustomConsole("-- promoter ---");
    }
    // if (alignment === "distributor") {
    //   dispatch(
    //     PostLoginSupplier(data.get("email"), data.get("password"))
    //   );
    // }
    CustomConsole({
      email: data.get("email"),
      password: data.get("password"),
    });
    if (rememberMe) {
      localStorage.setItem("rememberMe", "true");
      localStorage.setItem("email", data.get("email"));
      localStorage.setItem("password", data.get("password"));
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
  };

  const handleResetVerifyEmail = (event) => {
    let reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,7}$/;
    var result = reg.test(event.target.value);
    CustomConsole(result);
    setIsEmailProperForVerify(result);
    setResetEmail(event.target.value);
  };

  const handleChangeVerificationCode = (event) => {
    setVerificationCode(event.target.value);
  };
  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const handleClickNewPassword = () => {
    if (newPassword !== "") {
      dispatch(ForgotPasswordNewPassword(newPassword, verificationCode));
    } else {
      toast.error("Please enter your password", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleChangeEmail = (event) => {
    CustomConsole(event);
    if (alignment === "admin") {
      let reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,7}$/;
      var result = reg.test(event.target.value);
      CustomConsole(result);
      setIsEmailProper(result);
      setEmail(event.target.value);
    } else {
      setIsEmailProper(true);
    }
    // let reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,7}$/;
    // var result = reg.test(event.target.value);
    // CustomConsole(result);
    // setIsEmailProper(result);
    // setEmail(event.target.value);
    if (alignment === "distributor") setPhoneOrEmail(event.target.value);
  };
  const handleChangePassword = (event) => {
    CustomConsole(event.target.value);
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };

  React.useEffect(() => {
    CustomConsole(auth);
    if (auth.verifyError === false) {
      setVerificationCodeSent(true);
    } else {
      setVerificationCodeSent(false);
    }
    if (auth.changePasswordError === false) {
      setForgotPassword(false);
    }
    if (
      alignment !== "promoter" &&
      auth.error === false &&
      forgotPassword === false
    ) {
      setIsLogin(true);
      localStorage.setItem("roleId", JSON.stringify(auth.role_id));
      auth.error = true;
    }
    if (!auth.error && auth.promoterChecksum.length) {
      // setTimerCountDown(30);
      setIsOtpSent(true);
      CustomConsole("-- Sucessfully got the Data ---");
    }
    if (!auth.error && auth.promoterDetailsList) {
      setPromoterEmail(() => auth.promoterDetailsList);
    }
  }, [auth]);

  if (localStorage.getItem("token") || isLogin) {
    CustomConsole(localStorage.getItem("roleId"));
    if (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_DISTRIBUTER
    ) {
      CustomConsole("Getting here");
      return <Redirect to="/dashboard/deliveries" />;
    }
    if (
      parseInt(localStorage.getItem("roleId")) ===
      ADMIN_ROLES.BW_SUPPLIERMANAGER
    ) {
      return <Redirect to="/dashboard/trip_planning#punch_in" />;
    }
    if (
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_DELIVERYAGENT ||
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_SUPPLIER_LOADERS
    ) {
      CustomConsole("Getting here");
      return <Redirect to="/dashboard/driverprofile" />;
    }
    if (parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_WFSADMIN) {
      CustomConsole("Getting here");
      return <Redirect to="/dashboard/wfsHome" />;
    } else if (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_PROMOTER
    ) {
      CustomConsole("Getting here1");
      return <Redirect to="/dashboard/marketing" />;
    } else {
      CustomConsole("Getting here1");
      return <Redirect to="/dashboard/home" />;
    }
  }

  const handleSendEmail = () => {
    if (isEmailProperForVerify === true) {
      dispatch(ResetPasswordEmailVerify(resetEmail));
    } else {
      toast.error("Please enter your email address", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleCloseDialog = () => {
    setForgotPassword(false);
    setVerificationCodeSent(false);
    setResetEmail("");
    setIsEmailProperForVerify(false);
    setVerificationCode("");
    setNewPassword("");
  };

  const handleChangeAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleOnRoleChange = (event) => {
    CustomConsole("-- handleOnRoleChange ---");
    CustomConsole(event.target.value);
    setAlignment(event.target.value);
  };

  const handlePromoterEmailVerify = (event) => {
    CustomConsole(event.target.innerText);
    if (selectedPromoterEmail.length) {
      dispatch(PromoterEmailVerification({ email: selectedPromoterEmail }));
    } else {
      CustomConsole("-- Invalid Email --");
    }
  };
  const handlePromoterLogIn = (event) => {
    CustomConsole(event.target.innerText);
    const OTP = emailOtp + "";
    if (
      selectedPromoterEmail.length &&
      auth.promoterChecksum.length &&
      OTP.length === 6
    ) {
      dispatch(
        PromoterLogin({
          email: selectedPromoterEmail,
          checksum: auth.promoterChecksum,
          verify_otp: emailOtp,
        })
      );
    } else {
      CustomConsole("-- Invalid Props --");
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl fullWidth>
              <InputLabel sx={{ fontSize: "18px" }}>
                &nbsp;Role*&nbsp;&nbsp;&nbsp;
              </InputLabel>
              <Select
                defaultValue="admin"
                value={alignment}
                label="Role"
                onChange={handleOnRoleChange}
              >
                <MenuItem value={"admin"}>BookWater-Admin</MenuItem>
                <MenuItem value={"distributor"}>Distributor</MenuItem>
                <MenuItem value={"promoter"}>Promoter</MenuItem>
              </Select>
            </FormControl>
            {alignment === "promoter" ? (
              // promoterEmail<FormControl fullWidth>
              <Box
                sx={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: "250px",
                  gap: "10px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel sx={{ fontSize: "18px" }}>
                    &nbsp;Email * &nbsp;&nbsp;&nbsp;
                  </InputLabel>
                  <Select
                    value={selectedPromoterEmail}
                    label="Email"
                    onChange={(event) => {
                      setSelectedPromoterEmail(event.target.value);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {promoterEmail.map((emails, index) => {
                      return (
                        <MenuItem key={index} value={emails.email}>
                          {emails.email}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {selectedPromoterEmail.length ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* {isOtpSent && timerCountDown ? (
                      // <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      //   Resend OTP in :
                      //   <span style={{ color: "blue" }}>{timerCountDown}</span>
                      // </Typography>
                    ) : ( */}
                    <Button
                      onClick={handlePromoterEmailVerify}
                      variant="contained"
                    >
                      {isOtpSent ? "Resend OTP" : "Send OTP"}
                    </Button>
                    {/* )} */}
                  </Box>
                ) : (
                  ""
                )}
                {selectedPromoterEmail.length && isOtpSent ? (
                  <TextField
                    InputProps={{
                      startAdornment: <MailLock />,
                    }}
                    type="number"
                    value={emailOtp}
                    onChange={(event) => setEmailOTP(event.target.value)}
                    error={(emailOtp + "").length !== 6}
                    helperText={
                      (emailOtp + "").length !== 6 && "Please enter 6-digit OTP"
                    }
                  />
                ) : (
                  ""
                )}
                {isOtpSent && (emailOtp + "").length === 6 ? (
                  <Button onClick={handlePromoterLogIn} variant="contained">
                    Login
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              <React.Fragment>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={
                    alignment === "distributor"
                      ? "Email/Phone"
                      : "Email Address"
                  }
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={alignment === "distributor" ? phoneOrEmail : email}
                  onChange={handleChangeEmail}
                  error={isEmailProper === false ? true : false}
                  helperText={
                    isEmailProper === false &&
                    "Please enter a valid email address"
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={values.showPassword ? "text" : "password"}
                  id="password"
                  onChange={handleChangePassword}
                  value={password}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {values.showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      checked={rememberMe}
                      onChange={(event) => setRememberMe(event.target.checked)}
                    />
                  }
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>
                <Box container>
                  <Box item xs>
                    <Button onClick={() => setForgotPassword(!forgotPassword)}>
                      Forgot password?
                    </Button>
                  </Box>
                  {forgotPassword && (
                    <Dialog open={forgotPassword}>
                      <Paper
                        elevation="24"
                        sx={{ borderRadius: "10px" }}
                        className="drawer"
                      >
                        <Box sx={{ padding: "5px", cursor: "pointer" }}>
                          <CloseRoundedIcon onClick={handleCloseDialog} />
                        </Box>
                        <Box sx={{ padding: "20px" }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            Trouble in signing in?
                          </Typography>

                          <Typography
                            sx={{
                              textAlign: "left",
                              padding: "10px",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                            variant="body2"
                            gutterBottom
                          >
                            Don't worry, we've got your back! Just enter your
                            email address and we'll send you a verification code
                            with which you can reset your password.
                          </Typography>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleResetVerifyEmail}
                            error={
                              isEmailProperForVerify === false ? true : false
                            }
                            helperText={
                              isEmailProperForVerify === false &&
                              "Please enter a valid email address"
                            }
                          />
                          <Button
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            fullWidth
                            onClick={handleSendEmail}
                          >
                            Send Verification code
                          </Button>
                          {verificationCodeSent && (
                            <>
                              <Box>
                                <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  label="Verification code"
                                  autoFocus
                                  onChange={handleChangeVerificationCode}
                                  // error={
                                  //   isEmailProperForVerify === false ? true : false
                                  // }
                                  // helperText={
                                  //   isEmailProperForVerify === false &&
                                  //   "Please enter a valid email address"
                                  // }
                                />
                                <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  name="password"
                                  label="New Password"
                                  type={
                                    values.showPassword ? "text" : "password"
                                  }
                                  id="password"
                                  autoComplete="current-password"
                                  onChange={handleChangeNewPassword}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                          onClick={handleClickShowPassword}
                                        >
                                          {values.showPassword ? (
                                            <VisibilityIcon />
                                          ) : (
                                            <VisibilityOffIcon />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <Button
                                  variant="contained"
                                  sx={{ mt: 3, mb: 2 }}
                                  fullWidth
                                  onClick={handleClickNewPassword}
                                >
                                  Change password
                                </Button>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Paper>
                    </Dialog>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
