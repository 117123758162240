/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 25-01-2024
 */
import React from "react";
import * as Material from "@mui/material";

import * as MuiIcons from "@mui/icons-material";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import Paper from "@mui/material/Paper";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  GetAllOrdersWfsInAdmin,
  GetCompletedOrdersWfsInAdmin,
  GetOngoingOrdersWfsInAdmin,
  GetUserDetails,
  PutWfsEditBatchDetails,
} from "../Redux/Actions/adminWfsCanOrdersAction";

import ConformationDialog from "../../Components/Dialog/conformationDialog";
import WfsEmptyOrderFilter from "../../Components/Dialog/wfsOrderFilterDialog";
import WfsEmptyCanOrderTabel from "./WfsEmptyCanOrderTabel";
import WfsOngoingOrders from "./WfsOngoingOrders";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  GetWfsEmptyCanDetailsSku,
  WfsEmptyCanOrdersSku,
} from "../Redux/Actions/skuWfsDetailsAction";
import moment from "moment";

const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
function WfsEmptyCanOrderList({ userId, phoneNumberFromOrders }) {
  const dispatch = useDispatch();
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const [isTableView, setIsTableView] = React.useState(true);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [selectedOrderType, setSelectedOrderType] = React.useState({
    sort_order: "DESC",
    page_size: 25,
    page_number: 0,
    wfs_id: "",
    user_phone: phoneNumberFromOrders,
    ec_order_id: "",
    address_id: "",
    from_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    is_updated_date: true,
    order_status: "",
  });
  const [pageNumber, setPageNumber] = React.useState(1);
  const [adminRoles, setAdminRoles] = React.useState("");
  const [selectedOrderData, setSelectedOrderData] = React.useState([]);
  const [startsubmissionmsg, setStartsubmissionmsg] = React.useState("");
  const [pgVisible, setPgVisible] = React.useState(false);
  const adminWfsCanOrdersData = useSelector(
    (state) => state.adminWfsCanOrdersData
  );
  //function for closing conformation dialog
  const handelProceed = () => {
    setConfirmDialog(false);
  };

  //function for closing filter dialog
  const handelCloseFilter = () => {
    setOpenFilter(false);
  };

  //function for handling filter submit
  const handelFilterSubmit = () => {
    dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  };

  //useEffect for setting ongoing order details
  React.useEffect(() => {
    dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  }, [selectedOrderType.page_number]);

  // Setting roles on start submission message and adminWfsCanOrdersData changes
  React.useEffect(() => {
    setStartsubmissionmsg(adminWfsCanOrdersData.startSubmissionOngoing.msg);
    const rolesAdmin = parseInt(window.localStorage.getItem("roleId"), 10);
    if (rolesAdmin) {
      setAdminRoles(rolesAdmin);
    }
    return () => {
      adminWfsCanOrdersData.userDetails = [];
    };
  }, [adminWfsCanOrdersData]);

  //function for handling view change
  const handelViewChange = (e) => {
    setIsTableView(!isTableView);
  };
  CustomConsole(skuWfsDetails);

  //function for handling page size
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setSelectedOrderType((prev) => ({
      ...prev,
      page_size: event.target.value,
    }));
  };
  //function for handling page size api call
  const handleSearchPageSize = () => {
    dispatch(GetWfsEmptyCanDetailsSku(selectedOrderType));
  };

  CustomConsole(selectedOrderType);
  return (
    <>
      <Material.Box
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: 5,
          marginTop: "1rem",
          padding: ".5rem",
        }}
      >
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {" "}
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={isTableView} onChange={handelViewChange} />
              }
              label={isTableView ? "Table View" : "Other View"}
            />
          </FormGroup>{" "}
          <Material.Button
            onClick={() => setOpenFilter(true)}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Material.Button>
          <Material.TextField
            size="small"
            value={selectedOrderType.page_size}
            sx={{ width: "100px" }}
            label="Page size"
            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          />
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.IconButton
              onClick={() => {
                if (selectedOrderType.page_number > 0) {
                  setSelectedOrderType((prev) => ({
                    ...prev,
                    page_number: prev.page_number - 1,
                  }));
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{
                  color: selectedOrderType.page_number > 0 ? "blue" : "gray",
                }}
              />
            </Material.IconButton>
            <Material.Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {selectedOrderType.page_number + 1}
            </Material.Typography>
            <Material.IconButton
              sx={{ maxHeight: "50px" }}
              onClick={() => {
                if (
                  selectedOrderType.page_size ==
                  skuWfsDetails?.emptyCanDetails?.length
                ) {
                  setSelectedOrderType((prev) => ({
                    ...prev,
                    page_number: prev.page_number + 1,
                  }));
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color: selectedOrderType?.length === 25 ? "blue" : "gray",
                }}
              />
            </Material.IconButton>
          </Material.Box>
        </Material.Box>
        <>
          {isTableView && (
            <WfsEmptyCanOrderTabel
              selectedOrderData={skuWfsDetails.emptyCanDetails}
              userId={userId}
              selectedOrderType={selectedOrderType}
            />
          )}
          {!isTableView && (
            <>
              {skuWfsDetails.emptyCanDetails?.length > 0 ? (
                <WfsOngoingOrders
                  data={skuWfsDetails.emptyCanDetails}
                  startsubmissionmsg={startsubmissionmsg}
                  setPgVisible={setPgVisible}
                  userId={userId}
                  selectedOrderType={selectedOrderType}
                />
              ) : null}
            </>
          )}
        </>
        <WfsEmptyOrderFilter
          open={openFilter}
          onClose={handelCloseFilter}
          onSubmit={handelFilterSubmit}
          selectedOrderType={selectedOrderType}
          setSelectedOrderType={setSelectedOrderType}
        />
        <ConformationDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          dialogMsg="Are You Sure to delete locality from pincode"
          handelProceed={handelProceed}
        />
      </Material.Box>
    </>
  );
}

export default WfsEmptyCanOrderList;
