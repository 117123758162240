import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { styles } from "../wfsCanOrders/cardStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";
import {
  GetSupplierWfsOngoingOrders,
  PutSupplierAddDelivertSlots,
  PutSupplierOngoingStartSubmission,
} from "../Redux/Actions/supliersAction";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ordersStatusWFSCan } from "../../Components/AppMeta/appMetaConfig";
import moment from "moment";
import SupplierStartSubmissionDialog from "../../Components/DialoBoxes/SupplierStartSubmissionDialog";
import EditEmptyCanOrdersDialog from "../wfsCanOrders/EditEmptyCanOrdersDialog";
import { PutEditEmptyCanOrder } from "../Redux/Actions/wfsOrdersAction";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import EmptyCanOrderEdit from "../../Components/DialogBoxes/emptyCanEditOrder";
import SupplierWfsSingleOrderDetailsDialog from "./SupplierWfsSingleOrderDetailsDialog";

function WfsOrderFormView(props) {
  CustomConsole(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const [SelectDeliverySlot, setSelectDeliverySlot] = React.useState("");
  const [singleSelectedData, setSingleSelectedData] = React.useState({});
  const [addDeliveryBtn, setAddDeliveryBtn] = React.useState(false);
  const [valueOrderId, setValueOrderId] = React.useState("");
  const [selecteId, setSelecteId] = React.useState({});
  const [isFbEnabled, setIsFbEnabled] = React.useState(false);
  const [selectCheckBox, setSelectCheckBox] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openSupplierWFSOrdersDialog, setOpenSupplierWFSOrdersDialog] =
    React.useState(false);
  const [openEditEmptyCanOrderDialog, setOpenEditEmptyCanOrderDialog] =
    React.useState(false);
  const [openStartSubmissionDialog, setOpenStartSubmissionDialog] =
    React.useState(false);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  React.useEffect(() => {}, [props]);

  React.useEffect(() => {}, [singleSelectedData]);

  const orderStatuswfsCansorder = (ec_order_status) => {
    switch (ec_order_status) {
      case ordersStatusWFSCan.EC_ORDER_CONFIRMED:
        return "CONFIRMED";
      case ordersStatusWFSCan.EC_ORDER_COMPLETED:
        return "COMPLETED";
      case ordersStatusWFSCan.EC_ORDER_CANCELLED:
        return "CANCELLED";
      case ordersStatusWFSCan.EC_ORDER_PAYMENT_PENDING:
        return "PAYMENT PENDING";
      case ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED:
        return "ADMIN ACCEPTED";
      case ordersStatusWFSCan.EC_ORDER_ADMIN_REJECTED:
        return "ADMIN REJECTED";
      default:
        return "";
    }
  };

  const handleChangeOrderId = (event) => {
    const selectedOrdersData = props.data.find(
      (data) => data.ec_order_id === event.target.value
    );
    CustomConsole(selectedOrdersData);
    setSingleSelectedData(selectedOrdersData);
    setValueOrderId(event.target.value);
  };
  //   const handleViewDetails = () => {
  //     if (singleSelectedData.order_id) {
  //       const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${singleSelectedData.order_id}`;
  //       history.push(url);
  //     } else {
  //       toast.error("Please Select Order Id", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   };
  React.useEffect(() => {
    if (props.data && valueOrderId) {
      const output = props.data.find((obj) => {
        return obj.ec_order_id === valueOrderId;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      setSelecteId(output);
    }
  }, [props.data, valueOrderId]);

  const handleOkDialog = (props) => {
    CustomConsole("-----handleOkDialog-----------");
    CustomConsole(props);
    setOpenStartSubmissionDialog(false);
    // setStartBtn(true);
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(props.selectedOrderType));
    }, 500);
  };
  const handleStart = () => {
    CustomConsole("----------Start Submission-----------");
    setOpenStartSubmissionDialog(true);
    // setOrderId(props.OrderId);
    dispatch(
      PutSupplierOngoingStartSubmission({
        order_id: singleSelectedData.ec_order_id,
      })
    );
  };
  const handleEditEmptyCan = () => {
    setOpenEditEmptyCanOrderDialog(true);
  };
  const handleClose = () => {
    setOpenEditEmptyCanOrderDialog(false);
  };
  const handleSubmitCans = (props) => {
    CustomConsole("--------handleSubmitCans-------------");
    const reqObj = {
      order_id: props.ec_order_id,
      order_qty: props.orderQty + props.emptyCanQty,
      empty_qty: props.emptyCanQty,
    };
    CustomConsole(reqObj);
    dispatch(PutEditEmptyCanOrder(reqObj));
    setOpenEditEmptyCanOrderDialog(false);
    setTimeout(() => {
      dispatch(GetWfsEmptyCanDetailsSku(props.selectedOrderType));
    }, 500);
  };
  const handleOpenWfsSingleOrderDetails = () => {
    CustomConsole("--------handleOpenWfsSingleOrderDetails-------------");
    setOpenSupplierWFSOrdersDialog(true);
  };

  const handleCloseWFSOrderDialog = () => {
    setOpenSupplierWFSOrdersDialog(false);
  };

  return (
    <Material.Paper elevation={14} sx={{ padding: "20px", bgcolor: "#EBF5FB" }}>
      <Material.Box>
        <Material.Box
          sx={{
            // padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "row",
              sm: "row",
              md: "row",
              lg: "row",
            },
            gap: "10px",
          }}
        >
          <Material.Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                alignItems: "center",
              }}
            >
              <Material.InputLabel
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Order ID:
              </Material.InputLabel>
              <Material.Select
                variant="standard"
                sx={{ fontSize: "16px", fontWeight: "bold", width: "190px" }}
                value={valueOrderId}
                label="Order Id"
                onChange={handleChangeOrderId}
              >
                {props.data &&
                  props.data !== undefined &&
                  props.data !== null &&
                  props.data.length > 0 &&
                  props.data.map((orderIds, index) => {
                    return (
                      <Material.MenuItem
                        key={index}
                        value={orderIds.ec_order_id}
                      >
                        {orderIds.ec_order_id}
                      </Material.MenuItem>
                    );
                  })}
              </Material.Select>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            textAlign: "center",
          }}
        >
          <Material.Box>
            {/* <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Consumer Name </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.delivery_address?.contact_name}
                </Material.Typography>
              </Material.Box>
            </Material.Box> */}
      
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Id </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography
                  style={{
                    textDecorationLine: singleSelectedData.ec_order_id
                      ? "underline"
                      : "none",
                    color: "red",
                  }}
                  onClick={handleOpenWfsSingleOrderDetails}
                >
                  {singleSelectedData.ec_order_id}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>New Cans </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.order_details?.order_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Empty Cans </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.order_details?.return_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Empty Cans </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.empty_containers_submitting}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Assigned</Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.assigned_wfs_details?.wfs_incharge_name}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Container Material </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.order_details?.container_material}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>OTP </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.otp}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Damaged Cans</Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.order_details?.damaged_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Created At</Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.created_at}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Container Capacity </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.order_details?.container_capacity}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Expired Cans </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.order_details?.expired_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Status</Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {orderStatuswfsCansorder(singleSelectedData?.ec_order_status)}
                </Material.Typography>
              </Material.Box>
            </Material.Box>

            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Address</Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.assigned_wfs_details?.wfs_address !==
                    undefined &&
                    singleSelectedData?.assigned_wfs_details?.wfs_address
                      .Street}
                  ,
                  {singleSelectedData?.assigned_wfs_details?.wfs_address !==
                    undefined &&
                    singleSelectedData?.assigned_wfs_details?.wfs_address
                      ?.Village}
                  ,
                  {singleSelectedData?.assigned_wfs_details?.wfs_address !==
                    undefined &&
                    singleSelectedData?.assigned_wfs_details?.wfs_address
                      ?.District}
                  <br />
                  {singleSelectedData?.assigned_wfs_details?.wfs_address !==
                    undefined &&
                    singleSelectedData?.assigned_wfs_details?.wfs_address
                      ?.State}
                  ,
                  {singleSelectedData?.assigned_wfs_details?.wfs_address !==
                    undefined &&
                    singleSelectedData?.assigned_wfs_details?.wfs_address
                      ?.Pincode}
                  <br />
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        {/* <Material.Box>
          <Material.Button variant="contained" onClick={handleViewDetails}>
            view Details
          </Material.Button>
        </Material.Box> */}
        <Material.Box
          sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
        >
          <Material.Box>
            {selecteId?.ec_order_status ===
            ordersStatusWFSCan.EC_ORDER_ADMIN_ACCEPTED ? (
              <Material.Box sx={{ marginTop: "10px" }}>
                {selecteId?.order_details?.is_submit_finished === false &&
                selecteId?.order_details?.is_submit_started === false ? (
                  <>
                    <Material.Button
                      onClick={handleStart}
                      variant="contained"
                      sx={{
                        borderTopRightRadius: "20px",
                        borderBottomLeftRadius: "20px",
                      }}
                    >
                      start Submission
                    </Material.Button>
                  </>
                ) : (
                  <>
                    {/* {props.data ? ( */}
                    <Material.Box>
                      <Material.Typography
                        sx={{ fontWeight: "bold", color: "red", mt: "18px" }}
                      >
                        ORDER STARTED
                      </Material.Typography>
                    </Material.Box>
                    {/* ) : (
                    ""
                  )} */}
                  </>
                )}
              </Material.Box>
            ) : (
              ""
            )}
          </Material.Box>
          <Material.Box sx={{ mt: 1.5 }}>
            <Material.Button
              variant="contained"
              size="small"
              onClick={handleEditEmptyCan}
            >
              <MuiIcons.BorderColor /> Edit
            </Material.Button>
          </Material.Box>
        </Material.Box>
      </Material.Box>
      <SupplierStartSubmissionDialog
        openStartSubmissionDialog={openStartSubmissionDialog}
        handleClose={handleOkDialog}
        selectedOrderType={props.selectedOrderType}
        // storeStartSubmissionData={storeStartSubmissionData}
      />
      {openEditEmptyCanOrderDialog && (
        <EmptyCanOrderEdit
          open={openEditEmptyCanOrderDialog}
          onClose={handleClose}
          editDetails={singleSelectedData}
        />
      )}
      <SupplierWfsSingleOrderDetailsDialog
        openSupplierWFSOrdersDialog={openSupplierWFSOrdersDialog}
        singleIdData={selecteId}
        handleCloseWFSOrderDialog={handleCloseWFSOrderDialog}
        ordersStatusWFSCan={ordersStatusWFSCan}
        handleStart={handleStart}
        handleEditEmptyCanDetails={handleEditEmptyCan}
      />
    </Material.Paper>
  );
}

export default WfsOrderFormView;
