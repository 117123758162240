import React from "react";
import * as Material from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomConsole from "../../CustomConsole";

function CrateQrBarcodeDetailsTable() {
  const qrBarCodeData = useSelector((state) => state.skuQrBarcodeDetails);

  //function for changing date formate
  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    return momentEndDate;
  };

  const getQuantity = (data) => {
    // Extract numeric part of strings and convert them to numbers
    const quantityA = parseInt(data.start_number.replace(/[^\d]/g, ""), 10);
    const quantityB = parseInt(data.end_number.replace(/[^\d]/g, ""), 10);

    // Subtract the values
    const result = quantityB - quantityA +1;

    return result;
  };
  return (
    <Material.Box>
      <Material.Paper sx={{ width: { xs: "100%" }, overflow: "hidden" }}>
        <Material.TableContainer sx={{ maxHeight: 440 }}>
          <Material.Table size="small" stickyHeader>
            <Material.TableHead>
              <Material.TableRow>
                <Material.TableCell>Start Number</Material.TableCell>
                <Material.TableCell>End Number</Material.TableCell>
                <Material.TableCell>Quantity</Material.TableCell>
                <Material.TableCell>CompanyName</Material.TableCell>
                <Material.TableCell align="center">
                  OwnerName
                </Material.TableCell>
                <Material.TableCell align="center">
                  OwnerPhone
                </Material.TableCell>
                <Material.TableCell align="center">
                  GSTNumber
                </Material.TableCell>
                <Material.TableCell>PANNumber</Material.TableCell>
                {/* <Material.TableCell sx={{ color: "#2C3E50" }}>
                  Requested by
                </Material.TableCell> */}
                <Material.TableCell sx={{ color: "#2C3E50" }} align="center">
                  Address
                </Material.TableCell>
                <Material.TableCell sx={{ color: "#2C3E50" }}>
                  Landmark
                </Material.TableCell>
                <Material.TableCell sx={{ color: "#2C3E50" }}>
                  Area
                </Material.TableCell>
                <Material.TableCell sx={{ color: "#2C3E50" }}>
                  State
                </Material.TableCell>
                <Material.TableCell sx={{ color: "#2C3E50" }}>
                  County
                </Material.TableCell>
                <Material.TableCell sx={{ color: "#2C3E50" }}>
                  Pincode
                </Material.TableCell>
                <Material.TableCell sx={{ color: "#2C3E50" }}>
                  CreatedAt&nbsp;&nbsp;&nbsp;&nbsp;
                </Material.TableCell>

                {/* <Material.TableCell sx={{ color: "#2C3E50" }}>
                  Crate Description
                </Material.TableCell> */}
              </Material.TableRow>
            </Material.TableHead>
            <Material.TableBody>
              {qrBarCodeData.careQrListData.map((data) => (
                <>
                  <Material.TableRow
                    // key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    //   onClick={handleOpenSingleId}
                  >
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {data.start_number}
                    </Material.TableCell>
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {data.end_number}
                    </Material.TableCell>
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {getQuantity(data)}
                    </Material.TableCell>
                    <Material.TableCell component="th" scope="row">
                      {data.mft_details.company_name !== undefined
                        ? data.mft_details.company_name
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell component="th" scope="row">
                      {data.mft_details.owner_name !== undefined
                        ? data.mft_details.owner_name
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {data.mft_details.owner_phone !== undefined
                        ? data.mft_details.owner_phone
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {data.mft_details.other_details?.GST !== undefined
                        ? data.mft_details.other_details.GST
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {data.mft_details.other_details?.PAN !== undefined
                        ? data.mft_details.other_details.PAN
                        : "-"}
                    </Material.TableCell>
                    {/* <Material.TableCell component="th" scope="row" align="right">
                    {"Avinadhdevdistest"}
                  </Material.TableCell> */}
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {data.mft_details.address?.line1 !== undefined
                        ? data.mft_details.address.line1
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {data.mft_details.address?.landmark !== undefined
                        ? data.mft_details.address.landmark
                        : "-"}
                    </Material.TableCell>

                    {/* <Material.TableCell align="right">{"500"}</Material.TableCell> */}
                    <Material.TableCell align="right">
                      {data.mft_details.address?.area !== undefined
                        ? data.mft_details.address.area
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell align="right">
                      {data.mft_details.address?.state !== undefined
                        ? data.mft_details.address.state
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell align="right">
                      {data.mft_details.address?.country !== undefined
                        ? data.mft_details.address.country
                        : "-"}
                    </Material.TableCell>
                    <Material.TableCell align="right">
                      {data.mft_details.address?.pincode !== undefined
                        ? data.mft_details.address.pincode
                        : "-"}
                    </Material.TableCell>

                    <Material.TableCell align="right">
                      {endDateConverter(data.created_at)}
                    </Material.TableCell>
                  </Material.TableRow>
                </>
              ))}
            </Material.TableBody>
          </Material.Table>
        </Material.TableContainer>
      </Material.Paper>
    </Material.Box>
  );
}

export default CrateQrBarcodeDetailsTable;
