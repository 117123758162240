import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Slide from "@mui/material/Slide";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  ReduceUserStock,
  ReturnCansOrder,
  UpdateUserStock,
  GetUserStockDetails,
  UpdateCrateUserStock,
  DepositUserStock,
  GetWalletDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GetListofCrates } from "../Redux/Actions/SKUProductDetailsAction";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddReduceReturnDialog({
  addReduceReturn,
  setAddReduceReturn,
  userId,
  stockUpdates,
  address,
  walletBalance,
  singleSelectedStockData,
  roleId,
}) {
  CustomConsole(roleId);
  const dispatch = useDispatch();
  const userUpdates = useSelector((state) => state.userDetailsUpdate);

  const [productName, setProductName] = React.useState("");
  const [depositStockvalue, setDepositStockvalue] =
    React.useState("UnDeposited");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [submitFlag, setSubmitFlag] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [addressId, setAddressId] = React.useState("");
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [submitBtn, setSubmitBtn] = React.useState(false);
  const handleClose = () => {
    setAddReduceReturn(false);
  };
  //function for adding crate stock details
  const crateDetails = skuContainerDetails.cateDetails;
  const [selectedOption, setSelectedOption] = React.useState();
  const [alignment, setAlignment] = React.useState("Product");
  const [crateStock, setCrateStock] = React.useState({
    user_id: "",
    material: "",
    capacity: "",
    quantity: "",
  });
  let reqObjectCrateDetails = {
    activeStatus: true,
    capacity: "ASC",
    sort_by_name: "ASC",
    sort_by_price: "ASC",
    sort_by_created_at: "ASC",
  };

  //useEffect for getting crate details
  React.useEffect(() => {
    dispatch(GetListofCrates(reqObjectCrateDetails));
  }, []);
  React.useEffect(() => {}, [quantity, capacity, material]);
  //function for changing togglr button
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  //function for handel submit function
  const handleSubmit = () => {
    if (alignment === "Product") {
      setSubmitFlag(true);
      setSubmitBtn(true);
    } else {
      if (stockUpdates === "Add Stock") {
        dispatch(UpdateCrateUserStock(crateStock));
        setAddReduceReturn(false);
        setTimeout(() => {
          dispatch(GetUserStockDetails(userId));
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    CustomConsole(userUpdates);
    if (
      userUpdates.addStockMsg !== "" &&
      stockUpdates === "Add Stock" &&
      submitBtn === true
    ) {
      toast.success(userUpdates.addStockMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSubmitBtn(false);
      setAddReduceReturn(false);
      dispatch(GetUserStockDetails(userId));
    }
    if (
      userUpdates.returnCansOrderMsg !== "" &&
      stockUpdates === "Return Stock" &&
      submitBtn === true
    ) {
      toast.success(userUpdates.returnCansOrderMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSubmitBtn(false);
      setAddReduceReturn(false);
    }
    if (
      userUpdates.reduceCansMsg !== "" &&
      stockUpdates === "Reduce Stock" &&
      submitBtn === true
    ) {
      toast.success(userUpdates.reduceCansMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSubmitBtn(false);
      setAddReduceReturn(false);
    }
  }, [userUpdates]);

  React.useEffect(() => {
    CustomConsole(stockUpdates);
    if (stockUpdates === "Add Stock" && submitFlag === true) {
      const data = {
        container_capacity: capacity,
        container_material: material,
        is_deposit_disabled: true,
        address_id: addressId,
        total_undeposited_containers_qty: parseInt(quantity),
        user_id: userId,
      };
      if (capacity !== "" && material !== "" && quantity > 0) {
        dispatch(UpdateUserStock(data));
        setSubmitFlag(false);
      } else {
        toast.error("Check the values you entered", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSubmitFlag(false);
      }
    }
    if (stockUpdates === "Return Stock" && submitFlag === true) {
      const data = {
        container_capacity: capacity,
        container_material: material,
        container_qty: parseInt(quantity),
        user_id: userId,
        address_id: addressId || undefined,
      };
      if (
        addressId !== "" &&
        capacity !== "" &&
        material !== "" &&
        quantity > 0
      ) {
        dispatch(ReturnCansOrder(data));
        setSubmitFlag(false);
      } else {
        toast.error("Check the values you entered", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSubmitFlag(false);
      }
    }
    if (stockUpdates === "Reduce Stock" && submitFlag === true) {
      const data = {
        container_capacity: capacity,
        container_material: material,
        container_qty: parseInt(quantity),
        user_id: userId,
      };
      if (capacity !== "" && material !== "" && quantity > 0) {
        dispatch(ReduceUserStock(data));
        setSubmitFlag(false);
      } else {
        toast.error("Check the values you entered", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSubmitFlag(false);
      }
    }
  }, [submitFlag]);

  const handleChangeQuality = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setQuantity(inputValue);
      setCrateStock((prev) => ({
        ...prev,
        quantity:
          inputValue >= 0 && inputValue <= 999
            ? parseInt(inputValue || 0)
            : parseInt(prev.quantity || 0),
      }));
    }
  };

  //useEffect for handling selected product details
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = skuContainerDetails.getAvailableProducts.filter(
      (data) =>
        `${data.product_details.capacity}-${data.product_details.material}` ===
        event.target.value
    );
    if (selectedDetails.length > 0) {
      setProductName(
        `${selectedDetails[0].product_details.capacity}-${selectedDetails[0].product_details.material}`
      );
      setCapacity(selectedDetails[0].product_details.capacity);
      setMaterial(selectedDetails[0].product_details.material);
    }
  };

  //function for handling crate details
  const handelSelectedCrate = (id) => {
    const crateSelected = crateDetails.find((option) => option.id === id);
    setSelectedOption(crateSelected.id);
    CustomConsole(crateSelected);
    setCrateStock((prev) => ({
      ...prev,
      user_id: userId,
      capacity: crateSelected.capacity,
      material: crateSelected.name,
      quantity: quantity,
      id: crateSelected.id,
    }));
  };

  CustomConsole(crateDetails);

  const handleChnageDepositStock = (event) => {
    setDepositStockvalue(event.target.value);
  };
  const handleSubmitDepositStock = () => {
    CustomConsole("---------handleSubmitDepositStock------------------");
    const reqObj = {
      user_id: userId,
      capacity: capacity || crateStock.capacity,
      material: material || crateStock.material,
      quantity: parseInt(quantity),
      address_id: addressId,
      is_crate: singleSelectedStockData.is_crate,
      payment_method: "WALLET",
    };
    CustomConsole(reqObj);
    if (
      (capacity && material && quantity) ||
      crateStock.material !== "" ||
      crateStock.capacity !== ""
    ) {
      if (walletBalance > 0) {
        dispatch(DepositUserStock(reqObj));
        setTimeout(() => {
          dispatch(GetUserStockDetails(userId));
          dispatch(GetWalletDetails(userId));
        });
        setAddReduceReturn(false);
      }
      setMaterial("");
      setCapacity("");
      setProductName("");
      setSelectedOption("");
    } else {
      toast.error("Please Fill the Field ", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div>
      <Dialog
        open={addReduceReturn}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"xs"}
      >
        {stockUpdates === "Add Stock" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {" "}
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="Product">Add Product Stock</ToggleButton>
              {roleId === ADMIN_ROLES.BW_CONSUMER ? (
                ""
              ) : (
                <ToggleButton value="Crate">Add Crate Product</ToggleButton>
              )}
            </ToggleButtonGroup>
          </Box>
        )}
        <DialogContent>
          {alignment === "Product" && (
            <Box>
              {stockUpdates === "Return Stock" ? (
                ""
              ) : (
                <FormControl>
                  <RadioGroup
                    row
                    value={depositStockvalue}
                    onChange={handleChnageDepositStock}
                  >
                    {roleId === ADMIN_ROLES.BW_DISTRIBUTER ? (
                      ""
                    ) : (
                      <FormControlLabel
                        value="Deposited"
                        control={<Radio />}
                        label="Deposit"
                      />
                    )}
                    <FormControlLabel
                      value="UnDeposited"
                      control={<Radio />}
                      label="UnDeposit"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {depositStockvalue === "Deposited" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                    >
                      <Typography>Payment Method:</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        WALLET
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <Typography>Wallet Balance:</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {walletBalance}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <FormControl sx={{ minWidth: "220px" }} size="small">
                      <InputLabel>Product Details</InputLabel>
                      <Select
                        value={productName}
                        label="Capacity"
                        onChange={handleChangeSelectedProduct}
                      >
                        {skuContainerDetails.getAvailableProducts.map(
                          (data) => (
                            <MenuItem
                              value={`${data.product_details.capacity}-${data.product_details.material}`}
                              key={data.product_details.id}
                            >
                              <div>
                                <span>
                                  {" "}
                                  <strong>{data.product_details.name}</strong>
                                </span>
                                <br />
                                <small>
                                  Capacity:
                                  {data.product_details.capacity}/
                                </small>
                                <small>
                                  Material:
                                  {data.product_details.material}
                                </small>
                              </div>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack mt={2}>
                    <FormControl sx={{ minWidth: "220px" }}>
                      <InputLabel
                        sx={{ fontSize: "0.80rem", marginTop: "2px" }}
                        id="demo-simple-select-label"
                      >
                        Address
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Address"
                        onChange={(e) => setAddressId(e.target.value)}
                        sx={{ fontSize: "0.69rem" }}
                      >
                        {address.map((obj, idx) => {
                          return (
                            <MenuItem
                              value={obj.address_id}
                              sx={{ fontSize: "0.69rem" }}
                              key={idx}
                            >
                              {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Box>
                    <TextField
                      sx={{ minWidth: "220px" }}
                      size="small"
                      type="number"
                      label="Quantity"
                      InputProps={{
                        inputProps: {
                          pattern: "\\d*",
                          inputMode: "numeric",
                        },
                      }}
                      variant="outlined"
                      value={quantity}
                      onChange={handleChangeQuality}
                    />
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "10px" }}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitDepositStock}
                      sx={{
                        background: appColors.commonBtnColor.background,
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <FormControl sx={{ minWidth: "220px" }} size="small">
                      <InputLabel id="demo-select-small">
                        Product Details
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={productName}
                        label="Capacity"
                        onChange={handleChangeSelectedProduct}
                      >
                        {skuContainerDetails.getAvailableProducts.map(
                          (data) => (
                            <MenuItem
                              value={`${data.product_details.capacity}-${data.product_details.material}`}
                              key={data.product_details.id}
                            >
                              <div>
                                <span>
                                  {" "}
                                  <strong>{data.product_details.name}</strong>
                                </span>
                                <br />
                                <small>
                                  Capacity:
                                  {data.product_details.capacity}/
                                </small>
                                <small>
                                  Material:
                                  {data.product_details.material}
                                </small>
                              </div>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <Stack mt={2}>
                    <FormControl sx={{ minWidth: "220px" }}>
                      <InputLabel
                        sx={{ fontSize: "0.80rem", marginTop: "2px" }}
                        id="demo-simple-select-label"
                      >
                        Address
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Address"
                        onChange={(e) => setAddressId(e.target.value)}
                        sx={{ fontSize: "0.69rem" }}
                      >
                        {address.map((obj, idx) => {
                          return (
                            <MenuItem
                              value={obj.address_id}
                              sx={{ fontSize: "0.69rem" }}
                              key={idx}
                            >
                              {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Box>
                    <TextField
                      id="standard-number"
                      label="Enter quantity"
                      type="number"
                      InputProps={{
                        inputProps: {
                          pattern: "\\d*",
                          inputMode: "numeric",
                        },
                      }}
                      variant="standard"
                      value={quantity}
                      onChange={handleChangeQuality}
                    />
                  </Box>

                  <Box sx={{ display: "flex", marginTop: "10px" }}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{
                        background: appColors.commonBtnColor.background,
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {alignment === "Crate" && (
            <Box>
              <FormControl>
                <RadioGroup
                  row
                  value={depositStockvalue}
                  onChange={handleChnageDepositStock}
                >
                  {roleId === ADMIN_ROLES.BW_DISTRIBUTER ? (
                    ""
                  ) : (
                    <FormControlLabel
                      value="Deposited"
                      control={<Radio />}
                      label="Deposit"
                    />
                  )}
                  <FormControlLabel
                    value="UnDeposited"
                    control={<Radio />}
                    label="UnDepoist"
                  />
                </RadioGroup>
              </FormControl>
              {depositStockvalue === "Deposited" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                    >
                      <Typography>Payment Method:</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        WALLET
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <Typography>Wallet Balance:</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {walletBalance}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <FormControl sx={{ minWidth: "220px" }} size="small">
                      <InputLabel id="demo-select-small">
                        Crate Details
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selectedOption}
                        label="Crate Details"
                        onChange={(e) => handelSelectedCrate(e.target.value)}
                      >
                        {crateDetails?.map((data) => (
                          <MenuItem value={data.id} key={data.id}>
                            <div>
                              {data.name}/Capacity:{data.capacity}
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <TextField
                      sx={{ minWidth: "220px" }}
                      size="small"
                      type="number"
                      label="Quantity"
                      InputProps={{
                        inputProps: {
                          pattern: "\\d*",
                          inputMode: "numeric",
                        },
                      }}
                      variant="outlined"
                      value={quantity}
                      onChange={handleChangeQuality}
                    />
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "10px" }}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitDepositStock}
                      sx={{
                        background: appColors.commonBtnColor.background,
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <FormControl sx={{ minWidth: "220px" }} size="small">
                      <InputLabel id="demo-select-small">
                        Crate Details
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selectedOption}
                        label="Crate Details"
                        onChange={(e) => handelSelectedCrate(e.target.value)}
                      >
                        {crateDetails?.map((data) => (
                          <MenuItem value={data.id} key={data.id}>
                            <div>
                              {data.name}/Capacity:{data.capacity}
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Stack mt={1}>
                    <FormControl sx={{ width: "220px" }}>
                      <InputLabel
                        sx={{ fontSize: "0.80rem", marginTop: "2px" }}
                        id="demo-simple-select-label"
                      >
                        Address
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Address"
                        onChange={(e) => setAddressId(e.target.value)}
                        sx={{ fontSize: "0.69rem" }}
                      >
                        {address.map((obj, idx) => {
                          return (
                            <MenuItem
                              value={obj.address_id}
                              sx={{ fontSize: "0.69rem" }}
                              key={idx}
                            >
                              {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Box>
                    <TextField
                      id="standard-number"
                      label="Enter quantity"
                      type="number"
                      InputProps={{
                        inputProps: {
                          pattern: "\\d*",
                          inputMode: "numeric",
                        },
                      }}
                      variant="standard"
                      value={quantity}
                      onChange={handleChangeQuality}
                    />
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "10px" }}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{
                        background: appColors.commonBtnColor.background,
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
