import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { EmployeesPunchInOut } from "../Redux/Actions/supplierVehiclesActions";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

export const PunchInModel = ({ open }) => {
  const [warehouses, setwarehouses] = useState();
  const [selectedWarehouseIndex, setSelectedWarehouseIndex] = useState(null);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const ProfileDetails = useSelector((state) => state.profile);

  const history = useHistory();
  const dispatch = useDispatch();

  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);

  useEffect(() => {
    setwarehouses(userDetailsUpdate.addresses);
    if (userDetailsUpdate.addresses.length === 1) {
      setSelectedWarehouseIndex(0);
    }
  }, [userDetailsUpdate.addresses]);
  useEffect(() => {
    dispatch(
      GetFilteredUserAddressAdmin({
        // city: warehouseFilterCity,
        // user_id: supplierId,
      })
    );
  }, []);

  const onPunchIn = async () => {
    if (
      selectedWarehouseIndex === "" ||
      selectedWarehouseIndex === undefined ||
      selectedWarehouseIndex === null
    ) {
      //warehouse is required
      return;
    }
    dispatch(
      EmployeesPunchInOut({
        type: "IN",
        warehouse_id: warehouses[selectedWarehouseIndex]?.address_id,
      })
    );
  };
  return (
    <Dialog fullScreen sx={{ width: "100%" }} open={open}>
      <DialogTitle>
        <Typography
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}
        >
          Welcome {ProfileDetails?.user?.fullname}
          {/* {parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_DISTRIBUTER
            ? supplierDeliveries?.getSupplerCompanyProfile?.fullname
            : ""} */}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontWeight: "semiBold" }}>Please Punch In to Continue</Box>
          <Autocomplete
            disabled={!warehouses?.length}
            id="country-select-demo"
            sx={{ width: 210 }}
            options={warehouses?.map((item, index) => {
              return {
                name: item.contact_name,
                pincode: item.pincode,
                id: index,
              };
            })}
            autoHighlight
            getOptionLabel={(nameSearch) =>
              `${nameSearch.name} ${nameSearch.pincode}`
            }
            onChange={(_, newValue) => {
              setSelectedWarehouseIndex(newValue ? newValue?.id : null); // Set the selected  as the value
            }}
            renderOption={(props, nameSearch) => (
              <Box
                component="li"
                // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {nameSearch.name} - {nameSearch.pincode}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={
                  warehouses?.length
                    ? "Search Warehouse"
                    : "No warehouses Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: null, // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button onClick={onPunchIn} variant="contained">
              <PersonPinCircleIcon />
              Punch In
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
