const styles = {
  buttonStyle: {
    backgroundImage: "linear-gradient(#021636,#0c4aad)",
    "&:hover": {
      backgroundImage: "linear-gradient(#6b8df2,#021636)",
    },
  },
  contenctAlignBoxHead: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    gap: "10px",
  },
  contenctAlignBox: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "5px",
    padding: "5px",
  },
  // mainHeading: {
  //   color: "blue",
  //   fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
  //   fontWeight: "bold",
  // },
  mainHeadData: {
    display: "flex",
    fontSize: { sm: "14px", md: "16px" },
    fontWeight: "bold",
    color: "brown",
  },
  heading: {
    marginRight: "5px",
    textAlign: "start",
    width:"50%",
    color: "blue",
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  mainHeading: {
    marginRight: "5px",
    textAlign: "start",
    color: "blue",
    fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
    fontWeight: "bold",
  },
  answerData: {
    display: "flex",
    width: "70%",
    textAlign: "start",
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: "bold",
  },
};
export default styles;
