import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SearchIcon from "@mui/icons-material/Search";
import FactCheckIcon from "@mui/icons-material/FactCheck";

import moment from "moment";
import LinearProgressWithLabel from "../../Components/ProgressBars/LinearProgressWithLabel";
import AssignTrip from "./TripPlanning/AssignTrip2";

const Trips = () => {
  const [openAssignTrip, setOpenAssignTrip] = useState(false);
  return (
    <Box>
      <Card sx={{ border: "2px solid blue" }}>
        <CardContent>
          <Typography sx={{ fontWeight: "bold" }}>Trip Details</Typography>

          {[
            ADMIN_ROLES.BW_SUPPLIERMANAGER,
            ADMIN_ROLES.BW_DISTRIBUTER,
            ADMIN_ROLES.BW_ADMIN,
            ADMIN_ROLES.BW_SUPERADMIN,
            ADMIN_ROLES.BW_OPSHEAD,
          ].includes(parseInt(localStorage.getItem("roleId"))) && (
            <Box>
              <Button
                variant="contained"
                onClick={() => setOpenAssignTrip(true)}
              >
                Add Trip
              </Button>
              <Button sx={{ marginLeft: "20px" }} variant="contained">
                Refresh
              </Button>
            </Box>
          )}
          <Paper elevation={12} sx={styles.paperCommon}>
            <Grid container spacing={2}>
              <Grid item md={4.5}>
                <Box sx={styles.filterDataBox}>
                  <TextField
                    label="Trips from"
                    variant="standard"
                    value={moment().format("YYYY-MM-DD")}
                    type="date"
                    // onChange={(e) => setFromDate(e.target.value)}
                  />
                  <TextField
                    label="Trips till"
                    variant="standard"
                    value={moment().format("YYYY-MM-DD")}
                    type="date"
                    // onChange={(e) => setToDate(e.target.value)}
                  />
                  <ManageSearchIcon
                    sx={styles.searchDateIcon}
                    // onClick={() => OrderDetailsAndSummaryApi()}
                  />
                </Box>
              </Grid>
              <Grid item md={3} sx={{ mt: 1.3 }}>
                <TextField
                  label="Search Trip by order ID"
                  variant="standard"
                  //   value={orderId}
                  type="number"
                  //   onChange={(e) => setOrderId(e.target.value)}
                  InputProps={{
                    startAdornment: "BTORD-",
                    endAdornment: (
                      <SearchIcon
                        sx={styles.searchOrderUserIcon}
                        // onClick={() => handleSearchOrderId()}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4} sx={{ mt: 1.3 }}>
                <TextField
                  //   error={isUserSearchValid}
                  label="Search By Drivers"
                  variant="standard"
                  type="text"
                  //   value={user}
                  onChange={(e) => {
                    // setUser(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon
                        sx={styles.searchOrderUserIcon}
                        // onClick={() => handleSeacrhUserOrders()}
                      />
                    ),
                  }}
                  //   helperText={isUserSearchValid ? "Invalid entry." : ""}
                />
              </Grid>
            </Grid>
          </Paper>
          <Box sx={{ mt: 3 }}>
            <TableContainer
              component={Paper}
              elevation={12}
              sx={styles.paperCommon}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Date
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Slot
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Vehicle
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Driver
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Route / Status
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      Today {moment().format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell align="center">12:00-15:00</TableCell>
                    <TableCell align="center">Eicher TN01AB003</TableCell>
                    <TableCell align="center">Vinay</TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body2">
                          Vadapalani - Guindy
                        </Typography>
                        <LinearProgressWithLabel value={80} />
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={() => setOpenAssignTrip(true)}>
                        Edit
                      </Button>
                      <Button>
                        View <FactCheckIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {moment().add(2, "day").format(" dddd DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell align="center">12:00-15:00</TableCell>
                    <TableCell align="center">Eicher - TN01AB002</TableCell>
                    <TableCell align="center">Vinay</TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          background: "rgba(245, 16, 16, 0.31)",
                          borderRadius: "5px",
                          p: 1,
                        }}
                      >
                        <Typography variant="body2">
                          Vadapalani - ValsaraVakkam
                        </Typography>
                        <Typography variant="body2">Yet To Start</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={() => setOpenAssignTrip(true)}>
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {moment().add(-2, "day").format(" dddd DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell align="center">12:00-15:00</TableCell>
                    <TableCell align="center">Eicher - TN01AB002</TableCell>
                    <TableCell align="center">Vinay</TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          background: "rgba(14, 239, 29, 0.31)",
                          borderRadius: "5px",
                          p: 1,
                        }}
                      >
                        <Typography variant="body2">
                          Vadapalani - ValsaraVakkam
                        </Typography>
                        <Typography variant="body2">Completed</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={() => setOpenAssignTrip(true)}>
                        Edit
                      </Button>
                      <Button>
                        View <FactCheckIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      <AssignTrip open={openAssignTrip} setOpen={setOpenAssignTrip} />
    </Box>
  );
};

export default Trips;

const styles = {
  // Searc Order -> Filter Options
  foMainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  foIpLabel: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  foSelect: { fontSize: "14px", fontWeight: "bold", width: "120px" },

  instaBgImage: "linear-gradient( #F6E48B, #E7F887)",
  regularBgImage: "linear-gradient( #DDFFC9, #FFEFC9)",
  postpaidBgImage: "linear-gradient( #D8FFF8, #F6E5FF)",
  subscriptionBgImage: "linear-gradient( #FFE5E9, #E6F4EB)",

  //Order Summary Design
  osPaper: {
    padding: "10px",
    margin: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  osBox: {
    padding: "10px",
  },
  osCard: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    minWidth: "150px",
  },
  osCardHeadText: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  osTextBox: { display: "flex", flexDirection: "row", gap: "10px" },
  osTextHead: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "gray",
  },
  osTextAnswer: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
  },
  filterDataBox: {
    width: "100%",
    display: "flex",
    flexDirection: {
      lg: "row",
      md: "row",
      sm: "column",
      xs: "column",
    },
    padding: "10px",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center",
  },
  searchOrderUserIcon: {
    color: "blue",
    fontSize: "25px",
    "&:hover": {
      color: "black",
    },
  },
  reportDownloadIcon: {
    color: "brown",
    fontSize: "35px",
    "&:hover": {
      color: "green",
    },
  },
  searchDateIcon: {
    color: "blue",
    fontSize: "35px",
    "&:hover": {
      color: "black",
    },
  },
  paperCommon: {
    padding: "10px",
    margin: "10px",
    width: "100%",
    overflow: "auto",
  },
};
export const Styles = styles;
