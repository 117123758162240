import React from "react";
import {
  Avatar,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import { GetAssignDeliveryAgent } from "../Redux/Actions/supliersAction";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";

function Summary(props) {
  CustomConsole(props);
  // const dispatch = useDispatch();
  // const [sgentData, setAgentData] = React.useState([]);
  // const [slotData, setSlotData] = React.useState([]);
  // CustomConsole(slotData);
  // CustomConsole(sgentData);
  // const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(typeof props.assignType);
  const slot = props.dataSlots;

  CustomConsole(slot);
  const agent = props.dataAgents;
  CustomConsole(agent);
  const handleChangeDeliveryAgent = (event) => {
    props.setDeliveryAgent(event.target.value);
  };
  // React.useEffect(() => {
    //   // dispatch(GetAssignDeliveryAgent());
    //   const slot = props.dataSlots;
    //   const agent = props.dataAgents;
    //   setSlotData(() => slot);
    //   setAgentData(() => agent);
  // }, [props.dataSlots, props.dataAgents]);

  return (
    <>
      <Paper elevation={10} sx={{ padding: "10px" }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          {props.assignType === 2 ? (
            <Grid container spacing={0.1}>
              {props?.dataSlots?.map((obj, index) => (
                <Grid item xs={4} sm={4} md={4} key={index}>
                  {CustomConsole(`${obj}`)}
                  <Chip
                    sx={{
                      fontSize: "10px",
                    }}
                    avatar={
                      <Avatar
                        sx={{
                          fontSize: "10px",
                          backgroundColor: "#97D1F5",
                        }}
                      >
                        {index + 1}
                      </Avatar>
                    }
                    color="info"
                    variant={props.selectedSlot === obj ? "filled" : "outlined"}
                    label={obj.startTimeSlot + "-" + obj.endTimeSlot}
                    onClick={() => props.setSelectedSlot(obj)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <FormControl variant="standard" sx={{ minWidth: 220 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Agent
              </InputLabel>
              <Select
                value={props.deliveryAgent}
                onChange={handleChangeDeliveryAgent}
                label="Agent"
                size="small"
              >
                {props?.dataAgents?.map((obj, key) => {
                  return (
                    <MenuItem key={key} value={obj.driver_id}>
                      {obj.driver_profile?.fullname}-{obj.driver_profile?.phone}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Stack>
      </Paper>
    </>
  );
}

export default Summary;
