import React from "react";
import * as Material from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SupplierPreviousDeliveriesCard from "../../Components/Cards/SupplierPreviousDeliveriesCard";
import {
  CurrentDayDeliveries,
  GetQrBarcodeDetails,
  GetSupplierPreviousOnGoingDeliveries,
  PutSupplierCurrentDayCompleteDeliveries,
  PutSupplierCurrentDayVerifyOtp,
} from "../Redux/Actions/supliersAction";
import CustomConsole from "../../CustomConsole";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { toast } from "react-toastify";
import CameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/CameraDialog";
import CurrentDayDeliveriesEnterOtpDialog from "../../Components/DialogBoxes/SupplierDialogBox/CurrentDayDeliveriesEnterOtpDialog";

function PreviousDeliveries() {
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  CustomConsole(supplierDeliveries);

  const [openCameraDialog, setOpenCameraDialog] = React.useState(false);
  const [submitOtpBtn, setSubmitOtpBtn] = React.useState(false);
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [deliveryData, setDeliveryData] = React.useState([]);
  const [orderDetails, setOrderDetails] = React.useState("");
  const [searchByOrderId, setSearchByOrderId] = React.useState("");

  const [openQrBarCodeCamera, setOpenQrBarCodeCamera] = React.useState(false);
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [openOutScanCamera, setOpenOutScanCamera] = React.useState(false);
  const [openRollBackDialog, setOpenRollBackDialog] = React.useState(false);
  const [outScanBtn, setOutScanBtn] = React.useState(false);
  const [inScanBtn, setInScanBtn] = React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [assignDeliveryAgentBtn, setAssignDeliveryAgentBtn] =
    React.useState(false);
  const [uploadDriverChallanFile, setUploadDriverChallanFile] =
    React.useState("");
  const [uploadDriverChallanBtn, setUploadDriverChallanBtn] =
    React.useState(false);
  const [driverChallanUrl, setDriverChallanUrl] = React.useState("");
  const [adminRole, setAdminRoleId] = React.useState("");
  CustomConsole(driverChallanUrl);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [ordersPincodeSwitch, setOrdersPincodeSwitch] = React.useState(false);
  const [isProperScan, setIsProperScan] = React.useState(true);

  // const [currentScannedCodes, setCurrentScannedCodes] = React.useState([]);
  const [scannedCurrent, setScannedCurrent] = React.useState([]);
  const [inScannedCodes, setInScannedCodes] = React.useState([]);
  const [cameraScannedCodes, setCameraScannedCodes] = React.useState([]);
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);

  const [outScannedCodes, setOutScannedCodes] = React.useState([]);

  const [inScannedNo, setInScannedNo] = React.useState("");
  const [outScannedNo, setOutScannedNo] = React.useState("");

  const [isInScan, setIsInScan] = React.useState(false);
  CustomConsole("Live scan");
  CustomConsole(localStorage.getItem("current_scanned_codes"));

  const [currentScanned, setCurrentScanned] = React.useState(() =>
    localStorage.getItem("current_scanned_codes") &&
    localStorage.getItem("current_scanned_codes").length
      ? JSON.parse(localStorage.getItem("current_scanned_codes"))
      : []
  );
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    CustomConsole("------RoleId: " + storeRoleId);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);
  React.useEffect(() => {
    CustomConsole("----Inside Roles based  API choise----");
    CustomConsole(adminRole);
    CustomConsole(ADMIN_ROLES.BW_DISTRIBUTER === adminRole);
    CustomConsole(ADMIN_ROLES.BW_DELIVERYAGENT === adminRole);
    if (
      ADMIN_ROLES.BW_DISTRIBUTER === adminRole ||
      ADMIN_ROLES.BW_SUPPLIERMANAGER === adminRole
    ) {
      dispatch(GetSupplierPreviousOnGoingDeliveries());
    }
    if (
      ADMIN_ROLES.BW_DELIVERYAGENT === adminRole ||
      ADMIN_ROLES.BW_SUPPLIER_LOADERS === adminRole
    ) {
      dispatch(
        CurrentDayDeliveries({ pageNumber: 0, is_today: false, pageSize: 100 })
      );
    }
  }, [adminRole]);
  React.useEffect(() => {
    dispatch(GetQrBarcodeDetails(orderDetails.order_id));
  }, [orderDetails.order_id]);

  React.useEffect(() => {
    CustomConsole(supplierDeliveries);
    CustomConsole("----------BeforeAssignDriver-------------------");
    if (
      supplierDeliveries?.qrBarcodeDetails !== undefined &&
      supplierDeliveries?.qrBarcodeDetails !== null
    ) {
      if (Object.keys(supplierDeliveries?.qrBarcodeDetails).length > 0) {
        CustomConsole("------Successfully got QR barcode Details--------");
        CustomConsole(supplierDeliveries?.qrBarcodeDetails);

        // Storing in local storage

        // const encrypted = CryptoJS.AES.encrypt(
        //   supplierDeliveries?.qrBarcodeDetails?.scanned_for_order?.toString(),
        //   SECRET_KEY
        // ).toString();
        // localStorage.setItem("scanned_for_order", encrypted);

        // localStorage.setItem("current_scanned_codes", []);
        const sfo = supplierDeliveries.qrBarcodeDetails.scanned_for_order
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.scanned_for_order
            )
          : [];
        const cc = supplierDeliveries.qrBarcodeDetails.consumer_containers
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.consumer_containers
            )
          : [];
        const dfc = supplierDeliveries.qrBarcodeDetails
          .distributor_filled_containers
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.distributor_filled_containers
            )
          : [];
        localStorage.setItem("scanned_for_order", sfo);
        localStorage.setItem("consumer_containers", cc);
        localStorage.setItem("distributor_filled_containers", dfc);
      }
    }
    if (
      !supplierDeliveries.assigDeliveryAgenterror ||
      !supplierDeliveries.errorCurrentDeliveryVerifyOtp ||
      !supplierDeliveries.errorCurrentDeliveriesInScan
    ) {
      CustomConsole("---View order details API call here----");
      // dispatch(
      //   GetSupplierPreviousOnGoingDeliveries({
      //     pageNumber: 0,
      //     is_today: false,
      //     pageSize: 100,
      //   })
      // );
      if (ADMIN_ROLES.BW_DISTRIBUTER === adminRole) {
        dispatch(GetSupplierPreviousOnGoingDeliveries());
      }
      if (ADMIN_ROLES.BW_DELIVERYAGENT === adminRole) {
        dispatch(
          CurrentDayDeliveries({
            pageNumber: 0,
            is_today: false,
            pageSize: 500,
          })
        );
      }

      // dispatch(GetViewOrderDetails(orderId));
      // dispatch(GetQrBarcodeDetails(orderId));
      // if (assignDeliveryAgentBtn === true) {
      //   setAssignDeliveryAgentBtn(false);
      // }
      // if (submitOtpBtn === true) {
      //   setSubmitOtpBtn(false);
      //   handleCloseDialog();
      // }
      if (outScanBtn === true) {
        handleCloseCameraDialog();
        setOutScanBtn(false);
      }
      if (inScanBtn === true) {
        handleCloseCameraDialog();
        setInScanBtn(false);
      }
    }
  }, [assignDeliveryAgentBtn, submitOtpBtn, outScanBtn, inScanBtn]);
  React.useEffect(() => {
    // Retrieve the arrays from local storage
    const scannedForOrder =
      localStorage.getItem("scanned_for_order") &&
      localStorage.getItem("scanned_for_order").length
        ? JSON.parse(localStorage.getItem("scanned_for_order"))
        : [];
    const consumerContainers =
      localStorage.getItem("consumer_containers") &&
      localStorage.getItem("consumer_containers").length
        ? JSON.parse(localStorage.getItem("consumer_containers"))
        : [];
    const distributorFilledContainers =
      localStorage.getItem("distributor_filled_containers") &&
      localStorage.getItem("distributor_filled_containers").length
        ? JSON.parse(localStorage.getItem("distributor_filled_containers"))
        : [];
    CustomConsole("-------Retrived from local storage-------");
    CustomConsole(scannedForOrder);
    CustomConsole(consumerContainers);
    CustomConsole(distributorFilledContainers);
  }, []);
  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    // setOpenCameraDialog(false);
  };
  const handleCopyAddress = (props) => {
    CustomConsole("------------handleCopyAddress------------------");
    const address_compile = ` ${props.delivery_address.contact_name},${props.delivery_address.contact_phone},${props.delivery_address.address_line1},
    ${props.delivery_address.address_line2},
    ${props.delivery_address.address_line3},
    ${props.delivery_address.service_area}-
    ${props.delivery_address.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };
  const handleViewDetails = (props) => {
    const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${props.order_id}`;
    history.push(url);
  };
  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.delivery_address.contact_phone !== "") {
      CustomConsole(props.delivery_address.contact_phone);
      const telUrl = `tel:${props.delivery_address.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleLocation = (props) => {
    CustomConsole("------------handleLocation------------------");
    CustomConsole(props.delivery_address.latitude);
    CustomConsole(props.delivery_address.longitude);

    if (
      props.delivery_address.latitude !== undefined &&
      props.delivery_address.latitude !== null &&
      props.delivery_address.longitude !== undefined &&
      props.delivery_address.longitude !== null &&
      parseFloat(props.delivery_address.latitude) > 0 &&
      parseFloat(props.delivery_address.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props.delivery_address.latitude},${props.delivery_address.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handleScaDetails = (props, event) => {
    CustomConsole(props);
    setOrderDetails(props);
    CustomConsole("------------scaDetails------------------");
    CustomConsole(orderDetails);
    setIsProperScan(true);
    // const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${"BTORD-23455"}`;
    // history.push(url);
    // const buttonName = event.currentTarget.innerText;
    // if (buttonName === "IN-SCAN") {
    //   setIsInScan(true);
    // } else {
    //   setIsInScan(false);
    // }
    // if (
    //   props.packaged_drinking_water !== undefined &&
    //   props.packaged_drinking_water.delivery_slots !== undefined &&
    //   props.packaged_drinking_water.delivery_otp_status !== 200 &&
    //   props.packaged_drinking_water !== undefined &&
    //   props.packaged_drinking_water.delivery_slots !== undefined &&
    //   props.packaged_drinking_water.is_order_started === false
    // ) {
    //   CustomConsole("----Enter OTP----");
    //   setOpenEnterOtpDialog(true);
    //   // setSubmitOtpBtn(true);
    // } else if (
    //   props.packaged_drinking_water !== undefined &&
    //   props.packaged_drinking_water.delivery_slots !== undefined &&
    //   props.packaged_drinking_water.delivery_otp_status === 200 &&
    //   props.packaged_drinking_water !== undefined &&
    //   props.packaged_drinking_water.delivery_slots !== undefined &&
    //   props.packaged_drinking_water.is_order_started === false
    // ) {
    //   CustomConsole("----OTPLessDelivery----");

    //   const reqObj = {
    //     delivery_id: props.delivery_id,
    //     delivery_otp: props.deliveryOtp,
    //   };
    //   CustomConsole(reqObj);
    //   dispatch(PutSupplierCurrentDayVerifyOtp(reqObj));
    //   setSubmitOtpBtn(true);
    // } else
    if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_inscan_completed === true &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_outscan_completed === false &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_order_started === true
    ) {
      CustomConsole("----OutScan-----");
      setOpenOutScanCamera(true);
      setOpenQrBarCodeCamera(false);
      setOpenCameraDialog(true);
      CustomConsole(openOutScanCamera);
    } else if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_inscan_completed === false &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_outscan_completed === false &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_order_started === true
    ) {
      CustomConsole("----InScan-----");
      setOpenQrBarCodeCamera(true);
      setOpenOutScanCamera(false);
      setOpenCameraDialog(true);
      setIsInScan(true);
    } else if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.is_inscan_completed !== undefined &&
      props.packaged_drinking_water.is_inscan_completed === true &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.is_outscan_completed !== undefined &&
      props.packaged_drinking_water.is_outscan_completed === true
    ) {
      CustomConsole("Complete Delivery");
      // let lat = 0;
      // let longit = 0;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            CustomConsole(position);
            // lat = position.coords.latitude;
            // longit = position.coords.longitude;
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
      CustomConsole("latitude:" + latitude);
      CustomConsole("longitude:" + longitude);
      const reqObj = {
        delivery_otp:
          props.packaged_drinking_water !== undefined &&
          props.packaged_drinking_water.delivery_otp !== undefined &&
          props.packaged_drinking_water.delivery_otp,
        delivery_id: props.delivery_id,
        driver_challan: "",
        latitude: props.latitude,
        longitude: props.longitude,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayCompleteDeliveries(reqObj));
      setTimeout(() => {
        localStorage.removeItem("current_scanned_codes");
        localStorage.removeItem("consumer_containers");
        localStorage.removeItem("distributor_filled_containers");
      }, 1000);
      // setTimeout(() => {
      //   dispatch(GetViewOrderDetails(orderId));
      // }, 500);
    }
  };
  return (
    <Material.Box
      sx={{
        maxWidth: { xs: "390px", md: "100%", sm: "650px" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {ADMIN_ROLES.BW_DISTRIBUTER === adminRole ||
      ADMIN_ROLES.BW_SUPPLIERMANAGER === adminRole ? (
        <>
          {supplierDeliveries.getSupplierPreviuosOngoingDeliveries !==
            undefined &&
          supplierDeliveries.getSupplierPreviuosOngoingDeliveries !== null &&
          supplierDeliveries.getSupplierPreviuosOngoingDeliveries.length > 0 ? (
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                maxHeight: "550px",
                overflow: "auto",
              }}
            >
              {supplierDeliveries.getSupplierPreviuosOngoingDeliveries.map(
                (data, key) => {
                  return (
                    <SupplierPreviousDeliveriesCard
                      data={data}
                      key={key}
                      handleScaDetails={handleScaDetails}
                      handleCopyAddress={handleCopyAddress}
                      handleLocation={handleLocation}
                      handlePhone={handlePhone}
                      handleViewDetails={handleViewDetails}
                    />
                  );
                }
              )}
            </Material.Box>
          ) : (
            <Material.Card elevation={24} sx={{ marginTop: "20px" }}>
              <Material.Typography sx={{ fontWeight: "bold", color: "red" }}>
                No more Previous deliveries left!
              </Material.Typography>
            </Material.Card>
          )}
        </>
      ) : (
        <>
          {supplierDeliveries.getNextDayDeliveries !== undefined &&
          supplierDeliveries.getNextDayDeliveries !== null &&
          supplierDeliveries.getNextDayDeliveries.length > 0 ? (
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                maxHeight: "550px",
                overflow: "auto",
              }}
            >
              {supplierDeliveries.getNextDayDeliveries.map((data, key) => {
                return (
                  <SupplierPreviousDeliveriesCard
                    data={data}
                    key={key}
                    handleScaDetails={handleScaDetails}
                    handleCopyAddress={handleCopyAddress}
                    handleLocation={handleLocation}
                    handlePhone={handlePhone}
                    handleViewDetails={handleViewDetails}
                  />
                );
              })}
            </Material.Box>
          ) : (
            <Material.Card elevation={24} sx={{ marginTop: "20px" }}>
              <Material.Typography sx={{ fontWeight: "bold", color: "red" }}>
                No more Previous deliveries left!
              </Material.Typography>
            </Material.Card>
          )}
        </>
      )}
      <CurrentDayDeliveriesEnterOtpDialog
        openEnterOtpDialog={openEnterOtpDialog}
        enterOtpTextfied={enterOtpTextfied}
        otpValidationError={otpValidationError}
        setOtpValidationError={setOtpValidationError}
        setEnterOtpTextfied={setEnterOtpTextfied}
        // handleSubmitOTP={handleCurrentDaySubmitOtp}
        // handleClose={handleCloseDialog}
      />
      {/* <CameraDialog
        setOpenScanningDialog={setOpenScanningDialog}
        openCameraDialog={openCameraDialog}
        setOpenCameraDialog={setOpenCameraDialog}
        openQrBarCodeCamera={openQrBarCodeCamera}
        openOutScanCamera={openOutScanCamera}
        orderid={orderDetails.order_id}
        setInScanBtn={setInScanBtn}
        setOutScanBtn={setOutScanBtn}
        data={orderDetails}
        // decodedResults={decodedResults}
        // setScanCount={setScanCount}
        // onNewScanResultQrCode={onNewScanResultQrCodes}
        // onNewScanResult={onNewScanResults}
        handleClose={handleCloseCameraDialog}
        isInScan={isInScan}
        // setScannedCurrent={setScannedCurrent}
        setInScannedCodes={setInScannedCodes}
        inScannedCodes={inScannedCodes}
        setOutScannedCodes={setOutScannedCodes}
        outScannedCodes={outScannedCodes}
        inScannedNo={inScannedNo}
        setInScannedNo={setInScannedNo}
        setOutScannedNo={setOutScannedNo}
        outScannedNo={outScannedNo}
        isDistributor={true}
        setCurrentScanned={setCurrentScanned}
        currentScanned={currentScanned}
        cameraScannedCodes={cameraScannedCodes}
        setCameraScannedCodes={setCameraScannedCodes}
        isProperScan={isProperScan}
        setIsProperScan={setIsProperScan}
      /> */}
    </Material.Box>
  );
}

export default PreviousDeliveries;
