import React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomConsole from "../../CustomConsole";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Pincode from "../AdminSettings/Pincode";
import { ORDER_TYPE } from "../../Components/AppMeta/appMetaConfig";
// import TimerIcon from "@mui/icons-material/Timer";
// import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#FBFAF7",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: "7px",
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function FilterComponent(props) {
  const [area, setArea] = React.useState("");

  const [orderType, setOrderType] = React.useState("");
  const [pincodesArea, setPincodesArea] = React.useState([]);
  const [selectedPincode, setSelectedPincode] = React.useState("");
  // const [assignType, setAssignType] = React.useState("slot");

  // const area = [
  //   "KELAMBAKAM",
  //   "PADUR",
  //   "ASHOK NAGAR",
  //   "MOUNT ROAD",
  //   "WEST MAMBALAM",
  // ];
  React.useEffect(() => {
    if (selectedPincode) {
      setPincodesArea(() =>
        props?.supplierPinCodes
          ?.filter((obj) => obj.pincode === selectedPincode)
          .map((filteredObj) => filteredObj.areas)
      );
    }
  }, [selectedPincode, props.supplierPinCodes]);
  const handleChange = (event) => {
    setOrderType(event.target.value);
  };

  const handleAssign = (event) => {
    props.setUnAssign(event.target.value);
props.handleAssign();
    // if(event.target.value === 2){

    // }if(event.target.value === 3){

    // }
  };
  // const handleChangeArea = (e) => {
  //   setArea(e.target.value);
  // };
  // const handleChangePincode = (e) => {
  //   setPincode(e.target.value);
  // };
  const handleApply = () => {
    props.handleApply({
      pincode: selectedPincode,
      area: area,
      orderType: orderType,
    });
  };

  return (
    <Box>
      <Grid
        container
        // columnSpacing={{ md: 1, xl: 1 }}
        mt={{ sm: 1, md: 1, xl: 1 }}
      >
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Item variant="elevation" elevation={10}>
            {" "}
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <FormControl
                variant="standard"
                sx={{
                  mt: 1,
                  minWidth: { sm: "30%", xs: "30%", md: "35%", xl: "35%" },
                }}
              >
                <InputLabel>Pincodes</InputLabel>
                <Select
                  value={selectedPincode}
                  onChange={(e) => {
                    setSelectedPincode(e.target.value);
                  }}
                  label="Pincodes"
                  size="small"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {props?.supplierPinCodes?.map((obj, key) => {
                    return (
                      <MenuItem value={obj.pincode} key={key}>
                        {obj.pincode}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  mt: 1,
                  minWidth: { sm: "30%", xs: "30%", md: "35%", xl: "35%" },
                }}
              >
                <InputLabel>Area</InputLabel>
                <Select
                  value={area}
                  onChange={(e) => {
                    setArea(e.target.value);
                  }}
                  label="Area"
                  size="small"
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {pincodesArea?.[0]?.map((obj, key) => {
                    return (
                      <MenuItem
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        value={obj}
                        key={key}
                      >
                        {obj}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Item>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} xl={4}>
          <Item>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <ToggleButtonGroup
                color="primary"
                value={assignType}
                exclusive
                onChange={handleChangeAssignTog}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value="slot">
                  <TimerIcon />
                </ToggleButton>

                <ToggleButton value="agent">
                  <DeliveryDiningIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              {assignType === "slot" ? (
                <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Slot
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Slot"
                    size="small"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {slot.map((obj, key) => {
                      return (
                        <MenuItem key={key} value={obj.id}>
                          {obj}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Agent
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Agent"
                    size="small"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {agent.map((obj, key) => {
                      return (
                        <MenuItem key={key} value={obj.id}>
                          {obj.name}-{obj.phone}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </Item>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Item elevation={10}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Box sx={{ minWidth: 80 }}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={orderType}
                    onChange={handleChange}
                    variant="standard"
                    label="Type"
                    size="small"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={ORDER_TYPE.SUBSCRIPTION_ORDER}>
                      SUBS
                    </MenuItem>
                    <MenuItem value={ORDER_TYPE.INSTA_DELIVERY_ORDER}>
                      INSTA
                    </MenuItem>
                    <MenuItem value={ORDER_TYPE.REGULAR_ORDER}>REG</MenuItem>
                    <MenuItem value={ORDER_TYPE.POST_PAID_ORDER}>POST</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <FormControl variant="standard" sx={{ minWidth: 80 }}>
                <InputLabel>Slot/Agent</InputLabel>
                <Select
                  variant="standard"
                  value={props.unAssign}
                  onChange={handleAssign}
                  label="Slot/Agent"
                  size="small"
                >
                  {/* <MenuItem value={1}>Slot & Agent</MenuItem> */}
                  <MenuItem value={2}>Slot</MenuItem>
                  <MenuItem value={3}>Agent</MenuItem>
                </Select>
              </FormControl>

              <Button variant="outlined" size="small" onClick={handleApply}>
                Apply
              </Button>
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FilterComponent;
