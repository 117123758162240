import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CustomConsole from "../../CustomConsole";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  StepLabel,
  Switch,
  Tab,
  TextField,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { GetProductsAddress } from "../Redux/Actions/adminSkuBookingAction";
import CartCard from "./RegularBookingCards/CartCard";
import ProductsCard from "./RegularBookingCards/ProductsCard";
import { GetUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import ReassignSupplierDialog from "../AdminPoTrackPostPaid/ReassignSupplierDialog";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = ["Choose the products", "Place Order"];

const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
});

function RegularBooking({
  userId,
  open,
  setOpen,
  isPostpaid,
  poProducts,
  supplierId,
  poTrack,
  distributerName,
  setDistributerName,
  setSupplier,
  lastDate,
  consumerId,
  addressIdPre,
}) {
  const dispatch = useDispatch();
  const referenceDate = dayjs(moment(lastDate).endOf("day").format());
  const productDet = useSelector((state) => state.adminSkuBookingData);
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const userStockDet = useSelector(
    (state) => state.adminSkuBookingData.userStock
  );
  const [tabValue, setTabValue] = React.useState("1");
  const [products, setProducts] = React.useState([]);
  const [orderQty, setOrderQty] = React.useState(0);
  const [returnQty, setReturnQty] = React.useState(0);
  const [selectedProductCartDetails, setSelectedProductCartDetails] =
    React.useState({});
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [orderProducts, setOrderProducts] = React.useState([]);
  const [productId, setProductId] = React.useState(0);
  const [addressProductData, setAddressProductData] = React.useState([]);
  const [cartProducts, setCartProducts] = React.useState([]);
  const [addressId, setAddressId] = React.useState(null);
  const [otpLess, setOtpLess] = React.useState(false);
  const [orderDate, setOrderDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [userStock, setUserStock] = React.useState({});
  const [payMethod, setPayMethod] = React.useState(() =>
    !isPostpaid ? "RAZORPAY" : "POSTPAID"
  );

  const [selectedCoup, setSelectedCoup] = React.useState("");
  const [waterDrops, setwaterDrops] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [totalNo, setTotalNo] = React.useState(0);
  const [supplierSelectDig, setSupplierSelectDig] = React.useState(false);
  const [returnErr, setReturnErr] = React.useState(false);
  const [userSelAdd, setUserSelAdd] = React.useState({});
  const [userInfo, setUserInfo] = React.useState({});
  const [showDelCharges, setShowDelCharges] = React.useState(false);
  const [deliveryCharge, setDeliveryCharge] = React.useState(true);
  const [maxReturn, setMaxReturn] = React.useState(0);
  const today = moment();
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setCartProducts([]);
    setOrderProducts([]);
    setProducts([]);
    setTabValue(newValue);
  };

  const handleClose = () => {
    setCartProducts([]);
    setOrderProducts([]);
    setProducts([]);
    setOpen(false);
    setActiveStep(0);
  };
  useEffect(() => {
    CustomConsole(products);
    let product = products.filter((item) => item.id === productId);
    let productFind = orderProducts?.filter(
      (item) =>
        product?.[0]?.capacity === item.capacity &&
        product?.[0]?.material === item.material
    );
    setSelectedProductCartDetails(productFind?.[0] || {});
    setOrderQty(productFind?.[0]?.quantity || 0);
    setReturnQty(productFind?.[0]?.return || 0);
    return;
  }, [productId, activeStep]);

  const addProductsCart = (material, capacity, orderCan, returnCan) => {
    CustomConsole("---Number of cans ----");
    CustomConsole(orderCan);
    let productFind = products.filter(
      (item) => item.capacity === capacity && item.material === material
    );

    if (orderCan !== "" && !isNaN(orderCan)) {
      const existingProductIndex = orderProducts.findIndex(
        (products) =>
          products.capacity === capacity && products.material === material
      );

      if (existingProductIndex !== -1) {
        const updatedProducts = orderProducts.filter(
          (obj, idx) => idx !== existingProductIndex
        );
        const updatedProductObj = {
          quantity: parseFloat(orderCan),
          capacity: capacity,
          material: material,
          return: returnCan ? parseFloat(returnCan) : 0,
          sku_qty: productFind[0].sku_qty[0],
        };

        setOrderProducts([...updatedProducts, updatedProductObj]);
      } else {
        // Product does not exist, add a new entry
        const productObj = {
          quantity: parseFloat(orderCan),
          capacity: capacity,
          material: material,
          return: returnCan ? parseFloat(returnCan) : 0,
          sku_qty: productFind[0].sku_qty[0],
        };

        setOrderProducts((prev) => [...prev, productObj]);
      }
    }
  };

  const handleAddressChange = (e) => {
    setAddressId(e.target.value);
  };

  const onChangeStartDate = (newValue) => {
    if (newValue != null && newValue.$d != null) {
      setOrderDate(() => moment(newValue.$d).tz("Asia/kolkata").format());

      CustomConsole(newValue.$d);
    }
  };

  const handleOrderQty = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6);
    }
    setOrderQty(inputValue);
  };

  const handleReturn = (e) => {
    let error = false;

    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6);
    }
    setReturnQty(inputValue);

    if (
      userStock[0] &&
      (userStock[0].max_return < inputValue ||
        orderQty * selectedProduct?.sku_qty?.[0] < inputValue)
    ) {
      error = true;
    } else if (!userStock[0] && inputValue > 0) {
      error = true;
    } else {
      error = false;
    }

    setReturnErr(error);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const shouldDisableDate = (date) => {
    return date.isAfter(referenceDate);
  };

  const blockAfterOneWeek = (date) => {
    const today = dayjs();
    const nextWeek = today.add(7, "day");
    return dayjs(date).isAfter(nextWeek, "day");
  };

  const userDetailStyle = {
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#9BC2C9",
    borderRadius: "10px",
  };

  // const removeProductCart = ()=>{

  // }

  React.useEffect(() => {
    if (!isPostpaid) {
      setAddressId(addressIdPre);
    }
  }, [addressIdPre, isPostpaid]);

  React.useEffect(() => {
    CustomConsole("-----User Id for booking ---");
    CustomConsole(userId);
    if (open && isPostpaid) {
      dispatch(GetUserAddressAdmin(userId));
    }
  }, [userId, open, isPostpaid]);

  React.useEffect(() => {
    CustomConsole("----Address Id Changing---");
    CustomConsole(addressId);
    if (open && addressId) {
      setCartProducts([]);
      setOrderProducts([]);
      setProducts([]);
      dispatch(GetProductsAddress({ address_id: addressId }));
    }
  }, [open, addressId]);

  React.useEffect(() => {
    CustomConsole("----Product Data----");
    CustomConsole(productDet);
    setAddressProductData(() =>
      productDet?.products ? productDet.products : []
    );
  }, [productDet]);

  React.useEffect(() => {
    CustomConsole("-------------addressProductData---------");
    CustomConsole(addressProductData);
    let productsToShow = [];
    if (isPostpaid && addressProductData?.products && poProducts) {
      productsToShow = addressProductData?.products.filter((obj) =>
        poProducts.some(
          (item) =>
            item.container_capacity === obj.capacity &&
            item.container_material === obj.material
        )
      );

      CustomConsole("selected products for postpaid");
      CustomConsole(productsToShow);
    } else {
      productsToShow = addressProductData?.products
        ? addressProductData.products
        : [];
    }

    if (tabValue === "1" && productsToShow) {
      setProducts(() =>
        productsToShow.filter(
          (item) => item.booking_type === 1 || item.booking_type === 3
        )
      );
    } else if (tabValue === "2" && productsToShow) {
      setProducts(() =>
        productsToShow.filter(
          (item) => item.booking_type === 2 || item.booking_type === 3
        )
      );
    } else {
      setProducts([]);
    }
  }, [tabValue, addressProductData, isPostpaid, poProducts]);

  React.useEffect(() => {
    if (productId) {
      const selectedProduct = products.filter((obj) => {
        return obj.id === productId;
      });
      CustomConsole(selectedProduct);
      setSelectedProduct(() => selectedProduct[0]);
    }
  }, [productId, products]);

  React.useEffect(() => {
    if (orderQty) {
      setTotalNo(() =>
        selectedProduct?.sku_qty?.[0]
          ? selectedProduct.sku_qty[0] * orderQty
          : 0
      );
    }
  }, [selectedProduct, orderQty]);

  React.useEffect(() => {
    CustomConsole("-----Stock and selected order---");
    if (userStockDet && selectedProduct) {
      CustomConsole(userStockDet);
      CustomConsole(selectedProduct);

      let stockOfSelected = userStockDet.filter(
        (obj) =>
          obj.container_capacity === selectedProduct.capacity &&
          obj.container_material === selectedProduct.material
      );

      setUserStock(stockOfSelected);
    }
  }, [userStockDet, selectedProduct]);

  React.useEffect(() => {
    if (updateUserDetails?.address && !isPostpaid) {
      const address = updateUserDetails.address.find(
        (obj) => obj.address_id === addressIdPre
      );
      setUserSelAdd(() => (address ? address : {}));
    }
  }, [updateUserDetails, addressIdPre]);

  React.useEffect(() => {
    CustomConsole("----user det---");
    CustomConsole(addUserAdminDetails);
    if (addUserAdminDetails?.users?.[0]) {
      setUserInfo(addUserAdminDetails.users[0]);
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    const seletedDay = moment(orderDate);
    if (seletedDay.isSame(today, "day")) {
      setShowDelCharges(true);
      setDeliveryCharge(true);
    } else {
      setShowDelCharges(false);
      setDeliveryCharge(false);
    }
  }, [orderDate]);

  React.useEffect(() => {
    if (userStock?.[0]) {
      const returnStock = userStock[0].max_return;
      if (totalNo <= returnStock) {
        setMaxReturn(totalNo);
      } else if (totalNo >= returnStock) {
        setMaxReturn(returnStock);
      }
    }
  }, [userStock, totalNo]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Order Now
          </Typography>
        </Toolbar>
      </AppBar>
      <Box>
        <TabContext value={tabValue}>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <TabList onChange={handleTabChange} className={classes.tabUi}>
              <Tab label="Refill" value="1" />
              {/* <Tab label="One-Time Purchase" value="2" /> */}
            </TabList>
          </Box>
          {/* ------------Stepper------------- */}
          {!isPostpaid ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              mt={1}
            >
              <Grid xs={8} sm={8} md={4} xl={4}>
                <Typography sx={userDetailStyle}>
                  {userSelAdd.service_area}, {userSelAdd.address_line1},
                  {userSelAdd.address_line2}
                </Typography>
              </Grid>
              <Grid xs={8} sm={8} md={4} xl={4}>
                <Typography sx={userDetailStyle}>
                  Name: {userInfo.fullname}
                </Typography>
              </Grid>
              <Grid xs={8} sm={8} md={4} xl={4}>
                <Typography sx={userDetailStyle}>
                  Phone: {userInfo.phone}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          <Box sx={{ width: "100%", padding: 3 }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step
                  sx={{ cursor: "pointer" }}
                  key={label}
                  completed={completed[index]}
                >
                  <StepLabel
                    onClick={() => (index == 0 ? setActiveStep(index) : "")}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box p={1}>
                    <Grid
                      container
                      spacing={2}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"flex-start"}
                    >
                      <Grid
                        container
                        spacing={2}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                      ></Grid>
                      {activeStep === 0 ? (
                        <>
                          {isPostpaid ? (
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                              <Box>
                                <FormControl sx={{ width: 220 }} size="small">
                                  <InputLabel>Select Address</InputLabel>
                                  <Select
                                    value={addressId}
                                    label="Select Address"
                                    onChange={handleAddressChange}
                                  >
                                    {updateUserDetails?.address?.map(
                                      (data, idx) => {
                                        return (
                                          <MenuItem
                                            key={idx}
                                            value={data.address_id}
                                          >
                                            {data.contact_name}: {data.pincode}-
                                            {data.service_area} &nbsp; &nbsp;
                                            {data.is_primary ? (
                                              <>
                                                <Chip
                                                  label={"Primary"}
                                                  sx={{
                                                    bgcolor: "green",
                                                    color: "white",
                                                  }}
                                                />
                                              </>
                                            ) : null}{" "}
                                            &nbsp;
                                            {data.is_billing_address ===
                                            true ? (
                                              <>
                                                <Chip
                                                  label={"Billing"}
                                                  sx={{
                                                    bgcolor: "red",
                                                    color: "white",
                                                  }}
                                                />
                                              </>
                                            ) : null}
                                            &nbsp;
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                          ) : null}

                          {!isPostpaid && showDelCharges ? (
                            <Grid item xs={6} sm={5} md={4} xl={4}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={deliveryCharge}
                                    onChange={(e) =>
                                      setDeliveryCharge(e.target.checked)
                                    }
                                  />
                                }
                                label="Delivery Charge"
                              />
                            </Grid>
                          ) : null}

                          {!isPostpaid ? (
                            <Grid item xs={6} sm={5} md={4} xl={4}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={otpLess}
                                    onChange={(e) =>
                                      setOtpLess(e.target.checked)
                                    }
                                  />
                                }
                                label="OTP Less"
                              />
                            </Grid>
                          ) : null}
                          {isPostpaid ? (
                            <Grid item xs={6} sm={5} md={4} xl={4}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <Typography>
                                  Default Distributor: {distributerName}
                                </Typography>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={() => setSupplierSelectDig(true)}
                                >
                                  Change
                                </Button>
                              </Box>
                            </Grid>
                          ) : null}

                          <Grid item xs={6} sm={4} md={4} xl={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                label="Delivery Date"
                                inputFormat="DD/MM/YYYY"
                                // minDate={moment().subtract(0, 'days')}
                                value={orderDate}
                                onChange={onChangeStartDate}
                                disablePast
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    required
                                    inputProps={{
                                      ...params.inputProps,
                                      readOnly: true,
                                    }}
                                  />
                                )}
                                shouldDisableDate={
                                  !isPostpaid
                                    ? blockAfterOneWeek
                                    : shouldDisableDate
                                }
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={12} sm={12} md={7} xl={7}>
                            <Box
                              sx={{
                                borderRadius: 2,
                                backgroundColor: "#f0f5f9",
                                border: "1px solid #B7C2A0", // Border color

                                padding: "16px", // Padding inside the box

                                margin: "auto", // Center the box
                              }}
                            >
                              <Typography
                                p={1}
                                sx={{
                                  fontSize: "17px",
                                  fontWeight: 500,
                                  color: "GrayText",
                                  // textDecoration: "underline",
                                }}
                              >
                                Choose products
                              </Typography>
                              <Grid container spacing={2} padding={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box sx={{ width: "100%" }}>
                                    <FormControl
                                      size="small"
                                      sx={{ width: "90%" }}
                                    >
                                      <InputLabel
                                        id="product-type-select-label"
                                        sx={{
                                          fontSize: "1rem",
                                          marginTop: "2px",
                                        }}
                                      >
                                        Products
                                      </InputLabel>
                                      <Select
                                        labelId="product-type-select-label"
                                        id="product-type-select"
                                        value={productId}
                                        label="Product"
                                        onChange={(e) =>
                                          setProductId(e.target.value)
                                        }
                                      >
                                        {products.map((obj, idx) => {
                                          return (
                                            <MenuItem
                                              value={obj.id}
                                              sx={{ fontSize: "0.76rem" }}
                                              key={idx}
                                            >
                                              {`${obj.material}-${obj.capacity}`}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box sx={{ width: "90%" }}>
                                    <TextField
                                      size="small"
                                      InputLabelProps={{ shrink: true }}
                                      label="Order qty"
                                      fullWidth
                                      value={orderQty}
                                      onChange={handleOrderQty}
                                      helperText={`Order Qty.: ${orderQty}* Pack of ${
                                        selectedProduct?.sku_qty &&
                                        selectedProduct?.sku_qty?.[0]
                                          ? selectedProduct.sku_qty[0]
                                          : 0
                                      }= ${totalNo}`}
                                      inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Box sx={{ width: "90%" }}>
                                    <TextField
                                      size="small"
                                      InputLabelProps={{ shrink: true }}
                                      label="Return qty"
                                      fullWidth
                                      helperText={`Max return Qty. in units ${maxReturn}`}
                                      value={returnQty}
                                      onChange={handleReturn}
                                      error={returnErr}
                                    />
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                  <Box>
                                    <Button
                                      variant="outlined"
                                      onClick={() =>
                                        addProductsCart(
                                          selectedProduct.material,
                                          selectedProduct.capacity,
                                          orderQty,
                                          returnQty
                                        )
                                      }
                                    >
                                      Add
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box mt={1} sx={{ width: "100%" }}>
                              <ProductsCard
                                products={orderProducts}
                                setProducts={setOrderProducts}
                                otpLess={otpLess}
                                addressId={addressId}
                                bookingType={tabValue}
                                setCartProducts={setCartProducts}
                                orderDate={orderDate}
                                payMethod={payMethod}
                                setPayMethod={setPayMethod}
                                setwaterDrops={setwaterDrops}
                                setSelectedCoup={setSelectedCoup}
                                setActiveStep={setActiveStep}
                                isPostpaid={isPostpaid}
                                supplierId={supplierId}
                                deliveryCharge={deliveryCharge}
                              />
                            </Box>
                          </Grid>
                        </>
                      ) : null}

                      {activeStep === 1 ? (
                        <Grid
                          item
                          sx={{
                            gap: 1,
                          }}
                          // xs={12} sm={12} md={4} xl={4}
                        >
                          <CartCard
                            setCartProducts={setCartProducts}
                            cartProducts={cartProducts}
                            userId={userId}
                            otpLess={otpLess}
                            addressId={addressId}
                            bookingType={tabValue}
                            products={orderProducts}
                            payMethod={payMethod}
                            orderDate={orderDate}
                            selectedCoup={selectedCoup}
                            setSelectedCoup={setSelectedCoup}
                            waterDrops={waterDrops}
                            setwaterDrops={setwaterDrops}
                            isPostpaid={isPostpaid}
                            supplierId={supplierId}
                            poTrack={poTrack}
                            consumerId={consumerId}
                            setActiveStep={setActiveStep}
                            deliveryCharge={deliveryCharge}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>

          {/* ------------Stepper------------- */}
        </TabContext>
      </Box>
      <ReassignSupplierDialog
        open={supplierSelectDig}
        setOpen={setSupplierSelectDig}
        setSupplierSelect={setSupplier}
        setSupplierName={setDistributerName}
      />
    </Dialog>
  );
}

export default RegularBooking;
