//Css for empty can order page

export const styles = {
  ecoDetailsCard: {
    fontSize: "15px",
    fontWeight: "bold",

    color: "blue",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "start",
    padding: "10px",

    color: "black",
  },
  cardValues: {
    marginLeft: "5px",
    fontWeight: "500",

  },
  modalStyle: {
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',

    // bgcolor: 'white',
    // border: '2px solid #000',

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  TableHeading: {
    fontWeight: "bold",
    fontSize: 14, 
    backgroundImage:
      "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
  },
 CardHeading:{
  fontWeight: "bold",
    fontSize: 14, 
    backgroundImage:
      "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
    borderRadius:"1em"
 }
}
