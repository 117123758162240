import * as React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
// import { Transcribe } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CustomConsole from "../../CustomConsole";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ShowMoreDetailsDialog from "./ShowMoreDetailsDialog";

export default function TransactionsTable({
  transactionType,
  paginationModel,
  setPaginationModel,
  pageNumber,
  setPageNumber,
  disableBtn,
  setChangeInPage,
  pageSize,
  setPageSize,
  setSearchPageSize,
  handleSearchPageSize,
}) {
  const [openShowDetailsDialog, setOpenShowDetailsDialog] =
    React.useState(false);
  const [updatedByDetails, setUpdatedByDetails] = React.useState({});
  const waterDropStatus = {
    WATER_DROP_REFERRER_REWARDED: 1001, //applies for both infulencer and customer referrer rewarded
    WATER_DROP_REFERREE_REWARDED: 1002, //applies only for referree
    WATER_DROP_PROMO_DISCOUNT: 1003,
    WATER_DROP_PDW_ORDER_CONSUMER_PAID: 1004,
    WATER_DROP_REFUND_ORDER_CANCEL: 1005,
    WATER_DROP_CASHBACK_REWARDING: 0,
    WATER_DROP_ADMIN_REFUND: 1011,
    REWARD_FOR_CAMPAIGN_PARTICIPATION: 1111,
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: appColors.commonLayoutColor.background, // Set the desired background color here
            color: "black", // Set the desired text color here
          },
        },
      },
    },
    typography: {
      fontSize: 12, // Default font size for the entire table
    },
  });

  const adminTransactionDetails = useSelector(
    (state) => state.adminTransactionDetails
  );
  const dateComparator = (date1, date2) => {
    const momentDate1 = moment(date1, "DD/MM/YYYY");
    const momentDate2 = moment(date2, "DD/MM/YYYY");
    return momentDate1.isBefore(momentDate2)
      ? -1
      : momentDate1.isAfter(momentDate2)
      ? 1
      : 0;
  };

  const columnsOrdersTransaction = [
    {
      field: "id",
      headerName: "S.No",
      // width: 170,
      valueGetter: (params) => params.row.id,
    },
    { field: "transaction_id", headerName: "Transaction ID", width: 140 },
    { field: "order_id", headerName: "Order ID", width: 180 },
    // { field: "amount", headerName: "Amount", width: 120 },
    {
      field: "amount",
      headerName: "Amount",
      // width: 120,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const amount1 = parseFloat(v1);
        const amount2 = parseFloat(v2);

        if (amount1 < amount2) {
          return -1; // v1 comes before v2
        } else if (amount1 > amount2) {
          return 1; // v1 comes after v2
        } else {
          return 0; // amounts are equal
        }
      },
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      // width: 150,
      valueGetter: (params) =>
        params.row.payment_method === ""
          ? "Refunded"
          : params.row.payment_method,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      // width: 150,
      valueGetter: (params) => params.row.payment_status,
    },

    {
      field: "transaction_ts",
      headerName: "Payment Date",
      // width: 150,
      sortComparator: dateComparator,
    },
    {
      headerName: "Updated By", // Header text for the button column
      // width: 150, // Adjust the width as needed

      renderCell: (params) => {
        // Create a button element for each row
        return (
          <>
            {" "}
            {updatedByDetails.updated_user_details ? (
              <Button
                onClick={() => handleShowDetails(params.row)}
                variant="contained"
                size="small"
              >
                Show Details
              </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  const columnsDepositTransaction = [
    { field: "id", headerName: "S.No" },
    { field: "order_id", headerName: "Order ID" },

    { field: "container_base_price", headerName: "Base Price" },
    { field: "container_qty", headerName: "Quantity" },
    {
      field: "deposit_amount",
      headerName: "Deposit Amount",
      // width: 150,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const amount1 = parseFloat(v1);
        const amount2 = parseFloat(v2);

        if (amount1 < amount2) {
          return -1; // v1 comes before v2
        } else if (amount1 > amount2) {
          return 1; // v1 comes after v2
        } else {
          return 0; // amounts are equal
        }
      },
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      // width: 150,
      valueGetter: (params) =>
        params.row.payment_status === true ? "Paid" : "Not-Paid",
    },
    { field: "updated_at", headerName: "Updated At", width: 120 },
  ];

  const columnsWaterDropTransaction = [
    { field: "id", headerName: "S.No" },
    { field: "order_id", headerName: "Order ID" },
    { field: "consumer_name", headerName: "Consumer Name" },
    { field: "water_drops", headerName: "Water Drops" },
    {
      field: "transaction_status",
      headerName: "Transaction Status",
      // width: 250,
      valueGetter: (params) => {
        const transactionStatus = params.row.transaction_status;
        let statusText = "";
        switch (transactionStatus) {
          case waterDropStatus.WATER_DROP_REFERRER_REWARDED:
            statusText = "Referrer Rewarded";
            break;
          case waterDropStatus.WATER_DROP_REFERREE_REWARDED:
            statusText = "Referree Rewarded";
            break;
          case waterDropStatus.WATER_DROP_PROMO_DISCOUNT:
            statusText = "Promo Discount";
            break;
          case waterDropStatus.WATER_DROP_PDW_ORDER_CONSUMER_PAID:
            statusText = "Consumer Paid";
            break;
          case waterDropStatus.WATER_DROP_REFUND_ORDER_CANCEL:
            statusText = "Refund Order Cancel";
            break;
          case waterDropStatus.WATER_DROP_CASHBACK_REWARDING:
            statusText = "Cashback Rewarding";
            break;
          case waterDropStatus.WATER_DROP_ADMIN_REFUND:
            statusText = "BookWater Rewarded";
            break;
          case waterDropStatus.REWARD_FOR_CAMPAIGN_PARTICIPATION:
            statusText = "Rewards for participating in campaign";
            break;
          default:
            statusText = "Unknown";
            break;
        }

        return statusText;
      },
    },
    // {
    //   headerName: "Updated By", // Header text for the button column
    //   // width: 150, // Adjust the width as needed
    //   renderCell: (params) => {
    //     // Create a button element for each row
    //     return (
    //       <>
    //         {updatedByDetails.updated_user_details !== undefined ? (
    //           <Button
    //             onClick={() => handleShowDetails(params.row)}
    //             variant="contained"
    //             size="small"
    //           >
    //             Show Details
    //           </Button>
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     );
    //   },
    // },
    { field: "fullname", headerName: "Updated by", width: 250 },
    { field: "reason", headerName: "Reason", width: 250 },
  ];
  function handleShowDetails(rowData) {
    // Implement the logic to show more details based on rowData
    // You can open a modal, expand the row, or perform any other action here.
    CustomConsole("Showing details for row:", rowData);
    setUpdatedByDetails(rowData);
    setOpenShowDetailsDialog(true);
  }
  const rows = adminTransactionDetails.ordersTransaction.map((obj, index) => {
    if (transactionType === 10 || transactionType === 20) {
      const paymentDate = moment(obj.transaction_ts).format("DD/MM/YYYY");
      CustomConsole(paymentDate);
      return { ...obj, id: index + 1, transaction_ts: paymentDate };
    } else if (transactionType === 30) {
      const paymentDate = moment(obj.updated_at).format("DD/MM/YYYY");
      CustomConsole(paymentDate);
      return { ...obj, id: index + 1, updated_at: paymentDate };
    } else {
      return { ...obj, id: index + 1 };
    }
  });

  const pageNumberForward = () => {
    if (adminTransactionDetails.ordersTransaction.length > 0) {
      setPageNumber(pageNumber + 1);
      setChangeInPage(true);
    }
  };
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      setChangeInPage(true);
    }
  };
  // const handleSearchPageSize = () => {
  //   setSearchPageSize(true);
  // };
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };
  return (
    <>
      {/* <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
        <div style={{ minWidth: "320px" }}> */}
      <Box
        sx={{
          // maxHeight: "600px",
          // maxWidth: { xs: "350px", sm: "450px", md: "600px", lg: "100%" },
          width: { xs: "80vw", sm: "45vw", md: "40vw", lg: "50vw" },
          // maxWidth: "100vw",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            alignItems: { md: "flex-end", xs: "center" },
            justifyContent: { md: "flex-end", xs: "center" },
            gap: { md: "15px", xs: "0px" },
            padding: { md: "10px", xs: "0px" },
            marginTop: "5px",
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              // gap: "15px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageNumberBackward}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                // marginLeft: "10px",
                // marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageNumberForward}
                disabled={disableBtn}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              mb: "6px",
            }}
          >
            <FirstPageIcon
              sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {pageNumber}
            </Typography>
            <LastPageIcon
              sx={{
                color: pageNumber > 0 ? "blue" : "gray",
              }}
              // onClick={() => {
              //   if (adminTransactionDetails?.ordersTransaction?.length) {
              //     setPageNumber(() => pageNumber + 1);
              //   }
              // }}
              onClick={() => {
                if (adminTransactionDetails.ordersTransaction.length) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </Box>
          <Box sx={{ width: 120 }}>
            {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl> */}
            <Material.TextField
              size="small"
              value={pageSize}
              label="Page size"
              InputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handleChangePageSize, // Attach the input event handler
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <MuiIcons.Search
                      cursor="pointer"
                      size="30px"
                      onClick={handleSearchPageSize}
                    />
                  </Material.InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={rows}
            columns={
              transactionType === 10 || transactionType === 20
                ? columnsOrdersTransaction
                : transactionType === 30
                ? columnsDepositTransaction
                : transactionType === 40
                ? columnsWaterDropTransaction
                : []
            }
            autoHeight
            hideFooter={true}
            // sx={{
            //   "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
            //     paddingLeft: "8px",
            //     paddingRight: "8px",
            //   },
            // }}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </ThemeProvider>
      </Box>
      {/* </div>
      </div> */}

      <ShowMoreDetailsDialog
        openShowDetailsDialog={openShowDetailsDialog}
        setOpenShowDetailsDialog={setOpenShowDetailsDialog}
        updatedByDetails={updatedByDetails}
      />
    </>
  );
}
