/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Web View Booking Page
 *
 */

import React from "react";
import WareHouseSearchField from "../../Components/Cards/DashBoard/WareHouseSearchField";
import WareHouseDetailsTable from "../../Components/Cards/DashBoard/wareHouseTable";

export default function WareHouseDashBoardMain() {
  return (
    <>
      <WareHouseSearchField />
      <WareHouseDetailsTable/>
    </>
  );
}
