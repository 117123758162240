import { DatasetOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  useRadioGroup,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import CustomConsole from "../../CustomConsole";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));
function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;
  if (radioGroup) {
    checked = parseInt(radioGroup.value) === parseInt(props.value);
  }
  return <StyledFormControlLabel checked={checked} {...props} />;
}
function DateFilterDialog(props) {
  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <DialogTitle>Select Date Range</DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "10px",
          margin: "10px 20px 20px 20px",
        }}
      >
        {props.orderUserType > 0 ? (
          <RadioGroup
            defaultValue={props.orderUserType}
            onChange={(event) => {
              CustomConsole("EVENT:");
              CustomConsole(event);
              props.setOrderUserType(event.target.value);
            }}
          >
            <MyFormControlLabel
              value={1}
              label="B2C"
              control={<Radio value={1} />}
            />
            <MyFormControlLabel
              value={2}
              label="B2B"
              control={<Radio value={2} />}
            />
          </RadioGroup>
        ) : null}

        <FormControl variant="standard">
          <InputLabel htmlFor="input-with-icon-adornment">From date</InputLabel>
          <Input
            value={props.fromDate}
            onChange={(event) => props.setFromDate(event.target.value)}
            type="date"
            startAdornment={<InputAdornment position="start" />}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel htmlFor="input-with-icon-adornment">To date</InputLabel>
          <Input
            value={props.toDate}
            onChange={(event) => props.setToDate(event.target.value)}
            type="date"
            startAdornment={<InputAdornment position="start" />}
          />
        </FormControl>
        <Button
          variant="contained"
          sx={{
            backgroundImage: "linear-gradient(#021636,#0c4aad)",
            "&:hover": {
              backgroundImage: "linear-gradient(#6b8df2,#021636)",
            },
          }}
          startIcon={<DatasetOutlined />}
          onClick={props.handleDateRange}
        >
          {" "}
          Get Data
        </Button>
      </Box>
    </Dialog>
  );
}

export default DateFilterDialog;
