import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  crateInScanError: true,
  crateOutScanError: true,
  qrLessInscanError: true,
  qrLessOutscanError: true,
};

const ScanCrateSkuReduser = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.CRATE_INSCAN_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        crateInScanError: action.payload.error,
      };
    case actionType.CRATE_OUTSCAN_SKU:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        crateOutScanError: action.payload.error,
      };
    case actionType.QRLESSS_INSCAN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        qrLessInscanError: action.payload.error,
        crateOutScanError:action.payload.error
      };
    case actionType.QRLESSS_OUTSCAN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        qrLessOutscanError: action.payload.error,
      };
    default:
      return state;
  }
};

export default ScanCrateSkuReduser;
