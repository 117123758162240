/**
 * @author vinay kumar
 * @description Admin Suplier Driver Reducer
 * @copyright Bookwater tech pvt ltd
 * @Date 24-08-2023
 */
import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";



const initialState = {
    error: true,
    msg: "",
    getSupplierDriversList:[]
}

const AdminSupplierDriverReducer = (state = initialState, action) =>{
    CustomConsole(action);
    switch (action.type) {
      case actionType.GET_SUPPLIER_DRIVER_LIST:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
          getSupplierDriversList: action.payload.data,
        };
        default:
            return state;
    }
}
export default AdminSupplierDriverReducer   

