import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TabPanel from "../../../Components/Panels/TabPanel";
import SupplierCompletedDeliveriesCard from "../../../Components/Cards/SupplierCompletedDeliveriesCard";
import moment from "moment";
const steps = ["Setup", "Slot and Route", "Assign Orders", "Confirm"];
const deliverydata = {
  order_id: "BTORD-28978",
  consumer_id: 1047,
  supplier_id: 394,
  order_type: 200,
  tanker_type: 3,
  tanker_capacity: 0,
  min_tds: 0,
  max_tds: 0,
  pincode: "603103",
  trips_per_day: 1,
  weekdays: ["tue"],
  start_date: "2024-03-11T00:00:00.000Z",
  end_date: "2024-03-12T00:00:00.000Z",
  total_trips: 1,
  trip_rate: "55.00",
  discount: "0.00",
  conv_fee: "0.00",
  gateway_fee: "0.00",
  final_price: "255.00",
  order_status: 200,
  payment_status: 0,
  delivery_status: 100,
  delivery_address: {
    address_id: 4792,
    contact_name: "megha",
    contact_phone: "8903392001",
    address_line1: "madipakkam",
    address_line2: "chennai",
    landmark: "",
    pincode: "603103",
    status: true,
    city: "",
    state: "",
    user_id: "C4Z3ePMA1",
    address_line3: "",
    delivery_timing_option: 200,
    is_primary: true,
    latitude: "13.0623626",
    longitude: "80.2658030",
    latlongaddress: "",
    service_area: "KELAMBAKKAM",
    custom_price: "0.00",
    discount: 0,
    updated_by: "2187",
    is_billing_address: false,
    address_type: "",
    country: "INDIA",
    products: [],
    updated_at: "2024-03-11T02:49:33.106Z",
    existing_cans: "0",
    returned_cans: "0",
  },
  payment_due: "2024-03-11T00:00:00.000Z",
  payment_type: 100,
  created_at: "2024-03-11T09:55:05.715Z",
  reject_reason_type: 0,
  order_terms: 0,
  payment_details: {
    transaction_type: 0,
    transaction_ts: "2024-03-11T09:55:03Z",
    transaction_status: 0,
    user_id: "C4Z3ePMA1",
    amount: 255,
    order_id: "BTORD-28978",
    payment_method: "WATERWALLET",
    payment_type: "PREPAID",
    transaction_meta: {
      p: {
        transaction_ts: "2024-03-11T09:55:03Z",
        amount: 255,
        order_id: "BTORD-28978",
        payment_method: "WATERWALLET",
        payment_type: "PREPAID",
      },
    },
    created_at: "2024-03-11T15:25:05.000Z",
    transaction_id: "BWOPS8555",
    payment_status: "SUCCESS",
  },
  reject_reason: "",
  cancel_reason_type: 0,
  cancel_reason: "",
  trip_rate_final: "55.00",
  consumer_name: "megha",
  supplier_name: "DevTestDist1",
  updated_at: "2024-03-11T09:55:05.715Z",
  booking_type: "",
  convfee_perc: "0.00",
  tanker_gstcode: "0",
  tanker_cgst_perc: "0.00",
  tanker_sgst_perc: "0.00",
  conv_gstcode: "0",
  conv_cgst_perc: "0.00",
  conv_sgst_perc: "0.00",
  gateway_gstcode: "0",
  gateway_cgst_perc: "0.00",
  gateway_sgst_perc: "0.00",
  tanker_rate: "55",
  tanker_discount: "0.00",
  tanker_id: "",
  payment_method: "WALLET",
  pipe_length: "0.00",
  pipe_length_price: "0.00",
  road_width: "0.00",
  latitude: "13.044",
  longitude: "80.266",
  paysharphandle: 0,
  postpaidrequest: 0,
  packaged_drinking_water: {
    fivehndml_capacity_amt: 0,
    fivehndml_capacity_qty: 0,
    fivehndml_capacity_totamt: 0,
    fiveltr_capacity_amt: 0,
    fiveltr_capacity_qty: 0,
    fiveltr_capacity_totamt: 0,
    oneltr_capacity_amt: 0,
    oneltr_capacity_qty: 0,
    oneltr_capacity_totamt: 0,
    twenty_ltr_capacity_amt: 55,
    twenty_ltr_capacity_totamt: "55.00",
    twentyltr_capacity_qty: 1,
    twohndml_capacity_amt: 0,
    twohndml_capacity_qty: 0,
    twohndml_capacity_totamt: 0,
    twoltr_capacity_amt: 0,
    twoltr_capacity_qty: 0,
    twoltr_capacity_totamt: 0,
    selected_container_capacity: "20_Liters",
    selected_container_material: "PET",
    empty_container_qty: 0,
    new_container_qty: 1,
    delivery_otp_status: 100,
    current_same_day_delivery_charge: 15,
    total_delivery_charges: 0,
    promo_label: "",
    promo_discount_price: 0,
    isWaterDropUsed: false,
    discount_price: 0,
    used_Water_Drops: 0,
    is_subscribed_order: false,
    delivery_otp: "",
    delivery_slots: {
      supplier_selected_delivery_slot: "",
      admin_selected_delivery_slot: "",
      consumer_selected_delivery_slot: "",
    },
    is_inscan_completed: true,
    is_order_started: false,
    is_outscan_completed: false,
    submitted_filled_cans: 0,
    received_empty_cans: 0,
    undiscounted_price: 55,
    order_type: 0,
    edited_by: "1537",
    edit_reason: "Mistake while placing order",
  },
  areaname: "KELAMBAKKAM",
  payment_time: "2024-03-11T09:55:05.728Z",
  is_referred: false,
  is_settled: false,
  updated_by: "C4Z3ePMA1",
  delivery_challan: "",
  products: [],
  country: "INDIA",
  delivery_id: 28033,
  driver_profile: {
    city: "",
    phone: "7871868811",
    state: "",
    pincode: "",
    badge_no: "",
    fullname: "agent Vinay007",
    badge_url: "",
    aadhaar_no: "",
    license_no: "",
    aadhaar_url: "",
    license_url: "",
    address_line1: "",
    address_line2: "",
    address_line3: "",
    badge_valid_to: "",
    profile_pic_url: "",
    badge_valid_from: "",
    license_valid_to: "",
    emergency_contact: "",
    licence_valid_from: "",
  },
};
const AssignTrip = ({ open, setOpen }) => {
  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const theme = useTheme();
  const [editTripDate, setEditTripDate] = useState(false);
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          Create Trip
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: "10px",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "nowrap",
              gap: 2,
            }}
          >
            <Stepper
              alternativeLabel
              activeStep={currentActiveStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => (
                <Step sx={{}} key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Stack>
        <Stack sx={{ width: "100%", pt: 3 }} spacing={4}>
          <TabPanel value={currentActiveStep} index={0} dir={theme.direction}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ display: "flex" }}
                >
                  {/* <InputLabel id="demo-multiple-name-label">
                    Trip Date
                  </InputLabel> */}
                  <Box>
                    <TextField
                      fullWidth
                      required
                      disabled={!editTripDate}
                      type={editTripDate ? "date" : "text"}
                      label="Trip Date"
                      // variant="filled"
                      value={moment().format("YYYY-MM-DD")}
                    />
                    <Box>
                      {" "}
                      Default Today{" "}
                      <Button onClick={() => setEditTripDate((prev) => !prev)}>
                        Edit?
                      </Button>
                    </Box>
                  </Box>
                </FormControl>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Delivery Slot
                  </InputLabel>
                  <Select
                    value={0}
                    name="Delivery Slot"
                    required
                    label="Select Delivery Slot"
                    fullWidth
                  >
                    <MenuItem value={0}>12:00-15:00</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  required
                  label="Route"
                  variant="outlined"
                  //   key={}
                  //   value={null}
                />
                <Box sx={{ width: "100%" }}>
                  <StepperNavigate
                    showPrev={false}
                    navigateTo={(toIndex) =>
                      setCurrentActiveStep((prev) => prev + toIndex)
                    }
                  />
                </Box>
              </Box>
            </form>
          </TabPanel>
          <TabPanel value={currentActiveStep} index={1} dir={theme.direction}>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                alert("dsd");
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Vehicle
                  </InputLabel>
                  <Select
                    value={0}
                    name="Vehicle"
                    required
                    label="Select Vehicle"
                    fullWidth
                  >
                    <MenuItem value={0}>Vehicle 1</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Select Driver
                  </InputLabel>
                  <Select
                    value={0}
                    name="Driver"
                    required
                    label="Select Driver"
                    fullWidth
                  >
                    <MenuItem value={0}>Agent Vinay007</MenuItem>
                    <MenuItem>Agent 2</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-multiple-checkbox-label">
                    Loaders
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={["Loader 1", "Loader 2"]}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                    }}
                    label="Loaders"
                    //   input={<OutlinedInput label="Loaders" />}
                    renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {["Loader 1", "Loader 2", "Loader 3"].map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                        //   checked={newValues?.usage?.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ width: "100%" }}>
                  <StepperNavigate
                    navigateTo={(toIndex) =>
                      setCurrentActiveStep((prev) => prev + toIndex)
                    }
                  />
                </Box>
              </Box>
            </form>
          </TabPanel>
          <TabPanel value={currentActiveStep} index={2} dir={theme.direction}>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                alert("dsd");
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      overflow: "auto",
                    }}
                  >
                    <SupplierCompletedDeliveriesCard
                      data={deliverydata}
                      key={1}
                      handleCopyAddress={() => {}}
                      handleLocation={() => {}}
                      handlePhone={() => {}}
                      handleViewDetails={() => {}}
                    />
                    <SupplierCompletedDeliveriesCard
                      data={deliverydata}
                      key={1}
                      handleCopyAddress={() => {}}
                      handleLocation={() => {}}
                      handlePhone={() => {}}
                      handleViewDetails={() => {}}
                    />
                  </Box>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-multiple-name-label">
                      Assign Orders
                    </InputLabel>
                    <Select
                      value={0}
                      name="Orders"
                      label="Assign Orders"
                      fullWidth
                    >
                      <MenuItem>BTORD-0001</MenuItem>
                      <MenuItem>BTORD-0002</MenuItem>
                      <MenuItem>BTORD-0003</MenuItem>
                    </Select>
                  </FormControl>
                  <StepperNavigate
                    navigateTo={(toIndex) =>
                      setCurrentActiveStep((prev) => prev + toIndex)
                    }
                  />
                </Box>
              </Box>
            </form>
          </TabPanel>
          <TabPanel value={currentActiveStep} index={3} dir={theme.direction}>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                setOpen(false);
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      overflow: "auto",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <Box>Vehicle : Eicher Blue Container</Box>
                      <Box>Driver : Agent Vinay(9747587985)</Box>
                      <Box>
                        Loaders : Loader1(5458789585), Loader 2(5878995585)
                      </Box>
                      <Box>
                        <Box>Required Products For the Delivery</Box>
                        <Box sx={{ pl: 5 }}>
                          <table border={2}>
                            <tr>
                              <td>20_Liters Pet</td>
                              <td>34</td>
                            </tr>
                            <tr>
                              <td>500_Ml Glass</td>
                              <td>30</td>
                            </tr>
                          </table>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ pt: 3 }}>Deliveries</Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <Box>
                        <SupplierCompletedDeliveriesCard
                          data={deliverydata}
                          key={1}
                          handleCopyAddress={() => {}}
                          handleLocation={() => {}}
                          handlePhone={() => {}}
                          handleViewDetails={() => {}}
                        />
                        <SupplierCompletedDeliveriesCard
                          data={deliverydata}
                          key={1}
                          handleCopyAddress={() => {}}
                          handleLocation={() => {}}
                          handlePhone={() => {}}
                          handleViewDetails={() => {}}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <StepperNavigate
                    showNext={false}
                    navigateTo={(toIndex) =>
                      setCurrentActiveStep((prev) => prev + toIndex)
                    }
                  />
                  <Box sx={{ float: "right" }}>
                    <Box sx={{ color: "red" }}>
                      *Slot and Agent Will be Re-Assigned for all orders
                    </Box>
                    <Button
                      sx={{ float: "right" }}
                      variant="contained"
                      type="submit"
                    >
                      Finish
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          </TabPanel>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AssignTrip;

const StepperNavigate = ({
  navigateTo,
  showNext = true,
  showPrev = true,
  buttonsAuto = false,
  currentStep,
}) => {
  return (
    <>
      {showPrev && (
        <Button onClick={() => navigateTo(-1)} sx={{ float: "left" }}>
          Prev
        </Button>
      )}
      {showNext && (
        <Button
          onClick={() => navigateTo(1)}
          sx={{ float: "right" }}
          type="submit"
        >
          Next
        </Button>
      )}
    </>
  );
};

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <AltRouteIcon />,
    3: <AssignmentIcon />,
    4: <AssignmentTurnedInIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
