import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { green, red, blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOngoingOrders,
  GetPendingAmount,
} from "../../../Pages/Redux/Actions/accountClosureActions";
import OngoingOrders from "./OngoingOrders";
import PaymentClearance from "./PaymentClearance";
import StockClearance from "./StockClearance";
import WaterDrops from "./WaterDrops";
import Complete from "./Complete";
import {
  GetProfileDetails,
  GetUserStockDetails,
} from "../../../Pages/Redux/Actions/userDetailsAdminAction";
import {
  GetUserWaterDrops,
  GetWalletTransactions,
} from "../../../Pages/Redux/Actions/transactionsActions";

// Dispatch setup
const AccountClosureCard = ({
  open,
  setOpen,
  user_id,
  handleDeleteUser,
  waterBalance,
}) => {
  const dispatch = useDispatch();

  // useSelector declarations
  const ongoingOrders = useSelector((state) => state.orders?.ongoingOrders);
  const pendingAmount = useSelector((state) => state.orders?.pendingAmount);
  const adminTransactionDetails = useSelector(
    (state) => state.adminTransactionDetails
  );
  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);
  const { userProfileData } = userDetailsUpdate || {};
  const { consumer } = userProfileData || {};
  const isB2C = consumer?.is_b2c;

  // State declarations
  const [activeStep, setActiveStep] = useState(0);
  const [stepColors, setStepColors] = useState([
    blue[500],
    blue[500],
    blue[500],
    blue[500],
    blue[500],
  ]);

  const steps = [
    { label: "Ongoing Orders" },
    { label: "Stock Clearance" },
    { label: "Water Drops" },
    { label: "Payment Clearance" },
    { label: "Complete" },
  ];

  // Functions
  const handleApiCalls = () => {
    dispatch(GetOngoingOrders({ id: user_id }));
    dispatch(GetUserStockDetails(user_id));
    dispatch(GetUserWaterDrops(user_id));
    dispatch(GetWalletTransactions(user_id));
    dispatch(GetPendingAmount({ id: user_id }));
    dispatch(GetProfileDetails(user_id));
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <OngoingOrders ongoingOrders={ongoingOrders} />;
      case 1:
        return <StockClearance stockData={userDetailsUpdate.userStockData} />;
      case 2:
        return (
          <WaterDrops
            waterDrops={adminTransactionDetails.waterDropsData?.water_drops}
            userId={user_id}
          />
        );
      case 3:
        return (
          <PaymentClearance userId={user_id} waterBalance={waterBalance} />
        );
      case 4:
        return (
          <Complete
            handleDeleteUser={handleDeleteUser}
            stepColors={stepColors}
            user_id={user_id}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const getStepIcon = (index) => {
    let icon = index + 1;
    let bgColor = stepColors[index];

    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          borderRadius: "50%",
          mt: "6.5px",
          backgroundColor: bgColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s ease, transform 0.3s ease",
          "&:hover": {
            backgroundColor: "purple",
            transform: "scale(1.2)",
          },
        }}
      >
        <Typography variant="caption" color="white">
          {icon}
        </Typography>
      </Box>
    );
  };

  const getPaymentType = () => {
    if (isB2C) {
      return "B2C Prepaid User";
    } else if (!isB2C && pendingAmount === 0) {
      return "B2B Prepaid User";
    } else if (!isB2C && pendingAmount > 0) {
      return "B2B Postpaid User";
    }
    return "";
  };

  // useEffects
  useEffect(() => {
    if (open) {
      handleApiCalls();
    }
  }, [open]);

  useEffect(() => {
    if (ongoingOrders) {
      setStepColors((prev) => {
        const newColors = [...prev];
        newColors[0] =
          ongoingOrders?.regular?.length === 0 &&
          ongoingOrders?.return?.length === 0 &&
          ongoingOrders?.replacement?.length === 0
            ? green[500]
            : red[500];
        return newColors;
      });
    }
  }, [ongoingOrders]);

  useEffect(() => {
    if (userDetailsUpdate?.userStockData) {
      const stockData = userDetailsUpdate.userStockData || [];
      const hasNonZeroStock = stockData.some(
        (item) =>
          item.filled_container_qty > 0 ||
          item.total_deposited_containers_qty > 0 ||
          item.total_undeposited_containers_qty > 0 ||
          parseFloat(item.current_deposit_amount) > 0
      );

      setStepColors((prev) => {
        const newColors = [...prev];
        newColors[1] = hasNonZeroStock ? red[500] : green[500];
        return newColors;
      });
    }
  }, [userDetailsUpdate]);

  useEffect(() => {
    if (adminTransactionDetails?.waterDropsData) {
      setStepColors((prev) => {
        const newColors = [...prev];
        newColors[2] =
          adminTransactionDetails.waterDropsData?.water_drops > 0
            ? red[500]
            : green[500];
        return newColors;
      });
    }
  }, [adminTransactionDetails]);

  useEffect(() => {
    if (
      pendingAmount !== undefined ||
      userDetailsUpdate?.walletAmount !== undefined
    ) {
      setStepColors((prev) => {
        const newColors = [...prev];
        const hasPendingAmount = pendingAmount > 0;
        const hasWalletAmount = userDetailsUpdate?.walletAmount > 0;
        const newColor =
          hasPendingAmount || hasWalletAmount ? red[500] : green[500];
        console.log(`Setting step 3 color to: ${newColor}`);
        newColors[3] = newColor;
        return newColors;
      });
    }
  }, [pendingAmount, userDetailsUpdate]);

  // JSX return
  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
      <DialogTitle
        variant="h4"
        sx={{
          fontSize: { xs: "1.2rem", sm: "1.2rem" },
          fontWeight: "bold",
          textAlign: "left",
          color: red[800],
          position: "relative",
          display: "flex",
          alignItems: "center",
          paddingRight: "56px",
        }}
      >
        <span>
          Account Closure
          <span
            style={{
              color: "grey",
              fontWeight: "bold",
              fontSize: "1rem",
              marginLeft: "8px",
            }}
          >
            {`- ${getPaymentType()}`}
          </span>
        </span>
        <IconButton
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
          onClick={handleApiCalls}
        >
          <RefreshIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          height: "500px",
          display: "flex",
          flexDirection: "column",
          p: 1,
          backgroundColor: "#f0f0f5",
          transition: "background-color 0.3s ease",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              overflowX: { xs: "auto", sm: "hidden" },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                width: { xs: "100%", sm: "calc(95% + 32px)" },
                "& .MuiStepConnector-root": {
                  "& .MuiStepConnector-line": {
                    borderColor: "darkgrey",
                  },
                },
                "& .MuiStepLabel-root:hover": {
                  cursor: "pointer",
                  color: "blue",
                },
              }}
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  onClick={() => handleStepClick(index)}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <StepLabel
                    StepIconComponent={() => getStepIcon(index)}
                    sx={{
                      color: index === activeStep ? "blue" : "inherit",
                      fontWeight: index === activeStep ? "bold" : "normal",
                      "& .MuiStepLabel-label": {
                        color: index === activeStep ? "blue" : "inherit",
                      },
                    }}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>

        <Box sx={{ width: "100%", flexGrow: 1, mt: 3, overflowY: "auto" }}>
          {getStepContent(activeStep)}
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleDialogClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountClosureCard;
