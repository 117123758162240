/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author
 * @description Add product dialog box
 * @date
 * @version
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { GetSupplierDeliveryAgentDetailsAdmin } from "../../Pages/Redux/Actions/supliersAction";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AssignDeliverAgentEmptyCan } from "../../Pages/Redux/Actions/skuWfsDetailsAction";
import { employeeType } from "../AppMeta/appMetaConfig";
import CustomConsole from "../../CustomConsole";

export default function AssignDeliveryAgentDialog({
  open,
  setOpen,
  selectedData,
}) {
  const dispatch = useDispatch();
  CustomConsole(selectedData);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const agentDetails = useSelector(
    (state) => state.supplierDeliveries.getSupplierDriverDataAdmin
  );
  const [driverName, setDriverNmae] = React.useState();
  const [driverId, setDriverId] = React.useState({
    delivery_agent_id: "",
  });
  //function for handling assign delivery agent
  const handleSelectedProduct = (e) => {
    CustomConsole(e.target.value);
    const userId = agentDetails.find((data) => data.user_id === e.target.value);
    CustomConsole(userId);
    setDriverId({ delivery_agent_id: userId.user_id });
    setDriverNmae(e.target.value);
  };

  //function for handelclose operation
  const handleClose = () => {
    setOpen(false);
  };

  //useEffect for getting delivery agent
  React.useEffect(() => {
    if (selectedData?.ordered_user_details?.supplier_id !== undefined) {
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          supplier_id: selectedData?.ordered_user_details?.supplier_id,
          employee_type: employeeType.DRIVER,
        })
      );
    }
  }, [selectedData]);

  //function for submitting assig n delivery agent
  const handelCrateSubmit = () => {
    dispatch(AssignDeliverAgentEmptyCan(selectedData?.ec_order_id, driverId));
    setOpen(false);
  };

  CustomConsole(agentDetails);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Assign Delivery Agent"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex" }}>
            {/* <TextField
              id="outlined-select-currency-native"
              select
              sx={{
                width: { sm: "100%", xs: "100%" },
                marginTop: ".6rem",
              }}
              size="small"
              value={driverName}
              onChange={handleSelectedProduct}
              SelectProps={{
                native: true,
              }}
            >
              <option value="" disabled>
                Select Agent
              </option>
              {agentDetails?.map((option) => (
                <option
                  key={option.driver_id}
                  value={option.driver_profile.fullname}
                >
                  {option.driver_profile.fullname}/
                  {option.driver_profile.emergency_contact}
                </option>
              ))}
            </TextField> */}
            <FormControl sx={{ width: "100%" }} size="small">
              <InputLabel id="demo-select-small-label">Select Agent</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={driverName}
                label="Select Address"
                onChange={handleSelectedProduct}
                sx={{
                  color: "black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                  },
                }}
              >
                {agentDetails?.map((option) => (
                  <MenuItem key={option.user_id} value={option.user_id}>
                    {option.driver_profile.fullname}/
                    {option.driver_profile.emergency_contact}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button onClick={handelCrateSubmit} size="small" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
