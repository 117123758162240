/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";
const initialProfileState = {
  error: true,
  msg: "",
};

const wfsDetailsReducer = (state = initialProfileState, action) => {
  // CustomConsole("----profile reducer----");
  // CustomConsole(action); 
  switch (action.type) {
    case actionType.GET_WFS_DETAILS:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        data: action.payload.data,
        stock:action.payload.stock,
      };

    default:
      return state;
  }
};

export default wfsDetailsReducer;
