import { useDispatch } from "react-redux";
import { GetDepositAndUsersWalletSummary } from "../../Pages/Redux/Actions/NewDashboardAction";
import { AvailablePincodes } from "../../Pages/Redux/Actions/pincodesAction";
import { useEffect, useState } from "react";
import { Autocomplete, Box, MenuItem, TextField } from "@mui/material";

const RegionFilters = ({
  selectedCountry = "INDIA",
  setSelectedCountry,

  setFilterhouseCity,
  setSelectedState,
  selectedState,
}) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState();
  const [districts, setDistricts] = useState();
  const getCountries = async () => {
    const all_available_countries = await AvailablePincodes({
      // pincode: value,
      page_number: 1,
      page_size: 100,
      get: "country",
    });
    setCountries(all_available_countries?.data);
    // setAllAvailableCountries(all_available_countries?.data);
  };
  const getStates = async (params) => {
    const all_states = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "state_name",
    });
    setStates(all_states?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };
  const getDistricts = async (params) => {
    const all_Districts = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "district",
    });
    setDistricts(all_Districts?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };
  useEffect(() => {
    getCountries();
    dispatch(GetDepositAndUsersWalletSummary());
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      getStates({ country: selectedCountry });
    }
    setSelectedState("")
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState && selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      getDistricts({
        country: selectedCountry,
        state: selectedState,
      });
    }
    setFilterhouseCity("")
  }, [selectedState]);
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {
        <Autocomplete
          sx={{ width: "150px", fontSize: "16px" }}
          value={selectedCountry}
          // options={countries?.map((item, index) => {
          //   return { name: item.name, code: item.code, id: item.id };
          // })}
          options={countries || []}
          autoHighlight
          getOptionLabel={(nameSearch) => `${selectedCountry} `}
          // defaultValue={countries[0]?.name}
          onChange={(_, newValue) => {
            setSelectedCountry(newValue?.country || ""); // Set the selected  as the value
          }}
          renderOption={(props, item) => {
            return (
              <MenuItem
                onClick={() => setSelectedCountry(item.country)}
                key={item}
                value={item.country}
              >
                {item.country}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={"Country"}
              variant="standard"
              inputProps={{
                ...params.inputProps,
                autoComplete: "country", // disable autocomplete and autofill
              }}
            />
          )}
        />
      }
      <Autocomplete
        sx={{ width: "150px", fontSize: "16px" }}
        // key={warehouseFilteredState}
        // id="country-select-demo"
        // sx={{ width: 210 }}
        value={selectedState}
        // options={countries?.map((item, index) => {
        //   return { name: item.name, code: item.code, id: item.id };
        // })}
        options={states || []}
        autoHighlight
        getOptionLabel={(nameSearch) =>
          !nameSearch
            ? ""
            : typeof nameSearch === "object"
            ? nameSearch?.state_name?.toUpperCase()
            : nameSearch
        }
        // defaultValue={countries[0]?.name}
        onChange={(_, newValue) => {
          setSelectedState(newValue?.state_name || null); // Set the selected  as the value
        }}
        renderOption={(props, item) => {
          return (
            <MenuItem
              onClick={() => setSelectedState(item.state_name)}
              key={item}
              value={item.state_name?.toUpperCase()}
            >
              {item.state_name?.toUpperCase()}
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            onCh
            {...params}
            size="small"
            label={"State"}
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: "state_name", // disable autocomplete and autofill
            }}
          />
        )}
      />
      {selectedState ? (
        <Autocomplete
          sx={{ width: "200px", fontSize: "16px" }}
          id="country-select-demo"
          // sx={{ width: "max-content" }}
          options={
            districts?.map((item, index) => {
              return { name: item.district };
            }) || []
          }
          // defaultValue={{ name: "Chennai", code: "TN05", id: 1 }}
          autoHighlight
          getOptionLabel={(nameSearch) => `${nameSearch?.name || ""} `}
          onChange={(_, newValue) => {
            setFilterhouseCity(newValue?.name || null); // Set the selected  as the value
          }}
          renderOption={(props, nameSearch) => (
            <Box
              sx={{ fontSize: "16px" }}
              component="li"
              // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {nameSearch.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              autoComplete={false}
              aria-autocomplete="none"
              {...params}
              size="small"
              label={"City"}
              variant="standard"
              inputProps={{
                ...params.inputProps,
                autoComplete: null, // disable autocomplete and autofill
              }}
            />
          )}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default RegionFilters;
