import React from "react";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Button,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import CustomConsole from "../../CustomConsole";

import PromoTableDialog from "./PromoTableDialog";
import FilterList from "@mui/icons-material/FilterList";
import FilterPromo from "./FilterPromo";
import AddPromoDialog from "./AddPromoDialog";
import { useDispatch, useSelector } from "react-redux";
import { GetSinglePromoCodedetailsAction } from "../Redux/Actions/promocodeAction";
import { GetAllPromoCodeListAction } from "../Redux/Actions/promocodeAction";
import { toast } from "react-toastify";
import { offertype } from "../../Components/AppMeta/appMetaConfig";

function PromoCodeTypeTable({
  setPromoExpired,
  promoExpired,
  promoCreatedAt,
  setPromoCreatedAt,
  setPromoCodeType,
  promoCodeTableDetails,
  handleEditupdateDialog,
  setPromoId,
  promoId,
  updateData,
  setUpdateData,
  updateClicked,
  setUpdateClicked,
  pageNumber,
  setPromoCreateRepeat,
  setAddPromoDialogTrig,
  filterDialogFlag,
  setFilterDialogFlag,
  promoCodeType,
  promoOfferType,
  setPromoOfferType,
}) {
  const [promoTableDialogFlag, setPromoTableDialogFlag] = React.useState(false);
  // const [filterDialogFlag, setFilterDialogFlag] = React.useState(false);

  // const [promoCreatedAt, setPromoCreatedAt] = React.useState("DESC");
  // const [promoExpired, setPromoExpired] = React.useState(true);
  const [addPromoDialogFlag, setAddPromoDialogFlag] = React.useState(false);

  // const [promoCodeType, setPromoCodeType] = React.useState(0);
  // const [promoOfferType, setPromoOfferType] = React.useState(0);
  const [promoAlldetailsFlag, setPromoAlldetailsFlag] = React.useState("");
  // const [pageNumber, setPageNumber] = React.useState(1);
  const [updatedreloadBtn, setUpdatedreloadBtn] = React.useState(false);

  CustomConsole(promoId);
  // const [promoCodeTableDetails, setPromoCodeTableDetails] = React.useState([]);

  const dispatch = useDispatch();
  const adminPromoCodeData = useSelector((state) => state.promocodeAdmin);
  CustomConsole(adminPromoCodeData.promAllDetails);

  React.useEffect(() => {
    CustomConsole(promoCodeTableDetails);
    promoCodeTableDetails.map((data) => {
      CustomConsole(data);
    });
  }, [promoCodeTableDetails]);
  React.useEffect(() => {
    CustomConsole(adminPromoCodeData);
  }, [adminPromoCodeData]);
  CustomConsole(adminPromoCodeData.promAllDetails);

  const handleFilterPromo = () => {
    setFilterDialogFlag(true);
  };

  const clickFilterPromoDetails = () => {
    dispatch(
      GetAllPromoCodeListAction(
        pageNumber - 1,
        promoExpired,
        promoCodeType,
        promoOfferType,
        promoCreatedAt
      )
    );

    // dispatch(
    //   GetAllPromoCodeListAction(
    //     pageNumber - 1,
    //     promoExpired,
    //     promoCodeType,
    //     promoOfferType,
    //     promoCreatedAt
    //   )
    // );
  };

  const clickPromoSingleDetails = (id, updateData) => {
    CustomConsole(updateData);
    setUpdateClicked(true);

    CustomConsole(id);
    dispatch(GetSinglePromoCodedetailsAction(id));

    setPromoId(id);
    handleEditupdateDialog();
  };
  const repeatActionHandle = (id) => {
    dispatch(GetSinglePromoCodedetailsAction(id));
    setPromoCreateRepeat(true);
    setAddPromoDialogTrig(true);
  };
  const offertypeTable = (promo_offer_type) => {
    switch (promo_offer_type) {
      case offertype.CASHBACK_WATER_DROP:
        return "CASHBACK WATER DROP";
      case offertype.PERCENTAGE_DISCOUNT:
        return "PERCENTAGE DISCOUNT";
      case offertype.PRICE_DISCOUNT:
        return "PRICE DISCOUNT";
      default:
        return "";
    }
  };

  React.useEffect(() => {
    CustomConsole("PageNumber");
    if (pageNumber > 1) {
      CustomConsole(pageNumber * 10 + 2 - 10);
    }
  }, [pageNumber]);

  return (
    <Box
      sx={{
        marginTop: "20px",
        // width: { md: "100%", xs: "350px" },
        maxWidth: { xs: 350, lg: 1, md: 1, sm: 1 },
        overflow: "auto",
      }}
    >
      {/* <Typography
        sx={{
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
          margin: "1px",
        }}
      >
        PROMO TYPES
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        sx={{ margin: "10px" }}
        onClick={handleFilterPromo}
      >
        Filter <FilterList />
      </Button> */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              align="center"
              // colSpan={6}
              sx={{
                minWidth: "600px",
                backgroundColor: "#007bb2",
                color: "black",
                fontSize: 20,
              }}
            >
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                S.no
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Promo Label
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Promo Disclaimer
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Offer Type
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                IsExpired
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {adminPromoCodeData.error === false ? (
            <>
              {" "}
              <TableBody>
                {adminPromoCodeData.promAllDetails.length > 0 ? (
                  <>
                    {" "}
                    {adminPromoCodeData.promAllDetails.map((data, key) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            marginTop: 20,
                            cursor: "pointer",
                            "&:hover": {
                              boxShadow: "none",
                              backgroundColor: data.is_expired ? "" : "#BECAE0",
                            },
                          }}
                          onClick={() =>
                            !data.is_expired
                              ? clickPromoSingleDetails(data.promo_master_id)
                              : null
                          }
                        >
                          <TableCell component="th" scope="row">
                            {pageNumber > 1
                              ? pageNumber * 10 + (key + 1) - 10
                              : key + 1}
                          </TableCell>
                          <TableCell>{data.promo_label}</TableCell>
                          <TableCell>{data.promo_disclaimer}</TableCell>
                          <TableCell>
                            {offertypeTable(data.promo_offer_type)}
                          </TableCell>
                          <TableCell>
                            {data.is_expired === true ? "YES" : "NO"}
                          </TableCell>
                          <TableCell>
                            {data.is_expired === true ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() =>
                                  repeatActionHandle(data.promo_master_id)
                                }
                              >
                                Repeat Promo
                              </Button>
                            ) : (
                              "NO"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <Box>
                    <Typography sx={{ alignItems: "center" }}>
                      No Data Available
                    </Typography>
                  </Box>
                )}
              </TableBody>
            </>
          ) : (
            <span
              style={{ color: "red", fontWeight: "bold", marginTop: "20px" }}
            >
              No More Promocodes Left!!!
            </span>
          )}
        </Table>
      </TableContainer>

      <FilterPromo
        filterDialogFlag={filterDialogFlag}
        setFilterDialogFlag={setFilterDialogFlag}
        setPromoCreatedAt={setPromoCreatedAt}
        setPromoExpired={setPromoExpired}
        clickFilterPromoDetails={clickFilterPromoDetails}
        promoCodeType={promoCodeType}
        setPromoCodeType={setPromoCodeType}
        promoOfferType={promoOfferType}
        setPromoOfferType={setPromoOfferType}
        promoCreatedAt={promoCreatedAt}
        promoExpired={promoExpired}
      />
    </Box>
  );
}

export default PromoCodeTypeTable;
