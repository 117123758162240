import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import OrderButtonSku from "../../Components/Buttons/orderButton";

function EditReturnCanOrderDialog(props) {
  const [editReturn, setEditReturn] = React.useState({ return_qty: 0 });
  const handleCloseReturnDialog = () => {
    props.handleCloseReturnDialog();
  };
  const handleSubmitReturnDialog = () => {
    props.handleSubmitReturnDialog({
      qty: editReturn.return_qty,
      close: props.setOpenDialogReturnCanSingleOrders,
      is_consumer: props.IsConsumer,
    });
  };
  React.useEffect(() => {
    if (props.containerQty) {
      setEditReturn(() => ({
        return_qty: parseInt(props.containerQty),
      }));
    }
  }, [props.containerQty]);
  return (
    <Dialog open={props.openEditReturnCanDialog}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "20px" }}>
          <Button
            size="small"
            variant="contained"
            color="error"
            sx={{ maxHeight: "25px", minWidth: "14px" }}
            onClick={handleCloseReturnDialog}
          >
            <Close />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ textAlign: "start" }}>
            Quantity : -&nbsp;&nbsp;
          </Typography>
          <OrderButtonSku
            handelAddQuantity={() =>
              setEditReturn((prev) => ({
                ...prev,
                return_qty: parseInt(editReturn.return_qty + 1),
              }))
            }
            handelMinusQuantity={() =>
              setEditReturn((prev) => ({
                ...prev,
                return_qty: parseInt(
                  editReturn.return_qty > 0 ? editReturn.return_qty - 1 : 0
                ),
              }))
            }
            handelQuantityChange={(event) =>
              setEditReturn((prev) => ({
                ...prev,
                return_qty:
                  event.target.value >= 0 && event.target.value <= 999
                    ? parseInt(event.target.value || 0)
                    : parseInt(prev.return_qty || 0),
              }))
            }
            inputValue={parseInt(editReturn.return_qty)}
            // disabled={false}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={handleSubmitReturnDialog}
          >
            submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditReturnCanOrderDialog;
