import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddDistributor,
  AppSettings,
  GetDistributorDetails,
  UpdateAppSettings,
} from "../Redux/Actions/appSettingsAction";
import AddDistributorCard from "./AddDistributorCard";
import DistributorDetailsCard from "./DistributorDetailsCard";
import PwdSettingsCard from "./PwdSettingsCard";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssignedAreasCard from "./AssignedAreasCard";
import AssignDeliveryArea from "./AssignDeliveryArea";
import CsaRateforAll from "./CsaRateforAll";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import CustomConsole from "../../CustomConsole";
// 9345945087
export default function AdminDistributors() {
  const dispatch = useDispatch();
  const appSettingsDetails = useSelector((state) => state.appSettingsDetails);
  const [openAlert, setOpenAlert] = React.useState(false);

  const [twntyLtrMin, setTwntyLtrMin] = React.useState(0);
  const [twntyLtrMax, setTwntyLtrMax] = React.useState(0);
  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [distributorPhoneNum, setDistributorPhoneNum] = React.useState("");

  //  const [demo,setDemo] = React.useState(0);

  //to store the role id
  const [adminRole, setAdminRoleId] = React.useState("");

  const [pwdPincodeAreas, setPwdPincodeAreas] = React.useState([]);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const scrollDownToDetailsCard = React.useRef(null);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  React.useEffect(() => {
    dispatch(AppSettings());
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  React.useEffect(() => {
    CustomConsole(updateFlag);
    if (updateFlag) {
      let intervel = setInterval(() => {
        dispatch(AppSettings());
      }, 1000);
      return () => clearInterval(intervel);
    }
  }, [appSettingsDetails]);

  React.useEffect(() => {
    CustomConsole(appSettingsDetails);
    if (Object.keys(appSettingsDetails.admin_settings).length) {
      setTwntyLtrMin(appSettingsDetails.admin_settings.twenty_ltr_min);
      CustomConsole("---Look----");
      CustomConsole(appSettingsDetails.admin_settings.twenty_ltr_min);
      setTwntyLtrMax(appSettingsDetails.admin_settings.twenty_ltr_max);
    }
    if (appSettingsDetails.pdw_pincodes_areanames.length) {
      // CustomConsole(appSettingsDetails.pdw_pincodes_areanames);
      appSettingsDetails.pdw_pincodes_areanames.map((data) => {
        CustomConsole(data);
      });
      setPwdPincodeAreas(appSettingsDetails.pdw_pincodes_areanames);
      // setShowPwdPincodeAreas(true);
    }
    if (appSettingsDetails.error === false && updateFlag === true) {
      toast.success(appSettingsDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateFlag(false);
      dispatch(AppSettings());
    }
  }, [appSettingsDetails]);

  CustomConsole("Run...");

  const handleUpdateSettings = (reqObj) => {
    if (reqObj.twenty_ltr_min < reqObj.twenty_ltr_max) {
      dispatch(UpdateAppSettings(reqObj));
      setUpdateFlag(true);
    }
    if (reqObj.twenty_ltr_min > reqObj.twenty_ltr_max) {
      toast.error("Min value should be lesser than max value", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    CustomConsole(reqObj);
  };

  const handleAddDistributor = (reqObj) => {
    CustomConsole(reqObj);
    dispatch(AddDistributor(reqObj));
  };

  const handleViewDistrubutorDetails = (phone) => {
    CustomConsole(phone);
    dispatch(GetDistributorDetails(phone));
    CustomConsole("---triggered---");
    setTimeout(() => {
      scrollDownToDetailsCard.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  return (
    <Box
      sx={{
        marginTop: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "20px",
      }}
    >
      {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
      ADMIN_ROLES.BW_ADMIN === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
ADMIN_ROLES.BW_CSLEAD === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ||
      ADMIN_ROLES.BW_CS === adminRole ||
      ADMIN_ROLES.BW_SALES === adminRole ||
      ADMIN_ROLES.BW_FINANCE === adminRole ||
      ADMIN_ROLES.BW_FINANCEHEAD === adminRole ||
      ADMIN_ROLES.BW_OPS === adminRole ||
      ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              gap: "20px",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* add distributor card */}

            {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
            ADMIN_ROLES.BW_ADMIN === adminRole ||
            ADMIN_ROLES.BW_CSHEAD === adminRole ||
            ADMIN_ROLES.BW_SALESHEAD === adminRole ? (
              <AddDistributorCard handleAddDistributor={handleAddDistributor} />
            ) : null}

            {/* views distributor details card */}

            {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
            ADMIN_ROLES.BW_ADMIN === adminRole ||
            ADMIN_ROLES.BW_CSHEAD === adminRole ||
            ADMIN_ROLES.BW_SALESHEAD === adminRole ||
            ADMIN_ROLES.BW_CS === adminRole ||
            ADMIN_ROLES.BW_SALES === adminRole ||
            ADMIN_ROLES.BW_FINANCE === adminRole ||
            ADMIN_ROLES.BW_FINANCEHEAD === adminRole ||
            ADMIN_ROLES.BW_OPS === adminRole ||
            ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
              <DistributorDetailsCard
                handleViewDistrubutorDetails={handleViewDistrubutorDetails}
                setDistributorPhoneNum={setDistributorPhoneNum}
              />
            ) : null}

            {openAlert ? (
              <>
                <Snackbar
                  open={openAlert}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Uploaded Successfully!
                  </Alert>
                </Snackbar>
              </>
            ) : (
              ""
            )}
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{
              marginTop: "8px",
            }}
            spacing={2}
          >
            <Grid item md={6}>
              {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
              ADMIN_ROLES.BW_ADMIN === adminRole ||
              ADMIN_ROLES.BW_CSHEAD === adminRole ||
              ADMIN_ROLES.BW_SALESHEAD === adminRole ? (
                <CsaRateforAll />
              ) : null}
            </Grid>
          </Grid>
          {/* 9642266267 */}
          {/* {appSettingsDetails.pdw_pincodes_areanames.length ? ( */}
          {true ? (
            <>
              {distributorPhoneNum &&
              (ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                ADMIN_ROLES.BW_ADMIN === adminRole ||
                ADMIN_ROLES.BW_CSHEAD === adminRole ||
                ADMIN_ROLES.BW_SALESHEAD === adminRole) ? (
                <AssignDeliveryArea
                  scrollDown={scrollDownToDetailsCard}
                  distriNum={distributorPhoneNum}
                />
              ) : null}

              {distributorPhoneNum &&
              (ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                ADMIN_ROLES.BW_ADMIN === adminRole ||
                ADMIN_ROLES.BW_CSHEAD === adminRole ||
                ADMIN_ROLES.BW_SALESHEAD === adminRole ||
                ADMIN_ROLES.BW_CS === adminRole ||
                ADMIN_ROLES.BW_SALES === adminRole ||
                ADMIN_ROLES.BW_FINANCE === adminRole ||
                ADMIN_ROLES.BW_FINANCEHEAD === adminRole ||
                ADMIN_ROLES.BW_OPS === adminRole ||
                ADMIN_ROLES.BW_OPSHEAD === adminRole) ? (
                <AssignedAreasCard
                  distriNum={distributorPhoneNum}
                  pwdPincodeAreas={pwdPincodeAreas}
                />
              ) : null}
            </>
          ) : null}
        </Box>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Box>
  );
}
