import React from "react";
import * as Material from "@mui/material";
import { Close } from "@mui/icons-material";
import bgImage from "./../../Components/Assests/Images/bw_bg.svg";
import { Paper } from "@material-ui/core";
import moment from "moment";
import CustomConsole from "../../CustomConsole";

function CrateQrBarCodeDetailsDialog(props) {
  CustomConsole(props);
  const [ownerDetails, setOwnerDetails] = React.useState([]);
  CustomConsole(ownerDetails);
  const [crateDetails, setCrateDetails] = React.useState([]);
  const [linkedByUserdetailsDetails, setLinkedByUserdetailsDetails] =
    React.useState([]);
  const [productsDataDetails, setProductsDataDetails] = React.useState([]);
  const [productCrateDetails, setProductCrateDetails] = React.useState([]);

  React.useEffect(() => {
    CustomConsole(props.data?.[0]);
    CustomConsole("------------------------------");

    if (props.data[0]?.owner_details) {
      CustomConsole("-------------owner_details-----------------");
      CustomConsole(props.data[0]?.owner_details);
      const ownerData = [
        {
          key: "Name",
          value: props.data[0]?.owner_details?.fullname,
        },
        {
          key: "Phone number",
          value: props.data[0]?.owner_details?.phone
            ? props.data[0]?.owner_details?.phone
            : "-",
        },
        {
          key: "Wfs Name",
          value: props.data[0]?.owner_details?.wfs_name
            ? props.data[0]?.owner_details?.wfs_name
            : "-",
        },
      ];
      CustomConsole("ownerData: ");
      CustomConsole(ownerData);
      setOwnerDetails(ownerData);
    }
    if (props.data[0]?.crate_details) {
      const cratedetails = [
        {
          key: "Crate Id",
          value: props.data[0]?.crate_id,
        },
        {
          key: "Name",
          value: props.data[0]?.crate_details?.name,
        },
        {
          key: "Phone number",
          value: props.data[0]?.crate_details?.phone
            ? props.data[0]?.crate_details?.phone
            : "-",
        },
        {
          key: "Price",
          value: props.data[0]?.crate_details?.price
            ? props.data[0]?.crate_details?.price
            : "-",
        },
        {
          key: "Capacity",
          value: props.data[0]?.crate_details?.capacity
            ? props.data[0]?.crate_details?.capacity
            : "-",
        },
        {
          key: "Description",
          value: props.data[0]?.crate_details?.description
            ? props.data[0]?.crate_details?.description
            : "-",
        },
        {
          key: "Created At",
          value: props.data[0]?.created_at
            ? moment(props.data[0]?.created_at)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD HH:MM")
            : "-",
        },
        {
          key: "Updated At",
          value: props.data[0]?.updated_at
            ? moment(props.data[0]?.updated_at)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD HH:MM")
            : "-",
        },
      ];
      CustomConsole("cratedetails: ");
      CustomConsole(cratedetails);
      setCrateDetails(cratedetails);
    }
    if (props.data[0]?.linked_by_user) {
      const linkedByUserdetails = [
        {
          key: "Name",
          value: props.data[0]?.linked_by_user?.fullname,
        },
        {
          key: "Phone number",
          value: props.data[0]?.linked_by_user?.phone
            ? props.data[0]?.linked_by_user?.phone
            : "-",
        },
      ];
      CustomConsole("linkedByUserdetails: ");
      CustomConsole(linkedByUserdetails);
      setLinkedByUserdetailsDetails(linkedByUserdetails);
    }
    if (props.data[0]?.product_details) {
      const productsData = [
        {
          key: "qr Id",
          value: props.data[0].product_details?.qr_id,
        },
        {
          key: "Qr Code",
          value: props.data[0].product_details?.qr_code,
        },
        {
          key: "No of Times Used",
          value: props.data[0].product_details?.no_of_times_used,
        },
        {
          key: "Container Capacity",
          value: props.data[0].product_details?.container_details?.capacity,
        },
        {
          key: "Container Material",
          value: props.data[0].product_details?.container_details?.material,
        },
        {
          key: "Container Life Span",
          value: props.data[0].product_details?.container_details?.life_span,
        },
      ];
      const productCrateDetails = [
        {
          key: "Product Crate Name",
          value: props.data[0].product_details[0].crate_details.name,
        },
        {
          key: "Product Crate Name",
          value: props.data[0].product_details[0].crate_details.capacity,
        },
      ];
      setProductCrateDetails(productCrateDetails);
      setProductsDataDetails(productsData);
    }
    //Owner is WFS
  }, [props]);

  const handleClose = () => {
    props.handleClose();
  };
  return (
    <Material.Dialog fullScreen open={props.open}>
      <Material.AppBar
        sx={{ position: "relative", backgroundColor: "#1b185c" }}
      >
        <Material.Toolbar>
          <Material.IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </Material.IconButton>
          <Material.Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
          >
            Crate Details
          </Material.Typography>
          <Material.Button
            variant="contained"
            autoFocus
            color="inherit"
            sx={{ color: "red" }}
            onClick={handleClose}
          >
            Close
          </Material.Button>
        </Material.Toolbar>
      </Material.AppBar>
      <Material.DialogContent
        sx={{
          backgroundImage: `url(${bgImage})`,
          //   height: "100vH",
          //   width: "100vW",
          //   alignItems: "center",
          //   padding: "15px",
          gap: "20px",
        }}
      >
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: 2,
                borderColor: "gray",
                padding: "15px",
                marginTop: "20px",
                width: { xs: "80vW", md: "100%" },
              }}
            >
              <Material.Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  textAlign: "center",
                  m: 1,
                  textDecoration: "underline",
                }}
              >
                Owner Details:
              </Material.Typography>
              {ownerDetails.map((data, index) => {
                return (
                  <Material.Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: "14", md: "16px" },
                        fontWeight: 500,
                        // marginRight: "15px",
                        textAlign: "left",
                        m: 1,
                      }}
                    >
                      {data.key}:
                    </Material.Typography>
                    <Material.Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: "12", md: "14px" },
                        fontWeight: 400,
                        textAlign: "right",
                        m: 1,
                      }}
                    >
                      {data.value}
                    </Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Box>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: 2,
                borderColor: "gray",
                padding: "15px",
                marginTop: "20px",
                width: { xs: "80vW", md: "100%" },
              }}
            >
              <Material.Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  textAlign: "center",
                  m: 1,
                  textDecoration: "underline",
                }}
              >
                Crate Details:
              </Material.Typography>
              {crateDetails.map((data, index) => {
                return (
                  <Material.Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: "14", md: "16px" },
                        fontWeight: 500,
                        // marginRight: "15px",
                        textAlign: "left",
                        m: 1,
                      }}
                    >
                      {data.key}:
                    </Material.Typography>
                    <Material.Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: "12", md: "14px" },
                        fontWeight: 400,
                        textAlign: "right",
                        m: 1,
                      }}
                    >
                      {data.value}
                    </Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Box>
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: 2,
                borderColor: "gray",
                padding: "15px",
                marginTop: "20px",
                width: { xs: "80vW", md: "100%" },
              }}
            >
              <Material.Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  textAlign: "center",
                  m: 1,
                  textDecoration: "underline",
                }}
              >
                Link by User Details:
              </Material.Typography>
              {linkedByUserdetailsDetails.map((data, index) => {
                return (
                  <Material.Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: "14", md: "16px" },
                        fontWeight: 500,
                        // marginRight: "15px",
                        textAlign: "left",
                        m: 1,
                      }}
                    >
                      {data.key}:
                    </Material.Typography>
                    <Material.Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: "12", md: "14px" },
                        fontWeight: 400,
                        textAlign: "right",
                        m: 1,
                      }}
                    >
                      {data.value}
                    </Material.Typography>
                  </Material.Box>
                );
              })}
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box
          sx={{
            border: 2,
            borderColor: "gray",
            padding: "15px",
            marginTop: "20px",
          }}
        >
          <Material.Typography
            variant="h5"
            sx={{
              color: "white",
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: 500,
              textAlign: "center",
              m: 1,
              textDecoration: "underline",
            }}
          >
            Product Details:
          </Material.Typography>
          <Material.TableContainer sx={{ maxHeight: 440 }}>
            <Material.Table>
              <Material.TableHead sx={{ bgcolor: "#6495ED" }}>
                <Material.TableRow>
                  <Material.TableCell sx={{ color: "white" }}>
                    Qr Code
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    No.of Times Used
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Capacity
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Material
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Life Span
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Filled Wfs Company
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Filled Wfs Owner Name
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Filled Wfs Owner Phone
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Filled Wfs Owner Pincode
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Water Quality Mftg Date
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Water Quality Expiry Date
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Water Quality TDS
                  </Material.TableCell>
                  <Material.TableCell sx={{ color: "white" }}>
                    Water Quality PH
                  </Material.TableCell>
                </Material.TableRow>
              </Material.TableHead>
              <Material.TableBody>
                {props.data[0]?.product_details !== undefined &&
                props.data[0]?.product_details !== null &&
                props.data[0]?.product_details.length > 0 ? (
                  <>
                    {props.data[0]?.product_details?.map((data) => {
                      return (
                        <Material.TableRow>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.qr_code}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.no_of_times_used}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.container_details?.capacity}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.container_details?.material}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.container_details?.life_span}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.filled_wfs_details?.company_name}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.filled_wfs_details?.owner_name}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.filled_wfs_details?.owner_phone}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.filled_wfs_details?.pincode}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.quality_details?.mft_date}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.quality_details?.exp_date}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.quality_details?.tds}
                          </Material.TableCell>
                          <Material.TableCell sx={{ color: "#CDE3FC" }}>
                            {data.quality_details?.ph}
                          </Material.TableCell>
                        </Material.TableRow>
                      );
                    })}
                  </>
                ) : (
                  <Material.Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    No Products Available
                  </Material.Typography>
                )}
              </Material.TableBody>
            </Material.Table>
          </Material.TableContainer>
        </Material.Box>
        {/* <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: 2,
              borderColor: "gray",
              padding: "15px",
              marginTop: "20px",
              width: { xs: "80vW", md: "50%" },
            }}
          >
            <Material.Typography
              variant="h5"
              sx={{
                color: "white",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 500,
                textAlign: "center",
                m: 1,
              }}
            >
              Product Details:
            </Material.Typography>
            {props.data[0]?.product_details?.map((data, index) => {
              return (
                <>
                  <Material.Paper
                    sx={{
                      backgroundImage: `url(${bgImage})`,
                      gap: "10px",
                      border: "1px solid white",
                    }}
                  >
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Qr Id:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.qr_id}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Qr Code:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.qr_code}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        No.of Times Used :
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.no_of_times_used}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Container Capacity:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.container_details?.capacity}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Container Material:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.container_details?.material}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Container Life Span:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.container_details?.life_span}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Filled Wfs Company:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.filled_wfs_details?.company_name}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Filled Wfs Owner Name:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.filled_wfs_details?.owner_name}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Filled Wfs Owner Phone:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.filled_wfs_details?.owner_phone}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Filled Wfs Owner Pincode:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.filled_wfs_details?.pincode}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Water Quality Mftg Date:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.quality_details?.mft_date}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Water Quality Expiry Date:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.quality_details?.exp_date}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Water Quality TDS:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.quality_details?.tds}
                      </Material.Typography>
                    </Material.Box>
                    <Material.Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "14", md: "16px" },
                          fontWeight: 500,
                          // marginRight: "15px",
                          textAlign: "left",
                          m: 1,
                        }}
                      >
                        Water Quality PH:
                      </Material.Typography>
                      <Material.Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: "12", md: "14px" },
                          fontWeight: 400,
                          textAlign: "right",
                          m: 1,
                        }}
                      >
                        {data.quality_details?.ph}
                      </Material.Typography>
                    </Material.Box>
                  </Material.Paper>
                </>
              );
            })}
          </Material.Box>
        </Material.Box> */}
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default CrateQrBarCodeDetailsDialog;
