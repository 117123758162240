/**
 * @author vinay kumar
 * @description Deliveries Main component
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 19-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import { Box } from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  GetSupplierReplacementCanOrders,
  GetSupplierReturnCanOrders,
} from "../Redux/Actions/supliersAction";
import { useMediaQuery, useTheme } from "@mui/material";
import DeliveriesFilterDialog from "../../Components/DialogBoxes/SupplierDialogBox/DeliveriesFilterDialog";
import CurrentDayDeliveiesNew from "./CurrentDayDeliveiesNew";
import ReturnCanOrders from "../SupplierOrders/ReturnCanOrders";
import ReplacementCansOrders from "../SupplierOrders/ReplacementCansOrders";
import BuyBackOrderDetailsMain from "./BuyBackOrderViewMain";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
/**
 * @author vinay kumar
 * @description Showing Current Day Deliveries & Next Day Delivers & Completed Deliveries in App Bar
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 19-06-2023
 */

function DeliveriesMain() {
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  console.log(supplierDeliveries?.getSupplerReturnCanOrders?.length);

  CustomConsole(supplierDeliveries);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [searchOrderId, setSearchOrderId] = React.useState("BTORD");
  const [storeTodayDeliveries, setStoreTodayDeliveries] = React.useState([]);
  const [storePreviousDeliveried, setStorePreviousDeliveried] = React.useState(
    []
  );
  const [returnOrderCount, setReturnOrderCount] = React.useState(0);
  const [replacementOrderCount, setReplacementOrderCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);

  const [storeCompletedDeliveries, setStoreCompletedDeliveries] =
    React.useState([]);
  const [adminRole, setAdminRoleId] = React.useState("");

  // Use useMediaQuery to check the screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  CustomConsole(searchOrderId);
  CustomConsole(storeTodayDeliveries);
  CustomConsole(storePreviousDeliveried);
  CustomConsole(storeCompletedDeliveries);

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
    if (value === 1) {
    dispatch(
      GetSupplierReturnCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
      })
    ).then((data) => {
      setReturnOrderCount(data?.data?.length);
    });
    } else if (value === 2) {
    dispatch(
      GetSupplierReplacementCanOrders({
        page_number: pageNumber,
        page_size: pageSize,
      })
    ).then((data) => {
      setReplacementOrderCount(data?.data?.length || 0);
    });
    }
  }, [value]);

  // React.useEffect(() => {
  //   setReturnOrderCount(supplierDeliveries?.getSupplerReturnCanOrders?.length);
  //   setReplacementOrderCount(
  //     supplierDeliveries?.getSupplierReplacementCanOrders?.length
  //   );
  // }, [supplierDeliveries]);
  React.useState(() => {
    setStoreTodayDeliveries(supplierDeliveries.getCurrentDayDeliveries);
    setStorePreviousDeliveried(
      supplierDeliveries.getSupplierPreviuosOngoingDeliveries
    );
    setStoreCompletedDeliveries(supplierDeliveries.getCompletedDeliveries);
  }, [supplierDeliveries]);

  React.useEffect(() => {
    if (
      supplierDeliveries !== undefined &&
      supplierDeliveries.getCurrentDayDeliveries !== undefined &&
      supplierDeliveries.getCurrentDayDeliveries.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
    if (
      supplierDeliveries !== undefined &&
      supplierDeliveries.getCompletedDeliveries !== undefined &&
      supplierDeliveries.getCompletedDeliveries.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  }, [supplierDeliveries]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClosefilterDialog = (props) => {
    CustomConsole("--------handleClosefilterDialog---------");
    CustomConsole(props);
    setFilterDialogOpen(false);
  };

  const handleApplyFilter = (props) => {
    // dispatch(
    //   CurrentDayDeliveries({
    //     pageNumber: pageNumber - 1,
    //     is_today: true,
    //     pageSize: pageSize,
    //     agent_assigned: props.agent_assigned || undefined,
    //     phone: props.phone || undefined,
    //     pincode: props.enter_Pincode || undefined,
    //     booking_time: props.select_Booking_Time || undefined,
    //     delivery_slot_sort: props.select_Delivery_slot || undefined,
    //     order_type: props.order_Type || undefined,
    //     delivery_agent_id: props.delivery_agent_id || undefined,
    //   })
    // );
    setFilterDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ marginTop: "50px" }}>
        <Box sx={{ bgcolor: "background.paper" }}>
          <AppBar
            position="static"
            sx={{
              color: "black",
              borderRadius: "100px",
              // background: appColors.commonLayoutColor.background,
              // backgroundImage: appColors.commonLayoutColor.backgroundImage,
              background: "#CCE1F9",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              // variant="fullWidth"
              variant={isSmallScreen ? "scrollable" : "fullWidth"}
              aria-label="full width tabs example"
              TabIndicatorProps={{ style: { display: "none" } }} // Hide the bottom border
              sx={{ padding: "10px" }}
            >
              <Tab
                label="Regular"
                {...a11yProps(0)}
                sx={{
                  ...(value === 0 && {
                    bgcolor: "#92C0F8", // Change color when active
                    color: "black",
                  }),
                  borderRadius: 10,
                }}
              />

              <Tab
                // label={
                //   <Material.Badge
                //     badgeContent={
                //       supplierDeliveries?.getSupplerReturnCanOrders?.length
                //     }
                //     color="primary"
                //     sx={{
                //       "& .MuiBadge-badge": {
                //         backgroundColor: "#f50057", // Background color of the badge
                //         color: "#fff", // Text color of the badge content
                //         padding: "5px", // Padding around the badge content
                //         fontSize: "18px", // Font size of the badge content
                //         fontWeight: "bold", // Font weight of the badge content
                //         borderRadius: "50%", // Make it round
                //         marginBottom: 2,
                //       },
                //     }}
                //   >
                //     Return
                //   </Material.Badge>
                // }
                label={
                  <Box>
                    Return
                    <Typography sx={{ color: "red", fontWeight: "bold" }}>
                      {returnOrderCount > 0 ? returnOrderCount : 0}
                    </Typography>
                  </Box>
                }
                {...a11yProps(1)}
                sx={{
                  ...(value === 1 && {
                    bgcolor: "#92C0F8", // Change color when active
                    color: "black",
                  }),
                  borderRadius: 10,
                  paddingBottom: "0px",
                }}
              />

              <Tab
                // label={
                //   <Material.Badge
                //     badgeContent={12}
                //     color="primary"
                //     sx={{
                //       "& .MuiBadge-badge": {
                //         backgroundColor: "#f50057", // Background color of the badge
                //         color: "#fff", // Text color of the badge content
                //         padding: "8px", // Padding around the badge content
                //         fontSize: "18px", // Font size of the badge content
                //         fontWeight: "bold", // Font weight of the badge content
                //         borderRadius: "100%", // Make it round
                //         // marginBottom: 2,
                //         margin: "10px",
                //       },
                //     }}
                //   >
                //     Replacement
                //   </Material.Badge>
                // }
                label={
                  <Box>
                    Replacement
                    <Typography sx={{ color: "red", fontWeight: "bold" }}>
                      {replacementOrderCount > 0 ? replacementOrderCount : 0}
                    </Typography>
                  </Box>
                }
                {...a11yProps(2)}
                sx={{
                  ...(value === 2 && {
                    bgcolor: "#92C0F8", // Change color when active
                    color: "black",
                  }),
                  borderRadius: 7,
                  // padding: "25px",
                  paddingBottom: "0px",
                }}
              />

              <Tab
                label={
                  <Box>
                    Buy Back Order
                    <Typography sx={{ color: "red", fontWeight: "bold" }}>
                      {/* {Buy > 0 ? returnOrderCount : 0} */}
                    </Typography>
                  </Box>
                }
                {...a11yProps(3)}
                sx={{
                  ...(value === 3 && {
                    bgcolor: "#92C0F8", // Change color when active
                    color: "black",
                  }),
                  borderRadius: 10,
                  paddingBottom: "0px",
                }}
              />
            </Tabs>
          </AppBar>
          <Box sx={{ maxHeight: "640px", overflow: "auto" }}>
            {/* <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          > */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <CurrentDayDeliveiesNew />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <ReturnCanOrders />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <ReplacementCansOrders />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <BuyBackOrderDetailsMain />
            </TabPanel>
          </Box>
          {/* </SwipeableViews> */}
        </Box>
      </Box>

      <DeliveriesFilterDialog
        filterDialogOpen={filterDialogOpen}
        handleClose={handleClosefilterDialog}
        handleClickApply={handleApplyFilter}
        // handleSearch={handleSearchOrder}
        // orderid={orderid}
        agentListata={supplierDeliveries.getAssignDeliveryAgent}
        currentDayDeliveries={supplierDeliveries.getCurrentDayDeliveries}
      />
    </>
  );
}

export default DeliveriesMain;
