import React from "react";
import * as Material from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import CustomConsole from "../../CustomConsole";
import { Box, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { GetWfsOngoingBatchLineDetails } from "../Redux/Actions/wfsOnGoingBatchAction";
import { useDispatch } from "react-redux";
import { GetWfsOngoingBatch } from "../Redux/Actions/wfsOnGoingBatchAction";
import { toast } from "react-toastify";

function EditBatchDetailsDilaog(props) {
  CustomConsole(props);
  const selectCapacity = [{ value: "20_Liters" }, { value: "5_Liters" }];
  const selectMaterial = [
    { value: "PET" },
    { value: "PVC" },
    { value: "COPPER" },
    { value: "POLYCARBONATE" },
  ];
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lineId, setLineId] = React.useState("");
  const [batchQuantity, setBatchQuantity] = React.useState("");
  const [batchId, setBatchId] = React.useState("");
  const handlebatch = () => {
    let qty = parseInt(props.updateDetails.quantity) - batchQuantity;
    const payload = {
      batchId: batchId,

      material: material,
      capacity: capacity,
      lineId: lineId,
    };
    if (qty > 0) {
      payload.batchQuantity = qty;
    }
    props.handlebatch(payload);
  };
  const handleClose = () => {
    props.handleClose();
  };
  React.useEffect(() => {
    if (props.updateDetails) {
      setBatchId(props.updateDetails.batch_id);
      setBatchQuantity(props.updateDetails.quantity);
      setLineId(props.updateDetails.line_id);
      setMaterial(props.updateDetails.container_material);
      setCapacity(props.updateDetails.container_capacity);
    }
  }, [props.updateDetails]);

  const handleChangeBatchQty = (event) => {
    const value = parseInt(event.target.value);

    // Check if the entered value is a positive number
    if (value > 0) {
      setBatchQuantity(value);
    } else {
      setBatchQuantity("");
      toast.error("Please Edit More Than Zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <Box>
      <Dialog
        open={props.openEditBatchDialog}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "30px",
            },
          },
          background: "rgb(208,221,242)",
          backgroundImage:
            "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Edit Batch Details
          </Typography>
        </Box>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              padding: "10px",
            }}
          >
            <Box>
              <TextField
                // select
                disabled
                label="Select Capacity"
                // helperText="Please select your capacity"
                variant="outlined"
                value={batchId}
                onChange={(event) => setBatchId(event.target.value)}
              />
            </Box>
            <Box>
              <TextField
                // select
                disabled
                label="Select Capacity"
                // helperText="Please select your capacity"
                variant="outlined"
                value={capacity}
                onChange={(event) => setCapacity(event.target.value)}
              />
            </Box>

            <Box>
              <TextField
                // select
                disabled
                label="Select Material"
                // helperText="Please select your material"
                variant="outlined"
                value={material}
                onChange={(event) => setMaterial(event.target.value)}
              />
            </Box>
            <Box>
              {props.wfsBatchDetails.lineDetailsError === false ? (
                <TextField
                  select
                  label="Select Line ID"
                  helperText="Please select your line ID"
                  variant="outlined"
                  value={lineId}
                  onChange={(event) => setLineId(event.target.value)}
                >
                  {props.wfsBatchDetails?.lineBatchDetails.map((option) => (
                    <MenuItem value={option.line_id}>{option.line_id}</MenuItem>
                  ))}
                </TextField>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <TextField
                label="Enter batch quantity"
                // type="number"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                variant="outlined"
                value={batchQuantity}
                onChange={handleChangeBatchQty}
                // onInput={(e) => {
                //   e.target.value = Math.max(0, parseInt(e.target.value))
                //     .toString()
                //     .slice(0, 10);
                // }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                marginTop: "10px",
                gap: "20px",
              }}
            >
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                disabled={
                  !batchQuantity ||
                  batchQuantity < 1 ||
                  parseInt(props.updateDetails.quantity) - batchQuantity < 1
                }
                variant="contained"
                size="small"
                onClick={handlebatch}
              >
                edit batch
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default EditBatchDetailsDilaog;
