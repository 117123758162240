/**
 * @author vinay kumar
 * @description All can Orders
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 23-06-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import CustomConsole from "../../CustomConsole";
import * as Material from "@mui/material";
import moment from "moment";
import SupplierWFSTable from "../../Components/Tables/SupplierWFSTable";
import { useDispatch, useSelector } from "react-redux";
import { GetSupplierWfsAllOrders } from "../Redux/Actions/supliersAction";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import WfsAllOrderDetailsCard from "../../Components/Cards/WfsAllOrderDetailsCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GetWfsEmptyCanDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";

function AllOrders(props) {
  const history = useHistory();
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  // React.useEffect(() => {
  //   if (pageNumber) {
  //     dispatch(dispatch(GetWfsEmptyCanDetailsSku(props.selectedOrderType)));
  //     setPageBackWard(true);
  //     setPageForward(true);
  //   }
  // }, [pageNumber]);

  // React.useEffect(() => {
  //   if (
  //     skuWfsDetails.emptyCanDetails !== undefined &&
  //     skuWfsDetails.emptyCanDetails.length
  //   ) {
  //     setPageStatuss(false);
  //   } else {
  //     setPageStatuss(true);
  //   }
  // });
  // React.useEffect(() => {
  //   if (supplierDeliveries.getSupplierWfsAllOrders.length) {
  //     if (pageForward) {
  //       setPageNumber(() => pageNumber + 1);
  //     } else if (pageBackWard) {
  //       setPageNumber(() => pageNumber - 1);
  //     }
  //   }
  // }, [supplierDeliveries]);
  // React.useEffect(() => {
  //   if (pageSize) {
  //     dispatch(
  //       GetSupplierWfsAllOrders({
  //         pageNumber: pageNumber - 1,
  //         // pageSize: pageSize,
  //       })
  //     );
  //   }
  // }, [pageSize]);
  React.useEffect(() => {}, [pageForward, pageBackWard]);
  //Backward Arrow Button
  // const handleClickBack = () => {
  //   if (pageNumber - 1 >= 1) {
  //     setPageBackWard(true);
  //     setPageForward(false);
  //     // setPageNumber(pageNumber - 1);
  //     dispatch(
  //       GetSupplierWfsAllOrders({
  //         pageNumber: pageNumber - 1,
  //         pageSize: pageSize,
  //       })
  //     );
  //   }
  // };
  const handleClickBack = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setPageBackWard(true);
  };
  //Foewqrd Arrow Button

  // const handleClickForward = () => {
  //   if (pageNumber + 1 > 0) {
  //     setPageBackWard(false);
  //     setPageForward(true);
  //     dispatch(
  //       GetSupplierWfsAllOrders({
  //         pageNumber: pageNumber,
  //         pageSize: pageSize,
  //       })
  //     );
  //   }
  // };

  const handleClickForward = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
    setPageForward(true);
  };
  const handleBack = () => {
    const url = `/dashboard/deliveries`;
    history.push(url);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
  };
  // React.useEffect(() => {
  //   dispatch(GetSupplierWfsAllOrders({ pageNumber: 0 }));
  // }, []);
  return (
    <Material.Box
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      {skuWfsDetails.emptyCanDetails !== null &&
      skuWfsDetails.emptyCanDetails !== undefined &&
      skuWfsDetails.emptyCanDetails.length > 0 ? (
        <>
          {/* <SupplierWFSTable
          data={supplierDeliveries.getSupplierWfsAllOrders}
        /> */}
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              overflow: "auto",
              height: "420px",
            }}
          >
            <>
              {skuWfsDetails.emptyCanDetails.map((data, key) => {
                return (
                  <WfsAllOrderDetailsCard
                    key={key}
                    data={data}
                    OrderId={data.ec_order_id}
                    NewConatinerQty={data.order_details.order_qty}
                    EmptyCans={data.order_details.return_qty}
                    NoofDamagedCans={data.order_details.damaged_qty}
                    NoofExpiredCans={data.order_details.expired_qty}
                    OrderStatus={data.ec_order_status}
                    container_material={data.order_details.container_material}
                    container_capacity={data.order_details.container_capacity}
                    Otp={data.otp}
                    OrderAssignedTo={
                      data.assigned_wfs_details.wfs_incharge_name
                    }
                    Address={data.assigned_wfs_details.wfs_address}
                    OrderStarted={data.order_details.is_submit_started}
                    OrderFinished={data.order_details.is_submit_finished}
                    OrderCreatedAt={data.created_at}

                    // handleStart={handleStartSubmission}
                    //  handleStartSubmission={handleOpenStartSubmissionDialog}
                    // setPageNumbers={setPageNumbers}
                    // pageNumbers={pageNumbers}
                  />
                );
              })}
            </>
          </Material.Box>
          {/* <Material.Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px",
              }}
            >
              
              <Material.Button onClick={handleClickBack}>
                <ArrowBackIos /> Prev
              </Material.Button>
              <Material.Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Material.Typography>
              
              <Material.Button disabled={pageStatuss}  onClick={handleClickForward}>
                Next <ArrowForwardIos />
              </Material.Button>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box>
                <Material.Button variant="outlined" onClick={handleBack}>
                  {" "}
                  <Material.Typography sx={{ fontSize: "30px" }}>
                    🔙
                  </Material.Typography>
                </Material.Button>
              </Material.Box>
              <Material.Box>
                <Material.FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Material.InputLabel>Page size</Material.InputLabel>
                  <Material.Select
                    value={pageSize}
                    label="Page size"
                    onChange={handleChangePageSize}
                  >
                    <Material.MenuItem value={25}>25</Material.MenuItem>
                    <Material.MenuItem value={50}>50</Material.MenuItem>
                    <Material.MenuItem value={100}>100</Material.MenuItem>
                    <Material.MenuItem value={500}>500</Material.MenuItem>
                    <Material.MenuItem value={1000}>1000</Material.MenuItem>
                  </Material.Select>
                </Material.FormControl>
              </Material.Box>
            </Material.Box>
          </Material.Box> */}
        </>
      ) : (
        <Material.Typography>No All Orders Left</Material.Typography>
      )}
    </Material.Box>
  );
}

export default AllOrders;
