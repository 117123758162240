export const styles = {
    cardStyleHeading: {
      color: "#03a5fc",
      fontWeight: "bold",
      padding: "5px",
   
    },
    cardStyleValue: {
  
      marginLeft: "10px",
      color: "black",
      fontWeight: "500",
      textAlign: "right",
      wordBreak:"break-word"
  
    },
  };