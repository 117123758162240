import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomConsole from "../../CustomConsole";

export default function UpdateBannerTypeDialog({
  setIsUpdateBannerTypeDialog,
  isUpdateBannerTypeDialog,
  bannerType,
  setBannerType,
  bannerTypeId,
  setBannerTypeId,
  bannerStatus,
  setBannerStatus,
  setUpdateBannerTypeFlag, 
}) {
  const handleClose = () => {
    setIsUpdateBannerTypeDialog(false);
  };
  const handleChange = (event) => {
    setBannerStatus(event.target.checked);
  };
  const handleUpdateBtn = () => {
    setUpdateBannerTypeFlag(true);
  };
  return (
    <div>
      <Dialog
        open={isUpdateBannerTypeDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              {/* <TextField
                id="outlined-basic"
                label="Banner type name"
                variant="outlined"
                value={bannerTypeId}
                onChange={(event) => setBannerTypeId(event.target.value)}
              /> */}
              <TextField
                id="outlined-basic"
                label="Banner type name"
                variant="outlined"
                value={bannerType}
                onChange={(event) => setBannerType(event.target.value)}
              />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label="Enabled"
                  onChange={handleChange}
                  checked={bannerStatus}
                />
              </FormGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Button variant="outlined" size="small" onClick={handleUpdateBtn}>
                Update
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setIsUpdateBannerTypeDialog(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
