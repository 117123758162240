import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomConsole from "../../CustomConsole";

import { Box } from "@mui/material";

export default function FilterQrbarcodeDialog({
  filterDialog,
  setFilterDialog,
  ascDsc,
  qrBarCode,
  seriesType,
  setAscDsc,
  setQrbarcode,
  setSeriesType,
  setFilterFlag,
}) {
  const handleClose = () => {
    setFilterDialog(false);
  };

  return (
    <div>
      <Dialog
        open={filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              padding: "10px",
            }}
          >
            <Box>
              {" "}
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Date</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(event) => setAscDsc(event.target.value)}
                >
                  <FormControlLabel
                    value="ASC"
                    control={<Radio />}
                    label="ASC"
                  />
                  <FormControlLabel
                    value="DESC"
                    control={<Radio />}
                    label="DSC"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              {" "}
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  QR Barcode
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(event) => setQrbarcode(event.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="QR details"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Barcode details"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              {" "}
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Series type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(event) => setSeriesType(event.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Newly generated"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Regenerated"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Button variant="outlined" onClick={() => setFilterFlag(true)}>
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
