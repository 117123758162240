import { Box, Button, Typography } from "@mui/material";
import * as Material from "@mui/material";
import React from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  GetWfsOngoingBatch,
  WfsCreateBatchAction,
} from "../Redux/Actions/wfsOnGoingBatchAction";
import WfsOngoingBatchCard from "./WfsOngoingBatchCard";
import Skeleton from "@mui/material/Skeleton";
import WfsCreateBatchCard from "./WfsCreateBatchCard";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { GetWfsOngoingBatchLineDetails } from "../Redux/Actions/wfsOnGoingBatchAction";
import { toast } from "react-toastify";
import WfsBatchFormView from "./WfsBatchFormView";
import WfsBatchTableView from "./WfsBatchTableView";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@mui/styles";
import { GetUserProfile, GetWfsDetails } from "../Redux/Actions/profileAction";
import WfsCompletedBatchDetails from "../AdminWFSCanOrders/WfsCompletedBatchDetails";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { CreateWfsBatchSku } from "../Redux/Actions/skuWfsDetailsAction";
import { GetDistributorReturnCanOrders } from "../Redux/Actions/wfsOrdersAction";

function WfsBatch() {
  const dispatch = useDispatch();
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const wfsBatchDetails = useSelector((state) => state.wfsOngoingBatchDetails);
  const profileData = useSelector((state) => state.profile);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [isCreateBatchDialogOpen, setIsCreateBatchDialogOpen] =
    React.useState(false);
  const [wfsBatchView, setWfsBatchView] = React.useState("form");
  const [createBatchFlag, setCreateBatchFlag] = React.useState(false);
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lineId, setLineId] = React.useState("");
  const [batchQuantity, setBatchQuantity] = React.useState("");
  const [batchId, setBatchId] = React.useState();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [tabValueBatch, setTabValueBatch] = React.useState("Process");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  //@Removed/console.log(phoneNumber);

  const [ongoingBatchData, setOngoingBatchData] = React.useState([]);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  React.useEffect(() => {
    dispatch(GetUserProfile());
  }, []);

  React.useEffect(() => {
    setPhoneNumber(
      profileData.user !== undefined &&
        profileData.user !== null &&
        profileData.user.phone
    );
  }, [profileData]);

  React.useEffect(() => {
    wfsBatchDetails.ongoingBatch.map((data) => {
      setOngoingBatchData(data);
    });
  }, [wfsBatchDetails]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  React.useEffect(() => {
    if (createBatchFlag === true) {
      //@Removed/console.log("Hitting Create Batch API");
      const reqObj = {
        quantity: parseInt(batchQuantity),
        capacity: capacity,
        material: material,
        line_id: lineId,
        wfs_id: wfsDetails?.data?.wfs_id,
      };
      if (parseInt(batchQuantity) > 0) {
        dispatch(CreateWfsBatchSku(reqObj));
        setCapacity("");
        setLineId("");
        setMaterial("");
        setBatchQuantity("");
        setTimeout(() => {
          dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
        }, 2000);
      } else {
        //@Removed/console.log("Quantity cannot be zero");
        toast.error("Quantity cannot be zero", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      setCreateBatchFlag(false);

      setIsCreateBatchDialogOpen(false);
    }
  }, [createBatchFlag]);

  React.useEffect(() => {
    if (wfsDetails?.data?.wfs_id !== undefined) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
    }
  }, [wfsDetails]);

  React.useEffect(() => {
    dispatch(GetWfsOngoingBatchLineDetails());
    dispatch(GetAvailableProducts());
    dispatch(GetWfsDetails());
  }, []);

  React.useEffect(() => {
    //@Removed/console.log(wfsBatchDetails);
    if (wfsBatchDetails.lineBatchDetails !== undefined) {
      //@Removed/console.log(Object.keys(wfsBatchDetails.lineBatchDetails).length);
      //@Removed/console.log(wfsBatchDetails.lineBatchDetails.length);
      //@Removed/console.log(
      //   wfsBatchDetails.lineBatchDetails !== undefined ? true : false
      // );
    }

    //@Removed/console.log(wfsBatchDetails.lineBatchDetails !== null ? true : false);
    if (
      wfsBatchDetails.error === false &&
      wfsBatchDetails.batch_id !== "" &&
      createBatchFlag === true
    ) {
      setBatchId(wfsBatchDetails.batch_id);
      setOpenAlert(true);
      setIsCreateBatchDialogOpen(false);
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
      setCreateBatchFlag(false);
    } else {
      setIsCreateBatchDialogOpen(false);
      setOpenAlert(true);
    }
  }, [wfsBatchDetails]);

  React.useEffect(() => {
    if (
      wfsBatchDetails !== undefined &&
      wfsBatchDetails.ongoingBatch !== undefined &&
      wfsBatchDetails.ongoingBatch.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  }, [wfsBatchDetails]);

  // const [createBatchFlag, setCreateBatchFlag] = React.useState(false)
  React.useEffect(() => {
    //@Removed/console.log(`batchId ${batchId}`);
  }, [batchId]);
  React.useEffect(() => {
    if (wfsBatchView === "form") {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
    }
    if (wfsBatchView === "table") {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
    }
    if (wfsBatchView === "card") {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
    }
  }, [pageNumber]);

  const handleCreateBatch = () => {
    if (wfsBatchDetails.lineDetailsError === false) {
      setIsCreateBatchDialogOpen(true);
    } else {
      toast.error(
        "Contact Bookwater Support to add line details to create batch",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  const handleWfsBatchView = (event) => {
    setWfsBatchView(event.target.value);
  };

  const handleClickForward = () => {
    //@Removed/console.log("---handleClickForward -----");
    //@Removed/console.log("--pageNumber---:" + pageNumber);

    if (pageNumber + 1 > 0) {
      setPageNumber(() => pageNumber + 1);
    }
    // setPageStatuss(false);
  };

  const handleClickBack = () => {
    if (pageNumber > 0) {
      setPageBackWard(true);
      setPageForward(false);
      setPageNumber(() => pageNumber - 1);
      // setPageNumber(pageNumber - 1);
    }
  };

  const useStyles = makeStyles({
    tabUi: {
      "& .MuiTabs-flexContainer": {
        display: "flex",
        justifyContent: "space-between",
      },
      "& .PrivateTabIndicator-colorSecondary-12": {
        backgroundColor: "white",
      },
      "& .MuiTab-wrapper": {
        fontWeight: "600",
      },
    },
  });
  const classes = useStyles();
  const handleChangeTabValueBatch = (event, newValue) => {
    setTabValueBatch(newValue);
  };

  //useEffect for refreshing data
  React.useEffect(() => {
    if (!skuWfsDetails.linkProductError) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data?.wfs_id));
    }
  }, [skuWfsDetails.linkProductError]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // gap: "50px",
        alignItems: "center",
        justifyContent: "center",
        // padding: "10px",
        marginTop: "50px",
      }}
    >
      <Box sx={{ margin: "10px" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            ":hover": {
              backgroundColor: "black",
            },
          }}
          onClick={handleCreateBatch}
        >
          Create Batch
        </Button>
      </Box>
      <TabContext
        value={tabValueBatch}
        variant="scrollable"
        scrollButtons={false}
      >
        <TabList
          onChange={handleChangeTabValueBatch}
          className={classes.tabUi}
          sx={{ bgcolor: "#AED6F1" }}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab label="Batch Process" value="Process" />
          <Tab label="All Batch Details" value="CompletedBatchDetails" />
        </TabList>

        <TabPanel value="Process">
          {" "}
          <Material.Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              gap: "10px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {/* <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  },
                  alignItems: "center",
                }}
              >
                <Material.InputLabel
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  View:
                </Material.InputLabel>
                <Material.Select
                  variant="standard"
                  sx={{ fontSize: "16px", fontWeight: "bold", width: "150px" }}
                  value={wfsBatchView}
                  label="Ui View"
                  onChange={handleWfsBatchView}
                >
                  <Material.MenuItem value="form">Form</Material.MenuItem>
                  <Material.MenuItem value="table" disabled={true}>
                    Table
                  </Material.MenuItem>
                  <Material.MenuItem value="card" disabled={true}>
                    Card
                  </Material.MenuItem>
                </Material.Select>
              </Material.Box> */}
            </Material.Box>
            <Material.Box
              sx={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Material.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // margin: "10px",
                }}
              >
                <Material.Button onClick={handleClickBack}>
                  <ArrowBackIos />
                </Material.Button>
                <Material.Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{
                    // marginLeft: "10px",
                    // marginRight: "10px",
                    backgroundColor: "#2D81FF",
                    color: "white",
                    padding: "5px",
                    width: "20px",
                    height: "30px",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  {pageNumber + 1}
                </Material.Typography>

                <Material.Button
                  disabled={pageStatuss}
                  onClick={handleClickForward}
                >
                  <ArrowForwardIos />
                </Material.Button>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          {wfsBatchDetails.ongoingBatch &&
          wfsBatchDetails.ongoingBatch !== undefined &&
          wfsBatchDetails.ongoingBatch !== null &&
          wfsBatchDetails.ongoingBatch.length > 0 ? (
            <>
              {wfsBatchView === "form" ? (
                <Box>
                  <WfsBatchFormView
                    data={wfsBatchDetails?.ongoingBatch}
                    ongoingBatch={ongoingBatchData}
                    pageNumber={pageNumber}
                    setIsCreateBatchDialogOpen={setIsCreateBatchDialogOpen}
                    setIsUpdate={setIsUpdate}
                    isUpdate={isUpdate}
                  />
                </Box>
              ) : wfsBatchView === "table" ? (
                <Box>
                  <WfsBatchTableView
                    data={wfsBatchDetails?.ongoingBatch}
                    ongoingBatch={ongoingBatchData}
                  />
                </Box>
              ) : wfsBatchView === "card" ? (
                <>
                  <Box sx={{ padding: "20px" }}>
                    <WfsOngoingBatchCard
                      pageNumber={pageNumber}
                      ongoingBatch={wfsBatchDetails?.ongoingBatch}
                    />
                  </Box>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            "No Data Found"
          )}
        </TabPanel>
        <TabPanel value="CompletedBatchDetails">
          <WfsCompletedBatchDetails phoneNumber={phoneNumber} />
        </TabPanel>
      </TabContext>

      {/* {wfsBatchDetails.error === false ? (
        <>
          {wfsBatchDetails.ongoingBatch.map((data, key) => {
            return (
              <WfsOngoingBatchCard
                pageNumber={pageNumber}
                ongoingBatch={data}
                key={key}
              />
            );
          })}
        </>
      ) : (
        <Box>
          <Box
            sx={{
              width: 300,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Box>
      )} */}
      {isCreateBatchDialogOpen ? (
        <>
          <Box>
            <WfsCreateBatchCard
              isCreateBatchDialogOpen={isCreateBatchDialogOpen}
              setIsCreateBatchDialogOpen={setIsCreateBatchDialogOpen}
              setCapacity={setCapacity}
              setMaterial={setMaterial}
              setBatchQuantity={setBatchQuantity}
              setLineId={setLineId}
              setCreateBatchFlag={setCreateBatchFlag}
              material={material}
              capacity={capacity}
              batchQuantity={batchQuantity}
              lineId={lineId}
              wfsBatchDetails={wfsBatchDetails}
              pageNumber={pageNumber}
              setIsUpdate={setIsUpdate}
              isUpdate={isUpdate}
            />
          </Box>
        </>
      ) : (
        ""
      )}
      {batchId ? (
        <>
          <Snackbar
            open={openAlert}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {wfsBatchDetails.msg}
            </Alert>
          </Snackbar>
        </>
      ) : (
        ""
      )}
    </Box>
  );
}

export default WfsBatch;
