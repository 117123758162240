/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description BookWater App Authentication Page With Login & SignUP Components
 * @date 13/02/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import Slide from "@mui/material/Slide";
import { Button, Typography, Box, Grid } from "@mui/material";
import { GetWfsOngoingBatchLineDetails } from "../Redux/Actions/wfsOnGoingBatchAction";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ScannerGroupingProducts from "./scannerFunction";
import GroupProductCamera from "./cameraFunction";
import {
  CancelGroupProduct,
  CancelGroupProductsWfsSku,
  GroupProductsWfsSku,
} from "../Redux/Actions/skuWfsDetailsAction";
import { toast } from "react-toastify";

import WorkIcon from "@mui/icons-material/Work";
import ConformationDialog from "../../Components/Dialog/conformationDialog";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GroupProductWithCrate({ type }) {
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [dialogMsg, setDialogMsg] = React.useState("");

  const dispatch = useDispatch();
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const [capacity, setCapacity] = React.useState();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [selectedGroupingType, setSelectedGroupingType] =
    React.useState("scanner");
  const [canceProductOrCrate, setCanceProductOrCrate] =
    React.useState("product");
  const [cancelGrouping, setCancelGrouping] = React.useState("");
  const [groupingProduct, setGroupingProduct] = React.useState({
    crate_id: "",
    product_ids: [],
  });
  //function for handling control label function
  const handelSelectedControl = (e) => {
    CustomConsole(e.target.value);
    setSelectedGroupingType(e.target.value);
  };
  const handeleCancelProductOrCrate = (e) => {
    CustomConsole(e.target.value);
    setCanceProductOrCrate(e.target.value);
  };

  const onNewItemScanned = (qrBarcodeId) => {
    if (type === "group") {
      if (qrBarcodeId.includes("CR")) {
        if (groupingProduct.crate_id === "") {
          groupingProduct.crate_id = qrBarcodeId;
          setGroupingProduct({ ...groupingProduct });
        } else {
          if (groupingProduct.crate_id === qrBarcodeId) {
            setDialogMsg("Crate is already added to the group");
            setConfirmDialog(true);
          } else {
            setDialogMsg(
              "Already a crate is added to this group. Please Clear to continue!"
            );
            setConfirmDialog(true);
          }
        }
      } else if (
        groupingProduct.crate_id === "" &&
        !qrBarcodeId.includes("CR")
      ) {
        setDialogMsg("Please Scan Crate first for grouping products !");
        setConfirmDialog(true);
      } else if (
        groupingProduct.crate_id !== "" &&
        !qrBarcodeId.includes("CR")
      ) {
        // Check if the product ID already exists in the array
        if (!groupingProduct.product_ids.includes(qrBarcodeId)) {
          groupingProduct.product_ids.push(qrBarcodeId);
          setGroupingProduct({
            ...groupingProduct,
          });
        } else {
          // Handle case where the product ID is already in the array
          setDialogMsg("Product already added to the grouping");
          setConfirmDialog(true);
        }
      }
    } else {
      if (canceProductOrCrate === "crate") {
        if (cancelGrouping === "" && qrBarcodeId.includes("CR")) {
          // setCancelGrouping(qrBarcodeId);
          CustomConsole("-----------Crate cancel---------------");
          const CancelreqObj = {
            crate_id: qrBarcodeId,
          };
          dispatch(CancelGroupProductsWfsSku(CancelreqObj));
        } else if (cancelGrouping === "" && !qrBarcodeId.includes("CR")) {
          setDialogMsg("Please Scan Crate first for grouping products");
          setConfirmDialog(true);
        }
      } else {
        // setCancelGrouping(qrBarcodeId);
        CustomConsole("-----------product cancel---------------");
        const reqObj = {
          product_id: qrBarcodeId,
        };
        dispatch(CancelGroupProduct(reqObj));
      }
    }
  };
  //function for submitting grouping product
  const handelSubmit = () => {
    setCancelGrouping("");

    if (type === "group") {
      if (
        groupingProduct.crate_id === "" &&
        groupingProduct.product_ids.length === 0
      ) {
        toast.error("Please Scan Crate and Products to proceed ", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        dispatch(GroupProductsWfsSku(groupingProduct));
      }
    } else {
      if (canceProductOrCrate === "crate") {
        CustomConsole("-----------Crate cancel---------------");
        const CancelreqObj = {
          crate_id: cancelGrouping,
        };
        dispatch(CancelGroupProductsWfsSku(CancelreqObj));
      } else {
        CustomConsole("-----------product cancel---------------");
        const reqObj = {
          product_id: cancelGrouping,
        };
        dispatch(CancelGroupProduct(reqObj));
      }
    }
    setCancelGrouping("");
    setGroupingProduct({
      crate_id: "",
      product_ids: [],
    });
  };

  //function for handel clear operation
  const handelClear = () => {
    setGroupingProduct({
      crate_id: "",
      product_ids: [],
    });
    setCancelGrouping("");
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
          {type === "group"
            ? "Scan to Group Crate and Product"
            : "Scan Crate to Cancel Group Product"}
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        {type === "cancel" ? (
          <Grid xs={12}>
            <FormControl>
              <RadioGroup
                row
                onChange={handeleCancelProductOrCrate}
                defaultValue="product"
              >
                <FormControlLabel
                  value="product"
                  control={<Radio />}
                  label="Product"
                />
                <FormControlLabel
                  value="crate"
                  control={<Radio />}
                  label="crate"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        <Grid xs={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handelSelectedControl}
              defaultValue="scanner"
            >
              <FormControlLabel
                value="camera"
                control={<Radio />}
                label={`${
                  type.charAt(0).toUpperCase() + type.slice(1)
                } through Camera`}
              />
              <FormControlLabel
                value="scanner"
                control={<Radio />}
                label={`${
                  type.charAt(0).toUpperCase() + type.slice(1)
                } through Scanner`}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          {selectedGroupingType === "scanner" && (
            <ScannerGroupingProducts
              setGroupingProduct={() => {}}
              groupingProduct={groupingProduct}
              handelSubmit={handelSubmit}
              onNewItemScanned={onNewItemScanned}
              handelClear={handelClear}
              setCancelGrouping={setCancelGrouping}
              cancelGrouping={cancelGrouping}
              type={type}
            />
          )}
        </Grid>
        <Grid xs={10} md={5}>
          {selectedGroupingType === "camera" && (
            <GroupProductCamera
              setGroupingProduct={() => {}}
              groupingProduct={groupingProduct}
              handelSubmit={handelSubmit}
              handelClear={handelClear}
              onNewItemScanned={onNewItemScanned}
              setCancelGrouping={setCancelGrouping}
              cancelGrouping={() => {}}
              type={type}
            />
          )}
        </Grid>
        <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {type === "group" ? (
            <Box
              sx={{
                display: "flex",
                width: { md: "30%", sm: "50%", xs: "90%" },
                justifyContent: "center",
                flexDirection: "column",
                margin: "1rem 0",
                boxShadow: "5",
                borderRadius: "2rem",
                gap: 2,
              }}
            >
              <Typography>
                Crate Scanned : {groupingProduct.crate_id === "" ? 0 : 1}
              </Typography>
              <Typography>
                Product Scanned : {groupingProduct.product_ids.length}
              </Typography>
            </Box>
          ) : (
            // <Box
            //   sx={{
            //     display: "flex",
            //     width: { md: "30%", sm: "50%", xs: "90%" },
            //     justifyContent: "center",
            //     flexDirection: "column",
            //     margin: "1rem 0",
            //     boxShadow: "5",
            //     borderRadius: "2rem",
            //     gap: 2,
            //   }}
            // >
            //   {canceProductOrCrate === "crate" ? (
            //     <Typography>
            //       Crate Scanned : {cancelGrouping === "" ? 0 : 1}
            //     </Typography>
            //   ) : (
            //     <Typography>
            //       Product Scanned : {cancelGrouping === "" ? 0 : 1}
            //     </Typography>
            //   )}
            // </Box>
            ""
          )}
        </Grid>
      </Grid>
      <ConformationDialog
        setConfirmDialog={setConfirmDialog}
        confirmDialog={confirmDialog}
        dialogMsg={dialogMsg}
        showProceed={false}
        handelProceed={null}
      />
    </div>
  );
}
