import { ContactSupportOutlined } from "@mui/icons-material";
import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  userSettlementError: true,
  settleInfluncererror: true,
  userSettlementDetails: {},
  settlementDetailByDateError: false,
  settlementDetailByDate: {},
  settlementHistory: [],
  settlementHistoryError: true,
  requestError: false,
  requestListData: [],
  requestListError: false,
};

const adminSettlementReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  CustomConsole(action);
  switch (action.type) {
    case actionType.REQUEST_SETTLEMENTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_USER_SETTLEMENT_DETAILS:
      return {
        ...state,
        userSettlementError: action.payload.error,
        userSettlementDetails: action.payload.data,
      };
    case actionType.GET_DETAILS_BY_DATE:
      return {
        ...state,
        settlementDetailByDateError: action.payload.error,
        settlementDetailByDate: action.payload.data,
      };
    case actionType.WFS_INFLUENCER_SUPPLIER_SETTLEMENTS:
      return {
        ...state,
        settleInfluncererror: action.payload.error,
        msg: action.payload.msg,
        data: action.payload.data,
      };
    case actionType.SETTLEMENT_HISTORY:
      return {
        ...state,
        settlementHistoryError: action.payload.error,
        settlementHistory: action.payload.data,
      };
    case actionType.WATER_DROP_SETTLEMENT:
      return {
        ...state,
        requestError: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.SETTELMENT_REQUEST_LIST:
      CustomConsole(action);
      return {
        ...state,
        requestListError: action.payload.error,
        msg: action.payload.msg,
        requestListData: action.payload.data,
      };
    case actionType.SETTLEMENT_REQUEST_REJECT:
      return {
        ...state,
        msg: action.payload.msg,
      };
    case actionType.INFLUENCER_SETTLEMENTS_PAYMENT_SEND_MAIL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default adminSettlementReducer;
