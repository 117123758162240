import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../../CustomConsole";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import { RequestReplacementCan } from "../../Redux/Actions/userDetailsAdminAction";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import { GetSupplierDetailsList } from "../../Redux/Actions/supplierVehiclesActions";
import CloseIcon from "@mui/icons-material/Close";
import { CreateBuyBackOrder } from "../../Redux/Actions/buyBackOrdersAction";
import { GetProductsAddress } from "../../Redux/Actions/adminSkuBookingAction";

function BuyBackProductsOrdersDialog({ open, setOpen, userId }) {
  const dispatch = useDispatch();
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate); //userAddressAdmin.address
  const [material, setMaterial] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [availableProducts, setavailableProducts] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState("");
  const [addressId, setAddressId] = React.useState(0);
  const [productId, setProductId] = React.useState("");
  const [orderQty, setOrderQty] = React.useState("");
  const [supplierDetails, setSupplierDetails] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [collectProducts, setCollectProducts] = React.useState([]);

  //useeffect to get supplier details
  React.useEffect(() => {
    dispatch(GetSupplierDetailsList()).then((data) => {
      setSupplierDetails(data?.data);
    });
  }, []);

  //function to handel close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  //function to add selected product
  React.useEffect(() => {
    if (productId) {
      const selectedProduct = availableProducts?.filter((obj) => {
        return obj.id === productId;
      });
      CustomConsole(selectedProduct);
      setSelectedProduct(() => selectedProduct[0]);
    }
  }, [productId]);

  //function to handel supplier change
  const handelSupplierChange = (e) => {
    setSelectedSupplier(e.target.value);
  };

  //function to handel qty change
  const handleQuantityChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setOrderQty(() => event.target.value);
  };

  //function to handel multiple products
  const handleMultipleProductAdd = (material, capacity, id, image) => {
    CustomConsole("-----inside---");
    if (orderQty !== "" && !isNaN(orderQty) && material && capacity) {
      const existingProductIndex = collectProducts.findIndex(
        (products) => products.id === id
      );
      CustomConsole("---Existing products---");
      CustomConsole(existingProductIndex);
      // Parse order quantity to a float
      const qtyToAdd = parseFloat(orderQty);
      if (existingProductIndex !== -1) {
        const updatedProducts = collectProducts.map((product, idx) => {
          if (idx === existingProductIndex) {
            return {
              ...product,
              order_qty: product.order_qty + qtyToAdd, // Increase the order_qty
            };
          }
          return product;
        });

        setCollectProducts(updatedProducts);
        setProductId("");
        setOrderQty("");
      } else {
        // Product does not exist, add a new entry
        const productObj = {
          order_qty: parseFloat(orderQty),
          container_capacity: capacity,
          container_material: material,
          id: id,
          received_qty: 0,
          price: 0,
          total_price: 0,
          image: image,
        };
        setProductId("");
        setOrderQty("");
        return setCollectProducts((prev) => [...prev, productObj]);
      }
    }
  };

  //function to handel remove product
  const removeProduct = (id) => {
    const existingProductIndex = collectProducts.findIndex(
      (products) => products.id === id
    );
    const updatedProducts = collectProducts.filter(
      (obj, idx) => idx !== existingProductIndex
    );
    return setCollectProducts([...updatedProducts]);
  };
  //function to handel submit details
  const handelSubmit = () => {
    const submitObject = {
      products: collectProducts,
      address_id: addressId,
      supplier_id: selectedSupplier,
      user_id: userId,
    };
    if (collectProducts?.length > 0) {
      dispatch(CreateBuyBackOrder(submitObject)).then((data) => {
        if (!data.error) {
          handleClose();
        }
      });
    } else {
      toast.error("Please add the product to proceed with the order.", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };
  console.log(collectProducts);

  //function to handel address id change
  const handelAddressIdChange = (e) => {
    let addressId = e.target.value;
    setAddressId(addressId);
    dispatch(
      GetProductsAddress({
        address_id: addressId,
        validate_dist_stock: false,
      })
    ).then((data) => {
      if (!data.error) {
        setavailableProducts(data?.data?.products);
      }
    });
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
      <DialogTitle>Buy Back Product Order</DialogTitle>
      <DialogContent>
        <Stack mt={2} spacing={2}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="product-type-select-label"
              sx={{ fontSize: "1rem", marginTop: "2px" }}
            >
              Address
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Address"
              size="small"
              onChange={handelAddressIdChange}
            >
              {userAddressAdmin?.address?.map((obj, idx) => {
                return (
                  <MenuItem
                    value={obj.address_id}
                    sx={{ fontSize: "0.69rem" }}
                    key={idx}
                  >
                    {`${obj.contact_name}, ${obj.address_line1}, ${obj.address_line2}, ${obj.address_line3}, ${obj.service_area}, ${obj.pincode}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Stack
            direction="column"
            spacing={{ xs: 1, sm: 2 }}
            useFlexGap
            flexWrap="wrap"
            justifyContent="space-between"
            mt={2}
          >
            <FormControl size="small">
              <InputLabel
                id="product-type-select-label"
                sx={{ fontSize: "1rem", marginTop: "2px" }}
              >
                Products
              </InputLabel>
              <Select
                labelId="product-type-select-label"
                id="product-type-select"
                value={productId}
                label="Product"
                onChange={(e) => setProductId(e.target.value)}
              >
                {availableProducts?.map((data, idx) => {
                  if (data?.booking_type === 3 || data?.booking_type === 2) {
                    return (
                      <MenuItem
                        value={data.id}
                        sx={{ fontSize: "0.76rem" }}
                        key={idx}
                      >
                        {`${data.capacity}/${data.material}`}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
            <TextField
              size="small"
              InputLabelProps={{ shrink: true }}
              label="Total Quantity"
              required
              sx={{ width: "100%" }}
              value={orderQty}
              inputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handleQuantityChange, // Attach the input event handler
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() =>
                        handleMultipleProductAdd(
                          selectedProduct.material,
                          selectedProduct.capacity,
                          selectedProduct.id,
                          selectedProduct.image
                        )
                      }
                    >
                      Add
                    </Button>
                  </InputAdornment>
                ),
              }}
              // helperText={`${selectedProduct.container_material},${selectedProduct.container_capacity} for 1 quantity will be having ${selectedProduct.sku_qty} products`}
            />
          </Stack>
          <FormControl fullWidth size="small">
            <InputLabel
              id="product-type-select-label"
              sx={{ fontSize: "1rem" }}
            >
              Supplier
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              label="Supplier"
              onChange={handelSupplierChange}
            >
              {supplierDetails?.map((obj, idx) => {
                if (obj?.supplier_name !== null) {
                  return (
                    <MenuItem
                      value={obj?.id}
                      sx={{ fontSize: "0.69rem" }}
                      key={idx}
                    >
                      {`${obj?.supplier_profile?.company_name}`}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Stack>
        {collectProducts
          ? collectProducts.map((val, idx) => {
              return (
                <Box
                  sx={{
                    padding: "5px",
                    marginTop: ".5rem",
                    backgroundColor: "#b1f2ff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  key={idx}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "sans-serif",
                    }}
                  >
                    {`${
                      val?.container_capacity.length > 5
                        ? val?.container_material.slice(0, 7) + ".."
                        : val?.container_material
                    },${val?.container_capacity},`}
                    &nbsp;&nbsp;{" "}
                    <span style={{ color: "grey" }}>Qty:{val?.order_qty}</span>
                    &nbsp;
                    {val?.booking_type === 2 ? (
                      <span>
                        <LooksOneIcon />
                      </span>
                    ) : null}
                    {/* {val?.length >20? val?.slice(0,20)+"....":val?} */}
                  </Typography>
                  <CloseIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      removeProduct(val?.id);
                    }}
                  />
                </Box>
              );
            })
          : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handelSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BuyBackProductsOrdersDialog;
