import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import {
  GetCompleteQrDetails,
  GetProductCanColor,
} from "../Redux/Actions/adminQrBarcodeAction";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";

function ProductCapColor() {
  React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  // const [alignment, setAlignment] = React.useState('Scan');
  const [scanQrFlag, setScanQrFlag] = React.useState(false);
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [canColorResp, setCanColorResp] = React.useState({
    error: true,
    msg: "",
  });

  const getProductColor = async (code) => {
    CustomConsole(getProductColor);
    const resp = await GetProductCanColor(code);
    CustomConsole(resp);
    setCanColorResp(resp);
  };
  React.useEffect(() => {
    if (scannedQrCodeDetails !== "") {
      getProductColor(scannedQrCodeDetails);
      setScannedQrCodeDetails("");
    }
  }, [scannedQrCodeDetails]);

  const [scanOption, setScanOption] = React.useState("scanner");
  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeDetails(decodedText);

      // dispatch(GetDetailsFromQrcode(decodedText));
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedQrCodeDetails(decodedText);
    }
    setScanQrFlag(false);
    setScanOption("scanner");
  };

  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      setScanQrFlag(true);
    } else {
      setScanQrFlag(false);
    }
  };

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      setScannedQrCodeDetails(qrBarcodeId);
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);
  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        CustomConsole("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        CustomConsole("--- data is set ---");
      }
    }, 500);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <FormControl>
          <FormLabel>Select Scanning Option</FormLabel>
          <RadioGroup
            defaultValue="scanner"
            value={scanOption}
            name="radio-buttons-group"
            sx={{ display: "flex", flexDirection: "row" }}
            onChange={handleSelectScanOption}
          >
            <FormControlLabel
              value="scanner"
              control={<Radio />}
              label="Scanner Device"
            />
            <FormControlLabel
              value="camera"
              control={<Radio />}
              label="Camera"
            />
          </RadioGroup>
        </FormControl>

        {scanOption === "camera" ? (
          <>
            {" "}
            <Html5QrcodePlugin1
              fps={10}
              qrbox={250}
              scanQrFlag={scanQrFlag}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResultQrCode}
            />
          </>
        ) : (
          ""
        )}

        {scanOption === "scanner" ? (
          <Box>
            <TextField
              onChange={handleQrBarcodeIdFromScanner}
              label="QR/Barcode:"
              value={qrBarcodeId}
              size="small"
              sx={{
                height: "10px",
                width: "200px",
                marginBotton: "30px",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ) : (
          ""
        )}
        {canColorResp.error ? (
          canColorResp.msg.length ? (
            <h5 style={{ color: "red" }}>{canColorResp.msg}</h5>
          ) : (
            ""
          )
        ) : canColorResp.data && canColorResp.data.color ? (
          <Box
            sx={{
              marginTop: "35px",
            }}
          >
            <h5>No. of times used: {canColorResp.data.usage_count}</h5>
            <h5>Product Can Color:</h5>
            <AdfScannerIcon
              sx={{
                fontSize: "150px",
                color: canColorResp.data.color,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

export default ProductCapColor;
