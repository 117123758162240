export function formatAndMergeProducts(reportData) {
  try {
    reportData.products = reportData?.products
      ? JSON.parse(reportData.products)
      : [];
  } catch (error) {
    console.error("Failed to parse products JSON:", error);
    reportData.products = []; // Fallback to an empty array
  }
  const { packaged_drinking_water, products } = reportData;

  const formattedData = {};

  // Format the packaged_drinking_water
  if (packaged_drinking_water) {
    const drinkingWaterKey = `${packaged_drinking_water.selected_container_capacity}-${packaged_drinking_water.selected_container_material}`;

    formattedData[`${drinkingWaterKey}-order_quantity (1/pack)`] =
      packaged_drinking_water.twentyltr_capacity_qty || 0;
    formattedData[`${drinkingWaterKey}-return_quantity (Units)`] =
      packaged_drinking_water.empty_container_qty || 0;
    formattedData[`${drinkingWaterKey}-product_price`] =
      packaged_drinking_water.twenty_ltr_capacity_totamt || 0;
    formattedData[`${drinkingWaterKey}-cgst_charges`] =
      packaged_drinking_water.cgst?.charges || 0;
    formattedData[`${drinkingWaterKey}-sgst_charges`] =
      packaged_drinking_water.sgst?.charges || 0;
    formattedData[`${drinkingWaterKey}-igst_charges`] =
      packaged_drinking_water.igst?.charges || 0;

    formattedData[`${drinkingWaterKey}-extra_charges_cgst_price`] =
      packaged_drinking_water.extra_charges?.cgst?.price || 0;
    formattedData[`${drinkingWaterKey}-extra_charges_sgst_price`] =
      packaged_drinking_water.extra_charges?.sgst?.price || 0;
    formattedData[`${drinkingWaterKey}-extra_charges_price`] =
      packaged_drinking_water.extra_charges?.price || 0;
  }

  // Ensure products is always an array and format them
  (Array.isArray(products) ? products : []).forEach((product) => {
    const productKey = `${product.capacity}-${product.material}`;

    formattedData[`${productKey}-order_quantity (1/pack)`] =
      product.quantity || 0;
    formattedData[`${productKey}-return_quantity (Units)`] =
      product.return || 0;
    formattedData[`${productKey}-product_price`] = product.final_price || 0;
    formattedData[`${productKey}-cgst_charges`] = product.cgst?.charges || 0;
    formattedData[`${productKey}-sgst_charges`] = product.sgst?.charges || 0;
    formattedData[`${productKey}-igst_charges`] = product.igst?.charges || 0;

    // Extract GST, IGST, and delivery charge details for products
    formattedData[`${productKey}-extra_charge_cgst_price`] =
      product.extra_charges?.cgst?.price || 0;
    formattedData[`${productKey}-extra_charge_sgst_price`] =
      product.extra_charges?.sgst?.price || 0;
    formattedData[`${productKey}-extra_charge_price`] =
      product.extra_charges?.price || 0;
  });

  // Return the formatted data object
  return formattedData;
}
