export const styles = {
    LostCansStyleHeading: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
      padding: "10px",
      gap: "10px",
      color: "#0a83c4", 
      fontWeight: "bold"
    },
    LostCansStyleValue: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
      padding: "10px",
      gap: "10px",
    },
    CommonButton:{
      textTransform: "none",
                backgroundColor: "white",
                fontFamily: "'Montserrat', sans-serif",
                color: "white",
                textAlign: "center",
                borderRadius: "10px",
                fontSize: "12px",
                fontWeight: "bold",
                marginLeft: "10px",
                "&:hover": {
                  // boxShadow: "none",
                  // backgroundColor: "#29358f",
                  // color: "white",
                },
                background: "rgb(67,135,245)",
                background:
                  "radial-gradient(circle, rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
    }
  };
  