import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { Box, Button, Card, Typography } from "@mui/material";
import AddBannerTypeDialog from "./AddBannerTypeDialog";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  CreateBannerTypes,
  GetBannerTypes,
  UpdateBannerTypes,
  GetBannerTypeList,
} from "../Redux/Actions/adminBannersAction";
import { ToastContainer, toast } from "react-toastify";
import UpdateBannerTypeDialog from "./UpdateBannerTypeDialog";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    background: "rgb(208,221,242)",
    backgroundImage:
      "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function BannerTypesTable({
  bannerTypes,
  pageNumber,
  createdAt,
  isEnabled,
  setPageNumber,
  listEmpty,
  addBannerTypeFlag,
  setAddBannerTypeFlag,
  setBannerTypeFilterDialog,
}) {
  const dispatch = useDispatch();

  const bannersData = useSelector((state) => state.adminBannersData);
  const [isAddBannerTypeDialog, setIsAddBannerTypeDialog] =
    React.useState(false);
  const [isUpdateBannerTypeDialog, setIsUpdateBannerTypeDialog] =
    React.useState(false);

  const [bannerTypeName, setBannerTypeName] = React.useState("");
  const [switchInAddBannerType, setSwitchInAddBannerType] =
    React.useState(false);

  const [updateBannerTypeFlag, setUpdateBannerTypeFlag] = React.useState(false);
  // variables for updating banner type
  const [bannerTypeId, setBannerTypeId] = React.useState("");
  const [bannerType, setBannerType] = React.useState("");
  const [bannerStatus, setBannerStatus] = React.useState(false);

  const [pageNumberFlag, setPageNumberFlag] = React.useState(false);
  React.useEffect(() => {
    CustomConsole(bannersData);
    if (bannersData.error === false && addBannerTypeFlag === true) {
      toast.success(bannersData.bannerTypeMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAddBannerTypeFlag(false);
      setSwitchInAddBannerType("");
      setBannerTypeName("");
      setIsAddBannerTypeDialog(false);
    }
    if (bannersData.error === false && updateBannerTypeFlag === true) {
      toast.success(bannersData.UpdateTypeMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateBannerTypeFlag(false);
      setBannerTypeId("");
      setBannerType("");
      setIsUpdateBannerTypeDialog(false);
    }
  }, [bannersData]);

  React.useEffect(() => {
    CustomConsole(bannerTypes);
  }, [bannerTypes]);

  const handleAddBannerType = () => {
    setIsAddBannerTypeDialog(true);
  };

  React.useEffect(() => {
    if (updateBannerTypeFlag) {
      const reqObj = {
        banner_type_id: bannerTypeId,
        status: bannerStatus,
        banner_type: bannerType,
      };
      dispatch(UpdateBannerTypes(reqObj));
      setTimeout(() => {
        dispatch(GetBannerTypes(pageNumber - 1, createdAt, isEnabled));
      }, 1000);
    }
  }, [updateBannerTypeFlag]);
  React.useEffect(() => {
    if (addBannerTypeFlag) {
      const reqObj = {
        banner_type_name: bannerTypeName,
        status: switchInAddBannerType,
      };
      dispatch(CreateBannerTypes(reqObj));
      setTimeout(() => {
        dispatch(GetBannerTypeList());
      }, 1500);
    }
  }, [addBannerTypeFlag]);
  const handleBannerTypeTableClick = (
    bannerTypeId,
    bannerType,
    bannerStatus
  ) => {
    CustomConsole(bannerTypeId, bannerType, bannerStatus);
    setBannerTypeId(bannerTypeId);
    setBannerType(bannerType);
    setBannerStatus(bannerStatus);
    setIsUpdateBannerTypeDialog(true);
  };

  React.useEffect(() => {
    if (pageNumberFlag === true) {
      dispatch(GetBannerTypes(pageNumber - 1, createdAt, isEnabled));
      setPageNumberFlag(false);
    }
  }, [pageNumberFlag]);
  const handlePageNumberPlus = () => {
    setPageNumber((page) => page + 1);
    setPageNumberFlag(true);
  };
  const handlePageNumberMinus = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber((page) => page - 1);
      setPageNumberFlag(true);
    }
  };

  return (
    <Box sx={{ maxWidth: { xs: 350, lg: 1, md: 1, sm: 1 } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { md: "flex-end", xs: "center" },
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            diaplay: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            // padding: {lg:"10px",sm:"10"},
          }}
        >
          <Button variant="contained" onClick={handleAddBannerType}>
            Add Banner type
          </Button>
        </Box>
        <Box sx={{ marginTop: "11px" }}>
          <Button
            variant="outlined"
            // size="small"
            color="secondary"
            onClick={() => setBannerTypeFilterDialog(true)}
          >
            Filter
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gap: "15px",
            // padding: "10px",
            marginTop: "10px",
          }}
        >
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={handlePageNumberMinus}
            >
              <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
            </Button>
          </Box>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "2px",
              width: "30px",
              height: "25px",
              borderRadius: "15px",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {pageNumber}
          </Typography>
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={handlePageNumberPlus}
              disabled={listEmpty}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
            </Button>
          </Box>
        </Box>
      </Box>
      <Card>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead >
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Banner type</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bannerTypes.map((row, key) => (
                <StyledTableRow
                  key={row.banner_type_id}
                  onClick={() =>
                    handleBannerTypeTableClick(
                      row.banner_type_id,
                      row.banner_type,
                      row.status
                    )
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#BECAE0",
                    },
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {key + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.banner_type}</StyledTableCell>
                  <StyledTableCell>
                    {row.status ? `Enabled` : "Disabled"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {isAddBannerTypeDialog ? (
        <AddBannerTypeDialog
          isAddBannerTypeDialog={isAddBannerTypeDialog}
          setIsAddBannerTypeDialog={setIsAddBannerTypeDialog}
          switchInAddBannerType={switchInAddBannerType}
          setSwitchInAddBannerType={setSwitchInAddBannerType}
          setBannerTypeName={setBannerTypeName}
          bannerTypeName={bannerTypeName}
          setAddBannerTypeFlag={setAddBannerTypeFlag}
        />
      ) : (
        ""
      )}

      {isUpdateBannerTypeDialog ? (
        <>
          <UpdateBannerTypeDialog
            setIsUpdateBannerTypeDialog={setIsUpdateBannerTypeDialog}
            isUpdateBannerTypeDialog={isUpdateBannerTypeDialog}
            bannerType={bannerType}
            setBannerType={setBannerType}
            bannerTypeId={bannerTypeId}
            setBannerTypeId={setBannerTypeId}
            bannerStatus={bannerStatus}
            setBannerStatus={setBannerStatus}
            setUpdateBannerTypeFlag={setUpdateBannerTypeFlag}
          />
        </>
      ) : (
        ""
      )}
    </Box>
  );
}
