import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";

const WfsBatchFilterDialog = ({
  open,
  onClose,
  onSubmit,
  batchFilter,
  setBatchFilter,
}) => {
  const wfsStockDetails = useSelector((state) => state.userDetailsUpdate);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  //function for handling input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBatchFilter((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //function for handling onsubmit
  const handleSubmit = () => {
    onSubmit(batchFilter);
  };

  //function for handling clear filter operation
  const handelClearFilter = () => {
    setBatchFilter({
      page_number: 1,
      page_size: 25,
      wfs_id:  wfsStockDetails.assignedWfsAddress?.wfs_id === undefined
      ? wfsDetails.data?.wfs_id
      : wfsStockDetails.assignedWfsAddress?.wfs_id,
      material: "",
      capacity: "",
      batch_id: "",
      is_batch_started: "",
      is_batch_stopped: "",
      is_settled: "",
      sort_updated_at: "",
      sort_created_at: "",
      sort_batch_id: "",
      sort_quantity: "",
      sort_batch_start_time: "",
      sort_material: "",
      sort_capacity: "",
      sort_batch_stop_time: "",
    });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter Options</DialogTitle>
      <DialogContent>
        <Grid container rowGap={2} sx={{ paddingTop: "0.5rem" }}>
          <Grid item md={6} xs={12}>
            <TextField
              label="Material"
              name="material"
              size="small"
              value={batchFilter.wfs_name}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <TextField
              label="WFS Owner Phone"
              name="capacity"
              size="small"
              value={batchFilter.wfs_owner_phone}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item md={6} xs={12}>
            <TextField
              label="Batch Id"
              name="batch_id"
              size="small"
              value={batchFilter.incharge_phone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Batch Status</InputLabel>
              <Select
                name="is_batch_started"
                size="small"
                value={batchFilter.is_batch_started}
                onChange={handleInputChange}
              >
                <MenuItem value="true">Completed</MenuItem>
                <MenuItem value="false">Not Completed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Stop Batch Status</InputLabel>
              <Select
                name="is_batch_stopped"
                size="small"
                value={batchFilter.is_batch_stopped}
                onChange={handleInputChange}
              >
                <MenuItem value="true">Started</MenuItem>
                <MenuItem value="false">not started yet</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Settled Status</InputLabel>
              <Select
                name="is_settled"
                size="small"
                value={batchFilter.is_settled}
                onChange={handleInputChange}
              >
                <MenuItem value="true">Settled</MenuItem>
                <MenuItem value="false">Not Settled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort By Batch Id</InputLabel>
              <Select
                name="sort_batch_id"
                size="small"
                value={batchFilter.sort_batch_id}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort By Quantity</InputLabel>
              <Select
                name="sort_quantity"
                size="small"
                value={batchFilter.sort_quantity}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort By Batch Time</InputLabel>
              <Select
                name="sort_batch_start_time"
                size="small"
                value={batchFilter.sort_batch_start_time}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort By Material</InputLabel>
              <Select
                name="sort_material"
                size="small"
                value={batchFilter.sort_material}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort By Capacity</InputLabel>
              <Select
                name="sort_capacity"
                size="small"
                value={batchFilter.sort_capacity}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort By Batch Stop Time</InputLabel>
              <Select
                name="sort_batch_stop_time"
                size="small"
                value={batchFilter.sort_batch_stop_time}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" sx={{ width: "80%" }}>
              <InputLabel>Sort by Created At</InputLabel>
              <Select
                name="sort_created_at"
                size="small"
                value={batchFilter.sort_created_at}
                onChange={handleInputChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button
            onClick={onClose}
            color="error"
            variant="contained"
            size="small"
          >
            close
          </Button>
          <Button
            onClick={handelClearFilter}
            color="primary"
            variant="contained"
            size="small"
          >
            Clear Filters
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="small"
          >
            Apply Filters
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WfsBatchFilterDialog;
