export const appColors = {
  white: "#fff",
  blueColor: "#068fff",
  commonLayoutColor: {
    background: "rgb(208,221,242)",

    backgroundImage:
      "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
    borderRadius: "10px",
  },
  commonBtnColor: {
    background:
      "radial-gradient(circle, rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
  },
  commonTextColor: {
    color: "black",
  },
  commonFontColor: {
    fontFamily: "'Montserrat', sans-serif",
  },
};
