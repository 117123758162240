import { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomDataGrid from "../Tables/CustomDataGrid";

// // Simplified function to map object keys to columns

const ReportsTable = ({
  reportData,
  pageSize,
  pageNumber,
  updateTotalPages,
  isProducts,
  formattedProducts,
  selectedReportId,
  selectedReport,
}) => {
  const { loading } = useSelector((state) => ({
    loading: state.adminReport.loading,
  }));

  // Debugging Logs
  console.log("Report Data:", reportData);
  console.log("Page Size:", pageSize);
  console.log("Page Number:", pageNumber);
  console.log("Selected Report:", selectedReport);

  // Check if reportData is populated
  if (!reportData || reportData.length === 0) {
    console.log("No report data available!");
    return <div>No data available</div>;
  }

  // Convert keys to uppercase without flattening deeply
  const upperCaseData = reportData;
  const uniqueKeys = Array.from(new Set(upperCaseData.flatMap(Object.keys)));

  // Generate columns dynamically based on the unique keys
  const keys = uniqueKeys.map((key) => ({
    field: key,
    headerName: key.replace(/_/g, " ").toUpperCase(),
    flex: 1,
  }));
  const hasSubRows = selectedReport?.sub_rows;

  const subRowFields = hasSubRows?.fields;
  const subRowKey = hasSubRows?.key;
  const columns = keys?.filter((key) => {
    if (hasSubRows) {
      if (["PRODUCTS"].includes(key.field?.toUpperCase())) {
        return;
      } else return key;
    }
    if (isProducts) {
      if (
        ["PACKAGED_DRINKING_WATER", "PRODUCTS"].includes(
          key.field?.toUpperCase()
        )
      ) {
        return;
      } else return key;
    } else {
      return key;
    }
  });
  console.log("Columns Generated:", columns);

  // Generate rows dynamically based on the paginated data
  const rows = reportData.map((row, index) => ({
    id: index + 1,
    ...row,
  }));

  console.log("Rows Generated:", rows);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <CustomDataGrid
          columns={columns}
          rows={rows}
          subRowKey={subRowKey}
          subRowFields={subRowFields}
          isProducts={isProducts}
          formattedProducts={formattedProducts}
          pageSize={pageSize}
          pageNumber={pageNumber}
          selectedReportId={selectedReportId}
          selectedReport={selectedReport}
        />
      )}
    </>
  );
};

export default ReportsTable;
