/**
 * @author Gowtham Prasath
 * @description Deliveries Details page
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.175
 * @Date 05-06-2024
 */
// Importing the Libraries and Other Files
import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import { fromLonLat, toLonLat } from "ol/proj";
import location from "../Assests/Images/mapLocation.svg";
import Overlay from "ol/Overlay";
import { click } from "ol/events/condition";
import Select from "ol/interaction/Select";
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
const DistributorLocation = ({
  data,
  setAssignAgentDialogOpen,
  setOrderId,
  coordinates,
  setCoordinates,
}) => {
  const mapElement = useRef();
  const mapRef = useRef();
  console.log(mapElement);
  //use effect to mount map on render
  useEffect(() => {
    const map = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([80.2030987,13.0252880]), // Center on a default location
        zoom: 12,
      }),
    });

    mapRef.current = map; // Store map instance in ref

    // Add markers for all data
    const features = data.map((coord) => {
      let productsData = [];
      if (!coord?.packaged_drinking_water?.is_invalid) {
        productsData.push({
          capacity: coord?.packaged_drinking_water?.selected_container_capacity,
          image: coord?.packaged_drinking_water?.image,
          name: coord?.packaged_drinking_water?.name,
          material: coord?.packaged_drinking_water?.selected_container_material,
          is_qr: true,
          order_qty: coord?.packaged_drinking_water?.twentyltr_capacity_qty,
          return: coord?.packaged_drinking_water?.empty_container_qty,
          sku_qty: 1,
          order_price:
            coord?.packaged_drinking_water?.twenty_ltr_capacity_totamt,
          isInScanCompleted:
            coord?.packaged_drinking_water?.is_inscan_completed,
          isOutScanCompleted:
            coord?.packaged_drinking_water?.is_outscan_completed,
        });
      }
      if (coord?.products?.length > 0) {
        for (const data of coord?.products) {
          productsData.push({
            capacity: data.capacity,
            material: data.material,
            is_qr: data.is_qr,
            order_qty: data.quantity,
            name: data.name,
            return: data.return,
            image: data.image,
            is_invalid: true,
            received_empty: data.consumer_submitted_empty
              ? data.consumer_submitted_empty
              : 0,
            submitted_filled: data.consumer_received_filled
              ? data.consumer_received_filled
              : 0,
            sku_qty: data.sku_qty,
            order_price: data.product_price,
          });
        }
      }
      console.log(coord.order_id);
      const feature = new Feature({
        geometry: new Point(
          fromLonLat([
            coord?.delivery_address?.longitude,
            coord?.delivery_address?.latitude,
          ])
        ),
        orderData: coord,
        coordinates: [
          coord?.delivery_address?.longitude,
          coord?.delivery_address?.latitude,
        ],
      });

      // Create an overlay for each marker
      const element = document.createElement("div");
      element.className = "marker-label";
      element.onclick = () => {
        setAssignAgentDialogOpen(true);
        setCoordinates(coordinates);
        setOrderId(coord);
        element.style = "dispaly:none";
      };
      element.innerHTML = `<div style="background-color:${
        coord?.payment_type === 300
          ? "rgba(109, 20, 125, 0.6)"
          : coord?.packaged_drinking_water.order_type === 700
          ? "rgba(238, 65, 144, 0.6)"
          : coord?.packaged_drinking_water.is_subscribed_order === true
          ? "rgba(59, 194, 192, 0.6)"
          : "rgba(146, 78, 239, 0.6)"
      }; padding: 2px" >
      <div style="display: flex;flex-direction:column; font-weight:bold; color:green;background:#fff;font-size:.6rem ;border-radius:.5rem">
        <div style="display: flex; padding:0 2px">${coord.order_id}</div>
        <div  style="display: flex;flex-direction:column; ">
        ${productsData
          ?.map((item) => {
            return `<div style="display: flex; padding:1px">${item.name} - ${item.order_qty}</div>`;
          })
          ?.join("")}
        </div>
      </div>
    </div>`;

      const overlay = new Overlay({
        position: fromLonLat([
          coord?.delivery_address?.longitude,
          coord?.delivery_address?.latitude,
        ]),
        element: element,
        offset: [0, -15], // Adjust the offset to position the label correctly
      });

      map.addOverlay(overlay);
      return feature;
    });

    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        image: new Icon({
          src: location,
          scale: 1, // Adjust scale if necessary
        }),
      }),
    });
    map.addLayer(vectorLayer);
    getUserLocation();

    // Add click event listener for markers
    const selectClick = new Select({
      condition: click,
    });

    // selectClick.on("select", function (e) {
    //   const selectedFeatures = e.target.getFeatures();
    //   selectedFeatures.forEach((feature) => {
    //     const orderData = feature.get("orderData");
    //     const coordinates = feature.get("coordinates");
    //     if (orderData) {
    //       setAssignAgentDialogOpen(true);
    //       setCoordinates(coordinates);
    //       setOrderId(orderData);
    //     }
    //   });
    // });

    map.addInteraction(selectClick);

    return () => {
      map.setTarget(null);
      map.removeInteraction(selectClick);
    };
  }, [data]);


  //function to get user locatio n
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const coords = fromLonLat([longitude, latitude]);
          const userLocationFeature = new Feature({
            geometry: new Point(coords),
          });

          const userLocationLayer = new VectorLayer({
            source: new VectorSource({
              features: [userLocationFeature],
            }),
            style: new Style({
              image: new Icon({
                src: location,
                scale: 1, // Adjust scale if necessary
                color: "blue",
              }),
              text: new Text({
                text: 'Your Location',
                offsetY: -20, // Adjust the offset as necessary
                fill: new Fill({
                  color: 'blue',
                }),
                stroke: new Stroke({
                  color: 'white',
                  width: 5,
                }),
              }),
            }),
          });

          if (mapRef.current) {
            mapRef.current.addLayer(userLocationLayer);
            const view = mapRef.current.getView();
            view.setCenter(coords);
            view.setZoom(12);
          }
        },
        (error) => {
          console.error("Error getting user location:", error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div
      key={data}
      ref={mapElement}
      style={{ width: "100%", height: "100vh" }}
    ></div>
  );
};

export default DistributorLocation;
