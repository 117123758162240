/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./actionType";
import useAxios from "../../Axios/useAxios";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";

export const GetBankDetailsIfsc = (ifsc) => {
  let api = useAxios();
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/ifsc/verify/${ifsc}`);

      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BANK_DETAILS_IFSC,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
