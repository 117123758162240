import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { styles } from "../wfsCanOrders/cardStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { PutSupplierAddDelivertSlots } from "../Redux/Actions/supliersAction";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CustomConsole from "../../CustomConsole";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";

function ReturnCanFormView(props) {
  CustomConsole(props);
  const history = useHistory();
  const dispatch = useDispatch();

  const [singleSelectedData, setSingleSelectedData] = React.useState("");

  const [valueOrderId, setValueOrderId] = React.useState("");

  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  React.useEffect(() => {}, [props]);

  React.useEffect(() => {}, [singleSelectedData]);

  const handleChangeOrderId = (event) => {
    const selectedOrdersData = props.data.find(
      (data) => data.return_can_order_id === event.target.value
    );
    CustomConsole(selectedOrdersData);
    setSingleSelectedData(selectedOrdersData);
    setValueOrderId(event.target.value);
  };
  const handleViewDetails = () => {
    if (singleSelectedData.return_can_order_id) {
      const url = `/dashboard/ReturnCanSingleOrder?order_id=${singleSelectedData.return_can_order_id}`;
      history.push(url);
    } else {
      toast.error("Please Select Order Id", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleReturnCanEnterOTP = () => {
    CustomConsole("_______handleButtonData_______");
    // CustomConsole(props);
    // CustomConsole(props.order_id);
    // setReturnCanOrderId(props.order_id);
    props.handleReturnCanEnterOTP({
      order_id: singleSelectedData.return_can_order_id,
      is_submit_started: singleSelectedData.is_submit_started,
      is_submit_finished: singleSelectedData.is_submit_finished,
    });
    // if (
    //   singleSelectedData?.is_submit_started === false &&
    //   singleSelectedData?.is_submit_finished === false
    // ) {
    //   CustomConsole("------EnterOtp------------");
    //   setEnterOtpOpenDialog(true);
    // } else {
    //   CustomConsole("------Inscan------------");
    //   setOpenQrorCanDamageDialog(true);
    //   // setOpenCamera(true);
    // }
  };
  const handleOpenAssignDeliveryAgentDialog = () => {
    props.handleOpenAssignDeliveryAgentDialog(
      singleSelectedData.return_can_order_id
    );
  };

  return (
    <Material.Paper elevation={14} sx={{ padding: "20px", bgcolor: "#E9F7EF" }}>
      <Material.Box>
        <Material.Box
          sx={{
            // padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "row",
              sm: "row",
              md: "row",
              lg: "row",
            },
            gap: "10px",
          }}
        >
          <Material.Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                alignItems: "center",
              }}
            >
              <Material.InputLabel
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Order ID:
              </Material.InputLabel>
              <Material.Select
                variant="standard"
                sx={{ fontSize: "16px", fontWeight: "bold", width: "190px" }}
                value={valueOrderId}
                label="Order Id"
                onChange={handleChangeOrderId}
              >
                {props.data &&
                  props.data !== undefined &&
                  props.data !== null &&
                  props.data.length > 0 &&
                  props.data.map((orderIds, index) => {
                    return (
                      <Material.MenuItem
                        key={index}
                        value={orderIds.return_can_order_id}
                      >
                        {orderIds.return_can_order_id}
                      </Material.MenuItem>
                    );
                  })}
              </Material.Select>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            textAlign: "center",
          }}
        >
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Id </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData.return_can_order_id}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>No Of Cans </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.container_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Received Can Quantity </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.received_container_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Order Status </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.order_status ===
                    returnCanOrderStatus.APPROVED &&
                  singleSelectedData?.container_qty !==
                    singleSelectedData?.received_container_qty
                    ? "Ongoing"
                    : singleSelectedData?.order_status ===
                      returnCanOrderStatus.REJECTED
                    ? ""
                    : ""}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Capacity </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.container_capacity}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Material </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.container_material}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Delivery Agent ID </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {singleSelectedData?.delivery_agent_id
                    ? singleSelectedData?.delivery_agent_id
                    : "Not Assigned"}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box>
          <Material.Button variant="contained" onClick={handleViewDetails}>
            view Details
          </Material.Button>
        </Material.Box>
        {/* {(singleSelectedData?.delivery_agent_id === undefined ||
          singleSelectedData?.delivery_agent_id === null ||
          parseInt(singleSelectedData?.delivery_agent_id) === 0) &&
        parseInt(singleSelectedData?.order_status) === 200 ? (
          <Material.Box sx={{ marginTop: "10px" }}>
            <Material.Button
              variant="contained"
              sx={{
                // background: appColors.commonBtnColor.background,
                color: "white",
                borderRadius: "10px",
              }}
              onClick={handleOpenAssignDeliveryAgentDialog}
            >
              Assign Delivery Agent
            </Material.Button>
          </Material.Box>
        ) : (
          ""
        )}
        <Material.Box>
          <Material.Button
            variant="contained"
            onClick={handleReturnCanEnterOTP}
            sx={{
              //   background: appColors.commonBtnColor.background,
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
          >
            {singleSelectedData?.is_submit_started === false &&
            singleSelectedData?.is_submit_finished === false
              ? "Enter OTP"
              : singleSelectedData?.is_submit_started === true &&
                singleSelectedData?.is_submit_finished === false
              ? "in-scan"
              : null}
          </Material.Button>
        </Material.Box> */}
      </Material.Box>
    </Material.Paper>
  );
}

export default ReturnCanFormView;
