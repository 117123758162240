import { AddCircleOutline } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WarehouseLevelOperatingCost from "./WarehouseLevelOperatingCost";
import WFSOperatingLevelCostMain from "./WFSOperatingLevelCostMain";
import VehicleLevelOperatingCostMain from "./VehicleLevelOperatingCostMain";
import EmployeeOperatingCostMain from "./EmployeeOperatingCostMain";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import DuseDatesMain from "./DueDatesMain";
import DueDatesMain from "./DueDatesMain";

function OperationsLevelCostMain() {
  const [tabValueOperations, setTabValueOperations] =
    React.useState("duedates");

  const handleChangeTabOperations = (event, newValue) => {
    setTabValueOperations(newValue);
  };
  return (
    <Box sx={{ mt: "60px" }}>
      {[
        ADMIN_ROLES.BW_ADMIN,
        ADMIN_ROLES.BW_SUPERADMIN,
        ADMIN_ROLES.BW_FINANCE,
        ADMIN_ROLES.BW_FINANCEHEAD,
      ].includes(parseInt(localStorage.getItem("roleId"))) ? (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            value={tabValueOperations}
            variant="scrollable"
            scrollButtons={false}
            sx={{ padding: "0px" }}
          >
            <TabList
              onChange={handleChangeTabOperations}
              // className={classes.tabUi}
              sx={{ bgcolor: "#E9F1F7" }}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab label="due bills" value="duedates" />
              <Tab label="warehouse" value="warehouse" />
              <Tab label="wfs" value="wfs" />
              <Tab label="vehicles" value="vehicles" />
              <Tab label="Employees" value="Employees" />
            </TabList>

            <TabPanel value="warehouse" sx={{ padding: "10px 0 0 0" }}>
              <WarehouseLevelOperatingCost />
            </TabPanel>
            <TabPanel value="wfs" sx={{ padding: "10px 0 0 0" }}>
              <WFSOperatingLevelCostMain />
            </TabPanel>
            <TabPanel value="vehicles" sx={{ padding: "10px 0 0 0" }}>
              <VehicleLevelOperatingCostMain />
            </TabPanel>
            <TabPanel value="Employees" sx={{ padding: "10px 0 0 0" }}>
              <EmployeeOperatingCostMain />
            </TabPanel>
            <TabPanel value="duedates" sx={{ padding: "10px 0 0 0" }}>
              <DueDatesMain />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Alert severity="error">Access Denied</Alert>
      )}
    </Box>
  );
}

export default OperationsLevelCostMain;
