import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../Common/drawer.css";
import moment from "moment";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function WfsDetailsTable({
  setWfsDetails,
  scroll,
  setAddWfsFlag,
  setDateFilter,
  setPageNumber,
  setAssigned,
  setInstock,
  instock,
  pageNumber,
  handleClickWfs,
  wfsTabelFlag,
  dateFilter,
  assigned,
  setTrigEffect,
  triggEffect,
  filterEnable,
  setShowDetailsFlag,
}) {
  const addUpdateDetails = useSelector((state) => state.adminAddUpdateWfsData);
  const [disableBtn, setDisableBtn] = React.useState(false);

  //dialoge
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    CustomConsole(`---in table---`);
    CustomConsole(addUpdateDetails.wfsData.length);
    if (addUpdateDetails.wfsData.length === 0 && wfsTabelFlag) {
      setDisableBtn(true);
    }
    if (addUpdateDetails.wfsData.length > 0 && wfsTabelFlag) {
      setDisableBtn(false);
    }
  }, [addUpdateDetails]);

  const handleTableClick = (wfsId) => {
    CustomConsole("--Inside click--");
    setShowDetailsFlag(true);
    CustomConsole(wfsId);
    CustomConsole(addUpdateDetails.wfsData);
    addUpdateDetails.wfsData.map((data) => {
      CustomConsole("--inside map--");
      if (data.wfs_master_id === wfsId) {
        CustomConsole(data);
        setWfsDetails(data);
        setTrigEffect(triggEffect + 1);
      }
    });
    setTimeout(() => {
      scroll();
    }, 200);
    setAddWfsFlag(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const pageForward = () => {
    setPageNumber(pageNumber + 1);
  };

  const pageBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      setDisableBtn(false);
    }
  };

  const filterApply = () => {
    handleClickWfs();
    setPageNumber(1);
    setOpen(false);
  };

  return (
    <>
      <Card>
        {filterEnable ? (
          <Box
            sx={{
              float: "right",
              marginBottom: "8px",
            }}
          >
            <Button variant="contained" onClick={handleClickOpen}>
              Filter
            </Button>
          </Box>
        ) : null}
        {addUpdateDetails.wfsData.length > 0 ? (
          <Tooltip title="Click to update water filling station">
            <CardContent>
              <TableContainer
                component={Paper}
                sx={{ marginTop: "20px" }}
                className="drawer"
              >
                <Table sx={{ width: 1 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Company</StyledTableCell>
                      <StyledTableCell>Owner</StyledTableCell>
                      <StyledTableCell>PAN</StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell>Created</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addUpdateDetails.wfsData.map((row, key) => (
                      <StyledTableRow
                        key={key + 1}
                        onClick={() => handleTableClick(row.wfs_master_id)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          marginTop: 20,
                          cursor: "pointer",
                          "&:hover": {
                            background: "rgb(208,221,242)",
                            backgroundImage:
                              "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
                          },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.company_name}
                        </StyledTableCell>
                        <StyledTableCell>{row.owner_name}</StyledTableCell>
                        <StyledTableCell>
                          {row.company_pan_number}
                        </StyledTableCell>
                        <StyledTableCell>{row.owner_phone}</StyledTableCell>
                        <StyledTableCell>
                          {moment(row.created_at).format("DD/MM/YYYY")}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Tooltip>
        ) : disableBtn ? (
          <b>No Data Available</b>
        ) : null}
        {wfsTabelFlag ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageBackward}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageForward}
                disabled={disableBtn}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box>
        ) : null}
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <h4 style={{ textAlign: "center" }}>WFS Filter</h4>
        <DialogContent
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="space-evenly"
            spacing={2}
          >
            <Grid item md={6}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Date</FormLabel>
                <RadioGroup
                  onChange={(e) => {
                    setDateFilter(e.target.value);
                  }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={dateFilter}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="DESC"
                    control={<Radio />}
                    label="DESC"
                  />
                  <FormControlLabel
                    value="ASC"
                    control={<Radio />}
                    label="ASC"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Assigned WFS
                </FormLabel>
                <RadioGroup
                  onChange={(e) => {
                    setAssigned(e.target.value);
                  }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={assigned}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Assigned"
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Unassigned"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              sx={{
                marginTop: "20px",
              }}
              md={8}
            >
              <TextField
                required
                id="outlined-required"
                label="Enter minimum required instock"
                value={instock}
                onChange={(e) => {
                  setInstock(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              float: "right",
            }}
          >
            <Button variant="contained" onClick={filterApply}>
              Apply
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
