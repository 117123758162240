import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  lostCansList: [],
  notifyUsersMsg: "",
  diableUserCansMsg: "",
};

const adminLostCansReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.GET_LOSTCANS_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        lostCansList: action.payload.data,
      };
    case actionType.SEND_NOTIFICATION_USERS:
      return {
        ...state,
        error: action.payload.error,
        notifyUsersMsg: action.payload.msg,
      };
    case actionType.DISABLE_USER_CANS:
      return {
        ...state,
        error: action.payload.error,
        diableUserCansMsg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default adminLostCansReducer;
