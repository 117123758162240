/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description BookWater App Authentication Page With Login & SignUP Components
 * @date 13/02/23
 * @version 0.0.1 - Initial Release
 */
import React, { useCallback, useEffect, useState } from "react";
import * as Material from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import ConformationDialog from "../../Components/Dialog/conformationDialog";
import { GroupProductsWfsSku } from "../Redux/Actions/skuWfsDetailsAction";

const ScannerGroupingProducts = React.memo(
  ({
    setGroupingProduct,
    groupingProduct,
    handelSubmit,
    handelClear,
    onNewItemScanned,
    type,
    setCancelGrouping,
    cancelGrouping,
  }) => {
    const [qrBarcodeId, setQrBarcodeId] = useState("");
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [dialogMsg, setDialogMsg] = useState("");

    // function for handling handleChange using useCallback
    const handleChange = useCallback((e) => {
      setQrBarcodeId(e.target.value);
    }, []);

    const handleSubmit = (e) => {
      e.preventDefault();
      if (setQrBarcodeId) {
        onNewItemScanned(qrBarcodeId);
      }
      setQrBarcodeId("");

      return;
    };

    // Function for handling handelProceed function
    const handelProceed = () => {
      setConfirmDialog(false);
    };

    CustomConsole(groupingProduct);

    return (
      <div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            padding: "10px",
          }}
        >
          <form onSubmit={handleSubmit}>
            {/* Form element wrapping the TextField */}
            <Material.TextField
              disabled={false}
              autoFocus={true}
              size="small"
              label="QR/Barcode:"
              InputLabelProps={{
                shrink: true,
              }}
              value={qrBarcodeId}
              onChange={handleChange} // Listen for changes in TextField
            />
            <Material.Button type="submit" style={{ display: "none" }} />{" "}
            {/* Hidden submit button */}
          </form>
        </div>
        {type === "group" ? (
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", gap: 2 }}
          >
            <Material.Button
              size="small"
              onClick={handelClear}
              variant="contained"
            >
              Clear Action
            </Material.Button>
            <Material.Button
              size="small"
              onClick={handelSubmit}
              variant="contained"
            >
              Submit
            </Material.Button>
          </Material.Box>
        ) : (
          ""
        )}
        <ConformationDialog
          setConfirmDialog={setConfirmDialog}
          confirmDialog={confirmDialog}
          dialogMsg={dialogMsg}
          handelProceed={handelProceed}
        />
      </div>
    );
  }
);

export default ScannerGroupingProducts;
