import React from "react";

import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { GetSupplierOnGoingAssignedSlotAndAgent } from "../Redux/Actions/supliersAction";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#FBFAF7",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: "7px",
}));

function ReassignFilter({
  supplierPinCodes,
  area,
  setArea,
  selectedPincode,
  setSelectedPincode,
  agentDri,
  setAgentDri,
  pageSizeC,
}) {
  const supplierDeliveries = useSelector(
    (state) => state.supplierDeliveries.getAssignDeliveryAgent
  );
  const [radioValues, setRadioValues] = React.useState("pin");

  // const [agentDri, setAgentDri] = React.useState("");
  const [pincodesArea, setPincodesArea] = React.useState([]);
  const [agent, setAgent] = React.useState([]);
  const [orderID, setOrderId] = React.useState("");

  const dispatch = useDispatch();

  // const agent = [
  //   {
  //     id: 1,
  //     name: "Lebansty",
  //     phone: "8668198181",
  //   },
  //   {
  //     id: 2,
  //     name: "Leb",
  //     phone: "8668198182",
  //   },
  //   {
  //     id: 3,
  //     name: "Valan",
  //     phone: "8668198183",
  //   },
  //   {
  //     id: 4,
  //     name: "Vettel",
  //     phone: "8668198184",
  //   },
  //   {
  //     id: 5,
  //     name: "Max Vestappan",
  //     phone: "8668198185",
  //   },
  // ];

  React.useEffect(() => {
    CustomConsole("----Selected Pincodes----");
    if (selectedPincode) {
      setPincodesArea(() =>
        supplierPinCodes
          .filter((obj) => obj.pincode === selectedPincode)
          .map((filteredObj) => filteredObj.areas)
      );
    }
  }, [selectedPincode, supplierPinCodes]);

  React.useEffect(() => {
    if (supplierDeliveries) {
      setAgent(supplierDeliveries);
    }
  }, [supplierDeliveries]);

  const handleClickSearchPincodeArea = () => {
    if (selectedPincode !== "" || area !== "") {
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          pincode: selectedPincode,
          area: area,
        })
      );
    } else {
      toast.error("Please Select Pincode", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleClickAgent = () => {
    if (agentDri) {
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          agent_id: agentDri,
          page_size: pageSizeC,
        })
      );
    }
  };

  const handleClickOrderId = () => {
    CustomConsole("-------------orderID------------");
    CustomConsole("BTORD-" + orderID);
    if (orderID) {
      const orderId = orderID.trim().toUpperCase();
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          order_id: "BTORD-" + orderId,
        })
      );
    } else {
      toast.error("Please Enter Order Id", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleChangeOrderId = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setOrderId(() => event.target.value);
  };

  return (
    <>
      <Box component={Paper} elevation={3}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radioValues}
            onChange={(e) => setRadioValues(e.target.value)}
          >
            <FormControlLabel value="pin" control={<Radio />} label="Pincode" />

            <FormControlLabel value="agent" control={<Radio />} label="Agent" />
            <FormControlLabel
              value="order"
              control={<Radio />}
              label="Order Id"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {radioValues === "pin" ? (
        <Box sx={{ mt: { md: 2.5, xs: 0.5 } }}>
          <Item variant="elevation" elevation={5}>
            {" "}
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: { sm: "30%", xs: "30%", md: "35%", xl: "35%" },
                }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Pincodes
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedPincode}
                  onChange={(e) => {
                    setSelectedPincode(e.target.value);
                  }}
                  label="Pincodes"
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {supplierPinCodes?.map((obj, key) => {
                    return (
                      <MenuItem value={obj.pincode} key={key}>
                        {obj.pincode}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: { sm: "30%", xs: "30%", md: "35%", xl: "35%" },
                }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Area
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={area}
                  onChange={(e) => {
                    setArea(e.target.value);
                  }}
                  label="Area"
                  size="small"
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {pincodesArea?.[0]?.map((obj, key) => {
                    return (
                      <MenuItem
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        value={obj}
                        key={key}
                      >
                        {obj}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleClickSearchPincodeArea}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Item>
        </Box>
      ) : radioValues === "agent" ? (
        <Box mt={0.5}>
          <Item variant="elevation" elevation={5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <FormControl
                variant="standard"
                sx={{ mb: 1, minWidth: 180, maxWidth: 200 }}
              >
                <InputLabel id="agent-select-standard-label">Agent</InputLabel>
                <Select
                  labelId="agent-select-standard-label"
                  id="agent-select-standard"
                  value={agentDri}
                  onChange={(e) => setAgentDri(e.target.value)}
                  label="Agent"
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {agent.map((obj, key) => {
                    return (
                      <MenuItem key={key} value={obj.driver_id}>
                        {obj.driver_profile?.fullname}-
                        {obj.driver_profile?.phone}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleClickAgent}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Item>
        </Box>
      ) : radioValues === "order" ? (
        <Box mt={0.5}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextField
              sx={{ ml: 1, flex: 1 }}
              // placeholder="Search By Order"
              // label="Search By Order Id"
              value={orderID}
              // onChange={handleChangeOrderId}
              InputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handleChangeOrderId, // Attach the input event handler
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <Material.Typography sx={{ fontWeight: "bold" }}>
                      BTORD-
                    </Material.Typography>
                  </Material.InputAdornment>
                ),
              }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleClickOrderId}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
      ) : null}
    </>
  );
}

export default ReassignFilter;
