import { Box, Typography, Button } from "@mui/material";
import React from "react";
import PromoCodeTypeTable from "./PromoCodeTypeTable";
import { useDispatch, useSelector } from "react-redux";
import { GetAllPromoCodeListAction } from "../Redux/Actions/promocodeAction";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import AddPromoDialog from "./AddPromoDialog";
import PromoTableDialog from "./PromoTableDialog";
import { Grid } from "@mui/material";
import { FilterList } from "@mui/icons-material";

import CustomConsole from "../../CustomConsole";
function PromoCodemain() {
  const [promoId, setPromoId] = React.useState("");
  const [promoCodeType, setPromoCodeType] = React.useState(0);
  const [promoOfferType, setPromoOfferType] = React.useState(0);
  const [promoCodeTableDetails, setPromoCodeTableDetails] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [promoCreatedAt, setPromoCreatedAt] = React.useState("DESC");
  const [promoExpired, setPromoExpired] = React.useState(false);
  const [addPromoDialogFlag, setAddPromoDialogFlag] = React.useState(false);
  const [promoTableDialogFlag, setPromoTableDialogFlag] = React.useState(false);
  const [filterDialogFlag, setFilterDialogFlag] = React.useState(false);
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [promoMasterId, setPromoMasterId] = React.useState("");
  const [promoCreateRepeat, setPromoCreateRepeat] = React.useState(false);

  const [updateClicked, setUpdateClicked] = React.useState(false);
  const [updateData, setUpdateData] = React.useState([]);
  CustomConsole(updateData);

  const dispatch = useDispatch();
  const adminPromoCodeData = useSelector((state) => state.promocodeAdmin);

  const bookingDetails = useSelector((state) => state.adminBookingData);
  CustomConsole(bookingDetails);

  React.useEffect(() => {
    if (pageNumber) {
      dispatch(
        GetAllPromoCodeListAction(
          pageNumber - 1,
          promoExpired,
          promoCodeType,
          promoOfferType,
          promoCreatedAt
        )
      );
      setPageBackWard(true);
      setPageForward(true);
    }
  }, [pageNumber]);

  React.useEffect(() => {
    if (
      adminPromoCodeData.promAllDetails !== undefined &&
      adminPromoCodeData.promAllDetails.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  });

  const handleClickBack = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    setPageBackWard(true);
  };

  const handleClickForward = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber + 1);
    }
    setPageForward(true);
  };
  const clickAddPromo = () => {
    setAddPromoDialogFlag(true);
  };

  const handleEditupdateDialog = () => {
    setPromoTableDialogFlag(true);
  };
  const handleOpenPromoFilter = () => {
    setFilterDialogFlag(true);
    setPromoCodeType(0);
    setPromoOfferType(0);
    setPromoCreatedAt("DESC");
    setPromoExpired(false);
  };
  React.useState(() => {
    CustomConsole(adminPromoCodeData);
    if (adminPromoCodeData.error === false) {
      if (adminPromoCodeData.promAllDetails.length > 0) {
        setPromoCodeTableDetails(adminPromoCodeData.promAllDetails);
      }
    }
  }, [adminPromoCodeData]);

  CustomConsole(promoCodeTableDetails);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { md: "flex-end", xs: "center" },
          gap: "10px",
        }}
      >
        <Box>
          <Button variant="contained" color="secondary" onClick={clickAddPromo}>
            Add Promo
          </Button>
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          // sx={{ margin: "10px" }}
          onClick={handleOpenPromoFilter}
        >
          Filter <FilterList />
        </Button>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              // margin: "30px",
            }}
          >
            <Button onClick={handleClickBack}>
              <ArrowBackIos />
            </Button>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                // marginLeft: "10px",
                // marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Button disabled={pageStatuss} onClick={handleClickForward}>
              <ArrowForwardIos />
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid container direction="column">
        <Grid container direction="column">
          <PromoTableDialog
            promoTableDialogFlag={promoTableDialogFlag}
            setPromoTableDialogFlag={setPromoTableDialogFlag}
            promoId={promoId}
            setPromoId={setPromoId}
            pageNumber={pageNumber}
            promoExpired={promoExpired}
            promoCodeType={promoCodeType}
            promoOfferType={promoOfferType}
            promoCreatedAt={promoCreatedAt}
            updateClicked={updateClicked}
            setUpdateClicked={setUpdateClicked}
          />
          <AddPromoDialog
            addPromoDialogFlag={addPromoDialogFlag}
            setAddPromoDialogFlag={setAddPromoDialogFlag}
            pageNumber={pageNumber}
            promoExpired={promoExpired}
            promoCodeType={promoCodeType}
            promoOfferType={promoOfferType}
            promoCreatedAt={promoCreatedAt}
            setPromoCreateRepeat={setPromoCreateRepeat}
            promoCreateRepeat={promoCreateRepeat}
          />
          <Grid item xs={12} md={12}>
            <PromoCodeTypeTable
              promoOfferType={promoOfferType}
              setPromoOfferType={setPromoOfferType}
              setPromoCodeType={setPromoCodeType}
              promoCodeType={promoCodeType}
              promoCodeTableDetails={promoCodeTableDetails}
              // setPromoCodeTableDetails={setPromoCodeTableDetails}
              handleEditupdateDialog={handleEditupdateDialog}
              promoMasterId={promoMasterId}
              setPromoMasterId={setPromoMasterId}
              promoId={promoId}
              setPromoId={setPromoId}
              updateData={updateData}
              setUpdateData={setUpdateData}
              updateClicked={updateClicked}
              setUpdateClicked={setUpdateClicked}
              pageNumber={pageNumber}
              setPromoCreateRepeat={setPromoCreateRepeat}
              setAddPromoDialogTrig={setAddPromoDialogFlag}
              setFilterDialogFlag={setFilterDialogFlag}
              filterDialogFlag={filterDialogFlag}
              promoCreatedAt={promoCreatedAt}
              promoExpired={promoExpired}
              setPromoCreatedAt={setPromoCreatedAt}
              setPromoExpired={setPromoExpired}
            />
          </Grid>

          {/* </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PromoCodemain;
