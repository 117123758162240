import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";

export const GetWfsOngoingOrders = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  const errorResponse = {
    error: true,
    data: [],
  };
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/wfsOngoingOrders`, { params: props });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WFS_ONGOING_ORDERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_WFS_ONGOING_ORDERS,
        payload: errorResponse,
      });
    }
  };
};

export const GetWfsCompletedOrders = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  const errorResponse = {
    error: true,
    data: [],
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/wfsCompletedOrders`, { params: props });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WFS_COMPLETED_ORDERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_WFS_COMPLETED_ORDERS,
        payload: errorResponse,
      });
    }
  };
};

export const GetWfsAllOrders = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  const errorResponse = {
    error: true,
    data: [],
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/wfsAllOrders`, { params: props });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WFS_ALL_ORDERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_WFS_ALL_ORDERS,
        payload: errorResponse,
      });
    }
  };
};
export const VerifyOtp = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/verifyEmptyContainerOrderOtp`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.VERIFY_OTP,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const PutEditEmptyCanOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/wfs/edit/order`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_EDIT_WFS_ORDER,
          payload: response.data,
        });

        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const GetDistributorReturnCanOrders = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  const errorResponse = {
    error: true,
    data: [],
  };
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/Supplier/getWithdrawalOrderList`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_RETURN_CAN_ORDERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_RETURN_CAN_ORDERS,
        payload: errorResponse,
      });
    }
  };
};
export const ReturnCanVerifyOtp = (returnCanOrderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/Supplier/verifyWithdrawalOrder/${returnCanOrderId}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_RETURN_CAN_VERIFY_OTP,
          payload: response.data,
        });
        if (!response.data.error) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

//Action function for re uploding delivery challan
export const UploadEditedDeliveryChallan = (orderId, fileName) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/postpaid/dc/${orderId}/${fileName}`);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_DELIVERY_CHALLAN,
          payload: response.data,
        });
        if (!response.data.error) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
//This Action for complete Return order
export const CompleteDistributorReturnOrder = (returnCanOrderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/complete_wfs/withdrawalCanOrder/${returnCanOrderId}`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.COMPLETE_DISTRIBUTOR_RETURN_ORDER,
          payload: response.data,
        });
        if (!response.data.error) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
