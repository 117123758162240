import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import SubscriptionBooking from "./SubscriptionBooking";
import { useDispatch, useSelector } from "react-redux";
import { GetSubscriptionDetails } from "../Redux/Actions/adminSkuBookingAction";
import SubscriptionDataTable from "./SubscriptionDataTable";
import CustomConsole from "../../CustomConsole";

function Subscription({
  phoneNumber,
  setOpenBookingDialog,
  subscriptionId,
  setSubscriptionId,
  editSubscriptionDialog,
  setEditSubscriptionDialog,
  editProducts,
  setEditproducts,
  selectedDays,
  setSelectedDays,
  setUpdateSubscriptonText,
  setEditingAdrs,
  editingAdrs,
  setBooktypeSub,
}) {
  const dispatch = useDispatch();
  const skuBookingData = useSelector((state) => state.adminSkuBookingData);
  const [openSubs, setOpenSubs] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  // const [editProducts, setEditproducts] = React.useState([]);

  // const [selectedDays, setSelectedDays] = React.useState([]);
  // const [subscriptionId, setSubscriptionId] = React.useState(0);
  const [discountChecks, setDiscountChecks] = React.useState({
    promo: true,
    waterDrops: true,
  });

  const handleSubsOpen = () => {
    // setOpenSubs(true);
    setOpenBookingDialog(true);
  };

  React.useEffect(() => {
    let reqObj = {
      user_phone: phoneNumber,
    };
    dispatch(GetSubscriptionDetails(reqObj));
  }, []);

  return (
    <Box mt={2}>
      <Box sx={{ float: "right" }}>
        <Button
          variant="contained"
          onClick={() => handleSubsOpen(setEditproducts([]))}
        >
          Create Subscription
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <SubscriptionDataTable
            phoneNumber={phoneNumber}
            setOpen={setOpenSubs}
            editDialog={setOpenEditDialog}
            setEditproducts={setEditproducts}
            setEditingAdrs={setEditingAdrs}
            setSelectedDays={setSelectedDays}
            setSubscriptionId={setSubscriptionId}
            setDiscountChecks={setDiscountChecks}
            setOpenBookingDialog={setOpenBookingDialog}
            editSubscriptionDialog={editSubscriptionDialog}
            setEditSubscriptionDialog={setEditSubscriptionDialog}
            setUpdateSubscriptonText={setUpdateSubscriptonText}
            setBooktypeSub={setBooktypeSub}
          />
        </Grid>
      </Grid>

      {openSubs ? (
        <SubscriptionBooking
          open={openSubs}
          setOpen={setOpenSubs}
          editDialog={setOpenEditDialog}
          edit={openEditDialog}
          editProducts={editProducts}
          editAdrs={editingAdrs}
          daysSubs={selectedDays}
          subscriptionId={subscriptionId}
          discountChecks={discountChecks}
        />
      ) : null}
    </Box>
  );
}

export default Subscription;
