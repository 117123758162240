import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomConsole from "../../CustomConsole";

export default function BannerTypeFilterDialog({
  bannerTypeFilterDialog,
  setBannerTypeFilterDialog,
  setIsEnabled,
  setCreatedAt,
  setBannerTypeFilterFlag,
}) {
  const handleClose = () => {
    setBannerTypeFilterDialog(false);
  };

  return (
    <div>
      <Dialog
        open={bannerTypeFilterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            //   alignItems: "center",
            //   justifyContent: "center",
              gap: "10px",
            }}
          >
            <Box>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  CREATED AT
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(event) => setCreatedAt(event.target.value)}
                >
                  <FormControlLabel
                    value="DESC"
                    control={<Radio />}
                    label="DESC"
                  />
                  <FormControlLabel
                    value="ASC"
                    control={<Radio />}
                    label="ASC"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  ENABLE OR DISABLE
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(event) => setIsEnabled(event.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Enabled"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Disabled"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Button 
                variant="outlined"
                size="small"
                onClick={() => setBannerTypeFilterFlag(true)}
              >
                Filter
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
