import { graphTypes } from "../../../Components/AppMeta/appMetaConfig";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";

export const GetGraphData = ({ fromDate, toDate, orderTy }) => {
    const httpStatus = {
        SUCCESS: 200,
        BAD_REQUEST: 400,
      };
      let api = useAxios();
      return async (dispatch) => {
        try {
          let response = await api.get(
        `SSM/admin/graphsData?from_date=${fromDate}&to_date=${toDate}&order_status=${orderTy}`
      );
          if (response.status === httpStatus.SUCCESS) {
            dispatch({
              type: actionType.GETTING_GRAPH_DATA,
              payload: response.data,
            });
          } else {
            // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
          }
        } catch (error) {
          CustomConsole(error);
        }
      };
};

export const GetOrderTiming = ({ fromDate, toDate, page_size }) => {
  const httpStatus = {
      SUCCESS: 200,
      BAD_REQUEST: 400,
    };
    let api = useAxios();
    return async (dispatch) => {
      try {
        let response = await api.get(
        `OM/detect/delayed/deliveries?from_date=${fromDate}&to_date=${toDate}&page_number=&page_size=${page_size}`
      );
        if (response.status === httpStatus.SUCCESS) {
          dispatch({
            type: actionType.GETTING_ORDER_TIMING,
            payload: response.data,
          });
        } else {
          // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        }
      } catch (error) {
        CustomConsole(error);
      }
    };
};
export const GetActiveSupplierList = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`SSM/activeSuppliers/list`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GETTING_ACTIVE_SUPPLIER_LIST_GRAPH_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetSuppliersOrderData = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
CustomConsole("props:::");
    CustomConsole(props);
    const isSupplier = props.is_supplier;
    try {
      let response = await api.get(`SSM/suppliers/ordersData`, {
        params: props.params,
      });
      if (response.status === httpStatus.SUCCESS) {
if (isSupplier === true) {
        dispatch({
          type: actionType.GETTING_SUPPLIER_ORDERS_DATA,
          payload: response.data,
        });
      } else {
dispatch({
            type: actionType.GETTING_DRIVER_ORDERS_DATA,
            payload: response.data,
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetSalesDataForGraphView = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    CustomConsole("props:::");
    CustomConsole(props);
    let selectedGraphType = "";
    try {
      let response = await api.get(`SSM/revenue/b2bVsb2c`, {
        params: props.params,
      });
      if (response.status === httpStatus.SUCCESS) {
        switch (props.graph_type) {
          case graphTypes.B2B_B2C_REVENUE:
            selectedGraphType = actionType.GET_B2B_B2C_REVENUE;
            break;
          case graphTypes.B2B_QUANTITY_REVENUE:
            selectedGraphType = actionType.GET_B2B_QUANTITY_REVENUE;
            break;
          case graphTypes.B2C_QUANTITY_REVENUE:
            selectedGraphType = actionType.GET_B2C_QUANTITY_REVENUE;
            break;
          default:
            selectedGraphType = "";
            break;
        }
        if (selectedGraphType.length > 0) {
          dispatch({
            type: selectedGraphType,
            payload: response.data,
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetSalesRevenueDataForGraphView = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    CustomConsole("props:::");
    CustomConsole(props);
    let selectedGraphType = "";
    try {
      let response = await api.get(`SSM/revenue/sales/b2bVsb2c`, {
        params: props.params,
      });
      if (response.status === httpStatus.SUCCESS) {
        switch (props.graph_type) {
          case graphTypes.B2B_B2C_REVENUE:
            selectedGraphType = actionType.GET_B2B_B2C_REVENUE;
            break;
          case graphTypes.B2B_B2C_QUANTITY_REVENUE:
            selectedGraphType = actionType.GET_B2B_QUANTITY_REVENUE;
            break;
          case graphTypes.B2C_QUANTITY_REVENUE:
            selectedGraphType = actionType.GET_B2C_QUANTITY_REVENUE;
            break;
          default:
            selectedGraphType = "";
            break;
        }
        if (selectedGraphType.length > 0) {
          dispatch({
            type: selectedGraphType,
            payload: response.data,
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetSalesQuantityDataForGraphView = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    CustomConsole("props:::");
    CustomConsole(props);
    let selectedGraphType = "";
    try {
      let response = await api.get(
        `SSM/revenue/sales/b2bVsb2c?filterType=${props.params.filterType}&data=[${props.params.data}]&graph_type=${props.params.graph_type}&is_b2c=true`
      );
      if (response.status === httpStatus.SUCCESS) {
        switch (props.graph_type) {
          case graphTypes.B2B_B2C_REVENUE:
            selectedGraphType = actionType.GET_B2B_B2C_REVENUE;
            break;
          case graphTypes.B2B_B2C_QUANTITY_REVENUE:
            selectedGraphType = actionType.GET_B2B_QUANTITY_REVENUE;
            break;
          case graphTypes.B2C_QUANTITY_REVENUE:
            selectedGraphType = actionType.GET_B2C_QUANTITY_REVENUE;
            break;
          default:
            selectedGraphType = "";
            break;
        }
        if (selectedGraphType.length > 0) {
          dispatch({
            type: selectedGraphType,
            payload: response.data,
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetRevenueSalesBussinessVsConsumerGraphData = (
  filterType,
  b2bOrb2c,
  data,
  graphType
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `SSM/revenue/sales/b2bVsb2c?filterType=${filterType}&is_b2c=${b2bOrb2c}&data=${data}&graph_type=${graphType}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GETTING_REVENUE_SALES_B2B_B2C_GRAPH_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetRevenueB2BB2CGraphData = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`SSM/revenue/b2bVsb2c`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GETTING_REVENUE_B2B_B2C_GRAPH_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
