/**
 * @author vinay kumar
 * @description Add Address Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  AddUserAddressAdmin,
  EditUserAddress,
} from "../../../Pages/Redux/Actions/userDetailsAdminAction";
// ../Redux/Actions/userDetailsAdminAction
// import { SearchAreasByPincode } from "../Redux/Actions/adminBookingAction";
import { SkuAvailablePincodes } from "../../../Pages/Redux/Actions/skuPincodeAction";

import { useSelector } from "react-redux";
import { appColors } from "../../Assests/AppTheme/appThemeConst";

import Dialog from "@mui/material/Dialog";
import * as Material from "@mui/material";
import {
  GetSupplierDeliveryAgentDetailsAdmin,
  MapEmployeeToWarehouse,
  GetWarehouseWithEmployees,
  RefreshSupplierActions,
} from "../../../Pages/Redux/Actions/supliersAction";

// import { GetWarehouseWithEmployees } from "../..//Redux/Actions/supliersAction";

import MultiSelectChip from "../../Custom/MultiSelectChip";
import { DialogContent } from "@mui/material";
import { employeeType } from "../../AppMeta/appMetaConfig";
import CustomConsole from "../../../CustomConsole";
const required_keys = [
  "contact_name",
  "address_line1",
  "address_line2",
  "address_line3",
  "city",
  "pincode",
  "contact_phone",
  "service_area",
];

const validatePhoneNumber = (input) => {
  const phoneRegex = /^[6-9]{1}[0-9]{9}$/;
  if (!input?.toString().match(phoneRegex)) {
    return false;
  } else {
    return true;
  }
};

function AddorUpdateWarehouseDialog({
  isOpen,
  handleClose,
  details,
  isSubmitDisabled,
  supplierId,
  supplierIntegerId,
}) {
  const dispatch = useDispatch();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Material.Slide direction="up" ref={ref} {...props} />;
  });
  const editAddressFlag = useSelector((state) => state.userDetailsUpdate);
  const supplierDrivers = useSelector((state) => state.supplierDeliveries);
  const [validPincode, setValidPincode] = useState();
  const [serviceAreas, setServiceAreas] = useState([]);
  const [districts, setDistricts] = useState();
  const [countries, setCountries] = useState();

  const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  useEffect(() => {
    setValidPincode(skuPincodeDetails.pincodeData ? true : false);
    const uniqueCountryNames = Array.from(
      new Set(skuPincodeDetails.pincodeData?.map((item) => item.country))
    );
    const serviceAreasText = Array.from(
      new Set(skuPincodeDetails.pincodeData?.map((item) => item.locality))
    );
    const uniqueDistricts = Array.from(
      new Set(skuPincodeDetails.pincodeData?.map((item) => item.district))
    );
    CustomConsole(serviceAreasText);

    setServiceAreas(serviceAreasText || []);
    setCountries(uniqueCountryNames || []);
    setDistricts(uniqueDistricts || []);
    if (!skuPincodeDetails.pincodeData) {
      delete newValues.pincode;
      delete newValues.service_area;
      delete newValues.city;
      delete newValues.country;
      setNewValues({ ...newValues });
    } else {
      let new_values = {
        ...newValues,
        service_area: serviceAreasText.includes(details?.service_area)
          ? details?.service_area
          : "",
        pincode: skuPincodeDetails.pincodeData?.[0]?.pincode,
        city: uniqueDistricts[0],
        country: uniqueCountryNames[0],
      };
      // if (serviceAreasText.includes(details?.service_area)) {
      //   new_values.service_area
      // } else {
      // }
      setNewValues(new_values);
    }
  }, [skuPincodeDetails]);
  const [openDialog, setOpenDialog] = useState();
  const [managers, setManagers] = useState();
  const [is_formFilled, setIs_formFilled] = useState(true);
  const [drivers, setDrivers] = useState();
  const [loaders, setLoaders] = useState();
  const [warehouseDetails, setWarehouseDetails] = useState();
  const [mappingDetails, setmappingDetails] = useState();
  const [newValues, setNewValues] = useState({ address_type: "warehouse" });
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  useState(() => {
    CustomConsole(supplierDeliveries?.warehouseEmployees);
    setWarehouseDetails(supplierDeliveries?.warehouseEmployees || {});
  }, [supplierDeliveries]);
  const warehouseMapped = useSelector(
    (state) => state.supplierDeliveries.warehouseMapped
  );
  useEffect(() => {
    if (details?.address_id || supplierDrivers.actionTriger === false) {
      dispatch(RefreshSupplierActions());

      dispatch(GetWarehouseWithEmployees({ address_id: details?.address_id }));
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          supplier_id: supplierIntegerId,
        })
      );
    }
    return () => {
      supplierDrivers.actionTriger = true;

      // warehouseDetails = {};
      // warehouseMapped = {};
    };
  }, [supplierDrivers.actionTriger]);

  useEffect(() => {
    let managers = [];
    let drivers = [];
    let loaders = [];
    let warehouseDrivers = supplierDeliveries?.warehouseEmployees?.drivers;
    let warehouseManagers = supplierDeliveries?.warehouseEmployees?.managers;
    let warehouseLoaders = supplierDeliveries?.warehouseEmployees?.loaders;
    supplierDeliveries?.getSupplierDriverDataAdmin?.map((item) => {
      if (item.employee_type == employeeType.MANAGER) {
        managers.push({
          id: item.user_id,
          name: item.driver_profile?.fullname,
          is_selected: warehouseManagers?.includes(item.user_id),
        });
      }
      if (item.employee_type == employeeType.DRIVER) {
        drivers.push({
          id: item.user_id,
          name: item.driver_profile?.fullname,
          is_selected: warehouseDrivers?.includes(item.user_id),
        });
      }
      if (item.employee_type == employeeType.LOADER) {
        loaders.push({
          id: item.user_id,
          name: item.driver_profile?.fullname,
          is_selected: warehouseLoaders?.includes(item.user_id),
        });
      }
    });
    CustomConsole(drivers, ":drivers", managers, ":managers");
    setManagers(managers);
    setDrivers(drivers);
    setLoaders(loaders);
  }, [supplierDeliveries]);

  useEffect(() => {
    if (editAddressFlag.editAddressFlag == false) {
      handleClose();
    }
  }, [editAddressFlag.editAddressFlag]);
  useEffect(() => {
    if (details?.address_id) {
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          supplier_id: supplierIntegerId,
        })
      );
      onValueChanged({ key: "pincode", value: details.pincode });
    }
  }, [details, warehouseMapped]);

  const onValueChanged = ({ value, key }) => {
    if (key === "pincode") {
      if (value.length === 6) {
        dispatch(
          SkuAvailablePincodes({
            pincode: value,
            page_number: 1,
            page_size: 50,
            ignore_app_loading_spinner: true,
          })
        );
      } else {
        // skuPincodeDetails.pincodeData = [];
        setIs_formFilled(false);
      }
    } else if (key == "contact_phone") {
      let is_valid = validatePhoneNumber(value);
      if (!is_valid) {
        setIs_formFilled(false);
      }
    }
    if (warehouseDetails?.[key] == value) {
      delete newValues[key];
    } else {
      newValues[key] = value;
    }
    if (details) {
      details[key] = value;
    }
    setNewValues({ ...newValues });
    let is_all_filled = true;
    required_keys.map((key) => {
      CustomConsole(details?.[key], newValues[key]);
      if ((details?.[key] || newValues[key]) && value) {
        CustomConsole(newValues["service_area"]);
        if (
          (key == "pincode" && !newValues["service_area"]) ||
          value.length < 6
        ) {
          is_all_filled = false;
        }
      } else {
        is_all_filled = false;
      }
    });

    setIs_formFilled(is_all_filled);
  };
  const validateIsProperPhone = (value) => {
    let is_valid = validatePhoneNumber(value);
    if (!is_valid) {
      return false;
    } else {
      return true;
    }
  };
  const handleSumbit = async () => {
    let is_all_filled = true;
    required_keys.map((key) => {
      CustomConsole(details?.[key], newValues[key]);
      if (details?.[key] || newValues[key]) {
      } else {
        is_all_filled = false;
      }
    });
    if (!is_all_filled) {
      return;
    }
    if (details?.address_id) {
      dispatch(EditUserAddress(newValues, details.address_id, details.user_id));
    } else {
      dispatch(AddUserAddressAdmin(newValues, supplierId));
    }
  };
  CustomConsole(newValues);
  const mapEmployee = () => {
    setOpenDialog(false);
    CustomConsole(mappingDetails);
    dispatch(
      MapEmployeeToWarehouse({
        is_manager: true,
        employee_user_id: mappingDetails?.user_id,
        warehouse_id: [details.address_id],
        supplier_id: supplierId,
        is_add: mappingDetails?.is_add,
      })
    );
  };
  const onMappingEmployee = ({ user_id, is_add }) => {
    if (!is_add) {
      setmappingDetails({ user_id, is_add });
      CustomConsole(mappingDetails);
      setOpenDialog(true);
    } else {
      dispatch(
        MapEmployeeToWarehouse({
          is_manager: true,
          employee_user_id: user_id,
          supplier_id: supplierId,
          warehouse_id: [details.address_id],
          is_add: is_add,
        })
      );
    }
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Material.CardContent>
            <>
              <Material.Grid container spacing={2}>
                <Material.Grid item md={6} sm={12} xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="WareHouse name"
                    error={!details?.contact_name && !newValues.contact_name}
                    defaultValue={details?.contact_name}
                    InputLabelProps={{ shrink: true }}
                    // value={companyName}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "contact_name",
                      })
                    }
                  />
                </Material.Grid>

                <Material.Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Address line 1"
                    error={!details?.address_line1 && !newValues.address_line1}
                    InputLabelProps={{ shrink: true }}
                    // value={address1}
                    defaultValue={details?.address_line1}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "address_line1",
                      })
                    }
                  />
                </Material.Grid>
                <Material.Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    error={!details?.address_line2 && !newValues.address_line2}
                    label="Address line 2"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={details?.address_line2}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "address_line2",
                      })
                    }
                  />
                </Material.Grid>
                <Material.Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    error={!details?.address_line3 && !newValues.address_line3}
                    label="Address line 3"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={details?.address_line3}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "address_line3",
                      })
                    }
                  />
                </Material.Grid>
                <Material.Grid item md={6} sm={6} xs={12}>
                  <TextField
                    // size="small"
                    label="Pincode"
                    required
                    fullWidth
                    // sx={{ minWidth: "300px" }}
                    error={!validPincode || !newValues.pincode}
                    InputLabelProps={{ shrink: true }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    type="number"
                    // defaultValue={newValues?.pincode}
                    value={newValues?.pincode}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "pincode",
                      })
                    }
                  />
                </Material.Grid>
                <Material.Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="City"
                    required
                    // disabled
                    error={!newValues.city}
                    InputLabelProps={{ shrink: true }}
                    // defaultValue={details?.city}
                    value={newValues.city || ""}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "city",
                      })
                    }
                  />
                </Material.Grid>
                <Material.Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="country"
                    required
                    // disabled
                    error={!newValues.country}
                    InputLabelProps={{ shrink: true }}
                    // defaultValue={details?.country}
                    value={newValues.country || ""}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "country",
                      })
                    }
                  />
                </Material.Grid>
                <Material.Grid item md={6} sm={6} xs={12}>
                  <Material.FormControl fullWidth>
                    <Material.InputLabel
                      id="area-select-small"
                      error={newValues.service_area ? false : true}
                      required
                    >
                      Area
                    </Material.InputLabel>
                    <Material.Select
                      labelId="area-select-small"
                      id="area-select-small"
                      label="Area"
                      // style={{ width: 210 }}
                      fullWidth
                      key={newValues?.service_area}
                      // options={serviceAreas}
                      defaultValue={newValues?.service_area}
                      value={newValues?.service_area}
                      onChange={(event) =>
                        onValueChanged({
                          value: event.target.value,
                          key: "service_area",
                        })
                      }
                    >
                      {serviceAreas?.map((option) => (
                        <Material.MenuItem key={option} value={option}>
                          {option}
                        </Material.MenuItem>
                      ))}
                    </Material.Select>
                  </Material.FormControl>
                </Material.Grid>
                <Material.Grid item md={6} sm={6} xs={12}>
                  <TextField
                    // size="small"
                    label="Contact Phone"
                    required
                    error={
                      !validateIsProperPhone(
                        newValues?.contact_phone == undefined
                          ? details?.contact_phone
                          : newValues?.contact_phone
                      )
                    }
                    sx={{ minWidth: "300px" }}
                    InputLabelProps={{ shrink: true }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    type="number"
                    defaultValue={details?.contact_phone}
                    onChange={(event) =>
                      onValueChanged({
                        value: event.target.value,
                        key: "contact_phone",
                      })
                    }
                  />
                </Material.Grid>
                {details && (
                  <Material.Grid item md={12} sm={12} xs={12}>
                    Allocated Managers:
                    <Material.FormControl border={1} borderColor={"grey"}>
                      <MultiSelectChip
                        onRemoved={(user_id) =>
                          onMappingEmployee({ user_id, is_add: false })
                        }
                        onAdded={(user_id) =>
                          onMappingEmployee({ user_id, is_add: true })
                        }
                        items={managers}
                      />
                    </Material.FormControl>
                  </Material.Grid>
                )}
                {details && (
                  <Material.Grid item md={12} sm={12} xs={12}>
                    Allocated Agents:
                    <Material.FormControl border={1} borderColor={"grey"}>
                      <MultiSelectChip
                        items={drivers}
                        onRemoved={(user_id) =>
                          onMappingEmployee({ user_id, is_add: false })
                        }
                        onAdded={(user_id) =>
                          onMappingEmployee({ user_id, is_add: true })
                        }
                      />
                    </Material.FormControl>
                  </Material.Grid>
                )}
                {details && (
                  <Material.Grid item md={12} sm={12} xs={12}>
                    Allocated Loaders:
                    <Material.FormControl border={1} borderColor={"grey"}>
                      <MultiSelectChip
                        items={loaders}
                        onRemoved={(user_id) =>
                          onMappingEmployee({ user_id, is_add: false })
                        }
                        onAdded={(user_id) =>
                          onMappingEmployee({ user_id, is_add: true })
                        }
                      />
                    </Material.FormControl>
                  </Material.Grid>
                )}
              </Material.Grid>
            </>
          </Material.CardContent>
          {openDialog && (
            <Material.Dialog
              open={openDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setOpenDialog(false)}
              aria-describedby="alert-dialog-slide-description"
              fullWidth
              maxWidth="xs"
            >
              <Material.DialogContent>
                <Material.DialogContentText id="alert-dialog-slide-description">
                  Are you sure You want to remove User?
                </Material.DialogContentText>
              </Material.DialogContent>
              <Material.DialogActions>
                <Material.Button onClick={() => setOpenDialog(false)}>
                  NO
                </Material.Button>
                <Material.Button onClick={() => mapEmployee()}>
                  YES
                </Material.Button>
              </Material.DialogActions>
            </Material.Dialog>
          )}
        </DialogContent>
        <Material.DialogActions>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handleClose}
            sx={{
              background: appColors.commonBtnColor.background,
              borderRadius: "20px",
            }}
          >
            Cancel
          </Material.Button>

          <Material.Button
            variant="contained"
            size="small"
            onClick={() => handleSumbit()}
            disabled={isSubmitDisabled || !is_formFilled}
            sx={{
              marginRight: "10px",
              background: isSubmitDisabled
                ? "gray"
                : appColors.commonBtnColor.background,
              color: isSubmitDisabled ? "black" : "white",
              borderRadius: "20px",
            }}
          >
            {details ? "Update Warehouse" : "Add Warehouse"}
          </Material.Button>
        </Material.DialogActions>
      </Dialog>
    </>
  );
}

export default AddorUpdateWarehouseDialog;
