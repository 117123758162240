import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import styles from "./campaignDetailsStyle";
import CustomConsole from "../../CustomConsole";
import { useSelector } from "react-redux";

function GameActionCard(props) {
  const [campDetails, setCampDetails] = React.useState([]);
  const [promoterEmail, setPromoterEmail] = React.useState("");
  const [userReferral, setUserReferral] = React.useState("");
  const [isReferralError, setIsReferralError] = React.useState(false);
  const gamificationStore = useSelector((state) => state.gamificationReducer);
  React.useEffect(() => {
    CustomConsole("-- GameActionCard PlayAGame Response ---");
    CustomConsole(gamificationStore.playAGame);
    if (
      !gamificationStore.error &&
      gamificationStore.playAGame.responseId &&
      gamificationStore.playAGame.responseId.length
    ) {
      setUserReferral("");
      setPromoterEmail("");
    }
  }, [gamificationStore.playAGame]);
  const handleUserGame = (gameDetails) => {
    CustomConsole("--- handleUserGame ---");
    CustomConsole(gameDetails);
    let playedGameObj = {
      promoter_email: promoterEmail,
      action_id: gameDetails?.data?.action_id || "",
      reward_id: gameDetails?.data?.reward_id || "",
      consumer_referral: userReferral,
    };
    CustomConsole(playedGameObj);
    props.handleUserGameAction(playedGameObj);
    // Hit the API from Here
  };
  return (
    <Material.Grid item xs={12} sm={12} md={6} lg={4}>
      <Material.Paper elevation={8} sx={{ padding: "10px" }}>
        <Material.Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            fontWeight: "bold",
            color: "red",
          }}
        >
          Game Details
        </Material.Typography>
        <Material.Box sx={styles.contenctAlignBox}>
          <Material.Typography sx={styles.heading}>Name:</Material.Typography>
          <Material.Typography sx={styles.answerData}>
            {props.data?.name || "-"}
          </Material.Typography>
        </Material.Box>
        <Material.Box sx={styles.contenctAlignBox}>
          <Material.Typography sx={styles.heading}>
            Description:
          </Material.Typography>
          <Material.Typography sx={styles.answerData}>
            {props.data?.description || "-"}
          </Material.Typography>
        </Material.Box>
        <Material.Box sx={styles.contenctAlignBox}>
          <Material.Typography sx={styles.heading}>
            User Rewards:
          </Material.Typography>
          <Material.Typography sx={styles.answerData}>
            {props.data.reward_points !== undefined
              ? props.data.reward_points
              : "-"}
          </Material.Typography>
        </Material.Box>
        <Material.Box sx={styles.contenctAlignBox}>
          <Material.Typography sx={styles.heading}>
            Sales Points:
          </Material.Typography>
          <Material.Typography sx={styles.answerData}>
            {props.data?.action_points || "-"}
          </Material.Typography>
        </Material.Box>
        <Material.Box
          sx={{
            marginTop: "15px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "100%",
          }}
        >
          <Material.FormControl variant="standard">
            <Material.InputLabel sx={{ fontSize: "14px", fontWeight: "bold" }}>
              Promoter Email
            </Material.InputLabel>
            <Material.Select
              sx={{
                fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
                fontWeight: "bold",
              }}
              value={promoterEmail}
              label="Promoter Email"
              onChange={(event) => setPromoterEmail(event.target.value)}
              startAdornment={
                <Material.InputAdornment position="start">
                  <MuiIcons.MarkEmailUnreadTwoTone />
                </Material.InputAdornment>
              }
            >
              <Material.MenuItem value="">
                <em>None</em>
              </Material.MenuItem>
              {props.promoters &&
                props.promoters.length &&
                props.promoters.map((promoEmails, index) => {
                  return promoEmails.length ? (
                    <Material.MenuItem key={index} value={promoEmails}>
                      {promoEmails}
                    </Material.MenuItem>
                  ) : (
                    ""
                  );
                })}
            </Material.Select>
          </Material.FormControl>
          {props.campstatus ? (
            <Material.TextField
              type="email"
              varient="standard"
              label="Referral Code"
              value={userReferral}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <MuiIcons.SubtitlesOutlined />
                  </Material.InputAdornment>
                ),
              }}
              onChange={(event) =>
                setUserReferral(
                  event.target.value.toUpperCase().replace(/\s/g, "")
                )
              }
              error={userReferral.length > 7 ? false : true}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "blue",
              }}
            />
          ) : (
            ""
          )}
          {props.campstatus ? (
            <Material.Button
              disabled={userReferral.length > 7 ? false : true}
              variant="contained"
              sx={{
                backgroundImage:
                  userReferral.length > 7
                    ? "linear-gradient(#021636,#0c4aad)"
                    : "gray",
                "&:hover": {
                  backgroundImage: "linear-gradient(#6b8df2,#021636)",
                },
              }}
              startIcon={<MuiIcons.EmojiEventsOutlined />}
              onClick={() => handleUserGame(props)}
            >
              Reward User
            </Material.Button>
          ) : (
            ""
          )}
        </Material.Box>
      </Material.Paper>
    </Material.Grid>
  );
}

export default GameActionCard;
