/**
 * @author vinay kumar
 * @description Genral Details Card
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CustomConsole from "../../CustomConsole";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import SyncRounded from "@mui/icons-material/SyncRounded";
import {
  DisableAssignWfsSwitch,
  DeleteUser,
  DisableUserAdmin,
  EnableUserAdmin,
  GetAssignedWfsDetails,
  UpdateUserDetails,
  GetProfileDetails,
  UpdateUserRoleId,
  InfluencerEnableDisable,
  MakeUserInfluencer,
  UpdateReferralCode,
  GetReferralCode,
} from "../Redux/Actions/userDetailsAdminAction";
// import { AddWfsLine } from "../Redux/Actions/adminLineDetailsAction";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import { UsersStyles } from "./UsersStyles";
// import {
//   GetInfluencerDetails,
//   GetDepositDetails,
//   GetUserStockDetails,
// } from "../Redux/Actions/adminInfluencerAction";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GetCansRollBack } from "../Redux/Actions/userDetailsAdminAction";
import { PutCansRollBack } from "../Redux/Actions/userDetailsAdminAction";
import { EnableAssignWfsSwitch } from "../Redux/Actions/userDetailsAdminAction";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { DeliverySlotsEnableDisableSwitchAction } from "../Redux/Actions/userDetailsAdminAction";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../Common/drawer.css";
import { Grid } from "@mui/material";

import * as IconName from "react-icons/fc";

import * as Material from "@mui/material";

import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { QRCodeCanvas } from "qrcode.react";
import { useRef } from "react";

import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import RedeemAmountToAccount from "./RedeemAmountToAccount";
import DeleteUserDialog from "../../Components/Dialog/DeleteUserDialog";
import AddUpdateConsumerProductPrice from "../../Components/Dialog/ProductDiscountCustomer";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
export default function GeneralDetailsCard({
  adminUsers,
  // id,
  userRoleId,
  userIdG,
  setIsUserDetailsOpen,
  refershFlag,
  setrefershFlag,
  roles,
  referralCode,
  setReferralCode,
  // setUpdateFlagCheck,
  // updateFlagCheck,
  setRenderFlag,
  renderFlag,
  deliverySlotStatus,
  setDeliverySlotStatus,
  supplierType,
  influencerSetting,
  setInfluencerSetting,
  influencerValidFrom,
  setInfluencerValidFrom,
  influencerValidTill,
  setInfluencerValidTill,
}) {
  const theme = createTheme();
  const dispatch = useDispatch();
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const influencerDetails = useSelector((state) => state.influencerDetails);
  // const [renderFlag, setRenderFlag] = React.useState(true);
  const [fullname, setFullname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [roleId, setRoleId] = React.useState(0);
  const [userId, setUserId] = React.useState("");
  const [twntyLtrDiscount, setTwntyLtrDiscount] = React.useState(0);
  const [customPrice, setCustomPrice] = React.useState(0);
  const [distributorType, setDistributorType] = React.useState("");
  const [updateUserBtn, setUpdateUserBtn] = React.useState(false);
  const [isSwitchOn, setIsSwitchOn] = React.useState(true);
  const [switchRollBack, setSwitchRollBack] = React.useState(true);
  const [switchRoolBackChange, setSwitchRoolBackChange] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const [isrevertbackenabled, setIsrevertbackenabled] = React.useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = React.useState(false);

  const [changeInSwitch, setChangeInSwitch] = React.useState(false);
  const [showAssignedWfs, setShowAssignedWfs] = React.useState(false);
  const [assignedWfsAddress, setAssignedWfsAddress] = React.useState({});
  const [assignedWfsStock, setAssignedWfsStock] = React.useState({});
  const [switchWFSDetails, setSwitchWFSDetails] = React.useState(true);
  const [wfsId, setWfsId] = React.useState("");
  const [wfsSwitchChange, setWfsSwitchChange] = React.useState(false);
  const [selectedRoleId, setSelectedRoleId] = React.useState("");

  const [changeInInfluencer, setChangeInInfluencer] = React.useState(false);
  const [url, setUrl] = React.useState(
    `https://webapp.bookwater.com/authenticate?referrer=${referralCode}`
  );

  React.useEffect(() => {
    setUrl(
      `https://webapp.bookwater.com/authenticate?referrer=${referralCode}`
    );
  }, [referralCode]);

  const handleQr = (event) => {
    setUrl(event.target.value);
  };
  const qrRef = useRef();

  //usestate for adding product details
  const [conformAddProduct, setConformAddProduct] = React.useState(false);

  //Delivery slot disable enable
  const [switchDeliverySlots, setSwitchDeliverySlots] = React.useState(true);
  // const [deliverySlotStatus, setDeliverySlotStatus] = React.useState(true);
  const [reload, setReload] = React.useState(false);
  const [updatedMail, setUpdatedMail] = React.useState("");

  // Enable and disable influencer
  const handleChangeInfluencer = (event) => {
    CustomConsole(event.target.checked);
    setInfluencerSetting(event.target.checked);
    setChangeInInfluencer(true);
  };

  // make me as influencer function
  const handleInfluencerReq = () => {
    const reqObj = {
      pan_number: "",
      bank_name: "",
      ifsc_number: "",
      account_number: "",
      user_id: userId,
    };
    dispatch(MakeUserInfluencer(reqObj));
  };
  // Getting influencer details

  React.useEffect(() => {
    CustomConsole("-------influencer details------------------");
    CustomConsole(influencerDetails);
  }, [influencerDetails]);

  React.useEffect(() => {
    CustomConsole(distributorType);
    setDistributorType(supplierType);
  }, [supplierType]);
  React.useEffect(() => {
    CustomConsole(influencerSetting);
    if (influencerSetting === true && changeInInfluencer === true) {
      const reqObj = {
        user_id: userId,
        user_type: 111,
        valid_from: influencerValidFrom,
        valid_till: influencerValidTill,
      };
      dispatch(InfluencerEnableDisable(reqObj));
      setChangeInInfluencer(false);
    } else if (influencerSetting === false && changeInInfluencer === true) {
      const reqObj = {
        user_id: userId,
        user_type: 113,
        valid_from: influencerValidFrom,
        valid_till: influencerValidTill,
      };
      dispatch(InfluencerEnableDisable(reqObj));
      setChangeInInfluencer(false);
    }
  }, [influencerSetting]);

  CustomConsole(userRoleId);
  CustomConsole(updateUserDetails.userDepositData);
  CustomConsole(wfsId);
  const handleUpdateUserDetails = () => {
    setUpdateUserBtn(true);
    setTimeout(() => {
      dispatch(GetProfileDetails(userIdG));
    }, 1000);
  };

  CustomConsole(isrevertbackenabled);

  React.useEffect(() => {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var result = reg.test(email);
    var emailResult = false;
    if (roleId === 3000) {
      let regEmail = /^[^@]+@bookwater\.com$/;
      emailResult = regEmail.test(email);
    }
    if (updateUserBtn === true) {
      CustomConsole(parseInt(twntyLtrDiscount) < parseInt(customPrice));
      if (
        phone.length === 10 &&
        result === true &&
        fullname.trim() !== "" &&
        emailResult === false
      ) {
        dispatch(
          UpdateUserDetails(
            fullname,
            phone,
            email,
            roleId,
            twntyLtrDiscount || 0,
            userId,
            distributorType,
            customPrice
          )
        );
      } else {
        toast.error("Please update valid details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUpdateUserBtn(false);
      }

      setUpdateUserBtn(false);
    }
  }, [updateUserBtn]);

  const handleChangeRoles = (event) => {
    CustomConsole(event.target.value);
    setSelectedRoleId(event.target.value);
  };
  const handleRoleIdUpdate = () => {
    const reqObj = {
      user_id: userId,
      role_id: selectedRoleId,
    };
    if (selectedRoleId !== "") {
      CustomConsole("---- handleRoleIdUpdate ----");
      CustomConsole(reqObj);
      // check Email!!!!
      const regex = /@bookwater\.com$/i;
      const testResp = regex.test(updatedMail);
      if (
        testResp &&
        selectedRoleId !== ADMIN_ROLES.BW_DELIVERYAGENT &&
        selectedRoleId !== ADMIN_ROLES.BW_CONSUMER &&
        selectedRoleId !== ADMIN_ROLES.BW_DISTRIBUTER
      ) {
        dispatch(UpdateUserRoleId(reqObj));
      } else if (
        selectedRoleId === ADMIN_ROLES.BW_DELIVERYAGENT ||
        selectedRoleId === ADMIN_ROLES.BW_CONSUMER ||
        selectedRoleId === ADMIN_ROLES.BW_DISTRIBUTER
      ) {
        dispatch(UpdateUserRoleId(reqObj));
      } else {
        toast.error("Please update Mail With Bookwater E-Mail", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      toast.error("Please select role ID to update", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // handleing referral code change

  const handleChangeReferralCode = (event) => {
    CustomConsole(event.target.value);
    const inputValue = event.target.value;
    if (inputValue.length <= 10) {
      setReferralCode(inputValue);
      // setUrl(
      //   `https://webapp.bookwater.com/authenticate?referrer=${inputValue}`
      // );
    }
    // setReferralCode(event.target.value);
  };

  // handleing edit referral code click
  const handleClickEditReferral = () => {
    const reqObj = {
      user_id: userId,
      edited_refCode: referralCode,
    };
    dispatch(UpdateReferralCode(reqObj));
    setReload(true);

    setTimeout(() => {
      dispatch(GetReferralCode(userId));
    }, 1000);
    setTimeout(() => {
      setReload(false);
    }, 1500);
  };

  const handleTwntyLtrChange = (event) => {
    if (event.target.value >= 0 && event.target.value <= 99) {
      setTwntyLtrDiscount(event.target.value);
    } else {
      toast.error("Cannot be less than zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleCustomPriceChange = (event) => {
    if (event.target.value >= 0) {
      setCustomPrice(event.target.value);
    } else {
      toast.error("Cannot be less than zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleDeleteUser = () => {
    // dispatch(DeleteUser(userId));
    // setIsUserDetailsOpen(false);
    setDeleteUserDialog(true);
  };

  const handleCloseDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };
  const handleDeleteUserDialog = () => {
    dispatch(DeleteUser(userId));
    setIsUserDetailsOpen(false);
    setDeleteUserDialog(false);
  };

  React.useEffect(() => {
    if (userRoleId === ADMIN_ROLES.BW_WFSADMIN) {
      dispatch(GetAssignedWfsDetails(userIdG));
    }
  }, [refershFlag]);

  React.useEffect(() => {
    CustomConsole(ADMIN_ROLES.BW_ADMIN);
    CustomConsole(
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN
    );
  }, []);

  React.useEffect(() => {
    CustomConsole("--------updateUserDetails---------");
    CustomConsole(updateUserDetails);
    // CustomConsole(updateUserDetails.userProfileData.length);
    // CustomConsole(updateUserDetails.userProfileData.consumer);
    if (updateUserDetails.error === false) {
      setIsrevertbackenabled(updateUserDetails.is_revert_back_enabled);
      // CustomConsole(updateUserDetails.userProfileData.length);
      // CustomConsole(Object.keys(updateUserDetails.userProfileData).length);
      if (
        updateUserDetails.userProfileData !== undefined &&
        updateUserDetails.userProfileData !== null &&
        Object.keys(updateUserDetails.userProfileData).length > 0
      ) {
        CustomConsole("--------updateUserDetails---------");
        CustomConsole(updateUserDetails.userProfileData.user.twenty_ltr_discount);
        CustomConsole(
          updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        setTwntyLtrDiscount(
          updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        setCustomPrice(
          updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        CustomConsole("--------custom_twentyltr_price---------");
      }
      setUpdateUserBtn(false);
      // setUpdateFlagCheck(false);
    }
    if (updateUserDetails.error === false && updateUserBtn === true) {
      CustomConsole("Profile");

      CustomConsole("Profile");
      dispatch(GetProfileDetails(userIdG));
      setUpdateUserBtn(false);
    }
    // setTwntyLtrDiscount("");
    // setCustomPrice("");
  }, [updateUserDetails]);

  React.useEffect(() => {
    if (
      updateUserDetails.userProfileData !== undefined &&
      updateUserDetails.userProfileData.consumer !== undefined &&
      updateUserDetails.userProfileData.consumer.user_type !== undefined &&
      updateUserDetails.userProfileData.consumer.user_type !== null &&
      renderFlag === true &&
      userRoleId === ADMIN_ROLES.BW_CONSUMER
    ) {
      if (updateUserDetails.userProfileData.consumer.user_type === "111") {
        setInfluencerSetting(true);
      } else {
        setInfluencerSetting(false);
      }
      setInfluencerValidFrom(
        updateUserDetails.userProfileData.consumer.valid_from
      );
      setInfluencerValidTill(
        updateUserDetails.userProfileData.consumer.valid_till
      );
      setRenderFlag(false);
    }
  }, [updateUserDetails.userProfileData.consumer]);
  React.useEffect(() => {
    CustomConsole(updateUserDetails);
    if (updateUserDetails.error === false) {
      setAssignedWfsAddress(updateUserDetails.assignedWfsAddress);
      updateUserDetails.assignedWfsStock.map((data) => {
        setAssignedWfsStock(data);
      });
    }
  }, [updateUserDetails]);

  React.useEffect(() => {
    CustomConsole(adminUsers);
    adminUsers.map((data, key) => {
      CustomConsole(data);
      setFullname(data.fullname);
      setPhone(data.phone);
      setEmail(data.email);
      setUpdatedMail(data.email);
      setRoleId(data.role_id);
      setUserId(data.id);
      setIsSwitchOn(data.status);
    });
    if (adminUsers.length) {
      dispatch(GetCansRollBack(adminUsers[0].id));
    }
  }, [adminUsers]);

  React.useEffect(() => {
    if (changeInSwitch === true && isSwitchOn === true) {
      toast.success(updateUserDetails.enableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
    if (changeInSwitch === true && isSwitchOn === false) {
      toast.success(updateUserDetails.disableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
  }, [updateUserDetails]);

  React.useEffect(() => {
    if (wfsSwitchChange === true && switchWFSDetails === true) {
      toast.success("No Enable Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setWfsSwitchChange(false);
    }
    if (wfsSwitchChange === true && switchWFSDetails === false) {
      toast.success(updateUserDetails.wfsDiasbleuserSitchMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setWfsSwitchChange(false);
    }
  }, [updateUserDetails]);

  const handleChangeSwitch = (event) => {
    setChangeInSwitch(true);
    if (event.target.checked) {
      setIsSwitchOn(true);
      dispatch(EnableUserAdmin(userId));
    } else if (event.target.checked === false) {
      setIsSwitchOn(false);
      dispatch(DisableUserAdmin(userId));
    }
  };

  const handleClickRollaback = (event) => {
    CustomConsole("------handleClickRollaback------");
    const reqobj = {
      user_id: userId,
      is_revert_back_enabled: event.target.checked,
    };
    setIsrevertbackenabled(() => event.target.checked);
    CustomConsole(reqobj);
    dispatch(PutCansRollBack(reqobj));
  };

  const handleSwitchDeliverySlots = (event) => {
    CustomConsole("------handleSwitchDeliverySlots------");
    const reqObj = {
      user_id: userId,
      delivery_slots_status: true,
    };
    if (event.target.checked) {
      setDeliverySlotStatus(true);
      reqObj.delivery_slots_status = true;
    } else if (event.target.checked === false) {
      setDeliverySlotStatus(false);
      reqObj.delivery_slots_status = false;
    }
    CustomConsole(reqObj);
    dispatch(DeliverySlotsEnableDisableSwitchAction(reqObj));
  };

  const handleShowAssignedWfs = () => {
    if (updateUserDetails.assignedWfsAddress.pincode !== undefined) {
      setShowAssignedWfs(true);
      if (refershFlag === true) {
        setSwitchWFSDetails(true);
      }
    } else {
      toast.error(
        "Water Filling Station Not Assigned to the incharge,Please Contact BookWater Consumer Support!!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  CustomConsole(updateUserDetails.assignedWfsAddress.pincode);
  React.useEffect(() => {
    setWfsId(updateUserDetails.assignedWfsAddress.wfs_id);
    CustomConsole("-----  updateUserDetails.assignedWfsAddress.address -------");
    CustomConsole(updateUserDetails.assignedWfsAddress.address);
    if (
      updateUserDetails.assignedWfsAddress.address !== undefined &&
      Object.keys(updateUserDetails.assignedWfsAddress.address).length
    ) {
      setWfsInchargeAddress(() => {
        return [
          AddressData(
            "District",
            updateUserDetails.assignedWfsAddress.address.District !== undefined
              ? updateUserDetails.assignedWfsAddress.address.District
              : "District Not Available!"
          ),
          AddressData(
            "Pincode",
            updateUserDetails.assignedWfsAddress.address.Pincode !== undefined
              ? updateUserDetails.assignedWfsAddress.address.Pincode
              : "Pincode Not Available!"
          ),
          AddressData(
            "State",
            updateUserDetails.assignedWfsAddress.address.State !== undefined
              ? updateUserDetails.assignedWfsAddress.address.State
              : "State Not Available!"
          ),
          AddressData(
            "Street",
            updateUserDetails.assignedWfsAddress.address.Street !== undefined
              ? updateUserDetails.assignedWfsAddress.address.Street
              : "Street  Not Available!"
          ),
          AddressData(
            "Village",
            updateUserDetails.assignedWfsAddress.address.Village !== undefined
              ? updateUserDetails.assignedWfsAddress.address.Village
              : "Village  Not Available!"
          ),
          AddressData(
            "Country",
            updateUserDetails.assignedWfsAddress.address.country !== undefined
              ? updateUserDetails.assignedWfsAddress.address.country
              : "Country  Not Available!"
          ),
        ];
      });
    } else {
      setWfsInchargeAddress([
        { District: "District Not found" },
        { Pincode: "Pincode Not found" },
        { State: "State Not found" },
        { Village: "Village Not found" },
        { Country: "Country Not found" },
      ]);
    }
  }, [updateUserDetails]);

  const handleWFSSwitch = (event) => {
    setWfsSwitchChange(true);
    if (event.target.checked) {
      setSwitchWFSDetails(true);

      //  dispatch(EnableAssignWfsSwitch(wfsId))
    } else if (event.target.checked === false) {
      setSwitchWFSDetails(false);
      dispatch(DisableAssignWfsSwitch(wfsId));
      setShowAssignedWfs(false);
      setrefershFlag(false);
    }
  };

  function createData(name, calories) {
    return { name, calories };
  }

  const rows = [
    createData("WFS name", assignedWfsAddress.company_name),
    createData("Owner name", assignedWfsAddress.owner_name),
    createData("Owner phone", assignedWfsAddress.owner_phone),
    createData("Pincode", assignedWfsAddress.pincode),
  ];

  function AddressData(title, data) {
    return { title, data };
  }

  const [wfsInchargeAddress, setWfsInchargeAddress] = React.useState([
    { District: "" },
    { Pincode: "" },
    { State: "" },
    { Village: "" },
    { Country: "" },
  ]);

  //  const wfsInchargeAddress2 = [
  //   AddressData(
  //     "District",
  //     updateUserDetails.assignedWfsAddress.address !== undefined &&
  //       updateUserDetails.assignedWfsAddress.address.length &&
  //       updateUserDetails.assignedWfsAddress.address.District !== undefined
  //       ? updateUserDetails.assignedWfsAddress.address.District
  //       : "District Not Available!"
  //   ),
  //   AddressData(
  //     "Pincode",
  //     updateUserDetails.assignedWfsAddress.address !== undefined &&
  //       updateUserDetails.assignedWfsAddress.address.length &&
  //       updateUserDetails.assignedWfsAddress.address.Pincode !== undefined
  //       ? updateUserDetails.assignedWfsAddress.address.Pincode
  //       : "Pincode Not Available!"
  //   ),
  //   AddressData("State",
  //   updateUserDetails.assignedWfsAddress.address !== undefined &&
  //       updateUserDetails.assignedWfsAddress.address.length &&
  //       updateUserDetails.assignedWfsAddress.address.State !== undefined
  //       ? updateUserDetails.assignedWfsAddress.address.State
  //       : "State Not Available!"
  //       ),
  //   AddressData("Street",
  //   updateUserDetails.assignedWfsAddress.address !== undefined &&
  //       updateUserDetails.assignedWfsAddress.address.length &&
  //       updateUserDetails.assignedWfsAddress.address.Street !== undefined
  //       ? updateUserDetails.assignedWfsAddress.address.Street
  //       : "Street  Not Available!"
  //       ),
  //   AddressData(
  //     "Village",
  //     updateUserDetails.assignedWfsAddress.address !== undefined &&
  //       updateUserDetails.assignedWfsAddress.address.length &&
  //       updateUserDetails.assignedWfsAddress.address.Village !== undefined
  //       ? updateUserDetails.assignedWfsAddress.address.Village
  //       : "Village  Not Available!"

  //   ),
  //   AddressData(
  //     "Country",updateUserDetails.assignedWfsAddress.address !== undefined &&
  //     updateUserDetails.assignedWfsAddress.address.length &&
  //     updateUserDetails.assignedWfsAddress.address.country !== undefined
  //     ? updateUserDetails.assignedWfsAddress.address.country
  //     : "Country  Not Available!"
  //   ),
  // ];

  function createDataStock(name, calories) {
    return { name, calories };
  }

  const rowsStock = [
    createDataStock("Container material", assignedWfsStock.container_capacity),
    createDataStock("Container capacity", assignedWfsStock.container_material),
    createDataStock("Empty container", assignedWfsStock.empty_containers),
    createDataStock("Filled container", assignedWfsStock.filled_containers),
    createDataStock(
      "Physically damaged",
      assignedWfsStock.physically_damaged_containers
    ),
    createDataStock(
      "QR/Barcode damaged",
      assignedWfsStock.qr_bar_code_damaged_containers
    ),
    createDataStock("Ongoing containers", assignedWfsStock.ongoing_containers),
  ];

  const handleDistributorTypeChange = (event) => {
    CustomConsole(event.target.value);
    setDistributorType(event.target.value);
  };

  // Redeem water drop handling button click function
  const [openRedeem, setOpenRedeem] = React.useState(false);

  const handleClickRedeemWaterDrop = () => {
    CustomConsole("Redeem water drop");
    setOpenRedeem(true);
  };
  const qrcode = (
    <Paper elevation={14} sx={{ padding: "10px" }}>
      <QRCodeCanvas
        id="qrCode"
        value={url}
        size={100}
        bgColor={"white"}
        level={"H"}
        onChange={handleQr}
      />
    </Paper>
  );

  //useffect for calling product details function
  React.useEffect(() => {
    dispatch(GetAvailableProducts());
  }, []);

  return (
    <>
      <Paper elevation={24} sx={UsersStyles.cardshadow}>
        <Box>
          <Box>
            {/* Showing Genral Details For Users here */}
            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box>
                  <Box
                    component="form"
                    //   onSubmit={handleSubmit}
                    noValidate
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                      }}
                    ></Box>
                    <Material.Grid container spacing={2}>
                      <Material.Grid item md={3} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          id="FullName"
                          label="FullName"
                          value={fullname}
                          onChange={(event) => setFullname(event.target.value)}
                          InputProps={{
                            startAdornment: (
                              <Material.InputAdornment position="start">
                                <IconName.FcManager size="30px" />
                              </Material.InputAdornment>
                            ),
                          }}
                        />
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          id="Phone"
                          label="Phone"
                          value={phone}
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          onChange={(event) => setPhone(event.target.value)}
                          InputProps={{
                            startAdornment: (
                              <Material.InputAdornment position="start">
                                <IconName.FcPhoneAndroid size="30px" />
                              </Material.InputAdornment>
                            ),
                          }}
                        />
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          id="Email"
                          label="Email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          InputProps={{
                            startAdornment: (
                              <Material.InputAdornment position="start">
                                <IconName.FcInvite size="30px" />
                              </Material.InputAdornment>
                            ),
                          }}
                        />
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          id="Role"
                          label="Role"
                          disabled={true}
                          value={
                            roleId === ADMIN_ROLES.BW_DISTRIBUTER
                              ? "Supplier"
                              : [
                                  roleId === ADMIN_ROLES.BW_CONSUMER
                                    ? "Consumer"
                                    : [
                                        roleId === ADMIN_ROLES.BW_DELIVERYAGENT
                                          ? "Delivery Agent"
                                          : [
                                              roleId ===
                                              ADMIN_ROLES.BW_SUPERADMIN
                                                ? "Super Admin"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_ADMIN
                                                ? "Admin"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_DELIVERYAGENT
                                                ? "Delivery agent"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_WFSADMIN
                                                ? "Filling Station Incharge"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_FINANCE
                                                ? "Finance"
                                                : roleId === ADMIN_ROLES.BW_CS
                                                ? "Customer Support"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_FINANCEHEAD
                                                ? "Finance Head"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_CSHEAD
                                                ? "Customer Support Head"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_ADMIN
                                                ? "Admin"
                                                : roleId === ADMIN_ROLES.BW_OPS
                                                ? "Operation"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_OPSHEAD
                                                ? "Operation Head"
                                                : roleId === ADMIN_ROLES.BW_QA
                                                ? "QA"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_QAHEAD
                                                ? "QA Head"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_SALES
                                                ? "Sales"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_SALESHEAD
                                                ? "Sales Head"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_SUPERADMIN
                                                ? "Super Admin"
                                                : roleId ===
                                                  ADMIN_ROLES.BW_CSLEAD
                                                ? "Customer Support Lead"
                                                : roleId === ADMIN_ROLES.BW_IVML
                                                ? "Inventory Lead"
                                                : "",
                                            ],
                                      ],
                                ]
                          }
                          // onChange={(event) => setRoleId(event.target.value)}
                        />
                      </Material.Grid>
                      <Material.Grid item md={12} sm={6} xs={12}>
                        {roleId === 1002 ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "15px",
                              borderRadius: "10px",
                              padding: "10px",
                              width: "100%",
                              marginTop: "20px",
                              background:
                                appColors.commonLayoutColor.background,
                              backgroundImage:
                                appColors.commonLayoutColor.backgroundImage,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              {" "}
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  User's Status
                                </Typography>
                              </Box>
                              <Box>
                                {parseInt(localStorage.getItem("roleId")) ===
                                  ADMIN_ROLES.BW_ADMIN ||
                                parseInt(localStorage.getItem("roleId")) ===
                                  ADMIN_ROLES.BW_SUPERADMIN ||
                                parseInt(localStorage.getItem("roleId")) ===
                                  ADMIN_ROLES.BW_CSHEAD ? (
                                  <FormControlLabel
                                    control={<Switch checked={isSwitchOn} />}
                                    label={
                                      isSwitchOn ? (
                                        <Typography
                                          sx={{
                                            color: "black",
                                          }}
                                        >
                                          Enabled
                                        </Typography>
                                      ) : (
                                        <Typography
                                          sx={{
                                            color: "black",
                                          }}
                                        >
                                          Disabled
                                        </Typography>
                                      )
                                    }
                                    onChange={handleChangeSwitch}
                                  />
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Material.Grid>
                      {roleId === 1002 ? (
                        <Material.Grid item md={12}>
                          {userRoleId !== 3000 ? (
                            <>
                              {" "}
                              {parseInt(localStorage.getItem("roleId")) ===
                              ADMIN_ROLES.BW_SUPERADMIN ? (
                                <Box
                                  className="drawer"
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px",
                                  }}
                                >
                                  {roles.length > 0 && (
                                    <Box>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 200 }}
                                        size="small"
                                      >
                                        <InputLabel id="demo-select-small">
                                          Select role to add
                                        </InputLabel>
                                        <Select
                                          labelId="demo-select-small"
                                          id="demo-select-small"
                                          value={selectedRoleId}
                                          label="Select role to add"
                                          onChange={handleChangeRoles}
                                        >
                                          {roles.map((role) => {
                                            return (
                                              <MenuItem value={role.role_id}>
                                                {role.role_name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  )}
                                  <Box>
                                    <Button
                                      onClick={handleRoleIdUpdate}
                                      sx={{
                                        background:
                                          appColors.commonBtnColor.background,
                                      }}
                                      size="small"
                                      variant="contained"
                                    >
                                      Update Role
                                    </Button>
                                  </Box>
                                </Box>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Material.Grid>
                      ) : (
                        ""
                      )}
                      <Material.Grid item md={6}>
                        {userRoleId === 3000 && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item md={12}>
                                <Card elevation={10}>
                                  <CardContent>
                                    <Grid item md={12} sm={12} xs={12}>
                                      <Box>
                                        <Typography
                                          sx={{
                                            textAlign: "center",
                                            marginBottom: "10px",
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                            marginTop: "10px",
                                          }}
                                        >
                                          Individual customer packaged water
                                          discount
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent={"center"}
                                    >
                                      <Grid item md={6} sm={12} xs={12}>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          onClick={() =>
                                            setConformAddProduct(true)
                                          }
                                        >
                                          View Product Details
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                              <Grid item md={12}>
                                <Card elevation={10}>
                                  <CardContent>
                                    <Grid item md={12} sm={12} xs={12}>
                                      <Box>
                                        <Typography
                                          sx={{
                                            textAlign: "center",
                                            marginBottom: "10px",
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                            marginTop: "10px",
                                          }}
                                        >
                                          Redeem Water Drop
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent="center"
                                    >
                                      <Grid item md={6} sm={12} xs={12}>
                                        <Button
                                          variant="outlined"
                                          onClick={handleClickRedeemWaterDrop}
                                        >
                                          Request To Redeem
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                            <RedeemAmountToAccount
                              setOpenRedeem={setOpenRedeem}
                              openRedeem={openRedeem}
                              userId={userId}
                            />
                          </>
                        )}
                        <Material.Grid
                          item
                          md={6}
                          marginLeft="70px"
                          marginTop="20px"
                        >
                          {userRoleId === 2000 && (
                            <Box>
                              <FormControl
                                onChange={handleDistributorTypeChange}
                                value={distributorType}
                              >
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  value={distributorType}
                                >
                                  <FormControlLabel
                                    disabled={true}
                                    value="1"
                                    control={<Radio />}
                                    label="Tank water"
                                  />
                                  <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Packaged water"
                                  />
                                  <FormControlLabel
                                    disabled={true}
                                    value="3"
                                    control={<Radio />}
                                    label="Both"
                                  />
                                </RadioGroup>
                              </FormControl>

                              {distributorType === undefined ||
                              distributorType === null ||
                              distributorType === "" ? (
                                <Box
                                  sx={{
                                    background:
                                      appColors.commonLayoutColor.background,
                                    backgroundImage:
                                      appColors.commonLayoutColor
                                        .backgroundImage,
                                    borderRadius: "10px",
                                    padding: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    This Distributor is not packaged drinking
                                    water distributor. Please selected Packaged
                                    Water and Update.
                                  </Typography>
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                          )}
                        </Material.Grid>
                      </Material.Grid>

                      <Material.Box
                        sx={{ padding: "10px", marginLeft: "50px" }}
                      >
                        {roleId != 1002 ? (
                          <Material.Box sx={{ padding: "10px" }}>
                            {/* <Material.Grid item md={6}> */}
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "15px",
                                borderRadius: "10px",
                                padding: "10px",
                                width: "100%",
                                background:
                                  appColors.commonLayoutColor.background,
                                backgroundImage:
                                  appColors.commonLayoutColor.backgroundImage,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                {" "}
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    User's Status
                                  </Typography>
                                </Box>
                                <Box>
                                  {parseInt(localStorage.getItem("roleId")) ===
                                    ADMIN_ROLES.BW_ADMIN ||
                                  parseInt(localStorage.getItem("roleId")) ===
                                    ADMIN_ROLES.BW_SUPERADMIN ||
                                  parseInt(localStorage.getItem("roleId")) ===
                                    ADMIN_ROLES.BW_CSHEAD ? (
                                    <FormControlLabel
                                      control={<Switch checked={isSwitchOn} />}
                                      label={
                                        isSwitchOn ? (
                                          <Typography>Enabled</Typography>
                                        ) : (
                                          <Typography>Disabled</Typography>
                                        )
                                      }
                                      onChange={handleChangeSwitch}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            {/* </Material.Grid> */}
                          </Material.Box>
                        ) : (
                          ""
                        )}
                        <Material.Box sx={{ padding: "10px" }}>
                          {/* <Material.Grid item md={3}> */}
                          {(parseInt(localStorage.getItem("roleId")) ===
                            ADMIN_ROLES.BW_ADMIN ||
                            parseInt(localStorage.getItem("roleId")) ===
                              ADMIN_ROLES.BW_SUPERADMIN) &&
                          userRoleId === ADMIN_ROLES.BW_CONSUMER ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "15px",
                                borderRadius: "10px",
                                padding: "10px",
                                // backgroundColor: "#6391db",
                                background:
                                  appColors.commonLayoutColor.background,
                                backgroundImage:
                                  appColors.commonLayoutColor.backgroundImage,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                {" "}
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Influencer
                                  </Typography>
                                </Box>
                                <Box>
                                  <FormControlLabel
                                    control={
                                      <Switch checked={influencerSetting} />
                                    }
                                    label={
                                      influencerSetting ? (
                                        <Typography>Enabled</Typography>
                                      ) : (
                                        <Typography>Disabled</Typography>
                                      )
                                    }
                                    onChange={handleChangeInfluencer}
                                  />
                                </Box>
                              </Box>

                              {influencerValidFrom !== "" &&
                              influencerValidTill !== "" ? (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textAlign: "center",
                                        color: "black",
                                      }}
                                    >
                                      Valid from {influencerValidFrom}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        textAlign: "center",
                                        color: "black",
                                      }}
                                    >
                                      Valid till {influencerValidTill}
                                    </Typography>
                                  </Box>
                                </>
                              ) : (
                                ""
                              )}
                              <Box>
                                <Button
                                  variant="contained"
                                  sx={{
                                    background:
                                      appColors.commonBtnColor.background,
                                  }}
                                  size="small"
                                  onClick={handleInfluencerReq}
                                >
                                  Make this user as influencer
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            ""
                          )}

                          {/* Referral code edit JSX */}
                          <>
                            {userRoleId === 3000 && (
                              <Box>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    padding: "10px",
                                  }}
                                >
                                  Referral code
                                </Typography>

                                <Box sx={{ display: "flex", gap: "10px" }}>
                                  {reload === false ? (
                                    <TextField
                                      size="small"
                                      label="Referral code"
                                      InputProps={{ maxLength: 10 }}
                                      value={referralCode}
                                      onChange={handleChangeReferralCode}
                                    />
                                  ) : (
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <CircularProgress
                                          size={20}
                                          thickness={3}
                                        />
                                      </Box>
                                    </>
                                  )}

                                  <Button
                                    variant="contained"
                                    sx={{
                                      background:
                                        appColors.commonBtnColor.background,
                                    }}
                                    onClick={handleClickEditReferral}
                                  >
                                    Update
                                  </Button>
                                </Box>
                                <Box sx={{ margin: "10px" }} ref={qrRef}>
                                  <Typography>
                                    Scan to Register with BookWater <br />
                                    by the referrel code
                                  </Typography>
                                  {qrcode}
                                </Box>
                              </Box>
                            )}
                          </>

                          {/* </Material.Grid> */}
                          {/* {userRoleId !== 3000 ? ( */}
                          {/* <>
                            {" "}
                            {!(roleId === 2000 || roleId === 2001 || roleId ===3000|| userRoleId === 3000) ? (
                              <Box>
                                {parseInt(localStorage.getItem("roleId")) ===
                                ADMIN_ROLES.BW_SUPERADMIN ? (
                                  <Box
                                    className="drawer"
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    {roles.length > 0 && (
                                      <Box>
                                        <FormControl
                                          sx={{ m: 1, minWidth: 200 }}
                                          size="small"
                                        >
                                          <InputLabel id="demo-select-small">
                                            Select role to add
                                          </InputLabel>
                                          <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={selectedRoleId}
                                            label="Select role to add"
                                            onChange={handleChangeRoles}
                                          >
                                            {roles.map((role) => {
                                              return (
                                                <MenuItem value={role.role_id}>
                                                  {role.role_name}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    )}
                                    <Box>
                                      <Button
                                        onClick={handleRoleIdUpdate}
                                        sx={{
                                          background:
                                            appColors.commonBtnColor.background,
                                        }}
                                        size="small"
                                        variant="contained"
                                      >
                                        Update Role
                                      </Button>
                                    </Box>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            ) : (
                              ""
                            )}
                          </> */}
                          {/* ) : (
                          ""
                        )} */}
                        </Material.Box>
                      </Material.Box>
                      <Material.Grid
                        direction="row"
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="center"
                        alignItems="center"
                        gap="10px"
                        item
                        md={12}
                      >
                        {parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_ADMIN ||
                        parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_SUPERADMIN ||
                        parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_CSHEAD ||
                        parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_SALESHEAD ||
                        parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_FINANCEHEAD ? (
                          <Box>
                            <Button
                              variant="contained"
                              size="40px"
                              onClick={handleUpdateUserDetails}
                              sx={{
                                margin: "5px",
                                background: appColors.commonBtnColor.background,
                              }}
                            >
                              <SyncRounded />
                              Update
                            </Button>
                          </Box>
                        ) : (
                          ""
                        )}

                        {parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_ADMIN ||
                        parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_SUPERADMIN ||
                        parseInt(localStorage.getItem("roleId")) ===
                          ADMIN_ROLES.BW_CSHEAD ? (
                          <Button
                            variant="contained"
                            size="40px"
                            color="error"
                            onClick={handleDeleteUser}
                          >
                            {" "}
                            <DeleteOutlined />
                            Delete user
                          </Button>
                        ) : (
                          ""
                        )}
                      </Material.Grid>
                      {userRoleId === 2000 && (
                        <Material.Grid item md={6}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "15px",
                              borderRadius: "10px",
                              padding: "10px",
                              backgroundColor: "#6391db",
                              marginBottom: "20px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              {" "}
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Distributor Rollback Cans
                                </Typography>
                              </Box>
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Switch checked={isrevertbackenabled} />
                                  }
                                  label={
                                    isSwitchOn ? (
                                      <Typography
                                        sx={{
                                          color: "white",
                                        }}
                                      >
                                        Enabled
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          color: "white",
                                        }}
                                      >
                                        Disabled
                                      </Typography>
                                    )
                                  }
                                  onChange={handleClickRollaback}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Material.Grid>
                      )}
                      {userRoleId === 2000 && (
                        <Material.Grid item md={6}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "15px",
                              borderRadius: "10px",
                              padding: "10px",
                              backgroundColor: "#6391db",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              {" "}
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Delivery Slots Status
                                </Typography>
                              </Box>
                              <Box>
                                <FormControlLabel
                                  sx={{ marginLeft: "40px" }}
                                  control={
                                    <Switch checked={deliverySlotStatus} />
                                  }
                                  label={
                                    deliverySlotStatus ? (
                                      <Typography
                                        sx={{
                                          color: "white",
                                        }}
                                      >
                                        Enabled
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          color: "white",
                                        }}
                                      >
                                        Disabled
                                      </Typography>
                                    )
                                  }
                                  onChange={handleSwitchDeliverySlots}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Material.Grid>
                      )}
                    </Material.Grid>
                  </Box>
                  {userRoleId === ADMIN_ROLES.BW_WFSADMIN && (
                    <>
                      {" "}
                      <Grid container>
                        <Grid item padding="20px" md={12}>
                          <Button
                            variant="contained"
                            sx={{
                              background: appColors.commonBtnColor.background,
                            }}
                            onClick={handleShowAssignedWfs}
                          >
                            Show Assigned WFS
                          </Button>
                        </Grid>
                        <Grid item md={12}>
                          {showAssignedWfs === true &&
                          switchWFSDetails === true ? (
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  margin: "20px",
                                }}
                              >
                                <Typography>WFS Assign Details</Typography>

                                <FormControlLabel
                                  label="Enable"
                                  control={
                                    <Switch checked={switchWFSDetails} />
                                  }
                                  onChange={(event) =>
                                    handleWFSSwitch(
                                      event,
                                      updateUserDetails.assignedWfsAddress
                                        .wfs_id
                                    )
                                  }
                                />
                              </Box>
                              {switchWFSDetails === false ? (
                                ""
                              ) : (
                                <Box
                                  sx={{ marginTop: "10px", padding: "10px" }}
                                >
                                  <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                      <TableBody>
                                        {rows.map((row) => (
                                          <TableRow
                                            key={row.name}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {row.name}
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.calories}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Box>
                              )}
                              {switchWFSDetails === false ? (
                                ""
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      marginTop: "10px",
                                      padding: "10px",
                                    }}
                                  >
                                    Address
                                  </Typography>

                                  <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                      <TableBody>
                                        {wfsInchargeAddress.map((row) => (
                                          <TableRow
                                            key={row.title}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {row.title}
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.data}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Box>
                              )}
                              {switchWFSDetails === false ? (
                                ""
                              ) : (
                                <Box>
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      marginTop: "10px",
                                      padding: "10px",
                                    }}
                                  >
                                    In stock
                                  </Typography>

                                  <Box
                                    sx={{ marginTop: "10px", padding: "10px" }}
                                  >
                                    <TableContainer component={Paper}>
                                      <Table aria-label="simple table">
                                        <TableBody>
                                          {rowsStock.map((row) => (
                                            <TableRow
                                              key={row.name}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row.name}
                                              </TableCell>
                                              <TableCell align="right">
                                                {row.calories}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              </Container>
            </ThemeProvider>
          </Box>
          <DeleteUserDialog
            deleteUserDialog={deleteUserDialog}
            handleDelete={handleDeleteUserDialog}
            handleClose={handleCloseDeleteUserDialog}
          />
          <AddUpdateConsumerProductPrice
            conformAddProduct={conformAddProduct}
            setConformAddProduct={setConformAddProduct}
            userId={userId}
            data={updateUserDetails.userProfileData}
            area="product"
          />
        </Box>
      </Paper>
    </>
  );
}
