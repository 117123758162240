/**
 * @author vinay kumar
 * @description Assign Incharge Wfs Page
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomConsole from "../../CustomConsole";
import { useDispatch, useSelector } from "react-redux";
import {
  AddWfsLine,
  AssignWfsToUser,
  GetWaterFillingStations,
  GetWfsLineDetails,
} from "../Redux/Actions/adminLineDetailsAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
function AssignInchargeWfs({ wfsId, userId, refershFlag }) {
  const dispatch = useDispatch();
  const wfsLineDetails = useSelector((state) => state.adminWfsLineData);
  const theme = useTheme();
  const [pincode, setPincode] = React.useState("");
  const [submitBtn, setSubmitBtn] = React.useState(false);
  const [pageChange, setPageChange] = React.useState(false);
  const [pincodeError, setPincodeError] = React.useState(false);
  //pagination
  const [pageNumber, setPageNumber] = React.useState(1);
  //There is no data Disable ForwordBtn
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [isPaginationEnable, setIsPaginationEnable] = React.useState(false);

  React.useEffect(() => {
    if (
      wfsLineDetails.allFillingStationsDetails !== undefined &&
      wfsLineDetails.allFillingStationsDetails.length > 0
    ) {
      setPageStatuss(false);
    } else {
      setPageStatuss(true);
    }
  });
  const handleClickBack = () => {
    if (pageNumber - 1 >= 1) {
      setPageNumber(pageNumber - 1);
      setPageChange(true);
    }
  };
  const handleClickForward = () => {
    setPageNumber(pageNumber + 1);
    setPageChange(true);
  };

  React.useEffect(() => {
    CustomConsole(pageNumber);
    if (pageChange === true) {
      dispatch(GetWaterFillingStations(pincode, filter, pageNumber - 1));
      setPageChange(false);
    }
  }, [pageNumber]);
  React.useEffect(() => {
    if (
      wfsLineDetails.error === false &&
      wfsLineDetails?.allFillingStationsDetails.length
    ) {
      toast.success(wfsLineDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setAddLinesFlag(false);
      setIsPaginationEnable(true);
    } else if (
      wfsLineDetails.allFillingStationsDetails.length <= 0 &&
      submitBtn === true
    ) {
      toast.error("No data found!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSubmitBtn(false);
    }
  }, [wfsLineDetails]);
  const [filter, setFilter] = React.useState("");
  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };
  const handleClickSubmit = () => {
    CustomConsole(pageNumber);
    if (pincode !== "0") {
      dispatch(GetWaterFillingStations(pincode, filter, 0));
    } else {
      toast.error("Pincode cannot be zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    setSubmitBtn(true);
  };

  const handleTableClick = (wfsMasterId) => {
    const reqObj = {
      id: userId,
      role_id: ADMIN_ROLES.BW_WFSADMIN,
      wfs_id: wfsMasterId,
    };
    dispatch(AssignWfsToUser(reqObj));
    // setrefershFlag(true);
  };

  const handleChangePincode = (event) => {
    setPincode(event.target.value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {/* Filter and Showing Assign Incharge Wfs Details here   */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "10px",
          mb: 1,
          mt: 2,
        }}
      >
        <TextField
          // sx={{ margin: "10px" }}
          id="outlined-number"
          label="Pincode"
          size="small"
          // type="number"
          value={pincode}
          // onInput={(e) => {
          //   e.target.value = Math.max(0, parseInt(e.target.value))
          //     .toString()
          //     .slice(0, 6);
          // }}
          // InputLabelProps={{
          //   shrink: true,
          // }}
          onChange={handleChangePincode}
        />

        <FormControl size="small" sx={{ minWidth: "215px" }}>
          <InputLabel id="demo-simple-select-label">Filter</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label="Filter"
            onChange={handleChangeFilter}
          >
            <MenuItem value={"ASC"}>Ascending</MenuItem>
            <MenuItem value={"DESC"}>Decending</MenuItem>
          </Select>
        </FormControl>

        <Button size="small" variant="contained" onClick={handleClickSubmit}>
          Submit
        </Button>
      </Box>
      <Box>
        <TableContainer
          sx={{ width: { md: "650px", xs: "300px" } }}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Company
                </TableCell>
                <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Owner
                </TableCell>
                <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Phone
                </TableCell>
                <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Availability
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wfsLineDetails.allFillingStationsDetails.map((row) => (
                <TableRow
                  onClick={() => handleTableClick(row.wfs_master_id)}
                  key={row.wfs_master_id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#036bfc",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.company_name}
                  </TableCell>
                  <TableCell>{row.owner_name}</TableCell>
                  <TableCell>{row.owner_phone}</TableCell>
                  <TableCell>
                    {row.is_available === true ? "Available" : "Unavailable"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isPaginationEnable ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "30px",
          }}
        >
          <Button onClick={handleClickBack}>
            <ArrowBackIos />
          </Button>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "40px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber}
          </Typography>
          <Button disabled={pageStatuss} onClick={handleClickForward}>
            <ArrowForwardIos />
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}

export default AssignInchargeWfs;
