/**
 * @author vinay kumar
 * @description Users Stock
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { useDispatch } from "react-redux";
import {
  GetAccountsDetails,
  GetUserAddressAdmin,
  TansferDistributorCanCreateOrder,
} from "../Redux/Actions/userDetailsAdminAction";
import { GetUserStockDetails } from "../Redux/Actions/userDetailsAdminAction";

import { useSelector } from "react-redux";
import AddReduceReturnDialog from "./AddReduceReturnDialog";

import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import UserStockDetailsTable from "./UserStockDetailsTable";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TransfoerCansCreateOrderDialog from "../../Components/Dialog/TransfoerCansCreateOrderDialog";
import PlaceReturnCanDialog from "../../Components/Dialog/PlaceReturnCanDialog";

function UsersStock({
  id,
  userStockData,
  userRoleId,
  userIdA,
  userId,
  alignmentStock,
}) {
  CustomConsole(userIdA);
  CustomConsole(id);
  CustomConsole(userId);
  const dispatch = useDispatch();
  const history = useHistory();
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate); //userAddressAdmin.address
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const [adminRole, setAdminRoleId] = React.useState("");
  const [address, setAddress] = React.useState([]);
  const [openDialogCreateOrder, setOpenDialogCreateOrder] =
    React.useState(false);

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const delivery = [
    {
      value: "Delivery Time (6 AM- 6 PM)",
    },
    {
      value: "24 hours",
    },
  ];

  const [addReduceReturn, setAddReduceReturn] = React.useState(false);

  // const [userId, setUserId] = React.useState("");
  const [stockUpdates, setStockUpdates] = React.useState("");

  //current change
  const [availableAreasFlag, setAvailableAreasFlag] = React.useState(false);
  const [areasAvailable, setAvailableArea] = React.useState(null);
  const [selectArea, setSelectArea] = React.useState(false);
  const [areaValue, setAreaValue] = React.useState("");
  const [openReturnCanDialog, setOpenReturnCanDialog] = React.useState(false);
  const [radioStock, setRadioStock] = React.useState("Genral");

  const bookingDetails = useSelector((state) => state.adminBookingData);

  React.useEffect(() => {
    CustomConsole(bookingDetails);
    if (availableAreasFlag && bookingDetails.availableAreas.length > 0) {
      const area = bookingDetails.availableAreas[0].Name;
      CustomConsole(area);
      setAreaValue(area);
      setAvailableArea(bookingDetails.availableAreas);
      setAvailableAreasFlag(false);
      setSelectArea(true);
    }

    // CustomConsole("in address card");
    // CustomConsole(id);
    // dispatch(GetUserAddressAdmin(id));
  }, [bookingDetails]);

  // React.useEffect(() => {
  //   dispatch(GetAccountsDetails(userIdA));
  // }, []);

  //to get the address id and send it with the api for return stock
  React.useEffect(() => {
    dispatch(GetUserAddressAdmin(id));
  }, []);

  const handleAddStock = () => {
    setAddReduceReturn(true);
    setStockUpdates("Add Stock");
  };
  const handleReturnStock = () => {
    setAddReduceReturn(true);
    setStockUpdates("Return Stock");
  };
  const handleReduceStock = () => {
    // setAddReduceReturn(true);
    // setStockUpdates("Reduce Stock");
    toast.dark(
      "This feature is in Progress, Please Contact BookWater Support!",
      {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      }
    );
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  React.useEffect(() => {
    if (userAddressAdmin.address) {
      setAddress(userAddressAdmin.address);
    }
  }, [userAddressAdmin]);

  React.useEffect(() => {
    CustomConsole(userStockData);
  }, [userStockData]);
  React.useEffect(() => {
    if (userId !== "") {
      dispatch(GetUserStockDetails(userIdA));
    }
  }, [bookingDetails]);

  const handleTransferCansCreateOrderOpenDialog = () => {
    CustomConsole(
      "---------------handleTransferCansCreateOrderOpenDialog-------------------"
    );
    setOpenDialogCreateOrder(true);
  };

  const handleCreateOrder = (props) => {
    CustomConsole("---------------handleCreateOrder----------------");
    if (
      props.container_capacity !== "" &&
      props.container_material !== "" &&
      props.total_container_qty !== ""
    ) {
      const reqObj = {
        container_capacity: props.container_capacity,
        container_material: props.container_material,
        total_container_qty: parseInt(props.total_container_qty),
        user_id: userIdA,
      };
      CustomConsole(reqObj);
      dispatch(TansferDistributorCanCreateOrder(reqObj));
      props.setTotalContainerQty("");
      setOpenDialogCreateOrder(false);
      setTimeout(() => {
        const url = `/dashboard/orders`;
        history.push(url);
      }, 500);
    } else {
      toast.error("Please fill all Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleCloseDialog = () => {
    CustomConsole("---------------handleCloseDialog----------------");
    setOpenDialogCreateOrder(false);
  };
  const handleReturnCan = () => {
    CustomConsole("---------------handleReturnCan----------------");
    setOpenReturnCanDialog(true);
  };
  const handleTabChangeradioStock = (event) => {
    CustomConsole("---------------handleReturnCan----------------");
    setRadioStock(event.target.value);
  };

  return (
    <>
      <Material.Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        {/* <Material.FormControl>
          <Material.RadioGroup
            row
            value={radioStock}
            //   defaultValue={1}
            onChange={handleTabChangeradioStock}
          >
            <Material.FormControlLabel
              value="Genral"
              control={<Material.Radio />}
              label="Genral Stocks"
            />

            <Material.FormControlLabel
              value="Return"
              control={<Material.Radio />}
              label="Return Can Stock"
            />
          </Material.RadioGroup>
        </Material.FormControl> */}
      </Material.Box>
      {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
      ADMIN_ROLES.BW_ADMIN === adminRole ||
      ADMIN_ROLES.BW_CS === adminRole ||
      ADMIN_ROLES.BW_CSHEAD === adminRole ||
      ADMIN_ROLES.BW_CSLEAD === adminRole ||
      ADMIN_ROLES.BW_OPS === adminRole ||
      ADMIN_ROLES.BW_OPSHEAD === adminRole ||
      ADMIN_ROLES.BW_SALES === adminRole ||
      ADMIN_ROLES.BW_SALESHEAD === adminRole ||
      ADMIN_ROLES.BW_FINANCE === adminRole ||
      ADMIN_ROLES.BW_FINANCEHEAD === adminRole ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <>
            {userAddressAdmin.userStockData.map((data) => {
              return <UserStockDetailsTable data={data} />;
            })}
          </>

          {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
          ADMIN_ROLES.BW_ADMIN === adminRole ||
          ADMIN_ROLES.BW_CS === adminRole ||
          ADMIN_ROLES.BW_CSHEAD === adminRole ||
          ADMIN_ROLES.BW_CSLEAD === adminRole ||
          ADMIN_ROLES.BW_OPS === adminRole ||
          ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "40px",
                  padding: "40px",
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    onClick={handleAddStock}
                    sx={{
                      background: appColors.commonBtnColor.background,
                    }}
                  >
                    Add stock
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    onClick={handleReturnStock}
                    sx={{
                      background: appColors.commonBtnColor.background,
                    }}
                  >
                    Return stock
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    onClick={handleReduceStock}
                    sx={{
                      background: appColors.commonBtnColor.background,
                    }}
                  >
                    Reduce stock
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              {userRoleId === 2000 ? (
                <Box>
                  {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                  ADMIN_ROLES.BW_ADMIN === adminRole ||
                  ADMIN_ROLES.BW_CSHEAD === adminRole ||
                  ADMIN_ROLES.BW_CSLEAD === adminRole ? (
                    <Button
                      variant="contained"
                      onClick={handleTransferCansCreateOrderOpenDialog}
                      sx={{
                        background: appColors.commonBtnColor.background,
                      }}
                    >
                      Transfer Cans Create Order
                    </Button>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )}
            </Box>
            {/* <Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleReturnCan}
                  sx={{
                    background: appColors.commonBtnColor.background,
                  }}
                >
                  Return Can
                </Button>
              </Box>
            </Box> */}
          </Box>
        </Box>
      ) : (
        "Access Denied"
      )}

      {addReduceReturn ? (
        <>
          <AddReduceReturnDialog
            addReduceReturn={addReduceReturn}
            setAddReduceReturn={setAddReduceReturn}
            userId={userIdA}
            stockUpdates={stockUpdates}
            address={address}
          />
        </>
      ) : (
        ""
      )}
      <TransfoerCansCreateOrderDialog
        openDialogCreateOrder={openDialogCreateOrder}
        handleCreateOrder={handleCreateOrder}
        handleCloseDialog={handleCloseDialog}
      />
      <PlaceReturnCanDialog openReturnCanDialog={openReturnCanDialog} />
    </>
  );
}

export default UsersStock;
