/**
 * @author vinay kumar
 * @description Add Address Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import React from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import CustomConsole from "../../CustomConsole";
import CanIcon from "../../Images/CanIcon.svg";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import {
  AddUserAddressAdmin,
  DeleteUserAddress,
  EditUserAddress,
  GetAccountsDetails,
  GetUserAddressAdmin,
} from "../Redux/Actions/userDetailsAdminAction";
import { SearchAreasByPincode } from "../Redux/Actions/adminBookingAction";
import { useSelector } from "react-redux";

import EditAddressDialog from "./EditAddressDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import * as IconName from "react-icons/fc";
import * as Material from "@mui/material";
import GlassBottle750 from "../../Images/GlassBottle750.jpg";
import DiscountRoundedIcon from "@mui/icons-material/DiscountRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import {
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { BatteryFull, Height } from "@mui/icons-material";

function AddAddressDialog({
  name,
  setName,
  mobileNum,
  setMobileNum,
  setaddressemail,
  addressemail,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  addressLine3,
  setAddressLine3,
  landmark,
  setLandmark,
  setAddress,
  pincode,
  setPincode,
  areaValue,
  setAreaValue,
  deliveryTime,
  setDeliveryTime,
  isPrimaryAddress,
  setIsPrimaryAddress,
  userIdA,
  addressBtn,
  setAddressBtn,
  setDiscount,
  discount,
  setCustomPrice,
  customPrice,
  setVBottlesCapacity,
  bottleCapacity,
  setCansCapacity,
  cansCapacity,
  cratesCapacity,
  setCratesCapacity,
}) {
  const dispatch = useDispatch();
  const userAddressAdmin = useSelector((state) => state.userDetailsUpdate);
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const [adminRole, setAdminRoleId] = React.useState("");
  const [isBilling, setIsBilling] = React.useState(false);

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  const delivery = [
    {
      value: "Delivery Time (6 AM- 6 PM)",
    },
    {
      value: "24 hours",
    },
  ];

  const [userId, setUserId] = React.useState("");

  const [deleteUserBtn, setDeleteUserBtn] = React.useState(false);
  const [saveAddressFlag, setSaveAddressFlag] = React.useState(false);
  const [isPhoneProper, setisPhoneProper] = React.useState(true);
  //current change
  const [availableAreasFlag, setAvailableAreasFlag] = React.useState(false);
  const [areasAvailable, setAvailableArea] = React.useState(null);
  const [selectArea, setSelectArea] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  // const [areaValue, setAreaValue] = React.useState("");

  const bookingDetails = useSelector((state) => state.adminBookingData);

  React.useEffect(() => {
    if (addUserAdminDetails.error === false) {
      addUserAdminDetails.users.map((data) => {
        CustomConsole("hitting address api in address card");
        dispatch(GetUserAddressAdmin(data.id));
        setUserId(data.id);
      });
    }
  }, [addUserAdminDetails, saveAddressFlag, deleteUserBtn]);

  React.useEffect(() => {
    CustomConsole(bookingDetails);
    if (availableAreasFlag && bookingDetails.availableAreas.length > 0) {
      const area = bookingDetails.availableAreas[0].Name;
      CustomConsole(area);
      setAreaValue(area);

      setAvailableArea(bookingDetails.availableAreas);
      setAvailableAreasFlag(false);
      setSelectArea(true);
    }
  }, [bookingDetails]);

  // React.useEffect(() => {
  //   dispatch(GetAccountsDetails(userIdA));
  // }, []);

  React.useEffect(() => {
    CustomConsole(userAddressAdmin);
    userAddressAdmin.accounts.map((data) => {
      setUserId(data.user_id);
    });
    if (userAddressAdmin.error === true && saveAddressFlag === true) {
      // toast.success(userAddressAdmin.addUserAddressMsg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setSaveAddressFlag(false);
      setAddressBtn(false);
      // setDeleteUserBtn(false);
      setAddressLine1("");
      setAddressLine2("");
      setAddressLine3("");
      setName("");
      setMobileNum("");
      setDeliveryTime("");
      // setIsPrimaryAddress("");
      setLandmark("");
      setPincode("");
      setDiscount("");
      setCustomPrice("");
    }
  }, [userAddressAdmin]);

  React.useEffect(() => {
    if (
      userAddressAdmin.deleteUserAddressMsg !== "" &&
      deleteUserBtn === true
    ) {
      toast.success(userAddressAdmin.deleteUserAddressMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setDeleteUserBtn(false);
    }
  }, [userAddressAdmin]);

  const handlePhoneChange = (event) => {
    let reg = /^[6-9][0-9]{9}$/g;
    var result = reg.test(event.target.value);
    setMobileNum(event.target.value);
    setisPhoneProper(result);
  };
  const handleSaveAddress = () => {
    CustomConsole("save address");
    if (
      addressLine1 !== "" &&
      addressLine2 !== "" &&
      name !== "" &&
      mobileNum !== "" &&
      landmark !== "" &&
      pincode !== "" &&
      !emailError
    ) {
      const data = {
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_line3: addressLine3,
        email: addressemail,
        contact_name: name,
        contact_phone: mobileNum,
        // delivery_timing_option: deliveryTime,
        is_primary: isPrimaryAddress,
        landmark: landmark,
        pincode: pincode,
        status: true,
        service_area: areaValue,
        discount: parseInt(discount),
        // custom_price: parseInt(customPrice),
        is_billing_address: isBilling,
        details: {
          stock_capacity: {
            cans: parseInt(cansCapacity),
            bottles: parseInt(bottleCapacity),
            crates: parseInt(cratesCapacity),
          },
        },
      };
      dispatch(AddUserAddressAdmin(data, userId));
      setAddressBtn(false);
      setSaveAddressFlag(true);
      setAddressLine1("");
      setAddressLine2("");
      setAddressLine3("");
      setName("");
      setMobileNum("");
      setDeliveryTime("");
      // setIsPrimaryAddress(false);
      setLandmark("");
      setPincode("");
      setDiscount("");
      setCustomPrice("");
      setCansCapacity(0);
      setVBottlesCapacity(0);
      setCratesCapacity(0);
      setaddressemail("");
      setTimeout(() => {
        CustomConsole("hitting address api in address card");
        dispatch(GetUserAddressAdmin(userId));
        setUserId(data.id);
      }, 1000);
    } else {
      const data = {
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_line3: addressLine3,
        email: addressemail,
        details: {
          stock_capacity: {
            cans: cansCapacity,
            bottles: bottleCapacity,
            crates: cratesCapacity,
          },
        },
        contact_name: name,
        contact_phone: mobileNum,
        delivery_timing_option: deliveryTime,
        is_primary: isPrimaryAddress,
        landmark: landmark,
        pincode: pincode,
        status: true,
        service_area: areaValue,
      };
      CustomConsole(data);
      toast.error("Please Fill all Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const findingAreas = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length === 6) {
      dispatch(SearchAreasByPincode(e.target.value));
      setAvailableAreasFlag(true);
    }
  };

  const areaValueChange = (e) => {
    setAreaValue(e.target.value);
  };
  const handleCloseAddAddress = () => {
    setAddressBtn(false);
  };

  const handleClose = () => {
    setAddressBtn(false);
  };

  const handleAddressEmailChange = (event) => {
    setaddressemail(event.target.value);
    validateEmail(event.target.value);
  };

  const validateEmail = (value) => {
    // Basic email validation
    if (value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleBlur = () => {
    validateEmail(addressemail);
  };
  return (
    <>
      <Dialog
        open={addressBtn}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <TextField
              label="Full name"
              size="small"
              value={name}
              onChange={(event) => setName(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <IconName.FcManager size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Mobile Number"
              required
              size="small"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              value={mobileNum}
              onChange={handlePhoneChange}
              error={isPhoneProper === false ? true : false}
              helperText={
                isPhoneProper === false && "Please enter a Valid Phone Number"
              }
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <IconName.FcPhoneAndroid size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              value={addressemail}
              onChange={handleAddressEmailChange}
              onBlur={handleBlur}
              error={emailError}
              helperText={
                emailError ? "Please enter a valid email address" : ""
              }
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <EmailOutlinedIcon size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Address line 1"
              required
              size="small"
              value={addressLine1}
              onChange={(event) => setAddressLine1(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <IconName.FcHome size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Address line 2"
              required
              size="small"
              value={addressLine2}
              onChange={(event) => setAddressLine2(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <IconName.FcHome size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Address line 3"
              // required
              size="small"
              value={addressLine3}
              onChange={(event) => setAddressLine3(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <IconName.FcHome size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Landmark"
              required
              size="small"
              value={landmark}
              onChange={(event) => setLandmark(event.target.value)}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <IconName.FcHome size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Pincode"
              required
              size="small"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
              value={pincode}
              onChange={findingAreas}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <IconName.FcHome size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            {areasAvailable ? (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select area
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={areaValue}
                  onChange={areaValueChange}
                  label="Select area"
                >
                  {areasAvailable.map((area) => {
                    return <MenuItem value={area.Name}>{area.Name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ) : null}
            {/* <TextField
              label="Custom price"
              required
              size="small"
              value={customPrice}
              onChange={(event) => {
                if (event.target.value >= 0) {
                  setCustomPrice(event.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <TuneRoundedIcon size="30px" color="success" />
                  </Material.InputAdornment>
                ),
              }}
            /> */}
            <TextField
              label="Discount percentages"
              required
              size="small"
              value={discount}
              onChange={(event) => {
                if (event.target.value >= 0 && event.target.value <= 99) {
                  setDiscount(event.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <DiscountRoundedIcon size="30px" color="success" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Cans Capacity"
              size="small"
              value={cansCapacity}
              onChange={(event) => {
                if (event.target.value >= 0 && event.target.value <= 9999) {
                  setCansCapacity(event.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start" sx={{ mt: "5px" }}>
                    <img src={CanIcon} alt="" sizes="20px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              label="Bottles Capacity"
              size="small"
              value={bottleCapacity}
              onChange={(event) => {
                if (event.target.value >= 0 && event.target.value <= 9999) {
                  setVBottlesCapacity(event.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start" sx={{ mt: "5px" }}>
                    <BatteryFull size="30px" />
                  </Material.InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ width: "240px" }}
              label="Crates Capacity"
              size="small"
              value={cratesCapacity}
              onChange={(event) => {
                if (event.target.value >= 0 && event.target.value <= 9999) {
                  setCratesCapacity(event.target.value);
                }
              }}
            />
            {/* <TextField
              id="outlined-select-currency"
              required
              select
              label="Select"
              helperText="Please select Delivery Time"
              value={deliveryTime}
              size="small"
              onChange={(event) => setDeliveryTime(event.target.value)}
            >
              {delivery.map((option, key) => (
                <MenuItem key={key} value={key}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={isPrimaryAddress} />}
              label="Mark as primary address"
              onChange={(event) => setIsPrimaryAddress(event.target.checked)}
            />
          </Box>
          <FormControlLabel
            control={<Checkbox checked={isBilling} />}
            label="Mark as billing address"
            onChange={(event) => setIsBilling(event.target.checked)}
          />
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSaveAddress}
            >
              Add Address
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={handleCloseAddAddress}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddAddressDialog;
