import actionType from "./actionType";

export const startLoading = (count) => {
  return {
    type: actionType.SPINNER_LOADING,
    payload: { totalLoadingCounts: count ? count : 1 },
  };
};

export const stopLoading = () => {
  return {
    type: actionType.SPINNER_LOADED,
  };
};
